import { reminderActionTypes } from '../CONSTANTS'
import { reminderServices } from '../../services'

function getReminderInfo() {
    return dispatch => {
        dispatch(request())

        reminderServices.getReminderInfo()
            .then(res => dispatch(success(res)), error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: reminderActionTypes.GET_REMINDERS_REQUEST } }
    function success(response) { return { type: reminderActionTypes.GET_REMINDERS_SUCCESS, response } }
    function failed(error) { return { type: reminderActionTypes.GET_REMINDERS_FAILED, error } }
}

function saveReminderInfo(data) {
    return dispatch => {
        dispatch(request())

        reminderServices.updateReminderInfo(data)                                                   
            .then(res => dispatch(success(res)), error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: reminderActionTypes.SAVE_REMINDER_REQUEST } }
    function success(response) { return { type: reminderActionTypes.SAVE_REMINDER_SUCCESS, response } }
    function failed(error) { return { type: reminderActionTypes.SAVE_REMINDER_FAILED, error } }
}

export const reminderActions = {
    getReminderInfo,
    saveReminderInfo
}