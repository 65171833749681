const portal = process.env.REACT_APP_PORTAL_URL
const baseUrl = process.env.REACT_APP_API_ENDPOINT
const feedUrl = process.env.REACT_APP_SIGNALS_FEED
const socketsUrl = process.env.REACT_APP_SOCKETS_URL
const spotSockets = process.env.REACT_APP_SPOT_SOCKETS
const futuresSockets = process.env.REACT_APP_FUTURES_SOCKETS
const fastSpringUrl = process.env.REACT_APP_FASTSPRING_URL
const fsWebhook = process.env.REACT_APP_FASTSPRING_WH
const viralLoopsKey = process.env.REACT_APP_VIRAL_LOOPS_CAMPAIGN_ID
const apiVersion = "/api/v2"
const isQuickTradeLive = true
const isLiveTradeOrder = true
const isLiveVersion = true
const signalFeedToken = "dYVgsDBE4cc46bG9hypN75aCUxTwDV9vX7sukaEpav"
const apiAuthToken = "yNUfSrbYp66UYtkcYck80FwLtEaEZ07zJEqwvdkvESkab"

const analyticIds = {
    google: "UA-147664022-1",
    moonbag: "UA-147664022-1"
}

const domain = '.profitfarmers.com'

const endpoints = {
    v3: {
        auth: {
            login: "/api/v3/auth/access",
            autoLogin: "/api/v3/auth/auto-login",
            confirmEmailByCode: (email, code) => `/api/v3/account/confirm-email-by-code?email=${encodeURIComponent(email)}&code=${code}`,
            resendVerificationCode: (email) => `/api/v3/account/resend-verification-code?email=${encodeURIComponent(email)}`,
            forgotPassword: "/api/v3/account/forgot-password",
            resetPassword: "/api/v3/account/reset-password",
            logout: "/api/v3/auth/logout",
            devices: {
                validate: (deviceType) => `/api/v3/devices/validate?deviceType=${deviceType}`,
                signOut: (deviceType) => `/api/v3/devices/sign-out/${deviceType}`,
                signOutOthers: "/api/v3/devices/sign-out/other-devices"
            }
        },
        user: {
            account: {
                register: "/api/v3/account/create",
                resendEmailVerificationLink: "/api/v3/account/resend-verification-link",
                onboardingState: "/api/v3/profile/onboarding-state",
                accountId: '/api/v3/profile/basic-id',
                profile: "/api/v3/profile/id",
                profileCompletion: '/api/v3/profile/get-progress',
                details: "/api/v3/profile/details",
                updateBasicInfo: "/api/v3/profile/update-basic-info",
                addAvatar: "/api/v3/profile/avatar/change"
            },
            onboarding: {
                getState: '/api/v3/onboarding/get-state',
                getBasicInfo: '/api/v3/onboarding/basic-info/get',
                getTradingExpDetails: '/api/v3/onboarding/trading-exp/get',
                getFinancialDetails: '/api/v3/onboarding/financial-details/get',
                getTradingBalances: '/api/v3/onboarding/trading-balance/get',
                saveBasicInfo: '/api/v3/onboarding/basic-info/save',
                saveTradingExpDetails: '/api/v3/onboarding/trading-exp/save',
                saveFinancialDetails: '/api/v3/onboarding/financial-details/save',
                saveExchangeKeys: '/api/v3/onboarding/exchange-integration/save-keys',
                configureExchangeKeys: '/api/v3/onboarding/exchange-integration/configure',
                configureTradingBalance: '/api/v3/onboarding/trading-balance/configure',
                setTradingBalance: '/api/v3/onboarding/trading-balance/set',
                setToComplete: '/api/v3/onboarding/complete',
                skipOnboarding: '/api/v3/onboarding/skip'
            },
            watchlistedSignals: "/api/v3/watchlist/signals",
            addToOrRemoveFromWatchlist: (signalId) => `/api/v3/watchlist/signals/add-or-remove/${signalId}`,
            trade: {
                create: "/api/v3/trade/create",
                createMarketOrder: "/api/v3/trade/create/market-order",
                details: (signalId) => `/api/v3/trade/orders/by-signal?signalId=${signalId}`,
                cancelOrder: "/api/v3/trade/order/cancel",
                exitOrder: "/api/v3/trade/order/exit",
                forceExit: "/api/v3/orders/order/exit",
                forceCancel: "/api/v3/orders/order/cancel",
                spot: {
                    open: "/api/v3/trade/open?feed=binance",
                    history: "/api/v3/trade/history?feed=binance"
                },
                futures: {
                    positions: "/api/v3/trade/positions",
                    open: "/api/v3/trade/open?feed=binancefutures",
                    history: "/api/v3/trade/history?feed=binancefutures",
                    closePosition: "/api/v3/trade/positions/close"
                },
                result: (orderId) => `/api/v3/trade/orders/by-id?orderId=${orderId}`
            },
            wallet: {
                spot: "/api/v3/wallet/spot/balance",
                futures: "/api/v3/wallet/futures/balance"
            },
            notifications: {
                items: "/api/v3/notification/items",
                markAsRead: "/api/v3/notification/items/item/mark-as-read"
            },
            requirements: {
                list: "/api/v3/requirements/list",
                markAsCompleted: "/api/v3/requirements/mark-as-completed"
            },
            agreements: {
                agreeOnWeeklyDisclaimer: "/api/v3/agreements/weekly-disclaimer/agree"
            },
            security: {
                changeDefaultPassword: "/api/v3/security/password/change-default",
                changePassword: "/api/v3/security/password/change"
            },
            subscription: {
                getDetails: '/api/v3/subscriptions/details',
                cancel: "/api/v3/subscriptions/cancel"
            },
            feedback: {
                cancelledMembership: "/api/v3/feedback/cancelled-membership"
            }
        },
        settings: {
            automations: {
                autoClose: (settingsId, isAutoCloseEnabled) => `/api/v3/automations/settings/auto-close/update?settingsId=${settingsId}&isAutoCloseEnabled=${isAutoCloseEnabled}`
            },
            themes: {
                theme: "/api/v3/themes/settings",
                changeTheme: (theme) => `/api/v3/themes/settings/change-theme/${theme}`
            },
            notifications: {
                allow: "/api/v3/notification/allow",
                list: "/api/v3/notification/settings",
                update: "/api/v3/notification/settings/update",
                updateByType: "/api/v3/notification/settings/update/by-type"
            },
            subscriptionPlans: {
                list: "/api/v3/subscription-plans/list",
                trialOffer: "/api/v3/subscription-plans/trial-offer",
                sixMonthsOffer: "/api/v3/subscription-plans/sixth-month-offer"
            }
        },
        terminal: {
            authorize: "/api/v3/terminal/authorize",
            externalLogin: "/api/v3/terminal/external-login",
            enable: "/api/v3/terminal/enable",
            disable: "/api/v3/terminal/disable",
            settings: "/api/v3/terminal/settings",
            exchangeKeys: "/api/v3/terminal/exchange/keys",
            addExchangeKey: "/api/v3/terminal/exchange/keys/add",
            delExchangeKey: "/api/v3/terminal/exchange/keys/del",
            setLeverage: (symbol, value) => `/api/v3/terminal/settings/futures/set-leverage?symbol=${symbol}&leverage=${value}`,
            setMarginType: (symbol, value) => `/api/v3/terminal/settings/futures/set-margin-type?symbol=${symbol}&marginType=${value}`
        },
        scanner: {
            priceAction: "/api/v3/scanner/price-action",
            rsi: "/api/v3/scanner/rel-strength-index",
            tradeGuide: "/api/v3/scanner/trade-guide",
            watchlist: {
                list: "/api/v3/scanner/watchlist",
                addOrRemove: (coinpair) => `/api/v3/scanner/watchlist/add-or-remove/${coinpair}`
            }
        },
        system: {
            types: {
                messageTypes: '/api/v3/types/msg-types',
                audienceTypes: '/api/v3/types/audience-types'
            },
            exchanges: "/api/v3/exchanges/get",
            states: "/api/v3/tradestates/get",
            offers: {
                skip: "/api/v3/offers/one-time/skip"
            },
            subscriptions: {
                pro: '/subscription-plans/pro/subscribe'
            },
            strategies: "/api/v3/statistics/strategies",
            periodRanges: "/api/v3/statistics/period-ranges",
            signals: "/api/v3/system/signals",
            signalDetails: (sid) => `/api/v3/system/signals/${sid}`,
            news: "/api/v3/news/get",
            statistics: {
                data: "/api/v3/statistics/get",
                netPeakGains: (period) => `/api/v3/statistics/net-peak-gains?period=${period}`,
                monthlyPeakGains: "/api/v3/statistics/monthly-net-peak-gains",
                topWinners: "/api/v3/system/top-winners"
            },
            messages: {
                getList: (page, keyword, type) => `/api/v3/messages/list?page=${page}&keyword=${keyword}&type=${type}`,
                addHeaderPhoto: '/file-uploader/photo/add',
                replaceHeaderPhoto: '/file-uploader/photo/replace',
                save: '/msgs/add',
                edit: '/msgs/edit'
            },
            sessions: {
                getInfo: '/api/v3/sessions/trade-doc/get',
                save: '/api/v3/sessions/trade-doc/add'
            },
            reminders: {
                getInfo: '/api/v3/reminders/community/status',
                save: '/api/v3/reminders/community/status/update'
            }
        }
    },
    auth: {
        requestToken: `${apiVersion}/token/request`,
        refreshToken: `${apiVersion}/token/refresh`
    },
    account: {
        getMe: `${apiVersion}/account/me`,
        getTheme: `${apiVersion}/account/settings/themes`,
        saveUserTheme: (theme) => `${apiVersion}/account/settings/themes/${theme}/save`,
        signOutDevices: `${apiVersion}/account/devices/sign-out-others/confirm`,
        validateSignOutDevices: (deviceType) => `${apiVersion}/account/devices/validate?deviceType=${deviceType}`,
        signOut: (deviceType) => `${apiVersion}/account/devices/sign-out/${deviceType}`,
        getWallet: `${apiVersion}/account/cryptos/balance`,
        getFutureWallets: `${apiVersion}/account/cryptos/balance/futures`,
        checkEmail: (email) => `${apiVersion}/account/exists?email=${email}`,
        saveProfile: `${apiVersion}/account/profile/avatar/save`,
        editProfile: `${apiVersion}/account/profile/save`,
        changePassword: `${apiVersion}/account/security/change-password`,
        changeEmail: `${apiVersion}/account/change-email`,
        cancelSubscription: `${apiVersion}/account/subscription/cancel`,
        register: `${apiVersion}/account/create`,
        resendVerification: `${apiVersion}/account/resend-verification`,
        updateAnalyticStep: `${apiVersion}/account/onboarding/analyticstep`,
        settings: `${apiVersion}/account/trading/settings`,
        enabled: `${apiVersion}/account/trading/enable`,
        saveKeys: `${apiVersion}/account/trading/keys/save`,
        disableTrading: `${apiVersion}/account/trading/disable`,
        removeTradingKey: `${apiVersion}/account/trading/rem-keys`,
        weeklyDisclaimer: `${apiVersion}/account/update-weekly-disclaimer`,
        confirmEmail: `${apiVersion}/account/confirm-email`,
        forgotPass: `${apiVersion}/account/forgot-password`,
        getChecklist: `${apiVersion}/account/get-started/checklist`,
        checklistSave: `${apiVersion}/account/get-started/checklist/save`,
        resetPass: `${apiVersion}/account/reset-password`,
        externalLogin: `${apiVersion}/account/devices/external-login`
    },
    announcement: {
        getActiveAnnouncement: `${apiVersion}/systemadmin/announcement/active`
    },
    lead: {
        leadChangePassword: `${apiVersion}/affiliate-leads/security/change-password`
    },
    signals: {
        getStates: `${apiVersion}/trading/states`,
        getSignals: (state, page, exchange) => `${apiVersion}/trading/signals?state=${state}&page=${page}&filter=${exchange}`,
        getSignalById: (id) => `${apiVersion}/trading/signals/${id}`,
        getFollowedSignals: `${apiVersion}/trading/signals/followed`,
        followSignal: (signalId) => `${apiVersion}/trading/signals/followed/save/${signalId}`
    },
    notification: {
        getNotificationLists: `${apiVersion}/notifications/lists`,
        markNotificationAsRead: `${apiVersion}/notifications/mark-as-read`,
        getNotificationSettings: `${apiVersion}/notifications/settings`,
        saveNotificationSettings: `${apiVersion}/notifications/settings/save`,
    },
    trading: {
        getAutologin: `${apiVersion}/tc/external-login`,
        getFuturesOrderHistory: `${apiVersion}/trading/orders/history?feed=binancefutures`,
        getFuturesOpenOrder: `${apiVersion}/trading/orders/open?feed=binancefutures`,
        getFuturesOrderPositions: `${apiVersion}/trading/order/positions`,
        getOrderHistory: `${apiVersion}/trading/orders/history`,
        getOpenOrder: `${apiVersion}/trading/orders/open`,
        cancelOrder: `${apiVersion}/trading/orders/cancel`,
        closePositions: `${apiVersion}/trading/order/position/market-sell`,
        keys: `${apiVersion}/account/trading/keys`,
        existingTrailingUser: "/api/v1/account/trading/external-login",
        quickTrade: `${apiVersion}/trading/orders/create-market`,
        tradeDetails: (signalId) => `${apiVersion}/trading/signal/get-details/?signalId=${signalId}`,
        exitOrder: `${apiVersion}/trading/orders/panic-sell`
    },
    tradeOrder: {
        setLeverage: (symbol, value) => `${apiVersion}/account/trading/global-leverage?symbol=${symbol}&leverage=${value}`,
        setMarginType: (symbol, value) => `${apiVersion}/account/trading/set-margin-type?symbol=${symbol}&marginType=${value}`,
        createOrder: `${apiVersion}/trading/orders/create`
    },
    exchanges: {
        exchangeOptions: `${apiVersion}/exchange/options`,
    },
    statistics: {
        getStatistics: `${apiVersion}/statistics`,
        getStrategies: `${apiVersion}/strategies`,
        getPeriodRanges: `${apiVersion}/period-range`
    },
    scanner: {
        getPriceActions: `${apiVersion}/scanners/pa`,
        getRSI: `${apiVersion}/scanners/rsi`,
        getWatchList: `${apiVersion}/scanners/watchlist`,
        saveWatchlist: (pairedCoin) => `${apiVersion}/scanners/watchlist/save/${pairedCoin}`,
        getTradeGuide: `${apiVersion}/scanners/trade-guide`
    },
    survey: {
        save: `${apiVersion}/surveys/feedback/save`
    },
    plan: {
        getPlans: `${apiVersion}/plan/get`
    },
    visitor: {
        getVisitorId: (vid) => `${apiVersion}/visitor/get-id?id=${vid}`,
        addVisitation: `${apiVersion}/visitor/save`,
    }
}

export {
    portal,
    baseUrl,
    feedUrl,
    spotSockets,
    futuresSockets,
    socketsUrl,
    fastSpringUrl,
    fsWebhook,
    endpoints,
    domain,
    isQuickTradeLive,
    signalFeedToken,
    apiAuthToken,
    isLiveTradeOrder,
    isLiveVersion,
    analyticIds,
    viralLoopsKey
}
