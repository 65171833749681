import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { authActions } from '../../redux/actions'
import { isNotNull, decryptText, onSetNewState } from '../../utils'

class AutoLoginContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isRedirectedToDashboard: false,
            returnUrl: null
        }
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true

        const { dispatch, authentication } = this.props
        if (isNotNull(authentication) && authentication.loggedIn) {
            dispatch(authActions.logout())
        }

        // const searchQuery = this.props.location.search.replace('?','')
        // if (isNotNull(searchQuery)) {
        //     const parsedData = queryString.parse(searchQuery)
        //     if (isNotNull(parsedData)) {
        //         const { returnUrl } = parsedData
        //         if (isNotNull(returnUrl)) {
        //             onSetNewState(this, {
        //                 returnUrl
        //             })
        //         }
        //     }
        // }

        const promise = new Promise((resolve) => {
            localStorage.clear()   
            resolve(true)
        })

        promise.then((result) => {
            if (isNotNull(result) && result) {
                const params = (new URL(document.location)).searchParams
                
                const returnUrl = params.get('returnUrl')
                if (isNotNull(returnUrl)) {
                    onSetNewState(this, {
                        returnUrl
                    })
                }

                const token = params.get("token")
                if (isNotNull(token)) {
                    dispatch(authActions.autoLoginByToken(token))
                }
            }
        })
    }

    componentDidUpdate(prevProps) {
        const { authentication } = this.props
        const { returnUrl } = this.state

        if (isNotNull(authentication) && 
            prevProps.authentication !== authentication && 
            isNotNull(authentication.response)) {
                const { response } = authentication
                const { success, luid, hasRedirectUrl, redirectURL } = response
                if (isNotNull(success)) {                       
                    let newDataLayer = {
                        event: 'login',
                        loginMethod: 'email',
                        user_id: ''
                    }

                    if (isNotNull(luid))
                        newDataLayer.user_id = decryptText(luid)
                        
                    window.dataLayer.push(newDataLayer)

                    if (hasRedirectUrl && redirectURL)
                        window.location.href = redirectURL
                    else {
                        if (isNotNull(returnUrl)) {
                            window.location.href = returnUrl
                        } else
                            window.location.href = '/dashboard'
                    }                    
                } else 
                    window.location.href = '/forbidden'
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    render() {
        return (
            <></>
        )
    }
}

function mapStateToProps(state) {
    let { authentication } = state
    return {
        authentication
    }
}

const connectedAutoLogin = connect(mapStateToProps)(AutoLoginContainer)
export { connectedAutoLogin as AutoLoginContainer }
