import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { Row, Col, Card, Button, Form, InputGroup } from 'react-bootstrap'
import { Form as FormFinal, Field } from 'react-final-form'
import { PasswordHelper } from '../../../../components'
import {
    InputContainer, InputWrapper
} from '../../styled_components'
import { FormButton } from '../../../../styles'
import { FormSecurityWrapper } from '../../styled_components'
import { isNull, passwordValidator } from '../../../../utils'

export const SecurityView = (props) => {
    const { userInfo } = props
    const { isSecurityEdit, newPassword, confirmPassword, oldPassword } = props.state
    const $this = props.self

    let oldPassCriteria = {
        letter: false,
        capital: false,
        number: false,
        special: false,
        size: false
    }

    let newPassCriteria = {
        letter: false,
        capital: false,
        number: false,
        special: false,
        size: false
    }

    let confirmPassCriteria = {
        letter: false,
        capital: false,
        number: false,
        special: false,
        size: false,
        match: false
    }

    const onSecurityUpdate = (isEdit) => {
        $this.setState({ isSecurityEdit: !isEdit })
    }

    const onViewPasswordAsText = (fieldName) => {
        let getState = props.state
        getState[fieldName] = {
            eyeIcon: getState[fieldName].eyeIcon === faEye ? faEyeSlash : faEye,
            type: getState[fieldName].type === "password" ? "text" : "password"
        }
        $this.setState(getState)
    }

    const onSecurityValidate = values => {
        const errors = {}

        if (isNull(values.oldPassword)) {
            errors.oldPassword = 'Old Password is required'
            oldPassCriteria = {
                letter: false,
                capital: false,
                number: false,
                special: false,
                size: false
            }
        }
        else {
            oldPassCriteria.letter = passwordValidator.hasLowercase(values.oldPassword)
            oldPassCriteria.capital = passwordValidator.hasUppercase(values.oldPassword)
            oldPassCriteria.number = passwordValidator.hasNumeric(values.oldPassword)
            oldPassCriteria.special = passwordValidator.hasSpecialChar(values.oldPassword)
            oldPassCriteria.size = passwordValidator.isWithinMinAndMaxLength(values.oldPassword)

            if (!(oldPassCriteria.letter &&
                oldPassCriteria.capital &&
                oldPassCriteria.number &&
                oldPassCriteria.special &&
                oldPassCriteria.size))
                errors.oldPassword = "Invalid password"
        }

        if (isNull(values.newPassword)) {
            errors.newPassword = 'New Password is required'
            newPassCriteria = {
                letter: false,
                capital: false,
                number: false,
                special: false,
                size: false
            }
        }
        else {
            newPassCriteria.letter = passwordValidator.hasLowercase(values.newPassword)
            newPassCriteria.capital = passwordValidator.hasUppercase(values.newPassword)
            newPassCriteria.number = passwordValidator.hasNumeric(values.newPassword)
            newPassCriteria.special = passwordValidator.hasSpecialChar(values.newPassword)
            newPassCriteria.size = passwordValidator.isWithinMinAndMaxLength(values.newPassword)

            if (!(newPassCriteria.letter &&
                newPassCriteria.capital &&
                newPassCriteria.number &&
                newPassCriteria.special &&
                newPassCriteria.size))
                errors.newPassword = "Invalid password"
        }

        if (isNull(values.confirmPassword)) {
            errors.confirmPassword = 'Cofirm Password is required'
            confirmPassCriteria = {
                letter: false,
                capital: false,
                number: false,
                special: false,
                size: false,
                match: false
            }
        } else {
            confirmPassCriteria.letter = passwordValidator.hasLowercase(values.confirmPassword)
            confirmPassCriteria.capital = passwordValidator.hasUppercase(values.confirmPassword)
            confirmPassCriteria.number = passwordValidator.hasNumeric(values.confirmPassword)
            confirmPassCriteria.special = passwordValidator.hasSpecialChar(values.confirmPassword)
            confirmPassCriteria.size = passwordValidator.isWithinMinAndMaxLength(values.confirmPassword)
            confirmPassCriteria.match = values.newPassword !== values.confirmPassword ? false : true

            if (!(confirmPassCriteria.letter &&
                confirmPassCriteria.capital &&
                confirmPassCriteria.number &&
                confirmPassCriteria.special &&
                confirmPassCriteria.size &&
                confirmPassCriteria.match))
                errors.confirmPassword = "Invalid password"
        }

        return errors
    }

    const onSecuritySubmit = async (values, form) => {
        const { oldPassword, newPassword, confirmPassword } = values
        if (newPassword && confirmPassword) {
            const newData = {
                oldPassword,
                newPassword,
                confirmPassword
            }
            props.onChangePassword(newData)
            await props.sleep(300)
            Object.keys(values).forEach(key => {
                form.change(key, undefined)
                form.resetFieldState(key)
            })
        }
    }

    const renderSecurity = (isEdit, user) => {
        if (!isEdit)
            return (
                <>
                    <Form.Group>
                        <Row>
                            <Col sm={3} md={3} lg={3} className="col-form-label">
                                Username
								</Col>
                            <Col sm={9} md={9} lg={9} className="col-form-value">
                                {user.email}
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group>
                        <Row>
                            <Col sm={3} md={3} lg={3} className="col-form-label">
                                Password
							</Col>
                            <Col sm={9} md={9} lg={9} className="col-form-value">
                                ********
							</Col>
                        </Row>
                    </Form.Group>
                </>
            )
        else
            return (
                <div className="security-contents">
                    <FormFinal
                        onSubmit={onSecuritySubmit}
                        validate={onSecurityValidate}
                        decorators={[props.decorators]}
                        render={({ handleSubmit, form, pristine }) => (
                            <Form onSubmit={(event) => { const promise = handleSubmit(event); promise && promise.then(() => { form.reset(); }); return promise; }} id="formSecurity">
                                <Row className="mb-4">
                                    <Col sm={3} md={3} lg={3} className="col-form-label">
                                        Username
                                    </Col>
                                    <Col sm={9} md={9} lg={9} className="col-form-value">
                                        {user.email}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={3} md={3} lg={3} className="col-form-title">
                                        Password
                                    </Col>
                                    <Col sm={9} md={9} lg={9}>
                                        <Form.Group controlId="formExistingPassword">
                                            <Row>
                                                <Col sm={12} md={12} lg={12} className="col-form-label">
                                                    Current Password
                                                </Col>
                                                <Col sm={12} md={12} lg={12}>
                                                    <Field name="oldPassword" type={oldPassword.type}>
                                                        {({ input, meta, type }) => (
                                                            <InputContainer className={`${meta.error && meta.touched ? 'invalid-container' : ''}`}>
                                                                <InputWrapper ref={oldPass => $this.refOld = oldPass}>
                                                                    <InputGroup>
                                                                        <Form.Control
                                                                            maxLength="12"
                                                                            placeholder="Existing Password"
                                                                            autoComplete="off"
                                                                            {...input}
                                                                        />
                                                                        <InputGroup.Append>
                                                                            <InputGroup.Text onClick={() => onViewPasswordAsText("oldPassword")}>
                                                                                <FontAwesomeIcon icon={oldPassword.eyeIcon} className="eyeIcon" />
                                                                            </InputGroup.Text>
                                                                        </InputGroup.Append>
                                                                        <PasswordHelper
                                                                            show={meta.active && meta.touched && meta.error && meta.visited}
                                                                            target={$this.refOld}
                                                                            container={$this.refOld}
                                                                            placement="bottom"
                                                                            data={isNull(input.value) ? props.initialPasswordHelperState : oldPassCriteria}
                                                                        />
                                                                    </InputGroup>
                                                                </InputWrapper>
                                                            </InputContainer>
                                                        )}
                                                    </Field>
                                                </Col>
                                            </Row>
                                        </Form.Group>

                                        <Form.Group controlId="formPassword">
                                            <Row>
                                                <Col sm={12} md={12} lg={12} className="col-form-label">
                                                    New Password
                                                </Col>
                                                <Col sm={12} md={12} lg={12}>
                                                    <Field name="newPassword" type={newPassword.type}>
                                                        {({ input, meta, type }) => (
                                                            <InputContainer className={`${meta.error && meta.touched ? 'invalid-container' : ''}`}>
                                                                <InputWrapper ref={newPass => $this.refNew = newPass}>
                                                                    <InputGroup>
                                                                        <Form.Control
                                                                            maxLength="12"
                                                                            placeholder="New Password"
                                                                            autoComplete="off"
                                                                            {...input}
                                                                        />
                                                                        <InputGroup.Append>
                                                                            <InputGroup.Text onClick={() => onViewPasswordAsText("newPassword")}>
                                                                                <FontAwesomeIcon icon={newPassword.eyeIcon} className="eyeIcon" />
                                                                            </InputGroup.Text>
                                                                        </InputGroup.Append>
                                                                        <PasswordHelper
                                                                            show={meta.active && meta.touched && meta.error && meta.visited}
                                                                            target={$this.refNew}
                                                                            container={$this.refNew}
                                                                            placement="bottom"
                                                                            data={isNull(input.value) ? props.initialPasswordHelperState : newPassCriteria}
                                                                        />
                                                                    </InputGroup>
                                                                </InputWrapper>
                                                            </InputContainer>
                                                        )}
                                                    </Field>
                                                </Col>
                                            </Row>
                                        </Form.Group>

                                        <Form.Group controlId="formConfirmPassword">
                                            <Row>
                                                <Col sm={12} md={12} lg={12} className="col-form-label">
                                                    Confirm Password
                                                </Col>
                                                <Col sm={12} md={12} lg={12}>
                                                    <Field name="confirmPassword" type={confirmPassword.type}>
                                                        {({ input, meta, type }) => (
                                                            <InputContainer className={`${meta.error && meta.touched ? 'invalid-container' : ''}`}>
                                                                <InputWrapper ref={confirmPass => $this.refConfirm = confirmPass}>
                                                                    <InputGroup>
                                                                        <Form.Control
                                                                            maxLength="12"
                                                                            placeholder="Confirm Password"
                                                                            autoComplete="off"
                                                                            {...input}
                                                                        />
                                                                        <InputGroup.Append>
                                                                            <InputGroup.Text onClick={() => onViewPasswordAsText("confirmPassword")}>
                                                                                <FontAwesomeIcon icon={confirmPassword.eyeIcon} className="eyeIcon" />
                                                                            </InputGroup.Text>
                                                                        </InputGroup.Append>
                                                                        <PasswordHelper
                                                                            show={meta.active && meta.touched && meta.error && meta.visited}
                                                                            target={$this.refConfirm}
                                                                            container={$this.refConfirm}
                                                                            placement="top"
                                                                            confirm={true}
                                                                            data={isNull(input.value) ? props.initialPasswordHelperState : confirmPassCriteria}
                                                                        />
                                                                    </InputGroup>
                                                                </InputWrapper>
                                                            </InputContainer>
                                                        )}
                                                    </Field>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <FormButton>
                                    <Button type="submit" variant="primary" className="mt-3 mb-3 save-btn" disabled={pristine}>
                                        Save Changes
                                    </Button>
                                </FormButton>
                            </Form>
                        )}
                    />
                </div>
            )
    }

    return (
        <Card>
            <Card.Header>
                <h6>Sign in and security</h6>

                <Button variant="primary" className="edit-button" onClick={() => onSecurityUpdate(isSecurityEdit)}>
                    {isSecurityEdit ? "Cancel" : "Edit"}
                </Button>
            </Card.Header>
            <Card.Body>
                <FormSecurityWrapper>
                    {renderSecurity(isSecurityEdit, userInfo)}
                </FormSecurityWrapper>
            </Card.Body>
        </Card>
    )
}