import React from 'react'
import { connect } from 'react-redux'
import { Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { tradeDoctorSessionActions } from '../../redux/actions'
import { isNull, isNotNull, onSetNewState } from '../../utils'
import { TradeDoctorSessionStyle } from './styles'

class TradeDoctorSessionBooking extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isWithinTrialPeriod: null,
            show: undefined,
            sessionId: null,
            doNotRemindAgain: false
        }

        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
    }

    componentDidUpdate() {
        if (this.mounted) {
            const { isWithinTrialPeriod, show, sessionId } = this.state
            const { dispatch, authentication, tradeDocSession } = this.props
            const { user } = authentication

            if (isNull(isWithinTrialPeriod) && isNotNull(user)) {                    
                    if (user.isWithinTrialPeriod) {
                        dispatch(tradeDoctorSessionActions.getSessionInfo())
                    }

                    onSetNewState(this, {
                        isWithinTrialPeriod: user.isWithinTrialPeriod
                    })
                }

            if (isNotNull(isWithinTrialPeriod) && isNotNull(tradeDocSession) && !tradeDocSession.loading && show === undefined) {
                    const { result } = tradeDocSession
                    if (isNotNull(result)) {
                        const { sessionInfo } = result
                        if (isNull(sessionInfo) && isWithinTrialPeriod) {
                            onSetNewState(this, {
                                show: true,
                                doNotRemindAgain: false
                            })
                        } else {
                            if (isWithinTrialPeriod) {
                                const { doNotShow, dateChecked } = sessionInfo
                                if (!doNotShow && isNotNull(dateChecked)) {                                
                                    let now = moment()
                                    let parsedDate = Date.parse(dateChecked)
                                    let lastChecked = new Date(parsedDate)
                                        lastChecked = moment(lastChecked).format('MM/DD/yyyy h:mm:ss')
                                    let duration = moment.duration(now.diff(lastChecked))
                                    let pastTime = duration.asHours()
                                    if (pastTime > 24) {
                                        setTimeout(() => {
                                            onSetNewState(this, {
                                                show: true,
                                                sessionId: sessionInfo.id,
                                                doNotRemindAgain: doNotShow
                                            })
                                        }, 2*60*1000)
                                    } else {
                                        if (isNull(sessionId)) {
                                            onSetNewState(this, {
                                                sessionId: sessionInfo.id
                                            })
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    saveSessionChanges = () => {
        const { dispatch } = this.props
        const { sessionId, doNotRemindAgain } = this.state

        let data = {
            id: isNotNull(sessionId) ? sessionId : '',
            doNotShow: doNotRemindAgain
        }
        dispatch(tradeDoctorSessionActions.saveSessionInfo(data))

        onSetNewState(this, {
            show: false
        })
    }

    handleChooseTime = (e) => {
        e.preventDefault()

        this.saveSessionChanges()
        window.open("https://calendly.com/profitfarmers/trade-doctor-5x-blueprint-session")
    }

    handleChangeReminderValue = (e) => {
        let isChecked = e.target.checked
        onSetNewState(this, {
            doNotRemindAgain: isChecked
        })
    }

    handleCloseModal = (e) => {        
        this.saveSessionChanges()
    }

    render() {
        const { show } = this.state

        if (isNotNull(show)) {
            return (
                <Modal 
                    id="td-session" 
                    className={`generic-modal booking-trade-doctor-session`}
                    aria-hidden="true"
                    data-backdrop="true"
                    backdrop={true} 
                    keyboard={false}
                    show={show}
                    centered>
                        <TradeDoctorSessionStyle>
                            <button type='button' className='btn-exit' onClick={e => this.handleCloseModal(e)}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>

                            <div className='session-details-wrapper'>
                                <div className='trade-doc'>
                                    <img src='/images/trade-doctor/trade-doc.png' alt='ProfitFarmers Trade Doctor' />
                                </div>
                                <div className='session-details'>
                                    <h2>You're invited for a FREE Trade Doctor session!</h2>
                                    <p>Don't miss this opportunity to learn new skills and perspectives.</p>
                                    <div className='session-cover'>
                                        <h5>Here's what we'll cover during the session:</h5>
                                        <ul className='session-agenda'>
                                            <li>
                                                <FontAwesomeIcon icon={faStar} />
                                                <span>Platform and 5x Blueprint Q&A</span>
                                            </li>
                                            <li>
                                                <FontAwesomeIcon icon={faStar} />
                                                <span>Why some traders fail and how to avoid</span>
                                            </li>
                                            <li>
                                                <FontAwesomeIcon icon={faStar} />
                                                <span>Trading with a Statistical edge</span>
                                            </li>
                                            <li>
                                                <FontAwesomeIcon icon={faStar} />
                                                <span>Discuss trading goals</span>
                                            </li>
                                            <li>
                                                <FontAwesomeIcon icon={faStar} />
                                                <span>Find those winning trades to meet those goals</span>
                                            </li>
                                            <li>
                                                <FontAwesomeIcon icon={faStar} />
                                                <span>Introduction to Trade Doctor for PRO members</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='session-actions'>
                                        <button type='button' onClick={e => {this.handleChooseTime(e)}}>
                                            <span>Choose a Time <FontAwesomeIcon icon={faArrowRight} /></span>
                                        </button>
                                        <div>
                                            <Form.Group controlId="dontRemindMe">
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label="Don't remind me of this again"
                                                    onChange={e => this.handleChangeReminderValue(e)} />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TradeDoctorSessionStyle>
                </Modal>
            )
        }

        return (<></>)
    }
}

function mapStateToProps(state) {
    const { authentication, tradeDocSession } = state
    return {
        authentication,
        tradeDocSession
    }
}

const connectedTradeDoctorSessionBooking = connect(mapStateToProps)(TradeDoctorSessionBooking)
export { connectedTradeDoctorSessionBooking as TradeDoctorSessionBooking }