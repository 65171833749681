import _ from 'lodash'
import { coinNames } from './data'
import { isNotNull } from '../utils'

export function getCoinName(coin) {
    coin = coin.toUpperCase()
    const find = _.find(coinNames, (val) => val.coin === coin)
    if (isNotNull(find))
        return find.name
    else
        return coin
}

export function getFromSym(symbol, toSym) {
    if (isNotNull(symbol)) {
        return symbol.substring(toSym.length, symbol.length)
    }
}