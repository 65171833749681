import styled from 'styled-components'
import { device } from '../../../styles/CONSTANTS'

export const Aside = styled.div`
    display: none;
    justify-content: center;
    align-items: ${ props => props.alignment === 'center' ? 'center' : 'start' };
    width: 100%;
    height: 100%;
    padding: 15px;
    background-color: #f2f2f2;
    background-image: url(../../images/backgrounds/aside.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    overflow: hidden;

    .content {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-box-align: center;
        color: #fff;

        .aside-title {
            font-weight: 600;
            font-size: 2rem;
        }

        .btn-link {
            display: block;
            width: 175px;
            margin: 0 auto;
            padding: 10px 15px;
            font-size: 16px;
            font-weight: 400;
            color: #fff;
            border: 1px solid #fff;
            text-align: center;
            cursor: pointer;
            line-height: 1.5;
            border-radius: .25rem;
            transition: transform .2s;
            webkit-transition: -webkit-transform .2s;
            -webkit-transition: transform .2s;
        }

        @media only screen and (min-width: 767px) AND (max-width: 1023px) {
            .aside-title {
                font-size: 26px;
            }
            p {
                font-size: 13px;
            }
            .btn-link {
                font-size: 12px;
            }
        }
    }

    @media ${device.tablet} {
        display: flex;
    }
`


