import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Image } from 'react-bootstrap'
import { SettingMenuView } from './SettingMenuView'
import { pages } from '../../navigation/CONSTANTS'
import {
    ProfileContainer, SubscriptionContainer,
    NotificationContainer, AutoTradeContainer
} from './components'
import {
    SettingsContainer, SettingsBackgroundColor,
    SettingsWrapper, SettingsNavSideBar, SettingsMainContent, SettingsBodyWrapper,
    SettingsBodyBlock, SettingsBodyBlockInner
} from './styled_components'
import { setDefaultProfileImage, isNotNull } from '../../utils'

class SettingContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            exchangeValueSelected: 'binance'
        }
    }

    getUserInfo = (user) => {
        const userInfo = {
            firstName: '',
            lastName: '',
            email: '',
            profileAvatar: null,
            dateOfBirth: new Date(),
            gender: '',
            countryOfResidence: '',
            doesHaveCustomerSubscription: false,
            isPaid: false,
            isWithinTrialPeriod: null,
            isRenew: false,
            status: 6,
            sname: null,
            paymentSource: null,
            paymentStatusTxt: "PENDING",
            nextBillingDate: null,
            spPrice: null,
            isAutoTradingAllowed: false,
            isAutoCloseEnabled: false,
            tradingApiKey: null,
            customerId: null
        }

        if (isNotNull(user)) {
            if (isNotNull(user.id))
                userInfo.customerId = user.id

            if (isNotNull(user.firstName))
                userInfo.firstName = user.firstName

            if (isNotNull(user.lastName))
                userInfo.lastName = user.lastName

            if (isNotNull(user.avatarUrl))
                userInfo.profileAvatar = user.avatarUrl

            if (isNotNull(user.dateOfBirth))     
                userInfo.dateOfBirth = user.dateOfBirth            

            if (isNotNull(user.gender))
                userInfo.gender = user.gender

            if (isNotNull(user.countryOfResidence))
                userInfo.countryOfResidence = user.countryOfResidence

            if (isNotNull(user.userName))
                userInfo.email = user.userName

            if (isNotNull(user.isPaid)) {
                userInfo.isPaid = user.isPaid
                userInfo.isRenew = !user.isPaid && isNotNull(user.subscriptionId) ? true : false
            }

            if (isNotNull(user.doesHaveCustomerSubscription)) 
                userInfo.doesHaveCustomerSubscription = user.doesHaveCustomerSubscription
                
            if (isNotNull(user.isWithinTrialPeriod))
                userInfo.isWithinTrialPeriod = user.isWithinTrialPeriod

            if (isNotNull(user.paymentStatus))
                userInfo.status = user.paymentStatus

            if (isNotNull(user.paymentSource))
                userInfo.paymentSource = user.paymentSource

            if (isNotNull(user.subscriptionName))
                userInfo.sname = user.subscriptionName

            if (isNotNull(user.paymentStatusDisplayText))
                userInfo.paymentStatusTxt = user.paymentStatusDisplayText

            if (isNotNull(user.nextBillingDate))
                userInfo.nextBillingDate = user.nextBillingDate

            if (isNotNull(user.subscriptionPrice))
                userInfo.spPrice = user.subscriptionPrice

            if (isNotNull(user.isAutoTradingAllowed))
                userInfo.isAutoTradingAllowed = user.isAutoTradingAllowed

            if (isNotNull(user.isAutoCloseEnabled))
                userInfo.isAutoCloseEnabled = user.isAutoCloseEnabled

            if (isNotNull(user.tradingApiKey))
                userInfo.tradingApiKey = user.tradingApiKey
        }
        return userInfo
    }

    handlePageViewer = (page, userInfo, isLoad) => {
        switch (page) {
            case pages.setting.profile:
                return <ProfileContainer userInfo={userInfo} />
            case pages.setting.subscription:
                return <SubscriptionContainer loading={isLoad} userInfo={userInfo} />
            case pages.setting.autoTrade:
                return <AutoTradeContainer loading={isLoad} userInfo={userInfo} />
            case pages.setting.notificationSettings:
                return <NotificationContainer userInfo={userInfo} />
            default:
                return <ProfileContainer userInfo={userInfo} />
        }
    }

    handleGetTitle = (page) => {
        switch (page) {
            case pages.setting.profile:
                return "Profile Settings"
            case pages.setting.subscription:
                return "Subscription Info"
            case pages.setting.autoTrade:
                return "Auto-trade Settings"
            case pages.setting.notificationSettings:
                return "Notifications Settings"
            default:
                return "Profile Settings"
        }
    }

    render() {
        const { page, isEmailPromt } = this.props
        const { user, loading } = this.props.authentication
        const userInfo = this.getUserInfo(user)

        return (
            <SettingsContainer id="sidebar-opt-prof" className={`${isEmailPromt ? "show-email" : ""}`}>
                <SettingsBackgroundColor className="theme-main-wrapper"></SettingsBackgroundColor>

                <SettingsWrapper className="theme-settings-wrapper">
                    <SettingsNavSideBar>
                        <h1>Settings</h1>

                        <div className="avatar">
                            <div className="profile-avatar">
                                { isNotNull(userInfo) && 
                                    <Image
                                        src={userInfo.profileAvatar}
                                        fluid
                                        style={{ width: '100%' }}
                                        onError={(e) => setDefaultProfileImage(e)} />
                                }
                            </div>
                            <div className="profile-label">
                                <span style={{ textTransform: "capitalize" }}>{userInfo.firstName}</span>
                                <span className="second-name" style={{ textTransform: "capitalize" }}>{userInfo.lastName}</span>
                            </div>
                        </div>

                        <SettingMenuView user={user} />
                    </SettingsNavSideBar>

                    <SettingsMainContent>
                        <Row>
                            <Col>
                                <SettingsBodyWrapper className="settings-block-wrapper">
                                    <SettingsBodyBlock className="settings-block">
                                        <SettingsBodyBlockInner className="settings-block-inner">
                                            <h2 className="settings-name mb-4 theme-section-title">
                                                {this.handleGetTitle(page)}
                                            </h2>
                                            {this.handlePageViewer(page, userInfo, loading)}
                                        </SettingsBodyBlockInner>
                                    </SettingsBodyBlock>
                                </SettingsBodyWrapper>
                            </Col>
                        </Row>
                    </SettingsMainContent>
                </SettingsWrapper>
            </SettingsContainer>
        )
    }
}

function mapStateToProps(state) {
    const { authentication } = state
    return {
        authentication
    }
}
const connectedSettingsContainer = connect(mapStateToProps)(SettingContainer)
export { connectedSettingsContainer as SettingContainer }