import { endpoints, baseUrl } from './CONSTANTS'
import { authServices } from './authService'

const { v3 } = endpoints

function allowNotifications(token) {
    const requestOptions = {
        method: 'POST',        
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(token)
    }

    return fetch(`${baseUrl}${v3.settings.notifications.allow}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function getNotificationLists() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${v3.user.notifications.items}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function markNotificationAsRead(data) {
    const requestOptions = {
        method: 'POST',        
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        //headers: header.addAuthorizationHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${baseUrl}${v3.user.notifications.markAsRead}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function getNotificationSettings() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${v3.settings.notifications.list}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function saveNotificationTypeChanges(data) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        //headers: header.addAuthorizationHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${baseUrl}${v3.settings.notifications.updateByType}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(err => {
            return Promise.reject(err)
        })
}

function saveNotificationSettings(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        //headers: header.addAuthorizationHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${baseUrl}${v3.settings.notifications.update}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        if (res.status === 401)
            authServices.logout()
        else
            return data
    })
}

export const notificationServices = {
    allowNotifications,
    getNotificationLists,
    markNotificationAsRead,
    getNotificationSettings,
    saveNotificationTypeChanges,
    saveNotificationSettings
}

