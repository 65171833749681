import { SafeAnchor } from 'react-bootstrap'
import { ConnectToExchangeStyle } from '../styles'
import { AddExchangeKeysFrom } from '../../../components'

export const ConnectToExchange = (props) => {
    return (
        <ConnectToExchangeStyle>            
            <div className='exchange-form-container'>
                <div className='exchange-logo'>
                    <img src='/images/layout/binance-logo.svg' />
                </div>
                <div className='exchange-form-wrapper'>
                    <AddExchangeKeysFrom 
                        setConnected={props.setToConnected} 
                        exitForm={props.close} />
                </div>
            </div>

            <div className='help-section'>                
                <div className='help-video-wrapper'>
                    <iframe
                        height="100%"
                        width="100%"
                        title="Get Started Checklist"
                        src="https://www.youtube.com/embed/NhCycW1yEKg"
                        frameBorder="0"
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen />
                </div>

                <h3>Create your API Key</h3>
                <ol>
                    <li><SafeAnchor href="https://www.binance.com/en/register?ref=42030218" target="_blank" rel="noreferrer">Create or login</SafeAnchor> to your Binance account.</li>
                    <li>Navigate to "API Management" and select "Create API"</li>
                    <li>Add label to your API Key (eg. PF API)</li>
                    <li>Complete security verification process</li>
                    <li>Copy and Paste the keys here</li>
                    <li>Make sure to tick "Enable Spot & Margin Trading"</li>
                    <li>Tick "Restrict access to trusted IP's ony" and enter the IP address <strong>46.101.72.75</strong>, and click confirm</li>
                    <li>Click "Save" button before exit</li>
                </ol>
            </div>
        </ConnectToExchangeStyle>
    )
}