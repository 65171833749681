import styled from 'styled-components'

export const ConfirmOrder = styled.button`
    background-color: #15BD6F !important;
    color: #fff !important;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    border: 1px solid #15BD6F !important;
    padding: .375rem 1.2rem !important;
    margin-left: 0;
    width: 260px;
    border-radius: 2px !important;

    &:hover,
    &:visited,
    &:focus {
        color: #fff !important;
        outline: none !important;
        box-shadow: none !important;
    }

    &:disabled {
        opacity: 0.55;
    }
`

export const QuickTradeButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
`

export const TradePrice = styled.span`
    margin-left: 5px;
`

export const QuickTradeAskPrice = styled.span`
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #212534;
    padding: 8px;
    width: 100%;
    margin-left: 5px;

    ${TradePrice} {
        color: #fff;
    }

    .ask-price {
        background: #312739;
        padding: 2px 8px;
        font-size: 13px;
        color: #B93F5F;
        font-weight: 500;
    }
`

export const QuickTradeBidPrice = styled.span`
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #192C35;
    padding: 8px;
    width: 100%;
    margin-right: 5px;

    ${TradePrice} {
        color: #fff;
    }

    .bid-price {
        background: #183B3B;
        padding: 2px 8px;
        font-size: 13px;
        color: #15BD6F;
        font-weight: 500;
    }
`

export const QuickTradePrice = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-top: 5px;
`

export const QuickTradeInput = styled.div`

    label {
        font-size: 14px;
    }

    .input-wrapper, .calculation-wrapper {
        align-items: center;
        padding: 0 10px 22px;
        background: #17212D;
        border-radius: 4px;

        img {
            width: 24px;
            height: 24px;
            margin-right: 15px;
        }

        input, p {
            border: none;
            /* background: transparent; */
            color: #ced0dd;
            font-size: 14px;
            background: #17212D;
            height: 45px;
            padding: 0;

            &:hover,
            &:visited,
            &:focus {
                outline: none;
                box-shadow: none;
                z-index: 1;
            }
        }

        p {
            cursor: default;
        }

        .input-group-append, .input-group-prepend {

            .input-group-text {
                font-size: 14px;
                background: transparent;
                border: none;
                color: #ced0dd;

                small {
                    margin-right: 5px;
                    color: #606a72;
                }
            }
        }

        .range-slider-wrapper {
            padding: 0 12px;
            position: relative;
        }
    }

    .calculation-wrapper {
        padding: 0 10px;
        margin-top: 15px;

        p {
            display: flex;
            justify-content: start;
            align-items: center;
        }
    }
`

export const QuickTradeCalculation = styled.div`
    position: relative;
    border-radius: 8px;
    display: block;
    padding: 5px 12px;
    margin-bottom: 0px;

    p {
        margin-bottom: 0;
        font-size: 14px;
        text-align: center;
        color: #cbcedb;
    }

    .coin-names {
        font-size: 13px;
        color: #B5BECB;
        font-weight: 500;
    }
`


export const QuickTradeInputWrapper = styled.div`

`


export const QuickTradeExchangeIcon = styled.div`
    background: #0E1824;
    border-radius: 100%;
    border: 1px solid #1e2733;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    cursor: pointer;

    svg {
        width: 22px !important;
        height: auto;
        color: #ced0dd;
    }
`

export const QuickTradeExchangeLabel = styled.label`

`

export const QuickTradeExchangeOrderWrapper = styled.div`
    background: #1e2733;
    padding: 10px 5px;
    border-radius: 25px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;

    .quick-trade-logo {
        width: 26px;
    }

    p {
        text-align: center;
        margin-bottom: 0;
        font-size: 18px;
        margin-left: 7px;
        line-height: 0.2;
    }
`

export const QuickTradeExchangeType = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`

export const QuickTradeExchange = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
`

export const QuickTradeExchangeWrapper = styled.div`
    position: absolute;
    top: calc(100% - 48%);
    right: 0;
    left: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    z-index: 100;
`

export const QuickTradeContainer = styled.div`
    margin-bottom: 25px;

    &:last-child {
        margin-bottom: 0;
    }
`

export const QuickTradeWrapper = styled.div`

`