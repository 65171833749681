import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faMap } from '@fortawesome/free-solid-svg-icons'
import { DashboardPageLayout, TopNavBar, MainSidebar, MainWrapper } from '../../styles'
import { 
    Bell, 
    LoggedInUser, 
    UpgradeOfferContainer, 
    TerminalLoginButton,
    HelpLinks,
    NotificationPermissionAlert, 
    StatisticsMenuLink,
    ScannerMenuLinks,
    BlueprintMenuLink,
    MessageBoardMenuLink,
    CommunityMenuLink } from '../../components'
import { mediaDeviceSizes } from '../../constants/mediaQueries'
import { 
    InjectFastSpring, 
    addPageViewEvent, 
    seo,
    getSize, 
    isSupported,
    isNotNull } from '../../utils'

export const UpgradeOfferPage = (props) => {    
    let history = props.history
    let [device, setDevice] = useState(null)
    let [sidebarPane, setSidebarVisibility] = useState(null)
    const [isTokenFound, setTokenFound] = useState(null)

    useEffect(() => {
        seo({
            title: "Upgrade to Pro",
            metaDescription: ""
        })
        addPageViewEvent()

        document.body.classList.remove('offers')
        document.body.classList.remove('onboarding')
        document.body.classList.remove('messages')
        document.body.classList.remove('inner-pages')
        document.body.classList.add('upgrade-to-pro')
        
        InjectFastSpring()
        
        let deviceSize = getSize(window.innerWidth)
        setDevice(deviceSize)

        if (deviceSize === mediaDeviceSizes.xs || deviceSize === mediaDeviceSizes.sm || device === mediaDeviceSizes.lg)
            setSidebarVisibility(false)
        else
            setSidebarVisibility(true)
    }, [])

    const toggleSidebar = (e) => {
        let newState = !sidebarPane
        setSidebarVisibility(newState)
    }

    return (
        <>
            { isSupported() && isNotNull(isTokenFound) && !isTokenFound && 
                <NotificationPermissionAlert />
            }

            <DashboardPageLayout 
                id="page-wrapper" 
                className={`${sidebarPane && (device === mediaDeviceSizes.xs || device === mediaDeviceSizes.sm || device === mediaDeviceSizes.md || device === mediaDeviceSizes.lg) ? 'show': ''} ${isNotNull(isTokenFound) && !isTokenFound ? ' notif-permission' : ''}`}>
                <div className="back-drop" />

                <TopNavBar className={ isNotNull(isTokenFound) && !isTokenFound ? 'has-notif-permission' : '' }>
                    { (device === mediaDeviceSizes.xs || device === mediaDeviceSizes.sm || device === mediaDeviceSizes.md || device === mediaDeviceSizes.lg) && 
                        <button 
                            type="button" 
                            className='sidebar-toggle-btn'
                            onClick={(e) => toggleSidebar(e)} >
                            <FontAwesomeIcon icon={faBars} />
                        </button>
                    }
                    <NavLink to={`/dashboard`} className='brand'>
                        <img src='/images/brand/pf-logo.png' alt="ProfitFarmers Logo" />
                    </NavLink>
                    <div className='nav-menu-wrapper'>
                        <Bell 
                            isTokenFound={isTokenFound} 
                            setTokenFound={setTokenFound}
                            history={history} />

                        <LoggedInUser />
                    </div>
                </TopNavBar>

                <MainSidebar className={`${ sidebarPane && (device === mediaDeviceSizes.xs || device === mediaDeviceSizes.sm  || device === mediaDeviceSizes.md || device === mediaDeviceSizes.lg) ? 'show': ''} ${isNotNull(isTokenFound) && !isTokenFound ? ' has-notif-permission' : ''}`}>
                    <ul className='sidebar-menu-list'>
                        <li className='sidebar-menu-item'>
                            <NavLink to="/dashboard" className="menu-item-link">
                                <span className='menu-item-link-icon'><i className='fas fa-th-large'></i></span>
                                <span className='menu-item-link-text'>Dashboard</span>
                            </NavLink>
                        </li>
                        <li className='sidebar-menu-item'>
                            <div className='sidebar-menu-title'>
                                <span className='menu-title-text'>Tools</span>
                            </div>
                            <ul className='sidebar-submenu-list'>
                                <li className='sidebar-submenu-item'>
                                    <NavLink to="/signals" className="sidebar-submenu-item-link">
                                        <span className='submenu-item-link-icon'><i className='fas fa-seedling'></i></span>
                                        <span className='submenu-item-link-text'>Signals</span>
                                    </NavLink>                                
                                </li>
                                <li className='sidebar-submenu-item'>
                                    <TerminalLoginButton />
                                </li>

                                <ScannerMenuLinks />
                                <li className='sidebar-submenu-item'>
                                    <BlueprintMenuLink />
                                </li>
                                <MessageBoardMenuLink />
                            </ul>
                        </li>
                        <li className='sidebar-menu-item'>
                            <div className='sidebar-menu-title'>
                                <span className='menu-title-text'>Trade History</span>
                            </div>
                            <ul className='sidebar-submenu-list'>
                                <li className='sidebar-submenu-item'>
                                    <NavLink to="/orders" className="sidebar-submenu-item-link">
                                        <span className='submenu-item-link-icon'><i className='fas fa-file-invoice'></i></span>
                                        <span className='submenu-item-link-text'>Trade Orders</span>
                                    </NavLink>                                
                                </li>                           
                                    
                                <StatisticsMenuLink />
                            </ul>
                        </li>
                        <li className='sidebar-menu-item'>
                            <div className='sidebar-menu-title'>
                                <span className='menu-title-text'>Help Centre</span>
                            </div>
                            
                            <HelpLinks />
                        </li>
                        {/* <li className='sidebar-menu-item' id="trader-community">
                            <div className='sidebar-menu-title'>
                                <span className='menu-title-text'>Community</span>
                            </div>
                            
                            <ul className='sidebar-submenu-list'>
                                <CommunityMenuLink />
                            </ul>
                        </li> */}
                    </ul>
                </MainSidebar>
                
                <MainWrapper>
                    <UpgradeOfferContainer />
                </MainWrapper>
            </DashboardPageLayout>
        </>
    )
}