import React  from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FeaturesComparisonSectionStyle } from './styles/FeaturesComparisonStyle'

export const FeaturesComparisonSection = () => {
    const tradingSignalsTp = () => (
        <Tooltip style={{ zIndex: '9999' }}>
            Add some tooltip right here
        </Tooltip>
    )

    const takeProfitTp = () => (
        <Tooltip style={{ zIndex: '9999' }}>
            Add some tooltip right here
        </Tooltip>
    )

    const marketUpdatesTp = () => (
        <Tooltip style={{ zIndex: '9999' }}>
            Add some tooltip right here
        </Tooltip>
    )

    const breakevenStopLossTp = () => (
        <Tooltip style={{ zIndex: '9999' }}>
            Add some tooltip right here
        </Tooltip>
    )

    const tradeDoctorTp = () => (
        <Tooltip style={{ zIndex: '9999' }}>
            Add some tooltip right here
        </Tooltip>
    )

    const copyTradeTp = () => (
        <Tooltip style={{ zIndex: '9999' }}>
            Add some tooltip right here
        </Tooltip>
    )

    const ladderingModeTp = () => (
        <Tooltip style={{ zIndex: '9999' }}>
            Add some tooltip right here
        </Tooltip>
    )

    const tradeGuardianTp = () => (
        <Tooltip style={{ zIndex: '9999' }}>
            Add some tooltip right here
        </Tooltip>
    )

    const advancedTradingTp = () => (
        <Tooltip style={{ zIndex: '9999' }}>
            Add some tooltip right here
        </Tooltip>
    )

    const marketScannersTp = () => (
        <Tooltip style={{ zIndex: '9999' }}>
            Add some tooltip right here
        </Tooltip>
    )

    const tradingAcademyTp = () => (
        <Tooltip style={{ zIndex: '9999' }}>
            Add some tooltip right here
        </Tooltip>
    )

    const customerSupportTp = () => (
        <Tooltip style={{ zIndex: '9999' }}>
            Add some tooltip right here
        </Tooltip>
    )

    return (
        <FeaturesComparisonSectionStyle>
            <div className='container'>
                <div className='section-header'>
                    <h2 className='section-title'>ProfitFarmers Free vs. Pro</h2>
                    <p>Unlock the Full power of the ProfitFarmers System Now</p>
                </div>

                <div className='features-comparison-wrapper'>
                    <ul className='features-list'>
                        <li className='features-list-header'>
                            <span>Features</span>
                        </li>

                        <li>
                            <OverlayTrigger 
                                placement="top" 
                                delay={(window.innerWidth > 766 && { show: 200, hide: 1000 })}
                                overlay={tradingSignalsTp()}>
                                <span className='info-icon'><i className="fas fa-info-circle"></i></span>
                            </OverlayTrigger>
                            <span>Trading Signals</span>
                        </li>

                        <li>
                            <OverlayTrigger 
                                placement="top" 
                                delay={(window.innerWidth > 766 && { show: 200, hide: 1000 })}
                                overlay={takeProfitTp()}>
                                <span className='info-icon'><i className="fas fa-info-circle"></i></span>
                            </OverlayTrigger>
                            <span>Take-Profit Targets</span>
                        </li>

                        <li>
                            <OverlayTrigger 
                                placement="top" 
                                delay={(window.innerWidth > 766 && { show: 200, hide: 1000 })}
                                overlay={marketUpdatesTp()}>
                                <span className='info-icon'><i className="fas fa-info-circle"></i></span>
                            </OverlayTrigger>
                            <span>Market Updates</span>
                        </li>

                        <li>
                            <OverlayTrigger 
                                placement="top" 
                                delay={(window.innerWidth > 766 && { show: 200, hide: 1000 })}
                                overlay={breakevenStopLossTp()}>
                                <span className='info-icon'><i className="fas fa-info-circle"></i></span>
                            </OverlayTrigger>
                            <span>Breakeven Stop Loss</span>
                        </li>

                        <li>
                            <OverlayTrigger 
                                placement="top" 
                                delay={(window.innerWidth > 766 && { show: 200, hide: 1000 })}
                                overlay={tradeDoctorTp()}>
                                <span className='info-icon'><i className="fas fa-info-circle"></i></span>
                            </OverlayTrigger>
                            <span>Trade Doctor</span>
                        </li>

                        <li>
                            <OverlayTrigger 
                                placement="top" 
                                delay={(window.innerWidth > 766 && { show: 200, hide: 1000 })}
                                overlay={copyTradeTp()}>
                                <span className='info-icon'><i className="fas fa-info-circle"></i></span>
                            </OverlayTrigger>
                            <span>Copy-Trade</span>
                        </li>

                        <li>
                            <OverlayTrigger 
                                placement="top" 
                                delay={(window.innerWidth > 766 && { show: 200, hide: 1000 })}
                                overlay={ladderingModeTp()}>
                                <span className='info-icon'><i className="fas fa-info-circle"></i></span>
                            </OverlayTrigger>
                            <span>Laddering Mode</span>
                        </li>

                        <li>
                            <OverlayTrigger 
                                placement="top" 
                                delay={(window.innerWidth > 766 && { show: 200, hide: 1000 })}
                                overlay={tradeGuardianTp()}>
                                <span className='info-icon'><i className="fas fa-info-circle"></i></span>
                            </OverlayTrigger>
                            <span>Trade Guardian</span>
                        </li>

                        <li>
                            <OverlayTrigger 
                                placement="top" 
                                delay={(window.innerWidth > 766 && { show: 200, hide: 1000 })}
                                overlay={advancedTradingTp()}>
                                <span className='info-icon'><i className="fas fa-info-circle"></i></span>
                            </OverlayTrigger>
                            <span>Advanced Trading</span>
                        </li>

                        <li>
                            <OverlayTrigger 
                                placement="top" 
                                delay={(window.innerWidth > 766 && { show: 200, hide: 1000 })}
                                overlay={marketScannersTp()}>
                                <span className='info-icon'><i className="fas fa-info-circle"></i></span>
                            </OverlayTrigger>
                            <span>Market Scanners</span>
                        </li>

                        <li>
                            <OverlayTrigger 
                                placement="top" 
                                delay={(window.innerWidth > 766 && { show: 200, hide: 1000 })}
                                overlay={tradingAcademyTp()}>
                                <span className='info-icon'><i className="fas fa-info-circle"></i></span>
                            </OverlayTrigger>
                            <span>Trading Academy</span>
                        </li>

                        <li>
                            <OverlayTrigger 
                                placement="top" 
                                delay={(window.innerWidth > 766 && { show: 200, hide: 1000 })}
                                overlay={customerSupportTp()}>
                                <span className='info-icon'><i className="fas fa-info-circle"></i></span>
                            </OverlayTrigger>
                            <span>Customer Support</span>
                        </li>
                    </ul>

                    <ul className='features-list checklist'>
                        <li className='features-list-header'>
                            <span className='plan-icon'>
                                <img src='/images/subscriptions/bicycle.svg' alt='' />
                            </span>
                            <span className='plan-text'>Free Plan</span>
                            <span className='small-text'>FOR GETTING STARTED</span>
                        </li>

                        <li>
                            <span>Limited</span>
                            <span className='plan-icon bicycle'>
                                <img src='/images/subscriptions/bicycle.svg' alt='' />
                            </span>
                            <span className='frequency'>20-50/m</span>
                        </li>

                        <li>                            
                            <span>Only Target 1</span>
                            <ul className='take-profit-targets'>
                                <li><span className='hit'>T1</span></li>
                                <li><span>T2</span></li>
                                <li><span>T3</span></li>
                                <li><span>T4</span></li>
                            </ul>
                        </li>

                        <li>                            
                            <span>Limited</span>
                        </li>

                        <li>
                            <FontAwesomeIcon size="xs" icon={faTimes} className='not-supported' />
                        </li>

                        <li>
                            <FontAwesomeIcon size="xs" icon={faTimes} className='not-supported' />
                        </li>

                        <li>
                            <FontAwesomeIcon size="xs" icon={faCheck} className='supported' />
                        </li>

                        <li>
                            <FontAwesomeIcon size="xs" icon={faCheck} className='supported' />
                        </li>

                        <li>
                            <FontAwesomeIcon size="xs" icon={faCheck} className='supported' />
                        </li>

                        <li>
                            <FontAwesomeIcon size="xs" icon={faTimes} className='not-supported' />
                        </li>

                        <li>
                            <FontAwesomeIcon size="xs" icon={faTimes} className='not-supported' />
                        </li>

                        <li>
                            <FontAwesomeIcon size="xs" icon={faCheck} className='supported only-sm' />
                            <span className='no-sm'>Basic Access</span>
                        </li>

                        <li>
                            <FontAwesomeIcon size="xs" icon={faCheck} className='supported only-sm' />
                            <span className='no-sm'>Limited</span>
                        </li>
                    </ul>

                    <ul className='features-list checklist pro'>
                        <li className='features-list-header'>
                            <div className='pro-ribbon'><span>Full Access</span></div>
                            <span className='plan-icon'>
                                <img src='/images/subscriptions/rocket.svg' alt='' />
                            </span>
                            <span className='plan-text'>Pro Plan</span>
                            <span className='small-text'>FOR CRUSHING CRYPTO</span>
                        </li>

                        <li>
                            <span>Unlimited</span>
                            <span className='plan-icon rocket'>
                                <img src='/images/subscriptions/rocket.svg' alt='' />
                            </span>
                            <span className='frequency'>100-300+/m</span>
                        </li>

                        <li>                            
                            <span>All Targets</span>                            
                            <ul className='take-profit-targets'>
                                <li><span className='hit'>T1</span></li>
                                <li><span className='hit'>T2</span></li>
                                <li><span className='hit'>T3</span></li>
                                <li><span className='hit'>T4</span></li>
                            </ul>
                        </li>

                        <li>                            
                            <span className='text-em'>All Updates</span>
                        </li>

                        <li>
                            <FontAwesomeIcon size="xs" icon={faCheck} className='supported' />
                        </li>

                        <li>
                            <FontAwesomeIcon size="xs" icon={faCheck} className='supported' />
                        </li>

                        <li>
                            <FontAwesomeIcon size="xs" icon={faCheck} className='supported' />
                        </li>

                        <li>
                            <FontAwesomeIcon size="xs" icon={faCheck} className='supported' />
                        </li>

                        <li>
                            <FontAwesomeIcon size="xs" icon={faCheck} className='supported' />
                        </li>

                        <li>
                            <FontAwesomeIcon size="xs" icon={faCheck} className='supported' />
                        </li>

                        <li>
                            <FontAwesomeIcon size="xs" icon={faCheck} className='supported' />
                        </li>

                        <li>
                            <FontAwesomeIcon size="xs" icon={faCheck} className='supported only-sm' />
                            <span className='no-sm text-em'>Full Access</span>
                        </li>

                        <li>
                            <FontAwesomeIcon size="xs" icon={faCheck} className='supported only-sm' />
                            <span className='no-sm text-em'>Priority</span>
                        </li>
                    </ul>
                </div>

                <div className='tp-feedback'>
                    <span className='img-wrapper'>
                        <img src='/images/subscriptions/tp-oliver.png' alt='TrustPilot Rating' />
                    </span>
                </div>
            </div>
        </FeaturesComparisonSectionStyle>
    )
}