import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { routes } from '../../navigation/CONSTANTS'
import { onboardingActions, userActions } from '../../redux/actions'
import { onboardingState } from '../../constants/onboardingState'
import { isNotNull, onSetNewState } from '../../utils'

class Initialize extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            hasInitializedData: false,
            hasCompletedOnboarding: null
        }
        this.mounted = false
    }
    
    componentDidMount() {
        this.mounted = true
        const { dispatch } = this.props
        dispatch(onboardingActions.getOnboardingState())
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { dispatch, onboardingUser } = this.props
            if (isNotNull(onboardingUser) && prevProps.onboardingUser !== onboardingUser) {
                const { status } = onboardingUser
                if (isNotNull(status)) {
                    const { hasSkipped, state } = status
                    if (parseInt(state) === onboardingState.COMPLETE || hasSkipped) {
                        dispatch(userActions.getMe())
                        
                        setTimeout(() => {
                            onSetNewState(this, {
                                hasInitializedData: true,
                                hasCompletedOnboarding: true
                            })
                        }, 2000)
                    } else {
                        onSetNewState(this, {
                            hasInitializedData: true,
                            hasCompletedOnboarding: false
                        })
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    redirectTo = () => {
        const { hasInitializedData, hasCompletedOnboarding } = this.state
        if (isNotNull(hasInitializedData) && hasInitializedData) {
            return (<Redirect to={{
                pathname: routes.dashboard
            }}/>)

            // if (isNotNull(hasCompletedOnboarding)) {
            //     if (hasCompletedOnboarding) {
            //         return (<Redirect to={{
            //             pathname: routes.dashboard
            //         }}/>)
            //     } else {
            //         return (<Redirect to={{
            //             pathname: routes.account.getStarted
            //         }}/>)
            //     }
            // }
        }
    }

    render() {
        return (
            <>
                {this.redirectTo()}
            </>
        )
    }
}

function mapStateToProps(state) {
    let { onboardingUser } = state
    return {
        onboardingUser
    }
}

const connectedInitialize = connect(mapStateToProps)(Initialize)
export { connectedInitialize as Initialize }