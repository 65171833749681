import React from 'react'
import 'intro.js/introjs.css'
import { Steps } from 'intro.js-react'
import { onSetNewState, getSize } from '../../utils'
import { mediaDeviceSizes } from '../../constants/mediaQueries'

export class Tour extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            stepsEnabled: false,
            steps: [{
                element: '#tour-npg',
                title: 'Net Peak Gains Calculator',
                intro: 'Displays the calculation of Net Peak Gains for the last 30 & 90 days',
                position: 'right'
            }, {
                element: '#tour-upgrade',
                title: 'Upgrade to PRO',
                intro: 'Unlock all signals, features and analysis',
                position: 'left'
            }, {
                element: '#tour-msg-board',
                title: 'Message Board',
                intro: 'You can see our latest alerts, offers and market analysis here',
                position: 'left'
            }, {
                element: '#tour-academy',
                title: 'Learn to Use the Platform',
                intro: 'Watch & learn how to start trading using ProfitFarmers',
                position: 'left'
            }, {
                element: '#tour-case-studies',
                title: 'Case Studies and Interviews',
                intro: 'Watch and read Pro member testimonials and so that you can replicate their success',
                position: 'left'
            }, {
                element: '#tour-guides',
                title: 'Level Up Your Game',
                intro: 'Learn fundamentals about trading, how markets work and new strategies',
                position: 'left'
            }]
        }
        this.baseState = this.state
        this.stepsRef = null
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true

        let deviceSize = getSize(window.innerWidth)
        if (deviceSize !== mediaDeviceSizes.xs && 
            deviceSize !== mediaDeviceSizes.sm) {
                let { steps } = this.state
                steps.push({
                    element: '#tour-sidebar',
                    title: 'Sidebar Menu',
                    intro: 'Navigate between all of your trading tools and features',
                    position: 'top-right-aligned'
                }, {
                    element: '#tour-help-centre',
                    title: 'Get Support',
                    intro: 'Learn from the Knowledge base or contact our support team directly if you ever need help',
                    position: 'right'
                })

                onSetNewState(this, {
                    steps
                })
            }
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { enabled } = this.props

            if (prevProps.enabled !== enabled) {
                if (enabled)
                    document.body.style.overflow = 'hidden'

                onSetNewState(this, {
                    stepsEnabled: enabled
                })
            }
        }
    }

    componentWillUnmount() {
        this.setState(this.baseState)
        this.stepsRef = null
        this.mounted = false
    }

    handleExitTour = () => {
        document.body.style.overflow = 'auto'
    }

    render() {
        const { onCompleteTour } = this.props
        const { stepsEnabled, steps } = this.state
        const options = {
            hidePrev: true,
            tooltipClass: 'tour-tooltip',
            highlightClass: 'tour-helperLayer',
            exitOnEsc: false,
            exitOnOverlayClick: false,
            keyboardNavigation: false,
            showProgress: false,
            showBullets: false
        }

        return (
            <Steps 
                enabled={stepsEnabled}
                steps={steps} 
                ref={steps => (this.stepsRef = steps)}
                initialStep={0}
                options={options}
                onExit={() => this.handleExitTour()}
                onComplete={() => onCompleteTour()} />
        )
    }
}