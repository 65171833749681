import { useState } from 'react'
import moment from 'moment'
import ReactPlayer from 'react-player/lazy'
import { isNotNull } from '../../../utils'
import { TheNetPeakGainsSectionStyle } from './styles'

export const TheNetPeakGainsSection = (props) => {
    let [remainingDays, setRemainingDays] = useState('')
    let [remainingHours, setRemainingHours] = useState('')
    let [remainingMinutes, setRemainingMinutes] = useState('')
    let [remainingSeconds, setRemainingSeconds] = useState('')

    let [isEarlyBirdOffer, setIsEarlyBirdOffer] = useState(false)
    let [isFinalYearlyOffer, setIsFinalYearlyOffer] = useState(false)

    let parsedDate = Date.parse(props.validUntil)
    let expiry = new Date(parsedDate)
        expiry = moment(expiry).format('MM/DD/yyyy h:mm:ss')

    const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

    let today = new Date(),
        dd = String(today.getDate()).padStart(2, "0"),
        mm = String(today.getMonth() + 1).padStart(2, "0"),
        yyyy = today.getFullYear(),
        nextYear = yyyy + 1,
        dayMonth = moment(expiry).format('MM/DD/')

    today = mm + "/" + dd + "/" + yyyy
    if (today > expiry) {
        expiry = dayMonth + nextYear
    }

    const countDown = new Date(expiry).getTime(),
        x = setInterval(function() {    
            const now = new Date().getTime(),
                distance = countDown - now

            setRemainingDays(Math.floor(distance / (day)))
            setRemainingHours(Math.floor((distance % (day)) / (hour)))
            setRemainingMinutes(Math.floor((distance % (hour)) / (minute)))
            setRemainingSeconds(Math.floor((distance % (minute)) / second))

            if (isNotNull(remainingDays)) {
                if (parseInt(remainingDays) >= 14) {
                    if (!isEarlyBirdOffer)
                        setIsEarlyBirdOffer(true)

                    if (isFinalYearlyOffer)
                        setIsFinalYearlyOffer(false)
                } else {                        
                    if (isEarlyBirdOffer)
                        setIsEarlyBirdOffer(false)
                    
                    if (!isFinalYearlyOffer)  
                        setIsFinalYearlyOffer(true)
                }
            }
            if (distance < 0) {
                clearInterval(x)
            }
            //seconds
        }, 1000)

    return (
        <TheNetPeakGainsSectionStyle>
            <div className='container'>
                <h2 className='section-title'>Conserve Energy and Then Unleash Your Inner Techno-Tiger: +5000% Gains in 1 Month</h2>

                <div className='npg-chart'>
                    <img src='/images/layout/monthly-net-peak-gains.png' alt='Monthly Net Peak Gains' />
                </div>

                <p>Even the mighty Bengal Tiger couldn't hunt down 111 juicy animals in 1 month... unless it had some powerful tech upgrades</p>
                <p>ProfitFarmers Pro traders GORGED themselves in November just in time to hibernate for Christmas (chill life!).</p>

                <div className='trade-result'>
                    <div className='signal-thumbnail'>
                        <img src='/images/layout/sandusdt.png' alt='SANDUSDT' />
                    </div>
                    <div className='trade-label'>
                        <p>This one SANDUSDT trade even clocked in at <span className='em'>+1,544.97%</span></p>
                    </div>
                </div>

                <ul className='snapvids'>
                    <li>
                        <ReactPlayer 
                            url={'https://vimeo.com/644686342/8fa72414f2'}
                            controls={true} 
                            width='100%' 
                            volume={0} />

                        <p className='video-title'>LRC - Result +472% in 5 days</p>
                    </li>
                    <li>
                        <ReactPlayer 
                            url={'https://vimeo.com/649877279/3fd7c8106e'}
                            controls={true} 
                            width='100%' />

                        <p className='video-title'>SAND - Result +2819% in 25 days</p>
                    </li>
                    <li>
                        <ReactPlayer 
                            url={'https://vimeo.com/653587241/4aac414be7'}
                            controls={true} 
                            width='100%' />

                        <p className='video-title'>ARPA - Result +282% in 31 days</p>
                    </li>
                </ul>

                <p className='full-w'>Think about it for a moment. You must know by now that something in crypto is regularly going <strong>BOOM</strong>.</p>

                <h3>
                    <span>It's like clockwork.</span>
                    <span className='cog-icon'>
                        <img src='/images/layout/cog.png' alt="It's like clockwork" />
                    </span>
                </h3>

                <p>Even if you sat back and didn't trade at all for 11 months of the year and just jumped in at ONE TIME you could still generate HUGE profits in the blink of an eye.</p>
                <p>The catch is that without help, you'll never know when the market is about to explode. Any month can turn into an absolute monster haul.</p>
                <p>This is EXACTLY why we only recommend long-term memberships.</p>
                <p>
                    { isEarlyBirdOffer && <strong className='daily-rate'>$4 a day </strong> }
                    { isFinalYearlyOffer && <strong className='daily-rate'>$5 a day </strong> }
                    <span>for access to opportunities like this is a NO BRAINER. Stop thinking about rabbits and start hunting those buffalo!</span></p>
            </div>
        </TheNetPeakGainsSectionStyle>
    )
}