import glamorous from 'glamorous'
import { mediaQueries } from '../../constants/mediaQueries'

export const TopNavBar = glamorous.nav({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '56px',
    padding: '6px 25px',
    backgroundColor: '#0b1219',
    borderBottom: '2px solid #0a0f16',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99,

    '& .sidebar-toggle-btn': {
        display: 'none',
        background: 'none',
        outline: 'none',
        boxShadow: 'none',
        border: 'none',
        backgroundColor: '#0b1219',
        marginLeft: '-15px',
        padding: '17px',
        color: '#b8cde3'
    },

    '& .brand': {
        display: 'inline-block',
        width: '220px',

        '& img': {
            width: '100%',
            verticalAlign: 'middle'
        }
    },

    '& .nav-menu-wrapper': {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto'
    },

    '&.has-notif-permission': {
        top: '79px'
    },

    [mediaQueries.extraSmallDevices]: {
        padding: '6px 15px',

        '& .sidebar-toggle-btn': {
            display: 'inline-block'
        }
    },

    [mediaQueries.mediumDevices]: {
        padding: '6px 15px',

        '&.has-notif-permission': {
            top: '36px'
        },

        '& .sidebar-toggle-btn': {
            display: 'inline-block'
        }
    },

    [mediaQueries.largeDevices]: {
        '& .sidebar-toggle-btn': {
            width: '60px',
            height: '56px'
        }
    },

    [mediaQueries.extraLargeDevices]: {
        '& .sidebar-toggle-btn': {
            display: 'none'
        }
    }
})