import React from 'react'
import { CancelAnytimeSectionStyle } from './styles'
import { DynamicCheckout } from '../..'

export const CancelAnytimeSection = (props) => {
    return(
        <CancelAnytimeSectionStyle>
            <div className='container'>
                <img src='/images/trial-offer/cancel-anytime.png' />
                <h2 className='section-title'>NO RISK GUARANTEE - CANCEL ANYTIME</h2>
                <p>At the end of your $21, 21 day PRO trial, your plan will automatically renew onto our monthly $399 membership unless you either <strong>cancel</strong> or <strong>choose a more cost effective option</strong>.</p>
                <p>Most users find the PRO experience entirely exhilarating and quickly move to secure a cost effective membership plan so that they can continue their long-term trading gains.</p>
                <p>If you find the platform isn't for you, no problem, you can easily cancel by navigating to your subscription settings within your dashboard and clicking cancel before your trial period expires. <strong>You won't be charged again, we promise</strong>. We'll also send you a reminder when your trial is almost complete just to be sure.</p>
                <p>21 days of PRO trading for $21 and you can leave anytime to avoid a rebill. It's that simple!</p>
            
                <div className='checkout-btn'>
                    <DynamicCheckout 
                        text="Start 21 Day Trial for $21"
                        position="centered" 
                        userInfo={props.userInfo} 
                        offer={props.subscriptionPlan} />
                </div>
            </div>
        </CancelAnytimeSectionStyle>
    )
}