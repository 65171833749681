import { confirmEmailTypes } from "../CONSTANTS"

export function confirmEmail(state = {}, action) {
    switch (action.type) {
        case confirmEmailTypes.CONFIRM_EMAIL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case confirmEmailTypes.CONFIRM_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.response,
            };
        case confirmEmailTypes.CONFIRM_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state
    }
}