import styled from 'styled-components';

export const SectionBlock = styled.section`
    padding: 0;
    min-height: calc(100vh - 60px);
`

export const SectionBlockInner = styled.div`
    max-width: 1078px;
    margin: 0 auto;
    width: 100%;

    .invalid-container {
        .error-span {
            display: block !important;
        }
    }

    .trade-forms {
        min-height: 430px;
        padding: 40px 25px 75px;
    }
`

export const FormWrapper = styled.div`
    .form-group {
        .help-text {
            font-size: 14px;
            color: #6e8eb1;
            margin: 5px 0 15px;
            font-weight: 500;
        }
    }

    .help-message {
        font-size: 12px;
        padding: 0;
        color: #98b2d4;
        margin: 5px 0 15px;

        &:last-child {
            margin-bottom: 15px;
        }
    }

    .eks {
        display: none;
    }

    .eks-display {
        display: block;

        &:last-child {
            margin-bottom: 30px;
        }
    }

    .enable-content {
        display: none;
    }

    .enable-content-display {
        display: block;
    }

    #allow {
        width: 120px;
    }

    .dropdown {
        background-color: #2e3b4c !important;
        border-color: #283546;
        color: #dbdcdf;

        button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #dbdcdf;
        }

        .dropdown-menu.show {
          display: flex;
          flex-direction: column;
          padding: 0px;
          background-color: #2e3b4c !important;
          color: #dbdcdf;
          border-color: #283546;
          cursor: pointer;

          a {
            padding: 3px 10px;

            &:hover {
              background-color: #007bff !important;
            }
          }
        }
    }
`

export const TradingContent = styled.div`
    margin: 5px 0 25px;

    &.m-tradingContent {
        margin: 0 auto;
    }

    button {
      width: 220px;
    }

    .m-info {
        font-size: 13px !important;
    }

    #modal-pass-confirm {
        font-size: 15px;
    }
`

export const FormSendPass = styled.div`
  > div.error-span {
    color: red;
  }

  .input-cont {
    position: relative;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-width: 1px;
    border-radius: 6px;

    svg {
        position: absolute;
        top: 15px;
    }
  }
`

export const FormButton = styled.div`
	display: flex;
	justify-content: flex-end;

	button {
		color: #fff;
		border: none;
		font-size: 15px;
		font-weight: 600;

		a {
			color: white;
			text-decoration: none;
		}
	}

	.cancel-btn {
		background-color: #475973 !important;
		color: #FFFFFF !important;
		display: inline-block;
		font-weight: 400;
		text-align: center;
		border: 1px solid #577094 !important;
		padding: .375rem .75rem;
	}

	.save-btn {
		background-color: #15BD6F !important;
		color: #FFFFFF !important;
		display: inline-block;
		font-weight: 400;
		text-align: center;
		border: 1px solid #15BD6F !important;
		border-radius: 2px;
		padding: 8px 10px;
		min-width: 200px;

		&:disabled {
			background-color: rgb(25, 36, 49) !important;
		    color: #556371 !important;
		    border: 1px solid #263442 !important;
		    cursor: unset;
		    border-radius: 2px;
		    padding: 8px 10px;
		    min-width: 200px;
		}
	}

	&.settings-button {
        position: absolute;
        bottom: 0;
        right: 6px;
        left: 0;
        background: #17212d;

		.auto-trade-buttons {
            padding: 15px 25px;
        }
	}
`;


export const Inputs = styled.input`
    background-color: ${props => getInputBackground(props.inputType)};
    border-color: ${props => getInputBorder(props.inputType)} !important;
    color: #dbdcdf !important;
    height: 44px;

    &:focus {
        color: #dbdcdf;
        background-color: ${props => getInputBackground(props.inputType)};
    }

    &.sm {
        height: 35px;
        font-size: 13px;
    }
`


export const LoginEyeIconWrapper = styled.div`
    &.eye-icon {
        position: absolute;
        right: 10px;
    }
    & > .eye-icon {
        @media (min-width: 320px) {
            left: 85% !important;
        }

        @media (min-width: 360px) {
            left: 89% !important;
        }

        @media (min-width: 375px) {
            left: 90% !important;
        }

        @media (min-width: 414px) {
            left: 92% !important;
        }
        
        @media (min-width: 568px) {
            left: 94% !important;
        }
        
        @media (min-width: 736px) {
            left: 94% !important;
        }

        @media (min-width: 768px) {
            left: 92% !important;
        }
        
        @media (min-width: 992px) {
            left: 94% !important;
        }

        @media (min-width: 1024px) {
            left: 93% !important;
        }
    }
`

export const Btn = styled.button`
    background-color: ${props => getBackgroundColor(props.variant)};
    color: ${props => getTextColor(props.variant)};
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid ${props => getBorder(props.variant)} !important;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:disabled {
        background-color: rgb(43, 60, 75) !important;
        color: #556371 !important;
        border: 1px solid #616161 !important;
    }

    &:focus {
        outline: none;
    }
`

const getTextColor = (type) => {
    switch (type) {
        default:
            return "";
        case "primary":
            return '#FFFFFF !important';
        case "secondary":
            return "#FFFFFF !important";
        case "danger":
            return "#ff5757 !important";
        case "default":
            return "#fff !important";
    }
};

const getBackgroundColor = (type) => {
    switch (type) {
        default:
            return "";
        case "primary":
            return '#3F9F32 !important';
        case "secondary":
            return "#475973 !important";
        case "danger":
            return "transparent !important";
        case "default":
            return "#2e3b4c !important";
    }
};

const getBorder = (type) => {
    switch (type) {
        default:
            return "transparent";
        case "primary":
            return '#61B755';
        case "secondary":
            return "#577094";
        case "danger":
            return "1px solid #b93f3f !important";
    }
}


const getInputBackground = (type) => {
    switch (type) {
        default:
            return "";
        case "input":
            return '#272F3A !important';
        case "select":
            return "#272F3A !important";
    }
}

const getInputBorder = (type) => {
    switch (type) {
        default:
            return "";
        case "input":
            return '#394B63';
        case "select":
            return "#394B63";
    }
}


export const ToggleButton = styled.label`
    display: block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    margin-bottom: 0;
    color: #778698;
    pointer-events: none;
    font-size: 14px;

    &.active {
        pointer-events: auto;
        color: #94a7c1;
    }

    i {
        position: relative;
        display: inline-block;
        margin-right: .5rem;
        width: 38px;
        height: 21px;
        /* background-color: #383e4e; */
        background-color: #202A35;
        border-radius: 23px;
        vertical-align: text-bottom;
        transition: all 0.3s linear;

        &.active {
            &::before {
                background-color: #19212b;
            }

            &::after {
                background-color: #ccd4de;
            }
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 33px;
            height: 17px;
            background-color: #19212b;
            border-radius: 11px;
            transform: translate3d(2px,2px,0) scale3d(1,1,1);
            transition: all 0.25s linear;
        }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 16px;
            height: 16px;
            background-color: #4a4a4a;
            border-radius: 11px;
            box-shadow: 0 0px 2px 2px rgba(0,0,0,0.24);
            transform: translate3d(2px,2px,0);
            transition: all 0.2s ease-in-out;
        }
    }

    input {
        display: none;

        &:checked + i {
            background-color: #3f6654;

            &.active {
                background-color: #15bd6f;
            }

            &::before {
                transform: translate3d(14px, 2px, 0) scale3d(0, 0, 0);
            }

            &::after {
                transform: translate3d(20px, 2px, 0);
                background-color: #25313f;
            }
        }
    }

    &:active {

        i {
            &::after {
                width: 32px;
                transform: translate3d(2px,2.3px,0);
            }
        }

        input {

            &:checked + i {

                &::after {
                    width: 32px;
                    transform: translate3d(3px,2px,0);
                }
            }
        }
    }

    .input-cont {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .toggle-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 25px;

            .toggle-label {
                margin-right: 10px;
                color: #fff;
                font-size: 16px;
            }
        }

        @media screen and (min-width: 320px) and (max-width: 766px) {
            flex-direction: column-reverse;
            /* justify-content: flex-start; */
            align-items: flex-end;
        }
    }

    .help-text {
        /* text-align: right; */
        margin: 0 !important;
        text-align: start;
    }
`

export const Integrate = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .integrate-btn {
        font-weight: 700;
        width: 185px;
        padding: 8px;
        font-size: 15px;
    }
`

export const NoteWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .note {
        display: block;
        box-shadow: 0px 0px 1px 4px rgba(0,0,0,0.05);
        margin: 0 0 15px;
        padding: 15px 15px 15px 35px;
        border-radius: 4px;
        position: relative;

        &.sm {
            width: 100% !important;
        }

        h3 {
          font-size: 15px;
          margin-bottom: 0;
          line-height: 1.5;
          letter-spacing: 1px;
        }

        &.warning {
           color: #ffffffc4;
           width: calc(75% - 10px);
           background: #3c4c61;

          &.one {
              &:before {
                content: '1';
                position: absolute;
                left: -15px;
                top: calc(50% - 15px);
                width: 30px;
                height: 30px;
                background-image: linear-gradient(15deg,#0e1219,#26303d);
                border-radius: 15px;
                text-align: center;
                color: #fff;
                line-height: 30px;
                font-weight: 800;
              }
          }

          &.two {
              &:before {
                content: '2';
                position: absolute;
                left: -15px;
                top: calc(50% - 15px);
                width: 30px;
                height: 30px;
                background-image: linear-gradient(15deg,#0e1219,#26303d);
                border-radius: 15px;
                text-align: center;
                color: #fff;
                line-height: 30px;
                font-weight: 800;
              }
          }

          &.three {
              &:before {
                content: '3';
                position: absolute;
                left: -15px;
                top: calc(50% - 15px);
                width: 30px;
                height: 30px;
                background-image: linear-gradient(15deg,#0e1219,#26303d);
                border-radius: 15px;
                text-align: center;
                color: #fff;
                line-height: 30px;
                font-weight: 800;
              }
          }
        }

        p {
          margin-bottom: 0;
          text-align: left;
          font-size: 13px;

          &.note-title {
            margin-bottom: 10px;
            font-size: 14px;
            color: #abcbff;
          }
        }
    }
`

export const AllowWrapper = styled.div`
    padding: 35px;

    h4 {
        font-size: 24px;
        margin-bottom: 15px;
    }

    iframe {
        width: 100%;
        height: 350px;
        border-radius: 4px;
        border: 8px solid #161E27;
    }

    @media screen and (min-width: 320px) and (max-width: 414px) {
        padding: 0;

        iframe {
            height: 210px;
        }
    }
`

export const SettingsSideWrapper = styled.div`
    display: flex;
    padding: 0 40px;
`

export const SettingsProfileWrapper = styled.div`
    margin-left: 270px;
`

export const SettingsProfileInner = styled.div`
    display: flex;
    align-items: center;
    background-color: #334154;
    padding: 15px 50px;
    border-bottom: 1px solid #dee2e62e;

    .settings-avatar {
        width: 100px;
        height: 100px;
    }

    .username {
        padding-left: 15px;
    }
`

export const SettingsProfileSubMenu = styled.div`
    margin-bottom: 30px;

    .nav-tabs {
        border-bottom: 1px solid #dee2e62e;

        .nav-item {
            padding: 15px 25px;
            background-color: #394A60;
            border-bottom: 1px solid #dee2e62e;
            border-right: 1px solid #dee2e62e;

            &:hover {
                background: #1c232d;
            }

            a {
                color: #fff;
                text-decoration: none;

                &.active {
                    color: #0bea0b;
                }
            }
        }
    }
`
