import React from 'react'
import { connect } from 'react-redux'
import { SafeAnchor } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faUserGraduate,
    faLightbulb,
    faBookOpen,
    faHeadphonesAlt } from '@fortawesome/free-solid-svg-icons'
import { 
    MainMenuWrapper,
    MainMenu,
    MainMenuItem } from '../../styles'
import { isNull, isNotNull, onSetNewState } from '../../utils'

class HelpButtons extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isPremiumAccess: null
        }

        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true

        const { isLoading, isPremiumAccess } = this.state
        const { authentication } = this.props
        const { user } = authentication
        if (isLoading && 
            isNull(isPremiumAccess) && 
            isNotNull(user)) {
            onSetNewState(this, {
                isPremiumAccess: user.isPaid
            })
        }
    }

    componentDidUpdate() {
        if (this.mounted) {
            const { isLoading, isPremiumAccess } = this.state
            const { authentication } = this.props
            const { user } = authentication
            
            if (isLoading && 
                isNull(isPremiumAccess) && 
                isNotNull(user)) {
                onSetNewState(this, {
                    isPremiumAccess: user.isPaid
                })
            }
            
            if (isLoading && isNotNull(isPremiumAccess)) {
                onSetNewState(this, {
                    isLoading: false
                })
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    renderLoader = () => (
        <>
            <div style={{ width: '100%', height: '180px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0 }}>
                <div className="pf-spinner xs" style={{ marginTop: '-48px', marginRight: '45px' }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        </> 
    )

    render() {
        const { isLoading, isPremiumAccess } = this.state

        let baseDomain = null
        if (isNotNull(isPremiumAccess))
            baseDomain = isPremiumAccess ? 'pro-support' : 'support'

        return (
            <MainMenuWrapper>
                <MainMenu>
                    <MainMenuItem cols={4}>
                        <SafeAnchor href="https://www.profitfarmers.com/academy/" target="_blank" rel="noreferrer">
                            <span className='icon'>
                                <FontAwesomeIcon size='lg' icon={faUserGraduate} />
                            </span>
                            <span className='text'>Academy</span>
                        </SafeAnchor>                      
                    </MainMenuItem>

                    <MainMenuItem cols={4}>
                        { isLoading && this.renderLoader() }

                        { !isLoading && isNotNull(baseDomain) && 
                            <SafeAnchor href={`https://${baseDomain}.profitfarmers.com/`} target="_blank" rel="noreferrer">
                                <span className='icon'>
                                    <FontAwesomeIcon size='lg' icon={faLightbulb} />
                                </span>
                                <span className='text'>Knowledge Base</span>
                            </SafeAnchor>
                        }  
                    </MainMenuItem>

                    <MainMenuItem cols={4}>
                        { isLoading && this.renderLoader() }

                        { !isLoading && isNotNull(isPremiumAccess) && 
                            <SafeAnchor 
                                href={ isPremiumAccess 
                                    ? 'https://pro-support.profitfarmers.com/article/57-read-me-signal-strategies' 
                                    : 'https://support.profitfarmers.com/article/150-read-me-signal-strategies-summaries' } 
                                target="_blank" 
                                rel="noreferrer">
                                <span className='icon'>
                                    <FontAwesomeIcon size='lg' icon={faBookOpen} />
                                </span>
                                <span className='text'>Signal Strategy Guides</span>
                            </SafeAnchor>
                        }  
                    </MainMenuItem>

                    <MainMenuItem cols={4}>                        
                        { isLoading && this.renderLoader() }

                        { !isLoading && isNotNull(baseDomain) && 
                            <SafeAnchor href={ isPremiumAccess 
                                ? 'https://pro-support.profitfarmers.com/article/254-contacting-customer-support' 
                                : 'https://support.profitfarmers.com/article/255-customer-support-for-free-members'} target="_blank" rel="noreferrer">
                                <span className='icon'>
                                    <FontAwesomeIcon size='lg' icon={faHeadphonesAlt} />
                                </span>
                                <span className='text'>Contact Support</span>
                            </SafeAnchor>
                        }  
                    </MainMenuItem>
                </MainMenu>
            </MainMenuWrapper>
        )
    }
}

function mapStateToProps(state) {
    const { authentication } = state
    return {
        authentication
    }
}

const connectedHelpButtons = connect(mapStateToProps)(HelpButtons)
export { connectedHelpButtons as HelpButtons }