import styled from 'styled-components';
import { mediaQueries } from '../../../constants/mediaQueries';

export const AnnouncementButtonWrapper = styled.div`
    width: 135px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 auto;

    .btn-action {
        background-color: #16B46C !important;
        color: #fff !important;
        display: inline-block;
        font-weight: 400;
        text-align: center;
        border: 1px solid #02a73b !important;
        font-size: 15px !important;
        padding: 6px 15px !important;
        margin-left: 0;
        min-width: 135px;
    }

    ${mediaQueries.extraLargeDevices} {
        width: 135px;
    }
`

export const AnnouncementHtmlBody = styled.div`

`

export const AnnouncementTitle = styled.h4`

`

export const AnnouncementContentWrapper = styled.div`
    width: 100%;

    &.full {
        width: 100%;
    }

    p {
        font-size: 13px;
        font-weight: 600;
        word-break: break-word;
        margin-bottom: 15px;
        margin-right: 0;
        text-align: center;
        animation: textColorChange 20s infinite alternate;
        -webkit-animation: textColorChange 20s infinite alternate;
    }
    
    ${mediaQueries.mediumDevices} {
        p {
            margin-right: 25px;
            margin-bottom: 0;
            font-weight: 500;
        }
    }

    ${mediaQueries.largeDevices} {
        p {
            font-size: 15px;
            text-align: left;
            font-weight: 600;
        }
    }

    @keyframes textColorChange {
        0% { color: #fe007d; }
        25% { color: #8e44ad; }
        50% { color: #fe007d; }
        75% { color: #6b6bea; }
        100% { color: #fe007d; }
    }
`

export const AnnouncementContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${mediaQueries.mediumDevices} {
        flex-direction: row;
    }
`

export const AnnouncementWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px 30px;
    background-color: #000;

    ${mediaQueries.largeDevices} {
        padding: 15px 25px 15px 25px;
    }

    ${mediaQueries.extraLargeDevices} {
        padding: 15px 266px 15px 25px;
    }
`
