import styled from 'styled-components'

export const BodyContent = styled.div`
    position: relative;
    width: 100%;

    .loader-msg {
        display: block;
        text-align: center;
        margin-bottom: 0;
        margin-top: 135px;
        padding: 0 10px;
    }

    .gif-loader {
        img {
            width: 100%;
            
            @media screen and (min-width: 320px) and (max-width: 1024px) {
                margin-top: 40px;
            }
        }
    }

    @media screen and (min-width: 320px) and (max-width: 766px) {
        .section-header {
            display: block !important;
        }
    }
`