import React from 'react'
import jQuery from 'jquery'
import { SignalStatusStyle } from './styled_components'
import { isNotNull, isNull, onSetNewState } from '../../utils'

export class SignalStatus extends React.Component {   
    constructor(props) {
        super(props)

        this.state = {
            tradeDirection: null,
            tradeState: null,
            entryStartPrice: null, 
            entryEndPrice: null, 
            targetPrice: null,
            isUpdatingState: false
        }
        this.mounted = false
        this.stateChangeInterval = null
    }

    componentDidMount() {
        this.mounted = true
        const { tradeDirection, currentTradeState, entryStartPrice, entryEndPrice, targetPrice } = this.props
        if (isNotNull(tradeDirection) && isNotNull(currentTradeState)) {
            onSetNewState(this, {
                tradeDirection,
                tradeState: currentTradeState,
                entryStartPrice,
                entryEndPrice,
                targetPrice
            })
        }
    }

    componentDidUpdate() {
        if (this.mounted) {
            const { signalId, currentTradeState } = this.props
            const { tradeState } = this.state

            if (isNull(tradeState)) {
                if (isNotNull(currentTradeState)) {
                    onSetNewState(this, {
                        tradeState: currentTradeState
                    })
                }
            } else {
                if (isNotNull(tradeState.item1) && 
                    (tradeState.item1 !== 's-t1h' && tradeState.item1 !== 's-t2h' && tradeState.item1 !== 's-t3h')) {
                        jQuery(`#signal-${signalId}`).on('priceChanged', () => {
                            let price = jQuery(`#signal-${signalId}`).text()
                            if (isNotNull(price))
                                this.calculateStatus(price)
                        })
                }
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
        this.stateChangeInterval = null

        onSetNewState(this, {
            tradeDirection: null,
            tradeState: null,
            entryStartPrice: null,
            entryEndPrice: null,
            targetPrice: null,
            isUpdatingState: false
        })
    }

    calculateStatus = (price) => {
        const { isUpdatingState, tradeState, tradeDirection, entryStartPrice, entryEndPrice, targetPrice } = this.state
        if (!isUpdatingState) {                            
            onSetNewState(this, {
                isUpdatingState: true
            }, () => {
                if (isNotNull(tradeState) && isNotNull(price) && isNotNull(tradeDirection)) {
                    if (tradeDirection.toLowerCase() === "buy") {
                        if (parseFloat(price) < parseFloat(entryStartPrice)) {
                            if (tradeState.item1 !== 's-asl') {
                                onSetNewState(this, {
                                    tradeState: { item1: 's-asl', item2: "Approaching Stop Loss" }
                                })
                            }
                        } else {
                            if (parseFloat(price) < parseFloat(entryEndPrice)) {
                                if (tradeState.item1 !== 's-wez') {
                                    onSetNewState(this, {
                                        tradeState: { item1: 's-wez', item2: "Within Entry Zone" }
                                    })
                                }
                            } else {
                                // calculate the boundary for leaving entry zone
                                let diff = parseFloat(targetPrice) - parseFloat(entryEndPrice)
                                let extension = diff / 5
                                let boundary = parseFloat(entryEndPrice) + extension

                                if (parseFloat(price) < parseFloat(boundary)) {
                                    if (tradeState.item1 !== 's-lez') {
                                        onSetNewState(this, {
                                            tradeState: { item1: 's-lez', item2: "Leaving Entry Zone" }
                                        })
                                    }
                                } else {
                                    if (parseFloat(price) < parseFloat(targetPrice) && tradeState.item1 !== 's-at1') {
                                        onSetNewState(this, {
                                            tradeState: { item1: 's-at1', item2: "Awaiting T1" }
                                        })
                                    }
                                }
                            }
                        }
                    } else {
                        if (parseFloat(price) > parseFloat(entryEndPrice)) {
                            if (tradeState.item1 !== 's-asl') {
                                onSetNewState(this, {
                                    tradeState: { item1: 's-asl', item2: "Approaching Stop Loss"}
                                })
                            } else {
                                if (parseFloat(price) > parseFloat(entryStartPrice)) {
                                    if (tradeState.item1 !== 's-wez') {
                                        onSetNewState(this, {
                                            tradeState: { item1: 's-wez', item2: "Within Entry Zone" }
                                        })
                                    }
                                } else {
                                    // calculate the boundary for leaving entry zone
                                    let diff = parseFloat(entryStartPrice) - parseFloat(targetPrice)
                                    let extension = diff / 5
                                    let boundary = parseFloat(entryStartPrice) - extension

                                    if (parseFloat(price) > parseFloat(boundary)) {
                                        if (tradeState.item1 !== 's-lez') {
                                            onSetNewState(this, {
                                                tradeState: { item1: 's-lez', item2: "Leaving Entry Zone" }
                                            })
                                        }
                                    } else {
                                        if (parseFloat(price) > parseFloat(targetPrice) && tradeState.item1 !== 's-at1') {
                                            onSetNewState(this, {
                                                tradeState: { item1: 's-at1', item2: "Awaiting T1" }
                                            })
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                setTimeout(() => {
                    onSetNewState(this, {
                        isUpdatingState: false
                    })
                }, 5000)
            })
        }
    }
    
    renderStatus(size) {
        const { tradeState } = this.state
        if (isNotNull(tradeState)) {
            switch (tradeState.item1) {
                // case "s-asl": 
                // case "s-wez": 
                // case "s-lez": {
                //     return (
                //         <>Fresh</>
                //     )
                // }
                case "s-asl": {
                    return (
                        <>Approaching { isNull(size) ? <br/> : <></> } Stop Loss</>
                    )
                }
                case "s-wez": {
                    return (
                        <>Within { isNull(size) ? <br/> : <></> } Entry Zone</>
                    )
                }
                case "s-lez": {
                    return (
                        <>Leaving { isNull(size) ? <br/> : <></> } Entry Zone</>
                    )
                }
                default: {
                    return (
                        <>{tradeState.item2}</>
                    )
                }
            }
        }
    }

    render() {
        let { isActive, size, signalState } = this.props
        const { tradeState } = this.state

        return (
            <>
                { isNotNull(isActive) && !isActive && 
                    <>
                        { isNotNull(signalState) && signalState !== 4 && 
                            <>
                                <SignalStatusStyle 
                                    className={`status`} 
                                    state={'s-iaf'}>
                                    Trade Closed
                                </SignalStatusStyle>
                            </>
                        }

                        { isNotNull(signalState) && signalState === 4 && 
                            <>                                
                                <SignalStatusStyle 
                                    className={`status s-open`} 
                                    state={'s-open'}>
                                    Open
                                </SignalStatusStyle>
                            </>
                        }
                    </>
                }

                { isNotNull(isActive) && isActive && isNotNull(tradeState) && 
                    <SignalStatusStyle 
                        className={`status ${tradeState.item1}`} 
                        state={tradeState.item1}>
                        { this.renderStatus(size) }
                    </SignalStatusStyle>
                }
            </>
        )
    }
}