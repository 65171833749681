export default class ScannerSortFunctions {
    constructor(these) {
        this._handleCoinPair = this._handleCoinPair.bind(these)
        this._handleS1 = this._handleS1.bind(these)
        this._handleS2 = this._handleS2.bind(these)
        this._handleS3 = this._handleS3.bind(these)
        this._handleS4 = this._handleS4.bind(these)
        this._handleS5 = this._handleS5.bind(these)
        this._handleS6 = this._handleS6.bind(these)
        this._handleS7 = this._handleS7.bind(these)
        this._handleS8 = this._handleS8.bind(these)
        this._handlePrice = this._handlePrice.bind(these)
        this._handleVolume = this._handleVolume.bind(these)
        this._handleMarketCap = this._handleMarketCap.bind(these)
        this._handlePeakGains = this._handlePeakGains.bind(these)
        this._handleLiveFibo = this._handleLiveFibo.bind(these)
        this._handled3move = this._handled3move.bind(these)
        this.ClearSortState = this.ClearSortState.bind(these)
    }

    _handleCoinPair() {
        let oldState = this.state.coinPair

        let sort = new ScannerSortFunctions(this)
        sort.ClearSortState()

        this.setState({
            coinPair: !oldState,
            selectedItem: "coinPair"
        })
    }

    _handleS1() {
        let oldState = this.state.s1

        let sort = new ScannerSortFunctions(this)
        sort.ClearSortState()

        this.setState({
            s1: !oldState,
            selectedItem: "s1"
        })
    }

    _handleS2() {
        let oldState = this.state.s2

        let sort = new ScannerSortFunctions(this)
        sort.ClearSortState()

        this.setState({
            s2: !oldState,
            selectedItem: "s2"
        })
    }

    _handleS3() {
        let oldState = this.state.s3

        let sort = new ScannerSortFunctions(this)
        sort.ClearSortState()

        this.setState({
            s3: !oldState,
            selectedItem: "s3"
        })
    }

    _handleS4() {
        let oldState = this.state.s4

        let sort = new ScannerSortFunctions(this)
        sort.ClearSortState()

        this.setState({
            s4: !oldState,
            selectedItem: "s4"
        })
    }

    _handleS5() {
        let oldState = this.state.s5

        let sort = new ScannerSortFunctions(this)
        sort.ClearSortState()

        this.setState({
            s5: !oldState,
            selectedItem: "s5"
        })
    }

    _handleS6() {
        let oldState = this.state.s6

        let sort = new ScannerSortFunctions(this)
        sort.ClearSortState()

        this.setState({
            s6: !oldState,
            selectedItem: "s6"
        })
    }

    _handleS7() {
        let oldState = this.state.s7

        let sort = new ScannerSortFunctions(this)
        sort.ClearSortState()

        this.setState({
            s7: !oldState,
            selectedItem: "s7"
        })
    }

    _handleS8() {
        let oldState = this.state.s8

        let sort = new ScannerSortFunctions(this)
        sort.ClearSortState()

        this.setState({
            s8: !oldState,
            selectedItem: "s8"
        })
    }

    _handlePrice() {
        let oldState = this.state.price

        let sort = new ScannerSortFunctions(this)
        sort.ClearSortState()

        this.setState({
            price: !oldState,
            selectedItem: "price"
        })
    }

    _handleVolume() {
        let oldState = this.state.volume

        let sort = new ScannerSortFunctions(this)
        sort.ClearSortState()

        this.setState({
            volume: !oldState,
            selectedItem: "volume"
        })
    }

    _handleMarketCap() {
        let oldState = this.state.marketCap

        let sort = new ScannerSortFunctions(this)
        sort.ClearSortState()

        this.setState({
            marketCap: !oldState,
            selectedItem: "marketCap"
        })
    }

    _handlePeakGains() {
        let oldState = this.state.peakGains

        let sort = new ScannerSortFunctions(this)
        sort.ClearSortState()

        this.setState({
            peakGains: !oldState,
            selectedItem: "peakGains"
        })
    }

    _handleLiveFibo() {
        let oldState = this.state.liveFibo

        let sort = new ScannerSortFunctions(this)
        sort.ClearSortState()

        this.setState({
            liveFibo: !oldState,
            selectedItem: "liveFibo"
        })
    }

    _handled3move() {
        let oldState = this.state.d3move

        let sort = new ScannerSortFunctions(this)
        sort.ClearSortState()

        this.setState({
            d3move: !oldState,
            selectedItem: "d3move"
        })
    }

    //#region Helper
    ClearSortState() {
        let falseObject = {}
        let falseValues = ['coinPair', 'price', 'volume', 'marketCap', 'peakGains', 'liveFibo', 'd3move']
        for (var x = 1; x <= 8; x++)
            falseValues.push(`s${x}`)
        falseValues.map(value => falseObject[value] = false)
        this.setState(falseObject)
        this.setState({ selectedItem: "coinPair", currentSet: 20 })
    }
    //#endregion
}