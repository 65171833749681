import { endpoints, baseUrl } from './CONSTANTS'
import { authServices } from './authService'
import { isNotNull, encryptText } from '../utils'

const { v3, trading } = endpoints

function getAutologin() {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader(),
    }

    return fetch(`${baseUrl}${v3.terminal.authorize}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function settings() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${v3.terminal.settings}`, requestOpts)
        .then(handleResponse)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error))
}

function getKeys(asid, exchange) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader(),
    }

    return fetch(`${baseUrl}${v3.terminal.exchangeKeys}?asid=${asid}&exchange=${exchange}`, requestOptions)
        .then(handleResponse)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error))
}

function exchangeOptions() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader(),
    }

    return fetch(`${baseUrl}${v3.system.exchanges}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            if (response.length > 0) {
                return Promise.resolve(response)
            }

        }).catch(error => Promise.reject(error))
}

function existingTrailingUser(pass) {
    if (isNotNull(pass))
        pass = encryptText(pass)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        //headers: header.addAuthorizationHeader(),
        body: JSON.stringify({ password: pass })
    }

    return fetch(`${baseUrl}${v3.terminal.externalLogin}`, requestOptions)
        .then(handleResponse)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error))
}

function authorizeUser(password) {
    if (isNotNull(password))
        password = encryptText(password)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        //headers: header.addAuthorizationHeader(),
        body: JSON.stringify({ password })
    }

    return fetch(`${baseUrl}${v3.terminal.enable}`, requestOptions)
        .then(handleResponse)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.resolve(error))
}

function saveAutoCloseTradeSettings(data) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${v3.settings.automations.autoClose(data.settingsId, data.isAutoCloseEnabled)}`, requestOpts)
        .then(handleResponse)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error))
}

function saveKeys(tradingKey) {
    if (isNotNull(tradingKey.exchangeApiKey))
        tradingKey.exchangeApiKey = encryptText(tradingKey.exchangeApiKey)

    if (isNotNull(tradingKey.exchangeApiSecret))
        tradingKey.exchangeApiSecret = encryptText(tradingKey.exchangeApiSecret)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        //headers: header.addAuthorizationHeader(),
        body: JSON.stringify(tradingKey)
    }

    return fetch(`${baseUrl}${v3.terminal.addExchangeKey}`, requestOptions)
        .then(handleResponse)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error))
}

function disableTrading() {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader(),
    }

    return fetch(`${baseUrl}${v3.terminal.disable}`, requestOptions)
        .then(handleResponse)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error))
}

function deleteKeys(asid, exchange) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader(),
    }

    return fetch(`${baseUrl}${v3.terminal.delExchangeKey}?asid=${asid}&exchange=${exchange}`, requestOptions)
        .then(handleResponse)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error))
}

function getTradeDetails(signalId) {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${trading.tradeDetails(signalId)}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        if (res.status === 401)
            authServices.logout()
        else
            return data
    })
}

export const tradingServices = {
    getAutologin,
    settings,
    getKeys,
    exchangeOptions,
    existingTrailingUser,
    authorizeUser,
    saveAutoCloseTradeSettings,
    saveKeys,
    disableTrading,
    deleteKeys,
    getTradeDetails
}