import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const FeaturesComparisonSectionStyle = styled.section` 
    position: relative;
    padding: 40px 0 30px;
    color: #fff;
    
    & .container {
        & .section-header {
            margin-bottom: 10px;

            & .section-title {
                display: block;
                text-align: center;
                font-size: 25px;
                font-weight: 600
            }

            & p {
                max-width: 342px;
                margin: 0 auto 15px;
                font-size: 15px;
                color: #85A1BE;
                text-align: center;
            }
        }

        & .features-comparison-wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 7px 5px 7px 5px;
            background-color: #192431;
            border: 1px solid #394F6A;
            border-radius: 23px;
            box-shadow: 0px 3px 30px #00000070;
            max-width: 100%;
            margin: 0 auto 25px;

            & .features-list {
                margin: 0 auto;
                padding: 0;
                list-style-type: none;
                width: calc(100% - 172px);

                & li {
                    padding: 13px 8px;
                    height: 55px;

                    & .info-icon {
                        display: inline-block;
                        width: 13px;
                        height: 13px;
                        margin-right: 5px;

                        & i {
                            color: #5E90CF;
                        }
                    }

                    & span {
                        font-size: 13px;
                    }

                    &:nth-child(even) {
                        background-color: #233040;
                    }

                    &.features-list-header {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-end;
                        height: 100px;
                        padding: 10px 20px;
                        color: #fff;
    
                        & span {
                            font-size: 15px;
                            margin-bottom: 24px;
                        }
                    }
                }

                &.checklist {
                    width: 86px;
                    
                    & li {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        & span {
                            text-align: center;

                            &.plan-icon {
                                display: none;
                            }
                        }

                        & .take-profit-targets {
                            display: none;
                            margin: 0 15px;
                            padding: 0;
                            list-style-type: none;

                            & li {
                                padding: 0;
                                margin-right: 8px;
                                background-color: unset;

                                & span {                                    
                                    display: inline-block;
                                    width: 24px;
                                    height: 24px;
                                    background-color: #233040;
                                    border: 1px solid #1C2F46;
                                    color: #7a8da5;
                                    border-radius: 4px;
                                    font-size: 11px;
                                    font-weight: 600;
                                    line-height: 23px;
                                    letter-spacing: 1px;
                                    box-shadow: 0 0 3px 1px rgba(0,0,0,0.1);

                                    &.hit {
                                        background-color: #FFAD21;
                                        border-color: transparent;
                                        color: #fff;
                                    }
                                }

                                &:last-child {
                                    margin-right: 0
                                }
                            }
                        }

                        &.features-list-header {
                            padding: 10px 0;
                            justify-content: flex-end;

                            & span {                                
                                margin-bottom: 0;

                                &.plan-icon {
                                    display: block;
                                }
                            }

                            & .small-text {
                                font-size: 10px;
                                color: #85A1BE;
                                line-height: 1.2;
                            }
                        }

                        & svg {
                            font-size: 20px;

                            &.not-supported {
                                color: #F93966;
                            }

                            &.supported {
                                color: #15BD6F;
                            }
                        }

                        & .frequency {
                            color: #85A1BE;
                            font-size: 12px;
                        }

                        & .no-sm {
                            display: none;
                        }

                        & .only-sm {
                            display: inline-block;
                        }
                    }
                }

                &.pro {
                    border: 1px solid #15BD6F;
                    border-radius: 13px;

                    &.checklist {
                        & li {
                            &.features-list-header {
                                position: relative;

                                & .pro-ribbon {
                                    display: none;
                                    position: absolute;
                                    content: ' ';
                                    width: 110px;
                                    height: 110px;
                                    overflow: hidden;
                                    top: -20px;
                                    right: -11px;

                                    &:before, &:after {
                                        position: absolute;
                                        z-index: 1;
                                        content: ' ';
                                        display: block;
                                        border: 5px solid #6a2434;
                                        border-top-color: transparent;
                                        border-right-color: transparent;
                                    }

                                    &:before {
                                        top: 0;
                                        left: 0;
                                    }

                                    &:after {
                                        bottom: 0;
                                        right: 0;
                                    }

                                    & span {
                                        position: absolute;
                                        display: block;
                                        width: 167px;
                                        padding: 6px 0;
                                        background-color: #E3385E;
                                        box-shadow: 0 5px 10px rgba(0,0,0,.1);
                                        color: #fff;
                                        text-shadow: 0 1px 1px rgba(0,0,0,.2);
                                        text-transform: uppercase;
                                        text-align: center;
                                        left: -17px;
                                        top: 31px;
                                        transform: rotate(45deg);
                                        font-size: 12px;
                                    }
                                }
                            }

                            .text-em {
                                font-weight: 800;
                                letter-spacing: 1px;
                                text-shadow: 1px 1px 2px rgb(0 0 0 / 40%);
                                font-size: 18px;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }

        & .tp-feedback {
            display: none;

            & .img-wrapper {
                width: 360px;
                margin: 0 auto;

                & img {
                    width: 100%;
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        padding: 50px 0 60px;

        & .container {
            & .section-header {
                margin-bottom: 30px;

                & .section-title {
                    font-size: 30px;
                }

                & p {
                    max-width: 100%;
                    font-size: 18px;
                }
            }

            & .features-comparison-wrapper {
                margin: 0 auto;
                padding: 0 10px;
                max-width: 690px;

                & .features-list {
                    width: calc(100% - 410px);

                    & li {
                        padding: 13px 10px;

                        & .info-icon {
                            margin-right: 10px;
                        }

                        & span {
                            font-size: 15px;
                        }
                        
                        &.features-list-header {        
                            & span {
                                font-size: 17px;
                                font-weight: 600;
                                margin-bottom: 16px
                            }
                        }
                    }

                    &.checklist {
                        width: 210px;
                        border-left: 1px solid #30445c;

                        & li {
                            & .frequency {
                                font-size: 14px;
                            }

                            &.features-list-header {
                                & .small-text {
                                    font-size: 12px;
                                }
                            }

                            & svg {
                                &.supported {
                                    color: #85A1BE;
                                }
                            }

                            & .no-sm {
                                display: inline-block;
                            }
    
                            & .only-sm {
                                display: none;
                            }
                        }
                    }

                    &.pro {
                        padding-top: 10px;
                        padding-bottom: 10px;
                        margin-top: -10px;
                        margin-right: -10px;
                        margin-bottom: -10px;
                        background: transparent linear-gradient(332deg, #15BD6F 0%, #3B96E8 100%) 0% 0% no-repeat padding-box;
                        border: none;

                        & li {
                            font-weight: 600;

                            & svg {    
                                &.supported {
                                    color: #fff;
                                }
                            }

                            &:nth-child(even) {
                                background-color: rgba(255, 255, 255, 0.2);
                            }  
                        }
                        
                        &.checklist {
                            border-left: none;

                            & li {
                                & .frequency {
                                    font-size: 14px;
                                    color: #fff
                                }

                                &.features-list-header {
                                    & .pro-ribbon {
                                        display: block
                                    }

                                    & .small-text {
                                        color: #fff
                                    }
                                }
                            }
                        }
                    }
                }
            }

            & .tp-feedback {
                display: none
            }
        }
    }

    ${mediaQueries.largeDevices} {
        & .container {
            & .features-comparison-wrapper {
                max-width: 920px;

                & .features-list {
                    width: calc(100% - 590px);

                    & li {
                        &.features-list-header {
                            justify-content: center;

                            & span {
                                margin-top: auto;
                                margin-bottom: auto
                            }
                        }
                    }

                    &.checklist {
                        width: 300px;

                        & li {
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding-left: 40px;

                            & span {
                                &.plan-icon {
                                    display: inline-block;

                                    & img {
                                        height: 100%
                                    }

                                    &.rocket {                                        
                                        height: 28px;
                                        padding: 0 3px
                                    }

                                    &.bicycle {
                                        height: 18px;
                                        padding: 0 7px;
                                        margin-top: -10px
                                    }
                                }
                            }

                            & .take-profit-targets {
                                display: flex
                            }

                            &.features-list-header {
                                flex-direction: column;
                                justify-content: center;
                                align-items: flex-start;
                                padding: 10px 25px 10px 40px;

                                & span {
                                    &.plan-icon {
                                        display: none
                                    }

                                    &.small-text {
                                        margin-top: 0;
                                        margin-bottom: auto
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        padding: 60px 0;

        & .container {
            & .features-comparison-wrapper {
                max-width: 1000px;

                & .features-list {
                    & li {
                        &.features-list-header {
                            & span {
                                font-size: 24px
                            }
                        }
                    }

                    &.checklist {
                        & li {
                            &.features-list-header {
                                & span {
                                    font-size: 24px
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`