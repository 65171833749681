import styled from 'styled-components'

export const SecureYourFutureSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    padding: 60px 0;
    background-image: url(/images/layout/not-too-late-bg.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;

    .container {
        color: #fff;

        .text-with-img {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            margin: 0 auto 25px;

            .text {
                width: 55%;
                padding-right: 20px;

                .section-title {
                    font-size: 40px;
                    font-weight: 600;
                }
            }

            .img {
                width: 45%;
                padding-left: 10px;

                img {
                    width: 100%;
                }
            }

            &.reverse {
                .text {
                    padding-left: 10px;
                    padding-right: 0;
                }

                .img {
                    padding-right: 20px;
                    padding-left: 0;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`