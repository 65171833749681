import React from 'react'
import { connect } from 'react-redux'
import { authActions } from '../../redux/actions'
import { isNotNull } from '../../utils'

class TradeAutoContainer extends React.Component {

    componentDidMount() {
        const { authentication, dispatch } = this.props
        const params = (new URL(document.location)).searchParams
        const token = params.get("token")

        if (isNotNull(authentication) &&
            isNotNull(authentication.loggedIn)) {
            dispatch(authActions.loginFromMobile(token))
        }
    }

    componentDidUpdate() {
        const { authentication, history } = this.props
        if (isNotNull(authentication)) {
            const { loggedIn } = authentication
            const params = (new URL(document.location)).searchParams
            const tradeSignalId = params.get("trade-signal-id")
            if (loggedIn)
                history.push(`/dashboard?trade-signal-id=${tradeSignalId}`)
        }
    }

    render() {

        return (
            <div
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flex: 1,
                    backgroundColor: '#202A35',
                    height: '100vh'
                }}>
                <h1 style={{
                    fontSize: 14,
                    color: 'rgb(181, 190, 203)',
                    fontFamily: 'Montserrat, sans-serif',
                    textAlign: 'center'
                }}>Redirecting you to the trade order form. . .</h1>
            </div>
        )
    }
}

function mapStateToProps(state) {
    let { authentication } = state
    return {
        authentication
    }
}

const connectedTradeAutoContainer = connect(mapStateToProps)(TradeAutoContainer)
export { connectedTradeAutoContainer as TradeAutoContainer }