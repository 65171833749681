import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const FreeVsProSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    padding: 40px 5px;
    background-image: url(/images/trial-offer/freepro-bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .container {
        .section-title {
            font-size: 28px;
            font-weight: 800;
            color: #FFFFFF;
            text-align: center;
            margin-bottom: 25px;
        }

        .free-pro-cont {
            display: flex;
            justify-content: center;
            flex-direction: row;
            color: #FFFFFF;
            align-items: center;

            .free-pro-row {
                .title {
                    font-weight: 800;
                    text-align: center;
                }

                .features-list {
                    margin: 0;
                    padding-left: 25px;
                    padding-right: 2px;
                    list-style-type: none;
                    font-size: 13px;

                    .list-item {
                        position: relative;
                    }
                }

                &.freeAcc {
                    background-color: #304053;
                    border-left: 1px solid #7D9CBE;
                    border-bottom: 1px solid #7D9CBE;
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;
                    height: fit-content;

                    .title {
                        background-image: linear-gradient(90deg, #88A7C8, #465B6B);
                        padding: 10px 10px;
                        font-size: 15px;
                        border-top-left-radius: 15px;
                    }

                    .features-list {
                        .list-item {
                            margin-bottom: 10px;

                            &:before {
                                content: '';
                                position: absolute;
                                width: 12px;
                                height: 12px;
                                top: 5px;
                                left: -18px;
                                background-image: url(/images/trial-offer/thumbs-down-icon.png);
                                background-size: contain;
                                background-repeat: no-repeat;
                            }
                        }
                    }
                }

                &.proAcc {
                    background-color: #304053;
                    border: 1px solid #4259FB;
                    border-top: none;
                    border-radius: 15px;

                    .title {
                        background-image: linear-gradient(90deg, #4053FD, #5BA3DB);
                        padding: 20px 10px;
                        font-size: 20px;
                        border-top-left-radius: 15px;
                        border-top-right-radius: 15px;
                    }

                    .features-list {
                        .list-item {
                            &:before {
                                content: '';
                                position: absolute;
                                width: 12px;
                                height: 12px;
                                top: 5px;
                                left: -18px;
                                background-image: url(/images/trial-offer/green-star-icon.png);
                                background-size: contain;
                                background-repeat: no-repeat;
                            }

                            small {
                                color: #8EA5C1;
                            }
                        }
                    }
                }
            }
        }

        .checkout-btn {
            button {
                font-size: 15px;
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        .container {
            .section-title {
                font-size: 32px;
                padding: 0 55px;
            }

            .free-pro-cont {
                width: 600px;
                margin: auto;

                .free-pro-row {
                    .title {
                        text-align: left;
                    }

                    .features-list {
                        padding: 10px 0px 20px 50px;
                    }

                    &.freeAcc {
                        width: 45%;
                        
                        .title {
                            padding: 20px 10px 20px 30px;
                            font-size: 16px;
                            position: relative;

                            &:before {
                                content:'';
                                position: absolute;
                                width: 70px;
                                height: 50px;
                                bottom: 0;
                                right: 10px;
                                background-image: url(/images/trial-offer/free-bike.png);
                                background-size: contain;
                                background-repeat: no-repeat;
                            }
                        }
                    }

                    &.proAcc {
                        width: 55%;
                        
                        .title {
                            padding: 20px 10px 20px 30px;
                            font-size: 24px;
                            position: relative;

                            &:before {
                                content:'';
                                position: absolute;
                                width: 60px;
                                height: 69px;
                                bottom: 0;
                                right: 35px;
                                background-image: url(/images/trial-offer/pro-rocket.png);
                                background-size: contain;
                                background-repeat: no-repeat;
                            }
                        }
                    }
                }
            }

            .checkout-btn {
                button {
                    font-size: 18px;
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        .container {
            .section-title {
                font-size: 33px;
                padding: 0 150px;
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        padding: 60px 5px 30px;

        .container {
            .section-title {
                width: 1100px;
                margin-left: auto;
                margin-right: auto;
                font-size: 45px;
            }
            
            .free-pro-cont {
                width: 850px;
                
                .free-pro-row {
                    .features-list {
                        padding-left: 80px;
                        padding-top: 20px;
                        padding-bottom: 30px;

                        .list-item {
                            font-size: 18px;
                        }
                    }

                    &.freeAcc {
                        border-top-left-radius: 30px;
                        border-bottom-left-radius: 30px;

                        .title {
                            padding: 40px 10px 30px 55px;
                            font-size: 20px;
                            border-top-left-radius: 30px;

                            &:before {
                                width: 95px;
                                height: 70px;

                            }
                        }

                        .features-list {
                            .list-item {
                                &:before {
                                    width: 13px;
                                    height: 13px;
                                    left: -25px;
                                    top: calc(50% - 6.5px);
                                }
                            }
                        }
                    }

                    &.proAcc {
                        border-radius: 30px;

                        .title {
                            padding: 28px 10px 28px 55px;
                            font-size: 30px;
                            border-top-left-radius: 30px;
                            border-top-right-radius: 30px;

                            &:before {
                                width: 80px;
                                height: 92px;
                                right: 60px;

                            }
                        }

                        .features-list {
                            .list-item {
                                &:before {
                                    width: 18px;
                                    height: 18px;
                                    left: -28px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`