import React from 'react'
import { TheFounderStyle } from './styles/TheFounderStyle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export const TheFounderSection = () => {
    return (
        <TheFounderStyle> 
            <div className='container'>
                <span className='gains dogeusdt'></span>
                <span className='gains filusdt'></span>

                <div className='section-header'>
                    <h2 className='section-title'>There's Nothing Stopping You From 5x or 10x'ing your account</h2>
                    <p>With patience, a plan, and ProfitFarmers Pro, there’s nothing stopping you from turning $2,000 into $20,000 or $10,000 into $100,000 or more just by following simple strategies</p>
                    <h3>And we're going to show you how...</h3>
                </div>

                <div className='text-with-image'>

                    <div className='text-content quote-bg'>
                        <p>Me again, Matthew.</p>
                        <p>I just want you to see how ProfitFarmers Pro can change your life as a trader with real data.</p>
                        <p>Here's the real difference in Free vs. Pro:</p>
                        <p className='quote-text'>"Free users can make small gains mostly on auto-pilot. Pro users can do literally anything they want."</p>
                        <p>It sounds cliche, but it's 100% true. There's no limit to what you can do once you unlock the full power of this platform.</p>
                    </div>
                    <div className='img-content'>
                        <span className='greet-text-arrow'>
                            <em>"Hey there!"</em>
                            <img src='/images/subscriptions/mt-arrow.svg' alt='' className='arrow-svg' />
                        </span>

                        <img src='/images/subscriptions/mt-photo.png' alt='' />

                        <span className='founder-name-tag'>
                            <p className='founder-name'>MATTHEW TANSLEY</p>
                            <p className='founder-title'>CEO and founder of ProfitFarmers</p>
                        </span>
                    </div>
                </div>

                <div className='text-with-image img-on-left'>
                    <div className='img-content'>
                        <img src='/images/subscriptions/astro-with-cryptocoins.png' alt='ProfitFarmers coins' />
                    </div>

                    <div className='text-content'>
                        <h3>Our pro members have:</h3>

                        <ul className='pro-benefits-list'>
                            <li>
                                <FontAwesomeIcon icon={faCheck} />
                                <span>Quit dead-end jobs and become full-time traders</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} />
                                <span>Taken their lives back from staring at charts and finally started spending time with their partners and children</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} />
                                <span>Gone from a gardener to making ~2,000% profits and changing his lifestyle completely</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} />
                                <span>Started planning retirement before age 40</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} />
                                <span>Turned 8-hour workdays into 30-minute "workmornings"</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} />
                                <span>Finally started saving money for the future in just 15 minutes a day</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} />
                                <span>Stopped sitting home working a miserable job, and started traveling the world full-time just with ProfitFarmers.</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='life-changing'>
                    <div className='text-content text-center'>
                        <p>I don't know how else to say it...</p>
                        <h3 className='font-weight-bold'>ProfitFarmers can be literally <span className='green-text'>LIFE-CHANGING</span> <br/>for Pros and Even Beginners</h3>
                        <p className='gray-text'>5x more signals gives you 500% MORE opportunities for gains AND you escape the vice grip short-term variance (luck) has over you. 200 - 300+ signals makes you invincible to random swings.</p>
                        <p className='gray-text'>And 4x take-profit targets increases your <span className='font-weight-bold'>risk:reward</span> profile <span className='font-weight-bold'>exponentially</span>. You'll over 6x your reward profile WITHOUT increasing your risk (due to break-even stop-loss).</p>
                    </div>
                    <div className='img-content'>
                        <img src='/images/subscriptions/risk-reward.png' alt=''/>
                    </div>
                </div>  

                <div className='text-with-image avg-gain-target'>
                    <div className='text-content quote-bg'>
                        <p>Here's how...</p>
                        <h2 className='quote-text font-weight-bold'>How You Can Realistically 5x or Even 10x Your Account Step-By-Step With Simple Strategies</h2>
                        <p className='gray-text'>First, you need to know that 50% of our completed signals go to T2 and above.</p>
                        <p className='gray-text'>That's where the highest average gains are.</p>
                        <p className='gray-text'>Check it out:</p>
                    </div>
                    <div className='img-content'>
                        <img src='/images/subscriptions/avg-gain-per-target.png' alt='' />
                        <em className='gray-text'>(ProfitFarmers 2021: Full signal results breakdown)</em>
                    </div>
                </div>

                <div className='text-with-image img-on-left founder-free-users'>
                    <div className='img-content'>
                        <img src='/images/subscriptions/mt.png' alt='ProfitFarmers coins' />
                    </div>

                    <div className='text-content'>
                        <p>Free users are capped at ~10% gains. But 50% of the time, your signals will go way beyond that. T2 is more than 2x. T3 is 3x. T4 is almost 6x.</p>
                        <p className='gray-text'>Just letting you know that you have an insane amount of power in your hands just by gaining access to T2, T3 and T4.</p>
                        <p className='gray-text'>So now let's talk about how to grow your account.</p>
                        <p className='gray-text'>Once you have our high win-rate signals, you only need to manage your risk properly, choose the best risk:reward ratio trades that fit your game plan, and stick to your trade plans. Is it really that easy?</p>
                    </div>
                </div>

                <div className='hows-section'>
                    <p className='text-center'>Here's how you do it:</p>

                    <ul className='hows-list'>
                        <li className='list-col'>
                            <ul>
                                <li>
                                    <img src='/images/subscriptions/checkmark.svg' />
                                    <span>Set your account risk per trade (typically 2-5%)</span>
                                </li>
                                <li>
                                    <img src='/images/subscriptions/checkmark.svg' />
                                    <span>Maintain at least a 50% win-rate (we’ve averaged 78% on completed signals for 2 years but you should take into account having some close early trades as minor losses)</span>
                                </li>
                            </ul>
                        </li>
                        <li className='list-col'>
                            <ul>
                                <li>
                                    <img src='/images/subscriptions/checkmark.svg' />
                                    <span>Look for risk:reward (R:R) plays over 1:1.5 (the amount you stand to gain vs how much you would lose if you went to stop-loss)</span>
                                </li>
                                <li>
                                    <img src='/images/subscriptions/checkmark.svg' />
                                    <span>Compound your winnings into your trading balance</span>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <div className='chart-section'>
                        <h3 className='text-center quote-bg'>Don't believe me? I'm going to run the numbers for you.</h3>
                        <p className='text-center gray-text'>Say you have $5,000 in your account. Let’s plug some numbers into this <a href='https://coghlancapital.com/trade-return-calculator/' target='_blank'>trade return calculator</a>. At 4% maximum risk and 1:1.5 R:R, and just a 50% win rate, here are a couple of results over 100 completed trades.</p>

                        <div className='chart-two-col'>
                            <div className='chart-image'>
                                <img src='/images/subscriptions/trade-return-chart-a.png' />
                                <span>Not bad. Hypothetically you 4x’d your account after 100 completed trades.</span>
                            </div>
                            <div className='chart-image'>
                                <img src='/images/subscriptions/trade-return-chart-b.png' />
                                <span>Wow, that’s more than 15x. Not likely, but it’s within the realm of the possibilities if you stick to these parameters.</span>
                            </div>
                        </div>

                        <div className='chart-one-col text-center'>
                            <div className='chart-image'>
                                <h3>Here’s what happens with $10,000 in your account.</h3>
                                <img src='/images/subscriptions/trade-return-chart-c.png' />
                                <p>$30,000 +. That’s a MILD result.</p>
                            </div>
                            
                            <hr className='break-line'/>
                            
                            <div className='chart-image'>
                                <h3>Let’s turn it up a notch, same as before a $10,000 account size, 4% risk, 1.5 R:R but this time a 60% win rate.</h3>
                                <img src='/images/subscriptions/trade-return-chart-d.png' />
                                <p className='text-center quote-bg font-weight-bold'><span className='green-text'>~$130,000</span> from $10,000. Likely? No. Possible? 100% yes.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='text-with-image meme-section'>

                    <div className='text-content'>
                        <p>We want to be as transparent as possible. Everything you've just seen here is based on real math. It's 100% possible. We know because we and countless others are doing it right now. But it's on YOU to put in the work and do it right.</p>
                        <p>Nothing is guaranteed. It won't happen overnight. You need to detach from your emotions, follow the plan, and be patient. And be prepared for a bumpy ride, because the markets fluctuate.</p>
                    </div>
                    <div className='img-content'>
                        <img src='/images/subscriptions/will-smith-chris-rock.png' alt='' />
                    </div>
                </div>

                <div className='text-with-image img-on-left founder-last-section'>
                    <div className='img-content'>
                        <img src='/images/subscriptions/mt-2.png' alt='' />
                    </div>

                    <div className='text-content quote-bg'>
                        <p>The numbers you see here are 100% possible if you stick to the risk tolerance, only chase the right risk/reward profile, and maintain a 50% or better win-rate. No chasing losses, no taking big swings without good reason, no impatience. STICK TO THE PLAN AND BE PATIENT.</p>
                        <p>Some months can include a bunch of breakeven and partial loss trades, but we’ve won at a staggering 78% on completed trades over the last 2 years. If we don’t deliver a 60% win-rate on completed signals, you get your money back.</p>
                        <p>And don't forget, plenty of signals offer an R:R of 1:2, 1:3 or even 1:10+!</p>
                        <p>There is no 'one size fits all' plan of attack.</p>
                    </div>
                </div>

                <div className='last-text text-center'>
                    <p>You'll have the signals. You'll have the tools. All you need to do is follow the plan and think LONG TERM. Those 100 completed trades WILL come eventually.</p>
                    <p>Like we said, there's NOTHING stopping you from achieving this except your own mentality.</p>
                    <p>You can learn more about how to be a profitable trader <a href='https://www.profitfarmers.com/blog/how-to-be-a-long-term-profitable-trader' target='_blank'>here</a>.</p>
                </div> 

                <span className='coins-gains filusdt'></span>
            </div>
        </TheFounderStyle>
    )
}