import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const SideBarWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    width: 278px;
    margin-top: 56px;
    background-color: #0B1219;
    border-right: 1px solid #080e16;
    overflow: auto;
    transform: translate3d(-278px, 0, 0);
    transition: transform 0.35s;

    .sidebar-menu {
        height: 100%;

        .card {
            background-color: transparent;
            border: 0px solid transparent;
            height: 100%;
        
            &.sidelinks {
                &.blur {
                    filter: blur(2px);
                    pointer-events: none;
                }

                &.md {
                    position: fixed;
                    bottom: 20px;
                }
            }

            .card-body { 
                padding: 0 15px 0 20px;
		 	    height: 100%;

                .sidebar-menu-lists {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    height: 100%;
                    margin: 0;
                    padding-left: 0px;
                    list-style-type: none;
                    padding-top: 10px;
                    font-size: 13px;

                    .sidebar-menu-items {
                        padding: 10px 0 0;

                        &.side-bar-signals {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 10px;
                            margin-left: -10px;
                            width: 100%;

                            &:first-child {
                                margin-bottom: 0;
                            }
                        }
                    
                        .mobile-logout {
                            display: none;
                    
                            button {
                                background: transparent;
                                border: none;
                                box-shadow: none;
                                outline: none;
                                padding: 0;
                                font-size: 14px;
                                color: #879AAF;
                                font-weight: 500;
                    
                                &:focus {
                                    box-shadow: none !important;
                                    border: none !important;
                                    outline: none !important;
                                }
                            }
                        }
                    
                        .menus {
                            &.active {
                                color: #13B078;
                    
                                .sub-menu-icon {
                                    color: #13B078;
                                }
                    
                                .sub-menu-text {
                                    color: #13B078;
                                    font-weight: 500;
                                }
                            }
                        }
                    
                        .signal-link {
                            color: #879AAF;
                            font-weight: 400;
                            transition: all ease .2s;
                            transition-property: color;
                            letter-spacing: 1px;
                    
                            .sub-menu-icon {
                                color: #495B70;
                                width: 44px;
                                text-align: center;
                                display: inline-block;
                                transition: all ease .2s;
                                transition-property: color;
                            }
                    
                            &:hover {
                                color: #fff;
                                text-decoration: none;
                                transition-duration: .1s;

                                .sub-menu-icon {
                                    color: #fff;
                                    transition-duration: .1s;
                                }
                            }
                        }
                    
                        .btn-sidebar {
                            color: #879AAF;
                            background-color: #202C39;
                            border-color: #202C39;
                            padding: 5px 10px;
                            border-radius: 2px;
                            font-size: 12px;
                    
                            &:hover {
                                background-color: #0E1824 !important;
                                border-color: #0E1824 !important;
                                color: #6E7885;
                                box-shadow: none;
                            }
                    
                            &:active,
                            &:focus {
                                color: #6E7885;
                                background-color: #202C39;
                                border-color: #202C39;
                                box-shadow: none;
                            }
                    
                            @media screen and (min-width: 320px) and (max-width: 1199px) {
                                display: none;
                            }
                    
                            @media screen and (min-width: 1200px) and (max-width: 1440px) {
                                padding: 4px 8px;
                                font-size: 10px;
                            }
                        }

                        .sidebar-menu-content-title {
                            text-transform: uppercase;
                            color: #656C76;
                            font-size: 13px;
                            letter-spacing: 1px;
                            margin-bottom: 5px;

                            .sub-menu-icon {
                                margin-right: 10px;
                            }

                            .sub-menu-text {
                                font-weight: 500;
                                font-size: 12px;
                            }
                        }

                        .sidebar-menu-content-item-wrapper {
                            padding-left: 0;

                            .sidebar-menu-content-lists {
                                position: relative;
                                margin: 0 0 15px;
                                padding-left: 0px;
                                list-style-type: none;

                                &.signal-menu {
                                    & > li {
                                        margin-top: 14px;

                                        .sub-menu-icon {
                                            font-size: 16px;
                                        }

                                        .sub-menu-text {
                                            font-size: 18px !important;
                                            opacity: 0;
                                            transition: opacity 1s ease-in-out;
                                        }
                                    }
                                }

                                .sub-menu-text {
                                    color: #879AAF;
                                    font-size: 13px;
                                    align-items: center;

                                    .pro-feature {
                                        color: gold;
                                        margin-left: 10px;
                                        background-color: black;
                                        padding: 1px 3px;
                                        border-radius: 4px;
                                        border: 1px solid gold;
                                        font-size: 10px;
                                    }
                                }

                                & > li {
                                    display: block;
                                    padding: 5px 0;
                                    font-size: 14px;
                                    margin-left: -10px;

                                    button {
                                        box-shadow: none !important;
                                        
                                        &.sub-menu-item-link {
                                            display: inline-flex;
                                            padding: 0;
                                            color: #fff;
                                            align-items: center;
                                        }

                                        .sub-menu-icon {
                                            color: #495B70;
                                            width: 44px;                                           
                                        }

                                        .sub-menu-text {
                                            text-align: left;
                                            color: #879AAF;
                                        }
                                    }

                                    .rsi {
                                        width: 30px !important;
                                        margin-right: 13px !important;
                                    }

                                    & > div,
                                    & > a {
                                        display: flex;
                                        align-items: center;
                                        text-decoration: none;

                                        &.active {
                                            & > span:first-child {
                                                color: #41FFA7;
                                            }
                                        }

                                        & > span:first-child {
                                            color: #c1d0e2;
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-start;
                                            text-align: left;
                                            overflow: hidden;
                                            width: 20px;
                                            height: auto;
                                            transition: all ease .2s;
                                            transition-property: color;

                                            &.sub-menu-icon {
                                                justify-content: center;
                                                color: #495B70;
                                                width: 44px;                                      
                                            }

                                            & > img {
                                                border-radius: 50%;
                                            }

                                            & > i {
                                                font-size: 18px;
                                            }

                                            &:hover {
                                                color: #fff;
                                                transition-duration: .1s;
                                            }
                                        }
                                    }
                                }

                                .sub-menu-item {                        
                                    .sub-menu-item-link {
                                        font-weight: 400;
                                        letter-spacing: 1px;
                                        cursor: pointer;

                                        .sub-menu-icon {
                                            position: relative;

                                            .has-new {
                                                display: block;
                                                width: 8px;
                                                height: 8px;
                                                background-color: #fe0062;
                                                border-radius: 50%;
                                                right: 6px;
                                                position: absolute;
                                                top: 0;
                                            }
                                        }

                                        &.active {
                                            .sub-menu-icon,
                                            .sub-menu-text {
                                                color: #13B078;
                                            }
                                        }

                                        &:hover {
                                            .sub-menu-icon,
                                            .sub-menu-text {
                                                color: #fff;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.last {
                            .survey-link {
                                padding: 10px 15px;
                                text-align: center;
                                background-color: #16b46c;
                                border-radius: 4px;
                                border-bottom: 6px solid #0b8e52;
                                font-weight: 700;
                                text-shadow: 1px 1px rgb(0 0 0 / 30%);
                                transition: transform .5s;

                                a {                                    
                                    color: #fff;
                                    cursor: pointer;
                                    text-decoration: none;

                                    .sub-menu-text {
                                        font-size: 13px;
                                    }
                                }

                                .survey-link-icons {
                                    display: flex;
                                    width: 100%;
                                    justify-content: space-evenly;
                                    margin-top: 6px;
                                    align-items: center;
                                }

                                &:hover {
                                    transform: scale(1.05);
                                    
                                }
                            }
                        }

                        &:last-child {
                            margin-top: auto;
                        }
                    }

                    .to-hide-items {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        height: 100%;
        
                        .mobile-logout {
                            &.sm {
                                position: absolute;
                                bottom: 20px;
                            }

                            a {
                                color: #96a1b7;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

	nav {
		padding: 0.53rem 1rem !important;

		.navbar-brand {
			font-size: 16px !important;
			padding: 0.5rem 0;
		}
    }

    &.sidebar-size {
        &.show {
            //left: 0;
            transform: translate3d(0,0,0);
            //transition: width 300ms ease-in-out;
        }
    }

    &.notif-permission {
        top: 77px;
    }

    ${mediaQueries.mediumDevices} {
        &.notif-permission {
            top: 36px;
        }
    }

    @media only screen and (min-width: 768px) {
        .sidebar-menu {
            .card {
                height: 100%;
            }
        }
    }

    @media only screen and (min-width: 992px) {
        width: 60px;
        transform: translate3d(0,0,0);

        .sidebar-menu {
            .card {
                .card-body {
                    padding: 0;
                    
                    .sidebar-menu-lists {
                        .sidebar-menu-items {
                            &.side-bar-signals {
                                margin-left: 0;
                            }

                            .menus {
                                display: block;
                                width: 100%;
                                padding: 10px 3px;
                                text-align: center;

                                .sub-menu-icon {
                                    width: auto;
                                }

                                .sub-menu-text {
                                    display: none;
                                }
                            }

                            .sidebar-menu-content-item-wrapper {
                                .sidebar-menu-content-lists {
                                    & > li {
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;

                                        .sub-menu-icon {                                            
                                            font-size: 12px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;

                                            i {
                                                font-size: 16px;
                                            }
                                        }

                                        .sub-menu-text {
                                            font-size: 13px;
                                            width: 100%;
                                            margin-bottom: 0px;
                                        }

                                        & > div,
                                        & > button,
                                        & > a {
                                            & > span:first-child {
                                                &.sub-menu-icon {
                                                    width: auto;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .to-hide-items {
                            height: 100%;

                            .sidebar-menu-items {
                                .sidebar-menu-content-title {
                                    display: none;
                                }

                                .sidebar-menu-content-item-wrapper {
                                    .sidebar-menu-content-lists {
                                        & > li {
                                            margin-left: 0;

                                            button, a {
                                                display: block;
                                                width: 100%;
                                                padding: 10px 3px;
                                                text-align: center;

                                                &.sub-menu-item-link {
                                                    .sub-menu-text {
                                                        display: none;
                                                    }
                                                }
                                            }

                                            & > div,
                                            & > a {
                                                & > span:first-child {
                                                    &.sub-menu-icon {
                                                        width: auto;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.sidebar-size {
            &.show {
                width: 278px;

                .sidebar-menu {
                    .card {
                        .card-body {
                            .sidebar-menu-lists {
                                .sidebar-menu-items {
                                    padding: 10px 10px 0;

                                    .menus {
                                        display: flex;

                                        .sub-menu-icon {
                                            width: 44px;
                                        }

                                        .sub-menu-text {
                                            display: inline-block;
                                        }
                                    }

                                    .sidebar-menu-content-item-wrapper {
                                        .sidebar-menu-content-lists {
                                            & > li {
                                                padding: 0;

                                                & > div,
                                                & > button,
                                                & > a {
                                                    & > span:first-child {
                                                        &.sub-menu-icon {
                                                            width: 44px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .to-hide-items {
                                    .sidebar-menu-items {
                                        .sidebar-menu-content-title {
                                            display: inline-block;
                                        }

                                        .sidebar-menu-content-item-wrapper {
                                            .sidebar-menu-content-lists {
                                                & > li {
                                                    button, a {
                                                        display: flex;
                                                        text-align: left;

                                                        &.sub-menu-item-link {
                                                            .sub-menu-text {
                                                                display: inline-block;
                                                            }
                                                        }
                                                    }

                                                    & > div,
                                                    & > a {
                                                        & > span:first-child {
                                                            &.sub-menu-icon {
                                                                width: 44px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1200px) {        
        width: 242px;
        
        .sidebar-menu {
            .card {
                .card-body {
                    .sidebar-menu-lists {
                        padding-left: 12px;
                        padding-right: 12px;

                        .sidebar-menu-items {
                            .menus {
                                display: flex;
                                text-align: left;

                                .sub-menu-icon {
                                    width: 44px;
                                }

                                .sub-menu-text {
                                    display: flex;
                                    width: calc(100% - 44px)
                                }
                            }
                            
                            .btn-sidebar {
                                display: none;
                            }
                        }

                        .to-hide-items {
                            .sidebar-menu-items {
                                .sidebar-menu-content-title {
                                    display: inline-block;
                                }

                                .sidebar-menu-content-item-wrapper {
                                    .sidebar-menu-content-lists {
                                        & > li {
                                            padding: 0;

                                            button, a {
                                                display: flex;
                                                text-align: left;

                                                &.sub-menu-item-link {
                                                    .sub-menu-icon {
                                                        width: 44px;
                                                    }

                                                    .sub-menu-text {
                                                        display: flex;
                                                        width: calc(100% - 44px);

                                                        &.with-loader {
                                                            display: inline-block;
                                                            width: 100%;

                                                            .fa-pulse {
                                                                margin: 0 12px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            & > div,
                                            & > a {
                                                & > span:first-child {
                                                    &.sub-menu-icon {
                                                        width: 44px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.sidebar-size {
            &.show {
                width: 242px;
            }
        }
    }
    
    @media only screen and (min-width: 1440px) {
        width: 278px;

        &.sidebar-size {
            &.show {
                width: 278px;
            }
        }
    }
`