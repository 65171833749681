import { onboardingActionTypes } from '../CONSTANTS'
import { onboardingServices } from '../../services'
import { isNotNull, encryptText } from '../../utils'

function getOnboardingState() {
    return dispatch => {
        dispatch(request())

        onboardingServices.getOnboardingState()
            .then(res => dispatch(success(res)), error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: onboardingActionTypes.GET_ONBOARDING_STATE_REQUEST } }
    function success(response) { return { type: onboardingActionTypes.GET_ONBOARDING_STATE_SUCCESS, response } }
    function failed(error) { return { type: onboardingActionTypes.GET_ONBOARDING_STATE_FAILED, error } }
}

function getBasicInfo() {
    return dispatch => {
        dispatch(request())

        onboardingServices.getBasicInfo()
            .then(res => dispatch(success(res)), error => {
                dispatch(failed(error))
            })
    }
    
    function request() { return { type: onboardingActionTypes.GET_ONBOARDING_USER_PROFILE_REQUEST } }
    function success(response) { return { type: onboardingActionTypes.GET_ONBOARDING_USER_PROFILE_SUCCESS, response } }
    function failed(error) { return { type: onboardingActionTypes.GET_ONBOARDING_USER_PROFILE_FAILED, error } }
}

function getTradingExpDetails() {
    return dispatch => {
        dispatch(request())

        onboardingServices.getTradingExpDetails()
            .then(res => dispatch(success(res)), error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: onboardingActionTypes.GET_ONBOARDING_USER_TRADING_EXP_DETAILS_REQUEST } }
    function success(response) { return { type: onboardingActionTypes.GET_ONBOARDING_USER_TRADING_EXP_DETAILS_SUCCESS, response } }
    function failed(error) { return { type: onboardingActionTypes.GET_ONBOARDING_USER_TRADING_EXP_DETAILS_FAILED, error } }
}

function getFinancialDetails() {
    return dispatch => {
        dispatch(request())

        onboardingServices.getFinancialDetails()
            .then(res => dispatch(success(res)), error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: onboardingActionTypes.GET_ONBOARDING_USER_FINANCIAL_DETAILS_REQUEST } }
    function success(response) { return { type: onboardingActionTypes.GET_ONBOARDING_USER_FINANCIAL_DETAILS_SUCCESS, response } }
    function failed(error) { return { type: onboardingActionTypes.GET_ONBOARDING_USER_FINANCIAL_DETAILS_FAILED, error } }
}

function getTradingBalances() {
    return dispatch => {
        dispatch(request())

        onboardingServices.getTradingBalances()
            .then(res => dispatch(success(res)), error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: onboardingActionTypes.GET_ONBOARDING_USER_WALLET_BALANCES_REQUEST } }
    function success(response) { return { type: onboardingActionTypes.GET_ONBOARDING_USER_WALLET_BALANCES_SUCCESS, response } }
    function failed(error) { return { type: onboardingActionTypes.GET_ONBOARDING_USER_WALLET_BALANCES_FAILED, error } }
}

function saveBasicInfo(data) {
    return dispatch => {
        dispatch(request())

        onboardingServices.saveBasicInfo(data)
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failed(error)))
    }

    function request() { return { type: onboardingActionTypes.SAVE_ONBOARDING_USER_PROFILE_REQUEST } }
    function success(response) { return { type: onboardingActionTypes.SAVE_ONBOARDING_USER_PROFILE_SUCCESS, response } }
    function failed(error) { return { type: onboardingActionTypes.SAVE_ONBOARDING_USER_PROFILE_FAILED, error } }
}

function saveTradingExpDetails(data) {
    return dispatch => {
        dispatch(request())

        onboardingServices.saveTradingExpDetails(data)
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failed(error)))
    }

    function request() { return { type: onboardingActionTypes.SAVE_ONBOARDING_USER_TRADING_EXP_DETAILS_REQUEST } }
    function success(response) { return { type: onboardingActionTypes.SAVE_ONBOARDING_USER_TRADING_EXP_DETAILS_SUCCESS, response } }
    function failed(error) { return { type: onboardingActionTypes.SAVE_ONBOARDING_USER_TRADING_EXP_DETAILS_FAILED, error } }
}

function saveFinancialDetails(data) {
    return dispatch => {
        dispatch(request())

        onboardingServices.saveFinancialDetails(data)
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failed(error)))
    }

    function request() { return { type: onboardingActionTypes.SAVE_ONBOARDING_USER_FINANCIAL_DETAILS_REQUEST } }
    function success(response) { return { type: onboardingActionTypes.SAVE_ONBOARDING_USER_FINANCIAL_DETAILS_SUCCESS, response } }
    function failed(error) { return { type: onboardingActionTypes.SAVE_ONBOARDING_USER_FINANCIAL_DETAILS_FAILED, error } }
}

function saveExchangeKeys(data) {
    return dispatch => {
        dispatch(request())
        
        if (isNotNull(data.apiKey))
            data.apiKey = encryptText(data.apiKey)

        if (isNotNull(data.secretKey))
            data.secretKey = encryptText(data.secretKey)

        onboardingServices.saveExchangeKeys(data)
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failed(error)))
    }

    function request() { return { type: onboardingActionTypes.SAVE_ONBOARDING_USER_EXCHANGE_KEYS_REQUEST } }
    function success(response) { return { type: onboardingActionTypes.SAVE_ONBOARDING_USER_EXCHANGE_KEYS_SUCCESS, response } }
    function failed(error) { return { type: onboardingActionTypes.SAVE_ONBOARDING_USER_EXCHANGE_KEYS_FAILED, error } }
}

function configureExchangeKeys() {
    return dispatch => {
        dispatch(request())

        onboardingServices.configureExchangeIntegration()
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failed(error)))
    }

    function request() { return { type: onboardingActionTypes.CONFIGURE_EXCHANGE_INTEGRATION_REQUEST } }
    function success(response) { return { type: onboardingActionTypes.CONFIGURE_EXCHANGE_INTEGRATION_SUCCESS, response } }
    function failed(error) { return { type: onboardingActionTypes.CONFIGURE_EXCHANGE_INTEGRATION_FAILED, error } }
}

function configureTradingBalance() {
    return dispatch => {
        dispatch(request())

        onboardingServices.configureTradingBalance()
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failed(error)))
    }

    function request() { return { type: onboardingActionTypes.CONFIGURE_TRADING_BALANCE_REQUEST } }
    function success(response) { return { type: onboardingActionTypes.CONFIGURE_TRADING_BALANCE_SUCCESS, response } }
    function failed(error) { return { type: onboardingActionTypes.CONFIGURE_TRADING_BALANCE_FAILED, error } }
}

function setTradingBalance(data) {
    return dispatch => {
        dispatch(request())

        onboardingServices.setTradingBalance(data)
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failed(error)))
    }

    function request() { return { type: onboardingActionTypes.SET_TRADING_BALANCE_REQUEST } }
    function success(response) { return { type: onboardingActionTypes.SET_TRADING_BALANCE_SUCCESS, response } }
    function failed(error) { return { type: onboardingActionTypes.SET_TRADING_BALANCE_FAILED, error } }
}

function setToComplete() {
    return dispatch => {
        dispatch(request())

        onboardingServices.setToComplete()
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failed(error)))
    }

    function request() { return { type: onboardingActionTypes.SET_ONBOARDING_COMPLETE_REQUEST } }
    function success(response) { return { type: onboardingActionTypes.SET_ONBOARDING_COMPLETE_SUCCESS, response } }
    function failed(error) { return { type: onboardingActionTypes.SET_ONBOARDING_COMPLETE_FAILED, error } }
}

function skipOnboarding() {
    return dispatch => {
        dispatch(request())

        onboardingServices.skipOnboarding()
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failed(error)))
    }

    function request() { return { type: onboardingActionTypes.SKIP_ONBOARDING_REQUEST } }
    function success(response) { return { type: onboardingActionTypes.SKIP_ONBOARDING_SUCCESS, response } }
    function failed(error) { return { type: onboardingActionTypes.SKIP_ONBOARDING_FAILED, error } }
}

export const onboardingActions = {
    getOnboardingState,
    getBasicInfo,
    getTradingExpDetails,
    getFinancialDetails,
    getTradingBalances,
    saveBasicInfo,
    saveTradingExpDetails,
    saveFinancialDetails,
    saveExchangeKeys,
    configureExchangeKeys,
    configureTradingBalance,
    setTradingBalance,
    setToComplete,
    skipOnboarding
}