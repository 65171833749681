export const device = {
    mobileS: '(min-width: 320px)',
    mobileSM: '(min-width: 360px)',
    mobileM: '(min-width: 375px)',
    mobileMS: '(min-width: 411px)',
    mobileR: '(min-width: 414px)',
    mobileL: '(min-width: 425px)',
    mobileXL: '(min-width: 568px)',
    mobileXRL: '(min-width: 640px)',
    mobileXXL: '(min-width: 667px)',
    tabletS: '(min-width: 736px)',
    tablet: '(min-width: 768px)',
    tabletL: '(min-width: 812px)',
    tabletXL: '(min-width: 992px)',
    laptop: '(min-width: 1024px)',
    laptopL: '(min-width: 1200px)',
    desktop: '(min-width: 1920px)',
    desktopL: '(min-width: 1360px)',
    deviceS: '(min-height: 640px)',
    deviceSM: '(min-height: 667px)',
    deviceSL: '(min-height: 740px)',
    deviceR: '(min-height: 736px)',
    deviceL: '(min-height: 736px)',
    deviceXL: '(min-height: 768px)',
    deviceXXL: '(min-height: 812px)',
    deviceXXXL: '(min-height: 1024px)',
    deviceTablet: '(min-height: 736px)'
}

export const color = {
    primaryBG: '#15bd6f',
    primaryBorder: '#61B755',
    secondaryBG: '#475973',
    secondaryBorder: '#577094',
    primaryText: '#FFFFFF'
}
