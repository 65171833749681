import React from 'react'
import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import './styles/early-closed-trade-modal.scss'
import { ReviewClosedOrderDetails } from './ReviewClosedOrderDetails'
import { isNotNull } from '../../../../utils'

export const ClosedTradeReviewModal = (props) => {
    const { showModal, data } = props.state
    const { onClose } = props.eventHandlers

    const renderModalBody = () => {
        if (isNotNull(data)) {
            return (
                <>
                    <div className="close" style={{ zIndex: '1' }} onClick={(e) => onClose(e)}>
                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                    </div>
                    
                    <ReviewClosedOrderDetails 
                        data={data} 
                        eventHandlers={{
                            close: (e) => onClose(e)
                        }} />
                </>
            )
        }
    }

    return (
        <Modal 
            id="trade-closed-early-review-modal" 
            className="trade-closed-early-review-modal" 
            show={showModal} 
            aria-labelledby="tradeClosedReviewModal" 
            aria-hidden="true" 
            backdrop={'static'}
            keyboard={false}
            centered>
            <Modal.Body>
                {renderModalBody()}
            </Modal.Body>
        </Modal>
    )
}