import { signalStatesActionTypes, signalActionTypes, followedSignalActionTypes } from '../CONSTANTS'
import { signalServices } from '../../services'
import { decryptText, toCamelCase } from '../../utils'

function clearSignals() {
    return dispatch => {
        dispatch({
            type: signalActionTypes.RESET
        })
    }
}

function getSignalStates() {
    return dispatch => {
        dispatch(request())

        signalServices.getSignalStates().then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failed(error))
        })
    }

    function request() { return { type: signalStatesActionTypes.GET_STATES_REQUEST } }
    function success(states) { return { type: signalStatesActionTypes.GET_STATES_SUCCESS, states } }
    function failed(error) { return { type: signalStatesActionTypes.GET_STATES_FAIL, error } }
}

function getSignals(state, exchange, resultTypes, page, take) {
    return dispatch => {
        dispatch(request())

        signalServices.getSignals(state, exchange, resultTypes, page, take)
            .then(response => {
                let signalsData = decryptText(response)
                let parsedData = JSON.parse(signalsData, toCamelCase)
                dispatch(success(parsedData))
            }, error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: signalActionTypes.GET_SIGNALS_REQUEST } }
    function success(signals) { return { type: signalActionTypes.GET_SIGNALS_SUCCESS, signals } }
    function failed(error) { return { type: signalActionTypes.GET_SIGNALS_FAIL, error } }
}

function getNewSignalSet(state, exchange, resultTypes, page, take) {
    return dispatch => {
        dispatch(request())

        signalServices.getSignals(state, exchange, resultTypes, page, take)
        .then(response => {
            let signalsData = decryptText(response)
            let parsedData = JSON.parse(signalsData, toCamelCase)
            dispatch(success(parsedData))
        }, error => {
            dispatch(failed(error))
        })
    }

    function request() { return { type: signalActionTypes.GET_NEW_SIGNALS_REQUEST } }
    function success(signals) { return { type: signalActionTypes.GET_NEW_SIGNALS_SUCCESS, signals } }
    function failed(error) { return { type: signalActionTypes.GET_NEW_SIGNALS_FAIL, error } }
}

function getFollowedSignals() {
    return dispatch => {
        dispatch(request())

        signalServices.getFollowedSignals().then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failed(error))
        })
    }

    function request() { return { type: followedSignalActionTypes.GET_FOLLOWED_SIGNALS_REQUEST } }
    function success(followedSignals) { return { type: followedSignalActionTypes.GET_FOLLOWED_SIGNALS_SUCCESS, followedSignals } }
    function failed(error) { return { type: followedSignalActionTypes.GET_FOLLOWED_SIGNALS_FAIL, error } }
}

function updateSignals(signals) {
    return dispatch => {
        dispatch({
            type: signalActionTypes.UPDATE_SIGNAL,
            signals
        })
    }
}

function updateSignalState(signals) {
    return dispatch => {
        dispatch({
            type: signalActionTypes.UPDATE_SIGNAL_STATE, signals
        })
    }
}

function followSignal(signalId) {
    return dispatch => {
        dispatch(request())

        signalServices.followSignal(signalId).then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failed(error))
        })
    }

    function request() { return { type: followedSignalActionTypes.FOLLOW_SIGNAL_REQUEST } }
    function success(response) { return { type: followedSignalActionTypes.FOLLOW_SIGNAL_SUCCESS, response } }
    function failed(error) { return { type: followedSignalActionTypes.FOLLOW_SIGNAL_FAIL, error } }
}

function clear() {
    return dispatch => {
        dispatch({
            type: signalActionTypes.CLEAR_SIGNALS
        });
    }
}

export const signalActions = {
    clearSignals,
    getSignalStates,
    getSignals,
    getNewSignalSet,
    getFollowedSignals,
    updateSignals,
    updateSignalState,
    followSignal,
    clear,
}