import ReactGA from 'react-ga'

export function initializeReactGA(uid, page) {
	ReactGA.initialize(uid)
	//ReactGA.pageview(page)
}

export function recordEventReactGA(category, action) {
	ReactGA.event({
		category,
		action
	})
}

export function addPageViewEvent() {
	window.dataLayer.push({
		event: 'pageview',
		page: {
			path: document.location.pathname + document.location.search,
			title: document.title
			//path: document.location.origin + document.location.pathname + document.location.search,
		}
	})
}

export function pushEvent(eventName, data) {
	window.dataLayer.push({
		event: eventName,
		data
	})
}