import styled from 'styled-components'
import { mediaQueries } from '../../constants/mediaQueries'

const getFlexAlignment = (align) => {
    switch (align) {
        default: return 'center';
        case 'start': return 'flex-start';
        case 'end': return 'flex-end';
    }
} 

const getBgColor = (theme) => {
    switch (theme) {
        default: return '#0F1823'
        case 'light-dark': return '#1f2936';
        case 'light': return '#f1f6fb';
    }
}

const getHeaderTextColor = (theme) => {
    switch (theme) {
        default:            
        case 'light-dark':
            return '#fff';
        case 'light': 
            return '#162739';
    }
}

export const AlertBlock = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px 30px;
    background-color: ${ props => getBgColor(props.theme) };

    p {
        font-size: 13px;
        margin-bottom: 15px;
        font-weight: 600;
        text-align: center;
        word-break: break-word;
        animation: textColorChange 20s infinite alternate;
        -webkit-animation: textColorChange 20s infinite alternate;
    }

    a {
        background-color: #16B46C !important;
        color: #fff !important;
        display: inline-block;
        font-weight: 400;
        text-align: center;
        border: 1px solid #02a73b !important;
        font-size: 15px !important;
        padding: 6px 15px !important;
        margin-left: 0;
        word-break: keep-all;
        min-width: 135px;
    }

    &.black-friday {
        background-color: #000;
    }

    ${mediaQueries.mediumDevices} {
        flex-direction: row;

        p {
            margin-bottom: 0;
            font-weight: 500;
        }
    }

    ${mediaQueries.largeDevices} {
        p {
            text-align: left;
        }
    }

    ${mediaQueries.extraLargeDevices} {
        p {
            font-size: 16px;
            margin-right: 25px;
        }
    }

    @keyframes textColorChange {
        0% { color: #fe007d; }
        25% { color: #8e44ad; }
        50% { color: #fe007d; }
        75% { color: #6b6bea; }
        100% { color: #fe007d; }
    }
`

export const SectionBlock = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: ${ props => getBgColor(props.theme) };
    padding: 32px 0;

    .section-title {
        color: ${props => getHeaderTextColor(props.theme) }
    }
`

export const Container = styled.div`
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
`

export const RowBlock = styled.div`
    display: flex;
    align-items: ${props => getFlexAlignment(props.align) };
    justify-content: space-between;
    margin-bottom: 25px;

    &.as-block {
        flex-direction: column;
    }

    ${mediaQueries.extraSmallDevices} {
        flex-direction: column;
        padding: 0 6px;

        &.profile-info {
            margin-bottom: 15px;
        }
    }

    ${mediaQueries.mediumDevices} {
        padding: 0 10px;
        margin-bottom: 20px;

        &.profile-info {
            margin-bottom: 20px
        }
    }

    ${mediaQueries.extraLargeDevices} {
        flex-direction: row;
        margin-bottom: 30px;
    }
`

export const SectionHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;
    margin-bottom: 15px;

    .section-title {
        display: flex;
        align-items: center;
        margin-right: auto;
        margin-bottom: 0;

        .icon {
            margin-right: 15px;
            color: #13B078;
            width: 28px;
        }

        .text {
            font-size: 24px;
        }

        .separator {
            color: #1F324A;
            font-weight: 400;
            margin: 0 10px;
        }

        .header-link {
            font-size: 14px;
            color: #247DBF;
            text-decoration: underline;
        }
    }

    .section-actions {
        width: 160px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & a {
            padding: 6px 16px;
            background-color: #13B078;
            text-decoration: none;
            color: #fff;
            border-radius: 18px;
            transition: transform ease .5s;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    ${mediaQueries.extraSmallDevices} {
        .section-title {
            .text {
                font-size: 18px;
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        margin-bottom: 20px;

        .section-title {
            .text {
                font-size: 20px;
            }
        }
    }

    ${mediaQueries.largeDevices} {
        .section-title {
            .text {
                font-size: 28px;
            }
        }
    }
`