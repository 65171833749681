import { useEffect, useState } from 'react'
import moment from 'moment'
import { SafeAnchor } from 'react-bootstrap'
import { DynamicCheckout } from '../../../../components'
import { RecapSectionStyle } from '../styles'

export const RetRecap = (props) => {
    let [remainingDays, setRemainingDays] = useState('')
    let [remainingHours, setRemainingHours] = useState('')
    let [remainingMinutes, setRemainingMinutes] = useState('')
    let [remainingSeconds, setRemainingSeconds] = useState('')

    useEffect(() => {
        let parsedDate = Date.parse(props.validUntil)
        let expiry = new Date(parsedDate)
            expiry = moment(expiry).format('MM/DD/yyyy h:mm:ss')

        const second = 1000,
            minute = second * 60,
            hour = minute * 60,
            day = hour * 24;

        let today = new Date(),
            dd = String(today.getDate()).padStart(2, "0"),
            mm = String(today.getMonth() + 1).padStart(2, "0"),
            yyyy = today.getFullYear(),
            nextYear = yyyy + 1,
            dayMonth = moment(expiry).format('MM/DD/')
    
        today = mm + "/" + dd + "/" + yyyy
        if (today > expiry) {
            expiry = dayMonth + nextYear
        }

        const countDown = new Date(expiry).getTime()
        const x = setInterval(function() {    
            const now = new Date().getTime(),
                distance = countDown - now

            setRemainingDays(Math.floor(distance / (day)))
            setRemainingHours(Math.floor((distance % (day)) / (hour)))
            setRemainingMinutes(Math.floor((distance % (hour)) / (minute)))
            setRemainingSeconds(Math.floor((distance % (minute)) / second))

            if (distance < 0) {
                clearInterval(x)
            }
            //seconds
        }, 1000)

        return () => clearInterval(x)
    }, [])

    return (
        <RecapSectionStyle>
            <div className='container'>
                <h2 className='section-title'>SAVE 40% PLUS GET TRADE DOCTOR SUPPORT NOW</h2>
                <p>6 months of PRO access for just <strong className='em'>$899</strong> means you'll be ready to cash in on all of the next crypto booms, and the super low price will mean you can patiently wait for bad trading conditions to pass by.</p>
                <p>On top of that you get 2 personal Trade Doctor sessions to help you diagnose where you might have been going wrong in the past and give you actionable advice on how to make the most of your PRO account.</p>
                <p>Set yourself up for success now, all for less than <strong className='em'>$5 per day</strong>.</p>

                <div className='price-tag-wrapper ret'>
                    <div className='img-wrapper'>
                        <img src='/images/layout/the-package.png' alt='Exclusive Price for PRO Trader' />
                    </div>
                    <div className='text-col'>
                        <div className='original-price'>
                            <span className='crashed-out-price'>$1,499</span>
                        </div>

                        <div className='discounted-price'>
                            <span className='discounted-price-value'>$899</span>
                            <span><img src='/images/layout/only-tag.svg' alt='' /></span>
                        </div>

                        <h6 className='impt'>Hurry! Promo ends in</h6>

                        <div className='offer-validity-timer'>
                            <ul>
                                <li>
                                    <span id='rc-days' className='counter'>{parseInt(remainingDays) < 10 ? '0': ''}{remainingDays}</span>
                                    <small>DAYS</small>
                                </li>
                                <li>
                                    <span id='rc-hours' className='counter'>{parseInt(remainingHours) < 10 ? '0': ''}{remainingHours}</span>
                                    <small>HOURS</small>
                                </li>
                                <li>
                                    <span id='rc-minutes' className='counter'>{parseInt(remainingMinutes) < 10 ? '0': ''}{remainingMinutes}</span>
                                    <small>MINUTES</small>
                                </li>
                                <li>
                                    <span id='rc-seconds' className='counter'>{parseInt(remainingSeconds) < 10 ? '0': ''}{remainingSeconds}</span>
                                    <small>SECONDS</small>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>                

                <div className='checkout-btn'>
                    <DynamicCheckout 
                        text="Sign My Offer"
                        position="centered" 
                        userInfo={props.userInfo} 
                        offer={props.subscriptionPlan} />
                </div>

                <p className='little-note'>Note: Crypto payments are welcome, please <SafeAnchor href="https://www.profitfarmers.com/crypto-payment/" rel="noreferrer">contact our support team directly to organize this</SafeAnchor></p>
            </div>
        </RecapSectionStyle>
    )
}