import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'react-moment'
import { Card, Table } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { orderActions } from '../../../../redux/actions'
import { orderTypes, orderExchanges, orderStates } from '../../../../constants/orderConstants'
import { getCoin, setDefaultImage, isNull, isNotNull, onSetNewState } from '../../../../utils'

class ReviewClosedOrderDetails extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            reviewItem: null,
            signalFeed: '',
            signalCoinPair: '',
            signalStrategy: '',
            orderDetails: null,
            hasMultipleItems: false
        }
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        
        const { data } = this.props
        if (isNotNull(data)) {
            onSetNewState(this, {
                reviewItem: data
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.mounted) {
            const { dispatch, openOrderDetails } = this.props
            const { isLoading, reviewItem, orderDetails } = this.state

            if (isNotNull(reviewItem) && 
                isNull(orderDetails) && 
                !isLoading) {
                onSetNewState(this, {
                    isLoading: true
                }, () => {
                    dispatch(orderActions.getOrderDetails(reviewItem.actorId))
                })
            }

            if (prevProps.openOrderDetails !== openOrderDetails && 
                isNull(orderDetails) && 
                isLoading) {
                    const { open_order_details } = openOrderDetails
                    if (isNotNull(open_order_details)) {
                        const { feed, symbol, strategy, orderLines } = open_order_details
                        
                        onSetNewState(this, {
                            isLoading: false,
                            signalFeed: feed,
                            signalCoinPair: symbol, 
                            signalStrategy: strategy,
                            orderDetails: open_order_details,
                            hasMultipleItems: orderLines.length > 1
                        })
                    }
            }
        }
    }

    componentWillUnmount() {
        onSetNewState(this, {
            reviewItem: null,
            signalFeed: '',
            signalCoinPair: '',
            signalStrategy: '',
            orderDetails: null
        })

        this.mounted = false
    }

    handleCancelOrder = (e, feed, orderId, orderType) => {
        e.preventDefault()
        const { dispatch, eventHandlers } = this.props
        const { hasMultipleItems, orderDetails } = this.state

        Swal.fire({
            title: 'Are you sure you want to cancel this order?',
            text: 'It will cancel your Stop Loss and Take Profits Orders. All coins will remain in your wallet',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.value) {
                const requestData = {
                    feed,
                    orderId
                }
                dispatch(orderActions.forceCancelOrder(requestData))

                let title = orderType === orderTypes.STOP_BUY_ORDER 
                    ? "Order Cancelled!" 
                    : "Trade Closed!"

                if (hasMultipleItems) {
                    if (isNotNull(orderDetails) && isNotNull(orderDetails.orderLines)) {
                        const { orderLines } = orderDetails
                        let exists = _.find(orderLines, ['orderId', orderId])
                        if (exists) {
                            let remainingOrders = orderLines.length - 1
                            if (remainingOrders > 0) {
                                _.pull(orderLines, exists)
                                onSetNewState(this, {
                                    isLoading: false
                                })
                            } else {
                                onSetNewState(this, {
                                    reviewItem: null,
                                    signalFeed: '',
                                    signalCoinPair: '',
                                    signalStrategy: '',
                                    orderDetails: null
                                }, () => {
                                    eventHandlers.close(e)
                                })
                            }
                        }
                    }
                } else {
                    onSetNewState(this, {
                        reviewItem: null,
                        signalFeed: '',
                        signalCoinPair: '',
                        signalStrategy: '',
                        orderDetails: null
                    }, () => {
                        eventHandlers.close(e)
                    })
                }

                Swal.close()
                Swal.fire({
                    title: title,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    handlePanicExitOrder = (e, feed, orderId, orderType) => {
        e.preventDefault()
        const { dispatch, eventHandlers } = this.props
        const { hasMultipleItems, orderDetails } = this.state
        
        Swal.fire({
            title: 'Are you sure you want to exit this order?',
            text: 'It will close this order at market price by placing a Market buy/sell order on exchange',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.value) {
                onSetNewState(this, {
                    isLoading: true
                }, () => {
                    const requestData = {
                        feed,
                        orderId,
                        orderType
                    }
                    dispatch(orderActions.forceExitOrder(requestData))

                    if (hasMultipleItems) {
                        if (isNotNull(orderDetails) && isNotNull(orderDetails.orderLines)) {
                            const { orderLines } = orderDetails
                            let exists = _.find(orderLines, ['orderId', orderId])
                            if (exists) {
                                let remainingOrders = orderLines.length - 1
                                if (remainingOrders > 0) {
                                    _.pull(orderLines, exists)
                                    onSetNewState(this, {
                                        isLoading: false
                                    })
                                } else {
                                    onSetNewState(this, {
                                        reviewItem: null,
                                        signalFeed: '',
                                        signalCoinPair: '',
                                        signalStrategy: '',
                                        orderDetails: null
                                    }, () => {
                                        eventHandlers.close(e)
                                    })
                                }
                            }
                        }
                    } else {
                        onSetNewState(this, {
                            reviewItem: null,
                            signalFeed: '',
                            signalCoinPair: '',
                            signalStrategy: '',
                            orderDetails: null
                        }, () => {
                            eventHandlers.close(e)
                        })
                    }

                    Swal.close()
                    Swal.fire({
                        title: 'Trade Closed!',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000
                    })
                })
            }
        })
    }

    handleClosePosition = (e, feed, orderId, orderType) => {
        e.preventDefault()
        const { dispatch, eventHandlers } = this.props
        const { hasMultipleItems, orderDetails } = this.state
        
        Swal.fire({
            title: 'Are you sure you want to exit this position?',
            text: 'You will exit your position at the current Mark Price',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.value) {
                onSetNewState(this, {
                    isLoading: true
                }, () => {
                    const requestData = {
                        feed,
                        orderId,
                        orderType
                    }
                    dispatch(orderActions.forceExitOrder(requestData))

                    if (hasMultipleItems) {
                        if (isNotNull(orderDetails) && isNotNull(orderDetails.orderLines)) {
                            const { orderLines } = orderDetails
                            let exists = _.find(orderLines, ['orderId', orderId])
                            if (exists) {
                                let remainingOrders = orderLines.length - 1
                                if (remainingOrders > 0) {
                                    _.pull(orderLines, exists)
                                    onSetNewState(this, {
                                        isLoading: false
                                    })
                                } else {
                                    onSetNewState(this, {
                                        reviewItem: null,
                                        signalFeed: '',
                                        signalCoinPair: '',
                                        signalStrategy: '',
                                        orderDetails: null
                                    }, () => {
                                        eventHandlers.close(e)
                                    })
                                }
                            }
                        }
                    } else {
                        onSetNewState(this, {
                            reviewItem: null,
                            signalFeed: '',
                            signalCoinPair: '',
                            signalStrategy: '',
                            orderDetails: null
                        }, () => {
                            eventHandlers.close(e)
                        })
                    }

                    Swal.close()
                    Swal.fire({
                        title: 'Trade Closed!',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000
                    })
                })
            }
        })
    }

    renderTextContent = () => {
        const { signalCoinPair } = this.state
        if (isNotNull(signalCoinPair)) {
            return (
                <>
                    <h2 className="title">Warning: we are closing this trade early! - TAKE ACTION - {signalCoinPair}</h2>
                    <p>Hey there ProfitFarmer,</p>
                    <p>We don't like the look of this one and will be closing the trade early:</p>
                </>
            )
        }
    }

    renderSignalInfo = () => {
        const { signalFeed, signalCoinPair, signalStrategy } = this.state
        return (
            <Card className="signal-info-card">
                <Card.Body>
                    <h3>Signal Info</h3>  
                    <Table borderless={true}>
                        <tbody>
                            <tr>
                                <td className="align-right">Coin Pair</td>
                                <td>
                                    <div className="signal-info">
                                        <i className="coin-icon">
                                            <img 
                                                src={`https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/${getCoin(signalCoinPair)}.png`} 
                                                alt="coin-logo"
                                                onError={setDefaultImage} />
                                        </i>
                                        {signalCoinPair}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="align-right">Exchange</td>
                                <td>{signalFeed === 'SPOT' ? 'ON BINANCE' : 'ON BINANCE FUTURES'}</td>
                            </tr>
                            <tr>
                                <td className="align-right">Strategy</td>
                                <td>{signalStrategy}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        )
    }

    renderStopBuyOrderDetails = (value, key) => (
        <Card key={key}>
            <Card.Body>
                <h3>Trade Order Details</h3>  
                <Table borderless={true}>
                    <tbody>
                        <tr>
                            <td className="align-right">Ref #</td>
                            <td>{value.orderGroupId}</td>
                        </tr>

                        <tr>
                            <td className="align-right">Order Type</td>
                            <td>{value.type}</td>
                        </tr>

                        <tr>
                            <td className="align-right">Coin Pair</td>
                            <td>{value.coinPair}</td>
                        </tr>
                        
                        { value.status !== orderStates.CLOSE && 
                            <tr>
                                <td className="align-right">Status</td>
                                <td>{value.status}</td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </Card.Body>

            <Card.Footer className="order-actions">
                { value.status !== orderStates.CLOSE && 
                    <button type="button" className="btn btn-cancel-order" onClick={(e) => {this.handleCancelOrder(e, value.orderId, value.type)}}>
                        Cancel Order
                    </button>
                }

                { value.status === orderStates.CLOSE && 
                    <span className="btn btn-closed">CLOSED</span>
                }
            </Card.Footer>
        </Card>
    )

    renderTakeProfitOrderDetails = (feed, value, key) => {
        let profitOrLoss = ''
        if (value.status === orderStates.CLOSE && 
            isNotNull(value.buyPrice) && 
            isNotNull(value.closePrice)) {
                profitOrLoss = (parseFloat(value.closePrice) - parseFloat(value.buyPrice)) / parseFloat(value.buyPrice)
                profitOrLoss *= 100
        }

        return (
            <div key={key}>
                <Card>
                    <Card.Body>
                        <h3>Trade Order Details</h3>  
                        <Table borderless={true}>
                            <tbody>
                                <tr>
                                    <td className="align-right">Ref #</td>
                                    <td>{value.orderGroupId}</td>
                                </tr>
        
                                <tr>
                                    <td className="align-right">Coin Pair</td>
                                    <td>{value.coinPair}</td>
                                </tr>
        
                                <tr>
                                    <td className="align-right">Exchange</td>
                                    <td>{value.orderExchange === 'binance' ? 'Binance' : 'Binance Futures'}</td>
                                </tr>
        
                                <tr>
                                    <td className="align-right">Buy Price</td>
                                    <td>{value.buyPrice}</td>
                                </tr>
        
                                { value.status !== 'CLOSE' && value.orderExchange !== orderExchanges.BINANCE_FUTURES && 
                                    <>
                                        <tr>
                                            <td className="align-right">Remaining Coin(s)</td>
                                            <td>{`${value.unSoldCoins} ${value.coinPair.substring(value.coinPair.indexOf('/') + 1)}`}</td>
                                        </tr>

                                        <tr>
                                            <td className="align-right">Profit/Loss %</td>
                                            <td>
                                                <span id={`order-${value.orderId}-${value.orderExchange}-pnl`}></span>
                                            </td>
                                        </tr>
                                    </>
                                }
        
                                { value.status === 'CLOSE' && 
                                    <>
                                        <tr>
                                            <td className="align-right">Close Price</td>
                                            <td>{value.closePrice}</td>
                                        </tr>

                                        <tr>
                                            <td className="align-right">Closed At</td>
                                            <td>
                                                <Moment format="MMM D, YYYY HH:mm" >
                                                    {value.closedAt}
                                                </Moment> UTC
                                            </td>
                                        </tr>

                                        { value.orderExchange !== orderExchanges.BINANCE_FUTURES && 
                                            <tr>
                                                <td className="align-right">Profit/Loss %</td>
                                                <td>
                                                    <span>
                                                        { profitOrLoss < 0 
                                                            ? 
                                                                <span style={{
                                                                    backgroundColor: 'rgb(246, 70, 93)', 
                                                                    color: '#fff',
                                                                    padding: '2px 10px',
                                                                    borderRadius: '2px',
                                                                    letterSpacing: '1px',
                                                                    fontWeight: 500
                                                                }}>
                                                                    {profitOrLoss.toFixed(2)}%
                                                                </span> 
                                                            : 
                                                                <span style={{
                                                                    backgroundColor: 'rgb(14, 203, 129)',
                                                                    color: '#fff',
                                                                    padding: '2px 10px',
                                                                    borderRadius: '2px',
                                                                    letterSpacing: '1px',
                                                                    fontWeight: 500
                                                                }}>
                                                                    +{profitOrLoss.toFixed(2)}%
                                                                </span>
                                                        }
                                                    </span>
                                                </td>
                                            </tr>
                                        }
                                    </>
                                }
                            </tbody>
                        </Table>
                    </Card.Body>
                    <Card.Footer className="order-actions">                    
                        { value.status !== orderStates.CLOSE && 
                            <>
                                { value.orderExchange === orderExchanges.BINANCE && 
                                    <>
                                        <button type="button" className="btn btn-sell-order" onClick={(e) => {this.handlePanicExitOrder(e, feed, value.orderId, value.type)}}>
                                            Sell Coins Now
                                        </button>
                                    </>
                                }

                                { value.orderExchange === orderExchanges.BINANCE && 
                                    <>
                                        <button type="button" className="btn btn-cancel-order" onClick={(e) => {this.handleCancelOrder(e, value.orderId, value.type)}}>
                                            Keep My Coins
                                        </button>
                                    </>
                                }

                                { value.orderExchange === orderExchanges.BINANCE_FUTURES && 
                                    <>
                                        <button type="button" className="btn btn-cancel-order" onClick={(e) => {this.handleClosePosition(e, feed, value.orderId, value.type)}}>
                                            Close My Position
                                        </button>
                                    </>
                                }
                            </>
                        }

                        { value.status === orderStates.CLOSE && 
                            <span className="btn btn-closed">CLOSED</span>
                        }
                    </Card.Footer>
                </Card>     
                
                { value.status !== 'CLOSE' && 
                    <p className="note-text">Note: <em>Clicking 'Sell Coins Now' will execute a 100% market sell order based on the trade details shown above</em></p>
                }
            </div>
        )
    }

    renderOrderDetails = () => {
        const { signalFeed, orderDetails } = this.state
        if (isNotNull(orderDetails)) {
            const { orderLines } = orderDetails
            if (isNotNull(orderLines)) {
                if (orderLines.length > 0) {                    
                    return _.map(orderLines, (value, key) => {
                        if (isNotNull(value)) {
                            if (value.type === orderTypes.STOP_BUY_ORDER)
                                return this.renderStopBuyOrderDetails(value, key)
                            else
                                return this.renderTakeProfitOrderDetails(signalFeed, value, key)
                        }

                        return null
                    })
                } else {
                    return this.renderNoOrderFound()
                }
            }
        }
    }

    renderNoOrderFound = (notificationId) => {        
        const { eventHandlers } = this.props
    
        return (        
            <Card>
                <Card.Body>
                    <h3>Trade Order Details</h3>  
                    <p style={{ marginBottom: 0, textAlign: 'center', padding: '25px' }}>We cannot find any active trades to close.</p>
                </Card.Body>
                <Card.Footer className="order-actions">
                    <button type="button" className="btn btn-neutral-order" onClick={(e) => {eventHandlers.close(e)}}>
                        Ok
                    </button>
                </Card.Footer>
            </Card>
        )
    }

    renderLoader = () => {
        return (
            <>
                <div style={{ width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="pf-spinner xs" style={{ marginTop: '-48px', marginRight: '45px' }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            </>
        )
    }

    render() {
        const { isLoading } = this.state

        return (
            <>
                { isLoading && 
                    <>
                        {this.renderLoader()}
                    </>
                }

                { !isLoading && 
                    <>
                        {this.renderTextContent()}
                        {this.renderSignalInfo()}
                        {this.renderOrderDetails()}
                    </>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    const { openOrderDetails } = state
    return {
        openOrderDetails
    }
}

const connectedReviewClosedOrderDetails = connect(mapStateToProps)(ReviewClosedOrderDetails)
export { connectedReviewClosedOrderDetails as ReviewClosedOrderDetails }