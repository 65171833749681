import { useEffect, useState } from 'react'
import moment from 'moment'

export const SixMonthsOfferTimer = (props) => {
    let [remainingDays, setRemainingDays] = useState('')
    let [remainingHours, setRemainingHours] = useState('')
    let [remainingMinutes, setRemainingMinutes] = useState('')
    let [remainingSeconds, setRemainingSeconds] = useState('')

    let expiry = props.validUntil.format('MM/DD/yyyy h:mm:ss')

    const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

    let today = new Date(),
        dd = String(today.getDate()).padStart(2, "0"),
        mm = String(today.getMonth() + 1).padStart(2, "0"),
        yyyy = today.getFullYear(),
        nextYear = yyyy + 1,
        dayMonth = moment(expiry).format('MM/DD/')

    today = mm + "/" + dd + "/" + yyyy
    if (today > expiry) {
        expiry = dayMonth + nextYear
    }

    const countDown = new Date(expiry).getTime(),
        x = setInterval(function() {    
            const now = new Date().getTime(),
                distance = countDown - now

            setRemainingDays(Math.floor(distance / (day)))
            setRemainingHours(Math.floor((distance % (day)) / (hour)))
            setRemainingMinutes(Math.floor((distance % (hour)) / (minute)))
            setRemainingSeconds(Math.floor((distance % (minute)) / second))

            if (distance < 0) {
                clearInterval(x)
            }
            //seconds
        }, 1000)

    return (
        <>
            <div className='price-offer'>
                <span className='crashed-out-price'>$2,800</span>
                <span className='new-price'>$899</span>
            </div>
            <div className='discount-tag'>
                <span>SAVE $1,900</span>
            </div>
            <div className='price-increase'>This price ends in</div>
            <div className='offer-validity-timer'>
                <ul>
                    <li>
                        <span id='cta-d' className='counter'>{parseInt(remainingDays) < 10 ? '0': ''}{remainingDays}</span>
                        <small>Days</small>
                    </li>
                    <li>
                        <span id='cta-h' className='counter'>{parseInt(remainingHours) < 10 ? '0': ''}{remainingHours}</span>
                        <small>Hours</small>
                    </li>
                    <li>
                        <span id='cta-m' className='counter'>{parseInt(remainingMinutes) < 10 ? '0': ''}{remainingMinutes}</span>
                        <small>Minutes</small>
                    </li>
                    <li>
                        <span id='cta-s' className='counter'>{parseInt(remainingSeconds) < 10 ? '0': ''}{remainingSeconds}</span>
                        <small>Seconds</small>
                    </li>
                </ul>     
            </div>
        </>
    )
}