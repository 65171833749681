import styled from 'styled-components';

export const WalletBalance = styled.span`
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
        text-overflow: clip;
        white-space: normal;
        word-break: break-all;
    }
`;

export const WalletCoin = styled.h3`
    font-size: 16px;
    color: #778698;
    margin-bottom: 0;
    margin-left: 7px;
`;

export const WalletCoinWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .wallet-item-logo {
        width: 25px;
    }
`;

export const WalletButton = styled.button`

    background-color: #16B46C !important;
    color: #fff !important;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    border: 1px solid #02a73b !important;
    font-size: 15px !important;
    padding: 6px 15px !important;
    margin-left: 0;
    border-radius: 0 !important;

    &:focus {
        box-shadow: none;
        outline: none;
    }
`;

export const WalletButtonWrapper = styled.div`
    padding-top: 10px;
    border-top: 1px solid #495b6d;
    width: 100%;
`;

export const WalletCurrency = styled.div`
    width: 100%;
    text-align: center;
    padding-bottom: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
    }

    ${WalletCoinWrapper} {
        padding-bottom: 10px;
        border-bottom: 1px solid #192431;
    }

    .balance-text {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;

        &.first {
            padding-top: 10px;
        }

        ${WalletBalance} {
            font-size: 15px;

            .coinName {
                margin-left: 10px;
                font-size: 15px;
                color: #778698;
            }
        }

        .bal-usdt {
            font-size: 14px !important;
            color: #98a9be;
            margin-top: 0 !important;
        }
    }
`;

export const Wallet = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    min-height: 115px;
    border: 1px solid #2e3e51;
    overflow: hidden;
    padding: 15px 12px;
    border-radius: 4px;
    background: rgb(19 30 43);

    &.USDT {
        min-height: 95px;
    }
`;


export const WalletItems = styled.li`
    margin-bottom: 10px;

    &:last-child {
        padding-bottom: 15px;
    }
`;
