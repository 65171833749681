import React, { useEffect, useState }  from 'react'
import { Carousel } from 'react-bootstrap'
import ReactPlayer from 'react-player/lazy'
import { LearnHowToSectionStyle } from './styles/LearnHowToStyle'
import { mediaDeviceSizes } from '../../constants/mediaQueries'
import { getSize } from '../../utils'

export const LearnHowToSection = () => {
    let [device, setDevice] = useState(null)

    useEffect(() => {
        setDevice(getSize(window.innerWidth))
    }, [])

    return (
        <LearnHowToSectionStyle>
            <div className='container'>
                <h2 className='section-title'>Learn how you can 2x-10x your account 5x faster!</h2>

                { (device === mediaDeviceSizes.xs || device === mediaDeviceSizes.sm) && 
                    <Carousel>
                        <Carousel.Item>
                            <ul className='features-list'>
                                <li className='item'>
                                    <div className='thumbnail'>
                                        <img src='/images/subscriptions/5x-more.png' alt='5x more trading signals' />
                                    </div>
                                    <h5>5x more trading signals</h5>
                                </li>
                                <li className='item'>
                                    <div className='thumbnail'>
                                        <img src='/images/subscriptions/four-targets.png' alt='4x more take-profit targets' />
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='features-list'>
                                <li className='item'>
                                    <div className='thumbnail'>
                                        <img src='/images/subscriptions/four-targets.png' alt='4x more take-profit targets' />
                                    </div>
                                    <h5>4x more take-profit targets</h5>
                                </li>
                                <li className='item'>
                                    <div className='thumbnail'>
                                        <img src='/images/subscriptions/be-sl.png' alt='Break-even Stop-loss' />
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='features-list'>
                                <li className='item'>
                                    <div className='thumbnail'>
                                        <img src='/images/subscriptions/be-sl.png' alt='Break-even Stop-loss' />
                                    </div>
                                    <h5>Break-Even Stop-loss</h5>
                                </li>
                                <li className='item'>
                                    <div className='thumbnail'>
                                        <img src='/images/subscriptions/6x-reward.png' alt='6x the reward profile' />
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='features-list'>
                                <li className='item'>
                                    <div className='thumbnail'>
                                        <img src='/images/subscriptions/6x-reward.png' alt='6x the reward profile' />
                                    </div>
                                    <h5>6x the reward profile</h5>
                                </li>
                                <li className='item'>
                                    <div className='thumbnail'>
                                        <img src='/images/subscriptions/5x-more.png' alt='5x more trading signals' />
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>
                    </Carousel>
                } 

                { !(device === mediaDeviceSizes.xs || device === mediaDeviceSizes.sm) && 
                    <ul className='feature-thumbnails-list'>
                        <li className='feature'>
                            <div className='thumbnail'>
                                <img src='/images/subscriptions/5x-more.png' alt='5x more trading signals' />
                            </div>
                            <h5>5x more trading signals</h5>
                        </li>
                        <li className='feature'>
                            <div className='thumbnail'>
                                <img src='/images/subscriptions/four-targets.png' alt='4x more take-profit targets' />
                            </div>
                            <h5>4x more take-profit targets</h5>
                        </li>
                        <li className='feature'>
                            <div className='thumbnail'>
                                <img src='/images/subscriptions/be-sl.png' alt='Break-even Stop-loss' />
                            </div>
                            <h5>Break-Even Stop-loss</h5>
                        </li>
                        <li className='feature'>
                            <div className='thumbnail'>
                                <img src='/images/subscriptions/6x-reward.png' alt='6x the reward profile' />
                            </div>
                            <h5>6x the reward profile</h5>
                        </li>
                    </ul>
                }

                <div className='video-wrapper'>
                    <ReactPlayer 
                        url={'https://vimeo.com/687041112/e08ccbd713'}
                        controls={true} 
                        width='100%' 
                        volume={0} />
                </div>
            </div>
        </LearnHowToSectionStyle>
    )
}