import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const WhyUpgradeStyle = styled.section`
    position: relative;
    background-color: #162331;
    padding: 40px 0;

    .white-text {
        color: #fff !important;
    }

    .container {        
        .section-title {
            margin-bottom: 50px;

            h2 {
                font-size: 28px;
            }
        }

        h3 {
            font-size: 22px;
        }

        .icon-box {
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }

            .icon-box-col {
                margin-bottom: 40px;

                &:last-child {
                    margin-bottom: 0;
                    margin: auto;
                }

                .icon-img {
                    margin-bottom: 20px;
                }

                .icon-title {
                    margin-bottom: 10px;
                }

                .icon-description {
                    line-height: 1.7;
                    color: #85A1BE;
                }
            }
        }
    }

    ${mediaQueries. mediumDevices} {
        padding-bottom: 100px;

        .container {
            .section-title {
                h2 {
                    font-size: 40px;
                }

                span {
                    font-size: 20px;
                }
            }

            .icon-box {
                display: flex;
                flex-direction: row;
            
                .icon-box-col {
                    width: 50%;
                    padding: 0 25px;
                    margin-bottom: 0;

                    &.one-col {
                        width: 80%;
                    }
                }
            }

            &::after {
                content: '';
                position: absolute;
                background-image: url(/images/subscriptions/IAN.png);
                background-repeat: no-repeat;
                background-size: contain;
                width: 300px;
                height: 90px;
                left: calc(50% - 150px);
                bottom: -35px;
                z-index: 1;
            }
        }
    }

    ${mediaQueries. extraLargeDevices} {
        padding: 60px 0 120px;

        .container {
            max-width: 1300px;

            .icon-box {
                margin-bottom: 60px;

                .icon-box-col {
                    padding: 0 40px;

                    &.one-col {
                        width: 50%;
                    }
                }
            }

            &::after {
                width: 350px;
                height: 105px;
                bottom: -50px;
                left: calc(50% - 175px);
            }
        }
    }
`