import { sidebarActionTypes } from '../CONSTANTS'

export function showSidebar(state = { sidebar: true }, action) {
    switch (action.type) {
        case sidebarActionTypes.SHOW_SIDEBAR:
            return {
                ...state,
                sidebar: action.sidebar
            }
        default:
            return state
    }
}