import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import * as signalR from '@microsoft/signalr'
import _ from 'lodash'
import moment from 'moment'
import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn, faTimes } from '@fortawesome/free-solid-svg-icons'
import { RowBlock, SectionHeader, MessageList } from '../../../styles'
import { apiAuthToken, baseUrl } from '../../../services/CONSTANTS'
import { messagesActions } from '../../../redux/actions'
import { MessagePreview } from './MessagePreview'
import { isNull, isNotNull, onSetNewState } from '../../../utils'

class Messages extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isPro: undefined,
            messageItems: [],
            showPreviewMessage: false,
            selectedMessage: {
                headerPhotoUrl: null,
                title: null,
                htmlContent: null
            },
            announcement: null
        }

        this.mounted = false
        this.msgHubConnection = null
    }

    componentDidMount() {
        this.mounted = true
        
        const { dispatch } = this.props
        dispatch(messagesActions.getList(1, "", ""))
        // if (isNull(announcements.loading)) {
        //     dispatch(announcementActions.getActiveAnnouncement())
        // }
    }

    async componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { isLoading, isPro, messageItems, announcement } = this.state
            const { authentication, announcements, messageBoardItemList } = this.props
            const { user } = authentication

            // if (isLoading && 
            //     isNull(announcement) && 
            //     isNotNull(announcements) && 
            //     !announcements.loading) {
            //         const { active_announcement } = announcements
            //         if (isNotNull(active_announcement) && isNotNull(active_announcement.data)) {
            //             const { data } = active_announcement
            //             if (isNotNull(data)) {
            //                 onSetNewState(this, {
            //                     announcement: {
            //                         actionUrl: data.actionUrl,
            //                         title: data.title,
            //                         htmlBody: data.htmlBody
            //                     }
            //                 })
            //             }
            //         }

            //         onSetNewState(this, {
            //             isLoading: false
            //         })
            //     }

            if (isLoading && 
                isNotNull(messageBoardItemList) && 
                !messageBoardItemList.loading) {
                    const { dataList } = messageBoardItemList
                    if (isNotNull(dataList) && isNotNull(dataList.data)) {
                        const { data } = dataList
                        if (isNotNull(data) && messageItems !== data) {
                            onSetNewState(this, {
                                messageItems: data,
                                isLoading: false
                            })
                        }
                    }
            }

            if (isNotNull(user)) {
                if (isNull(isPro)) {
                    onSetNewState(this, {
                        isPro: user.isPaid
                    })
                }

                if (this.msgHubConnection === null) {
                    await this.initHubConnection()
                }
            }
        }
    }    

    initHubConnection = async () => {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${baseUrl}/messages`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
                accessTokenFactory: () => apiAuthToken
            })
            .configureLogging(signalR.LogLevel.None)
            .withAutomaticReconnect([0,0,10000])
            .build()
        hubConnection.serverTimeoutInMilliseconds = 120000

        this.msgHubConnection = hubConnection
        this.msgHubConnection.onclose(async () => {
            await this.startHubConnection()
        })

        this.msgHubConnection.on("onMessageToAll", (payload) => {
            const { dispatch } = this.props
            let { dataList } = this.props.messageBoardItemList

            if (isNotNull(dataList) && isNotNull(dataList.data)) {
                let { data } = dataList
                if (isNotNull(data)) {
                    let exists = _.find(data, ['id', payload.id])
                    if (!exists) {
                        data.unshift(payload)
                        data = _.orderBy(data, ['dateCreated'], ['desc'])
                        dispatch(messagesActions.updateList(dataList))
                    }
                }
            }
        })
        
        this.msgHubConnection.on("onMessageToPro", (payload) => {
            const { isPro } = this.state
            const { dispatch } = this.props
            let { dataList } = this.props.messageBoardItemList

            if (isPro && isNotNull(dataList) && isNotNull(dataList.data)) {
                let { data } = dataList
                if (isNotNull(data)) {
                    let exists = _.find(data, ['id', payload.id])
                    if (!exists) {
                        data.unshift(payload)
                        data = _.orderBy(data, ['dateCreated'], ['desc'])
                        dispatch(messagesActions.updateList(dataList))
                    }
                }
            }
        })

        this.msgHubConnection.on("onMessageToBasic", (payload) => {            
            const { isPro } = this.state
            const { dispatch } = this.props
            let { dataList } = this.props.messageBoardItemList

            if (!isPro && isNotNull(dataList) && isNotNull(dataList.data)) {
                let { data } = dataList
                if (isNotNull(data)) {
                    let exists = _.find(data, ['id', payload.id])
                    if (!exists) {
                        data.unshift(payload)
                        data = _.orderBy(data, ['dateCreated'], ['desc'])
                        dispatch(messagesActions.updateList(dataList))
                    }
                }
            }
        })

        await this.startHubConnection()
    }

    startHubConnection = async () => {
        try {
            const { user } = this.props.authentication
            await this.msgHubConnection
                .start()
                .catch((err) => {
                    console.log(err)
                })

            if (isNotNull(this.msgHubConnection) &&
                isNotNull(this.msgHubConnection.state) &&
                this.msgHubConnection.state === signalR.HubConnectionState.Connected) {
                this.msgHubConnection.invoke("GetConnectionId", user.userName)
                    .then(function (connectionId) {
                        //console.log('msg connectionId: ', connectionId)
                    })
                    .catch(function () {
                        //console.log(err)
                    })
            }
        } catch (err) {
            //console.log(err)

            if (isNotNull(this.msgHubConnection) &&
                isNotNull(this.msgHubConnection.state) &&
                this.msgHubConnection.state === signalR.HubConnectionState.Disconnected) {
                // restart connection
                setTimeout(async () =>
                    await this.startHubConnection(), 3000)
            }
        }
    }

    onCreateMarkupHandler = (htmlBody) => ({ __html: `${htmlBody}` })

    onActionUrlHandler = (actionUrl) => window.open(actionUrl, '_blank')

    handleSelectMessage = (e, msg) => {
        e.preventDefault()

        let selectedMessage = {
            type: msg.type,
            headerPhotoUrl: msg.headerPhotoUrl,
            title: msg.title,
            shortDescription: msg.shortDescription,
            htmlContent: msg.htmlContent,
            dateCreated: msg.dateCreated
        }

        onSetNewState(this, {
            selectedMessage,
            showPreviewMessage: true
        })
    }

    handleHideMessagePreview = (e) => {
        e.preventDefault()

        let selectedMessage = {
            headerPhotoUrl: null,
            title: null,
            shortDescription: null,
            htmlContent: null,
            dateCreated: null
        }

        onSetNewState(this, {
            selectedMessage,
            showPreviewMessage: false
        })
    }

    renderLoader = () => (
        <>
            <div style={{ width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0 }}>
                <div className="pf-spinner xs" style={{ marginTop: '-48px', marginRight: '45px' }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        </> 
    )

    renderItems = () => {
        let { messageItems } = this.state
        if (isNotNull(messageItems)) {
            if (messageItems.length > 0) {
                messageItems = _.orderBy(messageItems, ['dateCreated'], ['desc'])
                messageItems = _.take(messageItems, 3)
                const mappedItems = _.map(messageItems, (item, key) => {                
                    let messageType
                    let typeInText
                    switch(item.type) {
                        case 0: {
                            messageType = 'marketanalysis'
                            typeInText = 'Market Analysis'
                            break
                        }
                        case 1:
                        default: {
                            messageType = 'general'
                            typeInText = 'General'
                            break
                        }
                        case 2: {
                            messageType = 'promotions'
                            typeInText = 'Promotions'
                            break
                        }
                    }
    
                    return (    
                        <li key={key}>        
                            <div className='msg-wrapper' onClick={(e) => this.handleSelectMessage(e, item)}>
                                <div className='msg-thumbnail'>
                                    <img src={`/images/layout/def-tn-${messageType}.jpg`} />
                                </div>
                                <div className='msg-txt-content'>                                    
                                    <h5 className='header-texts'>{item.title}</h5>
                                    <div className='stamp'><small className='msg-ts'>{moment(item.dateCreated).format('LL')}</small> <span className='dot'></span> <small className={`msg-type ${messageType}`}>{typeInText}</small></div>
                                    <p>{item.shortDescription}</p>
                                </div>
                            </div>
                        </li>
                    )
                })
    
                return mappedItems
            }
            else 
                return (<li className='empty'><p>No result</p></li>)
        }
    }

    render() {
        const { ...data } = this.state

        return (
            <>
                <RowBlock className='as-block'>
                    <SectionHeader>
                        <h2 className='section-title'>
                            <FontAwesomeIcon icon={faBullhorn} className='icon' />
                            <span className='text'>Latest Updates</span>
                        </h2>
                        <div className='section-actions'>
                            <Link to="/messages/board">View all</Link>
                        </div>
                    </SectionHeader>
                    
                    <MessageList theme="dark" id="tour-msg-board">
                        { data.isLoading && this.renderLoader() }
                        { !data.isLoading && this.renderItems() }

                        {/* { !data.isLoading && isNotNull(data.announcement) &&
                            <li className='msg-board-item'>
                                <div className={`message-wrapper ${isNotNull(data.announcement.actionUrl) ? '': 'full'}`}>
                                    <h5 className='message-title'>{data.announcement.title}</h5>
                                    <div className='text-wrapper' dangerouslySetInnerHTML={this.onCreateMarkupHandler(data.announcement.htmlBody)}></div>
                                </div>
                                
                                { isNotNull(data.announcement.actionUrl) && 
                                    <div className='message-action'>
                                        <SafeAnchor href={data.announcement.actionUrl} target="_blank" rel="noreferrer">Learn more</SafeAnchor>
                                    </div>
                                }
                            </li>
                        } */}

                        {/* <li className='msg-board-item'>
                            <div className='message-wrapper'>
                                <h5 className='message-title'>New Net Peak Gains Calculator + Huge Discounts!</h5>
                                <div className='text-wrapper'>
                                    <p>Behold the Net Peak Gains calculator! Now you can see exactly how big the trading opportunities are even during choppy markets! This is why we're loved by traders all around the world.</p>
                                    <p>To celebrate we're giving away some huge discounts so you can get in on the action:</p>
                                    <ul className='no-list-style'>
                                        <li>1 - $200 (25%) off a quarterly plan by using coupon code <strong>PROFIT-PIE</strong>.</li>
                                        <li>2 - $1200 (40%) off a yearly plan using coupon code <strong>HUGE-PROFIT-PIE</strong></li>
                                    </ul>
                                    <p>During checkout simply click '<strong>ENTER-PROMOTIONAL-CODE</strong>' and add your chosen discount code.</p>
                                    <p>Existing members, please submit a support ticket if you wish to add more time to your subscription using these special offer prices.</p>
                                </div>
                            </div>
                            <div className='message-action'>
                                <SafeAnchor href="https://www.profitfarmers.com/blog/net-peak-gains-calculation/" target="_blank" rel="noreferrer">Learn more</SafeAnchor>
                            </div>
                        </li> */}

                        {/* <li className='msg-board-item'>
                            <div className='message-wrapper'>
                                <h5 className='message-title'>CASE STUDY: How ProfitFarmers Helped a Typical Family Man Grow his Account +15% During the Worst Bear Market in History</h5>
                                <div className='text-wrapper'>
                                    <p>Our latest case study is up! Here's what you'll take away from reading it:</p>
                                    <ul className='bullet-list'>
                                        <li>How you can trade profitably even in a bear market</li>
                                        <li>The power of using high quality trading software and tools</li>
                                        <li>Be inspired by an everyday family man crushing the crypto bears single handedly</li>
                                        <li>How you can make your own trading strategy (read exactly how Kingston achieved his gains!)</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='message-action'>
                                <SafeAnchor href="https://www.profitfarmers.com/case-studies/kingston-arthur/" target="_blank" rel="noreferrer">Read more</SafeAnchor>
                            </div>
                        </li> */}

                        {/*<li className='msg-board-item'>
                            <div className='message-wrapper full'>
                                <h5 className='message-title'>ALERT- AltCoins that can Move 500%-1000% in the Coming Weeks - ONLY for Copy-Trading Members</h5>
                                <div className='text-wrapper'>
                                    <p>Time to make some serious money! Make sure you've connected your Binance.com account and activated your copy-trading features, because the mad scientists in our expert trading team think they've found something. The anti-bearmarket medicine you've been searching for is close at hand as some coins are warming up for explosive moves. An early entry may help you turn a tiny 4 figure investment into 6 figure profits...BUT we will only be sharing this with our active users. Complete your account setup to be eligible and make sure you keep an eye on your EMAIL as that's where we will be sending all the juicy details.</p>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <small>Date Posted: 2022-06-03</small>
                                </div>
                            </div>
                        </li> */}
                    </MessageList>
                </RowBlock>

                <Modal
                    className='generic-modal message-preview'
                    tabIndex="-1"
                    role="dialog" 
                    aria-hidden="true"
                    show={data.showPreviewMessage} 
                    backdrop="static" 
                    keyboard={false} 
                    centered>
                        { isNotNull(data.selectedMessage.htmlContent) && 
                            <Modal.Body>
                                <button 
                                    type="button" 
                                    className='custom-close-btn'
                                    onClick={(e) => this.handleHideMessagePreview(e)}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>

                                <MessagePreview data={data.selectedMessage} />
                            </Modal.Body>
                        }
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    const { authentication, announcements, messageBoardItemList } = state
    return {
        authentication,
        announcements,
        messageBoardItemList
    }
}

const connectedMessages = connect(mapStateToProps)(Messages)
export { connectedMessages as Messages }