import { endpoints, baseUrl } from './CONSTANTS'
import { authServices } from './authService'

const { v3 } = endpoints

function getList(page, searchKeyword, selectedType) {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    try {
        return fetch(`${baseUrl}${v3.system.messages.getList(page, searchKeyword, selectedType)}`, requestOpts)
            .then(handleResponse)
            .then(response => {
                return Promise.resolve(response)
            })
            .catch(error => Promise.reject(error))
    } catch(e) {
        return Promise.reject(e)
    }
}

function addHeaderPhoto(data, fileName) {
    var formData = new FormData()
    formData.append('file', data, fileName)

    const requestOpts = {
        method: 'POST',
        credentials: 'include',
        body: formData
    }

    return fetch(`${baseUrl}${v3.system.messages.addHeaderPhoto}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function replaceHeaderPhoto(data, fileName, oldFileUrl) {
    var formData = new FormData()
    formData.append('file', data, fileName)
    formData.append('oldFileUrl', oldFileUrl)

    const requestOpts = {
        method: 'POST',
        credentials: 'include',
        body: formData
    }

    return fetch(`${baseUrl}${v3.system.messages.replaceHeaderPhoto}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function saveMessage(data) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(data)
    }

    return fetch(`${baseUrl}${v3.system.messages.save}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

function editMessage(data) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(data)
    }

    return fetch(`${baseUrl}${v3.system.messages.edit}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        if (res.status === 401)
            authServices.logout()
        else
            return data
    })
}

export const messagesServices = {
    getList,
    addHeaderPhoto,
    replaceHeaderPhoto,
    saveMessage,
    editMessage
}