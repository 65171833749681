import React, { useEffect, useState }  from 'react'
import { Tabs, Tab, SafeAnchor } from 'react-bootstrap'
import _ from 'lodash'
import { CheckoutSectionStyle } from './styles/CheckoutStyle'
import { GenericCheckoutForm } from '../../components'
import { mediaDeviceSizes } from '../../constants/mediaQueries'
import { isNotNull, getSize } from '../../utils'

export const CheckoutSection = (props) => {
    const [selected, setSelection] = useState('quarterly')
    const [list, setList] = useState([])
    let [device, setDevice] = useState(null)

    useEffect(() => {
        setDevice(getSize(window.innerWidth))

        if (isNotNull(props.subscriptionPlans)) {
            let plansList = [{
                id: '0',
                name: 'Monthly',
                val: 'monthly',
                price: 399,
                refKey: ''
            }, {
                id: '1',
                name: 'Quarterly',
                val: 'quarterly',
                originalPrice: 1197,
                price: 799,
                refKey: ''
            }, {
                id: '2',
                name: 'Yearly',
                val: 'yearly',
                originalPrice: 4788,
                price: 2999,
                refKey: ''
            }, {
                id: '3',
                name: `Lifetime`,
                val: 'lifetime',
                price: 5999,
                refKey: ''
            }]

            _.forEach(props.subscriptionPlans, p => {
                if (p.normalizedName.indexOf("COMBINATION") >= 0) {
                    if (p.normalizedName.indexOf("MONTHLY") >= 0) {
                        plansList[0].id = p.id
                        plansList[0].price = p.amount
                        plansList[0].refKey = p.refKey
                    } else if (p.normalizedName.indexOf("QUARTERLY") >= 0) {
                        plansList[1].id = p.id
                        plansList[1].price = p.amount
                        plansList[1].refKey = p.refKey
                    } else if (p.normalizedName.indexOf("YEARLY") >= 0) {
                        plansList[2].id = p.id
                        plansList[2].price = p.amount
                        plansList[2].refKey = p.refKey
                    } else {
                        plansList[3].id = p.id
                        plansList[3].price = p.amount
                        plansList[3].refKey = p.refKey
                    }
                }
            })

            setList(plansList)
        }
    }, [])

    const renderSubscriptionPlans = () => {
        if (isNotNull(list)) {
            let mappedList = _.map(list, (item, key) => {
                return (
                    <Tab eventKey={item.val} title={`${item.name}${!(device === mediaDeviceSizes.xs || device === mediaDeviceSizes.sm) ? ' Plan' : '' }`} key={key}>
                        <div className='pricing-snippet'>
                            <ul className='price-info'>                                                             
                                <li className='original-price'>
                                    { !(item.val === 'monthly' || item.val === 'lifetime') &&                                      
                                        <span>${item.originalPrice.toLocaleString('us-US', { type: 'currency', currency: 'USD' })}</span>
                                    }
                                </li>

                                <li className='discounted-price'>
                                    <span>${item.price.toLocaleString('us-US', { type: 'currency', currency: 'USD' })}</span>
                                </li>
                                
                                <li className={(item.val === 'quarterly' || item.val === 'yearly' || item.val === 'lifetime') ? `tag ${item.val}`: ''}>
                                    <span className='discount-tag'></span>
                                </li>
                            </ul>

                            { item.val === 'quarterly' && 
                                <p className='discount-tag'>Buy 2 months get 1 free!</p>
                            }

                            <div className='checkout-btn'>
                                <GenericCheckoutForm 
                                    userInfo={props.userInfo}
                                    subscriptionInfo={item} />

                                { item.val !== 'lifetime' && 
                                    <p className='help-text'>
                                        <small>*Billed {item.name} | cancel anytime</small>
                                    </p>
                                }
                            </div>
                        </div>
                    </Tab>
                )
            })

            return mappedList
        }
    }

    return (
        <CheckoutSectionStyle className={props.className}>
            <div className='container'>
                <div className='section-header'>
                    <h2 className='section-title'>Choose Your Subscription</h2>
                    <p>All the Signals and Tools You Need To Become a Profitable Trader</p>
                </div>

                <div className='price-details'>
                    <Tabs 
                        id='plans-tab' 
                        activeKey={selected} 
                        onSelect={(k) => setSelection(k)}>
                        {renderSubscriptionPlans()}
                    </Tabs>

                    <p className='note'>Note: Crypto payments are welcome, please <SafeAnchor href='https://pro-support.profitfarmers.com/article/254-contacting-customer-support'>contact our support team</SafeAnchor> directly to organize this.</p>
                </div>
            </div>
        </CheckoutSectionStyle>
    )
}