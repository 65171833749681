import styled from 'styled-components';

export const DatePickerLabel = styled.label`
    color: #ced0dd;
`;

export const DatePickerDivider = styled.div`
    color: #cdcfdc;
    margin: 0 10px;
    background: #cdcfdc;
    height: 2px;
    width: 15px;
`;

export const DatePickerStart = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const DatePickerContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .react-datepicker__input-container {
        input {
            width: 120px;
            background-color: #27323e;
            color: #ced0dd;
            display: inline-block;
            font-weight: 400;
            text-align: center;
            border: 1px solid #2e3e51;
            padding: .375rem .75rem;
            font-size: 14px;
            border-radius: 2px;

            &:disabled {
                cursor: not-allowed;
                color: #cdcfdc5e;
                background: #475973a3;
                border: 1px solid #57709463;
            }
        }
    }

`;

export const DatePickerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;


export const DropDownItem = styled.button`

`;

export const PeriodRangeWrapper = styled.div`
    .dropdown {
        .dropdown-toggle {
            color: rgb(206,208,221) !important;
            background-color: #202A35 !important;
            font-size: 14px !important;
            border-width: 1px !important;
            border-style: solid !important;
            border-color: #2E3E51 !important;
            border-image: initial !important;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 4px;
            padding: 8px 10px;

            &:focus,
            &:active {
                outline: none;
                box-shadow: none !important;
            }
        }

        .dropdown-menu {
            padding: 10px;
            background: #202a35;
            border: 1px solid #141b23;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .strategy-lists {
                height: 65vh;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 6px;
                }

                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #94a7c1;
                    outline: 1px solid #383e4e;
                    border-radius: 10px;
                }
            }

            

            &.show {
                display: flex;
            }

            .strategy-filter {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: #ced0dd;
                width: 100%;
                margin-bottom: 15px;
                font-size: 14px;

                &:last-child {
                    margin-bottom: 0;
                }

                input {
                    padding: 0;
                    height: initial;
                    width: initial;
                    margin-bottom: 0;
                    display: none;
                    cursor: pointer;
                }

                label {
                    position: relative;
                    cursor: pointer;
                    margin-bottom: 0;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    &::before {
                        content:'';
                        -webkit-appearance: none;
                        background-color: transparent;
                        border: 2px solid #ced0dd;
                        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                        padding: 10px;
                        display: inline-block;
                        position: relative;
                        vertical-align: middle;
                        cursor: pointer;
                        margin-right: 10px;
                    }
                }

                input:checked + label:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 4px;
                    left: 9px;
                    width: 6px;
                    height: 14px;
                    border: solid #ced0dd;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }
            }

            .dropdown-item {
                background-color: #0e1824 !important;
                color: #ced0dd !important;
                display: inline-block;
                font-weight: 400;
                text-align: center;
                padding: .375rem .75rem;
                width: 100%;
                font-size: 14px;
                margin-bottom: 10px;
                border-radius: 2px;

                &.submit {
                    background-color: #15BD6F !important;
                    border: none !important;
                    color: #fff !important;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    @media screen and (min-width: 320px) and (max-width: 766px) {
        margin-bottom: 16px;
        width: 100%;

        .dropdown {
            .dropdown-toggle {
                // width: 150px !important;
            }
        }
    }

    @media screen and (min-width: 767px) and (max-width: 1199px) {
        margin-bottom: 10px;

        .dropdown {
            .dropdown-toggle {
                // width: 170px !important;
            }
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        .dropdown {
            .dropdown-toggle {
                // width: 135px !important;
                font-size: 13px !important;
            }
        }
    }

    @media screen and (min-width: 1441px) and (max-width: 1650px) {
        .dropdown {
            .dropdown-toggle {
                // width: 175px !important;
                font-size: 14px !important;
            }
        }
    }

    @media screen and (min-width: 1651px) and (max-width: 1750px) {
        .dropdown {
            .dropdown-toggle {
                // width: 196px !important;
                font-size: 14px !important;
            }
        }
    }
`;
