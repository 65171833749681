import Dropzone from 'react-dropzone'
import Cropper from 'react-easy-crop'
import { Slider } from '@material-ui/core'
import { Button } from 'react-bootstrap'
import iziToast from 'izitoast'
import getCroppedImg from './cropImage'
import {
    DropZoneWrapper, CropWrapper, CropContainer, SliderControl,
    UpdateProfile
} from './styled_components'
import { isNull } from '../../../../utils'

export const ProfileImageUpdate = (props) => {
    const $this = props.self
    const {
        isImageSave, imageSrc, imgExt,
        crop, zoom, aspect, setCroppedAreaPixels } = props.state

    const onDrop = () => {
        const file = document.getElementById('inputUploader').files[0]
        const fileName = document.getElementById('inputUploader').files[0].name
        if (isNull(file) || isNull(fileName))
            alert("NO DATA!")
        else
            $this.setState({
                imageFileName: document.getElementById('inputUploader').files[0].name,
                imgExt: document.getElementById('inputUploader').files[0].type,
                isImageSave: false
            })

        const reader = new FileReader()
        let imgResult = ''

        reader.addEventListener("load", () => {
            // convert image file to base64 string
            imgResult = reader.result
            $this.setState({ imageSrc: imgResult })
        }, false)

        if (file)
            reader.readAsDataURL(file)
    }

    const onDropReject = () => {
        iziToast.warning({
            position: 'topRight',
            title: 'Warning',
            message: 'Maximum file upload size is 700KB',
            displayMode: 1,
        })
        $this.setState({ isImageSave: true })
    }

    const onCropChange = crop => $this.setState({ crop })

    const onZoomChange = zoom => $this.setState({ zoom })

    const onCropComplete = (croppedArea, croppedAreaPixels) => $this.setState({
        setCroppedAreaPixels: croppedAreaPixels
    })

    const onSubmitCroppedImage = async () => {
        try {
            let { croppedImage } = props.state
            croppedImage = await getCroppedImg(
                imageSrc,
                setCroppedAreaPixels,
                0,
                imgExt
            )
            $this.setState({
                setCroppedImage: croppedImage,
                showUploadModal: false,
                isImageSave: true
            })
            props.uploadAvatar()
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <Dropzone onDrop={() => onDrop()} onDropRejected={() => onDropReject()} accept="image/png,image/jpg,image/jpeg" noDrag preventDropOnDocument maxSize={700000}>
                {({ getRootProps, getInputProps }) => (
                    <DropZoneWrapper>
                        <div {...getRootProps()}>
                            <input {...getInputProps({ className: 'dropzone' })} id="inputUploader" />
                            <p>Browse File</p>
                        </div>
                    </DropZoneWrapper>
                )}
            </Dropzone>
            <CropWrapper className={`${isImageSave === true ? 'd-none' : 'd-block'}`}>
                <CropContainer >
                    <Cropper
                        image={imageSrc}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspect}
                        cropShape="round"
                        showGrid={false}
                        onCropChange={(e) => onCropChange(e)}
                        onCropComplete={(e, val) => onCropComplete(e, val)}
                        onZoomChange={(e) => onZoomChange(e)}
                    />
                </CropContainer>
                <SliderControl className="slider-control">
                    <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => onZoomChange(zoom)}
                    />
                </SliderControl>
            </CropWrapper>
            <UpdateProfile className={`${isImageSave === true ? 'd-none' : 'd-block'}`}>
                <Button variant="primary" onClick={() => onSubmitCroppedImage()} id="save-prof" disabled={isImageSave}>Save Avatar</Button>
            </UpdateProfile>
        </>
    )
}