import { walletActionTypes } from '../CONSTANTS'
import { walletServices } from '../../services'

function getBalance() {
    return dispatch => {
        dispatch(request())

        walletServices.getBalance().then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: walletActionTypes.BALANCE_WALLET_REQUEST } }
    function success(balance_wallet) { return { type: walletActionTypes.BALANCE_WALLET_SUCCESS, balance_wallet } }
    function failure(error) { return { type: walletActionTypes.BALANCE_WALLET_FAILURE, error } }
}

function getWallets() {
    return dispatch => {
        dispatch(request())

        walletServices.getBalance().then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: walletActionTypes.GET_ALL_REQUEST } }
    function success(crypto_wallet) { return { type: walletActionTypes.GET_ALL_SUCCESS, crypto_wallet } }
    function failure(error) { return { type: walletActionTypes.GET_ALL_FAILURE, error } }
}

function getFutureWallets() {
    return dispatch => {
        dispatch(request())

        walletServices.getFutureWallets().then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: walletActionTypes.GET_ALL_FUTURE_REQUEST } }
    function success(future_wallet) { return { type: walletActionTypes.GET_ALL_FUTURE_SUCCESS, future_wallet } }
    function failure(error) { return { type: walletActionTypes.GET_ALL_FUTURE_FAILURE, error } }
}

function getBinance() {
    return dispatch => {
        dispatch(request())

        walletServices.getBinance().then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: walletActionTypes.GET_BINANCE_REQUEST } }
    function success(binance_data) { return { type: walletActionTypes.GET_BINANCE_SUCCESS, binance_data } }
    function failure(error) { return { type: walletActionTypes.GET_BINANCE_FAILURE, error } }
}

function getBinanceFutures() {
    return dispatch => {
        dispatch(request())

        walletServices.getBinanceFuture().then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: walletActionTypes.GET_BINANCE_FUTURE_REQUEST } }
    function success(binance_data) { return { type: walletActionTypes.GET_BINANCE_FUTURE_SUCCESS, binance_data } }
    function failure(error) { return { type: walletActionTypes.GET_BINANCE_FUTURE_FAILURE, error } }
}

function createMarket(data) {
    return dispatch => {
        dispatch(request())

        walletServices.createMarket(data).then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: walletActionTypes.CREATE_MARKET_REQUEST } }
    function success(response) { return { type: walletActionTypes.CREATE_MARKET_SUCCESS, response } }
    function failure(error) { return { type: walletActionTypes.CREATE_MARKET_FAILURE, error } }
}

function clearWalletPage() {
    return dispatch => dispatch({ type: walletActionTypes.CLEAR_BALANCE_WALLET })
}

function clearWalletSidebar() {
    return dispatch => dispatch({ type: walletActionTypes.CLEAR_WALLET_SIDEBAR })
}

export const walletActions = {
    getBalance,
    getWallets,
    getFutureWallets,
    getBinance,
    getBinanceFutures,
    createMarket,
    clearWalletPage,
    clearWalletSidebar
}
