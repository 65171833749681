import { authActionTypes, userActionTypes } from '../CONSTANTS'

let user = JSON.parse(localStorage.getItem('profitfarmers.user'))
let token = JSON.parse(localStorage.getItem('profitfarmers.token'))

const initialState = (user !== null && typeof user.role !== 'undefined')
    ? { loggedIn: true, loading: false, user, token }
    : { loggedIn: false, loading: false }

export function authReducer(state = initialState, action) {
    switch (action.type) {
        case authActionTypes.LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }
        case authActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                loggedIn: true,
                response: action.response
            }
        case authActionTypes.LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case authActionTypes.LOGOUT:
            return {
                ...state,
                loggedIn: false,
                user: action.user
            }
        case authActionTypes.LOGIN_CLEAR:
            return {
                ...state,
                response: null
            }
        case authActionTypes.AUTO_LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }
        case authActionTypes.AUTO_LOGIN_SUCCESS: 
            return {
                ...state,
                loading: false,
                autoLoggedIn: true,
                response: action.response
            }
        case authActionTypes.AUTO_LOGIN_FAILED:
            return {
                ...state,
                loading: false,
                autoLoggedIn: false,
                error: action.err
            }
        case userActionTypes.GET_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }
        case userActionTypes.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                loggedIn: true,
                user: action.user
            }
        case userActionTypes.GET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case authActionTypes.GET_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }
        case authActionTypes.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                loggedIn: true,
                user: action.user
            }
        case authActionTypes.GET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case authActionTypes.CLEAR_AUTH:
            return {
                state: undefined
            }
        default:
            return state
    }

}

export function emailVerification(state = {}, action) {
    switch(action.type) {
        case authActionTypes.VERIFY_CODE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case authActionTypes.VERIFY_CODE_SUCCESS: 
            return {
                ...state,
                loading: false,
                verificationResult: action.response
            }
        case authActionTypes.VERIFY_CODE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function resendEmailVerification(state = {}, action) {
    switch(action.type) {
        case authActionTypes.RESEND_VERIFICATION_CODE_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case authActionTypes.RESEND_VERIFICATION_CODE_SUCCESS: {
            return {
                ...state,
                loading: false,
                resendCodeResult: action.response
            }
        }
        case authActionTypes.RESEND_VERIFICATION_CODE_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        default: 
            return state
    }
}