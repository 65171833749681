import glamorous from 'glamorous'
import * as glamor from 'glamor'
import { mediaQueries } from '../../constants/mediaQueries'

const zoomInDown = glamor.css.keyframes({
    'from': {
        opacity: 0,
        transform: 'scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0)'
    },
    '60%': {
        opacity: 1,
        transform: 'scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0)'
    }
})

const zoomOut = glamor.css.keyframes({
    'from': {
        opacity: 1
    },
    '50%': {
        opacity: 0,
        transform: 'scale3d(0.3, 0.3, 0.3)'
    },
    'to': {
        opacity: 0
    }
})

const fadeInLeft = glamor.css.keyframes({
    'from': {
        opacity: 0,
        //transform: 'translate3d(-100%, 0, 0)'
    },    
    'to': {
        opacity: 1,
        //transform: 'translate3d(0, 0, 0)'
    }
})

const fadeInRight = glamor.css.keyframes({
    'from': {
        opacity: 0,
        transform: 'translate3d(100%, 0, 0)'
    },
    'to': {
        opacity: 1,
        transform: 'translate3d(0, 0, 0)'
    }
})

export const IntroWrapper = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: '100vh',
    paddingBottom: '100px',

    '& h1': {
        marginTop: '100px',
        marginBottom: '30px',
        color: '#fff'
    },
    
    '& .iframe-wrapper': {
        display: 'block',
        margin: '0 auto 30px',
        borderRadius: '6px',
        outline: '2px solid #29323c',
        backgroundColor: '#080b0e',
        boxShadow: '0 0 20px 12px rgba(0, 0, 0, 0.3)',
        overflow: 'hidden',

        '& iframe': {
            display: 'block',
            width: '100%',     
            backgroundColor: '#080b0e'
        },

        '&.animated': {
            animationFillMode: 'both'
        },
    
        '&.fade-in': {
            animation: `${zoomInDown}`,
            animationDuration: '1s'
        },
    
        '&.fade-out': {
            animation: `${zoomOut}`,
            animationDuration: '1s',
            opacity: 0
        }
    },

    '& p': {
        color: '#abb9c9',
        fontSize: '22px',
        textAlign: 'center'
    },

    '& button': {
        marginTop: 'auto'
    },

    [mediaQueries.extraSmallDevices]: {
        '& h1': {
            marginTop: '110px',
            fontSize: '20px'
        },

        '& .iframe-wrapper': {
            width: '100%',
            height: '202px',

            '& iframe': {
                height: '202px'
            }
        },

        '& p': {
            fontSize: '15px'
        }
    },

    [mediaQueries.mediumDevices]: {
        '& h1': {
            marginBottom: '40px'
        },

        '& .iframe-wrapper': {
            width: '720px',
            height: '406px',

            '& iframe': {
                height: '406px'
            }
        },

        '& p': {
            fontSize: '21px'
        }
    }
})

export const FormBodyContent = glamorous.div({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    padding: '25px 0 35px',
    letterSpacing: '1px',

    '& .graphic': {
        display: 'block',
        textAlign: 'center',
        animation: `${fadeInLeft}`,
        animationDuration: '1s',
        animationFillMode: 'both',
        overflow: 'hidden',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'bottom center',
        width: 'calc(100% + 30px)',
        height: '205px',
        margin: '0 -15px 20px',

        '& img': {
            margin: '25px auto 0',
            width: '100%'
        },
        
        '&.basic-info': {
            backgroundImage: 'url(/images/form-graphics/tab-onboarding-basic-info.png)'
        },

        '&.trading-exp': {
            backgroundImage: 'url(/images/form-graphics/tab-onboarding-trading-exp.png)'
        },

        '&.financial-details': {
            backgroundImage: 'url(/images/form-graphics/tab-onboarding-financial-details.png)'
        },
            
        '&.basic-info,&.trading-exp,&.financial-details': {
            position: 'relative',
            // width: 'calc(100% + 78px)',
            // margin: '-20px -39px 25px',

            '&:before': {
                display: 'block',
                position: 'absolute',
                content: ' ',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: 'url(/images/form-graphics/stars.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'top'
            },

            '&:after': {
                display: 'block',
                position: 'absolute',
                content: ' ',
                left: '-15px',
                right: '-15px',
                bottom: 0,
                height: '100px',
                backgroundImage: 'linear-gradient(to bottom, transparent 0%, #1ea16480 100%)',
                opacity: '0.7'
            }
        },

        '&.with-preview': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 0,
            outline: '8px solid #0a121b',
            backgroundColor: '#080b0e',
            boxShadow: '0 0 20px 12px rgba(0, 0, 0, 0.3)',
            cursor: 'pointer',
            transition: 'transform .5s ease',
            overflow: 'hidden',

            '& img': {
                marginTop: 'auto',
                transform: 'scale(1.4)'
            },

            '&.notransform': {
                '& img': {
                    transform: 'unset'
                }
            },

            '&:hover': {
                transform: 'scale(1.1)'
            }
        },

        '&.video': {
            width: 'calc(100% + 32px)',
            minHeight: '220px',
            margin: '0 -16px 38px',

            '&:hover': {
                transform: 'none'
            }
        }
    },

    '& .form': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        paddingLeft: '25px',

        '&.with-converter': {
            width: 'calc(100% - 360px)'
        },

        '& .form-title': {
            color: '#fff'
        },

        '& .form-instruction': {
            fontSize: '19px'
        },

        '& .welcome-text': {
            fontSize: '20px'
        },

        '& .required': {
            color: '#993956',
            fontSize: '20px'
        },

        '& .detailed-instructions': {
            color: '#e6e9ed',

            '& ol li': {
                marginBottom: '12px',

                '& .highlighted': {
                    color: '#eeb511'
                }
            },

            '& em': {
                color: '#8A9BAE'
            },

            '& .inline-btn': {
                display: 'inline-block',
                padding: '1px 10px',
                backgroundColor: '#205ca7',
                borderRadius: '16px',
                cursor: 'pointer',

                '&:hover': {                    
                    backgroundColor: '#237be7'
                }
            }
        },
        
        '& .form-control': {
            color: '#c8d3e1',
            backgroundColor: '#0E151E',
            border: '1px solid #213247',
            boxShadow: 'none',
            borderRadius: '6px',

            '&::placeholder': {
                color: '#3C4F65'
            }
        },

        '& select.form-control': {
            backgroundColor: '#223346',
            borderColor: '#3C5778',
            borderRadius: '4px',

            '&::placeholder': {
                color: '#78899D'
            }
        },

        '& .form-check': {
            '& input[type="radio"]:checked, input[type="radio"]:not(:checked)': {
                position: 'absolute',
                left: '-9999px'
            },

            '& input[type="radio"]:checked + label, input[type="radio"]:not(:checked) + label': {
                position: 'relative',
                paddingLeft: '28px',
                cursor: 'pointer',
                lineHeight: '20px',
                display: 'inline-block'
            },

            '& input[type="radio"]:checked + label:before, input[type="radio"]:not(:checked) + label:before': {
                content: ' ',
                position: 'absolute',
                left: 0,
                top: 0,
                width: '20px',
                height: '20px',
                border: '1px solid #ddd',
                borderRadius: '100%',
                backgroundColor: '#f6f6f6'
            },

            '& input[type="radio"]:checked + label:after, input[type="radio"]:not(:checked) + label:after': {
                content: ' ',
                width: '12px',
                height: '12px',
                background: '#1ea164',
                position: 'absolute',
                top: '4px',
                left: '4px',
                borderRadius: '100%',
                transition: 'all 0.2s ease'
            },

            '& input[type="radio"]:not(:checked) + label:after': {
                opacity: 0,
                transform: 'scale(0)'
            },

            '& input[type="radio"]:checked + label:after': {
                opacity: 1,
                transform: 'scale(1)'
            }
        },

        '& .form-group': {
            marginBottom: '30px',
            
            '& .form-label': {
                color: '#e1e1e1',
                minHeight: '30px'
            },

            '& .input-wrapper': {
                '&.is-invalid': {
                    '& .form-control, .MuiInputBase-formControl': {
                        borderColor: '#993956'
                    }
                },

                '& .form-text': {
                    color: '#df5481',
                    fontSize: '14px'
                },

                '& .dropdown': {
                    '& .dropdown-toggle': {
                        backgroundColor: '#223346',
                        borderColor: '#3C5778',
                        width: '100%',
                        textAlign: 'left',

                        '& .selected-options': {
                            display: 'block',
                            width: '240px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        },
    
                        '&::after': {
                            position: 'absolute',
                            right: '5px',
                            top: '17px',
                            marginLeft: 'auto'
                        }
                    },

                    '& .dropdown-menu': {
                        backgroundColor: '#0E151E',
                        minWidth: '320px',

                        '& .dropdown-menuitem': {
                            color: '#fff',

                            '& .custom-control-label': {
                                display: 'block',
                                padding: '6px 20px',
                                cursor: 'pointer',

                                '&::before,::after': {
                                    left: '-3px',
                                    top: '9px'
                                }
                            },

                            '& .custom-checkbox': {
                                cursor: 'pointer',

                                '& .custom-control-input': {
                                    '&:checked~.custom-control-label::before': {
                                        borderColor: '#23d280',
                                        backgroundColor: '#1ea164',
                                        boxShadow: '0 0 0 2px rgb(35 210 128 / 40%)'
                                    }
                                }
                            },

                            '&:hover': {
                                backgroundColor: '#182536'
                            }
                        }
                    }
                }
            },

            '& .close-ended': {
                justifyContent: 'flex-start'
            },

            '&:last-child': {
                '&.notice-wrapper': {
                    marginTop: 'auto'
                }
            }
        },

        '& .notice': {
            display: 'flex',
            alignItems: 'baseline',
            marginTop: '40px',
            marginBottom: 0,
            fontSize: '13px',
            
            '& .icon': {                
                marginRight: '10px'
            }
        },

        '&.trading-exp': {
            '& .form-group': {
                '& .input-wrapper': {
                    '&.half': {
                        width: '50%'
                    },

                    '&.multi-selection': {
                        width: '320px'
                    }
                }
            }
        }
    },

    '&.api-connection': {
        '& .form': {
            minHeight: 'unset'
        }
    },

    '& .quick-trade-form': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '360px',
        minHeight: '420px',
        paddingTop: '30px',

        '& form': {
            paddingBottom: 0
        },

        '& .wallet-card': {
            position: 'relative',
            displat: 'flex',
            flexDirection: 'column',
            backgroundColor: '#1A2737',
            border: '1px solid #36495D',
            borderRadius: '9px',
            padding: '25px 20px 25px',
            marginBottom: '10px',

            '& .trade-icon': {
                display: 'inline-block',
                position: 'absolute',
                top: '-25px',
                right: 'calc(50% - 24px)',
                paddingTop: '10px',
                zIndex: 1,
                width: '48px',
                height: '48px',
                backgroundColor: '#090f16',
                border: '2px solid #213247',
                color: '#1ea164',
                borderRadius: '24px',
                textAlign: 'center',
                verticalAlign: 'middle',

                '& svg': {
                    width: '24px',
                    height: '24px'
                }
            },

            '& .form-group': {
                marginBottom: '1em',
                
                '& .input-group': {
                    alignItems: 'center'
                },

                '& select.form-control': {
                    backgroundColor: '#223346',
                    borderColor: '#3C5778',
                    color: '#fff',
                    boxShadow: 'none',
                    borderRadius: '4px',

                    '&::placeholder': {
                        color: '#fff'
                    }
                },

                '& .as-label, .form-label': {
                    fontSize: '14px'
                },

                '& .as-value': {
                    marginLeft: 'auto',
                    fontSize: '18px',
                    color: '#fff'
                },

                '& .as-button': {
                    marginLeft: '10px',

                    '& button': {
                        backgroundColor: '#090f16 !important',
                        border: 'none',
                        padding: '1px 9px',
                        minHeight: 'auto',
                        color: '#7d8da0', 

                        '& svg': {
                            width: '13px',
                            height: '13px',
                            verticalAlign: '-1px'
                        },

                        '&:hover': {
                            color: '#f6f6f6'
                        }
                    }
                },
        
                '& .form-control': {
                    color: '#fff',
                    backgroundColor: '#0E151E',
                    border: '1px solid #213247',
                    boxShadow: 'none',
                    letterSpacing: '1px',
                    borderRadius: '4px',
        
                    '&::placeholder': {
                        color: '#3C4F65'
                    },

                    '&.readonly': {
                        backgroundColor: '#1a2737'
                    },

                    '&.amount-to-trade,.amount-to-get': {
                        fontSize: '20px'
                    }
                },

                '& .input-wrapper': {
                    '&.is-invalid': {
                        '& .form-control, .MuiInputBase-formControl': {
                            borderColor: '#993956'
                        }
                    },
    
                    '& .form-text': {
                        color: '#df5481',
                        fontSize: '14px',
                        textAlign: 'center'
                    }
                }
            },

            '& .currency-selection': {
                display: 'flex',
                alignItems: 'center',

                '& .symbol-logo': {
                    marginRight: '10px'
                }
            },

            '& input': {
                outline: 'none',
                fontSize: '28px',
                fontWeight: 600,
                letterSpacing: '1px',
                width: '100%',
                textAlign: 'right'
            },

            '& p': {
                color: '#fff',
                fontSize: '28px',
                fontWeight: 600,
                letterSpacing: '1px',
                marginBottom: 0,
                textAlign: 'right'
            },

            '&.current': {
                '& .currency-selection': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    marginBottom: '15px',

                    '& .symbol-logo': {
                        width: '32px',
                        marginRight: 'auto'
                    },

                    '& .value': {
                        color: '#fff',
                        fontWeight: 500,
                        marginRight: '5px'
                    },

                    '&:last-child': {
                        marginBottom: 0
                    }
                }
            }
        },

        '& button': {
            marginTop: 0,
            border: 'none',
            padding: '5px 10px',
            minHeight: 'auto'
        }
    },

    '& a': {
        color: '#3281e0',

        '&:hover': {
            color: '#519bf5'
        }
    },

    [mediaQueries.extraSmallDevices]: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '0 0 35px',

        '& .graphic': {
            '&.with-preview': {
                width: 'calc(100% + 30px)',
                minHeight: '200px',
                margin: '0 -15px 38px',
            },

            '&.video': {
                width: 'calc(100% + 32px)',
                minHeight: '220px',
                margin: '0 -16px 38px'
            }
        },

        '& .form': {
            width: '100%',
            padding: '0 4px',
            minHeight: '280px',

            '& .form-title': {
                fontSize: '18px',
                fontWeight: 400,
                marginBottom: '10px',
                textAlign: 'center'
            },

            '& .form-instruction': {
                fontSize: '15px',
                fontWeight: 400,
                marginBottom: '20px',
                textAlign: 'center'
            },

            '& .detailed-instructions': {
                fontSize: '14px',

                '& ol': {
                    paddingLeft: '25px'
                }
            },

            '& .welcome-text': {
                textAlign: 'center',
                fontSize: '16px'
            },

            '& .form-group': {
                marginBottom: '25px',

                '& .form-label': {
                    fontSize: '14px'
                },

                '& .close-ended': {
                    justifyContent: 'flex-start'
                }
            },

            '& .form-control': {
                fontSize: '14px'
            },

            '& .notice': {
                marginTop: '10px',
                fontSize: '12px'
            },

            '&.trading-exp': {
                '& .form-group': {
                    '& .input-wrapper': {
                        '&.half': {
                            width: '100%'
                        },
    
                        '&.multi-selection': {
                            width: '100%'
                        }
                    }
                }
            },

            '&.with-converter': {
                width: '100%',
                padding: '60px 0 0'
            }
        },

        '& .quick-trade-form': {
            width: '100%',
            minHeight: '220px'
        },

        '&.completed': {
            flexDirection: 'column-reverse',

            '& .graphic': {
                margin: '50px auto 25px',
                height: 'auto',
                width: '218px'
            },

            '& .form': {
                '& .form-title': {
                    fontSize: '20px',
                    marginBottom: '25px'
                },

                '& .form-instruction': {
                    textAlign: 'center'
                },

                '& button': {
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }
            }
        }
    },

    [mediaQueries.smallDevices]: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '0 0 35px',

        '& .form': {
            width: '90%',
            padding: '0 4px',
            margin: '0 auto',
            minHeight: '280px',

            '& .form-title': {
                fontSize: '18px',
                fontWeight: 400,
                marginBottom: '10px',
                textAlign: 'center'
            },

            '& .form-instruction': {
                fontSize: '15px',
                fontWeight: 400,
                marginBottom: '20px',
                textAlign: 'center'
            },

            '& .detailed-instructions': {
                fontSize: '14px',

                '& ol': {
                    paddingLeft: '25px'
                }
            },

            '& .welcome-text': {
                textAlign: 'center',
                fontSize: '16px'
            },

            '& .form-group': {
                marginBottom: '25px',

                '& .form-label': {
                    fontSize: '14px'
                },

                '& .close-ended': {
                    justifyContent: 'flex-start'
                }
            },

            '& .form-control': {
                fontSize: '14px'
            },

            '& .notice': {
                marginTop: '10px',
                fontSize: '12px'
            },

            '&.trading-exp': {
                '& .form-group': {
                    '& .input-wrapper': {
                        '&.half': {
                            width: '100%'
                        },
    
                        '&.multi-selection': {
                            width: '100%'
                        }
                    }
                }
            },

            '&.with-converter': {
                width: '100%',
                padding: '60px 0 0'
            }
        },

        '& .quick-trade-form': {
            width: '100%',
            minHeight: '220px'
        },

        '&.completed': {
            flexDirection: 'column-reverse',

            '& .graphic': {
                margin: '50px auto 25px',
                height: 'auto',
                width: '218px'
            },

            '& .form': {
                '& .form-title': {
                    fontSize: '20px',
                    marginBottom: '25px'
                },

                '& .form-instruction': {
                    textAlign: 'center'
                },

                '& button': {
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }
            }
        }
    },

    [mediaQueries.mediumDevices]: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '20px 0 10px',

        '& .graphic': {
            width: 'calc(100% + 30px)',
            height: '260px',
            margin: '0 -15px 30px',

            '&.with-preview': {
                width: '631px',
                margin: '50px auto',
                minHeight: '340px'
            }
        },

        '& .form': {
            width: '90%',
            padding: '0 4px',
            margin: '0 auto',

            '& .form-title': {
                fontSize: '26px',
                fontWeight: 400,
                marginBottom: '10px',
                textAlign: 'center'
            },

            '& .form-instruction': {
                marginBottom: '30px',
                textAlign: 'center'
            },

            '& .welcome-text': {
                textAlign: 'center',
                fontSize: '16px'
            },

            '& .form-group': {
                '& .close-ended': {
                    justifyContent: 'flex-start'
                }
            },

            '&.with-converter': {
                width: '100%',
                padding: 0
            }
        },

        '&.with-conversion-tool': {
            flexDirection: 'column-reverse',            

            '& .quick-trade-form': {
                width: '100%',
                minHeight: '220px',
                paddingTop: '60px',
                marginBottom: '30px',

                '& > form': {                    
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-evenly'
                },

                '& .wallet-card': {
                    width: 'calc(50% - 20px)',
                    marginBottom: '20px',
                    padding: '15px',

                    '& .trade-icon': {
                        top: 'calc(50% - 24px)',
                        right: 'auto',
                        left: '-32px',
                        transform: 'rotate(270deg)'
                    }
                },

                '& button': {
                    display: 'block',
                    width: 'auto',
                    margin: '0 auto',
                    padding: '8px 25px'
                }
            },
        },

        '&.completed': {
            flexDirection: 'column-reverse',

            '& .graphic': {
                margin: '100px auto 25px',
                height: 'auto',
                width: '320px'
            },

            '& .form': {
                '& .form-title': {
                    fontSize: '28px',
                    marginBottom: '25px'
                },

                '& .welcome-text': {
                    fontSize: '21px'
                },

                '& button': {
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }
            }
        }
    },

    [mediaQueries.largeDevices]: {
        flexDirection: 'row',
        alignItems: 'center',

        '& .graphic': {
            '&.with-preview': {
                width: '500px'
            },

            '&.video': {
                minHeight: '281px'
            }
        },

        '& .form': {
            width: 'calc(100% - 500px)',

            '& .form-title': {
                fontSize: '22px'
            },

            '& .form-instruction': {
                fontSize: '18px'
            },

            '& .detailed-instructions': {
                fontSize: '15px'
            },

            '&.with-converter': {
                width: 'calc(100% - 300px)',
                padding: '40px 25px 0 0'
            }
        },

        '&.basic-info-content, &.trading-exp-content, &.financial-details-content': {
            flexDirection: 'column',

            '& .form': {
                width: '768px',
                maxWidth: '768px'
            }
        },

        '&.api-connection': {
            padding: '40px 0 0',
            maxWidth: '960px',
            margin: '0 auto',

            '& .form': {
                paddingLeft: '25px'
            }
        },

        '&.with-conversion-tool': {
            flexDirection: 'row',
            padding: 0,

            '& .quick-trade-form': {
                width: '300px',
                margin: '60px auto',

                '& > form': {                    
                    display: 'block',
                },

                '& .wallet-card': {
                    width: '100%',
                    marginBottom: '10px',
                    padding: '15px',

                    '& .trade-icon': {
                        top: '-25px',
                        right: 'calc(50% - 24px)',
                        left: 'auto',
                        transform: 'rotate(0deg)'
                    }
                },

                '& button': {
                    display: 'block',
                    width: 'auto',
                    margin: '0 auto',
                    padding: '8px 25px'
                }
            },
        },

        '&.completed': {
            paddingBottom: '60px',

            '& .graphic': {
                margin: '80px auto 25px'
            }
        },

        '& .quick-trade-form': {
        }
    },

    [mediaQueries.extraLargeDevices]: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '20px 0 100px 0',

        '& .graphic': {            
            width: '326px',
            height: '326px',
            margin: '60px 25px 0 0',
            backgroundSize: 'contain',

            '&.basic-info': {
                backgroundImage: 'url(/images/form-graphics/onboarding-basic-info.png)'
            },

            '&.trading-exp': {
                backgroundImage: 'url(/images/form-graphics/onboarding-trading-exp.png)'
            },

            '&.financial-details': {
                backgroundImage: 'url(/images/form-graphics/onboarding-financial-details.png)'
            },

            '&.basic-info,&.trading-exp,&.financial-details': {
                '&:before,&:after': {
                    display: 'none'
                }
            },

            '&.with-preview': {
                margin: '0 25px 0 0',
                width: '65%',
                minHeight: '326px',
            },

            '&.video': {
                minHeight: '260px',
                height: '260px'
            }
        },

        '& .form': {
            width: 'calc(100% - 326px)',
            //minHeight: '420px',
            paddingLeft: '15px',

            '& .form-title': {
                fontSize: '28px',
                marginBottom: '12px',
                textAlign: 'left'
            },

            '& .form-instruction': {
                textAlign: 'left'
            },

            '&.with-converter': {
                width: 'calc(100% - 360px)',
                padding: 0
            }
        },

        '&.basic-info-content, &.trading-exp-content, &.financial-details-content': {
            flexDirection: 'row',

            '& .form': {
                maxWidth: '690px'
            }
        },

        '&.api-connection': {
            padding: '50px 0'
        },

        '&.with-conversion-tool': {
            padding: '0 0 100px',

            '& .quick-trade-form': {
                paddingTop: '25px',
                margin: '0 auto'
            }
        },

        '&.completed': {
            flexDirection: 'row',

            '& .graphic': {
                margin: '0 auto 25px',
                height: 'auto',
                width: '320px'
            },

            '& .form': {
                paddingTop: '100px',

                '& .form-title': {
                    textAlign: 'left'
                },

                '& .welcome-text': {
                    textAlign: 'left'
                },

                '& button': {
                    marginLeft: 0
                }
            }
        }
    }
})

export const FormInfoText = glamorous.div({
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    width: '100%',
    padding: '15px',
    marginBottom: '25px',
    borderLeft: '3px solid #f7c130',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    backgroundColor: '#101d26',
    color: '#ffda77',

    '& p': {
        marginBottom: 0,
        color: '#ffda77'
    },

    '& label': {
        color: '#ffda77',

        '& strong': {
            fontWeight: 500,
            letterSpacing: '1px'
        }
    },

    '& ul li': {
        marginBottom: '15px',
        fontSize: '14px',

        '&:last-child': {
            marginBottom: 0
        }
    },

    [mediaQueries.extraSmallDevices]: {
        padding: '6px 10px',
        marginBottom: 0,

        '& p': {
            fontSize: '14px'
        },

        '& label': {
            marginBottom: '2px'
        }
    },

    [mediaQueries.largeDevices]: {
        maxWidth: '960px',
        margin: '0 auto'
    }
}, ({ type }) => ({
    flexDirection: type === 'list' ? 'column' : 'row'
}))

export const FormActions = glamorous.div({
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    background: 'linear-gradient(to bottom, #1c3044, #101823)',
    borderTop: '1px solid #3d5368',

    '& button': {
        marginTop: 0,

        '& svg': {
            display: 'none'
        },

        '&.skip-btn': {
            marginRight: '10px'
        },

        '&.default-btn': {
            backgroundColor: '#4e5d6f',
            color: '#fff',

            '& svg': {
                color: '#fff'
            }
        },

        '&:first-child': {
            '&.back-btn': {                
                marginRight: 'auto'
            }
        },

        '&:hover': {
            overflow: 'hidden'
        },

        '&:disabled': {
            opacity: '0.2'
        }
    },

    [mediaQueries.extraSmallDevices]: {
        padding: '15px',
        margin: '10px -15px 0',

        '& button': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '40px',
            height: '40px',
            borderRadius: '9px',
            padding: 0,
            
            '& svg': {
                display: 'inline-block',
                color: '#101823',
                width: 'auto !important',
                height: '20px'
            },

            '& > span': {
                display: 'none'
            },

            '&.skip-btn': {
                marginRight: '6px'
            },

            '&.default-btn': {
                minHeight: '40px',
                backgroundColor: '#4e5d6f',

                '& svg': {
                    color: '#fff'
                }
            }
        }
    },

    [mediaQueries.smallDevices]: {
        padding: '15px',
        margin: '10px -15px 0',

        '& button': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '40px',
            height: '40px',
            borderRadius: '9px',
            padding: 0,
            
            '& svg': {
                display: 'inline-block',
                color: '#101823',
                width: 'auto !important',
                height: '20px'
            },

            '& > span': {
                display: 'none'
            },

            '&.skip-btn': {
                marginRight: '6px'
            },

            '&.default-btn': {
                minHeight: '40px',
                backgroundColor: '#4e5d6f',

                '& svg': {
                    color: '#fff'
                }
            }
        }
    },

    [mediaQueries.mediumDevices]: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        padding: '10px 25px',
        margin: 0,

        '& button': {
            width: 'auto',
            height: '45px',
            minHeight: '45px',
            borderRadius: '25px',
            padding: '8px 18px',

            '& svg': {
                display: 'none'
            },

            '& > span': {
                display: 'inline-block'
            },

            '&:hover': {
                overflow: 'hidden'
            }
        }
    },

    [mediaQueries.largeDevices]: {
        padding: '8px 25px',

        '& button': {            
            height: '40px',
            minHeight: '40px',
            padding: '1px 25px'
        }
    },

    [mediaQueries.extraLargeDevices]: {
        padding: '10px 25px',

        '& button': {
            height: '40px',
            minHeight: '40px',
            padding: '0 25px'
        }
    }
})

export const GifLoader = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: '420px',

    '& p': {
        textAlign: 'center',
        fontSize: '17px'
    },

    '& img': {
        width: '100%'
    },

    [mediaQueries.mediumDevices]: {
        width: '640px',

        '& p': {
            fontSize: '20px'
        }
    }
})