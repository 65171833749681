import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const TheNetPeakGainsSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    padding: 40px 0;
    background-color: #060B0F;

    &::before {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: -1px;
        left: 0;
        content: ' ';
        background-image: url(/images/layout/net-peak-gains-bg.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: 0;
    }

    &::after {
        display: block;
        width: 100%;
        height: 50px;
        position: absolute;
        bottom: 0;
        left: 0;
        content: ' ';
        background-image: url(/images/layout/double-lines.svg);
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;
    }

    .container {
        position: relative;

        .section-title {
            display: block;
            width: 100%;
            color: #fff;
            text-align: center;
            //font-size: 42px;
            font-size: 25px;
            font-weight: 600;
            max-width: 1100px;
            margin: 0 auto 25px;
        }

        .npg-chart {
            display: block;
            margin: 0 auto 25px;
            width: 100%;

            img {
                width: 100%;
            }
        }

        h3 {
            color: #fff;
            //font-size: 45px;
            font-size: 30px;
            font-weight: 600;
            text-align: center;

            .cog-icon {
                display: inline-block;
                width: 200px;
                margin-left: 15px;
                margin-top: 10px;

                img {
                    width: 100%;
                }
            }
        }

        p {
            display: block;
            color: #fff;
            text-align: center;
            max-width: 780px;
            margin: 0 auto 25px;
            font-size: 16px;

            &.full-w {
                max-width: 100%;
            }

            .daily-rate {
                color: #16BD70;
            }
        }

        .trade-result {
            position: relative;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;
            max-width: 380px;
            margin: 0 auto 25px;

            .signal-thumbnail {
                width: 380px;

                img {
                    width: 100%;
                }
            }

            .trade-label {
                position: relative;

                p {
                    font-size: 16px;
                    font-weight: 700;
                    max-width: 300px;
                    text-align: left;

                    .em {
                        color: #15bd6f;
                    }
                }

                &::after {
                    position: absolute;
                    display: block;
                    bottom: -5px;
                    right: 10px;
                    width: 124px;
                    height: 24px;
                    content: ' ';
                    background-image: url(/images/layout/sandusdt-pointer.png);
                    background-repeat: no-repeat;
                    background-size: 100%;
                    transform: rotate(180deg);
                }
            }
        }

        .snapvids {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            margin: 0 auto 20px;
            list-style-type: none;
            padding: 0;

            li {
                position: relative;
                padding: 10px;
                width: 373px;

                > div {
                    height: 360px !important;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 17px;
                    font-weight: 600;
                    margin-bottom: 0;
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        padding: 60px 0 150px;

        &::after {
            height: 150px;
        }

        .container {
            .section-title {
                font-size: 28px;
            }

            .trade-result {
                max-width: 100%;
                
                .signal-thumbnail {
                    width: 60%;
                }

                .trade-label {
                    width: 40%;

                    p {
                        font-size: 25px;
                    }

                    &::after {
                        width: 275px;
                        height: 60px;
                        transform: rotate(0);
                        bottom: -40px;
                        left: 0;
                    }
                }
            }

            .snapvids {
                flex-direction: row;
                
                li {
                    > div {
                        height: 230px !important;
                    }
                }
            }
        }

    }

    ${mediaQueries.largeDevices} {
        .container {
            .section-title {
                font-size: 35px;
            }
            .npg-chart {
                width: 790px;
            }

            .trade-result {
                .signal-thumbnail {
                    img {
                        width: 450px;
                    }
                }

                .trade-label {
                    &::after {
                        bottom: -80px;
                        width: 335px;
                        height: 100px;
                        right: 0;
                        left: unset;
                    }
                }
            }

            .snapvids {                
                li {
                    > div {
                        height: 320px !important;
                    }
                }
            }
        }
    }

    ${mediaQueries.superExtraLargeDevices} {
        padding: 60px 0 200px;

        &::after {
            height: 200px;
        }
        .container {
            .section-title {
                font-size: 42px;
            }

            .trade-result {
                .signal-thumbnail {
                    width: 420px;
                    
                    img {
                        width: 100%;
                    }
                }

                .trade-label {
                    width: unset;
                    position: absolute;
                    left: 0px;

                    p {
                        font-size: 28px;
                        width: 315px;
                    }

                    &::after {
                        left: 0;
                    }
                }
            }

            .snapvids {                
                li {
                    > div {
                        height: 360px !important;
                    }
                }
            }
        }
    }
`