import React from 'react'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import { isNotNull, onSetNewState } from '../../../../utils'
import { userActions } from '../../../../redux/actions'
import queryString from 'query-string'
import { PaymentCompleteView } from './PaymentCompleteView'

class PaymentCompleteContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            redirectTarget: 0,
            remainingSeconds: 10
        }

        this.mounted = false
        this.baseState = this.state

        this.redirectDelay = null
    }

    componentDidMount() {
        this.mounted = true

        const { dispatch, location } = this.props

        Cookies.remove('PAPVisitorId')
        Cookies.remove('PAPAffiliateId')
        Cookies.remove('PAPBannerId')

        const val = queryString.parse(location.search)
        if (isNotNull(val.token)) {
            const tokenParams = val.token
            const token = tokenParams.replace(/ /g, '+')

            if (isNotNull(token))
                dispatch(userActions.loginByToken(token))
        }
        else
            this.onRedirectToDashboardDelay()

        //dispatch(userActions.loginAuto())
        //this.onRedirectToDashboardDelay()
    }

    componentDidUpdate(prevProps) {
        const { authentication } = this.props
        if (prevProps.authentication.loading && authentication.loading === false) {
            const { user } = authentication
            if (isNotNull(user)) {
                const { isEmailConfirmed } = user
                if (isNotNull(isEmailConfirmed) && isEmailConfirmed === false)
                    localStorage.setItem('emailError', true)
            }
            else
                window.location.href = '/account/login'
        }

        this.onRedirectToDashboardDelay()
    }

    componentWillUnmount() {
        clearTimeout(this.redirectDelay)
        this.setState(this.baseState)

        this.mounted = false
    }

    onRedirectToDashboardDelay() {
        this.redirectDelay = setTimeout(() => {
            let { remainingSeconds, redirectTarget } = this.state

            if (remainingSeconds !== redirectTarget)
                onSetNewState(this, { remainingSeconds: remainingSeconds -= 1 })
            else
                this.onRedirectToDashboardHandler()
        }, 1000)
    }

    onRedirectToDashboardHandler = () => window.location.href = '/dashboard'

    render() {
        return (
            <PaymentCompleteView
                onRedirectToDashboardHandler={this.onRedirectToDashboardHandler}
                context={this}
                state={this.state}
                {...this.props}>
                
            </PaymentCompleteView>    
        )
    }
}

function mapStateToProps(state) {
    const { authentication } = state
    return {
        authentication
    }
}
const connectedPaymentCompleteContainer = connect(mapStateToProps)(PaymentCompleteContainer)
export { connectedPaymentCompleteContainer as PaymentCompleteContainer }