import React, { useEffect, useState} from 'react'
import { Carousel, SafeAnchor } from 'react-bootstrap'
import { CaseStudiesStyle } from './style/CaseStudiesStyle'
import { mediaDeviceSizes } from '../../constants/mediaQueries'
import { getSize } from '../../utils'

export const CaseStudies = () => {
    let [device, setDevice] = useState(null)

    useEffect(() => {
        setDevice(getSize(window.innerWidth))
    }, [])
        
    return (

        <CaseStudiesStyle>
            { (device === mediaDeviceSizes.xs || device === mediaDeviceSizes.sm || device === mediaDeviceSizes.md) &&
                <Carousel controls={false} className="case-study-mobile-carousel">
                    <Carousel.Item>
                        <SafeAnchor href='https://www.profitfarmers.com/case-studies/kyne-myers/' target="_blank" className="case-study-mobile-carousel-item-link">
                            <img src='../images/case-studies/myers-featured-image.jpg' alt="Kyne Myers" />
                        </SafeAnchor>
                    </Carousel.Item>

                    <Carousel.Item>
                        <SafeAnchor href='https://www.profitfarmers.com/case-studies/kingston-arthur/' target="_blank" className="case-study-mobile-carousel-item-link">
                            <img src='../images/case-studies/kingston-arthur-featured-img.jpg' alt="Kingston Arthur" />
                        </SafeAnchor>
                    </Carousel.Item>

                    <Carousel.Item>
                        <SafeAnchor href='https://www.profitfarmers.com/case-studies/hooman-attaran/' target="_blank" className="case-study-mobile-carousel-item-link">
                            <img src='../images/case-studies/hooman-attaran-featured-image.jpg' alt="Hooman Attaran" />
                        </SafeAnchor>
                    </Carousel.Item>

                    <Carousel.Item>
                        <SafeAnchor href='https://www.profitfarmers.com/case-studies/jaime-oliveira/' target="_blank" className="case-study-mobile-carousel-item-link">
                            <img src='../images/case-studies/jaime-o-featured-image.jpg' alt="Jaime O" />
                        </SafeAnchor>
                    </Carousel.Item>

                    <Carousel.Item>
                        <SafeAnchor href='https://www.profitfarmers.com/case-studies/bernhard-wagner/' target="_blank" className="case-study-mobile-carousel-item-link">
                            <img src='../images/case-studies/bernhard-wagner-featured-img.jpg' alt="Bernhard Wagner" />
                        </SafeAnchor>
                    </Carousel.Item>

                    <Carousel.Item>
                        <SafeAnchor href='https://www.profitfarmers.com/case-studies/tim-griffith/' target="_blank" className="case-study-mobile-carousel-item-link">
                            <img src='../images/case-studies/tim-griffith-featured-image.jpg' alt="Tim Griffith" />
                        </SafeAnchor>
                    </Carousel.Item>
                </Carousel>
            }

            { (device === mediaDeviceSizes.lg || device === mediaDeviceSizes.xl || device === mediaDeviceSizes.xxl) &&
                <Carousel controls={false} className="case-study-multi-carousel">
                    <Carousel.Item>
                        <div className="case-study-multi-carousel-item">
                            <SafeAnchor href='https://www.profitfarmers.com/case-studies/kyne-myers/' target="_blank" className="case-study-multi-carousel-item-link">
                                <img src='../images/case-studies/myers-featured-image.jpg' alt="Kyne Myers" />
                            </SafeAnchor>
                            <SafeAnchor href='https://www.profitfarmers.com/case-studies/kingston-arthur/' target="_blank" className="case-study-multi-carousel-item-link">
                                <img src='../images/case-studies/kingston-arthur-featured-img.jpg' alt="Kingston Arthur" />
                            </SafeAnchor>
                        </div> 
                    </Carousel.Item>

                    <Carousel.Item>
                        <div className="case-study-multi-carousel-item">
                            <SafeAnchor href='https://www.profitfarmers.com/case-studies/hooman-attaran/' target="_blank" className="case-study-multi-carousel-item-link">
                                <img src='../images/case-studies/hooman-attaran-featured-image.jpg' alt="Hooman Attaran" />
                            </SafeAnchor>
                            <SafeAnchor href='https://www.profitfarmers.com/case-studies/jaime-oliveira/' target="_blank" className="case-study-multi-carousel-item-link">
                                <img src='../images/case-studies/jaime-o-featured-image.jpg' alt="Jaime O" />
                            </SafeAnchor> 
                        </div>    
                    </Carousel.Item>

                    <Carousel.Item>
                        <div className="case-study-multi-carousel-item">
                            <SafeAnchor  href='https://www.profitfarmers.com/case-studies/bernhard-wagner/' target="_blank" className="case-study-multi-carousel-item-link">
                                <img src='../images/case-studies/bernhard-wagner-featured-img.jpg' alt="Bernhard Wagner" />
                            </SafeAnchor> 
                            <SafeAnchor href='https://www.profitfarmers.com/case-studies/tim-griffith/' target="_blank" className="case-study-multi-carousel-item-link">
                                <img src='../images/case-studies/tim-griffith-featured-image.jpg' alt="Tim Griffith" />
                            </SafeAnchor> 
                        </div>   
                    </Carousel.Item>
                </Carousel>
            }

            <Carousel controls={false} className="video-testimony-carousel">
                <Carousel.Item>
                    <SafeAnchor href='https://youtu.be/uzH5YHqXOFc' target="_blank" className="video-testimony-carousel-item-link">
                        <img src='../images/case-studies/kingston-video-cover-img.jpg' alt="Kingston" />
                    </SafeAnchor>
                </Carousel.Item>

                <Carousel.Item>
                    <SafeAnchor href='https://youtu.be/yMvi7_YEIVw' target="_blank" className="video-testimony-carousel-item-link">
                        <img src='../images/case-studies/henry-video-cover.png' alt="Henry" />
                    </SafeAnchor>
                </Carousel.Item>

                <Carousel.Item>
                    <SafeAnchor href='https://youtu.be/jAMI4PXRR_w' target="_blank" className="video-testimony-carousel-item-link">
                        <img src='../images/case-studies/alp-video-cover.png' alt="Alp" />
                    </SafeAnchor>
                </Carousel.Item>

                <Carousel.Item>
                    <SafeAnchor href='https://youtu.be/ezD2mXknWF8' target="_blank" className="video-testimony-carousel-item-link">
                        <img src='../images/case-studies/benjamin-video-cover.png' alt="Benjamin" />
                    </SafeAnchor>
                </Carousel.Item>

                <Carousel.Item>
                    <SafeAnchor href='https://youtu.be/2ihArcwnZT8' target="_blank" className="video-testimony-carousel-item-link">
                        <img src='../images/case-studies/mads-video-cover.png' alt="Mads" />
                    </SafeAnchor>
                </Carousel.Item>

                <Carousel.Item>
                    <SafeAnchor href='https://youtu.be/nXOHucoBivU' target="_blank" className="video-testimony-carousel-item-link">
                        <img src='../images/case-studies/stewart-video-cover.png' alt="Stewart" />
                    </SafeAnchor>
                </Carousel.Item>
            </Carousel>
        </CaseStudiesStyle>
    )
}