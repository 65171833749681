import React from 'react'
import { connect } from 'react-redux'
import { addPageViewEvent, seo } from '../../utils'
import { PricingView } from './PricingView'

class PricingContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            subscriptionPlans: [
                {
                    name: 'Monthly',
                    val: 'monthly',
                    price: 265
                },
                {
                    name: 'Quarterly',
                    val: 'quarterly',
                    price: 699
                },
                {
                    name: 'Yearly',
                    val: 'yearly',
                    price: 2659
                }
            ]
        }

        this.mounted = false
        this.baseState = this.state
    }

    componentDidMount() {
        this.mounted = true

        seo({
            title: "Pricing",
            metaDescription: ""
        })
        addPageViewEvent()
    }

    componentDidUpdate() {

    }

    componentWillUnmount() {

    }

    render() {
        return (
            <PricingView 
                state={this.state} />
        )
    }
}

function mapStateToProps(state) {
    const { authentication } = state
    return {
        authentication
    }
}

const connectedPricingContainer = connect(mapStateToProps)(PricingContainer)
export { connectedPricingContainer as PricingContainer }