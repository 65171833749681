import React from 'react'
import { connect } from 'react-redux'
import { PrimaryButton } from '../../styles'
import { FormBodyContent } from './'
import { onboardingActions } from '../../redux/actions'

class CompleteOnboarding extends React.Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    componentDidMount() {
        this.mounted = true
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { setToComplete } = this.props
            if (prevProps.setToComplete !== setToComplete && 
                !setToComplete.loading) {
                    const { result } = setToComplete
                    if (result.success) window.location.href = '/dashboard'
                }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    handleClickNext = (e) => {
        e.preventDefault()

        const { dispatch } = this.props
        dispatch(onboardingActions.setToComplete())
    }

    render = () => {
        return (
            <FormBodyContent className='completed'>
                <div className='form'>
                    <h2 className='form-title'>Congratulations, You're all set!</h2>
                    <p className='welcome-text'>You're ready to start! Don't forget to check out the Academy before you begin trading.</p>

                    <PrimaryButton 
                        customBg="gradient"
                        type="submit" 
                        size="lg"
                        onClick={(e) => this.handleClickNext(e)}>
                        <span>Continue to app</span>
                    </PrimaryButton>
                </div>

                <div className='graphic'>
                    <img src='../images/form-graphics/complete.gif' alt="ProfitFarmers - Basic Info" />
                </div>
            </FormBodyContent>
        )
    }
}

function mapStateToProps(state) {
    const { setToComplete } = state
    return { 
        setToComplete
    }
}

const connectedCompleteOnboarding = connect(mapStateToProps)(CompleteOnboarding)
export { connectedCompleteOnboarding as CompleteOnboarding }