import styled from 'styled-components';

export const Filter = styled.ul`
    margin: 0;
    padding-left: 0;
    list-style-type: none;
    display: flex;

    li {
        display: inline;
        margin-right: 20px;

        label {
            margin-right: 10px;
            margin-bottom: 0;
        }

        .dropdown {
            display: inline;

            .dropdown-toggle {
                background-color: transparent;
                border: none;
                padding: 0;
                vertical-align: top;
                color: #fff;
            }
        }

        &.form-inline {
            .form-group {
                display: inline-flex;
                margin-right: 10px;

                .form-control {
                    color: #ced0dd;
                    background-color: #19212b;
                    border: 1px solid #383e4e;
                }

                &:last-child {
                    margin-right: 0;
                }

                .btn-light {
                    color: #ced0dd;
                    background-color: #19212b;
                    border: 1px solid #383e4e;
                }
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }

    select {
        cursor: pointer;
    }

    @media screen and (min-width: 320px) and (max-width: 766px) {
        display: grid;
        height: auto;
        width: 100%;
        min-width: auto;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        column-gap: 15px;
        row-gap: 15px;

        .form-inline {
            margin-right: 0px;

            .form-group {
                width: 100%;
                margin-bottom: 0px;

                .form-control {
                    width: 100%;
                }

                .btn-light {
                    
                    width: 100%;
                }
            }
        }
    }
`;
