import _ from 'lodash'
import jQuery from 'jquery'
import { signalDetailsActions } from '../../../redux/actions'
import { isNotNull, onSetNewState, toBtcFormat, toUsdFormat } from '../../../utils'

const BTCUSDTpriceWS = ({ props, data }) => {
    const btcUsdtInfo = _.find(data, (val, key) => key.toUpperCase() === "BTCUSDT")
    if (isNotNull(btcUsdtInfo) && isNotNull(btcUsdtInfo.spot)) {
        // BTCUSDT has information
        // set bid and ask prices
        onSetNewState(props, {
            btcUsdtBidPrice: _.round(parseFloat(btcUsdtInfo.spot.bidPrice), 2).toFixed(2),
            btcUsdtAskPrice: _.round(parseFloat(btcUsdtInfo.spot.askPrice), 2).toFixed(2),
            btcUsdtLastPrice: toUsdFormat(parseFloat(btcUsdtInfo.spot.lastPrice))
        })
    }
}

const priceUpdate = (id, price) => {
    if (isNotNull(price))
        jQuery(`#signal-${id}`).text(`${price}`).trigger('priceChanged')
}

const setPnL = (orderId, exchange, percentage) => {
    if (isNotNull(percentage)) {
        //console.log(`setting: ${orderId}, exchange: ${exchange}, value: ${percentage}`)
        let html = ''
        if (percentage < 0) {
            html = `<span style="
                        background-color: rgb(246, 70, 93); 
                        color: #fff;
                        padding: 2px 10px;
                        border-radius: 2px;
                        letter-spacing: 1px;
                        font-weight: 500">
                        ${percentage.toFixed(2)}%
                    </span>`
        } else {
            html = `<span style="
                        background-color: rgb(14, 203, 129); 
                        color: #fff;
                        padding: 2px 10px;
                        border-radius: 2px;
                        letter-spacing: 1px;
                        font-weight: 500">
                        +${percentage.toFixed(2)}%
                    </span>`
        }
        //jQuery(`#order-${orderId}-${exchange}-pnl`).html(`${html}`)
        document.getElementById(`order-${orderId}-${exchange}-pnl`).innerHTML = html
    }
}

const SignalSpotWS = ({ props, data }) => {
    const { dispatch, signals, signalDetails, openOrderDetails } = props.props

    if (isNotNull(signals) && isNotNull(signals.loading) && isNotNull(signals.signals) && !signals.loading) {
        const signal = [...signals.signals]
        _.forEach(signal, signalItem => {
            if (isNotNull(signalItem) &&
                isNotNull(signalItem.feed) &&
                isNotNull(signalItem.symbol)) {

                const findLastPrice = _.find(data, (val) => {
                    return val.s.toUpperCase() === signalItem.symbol.toUpperCase()
                })

                if (isNotNull(findLastPrice)) {
                    const isUsdSignal = (signalItem.symbol.toUpperCase().endsWith("USDT") || signalItem.symbol.toUpperCase().endsWith("USD")) ? true : false

                    if (signalItem.feed.toUpperCase() === "SPOT") {
                        if (isNotNull(findLastPrice.c)) {
                            let price = ''
                            if (isUsdSignal) {
                                if (parseFloat(findLastPrice.c) < 1)
                                    price = toBtcFormat(findLastPrice.c)
                                else
                                    price = toUsdFormat(findLastPrice.c)
                            } else 
                                price = toBtcFormat(findLastPrice.c)

                            priceUpdate(signalItem.id, price)
                        }
                    }
                }

            }
        })
    }

    if (isNotNull(signalDetails) && isNotNull(signalDetails.loading) && isNotNull(signalDetails.item) && !signalDetails.loading) {
        const findLastPrice = _.find(data, (val) => val.s.toUpperCase() === signalDetails.item.symbol)
        if (isNotNull(findLastPrice)) {
            const isUsdSignal = (signalDetails.item.symbol.toUpperCase().endsWith("USDT") || signalDetails.item.symbol.toUpperCase().endsWith("USD")) ? true : false

            if (signalDetails.item.feed.toUpperCase() === "SPOT") {
                if (isNotNull(findLastPrice.c)) {
                    if (isUsdSignal) {
                        if (parseFloat(findLastPrice.c) < 1)
                            signalDetails.item.lastPrice = toBtcFormat(findLastPrice.c)
                        else
                            signalDetails.item.lastPrice = toUsdFormat(findLastPrice.c)
                    } else 
                        signalDetails.item.lastPrice = toBtcFormat(findLastPrice.c)

                    dispatch(signalDetailsActions.updateSignalDetails(signalDetails.item))
                }
            }
        }
    }

    if (isNotNull(openOrderDetails) && !openOrderDetails.loading) {
        const { open_order_details } = openOrderDetails
        if (isNotNull(open_order_details)) {
            const { symbol, orderLines } = open_order_details
            if (isNotNull(orderLines) && orderLines.length > 0) {
                _.forEach(orderLines, orderLine => {
                    if (orderLine.orderExchange === 'binance' && orderLine.type === 'Custom - TAKE PROFIT - MARKET') {
                        const ticker = _.find(data, (val) => {
                            return val.s.toUpperCase() === symbol.toUpperCase()
                        })
                        if (isNotNull(ticker) && isNotNull(orderLine.buyPrice)) {
                            // calculate profit or loss since buy price against last price
                            let progress = (parseFloat(ticker.c) - parseFloat(orderLine.buyPrice)) / parseFloat(orderLine.buyPrice)
                            progress *= 100
                            setPnL(orderLine.orderId, orderLine.orderExchange, progress)
                        }
                    }
                })
            }
        }
    }
}

const SignalFuturesWS = ({ props, data }) => {
    const { dispatch, signals, signalDetails, openOrderDetails } = props.props

    if (isNotNull(signals) && isNotNull(signals.loading) && isNotNull(signals.signals) && !signals.loading) {
        const signal = [...signals.signals]
        _.forEach(signal, signalItem => {
            if (isNotNull(signalItem) &&
                isNotNull(signalItem.feed) &&
                isNotNull(signalItem.symbol)) {

                const findLastPrice = _.find(data, (val) => {
                    return val.s.toUpperCase() === signalItem.symbol.toUpperCase()
                })

                if (isNotNull(findLastPrice)) {
                    const isUsdSignal = (signalItem.symbol.toUpperCase().endsWith("USDT") || signalItem.symbol.toUpperCase().endsWith("USD")) ? true : false

                    if (signalItem.feed.toUpperCase() === "FUTURES") {
                        if (isNotNull(findLastPrice.c)) {
                            let price = ''
                            if (isUsdSignal) {
                                if (parseFloat(findLastPrice.c) < 1)
                                    price = toBtcFormat(findLastPrice.c)
                                else
                                    price = toUsdFormat(findLastPrice.c)
                            } else 
                                price = toBtcFormat(findLastPrice.c)

                            //const price = isUsdSignal ? toUsdFormat(parseFloat(findLastPrice.c)) : toBtcFormat(parseFloat(findLastPrice.c))
                            priceUpdate(signalItem.id, price)
                        }
                    }
                }

            }
        })
    }

    if (isNotNull(signalDetails) && isNotNull(signalDetails.loading) && isNotNull(signalDetails.item) && !signalDetails.loading) {
        const findLastPrice = _.find(data, (val) => val.s.toUpperCase() === signalDetails.item.symbol)
        if (isNotNull(findLastPrice)) {
            const isUsdSignal = (signalDetails.item.symbol.toUpperCase().endsWith("USDT") || signalDetails.item.symbol.toUpperCase().endsWith("USD")) ? true : false
            if (signalDetails.item.feed.toUpperCase() === "FUTURES") {
                if (isNotNull(findLastPrice.c)) {
                    if (isUsdSignal) {
                        if (parseFloat(findLastPrice.c) < 1)
                            signalDetails.item.lastPrice = toBtcFormat(findLastPrice.c)
                        else
                            signalDetails.item.lastPrice = toUsdFormat(findLastPrice.c)
                    } else 
                        signalDetails.item.lastPrice = toBtcFormat(findLastPrice.c)
                        
                    //signalDetails.item.lastPrice = isUsdSignal ? toUsdFormat(parseFloat(findLastPrice.c)) : toBtcFormat(parseFloat(findLastPrice.c))
                    dispatch(signalDetailsActions.updateSignalDetails(signalDetails.item))
                }
            }
        }
    }

    if (isNotNull(openOrderDetails) && !openOrderDetails.loading) {
        const { open_order_details } = openOrderDetails
        if (isNotNull(open_order_details)) {
            const { symbol, orderLines } = open_order_details
            if (isNotNull(orderLines) && orderLines.length > 0) {
                _.forEach(orderLines, orderLine => {
                    if (orderLine.orderExchange === 'binancefutures' && orderLine.type === 'Custom - TAKE PROFIT - MARKET') {
                        const ticker = _.find(data, (val) => {
                            return val.s.toUpperCase() === symbol.toUpperCase()
                        })
                        if (isNotNull(ticker) && isNotNull(orderLine.buyPrice)) {
                            // calculate profit or loss since buy price against last price
                            let progress = (parseFloat(ticker.c) - parseFloat(orderLine.buyPrice)) / parseFloat(orderLine.buyPrice)
                            progress *= 100
                            setPnL(orderLine.orderId, orderLine.orderExchange, progress)
                        }
                    }
                })
            }
        }
    }
}

export {
    SignalSpotWS,
    SignalFuturesWS,
    BTCUSDTpriceWS
}