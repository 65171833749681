import styled from 'styled-components'

export const CoinPairLogo = styled.img`
    width: 45px;
    height: 45px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-size: 99%;
    border-radius: 50%;
    background-color: transparent;
`