import styled from 'styled-components';
import {
    DataSignalGridWrapper, SectionBlockInner,
    SectionTitle, SectionHeader,
    DataSignalGrid, DataSignals, SignalN,
    SignalI, SignalPrice, SignalVol,
    SignalMarketCap, SignalPeakGains, SignalLiveFibo,
    WaveInfoContainer, CoinLogo, CoinName,
    Wave, CoinInfoContainer,PreloaderWrapper,
    Preloader, PreloaderLogo, PreloaderItem,
    PreloaderWatch
} from './ScannerBodiesStyle';
import { Filter } from './ScannerHeaderStyle';

export const SectionBlock = styled.section`
    padding: 40px 30px;
    min-height: calc(100vh - 60px);

    //#region media 320 - 414
    @media screen and (min-width: 320px) and (max-width: 414px) {
        padding: 20px;

        ${PreloaderWrapper} {
            padding: 0px 20px 0;
            &:last-child {
                padding-bottom: 20px;
            }

            ${Preloader} {
                margin-bottom: 0;

                ${PreloaderLogo} {
                    width: 25px;
                    height: 22px;
                }

                ${PreloaderItem} {
                    height: 45px;

                    &.header {
                        height: 25px;
                    }
                }
            }
        }
    }
    //#endregion

    //#region media 766 - 992
    @media screen and (min-width: 766px) and (max-width: 992px) {

        ${SectionBlockInner} {

            ${SectionHeader} {

                ${SectionTitle} {
                    font-size: 25px !important;

                    svg {
                        font-size: 25px !important;
                    }
                }
            }
        }
    }
    //#endregion

    //#region media 993 - 1023
    @media screen and (min-width: 993px) and (max-width: 1023px) {

        ${SectionBlockInner} {

            ${SectionHeader} {

                ${SectionTitle} {
                    font-size: 24px !important;

                    svg {
                        font-size: 24px !important;
                    }
                }
            }

            
        }

        ${PreloaderWrapper} {
            padding: 0px 20px 0;
            &:last-child {
                padding-bottom: 20px;
            }
        }
    }
    //#endregion

    //#region media 1024 - 1199
    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        padding: 20px 15px;
        font-size: 10px;

        ${SectionBlockInner} {

            ${SectionHeader} {

                ${SectionTitle} {
                    font-size: 20px;

                    svg {
                        font-size: 10px;
                    }
                }

                ${Filter} {

                    li {

                        form {
                            .form-group {
                                .form-control {
                                    font-size: 10px;
                                }

                                .btn-light {
                                    font-size: 10px;
                                    padding: 6px 10px;
                                }
                            }
                        }
                    }
                }
            }

            ${DataSignalGridWrapper} {

                &.price-grid {

                    .scanner-wrapper {
                        /* margin-right: 35px; */

                        ${DataSignalGrid} {

                            ${DataSignals} {
                                font-size: 10px;

                                ${SignalN} {
                                    width: 145px;
                                    padding: 0 10px;


                                    ${WaveInfoContainer} {

                                        ${CoinInfoContainer} {

                                            ${CoinLogo} {
                                                width: 20px;
                                                height: 20px;
                                                margin-top: -23px;
                                                margin-right: 5px;
                                            }

                                            ${CoinName} {

                                            }
                                        }
                                    }

                                    svg {
                                        font-size: 8px;
                                    }
                                }

                                ${SignalI} {
                                    width: 53px;

                                    ${WaveInfoContainer} {
                                        ${Wave} {
                                            width: 20px;
                                            height: 20px;
                                        }
                                    }

                                    svg {
                                        font-size: 8px;
                                    }
                                }

                                ${SignalPrice},
                                ${SignalVol},
                                ${SignalMarketCap},
                                ${SignalPeakGains},
                                ${SignalLiveFibo} {
                                    width: 100px;

                                    svg {
                                        font-size: 8px;
                                    }
                                }

                                ${SignalLiveFibo} {
                                    padding-right: 10px;
                                }

                                ${SignalPeakGains} {
                                    width: 101px;
                                }
                            }
                        }
                    }

                    ${PreloaderWrapper} {
                        padding: 0px 20px 0;
                        &:last-child {
                            padding-bottom: 20px;
                        }

                        ${Preloader} {
                            margin-bottom: 0;

                            ${PreloaderLogo} {
                                width: 25px;
                                height: 25px;
                            }

                            ${PreloaderItem} {
                                height: 45px;

                                &.header {
                                    height: 25px;
                                }
                            }

                            ${PreloaderWatch} {
                                display: block;
                            }
                        }
                    }
                }

                &.rsi-grid {

                    ${DataSignalGrid} {

                        ${DataSignals} {
                            font-size: 10px;

                            ${SignalN} {
                                font-size: 10px;
                                width: 18%;
                                padding: 0 10px;

                                ${WaveInfoContainer} {

                                    ${CoinInfoContainer} {

                                        ${CoinLogo} {
                                            width: 20px;
                                            height: 20px;
                                            margin-top: -23px;
                                            margin-right: 5px;
                                        }

                                        ${CoinName} {

                                        }
                                    }
                                }

                                svg {
                                    font-size: 8px;
                                }
                            }

                            ${SignalI} {
                                width: 7%;

                                svg {
                                    font-size: 8px;
                                }
                            }

                            ${SignalPrice},
                            ${SignalVol},
                            ${SignalMarketCap},
                            ${SignalPeakGains},
                            ${SignalLiveFibo} {
                                svg {
                                    font-size: 8px;
                                }
                            }

                            ${SignalPrice},
                            ${SignalVol} {
                                margin-left: 5px;
                            }
                        }
                    }

                    ${PreloaderWrapper} {
                        padding: 0px 20px 0;
                        &:last-child {
                            padding-bottom: 20px;
                        }

                        ${Preloader} {
                            margin-bottom: 0;

                            ${PreloaderLogo} {
                                width: 25px;
                                height: 25px;
                            }

                            ${PreloaderItem} {
                                height: 45px;

                                &.header {
                                    height: 25px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //#endregion

    //#region media 1200 - 1440
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        padding: 30px 55px;
        font-size: 10px;

        ${SectionBlockInner} {

            ${SectionHeader} {

                ${SectionTitle} {
                    font-size: 20px;

                    svg {
                        font-size: 10px;
                    }
                }

                ${Filter} {

                    li {

                        form {
                            .form-group {
                                .form-control {
                                    font-size: 10px;
                                }

                                .btn-light {
                                    font-size: 10px;
                                    padding: 6px 10px;
                                }
                            }
                        }
                    }
                }
            }

            ${DataSignalGridWrapper} {

                &.price-grid {

                    .scanner-wrapper {
                        /* margin-right: 35px; */

                        ${DataSignalGrid} {

                            ${DataSignals} {
                                font-size: 10px;

                                ${SignalN} {
                                    width: 145px;
                                    padding: 0 10px;


                                    ${WaveInfoContainer} {

                                        ${CoinInfoContainer} {

                                            ${CoinLogo} {
                                                width: 20px;
                                                height: 20px;
                                                margin-top: -23px;
                                                margin-right: 5px;
                                            }

                                            ${CoinName} {

                                            }
                                        }
                                    }

                                    svg {
                                        font-size: 8px;
                                    }
                                }

                                ${SignalI} {
                                    width: 53px;

                                    ${WaveInfoContainer} {
                                        ${Wave} {
                                            width: 20px;
                                            height: 20px;
                                        }
                                    }

                                    svg {
                                        font-size: 8px;
                                    }
                                }

                                ${SignalPrice},
                                ${SignalVol},
                                ${SignalMarketCap},
                                ${SignalPeakGains},
                                ${SignalLiveFibo} {
                                    width: 100px;

                                    svg {
                                        font-size: 8px;
                                    }
                                }

                                ${SignalLiveFibo} {
                                    padding-right: 10px;
                                }

                                ${SignalPeakGains} {
                                    width: 101px;
                                }
                            }
                        }
                    }

                    ${PreloaderWrapper} {
                        padding: 0px 20px 0;
                        &:last-child {
                            padding-bottom: 20px;
                        }

                        ${Preloader} {
                            margin-bottom: 0;

                            ${PreloaderLogo} {
                                width: 25px;
                                height: 25px;
                            }

                            ${PreloaderItem} {
                                height: 45px;

                                &.header {
                                    height: 25px;
                                }
                            }

                            ${PreloaderWatch} {
                                display: block;
                            }
                        }
                    }
                }

                &.rsi-grid {

                    ${DataSignalGrid} {

                        ${DataSignals} {
                            font-size: 10px;

                            ${SignalN} {
                                font-size: 10px;
                                width: 18%;
                                padding: 0 10px;

                                ${WaveInfoContainer} {

                                    ${CoinInfoContainer} {

                                        ${CoinLogo} {
                                            width: 20px;
                                            height: 20px;
                                            margin-top: -23px;
                                            margin-right: 5px;
                                        }

                                        ${CoinName} {

                                        }
                                    }
                                }

                                svg {
                                    font-size: 8px;
                                }
                            }

                            ${SignalI} {
                                width: 7%;

                                svg {
                                    font-size: 8px;
                                }
                            }

                            ${SignalPrice},
                            ${SignalVol},
                            ${SignalMarketCap},
                            ${SignalPeakGains},
                            ${SignalLiveFibo} {
                                svg {
                                    font-size: 8px;
                                }
                            }

                            ${SignalPrice},
                            ${SignalVol} {
                                margin-left: 5px;
                            }
                        }
                    }

                    ${PreloaderWrapper} {
                        padding: 0px 20px 0;
                        &:last-child {
                            padding-bottom: 20px;
                        }

                        ${Preloader} {
                            margin-bottom: 0;

                            ${PreloaderLogo} {
                                width: 25px;
                                height: 25px;
                            }

                            ${PreloaderItem} {
                                height: 45px;

                                &.header {
                                    height: 25px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //#endregion

    //#region  media 1441 - 1739
    @media screen and (min-width: 1441px) and (max-width: 1739px) {
        font-size: 14px;
        padding: 40px 35px;

        ${SectionBlockInner} {

            ${SectionHeader} {

                ${SectionTitle} {
                    font-size: 25px;

                    svg {
                        font-size: 14px;
                    }
                }

                ${Filter} {

                    li {

                        form {
                            .form-group {
                                .form-control {
                                    font-size: 14px;
                                }

                                .btn-light {
                                    font-size: 14px;
                                    padding: 6px 10px;
                                }
                            }
                        }
                    }
                }
            }

            ${DataSignalGridWrapper} {

                &.price-grid {

                    .scanner-wrapper {
                        /* margin-right: 38px; */

                        ${DataSignalGrid} {

                            ${DataSignals} {
                                font-size: 14px;

                                ${SignalN} {
                                    width: 180px;
                                    padding: 0 10px;


                                    ${WaveInfoContainer} {

                                        ${CoinInfoContainer} {

                                            ${CoinLogo} {
                                                width: 25px;
                                                height: 25px;
                                            }

                                            ${CoinName} {

                                            }
                                        }
                                    }

                                    svg {
                                        font-size: 8px;
                                    }
                                }

                                ${SignalI} {
                                    width: 60px;

                                    ${WaveInfoContainer} {
                                        ${Wave} {
                                            width: 25px;
                                            height: 25px;
                                        }
                                    }

                                    svg {
                                        font-size: 8px;
                                    }
                                }

                                ${SignalPrice},
                                ${SignalVol},
                                ${SignalMarketCap},
                                ${SignalPeakGains},
                                ${SignalLiveFibo} {
                                    width: 124px;

                                    svg {
                                        font-size: 8px;
                                    }
                                }

                                ${SignalLiveFibo} {
                                    padding-right: 10px;
                                }

                                ${SignalPeakGains} {
                                    width: 112px;
                                }
                            }
                        }
                    }

                    ${PreloaderWrapper} {
                        padding: 0px 20px 0;
                        &:last-child {
                            padding-bottom: 20px;
                        }

                        ${Preloader} {
                            margin-bottom: 0;

                            ${PreloaderLogo} {
                                width: 35px;
                                height: 35px;
                            }

                            ${PreloaderItem} {
                                height: 55px;

                                &.header {
                                    height: 35px;
                                }
                            }

                            ${PreloaderWatch} {
                                display: block;
                                height: 20px;
                            }
                        }
                    }
                }

                &.rsi-grid {

                    ${DataSignalGrid} {

                        ${DataSignals} {
                            font-size: 14px;

                            ${SignalN} {
                                font-size: 14px;
                                width: 18%;
                                padding: 0 10px;

                                ${WaveInfoContainer} {

                                    ${CoinInfoContainer} {

                                        ${CoinLogo} {
                                            width: 20px;
                                            height: 20px;
                                            margin-top: -23px;
                                            margin-right: 5px;
                                        }

                                        ${CoinName} {

                                        }
                                    }
                                }

                                svg {
                                    font-size: 8px;
                                }
                            }

                            ${SignalI} {
                                width: 7%;

                                svg {
                                    font-size: 8px;
                                }
                            }

                            ${SignalPrice},
                            ${SignalVol},
                            ${SignalMarketCap},
                            ${SignalPeakGains},
                            ${SignalLiveFibo} {
                                svg {
                                    font-size: 8px;
                                }
                            }

                            ${SignalPrice},
                            ${SignalVol} {
                                margin-left: 5px;
                            }
                        }
                    }

                    ${PreloaderWrapper} {
                        padding: 0px 20px 0;
                        &:last-child {
                            padding-bottom: 20px;
                        }

                        ${Preloader} {
                            margin-bottom: 0;

                            ${PreloaderLogo} {
                                width: 25px;
                                height: 25px;
                            }

                            ${PreloaderItem} {
                                height: 45px;

                                &.header {
                                    height: 25px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //#endregion
`;
