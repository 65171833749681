import styled from 'styled-components'

export const CustomToggle = styled.label`
    display: block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    text-align: center;
    margin-bottom: 0;
    color: #545659;
    pointer-events: none;

    &.statistics {
	    text-align: right;
		color: #778698 !important;
	}

    &.active {
        pointer-events: auto;
		color: #778698;
		font-size: 15px;
		font-weight: 500;
    }

    i {
        position: relative;
        display: inline-block;
        margin-right: .5rem;
        width: 38px;
        height: 21px;
        background-color: #383e4e;
        border-radius: 23px;
        vertical-align: text-bottom;
        transition: all 0.3s linear;

        &.active {
            &::before {
                background-color: #19212b;
            }

            &::after {
                background-color: #ccd4de;
            }
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 33px;
            height: 17px;
            background-color: #545659;
            border-radius: 11px;
            transform: translate3d(2px,2px,0) scale3d(1,1,1);
            transition: all 0.25s linear;
        }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 16px;
            height: 16px;
            background-color: #707274;
            border-radius: 11px;
            box-shadow: 0 0px 2px 2px rgba(0,0,0,0.24);
            transform: translate3d(2px,2px,0);
            transition: all 0.2s ease-in-out;
        }
    }

    input {
        display: none;

        &:checked + i {
            background-color: #15bd6f;

            &::before {
                transform: translate3d(14px, 2px, 0) scale3d(0, 0, 0);
            }

            &::after {
                transform: translate3d(20px, 2px, 0);
                background-color: #25313f;
            }
        }
    }

    &:active {

        i {
            &::after {
                width: 32px;
                transform: translate3d(2px,2.3px,0);
            }
        }

        input {

            &:checked + i {

                &::after {
                    width: 32px;
                    transform: translate3d(3px,2px,0);
                }
            }
        }
    }
`