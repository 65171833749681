import { offersActionTypes } from '../CONSTANTS'
import { offerServices } from '../../services'

function skipOffer() {
    return dispatch => {
        dispatch(request())
        
        offerServices.skipOffer()
            .then(res => dispatch(success(res)), error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: offersActionTypes.SKIP_OFFER_REQUEST } }
    function success(response) { return { type: offersActionTypes.SKIP_OFFER_SUCCESS, response } }
    function failed(error) { return { type: offersActionTypes.SKIP_OFFER_FAILED, error } }
}

export const offerActions = {
    skipOffer
}