import React from 'react'
import { LockedPricingWrapper } from './styled_components'
import { Btn } from '../../styles'
import { isNotNull } from '../../utils'

export class LockedPricing extends React.Component {

    render() {
        const { user } = this.props

        return (
            <LockedPricingWrapper id="lockedPricing">
                <section className="section-block">
                    <div className="section-block-inner">
                        <div className="section-header">
                            <div className="locked-content">
                                <h2 className="title">
                                    You need to activate your account!
                                </h2>
                                <p className="sub-title">
                                    {isNotNull(user) && user.paymentStatus === 3 && user.paymentStatusTxt === 'EXPIRED' &&
                                        <>It looks like your membership has expired.&nbsp;</>
                                    }
                                    If you want access to the latest trading signals and our auto-trade feature please hit the subscribe button below and choose the membership package that suits you best.
                                </p>
                                <p className="sub-title">
                                    See you&nbsp;
                                    {isNotNull(user) && user.paymentStatus === 3 && user.paymentStatusTxt === 'EXPIRED' &&
                                        <>again&nbsp;</>
                                    }
                                    soon ProfitFarmer!
                                </p>
                            </div>
                        </div>
                        <div className="section-body">
                            <div className="lock-btn">
                                <Btn className="btn btn-deposit" onClick={this.props.modalToggle} variant="primary">
                                    <span>Subscribe Now</span>
                                </Btn>
                            </div>
                        </div>
                    </div>
                </section>
            </LockedPricingWrapper>
        )
    }
}