import { useEffect, useState } from 'react'
import moment from 'moment'
import { SafeAnchor } from 'react-bootstrap'
import { DynamicCheckout } from '../../../../components'
import { GorgeOnCryptoSectionStyle } from '../styles'

export const SMGorgeOnCrypto = (props) => {
    let [remainingDays, setRemainingDays] = useState('')
    let [remainingHours, setRemainingHours] = useState('')
    let [remainingMinutes, setRemainingMinutes] = useState('')
    let [remainingSeconds, setRemainingSeconds] = useState('')

    useEffect(() => {
        let parsedDate = Date.parse(props.validUntil)
        let expiry = new Date(parsedDate)
            expiry = moment(expiry).format('MM/DD/yyyy h:mm:ss')

        const second = 1000,
            minute = second * 60,
            hour = minute * 60,
            day = hour * 24;

        let today = new Date(),
            dd = String(today.getDate()).padStart(2, "0"),
            mm = String(today.getMonth() + 1).padStart(2, "0"),
            yyyy = today.getFullYear(),
            nextYear = yyyy + 1,
            dayMonth = moment(expiry).format('MM/DD/')
    
        today = mm + "/" + dd + "/" + yyyy
        if (today > expiry) {
            expiry = dayMonth + nextYear
        }

        const countDown = new Date(expiry).getTime()
        const x = setInterval(function() {    
            const now = new Date().getTime(),
                distance = countDown - now

            setRemainingDays(Math.floor(distance / (day)))
            setRemainingHours(Math.floor((distance % (day)) / (hour)))
            setRemainingMinutes(Math.floor((distance % (hour)) / (minute)))
            setRemainingSeconds(Math.floor((distance % (minute)) / second))

            if (distance < 0) {
                clearInterval(x)
            }
            //seconds
        }, 1000)
        
        return () => clearInterval(x)
    }, [])

    return (
        <GorgeOnCryptoSectionStyle>
            <div className='container'>
                <h2 className='section-title'>
                    <span>Gorge On Crypto Profits All Year Round - Only <span className='crashed-out-price'>$2,800</span> Now Just <span className='new-price'>$899</span></span>
                </h2>
                <p>You can save more than $1,900 and receive all of the bonus items listed above but only if you act now!</p>
                <h3>This price ends in</h3>

                <div className='offer-validity-timer'>
                    <ul>
                        <li>
                            <span id='g-days' className='counter'>{parseInt(remainingDays) < 10 ? '0': ''}{remainingDays}</span>
                            <small>DAYS</small>
                        </li>
                        <li>
                            <span id='g-hours' className='counter'>{parseInt(remainingHours) < 10 ? '0': ''}{remainingHours}</span>
                            <small>HOURS</small>
                        </li>
                        <li>
                            <span id='g-minutes' className='counter'>{parseInt(remainingMinutes) < 10 ? '0': ''}{remainingMinutes}</span>
                            <small>MINUTES</small>
                        </li>
                        <li>
                            <span id='g-seconds' className='counter'>{parseInt(remainingSeconds) < 10 ? '0': ''}{remainingSeconds}</span>
                            <small>SECONDS</small>
                        </li>
                    </ul>
                </div>

                <div className='checkout-btn'>
                    <DynamicCheckout 
                        text="Upgrade Now"
                        position="centered" 
                        userInfo={props.userInfo} 
                        offer={props.subscriptionPlan} />
                </div>

                <p className='little-note'>Note: Crypto payments are welcome, please <SafeAnchor href="https://www.profitfarmers.com/crypto-payment/" rel="noreferrer">contact our support team directly to organize this</SafeAnchor></p>
            </div>
        </GorgeOnCryptoSectionStyle>
    )
}