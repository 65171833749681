import { forgotPassActionTypes } from "../CONSTANTS";

export function forgotPass(state = {}, action) {
    switch (action.type) {
        case forgotPassActionTypes.FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };

        case forgotPassActionTypes.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.response
            };

        case forgotPassActionTypes.FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        default:
            return state
    }
}