import styled from 'styled-components'

export const UnleashTheTradingBeastSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    padding: 60px 0;
    background-image: url(/images/trial-offer/introsection-bg.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;

    .container {
        text-align: center;
        color: #FFFFFF;

        .section-title {
            font-size: 40px;
            font-weight: 600;
            color: #FFFFFF;
            margin: 0 auto 25px;
            max-width: 810px;
            text-align: center;

            .em {
                color: #15bd6f;
            }
        }

        h3 {
            font-size: 25px;
        }

        p {
            font-size: 17px;
            max-width: 700px;
            margin: 0 auto 25px;
            
            &.little-note {
                max-width: 100%;
                font-size: 16px;
            }

            .daily-rate {
                color: #16BD70;
            }
        }

        .offer-validity-timer {
            ul {
                margin: 30px 0 40px;
                padding: 0;
                list-style-type: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                li {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 0 15px;
                    color: #FFFFFF;
                    line-height: 1;

                    .counter {
                        font-size: 60px;
                        font-weight: 600;
                    }

                    small {
                        font-size: 12px;
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }
`