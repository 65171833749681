import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import {
    DataSignalGridWrapper, DataSignalGrid, DataSignals,
    SignalN, SignalI, ToggleButton, DataActions, DataPreActions,
    CoinLogo, CoinName, SignalPrice, SignalVol, SignalMarketCap,
    SignalPeakGains, SignalLiveFibo, WaveInfoContainer,
    Wave, CoinInfoContainer,
    GridBlock, MoreInfoWrapper,
    MoreInfo, MoreInfoTitle, MoreInfoValue
} from '../styled_components'
import { getCoin, setCoinLogo, setDefaultImage } from '../../../utils'

export class PriceActionItem extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            watch: this.props.watchListed
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isWatched !== this.props.isWatched)
            this.setState({ watch: this.props.watchListed })
    }

    onGetWaveStyleHandler(waveValue) {
        switch (waveValue) {
            default: { // this means this is already zero
                return "neutral filled"
            }
            case 3: {
                return "big-raise filled"
            }
            case 2: {
                return "small-raise filled"
            }
            case 1: {
                return "neutral filled"
            }
            case -1: {
                return "neutral filled"
            }
            case -2: {
                return "small-drop filled"
            }
            case -3: {
                return "big-drop filled"
            }
        }
    }

    onGetFilterWaveDataHandler(filterValue) {
        let { peakStats } = this.props
        switch (filterValue) {
            case "H6":
                return peakStats.h6
            case "H12":
                return peakStats.h12
            case "D1":
                return peakStats.d1
            case "D2":
                return peakStats.d2
            case "D3":
                return peakStats.d3
            case "D7":
                return peakStats.d7
            case "D15":
                return peakStats.d15
            case "D30":
                return peakStats.d30
            case "D100":
                return peakStats.d100
            case "D200":
                return peakStats.d200
            case "D500":
                return peakStats.d500
            default:
                return peakStats.h6
        }
    }

    onShowModalHandler = (e) => {
        var data = {
            n: e.n,
            cp: e.cp,
            sm5: e.sm5,
            sm15: e.sm15,
            sm30: e.sm30,
            sh1: e.sh1,
            sh4: e.sh4,
            sh6: e.sh6,
            sh12: e.sh12,
            sd1: e.sd1,
            logo: e.logo,
            strength: e.strength
        }

        this.props.show(data)
    }

    onSaveWatchedPairedHandler = (data) => {
        let flag = false
        if (data.isWatched !== false) {
            flag = false
            this.setState({ watch: true })
        }
        else {
            flag = true
            this.setState({ watch: false })
        }
        this.props.handleChange(data.n, flag)
    }

    onExpand = (coinName) => this.props.onExpand(coinName)

    render() {
        const { watch } = this.state
        let { cp } = this.props
        const { paFilter, n, sm5, sm15, sm30, sh1, sh4, sh6, sh12, sd1, mc, strength, selectedMoved } = this.props

        const sm5Style = this.onGetWaveStyleHandler(sm5)
        const sm15Style = this.onGetWaveStyleHandler(sm15)
        const sm30Style = this.onGetWaveStyleHandler(sm30)
        const sh1Style = this.onGetWaveStyleHandler(sh1)
        const sh4Style = this.onGetWaveStyleHandler(sh4)
        const sh6Style = this.onGetWaveStyleHandler(sh6)
        const sh12Style = this.onGetWaveStyleHandler(sh12)
        const sd1Style = this.onGetWaveStyleHandler(sd1)
        const pg = this.onGetFilterWaveDataHandler(paFilter).pg + "%"
        const pl = this.onGetFilterWaveDataHandler(paFilter).pl + "%"
        const fibo = this.onGetFilterWaveDataHandler(paFilter).hhcmp + "%"
        const fiboDown = this.onGetFilterWaveDataHandler(paFilter).llcmp + "%"
        const logoImage = setCoinLogo(getCoin(n))
        const volume = Math.trunc(Math.round(this.onGetFilterWaveDataHandler(paFilter).v, 0)).toLocaleString()
        const convertedMktCap = Math.round(Math.abs(Number(mc)) / 1.0e+6, 0)
        const mktCap = `${Math.trunc(convertedMktCap).toLocaleString()} M`
        const peakGains = selectedMoved === "up" ? pg : pl
        const liveFibo = selectedMoved === "up" ? fibo : fiboDown
        const moreInfoClassAssign = (watch) ? 'watch-active' : ''
        const dataSignalStrengthClassAssign = `grid-col ${strength}`
        cp = (cp % 1) === 0 ? parseInt(cp) : cp

        return (
            <DataSignalGridWrapper className="theme-grid-wrapper">
                <DataSignalGrid className="theme-grid">
                    <DataPreActions>
                        <ToggleButton
                            onClick={() => this.onExpand(n)}>
                            <FontAwesomeIcon icon={this.props.onDisplay(n) === "block" ? faChevronUp : faChevronDown} className="icon" />
                        </ToggleButton>
                    </DataPreActions>
                    <DataSignals className={`str-${n} ${dataSignalStrengthClassAssign} data-grid-signal`} onClick={strength != null ? () => this.onShowModalHandler(this.props) : null}>
                        <SignalN>
                            <WaveInfoContainer>
                                <CoinInfoContainer>
                                    <CoinLogo>
                                        <img className="img-fluid" onError={setDefaultImage} src={logoImage} alt="coin logo" />
                                    </CoinLogo>
                                    <CoinName>{n}</CoinName>
                                </CoinInfoContainer>
                            </WaveInfoContainer>
                        </SignalN>
                        <SignalI>
                            <WaveInfoContainer>
                                <Wave className={`s1-${n} ${sm5Style}`} />
                            </WaveInfoContainer>
                        </SignalI>
                        <SignalI>
                            <WaveInfoContainer>
                                <Wave className={`s2-${n} ${sm15Style}`} />
                            </WaveInfoContainer>
                        </SignalI>
                        <SignalI>
                            <WaveInfoContainer>
                                <Wave className={`s3-${n} ${sm30Style}`} />
                            </WaveInfoContainer>
                        </SignalI>
                        <SignalI>
                            <WaveInfoContainer>
                                <Wave className={`s4-${n} ${sh1Style}`} />
                            </WaveInfoContainer>
                        </SignalI>
                        <SignalI>
                            <WaveInfoContainer>
                                <Wave className={`s5-${n} ${sh4Style}`} />
                            </WaveInfoContainer>
                        </SignalI>
                        <SignalI>
                            <WaveInfoContainer>
                                <Wave className={`s6-${n} ${sh6Style}`} />
                            </WaveInfoContainer>
                        </SignalI>
                        <SignalI>
                            <WaveInfoContainer>
                                <Wave className={`s7-${n} ${sh12Style}`} />
                            </WaveInfoContainer>
                        </SignalI>
                        <SignalI>
                            <WaveInfoContainer>
                                <Wave className={`s8-${n} ${sd1Style}`} />
                            </WaveInfoContainer>
                        </SignalI>
                        <SignalPrice className="text-right">
                            <span className={`cp-${n}`}>{cp.toLocaleString()}</span>
                        </SignalPrice>

                        <SignalVol className="text-right">
                            <span className={`volume-${n}`}>{volume}</span>
                        </SignalVol>

                        <SignalMarketCap className="text-right">
                            <span className={`mktCap-${n}`}>{mktCap}</span>
                        </SignalMarketCap>

                        <SignalPeakGains className="text-right" move={selectedMoved}>
                            <span className={`peakGains-${n} move-data`}>{peakGains}</span>
                        </SignalPeakGains>

                        <SignalLiveFibo className="text-right">
                            <span className={`liveFibo-${n}`}>{liveFibo}</span>
                        </SignalLiveFibo>
                    </DataSignals>
                    <DataActions className="grid-col has-btns pa-m">
                        <ToggleButton onClick={() => this.onSaveWatchedPairedHandler(this.props)}>
                            <FontAwesomeIcon icon={faEye} className={moreInfoClassAssign} ></FontAwesomeIcon>
                        </ToggleButton>
                    </DataActions>
                </DataSignalGrid>
                <GridBlock className={`grid-block`} style={{ display: this.props.onDisplay(n) }}>
                    <MoreInfoWrapper>
                        <MoreInfo className="scanner-info-wrapper">
                            <MoreInfoTitle>Coin Name</MoreInfoTitle>
                            <MoreInfoValue>{n}</MoreInfoValue>
                        </MoreInfo>
                        <MoreInfo>
                            <MoreInfoTitle>Price</MoreInfoTitle>
                            <MoreInfoValue className={`cp-${n}`}>{cp}</MoreInfoValue>
                        </MoreInfo>
                        <MoreInfo>
                            <MoreInfoTitle>{selectedMoved === "up" ? "Peak Gains" : "Peak Drop"}</MoreInfoTitle>
                            <MoreInfoValue className={`peakGains-${n} move-data`} move={selectedMoved}>{peakGains}</MoreInfoValue>
                        </MoreInfo>
                        <MoreInfo>
                            <MoreInfoTitle>Live Febo</MoreInfoTitle>
                            <MoreInfoValue className={`liveFibo-${n}`}>{liveFibo}</MoreInfoValue>
                        </MoreInfo>
                        <MoreInfo>
                            <MoreInfoTitle>Volume</MoreInfoTitle>
                            <MoreInfoValue className={`volume-${n}`}>{volume}</MoreInfoValue>
                        </MoreInfo>
                        <MoreInfo>
                            <MoreInfoTitle>Market Cap</MoreInfoTitle>
                            <MoreInfoValue className={`mktCap-${n}`}>{mktCap}</MoreInfoValue>
                        </MoreInfo>
                        <MoreInfo>
                            <ToggleButton onClick={() => this.onSaveWatchedPairedHandler(this.props)} className={moreInfoClassAssign}>
                                <FontAwesomeIcon icon={faEye}  ></FontAwesomeIcon> Add to Watchlist
                            </ToggleButton>
                        </MoreInfo>
                    </MoreInfoWrapper>
                </GridBlock>
            </DataSignalGridWrapper>
        )
    }
}
