import { signalDetailsActionTypes } from '../CONSTANTS'

export function signalDetails(state = {}, action) {
    switch (action.type) {
        case signalDetailsActionTypes.GET_BY_ID_REQUEST:
            return {
                ...state,
                loading: true,
                item: null
            }
        case signalDetailsActionTypes.GET_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                item: action.signalDetails
            }
        case signalDetailsActionTypes.GET_BY_ID_FAIL:
            return {
                ...state,
                loading: false,
                item: null,
                error: action.error
            }
        case signalDetailsActionTypes.UPDATE_SIGNAL_DETAILS:
            return {
                ...state,
                loading: false,
                item: action.signalDetails
            }
        default:
            return state
    }
}