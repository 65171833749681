import React from 'react'
import { CheckoutButton } from '../../styles'
import { isNotNull, isNull, onSetNewState } from '../../utils'

export class DynamicCheckout extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            text: '',
            position: '',
            userId: undefined,
            customerId: undefined,
            leadId: undefined,
            isLead: undefined,
            userName: undefined,
            firstName: undefined,
            lastName: undefined
        }

        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        const { text, position, userInfo } = this.props
        const { userId } = this.state

        if (isNotNull(text)) {
            onSetNewState(this, { 
                text 
            })
        }

        if (isNotNull(position)) {
            onSetNewState(this, {
                position
            })
        }

        if (isNull(userId) && isNotNull(userInfo)) {
            onSetNewState(this, {
                userId: userInfo.id,
                customerId: userInfo.customerId,
                leadId: userInfo.leadId,
                isLead: userInfo.isLead,
                userName: userInfo.userName,
                firstName: userInfo.firstName,
                lastName: userInfo.lastName
            })
        }
    }

    componentDidUpdate() {
        if (this.mounted) {
            const { userInfo, offer } = this.props
            const { isLoading, userId, subscriptionPlanId } = this.state
            
            if (isNull(userId) && 
                isNotNull(userInfo)) {
                onSetNewState(this, {
                    userId: userInfo.id,
                    customerId: userInfo.customerId,
                    leadId: userInfo.leadId,
                    isLead: userInfo.isLead,
                    userName: userInfo.userName,
                    firstName: userInfo.firstName,
                    lastName: userInfo.lastName
                })
            }

            if (isNull(subscriptionPlanId) && 
                isNotNull(offer)) {
                    onSetNewState(this, {
                        subscriptionPlanId: offer.id,
                        subscriptionRef: offer.refKey
                    })
                }

            if (isLoading && isNotNull(subscriptionPlanId) && isNotNull(userId)) {
                onSetNewState(this, {
                    isLoading: false
                })
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
        this.setState(this.baseState)
    }

    handlePayment = (e) => {
        e.preventDefault()

        const { ...data } = this.state
        if (isNotNull(data.userId)) {
            window.dataLayer.push({
                event: 'begin_checkout',
                user_id: data.userId
            })

            // create new payment session
            const newPaymentSession = {
                reset: true,
                products: [{
                    path: data.subscriptionRef,
                    quantity: 1
                }],
                paymentContact: {
                    email: data.userName,
                    firstName: data.firstName,
                    lastName: data.lastName
                },
                tags: {
                    userId: data.userId,
                    customerId: data.customerId,
                    leadId: data.leadId,
                    isLead: data.isLead,
                    sPlanId: data.subscriptionPlanId
                },
                checkout: true
            }
            window.fastspring.builder.push(newPaymentSession)
        }
    }

    render() {
        const { text, position } = this.state
        const key = Math.random()

        return (
            <CheckoutButton 
                key={key} 
                position={position} 
                type="button" 
                onClick={(e) => this.handlePayment(e)}>
                <span>{text}</span>
            </CheckoutButton>
        )
    }
}