import React from 'react'
import { isNull } from 'lodash'
import { Row, Col, Card, Form, InputGroup, OverlayTrigger, Tooltip, SafeAnchor } from 'react-bootstrap'
import { Form as FormFinal, Field, FormSpy } from "react-final-form"
import { OnChange } from 'react-final-form-listeners'
import { SRLWrapper } from 'simple-react-lightbox'
import { SettingsContentWrapper, CardBody, SettingsTitle, SettingsGroupWrapper, InputWrapper } from '../../styled_components'
import { AllowWrapper, FormWrapper, Inputs, ToggleButton, Btn, FormButton, TradingContent, FormSendPass } from './styled_components'
import { ErrorContainer } from '../../../../styles/layout'
import { isNotNull, onSetNewState, sleep, validateKey } from '../../../../utils'
import jQuery from 'jquery'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faEye, faTimes } from '@fortawesome/free-solid-svg-icons'
import { GenericModal } from '../../../../components/Modals/GenericModal'
import { tradingActions } from '../../../../redux/actions'

export const AutoTradeView = (props) => {
    const $this = props.context
    const $state = props.state
    const { isKeyLoading } = props.tradings
    const { isPaid, isAutoTradingAllowed, isAutoCloseEnabled, tradingApiKey, loading } = props.userInfo
    const { exchangeValueSelected, exchangeKeySelected, changeExchangeLoader } = props.state
    const isExchangeDisabled = isNotNull(isKeyLoading) ? isKeyLoading : true
    const lightboxOptions = {
        buttons: {
            showAutoplayButton: false,
            showThumbnailsButton: false
        }
    }

    //#region Events
    const onSubmitHandler = async values => {
        await sleep(300)
        const { user } = $this.props.authentication
        const { settings } = props.tradings

        if (values.allowTrade) {
            const autoTradeKeys = {
                id: user.customerId,
                asid: settings.data.id,
                exchangeId: exchangeKeySelected,
                exchangeApiKey: values.key,
                exchangeApiSecret: values.secret
            }

            props.onSaveExchangeKeysHandler(autoTradeKeys)
        }
        else
            props.onDisableTradingHandler()
    }

    const onValidateHandler = async values => {
        await sleep(300)
        let errors = {}
        let key = []
        let secret = []

        if (values.allowTrade) {
            if (typeof values.key === 'undefined')
                key.push("Please enter API Key")
            else {
                if (!validateKey(values.key))
                    key.push("Please enter a valid API key")

                if (values.key === values.secret)
                    key.push("Your API key cannot be used as your Secret key")
            }

            if (typeof values.secret === 'undefined')
                secret.push("Please enter Secret Key")
            else {
                if (!validateKey(values.secret))
                    secret.push("Please enter a valid Secret key")

                if (values.secret === values.key)
                    secret.push("Your Secret key cannot be used as your API key")
            }

            if (key.length > 0)
                errors.key = key

            if (secret.length > 0)
                errors.secret = secret

            return errors
        } else {
            const { settings } = $this.props.tradings

            if (isNotNull(settings))
                secret.push("Make sure that your settings is enabled.")

            return errors
        }
    }

    const onSubmitPasswordHandler = async values => {
        await sleep(300)
        const { sendPassword } = values
        const { dispatch } = $this.props
        const { failure } = $this.props.tradings

        if (sendPassword) {
            if (isNotNull(failure) && isNotNull(failure.isExist) && failure.isExist) {
                dispatch(tradingActions.existingTrailingUser(sendPassword))
            }
            else
                dispatch(tradingActions.authorizeUser(sendPassword))
        }
    }

    const onValidatePasswordHandler = async values => {
        await sleep(300)
        let errors = {}
        let sendPassword = []

        if (values.sendPassword === undefined)
            sendPassword.push("Please enter your password")

        if (sendPassword.length > 0)
            errors.sendPassword = sendPassword

        return errors
    }

    const onChangeAutoCloseTradeSettings = (e, value) => {
        e.preventDefault()

        const { settings } = props.tradings
        if (isNotNull(settings)) {
            let newSettings = {
                settingsId: settings.data.id,
                isAutoCloseEnabled: value
            }
            props.onChangeAutoCloseTradeSettings(newSettings)
        }
    }

    const onShowWarningHandler = () => onSetNewState($this, { warning: true })

    const onCloseWarningHandler = () => onSetNewState($this, { warning: false })

    const onCloseModalHandler = () => {
        onSetNewState($this, {
            autoTradingModal: false,
            eyeIcon: faEye,
            passwordType: 'password'
        })

        //check the current allow using jquery
        const getCurrentSelectedSettings = $this.formInput.getFieldState('allowTrade').value

        if (getCurrentSelectedSettings === true) {
            if (isNotNull(isAutoTradingAllowed) && !isAutoTradingAllowed)
                $this.formInput.change('allowTrade', false)
            else
                $this.formInput.change('allowTrade', false)
        }

        $this.onDismissErrorHandler()
    }

    const onPassedFormHandler = (form) => {
        $this.formInput = form
    }

    const onHandleEyeIconVisibility = () => $this.onVisibilitySetState()

    //#endregion

    //#region Render
    const renderLoader = () => (
        <>
            <div className="pf-spinner xs" style={{ marginTop: '-49px', marginRight: '32px', verticalAlign: 'middle' }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </>
    )

    const renderVideo = () => (
        <AllowWrapper>
            <iframe
                title="How to Connect Your Binance Account to ProfitFarmers"
                src="https://www.youtube.com/embed/NhCycW1yEKg"
                frameBorder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen />
        </AllowWrapper>
    )

    const renderStep1Helper = () => (
        <SRLWrapper options={lightboxOptions}>
            <div style={{ marginTop: '10px', border: '6px solid rgb(11 18 25)' }}>
                <img src="/images/auto-trade-settings-step-1.gif" alt="Generate exchange API keys in Binance.com" style={{ width: '100%' }} />
            </div>
        </SRLWrapper>
    )
    
    const renderStep2Helper = () => (
        <SRLWrapper options={lightboxOptions}>
            <div style={{ marginTop: '10px', border: '6px solid rgb(11 18 25)' }}>
                <img src="/images/auto-trade-settings-step-2.png" alt="Copy Paste API Key & Secret Key to connect your exchange account" style={{ width: '100%' }} />
            </div>
        </SRLWrapper>
    )    
    
    const renderStep3Helper = () => (
        <SRLWrapper options={lightboxOptions}>
            <div style={{ marginTop: '10px', border: '6px solid rgb(11 18 25)' }}>
                <img src="/images/auto-trade-settings-step-3.png" alt="Edit Restrictions & IP Whitelisting" style={{ width: '100%' }} />
            </div>
        </SRLWrapper>
    )

    const renderFormLabel = () => {
        const width = window.innerWidth

        if (width >= 766)
            return (<Form.Label>Allow Auto-trade</Form.Label>)
        else
            return (<Form.Label>Allow</Form.Label>)
    }

    const renderTradeGuardianLabel = () => {
        const width = window.innerWidth
        if (width >= 766)
            return (<Form.Label>Activate Trade Guardian</Form.Label>)
        else
            return (<Form.Label>Trade Guardian</Form.Label>)
    }

    const renderAutoTradeChange = (field) => (
        <Field name={field} subscription={{}}>
            {() => (
                <>
                    {
                        field === 'allowTrade' &&
                        <FormSpy subscription={{}}>
                            {() => (
                                <OnChange name={field} >
                                    {value => {
                                        if (value) {
                                            // $this.onDispatchGetMeHandler()
                                            jQuery('#enable-show').removeClass('enable-content-display')

                                            if (!isAutoTradingAllowed && tradingApiKey === null) {
                                                onSetNewState($this, { autoTradingModal: true })
                                            }
                                            else {
                                                if ((value.key !== null || value.key !== '') && (value.secret !== null || value.secret !== ''))
                                                    jQuery('.btn-delete').removeAttr('disabled')
                                                else
                                                    jQuery('.btn-delete').attr('disabled', true)

                                                jQuery('.eks').addClass('eks-display')
                                                jQuery('.divider').css({ display: 'block' })
                                                jQuery('.note-wrapper').css({ display: 'flex' })
                                            }
                                        }
                                        else {
                                            jQuery('.btn-delete').attr('disabled', true)
                                            jQuery('.eks').removeClass('eks-display')
                                            jQuery('.divider').css({ display: 'none' })
                                            jQuery('.note-wrapper').css({ display: 'none' })
                                            jQuery('#enable-show').addClass('enable-content-display')
                                        }
                                    }}
                                </OnChange>
                            )}
                        </FormSpy>
                    }
                    {
                        field === 'exchange' &&
                        <FormSpy subscription={{}}>
                            {() => (
                                <OnChange name={field}>
                                    {(value, key) => {
                                        if (isNotNull(value)) {
                                            const { exchange } = $this.props.tradings
                                            if (isNotNull(exchange)) {
                                                if (exchange.length > 0) {
                                                    const findExchange = _.find(exchange, e => e.value.toLowerCase() === value)
                                                    if (isNotNull(findExchange))
                                                        $this.onUpdateCurrentExchange(value)
                                                }
                                            }

                                            onSetNewState($this, {
                                                exchangeValueSelected: value,
                                                exchangeKeySelected: key,
                                                isAutoTradingAllowed: true
                                            })
                                        }
                                    }}
                                </OnChange>
                            )}
                        </FormSpy>
                    }
                </>
            )}
        </Field>
    )

    const renderAutoTrade = () => {
        const { keys, isSaving, isDeleting } = props.tradings
        const key = isNull(keys) ? null : keys

        return (
            <FormWrapper>
                <FormFinal
                    initialValues={{
                        key: isNotNull(key) ? key.exchangeApiKey : '',
                        secret: isNotNull(key) ? key.exchangeApiSecret : '',
                        allowTrade: $state.isAutoTradingAllowed,
                        exchange: exchangeValueSelected
                    }}
                    onSubmit={onSubmitHandler}
                    validate={onValidateHandler}
                    render={({ form, values, pristine, handleSubmit }) => (
                        <Form onSubmit={
                            event => {
                                const promise = handleSubmit(event)
                                promise && promise.then(() => {
                                    setTimeout(() => {
                                        values.secret = 'YOUCANTSEEME'
                                        form.reset(values)
                                    }, 2500)
                                })
                                return promise
                            }
                        } style={{ paddingBottom: !isAutoTradingAllowed ? '40px' : '0' }}>
                            {onPassedFormHandler(form)}
                            <Form.Group className='allow-input'>
                                <SettingsTitle>
                                    Auto-Trade <br />
                                    <p style={{ marginTop: '10px', fontSize: '14px', color: '#c4daf1', fontWeight: 400, marginBottom: 0 }}>Enable/disable Auto Trading Functions</p>
                                </SettingsTitle>

                                <SettingsGroupWrapper>
                                    <Row>
                                        <Col bsPrefix='col-4' className='col-form-label'>
                                            {renderFormLabel()}
                                        </Col>
                                        <Col bsPrefix='col-8 col-form-label'>
                                            <div className='switch-select'>
                                                <ToggleButton className='settings-toggle active'>
                                                    <Field name='allowTrade' type='checkbox'>
                                                        {({ input }) => (
                                                            <>
                                                                <div className='input-cont' style={{ justifyContent: 'end'}}>
                                                                    <p className='enable-content help-text' id='enable-show'></p>&nbsp;

                                                                    <span className='toggle-wrapper'>
                                                                        <span className='toggle-label'> {(input.checked) ? 'Yes' : 'No'} </span>
                                                                        <input type='checkbox' {...input} checked={(input.checked)} />

                                                                        <i className={`settings-toggle active`} />
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Field>
                                                </ToggleButton>
                                            </div>
                                        </Col>
                                    </Row>

                                    { !loading && isAutoTradingAllowed &&
                                        <>
                                            <Row>
                                                <Col bsPrefix='col-9' className="col-form-label">
                                                    <Form.Label className="mb-0">
                                                        {renderTradeGuardianLabel()}
                                                        <OverlayTrigger 
                                                            placement="top" 
                                                            delay={(window.innerWidth > 766 && { show: 200, hide: 1000 })}
                                                            overlay={
                                                                <Tooltip id='auto-close-trade-tooltip' style={{ zIndex: '9999' }}>
                                                                    To learn more about this function, click <SafeAnchor href={ isPaid ? 'https://pro-support.profitfarmers.com/article/88-trade-guardian-automatically-closes-close-early-trades' : 'https://support.profitfarmers.com/article/225-trade-guardian-automatically-closes-close-early-trades' } target="_blank" rel="noreferrer">here</SafeAnchor>.
                                                                </Tooltip>
                                                            }>
                                                            <span style={{ marginLeft: '10px', fontSize: '16px' }}><i className="fas fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </Form.Label>
                                                </Col>
                                                <Col bsPrefix='col-3 col-form-label' style={{ cursor: 'pointer', textAlign: 'right' }}>
                                                    { $state.isUpdatingAutoCloseSettings && renderLoader()}
                                                    { !$state.isUpdatingAutoCloseSettings && 
                                                        <>
                                                            <div className="switch-select" onClick={(e) => onChangeAutoCloseTradeSettings(e, !isAutoCloseEnabled)}>
                                                                <ToggleButton className={`settings-toggle ${isAutoCloseEnabled ? 'active' : ''}`}>
                                                                    <div className='input-cont'>
                                                                        <p className='enable-content help-text'></p>&nbsp;
                                                                        <span className='toggle-wrapper'>
                                                                            <span className='toggle-label'>{ isAutoCloseEnabled ? 'Yes' : 'No'}</span>
                                                                            <input type='checkbox' 
                                                                                id="allow-auto-close-trade"
                                                                                name="auto-close-trade-settings"
                                                                                checked={isAutoCloseEnabled}
                                                                                readOnly />

                                                                            <i className={`settings-toggle active`} />
                                                                        </span>
                                                                        
                                                                    </div>
                                                                </ToggleButton>
                                                            </div>
                                                        </>
                                                    }
                                                </Col>
                                            </Row>
                                        </>                                        
                                    }
                                </SettingsGroupWrapper>
                            </Form.Group>

                            {renderAutoTradeChange('allowTrade', 'eks')}
                            {renderAutoTradeChange('exchange', '')}
                            {
                                !loading && isAutoTradingAllowed !== 'undefined' &&
                                <>
                                    <Form.Group className='eks exchange-inputs'>
                                        <SettingsTitle>
                                            Step 1: Generate exchange API keys in <a href="https://www.binance.com/" target="_blank" rel="noreferrer">Binance.com</a>
                                        </SettingsTitle>

                                        <SettingsGroupWrapper>
                                            <ol style={{ marginBottom: '25px', lineHeight: '1.8' }}>
                                                <li>Log into Binance and go to <a href="https://www.binance.com/en/my/settings/api-management" target="_blank" rel="noreferrer">API Section</a></li>
                                                <li>Create a new API KEY</li>
                                                <li>Keep this tab open (DO NOT leave/refresh the page)</li>
                                            </ol>

                                            {/* <p className="mb-5">Login to Binance and go to API section. <br />Create a new API KEY with all permissions enabled except withdrawal</p> */}

                                            <Row>
                                                <Col bsPrefix="col-md-3 col-xs-12 col-sm-12" className="col-form-label">
                                                    <Form.Label>Choose Spot or Futures:</Form.Label>
                                                </Col>
                                                <Col bsPrefix="col-md-9 col-xs-12 col-sm-12">
                                                    <Field name="exchange" type="button">
                                                        {({ input }) => (
                                                            <>
                                                                <Inputs inputType="select" disabled={isExchangeDisabled} value={$state.exchangeValueSelected} id="exchange" as="select" {...input} className="form-control">
                                                                    {$state.exchangeDisplay}
                                                                </Inputs>

                                                                <p className="help-text">This is the exchange that you wish to connect to your ProfitFarmers account</p>
                                                            </>
                                                        )}
                                                    </Field>                                                    

                                                    {renderStep1Helper()}
                                                </Col>
                                            </Row>
                                            
                                            <div className="alert alert-info" style={{ marginTop: '25px', color: '#fff', border: 'none', backgroundColor: 'rgb(14 24 35)' }}>
                                                <i className="fas fa-question-circle" style={{ marginRight: '8px' }}></i> 
                                                <span>Need help? <a href={ isPaid ? 'https://pro-support.profitfarmers.com/article/38-how-to-connect-your-binance-account-to-profitfarmers-via-api-key' : 'https://support.profitfarmers.com/article/176-how-to-connect-your-binance-account-to-profitfarmers-via-api-key' }>Read our guide to connecting Binance Spot or Futures API keys</a>.</span>
                                            </div>
                                        </SettingsGroupWrapper>
                                    </Form.Group>

                                    <Form.Group className="eks key-input">
                                        <SettingsTitle>
                                            Step 2: Copy Paste API Key & Secret Key to connect your exchange account
                                        </SettingsTitle>
                                        {
                                            changeExchangeLoader ?
                                                <SettingsGroupWrapper className="pb-0">
                                                    <p className="mb-5 text-center">
                                                        <span className="spinner-border spinner-border-sm mb-1" role="status">
                                                        </span>
                                                        <span className="ml-2">
                                                            Please wait ..
                                                    </span>
                                                    </p>
                                                </SettingsGroupWrapper>
                                                :
                                                <SettingsGroupWrapper className="pb-0">
                                                    <ol style={{ marginBottom: '25px', lineHeight: '1.8' }}>
                                                        <li>Copy API KEY into "This is your Binance API Key" field in ProfitFarmers</li>
                                                        <li>Copy SECRET KEY into "This is your Binance Secret Key" field in ProfitFarmers</li>
                                                    </ol>

                                                    <div className="alert alert-warning">
                                                        ATTENTION: Make sure you copy the actual code and not ******. If you can no longer see the Secret Key in Binance, you have to delete the previous API and redo Step 1
                                                    </div>
                                                
                                                    <Row>
                                                        <Col bsPrefix="col-md-3 col-xs-12 col-sm-12" className="col-form-label">
                                                            <Form.Label>API Key:</Form.Label>
                                                        </Col>
                                                        <Col bsPrefix="col-md-9 col-xs-12 col-sm-12">
                                                            <Field name="key" type="text">
                                                                {({ input, meta }) => (
                                                                    <>
                                                                        <Inputs
                                                                            disabled={isDeleting}
                                                                            ref={input => props.onSetApiKeyInputHandler(input)}
                                                                            inputType="input"
                                                                            as="input"
                                                                            {...input}
                                                                            className="form-control"
                                                                            autoComplete="off"
                                                                            placeholder="Enter API key" />

                                                                        <ErrorContainer className={`${meta.error && meta.touched ? 'invalid api-keys' : ''}`}>
                                                                            {meta.error && meta.touched && (<><FontAwesomeIcon icon={faExclamationCircle} className="icon" /><span>{meta.error}</span></>)}
                                                                        </ErrorContainer>

                                                                        <p className="help-text">This is your Binance API Key</p>
                                                                    </>
                                                                )}
                                                            </Field>
                                                        </Col>
                                                    </Row>
                                                </SettingsGroupWrapper>
                                        }
                                    </Form.Group>

                                    <Form.Group className="eks secret-input">
                                        {
                                            changeExchangeLoader === false &&
                                            <SettingsGroupWrapper className="pt-0">
                                                <Row>
                                                    <Col bsPrefix="col-md-3 col-xs-12 col-sm-12" className="col-form-label">
                                                        <Form.Label>Secret Key:</Form.Label>
                                                    </Col>
                                                    <Col bsPrefix="col-md-9 col-xs-12 col-sm-12">
                                                        <Field name="secret" type="password" >
                                                            {({ input, meta }) => (
                                                                <>
                                                                    <Inputs
                                                                        inputType="input"
                                                                        as="input"
                                                                        {...input}
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        placeholder="YOUCANTSEEME"
                                                                    />

                                                                    <ErrorContainer className={`${meta.error && meta.touched ? 'invalid api-keys' : ''}`}>
                                                                        {meta.error && meta.touched && (<><FontAwesomeIcon icon={faExclamationCircle} className="icon" /><span>{meta.error}</span></>)}
                                                                    </ErrorContainer>

                                                                    <p className="help-text">This is your Binance Secret Key</p>
                                                                </>
                                                            )}
                                                        </Field>
                                                        
                                                        {renderStep2Helper()}
                                                    </Col>
                                                </Row> 
                                            </SettingsGroupWrapper>
                                        }
                                    </Form.Group>

                                    { isAutoTradingAllowed && 
                                        <>
                                            <Form.Group>
                                                <SettingsTitle>
                                                    Step 3: Edit Restrictions & IP Whitelisting
                                                </SettingsTitle>
                                                <SettingsGroupWrapper className="pb-0">
                                                    <ol style={{ marginBottom: '25px', lineHeight: '1.8' }}>
                                                        <li>Click "Edit restrictions" in Binance API</li>
                                                        <li>Enable Spot & Margin Trading</li>
                                                        <li>Enable Futures Trading (optional - Futures trading on Binance is restricted in NL, DE, IT, AU & HK)</li>
                                                        <li>
                                                            Restrict access to trusted IPs only <b>(optional but recommended)</b>
                                                            <ul>
                                                                <li>Enter: <b style={{ fontSize: '20px', color: '#f5ca1e' }}>46.101.72.75</b></li>
                                                                <li>Click Confirm</li>
                                                            </ul>
                                                        </li>
                                                        <li>Click "Save" in Binance</li>
                                                        <li>Click "Save and Continue" in ProfitFarmers</li>
                                                    </ol>

                                                    <p>To connect your futures account, go to step 1 and change "Exchange" to "Binance Futures"</p>

                                                    <p>If you have activated "Enable Futures" in your API, you can use the same API key. Alternatively, you can create a new API key for futures integration.</p>

                                                    {/* <p>
                                                        <SafeAnchor href="https://profitfarmers.freshdesk.com/support/solutions/articles/47001183203-how-to-create-your-binance-futures-api-and-connect-it-to-profitfarmers" className="mb-5" target="_blank">How to create your Binance Futures API and connect it to ProfitFarmers</SafeAnchor>
                                                    </p>
                                                    <p className="mb-5">Allow access to <b>46.101.72.75</b> or 'all IPs' while creating API key/secret on exchange</p> */}

                                                    {renderStep3Helper()}
                                                </SettingsGroupWrapper>
                                            </Form.Group>

                                            <Form.Group>
                                                <SettingsGroupWrapper style={{ paddingBottom: '100px', marginTop: '-10px' }}>
                                                    <div className="alert" style={{ backgroundColor: 'rgb(14 24 35)' }}>
                                                        <i className="fas fa-question-circle" style={{ marginRight: '8px' }}></i> 
                                                        <a href={ isPaid ? 'https://pro-support.profitfarmers.com/article/28-issues-connecting-binance-api' : 'https://support.profitfarmers.com/article/205-issues-connecting-binance-api-with-profitfarmers-troubleshoot-problems-here' } target="_blank" rel="noreferrer">Issues connecting your Binance.com account with ProfitFarmers? Get help here...</a>
                                                    </div>
                                                </SettingsGroupWrapper>
                                            </Form.Group>
                                        </>
                                    }
                                </>
                            }


                            <FormButton className="text-center settings-button">
                                <SettingsGroupWrapper className="auto-trade-buttons">
                                    <Btn type="submit" disabled={(pristine || isSaving) && isAutoTradingAllowed} variant="primary" className="btn-submit mr-2">
                                        {isSaving && <> <i className="fas fa-spinner fa-pulse mr-1"></i> Saving... </>}
                                        {!isSaving && <> Save and Continue </>}
                                    </Btn>

                                    <Btn type="reset" variant="secondary" className="btn-delete" onClick={onShowWarningHandler}>
                                        {isDeleting && <> <i className="fas fa-spinner fa-pulse mr-1"></i> Deleting... </>}
                                        {!isDeleting && <> Delete </>}
                                    </Btn>
                                </SettingsGroupWrapper>
                            </FormButton>

                        </Form>
                    )}
                />
            </FormWrapper>
        )
    }

    const renderAutoTradeModalBody = () => {
        const { loader } = $this.props.tradings
        const { passwordType, eyeIcon } = $this.state

        return (
            <>
                <TradingContent>
                    <p id="modal-pass-confirm">Please enter your ProfitFarmers account password to confirm.</p>
                </TradingContent>

                <FormFinal
                    onSubmit={onSubmitPasswordHandler}
                    validate={onValidatePasswordHandler}
                    render={({ handleSubmit, pristine }) => (
                        <Form onSubmit={handleSubmit}>
                            <input type="hidden" />

                            <Form.Group controlId="formPassword" className="removedIfExisting" >
                                <FormSendPass>
                                    <Field name="sendPassword" type={passwordType}>
                                        {({ input, meta }) => (
                                            <>

                                                <InputWrapper>
                                                    <InputGroup>
                                                        <Form.Control
                                                            placeholder="Password"
                                                            autoComplete="off"
                                                            {...input}
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text onClick={onHandleEyeIconVisibility}>
                                                                <FontAwesomeIcon icon={eyeIcon} className="eyeIcon" />
                                                            </InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </InputWrapper>

                                                <ErrorContainer className={`${meta.error && meta.touched ? 'settings invalid' : ''}`}>
                                                    {meta.error && meta.touched && (<>&nbsp; <FontAwesomeIcon icon={faExclamationCircle} className="icon" /><span>{meta.error}</span></>)}
                                                </ErrorContainer>
                                            </>
                                        )}
                                    </Field>
                                </FormSendPass>
                            </Form.Group>

                            <FormButton className="removedIfExisting" style={{ display: 'flex', justifyContent: 'center', margin: '15px 0 5px' }}>
                                <Btn type="submit" variant="primary" disabled={pristine || loader} className="submitPass save-btn">
                                    {loader ?
                                        <>
                                            <i className="fas fa-spinner fa-pulse mr-1"></i> Submitting...
                                            </>
                                        : <>Submit</>
                                    }
                                </Btn>
                            </FormButton>
                        </Form>
                    )}
                />
            </>
        )
    }

    const renderRemoveBodyModal = () => {

        return (<>
            <div className="icon-warning">
                <i className="fas fa-exclamation-triangle"></i>
            </div>

            <h3>Warning!</h3>
            <p className="mb-2">If you delete this API key, any existing and open trade orders will FAIL. Make sure you have no open trades!</p>
            <p className="mb-4">Are you absolutely sure you are ready to delete this Key?</p>

            <div className="asl-warning-buttons">
                <Btn variant="primary" className="btn-yes" onClick={() => props.onDeleteExchangeKeys()}>
                    <span> YES </span>
                </Btn>

                <Btn variant="danger" className="btn-no" onClick={() => onCloseWarningHandler()}>
                    <span> NO </span>
                </Btn>
            </div>
        </>)
    }

    //#endregion

    return (
        <>
            <SettingsContentWrapper className="settings-trade account-settings-wrapper">
                <Row>
                    <Col bsPrefix="col">
                        <Card>
                            <Card.Header>
                                <h6>How to Connect Exchange with Profitfarmers</h6>
                            </Card.Header>
                            <CardBody className="card-body auto-trade-settings">
                                {renderVideo()}
                                {renderAutoTrade()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </SettingsContentWrapper>

            {/* AUTO TRADE MODAL */}
            <GenericModal
                id="autoTradingModal"
                className="autoTradingModal"
                show={$state.autoTradingModal && !isAutoTradingAllowed && tradingApiKey === null}
                onHide={() => onCloseModalHandler()}
                header={() => (
                    <>
                        <div className="close" onClick={() => onCloseModalHandler()} style={{ zIndex: '1' }}>
                            <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                        </div>
                        <h3>Enable Auto-trading</h3>
                    </>
                )}
                bodyContent={() => renderAutoTradeModalBody()}
            />

            {/* REMOVE KEY MODAL */}
            <GenericModal
                className="autoTradingModal"
                show={$state.warning}
                closeButton={true}
                onHide={() => onCloseWarningHandler()}
                bodyClass="asl-waring"
                bodyContent={() => renderRemoveBodyModal()}
            />
        </>
    )
}
