import React, { createRef } from 'react'
import { connect } from 'react-redux'
import jQuery from 'jquery'
import queryString from 'query-string'
import { Form, InputGroup } from 'react-bootstrap'
import { Form as FinalForm, Field } from 'react-final-form'
import newFormDecorator from 'final-form-focus'
import iziToast from 'izitoast'
import { browserName, osName, osVersion } from 'react-device-detect'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faEye, faEyeSlash, faExclamationCircle, faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons"
import { Error } from '../../styles'
import { PasswordHelper, Button } from '../../components'
import { authActions } from '../../redux/actions'
import { customErrorCodes } from '../../constants/customErrorCodes'
import { 
    browserWindow, 
    isNotNull, 
    getDeviceType, 
    passwordValidator, 
    emailValidator, 
    isNull, 
    onSetNewState, 
    decryptText } from '../../utils'

class Login extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isSubmitting: false,
            isRedirectedFromVerification: false,
            userName: '',
            userPass: '',
            passwordInputType: 'password',
            showPasswordAsText: false,
            eyeIcon: faEye,
            passwordCriteria: {
                letter: false,
                capital: false,
                number: false,
                special: false,
                size: false
            },
            returnUrl: null
        }

        this.baseState = this.state

        this.initialPasswordHelperState = {
            letter: false,
            capital: false,
            number: false,
            special: false,
            size: false,
            match: false
        }

        this.formDecorator = newFormDecorator()        
        this.mounted = false
    }
    
    componentDidMount() {
        window.location.href = "https://www.profitfarmers.com/login";

        // this.mounted = true

        // const searchQuery = this.props.location.search.replace('?','')
        // if (isNotNull(searchQuery)) {
        //     const parsedData = queryString.parse(searchQuery)
        //     if (isNotNull(parsedData)) {
        //         const { returnUrl } = parsedData
        //         if (isNotNull(returnUrl)) {
        //             onSetNewState(this, {
        //                 returnUrl
        //             })
        //         }
        //     }
        // }

        // const { dispatch, authentication } = this.props
        // if (isNotNull(authentication) && authentication.loggedIn) {
        //     dispatch(authActions.logout())
        // } else {
        //     // make sure that the localStorage is empty
        //     this.onGetRememberedUserHandler()
        // }

        // window.addEventListener('resize', () => browserWindow.adjustDimensions(this))
        // jQuery('body').css('height', '100%')
    }

    componentDidUpdate(prevProps) {
        // const { dispatch, authentication } = this.props

        // if (this.state.userName === "") {
        //     // read user email
        //     const searchQuery = this.props.location.search.replace('?','')
        //     if (isNotNull(searchQuery)) {
        //         const parsedData = queryString.parse(searchQuery)
        //         if (isNotNull(parsedData)) {
        //             const { e } = parsedData
        //             if (isNotNull(e)) {
        //                 let email = decryptText(e.replace(/\s/g, '+'))
        //                 onSetNewState(this, {
        //                     userName: email,
        //                     isRedirectedFromVerification: true
        //                 }, () => {
        //                     jQuery('#formBasicEmail').prop('readonly', true)
        //                     jQuery('#formBasicPassword').select()
        //                 })
        //             }
        //         }
        //     }
        // }

        // if (isNotNull(authentication) && 
        //     prevProps.authentication !== authentication) {
        //         const { returnUrl } = this.state
        //         const { response } = authentication
        //         if (this.state.isSubmitting && isNotNull(response)) {
        //             const { success, luid, hasRedirectUrl, redirectURL, errorCode } = response
        //             if (isNotNull(success)) {                        
        //                 let newDataLayer = {
        //                     event: 'userLogin',
        //                     loginMethod: 'email',
        //                     userId: ''
        //                 }

        //                 if (isNotNull(luid))
        //                     newDataLayer.userId = decryptText(luid)

        //                 if (isNotNull(returnUrl)) {
        //                     window.dataLayer.push(newDataLayer)
        //                     window.location.href = returnUrl
        //                 } else {
        //                     if (!success && isNotNull(errorCode)) {
        //                         switch(errorCode) {
        //                             case customErrorCodes.INVALID_USERNAME:
        //                             case customErrorCodes.INVALID_USERNAME_OR_PASSWORD:                                
        //                             default: {
        //                                 iziToast.error({
        //                                     position: 'topRight',
        //                                     title: 'Error',
        //                                     message: 'Invalid username or password',
        //                                 })
        //                                 dispatch(authActions.clearLoginError())
        //                                 break
        //                             }
        //                             case customErrorCodes.UNVERIFIED_EMAIL: {
        //                                 window.location.href = `/account/verify?email=${this.state.userName}`
        //                                 break
        //                             }
        //                         }
        //                     } else {
        //                         window.dataLayer.push(newDataLayer)

        //                         if (hasRedirectUrl && redirectURL)
        //                             window.location.href = redirectURL
        //                         else
        //                             window.location.href = '/dashboard' //routes.account.getStarted
        //                     }
        //                 }

        //                 onSetNewState(this, {
        //                     isSubmitting: false
        //                 })
        //             }
        //         }
        // }
    }

    onGetRememberedUserHandler = () => {
        const isToRememberMe = localStorage.getItem('rememberMe') === 'true';
        const username = localStorage.getItem('user')
        const userName = isToRememberMe ? username : isNotNull(username) ? username : ''
        onSetNewState(this, { userName, isToRememberMe })
    }

    onViewPasswordAsText = () => {
        const oldState = this.state.passwordInputType
        const newState = oldState === "password" ? "text" : "password"
        const newWord = oldState === "password" ? faEyeSlash : faEye

        this.setState({
            passwordInputType: newState,
            eyeIcon: newWord
        })
    }

    onValidate = (values) => {
        const errors = {}
        let { passwordCriteria } = this.state

        if (isNull(values.username))
            errors.username = 'Email address is required'
        else {
            if (!emailValidator.isValid(values.username))
                errors.username = 'Please enter a valid email address'
        }

        if (isNull(values.password)) {
            errors.password = 'New Password is required'
            passwordCriteria.letter = false
            passwordCriteria.capital = false
            passwordCriteria.number = false
            passwordCriteria.special = false
            passwordCriteria.size = false
        } else {
            passwordCriteria.letter = passwordValidator.hasLowercase(values.password)
            passwordCriteria.capital = passwordValidator.hasUppercase(values.password)
            passwordCriteria.number = passwordValidator.hasNumeric(values.password)
            passwordCriteria.special = passwordValidator.hasSpecialChar(values.password)
            passwordCriteria.size = passwordValidator.isWithinMinAndMaxLength(values.password)

            if (!(passwordCriteria.letter &&
                passwordCriteria.capital &&
                passwordCriteria.number &&
                passwordCriteria.special &&
                passwordCriteria.size))
                errors.password = "Invalid password"
        }

        onSetNewState(this, passwordCriteria)

        return errors
    }

    onSubmit = (values) => {
        const { isSubmitting, isToRememberMe } = this.state
        const { username, password } = values

        if (!isSubmitting && isNotNull(username) && isNotNull(password)) {
            onSetNewState(this, { 
                isSubmitting: true,
                userName: username, 
                userPass: password 
            })
            
            localStorage.setItem('rememberMe', isToRememberMe);
            localStorage.setItem('user', isToRememberMe ? username : '')

            const { dispatch } = this.props;
            const requestData = {
                username,
                password,
                deviceType: getDeviceType(),
                browser: `${browserName}`,
                platform: `${osName} ${osVersion}`,
                token: ""
            }
            dispatch(authActions.login(requestData));
        }
    }

    renderSubmitButton = (isLoading, submitting, pristine) => {
        const { isSubmitting } = this.state
        if (isSubmitting)
            return (
                <Button
                    className="auth-btn"
                    variant="primary"
                    disabled={isSubmitting}>
                    <span>Signing in...</span>
                </Button>
            )
        else
            return (
                <Button
                    className="auth-btn"
                    type="submit"
                    variant="primary"
                    disabled={isSubmitting} >
                    <span>Sign in</span>
                </Button>
            )
    }

    render() {
        // const { loading } = this.props.authentication
        // let { isRedirectedFromVerification, userName, userPass } = this.state

        // return (
        //     <>
        //         <h1 className='title'><strong>Sign in</strong></h1>

        //         <FinalForm
        //             onSubmit={this.onSubmit}
        //             validate={this.onValidate}
        //             decorators={[this.formDecorator]}
        //             initialValues={{ username: userName, password: userPass }}
        //             render={({ handleSubmit, submitting, pristine }) => {
        //                 return (
        //                     <Form
        //                         onSubmit={handleSubmit}>
        //                             { isRedirectedFromVerification &&                                     
        //                                 <div className='alert'>
        //                                     <span><FontAwesomeIcon icon={faCheck} /> Verification Completed</span>
        //                                 </div>
        //                             }

        //                             <Form.Group controlId="formBasicEmail">
        //                                 <Field name="username" type="email">
        //                                     {({ input, meta }) => (
        //                                         <>
        //                                             <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
        //                                                 <InputGroup>
        //                                                     <InputGroup.Prepend>
        //                                                         <InputGroup.Text>
        //                                                             <FontAwesomeIcon size="sm" className="icon" icon={faEnvelope} />
        //                                                         </InputGroup.Text>
        //                                                     </InputGroup.Prepend>

        //                                                     <Form.Control
        //                                                         disabled={loading}
        //                                                         placeholder="Email"
        //                                                         {...input}
        //                                                         autoComplete="off" />
        //                                                 </InputGroup>
        //                                             </div>

        //                                             <Error className={`${meta.error && meta.touched ? 'invalid' : ''}`}>
        //                                                 {meta.error && meta.touched && (
        //                                                     <>
        //                                                         <FontAwesomeIcon icon={faExclamationCircle} className="icon" />
        //                                                         <span>{meta.error}</span>
        //                                                     </>
        //                                                 )}
        //                                             </Error>
        //                                         </>
        //                                     )}
        //                                 </Field>
        //                             </Form.Group>

        //                             <Form.Group controlId="formBasicPassword">
        //                                 <Field name="password">
        //                                     {({ input, meta }) => {
        //                                         let element = createRef()
        //                                         return (
        //                                             <>
        //                                                 <div ref={element} className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
        //                                                     <InputGroup>
        //                                                         <InputGroup.Prepend>
        //                                                             <InputGroup.Text>
        //                                                                 <FontAwesomeIcon size="sm" className="icon" icon={faLock} />
        //                                                             </InputGroup.Text>
        //                                                         </InputGroup.Prepend>

        //                                                         <Form.Control
        //                                                             disabled={loading}
        //                                                             type={this.state.passwordInputType}
        //                                                             placeholder="Password"
        //                                                             {...input}
        //                                                             autoComplete="off" />

        //                                                         <InputGroup.Append>
        //                                                             <InputGroup.Text onClick={this.onViewPasswordAsText}>
        //                                                                 <FontAwesomeIcon size="sm" icon={this.state.eyeIcon} className="" />
        //                                                             </InputGroup.Text>
        //                                                         </InputGroup.Append>
        //                                                     </InputGroup>

        //                                                     <PasswordHelper
        //                                                         show={meta.active && meta.touched && meta.error && meta.visited}
        //                                                         target={element}
        //                                                         container={element}
        //                                                         placement="bottom"
        //                                                         data={isNull(input.value) ? this.initialPasswordHelperState : this.state.passwordCriteria} />
        //                                                 </div>
        //                                             </>
        //                                         )
        //                                     }}
        //                                 </Field>
        //                             </Form.Group>

        //                             {this.renderSubmitButton(loading, submitting, pristine)}
        //                     </Form>
        //                 )
        //             }}
        //         />
        //     </>
        // )

        return (
            <></>
        )
    }
}

function mapStateToProps(state) {
    let { authentication, username, onboardingUser } = state
    return {
        authentication,
        username,
        onboardingUser
    }
}

const connectedLogin = connect(mapStateToProps)(Login)
export { connectedLogin as Login }