import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
//import logger from 'redux-logger'
import reducers from './reducers'

// Array of all middlewares to be applied
//const middlewares = [thunk, logger]

// devtools for debugging in dev development
const devTools =
    // eslint-disable-next-line no-undef
    process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
        : (a) => a

const store = createStore(
    reducers,
    compose(applyMiddleware(thunk), devTools)
)

export default store
