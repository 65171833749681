import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStopCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
import { GenericModal } from '../../../../components/Modals'
import { SignalStatus } from '../../../../components/SignalContents'
import {
    TradeFormSignalDetailsStyle,
    TradeFormSignalPeekView, SignalProgressPeekView, TradeProgressValue,
    SignalPriceRange, SignalTargetPriceValue,
    WalletCard, WalletCardInner, WalletCoinBalance, WalletCardAction
} from '../../styled_components'
import { CoinPairLogo } from '../../../../styles'
import {
    onSetNewState, isNotNull, setCoinLogo, setDefaultImage,
    toBtcFormat, toUsdFormat, ScientificToDecimal
} from '../../../../utils'
import { SafeAnchor } from 'react-bootstrap'

class TradeFormSignalDetails extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id: '',
            symbol: '',
            strategy: '',
            direction: '',
            entryStartPrice: '',
            entryEndPrice: '',
            targetPrice: '',
            targets: null,
            stopLoss: '',
            state: null,
            potentialGains: '',
            latestProgress: '',
            result: null,
            showQuickTradeError: false,
            isActive: false,
            signalState: null
        }
        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
    }

    componentDidUpdate(prevProps, prevState) {
        const { signalInfo } = this.props
        let { symbol, strategy, direction, entryStartPrice, entryEndPrice, targets, stopLoss,
            state, potentialGains, latestProgress, result, isActive, signalState } = this.state

        if (isNotNull(signalInfo)) {
            if (isNotNull(signalInfo.symbol) && this.state.symbol !== signalInfo.symbol) {
                symbol = signalInfo.symbol
                strategy = signalInfo.strategy
                direction = signalInfo.direction
                entryStartPrice = signalInfo.entryStartPrice
                entryEndPrice = signalInfo.entryEndPrice
                stopLoss = signalInfo.stopLoss
                isActive = signalInfo.isActive
                signalState = signalInfo.signalState

                if (isNotNull(signalInfo.state)) {
                    state = signalInfo.state
                    potentialGains = signalInfo.potentialGains
                }

                if (isNotNull(signalInfo.peakResult)) {
                    result = signalInfo.peakResult
                    latestProgress = signalInfo.lastProgressTxt
                }

                let targetPrice = null
                if (isNotNull(signalInfo.targets)) {
                    targetPrice = signalInfo.targets[0].startPrice
                }

                onSetNewState(this, {
                    id: signalInfo.id,
                    symbol,
                    strategy,
                    direction,
                    entryStartPrice,
                    entryEndPrice,
                    targetPrice,
                    stopLoss,
                    state,
                    potentialGains,
                    latestProgress,
                    result,
                    isActive,
                    signalState
                })
            }

            if (isNotNull(signalInfo.targets) && this.state.targets !== signalInfo.targets) {
                targets = signalInfo.targets
                onSetNewState(this, {
                    targets
                })
            }
        }
    }

    renderCoinPairInfo = (toSym) => {
        const { symbol, strategy } = this.state

        let coinLogo = null
        if (isNotNull(toSym))
            coinLogo = setCoinLogo(toSym)

        return (
            <SignalProgressPeekView className="mb-0" style={{ padding: '15px 0' }}>
                <div className="coinpair-wrapper sm">
                    <CoinPairLogo
                        src={coinLogo}
                        className={toSym}
                        icon={toSym}
                        onError={setDefaultImage} />

                    <div className="coinpair-info">
                        <h3 className="coinpair sm">
                            <span>{symbol}</span>
                            <small className="last-price">{strategy}</small>
                        </h3>
                    </div>
                </div>
            </SignalProgressPeekView>
        )
    }

    renderTradeProgress = () => {
        const { id, direction, entryStartPrice, entryEndPrice, state, potentialGains, latestProgress, result, isActive, signalState, targetPrice } = this.state

        return (
            <>
                <div className="trade-progress-value">
                    {isNotNull(state) &&
                        <>
                            {(state.item1 === 's-wez' || state.item1 === 's-lez') && isNotNull(potentialGains) &&
                                <TradeProgressValue className="progress-value positive">
                                    <span>{ potentialGains !== '????%' ? `+${potentialGains}` : `${potentialGains}`}</span>
                                    <small className="duration">potential gains</small>
                                </TradeProgressValue>
                            }

                            {isNotNull(result) &&
                                <h3 className={`progress-value ${result.item1 === 'loss' ? "negative" : "positive"}`}>
                                    <span>{result.item1 === 'loss' ? `-${latestProgress}` : `+${latestProgress}`}</span>
                                    <small className="duration">since entry</small>
                                </h3>
                            }
                        </>
                    }
                </div>

                <div className="trade-progress sm">
                    <div className="trade-status">
                        <SignalStatus size="sm" 
                            signalId={id}
                            isActive={isActive}
                            currentTradeState={state}
                            signalState={signalState} 
                            tradeDirection={direction} 
                            entryStartPrice={entryStartPrice} 
                            entryEndPrice={entryEndPrice} 
                            targetPrice={targetPrice} />
                    </div>
                </div>
            </>
        )
    }

    renderSignalInstructions = () => {
        const { direction, entryStartPrice, entryEndPrice, targets, stopLoss } = this.state

        const renderZoneWidthCalculation = (entryStartPrice, entryEndPrice) => {
            if (isNotNull(entryStartPrice) && isNotNull(entryEndPrice)) {
                const startPrice = parseFloat(entryStartPrice)
                const endPrice = parseFloat(entryEndPrice)
                const totalZoneWidth = _.round(((endPrice - startPrice) / startPrice) * 100, 2)
                return (<>{totalZoneWidth}%</>)
            } else
                return (<>0%</>)
        }

        return (
            <SignalPriceRange className="sm" direction={direction}>
                <div className="price-range sm mt-2">
                    <div className="price-range-entry">
                        <label className="entry-label">
                            <span className="price-range-icons">
                                <i className="fas fa-sign-in-alt"></i>
                            </span>
                            Entry zone
                        </label>
                    </div>

                    <div className="entry-value">
                        <span><span>{ScientificToDecimal(entryStartPrice)} - {ScientificToDecimal(entryEndPrice)}</span></span>
                    </div>
                </div>

                <div className="price-range sm">
                    <div className="price-range-entry">
                        <label className="entry-label">
                            <span className="price-range-icons">
                                
                            </span>
                            ZONE % WIDTH
                        </label>
                    </div>

                    <div className="entry-value width-value">
                        {renderZoneWidthCalculation(entryStartPrice, entryEndPrice)}
                    </div>
                </div>

                <div className="price-range sm align-items-start d-block">
                    <div className="price-range-entry mb-2">
                        <span className="price-range-icons">
                            <i className="fas fa-bullseye"></i>
                        </span>

                        <label className="target-label">
                            <span className="target-text">Target(s)</span>
                        </label>
                    </div>

                    <div className="target-value">
                        <span>
                            {isNotNull(targets) && targets.length > 0 &&
                                targets.map((item, key) => {
                                    return (
                                        <SignalTargetPriceValue key={key}>
                                            <span className="target-index-sm">T{key + 1}</span>
                                            <span className="targets">
                                                {ScientificToDecimal(item.startPrice)}
                                            </span>
                                        </SignalTargetPriceValue>
                                    )
                                })
                            }
                        </span>
                    </div>
                </div>

                <div className="price-range sm">
                    <div className="price-range-entry">
                        <label className="entry-label">
                            <span className="price-range-icons">
                                <FontAwesomeIcon icon={faStopCircle}></FontAwesomeIcon>
                            </span>
                            Stop Loss
                        </label>
                    </div>

                    <div className="entry-value stoploss">
                        <span><span>{ScientificToDecimal(stopLoss)}</span></span>
                    </div>
                </div>
            </SignalPriceRange>
        )
    }

    renderWalletCard = (fromSym) => {
        const { feed, cryptoWallet, futureWallet, orderDetails } = this.props
        const { loading, items } = feed.toUpperCase() === "SPOT" ? cryptoWallet : futureWallet

        let sourceWallet = null
        let usdWallet = null
        let btcWallet = null
        let busdWallet = null
        if (isNotNull(items)) {
            sourceWallet = _.find(items.data, c => c.name === fromSym)
            usdWallet = _.find(items.data, c => c.name === 'USDT')
            btcWallet = _.find(items.data, c => c.name === 'BTC')
            busdWallet = _.find(items.data, c => c.name === 'BUSD')
        }

        return (
            <>
                { isNotNull(loading) && !loading &&
                    <WalletCard className="order-wallet-wrapper">
                        <WalletCardInner className="order-wallet" data-bind="attr: { class: 'wallet ' + lowerCoinName }">
                            {isNotNull(items.msg) &&
                                <>
                                    <p className="balance-title">{ isNotNull(orderDetails) && isNotNull(orderDetails.feed) ? orderDetails.feed : '' } Wallet Balance</p>
                                    <p className="text-center mt-3">{items.msg}</p>
                                </>
                            }

                            {isNotNull(sourceWallet) &&
                                <>
                                    <p className="balance-title">{ isNotNull(orderDetails) && isNotNull(orderDetails.feed) ? orderDetails.feed : '' } Wallet Balance</p>
                                    <WalletCoinBalance>
                                        <p>{`${sourceWallet.name}: `}</p>

                                        { sourceWallet.name === 'BTC' &&
                                            <>
                                                <span>{toBtcFormat(sourceWallet.balance.free)}</span>
                                            </>
                                        }

                                        { sourceWallet.name === 'USDT' &&
                                            <>
                                                <span>{toUsdFormat(sourceWallet.balance.free)}</span>
                                            </>
                                        }

                                        { fromSym === 'BUSD' && sourceWallet.name === 'BUSD' && isNotNull(busdWallet) && 
                                            <>
                                                <span>{toUsdFormat(busdWallet.balance.free)}</span>
                                            </>
                                        }

                                        { feed.toUpperCase() === "FUTURES" && fromSym !== 'BUSD' && 
                                            <>
                                                <WalletCardAction>
                                                    <button className="btn btn-success" onClick={() => this.onQuickTradeHandler(feed)}>Add Funds</button>
                                                </WalletCardAction>
                                            </>
                                        }
                                    </WalletCoinBalance>

                                    { feed.toUpperCase() === "SPOT" && fromSym !== 'BUSD' && 
                                        <>
                                            <WalletCoinBalance>
                                                <p>{sourceWallet.name === 'BTC' ? '$' : '₿'}</p>
                                                {sourceWallet.name === 'BTC' &&
                                                    <>
                                                        <span>{toUsdFormat(usdWallet.balance.free)}</span>
                                                        <small>{usdWallet.name}</small>
                                                    </>
                                                }

                                                {sourceWallet.name === 'USDT' &&
                                                    <>
                                                        <span>{toBtcFormat(btcWallet.balance.free)}</span>
                                                        <small>{btcWallet.name}</small>
                                                    </>
                                                }

                                                <WalletCardAction>
                                                    <button className="btn btn-success" onClick={() => this.props.onCreateQuickTradeHandler(feed)}>Convert</button>
                                                </WalletCardAction>
                                            </WalletCoinBalance>
                                        </>
                                    }
                                </>
                            }
                        </WalletCardInner>
                    </WalletCard>
                }
            </>
        )
    }

    onQuickTradeHandler = (feed) => {
        if (isNotNull(feed)) {
            if (feed.toUpperCase() === "SPOT")
                this.props.onCreateQuickTradeHandler()
            else
                this.setState({ showQuickTradeError: true })
        }
    }

    onQuickTradeHideHandler = () => this.setState({ showQuickTradeError: false })

    render() {
        const { feed, fromSym, toSym, hasConnectedExchange } = this.props

        return (
            <>
                <TradeFormSignalDetailsStyle id="side-column">
                    {/* <TradeFormTypeToggle className="toggle-advance-wrapper">
                        <TradeFormTypeToggleBtn>
                            <input type="checkbox" onChange={ onChangeFormTradeTypeHandler } checked={enableAdvancedForm} />
                            <i className="advance-toggle active" />Advanced Options
                        </TradeFormTypeToggleBtn>
                    </TradeFormTypeToggle> */}

                    <div className="signal-info-wrapper">
                        <div>
                            <p className="signal-title">SIGNAL DETAILS</p>
                            <TradeFormSignalPeekView className="sm">
                                {this.renderCoinPairInfo(toSym)}

                                {this.renderTradeProgress()}

                                {this.renderSignalInstructions()}
                            </TradeFormSignalPeekView>
                        </div>

                        { hasConnectedExchange && isNotNull(feed) && this.renderWalletCard(fromSym)}
                    </div>
                </TradeFormSignalDetailsStyle>

                {/* Future Convert Modal */}
                <GenericModal
                    id="autoTradingModal"
                    className="autoTradingModal"
                    show={this.state.showQuickTradeError}
                    bodyClass="asl-waring"
                    onHide={this.onQuickTradeHideHandler}
                    bodyContent={() => {
                        return (
                            <>
                                <div className="close" onClick={() => this.onQuickTradeHideHandler()} style={{ zIndex: '1' }}>
                                    <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                </div>
                                <div className="icon-warning">
                                    <i className="fas fa-info-circle text-info"></i>
                                </div>

                                <h3 className="mt-3">&nbsp;</h3>
                                <p className="mb-4">
                                    To trade Binance FUTURES, you’ll need to add funds to your USDⓈ-M Futures wallet. <br />
                                    DO NOT send funds to your COIN-M Futures wallet.
                                </p>
                                <p>Please read this <SafeAnchor href="https://profitfarmers.freshdesk.com/en/support/solutions/articles/47001173631-how-to-transfer-funds-from-your-spot-wallet-to-your-futures-wallet-using-binance" target="_blank">quick guide</SafeAnchor> for how to do this.</p>
                                <div className="asl-warning-buttons">
                                    <button variant="primary" className="btn-yes" onClick={() => this.onQuickTradeHideHandler()} style={{ color: '#fff' }}>
                                        <span>Ok, I Understand</span>
                                    </button>
                                </div>
                            </>
                        )
                    }}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    const { cryptoWallet, futureWallet } = state
    return {
        cryptoWallet,
        futureWallet
    }
}

const connectedTradeFormSignalDetails = connect(mapStateToProps)(TradeFormSignalDetails)
export { connectedTradeFormSignalDetails as TradeFormSignalDetails }