import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const MoneyBackGuaranteeStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    padding: 60px 0;
    background-color: #000;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;
        color: #fff;
        text-align: left;

        .img-wrapper {
            width: 267px;
            margin: 0 auto 25px;

            img {
                width: 100%;
            }
        }

        .text-wrapper {
            padding-left: 0;

            .section-title {
                display: block;
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 25px;
                text-align: center;
                max-width: 385px;
            }

            p {
                color: #9AB9D5;
                text-align: justify;

                strong {
                    color: #fff;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        .container {
            flex-direction: row;
            max-width: 920px;

            .img-wrapper {
                width: 300px;
                margin-bottom: 0;
            }

            .text-wrapper {
                width: calc(100% - 300px);
                padding-left: 25px;

                .section-title {
                    font-size: 25px;
                    text-align: left;
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        .container {
            max-width: 1000px;

            .text-wrapper {
                width: calc(100% - 400px);
                padding-left: 0;

                .section-title {
                    font-size: 30px;
                    max-width: 500px;
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        .container {
            max-width: 1200px;

            .img-wrapper {
                width: 500px;
            }

            .text-wrapper {
                width: calc(100% - 500px);
                padding-left: 25px;

                .section-title {
                    font-size: 36px;
                    text-align: left;
                    max-width: 600px;
                }

                p {
                    font-size: 18px;
                    text-align: left;
                }
            }
        }
    }
`