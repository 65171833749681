import { isNotNull } from '../../utils'
import { surveyActionTypes } from '../CONSTANTS'

export function cancellationSurvey(state = {}, action) {
    switch (action.type) {
        case surveyActionTypes.GET_SURVEY:
            return {
                ...state,
                data: action.surveyData,
                step: "q1",
                answers: [
                    {
                        key: "q1",
                        answerKey: null,
                        value: null,
                        value2: null
                    },
                    {
                        key: "q2",
                        answerKey: null,
                        value: null,
                        value2: null
                    },
                    {
                        key: "q3",
                        answerKey: null,
                        value: null,
                        value2: null
                    }
                ]
            }
        case surveyActionTypes.UPDATE_SURVEY:
            return {
                ...state,
                data: state.data.map(survey => {
                    var temp = Object.assign({}, survey)
                    if (isNotNull(action) &&
                        isNotNull(action.data) &&
                        isNotNull(action.data.key) &&
                        isNotNull(action.data.answer) &&
                        temp.key === action.data.key)
                        temp.answer = action.data.answer
                    return temp
                }),
                answers: state.answers.map(answer => {
                    var temp = Object.assign({}, answer)
                    if (isNotNull(action) &&
                        isNotNull(action.data) &&
                        isNotNull(action.data.key) &&
                        isNotNull(action.data.answer) &&
                        isNotNull(action.data.answer.value) &&
                        temp.key === action.data.key) {
                        temp.value = action.data.answer.value
                        temp.value2 = ""
                        temp.answerKey = action.data.answer.key
                    }
                    return temp
                })
            }
        case surveyActionTypes.UPDATE_ANSWER:
            return {
                ...state,
                answers: state.answers.map(answer => {
                    var temp = Object.assign({}, answer)
                    if (isNotNull(action) &&
                        isNotNull(action.data) &&
                        isNotNull(action.data.key) &&
                        isNotNull(action.data.answerKey) &&
                        temp.key === action.data.key)
                        temp.value2 = action.data.value2
                    return temp
                })
            }
        case surveyActionTypes.UPDATE_STEP:
            return {
                ...state,
                step: action.step
            }
        case surveyActionTypes.RESET_SURVEY:
            return {
                ...state,
                data: action.surveyData,
                step: "q1",
                answers: [
                    {
                        key: "q1",
                        answerKey: null,
                        value: null,
                        value2: null
                    },
                    {
                        key: "q2",
                        answerKey: null,
                        value: null,
                        value2: null
                    },
                    {
                        key: "q3",
                        answerKey: null,
                        value: null,
                        value2: null
                    }
                ]
            }
        default:
            return state
    }
}