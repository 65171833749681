import React from 'react'
import { connect } from 'react-redux'
import { Popover, Overlay, SafeAnchor } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBell } from "@fortawesome/free-solid-svg-icons"
import _ from 'lodash'
import { Skeleton } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import { NotificationItems } from './NotificationItems'
import { notificationActions } from '../../redux/actions'
import { notificationActionTypes } from '../../redux/CONSTANTS'
import { notiTypes } from '../../constants/notificationTypeConstants'
import {
    NotificationBellWrapper,
    NotificationBadge
} from './styled_components'
import { NotificationButton, NotificationContentLists, LoaderWrapper } from '../../pages/Notification/styled_components'
import { isNotNull, onSetNewState } from '../../utils'

class NotificationBell extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            target: null,
            totalUnread: 0,

            markedAllAsReadData: {
                notification_id: "ALL",
                isMarkedAll: true
            },
            selectedActorId: null,
            isRedirect: false,
            notif_id: null,
            signal_id: null,
            isRead: null,
            isMarked: false,
            width: window.innerWidth,
            height: window.innerHeight
        }
        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        const { notificationLists } = this.props
        this.handleTotalUnreadNotification(notificationLists)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.mounted) {
            const { dispatch, notificationLists, markedNotification, history } = this.props

            if (prevProps.notificationLists !== notificationLists)
                this.handleTotalUnreadNotification(notificationLists)

            if (prevProps.markedNotification !== markedNotification) {
                const { loading, item, active_id } = markedNotification
                if (!loading && isNotNull(item) && isNotNull(item.success) && item.success === true) {
                    onSetNewState(this, { isMarked: false })
                    if (prevState.isRedirect === true) {
                        const { notif_id, signal_id, isRead } = this.state
                        onSetNewState(this, { isRedirect: false })
                        this.props.notificationItemPreview(true)
                        history.push(`/dashboard?sId=${signal_id}&isRead=${isRead}&id=${notif_id}`)
                    }

                    if (isNotNull(notificationLists) &&
                        isNotNull(notificationLists.notification_lists) &&
                        isNotNull(notificationLists.notification_lists.data)) {

                        let notification = [...notificationLists.notification_lists.data]
                        _.forEach(notification, n => {
                            if (this.state.selectedActorId === "ALL") {
                                if (n.type !== notiTypes.MANUALLY_CLOSED)
                                    n.isRead = true
                            } else
                                if (n.id === (this.state.selectedActorId === null ? active_id : this.state.selectedActorId))
                                    n.isRead = true
                        })
                        let notification_lists = {
                            data: notification
                        }
                        dispatch({ type: notificationActionTypes.GET_ALL_NOTIFICATION_LISTS_SUCCESS, notification_lists })
                        dispatch({ type: notificationActionTypes.CLEAR_MARKED_NOTIFICATION })
                        onSetNewState(this, { selectedActorId: null })
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)
        this.mounted = false
    }

    handleRedirection = (data) => {
        const { history } = this.props
        if (isNotNull(data)) {
            const { id, actorId, isRead, type } = data
            if (isNotNull(type)) {
                switch (type) {
                    default: break
                    case notiTypes.NEW_SIGNAL:
                    case notiTypes.STOP_LOSS_HIT: 
                    case notiTypes.TARGET_HIT: 
                    case notiTypes.ALL_TARGETS_HIT: {
                        // Preview Signal
                        const currrentPath = window.location.pathname
                        if (isNotNull(currrentPath)) {
                            if (currrentPath === '/dashboard') {
                                this.props.notificationItemPreview(true)
                                history.push(`/dashboard?sId=${actorId}&isRead=${isRead}&id=${id}`)
                            } else {
                                if (isNotNull(isRead)) {
                                    if (isRead === true) {
                                        this.props.notificationItemPreview(true)
                                        history.push(`/dashboard?sId=${actorId}&isRead=${isRead}&id=${id}`)
                                    }
                                    else
                                        onSetNewState(this, {
                                            isRedirect: true,
                                            notif_id: id,
                                            signal_id: actorId,
                                            isRead: isRead
                                        })
                                }
                            }
                        }

                        break
                    }
                    case notiTypes.MANUALLY_CLOSED: {
                        this.props.onReviewClosedEarlyItem(data)
                        break
                    }
                    case notiTypes.AUTO_CLOSE_EARLY: {
                        this.props.onReviewAutoClosedEarlyItem(data)
                        break
                    }
                    case notiTypes.ORDER_SUCCEEDED:
                    case notiTypes.ORDER_FAILED: {
                        this.props.onReviewOrderResultItem(data)
                        break
                    }
                }
            }
        }
    }

    showNotification = event => onSetNewState(this, { target: event.target.parentElement, show: !this.state.show })

    hideNotification = () => onSetNewState(this, { show: false })

    handleMarkAsRead = (data, e) => {
        e.preventDefault()
        const { dispatch } = this.props
        const { totalUnread } = this.state
        if (totalUnread > 0) {
            if (isNotNull(data)) {
                const { id, type, isMarkedAll, isRead } = data
                if (isNotNull(id))
                    onSetNewState(this, { selectedActorId: id })

                if (isNotNull(type) && type !== notiTypes.MANUALLY_CLOSED) {
                    const responseData = {
                        "notification_id": id,
                        "isMarkedAll": isRead
                    }
                    dispatch(notificationActions.markNotificationAsRead(responseData))
                }

                if (isNotNull(isMarkedAll) && isMarkedAll === true) {
                    onSetNewState(this, {
                        isMarked: true,
                        selectedActorId: "ALL"
                    })
                    dispatch(notificationActions.markNotificationAsRead(data))
                }
            }
        }
    }

    handleTotalUnreadNotification = (notificationLists) => {
        if (isNotNull(notificationLists)) {
            let { loader, notification_lists } = notificationLists
            if (!loader && isNotNull(notification_lists)) {
                const { data } = notification_lists
                if (isNotNull(data)) {
                    const orderLists = _.orderBy(data, o => new Date(o.dateCreated), 'desc')

                    const promise = new Promise((resolve) => {
                        const find = _.countBy(orderLists, f => f.isRead === false)
                        if (isNotNull(find))
                            resolve(find.true)
                    })
                    promise.then((result) => {
                        onSetNewState(this, { totalUnread: result })
                    }, (err) => {
                        // console.log(err)
                    })
                }
            }
        }
    }

    render() {
        let { show, target, totalUnread, markedAllAsReadData, width } = this.state
        let { loader, notification_lists } = this.props.notificationLists
        let notificationLists = []

        if (!loader) {
            if (isNotNull(notification_lists)) {
                const { data } = notification_lists
                if (isNotNull(data)) {
                    let orderLists = _.orderBy(data, o => new Date(o.dateCreated), 'desc')
                    let filter = _.filter(orderLists, (val, key) => key <= 7)

                    notificationLists = _.map(filter, (value, keys) => {
                        if (typeof value !== 'undefined' && value !== null && value !== '') {
                            let keyNum = value.id + keys + value.actorId
                            return (
                                <NotificationItems
                                    key={keyNum}
                                    handleRedirect={this.handleRedirection}
                                    handleRead={this.handleMarkAsRead}
                                    hide={this.hideNotification}
                                    {...value}
                                />
                            )
                        } else
                            return null
                    })
                }
            }
        }

        let preloaderData = [0, 1, 2, 3, 4, 5, 6, 7]
        let preloader = null
        preloader = _.map(preloaderData, (val, key) => {
            return (
                <LoaderWrapper key={key}>
                    <Skeleton variant="rect" style={{
                        width: '100%',
                        height: '100%',
                        backgroundImage: 'linear-gradient(15deg,#293442,#293442)',
                        border: '1px solid #293442',
                        borderRadius: '8px'
                    }} className="skeleton" />
                </LoaderWrapper>
            )
        })

        return (
            <NotificationBellWrapper ref={bell => this.notificationBell = bell}>
                <NotificationButton type="button" onClick={this.showNotification}>
                    <FontAwesomeIcon icon={faBell} />
                    {
                        totalUnread > 0 &&
                        <NotificationBadge >
                            {totalUnread > 99 ? `99+` : totalUnread}
                        </NotificationBadge>
                    }
                </NotificationButton>
                <Overlay
                    show={show}
                    target={target}
                    container={this.notificationBell}
                    rootClose={true}
                    onHide={this.hideNotification}
                    placement={(width > 767) ? 'bottom-end' : 'bottom-start'}
                >
                    <Popover
                        id="notification-bell">
                        <Popover.Title as="div">
                            <h3>Notifications</h3>
                            {
                                this.state.isMarked === false
                                    ? <SafeAnchor href={void (0)} disabled={loader === false ? false : true} onClick={(e) => this.handleMarkAsRead(markedAllAsReadData, e)}>Mark All as Read</SafeAnchor>
                                    : <i className="fas fa-spinner fa-pulse"></i>
                            }
                        </Popover.Title>
                        <Popover.Content>
                            {
                                !loader &&
                                <>
                                    {notificationLists.length > 0 &&
                                        <div className="notif-title">
                                            <h6>Earlier</h6>
                                        </div>
                                    }
                                </>
                            }
                            <NotificationContentLists className={window.innerHeight >= 1366 ? "update" : ""}>
                                {
                                    loader &&
                                    <>{preloader}</>
                                }
                                {
                                    !loader &&
                                    <>
                                        {notificationLists}
                                    </>
                                }
                            </NotificationContentLists>
                        </Popover.Content>
                        <div className="popover-footer">
                            <Link to="/notifications" onClick={() => this.hideNotification()}>
                                See all
                            </Link>
                        </div>
                    </Popover>
                </Overlay>
            </NotificationBellWrapper>
        )
    }
}

function mapStateTotProps(state) {
    let { authentication, notificationLists, markedNotification } = state
    return {
        authentication,
        notificationLists,
        markedNotification
    }
}

const connectedNotificationBell = connect(mapStateTotProps)(NotificationBell)
export { connectedNotificationBell as NotificationBell }
