import React from 'react'
import { connect } from 'react-redux'
import { Card, Table } from 'react-bootstrap'
import Moment from 'react-moment'
import { signalDetailsActions } from '../../../../redux/actions'
import { isNotNull, getCoin, setDefaultImage, onSetNewState, isNull } from '../../../../utils'

class AutoClosedTradeOrderDetails extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            reviewItem: null,
            details: null
        }
        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true

        const { data } = this.props
        if (isNotNull(data)) {
            onSetNewState(this, {
                reviewItem: data
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.mounted) {
            const { dispatch, signalDetails } = this.props            
            const { isLoading, reviewItem, details } = this.state

            if (isNotNull(reviewItem) && isNull(details) && !isLoading) {
                onSetNewState(this, {
                    isLoading: true
                }, () => {
                    dispatch(signalDetailsActions.getSignalDetails(reviewItem.actorId))
                })
            }
            
            if (isNotNull(signalDetails) && !signalDetails.loading && 
                prevProps.signalDetails !== signalDetails && 
                prevState.details !== signalDetails && isLoading) {
                    const { item } = signalDetails
                    if (isNotNull(item)) {                        
                        onSetNewState(this, {
                            isLoading: false,
                            details: item
                        })
                    }
                }
        }
    }

    componentWillUnmount() {
        this.setState(this.baseState)
        this.mounted = false
    }

    renderSignalDetails = () => {
        const { details } = this.state
        if (isNotNull(details)) {
            return (
                <Card className="signal-info-card">
                    <Card.Body>
                        <h3>Signal Info</h3>
                        <Table borderless={true}>
                        <tbody>
                            <tr>
                                <td className="align-right">Coin Pair</td>
                                <td>
                                    <div className="signal-info">
                                        <i className="coin-icon">
                                            <img 
                                                src={`https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/${getCoin(`btc`)}.png`} 
                                                alt="coin-logo"
                                                onError={setDefaultImage} />
                                        </i>
                                        {details.symbol}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="align-right">Direction</td>
                                <td>{details.direction}</td>
                            </tr>
                            <tr>
                                <td className="align-right">Exchange</td>
                                <td>{details.feed === 'SPOT' ? 'ON BINANCE' : 'ON BINANCE FUTURES'}</td>
                            </tr>
                            <tr>
                                <td className="align-right">Strategy</td>
                                <td>{details.strategy}</td>
                            </tr>
                            <tr>
                                <td className="align-right">Created On</td>
                                <td>
                                    <Moment format="MMM D YYYY HH:mm">
                                        {details.datePublished}
                                    </Moment> UTC
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    </Card.Body>
                </Card>
            )
        }
    }

    renderLoader = () => {
        return (
            <>
                <div style={{ width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="pf-spinner xs" style={{ marginTop: '-48px', marginRight: '45px' }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            </>
        )
    }

    render() {
        const { isLoading } = this.state

        return (
            <>
                { isLoading && 
                    <>
                        {this.renderLoader()}
                    </>
                }

                { !isLoading && 
                    <>
                        <h2 className="trigger-title">Trade Guardian has triggered</h2>
                        <p>A trade that you are in has been tagged as closed early. Our system will now close this trade at market price by placing a Market buy/sell order on exchange.</p>

                        {this.renderSignalDetails()}
                    </>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    const { signalDetails } = state
    return {
        signalDetails
    }
}

const connectedAutoClosedTradeOrderDetails = connect(mapStateToProps)(AutoClosedTradeOrderDetails)
export { connectedAutoClosedTradeOrderDetails as AutoClosedTradeOrderDetails }