import styled from 'styled-components';

export const ForgotPasswordSectionWrapper = styled.div`
position: relative;
width: 100%;
height: 100%;

.brand-wrapper {
    margin: auto;
    padding: 15px 20px;
    
    @media (min-width: 568px) {
        padding: 15px 45px;
        text-align: center;
    }

    @media (min-width: 768px) {
        padding: 0px;
        margin: 25px auto;
    }
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;

    @media (min-width: 360px) {
        min-height: 535px !important;
    }

    @media (min-width: 768px) {
        width: 85% !important;
        min-height: 480px !important;
        margin-bottom: 0px !important;
    }

    @media (min-width: 992px) {
        width: 720px !important;
        min-height: 535px !important;
    }
}

form {
    @media (min-width: 360px) {
        margin-top: 3rem !important;
    }
}

.recovery-email {
    margin: 20px 0px;

    @media (min-width: 568px) {
        margin: 25px 0px 20px;
    }
}

.body-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .body-content {
        padding: 10px 0 20px;
    }

    @media screen and (min-width: 320px) and (max-width: 767px) {
        height: unset;
    }
}`;

export const ForgotPasswordTitleWrapper = styled.div`
text-align: center;
`;

export const ForgotPasswordTitle = styled.h2`
color: #3f9f32;
font-weight: 700;
margin: 15px 0;

`;

export const ForgotPasswordParagraph = styled.p`
font-size: 15px;
font-weight: 500;
`;

export const InputWrapper = styled.div`
input {
    &:focus {
        background-color: transparent;
    }

    &.resetEmail {
        background-color: transparent;
    }
}

&.is-invalid {
    .error-span {
        display: inline-block !important;
        color: red;
    }
}
`;

export const ErrorText = styled.small`
font-size: 12px;
`;


export const InputContainer = styled.div`

input {
    &:focus {
        box-shadow: unset;
    }
}

border: 1px solid #e2e0df;
border-radius: 6px;
background: #fafafa;
display: flex;
justify-content: center;
align-items: center;
padding: 7px;
margin-bottom: 1rem;

svg {
width: 40px !important;
height: 35px;
margin-right: -1px;
align-items: center;
padding: .375rem .75rem;
margin-bottom: 0;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
text-align: center;
white-space: nowrap;
border-radius: .25rem;
background: 0 0;
border: none;
color: #000;
border-radius: unset;
}

.eye {
width: 45px !important;
margin-top: 5px;
border: none !important;
}

input {
background: 0 0;
border: none;
color: #000;
font-weight: 600;
font-size: 14px;
border-radius: unset;
}

&.invalid-container {
margin-bottom: 0;
border: 1px solid red;
}

&.is-invalid {
margin-bottom: 0;
}
`;

export const FormButton = styled.div`
margin: 20px 0;

@media (min-width: '568px') {
margin: 25px 0 20px;
}

button {
width: 100%;
padding: 8px 0;
margin: 10px 0 20px;
background-color: #15bd6f !important;
border: 1px solid #475973 !important;
color: #fff;
font-weight: 600;
font-size: 18px;
border: none;

a {
  color: white;
  text-decoration: none;
}

@media (min-width: '568px') {
  margin: 25px 0;
}

@media (min-width: '768px') {
  width: unset;
  padding: 8px 80px;
}
}
`;

export const LinkWrapper = styled.div`
font-weight: 500;
a {
color: #000 !important;
&:hover {
  color: #000 !important;
}
}
`;

export const ForgotPasswordFooter = styled.div``;

export const InputContainerPrepend = styled.span``;

export const EyeIcon = styled.div`
cursor: pointer;
// position: absolute;
right: 0;
`;

export const ForgotPasswordMain = styled.div`
font-family: 'Montserrat', sans-serif;

.back-to-login-btn {
    min-width: 250px;
    padding: 8px 10px;
    margin: 10px 0 20px;
    background-color: #15bd6f !important;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    outline: none;
}

.card {
min-height: 435px;
box-shadow: 0 0 20px #cacacab5;
border: none;
overflow: hidden;
width: 89%;
margin-bottom: 25px;

&.larger-form {
  min-height: 535px;
}

@media (min-width: '360px') {
  min-height: 535px;
}

@media (min-width: '768px') {
  width: 85%;
  /* min-height: 535px; */
  min-height: 480px;
  margin-bottom: 0;
}

@media (min-width: '768px') {
  width: 720px;
  min-height: 535px;
}

.card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  .formForgotPassword {
    padding: 0;
  }
}
}
form {
margin-top: 1.5rem;
padding: 0;

@media (min-width: '360px') {
  margin-top: 3rem;
}
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .card-body {
        .row {
            ${ForgotPasswordTitleWrapper} {
                ${ForgotPasswordTitle} {
                    font-size: 22px;
                }

                ${ForgotPasswordParagraph} {
                    font-size: 13px;
                }
            }

            ${InputWrapper} {
                ${InputContainer} {
                    > input {
                        font-size: 13px;
                    }
                }
            }

            ${FormButton} {
                button {
                    font-size: 15px;
                }
            }

            ${LinkWrapper} {
                a {
                    font-size: 14px;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .card-body {
        .row {
            ${ForgotPasswordTitleWrapper} {
                ${ForgotPasswordTitle} {
                    font-size: 24px;
                }

                ${ForgotPasswordParagraph} {
                    font-size: 13px;
                }
            }

            ${InputWrapper} {
                ${InputContainer} {
                    > input {
                        font-size: 13px;
                    }
                }
            }

            ${FormButton} {
                button {
                    font-size: 15px;
                }
            }

            ${LinkWrapper} {
                a {
                    font-size: 14px;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
    .card {
        width: 570px;

        .card-body {
            .row {
                ${ForgotPasswordTitleWrapper} {
                    ${ForgotPasswordTitle} {
                        font-size: 24px;
                    }

                    ${ForgotPasswordParagraph} {
                        font-size: 13px;
                    }
                }

                ${InputWrapper} {
                    ${InputContainer} {
                        > input {
                            font-size: 13px;
                        }
                    }
                }

                ${FormButton} {
                    button {
                        font-size: 15px;
                    }
                }

                ${LinkWrapper} {
                    a {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
    .card {
        width: 630px;

        .card-body {
            .row {
                ${ForgotPasswordTitleWrapper} {
                    ${ForgotPasswordTitle} {
                        font-size: 26px;
                    }

                    ${ForgotPasswordParagraph} {
                        font-size: 14px;
                    }
                }

                ${InputWrapper} {
                    ${InputContainer} {
                        > input {
                            font-size: 14px;
                        }
                    }
                }

                ${FormButton} {
                    button {
                        font-size: 16px;
                    }
                }

                ${LinkWrapper} {
                    a {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1441px) and (max-width: 1749px) {
    .card {
        .card-body {
            .row {
                ${ForgotPasswordTitleWrapper} {
                    ${ForgotPasswordTitle} {
                        font-size: 26px;
                    }

                    ${ForgotPasswordParagraph} {
                        font-size: 14px;
                    }
                }

                ${InputWrapper} {
                    ${InputContainer} {
                        > input {
                            font-size: 14px;
                        }
                    }
                }

                ${FormButton} {
                    button {
                        font-size: 16px;
                    }
                }

                ${LinkWrapper} {
                    a {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
`;