export const mediaDeviceSizes = {
    xs: 'EXTRA_SMALL',
    sm: 'SMALL',
    md: 'MEDIUM',
    lg: 'LARGE',
    xl: 'EXTRA_LARGE',
    xxl: 'DOUBLE_EXTRA_LARGE'
}

export const mediaQueries = {
    extraSmallDevices: '@media only screen and (max-width: 600px)',
    smallDevices: '@media only screen and (min-width: 601px)',
    mediumDevices: '@media only screen and (min-width: 768px)',
    largeDevices: '@media only screen and (min-width: 992px)',
    extraLargeDevices: '@media only screen and (min-width: 1200px)',
    superExtraLargeDevices: '@media only screen and (min-width: 1440px)',
    fourK: '@media only screen and (min-width: 2560px)'
}