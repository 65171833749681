import { faEnvelope, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form as FormFinal, Field } from 'react-final-form'
import { Container, Card, Row, Col, Button, Form, InputGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Footer, Header } from '../../components'
import {
    ForgotPasswordFooter, ForgotPasswordMain,
    ForgotPasswordSectionWrapper, ForgotPasswordTitle,
    ForgotPasswordTitleWrapper, FormButton,
    LinkWrapper
} from '../ForgotPassword/styled_components/ForgotPasswordStyle'
import { FormBodyWrapper, Error } from '../../styles'
import { emailValidator, isNull, sleep } from '../../utils'

export const ChangeEmailView = (props) => {
    const $state = props.state

    const onFormSubmit = async values => {
        await sleep(300)
        props.submit(values)
    }

    const onFormValidate = async values => {
        const errors = {}

        if (isNull(values.newEmail))
            errors.newEmail = "Email is required"
        else if (!emailValidator.isValid(values.newEmail))
            errors.newEmail = 'Please enter a valid email address'

        return errors
    }

    const renderChangeEmailForm = () => (
        <FormFinal
            initialValues={{
                changeEmail: $state.email
            }}
            onSubmit={onFormSubmit}
            validate={onFormValidate}
            render={({ handleSubmit, submitting }) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                        <Field name="changeEmail" type="email">
                            {({ input, meta, type }) => (
                                <>
                                    <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                        <InputGroup>
                                            <InputGroup.Append>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faEnvelope} className="icon" />
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                            <Form.Control
                                                type={type}
                                                placeholder="Email address"
                                                autoComplete="off"
                                                readOnly
                                                className="form-control changeEmail"
                                                {...input}
                                            />
                                        </InputGroup>
                                    </div>
                                </>
                            )}
                        </Field>
                    </Form.Group>

                    <Form.Group controlId="formBasicNewEmail">
                        <Field name="newEmail" type="email">
                            {({ input, meta, type }) => (
                                <>
                                    <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                        <InputGroup>
                                            <InputGroup.Append>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faEnvelope} className="icon" />
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                            <Form.Control
                                                type={type}
                                                placeholder="Enter new email address"
                                                autoComplete="off"
                                                className="form-control newEmail"
                                                {...input}
                                            />
                                        </InputGroup>
                                    </div>
                                    <Error className={`${meta.error && meta.touched ? 'invalid' : ''}`}>
                                        {meta.error && meta.touched && (
                                            <>
                                                <FontAwesomeIcon icon={faExclamationCircle} className="icon" />
                                                <span>{meta.error}</span>
                                            </>
                                        )}
                                    </Error>
                                </>
                            )}
                        </Field>
                    </Form.Group>

                    <FormButton className="text-center">
                        {
                            $state.isSubmitted
                                ? <Button variant="primary" className="mt-3 mb-3 save-btn">
                                    <i className="fas fa-spinner fa-pulse"></i>
                                </Button>
                                : <Button type="submit" disabled={submitting} variant="primary">
                                    Change Email Address
                                </Button>
                        }
                    </FormButton>
                    <LinkWrapper className="text-center" style={{ marginBottom: '1rem' }}>
                        <Link to={`/account/login`}>Cancel</Link>
                    </LinkWrapper>
                </Form >
            )}
        />
    )

    return (
        <ForgotPasswordSectionWrapper>
            <div className="body-container">
                <div className="body-content">
                    <Header purpose="login" />
                    <ForgotPasswordMain>
                        <Container>
                            <Row className="justify-content-center">
                                <Card className="larger-form">
                                    <Card.Body className="center">
                                        <Row className="justify-content-center" style={{ width: '100%' }}>
                                            <Col className="formForgotPassword" md={8} lg={8} xl={8}>
                                                <ForgotPasswordTitleWrapper>
                                                    <ForgotPasswordTitle>Change Email Address</ForgotPasswordTitle>
                                                </ForgotPasswordTitleWrapper>

                                                <FormBodyWrapper>
                                                    {renderChangeEmailForm()}
                                                </FormBodyWrapper>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Row>
                        </Container>
                    </ForgotPasswordMain>
                    <ForgotPasswordFooter>
                        <Footer />
                    </ForgotPasswordFooter>
                </div>
            </div>
        </ForgotPasswordSectionWrapper>
    )
}