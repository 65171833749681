export const tradingExpQuestionnaire = [{
    id: 'trading-exp-1',
    name: 'expLevel',
    type: 'dropdown',
    question: 'How long have you been trading cryptocurrency?',
    answers: [{ 
        answerKey: 'NO_EXP',
        answerText: 'Never before'
    }, {
        answerKey: 'LESS_THAN_A_YEAR',
        answerText: 'Less than a year'
    }, {
        answerKey: 'ONE_TO_TWO_YEARS',
        answerText: '1-2 years'
    }, {
        answerKey: 'TWO_TO_FIVE_YEARS',
        answerText: '2-5 years'
    }, {
        answerKey: 'ABOVE_FIVE_YEARS',
        answerText: '5 years+'
    }]
}, {
    id: 'trading-exp-3',
    name: 'tradingPurpose',
    type: 'dropdown',
    question: 'What is your purpose of trading?',
    answers: [{
        answerKey: 'SHORT_TERM_GAINS',
        answerText: 'Short term gains'
    }, {
        answerKey: 'LONG_TERM_SAVINGS',
        answerText: 'Long term savings'
    }, {
        answerKey: 'SIDE_INCOME_STREAM',
        answerText: 'Side income stream'
    }]
}, {
    id: 'trading-exp-2',
    name: 'hasFuturesTradingExp',
    type: 'close-ended',
    question: 'Are you confident with Leverage trading and/or Futures trading?',
    answers: [{
        answerKey: 'YES',
        answerText: 'Yes'
    }, {
        answerKey: 'NO',
        answerText: 'No'
    }]
}, {
    id: 'trading-exp-4',
    name: 'capital',
    type: 'dropdown',
    question: 'How much money do you plan to trade with? (Your spot and futures account combined)',
    answers: [{
        answerKey: 'UP_TO_1K',
        answerText: 'Up to $1,000'
    }, {
        answerKey: '1K_TO_5K',
        answerText: '$1,000 - $5,000'
    }, {
        answerKey: '5K_TO_10K',
        answerText: '$5,000 - $10,000'
    }, {
        answerKey: '10K_TO_20K',
        answerText: '$10,000 - $20,000'
    }, {
        answerKey: '20K_TO_50K',
        answerText: '$20,000 - $50,000'
    }, {
        answerKey: '50K_TO_100K',
        answerText: '$50,000 - $100,000'
    }, {
        answerKey: '100K_TO_200K',
        answerText: '$100,000 - $200,000'
    }, {
        answerKey: '200K_TO_500K',
        answerText: '$200,000 - $500,000'
    }, {
        answerKey: 'ABOVE_500K',
        answerText: '$500,000+'
    }]
}, {
    id: 'trading-exp-5',
    name: 'riskLevel',
    type: 'dropdown',
    question: 'What level of risk are you ok with?',
    answers: [{
        answerKey: 'LOW',
        answerText: 'Low'
    }, {
        answerKey: 'MED',
        answerText: 'Medium'
    }, {
        answerKey: 'HIGH',
        answerText: 'High'
    }]
}, {
    id: 'trading-exp-6',
    name: 'otherAssets',
    type: 'multi-select',
    question: 'What other types of assets do you trade?',
    answers: [{
        answerKey: 'STOCK_AND_SHARES',
        answerText: 'Stocks and Shares'
    }, {
        answerKey: 'FOREX',
        answerText: 'Forex'
    }, {
        answerKey: 'COMMODITIES',
        answerText: 'Commodities'
    }, {
        answerKey: 'INDICES',
        answerText: 'Indices'
    }, {
        answerKey: 'OPTIONS',
        answerText: 'Options'
    }, {
        answerKey: 'BONDS',
        answerText: 'Bonds'
    }, {
        answerKey: 'CFD',
        answerText: 'CFDs'
    }, {
        answerKey: 'NFT',
        answerText: 'NFTs'
    }]
}]