import { deviceActionTypes } from '../CONSTANTS'

export function userDevice(state = {}, action) {
    switch (action.type) {
        case deviceActionTypes.VALIDATE_SIGNOUT_DEVICES_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case deviceActionTypes.VALIDATE_SIGNOUT_DEVICES_SUCCESS: {
            return {
                ...state,
                loading: false,
                validateSignout: action.response
            }
        }
        case deviceActionTypes.VALIDATE_SIGNOUT_DEVICES_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        default:
            return state
    }
}