import styled from 'styled-components'

export const Table = styled.table`
    font-size: 13px;
    background-color: #131E2B;
    color: #ced0dd !important;
    margin-bottom: 0;
    border-radius: 4px;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;

    thead {
        tr {
            th {
                color: #6E7885;
                border: none !important;
                text-transform: uppercase;
                padding: 20px 15px;
                font-weight: 600;
                text-align: center;
                vertical-align: middle;
            }
        }

        /* order-table thead */
        &.order-thead {
            tr {
                th {
                    text-align: left;
                }
            }
        }
    }

    tbody {
        background-color: #0E1824;

        /* default tr style */
        tr {
            td {
                padding: 20px 15px;
                border-bottom: 2px solid #131E2B;
                border-top: none;
                vertical-align: middle;
                text-align: center;
            }
        }

        /* order history table tbody */
        &.order-table {
            .custom-splitter {
                background: #192431;
                cursor: pointer;

                td {
                    b {
                        font-size: 14px;
                        color: #fff;
                    }

                    .coin-name {
                        color: #6E7885;
                        font-weight: 600;
                        margin-bottom: 0;
                    }

                    &.cancel-trade {
                        text-align: center !important;

                        .btn-danger {
                            padding: 4px 10px;
                            border-radius: 2px;
                            background: #993956;
                            border: 1px solid #993956;
                        }
                    }
                }
            }

            .expanded-data {
                /* animation: showhide .3s ease-in-out;
                opacity: 1;
                transform: scaleY(1);

                @keyframes showhide {
                    0% {
                        opacity: 0;
                    }
                    1% {
                        opacity: 0;
                        transform: scaleY(0);
                    }
                    100% {
                        opacity: 1;
                        transform: scaleY(1);
                    }
                } */

                -webkit-animation: fade-in 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                animation: fade-in 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

                @-webkit-keyframes fade-in {
                    0% {
                        opacity: 0;
                    }
                    100% {
                        opacity: 1;
                    }
                }
                @keyframes fade-in {
                    0% {
                        opacity: 0;
                    }
                    100% {
                        opacity: 1;
                    }
                }

                .table-space {
                    background: #131E2B !important;
                }

                .order-action-btns {
                    .btn-warning {
                        background-color: #16b46c;
                        border-color: #16b46c;
                        color: #fff;
                    }

                    .btn-force-buy {
                        background-color: #16b46c;
                        border-color: #16b46c;
                        color: #fff;
                    }

                    .btn-force-sell {
                        background-color: #f1a92e;
                        border-color: #f1a92e;
                        color: #fff;
                    }
                }
            }

            td {
                text-align: left !important;
                border-top: 5px solid #131E2B;
                font-size: 14px;
                font-weight: 500;
            }
        }

        /* statistics table body */
        &.statistics {
            tr {
                td {
                    padding: 25px;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }

    @media screen and (min-width: 320px) and (max-width: 1023px) {
        thead {
            &.order-thead,
            &.statistics-thead {
                display: none;
            }
        }

        tbody {
            &.order-table {
                .custom-splitter {
                    .cancel-trade {
                        padding: 20px 15px 20px 0;
                    }
                }
            }

            .expanded-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;
                font-size: 13px;

                .col-sm-6 {
                    padding: 0;

                    &.col-md-8 {
                        text-align: start;
                        color: #6E7885;
                        font-weight: 600;
                    }

                    &.col-md-4 {
                        text-align: end;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        thead {
            tr {
                th {
                    font-size: 12px;
                    padding: 15px 10px;

                    &:first-child {
                        padding: 15px 0;
                    }
                }
            }
        }

        tbody {
            &.order-table {
                td {
                    font-size: 12px;
                    padding: 15px 10px;

                    &:first-child {
                        padding: 15px;
                    }
                }
            }

            &.statistics {
                tr {
                    td {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        thead {
            tr {
                th {
                    font-size: 12px;
                    padding: 15px 10px;

                    &:first-child {
                        padding: 15px 0;
                    }
                }
            }
        }

        tbody {
            &.order-table {
                td {
                    font-size: 12px;
                    padding: 15px 10px;

                    &:first-child {
                        padding: 15px;
                    }
                }
            }

            &.statistics {
                tr {
                    td {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    &.table-statistics {

        thead {
            th {
                &:first-child {
                    width: 333px;
                }
            }
        }

        tbody {
            @media screen and (min-width: 320px) and (max-width: 510px) {
                tr {
                    td {
                        .expanded-wrapper {
                            &:first-child {
                                flex-direction: column;
                                padding-bottom: 15px;
                            }
                        }
                    }
                }
            }
        }
        
    }
`;

export const StatusColor = styled.span`
    background: ${props => props.type === 'OPEN' ? '#0C252A' : '#212534'};
    color: ${props => props.type === 'OPEN' ? '#15BD6F' : props.type === 'PENDING' ? '#a9a9a9' : '#B93F5F'};
    padding: 6px 15px !important;
    font-size: 13px;
    font-weight: 600;
    border-radius: 2px;
    margin-bottom: 0;
    text-align: center !important;
`;
