import React from 'react'
import { connect } from 'react-redux'
import iziToast from 'izitoast'
import { walletActions } from '../../redux/actions'
import { QuickTradeView } from './QuickTradeView'
import { isNotNull, onSetNewState } from '../../utils'

class QuickTrade extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            currentAmount: 0,
            isTrade: false
        }
        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            if (prevProps.quickTrade !== this.props.quickTrade) {
                const { loading, quick_trade } = this.props.quickTrade
                if (!loading && isNotNull(quick_trade)) {
                    const { success, errMsg } = quick_trade
                    if (isNotNull(success)) {
                        if (success) {
                            iziToast.success({
                                position: 'topRight',
                                title: 'Success',
                                message: 'Trade Successful!',
                                displayMode: 1,
                            })
                            
                            onSetNewState(this, { isTrade: false }, () => {
                                const { dispatch } = this.props
                                dispatch(walletActions.getWallets())
                            })

                            this.props.hide()
                        } else {
                            iziToast.error({
                                position: 'topRight',
                                title: 'Error',
                                message:
                                    isNotNull(errMsg) &&
                                        errMsg === "Filter failure: MIN_NOTIONAL"
                                        ? "Amount must be greater than or equal 11 USD/USDT"
                                        : errMsg,
                                displayMode: 1,
                            })
                            onSetNewState(this, { isTrade: false })
                        }
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)
        this.mounted = false
    }

    onConfirmOrderHandler = (data) => {
        if (isNotNull(data)) {
            const { dispatch } = this.props
            dispatch(walletActions.createMarket(data))
        }
    }

    render() {
        return (
            <QuickTradeView
                self={this}
                state={this.state}
                confirmOrder={this.onConfirmOrderHandler}
                {...this.props}
            />
        )
    }
}

function mapStateToProps(state) {
    const { quickTrade, checkList } = state
    return {
        quickTrade,
        checkList
    }
}

const connectedQuickTrade = connect(mapStateToProps)(QuickTrade)
export { connectedQuickTrade as QuickTrade }