import { Skeleton } from "@material-ui/lab"
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWallet } from "@fortawesome/free-solid-svg-icons"
import { Table, Image } from 'react-bootstrap'
import {
    WalletWrapper, WalletContainer, WalletTitle, EstimatedContainer, EstimatedValTitle, EstimatedValueContainer,
    EstimatedValue, EstimatedConvertedValue, WalletTableContainer, WalletDataContainer, WalletSearchTabWrapper,
    WalletSearchContainer, WalletHeaderContainer, WalletBalanceWrapper, ShowWalletBalance, WalletContainerWrapper
} from './styled_components'
import { isNotNull, getCoinName, setCoinLogo, setDefaultImage, getFixedPrice } from '../../utils'

export const WalletView = (props) => {
    const { user } = props.authentication
    const { item, loader } = props.balanceWallet
    const {
        show, isDisable, balanceShow,
        btcValue, btcAlmostEqualValue, totalBalance,
        searchValue, currentSet
    } = props.state
    const width = window.innerWidth

    const onBalanceToggle = () => props.onBalanceToggle()

    const onSearch = (event) => props.onSearch(event.target.value.toLowerCase())

    const renderPreloader = () => {
        return (
            <>
                <Skeleton variant="rect" height={70} style={{
                    width: '100%',
                    marginBottom: '15px',
                    backgroundImage: 'linear-gradient(15deg,#334156,#385065)',
                    border: '1px solid #27313f',
                    borderRadius: '4px'
                }} className="skeleton" />

                <Skeleton variant="rect" style={{
                    width: '100%',
                    height: '625px',
                    backgroundImage: 'linear-gradient(15deg,#334156,#385065)',
                    border: '1px solid #27313f',
                    borderRadius: '4px'
                }} className="skeleton" />
            </>
        )
    }

    const renderWalletItems = () => {
        if (isNotNull(item)) {
            const { data, totalBtc, totalUsdt } = item
            if (isNotNull(data)) {
                const filtered = _.filter(data, (val) => {
                    return searchValue === null
                        ? data
                        : val.name.toLowerCase().includes(searchValue) || val.coinName.toLowerCase().includes(searchValue)
                })

                const filteredBalance = _.filter(filtered, (val) => {
                    return balanceShow ? data : val.balance.free !== 0 && parseFloat(val.balance.usdt) > 10
                })

                const filteredData = _.filter(filteredBalance, (val, key) => key <= currentSet)
                if (isNotNull(filteredData) && filteredData.length > 0) {
                    return _.map(filteredData, (value, key) => {
                        return (
                            <tr key={key} className="expanded-data">
                                {width > 1024 &&
                                    <>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <Image className="wallet-logo" src={setCoinLogo(value.name.toLowerCase())} onError={(e) => setDefaultImage(e)} fluid roundedCircle />
                                                <div className="coinSymbolName">
                                                    {value.name}
                                                </div>
                                            </div>
                                        </td>
                                        <td>{getCoinName(value.name)}</td>
                                        <td>{value.balance.total}</td>
                                        <td>{value.balance.total}</td>
                                        <td>{`${value.name === 'BTC' ? '--' : (typeof value.balance.btc !== 'undefined' ? _.round(value.balance.btc, 8) : "0.00")}`}</td>
                                        <td>{`${value.name === 'USDT' ? '--' : (typeof value.balance.usdt !== 'undefined' ? getFixedPrice(value.balance.usdt) : "0.00")}`}</td>
                                    </>
                                }

                                {width <= 1024 &&
                                    <td colSpan={9}>
                                        <div className="expanded-wrapper">
                                            <span className="col-sm-6 col-md-8">COIN</span>
                                            <span className="col-sm-6 col-md-4">
                                                <div className="d-flex align-items-center justify-content-end">
                                                    <Image className="wallet-logo" src={setCoinLogo(value.name.toLowerCase())} onError={(e) => setDefaultImage(e)} fluid roundedCircle />
                                                    <div className="coinSymbolName">
                                                        {value.name}
                                                    </div>
                                                </div>
                                            </span>
                                        </div>

                                        <div className="expanded-wrapper">
                                            <span className="col-sm-6 col-md-8">NAME</span>
                                            <span className="col-sm-6 col-md-4">{getCoinName(value.name)}</span>
                                        </div>

                                        <div className="expanded-wrapper">
                                            <span className="col-sm-6 col-md-8">TOTAL BALANCE</span>
                                            <span className="col-sm-6 col-md-4">{value.balance.free}</span>
                                        </div>

                                        <div className="expanded-wrapper">
                                            <span className="col-sm-6 col-md-8">AVAILABLE BALANCE</span>
                                            <span className="col-sm-6 col-md-4">{value.balance.total}</span>
                                        </div>

                                        <div className="expanded-wrapper">
                                            <span className="col-sm-6 col-md-8">BTC</span>
                                            <span className="col-sm-6 col-md-4">{`${value.name === 'BTC' ? '--' : (typeof value.balance.btc !== 'undefined' ? _.round(value.balance.btc, 8) : "0.00")}`}</span>
                                        </div>

                                        <div className="expanded-wrapper">
                                            <span className="col-sm-6 col-md-8">USDT</span>
                                            <span className="col-sm-6 col-md-4">{`${value.name === 'USDT' ? '--' : (typeof value.balance.usdt !== 'undefined' ? getFixedPrice(value.balance.usdt) : "0.00")}`}</span>
                                        </div>
                                    </td>
                                }
                            </tr>
                        )
                    })
                } else if (isNotNull(filteredData) && filteredData.length === 0) {
                    if (isNotNull(searchValue))
                        return (
                            <tr className="no-data">
                                <td colSpan="6">
                                    <h5 className="my-3">No Coin Found</h5>
                                </td>
                            </tr>
                        )
                    else
                        if ((isNotNull(totalBtc) && totalBtc === 0) && (isNotNull(totalUsdt) && totalUsdt === 0))
                            return (
                                <tr className="no-data">
                                    <td colSpan="6">
                                        <h5 className="my-3">You do not have any wallets that have a balance</h5>
                                    </td>
                                </tr>
                            )
                }
            }
        } else
            return null
    }

    const walletTable = renderWalletItems(item)

    return (
        <WalletWrapper className="theme-main-wrapper">
            <WalletContainer className="theme-balance-page">
                <WalletContainerWrapper>
                    <WalletTitle className="mb-0 theme-section-title"><FontAwesomeIcon icon={faWallet} /> Balance</WalletTitle>

                    <WalletSearchTabWrapper>
                        <WalletBalanceWrapper>
                            <ShowWalletBalance onChange={() => !isDisable ? onBalanceToggle() : null} className="wallet-toggle">
                                <input type="checkbox" />
                                <i className="wallet-toggle" />
                                {balanceShow ? "Show Balance" : "Show 0 Balance"}
                            </ShowWalletBalance>
                        </WalletBalanceWrapper>
                        <WalletSearchContainer>
                            <input type="text" className="form-control" placeholder="Search Coin" onChange={(e) => onSearch(e)} />
                        </WalletSearchContainer>
                    </WalletSearchTabWrapper>
                </WalletContainerWrapper>

                { loader && renderPreloader() }

                { !loader &&
                    <>
                        { isNotNull(user) &&
                            <>
                                { (!show && user.isAutoTradingAllowed && user.tradingApiKey !== null) &&
                                    <WalletDataContainer>
                                        <h4 className="text-center mt-5">Oops, it looks like your wallet is empty.</h4>
                                    </WalletDataContainer>
                                }

                                { ((!show && !user.isAutoTradingAllowed && user.tradingApiKey == null) || (!user.isAutoTradingAllowed && user.tradingApiKey !== null)) &&
                                    <WalletDataContainer>
                                        <h4 className="text-center mt-5">Auto-trading feature is currently disabled in your account. To enable this, please go to <Link to="/account/auto-trade">Settings.</Link></h4>
                                    </WalletDataContainer>
                                }

                                { show && user.isAutoTradingAllowed && user.tradingApiKey !== null &&
                                    <WalletDataContainer className="theme-estimated-value">
                                        <EstimatedContainer>
                                            <WalletHeaderContainer>
                                                <EstimatedValTitle>Estimated Value</EstimatedValTitle>

                                                <EstimatedValueContainer>
                                                    <span>
                                                        <EstimatedValue>{btcValue}<span> BTC</span></EstimatedValue>
                                                        <EstimatedConvertedValue> <span>= ${btcAlmostEqualValue}</span></EstimatedConvertedValue>
                                                    </span>
                                                </EstimatedValueContainer>
                                            </WalletHeaderContainer>
                                        </EstimatedContainer>
                                        <WalletTableContainer>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>COIN</th>
                                                        <th>NAME</th>
                                                        <th>TOTAL BALANCE</th>
                                                        <th>AVAILABLE BALANCE</th>
                                                        <th>BTC</th>
                                                        <th>USDT</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="wallet-table">
                                                    {walletTable !== null ?
                                                        <>{walletTable}</> :
                                                        <tr>
                                                            <td>
                                                                <div className="text-center">
                                                                    {
                                                                        totalBalance < 11
                                                                            ? <h5 className="my-3">You do not have any wallets that have a balance</h5>
                                                                            : <div className="spinner-border" role="status">
                                                                                <span className="sr-only">Loading...</span>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                        </WalletTableContainer>
                                    </WalletDataContainer>
                                }
                            </>
                        }
                    </>
                }
            </WalletContainer>
        </WalletWrapper>
    )
}