import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const ProTrialDaysSectionStyle = styled.section`
    position: relative;
    background-color: #0B1219;
    width: 100%;
    padding: 0px 10px 40px;

    .container {
        .image-text-row {
            display: flex;
            flex-direction: column-reverse;
            text-align: center;

            .text-col {
                color: #FFFFFF;

                .section-title {
                    font-weight: 800;
                    font-size: 25px;
                }
            }

            .image-col {
                img {
                    width: 100%;
                }
            }
        }

        .text {
            color: #9AB9D5;
            text-align: center;

            strong {
                color: #FFFFFF;
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        .container {
            .image-text-row {
                margin-bottom: 25px;
                
                .text-col {
                    .section-title {
                        font-size: 35px;
                    }
                }

                .image-col {
                    img {
                        width: 60%;
                    }
                }
            }  
        }
    }

    ${mediaQueries.largeDevices} {
        .container {
            .image-text-row {
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .text-col {
                    width: 50%;
                    text-align: left;
                }

                .image-col {
                    width: 50%;

                    img {
                        width: 100%;
                    }
                }
            }

            .text {
                width: 950px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        .container {
            .image-text-row {
                .text-col {
                    width: 45%;

                    .section-title {
                        font-size: 36px;
                    }

                    p {
                        padding-right: 110px;
                    }
                }

                .image-col {
                    width: 55%;
                }
            }
        }
    }

    ${mediaQueries.superExtraLargeDevices} {
        .container {
            .image-text-row {
                .text-col {
                    .section-title {
                        font-size: 40px;
                    }
                }
            }
        }
    }
`