import React from 'react'
import { ImagineThreeMonthsStyle } from './styles/ImagineThreeMonthsStyle'

export const ImagineThreeMonthsSection = () => {
    return (
        <ImagineThreeMonthsStyle>
            <div className='container'>
                <div className='text-content'>
                    <h2 className='font-weight-bold'>Imagine Where You Could Be In Just 3 Months</h2>
                    <p>Imagine…</p>
                    <p>3 months from now, you’re making gains on 78% of 300+ completed trading signals.</p>
                    <p>Maybe you’ll be the next ProfitFarmer to make $14,000 in one month. Or maybe you’ll be the next to quit their job and travel the world.</p>
                    <p>Maybe you’ll be the one who cuts their workday in half or more, and finally spends more time with their partner and children. Or maybe you’ll just make solid money in 15 minutes a day, and sleep all day every day.</p>
                    <p>It’s up to you.</p>
                    <p>That’s the key there. YOU.</p>
                    <p>With ProfitFarmers Pro, you can finally be free. Then, it’s up to you to do whatever you want to do. Invest, travel, hang out with your family, sleep - it’s your life. Live it.</p>
                    <p>Why wouldn’t you want that?</p>
                    <p>You either get 60% completed signals win-rate or you get your money back. It’s that simple. Set a realistic target, a realistic time frame and start kicking some ass!</p>
                </div>
            </div>
        </ImagineThreeMonthsStyle>
    )
}