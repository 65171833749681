import React from 'react';
import ReactDOM from 'react-dom'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import App from './App'
import SimpleReactLightbox from 'simple-react-lightbox'

window.dataLayer = window.dataLayer || []

ReactDOM.render(
    <div style={{ width: '100%', minHeight: '100%', position: 'relative' }}>
      <SimpleReactLightbox>
          <App />
      </SimpleReactLightbox>
    </div>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
