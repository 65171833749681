import React from 'react'
import { connect } from 'react-redux'
import { SafeAnchor } from 'react-bootstrap'
import { blueprintService } from '../../services'
import { isNotNull, isNull, onSetNewState } from '../../utils'

class BlueprintMenuLink extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isPremiumAccess: null
        }

        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true

        const { isLoading, isPremiumAccess } = this.state
        const { authentication } = this.props
        const { user } = authentication
        if (isLoading && 
            isNull(isPremiumAccess) && 
            isNotNull(user)) {
                onSetNewState(this, {
                    isPremiumAccess: user.isPaid
                })
            }
    }

    componentDidUpdate() {
        if (this.mounted) {
            const { isLoading, isPremiumAccess } = this.state
            const { authentication } = this.props
            const { user } = authentication
            
            if (isLoading && 
                isNull(isPremiumAccess) && 
                isNotNull(user)) {
                onSetNewState(this, {
                    isPremiumAccess: user.isPaid
                })
            }
            
            if (isLoading && isNotNull(isPremiumAccess)) {
                onSetNewState(this, {
                    isLoading: false
                })
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    viewCourseHandler = async (e) => {
        e.preventDefault()
        
        const { authentication } = this.props
        if (isNotNull(authentication)) {
            const { user } = authentication
            if (isNotNull(user) && isNotNull(user.roles)) {
                const { userName, roles } = user
                let userRole = roles[0]
                let res = await blueprintService.setRole(userName, userRole.toLowerCase())
                if (isNotNull(res) && res.success) {
                    window.open('https://www.profitfarmers.com/academy/the-pro-trader-5x-blueprint', '_blank')
                }
            }
        }
    }

    render() {
        const { isLoading, isPremiumAccess } = this.state
        if (!isLoading && 
            isNotNull(isPremiumAccess) && 
            isPremiumAccess) {
            // return (
            //     <li className='sidebar-submenu-item'>
            //         <SafeAnchor 
            //             href='https://www.profitfarmers.com/academy/the-pro-trader-5x-blueprint' 
            //             target="_blank" 
            //             rel="noreferrer" className="sidebar-submenu-item-link">
            //             <span className='submenu-item-link-icon'><i className='fas fa-map'></i></span>
            //             <span className='submenu-item-link-text'>Pro Trader 5x Blueprint</span>
            //         </SafeAnchor>
            //     </li>
            // )

            return (
                <button type='button' className='menu-btn' onClick={(e) => this.viewCourseHandler(e)} disabled={isLoading}>
                    <span className='icon'>
                        <i className='fas fa-map'></i>
                    </span>
                    <span className='text'>Pro Trader 5x Blueprint</span>
                </button>
            )
        }

        return (<></>)
    }
}

function mapStateToProps(state) {
    const { authentication } = state
    return {
        authentication
    }
}

const connectedBlueprintMenuLink = connect(mapStateToProps)(BlueprintMenuLink)
export { connectedBlueprintMenuLink as BlueprintMenuLink }