import { surveyActionTypes } from '../CONSTANTS'
import { surveyData } from '../../utils/data'
import { surveyServices } from '../../services'

function getSurvey() {
    return dispatch => dispatch({ type: surveyActionTypes.GET_SURVEY, surveyData })
}

function updateSurvey(data) {
    return dispatch => dispatch({ type: surveyActionTypes.UPDATE_SURVEY, data })
}

function updateStep(step) {
    return dispatch => dispatch({ type: surveyActionTypes.UPDATE_STEP, step })
}

function updateAnswer(data) {
    return dispatch => dispatch({ type: surveyActionTypes.UPDATE_ANSWER, data })
}

function resetSurvey() {
    return dispatch => dispatch({ type: surveyActionTypes.RESET_SURVEY, surveyData })
}

function saveSurvey(data) {
    return dispatch => {
        dispatch(request())

        surveyServices.saveSurvey(data).then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: surveyActionTypes.SAVE_SURVEY_REQUEST } }
    function success(response) { return { type: surveyActionTypes.SAVE_SURVEY_SUCCESS, response } }
    function failure(error) { return { type: surveyActionTypes.SAVE_SURVEY_FAILURE, error } }
}

export const surveyActions = {
    getSurvey,
    updateSurvey,
    updateAnswer,
    updateStep,
    resetSurvey,
    saveSurvey
}