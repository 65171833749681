import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const WelcomeMessageBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    
    .header-photo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 100%;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
        overflow: hidden;
        margin-bottom: 25px;

        &.welcome {
            height: auto;

            img {
                width: 100%;
                margin-top: -2px;
            }
        }

        &.tour {
            height: auto;
            margin-bottom: 0;

            img {
                width: 376px;
                margin: 20px auto;
            }
        }
    }

    .text-content {
        padding: 0 25px;

        h1, p {
            display: block;
            text-align: center;
        }

        h1 {
            color: #0D141E;
            font-size: 20px;
            font-weight: bold;
        }

        p {
            color: #515F6E;
            font-size: 14px !important;
            font-weight: 500;
        }
    }

    ${mediaQueries.mediumDevices} {
        .header-photo {
            max-width: 652px;

            &.welcome {
                height: 293px;
            }

            &.tour {
                margin-bottom: 25px;

                img {
                    margin: 50px auto 20px;
                }
            }
        }

        .text-content {
            h1 {
                font-size: 26px;
            }

            p {
                font-size: 17px !important;
            }
        }
    }

    ${mediaQueries.largeDevices} {
        .header-photo {
            &.welcome {
                height: auto;
            }
        }
    }

    @-webkit-keyframes fadeInText {
        100% {
            opacity: 1;
            filter: blur(0);
        }
    }

    @keyframes fadeInText {
        100% {
            opacity: 1;
            filter: blur(0);
        }
    }
`