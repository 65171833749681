import React from 'react'
import { Modal } from 'react-bootstrap'
import { isNotNull, onSetNewState } from '../../utils'
import './styles/generic-modal.scss'

export class GenericModal extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
            header: null,
            isHeaderIniated: false,
            bodyContent: null,
            isBodyInitiated: false,
            footer: null,
            isFooterInitiated: false
        }
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
    }

    componentDidUpdate(prevProps, prevState) {
        const { show, isHeaderIniated, isBodyInitiated, isFooterInitiated } = this.state

        if (!isHeaderIniated && isNotNull(this.props.header)) {
            onSetNewState(this, {
                header: this.props.header,
                isHeaderIniated: true
            })
        }

        if (!isBodyInitiated && isNotNull(this.props.bodyContent)) {
            onSetNewState(this, {
                bodyContent: this.props.bodyContent,
                isBodyInitiated: true
            })
        }

        if (!isFooterInitiated && isNotNull(this.props.footer)) {
            onSetNewState(this, {
                footer: this.props.footer,
                isFooterInitiated: true
            })
        }

        if (!show && this.props.show) {
            onSetNewState(this, {
                show: this.props.show
            })
        }
    }

    componentWillUnmount() {
        onSetNewState(this, {
            show: false,
            header: null,
            isHeaderIniated: false,
            bodyContent: null,
            isBodyInitiated: false,
            footer: null,
            isFooterInitiated: false
        })

        this.mounted = false
    }

    render() {
        const { show, onHide, id, className, style, backdrop, keyboard, size, closeButton, bodyClass } = this.props
        const { header, bodyContent, footer } = this.state
        
        if (isNotNull(bodyContent)) {
            return (
                <Modal id={id}
                    className={`generic-modal ${className}`}
                    style={style}
                    show={show}
                    onHide={onHide}
                    aria-labelledby="genericModal"
                    aria-hidden="true"
                    data-backdrop="true"
                    backdrop={isNotNull(backdrop) ? backdrop : true}
                    keyboard={isNotNull(keyboard) ? keyboard : false}
                    size={size}
                    centered>
                    { isNotNull(header) &&
                        <Modal.Header closeButton={isNotNull(closeButton) ? closeButton : false}>
                            {header()}
                        </Modal.Header>
                    }
    
                    { isNotNull(bodyContent) &&
                        <Modal.Body className={isNotNull(bodyClass) ? bodyClass : ""}>
                            {bodyContent()}
                        </Modal.Body>
                    }
    
                    { isNotNull(footer) &&
                        <Modal.Footer className="custom-modal-footer">
                            {footer()}
                        </Modal.Footer>
                    }
                </Modal>
            )
        } else {
            return null
        }
    }
}