import { subscriptionActionTypes, userActionTypes } from '../CONSTANTS'
import { subscriptionServices, userServices } from '../../services'
import { isNotNull } from '../../utils'

function getSubscriptionDetails() {
    return dispatch => {
        dispatch(request())

        subscriptionServices.getSubscriptionDetails()
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failed(error)))
    }

    function request() { return { type: subscriptionActionTypes.GET_DETAILS_REQUEST } }
    function success(response) { return { type: subscriptionActionTypes.GET_DETAILS_SUCCESS, response } }
    function failed(error) { return { type: subscriptionActionTypes.GET_DETAILS_FAILED, error } }
}

function cancelSubscription() {
    return dispatch => {
        dispatch(request())

        subscriptionServices.cancelSubscription().then(response => {
            if (isNotNull(response.success)) {
                if (!response.success)
                    dispatch(failure(response.msg))
                else {
                    dispatch(success(response))
                    userServices.getMe().then(user => {
                        dispatch({ type: userActionTypes.GET_SUCCESS, user })
                    })
                }
            } else
                if (isNotNull(response.msg))
                    dispatch(failure(response.msg))
        }).catch(error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: subscriptionActionTypes.CANCEL_SUB_REQUEST } }
    function success(subscription) { return { type: subscriptionActionTypes.CANCEL_SUB_SUCCESS, subscription } }
    function failure(error) { return { type: subscriptionActionTypes.CANCEL_SUB_FAILURE, error } }
}

export const subscriptionActions = {
    getSubscriptionDetails,
    cancelSubscription
}