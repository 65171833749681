import { useEffect, useState } from 'react'
import moment from 'moment'
import { isNotNull } from '../../utils'

export const OfferTimer = (props) => {
    let [remainingDays, setRemainingDays] = useState('')
    let [remainingHours, setRemainingHours] = useState('')
    let [remainingMinutes, setRemainingMinutes] = useState('')
    let [remainingSeconds, setRemainingSeconds] = useState('')

    let [isEarlyBirdOffer, setIsEarlyBirdOffer] = useState(false)
    let [isFinalYearlyOffer, setIsFinalYearlyOffer] = useState(false)

    let parsedDate = Date.parse(props.validUntil)
    let expiry = new Date(parsedDate)
        expiry = moment(expiry).format('MM/DD/yyyy h:mm:ss')

    const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

    let today = new Date(),
        dd = String(today.getDate()).padStart(2, "0"),
        mm = String(today.getMonth() + 1).padStart(2, "0"),
        yyyy = today.getFullYear(),
        nextYear = yyyy + 1,
        dayMonth = moment(expiry).format('MM/DD/')

    today = mm + "/" + dd + "/" + yyyy
    if (today > expiry) {
        expiry = dayMonth + nextYear
    }

    const countDown = new Date(expiry).getTime(),
        x = setInterval(function() {    
            const now = new Date().getTime(),
                distance = countDown - now

            setRemainingDays(Math.floor(distance / (day)))
            setRemainingHours(Math.floor((distance % (day)) / (hour)))
            setRemainingMinutes(Math.floor((distance % (hour)) / (minute)))
            setRemainingSeconds(Math.floor((distance % (minute)) / second))
            
            if (isNotNull(remainingDays)) {
                if (parseInt(remainingDays) >= 14) {
                    if (!isEarlyBirdOffer)
                        setIsEarlyBirdOffer(true)

                    if (isFinalYearlyOffer)
                        setIsFinalYearlyOffer(false)
                } else {
                    if (isEarlyBirdOffer)
                        setIsEarlyBirdOffer(false)
                    
                    if (!isFinalYearlyOffer)  
                        setIsFinalYearlyOffer(true)
                }
            }

            if (distance < 0) {
                clearInterval(x)
            }
            //seconds
        }, 1000)

    return (
        <>
            <div className='price-offer'>
                <span className='crashed-out-price'>$4,300</span>
                { isEarlyBirdOffer && <span className='new-price'>$1,499</span> }
                { isFinalYearlyOffer && <span className='new-price'>$1,799</span> }
            </div>
            <div className='discount-tag'>
                { isEarlyBirdOffer && <span>SAVE $2,800</span> }
                { isFinalYearlyOffer && <span>SAVE $2,500</span> }
            </div>
            { isEarlyBirdOffer && <div className='price-increase'>Price will increase +20% in</div> }
            { isFinalYearlyOffer && <div className='price-increase'>This price ends in</div> }
            <div className='offer-validity-timer'>
                <ul>
                    <li>
                        <span id='cta-d' className='counter'>{parseInt(remainingDays) < 10 ? '0': ''}{remainingDays}</span>
                        <small>Days</small>
                    </li>
                    <li>
                        <span id='cta-h' className='counter'>{parseInt(remainingHours) < 10 ? '0': ''}{remainingHours}</span>
                        <small>Hours</small>
                    </li>
                    <li>
                        <span id='cta-m' className='counter'>{parseInt(remainingMinutes) < 10 ? '0': ''}{remainingMinutes}</span>
                        <small>Minutes</small>
                    </li>
                    <li>
                        <span id='cta-s' className='counter'>{parseInt(remainingSeconds) < 10 ? '0': ''}{remainingSeconds}</span>
                        <small>Seconds</small>
                    </li>
                </ul>     
            </div>
        </>
    )
}