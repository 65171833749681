import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Swal from 'sweetalert2'
import { orderActions } from '../../redux/actions'
import { orderActionTypes } from '../../redux/CONSTANTS'
import { OrderView } from './OrderView'
import { Dimension, isNotNull, onSetNewState, queryString, addPageViewEvent, seo } from '../../utils'

class OrderContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            eventKey: 'orders',
            currentHistorySet: 10,
            currentOpenSet: 10,
            currentFuturesHistorySet: 10,
            currentFuturesOpenSet: 10,
            currentPositionSet: 10,
            totalHistoryItems: 0,
            totalOpenItems: 0,
            totalPositionItem: 0,
            selectedGroup: null,
            expanded: null,
            feedValue: "SPOT",
            isLivePositions: true,
            closedPositionIndex: null,
            orderPositionList: [],
        }

        this.mounted = false
        window.onscroll = _.debounce(() => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.scrollingElement.scrollHeight - 1) {
                this.onScrollLoadNewHistoryDataHandler()
                this.onScrollLoadNewOpenDataHandler()
            }
        })
    }

    componentDidMount() {
        this.mounted = true
        const { dispatch } = this.props

        // SPOT 
        dispatch(orderActions.getOrderHistory())
        dispatch(orderActions.getOpenOrders())

        // FUTURES
        dispatch(orderActions.getFuturesOrderHistory())
        dispatch(orderActions.getFuturesOpenOrder())

        dispatch(orderActions.getOrderPositions())

        // check feed if the value is futures then
        // show futures order history
        const feed = queryString("feed")

        if (isNotNull(feed) && feed.toUpperCase() === "FUTURES")
            this.onSetFeedToggleHandler(feed.toUpperCase())     

        seo({
            title: "Trade Orders",
            metaDescription: ""
        })
        addPageViewEvent()

        window.addEventListener('resize', () => Dimension.updateDimensions(this))
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { exitOrder, cancelOrder, openOrder, futuresOpenOrder, dispatch } = this.props

            if (isNotNull(exitOrder) && 
                isNotNull(exitOrder.loading) && 
                exitOrder.loading === false && 
                prevProps.exitOrder.loading === true) {
                setTimeout(() => {
                    dispatch(orderActions.getOpenOrders())
                    dispatch(orderActions.getOrderHistory())
                    dispatch(orderActions.getFuturesOpenOrder())  
                    dispatch(orderActions.getFuturesOrderHistory())
                    dispatch(orderActions.getOrderPositions())
                }, 2000)
            }

            if (isNotNull(cancelOrder) &&
                isNotNull(cancelOrder.loading) &&
                cancelOrder.loading === false &&
                prevProps.cancelOrder.loading === true) {

                Swal.close()
                Swal.fire(
                    'Cancelled!',
                    'Your trade has been cancelled.',
                    'success'
                )

                // remove from the reducer
                if (isNotNull(openOrder) && isNotNull(openOrder.listsItem) && isNotNull(openOrder.listsItem.data)) {
                    const openOrderLists = [...openOrder.listsItem.data]
                    const { selectedGroup } = this.state
                    const index = _.findIndex(openOrderLists, order => order.groupId === selectedGroup)

                    if (index >= 0) {
                        openOrderLists.splice(index, 1)
                        const open_order = { data: openOrderLists }
                        dispatch({ type: orderActionTypes.OPEN_ORDER_SUCCESS, open_order })
                    }
                }

                if (isNotNull(futuresOpenOrder) && isNotNull(futuresOpenOrder.futures_open_order_item) && isNotNull(futuresOpenOrder.futures_open_order_item.data)) {
                    const openOrderLists = [...futuresOpenOrder.futures_open_order_item.data]
                    const { selectedGroup } = this.state
                    const index = _.findIndex(openOrderLists, order => order.groupId === selectedGroup)

                    if (index >= 0) {
                        openOrderLists.splice(index, 1)
                        const open_order = { data: openOrderLists }
                        dispatch({ type: orderActionTypes.FUTURES_OPEN_ORDER_SUCCESS, open_order })
                    }
                }

                setTimeout(() => {
                    dispatch(orderActions.getOrderHistory())
                    dispatch(orderActions.getFuturesOrderHistory())
                }, 2000)
            }


            if (prevProps.orderHistory !== this.props.orderHistory) {
                const { loading, items } = this.props.orderHistory
                if (!loading) {
                    if (isNotNull(items) && isNotNull(items.data))
                        onSetNewState(this, { totalHistoryItems: items.data.length })
                }
            }

            if (prevProps.openOrder !== this.props.openOrder) {
                const { loader, listsItem } = this.props.openOrder
                if (!loader) {
                    if (isNotNull(listsItem) && isNotNull(listsItem.data))
                        onSetNewState(this, { totalOpenItems: listsItem.data.length })
                }
            }

            if (prevProps.orderPositions !== this.props.orderPositions) {
                const { loading_positions, positionData } = this.props.orderPositions
                if (!loading_positions) {
                    if (isNotNull(positionData))
                        onSetNewState(this, {
                            totalPositionItem: isNotNull(positionData.position) ? positionData.positions.length : 0,
                            orderPositionList: positionData
                        })
                }
            }

            if (prevProps.closeOrderPosition !== this.props.closeOrderPosition) {

                const { loading_close_order_position, positionsClosed } = this.props.closeOrderPosition

                if (!loading_close_order_position && isNotNull(positionsClosed)) {

                    const { success } = positionsClosed

                    Swal.close()

                    if (success) {

                        Swal.fire(
                            'Closed!',
                            'Your position order has been closed.',
                            'success'
                        )

                        const { closedPositionIndex, orderPositionList } = this.state

                        // set position index to zero so that it will not be shown in display
                        orderPositionList.positions[closedPositionIndex].positionAmt = '0';
                        onSetNewState(this, { orderPositionList })
                    }
                    else
                        Swal.fire(
                            'Error',
                            'Oops, there`s a problem closing your order please try again',
                            'error'
                        )
                }

            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => Dimension.updateDimensions(this))
        this.mounted = false
    }

    onTriggerOrderRefresh = () => {
        const { dispatch } = this.props

        // SPOT 
        dispatch(orderActions.getOrderHistory())
        dispatch(orderActions.getOpenOrders())

        // FUTURES
        dispatch(orderActions.getFuturesOrderHistory())
        dispatch(orderActions.getFuturesOpenOrder())

        dispatch(orderActions.getOrderPositions())
    }

    onScrollLoadNewHistoryDataHandler = () => {
        const { currentHistorySet, totalHistoryItems } = this.state
        const newSet = currentHistorySet + 10
        if (currentHistorySet <= totalHistoryItems)
            onSetNewState(this, { currentHistorySet: newSet })
    }

    onScrollLoadNewOpenDataHandler = () => {
        const { currentOpenSet, totalOpenItems } = this.state
        const newSet = currentOpenSet + 10
        if (currentOpenSet <= totalOpenItems)
            onSetNewState(this, { currentOpenSet: newSet })
    }

    onExpandHandler = (id) => {
        let active = this.state.expanded === id ? null : id
        onSetNewState(this, { expanded: active })
    }

    onDisplayHandler = (id) => this.state.expanded === id ? 'table-row' : 'none'

    handleExitTradeOrder = (orderType, tradeItem, feed) => {
        const { openOrder, futuresOpenOrder } = this.props
        switch (feed) {
            default: {
                this.onForceExitOrder(
                    orderType,
                    openOrder,
                    openOrder.listsItem,
                    openOrder.listsItem.data,
                    tradeItem,
                    feed
                )
                break
            }
            case 'FUTURES': {
                this.onForceExitOrder(
                    orderType,
                    futuresOpenOrder,
                    futuresOpenOrder.futures_open_order_item,
                    futuresOpenOrder.futures_open_order_item.data,
                    tradeItem,
                    feed
                )
                break
            }
        }
    }

    onForceExitOrder = (orderType, openOrder, listItem, data, tradeItem, feed) => {
        const { dispatch } = this.props
        if (isNotNull(openOrder) && 
            isNotNull(listItem) && 
            isNotNull(data) && 
            isNotNull(tradeItem) && 
            isNotNull(tradeItem[0])) {
                const groupId = tradeItem[0].group_id
                if (isNotNull(groupId)) {
                    this.onSelectedGroupIdHandler(groupId)

                    const orderDetails = _.find(data, o => o.groupId===groupId)
                    if (isNotNull(orderDetails) && isNotNull(orderDetails.item)) {
                        Swal.fire({
                            title: 'Are you sure you want to exit this order?',
                            text: 'It will close this order at market price by placing a Market buy/sell order on exchange',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Confirm'
                        }).then((result) => {
                            if (result.value) {
                                const orderData = _.find(orderDetails.item, o => o.type === orderType)
                                const orderObj = {
                                    sellId: orderData.id,
                                    type: orderData.type,
                                    feed
                                }
                                
                                dispatch(orderActions.exitOrder(orderObj))
                            }
                        })
                    }
                }
            }
    }

    onCancelTradeHandler = (orderType, tradeItem, feed) => {
        const { openOrder, futuresOpenOrder } = this.props
        switch (feed) {
            case 'FUTURES': {
                this.onCancelOrderGeneric(
                    orderType,
                    futuresOpenOrder,
                    futuresOpenOrder.futures_open_order_item,
                    futuresOpenOrder.futures_open_order_item.data,
                    tradeItem,
                    feed
                )
                break
            }
            default: {
                this.onCancelOrderGeneric(
                    orderType,
                    openOrder,
                    openOrder.listsItem,
                    openOrder.listsItem.data,
                    tradeItem,
                    "SPOT"
                )
                break
            }
        }
    }

    onCancelOrderGeneric = (orderType, openOrder, listItem, data, tradeItem, feed) => {
        const { dispatch } = this.props
        if (isNotNull(openOrder) &&
            isNotNull(listItem) &&
            isNotNull(data) &&
            isNotNull(tradeItem) &&
            isNotNull(tradeItem[0])) {
            // since we are sure that trade index 0 will always have value
            const groupId = tradeItem[0].group_id

            if (isNotNull(groupId)) {
                // find openOrder in lodash
                const orderList = _.find(data, o => o.groupId === groupId)
                this.onSelectedGroupIdHandler(groupId)
                if (isNotNull(orderList) && isNotNull(orderList.item)) {
                    // since we are sure that open order will have atleast 1 trade
                    Swal.fire({
                        title: 'Are you sure?',
                        text: "You want to cancel this trade order?",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, cancel it!'
                    }).then((result) => {
                        if (result.value) {
                            // request from endpoint then remove from reducer
                            Swal.fire({
                                title: 'Cancelling trade order..',
                                allowOutsideClick: false,
                                showConfirmButton: false,
                                willOpen: () => Swal.showLoading()
                            })

                            let selectedOrder = _.find(orderList.item, o => o.type === orderType)
                            dispatch(orderActions.cancelOrder(selectedOrder.id, feed))
                        }
                    })
                }
            }
        }
    }

    onClosePositionOrderHandler = (positionObject, index) => {
        const { dispatch } = this.props

        onSetNewState(this, { closedPositionIndex: index })
        dispatch(orderActions.closePositions(positionObject))
    }

    onSetFeedToggleHandler = value => {
        onSetNewState(this, { feedValue: value })
    }

    onSelectedGroupIdHandler = groupId => onSetNewState(this, { selectedGroup: groupId })

    render() {
        return (
            <OrderView
                onExpand={this.onExpandHandler}
                onDisplay={this.onDisplayHandler}
                handleExitTradeOrder={this.handleExitTradeOrder}
                onCancelTrade={this.onCancelTradeHandler}
                eventKey={this.eventKey}
                futuresEventKey={this.futuresEventKey}
                onSetFeedToggleHandler={this.onSetFeedToggleHandler}
                onTriggerOrderRefresh={this.onTriggerOrderRefresh}
                onClosePositionOrderHandler={this.onClosePositionOrderHandler}
                state={this.state}
                {...this.props}
            />
        )
    }
}

function mapStateToProps(state) {
    const { authentication, orderHistory, openOrder, cancelOrder, futuresOrderHistory, futuresOpenOrder, orderPositions, closeOrderPosition, exitOrder } = state
    return {
        authentication,
        orderHistory,
        openOrder,
        cancelOrder,
        futuresOrderHistory,
        futuresOpenOrder,
        orderPositions,
        closeOrderPosition,
        exitOrder
    }
}
const connectedOrderContainer = connect(mapStateToProps)(OrderContainer)
export { connectedOrderContainer as OrderContainer }