import React, { useState } from 'react'
import {
    RadioGroup, Radio, FormControl,
    FormControlLabel
} from '@material-ui/core'
import { Modal, Button, SafeAnchor } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
import {
    SurveyWrapper, SurveyBody, SurveyFooter,
    SurveyQuestionWrapper, SurveyQuestion, SurveyButtonWrapper,
    ButtonContainer
} from './styled_components'
import { CloseButton } from '../../styles'
import { getSurveyQuestion } from '../../utils/data'
import { isNotNull, isNull } from '../../utils'

export const CustomSurvey = (props) => {
    const { title, data, nextBillingDate, customerId, hasClose, isCancel } = props
    const steps = {
        Q1: "q1",
        Q2: "q2",
        Q3: "q3"
    }
    const withInputFields = {
        Q1: {
            option1: 4,
            option2: 10
        },
        Q2: {
            option1: 19
        }
    }
    const [inputAnswers, setInputAnswers] = useState([
        {
            questionKey: steps.Q1,
            answerKey: null,
            answerValue: ""
        },
        {
            questionKey: steps.Q2,
            answerKey: null,
            answerValue: ""
        }
    ])
    const [timer, setTimer] = useState(null)

    const onChangeHandler = (event, questionKey) => {
        const targetValue = event.target.value
        setInputAnswers(
            inputAnswers.map((answers) => {
                var temp = Object.assign({}, answers)
                if (temp.questionKey === questionKey &&
                    parseInt(temp.answerKey) !== parseInt(targetValue)) {
                    temp.answerValue = ""
                }
                return temp
            })
        )
        if (timer) {
            clearTimeout(timer)
            setTimer(null)
        }
        const data = {
            key: questionKey,
            answer: {
                key: targetValue,
                value: onFindSurveyValue(questionKey, targetValue),
                value2: ""
            }
        }
        props.updateSurvey(data)
    }

    const onFindSurveyValue = (questionKey, optionKey) => {
        if (isNotNull(questionKey) &&
            isNotNull(optionKey) &&
            isNotNull(data) &&
            isNotNull(data.data) &&
            data.data.length > 0) {

            const findQuestion = _.find(data.data, q => q.key === questionKey)
            if (isNotNull(findQuestion) &&
                isNotNull(findQuestion.options) &&
                findQuestion.options.length > 0) {

                const findOption = _.find(findQuestion.options, q => q.key === parseInt(optionKey))
                if (isNotNull(findOption))
                    return findOption.value
            }
        }
        return ""
    }

    const onStepHandler = (step) => {
        if (isNotNull(step))
            props.updateStep(step)
    }

    const onInputChangeHandler = (event, selectedValue, questionKey) => {
        setInputAnswers(
            inputAnswers.map((answers) => {
                var temp = Object.assign({}, answers)
                if (temp.questionKey === questionKey) {
                    temp.answerValue = event.target.value
                    temp.answerKey = selectedValue
                }
                return temp
            })
        )

        if (timer) {
            clearTimeout(timer)
            setTimer(null)
        }
        setTimer(
            setTimeout(() => {
                const data = {
                    key: questionKey,
                    answerKey: selectedValue,
                    value2: event.target.value
                }
                props.updateAnswer(data)
            }, 800)
        )
    }

    const onSubmit = (surveyAnswers) => {
        if (isNotNull(surveyAnswers) && surveyAnswers.length > 0) {
            const requestData = {
                customerId,
                q1: "",
                q1Answer1: "",
                q1Answer2: "",
                q2: "",
                q2Answer1: "",
                q2Answer2: "",
                q3: "",
                q3Answer1: ""
            }

            surveyAnswers.forEach(survey => {
                if (isNotNull(survey) && isNotNull(survey.key)) {
                    if (survey.key === steps.Q1) {
                        requestData.q1 = getSurveyQuestion(steps.Q1)
                        requestData.q1Answer1 = survey.value
                        requestData.q1Answer2 = survey.value2
                    }

                    if (survey.key === steps.Q2) {
                        requestData.q2 = getSurveyQuestion(steps.Q2)
                        requestData.q2Answer1 = survey.value
                        requestData.q2Answer2 = survey.value2
                    }

                    if (survey.key === steps.Q3) {
                        requestData.q3 = "If we fixed this would you consider signing up again?"
                        requestData.q3Answer1 = survey.value
                    }
                }
            })

            //requestData.q3 = `You told us your main reason for leaving was (${isNotNull(requestData.q1Answer2) ? requestData.q1Answer2 : requestData.q1Answer1}) and that if you could change one thing about ProfitFarmers it would be (${isNotNull(requestData.q2Answer2) ? requestData.q2Answer2 : requestData.q2Answer1}) If we fixed this would you consider signing up again?`

            props.onSubmit(requestData)
        }
    }

    const renderSurveyQuestion = (survey) => {
        const onGetAnswers = (step, answers) => {
            if (isNotNull(step) && isNotNull(answers) && answers.length > 0) {
                const find = _.find(answers, q => q.key === step)
                if (isNotNull(find) && isNotNull(find.value)) {

                    const answer = (
                        find.key === steps.Q1 &&
                        (
                            parseInt(find.answerKey) === withInputFields.Q1.option1 ||
                            parseInt(find.answerKey) === withInputFields.Q1.option2
                        )
                    ) || (
                            find.key === steps.Q2 &&
                            parseInt(find.answerKey) === withInputFields.Q2.option1
                        )
                        ? parseInt(find.answerKey) === withInputFields.Q1.option1
                            ? `${find.value} which is ${find.value2}`
                            : find.value2
                        : find.value

                    return (
                        <>
                            <strong>({answer})</strong>&nbsp;
                        </>
                    )
                }
            }
        }

        const onSetQuestionHandler = (questionNumber, value, answers) => {
            let isQuestionDisplay = true

            if (isNotNull(answers)) {
                const find = _.find(answers, q => q.key === steps.Q2)
                if (isNotNull(find) && isNotNull(find.value) && isNotNull(find.answerKey) && find.answerKey === 1)
                    isQuestionDisplay = false
            }

            switch (value.key) {
                case steps.Q1:
                    return `${value.question}`
                case steps.Q2:
                    return `${value.question}`
                case steps.Q3:
                    return (
                        <>
                            You told us your main reason for leaving was {onGetAnswers(steps.Q1, answers)}
                            {isQuestionDisplay &&
                                <>and that if you could change one thing about ProfitFarmers it would be {onGetAnswers(steps.Q2, answers)}</>
                            }
                             <br /><br />If we fixed this would you consider signing up again?
                        </>
                    )
                default:
                    return null
            }
        }

        if (isNotNull(survey) &&
            isNotNull(survey.data) &&
            isNotNull(survey.step) &&
            isNotNull(survey.data) &&
            survey.data.length > 0) {

            return survey.data.map((value, key) => {
                if (value.key === survey.step) {
                    const selectedValue = isNotNull(value.answer) && isNotNull(value.answer.key) ? value.answer.key : null
                    return (
                        <SurveyQuestionWrapper key={key}>
                            <SurveyQuestion>{onSetQuestionHandler(`Q${key + 1}.`, value, survey.answers)}</SurveyQuestion>
                            <FormControl component="fieldset" className="option-wrapper">
                                <RadioGroup aria-label={value.key} name={value.key} value={selectedValue} onChange={(e) => onChangeHandler(e, value.key)}>
                                    {renderOptions(value.options, selectedValue, value.key)}
                                </RadioGroup>
                            </FormControl>
                            {value.key === steps.Q3 &&
                                <div className="billing">
                                    <p className="text-left">
                                        If you cancel your subscription today, your account will stay active until the end of the billing cycle on&nbsp;
                                        <b className="text-light">{isNotNull(nextBillingDate) ? nextBillingDate : ""}</b>, and your account will be locked.
                                    </p>
                                </div>
                            }
                        </SurveyQuestionWrapper>
                    )
                } else
                    return null
            })
        }
    }

    const renderOptions = (options, selectedValue, questionKey) => {

        const renderTextField = (currentKey, selectedValue, questionKey) => {
            if (isNotNull(currentKey) &&
                isNotNull(questionKey) &&
                isNotNull(selectedValue) &&
                parseInt(selectedValue) === currentKey) {
                selectedValue = parseInt(selectedValue)
                const find = _.find(inputAnswers, q => q.questionKey === questionKey)
                if (questionKey === steps.Q1 && selectedValue === withInputFields.Q1.option1 && isNotNull(find)) {
                    return (
                        <div className="survey-input-wrapper">
                            <label>Which product:</label>
                            <input type="text" value={find.answerValue} onChange={(e) => onInputChangeHandler(e, selectedValue, questionKey)} />
                        </div>
                    )
                }


                if (((selectedValue === withInputFields.Q1.option2 && questionKey === steps.Q1) ||
                    (selectedValue === withInputFields.Q2.option1 && questionKey === steps.Q2)) && isNotNull(find)) {
                    return (
                        <div className="survey-input-wrapper">
                            <textarea type="text" rows={3} value={find.answerValue} onChange={(e) => onInputChangeHandler(e, selectedValue, questionKey)} />
                        </div>
                    )
                }

            }
        }

        if (isNotNull(options) && options.length > 0) {
            return options.map((option, key) => {
                const selectedOption = isNotNull(selectedValue) && parseInt(selectedValue) === option.key ? true : false
                return (
                    <div key={key}>
                        <FormControlLabel
                            checked={selectedOption}
                            value={option.key}
                            control={<Radio />}
                            label={option.value}
                        />
                        {renderTextField(option.key, selectedValue, questionKey)}
                    </div>
                )
            })
        } else
            return null
    }

    const renderButtonActions = (survey) => {
        const onDisableHandler = (step, answers) => {
            if (isNotNull(step) && isNotNull(answers)) {
                const find = _.find(answers, q => q.key === step)
                if (isNotNull(find) && isNotNull(find.value)) {
                    find.answerKey = parseInt(find.answerKey)
                    if (isNull(find.value2) && (
                        (find.key === steps.Q1 && (find.answerKey === withInputFields.Q1.option1 || find.answerKey === withInputFields.Q1.option2)) ||
                        (find.key === steps.Q2 && find.answerKey === withInputFields.Q2.option1))
                    )
                        return true
                    return false
                }
            }
            return true
        }

        if (isNotNull(survey) && isNotNull(survey.step)) {
            const renderButton = (step) => {
                switch (step) {
                    case steps.Q1:
                        return (
                            <Button
                                onClick={() => onStepHandler(steps.Q2)}
                                disabled={onDisableHandler(step, survey.answers)}>
                                Next
                            </Button>
                        )
                    case steps.Q2:
                        return (
                            <SurveyButtonWrapper>
                                <Button onClick={() => onStepHandler(steps.Q1)}>Prev</Button>
                                <ButtonContainer>
                                    <Button className="btn-submit" onClick={() => onStepHandler(steps.Q3)} disabled={onDisableHandler(step, survey.answers)}>Proceed to cancellation</Button>
                                    <SafeAnchor onClick={() => props.onHide()}>Never mind, I don't want to cancel</SafeAnchor>
                                </ButtonContainer>
                            </SurveyButtonWrapper>
                        )
                    case steps.Q3:
                        return (
                            <SurveyButtonWrapper className="justify-content-center">
                                <ButtonContainer>
                                    {isCancel === false
                                        ? <Button className="btn-submit" disabled={onDisableHandler(step, survey.answers)} onClick={() => onSubmit(survey.answers)}>Finish cancellation</Button>
                                        : <Button disabled><i className="fas fa-spinner fa-pulse"></i> Canceling..</Button>
                                    }
                                    <SafeAnchor onClick={() => props.onHide()} disabled={isCancel}>Never mind, I don't want to cancel</SafeAnchor>
                                </ButtonContainer>
                            </SurveyButtonWrapper>
                        )
                    default:
                        break
                }
            }

            return (
                <Modal.Footer as={SurveyFooter}>
                    {renderButton(survey.step)}
                </Modal.Footer>
            )
        } else
            return null
    }

    return (
        <SurveyWrapper >
            <Modal.Header>
                {isNotNull(title) && <h3 className="title">{title}</h3>}
                {isNotNull(hasClose) && hasClose &&
                    <CloseButton onClick={() => props.onHide()} className="custom-close">
                        <FontAwesomeIcon icon={faTimes} className="icon" />
                    </CloseButton>
                }
            </Modal.Header>
            <Modal.Body as={SurveyBody}>
                {renderSurveyQuestion(data)}
            </Modal.Body>
            {renderButtonActions(data)}
        </SurveyWrapper>
    )
}