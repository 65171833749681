import React, { useEffect, useState }  from 'react'
import ReactPlayer from 'react-player/lazy'
import { Carousel } from 'react-bootstrap'
import moment from 'moment'
import { mediaDeviceSizes } from '../../../constants/mediaQueries'
import { getSize, isNull, isNotNull } from '../../../utils'
import { IntroSectionStyle } from './styles'
import { CheckoutButton } from '../../../styles'
import { DynamicCheckout } from '../..'

export const IntroSection = (props) => {
    let [device, setDevice] = useState(null)
    
    let [remainingDays, setRemainingDays] = useState('')
    let [remainingHours, setRemainingHours] = useState('')
    let [remainingMinutes, setRemainingMinutes] = useState('')
    let [remainingSeconds, setRemainingSeconds] = useState('')

    let [isEarlyBirdOffer, setIsEarlyBirdOffer] = useState(false)
    let [isFinalYearlyOffer, setIsFinalYearlyOffer] = useState(false)

    useEffect(() => {
        setDevice(getSize(window.innerWidth))
    }, [])

    let parsedDate = Date.parse(props.validUntil)
    let expiry = new Date(parsedDate)
        expiry = moment(expiry).format('MM/DD/yyyy h:mm:ss')

    const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

    let today = new Date(),
        dd = String(today.getDate()).padStart(2, "0"),
        mm = String(today.getMonth() + 1).padStart(2, "0"),
        yyyy = today.getFullYear(),
        nextYear = yyyy + 1,
        dayMonth = moment(expiry).format('MM/DD/')

    today = mm + "/" + dd + "/" + yyyy
    if (today > expiry) {
        expiry = dayMonth + nextYear
    }

    const countDown = new Date(expiry).getTime(),
        x = setInterval(function() {    
            const now = new Date().getTime(),
                distance = countDown - now

            setRemainingDays(Math.floor(distance / (day)))
            setRemainingHours(Math.floor((distance % (day)) / (hour)))
            setRemainingMinutes(Math.floor((distance % (hour)) / (minute)))
            setRemainingSeconds(Math.floor((distance % (minute)) / second))

            if (isNotNull(remainingDays)) {
                if (parseInt(remainingDays) >= 14) {
                    if (!isEarlyBirdOffer)
                        setIsEarlyBirdOffer(true)

                    if (isFinalYearlyOffer)
                        setIsFinalYearlyOffer(false)
                } else {                        
                    if (isEarlyBirdOffer)
                        setIsEarlyBirdOffer(false)
                    
                    if (!isFinalYearlyOffer)  
                        setIsFinalYearlyOffer(true)
                }
            }
            
            if (distance < 0) {
                clearInterval(x)
            }
            //seconds
        }, 1000)

    const onScrollTo = (e) => {
        e.preventDefault() 
        document.getElementById('nextSection').scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
        })
    }

    return (
        <IntroSectionStyle 
            className={`${props.page === 'upgrade' && isNull(props.withinTrial) ? 'up-w-trial': ''} ${props.page === 'upgrade' && isNotNull(props.withinTrial) && props.withinTrial ? 'up-win-trial': ''}`}>
            <div className='container'>

                { isNull(props.withinTrial) && 
                    <>
                        <img src='/images/brand/pf-logo.png' className='pf-logo'/>

                        <div className='tml-row'>
                            <div className='tml-column'>
                                <img src='/images/trial-offer/tp-5-stars.svg' alt='5 stars'/>
                                <br />
                                <span className='tml-text'>"the Holy Grail of crypto"</span>
                            </div>
                            <div className='tml-column'>
                                <img src='/images/trial-offer/tp-5-stars.svg' alt='5 stars'/>
                                <br />
                                <span className='tml-text'>"Took a 10% gain on my first trade!"</span>
                            </div>
                            <div className='tml-column'>
                                <img src='/images/trial-offer/tp-5-stars.svg' alt='5 stars'/>
                                <br />
                                <span className='tml-text'>"crypto tool that works"</span>
                            </div>
                        </div>
                    </>
                }

                <div className={`content ${isNotNull(props.withinTrial) && props.withinTrial ? 'long-txt' : '' }`}>
                    { isNull(props.withinTrial) && 
                        <>
                            { props.page === 'upgrade' 
                                ? <h1>GET 21 DAYS OF PRO ACCESS FOR ONLY <span className='crashed-out-price'>$399</span> <span className='price'>$21</span></h1>
                                : <h1>WAIT! GET 21 DAYS OF PRO ACCESS FOR ONLY <span className='crashed-out-price'>$399</span> <span className='price'>$21</span></h1> 
                            }

                            <p>For only $1 per day, unleash your inner trading beast by unlocking all signals, targets and trading automations. PLUS instant access to <strong>‘The PRO Trader 5x Blueprint’</strong>. Check the PRO demo tour below where Carlo makes $1650 in 15 minutes of trading time. Now it’s your turn.</p>
                        </>
                    }
                    
                    { isNotNull(props.withinTrial) && props.withinTrial && isEarlyBirdOffer && 
                        <>
                            <h1>SIGN UP NOW AND <span className='price'>SAVE 65%</span> ON PROFITFARMERS 
                                <br />PRO'S INSANE TRADING POWER
                                <br />PLUS GET EXCLUSIVE BONUS ITEMS</h1>
                            <p>Are you ready to <strong>5x</strong> your account? Get 365 days of access to our elite signals, copy-trading, auto-trading, market scanners and market analysis. <span className='grn-txt'>Only $4 per day</span>, this deal will <strong>NEVER</strong> happen again.</p>
                        </>
                    }

                    { isNotNull(props.withinTrial) && props.withinTrial && isFinalYearlyOffer && 
                        <>
                            <h1>SIGN UP NOW AND <span className='price'>SAVE 60%</span> ON PROFITFARMERS 
                                <br />PRO'S INSANE TRADING POWER
                                <br />PLUS GET EXCLUSIVE BONUS ITEMS</h1>
                            <p>Are you ready to <strong>5x</strong> your account? Get 365 days of access to our elite signals, copy-trading, auto-trading, market scanners and market analysis. <span className='grn-txt'>Only $5 per day</span>, this deal will <strong>NEVER</strong> happen again.</p>
                        </>
                    }
                </div>

                <div className='video-wrapper'>
                    { isNull(props.withinTrial) &&                     
                        <ReactPlayer 
                            url={'https://vimeo.com/756677485/6c9d0a0c10'}
                            controls={true} 
                            width='100%' 
                            volume={0} />
                    }

                    { isNotNull(props.withinTrial) && props.withinTrial && 
                        <ReactPlayer 
                            url={'https://vimeo.com/762159729/a720d1ccfb'}
                            controls={true} 
                            width='100%' 
                            volume={0} />
                    }
                </div>

                { isNull(props.withinTrial) && 
                    <div className='checkout-btn'>
                        <DynamicCheckout 
                            text="Try PRO for 21 days - Only $21"
                            position="centered" 
                            userInfo={props.userInfo} 
                            offer={props.subscriptionPlan} />
                    </div>
                }

                { isNotNull(props.withinTrial) && props.withinTrial && 
                    <div className='checkout-btn'>
                        <CheckoutButton 
                            position="centered"
                            type="button" 
                            onClick={(e) => onScrollTo(e)}>
                            <span>Learn More</span>
                        </CheckoutButton>
                    </div>
                }

                <div className='trust-pilot-rating'>
                    <span className='score'>4.6 Trustscore on</span>
                    <span className='tp-logo'>
                        <img src='/images/offers/trustpilot-icon.svg' className='icon-logo' alt='ProfitFarmers - TrustPilot Icon Logo' />
                        <img src='/images/offers/trustpilot-text-light.svg' className='text-logo' alt="ProfitFarmers - TrustPilot Text Logo" />
                        <img src='/images/offers/trustpilot-rating-stars.png' className='rating-stars' alt='ProfitFarmers - TrustPilot rating score' />
                    </span>
                </div>

                <div className='featured-logo-container'>
                    <span>As Featured On</span>
                    <div className='featured-logos'>
                        { (device === mediaDeviceSizes.xs) &&
                            <Carousel controls={false}>
                                <Carousel.Item interval={1500}>
                                    <div className='logo-carousel'>
                                        <img src='/images/trial-offer/ambcrypto-logo.svg' alt='TrustPilot Score' />
                                        <img src='/images/trial-offer/cryptonewsflash-logo.svg' alt='TrustPilot Score' />
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item interval={1500}>
                                    <div className='logo-carousel'>
                                        <img src='/images/trial-offer/ambcrypto-logo.svg' alt='TrustPilot Score' />
                                        <img src='/images/trial-offer/ambcrypto-logo.svg' alt='TrustPilot Score' />
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item interval={1500}>
                                    <div className='logo-carousel'>
                                        <img src='/images/trial-offer/ambcrypto-logo.svg' alt='TrustPilot Score' />
                                        <img src='/images/trial-offer/ambcrypto-logo.svg' alt='TrustPilot Score' />
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item interval={1500}>
                                    <img src='/images/trial-offer/ambcrypto-logo.svg' alt='TrustPilot Score' />
                                </Carousel.Item>
                            </Carousel>
                        }

                        { !(device === mediaDeviceSizes.xs) &&
                            <><div className='logo-col'>
                                <img src='/images/trial-offer/ambcrypto-logo.svg' alt='TrustPilot Score' />
                            </div>
                            <div className='logo-col'>
                                <img src='/images/trial-offer/cryptonewsflash-logo.svg' alt='TrustPilot Score' />
                            </div>
                            <div className='logo-col'>
                                <img src='/images/trial-offer/btcmanager-logo.svg' alt='TrustPilot Score' />
                            </div>
                            <div className='logo-col'>
                                <img src='/images/trial-offer/crypto-insider-logo.svg' alt='TrustPilot Score' />
                            </div>
                            <div className='logo-col'>
                                <img src='/images/trial-offer/btc-echo-logo.svg' alt='TrustPilot Score' />
                            </div>
                            <div className='logo-col'>
                                <img src='/images/trial-offer/coingape-logo.svg' alt='TrustPilot Score' />
                            </div>
                            <div className='logo-col'>
                                <img src='/images/trial-offer/kointimes-logo.png' alt='TrustPilot Score' />
                            </div></>
                        }    
                    </div>
                </div>
            </div>
        </IntroSectionStyle>
    )
}