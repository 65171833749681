import { checkListActionTypes } from '../CONSTANTS'
import { checkListServices } from '../../services'

function getCheckLists() {
    return dispatch => {
        dispatch(request())

        checkListServices.getCheckLists().then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })

        function request() { return { type: checkListActionTypes.CHECKLIST_REQUEST } }
        function success(checklist) { return { type: checkListActionTypes.CHECKLIST_SUCCESS, checklist } }
        function failure(error) { return { type: checkListActionTypes.CHECKLIST_FAILURE, error } }
    }
}

function saveChecklist(id, data) {
    return dispatch => {
        dispatch(request())
        
        checkListServices.saveChecklist(id, data).then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: checkListActionTypes.CHECKLIST_SAVE_REQUEST } }
    function success(checkItem) { return { type: checkListActionTypes.CHECKLIST_SAVE_SUCCESS, checkItem } }
    function failure(checklist_error) { return { type: checkListActionTypes.CHECKLIST_SAVE_FAILURE, checklist_error } }
}

function setCheckListItems(progressChecklist, progress) {
    return dispatch => {
        dispatch({
            type: checkListActionTypes.CHECKLIST_SET,
            progressChecklist,
            progress,
        })
    }
}

function messageUpdate(isShowMessage) {
    return dispatch => {
        dispatch({
            type: checkListActionTypes.CHECKLIST_MESSAGE,
            isShowMessage
        })
    }
}

function stepsCompleted(stepsCompleted) {
    return dispatch => {
        dispatch({
            type: checkListActionTypes.CHECKLIST_STEPS,
            stepsCompleted
        })
    }
}

function clear() {
    return dispatch => {
        dispatch({ type: checkListActionTypes.CHECKLIST_CLEAR })
    }
}

export const checkListActions = {
    getCheckLists,
    saveChecklist,
    setCheckListItems,
    messageUpdate,
    stepsCompleted,
    clear
}