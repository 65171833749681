import glamorous from 'glamorous'
import { mediaQueries } from '../../constants/mediaQueries'

export const ModalContent = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '25px 15px',

    '& .title': {
        fontSize: '20px',
        textAlign: 'center',
        margin: '15px auto'
    },

    '& .sub-title': {
        color: '#9da7b3',
        marginBottom: '25px',
        fontSize: '16px !important',
        textAlign: 'center',
        lineHeight: '1.7'
    },

    '& .iframe-wrapper': {
        display: 'block',
        margin: '0 auto 30px',
        borderRadius: '6px',
        outline: '2px solid #29323c',
        backgroundColor: '#080b0e',
        boxShadow: '0 0 20px 12px rgba(0,0,0,0.3)',

        '& iframe, & img': {
            display: 'block',
            width: 'auto',
            minHeight: 'auto',
            backgroundColor: '#080b0e',
            width: '100% ',
            height: 'auto !important'
        }
    },

    '& .upgrade-btn': {
        fontSize: '18px',
        fontWeight: 600,
        letterSpacing: '1px',
        transition: 'all 0.5s ease 0s',
        width: 'auto',
        height: '50px',
        padding: '6px 25px',
        borderRadius: '25px',
        color: '#fff',
        backgroundColor: '#1ea164',
        border: '2px solid #23d381',
        cursor: 'pointer',
        lineHeight: '1.8',

        '&:hover': {
            transform: 'scale(1.05)',
            backfaceVisibility: 'hidden',
            overflow: 'auto'
        }
    },

    [mediaQueries.mediumDevices]: {
        '& .iframe-wrapper': {
            '& iframe, & img': {
                width: '320px'
            }
        }
    },

    [mediaQueries.largeDevices]: {
        '& .title': {
            fontSize: '25px'
        },

        '& .sub-title': {
            fontSize: '17px !important'
        },

        '& .iframe-wrapper': {
            '& iframe, & img': {
                width: '394px'
            }
        }
    }
})