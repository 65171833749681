import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
// import '../../assets/scss/onboarding.scss'
import { addPageViewEvent, seo } from '../../utils'
import { OnboardingLayout } from '../../styles'
import { Onboarding } from '../../components/GetStarted'

export const SetupAccountPage = (props) => {
    useEffect(() => {
        seo({
            title: "Setup Account",
            metaDescription: ""
        })
        addPageViewEvent()

        document.head.classList.add('onboarding')
        document.body.classList.add('onboarding')
    }, [])

    return (
        <OnboardingLayout>
            <Container>
                <div className='content-wrapper'>
                    <Onboarding {...props} />
                </div>
            </Container>
        </OnboardingLayout>
    )
}