import React from 'react'
import { connect } from 'react-redux'
import jQuery from 'jquery'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import _ from 'lodash'
import { checkListActions, signalActions, tradingActions, walletActions, userActions } from '../../../../redux/actions'
import { walletActionTypes } from '../../../../redux/CONSTANTS'
import { AutoTradeView } from './AutoTradeView'
import { Dimension, isNotNull, onSetNewState, toastCustomError, toastSuccess, toastWarning, addPageViewEvent, seo, toastError } from '../../../../utils'

class AutoTradeContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            autoTradingModal: false,
            has_get_keys: false,
            userASid: null,
            exchangeValueSelected: 'binance',
            exchangeKeySelected: null,
            exchangeDisplay: null,
            warning: false,
            updateTrading: false,
            hasGetSettings: false,
            removeKeys: false,
            errorBox: false,
            passwordType: 'password',
            eyeIcon: faEye,
            removeExistingTralingError: false,
            saveKeys: false,
            disableTrading: false,
            updateDeleteKeys: false,
            exchangeOptionLock: false,
            changeExchangeLoader: true,
            isAutoTradingAllowed: false,
            isAllowedAutoTradingLock: false,
            isAutoCloseEnabled: false,
            isUpdatingAutoCloseSettings: false
        }

        this.baseState = this.state
        this.formInput = null
        this.getMeDelay = null
        this.getMeDelay_1 = null
        this.mounted = false

        this.apiKeyInput = null
    }

    componentDidMount() {
        this.mounted = true

        seo({
            title: "Auto-trade Settings",
            metaDescription: ""
        })
        addPageViewEvent()        

        const user = this.props.userInfo
        const { dispatch } = this.props
        dispatch(signalActions.clear())
        // trading exchange options
        dispatch(tradingActions.exchangeOptions())

        // enable button delete if user has exchange keys, disable if none
        if (!user.isAutoTradingAllowed) {
            jQuery('.btn-delete').attr('disabled', 'true')
            jQuery('#enable-show').addClass('enable-content-display')
            jQuery('.divider').css({ display: 'none' })
            jQuery('.note-wrapper').css({ display: 'none' })
            onSetNewState(this, { allowTradeValue: false })
        } else {
            jQuery('.btn-delete').attr('disabled', 'false')
            jQuery('.btn-delete').removeAttr('disabled', 'false')
            jQuery('#enable-show').removeClass('enable-content-display')
            jQuery('.note-wrapper').css({ display: 'flex' })
            jQuery('#enable-show').removeClass('enable-content-display')
            onSetNewState(this, { allowTradeValue: true })
        }

        const { settings } = this.props.tradings

        if (isNotNull(settings))
            dispatch(tradingActions.getSettings())

        jQuery("input[type='password']").keypress(function (e) {
            if (e.keyCode === 32)
                return false
        })
        
        window.addEventListener('resize', () => Dimension.updateDimensions(this))
        jQuery('#nav-link-settings').addClass('active')
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { user } = this.props.authentication

        const { failure,
            response,
            existingTrailUser,
            existingError,
            keys,
            isSaving,
            rem_keys,
            disable,
            loading,
            isKeyLoading,
            remove,
            exchange,
            settings,
            keys_error,
            isDeleting } = this.props.tradings

        const { updateTrading,
            hasGetSettings,
            exchangeValueSelected,
            saveKeys,
            disableTrading,
            updateDeleteKeys,
            removeKeys,
            exchangeOptionLock,
            exchangeKeySelected,
            isAllowedAutoTradingLock } = this.state

        const { isAutoTradingAllowed, isAutoCloseEnabled } = this.props.userInfo
        const { dispatch } = this.props

        if (isNotNull(user)) {
            const { isAutoTradingAllowed } = user
            if (isAutoTradingAllowed) {
                jQuery('.btn-delete').attr('disabled', 'false')
                jQuery('.btn-delete').removeAttr('disabled')

                if (!updateTrading) {
                    jQuery('.eks').addClass('eks-display')
                    jQuery('.divider').css({ display: 'block' })
                    jQuery('.note-wrapper').css({ display: 'flex' })
                    onSetNewState(this, { updateTrading: true })
                }
            } else {
                jQuery('.btn-delete').attr('disabled', 'true')

                if (updateTrading) {
                    jQuery('.eks').removeClass('eks-display')
                    jQuery('.divider').css({ display: 'none' })
                    jQuery('.note-wrapper').css({ display: 'none' })

                    onSetNewState(this, { updateTrading: false })
                }
            }
        }

        if (isNotNull(isAutoTradingAllowed) && isAutoTradingAllowed && !isAllowedAutoTradingLock)
            onSetNewState(this, { isAutoTradingAllowed: true, isAllowedAutoTradingLock: true })

        if (isNotNull(isAutoCloseEnabled) && isAutoCloseEnabled !== prevState.isAutoCloseEnabled)
            onSetNewState(this, { isAutoCloseEnabled, isUpdatingAutoCloseSettings: false })

        if (isNotNull(user) && isNotNull(user.tradingApiKey)) {
            jQuery('.enable-content').html('To enable autotrading, please select YES')

            if (isNotNull(settings) && !hasGetSettings)
                onSetNewState(this, { hasGetSettings: true })
        }
        else
            jQuery('.enable-content').html('To enable autotrading, please select YES and then enter your ProfitFarmers account password to confirm')

        if (isNotNull(exchange) && !exchangeOptionLock) {
            onSetNewState(this, {
                exchangeDisplay: exchange.map((item, key) => (<option value={item.value.toLowerCase()} key={key}>{item.displayName}</option>)),
                exchangeOptionLock: true
            })
        }

        // load exchange api keys for binance
        // change if there are lots of exchange options

        const defaultExchangeValue = jQuery('#exchange').val()
        if (isNotNull(defaultExchangeValue))
            this.onUpdateCurrentExchange(exchangeValueSelected)
        else
            this.onUpdateCurrentExchange(defaultExchangeValue)

        // If password is correct
        if (isNotNull(response)) {
            const { success } = response
            if (isNotNull(success) && success) {
                let { autoTradingModal } = this.state
                if (autoTradingModal) {
                    autoTradingModal = false

                    onSetNewState(this, { autoTradingModal })
                    jQuery('.eks').addClass('eks-display')
                    dispatch(tradingActions.exchangeOptions())
                    dispatch(tradingActions.getSettings())

                    jQuery('.divider').css({ display: 'block' })
                    jQuery('.note-wrapper').css({ display: 'flex' })
                }
            }
        }

        // if password is incorrect
        if (isNotNull(failure)) {
            let { errorBox } = this.state
            if (isNotNull(failure.success)) {
                if (!failure.success) {
                    if (isNotNull(failure.msg)) {
                        switch (failure.msg) {
                            case "Invalid Password": {
                                if (!errorBox) {
                                    let error = `<p class="error-title">Error</p> ${failure.msg}`
                                    toastCustomError(error)
                                    onSetNewState(this, { errorBox: true })
                                } else
                                    this.onDismissErrorHandler()
                                break
                            }
                            case "Email already Exist": {
                                if (!errorBox) {
                                    let error = `<p class="error-title">Error</p> ${failure.msg}`

                                    toastCustomError(error)
                                    onSetNewState(this, { errorBox: true })

                                    jQuery('.enable-form').addClass('d-none')
                                    jQuery('.trailing-form').removeClass('d-none')
                                }

                                jQuery('#modal-pass-confirm').html('Oops, it looks like you are trying to connect an existing TrailingCrypto subscription into your ProfitFarmers account.<br /><br /> Unfortunately, this is not possible.')
                                jQuery('#modal-pass-confirm').css({
                                    marginTop: '10px',
                                    padding: '0 10px'
                                })
                                jQuery('.removedIfExisting').addClass('d-none')

                                break
                            }
                            default:
                                break
                        }
                    }
                }
            }
        }

        //if password TrailingCrypto is correct
        if (isNotNull(existingTrailUser) && isNotNull(existingTrailUser.success) && existingTrailUser.success) {
            let { autoTradingModal } = this.state
            if (autoTradingModal) {
                autoTradingModal = false
                onSetNewState(this, { autoTradingModal })

                jQuery('.eks').addClass('eks-display')
                jQuery('.divider').css({ display: 'block' })
                jQuery('.note-wrapper').css({ display: 'flex' })
                dispatch(tradingActions.exchangeOptions())
                dispatch(tradingActions.getSettings())

                this.onDismissErrorHandler()
            }
        }

        // if password TrailingCrypto is incorrect
        if (isNotNull(existingError)) {
            const { removeExistingTralingError } = this.state
            if (isNotNull(existingError.success) && !existingError.success) {
                if (removeExistingTralingError)
                    this.onRemoveTrailingExistingError()
                else {
                    const error = `<p class="error-title">Error</p> ${existingError.msg}`
                    toastCustomError(error)
                    onSetNewState(this, { removeExistingTralingError: true })
                }
            }
        }

        // if save keys is success
        if (saveKeys && isNotNull(keys)) {
            const { success } = keys
            if (!isSaving) {
                if (success) {                    
                    toastSuccess("Exchange saved successfully")

                    if (!this.state.isAutoTradingAllowed && 
                        isNotNull(keys.exchangeApiKey)) {
                        toastSuccess('Auto-trading enabled')
                        // disable auto trade
                        // user.isAutoTradingAllowed = false
                    }
                } else {
                    toastError(keys.msg)
                }
                
                onSetNewState(this, { saveKeys: false })
            }
        }

        // if user will disable trading
        if (disableTrading && isNotNull(disable)) {
            const { success } = disable
            if (!loading && success) {
                toastSuccess('Auto-trading disabled.')
                onSetNewState(this, { disableTrading: false })
                // dispatch(tradingActions.disableClear())

                if (isNotNull(user.isAutoTradingAllowed))
                    user.isAutoTradingAllowed = false

                this.onUpdateWalletsHandler()
            }
        }

        if (prevProps.tradings.isKeyLoading === true && isKeyLoading === false) {
            onSetNewState(this, { changeExchangeLoader: false })
        }

        if (prevProps.tradings.loading === true && loading === false) {
            // onSetNewState(this,{ changeExchangeLoader: false })
            if (isNotNull(user)) {
                const { isAutoTradingAllowed } = user

                if (isAutoTradingAllowed && prevProps.tradings.isDeleting !== isDeleting) {
                    toastSuccess("Exchange successfully deleted")
                }

                if (isAutoTradingAllowed && prevProps.tradings.isSaving !== isSaving) {
                    this.props.tradings.keys_error = null

                    // set hasFuturekey to true
                    if (exchangeValueSelected === 'binancefutures' && isNotNull(settings) && isNotNull(settings.data))
                        settings.data.hasFuturesKey = true

                    // if (isNotNull(this.props.checkList)) {
                    //     const { item, isLoading } = this.props.checkList
                    //     if (!isLoading &&
                    //         isNotNull(item) &&
                    //         isNotNull(item.data) &&
                    //         isNotNull(item.data.isStepsCompleted) &&
                    //         !item.data.isStepsCompleted)
                    //         dispatch(checkListActions.getCheckLists())
                    // }

                    this.onUpdateWalletsHandler()
                } else {
                    if (isNotNull(keys_error) && isNotNull(keys)) {
                        keys.exchangeApiKey = null
                        keys.exchangeApiSecret = null
                    }
                }
            }
        }

        // if user delete exchange api key and secret keys
        if (updateDeleteKeys && isNotNull(remove)) {
            const { success, msg } = remove
            if (success && isNotNull(msg)) {
                onSetNewState(this, { updateDeleteKeys: false })
                if (isNotNull(keys)) {
                    const { exchangeApiKey } = keys                    
                    // clear key
                    keys.exchangeApiKey = null
                    keys.exchangeApiSecret = null

                    if (isNotNull(exchangeApiKey) && isNotNull(user) && user.isAutoTradingAllowed) {
                        jQuery('.btn-delete').attr('disabled', 'false')
                        jQuery('.btn-delete').removeAttr('disabled', 'false')

                        if (isNotNull(settings) && isNotNull(settings.data) && msg.includes("binancefutures"))
                            settings.data.hasFuturesKey = false
                    }
                    else
                        jQuery('.btn-delete').attr('disabled', 'true')
                }
                else
                    jQuery('.btn-delete').attr('disabled', 'true')
            }
        }

        // for exchange value exchangeKeySelected
        if (isNotNull(exchangeValueSelected) && isNotNull(exchange)) {
            const findExchange = _.find(exchange, e => e.value === exchangeValueSelected.toUpperCase())
            // console.log("EXCHANGE FOUND : ", findExchange)
            if (isNotNull(findExchange)) {
                const { id } = findExchange
                if (id !== exchangeKeySelected)
                    onSetNewState(this, { exchangeKeySelected: id })
            }
        }

        // if get keys failed
        if (!removeKeys && isNotNull(rem_keys)) {
            toastWarning('Please configure exchange API KEY')
            onSetNewState(this, { removeKeys: true })
        }

        // checking if the keys has values, if none disable delete button else enable
        if (isNotNull(keys)) {
            // only clear exchange key if it has api key
            const { exchangeApiKey } = keys
            if (isNotNull(exchangeApiKey) && isNotNull(user) && user.isAutoTradingAllowed) {
                jQuery('.btn-delete').attr('disabled', 'false')
                jQuery('.btn-delete').removeAttr('disabled', 'false')
            } else
                jQuery('.btn-delete').attr('disabled', 'true')
        } else {
            jQuery('.btn-delete').attr('disabled', 'true')
        }

        if (prevProps.saveAutoCloseTradeSettings !== this.props.saveAutoCloseTradeSettings) {
            const { loading, save_auto_close_trade_settings } = this.props.saveAutoCloseTradeSettings
            if (!loading && isNotNull(save_auto_close_trade_settings)) {
                const { success } = save_auto_close_trade_settings
                if (isNotNull(success) && success) {
                    dispatch(userActions.getMe())
                }
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
        window.removeEventListener('resize', () => Dimension.updateDimensions(this))

        clearTimeout(this.getMeDelay)
        clearTimeout(this.getMeDelay_1)

        this.setState(this.baseState)
    }

    onUpdateWalletsHandler = () => {
        const { dispatch } = this.props
        dispatch({ type: walletActionTypes.GET_ALL_SUCCESS })
        dispatch({ type: walletActionTypes.GET_ALL_FUTURE_SUCCESS })
        dispatch(userActions.getMe())
        dispatch(walletActions.getWallets())
        dispatch(walletActions.getFutureWallets())
        dispatch(walletActions.getBinance())
    }

    onRemoveTrailingExistingError = () => {
        let { removeExistingTralingError } = this.state
        if (removeExistingTralingError) {
            removeExistingTralingError = false
            onSetNewState(this, { removeExistingTralingError })

            const { dispatch } = this.props
            dispatch(tradingActions.clearExistingTrailingError())
        }
    }

    // remove alert on auto trading modal pop up
    onDismissErrorHandler() {
        let { errorBox } = this.state

        if (errorBox) {
            errorBox = false
            onSetNewState(this, {
                errorBox,
                errorMessage: ''
            })

            const { dispatch } = this.props
            dispatch(tradingActions.clear())
        }
    }

    onUpdateCurrentExchange = (exchange) => {
        const { settings } = this.props.tradings
        const { has_get_keys, exchangeValueSelected } = this.state
        // const { dispatch } = this.props

        // reset key when changing
        // dispatch({ type: tradingActionTypes.TRADING_GET_KEYS_SUCCESS, })

        if (exchange !== exchangeValueSelected && has_get_keys)
            onSetNewState(this, { has_get_keys: false, changeExchangeLoader: true })

        if (isNotNull(settings) && isNotNull(exchange)) {
            const { data } = settings
            if (isNotNull(data) && !has_get_keys) {
                onSetNewState(this, {
                    has_get_keys: true,
                    userAsid: data.id
                })

                const { dispatch } = this.props
                dispatch(tradingActions.getKeys(data.id, exchange))
            }
        }
    }

    onDisableTradingHandler = () => {
        const { dispatch } = this.props
        dispatch(tradingActions.disableTrading())
        onSetNewState(this, { disableTrading: true })
    }

    handleAutoCloseTradeSettingsChanges = (settings) => {
        onSetNewState(this, {
            isUpdatingAutoCloseSettings: true
        }, () => {
            const { dispatch } = this.props
            dispatch(tradingActions.saveAutoCloseTradeSettings(settings))
        })
    }

    onSaveExchangeKeysHandler = (tradingKey) => {
        const { dispatch } = this.props
        const { user } = this.props.authentication

        dispatch(tradingActions.saveKeys(tradingKey))

        // set autotrading to true
        user.isAutoTradingAllowed = true
        onSetNewState(this, { saveKeys: true })
    }

    onSetApiKeyInputHandler = input => {
        this.apiKeyInput = input
    }

    onDeleteExchangeKeys = () => {
        const { dispatch } = this.props
        const { settings, exchange } = this.props.tradings
        let asid = null

        if (isNotNull(exchange) && isNotNull(settings)) {
            const { data } = settings
            if (isNotNull(data)) {
                asid = data.id
                const { exchangeValueSelected } = this.state

                dispatch(tradingActions.deleteKeys(asid, exchangeValueSelected.toLowerCase()))
                this.setState({ updateDeleteKeys: true, warning: false })
            }
        }
    }

    onVisibilitySetState = () => {
        const oldState = this.state.passwordType
        const newState = oldState === "password" ? "text" : "password"
        const newWord = oldState === "password" ? faEyeSlash : faEye
        onSetNewState(this, { passwordType: newState, eyeIcon: newWord })
    }

    render() {
        return (
            <AutoTradeView
                state={this.state}
                context={this}
                tradings={this.props.tradings}
                onChangeAutoCloseTradeSettings={this.handleAutoCloseTradeSettingsChanges}
                onSetApiKeyInputHandler={this.onSetApiKeyInputHandler}
                onSaveExchangeKeysHandler={this.onSaveExchangeKeysHandler}
                onDisableTradingHandler={this.onDisableTradingHandler}
                onDeleteExchangeKeys={this.onDeleteExchangeKeys}
                {...this.props}
            />
        )
    }
}

function mapStateToProps(state) {
    const { tradings, saveAutoCloseTradeSettings, authentication, checkList } = state
    return {
        tradings, 
        saveAutoCloseTradeSettings,
        authentication,
        checkList
    }
}
const connectedAutoTradeContainer = connect(mapStateToProps)(AutoTradeContainer)
export { connectedAutoTradeContainer as AutoTradeContainer }