import { planActionTypes } from '../CONSTANTS'
import { planServices } from '../../services'
import { isNotNull } from '../../utils'

function get() {
    return dispatch => {
        dispatch(request())

        planServices.get().then(response => {
            if (isNotNull(response))
                dispatch(success(response))
        }).catch(error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: planActionTypes.GET_PLAN_REQUEST } }
    function success(plans) { return { type: planActionTypes.GET_PLAN_SUCCESS, plans } }
    function failure(error) { return { type: planActionTypes.GET_PLAN_FAILURE, error } }
}

function getTrialOffer() {
    return dispatch => {
        dispatch(request())
        
        planServices.getTrialOffer().then(response => {
            if (isNotNull(response))
                dispatch(success(response))
        }).catch(error => {
            dispatch(failed(error))
        })
    }

    function request() { return { type: planActionTypes.GET_TRIAL_PLAN_REQUEST } }
    function success(trialPlan) { return { type: planActionTypes.GET_TRIAL_PLAN_SUCCESS, trialPlan } }
    function failed(error) { return { type: planActionTypes.GET_TRIAL_PLAN_FAILED, error } }
}

function getSixMonthsOffer() {
    return dispatch => {
        dispatch(request())

        planServices.getSixMonthsOffer().then(response => {
            if (isNotNull(response))
                dispatch(success(response))
        }).catch(error => {
            dispatch(failed(error))
        })
    }

    function request() { return { type: planActionTypes.GET_SIX_MONTH_PLAN_REQUEST } }
    function success(sixMonthPlan) { return { type: planActionTypes.GET_SIX_MONTH_PLAN_SUCCESS, sixMonthPlan } }
    function failed(error) { return { type: planActionTypes.GET_SIX_MONTH_PLAN_FAILED, error } }
}

export const planActions = {
    get,
    getTrialOffer,
    getSixMonthsOffer
}