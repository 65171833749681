import styled, { keyframes } from 'styled-components'
import { mediaQueries } from '../../constants/mediaQueries'

const pulseYellow = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
    }  
    70% {
        box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
    }  
    100% {
        box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
    }
`

export const NetPeakGainsStatistics = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px;
    background-color: #0f1823;
    border: 2px solid #18283c;
    box-shadow: 0px 3px 18px #00000061;
    border-radius: 9px;
    margin-right: 0;
    margin-bottom: 15px;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 12px;

        h2 {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: calc(100% - 108px);
            font-size: 13px;
            color: #fff;

            .icon-question {
                width: 13px;
                height: 13px;
                border-radius: 50%;
                margin-left: 8px;
                color: #e0c176;
                box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
                animation: ${pulseYellow} 2s infinite;
            }

            &.with-legend {
                justify-content: space-between;
                width: 100%;

                label {
                    margin-bottom: 0;

                    .legend {
                        display: inline-block;
                        width: 35px;
                        height: 5px;
                        background-color: #27D194;
                        margin-right: 12px;
                    }

                    .label-text {
                        font-size: 12px;
                        text-transform: uppercase;
                        color: #8A9BAE;
                    }
                }
            }

            .help-txt {
                color: #f7eab4;
            }
        }
        
        .actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 108px;

            .actions-label {
                display: none;
                width: 140px;
                font-size: 13px;
                margin-right: 10px;
            }

            .period-selector {
                width: 100%;
                box-shadow: 0.3rem 0.3rem 0.6rem #0f1922, -0.2rem -0.2rem 0.5rem #223040;
                border-radius: 9px;
                padding: 5px;

                .toggle-btn {
                    width: 100%;
                    border-color: transparent !important;
                    background-color: transparent !important;
                    color: #6a7b8f !important;
                    font-size: 13px;

                    &.selected {            
                        box-shadow: inset 0.2rem 0.2rem 0.5rem #0f1922, inset -0.2rem -0.2rem 0.5rem #223040;
                        color: #15bd6f !important;
                    }

                    &:active,
                    &:focus {
                        outline: none;
                        color: #15bd6f !important;
                    }

                    &:hover {
                        color: #15bd6f !important;
                    }
                }
            }
        }
    }

    .time-range.mp-only {
        display: flex;
        align-items: center;
        font-size: 14px;
        justify-content: flex-start;
        width: 100%;
    }

    .dot {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        margin-left: 5px;
        background-color: #13B079;
    }

    .results-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 25px;

        .net-peak-gains {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 110px;
            background-color: #0f1822;
            border: 1px solid #345A6A;
            box-shadow: inset 0px 0px 16px #184458;
            border-radius: 17px;
            margin-bottom: 10px;

            .result-icon-wrapper {
                position: relative;
                margin: 0 15px 2px;
                width: 98px;
                height: 75px;

                img {
                    width: 100%;
                    position: relative;
                }
                
                &::before {
                    position: absolute;
                    content: ' ';
                    background: transparent radial-gradient(closest-side at 50% 50%, #009DFB 0%, #0B131A00 100%) 0% 0% no-repeat padding-box;
                    width: 125px;
                    height: 75px;
                    left: calc(50% - 62.5px);
                    opacity: 0.4;
                    z-index: 0;
                }
            }

            .net-peak-gains-details {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 50%;
            }

            .result-value {
                display: block;
                font-size: 28px;
                font-weight: 700;
                margin-bottom: 0;

                &.pos-val {
                    color: #15bd6f;
                }

                &.neg-val {
                    color: #d92d72;
                }
            }

            .result-label {
                display: block;
                color: #8A9BAE;
                font-size: 12px;
            }

            .legends {
                display: block;

                .legend {
                    display: inline-block;
                    width: 17px;
                    height: 5px;

                    &.winner {
                        background-color: #13B079;
                    }

                    &.loser {
                        background-color: #B93F5F;
                    }

                    &.ect {
                        background-color: #247DBF;
                    }
                }
            }
        }

        .net-peak-gains-breakdown {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            padding-left: 0;

            .time-range {
                display: none;
                font-size: 14px;
            }

            .the-breakdown {
                width: 100%;
                height: 140px;
                border-radius: 10px;
                list-style-type: none;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: space-around;
                padding: 10px 8px;
                background-color: #0a0f16;
                border: 2px solid #0F161E;

                li {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: auto;
                    border-right: 1px solid #18283C;

                    .text-label {
                        font-size: 12px;
                    }

                    .total {
                        padding: 10px 0;
                        color: #fff;
                        font-weight: 500;
                        font-size: 17px;
                    }

                    .percentage {
                        font-size: 15px;
                        padding: 5px 10px;
                        border-radius: 5px;
                        font-weight: 600;
                        letter-spacing: 1px;
                    }

                    &.winning {
                        padding-left: 0;
                        padding-right: 10px;

                        .percentage {
                            background-color: #1A3631;
                            color: #13B079;
                        }
                    }

                    &.losing {
                        padding-left: 10px;
                        padding-right: 10px;

                        .percentage {
                            background-color: #292330;
                            color: #B93F5F;
                        }
                    }

                    &.closed-early {
                        padding-left: 10px;
                        padding-right: 0;

                        .percentage {
                            background-color: #14293D;
                            color: #247DBF;
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                        border-right: none;
                    }
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        padding: 14px 12px 12px;
        width: 60%;
        margin-right: 10px;

        .header {
            h2 {
                font-size: 15px;

                .icon-question {                    
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        padding: 25px 12px 25px 15px;
        width: 65%;
        margin-right: 15px;
        margin-bottom: 0;

        .header {
            h2 {
                width: calc(100% - 225px);
                font-size: 16px;
                margin-bottom: 0;

                .icon-question {                
                    width: 16px;
                    height: 16px;
                    margin-left: 10px;
                }
            }

            .actions {
                width: 225px;

                .actions-label {
                    display: flex;
                }

                .period-selector {
                    .toggle-btn {
                        font-size: 14px;
                    }
                }
            }
        }

        .time-range.mp-only {
            display: none;
        }

        .results-wrapper {
            flex-direction: row;

            .net-peak-gains {
                flex-direction: column;
                width: 200px;
                height: 200px;
                margin-bottom: 0;

                .result-icon-wrapper {                    
                    margin: 18px 0 2px;
                }

                .net-peak-gains-details {                    
                    width: 100%;
                }
            }

            .net-peak-gains-breakdown {
                width: calc(100% - 200px);
                padding-left: 15px;

                .time-range {
                    display: flex;
                }

                .the-breakdown {
                    padding: 15px 10px;
                    height: 164px;

                    li {
                        &.winning {
                            padding-right: 10px;
                        }

                        &.losing {
                            padding-left: 10px;
                            padding-right: 10px;
                        }

                        &.closed-early {
                            padding-left: 10px;
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        padding: 20px;

        .header {
            margin-bottom: 16px;

            h2 {
                font-size: 18px;

                .icon-question {                    
                    width: 18px;
                    height: 18px;
                }
            }
        }

        .results-wrapper {
            .net-peak-gains-breakdown {
                .the-breakdown {
                    padding: 15px;

                    li {
                        &.winning {
                            padding-right: 15px;
                        }

                        &.losing {
                            padding-left: 15px;
                            padding-right: 15px;
                        }

                        &.closed-early {
                            padding-left: 15px;
                        }
                    }
                }
            }
        }
    }
`