import glamorous from 'glamorous'

const { A, Img } = glamorous

const BrandWrapper = glamorous.div({
    display: 'inline-block',
    margin: '80px auto 50px',
    width: '220px'
})

export function Brand({imageUrl}) {
    return (
        <BrandWrapper>
            <A href={`https://www.profitfarmers.com`}>
                <Img 
                    src={imageUrl} 
                    width={`100%`} 
                    alt="ProfitFarmers Logo" />
            </A>
        </BrandWrapper>
    )
}
