import styled from 'styled-components'
import { mediaQueries } from '../../constants/mediaQueries'

export const MessagesInnerContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    min-height: calc(100vh - 36px);
    padding: 81px 11px 20px;
    max-width: 1180px;
    margin: 0 auto;
    
    .page-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;

        .page-title {
            display: flex;
            align-items: center;
            margin-right: auto;
            color: #fff;
            width: calc(100% - 42px);
            margin-bottom: 0;
            //width: 100%;

            .icon {
                margin-right: 15px;
                color: #13B078;
                width: 20px;
            }

            .text {
                font-size: 20px;
            }

            .icon-question {
                margin-left: 10px;
                font-size: 16px;
                cursor: pointer;
            }
        }

        .page-actions {
            width: 42px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button {
                display: inline-block;
                padding: 4px 8px;
                background-color: #13b078;
                text-decoration: none;
                color: #fff;
                border-radius: 22px;
                transition: all ease .5s;
                outline: none;
                box-shadow: none;
                border-color: transparent;
                font-size: 13px;

                .icon {
                    margin: 0;
                    font-size: 15px;
                    margin-top: 2px;
                }
                
                .btn-text {
                    display: none;
                }

                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        &.full {
            .page-title {
                width: 100%;
            }

            .page-actions {
                display: none;
            }
        }
    }

    .filters-bar {
        display: block;
        width: 100%;
        margin-bottom: 25px;
        padding: 8px 10px;
        background-color: transparent;
        border-radius: 9px;
        box-shadow: 0.3rem 0.3rem 0.6rem #0f1922, -0.2rem -0.2rem 0.5rem #223040;

        .input-group {
            .input-group-prepend {
                .input-group-text {
                    background-color: transparent;
                    border: none;
                }
            }

            .form-control {
                background-color: transparent;
                border: none;
                font-size: 14px;
                color: #fff;
                height: 35px;
                box-shadow: none;
                border-radius: 0;

                &::placeholder {
                    color: #6f89a4;
                    font-size: 18px;
                }
            }

            .input-group-prepend {
                .input-group-text {
                    padding: 0.375rem 0.45rem;
                }
            }

            .input-group-append {
                .dropdown {
                    .dropdown-toggle {
                        background-color: #0b1219;
                        border: none;
                        border-radius: 6px;
                        padding-left: 10px;
                        padding-right: 10px;
                        box-shadow: none;
                        outline: none;
                        color: #13b078;
                        font-weight: 600;

                        &::after {
                            margin-left: 0.555em;
                            vertical-align: 0.155em;
                        }
                    }

                    .dropdown-menu {
                        background-color: #06090c;

                        .dropdown-item-text {
                            font-size: 13px;
                            color: #8c9197;
                        }

                        .dropdown-item {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            color: #bec2c7;

                            &:hover {
                                background-color: transparent;
                                color: #13b078;
                            }
                        }

                        .dropdown-divider {
                            border-color: #495057;
                        }
                    }

                    &.date-range {
                        .dropdown-menu {
                            width: 332px;

                            .dropdown-item {
                                .date-range-value {
                                    font-size: 13px;
                                    color: #737a81;
                                }
                            }
                        }
                    }
                }

                &:last-child {
                    .dropdown {
                        .dropdown-toggle {
                            padding-right: 10px;
                        }
                    }
                }
            }
        }

        &.is-sticky {
            position: fixed;
            top: 56px;
            right: 0;
            z-index: 999;
            box-shadow: 0px 1px 18px #00000047;
            animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
            border-radius: 0;
            border: 10px solid #23303e;  
            width: 100% !important;
            background-color: #0b1219;

            .input-group {
                .input-group-append {
                    .dropdown {
                        .dropdown-toggle {
                            background-color: #1c2732;
                            color: #13b078;
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        .page-header {
            .page-title {
                .icon {
                    width: 25px;
                }

                .text {
                    font-size: 25px;
                }
            }
        }

        .filters-bar {
            &.is-sticky {
                top: 56px;
            }
        }
    }

    ${mediaQueries.largeDevices} {
        padding-left: 15px;
        padding-right: 15px;

        .filters-bar {
            &.is-sticky { 
                width: calc(100% - 60px) !important;
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        padding: 100px 20px 50px;

        .filters-bar {
            &.is-sticky {
                width: calc(100% - 280px) !important
            }
        }
    }

    @media (min-width: 1500px) {
        padding: 81px 25px 25px;

        .filters-bar {
            &.is-sticky {
                top: 91px;
                width: 1174px !important;
                right: calc(50% - 725.5px);
            }
        }
    }
`