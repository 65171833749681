import { checkListActionTypes } from '../CONSTANTS'

export function checkList(state = {}, action) {
    switch (action.type) {
        case checkListActionTypes.CHECKLIST_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case checkListActionTypes.CHECKLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                item: action.checklist
            }
        case checkListActionTypes.CHECKLIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        case checkListActionTypes.CHECKLIST_UPDATE:
            return {
                ...state,
                isLoading: false,
                item: action.checklist
            }
        default:
            return state
    }
}

export function saveCheckList(state = {}, action) {
    switch (action.type) {
        case checkListActionTypes.CHECKLIST_SAVE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case checkListActionTypes.CHECKLIST_SAVE_SUCCESS:
            return {
                ...state,
                loading: false,
                checkItem: action.checkItem
            }
        case checkListActionTypes.CHECKLIST_SAVE_FAILURE:
            return {
                ...state,
                loading: false,
                checklist_error: action.checklist_error
            }

        case checkListActionTypes.CHECKLIST_CLEAR:
            return {
                ...state,
                checkItem: {}
            }
        default:
            return state
    }
}

var listItems = {
    progressChecklist: [0, 0, 0, 0, 0],
    progress: 0,
    progressChecklistMap: [30, 20, 20, 20, 10],
    progressChecklistTitles: [
        "Open an account with Binance",
        "Connect your Binance account with PF",
        "Add Bitcoin to your Binance account",
        "Make sure you have USDT",
        "Choose your first signal and trade"
    ],
    progressChecklistMessages: [
        "Before you can start making money, you need to open a Binance account.",
        "Now you need to connect your Binance account with PF. This is quick and easy to do",
        "To make money, you need to have money to trade with. Buy Bitcoin on Binance or transfer already existing crypto to your wallet",
        "Some of our signals are traded with Bitcoin (BTC), others with the coin USDT (USD Tethered). To make most money you need both coins.",
        "You are now on the last step! Click a signal and make a trade now"
    ]
}

export function checkListItems(state = listItems, action) {
    switch (action.type) {
        case checkListActionTypes.CHECKLIST_SET:
            return {
                ...state,
                progressChecklist: action.progressChecklist,
                progress: action.progress,
                progressChecklistMap: [30, 20, 20, 20, 10],
                progressChecklistTitles: [
                    "Open an account with Binance",
                    "Connect your Binance account with PF",
                    "Add Bitcoin to your Binance account",
                    "Make sure you have USDT",
                    "Choose your first signal and trade"
                ],
                progressChecklistMessages: [
                    "Before you can start making money, you need to open a Binance account.",
                    "Now you need to connect your Binance account with PF. This is quick and easy to do",
                    "To make money, you need to have money to trade with. Buy Bitcoin on Binance or transfer already existing crypto to your wallet",
                    "Some of our signals are traded with Bitcoin (BTC), others with the coin USDT (USD Tethered). To make most money you need both coins.",
                    "You are now on the last step! Click a signal and make a trade now"
                ]
            }

        default:
            return state
    }
}

export function checklistStatus(state = { isShowMessage: true, stepsCompleted: false }, action) {
    switch (action.type) {
        case checkListActionTypes.CHECKLIST_MESSAGE:
            return {
                ...state,
                isShowMessage: action.isShowMessage
            }

        case checkListActionTypes.CHECKLIST_STEPS:
            return {
                ...state,
                stepsCompleted: action.stepsCompleted
            }
        default:
            return state
    }
}
