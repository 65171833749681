import React from 'react'
import { SafeAnchor } from 'react-bootstrap'
import { MoneyBackGuaranteeStyle } from './styles/MoneyBackGuaranteeStyle'

export const MoneyBackGuaranteeSection = () => {
    return (
        <MoneyBackGuaranteeStyle>
            <div className='container'>
                <div className='img-wrapper'>
                    <img src='/images/offers/money-back-guarantee.png' />
                </div>
                <div className='text-wrapper'>
                    <h3 className='section-title'>100% Refund if we don't deliver a 60% Signal Win Rate</h3>
                    <p>We will offer you a full <strong>100% refund</strong> during your <strong>first 30 days</strong> with ProfitFarmers if we are unable to provide <strong>completed</strong> trading signals that achieve a <strong>60%</strong> win rate vs lose rate or higher during that time period. We obviously cannot guarantee your personal performance as every user trades differently, learn more about our <SafeAnchor href='https://www.profitfarmers.com/refund-policy/' target="_blank" rel="noreferrer">refund policy here</SafeAnchor>.</p>
                    <p>Winning signals are defined as those that reach their first target (or higher) and, therefore, offer some level of profitable opportunity. You can read more about <SafeAnchor href='https://www.profitfarmers.com/blog/how-are-trading-signal-results-statistics-calculated/' target="_blank" rel="noreferrer">how our signal statistics are calculated here</SafeAnchor>.</p>
                </div>
            </div>
        </MoneyBackGuaranteeStyle>
    )
}