import React from 'react'
import { Button, Image, Modal, SafeAnchor } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonGroup, Button as MaterialButton } from '@material-ui/core'
import { faExchangeAlt, faArrowRight, faArrowLeft, faTimes, faWallet } from '@fortawesome/free-solid-svg-icons'
import { Skeleton } from "@material-ui/lab"
import _ from 'lodash'
import { WalletItem } from './WalletItem'
import { QuickTrade } from '../QuickTrade'
import {
    WalletSideWrapper, WalletTitleWrapper, WalletTitle,
    WalletTitleCointainer, WalletTitleLists,
    WalletBtnBalanceWrapper, ShowWalletBalance,
    WalletSearchWrapper, ContentSidePanel,
    WalletWrapper, WalletList,
    WalletBtnWrapper, WalletIcon,
    WalletQuickTradeWrapper, WalletQuickTradeChangeWrapper,
    WalletQuickTradeChangeContainer, WalletButtonWrapper, WalletButton,
    FeedButton, WalletItems, Wallet, WalletCurrency, WalletCoinWrapper, WalletCoin, WalletBalance
} from './styled_components'
import { isNotNull, setDefaultImage, setCoinLogo, ScientificToDecimal, getCoin } from '../../utils'

export const WalletSidebarView = (props) => {
    const $this = props.self
    const { isWalletHide, isEmailPromt, cryptoWallet, futureWallet, orderPositions } = props
    const { user } = props.authentication
    const {
        isDisable, walletShow, balanceShow,
        searchValue, isActive, isAutoTradeConfigured,
        sourceCoin, targetCoin, totalBalance,
        currentSet, quickTradeShow, quickTradeLastPrice } = props.state

    const [feedValue, setFeedToggle] = React.useState("SPOT")
    const { loading, items } = feedValue === "SPOT" ? cryptoWallet : futureWallet

    const onHandleScroll = _.debounce((e) => {
        if (e.target.clientHeight + e.target.scrollTop === e.target.scrollHeight)
            props.loadNewSet()
    })

    const onQuickTradeHide = () => $this.setState({ quickTradeShow: false })

    const onBalanceToggle = () => $this.setState((prev) => {
        return ({ balanceShow: !prev.balanceShow, currentSet: 20 })
    })

    const onSearchHandler = (event) => {
        $this.setState({
            searchValue: event.target.value.toLowerCase()
        })
    }

    const renderPreloader = () => {
        const preloaderData = [0, 1, 2, 3, 4, 5]

        return _.map(preloaderData, (val, key) => {
            return (
                <div key={key}>
                    <Skeleton variant="rect" style={{
                        width: '100%',
                        height: '100px',
                        backgroundImage: 'linear-gradient(15deg,#334156,#385065)',
                        marginBottom: key === 5 ? '120px' : '10px',
                        boxShadow: '0px 2px 1px 1px rgba(0,0,0,0.2)'
                    }} className="skeleton" />
                </div>
            )
        })
    }

    const renderWalletTitle = () => {
        return (
            <WalletTitleCointainer>
                <WalletTitleLists>
                    <span>
                        Wallet
                    </span>
                    
                    <Button className="btn-sync" disabled={isDisable} onClick={() => !isDisable ? props.syncWallet() : null}>
                        <i className="fas fa-sync-alt sync"></i>
                    </Button>

                    { window.innerWidth > 1200 &&
                        <Button id="sidebar-btn" className={`btn-sidebar btn-none ${walletShow ? "" : "show"}`} onClick={() => props.handleWallet()}>
                            <FontAwesomeIcon icon={walletShow ? faArrowRight : faArrowLeft} className="icon" />
                        </Button>
                    }
                </WalletTitleLists>
            </WalletTitleCointainer>
        )
    }

    const renderBalanceToggle = () => {
        if (feedValue === "SPOT")
            return (
                <WalletBtnBalanceWrapper>
                    <ShowWalletBalance onChange={() => isDisable === false ? onBalanceToggle() : null} className={`wallet-toggle ${isActive}`}>
                        <input type="checkbox" />
                        <i className={`wallet-toggle ${isActive}`} />
                        {balanceShow ? "Show Balance" : "Show 0 Balance"}
                    </ShowWalletBalance>
                </WalletBtnBalanceWrapper>
            )
        else
            return null
    }

    const renderSearch = () => {
        if (feedValue === "SPOT")
            return (
                <WalletSearchWrapper>
                    <input type="text" placeholder="Search Coin" onChange={(e) => onSearchHandler(e)} disabled={isDisable} />
                </WalletSearchWrapper>
            )
        else
            return null
    }

    const renderQuickTradeConvert = () => {
        if (feedValue === "SPOT")
            return (
                <WalletQuickTradeWrapper>
                    <WalletQuickTradeChangeWrapper>
                        <WalletQuickTradeChangeContainer>
                            <Image className="quick-trade-logo" src={setCoinLogo(sourceCoin)} onError={(e) => setDefaultImage(e)} fluid roundedCircle alt="coin logo" />
                        </WalletQuickTradeChangeContainer>
                        <WalletQuickTradeChangeContainer>
                            <FontAwesomeIcon onClick={() => !isDisable ? props.handleQuickTradeChange(sourceCoin, targetCoin) : null} icon={faExchangeAlt} className={isDisable ? "disable icon" : "icon"} />
                        </WalletQuickTradeChangeContainer>
                        <WalletQuickTradeChangeContainer>
                            <Image className="quick-trade-logo" src={setCoinLogo(targetCoin)} onError={(e) => setDefaultImage(e)} fluid roundedCircle alt="coin logo" />
                        </WalletQuickTradeChangeContainer>
                    </WalletQuickTradeChangeWrapper>
                    <WalletButtonWrapper>
                        <WalletButton disabled={isDisable} className={`btn btn-default`} onClick={() => !isDisable ? props.showQuickTrade() : null}>Convert</WalletButton>
                    </WalletButtonWrapper>
                </WalletQuickTradeWrapper>
            )
        else
            return null
    }

    const renderWalletItems = (items) => {
        if (isNotNull(items)) {
            const { data, msg } = items
            if (isNotNull(data)) {
                const filterFeed = _.filter(data, function (val) {
                    return feedValue === "SPOT" ? data : val.name.toLowerCase().includes("usdt")
                })

                const filtered = _.filter(filterFeed, function (val) {
                    return searchValue === null ? filterFeed : val.name.toLowerCase().includes(searchValue) || val.coinName.toLowerCase().includes(searchValue)
                })

                const filteredBalance = _.filter(filtered, (val) => {
                    return feedValue === "FUTURES"
                        ? filtered
                        : balanceShow
                            ? filtered
                            : val.balance.free !== 0 && parseFloat(val.balance.usdt) > 11
                })

                const filteredData = _.filter(filteredBalance, (val, key) => key <= currentSet)
                if (isNotNull(filteredData) && filteredData.length > 0) {
                    return _.map(filteredData, (value, key) => <WalletItem key={key} {...value} />)
                } else if (isNotNull(filteredData) && filteredData.length === 0) {
                    if (isNotNull(searchValue))
                        return (<li>No Coins Found</li>)
                    else {
                        if (totalBalance < 11)
                            return (<li>You do not have any wallets with sufficient balance</li>)
                        else
                            return (
                                <li>
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </li>
                            )
                    }
                } else
                    return (<li>Unable to get wallet data, please try again later</li>)
            } else {                
                // if (feedValue === "FUTURES") {
                //     return (
                //         <SafeAnchor className="help-link ml-1" href={ user.isPaid ? 'https://pro-support.profitfarmers.com/article/38-how-to-connect-your-binance-account-to-profitfarmers-via-api-key' : 'https://support.profitfarmers.com/article/176-how-to-connect-your-binance-account-to-profitfarmers-via-api-key' } target="_blank">
                //             <div className="help-link-content">
                //                 <span className="">
                //                 <i class="fas fa-lightbulb"></i> How to generate and connect API keys (spot and futures combined guide)
                //                 </span>
                //             </div>
                //         </SafeAnchor>
                //     )
                // } else {
                //     return (<>{msg}</>)
                // }
                
                return (
                    <li>
                        <SafeAnchor className="help-link" href={ user.isPaid ? 'https://pro-support.profitfarmers.com/article/38-how-to-connect-your-binance-account-to-profitfarmers-via-api-key' : 'https://support.profitfarmers.com/article/176-how-to-connect-your-binance-account-to-profitfarmers-via-api-key' } target="_blank">
                            <div className="help-link-content">
                                <span className="">
                                <i className="fas fa-lightbulb"></i> How to generate and connect API keys (spot and futures combined guide)
                                </span>
                            </div>
                        </SafeAnchor>
                    </li>
                )
            }
                
        } else
            return (<li>Unable to get wallet data, please try again later</li>)
    }

    const renderWallets = (loading, items) => {
        return (
            <WalletWrapper>
                <WalletList>
                    {isNotNull(user) &&
                        <>
                            {loading &&
                                <li>
                                    {renderPreloader()}
                                </li>
                            }

                            {!loading &&
                                <>
                                    { isAutoTradeConfigured
                                        ? renderWalletItems(items)
                                        : feedValue === "FUTURES" 
                                            ? 
                                                <li>
                                                    <SafeAnchor className="help-link ml-1" href={ user.isPaid ? 'https://pro-support.profitfarmers.com/article/28-issues-connecting-binance-api' : 'https://support.profitfarmers.com/article/205-issues-connecting-binance-api-with-profitfarmers-troubleshoot-problems-here' } target="_blank">
                                                        <div className="help-link-content">
                                                            <span className="">
                                                                <i className="fas fa-lightbulb"></i> How to generate and connect API keys (spot and futures combined guide)
                                                            </span>
                                                        </div>
                                                    </SafeAnchor>
                                                </li>
                                            : <li>You do not have any available wallets yet</li>
                                    }
                                </>
                            }
                        </>
                    }
                </WalletList>
            </WalletWrapper>
        )
    }

    const renderMobileWalletToggle = () => {
        return (
            <WalletIcon>
                <FontAwesomeIcon icon={faWallet} className="icon" />
            </WalletIcon>
        )
    }

    const renderSpotFuturesToggle = () => {
        const onSetFeedToggleHandler = value => {
            setFeedToggle(value)
            $this.setState({
                feed: value === "SPOT" ? true : false,
                exchange: value === "SPOT" ? "binance" : "binancefutures",
                searchValue: null
            })
            if (value === "FUTURES")
                $this.setState({
                    balanceShow: false,
                    currentSet: 20
                })
        }

        return (
            <ButtonGroup className="feed-toggle" color="primary" aria-label="outlined primary button group">
                <FeedButton as={MaterialButton} onClick={() => onSetFeedToggleHandler("SPOT")} active={feedValue} className={`feed-btn ${feedValue === 'SPOT' ? 'selected' : ''}`}>SPOT</FeedButton>
                <FeedButton as={MaterialButton} onClick={() => onSetFeedToggleHandler("FUTURES")} active={feedValue === "SPOT" ? "FUTURES" : "SPOT"} className={`feed-btn ${feedValue === 'FUTURES' ? 'selected' : ''}`}>FUTURES</FeedButton>
            </ButtonGroup>
        )
    }

    const renderQuicktradeModalHeader = () => {
        return (
            <>
                <div className="close-btn" onClick={() => onQuickTradeHide()}>
                    <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                </div>
                Quick Trade
                <div className="input-group-append">
                    <span id="basic-addon2">
                        <small>
                            <span className="coin-sign">{sourceCoin === "USDT" ? "$" : "₿"}</span>
                            {ScientificToDecimal(quickTradeLastPrice)}
                            <span className="source-coin">{sourceCoin}</span>
                        </small>
                    </span>
                </div>
            </>
        )
    }

    const renderQuickTradeModalBody = () => {
        return (
            <QuickTrade
                hide={onQuickTradeHide}
                handleCalculation={props.handleCalculation}
                handleChange={props.handleQuickTradeChange}
                {...props.state}
            />
        )
    }

    const renderPositions = (data) => {
        const renderPositionItems = (positions) => {
            const filteredPositions = _.filter(positions, (val) => {
                return isNotNull(val.positionAmt) && parseFloat(val.positionAmt) > 0
            })

            if (isNotNull(filteredPositions) && filteredPositions.length > 0) {
                return _.map(filteredPositions, (item, key) => {
                    return (
                        <WalletItems className="theme-wallet-items-wrapper" key={key}>
                            <Wallet className="theme-wallet-items">
                                <WalletCurrency>
                                    <WalletCoinWrapper className="theme-wallet-coin">
                                        <Image className="wallet-item-logo img-fluid" src={setCoinLogo(getCoin(item.symbol))} onError={(e) => setDefaultImage(e)} fluid roundedCircle />
                                        <WalletCoin>{item.symbol}</WalletCoin>
                                    </WalletCoinWrapper>
                                    <div className="balance-text first">
                                        <WalletBalance className="positionAmt">{parseFloat(item.positionAmt) <= 0 ? "0.00" : ScientificToDecimal(parseFloat(item.positionAmt))}<span className="coinName">SIZE</span></WalletBalance>
                                    </div>
                                </WalletCurrency>
                            </Wallet>
                        </WalletItems>
                    )
                })
            } else
                return (<>No position found</>)
        }

        if (isNotNull(user) && 
            isNotNull(data) &&
            isNotNull(data.loading_positions) &&
            !data.loading_positions &&
            isNotNull(data.positionData) &&
            isNotNull(data.positionData.positions) &&
            data.positionData.positions.length > 0) {

            return (
                <WalletWrapper className="position">
                    <WalletTitle className="mt-2 mb-3">Position</WalletTitle>
                    <WalletList>
                        {renderPositionItems(data.positionData.positions)}
                    </WalletList>
                </WalletWrapper>
            )
        }
    }

    return (
        <>
            {!isWalletHide &&
                <WalletSideWrapper className={`wallets theme-sidebar ${isEmailPromt ? "show-email" : ""} ${isNotNull(props.isTokenFound) && !props.isTokenFound ? 'notif-permission' : ''}`}>
                    <WalletTitleWrapper>
                        {renderWalletTitle()}
                        {renderSpotFuturesToggle()}
                        {renderSearch()}
                        {renderQuickTradeConvert()}
                        {renderBalanceToggle()}

                        {balanceShow
                            ? <WalletTitle className="mt-2">ALL COINS</WalletTitle>
                            : ""
                        }
                    </WalletTitleWrapper>

                    <ContentSidePanel className={`${walletShow ? "" : "hide"} theme-wallet-side ${feedValue === "FUTURES" ? "futures" : ""}`} onScroll={(e) => onHandleScroll(e)}>
                        {renderWallets(loading, items)}

                        {isNotNull(feedValue) && feedValue === "FUTURES" &&
                            renderPositions(orderPositions)
                        }
                    </ContentSidePanel>

                    <WalletBtnWrapper id="wallet-click" className={walletShow ? "" : "show"} onClick={() => props.handleWallet(true)}>
                        {renderMobileWalletToggle()}
                    </WalletBtnWrapper>
                </WalletSideWrapper>
            }

            <Modal
                id="quickTradeModal"
                className="generic-modal quick-trade"
                show={quickTradeShow}
                onHide={onQuickTradeHide}
                aria-labelledby="genericModal"
                aria-hidden="true"
                data-backdrop="true"
                centered
            >
                <Modal.Header>
                    {renderQuicktradeModalHeader()}
                </Modal.Header>
                <Modal.Body>
                    {renderQuickTradeModalBody()}
                </Modal.Body>
            </Modal>
        </>
    )
}