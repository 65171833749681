import { sidebarActionTypes } from '../CONSTANTS'

function showSidebar(sidebar) {
    return dispatch => {
        dispatch({
            type: sidebarActionTypes.SHOW_SIDEBAR,
            sidebar
        })
    }
}

export const sidebarActions = {
    showSidebar
}