import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { notificationActions } from '../../redux/actions'
import { notificationActionTypes } from '../../redux/CONSTANTS'
import { notiTypes } from '../../constants/notificationTypeConstants'
import { NotificationView } from './NotificationView'
import { isNotNull, onSetNewState, addPageViewEvent, seo } from '../../utils'

class NotificationContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            markedAllAsReadData: {
                notification_id: "ALL",
                isMarkedAll: true
            },
            isMarked: false,
            totalUnread: 0,
            currentSet: 19,
            totalItems: 0,
            selectedActorId: null,
            isRedirect: false,
            notif_id: null,
            signal_id: null,
            isRead: null
        }
        this.baseState = this.state
        this.mounted = false

        window.onscroll = _.debounce(() => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.scrollingElement.scrollHeight - 1)
                this.loadAnotherSet()
        }, 100)
    }

    componentDidMount() {
        this.mounted = true
        const { notificationLists } = this.props
        this.handleNotification(notificationLists)

        seo({
            title: "Notifications",
            metaDescription: ""
        })
        addPageViewEvent()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.mounted) {
            const { dispatch, notificationLists, markedNotification, history } = this.props

            if (prevProps.notificationLists !== notificationLists)
                this.handleNotification(notificationLists)

            if (prevProps.markedNotification !== markedNotification) {
                const { loading, item, active_id } = markedNotification
                if (!loading && isNotNull(item) && isNotNull(item.success) && item.success === true) {
                    onSetNewState(this, { isMarked: false })
                    if (prevState.isRedirect === true) {
                        onSetNewState(this, { 
                            isRedirect: false 
                        }, () => {
                            const { notif_id, signal_id, isRead } = this.state
                            history.push(`/signals?sId=${signal_id}&isRead=${isRead}&id=${notif_id}&actn=view`)
                            //window.open(`/dashboard?sId=${signal_id}&isRead=${isRead}&id=${notif_id}`, '_blank')
                        })
                    }

                    if (isNotNull(notificationLists) &&
                        isNotNull(notificationLists.notification_lists) &&
                        isNotNull(notificationLists.notification_lists.data)) {
                        let notification = [...notificationLists.notification_lists.data]
                        _.forEach(notification, n => {
                            if (this.state.selectedActorId === "ALL") {
                                if (n.type !== 4)
                                    n.isRead = true
                            } else
                                if (n.id === (this.state.selectedActorId === null ? active_id : this.state.selectedActorId))
                                    n.isRead = true
                        })
                        let notification_lists = {
                            data: notification
                        }
                        dispatch({ type: notificationActionTypes.GET_ALL_NOTIFICATION_LISTS_SUCCESS, notification_lists })
                        dispatch({ type: notificationActionTypes.CLEAR_MARKED_NOTIFICATION })
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)
        this.mounted = false
    }

    loadAnotherSet = () => {
        const { currentSet, totalItems } = this.state
        const newSet = currentSet + 19
        if (currentSet <= totalItems)
            onSetNewState(this, { currentSet: newSet })
    }

    handleMarkAsRead = (data, e) => {
        e.preventDefault()
        const { dispatch } = this.props
        const { totalUnread } = this.state
        if (totalUnread > 0 && isNotNull(data)) {
            const { id, type, isMarkedAll, isRead } = data
            if (isNotNull(id))
                onSetNewState(this, { selectedActorId: id })
            if (isNotNull(type) && type !== 4) {
                const requestData = {
                    "notification_id": id,
                    "isMarkedAll": isRead
                }
                dispatch(notificationActions.markNotificationAsRead(requestData))
            }
            if (isNotNull(isMarkedAll) && isMarkedAll === true) {
                onSetNewState(this, {
                    isMarked: true,
                    selectedActorId: "ALL"
                })
                dispatch(notificationActions.markNotificationAsRead(data))
            }
        }
    }

    handleRedirection = (data) => {
        const { history } = this.props
        if (isNotNull(data)) {
            const { id, actorId, isRead, type } = data
            if (isNotNull(type) && isNotNull(isRead) && isNotNull(id) && isNotNull(actorId)) {
                switch (type) {
                    default: break
                    case notiTypes.NEW_SIGNAL:
                    case notiTypes.STOP_LOSS_HIT: 
                    case notiTypes.TARGET_HIT: 
                    case notiTypes.ALL_TARGETS_HIT: {
                        const currrentPath = window.location.pathname
                        if (isNotNull(currrentPath) && currrentPath !== '/signals') {
                            if (isRead === true) {
                                history.push(`/signals?sId=${actorId}&isRead=${isRead}&id=${id}&actn=view`)
                            } else
                                onSetNewState(this, {
                                    isRedirect: true,
                                    notif_id: id,
                                    signal_id: actorId,
                                    isRead: isRead
                                })
                        }
                        break
                    }
                    case notiTypes.MANUALLY_CLOSED: {
                        this.props.onReviewEarlyClosedItem(data)
                        break
                    }
                    case notiTypes.AUTO_CLOSE_EARLY: {
                        this.props.onReviewAutoEarlyClosedItem(data)
                        break
                    }
                    case notiTypes.ORDER_SUCCEEDED:
                    case notiTypes.ORDER_FAILED: {
                        this.props.onReviewOrderResultItem(data)
                        break
                    }
                }
            }
        }
    }

    handleNotification = (notificationLists) => {
        if (isNotNull(notificationLists)) {
            let { loader, notification_lists } = notificationLists
            if (!loader && isNotNull(notification_lists)) {
                const { data } = notification_lists
                if (isNotNull(data)) {
                    const orderLists = _.orderBy(data, o => new Date(o.dateCreated), 'desc')

                    const promise = new Promise((resolve) => {
                        const find = _.countBy(orderLists, f => f.isRead === false)
                        if (isNotNull(find))
                            resolve(find.true)
                    })
                    promise.then((result) => {
                        onSetNewState(this, { totalUnread: result })
                    }, (err) => {
                        // console.log(err)
                    })

                    onSetNewState(this, { totalItems: data.length })
                }
            }
        }
    }

    render() {
        return (
            <NotificationView
                handleRead={this.handleMarkAsRead}
                handleRedirect={this.handleRedirection}
                state={this.state}
                {...this.props}
            />
        )
    }

}

function mapStateToProps(state) {
    const { authentication, notificationLists, markedNotification } = state
    return {
        authentication,
        notificationLists,
        markedNotification
    }
}
const connectedNotificationContainer = connect(mapStateToProps)(NotificationContainer)
export { connectedNotificationContainer as NotificationContainer }