import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const TradeGuardianSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    padding: 40px 5px;
    background-image: url(/images/trial-offer/trade-guardian-bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .container {
        .hot-feature {
            img {
                margin-bottom: 15px;
                max-width: 226px;
                width: 150px;
            }
        }

        .text-with-vid {
            display: flex;
            flex-direction: column;

            .video-wrapper {
                text-align: center;
                margin-bottom: 25px;

                > div {
                    height: 220px !important;
                }
            }

            .text-col {
                .section-title {
                    font-size: 20px;
                    font-weight: 700;
                    color: #FFFFFF;
                }

                p {
                    color: #C1CBD8;
                    font-family: 'Noto Sans';
                    font-weight: 300;
                    font-size: 16px;

                    .white-text {
                        color: #FFFFFF;
                    }
                }

                .benefits-list {
                    list-style-type: none;
                    padding-left: 50px;
                    font-family: 'Noto Sans';
                    color: #C1CBD8;
                    font-weight: 300;

                    .list-item {
                        margin-bottom: 20px;
                        position: relative;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &:before {
                            content: '';
                            position: absolute;
                            width: 32px;
                            height: 32px;
                            top: calc(50% - 16px);
                            left: -50px;
                            background-image: url(/images/trial-offer/check-icon.png);
                            background-repeat: no-repeat;
                            background-size: contain;;
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        .container {
            .text-with-vid {
                .video-wrapper {
                    > div {
                        height: 360px !important;
                    }
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        .container {
            .text-with-vid {
                flex-direction: row;
                //justify-content: center;
                //align-items: center;

                .video-wrapper {
                    text-align: left;
                    width: 50%;
                    padding-right: 30px;

                    > div {
                        height: 270px !important;
                    }

                    img {
                        width: 180px;
                    }
                }

                .text-col {
                    width: 50%;

                    .section-title {
                        font-size: 36px;
                    }

                    p {
                        font-size: 18px;
                    }

                    .benefits-list {
                        .list-item {
                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }

        &.up-w-trial,
        &.up-win-trial {
            .container {
                .text-with-vid {
                    flex-direction: row-reverse;

                    .video-wrapper {
                        padding-right: 0;
                        padding-left: 30px;
                    }

                    .text-col {
                        p {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        .container {
            .text-with-vid {
                .video-wrapper {
                    > div {
                        height: 360px !important;
                    }

                    img {
                        width: 200px;
                        margin-bottom: -15px;
                    }
                }
                .text-col {
                    .benefits-list {
                        margin-top: 35px;
                    }
                }
            }
        }
    }
`