import React from 'react'
import { connect } from 'react-redux'
import { Form, Col } from 'react-bootstrap'
import { Form as FinalForm, Field } from 'react-final-form'
import newFormDecorator from 'final-form-focus'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import { onboardingActions } from '../../redux/actions'
import { tradingExpQuestionnaire } from '../../utils/data'
import { isNull, isNotNull, onSetNewState } from '../../utils'

class TradingExpForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {   
            questionnaire: [],         
            expLevel: '',
            tradingPurpose: ''
        }
        this.baseState = this.state
        this.mounted = false
        this.formDecorator = newFormDecorator()
    }
    
    componentDidMount() {
        this.mounted = true
        
        onSetNewState(this, {
            questionnaire: tradingExpQuestionnaire
        })
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { saveOnboardingUserTradingExpDetails, exitForm } = this.props
            const { isSubmitting } = this.state
            
            if (prevProps.saveOnboardingUserTradingExpDetails !== saveOnboardingUserTradingExpDetails && 
                !saveOnboardingUserTradingExpDetails.loading && 
                isSubmitting) {
                    onSetNewState(this, {
                        isSubmitting: false
                    }, () => {
                        Swal.fire({
                            icon: 'success',
                            allowOutsideClick: false,
                            showConfirmButton: false,
                            timer: 2000,
                            didOpen: () => Swal.showLoading(),
                            willClose: () => exitForm()
                        })
                    })
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }  

    getValue = (fieldName) => {
        const { 
            expLevel,
            tradingPurpose
         } = this.state

        switch(fieldName) {
            default:
            case 'expLevel': return expLevel
            case 'tradingPurpose': return tradingPurpose
        }
    }  

    handleSelectAnswer = (e) => {
        switch(e.target.name) {
            default:
            case 'expLevel': {
                onSetNewState(this, {
                    expLevel: e.target.value
                })
                break
            }
            case 'hasFuturesTradingExp':  {
                const { hasFuturesTradingExp } = this.state
                onSetNewState(this, {
                    hasFuturesTradingExp: hasFuturesTradingExp === 'YES' ? 'NO' : 'YES'
                })
                break
            }
            case 'tradingPurpose':  {
                onSetNewState(this, {
                    tradingPurpose: e.target.value
                })
                break
            }
            case 'capital':  {
                onSetNewState(this, {
                    capital: e.target.value
                })
                break
            }
            case 'riskLevel':  {
                onSetNewState(this, {
                    riskLevel: e.target.value
                })
                break
            }
        }
    }

    onValidate = (values) => {
        const errors = {}

        // validate exp level
        if (isNull(values.expLevel))
            errors.expLevel = "Please select your trading experience"
        
        // validate trading purpose
        if (isNull(values.tradingPurpose))
            errors.tradingPurpose = "Please select a purpose"

        return errors
    }

    onSubmit = (values) => {
        const { dispatch } = this.props
        const { isSubmitting, ...selectedValues } = this.state

        if (!isSubmitting) {            
            let data = {
                expLevel: selectedValues.expLevel,
                tradingPurpose: selectedValues.tradingPurpose
            }
            dispatch(onboardingActions.saveTradingExpDetails(data))

            onSetNewState(this, {
                isSubmitting: true
            })
        }
    }    

    onSkip = (e) => {
        e.preventDefault() 
        const { exitForm } = this.props
        exitForm()
    }

    renderQuestions = () => {
        let mappedQuestions = []
        const { questionnaire } = this.state

        if (isNotNull(questionnaire) && questionnaire.length > 0) {
            let questionNo = 0
            mappedQuestions = _.map(questionnaire, (value, key) => {
                questionNo++

                let thisValue = this.getValue(value.name)

                if (value.name === 'expLevel' || value.name === 'tradingPurpose') {
                    switch (value.type) {
                        default:
                        case 'dropdown': {
                            let mappedAnswers = _.map(value.answers, (item, itemKey) => {
                                return (<option key={itemKey} value={item.answerKey}>{item.answerText}</option>)
                            })
    
                            let selectionPlaceholder = ""
                            switch(value.name) {
                                default: 
                                case 'expLevel': {
                                    selectionPlaceholder = "Select your trading experience"
                                    break
                                }
                                case 'tradingPurpose': {
                                    selectionPlaceholder = "Select a purpose"
                                    break
                                }
                            }
    
                            return (
                                <Form.Group key={key} controlId={value.id}>
                                    <Col xs={12} sm={12} md={12} lg={12} className="p-0">
                                        <Form.Label>{questionNo}. {value.question}</Form.Label>
                                        <Field name={value.name} type="text">
                                            {({ input, meta }) => (
                                                <>
                                                    <div 
                                                        className={`input-wrapper half ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                                        <Form.Control 
                                                            as="select" 
                                                            name={value.name} 
                                                            className={questionNo === 1 ? 'focus-on' : '' }
                                                            value={thisValue} 
                                                            onChange={(e) => this.handleSelectAnswer(e)}>
                                                            <option value="">-- select --</option>
                                                            {mappedAnswers}
                                                        </Form.Control>
    
                                                        { meta.error && meta.touched && 
                                                            <Form.Text>
                                                                {meta.error}
                                                            </Form.Text>
                                                        }
                                                    </div>
                                                </>
                                            )}
                                        </Field>
                                    </Col>
                                </Form.Group>
                            )
                        }
                    }
                } else {
                    return null
                }
            })
        }

        return (
            <>{mappedQuestions}</>
        )
    }

    render() {
        let { ...state } = this.state

        return (
            <FinalForm 
                onSubmit={this.onSubmit} 
                validate={this.onValidate} 
                decorators={[this.formDecorator]} 
                initialValues={{
                    ...state
                }} 
                render={({ handleSubmit }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <h1>Help Us To Know You More</h1>
                            <p className='sub-text'>Your answers will help us send you the right education and information about our platform</p>
                            
                            <div className='user-info-form'>
                                {this.renderQuestions()}
                            </div>

                            <p><FontAwesomeIcon icon={faInfoCircle} /> <small>Your privacy is our priority. All answers are confidential and we do not share with 3rd parties.</small></p>

                            <div className='user-info-form-actions'>
                                <button 
                                    type="submit" 
                                    className='submit-keys-btn'>
                                        Submit
                                    </button>

                                <button 
                                    type='button'
                                    className='skip-btn' 
                                    onClick={(e) => this.onSkip(e)}>
                                    No thanks
                                </button>
                            </div>
                        </Form>
                    )
                }} />
        )
    }
}

function mapStateToProps(state) {
    let { saveOnboardingUserTradingExpDetails } = state
    return {
        saveOnboardingUserTradingExpDetails
    }
}

const connectedTradingExpForm = connect(mapStateToProps)(TradingExpForm)
export { connectedTradingExpForm as TradingExpForm }