import styled from 'styled-components'
import { mediaQueries } from '../../constants/mediaQueries'

const getBg = (variant) => {
    switch(variant) {
        default: {
            return 'transparent linear-gradient(279deg, #FF3030 0%, #82C4FF 100%) 0% 0% no-repeat padding-box';
        }
    }
}

const getPosition = (pos) => {
    switch(pos) {
        default: return '25px auto';
        case 'left': return '25px auto 25px 0';
        case 'right': return '25px 0 25px auto';
    }
}

export const CheckoutButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    transition: all 0.5s ease 0s;
    width: auto;
    height: auto;
    margin: 25px auto;
    padding: 12px 30px;
    color: #fff;
    cursor: pointer;
    background: ${props => getBg(props.variant) };
    border: 3px solid rgba(255, 255, 255, 0.1);
    border-radius: 39.5px;
    text-align: center;

    &:hover {
        transform: scale(1.05);
        backface-visibility: hidden;
        overflow: auto;
    }

    ${mediaQueries.mediumDevices} {
        font-size: 17px;
        font-weight: 700;
        padding: 14px 30px;
    }

    ${mediaQueries.largeDevices} {
        font-size: 18px;
        font-weight: 700;
        padding: 10px 50px;
        margin: ${props => getPosition(props.position)};
    }

    ${mediaQueries.extraLargeDevices} {        
        padding: 15px 50px;
    }
`