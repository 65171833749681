import { tradeOrderActionTypes } from '../CONSTANTS'
import { tradeOrderServices } from '../../services'
import { isNotNull } from '../../utils'

function setLeverage (symbol, value) {
    return dispatch => {
        dispatch(request())

        tradeOrderServices.setLeverage(symbol, value)
            .then(response => {
                if (isNotNull(response)) {
                    if (isNotNull(response.success) && response.success)
                        dispatch(success(response))
                    else
                        dispatch(failure(response))
                } else
                    dispatch(failure(response))
            })
            .catch(error => dispatch(failure(error)))
    }

    function request() { return { type: tradeOrderActionTypes.SET_LEVERAGE_REQUEST } }
    function success(response) { return { type: tradeOrderActionTypes.SET_LEVERAGE_SUCCESS, response } }
    function failure(error) { return { type: tradeOrderActionTypes.SET_LEVERAGE_FAILURE, error}}
}

function clearLeverage() {
    return dispatch => {
        dispatch(resetLeverage())
    }

    function resetLeverage(response) { return { type: tradeOrderActionTypes.SET_LEVERAGE_CLEAR, response } }
}

function setMarginType (symbol, value) {
    return dispatch => {
        dispatch(request())

        tradeOrderServices.setMarginType(symbol, value)
            .then(response => {
                if (isNotNull(response)) {
                    if (isNotNull(response.success) && response.success)
                        dispatch(success(response))
                    else
                        dispatch(failure(response))
                } else
                    dispatch(failure(response))
            })
            .catch(error => dispatch(failure(error)))
    }

    function request() { return { type: tradeOrderActionTypes.SET_MARGIN_TYPE_REQUEST } }
    function success(response) { return { type: tradeOrderActionTypes.SET_MARGIN_TYPE_SUCCESS, response} }
    function failure(error) { return { type: tradeOrderActionTypes.SET_MARGIN_TYPE_FAILURE, error}}
}

function clearMarginType() {
    return dispatch => {
        dispatch(resetMarginType())
    }

    function resetMarginType(response) { return { type: tradeOrderActionTypes.SET_MARGIN_TYPE_CLEAR, response }}
}

function createWithLeverage(symbol, value, order) {
    return dispatch => {
        dispatch(request())

        tradeOrderServices.createWithLeverage(symbol, value, order)
            .then(response => {                
                if (isNotNull(response.success)) {
                    if (response.success)
                        dispatch(success(response))
                    else
                        dispatch(failure(response))
                } else {
                    if (isNotNull(response.error))
                        dispatch(failure(response.error))
                }
                dispatch(success(response))
            })
            .catch(error => dispatch(failure(error)))
    }

    function request() { return { type: tradeOrderActionTypes.CREATE_ORDER_WITH_LEVERAGE_REQUEST } }
    function success(response) { return { type: tradeOrderActionTypes.CREATE_ORDER_WITH_LEVERAGE_SUCCESS, response } }
    function failure(error) { return { type: tradeOrderActionTypes.CREATE_ORDER_WITH_LEVERAGE_FAILURE, error } }
}

function create(order) {
    return dispatch => {
        dispatch(request())

        tradeOrderServices.create(order)
            .then(response => {
                if (isNotNull(response.success)) {
                    if (response.success)
                        dispatch(success(response))
                    else
                        dispatch(failure(response.msg))
                } else {
                    if (isNotNull(response.error))
                        dispatch(failure(response.error))
                }
                dispatch(success(response))
            })
            .catch(error => dispatch(failure(error)))
    }

    function request() { return { type: tradeOrderActionTypes.CREATE_ORDER_REQUEST } }
    function success(response) { return { type: tradeOrderActionTypes.CREATE_ORDER_SUCCESS, response } }
    function failure(error) { return { type: tradeOrderActionTypes.CREATE_ORDER_FAILURE, error } }
}

function clear() {
    return dispatch => {
        dispatch(clearTradeOrder())
        dispatch(clearTradeOrderWithLeverage())
    }

    function clearTradeOrder(response) { return { type: tradeOrderActionTypes.CREATE_ORDER_CLEAR, response } }
    function clearTradeOrderWithLeverage(response) { return { type: tradeOrderActionTypes.CREATE_ORDER_WITH_LEVERAGE_CLEAR, response } }
}

export const tradeOrderActions = {
    setLeverage,
    clearLeverage,
    setMarginType,
    clearMarginType,
    createWithLeverage,
    create,
    clear
}