import styled from 'styled-components';

export const LoaderContentItem = styled.div`
    width: 100%;
    margin-bottom: 5px;
    height: 15px;

    &:first-child {
        width: 100px;
    }
    
    &:last-child {
        margin-bottom: 0;
    }
`;

export const LoaderContent = styled.div`
    width: calc(100% - 42px); 
`;

export const LoaderImg = styled.div`
    width: 32px;
    height: 32px;
    margin-right: 10px;
`;

export const LoaderWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 70px;
    padding: 0 10px;
    margin-bottom: 10px;

    &:first-child {
        margin-top: 10px;
    }

    &:last-child {
        border-bottom: 1px solid transparent;
    }
`;