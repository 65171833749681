import React from 'react'
import { Form } from 'react-bootstrap'
import { Form as FormFinal, Field } from "react-final-form"
import _ from 'lodash'
import VolumeField from './VolumeField'
import {
    QuickTradeWrapper, QuickTradeContainer, QuickTradeButtonWrapper,
    ConfirmOrder, QuickTradeInputWrapper, QuickTradeInput,
    QuickTradePrice, QuickTradeBidPrice, QuickTradeAskPrice,
    TradePrice
} from './styled_components'
import { isNull, priceFormat } from '../../utils'

export const QuickTradeView = (props) => {
    const { isTrade } = props.state
    const {
        orderType, exchange, coin, live,
        quickTradeBidPrice, quickTradeAskPrice,
        quickTradeLastPrice, sourceCoin } = props

    // const [] = React.useState(0)

    const onPriceHelper = (value, target) => {
        return target === "BTC"
            ? priceFormat(
                parseFloat(value * quickTradeBidPrice),
                parseFloat(value * quickTradeBidPrice),
                "BTCUSDT").startPrice
            : _.round(parseFloat(value / quickTradeBidPrice), 8)
    }

    const onSubmitHandler = async data => {
        props.self.setState({ isTrade: true })
        let amount = 0
        if (sourceCoin === "USDT")
            amount = onPriceHelper(data.volume, sourceCoin)
        if (sourceCoin === "BTC")
            amount = data.volume

        const requestData = {
            order_type: data.order_type,
            exchange: data.exchange,
            coin: data.coin,
            volume: amount,
            auto_volume: data.auto_volume,
            entry_price: data.entry_price,
            stop_price: data.stop_price,
            relative_stop_price: data.relative_stop_price,
            bot_multiplier: data.bot_multiplier,
            stop_delay: data.stop_delay,
            expiry: data.expiry,
            live: data.live
        }
        props.confirmOrder(requestData)
    }

    const onValidateHandler = data => {
        let error = {}
        let count = 0
        const balance = parseFloat(quickTradeLastPrice)

        if (isNull(data.volume))
            error.volume = "Amount is required"
        else if (isNaN(data.volume))
            error.volume = "Please enter a valid amount!"
        else {
            for (var x = 0; x < data.volume.length; x++) {
                if (data.volume.charAt(x) === ".")
                    count += 1
            }

            //Check Available Balance
            if (balance === 0 || balance < 0)
                error.volume = "You don't have enough balance!"
            else {
                //Check User Input
                let userValue = parseFloat(data.volume)
                if (userValue === 0 || userValue < 0 || count > 1)
                    error.volume = "Please enter a valid amount!"
                else if (userValue > balance)
                    error.volume = "Cannot enter amount greater than the balance!"
                else {
                    if (sourceCoin === "BTC" && (userValue * quickTradeBidPrice) < 11)
                        error.volume = "Amount must be greater than or equal 11 USD"

                    if (sourceCoin === "USDT" && userValue < 11)
                        error.volume = "Amount must be greater than or equal 11 UDST"
                }
            }
        }


        return error
    }

    const renderHiddenInputFields = () => {
        return (
            <>
                <Field name="order_type" type="hidden">
                    {({ input }) => (
                        <Form.Control
                            {...input}
                        />
                    )}
                </Field>
                <Field name="exchange" type="hidden">
                    {({ input }) => (
                        <Form.Control
                            {...input}
                        />
                    )}
                </Field>
                <Field name="coin" type="hidden">
                    {({ input }) => (
                        <Form.Control
                            {...input}
                        />
                    )}
                </Field>
                <Field name="auto_volume" type="hidden">
                    {({ input }) => (
                        <Form.Control
                            {...input}
                        />
                    )}
                </Field>
                <Field name="entry_price" type="hidden">
                    {({ input }) => (
                        <Form.Control
                            {...input}
                        />
                    )}
                </Field>
                <Field name="stop_price" type="hidden">
                    {({ input }) => (
                        <Form.Control
                            {...input}
                        />
                    )}
                </Field>
                <Field name="relative_stop_price" type="hidden">
                    {({ input }) => (
                        <Form.Control
                            {...input}
                        />
                    )}
                </Field>
                <Field name="bot_multiplier" type="hidden">
                    {({ input }) => (
                        <Form.Control
                            {...input}
                        />
                    )}
                </Field>
                <Field name="stop_delay" type="hidden">
                    {({ input }) => (
                        <Form.Control
                            {...input}
                        />
                    )}
                </Field>
                <Field name="expiry" type="hidden">
                    {({ input }) => (
                        <Form.Control
                            {...input}
                        />
                    )}
                </Field>
                <Field name="live" type="hidden">
                    {({ input }) => (
                        <Form.Control
                            {...input}
                        />
                    )}
                </Field>
            </>
        )
    }

    const renderBidAskPrices = () => {
        return (
            <QuickTradeContainer>
                <QuickTradePrice>
                    <QuickTradeBidPrice className="quick-trade-price bid">
                        <TradePrice className="bid-trade-price">{quickTradeBidPrice}</TradePrice>
                        <span className="bid-price">BID</span>
                    </QuickTradeBidPrice>
                    <QuickTradeAskPrice className="quick-trade-price ask">
                        <TradePrice className="ask-trade-price">{quickTradeAskPrice}</TradePrice>
                        <span className="ask-price">ASK</span>
                    </QuickTradeAskPrice>
                </QuickTradePrice>
            </QuickTradeContainer>
        )
    }

    const renderFormInputs = () => {
        return (
            <QuickTradeContainer>
                <QuickTradeInputWrapper>
                    <QuickTradeInput>
                        {renderHiddenInputFields()}
                        <VolumeField
                            {...props}
                        />
                    </QuickTradeInput>
                </QuickTradeInputWrapper>
            </QuickTradeContainer>
        )
    }

    const renderConfirmOrder = (pristine) => {
        return (
            <QuickTradeContainer>
                <QuickTradeButtonWrapper>
                    {
                        isTrade === false
                            ? <ConfirmOrder type="submit" className="btn btn-default" disabled={pristine}>Confirm Order</ConfirmOrder>
                            : <ConfirmOrder className="btn btn-default" disabled={pristine}><i className="fas fa-spinner fa-pulse"></i></ConfirmOrder>
                    }
                </QuickTradeButtonWrapper>
            </QuickTradeContainer>
        )
    }

    return (
        <QuickTradeWrapper>
            <FormFinal
                onSubmit={onSubmitHandler}
                validate={onValidateHandler}
                initialValues={{
                    order_type: orderType,
                    exchange: exchange,
                    coin: coin,
                    volume: 0,
                    auto_volume: false,
                    entry_price: "",
                    stop_price: "",
                    relative_stop_price: "",
                    bot_multiplier: "",
                    stop_delay: 0,
                    expiry: "",
                    live: live,
                }}
                render={({ handleSubmit, form, values, pristine }) => (
                    <Form onSubmit={(event) => { const promise = handleSubmit(event); promise && promise.then(() => { form.reset(values); }); return promise; }} autoComplete="off">
                        {renderBidAskPrices()}
                        {renderFormInputs()}
                        {renderConfirmOrder(pristine)}
                    </Form>
                )}
            />
        </QuickTradeWrapper>
    )
}