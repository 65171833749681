import styled from 'styled-components'
import { mediaQueries } from '../../constants/mediaQueries'

export const TopTradesList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    border: 2px solid #18283c;
    box-shadow: 0px 3px 18px #00000061;
    border-radius: 9px;
    min-height: 200px;

    .header {
        width: 100%;
        padding: 15px;

        h3 {
            font-size: 14px;
            color: #fff;
            margin-bottom: 0;
        }
    }

    .closed-trades-list {
        width: 100%;
        list-style-type: none;
        padding: 0;
        margin: 0;
        height: 254px;
        overflow: hidden;

        .closed-trades-list-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 4px 15px 4px 10px;

            .symbol-logo {
                background-color: #1E3540;
                border-radius: 8px;
                padding: 2px;
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                margin-right: 10px;

                img {
                    margin: 0 auto;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    overflow: hidden;
                }
            }

            .trade-details {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                width: 100%;

                .symbol,
                .peak-gains-val {
                    font-size: 13px;
                }

                .symbol {
                    color: #fff;
                    font-weight: 400;
                    width: 124px;
                }

                .peak-gains-val {
                    font-weight: 500;
                    color: #15bd6f;
                    letter-spacing: 1px;
                }

                .duration {
                    font-size: 12px;
                    font-style: italic;
                    margin-left: auto;
                    width: 85px;
                    text-align: right;
                }
            }

            &:nth-child(odd) {
                background-color: rgba(0,0,0,0.2);
            }

            &:last-child {
                padding-bottom: 12px;
            }
        }

        &.full {
            height: auto;
        }
    }

    ${mediaQueries.mediumDevices} {
        .closed-trades-list {
            height: 324px;

            .closed-trades-list-item {
                padding: 4px 10px;

                .trade-details {
                    .symbol {
                        width: 95px;
                        font-size: 11px;
                    }
                }
            }

            &.full {
                height: 615px;
            }
        }
    }

    ${mediaQueries.largeDevices} {
        .closed-trades-list {
            height: 254px;

            .closed-trades-list-item {
                padding: 4px 15px 4px 10px;
            }

            &.full {
                height: 549px;
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        .closed-trades-list {
            .closed-trades-list-item {
                .trade-details {
                    .symbol {
                        width: 124px;
                        font-size: 13px;
                    }
                }
            }
        }
    }
`