import React, { useEffect } from 'react'
import { Form, InputGroup } from "react-bootstrap"
import { Form as FinalForm, Field } from "react-final-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle, faEnvelope, faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"

import { Button } from '../../components'
import { FormContainer, FormQuickLinks } from '../../components/Auth'
import { Brand, Layout, Error } from '../../styles'
import { emailValidator, isNotNull, sleep, addPageViewEvent, seo } from "../../utils"

export const ForgotPasswordView = props => {    
    const $state = props.state
    
    useEffect(() => {
        document.body.classList.add('authentication')
    }, [])

    const onSendPasswordHandler = async values => {

        await sleep(300)

        const { resetEmail } = values

        if (isNotNull(resetEmail))
            props.onSubmitForgotPasswordHandler(resetEmail)
    }

    const onValidateFormHandler = values => {

        let errors = {}
        let resetEmail = []

        if (!emailValidator.isValid(values.resetEmail))
            resetEmail.push("Please enter a valid email address")

        if (resetEmail.length > 0)
            errors.resetEmail = resetEmail

        return errors
    }

    const renderForgotPasswordForm = () => {
        return (
            <FinalForm
                onSubmit={onSendPasswordHandler}
                validate={onValidateFormHandler}
                decorators={[props.decorators]}
                initialValues={{ resetEmail: $state.resetEmail }}
                render={({ handleSubmit, submitting }) => (
                    <Form onSubmit={handleSubmit} noValidate style={{ minWidth: '293px'}}>
                        <Form.Group controlId="formForgotPassword">
                            <Field name="resetEmail" type="email">
                                {({ input, meta, type }) => (
                                    <>
                                        <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                            <InputGroup>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faEnvelope} className="icon" />
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control
                                                    type={type}
                                                    placeholder="Enter your email address"
                                                    autoComplete="off"
                                                    {...input}
                                                />
                                            </InputGroup>
                                        </div>

                                        <Error className={`${meta.error && meta.touched ? 'invalid' : ''}`}>
                                            {meta.error && meta.touched && (
                                                <>
                                                    <FontAwesomeIcon icon={faExclamationCircle} className="icon" />
                                                    <span>{meta.error}</span>
                                                </>
                                            )}
                                        </Error>
                                    </>
                                )}
                            </Field>
                        </Form.Group>

                        <Button type="submit" className="auth-btn" disabled={submitting || $state.isResetEmailLoading} variant="primary">
                            <span>{$state.isResetEmailLoading ? 'Please wait..' : 'Reset password'}</span>
                        </Button>
                    </Form>
                )}
            />
        )
    }

    return (
        <Layout>
            <FormContainer>
                <Brand imageUrl={`/images/brand/pf-logo.png`} />
                
                <h1 className='title'>
                    <strong>Forgot password?</strong>
                </h1>

                <p className='description'>No worries, we'll send you reset instructions</p>

                {renderForgotPasswordForm()}                
                
                <FormQuickLinks>
                    <Link to={`/account/login`}>
                        <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: '8px'}} />
                        <span>Back to login</span>
                    </Link>
                </FormQuickLinks>
            </FormContainer>
        </Layout>
    )

}