import styled from 'styled-components';

export const SurveyBody = styled.div`
    padding: 30px 40px !important;
    

    h4 {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.8;
        color: #adc4df;
    }
`

export const SurveyFooter = styled.div`
    padding: 16px !important;

    button {
        background-color: #27323e !important;
        border: 1px solid #27323e !important;

        &.btn-submit {
            background-color: #993956cc !important;
            border: 1px solid #993956 !important;

            &:hover:enabled {
                background-color: #993956 !important;
                border-color: #993956 !important;
                box-shadow: none;
            }
        }

        &:disabled {
            cursor: not-allowed;
        }

        &:hover:enabled {
            background-color: #0E1824 !important;
            border-color: #0E1824 !important;
            box-shadow: none;
        }
    }
`

export const SurveyQuestionWrapper = styled.div`
    .option-wrapper {
        width: 100%;
        padding: 10px 10px 0 20px;
        max-height: 60vh;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
            background-color: #39506b;
            outline: 1px solid #383e4e;
            /* border-radius: 10px; */
        }

        .MuiFormGroup-root {
            label {
                margin-bottom: 0;
            }

            .MuiIconButton-colorSecondary {
                &.Mui-checked {
                    color: #16b46c !important;
                }

                &:hover {
                    background-color: rgb(22 180 108 / 4%) !important;
                }
            }

            .MuiTypography-body1 {
                letter-spacing: 1.5px;
            }
            
            .survey-input-wrapper {
                display: flex;
                align-items: center;
                column-gap: 15px;
                padding-left: 32px;
                margin-bottom: 20px;
                font-size: 14px;

                label {
                    margin-bottom: 0px;
                }

                input {
                    min-width: 250px;
                }

                textarea {
                    width: 100%;
                }

                input, textarea {
                    background-color: #17212D !important;
                    border: 1px solid #39506b !important;
                    border-radius: 2px;
                    color: #fff;
                    letter-spacing: 1.5px;
                }
            }
        }
    }

    .billing {
        p {
            margin-bottom: 0%;
        }
    }
`

export const SurveyQuestion = styled.h4`

`

export const SurveyButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
`

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    a {
        color: #7a96b7;
    }
`

export const SurveyWrapper = styled.div`
    overflow: hidden;
    letter-spacing: 1.5px;

    .modal-header {
        border-bottom: 8px solid #39506b !important;
        padding: 60px;
        position: relative;
        align-items: center;

        .title {
            display: block;
            width: 100%;
            text-align: center;
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 320px) and (max-width: 766px) {
        .modal-header {
            .title {
                font-size: 16px;
            }
        }

        .modal-body {
            padding: 16px !important;

            h4 {
                font-size: 14px;
            }

            .option-wrapper {
                padding: 10px 0;

                .MuiFormGroup-root {
                    .MuiTypography-body1 {
                        font-size: 12px;
                    }

                    .survey-input-wrapper{
                        flex-direction: column;
                        align-items: initial;
                        row-gap: 10px;

                        input {
                            min-width: auto;
                        }
                    }
                }
            }
        }

        .modal-footer {
            button {
                width: 100%;
            }

            ${SurveyButtonWrapper} {
                flex-direction: column;
                row-gap: 10px;
                width: 100%;

                a {
                    text-align: center;
                }

                ${ButtonContainer} {
                    width: 100%;
                }
            }
        }
    }
`