import React from 'react'
import { Image } from 'react-bootstrap'
import _ from 'lodash'
import {
    WalletItems, Wallet,
    WalletCurrency, WalletCoin,
    WalletBalance, WalletCoinWrapper
} from './styled_components'
import { isNotNull, setCoinLogo, setDefaultImage, priceFormat, ScientificToDecimal } from '../../utils'

export class WalletItem extends React.PureComponent {
    render() {
        const { name } = this.props
        const balance = isNotNull(this.props.balance) ? this.props.balance : { free: 0, usdt: 0 }
        const bal = _.round(parseFloat(balance.free), 8)
        const usdt = priceFormat(parseFloat(balance.free), parseFloat(balance.free), "USDT")
        const price_checker = (n) => {
            var result = (n - Math.floor(n)) !== 0
            if (result)
                return false
            else
                return true
        }

        let newPrices = 0
        let result = price_checker(usdt.startPrice)
        if (result)
            newPrices = parseFloat(usdt.startPrice).toFixed(2)
        else
            newPrices = usdt.startPrice
            
        return (
            <WalletItems className="theme-wallet-items-wrapper">
                <Wallet className={`${name === "BTC" ? "BTC" : (name === "USDT" ? "USDT" : "")} theme-wallet-items`}>
                    {
                        name === "USDT" &&
                        <WalletCurrency>
                            <WalletCoinWrapper className="theme-wallet-coin">
                                <Image className="wallet-item-logo img-fluid" src={`https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/usdt.png`} onError={(e) => setDefaultImage(e)} fluid roundedCircle />
                                <WalletCoin>{name}</WalletCoin>
                            </WalletCoinWrapper>
                            <div className="balance-text first">
                                <WalletBalance>{parseFloat(newPrices) <= 0 ? "0.00" : ScientificToDecimal(parseFloat(newPrices))}<span className="coinName">{name}</span></WalletBalance>
                            </div>
                        </WalletCurrency>
                    }
                    {
                        name !== "USDT" &&
                        <>
                            <WalletCurrency>
                                <WalletCoinWrapper className="theme-wallet-coin">
                                    <Image className="wallet-item-logo img-fluid" src={setCoinLogo(name)} onError={(e) => setDefaultImage(e)} fluid roundedCircle />
                                    <WalletCoin>{name}</WalletCoin>
                                </WalletCoinWrapper>
                                <div className="balance-text first">
                                    <WalletBalance>{bal < 0 ? 0 : ScientificToDecimal(bal)}<span className="coinName">{name}</span></WalletBalance>
                                </div>
                                <div className="balance-text">
                                    <WalletBalance className="bal-usdt">
                                        <span className={`usdt-${name}`}>{isNotNull(balance.usdt) ? balance.usdt > 0 ? ScientificToDecimal(balance.usdt) : "0.00" : "0.00"}</span> USDT
                                    </WalletBalance>
                                </div>
                            </WalletCurrency>
                        </>
                    }
                </Wallet>
            </WalletItems>
        )
    }
}