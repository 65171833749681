import styled from 'styled-components'
import { mediaQueries } from '../../constants/mediaQueries'

export const TopWinnersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    ${mediaQueries.mediumDevices} {
        width: 40%;        
    }

    ${mediaQueries.largeDevices} {
        width: 35%;        
    }
`