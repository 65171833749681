import { leadActionTypes } from '../CONSTANTS'
import { leadServices } from '../../services'
import { isNotNull, encryptText } from '../../utils'

function leadChangePasswordClear() {
    return dispatch => {
        dispatch({ type: leadActionTypes.LEAD_CHANGE_PASSWORD_CLEAR });
    }
}

function leadChangePassword(data) {
    return dispatch => {
        dispatch(request())
        
        if (isNotNull(data.oldPassword))
            data.oldPassword = encryptText(data.oldPassword)

        if (isNotNull(data.newPassword))
            data.newPassword = encryptText(data.newPassword)

        if (isNotNull(data.confirmPassword))
            data.confirmPassword = encryptText(data.confirmPassword)

        leadServices.leadChangePassword(data).then(response => {
            if (typeof response.success != 'undefined') {
                if (!response.success)
                    dispatch(failure(response))
                else
                    dispatch(success(response))
            } else {
                if (typeof response.errors != 'undefined') {
                    dispatch(failure(response.errors))
                }
            }
        }).catch(error => {
            dispatch(failure(error))
        })
    }
    function request() { return { type: leadActionTypes.LEAD_CHANGE_PASSWORD_REQUEST } }
    function success(response) { return { type: leadActionTypes.LEAD_CHANGE_PASSWORD_SUCCESS, response } }
    function failure(error) { return { type: leadActionTypes.LEAD_CHANGE_PASSWORD_FAILURE, error } }
}

export const leadActions = {
    leadChangePasswordClear,
    leadChangePassword
}