import React from 'react'
import { Overlay, Popover } from 'react-bootstrap'
import { PasswordHelperWrapper } from './stlyed_components'
import { isNotNull } from '../../utils'

class PasswordHelper extends React.Component {

    render() {
        const { show, target, container, placement, data, confirm } = this.props
        return (
            <Overlay
                show={show}
                target={target}
                container={container}
                placement={placement}>

                <PasswordHelperWrapper as={Popover}
                    className="pass-helper">
                    <Popover.Title className="pass-helper-title">
                        <span>Password must meet the following requirements:</span>
                    </Popover.Title>
                    <Popover.Content className="pass-helper-body">
                        <ul className="pass-helper-criteria-list">
                            <li>
                                <span className="pass-helper-icons">
                                    {
                                        isNotNull(data) && isNotNull(data.letter)
                                            ? !data.letter
                                                ? <i className="fas fa-times error"></i>
                                                : <i className="fas fa-check success"></i>
                                            : <i className="fas fa-times error"></i>
                                    }
                                </span>
                                <span className="pass-helper-text">At least <strong>one lowercase</strong></span>
                            </li>
                            <li>
                                <span className="pass-helper-icons">
                                    {
                                        isNotNull(data) && isNotNull(data.capital)
                                            ? !data.capital
                                                ? <i className="fas fa-times error"></i>
                                                : <i className="fas fa-check success"></i>
                                            : <i className="fas fa-times error"></i>
                                    }
                                </span>
                                <span className="pass-helper-text">At least <strong>one uppercase</strong></span>
                            </li>
                            <li>
                                <span className="pass-helper-icons">
                                    {
                                        isNotNull(data) && isNotNull(data.number)
                                            ? !data.number
                                                ? <i className="fas fa-times error"></i>
                                                : <i className="fas fa-check success"></i>
                                            : <i className="fas fa-times error"></i>
                                    }
                                </span>
                                <span className="pass-helper-text">At least <strong>one number</strong></span>
                            </li>
                            <li>
                                <span className="pass-helper-icons">
                                    {
                                        isNotNull(data) && isNotNull(data.special)
                                            ? !data.special
                                                ? <i className="fas fa-times error"></i>
                                                : <i className="fas fa-check success"></i>
                                            : <i className="fas fa-times error"></i>
                                    }
                                </span>
                                <span className="pass-helper-text">At least <strong>one special character</strong></span>
                            </li>
                            <li>
                                <span className="pass-helper-icons">
                                    {
                                        isNotNull(data) && isNotNull(data.size)
                                            ? !data.size
                                                ? <i className="fas fa-times error"></i>
                                                : <i className="fas fa-check success"></i>
                                            : <i className="fas fa-times error"></i>
                                    }
                                </span>
                                <span className="pass-helper-text">Be at least <strong>6-12 characters</strong></span>
                            </li>
                            {
                                isNotNull(confirm) && confirm &&
                                <li>
                                    <span className="pass-helper-icons">
                                        {
                                            isNotNull(data) && isNotNull(data.match)
                                                ? !data.match
                                                    ? <i className="fas fa-times error"></i>
                                                    : <i className="fas fa-check success"></i>
                                                : <i className="fas fa-times error"></i>
                                        }
                                    </span>
                                    <span className="pass-helper-text">Password must match</span>
                                </li>
                            }
                        </ul>
                    </Popover.Content>
                </PasswordHelperWrapper>

            </Overlay>
        )
    }
}

export {
    PasswordHelper
}
