import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import iziToast from 'izitoast'
import moment from 'moment'
import { Popover, Overlay, SafeAnchor } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { BellContentWrapper } from './BellStyle'
import { SymbolIcon, OrderIcon, NotificationList } from '../../styles'
import { fetchToken, onMessageListener } from '../../firebase'
import { notificationActionTypes } from '../../redux/CONSTANTS'
import { notificationActions } from '../../redux/actions'
import { notiTypes } from '../../constants/notificationTypeConstants'
import { 
    isNull,
    isNotNull, 
    onSetNewState, 
    getCoin, 
    setCoinLogo, 
    setDefaultImage, 
    getSpecificHistoryTime,
    isSupported } from '../../utils'

class Bell extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isTokenFound: false,
            isLoading: true,
            isToRefresh: false,
            isAddingToBell: false,
            isAddingToBrowser: false,
            hasInitNotifPermission: false,
            hasDesktopNotificationSupport: false,
            hasInitNotifSettings: false,
            hasSubscription: null,
            hasAllowSound: false,
            isToRecount: false,
            totalUnreadMsgs: 0,
            items: [],
            browserItems: [],
            isToMarkAll: null,
            selectedId: null,
            show: false,
            targetEl: null
        }

        this.baseState = this.state
        this.mounted = false
        this.element = React.createRef()
        this.newSignalAudio = document.getElementById('new-signal-audio')
        this.closeSignalAudio = document.getElementById('closed-signal-audio')
    }

    async componentDidMount() {
        this.mounted = true
        const { 
            dispatch, 
            isTokenFound, 
            setTokenFound, 
            notifications, 
            notificationLists } = this.props

        if (isSupported()) {
            if (!isTokenFound) {         
                let token = await fetchToken(setTokenFound)
                if (isNotNull(token))
                    dispatch(notificationActions.allowNotifications(token))
                else
                    setTokenFound(false)
            }

            onSetNewState(this, {
                hasDesktopNotificationSupport: true
            })  
        }        

        if (isNull(notifications.loading))
            dispatch(notificationActions.getNotificationSettings())
            
        if (isNull(notificationLists.loader))
            dispatch(notificationActions.getNotificationLists())
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.mounted) {
            const { 
                isLoading,
                hasInitNotifPermission, 
                hasDesktopNotificationSupport, 
                hasInitNotifSettings, 
                hasSubscription,
                isToRecount,
                items,
                isToMarkAll } = this.state
            const { 
                notifications, 
                notificationLists, 
                markedNotification,
                subscriptionDetails, 
                dispatch } = this.props

            if (!hasInitNotifPermission) {
                onSetNewState(this, {
                    hasInitNotifPermission: true
                })

                if (hasDesktopNotificationSupport && isNotNull(Notification)) {
                    if (Notification.permission !== 'granted')
                        Notification.requestPermission()
                }
            }

            // user notification settings
            if (isNotNull(notifications) && 
                !notifications.loading && 
                !hasInitNotifSettings) {
                    const { notification_settings } = notifications
                    if (isNotNull(notification_settings)) {
                        const { data } = notification_settings
                        if (isNotNull(data)) {
                            onSetNewState(this, {
                                hasInitNotifSettings: true,
                                hasAllowSound: data.allowSoundOnPush
                            })
                        }
                    }
                }

            // user subscription details
            if (isNull(hasSubscription) && 
                isNotNull(subscriptionDetails) && 
                !subscriptionDetails.loading) {
                    const { subscription } = subscriptionDetails
                    if (isNotNull(subscription)) {                        
                        onSetNewState(this, {
                            hasSubscription: true
                        })
                    } else {
                        onSetNewState(this, {
                            hasSubscription: false
                        })
                    }
            }
            
            // user notifications
            if (isNotNull(notificationLists) && 
                isLoading) {
                    const { notification_lists } = notificationLists
                    if (isNotNull(notification_lists)) {
                        const { data } = notification_lists
                        let unReadMsgs = null
                        let totalUnreadMsgs = null

                        if (isNotNull(data)) {
                            unReadMsgs = _.countBy(data, f => f.isRead === false)
                            totalUnreadMsgs = isNotNull(unReadMsgs) ? unReadMsgs.true : 0
                        }
                        
                        onSetNewState(this, {
                            isLoading: false,
                            items: data,
                            totalUnreadMsgs
                        })
                    }


            }
            
            if (isNotNull(notificationLists) && 
                !isLoading && 
                prevProps.notificationLists !== notificationLists) {
                    if (isNull(items)) {                        
                        onSetNewState(this, {
                            isLoading: true
                        })
                    } 

                    if (isNotNull(items)) {
                        const { notification_lists } = notificationLists
                        const { data } = notification_lists
                        let unReadMsgs = null
                        let totalUnreadMsgs = null

                        if (isNotNull(data)) {
                            unReadMsgs = _.countBy(data, f => f.isRead === false)
                            totalUnreadMsgs = isNotNull(unReadMsgs) ? unReadMsgs.true : 0
                        }
                        
                        onSetNewState(this, {
                            isLoading: false,
                            items: data,
                            totalUnreadMsgs
                        })
                    }
                }

            // mark notification as read
            if (isNotNull(markedNotification) && 
                !markedNotification.loading && 
                isNotNull(markedNotification.item) && 
                markedNotification.item.success) {
                    if (isNotNull(notificationLists) && 
                        isNotNull(notificationLists.notification_lists) && 
                        isNotNull(notificationLists.notification_lists.data)) {
                            let notificationItems = [...notificationLists.notification_lists.data]
                            if (isToMarkAll) {
                                _.forEach(notificationItems, n => {
                                    if (n.type !== notiTypes.MANUALLY_CLOSED)
                                        n.isRead = true
                                })
                            } else {
                                _.forEach(notificationItems, n => {
                                    if (n.id === this.state.selectedId)
                                        n.isRead = true
                                })
                            }

                            let notification_lists = {
                                data: notificationItems
                            }
                            dispatch({ type: notificationActionTypes.GET_ALL_NOTIFICATION_LISTS_SUCCESS, notification_lists })
                            dispatch({ type: notificationActionTypes.CLEAR_MARKED_NOTIFICATION })
                            onSetNewState(this, { selectedId: null, isToMarkAll: false, totalUnreadMsgs: 0 })
                    }
                }

            if (isNotNull(hasSubscription) && 
                hasInitNotifSettings) {

                onMessageListener().then(payload => {
                    this.showNotification(payload)
                }).catch(err => console.log('failed: ', err))                
        
                if ("serviceWorker" in navigator) {
                    navigator.serviceWorker.addEventListener('message', (evt) => {
                        var msg = evt.data
                        if (msg === 'reloadnotif') {                                       
                            const { hasAllowSound } = this.state
                            if (hasAllowSound) {
                                this.newSignalAudio.muted = false
                                this.newSignalAudio.play()
                            }

                            onSetNewState(this, {
                                isLoading: true
                            }, () => {
                                dispatch(notificationActions.getNotificationLists())
                            })
                        }
                    })
                }
            }

            if (isToRecount) {
                let unReadMsgs = _.countBy(items, f => f.isRead === false)
                let totalUnreadMsgs = isNotNull(unReadMsgs) ? unReadMsgs.true : 0

                if (parseInt(totalUnreadMsgs) !== parseInt(this.state.totalUnreadMsgs)) {
                    onSetNewState(this, {
                        totalUnreadMsgs,
                        isToRecount: false
                    })
                }
            }

            // if (prevState.show !== show && show && !isLoading && isToRefresh) {
            //     onSetNewState(this, {
            //         isLoading: true,
            //         isToRefresh: false
            //     }, () => {
            //         dispatch(notificationActions.getNotificationLists())
            //     })
            // }
        }
    }

    componentWillUnmount() {
        this.mounted = false
        this.setState(this.baseState)
    }

    markNotificationAsRead = (id) => {
        onSetNewState(this, {
            selectedId: id
        }, () => {
            const { dispatch } = this.props
            const requestData = {
                'notification_id': id,
                'isMarkedAll': false
            }
            dispatch(notificationActions.markNotificationAsRead(requestData))
        })
    } 

    handleShow = event => onSetNewState(this, { targetEl: event.target.parentElement, show: !this.state.show, isToRefresh: true })

    handleHide = () => onSetNewState(this, {
        show: false
    })

    handleView = (e, notif) => {
        e.preventDefault()

        if (notif.isRead) 
            this.viewNotification(notif)
        else {
            this.markNotificationAsRead(notif.id)

            setTimeout(() => {            
                this.viewNotification(notif)
            }, 1000)   
        }

        this.handleHide()
    }

    handleMarkAllAsRead = (e) => {
        e.preventDefault()

        onSetNewState(this, {
            isToMarkAll: true
        }, () => {
            const { totalUnreadMsgs } = this.state
            if (isNotNull(totalUnreadMsgs) && totalUnreadMsgs > 0) {            
                const { dispatch } = this.props
                const requestData = {
                    'notification_id': 'ALL',
                    'isMarkedAll': true
                }
                dispatch(notificationActions.markNotificationAsRead(requestData))
            }
        })
    }

    handleBellNotification = (data) => {
        if (isNotNull(data)) {
            let logoSrc = null

            switch (data.type) {
                default: {
                    logoSrc = getCoin(data.symbol)
                    logoSrc = `https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/${logoSrc}.png`

                    break
                }
                case 5: {
                    logoSrc = 'https://pf-icons.s3.eu-west-2.amazonaws.com/event-icon.png'
                    break
                }
                case 6: {
                    logoSrc = 'https://pf-icons.s3.eu-west-2.amazonaws.com/success-icon.png'
                    break
                }
                case 7: {
                    logoSrc = 'https://pf-icons.s3.eu-west-2.amazonaws.com/failed-icon.png'
                    break
                }
            }
            
            iziToast.show({
                id: data.id,
                class: "notifToast single",
                timeout: data.type === notiTypes.ORDER_FAILED ? false : 5000,
                theme: 'dark',
                title: `${data.message}`,
                close: true,
                progressBar: true,
                animateInside: true,
                drag: false,
                displayMode: 1,
                message: moment.utc(data.dateCreated).local().format('lll'),
                transitionIn: 'fadeInRight',
                transitionOut: 'flipOutX',
                position: window.innerWidth >= 765 ? 'topRight' : 'topCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
                iconUrl: logoSrc,
                zindex: 999,
                buttons: data.type === notiTypes.ORDER_FAILED 
                    ? [['<button>View</button>', (() => {
                            this.props.onReviewOrderResultItem(data)
                        })]] 
                    : [],
                onClosed: () => {
                    if (data.type === notiTypes.ORDER_FAILED) {
                        this.markNotificationAsRead(data.id)
                    }
                }
            })
        }
    }

    viewNotification = (notif) => {
        let params = []
        for (let p in notif) {
            if (notif.hasOwnProperty(p)) {
                params.push(encodeURIComponent(p) + "=" + encodeURIComponent(notif[p]))
            }
        }
        params = params.join("&")

        const { sourcePage, history } = this.props
        if (isNotNull(sourcePage) && sourcePage === "any") {
            switch (notif.type) {
                default:
                case notiTypes.NEW_SIGNAL:
                case notiTypes.STOP_LOSS_HIT:
                case notiTypes.TARGET_HIT:
                case notiTypes.ALL_TARGETS_HIT: {
                    history.push(`/signals?sId=${notif.actorId}&isRead=${notif.isRead}&id=${notif.id}&actn=view`)
                    
                    const currrentPath = window.location.pathname
                    if (isNotNull(currrentPath)) {
                        if (currrentPath === '/signals') {
                            this.props.notificationItemPreview(true)               
                        }
                    }
                    break
                }
                case notiTypes.MANUALLY_CLOSED: {
                    this.props.onReviewEarlyClosedItem(notif)
                    break
                }
                case notiTypes.AUTO_CLOSE_EARLY: {
                    this.props.onReviewAutoEarlyClosedItem(notif)
                    break
                }
                case notiTypes.ORDER_SUCCEEDED:
                case notiTypes.ORDER_FAILED: {
                    this.props.onReviewOrderResultItem(notif)
                    break
                }
            } 
        } else {            
            switch (notif.type) {
                default:
                case notiTypes.NEW_SIGNAL:
                case notiTypes.STOP_LOSS_HIT:
                case notiTypes.TARGET_HIT:
                case notiTypes.ALL_TARGETS_HIT: {
                    history.push(`/signals?sId=${notif.actorId}&isRead=${notif.isRead}&id=${notif.id}&actn=view`)
                    break
                }
                case notiTypes.MANUALLY_CLOSED: {
                    history.push(`/signals?${params}&actn=mc`)
                    break
                }
                case notiTypes.AUTO_CLOSE_EARLY: {
                    history.push(`/signals?${params}&actn=tg`)
                    break
                }
                case notiTypes.ORDER_SUCCEEDED:
                case notiTypes.ORDER_FAILED: {
                    history.push(`/signals?${params}&actn=tr`)
                    break
                }
            } 
        }   
    }

    onViewInMessageBoard = () => window.location.href = "/messages/board"

    showNotification = (payload) => {
        onSetNewState(this, {
            isLoading: true
        }, () => {            
            const { dispatch } = this.props
            dispatch(notificationActions.getNotificationLists())
        })

        const { notification, data } = payload  
        const currrentPath = window.location.pathname 
        const isMessageBoardNotif = data.type === "9"       

        let logoSrc = null
        switch (data.type) {
            default: {
                logoSrc = `https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/${data.toSym.toLowerCase()}.png`
                break
            }
            case "5": {
                logoSrc = 'https://pf-icons.s3.eu-west-2.amazonaws.com/event-icon.png'
                break
            }
            case "6": {
                logoSrc = 'https://pf-icons.s3.eu-west-2.amazonaws.com/success-icon.png'
                break
            }
            case "7": {
                logoSrc = 'https://pf-icons.s3.eu-west-2.amazonaws.com/failed-icon.png'
                break
            }
            case "9": {
                logoSrc = `https://pf-icons.s3.eu-west-2.amazonaws.com/new-msg-icon.png`
                break
            }
        }        
        
        iziToast.show({
            id: payload.messageId,
            class: "notifToast single",
            timeout: isMessageBoardNotif && currrentPath !== '/messages/board' ? false : 5000,
            theme: 'dark',
            title: isMessageBoardNotif ? "New Message" : `${notification.title}`,
            close: isMessageBoardNotif ? true : false,
            progressBar: true,
            animateInside: true,
            drag: false,
            displayMode: 1,
            message: isMessageBoardNotif ? notification.title : notification.body,
            transitionIn: 'fadeInRight',
            transitionOut: 'flipOutX',
            position: window.innerWidth >= 765 ? 'topRight' : 'topCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
            iconUrl: logoSrc,
            zindex: 999,
            buttons: isMessageBoardNotif && currrentPath !== '/messages/board'
                ? [['<button>View</button>', (() => {
                        this.onViewInMessageBoard()
                    })]] 
                : []
        })
    }

    renderLoader = () => (
        <>
            <div style={{ width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0 }}>
                <div className="pf-spinner xs" style={{ marginTop: '-48px', marginRight: '45px' }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        </> 
    )

    renderIcon = (type, symbol) => {
        if (isNotNull(type)) {
            switch (type) {
                default: {
                    let logoSrc = getCoin(symbol)
                    let imgSrc = setCoinLogo(logoSrc)
                    return (<SymbolIcon src={imgSrc} className={logoSrc} icon={logoSrc} onError={setDefaultImage} />)
                }
                case 5: {
                    return (
                        <OrderIcon className="automation">
                            <i className="fas fa-bolt"></i>
                        </OrderIcon>
                    )
                }
                case 6: {
                    return (
                        <OrderIcon className="success">
                            <i className="fas fa-check"></i>
                        </OrderIcon>
                    )
                }
                case 7: {
                    return (
                        <OrderIcon className="failed">
                            <i className="fas fa-exclamation"></i>
                        </OrderIcon>
                    )
                }
            }
        }
    }
    
    renderItems = () => {
        const { isLoading, items } = this.state

        if (isLoading) {
            return (
                <li>
                    {this.renderLoader()}
                </li>
            )
        } else {
            if (isNotNull(items) && items.length > 0) {
                let sortedItems = _.orderBy(items, o => new Date(o.dateCreated), 'desc')
                let filteredItems = _.filter(sortedItems, (val, key) => key <= 7)
                let mappedItems = _.map(filteredItems, (value, key) => {
                    let date = getSpecificHistoryTime(value.dateCreated)

                    return (
                        <li key={key} className={`list-item ${value.isRead ? '' : 'unread'}`} onClick={(e) => this.handleView(e, value)}>
                            {this.renderIcon(value.type, value.symbol)}
                            <div className='msg'>                            
                                <span className='text'>{value.message}</span>
                                <span className='ts'>{date}</span>
                            </div>
                        </li>
                    )
                })
                return (<>{mappedItems}</>)
            } else {
                return (
                    <li>
                        <span>You have no notifications right now. Come back later.</span>
                    </li>
                )
            }
        }
    }

    renderList = () => {
        const { totalUnreadMsgs, items } = this.state
        return (
            <Popover id={`notifications-popover-positioned-bottom`} className='notification-list-wrapper'>
                <Popover.Title as="div">
                    <h4>Notifications</h4>
                    { isNotNull(totalUnreadMsgs) && totalUnreadMsgs > 0 && 
                        <SafeAnchor href={void(0)} onClick={(e) => this.handleMarkAllAsRead(e)}>Mark all as read</SafeAnchor>
                    }
                </Popover.Title>
                <Popover.Content>
                    <NotificationList className='notification-list'>
                        {this.renderItems()}
                        { isNotNull(items) && items.length > 6 && 
                            <li>
                                <SafeAnchor href='/notifications'>View all notifications</SafeAnchor>
                            </li>
                        }
                    </NotificationList>
                </Popover.Content>
            </Popover>
        )
    }

    render() {
        const { totalUnreadMsgs, show, targetEl } = this.state
        
        return (
            <BellContentWrapper>
                <div ref={this.element}>
                    <button type='button' onClick={this.handleShow}>
                        <FontAwesomeIcon icon={faBell} />
                        { isNotNull(totalUnreadMsgs) && totalUnreadMsgs > 0 && 
                            <span className='counter'></span>
                        }
                    </button>
                    <Overlay 
                        show={show} 
                        onHide={this.handleHide}
                        rootClose={true} 
                        container={this.element.current} 
                        target={targetEl}
                        placement="bottom">
                            {this.renderList()}  
                    </Overlay>
                </div>
            </BellContentWrapper>
        )
    }
}

function mapStateToProps(state) {
    const { 
        puid, 
        notifications, 
        notificationLists, 
        markedNotification, 
        subscriptionDetails } = state
    return {
        puid,
        notifications,
        notificationLists, 
        markedNotification,
        subscriptionDetails
    }
}

const connectedBell = connect(mapStateToProps)(Bell)
export { connectedBell as Bell }