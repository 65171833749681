import styled from 'styled-components';
import { SettingsGroupWrapper } from '../../../styled_components'

export const RadioButton = styled.div`
    position: relative;

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
        margin-right: 11px;
    }

    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 21px;
        height: 21px;
        border-radius: 100%;
        background: #19212b;
    }
    
    [type="radio"]:not(:checked) + label:before {
        border: 2px solid #3d4854;
    }
    
    [type="radio"]:checked + label:before {
        border: 1px solid #15bd6f;
    }

    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 11px;
        height: 11px;
        background: #15bd6f;
        position: absolute;
        top: 5px;
        left: 5px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }

`

export const ToggleButton = styled.label`
    display: block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    margin-bottom: 0;
    color: #778698;
    pointer-events: none;
    font-size: 14px;

    &.active {
        pointer-events: auto;
        color: #94a7c1;
    }

    i {
        position: relative;
        display: inline-block;
        margin-right: .5rem;
        width: 38px;
        height: 21px;
        background-color: #3d4854;
        border-radius: 23px;
        vertical-align: text-bottom;
        transition: all 0.3s linear;

        &.active {
            &::before {
                background-color: #19212b;
            }

            &::after {
                background-color: #ccd4de;
            }
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 33px;
            height: 17px;
            background-color: #19212b;
            border-radius: 11px;
            transform: translate3d(2px,2px,0) scale3d(1,1,1);
            transition: all 0.25s linear;
        }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 16px;
            height: 16px;
            background-color: #4a4a4a;
            border-radius: 11px;
            box-shadow: 0 0px 2px 2px rgba(0,0,0,0.24);
            transform: translate3d(2px,2px,0);
            transition: all 0.2s ease-in-out;
        }
    }

    input {
        display: none;

        &:checked + i {
            background-color: #3f6654;

            &.active {
                background-color: #15bd6f;
            }

            &::before {
                transform: translate3d(14px, 2px, 0) scale3d(0, 0, 0);
            }

            &::after {
                transform: translate3d(20px, 2px, 0);
                background-color: #25313f;
            }
        }
    }

    &:active {

        i {
            &::after {
                width: 32px;
                transform: translate3d(2px,2.3px,0);
            }
        }

        input {

            &:checked + i {

                &::after {
                    width: 32px;
                    transform: translate3d(3px,2px,0);
                }
            }
        }
    }
`;

export const NotificationDefaultButton = styled.button`
    background-color: #17212D !important;
    color: #fff !important;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    border: 1px solid #263442 !important;
    margin-left: 30px;
    width: 120px;
    border-radius: 2px !important;
    padding: 8px 10px !important;
    font-size: 14px !important;

    &:focus,
    &:hover,
    &:visited,
    &:active {
        outline: none;
        box-shadow: 0 0 0 transparent;
    }
`;

export const NotificationDefaultText = styled.p`
    margin-bottom: 0;
    display: flex;
    align-items: center;
`;

export const NotificationDefaultTextSmall = styled.p`
    margin-right: 10px;
    margin-bottom: 0;
`;

export const NotificationDefaultWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
`;

export const NotificationEmailNotifyWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;

export const NotificationItemToggle = styled.div`
    display: flex;
    padding: 0 5px;

    ${ToggleButton} {
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            margin-right: 5px;
        }
    }

    &.allow-notify {
        margin-left: 0;
        display: flex;
        align-items: center;
    }

    .watchlisted {
        .btn-secondary,
        .btn-success {
            width: 160px !important;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .toggle-custom {
        .btn-secondary,
        .btn-success {
            width: 80px !important;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .switch {
        .btn-secondary {
            color: #fff !important;
            background-color: #25313f !important;
            font-size: 13px;
        }

        .btn-success {
            color: #fff !important;
            background-color: #3f9f32 !important;
            font-size: 13px;
        }
    }

    &.hidden-toggle {
        opacity: 0;
        pointer-events: none;
    }

    .toggle-label {
        color: #fff;
        margin-right: 10px;
        font-size: 15px;
    }
`;

export const NotificationItemTextWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const NotificationItemText = styled.span`
    &.txt {
        color: #79899c;
        font-size: 14px;
        margin-bottom: 15px;
        display: block;
    }
`;

export const NotificationItemTextEmail = styled.p`
    width: 100%;
    text-align: center;
    font-size: 12px;
    margin-bottom: 0;
`;

export const NotificationSignalUpdateWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;

    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
        margin-left: 20px;
    }

    label {
        margin-left: 5px;
        position: relative;
        cursor: pointer;
        margin-bottom: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;


        &::before {
            content:'';
            -webkit-appearance: none;
            background-color: transparent;
            border: 2px solid #0079bf;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
            padding: 10px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 5px;
            border-radius: 100%;
        }
    }

    input:checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 9px;
        width: 6px;
        height: 14px;
        border: solid #0079bf;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
`;

export const NotificationSignalOptionWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const NotificationCoinWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .col-items {
        padding: 0 !important;
        display: flex;
        position: absolute;
        right: 0;

        input {
            &:disabled+label {
                color: #556371 !important;

                &::before {
                    background-color: #ffffff24 !important;
                    border: 2px solid #38444b !important;
                }

                &::after {
                    border-color: #5e6b72 !important;
                }
            }
        }
    }
`;

export const NotificationItems = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    &.with-divider {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #223143;

        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
        }
    }

    svg,
    i {
        color: #8798AC;
        margin-right: 10px;
    }

    i {
        &.browser-icon {
            display: inline-block;
            width: 24px;
            height: 24px;
            background-image: url('/images/browsers.png');
            background-repeat: no-repeat;
            vertical-align: middle;

            &.chrome {
                background-position: -28px -1px;
            }

            &.edge {
                background-position: -28px -26px;
            }

            &.firefox {
                background-position: -28px -51px;
            }

            &.opera {
                background-position: -28px -75px;
            }

            &.default {
                background-position: -28px -101px;
            }
        }
    }

    .item-label {
        span {
            color: #c1c1c1;
            font-weight: 500;
        }

        small.help-text {
            color: #7E8FA2;
            margin-left: 25px;
        }
    }

    .push-icon-wrapper {
        display: flex;
        align-items: center;

        .push-icon {
            position: relative;
            width: 16px;
            height: 16px;
            border: 2px solid #8798AC;
            margin-right: 10px;
            border-radius: 2px;

            .push-circle {
                width: 8px;
                height: 8px;
                background: #8798AC;
                border-radius: 50%;
                position: absolute;
                top: -5px;
                right: -5px;
            }
        }
    }

    &.top {
        display: flex !important;
    }

    .row {

        .row-items {
            display: flex;
            justify-content: start;
            width: 100%;
        }
    }

    &.email-lists {
        justify-content: center;
    }

    &.sub-notif-item {
        padding: 10px 0 25px 10px;

        .item-label {
            font-size: 14px;
        }
    }
`;

export const NotificationLists = styled.ul`
    list-style: none;
    padding-left: 5px;
    margin-bottom: 25px;

    .row {
        width: 100%;
        margin: 0;
    }
`;

export const NotificationToggleWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

export const NotificationMenuWrapper = styled.div`
    width: 100%;
    min-height: 200px;

    -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

    @-webkit-keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

export const NotificationByPushWrapper = styled.div`
    width: 100%;
`;

export const NotificationTypesWrapper = styled.div`
    width: 100%;
    padding: 0 25px;

    .accordion {
        .push-icon-wrapper {
            display: flex;
            align-items: center;

            .push-icon {
                position: relative;
                width: 16px;
                height: 16px;
                border: 2px solid #8798AC;
                margin-right: 15px;
                border-radius: 2px;

                .push-circle {
                    width: 8px;
                    height: 8px;
                    background: #8798AC;
                    border-radius: 50%;
                    position: absolute;
                    top: -5px;
                    right: -5px;
                }
            }
        }

        .card {
            border-bottom: 1px solid #223143 !important;
            border-radius: 0 !important;
            cursor: pointer;            
            background-image: none;

            .email-notification-recipient {
                display: flex;
                padding: 0 0 20px;
                margin-bottom: 15px;

                i {
                    color: #15bd6f;
                    font-size: 23px;
                    margin-right: 18px;
                }

                span {
                    font-weight: bold;
                }
            }

            .card-header {
                background: #192431;
                padding: 12px 0;
                font-size: 16px;
                font-weight: 500;

                .header-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .title {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;

                        small {
                            color: #afbfd0;
                            font-weight: 500;
                        }
                    }
                }

                i {
                    color: #8798AC;
                    margin-right: 15px;
                }

                svg {
                    color: #8798AC;
                    margin-right: 25px;
                }
            }

            .card-body {
                padding: 0 30px 20px;

                small {
                    color: #6e7885;
                    font-weight: 500;
                    font-size: 13px;

                    .value-desc {
                        text-transform: lowercase;
                    }
                }

                .notification-type {
                    margin-left: -30px;

                    h6 {
                        font-size: 14px;
                        margin-bottom: 15px;
                        color: #fff;
                    }

                    .sub-notif-lists {
                        li {
                            padding: 5px 0;
                            font-size: 15px;
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }
    }    

    &.types {
        .accordion {
            .card {
                .card-body {
                    padding: 10px 30px 20px;

                    .notification-type {
                        margin: auto;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 320px) and (max-width: 414px) {
        .accordion {
            .card {
                background: none;
                border: none;

                .card-body {
                    .notification-type {
                        margin-left: auto;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 767px) and (max-width: 1023px) {
        .accordion {
            .card {
                background: none;
                border: none;
            }
        }
    }
`;

export const NotificationByProfitfarmerWrapper = styled.div`
    width: 100%;
    position: relative;

    ${NotificationMenuWrapper} {
        ${NotificationLists} {
            ${NotificationItems} {
                display: block;

                .row-items {

                    .col-items {
                        padding: 10px 15px;

                        input {
                            padding: 0;
                            height: initial;
                            width: initial;
                            margin-bottom: 0;
                            display: none;
                            cursor: pointer;
                        }

                        label {
                            position: relative;
                            cursor: pointer;
                            margin-bottom: 0;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            &::before {
                                content:'';
                                -webkit-appearance: none;
                                background-color: transparent;
                                border: 2px solid #0079bf;
                                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                                padding: 10px;
                                display: inline-block;
                                position: relative;
                                vertical-align: middle;
                                cursor: pointer;
                                margin-right: 5px;
                            }
                        }

                        input:checked + label:after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 4px;
                            left: 9px;
                            width: 6px;
                            height: 14px;
                            border: solid #0079bf;
                            border-width: 0 2px 2px 0;
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }
    }
`;

export const NotificationUpdateText = styled.p`
    color: #6E7885;
`;

export const NotificationUpdateButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {

        &.btn-cancel {
            background-color: #475973 !important;
            color: #fff !important;
            display: inline-block;
            font-weight: 400;
            text-align: center;
            border: 1px solid #577094 !important;
            padding: 8px 10px !important;
            min-width: 130px;
            border-radius: 2px !important;
        }

        &.btn-save {
            background-color: #15BD6F !important;
            border: 1px solid #15BD6F !important;
            color: #fff !important;
            display: inline-block;
            font-weight: 400;
            text-align: center;
            padding: 8px 10px !important;
            margin-left: 8px;
            min-width: 130px;
            border-radius: 2px !important;
        }

        &:focus,
        &:hover,
        &:visited,
        &:active {
            outline: none;
            box-shadow: 0 0 0 transparent;
        }

        &:disabled {
            background-color: rgb(43,60,75) !important;
            color: #556371 !important;
            border: 1px solid #616161 !important;

            &:hover {
                background-color: rgb(43,60,75) !important;
                color: #556371 !important;
                border: 1px solid #616161 !important;
            }
        }
    }

    span {
        &.btn-def {
            background-color: rgb(43,60,75) !important;
            color: #556371 !important;
            border: 1px solid #263442 !important;
            padding: 8px 10px !important;
            margin-left: 8px;
            min-width: 130px;
            border-radius: 2px !important;

            &:hover {
                background-color: rgb(43,60,75) !important;
                color: #556371 !important;
                border: 1px solid #616161 !important;
            }
        }
    }
`;

export const NotificationUpdateTextWrapper = styled.div`
    width: 100%;
`;

export const NotificationUpdateWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    padding: 15px 15px 0;
`;

export const NotificationnTitle = styled.h5`
    margin-bottom: 0;
`;

export const NotificationDivider = styled.hr`

`;

export const NotificationContainer = styled.div`
    width: 100%;
`;

export const NotificationWrapper = styled.div`
    width: 100%;
    overflow: hidden;

    @media screen and (min-width: 320px) and (max-width: 766px) {
        ${NotificationContainer} {
             ${SettingsGroupWrapper} {
                 padding: 15px 0;

                 ${NotificationByProfitfarmerWrapper} {
                     ${NotificationLists} {
                         margin-bottom: 0;

                         ${NotificationItems} {
                             font-size: 14px;

                             ${NotificationItemToggle} {
                                 ${ToggleButton} {
                                     .toggle-label {
                                         font-size: 14px;
                                     }
                                 }
                             }
                         }
                     }
                 }
            }

            ${NotificationTypesWrapper} {
                padding: 0 10px;

                .accordion {
                    .card {
                        .card-header {
                            padding: 15px 12px;
                            font-size: 14px;

                            svg {
                                margin-right: 0;
                            }
                        }

                        .card-body {
                            padding: 0 10px 20px;

                            .sub-notif-item {
                                padding: 20px 0 25px 10px;
                            }

                            .notification-type {
                                h6 {
                                    font-size: 16px;
                                }

                                .sub-notif-lists {
                                    padding-left: 5px;

                                    ${NotificationItems} {
                                        font-size: 14px;
                                    }
                                }

                                .toggle-label {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const NotificationSettingsWrapper = styled.div`
    .card-body {
        padding: 0;

        .settings-title {
            padding: .75rem 1.25rem;
            margin-bottom: 0;
            background-color: rgba(0,0,0,.03);
            border-bottom: 1px solid rgba(0,0,0,.125);
        }

        .notification {
            padding: 20px 25px 0;
        }
    }
`;
