import React from 'react'
import { connect } from 'react-redux'
import { FeaturedContentWrapper } from './FeaturedContentWrapper'
import { Carousel, SafeAnchor } from 'react-bootstrap'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGem } from '@fortawesome/free-solid-svg-icons'
import { subscriptionActions } from '../../redux/actions'
import { subscriptionStatus } from '../../constants/subscriptionStatus'
import { isNull, isNotNull, onSetNewState } from '../../utils'
import { OfferTimer } from './OfferTimer'
import { SixMonthsOfferTimer } from './SixMonthsOfferTimer'

class FeaturedContent extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            userId: null,
            isWithinTrialPeriod: null,
            trialEndsAt: null,
            hasTrialEnded: null,
            hasTrialHistory: null,
            hasSubscription: false,
            status: null
        }

        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        const { ...state } = this.state
        const { dispatch, subscriptionDetails } = this.props

        if (isNull(subscriptionDetails.loading))
            dispatch(subscriptionActions.getSubscriptionDetails())

        if (state.isLoading && isNotNull(subscriptionDetails.loading)) {
            const { subscription } = subscriptionDetails
            if (isNotNull(subscription)) {                        
                onSetNewState(this, {
                    isLoading: false,
                    hasSubscription: true,
                    status: subscription.paymentStatus
                })
            } else {
                onSetNewState(this, {
                    isLoading: false,
                    hasSubscription: false
                })
            }
        }
    }

    componentDidUpdate() {
        if (this.mounted) {
            const { isLoading, userId } = this.state
            const { authentication, subscriptionDetails } = this.props
            
            if (isNull(userId) && 
                isNotNull(authentication) && 
                isNotNull(authentication.user)) {
                    const { ...data } = authentication.user
                    onSetNewState(this, {
                        userId: data.userId,
                        isWithinTrialPeriod: data.isWithinTrialPeriod,
                        trialEndsAt: data.trialPeriodEndDate,
                        hasTrialEnded: data.hasTrialEnded,                        
                        hasTrialHistory: data.hasTrialHistory
                    })
                }

            if (isNotNull(subscriptionDetails) && 
                !subscriptionDetails.loading && 
                isLoading) {
                    const { subscription } = subscriptionDetails
                    if (isNotNull(subscription)) {                        
                        onSetNewState(this, {
                            isLoading: false,
                            hasSubscription: true,
                            status: subscription.paymentStatus
                        })
                    } else {
                        onSetNewState(this, {
                            isLoading: false,
                            hasSubscription: false
                        })
                    }
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    handleClickUpgradeToProBtn = (e) => {
        e.preventDefault()
        window.location.href = '/subscription-plans/pro/subscribe'
    }

    renderOfferWithCountDown = () => {
        const { trialEndsAt } = this.state
        if (isNotNull(trialEndsAt)) {
            return (
                <OfferTimer validUntil={trialEndsAt} />
            )
        }
        return (<></>)
    }

    renderSixMonthsOfferWithCountdown = (expiration) => {
        if (isNotNull(expiration)) {
            return (
                <SixMonthsOfferTimer validUntil={expiration} />
            )
        }
        return (<></>)
    }

    renderFinalOffer = (newExpiry) => (
        <>
            <div className='upgrade-cta'>
                <div className='text-with-img'>
                    <div className='img'>
                        <img src='/images/layout/upgrade-cta-pro.png' alt='' />
                    </div>
                    <div className='text'>
                        <h4>6 Months PRO</h4>
                        {this.renderSixMonthsOfferWithCountdown(newExpiry)}
                    </div>
                </div>
            </div>
            <div className='carousel-btn'>
                <SafeAnchor href={void(0)} onClick={(e) => this.handleClickUpgradeToProBtn(e)}>
                    <span className='btn-text'><FontAwesomeIcon icon={faGem} /> Upgrade to PRO</span>
                </SafeAnchor>
            </div>
        </>
    )

    render() {
        const { 
            isLoading, 
            hasSubscription, 
            isWithinTrialPeriod,
            status,
            hasTrialHistory,
            trialEndsAt } = this.state

        let newExpiry = null
        let isWithinBonusOffer = null
        if (isNotNull(trialEndsAt)) {
            let now = moment()
            let parsedDate = Date.parse(trialEndsAt)
            let expiry = new Date(parsedDate)
                expiry = moment(expiry).format('MM/DD/yyyy h:mm:ss')

            let duration = moment.duration(now.diff(expiry))
            let hours = duration.asHours()
            
            if (parseInt(hours) <= 99) {
                newExpiry = now.add(72, 'hours')
                isWithinBonusOffer = true
            } else {
                isWithinBonusOffer = false
            }
        }

        let myStyle = ''
        if (isWithinTrialPeriod)
            myStyle = 'for-upgrade'
        else {
            if (hasTrialHistory) {
                if (isNotNull(isWithinBonusOffer) && isWithinBonusOffer) {
                    myStyle = 'for-expd-trial'
                } else {
                    myStyle = 'def'
                }
            } else {
                myStyle = 'for-trial'
            }
        }

        return (
            <>
                { !isLoading && 
                    <>
                        { (!hasSubscription || (hasSubscription && (isWithinTrialPeriod || (isNotNull(status) && (status === subscriptionStatus.DEACTIVATED || status === subscriptionStatus.EXPIRED))))) &&                             
                            <FeaturedContentWrapper id='tour-upgrade' className={myStyle}>
                                { isWithinTrialPeriod && 
                                    <>
                                        <div className='upgrade-cta'>
                                            <div className='text-with-img'>
                                                <div className='img'>
                                                    <img src='/images/layout/upgrade-cta-pro.png' alt='' />
                                                </div>
                                                <div className='text'>
                                                    <h4>365 Days PRO</h4>
                                                    {this.renderOfferWithCountDown()}                                                
                                                </div>
                                            </div>
                                        </div>
                                        <div className='carousel-btn'>
                                            <SafeAnchor href={void(0)} onClick={(e) => this.handleClickUpgradeToProBtn(e)}>
                                                <span className='btn-text'><FontAwesomeIcon icon={faGem} /> Upgrade to PRO</span>
                                            </SafeAnchor>
                                        </div>
                                    </>
                                }
                                
                                { !isWithinTrialPeriod && !hasTrialHistory && 
                                    <>
                                        <div className='trial-cta'>
                                            <div className='text'>
                                                <h4>
                                                    <span>21 Days PRO Trial</span><br />
                                                    <small>+PLUS The PRO Trader 5x Blueprint</small>
                                                </h4>
                                                <div className='price-details'>
                                                    <span className='new-price'>$21</span>
                                                    <span className='old-price'>
                                                        <span className='crashed-old-price'>$399</span>
                                                        <span>
                                                            <img src='/images/layout/tag-only.png' alt='' />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='carousel-btn'>
                                            <SafeAnchor href={void(0)} onClick={(e) => this.handleClickUpgradeToProBtn(e)}>
                                                <span className='btn-text'><FontAwesomeIcon icon={faGem} /> Secure This Offer</span>
                                            </SafeAnchor>
                                        </div>
                                    </>
                                }

                                { !isWithinTrialPeriod && hasTrialHistory && 
                                    <>
                                        { isNotNull(isWithinBonusOffer) && 
                                            isWithinBonusOffer && 
                                            this.renderFinalOffer(newExpiry) }

                                        { (isNull(isWithinBonusOffer) || 
                                            (isNotNull(isWithinBonusOffer) && !isWithinBonusOffer)) && 
                                            <>
                                                <Carousel controls={false}>
                                                    <Carousel.Item>
                                                        <img className='' src='../images/case-studies/tim-griffith.png' alt='' />
                                                        <Carousel.Caption>
                                                            <p className='member-text'>"I’ve gone from $2800 to $14000 in the last 30 days"</p>
                                                            <p className='member-name'>Tim G. 
                                                                <img src='/images/case-studies/trustpilot-5-star.svg' alt='' />
                                                            </p>
                                                        </Carousel.Caption>
                                                    </Carousel.Item>
                                                    
                                                    <Carousel.Item>
                                                        <img className='' src='../images/case-studies/Kingston-Arthur-Profile.png' alt='' />
                                                        <Carousel.Caption>
                                                            <p className='member-text'>"I've grown my account with 20 pretty conservative..."</p>
                                                            <p className='member-name'>Kingston A. 
                                                                <img src='/images/case-studies/trustpilot-5-star.svg' alt='' />
                                                            </p>
                                                        </Carousel.Caption>
                                                    </Carousel.Item>
                                                    
                                                    <Carousel.Item>
                                                        <img className='' src='../images/case-studies/bernhard.png' alt='' />
                                                        <Carousel.Caption>
                                                            <p className='member-text'>"I started PF with $400 and traded that up to $8,000 now"</p>
                                                            <p className='member-name'>Bernhard W. 
                                                                <img src='/images/case-studies/trustpilot-5-star.svg' alt='' />
                                                            </p>
                                                        </Carousel.Caption>
                                                    </Carousel.Item>
                                                </Carousel>

                                                <div className='carousel-btn'>
                                                    <SafeAnchor href={void(0)} onClick={(e) => this.handleClickUpgradeToProBtn(e)}>
                                                        <span className='btn-text'><FontAwesomeIcon icon={faGem} /> Upgrade Now</span>
                                                    </SafeAnchor>
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                            </FeaturedContentWrapper>
                        }
                    </>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    const { authentication, subscriptionDetails } = state
    return {
        authentication,
        subscriptionDetails
    }
}

const connectedFeaturedContent = connect(mapStateToProps)(FeaturedContent)
export { connectedFeaturedContent as FeaturedContent }