import { Skeleton } from "@material-ui/lab"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import {
    PreloaderWrapper, Preloader, PreloaderItem,
    SignalPeakGains, SignalLiveFibo, SignalI,
    SignalPrice, SignalVol, SignalMarketCap,
    DataSignalGrid, DataSignals, SignalN
} from '../styled_components'

export const ScannerBodyHeadingView = (props) => {
    const {
        coinPair, loading, functions, type,
        s1, s2, s3, s4, s5, s6, s7, s8,
        price, volume, marketCap,
        peakGains, liveFibo, d3move,
        selectedMoved
    } = props

    const preloader = (
        <PreloaderWrapper className="pb-0 preloader-wrapper">
            <Preloader className="preloader-wrapper">
                <PreloaderItem className="header">
                    <Skeleton variant="rect" style={{
                        width: '100%',
                        height: '100%',
                        backgroundImage: 'linear-gradient(15deg,#334156,#385065)',
                        border: '1px solid #27313f',
                        borderRadius: '4px',
                        boxShadow: '0px 3px 3px 2px rgba(0,0,0,0.08)'
                    }} className="skeleton" />
                </PreloaderItem>
            </Preloader>
        </PreloaderWrapper>
    )

    const heading = (
        <DataSignalGrid className="grid-h">
            <DataSignals className="grid-col h">
                <SignalN className="rsi-m-c pa-m h" onClick={functions._handleCoinPair}>
                    <span>Coin Pair</span>
                    <FontAwesomeIcon icon={coinPair ? faChevronUp : faChevronDown} className={`icon ${coinPair ? "show" : ""}`} />
                </SignalN>
                <SignalI className="rsi-m h" onClick={functions._handleS1}>
                    <span>M5</span>
                    <FontAwesomeIcon icon={s1 ? faChevronUp : faChevronDown} className={`icon ${s1 ? "show" : ""}`} />
                </SignalI>
                <SignalI className="rsi-m h" onClick={functions._handleS2}>
                    <span>M15</span>
                    <FontAwesomeIcon icon={s2 ? faChevronUp : faChevronDown} className={`icon ${s2 ? "show" : ""}`} />
                </SignalI>
                <SignalI className="hidden-m rsi-m h" onClick={functions._handleS3}>
                    <span>M30</span>
                    <FontAwesomeIcon icon={s3 ? faChevronUp : faChevronDown} className={`icon ${s3 ? "show" : ""}`} />
                </SignalI>
                <SignalI className="rsi-m h" onClick={functions._handleS4}>
                    <span>H1</span>
                    <FontAwesomeIcon icon={s4 ? faChevronUp : faChevronDown} className={`icon ${s4 ? "show" : ""}`} />
                </SignalI>
                <SignalI className="hidden-m rsi-m h" onClick={functions._handleS5}>
                    <span>H4</span>
                    <FontAwesomeIcon icon={s5 ? faChevronUp : faChevronDown} className={`icon ${s5 ? "show" : ""}`} />
                </SignalI>
                <SignalI className="rsi-m h" onClick={functions._handleS6}>
                    <span>H6</span>
                    <FontAwesomeIcon icon={s6 ? faChevronUp : faChevronDown} className={`icon ${s6 ? "show" : ""}`} />
                </SignalI>
                <SignalI className="hidden-m rsi-m h" onClick={functions._handleS7}>
                    <span>H12</span>
                    <FontAwesomeIcon icon={s7 ? faChevronUp : faChevronDown} className={`icon ${s7 ? "show" : ""}`} />
                </SignalI>
                <SignalI className="rsi-m h" onClick={functions._handleS8}>
                    <span>D</span>
                    <FontAwesomeIcon icon={s8 ? faChevronUp : faChevronDown} className={`icon ${s8 ? "show" : ""}`} />
                </SignalI>
                <SignalPrice className="text-right pa-m h" onClick={functions._handlePrice}>
                    <span>Price</span>
                    <FontAwesomeIcon icon={price ? faChevronUp : faChevronDown} className={`icon ${price ? "show" : ""}`} />
                </SignalPrice>

                <SignalVol className="text-right pa-m h" onClick={functions._handleVolume}>
                    <span>Volume</span>
                    <FontAwesomeIcon icon={volume ? faChevronUp : faChevronDown} className={`icon ${volume ? "show" : ""}`} />
                </SignalVol>
                {type === 'relative_strength' &&
                    <SignalMarketCap className="text-right h" onClick={functions._handled3move}>
                        <span>D3 Move</span>
                        <FontAwesomeIcon icon={d3move ? faChevronUp : faChevronDown} className={`icon ${d3move ? "show" : ""}`} />
                    </SignalMarketCap>
                }
                {type === 'price_action' &&
                    <>
                        <SignalMarketCap className="text-right pa-m h" onClick={functions._handleMarketCap}>
                            <span>MarketCap</span>
                            <FontAwesomeIcon icon={marketCap ? faChevronUp : faChevronDown} className={`icon ${marketCap ? "show" : ""}`} />
                        </SignalMarketCap>
                        <SignalPeakGains className="text-right pa-mt h" onClick={functions._handlePeakGains}>
                            <span>{selectedMoved === "up" ? "Peak Gains" : "Peak Drop"}</span>
                            <FontAwesomeIcon icon={peakGains ? faChevronUp : faChevronDown} className={`icon ${peakGains ? "show" : ""}`} />
                        </SignalPeakGains>
                    </>
                }
                <SignalLiveFibo className="text-right pa-m h" onClick={functions._handleLiveFibo}>
                    <span>Live Fibo</span>
                    <FontAwesomeIcon icon={liveFibo ? faChevronUp : faChevronDown} className={`icon ${liveFibo ? "show" : ""}`} />
                </SignalLiveFibo>
            </DataSignals>
        </DataSignalGrid>
    )

    return (
        <>
            {loading &&
                <>
                    {preloader}
                </>
            }
            {!loading &&
                <>
                    {heading}
                </>
            }
        </>
    )
}