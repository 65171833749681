import React from 'react'
import { connect } from 'react-redux'
import { userActions, notificationActions } from '../../../../redux/actions'
import { NotificationView } from './NotificationView'
import { Dimension, isNull, isNotNull, onSetNewState, addPageViewEvent, seo } from '../../../../utils'

class NotificationContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            hasLoadedSettings: false,
            allowEmail: false,
            allowPush: false,
            allowSoundOnPush: false,
            types: [],
            userId: null,
            toggles: [false, false, false, false, false],
            isUpdatingPushNotifSettings: false,
            isUpdatingPushSoundSettings: false,
            isUpdatingEmailTypeSettings: false,
            isUpdatingPushTypeSettings: false
        }
        this.baseState = this.state
        this.mounted = false

        window.addEventListener('resize', () => Dimension.updateDimensions(this))
    }

    componentDidMount() {
        this.mounted = true

        const { hasLoadedSettings } = this.state
        const { dispatch, notifications } = this.props


        if (!hasLoadedSettings) {
            dispatch(notificationActions.getNotificationSettings())

            const { notification_settings } = notifications
            if (isNotNull(notification_settings) && isNotNull(notification_settings.data)) {
                const { id, types } = notification_settings.data

                onSetNewState(this, {
                    userId: id,
                    allowSoundOnPush: notification_settings.data.allowSoundOnPush,
                    allowEmail: notification_settings.data.allowEmail,
                    allowPush: notification_settings.data.allowPush,
                    types: types,
                    isUpdatingPushNotifSettings: false,
                    isUpdatingPushSoundSettings: false,
                    isUpdatingEmailTypeSettings: false,
                    isUpdatingPushTypeSettings: false,
                    hasLoadedSettings: true
                })
            }
        }

        seo({
            title: "Notification Settings",
            metaDescription: ""
        })
        addPageViewEvent()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.notifications !== this.props.notifications) {
            const { notification_settings, loading } = this.props.notifications

            if (!loading) {
                // notification settings
                if (isNotNull(notification_settings) && isNotNull(notification_settings.data)) {
                    const { id, types } = notification_settings.data

                    onSetNewState(this, {
                        userId: id,
                        allowSoundOnPush: notification_settings.data.allowSoundOnPush,
                        allowEmail: notification_settings.data.allowEmail,
                        allowPush: notification_settings.data.allowPush,
                        types: types,
                        isUpdatingPushNotifSettings: false,
                        isUpdatingPushSoundSettings: false,
                        isUpdatingEmailTypeSettings: false,
                        isUpdatingPushTypeSettings: false,
                        hasLoadedSettings: true
                    })
                }
            }
        }

        if (prevProps.saveNotificationTypeChanges !== this.props.saveNotificationTypeChanges) {
            const { loader, notification_type_settings } = this.props.saveNotificationTypeChanges
            if (!loader && isNotNull(notification_type_settings)) { 
                const { success } = notification_type_settings
                if (isNotNull(success) && success) {
                    const { dispatch } = this.props
                    dispatch(notificationActions.getNotificationSettings())
                }
            }
        }

        if (prevProps.saveNotificationSettings !== this.props.saveNotificationSettings) {
            const { loader, save_notification_settings } = this.props.saveNotificationSettings

            if (!loader) {
                if (isNotNull(save_notification_settings)) {
                    const { success } = save_notification_settings

                    if (isNotNull(success) && success) {
                        // iziToast.success({
                        //     position: 'topRight',
                        //     title: 'Success',
                        //     message: 'Notification Settings Update Success!',
                        // })

                        const { dispatch } = this.props
                        dispatch(notificationActions.getNotificationSettings())                    
                        dispatch(userActions.getMe())
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)
        window.removeEventListener('resize', () => Dimension.updateDimensions(this))
        this.mounted = false
    }

    handlePushTypeSettingsChanges = (settings) => {
        onSetNewState(this, {
            isUpdatingPushTypeSettings: true
        }, () => {
            const { dispatch } = this.props
            dispatch(notificationActions.saveNotificationTypeChanges(settings))
        })
    }

    handleEmailTypeSettingsChanges = (settings) => {
        onSetNewState(this, {
            isUpdatingEmailTypeSettings: true
        }, () => {
            const { dispatch } = this.props
            dispatch(notificationActions.saveNotificationTypeChanges(settings))
        })
    }

    handlePushSettingsChanges = (settings) => {
        onSetNewState(this, {
            isUpdatingPushNotifSettings: true
        }, () => {
            const { dispatch } = this.props
            dispatch(notificationActions.saveNotificationSettings(settings))
        })
    }

    handlePushSoundSettingsChanges = (settings) => {
        onSetNewState(this, {
            isUpdatingPushSoundSettings: true
        }, () => {
            const { dispatch } = this.props
            dispatch(notificationActions.saveNotificationSettings(settings))
        })
    }

    render() {
        return (
            <NotificationView
                self={this}
                state={this.state}
                onChangePushSettings={this.handlePushSettingsChanges} 
                onChangePushSoundSettings={this.handlePushSoundSettingsChanges}
                onChangePushTypeSettings={this.handlePushTypeSettingsChanges}
                onChangeEmailTypeSettings={this.handleEmailTypeSettingsChanges}
                {...this.props}
            />
        )
    }
}

function mapStateToProps(state) {
    const { notifications, saveNotificationTypeChanges, saveNotificationSettings } = state
    return {
        notifications,
        saveNotificationTypeChanges,
        saveNotificationSettings
    }
}
const connectedNotificationContainer = connect(mapStateToProps)(NotificationContainer)
export { connectedNotificationContainer as NotificationContainer }