import { isNotNull } from '../utils'

function addAuthorizationHeader() {
    var header = {}
    var security = JSON.parse(localStorage.getItem('profitfarmers.token'))

    if (isNotNull(security) && isNotNull(security.access)) {
        header = {
            'Authorization': `Bearer ${security.access.token}`,
            'Content-Type': 'application/json'
        }
    }
    return header
}

function addAuthorizationHeaderFile() {
    var header = {}
    var security = JSON.parse(localStorage.getItem('profitfarmers.token'))
    if (isNotNull(security) && isNotNull(security.access)) {
        header = {
            'Authorization': `Bearer ${security.access.token}`
        }
    }
    return header
}

export const header = {
    addAuthorizationHeader,
    addAuthorizationHeaderFile
}
