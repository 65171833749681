import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTachometerAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Modal, SafeAnchor } from 'react-bootstrap'
import _ from 'lodash'
import { scannerActions } from '../../redux/actions'
import { TradeGuide } from './components'
import { RSIView, PriceActionView, ScannerBodyHeadingView, ScannerHeader } from './Views'
import {
    SectionBlock, SectionBlockInner, SectionHeader,
    SectionTitle, DataSignalGridWrapper, TradeGuideCloseButton
} from './styled_components'
import ScannerSortFunctions from './ScannerSortFunctions'
import { isNotNull, onSetNewState, addPageViewEvent, seo } from '../../utils'
import '../../assets/scss/scanner.scss'

class ScannerContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isInitialized: false,
            filterValue: "H6",
            watched: false,
            coinTarget: "ALL",
            selectedMoved: "up",
            priceActionScannerModal: false,
            targetCoin: null,
            coinPair: false,
            s1: false,
            s2: false,
            s3: false,
            s4: false,
            s5: false,
            s6: false,
            s7: false,
            s8: false,
            price: false,
            volume: false,
            marketCap: false,
            peakGains: false,
            liveFibo: false,
            d3move: false,
            selectedItem: "coinPair",
            functions: new ScannerSortFunctions(this),
            currentSet: 20,
            totalItems: 0,
            isExpanded: null,
            displayInfo: false
        }
        this.mounted = false
        this.baseState = this.state

        window.onscroll = _.debounce(() => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.scrollingElement.scrollHeight - 1)
                this.onScrollLoadNewScannerDataHandler()
        }, 100)
    }

    componentDidMount() {
        this.mounted = true

        this.onDispatchFilterHandler()
            
        let pageTitle = this.props.type === 'price_action' ? "Price Action Scanner" : "RSI Scanner"            
        seo({
            title: pageTitle,
            metaDescription: ""
        })
        addPageViewEvent()
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { isInitialized } = this.state
            const { authentication } = this.props

            if (!isInitialized && isNotNull(authentication)) {
                const { user } = authentication
                if (isNotNull(user)) {
                    onSetNewState(this, {
                        isInitialized: true
                    })

                    if (!user.isPaid) {
                        window.location.href = '/subscription-plans/pro/subscribe'   
                    }
                }
            }

            if (prevProps.type !== this.props.type)
                this.onDispatchFilterHandler()

            if (prevProps.relativeStrengthAllItems !== this.props.relativeStrengthAllItems) {
                const { items } = this.props.relativeStrengthAllItems
                if (isNotNull(items))
                    onSetNewState(this, { totalItems: items.length })
            }

            if (prevProps.priceActions !== this.props.priceActions) {
                const { items } = this.props.priceActions
                if (isNotNull(items))
                    onSetNewState(this, { totalItems: items.length })
            }
        }
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)
        this.mounted = false
    }

    onDispatchFilterHandler = () => {
        const { type, dispatch } = this.props
        if (isNotNull(type)) {
            if (type === "price_action") {
                onSetNewState(this, { filterValue: "H6", watched: false, coinTarget: "ALL" })
                dispatch(scannerActions.getPriceAction())
                dispatch(scannerActions.getWatchLists())
            }
            else {
                onSetNewState(this, { filterValue: "rsi7", watched: false, coinTarget: "ALL" })
                dispatch(scannerActions.getRSI())
                dispatch(scannerActions.getWatchLists())
            }
            this.state.functions.ClearSortState()
        }
    }

    onScrollLoadNewScannerDataHandler = () => {
        const { currentSet, totalItems } = this.state
        const newSet = currentSet + 20
        if (currentSet <= totalItems)
            onSetNewState(this, { currentSet: newSet })
    }

    onWatchFilterChangeHandler = () => onSetNewState(this, function (prev) {
        return { watched: !prev.watched, currentSet: 20 }
    })

    onFilterValueChangeHandler = (event) => onSetNewState(this, { filterValue: event.target.value, currentSet: 20 })

    onCoinTargetChangeHandler = (event) => onSetNewState(this, { coinTarget: event.target.value, currentSet: 20 })

    //#region Price Action Scanner
    onShowModalHandler = (e) => {
        onSetNewState(this, {
            targetCoin: e,
            priceActionScannerModal: true
        })
    }

    onCloseModalHandler = () => onSetNewState(this, { priceActionScannerModal: false })

    onWatchHandler = (coinPair, hasWatched, type) => {
        if (isNotNull(coinPair)) {
            const { dispatch, priceActions, relativeStrengthAllItems } = this.props
            if (isNotNull(type)) {
                if (type === "price_action" && isNotNull(priceActions) && isNotNull(priceActions.items)) {
                    const find = _.find(priceActions.items, (info) => {
                        return info.n === coinPair
                    })
                    if (isNotNull(find)) {
                        find.isWatched = hasWatched
                        const newArray = _.map(priceActions.items, function (info) {
                            return info.n === coinPair ? find : info;
                        })
                        dispatch(scannerActions.updatePriceAction(newArray))
                    }
                    dispatch(scannerActions.saveWatchlist(coinPair))
                }

                if (type === "relative_strength" && isNotNull(relativeStrengthAllItems) && isNotNull(relativeStrengthAllItems.items)) {
                    const find = _.find(relativeStrengthAllItems.items, (info) => {
                        return info.n === coinPair
                    })
                    if (isNotNull(find)) {
                        find.isWatched = hasWatched
                        const newArray = _.map(relativeStrengthAllItems.items, function (info) {
                            return info.n === coinPair ? find : info;
                        })
                        dispatch(scannerActions.updateRSI(newArray))
                    }
                    dispatch(scannerActions.saveWatchlist(coinPair))
                }
            }

        }
    }

    onMoveChangeHandler = (event) => onSetNewState(this, { selectedMoved: event.target.value, currentSet: 20 })
    //#endregion

    onExpandHandler = (coinName) => {
        let active = this.state.isExpanded === coinName ? null : coinName
        onSetNewState(this, { isExpanded: active })
    }

    onDispalyHandler = (coinName) => this.state.isExpanded === coinName ? 'block' : 'none'

    onInformationDisplayHandler = () => this.setState((prev) => ({ displayInfo: !prev.displayInfo }))

    renderScannerInformation = (type) => {
        if (type === 'price_action')
            return (
                <div>
                    <p>ProfitFarmers' Price Action Scanner makes spotting potentially profitable price action faster and easier than ever before.</p>
                    <p>Identifying the price action of a coin pair is fundamental for finding a good trade opportunity.</p>
                    <p>A typical trader does this by switching between countless charts to perform technical analysis from Elliot Wave patterns, candlestick patterns, support &amp; resistance areas and more! Unfortunately this often requires a lot of time and experience to do well.</p>
                    <p>With ProfitFarmers' Price Action scanner, the hard work of identifying price action is simplified into an easy-to-understand, color-coded chart.</p>
                    <p>If you want to learn:</p>
                    <ul>
                        <li>What the Price Action Scanner actually does</li>
                        <li>How it's used to help spot good trade opportunities</li>
                        <li>A demonstration of using the Price Action Scanner</li>
                        <li>How Matthew used the PAS for less than 5 minutes and made 60% gains</li>
                    </ul>
                    <p>Then watch our educational video here:</p>
                    <p><SafeAnchor href="https://youtu.be/Gv6FGwCboyY" target="_blank">How to use our FREE Price Action Scanner (PAS) to find consistently profitable trades FAST</SafeAnchor></p>
                </div>
            )
        else
            return (
                <div>
                    <p>ProfitFarmers' RSI Scanner helps you find high-probability trades in minutes.</p>
                    <p>RSI stands for Relative Strength Index, which is one of the most popular technical indicators used by crypto traders. Our Scanner processes RSI oscillations from 8 different timeframes for over 300 coin pairs on Binance.com to highlight trending pairs that could be played for a profit.</p>
                    <p>If you want to learn:</p>
                    <ul>
                        <li>What the Relative Strength Index Scanner actually does</li>
                        <li>How it's used to help predict price action</li>
                        <li>A demonstration of using the RSI scanner</li>
                        <li>How you can use the Price Action Scanner along with the RSI scanner to help confirm potential price action</li>
                    </ul>
                    <p>Then watch our educational video here:</p>
                    <p><SafeAnchor href="https://youtu.be/ledLLWL0mfE" target="_blank">How to Quickly Spot Profitable Price Movement with FREE Relative Strength Index (RSI) Scanner</SafeAnchor></p>
                </div>
            )
    }

    render() {
        const { loading } = this.props.relativeStrengthAllItems
        const { type, title } = this.props
        const { isInitialized, filterValue, coinTarget, watched, targetCoin, priceActionScannerModal, selectedMoved, displayInfo } = this.state

        if (isInitialized) {
            return (
                <div>
                    <SectionBlock>
                        <SectionBlockInner className="scanner-lg theme-scanner-wrapper">
                            <SectionHeader>
                                <SectionTitle className="theme-section-title">
                                    <FontAwesomeIcon icon={faTachometerAlt} className="icon" />
                                    {title}
                                    <i className="fas fa-info-circle information" onClick={() => this.onInformationDisplayHandler()} />
                                </SectionTitle>
                                <ScannerHeader
                                    type={type}
                                    title={title}
                                    onChangeFilter={this.onFilterValueChangeHandler}
                                    onCoinTargetFilterChange={this.onCoinTargetChangeHandler}
                                    onWatchFilterChange={this.onWatchFilterChangeHandler}
                                    onMoveChange={this.onMoveChangeHandler}
                                    selectedMoved={selectedMoved}
                                    filterValue={filterValue}
                                    coinTargetFilter={coinTarget}
                                    isWatched={watched}
                                    {...this.props}
                                ></ScannerHeader>
                            </SectionHeader>
    
                            <DataSignalGridWrapper className={`${isNotNull(type) && type === "price_action" ? "price-grid" : "rsi-grid"} theme-scanner`}>
                                <ScannerBodyHeadingView type={type} loading={loading} {...this.state} />
                                
                                { type === 'price_action' &&
                                    <PriceActionView
                                        type={type}
                                        onCloseModal={this.onCloseModalHandler}
                                        onShowModal={this.onShowModalHandler}
                                        onWatch={this.onWatchHandler}
                                        onExpand={this.onExpandHandler}
                                        onDisplay={this.onDispalyHandler}
                                        paFilter={filterValue}
                                        targetFilter={coinTarget}
                                        isWatched={watched}
                                        target={targetCoin}
                                        {...this.state}
                                        {...this.props}
                                    />
                                }
    
                                {type === 'relative_strength' &&
                                    <RSIView
                                        type={type}
                                        rsiFilter={filterValue}
                                        targetFilter={coinTarget}
                                        isWatched={watched}
                                        onWatch={this.onWatchHandler}
                                        onExpand={this.onExpandHandler}
                                        onDisplay={this.onDispalyHandler}
                                        {...this.state}
                                        {...this.props}
                                    />
                                }
                            </DataSignalGridWrapper>
                        </SectionBlockInner>
                    </SectionBlock>
    
                    <Modal
                        className="trade-guide-modal"
                        id="videoModal"
                        aria-hidden="true"
                        show={priceActionScannerModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        onHide={this.onCloseModalHandler}
                        data-backdrop="false">
                        <TradeGuideCloseButton onClick={this.onCloseModalHandler}>
                            <FontAwesomeIcon icon={faTimes} className="icon" />
                        </TradeGuideCloseButton>
                        <TradeGuide data={targetCoin} />
                    </Modal>
    
                    <Modal
                        className="scanner-info"
                        show={displayInfo}
                        centered
                        aria-hidden="true"
                        aria-labelledby="contained-modal-title-vcenter"
                        onHide={this.onInformationDisplayHandler}
                        data-backdrop="false"
                    >
                        <Modal.Header closeButton>
                            {type === 'price_action'
                                ? <>Price Action Scanner Information</>
                                : <>RSI Scanner Information</>
                            }
                        </Modal.Header>
                        <Modal.Body>
                            {this.renderScannerInformation(type)}
                        </Modal.Body>
                    </Modal>
                </div>
            )
        } else {
            return (<></>)
        }
    }

}

function mapStateToProps(state) {
    const { authentication } = state
    return {
        authentication
    }
}

const connectedScannerContainer = connect(mapStateToProps)(ScannerContainer)
export { connectedScannerContainer as ScannerContainer }