import { useEffect, useState } from 'react'
import moment from 'moment'
import { LimitedOfferStyle } from './styles'

export const LimitedSixMonthsOffer = (props) => {
    let [remainingDays, setRemainingDays] = useState('')
    let [remainingHours, setRemainingHours] = useState('')
    let [remainingMinutes, setRemainingMinutes] = useState('')
    let [remainingSeconds, setRemainingSeconds] = useState('')
    
    useEffect(() => {
        let expiry = props.validUntil

        const second = 1000,
            minute = second * 60,
            hour = minute * 60,
            day = hour * 24;

        let today = new Date(),
            dd = String(today.getDate()).padStart(2, "0"),
            mm = String(today.getMonth() + 1).padStart(2, "0"),
            yyyy = today.getFullYear(),
            nextYear = yyyy + 1,
            dayMonth = moment(expiry).format('MM/DD/')

        today = mm + "/" + dd + "/" + yyyy
        if (today > expiry) {
            expiry = dayMonth + nextYear
        }

        const countDown = new Date(expiry).getTime()
        const x = setInterval(function() {
            const now = new Date().getTime(),
                distance = countDown - now

            setRemainingDays(Math.floor(distance / (day)))
            setRemainingHours(Math.floor((distance % (day)) / (hour)))
            setRemainingMinutes(Math.floor((distance % (hour)) / (minute)))
            setRemainingSeconds(Math.floor((distance % (minute)) / second))
            
            if (distance < 0) {
                clearInterval(x)
            }
            //seconds
        }, 1000)

        return () => clearInterval(x)
    }, [])

    return (
        <LimitedOfferStyle>
            <p>ATTN - Your exclusive <strong>Pro Trader 5x Blueprint</strong> discount ends in <strong>[<span id="days">{remainingDays}</span>D <span id="hours">{remainingHours}</span>h <span id="minutes">{remainingMinutes}</span>m <span id="seconds">{remainingSeconds}</span>s]</strong>. Save over $1,900 before time runs out!</p>
        </LimitedOfferStyle>
    )
}