import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { WeeklyTotalMarketCapSectionStyle } from './styles'

export const WeeklyTotalMarketCapSection = () => {
    return (
        <WeeklyTotalMarketCapSectionStyle>
            <div className='container'>
                <h2 className='section-title'>
                    <small>PAY ATTENTION:</small><br />
                    <span>Can You See A Better Time For Trading Crypto Than Now?</span>
                </h2>

                <div className='mc-chart'>
                    <img src='/images/layout/chart.png' alt='Weekly Cryptocurrency Total Market Cap' />
                </div>

                <h3>This is the Cryptocurrency Total Market Cap on Weekly Timeframe</h3>

                <p>The price on the chart represents the entire crypto market place as one investment.</p>
                <p>What do your eyes tell you?</p>
                <p>Here's the blurb from the ProfitFarmers trading wizards:</p>

                <ul className='blurb-list'>
                    <li>
                        <FontAwesomeIcon icon={faEye} />
                        <span>Macro perspective we see a potential bottom within this zone</span>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faEye} />
                        <span>Fractals in the past years clearly display a trend with clear sight for the upcoming bull run</span>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faEye} />
                        <span>RSI has already touched 30 meaning oversold and right now that market is accumulating</span>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faEye} />
                        <span>Breakout over local EMA's will accompany resumption of major uptrend in the overall market</span>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faEye} />
                        <span>Market has already experienced major downside and deleveraging events along with new regulation</span>
                    </li>
                </ul>

                <h3>In simple English: LETS F***ING GO!</h3>
            </div>
        </WeeklyTotalMarketCapSectionStyle>
    )
}