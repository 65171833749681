import { endpoints, baseUrl } from './CONSTANTS'
import { authServices } from './authService'

const { v3 } = endpoints

function getBalance() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${v3.user.wallet.spot}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function getFutureWallets() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${v3.user.wallet.futures}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function getBinance() {
    const requestOpts = {
        method: 'GET'
    }

    return fetch("https://api.binance.com/api/v3/ticker/24hr", requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function getBinanceFuture() {
    const requestOpts = {
        method: 'GET'
    }

    return fetch("https://fapi.binance.com/fapi/v1/ticker/24hr", requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function createMarket(data) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        //headers: header.addAuthorizationHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${baseUrl}${v3.user.trade.createMarketOrder}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        if (res.status === 401)
            authServices.logout()
        else
            return data
    })
}

export const walletServices = {
    getBalance,
    getFutureWallets,
    getBinance,
    getBinanceFuture,
    createMarket
}