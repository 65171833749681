import glamorous from 'glamorous'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const TestimonialsSectionStyle = glamorous.section({
    position: 'relative',
    display: 'block',
    width: '100%',
    overflow: 'hidden',
    backgroundColor: '#06070A',
    padding: '60px 0 10px',

    '& .container': {
        paddingLeft: 0,
        paddingRight: 0,
        maxWidth: '100%',

        '& .section-title': {
            color: '#FFF',
            fontSize: '26px',
            fontWeight: 700,
            textAlign: 'center',

            '& small': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '206px',
                margin: '15px auto 0',
                fontSize: '17px',

                '& .tp-logo-wrapper': {
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    marginTop: '-10px',

                    '& img': {
                        '&:first-child': {
                            width: '27px'
                        },

                        '&:last-child': {
                            marginLeft: '-7px',
                            height: '16px'
                        }
                    }
                }
            }
        },

        '& .carousel': {
            '& .carousel-inner': {
                padding: '0 0 35px',

                '& .carousel-item': {
                    padding: '35px 28px',

                    '& .testimony-list': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '0 auto',
                        padding: 0,
                        listStyleType: 'none',

                        '& .testimony': {
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            width: '422px',
                            minHeight: '500px',
                            borderRadius: '3rem',
                            //boxShadow: '0.8rem 0.8rem 1.4rem #111C29, -0.2rem -0.2rem 1.8rem #111C29',
                            backgroundColor: '#111C29',
                            padding: '5rem 2rem 2rem 48px',
                            marginRight: '20px',
                            color: '#A3B0C5',
                            fontStyle: 'italic',
                            textAlign: 'left',

                            '& h6': {
                                fontSize: '17px',
                                fontWeight: 600,
                                marginBottom: '20px'
                            },

                            '& p': {
                                fontSize: '15px',
                                fontWeight: 400,
                                lineHeight: '1.5',

                                '& .emoji': {
                                    height: '20px',
                                    marginLeft: '3px',
                                    marginBottom: '5px'
                                }
                            },

                            '& .author': {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                width: '100%',
                                marginTop: 'auto',
                                fontStyle: 'normal',

                                '& .user-name': {
                                    textAlign: 'right',

                                    '& > label': {
                                        fontSize: '18px',
                                        fontWeight: 600
                                    },

                                    '& > img': {
                                        display: 'block',
                                        height: '22px'
                                    }
                                },
                                
                                '& .user-avatar': {
                                    width: '70px',
                                    height: '70px',
                                    borderRadius: '35px',
                                    overflow: 'hidden',
                                    marginLeft: '15px',

                                    '& img': {
                                        width: '100%'
                                    }
                                }
                            },

                            '&:before': {
                                display: 'block',
                                position: 'absolute',
                                content: ' ',
                                left: '25px',
                                top: '30px',
                                width: '30px',
                                height: '21px',
                                backgroundImage: 'url(/images/offers/pf-tml-quote-1.png)',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat'
                            },

                            '&:last-child': {
                                marginRight: 0
                            }
                        }
                    }
                }
            },

            '& .carousel-control-prev': {
                left: '-180px'
            },

            '& .carousel-control-next': {
                right: '-180px'
            }
        }
    },

    [mediaQueries.mediumDevices]: {
        '& .container': {
            '& .section-title': {
                fontSize: '30px',

                '& small': {
                    fontSize: '19px',
                    width: '241px',

                    '& .tp-logo-wrapper': {
                        '& img': {
                            '&:first-child': {
                                width: '34px'
                            },

                            '&:last-child': {
                                height: '20px'
                            }
                        }
                    }
                }
            }
        }
    },

    [mediaQueries.largeDevices]: {
        padding: '60px 0',

        '& .container': {
            '& .carousel': {
                '& .carousel-control-prev': {
                    left: '-42px'
                },
    
                '& .carousel-control-next': {
                    right: '-42px'
                }
            }
        }
    },

    [mediaQueries.extraLargeDevices]: {
        padding: '80px 0 60px',

        '& .container': {
            paddingLeft: '15px',
            paddingRight: '15px',
            maxWidth: '1920px',

            '& .section-title': {
                fontSize: '36px',

                '& small': {
                    width: '301px',
                    fontSize: '24px',

                    '& .tp-logo-wrapper': {
                        '& img': {
                            '&:first-child': {
                                width: '40px'
                            },
    
                            '&:last-child': {
                                marginLeft: '8px',
                                height: '25px'
                            }
                        }
                    }
                }
            },

            '& .carousel': {
                '& .carousel-inner': {                    
                    '& .carousel-item': {
                        padding: '35px 0',

                        '& .testimony-list': {
                            '& .testimony': {
                                minHeight: '572px',

                                '& h6': {
                                    fontSize: '23px'
                                },
    
                                '& p': {
                                    fontSize: '17px'
                                }
                            }
                        }
                    }
                },

                '& .carousel-control-prev': {
                    left: '-90px'
                },
    
                '& .carousel-control-next': {
                    right: '-90px'
                }
            }
        }
    },

    [mediaQueries.superExtraLargeDevices]: {
        '& .container': {
            '& .carousel': {
                '& .carousel-control-prev': {
                    left: '-180px'
                },
    
                '& .carousel-control-next': {
                    right: '-180px'
                }

            }
        }
    }
})