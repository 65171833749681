import styled from 'styled-components'

export const CloseButton = styled.div`
    background: none;
    border: none;
    outline: none;
    color: #d0d2d4;
    font-size: 20px;
    position: absolute;
    cursor: pointer;
    z-index: 100;
    top: -15px;
    right: -8px;
    width: 50px;
    background-color: #993956;
    border-radius: 2px;
    line-height: 29px;
    text-align: center;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        color: #fff;
    }

    @media screen and (min-width: 320px) and (max-width: 414px) {
        right: 10px;
    }
`

export const CheckList = styled.div`
    padding: 15px;

    .form-group {
        .form-check {
            margin-bottom: 10px;

            .form-check-label {
                font-size: 14px;
            }
        }

        .custom-control {
            display: flex;
            margin-bottom: 10px;
            padding-bottom: 10px !important;
            border-bottom: 1px solid #222c38;
            width: 100%;
            padding-left: 2rem;
            padding-right: 10px;

            .custom-control-input:disabled~.custom-control-label,
            .custom-control-input[disabled]~.custom-control-label {
                color: #4f5a67;

                &::before {
                    background-color: #192431;
                    border: 2px solid #333f4e;
                }

                &.checked {
                    &::before {
                        background-color: #238636;
                        border: 2px solid #42c75c;
                    }
                }

                &.active {
                    &::before {
                        background-color: #192431;
                        border: 2px solid #606f80;
                    }
                }
            }

            #custom-inline-checkbox-0,
            #custom-inline-checkbox-2 {
                &::after {
                    cursor: pointer;
                }
            }

            .custom-control-label {
                font-size: 14px;
                width: 100%;

                &.active {
                    font-weight: bold;
                    color: #61940f !important;
                }

                &::before {
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                    background-color: #1e3824;
                    left: -2rem;
                    width: 20px;
                    height: 20px;
                    content: "";
                    display: block;
                    pointer-events: none;
                    border: #fff solid 2px;
                }

                &::after {
                    top: 0rem;
                    bottom: 0;
                    margin: auto 0;
                    position: absolute;
                    left: -2rem;
                    width: 20px;
                    height: 20px;
                    content: "";
                    display: block;
                    background: no-repeat 50%/50% 50%;
                }

                &.checked {
                    text-decoration: line-through;
                    color: #6e7885 !important;
                }
            }

            &:last-child {
              margin-bottom: 0;
              border-bottom: none;
            }
        }

        .hover-icon {
            display: flex;
            align-items: center;

            i {
                font-size: 20px;
                width: auto;
                height: auto;
                text-align: center;
                border-radius: 50%;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
`

export const ProgressWrapper = styled.div`
    width: 100%;
    text-align: center;

    .progress {
        height: 20px;
        background: #222C38;
        text-shadow: 1px 1px 10px rgba(0,0,0,0.3);
        letter-spacing: 2px;
        border-radius: 10px;
        margin-bottom: 5px;

        .progress-bar {
            background-color: #28a745;
        }
    }

    span {
        width: 100%;
        text-align: center;        
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 12px;
        color: #fff;
    }
`

export const CheckListTitle = styled.span`
  font-size: 20px;
  font-weight: 600;
  text-align: left;
`

export const CheckListWrapper = styled.div`
    padding: 15px;
    width: 100%;

	&.show {
		transition: all 300ms ease-in-out;
		margin-left: -80px;
	}

	@media screen and (min-width: 768px) and (max-width: 1023px) {
        padding: 0 10px;

		&.show {
		   transition: all 300ms ease-in-out;
		   margin-left: 0;
	   }

        ${CheckListTitle} {
             font-size: 16px;
        }

        ${CheckList} {
            .form-group {
                .custom-control {
                    .custom-control-label {
                        font-size: 11px !important;
                    }
                }

                .hover-icon {
                    display: flex;
                    align-items: center;

                    i {
                        font-size: 12px;
                        width: auto;
                        text-align: center;
                    }
                }
            }

			${ProgressWrapper} {
                span {
                    font-size: 12px;
                }
            }
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        padding: 0 10px;

		&.show {
		   transition: all 300ms ease-in-out;
		   margin-left: 0;
	   }

        ${CheckListTitle} {
             font-size: 16px;
        }

        ${CheckList} {
            .form-group {
                .custom-control {
                    .custom-control-label {
                        font-size: 11px !important;
                    }
                }

                .hover-icon {
                    display: flex;
                    align-items: center;

                    i {
                        font-size: 14px;
                        width: auto;
                        text-align: center;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        ${CheckListTitle} {
             font-size: 16px !important;
        }

        ${CheckList} {
            .form-group {
                .custom-control {
                    padding-left: 1.5rem;

                    .custom-control-label {
                        font-size: 11px;
                    }
                }

                .hover-icon {
                    display: flex;
                    align-items: center;

                    i {
                        font-size: 14px;
                        width: auto;
                        text-align: center;
                    }
                }
            }

            ${ProgressWrapper} {
                span {
                    font-size: 12px;
                }
            }
        }
    }

    @media screen and (min-width: 1441px) and (max-width: 1749px) {
        ${CheckListTitle} {
             font-size: 18px;
        }

        ${CheckList} {
            .form-group {
                .custom-control {
                    .custom-control-label {
                        font-size: 14px;
                    }
                }

                .hover-icon {
                    display: flex;
                    align-items: center;

                    i {
                        font-size: 16px;
                        width: auto;
                        text-align: center;
                    }
                }
            }

            ${ProgressWrapper} {
                span {
                    font-size: 14px;
                }
            }
        }
    }
`