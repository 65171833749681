import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { ThreeYearStatsSectionStyle } from './styles'
import { mediaDeviceSizes } from '../../../constants/mediaQueries'
import { getSize, isNull, isNotNull } from '../../../utils'

export const ThreeYearStatsSection = (props) => {
    let [device, setDevice] = useState(null)

    useEffect(() => {
        setDevice(getSize(window.innerWidth))
    }, [])

    return (
        <ThreeYearStatsSectionStyle>
            <div className='container'>
                <h2 className='section-title'>In the Last 3 Years, our Members had Access to 77,000% Peak Gains</h2>

                <div className='stats-content'>
                    <div className='stats-peak-gains'>
                        <div className='rocket-img'>
                            <img src='/images/layout/PF-rocket-image.png' alt='ProfitFarmers Rocket' />
                        </div>
                        <div className='value'>77,000%<br /><span>GAINS</span></div>
                    </div>
                    <div className='stats-peak-gains-breakdown'>
                        <div className='counter'>
                            <div className='value'>
                                <span className='icon'>
                                    <img src='/images/offers/signals-produced.svg' alt='' />
                                </span>
                                <span className='text'>
                                    6,020
                                    <small>Signals Produced</small>
                                </span>
                            </div>
                            <div className='value'>
                                <span className='icon'>
                                    <img src='/images/offers/peak-gains.svg' alt='' />
                                </span>
                                <span className='text'>
                                    77,000%
                                    <small>Total Peak Gains</small>
                                </span>
                            </div>
                        </div>

                        { (device === mediaDeviceSizes.xs || device === mediaDeviceSizes.sm) && 
                            <Carousel className='top-signals-carousel'>
                                <Carousel.Item>
                                    <img src='/images/offers/signal-1.png' alt='Top signal' />        
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src='/images/offers/signal-2.png' alt='Top signal' />      
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src='/images/offers/signal-3.png' alt='Top signal' />     
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src='/images/offers/signal-4.png' alt='Top signal' />     
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src='/images/offers/signal-5.png' alt='Top signal' />      
                                </Carousel.Item>
                            </Carousel>
                        }

                        { !(device === mediaDeviceSizes.xs || device === mediaDeviceSizes.sm) && 
                            <div className='top-signals'>
                                <img src='/images/offers/signal-1.png' alt='Top signal' />
                                <img src='/images/offers/signal-2.png' alt='Top signal' />
                                <img src='/images/offers/signal-3.png' alt='Top signal' />
                                <img src='/images/offers/signal-4.png' alt='Top signal' />
                                <img src='/images/offers/signal-5.png' alt='Top signal' />
                            </div>
                        }

                        <p>80+ signals with over 100% gains</p>
                    </div>
                </div>
            </div>
        </ThreeYearStatsSectionStyle>
    )
}