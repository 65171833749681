import styled from 'styled-components'
import { mediaQueries } from '../../constants/mediaQueries'

export const DashboardPageLayout = styled.main`
    position: relative;
    font-family: Montserrat, sans-serif;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0;

    .back-drop {
		display: none;
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background: #00000094;
		z-index: 16;
		transition: all 300ms ease-in-out;
	}

	&.show {
		.back-drop {
			display: block;
			cursor: pointer;
		}
	}

    &.notif-permission {
		margin-top: 79px;
    }

    ${mediaQueries.mediumDevices} {
        &.notif-permission {
            margin-top: 36px;
        }
    }

    ${mediaQueries.largeDevices} {
        padding-left: 60px;
    }

    ${mediaQueries.extraLargeDevices} {
        padding-left: 278px;
        
        &.show {
            padding-left: 278px;
        }
    }
`