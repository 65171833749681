import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import {
    DataSignalGridWrapper, DataSignalGrid, DataSignals,
    SignalN, SignalI, ToggleButton, DataPreActions,
    CoinLogo, CoinName, SignalPrice, SignalVol, SignalMarketCap,
    SignalPeakGains, WaveInfoContainer,
    CoinInfoContainer, GridBlock, MoreInfoWrapper,
    MoreInfo, MoreInfoTitle, MoreInfoValue, DataActions
} from '../styled_components'
import { setCoinLogo, setDefaultImage, getCoin, ScientificToDecimal } from '../../../utils'

export class RelativeStrengthIndexItem extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            watch: this.props.watchListed
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isWatched !== this.props.isWatched)
            this.setState({ watch: this.props.watchListed })
    }

    onGetRsiStyleHandler(rsiValue) {
        if (parseFloat(rsiValue) > 70)
            return "signal-i border-blue"
        else if (parseFloat(rsiValue) < 30 && parseFloat(rsiValue) !== 0)
            return "signal-i border-red"
        else
            return "signal-i"
    }

    onGetRsiFilterDataHandler(rsiFilterValue) {
        let { rsi7, rsi14 } = this.props
        return rsiFilterValue === "rsi7" ? rsi7 : rsi14
    }

    onExpand = (coinName) => this.props.onExpand(coinName)

    onSaveWatchedPairedHandler = (data) => {
        let flag = false
        if (data.isWatched !== false) {
            flag = false
            this.setState({ watch: true })
        }
        else {
            flag = true
            this.setState({ watch: false })
        }
        this.props.handleChange(data.n, flag)
    }

    render() {
        const { watch } = this.state
        const { rsiFilter, n, cp, pm, fm, v } = this.props
        // Implement with borders
        const rsi5mStyle = this.onGetRsiStyleHandler(this.onGetRsiFilterDataHandler(rsiFilter).rsi5m)
        const rsi15mStyle = this.onGetRsiStyleHandler(this.onGetRsiFilterDataHandler(rsiFilter).rsi15m)
        const rsi30mStyle = this.onGetRsiStyleHandler(this.onGetRsiFilterDataHandler(rsiFilter).rsi30m) + " hidden-m"
        const rsi1hStyle = this.onGetRsiStyleHandler(this.onGetRsiFilterDataHandler(rsiFilter).rsi1h)
        const rsi4hStyle = this.onGetRsiStyleHandler(this.onGetRsiFilterDataHandler(rsiFilter).rsi4h) + " hidden-m"
        const rsi6hStyle = this.onGetRsiStyleHandler(this.onGetRsiFilterDataHandler(rsiFilter).rsi6h)
        const rsi12hStyle = this.onGetRsiStyleHandler(this.onGetRsiFilterDataHandler(rsiFilter).rsi12h) + " hidden-m"
        const rsi1dStyle = this.onGetRsiStyleHandler(this.onGetRsiFilterDataHandler(rsiFilter).rsi1d)

        // RSI Scanner Text
        const rsi5mText = this.onGetRsiFilterDataHandler(rsiFilter).rsi5m.toFixed(2)
        const rsi15mText = this.onGetRsiFilterDataHandler(rsiFilter).rsi15m.toFixed(2)
        const rsi30mText = this.onGetRsiFilterDataHandler(rsiFilter).rsi30m.toFixed(2)
        const rsi1hText = this.onGetRsiFilterDataHandler(rsiFilter).rsi1h.toFixed(2)
        const rsi4hText = this.onGetRsiFilterDataHandler(rsiFilter).rsi4h.toFixed(2)
        const rsi6hText = this.onGetRsiFilterDataHandler(rsiFilter).rsi6h.toFixed(2)
        const rsi12hText = this.onGetRsiFilterDataHandler(rsiFilter).rsi12h.toFixed(2)
        const rsi1dText = this.onGetRsiFilterDataHandler(rsiFilter).rsi1d.toFixed(2)
        const livefibo = `${fm.toFixed(2).toLocaleString()}%`
        const volume = Math.trunc(Math.round(v, 0)).toLocaleString()
        const price = ScientificToDecimal(cp)
        const d3move = pm.toFixed(2)
        const logoImage = setCoinLogo(getCoin(n))
        const moreInfoClassAssign = (watch) ? 'watch-active' : ''

        return (
            <DataSignalGridWrapper className="rsi-grid theme-grid-wrapper">
                <DataSignalGrid className="theme-grid">
                    <DataPreActions>
                        <ToggleButton
                            onClick={() => this.onExpand(n)}>
                            <FontAwesomeIcon icon={this.props.onDisplay(n) === "block" ? faChevronUp : faChevronDown} className="icon" />
                        </ToggleButton>
                    </DataPreActions>
                    <DataSignals className="grid-col">
                        <SignalN className="sn-m">
                            <WaveInfoContainer>
                                <CoinInfoContainer>
                                    <CoinLogo>
                                        <img onError={setDefaultImage} src={`${logoImage}?1`} alt="coin logos" />
                                    </CoinLogo>
                                    <CoinName>{n}</CoinName>
                                </CoinInfoContainer>
                            </WaveInfoContainer>
                        </SignalN>

                        <SignalI className={`s1-${n} ${rsi5mStyle}`}>
                            <span className={`rsi5mText-${n}`}>{rsi5mText !== 0 ? rsi5mText : "0.00"}</span>
                        </SignalI>
                        <SignalI className={`s2-${n} ${rsi15mStyle}`}>
                            <span className={`rsi15mText-${n}`}>{rsi15mText !== 0 ? rsi15mText : "0.00"}</span>
                        </SignalI>
                        <SignalI className={`s3-${n} ${rsi30mStyle}`}>
                            <span className={`rsi30mText-${n}`}>{rsi30mText !== 0 ? rsi30mText : "0.00"}</span>
                        </SignalI>
                        <SignalI className={`s4-${n} ${rsi1hStyle}`}>
                            <span className={`rsi1hText-${n}`}>{rsi1hText !== 0 ? rsi1hText : "0.00"}</span>
                        </SignalI>
                        <SignalI className={`s5-${n} ${rsi4hStyle}`}>
                            <span className={`rsi4hText-${n}`}>{rsi4hText !== 0 ? rsi4hText : "0.00"}</span>
                        </SignalI>
                        <SignalI className={`s6-${n} ${rsi6hStyle}`}>
                            <span className={`rsi6hText-${n}`}>{rsi6hText !== 0 ? rsi6hText : "0.00"}</span>
                        </SignalI>
                        <SignalI className={`s7-${n} ${rsi12hStyle}`}>
                            <span className={`rsi12hText-${n}`}>{rsi12hText !== 0 ? rsi12hText : "0.00"}</span>
                        </SignalI>
                        <SignalI className={`s8-${n} ${rsi1dStyle}`}>
                            <span className={`rsi1dText-${n}`}>{rsi1dText !== 0 ? rsi1dText : "0.00"}</span>
                        </SignalI>
                        <SignalPrice className="text-right">
                            <span className={`cp-${n}`}>{price !== 0 ? price : "0.00"}</span>
                        </SignalPrice>
                        <SignalVol className="text-right">
                            <span className={`volume-${n}`}>{volume !== 0 ? volume : "0.00"}</span>
                        </SignalVol>
                        <SignalMarketCap className="text-right" color={d3move < 0 ? "#EB2436" : d3move > 0 ? "#01bf86" : ""}>
                            <span className={`d3move-${n} d3-data`}>{d3move !== 0 ? `${d3move}%` : "0.00%"}</span>
                        </SignalMarketCap>
                        <SignalPeakGains className="text-right">
                            <span className={`livefibo-${n}`}>{livefibo !== 0 ? livefibo : "0.00"}</span>
                        </SignalPeakGains>
                    </DataSignals>
                    <DataActions className="grid-col has-btns pa-m">
                        <ToggleButton onClick={() => this.onSaveWatchedPairedHandler(this.props)}>
                            <FontAwesomeIcon icon={faEye} className={moreInfoClassAssign} ></FontAwesomeIcon>
                        </ToggleButton>
                    </DataActions>
                </DataSignalGrid>
                <GridBlock className={`grid-block`} style={{ display: this.props.onDisplay(n) }}>
                    <MoreInfoWrapper>
                        <MoreInfo>
                            <MoreInfoTitle>Coin Name</MoreInfoTitle>
                            <MoreInfoValue>{n}</MoreInfoValue>
                        </MoreInfo>
                        <MoreInfo>
                            <MoreInfoTitle>Price</MoreInfoTitle>
                            <MoreInfoValue className={`cp-${n}`}>{price}</MoreInfoValue>
                        </MoreInfo>
                        <MoreInfo>
                            <MoreInfoTitle>Volume</MoreInfoTitle>
                            <MoreInfoValue className={`volume-${n}`}>{volume}</MoreInfoValue>
                        </MoreInfo>
                        <MoreInfo>
                            <MoreInfoTitle>D3 Move</MoreInfoTitle>
                            <MoreInfoValue className={`d3move-${n} d3-data`} color={d3move < 0 ? "#EB2436" : d3move > 0 ? "#01bf86" : ""}>{d3move !== 0 ? `${d3move}%` : "0.00%"}</MoreInfoValue>
                        </MoreInfo>
                        <MoreInfo>
                            <MoreInfoTitle>Price Action</MoreInfoTitle>
                            <MoreInfoValue className={`livefibo-${n}`}>{livefibo}</MoreInfoValue>
                        </MoreInfo>
                        <MoreInfo>
                            <ToggleButton onClick={() => this.onSaveWatchedPairedHandler(this.props)} className={moreInfoClassAssign}>
                                <FontAwesomeIcon icon={faEye}  ></FontAwesomeIcon> Add to Watchlist
                            </ToggleButton>
                        </MoreInfo>
                    </MoreInfoWrapper>
                </GridBlock>
            </DataSignalGridWrapper>
        )
    }
}
