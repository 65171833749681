import React from 'react'
import { connect } from 'react-redux'
import { OverlayTrigger, Tooltip, SafeAnchor } from 'react-bootstrap'
import { statisticActions } from '../../redux/actions'
import { ButtonGroup } from '@mui/material'
import { isNull, isNotNull, onSetNewState } from '../../utils'

class RollingPeriodNetPeakGains extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            rollingPeriod: 0,
            netPeakGainsData: null
        }

        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true

        const { dispatch } = this.props
        const { rollingPeriod } = this.state
        dispatch(statisticActions.getNetPeakGains(rollingPeriod))
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { netPeakGains } = this.props
            const { result } = netPeakGains
            const { isLoading, netPeakGainsData } = this.state

            if (isLoading && !netPeakGains.loading) {
                if (isNull(netPeakGainsData)) {
                    onSetNewState(this, {
                        isLoading: false,
                        netPeakGainsData: result.data
                    })
                } else {
                    if (prevProps.netPeakGains !== netPeakGains) {
                        onSetNewState(this, {
                            isLoading: false,
                            netPeakGainsData: result.data
                        })
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    handleChangeRollingPeriod = (e, period) => {
        e.preventDefault()
        const { dispatch } = this.props
        onSetNewState(this, {
            isLoading: true,
            rollingPeriod: period
        }, () => {            
            dispatch(statisticActions.getNetPeakGains(period))
        })
    }

    renderPeakGains = () => {
        const { netPeakGainsData } = this.state
        if (isNotNull(netPeakGainsData)) {
            let newValue = Math.round(netPeakGainsData.netPeakGains * 100) / 100
            if (parseFloat(newValue) > 0)
                return (<span className='result-value pos-val'>+{newValue}%</span>)

            return (<span className='result-value neg-val'>{newValue}%</span>)
        }

        return (<span className='result-value'>--</span>)
    }

    renderWinningResult = () => {
        const { netPeakGainsData } = this.state
        if (isNotNull(netPeakGainsData)) {
            let newValue = Math.round(netPeakGainsData.percentageGains * 100) / 100            
            return (
                <li className='winning'>
                    <span className='text-label'>WINNING</span>                                
                    <span className='total'>{netPeakGainsData.totalWinners}</span>
                    <span className='percentage'>+{newValue}%</span>
                </li>
            )
        }

        return (
            <li className='winning'>
                <span className='text-label'>WINNING</span>                                
                <span className='total'>--</span>
                <span className='percentage'>0.00%</span>
            </li>
        )
    }

    renderLosingResult = () => {
        const { netPeakGainsData } = this.state
        if (isNotNull(netPeakGainsData)) {
            let newValue = Math.round(netPeakGainsData.percentageLoss * 100) / 100            
            return (
                <li className='losing'>
                    <span className='text-label'>LOSING</span>                                
                    <span className='total'>{netPeakGainsData.totalLosers}</span>
                    <span className='percentage'>{newValue}%</span>
                </li>
            )
        }

        return (
            <li className='losing'>
                <span className='text-label'>LOSING</span>                                
                <span className='total'>--</span>
                <span className='percentage'>0.00%</span>
            </li>
        )
    }

    renderClosedEarlyResult = () => {
        const { netPeakGainsData } = this.state
        if (isNotNull(netPeakGainsData)) {
            let newValue = Math.round(netPeakGainsData.percentageEarlyClosedTradeResult * 100) / 100            
            return (
                <li className='closed-early'>
                    <span className='text-label'>CLOSED EARLY</span>
                    <span className='total'>{netPeakGainsData.totalEarlyClosedTrades}</span>
                    <span className='percentage'>{ parseFloat(newValue) > 0 ? `+${newValue}` : newValue }%</span>
                </li>
            )
        }

        return (
            <li className='closed-early'>
                <span className='text-label'>CLOSED EARLY</span>
                <span className='total'>--</span>
                <span className='percentage'>0.00%</span>
            </li>
        )
    }

    render() {
        const { rollingPeriod, netPeakGainsData } = this.state
        return (
            <>            
                <div className='header'>
                    <h2>
                        <span>PRO Signals Results Summary</span>
                        <OverlayTrigger placement="right" delay={{ show: 200, hide: 1000}} overlay={
                            <Tooltip id='tooltip-right' className="signals-tooltip">
                                <SafeAnchor href="https://www.profitfarmers.com/blog/net-peak-gains-calculation/" target="_blank">
                                    Learn How This Calculator Works
                                </SafeAnchor>
                            </Tooltip>
                        }>
                            <span className="icon-question">
                                <i className="fas fa-question-circle"></i>
                            </span>
                        </OverlayTrigger>
                    </h2>
                    <div className='actions'>
                        <span className='actions-label'>Date Range</span>
                        <ButtonGroup className="period-selector" aria-label="outlined primary button group">
                            <button type='button' className={`toggle-btn ${rollingPeriod === 0 ? 'selected': ''}`} onClick={(e) => this.handleChangeRollingPeriod(e, 0)}>30 D</button>
                            <button type='button' className={`toggle-btn ${rollingPeriod === 1 ? 'selected': ''}`} onClick={(e) => this.handleChangeRollingPeriod(e, 1)}>90 D</button>
                        </ButtonGroup>
                    </div>
                </div>
                
                <h3 className='time-range mp-only'>{isNotNull(netPeakGainsData) ? netPeakGainsData.timestamp : '--'} <span className='dot'></span></h3>
                
                <div className='results-wrapper'>                    
                    <div className='net-peak-gains'>
                        <div className='result-icon-wrapper'>
                            <img src="/images/icons/net-peak-gains.svg" alt='Net Peak Gains Icon' />
                        </div>
                        <div className='net-peak-gains-details'>
                            { this.renderPeakGains() }
                            <span className='result-label'>NET PEAK GAINS</span>
                            <div className='legends'>
                                <span className='legend winner'></span>
                                <span className='legend loser'></span>
                                <span className='legend ect'></span>
                            </div>
                        </div>
                    </div>
                    <div className='net-peak-gains-breakdown'>
                        <h3 className='time-range'>{isNotNull(netPeakGainsData) ? netPeakGainsData.timestamp : '--'} <span className='dot'></span></h3>
                        <ul className='the-breakdown'>
                            {this.renderWinningResult()}
                            {this.renderLosingResult()}
                            {this.renderClosedEarlyResult()}
                        </ul>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    const { authentication, netPeakGains } = state
    return {
        authentication,
        netPeakGains
    }
}

const connectedRollingPeriodNetPeakGains = connect(mapStateToProps)(RollingPeriodNetPeakGains)
export { connectedRollingPeriodNetPeakGains as RollingPeriodNetPeakGains }