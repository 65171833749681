import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { NotificationPreview } from '../Notification'
import { ModalBodySectionWrapper, AgreeButton } from './styled_components'
import { isNotNull } from '../../utils'

export const LayoutModalView = (props) => {
    const { isTradeAllowed } = props
    const {
        isDeviceSingout, hasAgreedToWeekly, previewNotification,
        isAgreeRequested, viewAutoTradeMessage
    } = props.state
    const { user } = props.authentication

    const onModalShowHandler = () => {
        const loginType = JSON.parse(localStorage.getItem('profitfarmers.type'))
        let modals = {
            isShow: false,
            isDeviceConfirmSignout: false,
            isDeviceLogout: false,
            isAggreedToWeeklyShow: false,
            isNotification: false,
            isAutoTrade: false,
            id: null,
            size: null
        }

        if (loginType === 4) {
            modals.id = "alreadyLoggedIn"
            modals.isShow = true
            modals.isDeviceConfirmSignout = true
            modals.isDeviceLogout = false
            modals.isNotification = false
            modals.isAutoTrade = false
        } else if (isDeviceSingout === true) {
            modals.id = "alreadyLoggedIn"
            modals.isShow = isDeviceSingout
            modals.isDeviceConfirmSignout = false
            modals.isDeviceLogout = true
            modals.isNotification = false
            modals.isAutoTrade = false
        } else if (previewNotification === true) {
            modals.id = "notificationModal"
            modals.isShow = true
            modals.isDeviceConfirmSignout = false
            modals.isDeviceLogout = false
            modals.isNotification = true
            modals.isAutoTrade = false
        } else if (viewAutoTradeMessage) {
            modals.id = "autoTradingModal"
            modals.isShow = true
            modals.isDeviceConfirmSignout = false
            modals.isDeviceLogout = false
            modals.isNotification = false
            modals.isAutoTrade = true
        } else {
            if (isNotNull(user)) {
                modals.id = hasAgreedToWeekly === false ? "modalRiskReminder" : null
                modals.isShow = hasAgreedToWeekly === false ? true : false
                modals.isAggreedToWeeklyShow = hasAgreedToWeekly === false ? true : false
                modals.size = "lg"
            }
            modals.isDeviceConfirmSignout = false
            modals.isDeviceLogout = false
            modals.isNotification = false
            modals.isAutoTrade = false
            modals.size = null
        }
        return modals
    }

    const onDeselectHandler = (modalHandler) => {
        if (modalHandler.isNotification)
            return props.closeNotificationPreview()
        else if (modalHandler.isAutoTrade)
            return props.onViewAutoTradeErrorMessageHandler(false)
        return null
    }

    const renderAggrementModalContent = () => (
        <ModalBodySectionWrapper className="text-light">
            <section className="header-wrapper text-center">
                <img src={"/images/disclaimer/bug.png"} className="img-fluid" width={70} height={70} alt="bug logo" />
                <label className="text-light text-center risk-text">Friendly Risk Reminder</label>
            </section>
            <section className="message-wrapper">
                <h4>Hey there ProfitFarmer!</h4>
                <p>Your safety and security are extremely important to us and we go to lengths to ensure everything is running smoothly. Nevertheless we cannot guarantee a perfect operation at all times.</p>
                <p>We want to remind you of the following risk you must accept when using our system as we cannot accept liability for any losses, including for the following situations:</p>
                <ul className="fa-ul">
                    <li className="m-3">
                        <span className="fa-li"><img src={"/images/disclaimer/bug.png"} className="img-fluid" width="25" alt="bug logo" /></span>
                        <span>Bugs or other technical glitches</span>
                    </li>
                    <li className="m-3">
                        <span className="fa-li"><img src={"/images/disclaimer/hacker.png"} className="img-fluid" width="20" alt="hacker logo" /></span>
                        <span>Hacks or other data breaches</span>
                    </li>
                    <li className="m-3">
                        <span className="fa-li"><img src={"/images/disclaimer/server-down.png"} className="img-fluid" width="20" alt="server-down logo" /></span>
                        <span>Server downtime or issue related to 3rd parties such as TrailingCrypto and Binance</span>
                    </li>
                    <li className="m-3">
                        <span className="fa-li"><img src={"/images/disclaimer/low-market.png"} className="img-fluid" width="20" alt="low-market logo" /></span>
                        <span>Poor signal performance or the market crashing</span>
                    </li>
                </ul>
            </section>
            <section className="footer-wrapper text-center">
                <i className="remember-text fas fa-exclamation-circle mr-2"></i>
                <label className="remember-text text"> Remember, Your Money = Your Responsibility</label>
            </section>
            <div className="wrapper d-block text-center">
                <AgreeButton className="btn btn-primary" disabled={isAgreeRequested} onClick={() => props.hasAgreedToWeeklyDisclaimer()}>
                    <span>I HAVE READ AND UNDERSTOOD</span>
                </AgreeButton>
            </div>
        </ModalBodySectionWrapper>
    )

    const renderNotificationModalContent = () => (
        <NotificationPreview
            isTradeAllowed={isTradeAllowed}
            handleClose={() => props.closeNotificationPreview()}
            notificationPreviewClose={(data, isPreview, isPushPreview) => props.onNotificationPreviewCloseHandler(data, isPreview, isPushPreview)}
            updateSelectedNotification={(e) => props.updateSelectedNotificationHandler(e)}
            openTradeManual={(e) => props.openTradeManual(e)}
            onCloseNotificationPreviewSubmittedHandler={(e) => props.onCloseNotificationPreviewSubmittedHandler(e)}
            {...props.state}
        />
    )

    const renderAutoTradeMessage = () => (
        <>
            <div className="close" onClick={() => props.onViewAutoTradeErrorMessageHandler(false)} style={{ zIndex: '1' }}>
                <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </div>
            <div className="icon-warning">
                <i className="fas fa-exclamation-triangle"></i>
            </div>
            <h3> Turn On Auto-trade? </h3>
            <p>To enable auto-trade in your account, <Link to="/account/auto-trade" onClick={() => props.onViewAutoTradeErrorMessageHandler(false)}>go to settings</Link>.</p>
        </>
    )

    const renderModalBody = (modalHandler) => {
        if (modalHandler.isAggreedToWeeklyShow === true)
            return renderAggrementModalContent()
        if (modalHandler.isNotification === true)
            return renderNotificationModalContent()
        if (modalHandler.isAutoTrade === true)
            return renderAutoTradeMessage()
    }

    const modalHandler = onModalShowHandler()
    return (
        <Modal
            id={modalHandler.id}
            className={`generic-modal ${modalHandler.id}`}
            show={modalHandler.isShow}
            size={modalHandler.size}
            style={modalHandler.isAggreedToWeeklyShow === true ? { color: '#15bd70' } : null}
            onHide={() => onDeselectHandler(modalHandler)}
            aria-labelledby="genericModal"
            aria-hidden="true"
            data-backdrop="true"
            keyboard={false}
            centered
        >
            <Modal.Body className={modalHandler.isAutoTrade ? "asl-waring" : ""}>
                {renderModalBody(modalHandler)}
            </Modal.Body>
        </Modal>
    )
}