import React from 'react'
import { BreakevenSectionStyle } from './styles'
import ReactPlayer from 'react-player/lazy'

export const BreakevenSection = () => {
    return (
        <BreakevenSectionStyle>
            <div className='container'>
                <div className='text-with-vid'>
                    <div className='text-col'>
                        <img src='/images/trial-offer/hot-feature.png'/>
                        <h2 className='section-title'>BREAKEVEN STOP LOSS</h2>
                        <p>This smart feature automatically moves your stop loss up when your trade hits the next target. That way, you can lock in profit.</p>
                        <p>Here are just some of the benefits:</p>

                        <ul className='benefits-list'>
                            <li className='list-item'>Maximise your profits during trend changes</li>
                            <li className='list-item'>Minimise your losses during choppy price action</li>
                            <li className='list-item'>Spend less time watching the charts</li>
                        </ul>
                    </div>
                    <div className='video-wrapper'>
                        <ReactPlayer 
                        url={'https://player.vimeo.com/video/689546399'}
                        controls={true} 
                        width='100%' 
                        volume={0} />
                    </div>
                </div>
            </div>
        </BreakevenSectionStyle>
    )
}