import React from 'react'
import { SafeAnchor } from 'react-bootstrap'
import { GuideArticlesStyle } from "./style/GuideArticlesStyle"

export class GuideArticles extends React.Component {

    render() {
        
        return (
            <GuideArticlesStyle>
                <ul className='guide-article-list'>
                    <li className='guide-article-list-item trade-guides'>
                        <SafeAnchor href="https://www.profitfarmers.com/blog/super-powerful-riskreward-trading-features/" target="_blank" className='guide-article-list-item-link'>
                            <img src='../images/guide-articles/risk-reward-featured-image.jpg' alt='Our Super Powerful Risk:Reward Trading Features Explained' />
                            <span className='guide-article-item-details'>
                                <p className='guide-article-item-category'>Trading Guides</p>
                                <p className='guide-article-item-title'>Our Super Powerful Risk:Reward Trading Features Explained</p>
                            </span>
                        </SafeAnchor>
                    </li>
                    <li className='guide-article-list-item trade-guides'>
                        <SafeAnchor href="https://youtu.be/77lt0P7lqZw" target="_blank" className="guide-article-list-item-link">
                            <img src="../images/guide-articles/how-to-5x-your-trading-account-using-aggressive-heiko-strategy.png" alt="How to 5x Your Trading Account Using Aggressive Heiko Strategy" />
                            <span className='guide-article-item-details'>
                                <p className='guide-article-item-category'>Trading Guides</p>
                                <p className='guide-article-item-title'>How to 5x Your Trading Account Using Aggressive Heiko Strategy</p>
                            </span>
                        </SafeAnchor>
                    </li>
                    {/* <li className="guide-article-list-item trade-guides">
                        <SafeAnchor href='https://www.profitfarmers.com/blog/risk-management/' target="_blank" className="guide-article-list-item-link">
                            <img src='../images/guide-articles/risk-management-101-featured-image.jpg' alt="Risk Management" />
                            <span className='guide-article-item-details'>
                                <p className='guide-article-item-category'>Trading Guides</p>
                                <p className='guide-article-item-title'>Risk Management 101: The Secret to Safe and Stress-Free Trading</p>
                            </span>
                        </SafeAnchor>
                    </li> */}

                    <li className="guide-article-list-item trade-guides">
                        <SafeAnchor href='https://www.profitfarmers.com/blog/why-the-top-1-trading-whales-always-win/' target="_blank" className="guide-article-list-item-link">
                            <img src='../images/guide-articles/pf-top-1-trading-whale-featured-image.png' alt="Top 1 Trading Whale" />
                            <span className='guide-article-item-details'>
                                <p className='guide-article-item-category'>Trading Guides</p>
                                <p className='guide-article-item-title'>Why The Top 1% Trading Whales ALWAYS Win (Unless You’re a Fish)</p>
                            </span>
                        </SafeAnchor>
                    </li>

                    <li className="guide-article-list-item trade-guides">
                        <SafeAnchor href='https://www.profitfarmers.com/blog/4-differences-between-winning-and-losing-traders/' target="_blank" className="guide-article-list-item-link">
                            <img src='../images/guide-articles/4-difference-between-winners-and-losers-featured-image.jpg' alt="Difference between Winners and Losers" />
                            <span className='guide-article-item-details'>
                                <p className='guide-article-item-category'>Trading Guides</p>
                                <p className='guide-article-item-title'>4 Differences Between Winning and Losing Traders</p>
                            </span>
                        </SafeAnchor>
                    </li>

                    {/* <li className="guide-article-list-item trade-guides">
                        <SafeAnchor href='https://www.profitfarmers.com/blog/how-to-trade-bearish-market-cycles-and-choppy-price-action/' target="_blank" className="guide-article-list-item-link">
                            <img src='../images/guide-articles/trading-bearish-cycles-featured-image.jpg' alt="Trading Bearish Cycles" />
                            <span className='guide-article-item-details'>
                                <p className='guide-article-item-category'>Trading Guides</p>
                                <p className='guide-article-item-title'>How to Trade Bearish Market Cycles and Choppy Price Action</p>
                            </span>
                        </SafeAnchor>
                    </li> */}

                    <li className="guide-article-list-item trade-guides">
                        <SafeAnchor href='https://www.profitfarmers.com/blog/cognitive-biases-in-trading/' target="_blank" className="guide-article-list-item-link">
                            <img src='../images/guide-articles/7-biases-featured-image.jpg' alt="Cognitive Biases" />
                            <span className='guide-article-item-details'>
                                <p className='guide-article-item-category'>Trading Guides</p>
                                <p className='guide-article-item-title'>Cognitive Biases in Trading – 7 Reasons Why You’re Stupid</p>
                            </span>
                        </SafeAnchor>
                    </li>

                    {/* <li className="guide-article-list-item trade-guides">
                        <SafeAnchor href='https://www.profitfarmers.com/blog/the-ultimate-guide-to-crypto-market-cycles/' target="_blank" className="guide-article-list-item-link">
                            <img src='../images/guide-articles/ultimate-guide-crypto-market-cycles-featured-image.jpg' alt="Ultimate Guide" />
                            <span className='guide-article-item-details'>
                                <p className='guide-article-item-category'>Trading Guides</p>
                                <p className='guide-article-item-title'>The Ultimate Guide to Crypto Market Cycles</p>
                            </span>
                        </SafeAnchor>
                    </li> */}

                    {/* <li className="guide-article-list-item trade-guides">
                        <SafeAnchor href='https://www.profitfarmers.com/blog/obv-indicator-reversal-strategy/' target="_blank" className="guide-article-list-item-link">
                            <img src='../images/guide-articles/OBV-Indicator-featured-image.jpg' alt="OBV Indicator" />
                            <span className='guide-article-item-details'>
                                <p className='guide-article-item-category'>Trading Guides</p>
                                <p className='guide-article-item-title'>Using the OBV Indicator to Find Price Reversal Opportunities</p>
                            </span>
                        </SafeAnchor>
                    </li> */}
                </ul>
            </GuideArticlesStyle>
        )
    }
}