import { themeActionTypes } from '../CONSTANTS'

export function userTheme(state = {}, action) {

	switch (action.type) {
		case themeActionTypes.GET_THEME_REQUEST:
			return {
				...state,
				isThemeLoading: true
			}

		case themeActionTypes.GET_THEME_SUCCESS:
			return {
				...state,
				isThemeLoading: false,
				theme: action.theme
			}

		case themeActionTypes.GET_THEME_FAILURE:
			return {
				...state,
				isThemeLoading: false,
				isError: action.isError
			}

		default:
			return state
	}
}

export function setThemeSettings(state = { isDarkMode: null }, action) {
    switch (action.type) {
        case themeActionTypes.SET_THEME_SETTINGS:
            return {
                ...state,
                isDarkMode: action.isDarkMode
            };
        default:
            return state
    }
}