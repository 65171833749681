import React from 'react'
import { connect } from 'react-redux'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Moment from 'react-moment'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStopCircle, faTimes, faBalanceScaleLeft } from "@fortawesome/free-solid-svg-icons"
import { Skeleton } from "@material-ui/lab"
import { SignalStatus } from '../../../../components'
import {
    SignalDetailsContentStyle, MainContentCol, AsideContentCol, SignalDetailsCloseBtn,
    CoinPairInfoWrapper, CoinPairInfoWrapperContent, CryptoCoinName, SignalStatusContent,
    SignalTimestamp, SignalStatusLastPrice, SignalBreakPointsGroup,
    SignalBreakPointsGrpLabel, SignalBreakGroupCtr, SignalBreakGroupCtrItem, SignalTargetPriceCounter
} from '../../styled_components'
import { SignalInstructionsSection } from '../../styled_components/SignalDetailsContentStyle'
import { CoinPairLogo } from '../../../../styles'
import { onSetNewState, isNull, isNotNull, toUsdFormat, toBtcFormat, setCoinLogo, setDefaultImage, ScientificToDecimal } from '../../../../utils'

class SignalDetailsContent extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            exchange: '',
            toSym: null,
            id: '',
            symbol: '',
            direction: '',
            lastPrice: '',
            entryStartPrice: 0,
            entryEndPrice: 0,
            targets: null,
            targetBarFills: null,
            stopLossPrice: 0,
            leverage: 0,
            datePublished: null,
            priceWhenCreated: 0,
            state: null,
            isActive: null,
            closedPriceAt: null,
            hasWidget: false,
            signalState: null
        }
        this.baseState = this.state

        this.mounted = false
        this.wsClient = null
    }

    componentDidMount() {
        if (!window.doBuild)
            this.initChartWidget()
        else
            window.doBuild()

        this.mounted = true
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.signalDetails !== this.props.signalDetails) {
            const { loading, item } = this.props.signalDetails

            if (!loading && isNotNull(item) && isNotNull(item.id) && prevProps.id !== item.id) {
                let { toSym, id, symbol, direction, feed, entryStartPrice, entryEndPrice, targets, targetBarFills,
                    stopLoss, leverage, state, datePublished, priceWhenCreated, isActive, closedPriceAt, lastPrice, signalState } = item

                let exchange = ''
                if (feed === 'SPOT')
                    exchange = "ON BINANCE"
                else
                    exchange = "ON BINANCE FUTURES"

                // initialize entry zone price format
                // try to reformat prices as USD/USDT
                // let newEntryPriceFormat = priceFormat(entryStartPrice, entryEndPrice, symbol)
                // if (isNotNull(newEntryPriceFormat)) {
                //     if (isNotNull(newEntryPriceFormat.startPrice))
                //         entryStartPrice = newEntryPriceFormat.startPrice

                //     if (isNotNull(newEntryPriceFormat.endPrice))
                //         entryEndPrice = newEntryPriceFormat.endPrice
                // }

                let stopLossPrice = stopLoss

                onSetNewState(this, {
                    loading,
                    exchange,
                    toSym,
                    id,
                    direction,
                    symbol,
                    entryStartPrice,
                    entryEndPrice,
                    targets,
                    targetBarFills,
                    stopLossPrice,
                    leverage,
                    state,
                    datePublished,
                    priceWhenCreated,
                    isActive,
                    closedPriceAt,
                    lastPrice,
                    signalState
                })

                if (this.mounted) {
                    this.renderChartWidget(symbol, feed)
                }
            }
        }
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)

        // stop binance websockets connection
        if (isNotNull(this.wsClient)) {
            this.wsClient.close()
            this.wsClient = null
        }

        clearTimeout(this.priceFeedInterval)
        clearTimeout(this.chartInterval)

        this.props.onHide()

        this.mounted = false
    }

    initSignalPriceFeed = (symbol, feed) => {
        if (this.mounted && isNull(this.wsClient)) {
            let baseUrl = feed === 'SPOT'
                ? 'wss://stream.binance.com:9443/ws/'
                : 'wss://fstream.binance.com/ws/'
            let streamName = `${baseUrl}${symbol.toLowerCase()}@ticker`
            this.wsClient = new W3CWebSocket(streamName)

            // connect to binance websockets
            this.wsClient.onopen = () => {
                console.log(`Listening to ${symbol} price feed`)
            }

            // try to reconnect when connection is closed
            this.wsClient.onclose = () => {
                this.priceFeedInterval = setTimeout(() => {
                    this.wsClient = null
                    this.initSignalPriceFeed(symbol, feed)
                }, 3000)
            }

            // try to reconnect when connection throws an error
            this.wsClient.onerror = () => {
                this.priceFeedInterval = setTimeout(() => {
                    this.wsClient = null
                    this.initSignalPriceFeed(symbol, feed)
                }, 3000)
            }

            this.wsClient.onmessage = (response) => {
                let { data } = response
                if (isNotNull(data)) {
                    data = JSON.parse(data)
                    if (data.e === "24hrTicker") {
                        let { lastPrice } = this.state

                        if (isNotNull(symbol) && symbol === data.s) {
                            let isUsdSignal = (symbol.endsWith("USDT") || symbol.endsWith("USD")) ? true : false

                            if (parseFloat(lastPrice) !== parseFloat(data.c)) {
                                onSetNewState(this, {
                                    lastPrice: isUsdSignal ? toUsdFormat(data.c) : toBtcFormat(data.c)
                                })
                            }
                        }
                    }
                }
            }
        }
    }

    initChartWidget = () => {
        const script = document.createElement("script")
        script.type = "text/javascript"
        script.async = true
        script.src = "https://s3.tradingview.com/tv.js"

        document.body.appendChild(script)
    }

    renderChartWidget = (symbol, feed) => {
        let { hasWidget } = this.state

        if (!hasWidget && isNotNull(symbol) && isNotNull(window.TradingView)) {
            onSetNewState(this, {
                hasWidget: true
            })

            this.chartInterval = setTimeout(() => {
                new window.TradingView.widget({
                    "width": "100%",
                    "height": 300,
                    "symbol": `BINANCE:${symbol}${feed === 'SPOT' ? '' : 'PERP'}`,
                    "interval": "1",
                    "timezone": "Etc/UTC",
                    "theme": `dark`,
                    "style": "1",
                    "locale": "en",
                    "toolbar_bg": "#F1F3F6",
                    "enable_publishing": false,
                    "save_image": false,
                    "container_id": "tradingview_3665e"
                })
            }, 500)
        }
    }

    renderTargetPrices = (targets, fills, midEntry, stoploss) => {
        if (isNotNull(targets)) {
            return targets.map((value, key) => {
                let m2t = ''
                let m2s = ''
                let rr = ''

                if (isNotNull(value.startPrice) && 
                    isNotNull(midEntry) && 
                    isNotNull(stoploss)) {
                    m2t = parseFloat(value.startPrice) - parseFloat(midEntry)
                    m2t /= midEntry
                    m2t *= 100

                    m2s = parseFloat(midEntry) - parseFloat(stoploss)
                    m2s /= parseFloat(stoploss)
                    m2s *= 100

                    rr = (parseFloat(m2t) / parseFloat(m2s)).toFixed(1)
                }

                return (
                    <SignalBreakGroupCtrItem key={value.index}>
                        <SignalTargetPriceCounter fill={fills[key]}>T{value.index}</SignalTargetPriceCounter>
                        <span className="item-value">
                            <span>{ScientificToDecimal(value.startPrice)}</span>
                        </span>
                        <span className='rr'>({rr})</span>
                    </SignalBreakGroupCtrItem>
                )
            })
        }
    }

    renderStatus = (signalId, isActive, tradeDirection, currentTradeState, signalState, entryStartPrice, entryEndPrice, targetPrice) => (
        <SignalStatus 
            signalId={signalId}
            isActive={isActive}
            currentTradeState={currentTradeState}
            signalState={signalState}
            tradeDirection={tradeDirection} 
            entryStartPrice={entryStartPrice} 
            entryEndPrice={entryEndPrice} 
            targetPrice={targetPrice} />
    )

    onClose = () => {
        this.props.onHide()

        if (isNotNull(this.wsClient)) {
            this.wsClient.close()
            this.wsClient = null
        }
    }

    render() {
        const { loading, id, exchange, toSym, symbol, direction, lastPrice, entryStartPrice, entryEndPrice,
            targets, targetBarFills, stopLossPrice, leverage, state, datePublished,
            priceWhenCreated, isActive, closedPriceAt, signalState } = this.state
        const { isDarkMode } = this.props

        let coinLogo = null
        if (isNotNull(toSym))
            coinLogo = setCoinLogo(toSym)

        let targetPrice = null
        if (isNotNull(targets)) {
            targetPrice = targets[0].startPrice
        }

        let midEntry = null
        if (entryStartPrice !== '????' && entryEndPrice !== '????') {
            midEntry = (parseFloat(entryStartPrice) + parseFloat(entryEndPrice)) / 2
        }

        return (
            <>
                { loading &&
                    <SignalDetailsContentStyle>
                        <MainContentCol className="loader">
                            <Skeleton variant="rect" height={50} style={{ width: '100%', backgroundColor: '#1f2b39' }} className="skeleton" />
                            <Skeleton variant="rect" height={470} style={{ width: '100%', margin: '10px 0 20px', backgroundColor: '#1f2b39' }} className="skeleton" />
                        </MainContentCol>

                        <AsideContentCol isDarkMode={isDarkMode}>
                            <Skeleton variant="rect" height={230} style={{ width: '100%', backgroundColor: '#1f2b39' }} className="skeleton" />
                        </AsideContentCol>
                    </SignalDetailsContentStyle>
                }

                { !loading &&
                    <SignalDetailsContentStyle>
                        <MainContentCol className="theme-preview-main-column" style={{ paddingBottom: '25px' }}>
                            <SignalDetailsCloseBtn onClick={this.onClose}>
                                <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                            </SignalDetailsCloseBtn>

                            <CoinPairInfoWrapper>
                                <CoinPairInfoWrapperContent>
                                    <CoinPairLogo
                                        src={coinLogo}
                                        className={toSym}
                                        icon={toSym}
                                        onError={setDefaultImage} />

                                    <CryptoCoinName className="theme-coin-name">
                                        <span>{symbol}</span>
                                        <small>{exchange}</small>
                                    </CryptoCoinName>
                                </CoinPairInfoWrapperContent>

                                <SignalStatusContent>
                                    <div className="trade-progress">
                                        <div className="trade-status">
                                            {this.renderStatus(id, isActive, direction, state, signalState, entryStartPrice, entryEndPrice, targetPrice)}
                                        </div>
                                    </div>
                                </SignalStatusContent>
                            </CoinPairInfoWrapper>

                            <SignalTimestamp className="theme-crypto-dates">
                                <span>Created: &nbsp;
                                    <b>
                                        <Moment format="MMM D YYYY HH:mm" utc withTitle local>
                                            {datePublished}
                                        </Moment>
                                    </b>
                                </span>
                                <span>Price created: &nbsp; <b>{priceWhenCreated}</b></span>
                            </SignalTimestamp>

                            <SignalInstructionsSection className="theme-cryptocoin-info">
                                <SignalBreakPointsGroup>
                                    <SignalBreakPointsGrpLabel className="last-price-label">
                                        <div className="price-range-wrapper">
                                            <span className="price-range-icons">
                                                <i className="fas fa-database"></i>
                                            </span>
                                        </div>

                                        { signalState !== 1 &&
                                            <SignalStatusLastPrice>
                                                <span className="signal-status-lastprice-label">Last price</span>
                                                <span className="signal-status-lastprice-value" id={`signal-preview-${symbol}`}>{isNotNull(lastPrice) ? ScientificToDecimal(lastPrice) : "loading..."}</span>
                                            </SignalStatusLastPrice>
                                        }

                                        { signalState === 1 &&
                                            <SignalStatusLastPrice>
                                                <span className="signal-status-lastprice-label">Closed price</span>
                                                <span className="signal-status-lastprice-value" id={`signal-preview-${symbol}`}>{ScientificToDecimal(closedPriceAt)}</span>
                                            </SignalStatusLastPrice>
                                        }
                                    </SignalBreakPointsGrpLabel>
                                </SignalBreakPointsGroup>

                                <hr />

                                <SignalBreakPointsGroup className="entries">
                                    <SignalBreakPointsGrpLabel>
                                        <div className="price-range-wrapper">
                                            <span className="price-range-icons">
                                                <i className="fas fa-sign-in-alt"></i>
                                            </span>

                                            <span>Entry zone</span>
                                        </div>

                                        <OverlayTrigger placement="right" overlay={
                                            <Tooltip id='tooltip-right' className="signal-preview-tooltip">
                                                This is the suggested buy zone
                                            </Tooltip>}>
                                            <span className="overlay-info">
                                                <i className="fas fa-info-circle"></i>
                                            </span>
                                        </OverlayTrigger>
                                    </SignalBreakPointsGrpLabel>

                                    <SignalBreakGroupCtr>
                                        <SignalBreakGroupCtrItem className={`entry`}>
                                            <span className="item-value">
                                                <span>{ScientificToDecimal(entryStartPrice)} - {ScientificToDecimal(entryEndPrice)}</span>
                                            </span>
                                        </SignalBreakGroupCtrItem>
                                    </SignalBreakGroupCtr>
                                </SignalBreakPointsGroup>

                                <hr />

                                <SignalBreakPointsGroup className="targets">
                                    <SignalBreakPointsGrpLabel>
                                        <div className="price-range-wrapper">
                                            <span className="price-range-icons">
                                                <i className="fas fa-bullseye"></i>
                                            </span>

                                            <span>Target(s)</span>
                                        </div>

                                        <OverlayTrigger placement="right" overlay={
                                            <Tooltip id='tooltip-right' className="signal-preview-tooltip">
                                                These are the suggested prices at which to sell
                                            </Tooltip>}>
                                            <span className="overlay-info">
                                                <i className="fas fa-info-circle"></i>
                                            </span>
                                        </OverlayTrigger>
                                    </SignalBreakPointsGrpLabel>

                                    <SignalBreakGroupCtr className="targetLists">
                                        {this.renderTargetPrices(targets, targetBarFills, midEntry, stopLossPrice)}
                                    </SignalBreakGroupCtr>
                                </SignalBreakPointsGroup>

                                <hr />

                                <SignalBreakPointsGroup className="stoploss">
                                    <SignalBreakPointsGrpLabel>
                                        <div className="price-range-wrapper">
                                            <span className="price-range-icons">
                                                <FontAwesomeIcon icon={faStopCircle}></FontAwesomeIcon>
                                            </span>

                                            <span>Stop loss</span>
                                        </div>

                                        <OverlayTrigger placement="right" overlay={
                                            <Tooltip id='tooltip-right' className="signal-preview-tooltip">
                                                If the trade goes in the wrong direction, this is the suggested price to exit the trade
                                            </Tooltip>}>
                                            <span className="overlay-info">
                                                <i className="fas fa-info-circle"></i>
                                            </span>
                                        </OverlayTrigger>
                                    </SignalBreakPointsGrpLabel>

                                    <SignalBreakGroupCtr>
                                        <SignalBreakGroupCtrItem className={`stop-loss`}>
                                            <span className="item-value">
                                                {ScientificToDecimal(stopLossPrice)}
                                            </span>
                                        </SignalBreakGroupCtrItem>
                                    </SignalBreakGroupCtr>
                                </SignalBreakPointsGroup>

                                {parseInt(leverage) > 1 &&
                                    <>
                                        <hr />

                                        <SignalBreakPointsGroup className="leverage">
                                            <SignalBreakPointsGrpLabel>
                                                <div className="price-range-wrapper">
                                                    <span className="price-range-icons">
                                                        <FontAwesomeIcon icon={faBalanceScaleLeft}></FontAwesomeIcon>
                                                    </span>
                                                    <span>Leverage</span>
                                                </div>

                                                <OverlayTrigger placement="right" overlay={
                                                    <Tooltip id='tooltip-right' className="signal-preview-tooltip">
                                                        This is the suggested leverage multiplier to use
                                                    </Tooltip>}>
                                                    <span className="overlay-info">
                                                        <i className="fas fa-info-circle"></i>
                                                    </span>
                                                </OverlayTrigger>
                                            </SignalBreakPointsGrpLabel>

                                            <SignalBreakGroupCtr>
                                                <span className="item-value text-white">
                                                    <span><span className="leverage-value">{leverage < 1 ? leverage + 1 : leverage}x</span> Leverage</span>
                                                </span>
                                            </SignalBreakGroupCtr>
                                        </SignalBreakPointsGroup>
                                    </>
                                }
                            </SignalInstructionsSection>
                        </MainContentCol>

                        <AsideContentCol isDarkMode={isDarkMode} className="theme-preview-side-column">
                            <div className="tradingview-widget-container">
                                <div id="tradingview_3665e"></div>
                            </div>
                        </AsideContentCol>
                    </SignalDetailsContentStyle>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    const { signalDetails } = state
    return {
        signalDetails
    }
}

const connectedSignalDetailsContent = connect(mapStateToProps)(SignalDetailsContent)
export { connectedSignalDetailsContent as SignalDetailsContent }