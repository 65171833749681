import { orderActionTypes } from '../CONSTANTS'


export function futuresOpenOrder(state = {}, action) {
    switch (action.type) {
        case orderActionTypes.FUTURES_OPEN_ORDER_REQUEST:
            return {
                ...state,
                futures_open_order_loading: true
            }
        case orderActionTypes.FUTURES_OPEN_ORDER_SUCCESS:
            return {
                ...state,
                futures_open_order_loading: false,
                futures_open_order_item: action.open_order
            }
        case orderActionTypes.FUTURES_OPEN_ORDER_FAILURE:
            return {
                ...state,
                futures_open_order_loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function futuresOrderHistory(state = {}, action) {
    switch (action.type) {
        case orderActionTypes.FUTURES_ORDER_HISTORY_REQUEST:
            return {
                ...state,
                futures_history_loading: true
            }
        case orderActionTypes.FUTURES_ORDER_HISTORY_SUCCESS:
            return {
                ...state,
                futures_history_loading: false,
                futures_order_history_item: action.order_history
            }
        case orderActionTypes.FUTURES_ORDER_HISTORY_FAILURE:
            return {
                ...state,
                futures_history_loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function orderHistory(state = {}, action) {
    switch (action.type) {
        case orderActionTypes.ORDER_HISTORY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case orderActionTypes.ORDER_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.order_history
            }
        case orderActionTypes.ORDER_HISTORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function openOrder(state = {}, action) {
    switch (action.type) {
        case orderActionTypes.OPEN_ORDER_REQUEST:
            return {
                ...state,
                loader: true
            }
        case orderActionTypes.OPEN_ORDER_SUCCESS:
            return {
                ...state,
                loader: false,
                listsItem: action.open_order
            }
        case orderActionTypes.OPEN_ORDER_FAILURE:
            return {
                ...state,
                loader: false,
                error: action.error
            }
        default:
            return state
    }
}

export function openOrderDetails(state = {}, action) {
    switch (action.type) {
        case orderActionTypes.ORDER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case orderActionTypes.ORDER_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                open_order_details: action.response
            }
        case orderActionTypes.ORDER_DETAILS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function orderPositions(state = {}, action) {
    switch (action.type) {
        case orderActionTypes.ORDER_POSITION_REQUEST:
            return {
                ...state,
                loading_positions: true
            }
        case orderActionTypes.ORDER_POSITION_SUCCESS:
            return {
                ...state,
                loading_positions: false,
                positionData: action.orderPositions
            }
        case orderActionTypes.ORDER_POSITION_FAILRUE:
            return {
                ...state,
                loading_positions: false,
                error: action.error
            }
        default:
            return state
    }
}

export function closeOrderPosition(state = {}, action) {
    switch (action.type) {
        case orderActionTypes.CLOSE_ORDER_POSITION_REQUEST:
            return {
                ...state,
                loading_close_order_position: true
            }
        case orderActionTypes.CLOSE_ORDER_POSITION_SUCCESS:
            return {
                ...state,
                loading_close_order_position: false,
                positionsClosed: action.positionsClosed
            }
        case orderActionTypes.CLOSE_ORDER_POSITION_FAILRUE:
            return {
                ...state,
                loading_close_order_position: false,
                error: action.error
            }
        default:
            return state
    }
}

export function cancelOrder(state = {}, action) {
    switch (action.type) {
        case orderActionTypes.CANCEL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case orderActionTypes.CANCEL_SUCCESS:
            return {
                ...state,
                loading: false,
                isCancel: action.isCancel,
            }
        case orderActionTypes.CANCEL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function exitOrder(state = {}, action) {
    switch (action.type) {

        case orderActionTypes.EXIT_ORDER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case orderActionTypes.EXIT_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                orderExit: action.orderExit,
            }
        case orderActionTypes.EXIT_ORDER_FAILRUE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function forceExitOrder(state = {}, action) {
    switch (action.type) {
        case orderActionTypes.FORCE_EXIT_ORDER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case orderActionTypes.FORCE_EXIT_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                status: action.response
            }
        case orderActionTypes.FORCE_EXIT_ORDER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function forceCancelOrder(state = {}, action) {
    switch (action.type) {
        case orderActionTypes.FORCE_CANCEL_ORDER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case orderActionTypes.FORCE_CANCEL_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                status: action.response
            }
        case orderActionTypes.FORCE_CANCEL_ORDER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function orderResult(state = {}, action) {
    switch (action.type) {
        case orderActionTypes.ORDER_RESULT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case orderActionTypes.ORDER_RESULT_SUCCESS:
            return {
                ...state,
                loading: false,
                order_result: action.response
            }
        case orderActionTypes.ORDER_RESULT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}