import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { onboardingActions } from '../../redux/actions'
import { onboardingState } from '../../constants/onboardingState'
import { isNull, isNotNull, onSetNewState } from '../../utils'
import { OnboardingModalContent } from './styles'
import { userActions } from '../../redux/actions'
import { WelcomeMessage } from '../../components/GetStarted/v2/WelcomeMessage'
import { ConnectToExchange } from '../../components/GetStarted/v2/ConnectToExchange'
import { MoreUserInfo } from '../../components/GetStarted/v2/MoreUserInfo'

class OnboardingModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            name: undefined,
            show: undefined,
            onboardState: undefined,
            newOnboardState: undefined,
            isConnectedToExchange: false,
            showTour: false
        }
        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        const { dispatch } = this.props
        dispatch(onboardingActions.getOnboardingState())
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { name, show, newOnboardState } = this.state
            const { authentication, onboardingUser, onCompleteMyProfile } = this.props
            const { user } = authentication

            if (isNotNull(user) && isNull(name)) {
                onSetNewState(this, {
                    name: user.fullName
                })
            }
            
            if (isNull(show) && 
                isNotNull(onboardingUser) && 
                !onboardingUser.loading && 
                isNotNull(onboardingUser.status)) {
                    const { status } = onboardingUser
                    const { hasSkipped, state } = status

                    if ((parseInt(state) === onboardingState.BASIC_INFO || 
                        parseInt(state) === onboardingState.TRADING_EXP || 
                        parseInt(state) === onboardingState.FINANCIAL_DETAILS || 
                        parseInt(state) === onboardingState.CONNECT_TO_BINANCE) && !hasSkipped) {
                        onSetNewState(this, { 
                            show: true,
                            onboardState: state,
                            newOnboardState: state
                        })
                    } else {
                        onSetNewState(this, { 
                            show: false,
                            onboardState: state,
                            newOnboardState: state
                        })
                    }
            }
            
            if (isNotNull(onCompleteMyProfile) && 
                onCompleteMyProfile && 
                !show) {
                    if ((newOnboardState !== onboardingState.ENTER_EXCHANGE_KEYS)) {
                        onSetNewState(this, {
                            show: true,
                            newOnboardState: onboardingState.ENTER_EXCHANGE_KEYS
                        })
                    } else {
                        onSetNewState(this, {
                            show: true
                        })
                    }
            }
        }
    }

    componentWillUnmount() {
        this.setState(this.baseState)
        this.mounted = false
    }

    handleConnectExchange = (e) => {
        e.preventDefault()

        onSetNewState(this, {
            newOnboardState: onboardingState.ENTER_EXCHANGE_KEYS
        })
    }

    handleConnectedToExchange = () => {
        onSetNewState(this, {
            newOnboardState: onboardingState.TRADING_EXP
        })
    }

    handleCloseForm = () => {
        const { dispatch, handleResetProfileState } = this.props        
        dispatch(userActions.getProfileCompletionStatus())

        handleResetProfileState()
        onSetNewState(this, {
            showTour: true
        })
    }

    handleSkipExchangeConnection = (e) => {
        e.preventDefault()        
        const { dispatch } = this.props
        dispatch(onboardingActions.skipOnboarding())
        onSetNewState(this, {
            showTour: true
        })
    }

    handleStartTour = (e) => {
        e.preventDefault()
        const { takeTour, handleResetProfileState } = this.props
        onSetNewState(this, {
            show: false
        }, () => {
            handleResetProfileState()
            takeTour(true)
        })
    }

    handleSkipTour = (e) => {
        const { handleResetProfileState } = this.props
        onSetNewState(this, {
            show: false
        }, () => {
            setTimeout(() => {
                onSetNewState(this, {                
                    showTour: false
                })
            }, 1000)
            handleResetProfileState()
        })
    }

    renderBodyContent = () => {
        const { newOnboardState, showTour } = this.state
        if (isNotNull(newOnboardState)) {
            switch (newOnboardState) {
                default: {
                    return (
                        <WelcomeMessage showTour={showTour} />
                    )
                }
                case onboardingState.ENTER_EXCHANGE_KEYS: {
                    if (!showTour) {
                        return (
                            <ConnectToExchange 
                                setToConnected={() => this.handleConnectedToExchange() }
                                close={() => this.handleCloseForm()} />
                        )
                    } else {
                        return (
                            <WelcomeMessage showTour={showTour} />
                        )
                    }
                }
                case onboardingState.TRADING_EXP: {
                    if (!showTour) {
                        return (
                            <MoreUserInfo close={() => this.handleCloseForm()} />
                        )
                    } else {
                        return (
                            <WelcomeMessage showTour={showTour} />
                        )
                    }
                }
            }
        }
    }

    render() {
        const { showTour, show, newOnboardState } = this.state

        if (isNotNull(show)) {
            return (
                <Modal 
                    id='account-setup'
                    className={`generic-modal account-setup ${newOnboardState === onboardingState.ENTER_EXCHANGE_KEYS && !showTour 
                        ? 'add-keys' : '' }`}
                    aria-hidden="true"
                    data-backdrop="true"
                    backdrop={true} 
                    keyboard={false}
                    show={show}
                    centered>
                        <OnboardingModalContent>
                            <Modal.Body>
                                { this.renderBodyContent() }
                            </Modal.Body>

                            { isNotNull(newOnboardState) && 
                                newOnboardState === onboardingState.BASIC_INFO && 
                                <>
                                    { !showTour &&  
                                        <Modal.Footer>
                                            <div className='onboarding-actns l-col'>
                                                <button 
                                                    type="button" 
                                                    className='next-btn' 
                                                    onClick={(e) => this.handleConnectExchange(e)}>
                                                    Great, let's go!
                                                </button>
                                        
                                                <button 
                                                    type="button" 
                                                    className='skip-btn' 
                                                    onClick={(e) => this.handleSkipExchangeConnection(e)}>
                                                    I'll do it later
                                                </button>
                                            </div>
                                        </Modal.Footer>
                                    }

                                    { showTour &&  
                                        <Modal.Footer>
                                            <div className='onboarding-actns l-col'>
                                                <button 
                                                    type="button" 
                                                    className='next-btn'
                                                    onClick={(e) => this.handleStartTour(e)}>
                                                    Start tour
                                                </button>
                                        
                                                <button 
                                                    type="button" 
                                                    className='skip-btn' 
                                                    onClick={(e) => this.handleSkipTour(e)}>
                                                    Show me later
                                                </button>
                                            </div>
                                        </Modal.Footer>
                                    }
                                </>
                            }

                            { isNotNull(newOnboardState) && 
                                (newOnboardState === onboardingState.ENTER_EXCHANGE_KEYS || 
                                newOnboardState === onboardingState.TRADING_EXP) && 
                                showTour && 
                                <Modal.Footer>
                                    <div className='onboarding-actns l-col'>
                                        <button 
                                            type="button" 
                                            className='next-btn'
                                            onClick={(e) => this.handleStartTour(e)}>
                                            Start tour
                                        </button>
                                
                                        <button 
                                            type="button" 
                                            className='skip-btn' 
                                            onClick={(e) => this.handleSkipTour(e)}>
                                            Show me later
                                        </button>
                                    </div>
                                </Modal.Footer>
                            }
                        </OnboardingModalContent>                    
                </Modal>
            )
        }

        return (<></>)
    }
}

function mapStateToProps(state) {
    const { authentication, onboardingUser } = state
    return {
        authentication,
        onboardingUser
    }
}

const connectedOnboardingModal = connect(mapStateToProps)(OnboardingModal)
export { connectedOnboardingModal as OnboardingModal }