import React from 'react'
import { connect } from 'react-redux'
import { SafeAnchor } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { AlertMsg } from '../../styles'
import { isNull, isNotNull, onSetNewState } from '../../utils'

class NotificationPermissionAlert extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isPremiumAccess: null
        }

        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true

        const { isPremiumAccess } = this.state
        const { authentication } = this.props
        const { user } = authentication
        if (isNull(isPremiumAccess) && 
            isNotNull(user)) {
                onSetNewState(this, {
                    isPremiumAccess: user.isPaid
                })
            }
    }

    componentDidUpdate() {
        if (this.mounted) {
            const { isPremiumAccess } = this.state
            const { authentication } = this.props
            const { user } = authentication

            if (isNull(isPremiumAccess) && 
                isNotNull(user)) {
                onSetNewState(this, {
                    isPremiumAccess: user.isPaid
                })
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    render() {
        const { isPremiumAccess } = this.state
        let helpLink = null
        if (isNotNull(isPremiumAccess)) {
            helpLink = isPremiumAccess 
                ? "https://pro-support.profitfarmers.com/article/257-how-to-activate-the-push-notifications" 
                : "https://support.profitfarmers.com/article/258-how-to-activate-the-push-notifications"
            
            return (
                <AlertMsg variant="warning">
                    <span>❗️ We need notification permission.</span>
                    <span>Please enable your browser notifications to get the latest signals and updates. <SafeAnchor href={helpLink} target="_blank" style={{ color: '#ced0dd' }}><FontAwesomeIcon icon={faQuestionCircle} /></SafeAnchor></span>
                </AlertMsg>
            )
        }

        return (<></>)
    }
}

function mapStateToProps(state) {
    const { authentication } = state
    return { 
        authentication
    }
}

const connectedNotificationPermissionAlert = connect(mapStateToProps)(NotificationPermissionAlert)
export { connectedNotificationPermissionAlert as NotificationPermissionAlert }