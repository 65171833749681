import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import * as signalR from '@microsoft/signalr'
import jQuery from 'jquery'
import _ from 'lodash'
import iziToast from 'izitoast'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { onboardingActions, themeActions, notificationActions, userActions, walletActions } from '../../redux/actions'
import { WalletSidebar, Announcement } from '../../components'
import { TradeClosedEarlyContainer, ClosedTradeReviewContainer, AutoClosedTradeReviewContainer, OrderResultReviewContainer } from './components'
import { HomeContainer } from '../Home'
import { NotificationContainer } from '../Notification'
import { WalletContainer } from '../Wallet'
import { StatisticContainer } from '../Statistic'
import { ScannerContainer } from '../Scanner'
import { OrderContainer } from '../Order'
import { SettingContainer } from '../Setting'
import Theme from '../../components/Themes/Theme'
import { Sidebar, Bell, LoggedInUser, NotificationPermissionAlert } from '../../components'
import { LayoutWrapper, MainContent } from './styled_components'
import { spotSockets, futuresSockets } from '../../services/CONSTANTS'
import { notificationActionTypes, userActionTypes } from '../../redux/CONSTANTS'
import { notiTypes } from '../../constants/notificationTypeConstants'
import { onboardingState } from '../../constants/onboardingState'
import { pages } from '../../navigation/CONSTANTS'
import { LayoutModalView } from './LayoutModalView'
import { TopNavBar } from '../../styles'
import { isNotNull, isNull, getCoin, onSetNewState, isSupported } from '../../utils'
import '../../components/Modals/styles/generic-modal.scss'
import { SignalSpotWS, SignalFuturesWS } from './websockets'

class LayoutContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isInit: false, 
            isTokenFound: null,
            showSidebar: true,
            isResend: false,
            isEmailPromt: false,
            hasDesktopNotificationSupport: false,
            notification: null,
            notification_set: false,
            viewNotificationItem: false,
            previewNotification: false,
            selectedNotification: null,
            toastInstance: null,
            totalUnread: 0,
            isPreview: false,
            isPushPreview: false,
            browserNotificationLists: [],
            closedNotificationLists: [],
            closedNotificationPreviewSubmitted: false,
            isSignOutSubmitted: false,
            hasAgreedToWeekly: true,
            
            selectedClosedEarlyReviewItem: null,
            selectedAutoClosedEarlyReviewItem: null,
            selectedOrderResultReviewItem: null,

            viewQuickTrade: false,
            viewTradeManual: false,
            viewAutoTradeMessage: false,

            // Websockets
            btcUsdtBidPrice: null,
            btcUsdtAskPrice: null,
            btcUsdtLastPrice: null
        }

        this.mounted = false
        this.baseState = this.state
        this.signOutHubConnection = null
        this.spotHubConnection = null
        this.futuresHubConnection = null
        this.newNotificationAudio = null
    }

    componentDidMount() {
        this.mounted = true

        if (("Notification" in window)) {
            onSetNewState(this, {
                hasDesktopNotificationSupport: true
            })
        }

        const { dispatch } = this.props
        dispatch(onboardingActions.getOnboardingState())
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.mounted) {
            const { 
                dispatch, 
                userTheme, 
                //setThemeSettings,
                authentication, 
                userAgreement, 
                forceExitOrder, 
                forceCancelOrder,
                onboardingUser,
                notificationLists } = this.props
            const { isInit, hasDesktopNotificationSupport, notification_set } = this.state
            
            if (!isInit && isNotNull(onboardingUser) && prevProps.onboardingUser !== onboardingUser) {
                const { status } = onboardingUser
                if (isNotNull(status)) {
                    const { hasSkipped, state } = status
                    if (parseInt(state) !== onboardingState.COMPLETE && !hasSkipped) {
                        window.location.href = '/'
                    } else {
                        if (isNull(authentication.user)) {
                            // Get User Info
                            dispatch(userActions.getMe())
                        }
                        
                        if (isNull(userTheme.theme)) {
                            // Get User theme
                            dispatch(themeActions.getTheme())
                        }
        
                        // get Notifications
                        if (isNull(notificationLists.notification_lists)) {
                            dispatch(notificationActions.getNotificationLists())
                        }

                        await this.initSpotWebSockets()
                        await this.initFuturesWebSockets()

                        this.newNotificationAudio = document.getElementById('new-signal-audio')

                        onSetNewState(this, {
                            isInit: true
                        })
                    }
                }
            }

            // Process User Theme
            if (isNotNull(userTheme) &&
                prevProps.userTheme !== userTheme &&
                isNotNull(userTheme.theme)) {
                const { isDarkMode } = userTheme.theme

                if (isNotNull(isDarkMode))
                    dispatch(themeActions.setThemeSettings(isDarkMode))
            }

            // Checking if email is verified
            if (isNotNull(authentication) && prevProps.authentication !== authentication) {
                const { user, loading } = authentication
                if (!loading) {
                    if (isNotNull(user)) {
                        const { hasAgreedToWeeklyTermsOfUse } = user

                        if (isNotNull(hasAgreedToWeeklyTermsOfUse))
                            onSetNewState(this, { hasAgreedToWeekly: hasAgreedToWeeklyTermsOfUse })
                    }
                }
            }

            // Process UserAgreement
            if (isNotNull(userAgreement) && prevProps.userAgreement !== userAgreement) {
                const { loading, hasAgreedToWeeklyDisclaimer } = userAgreement
                if (!loading) {
                    if (isNotNull(hasAgreedToWeeklyDisclaimer))
                        onSetNewState(this, {
                            hasAgreedToWeekly: hasAgreedToWeeklyDisclaimer
                        })
                }
            }

            // Display Notification Permission
            if (!notification_set) {
                onSetNewState(this, { notification_set: true })

                if (hasDesktopNotificationSupport && isNotNull(Notification))
                    if (Notification.permission !== 'granted')
                        Notification.requestPermission()
            }

            if (prevProps.notificationLists !== this.props.notificationLists) {
                const { loader, active_items } = this.props.notificationLists
                if (!loader) {
                    if (isNotNull(active_items) && isNotNull(active_items.actorId)) {
                        onSetNewState(this, {
                            previewNotification: true,
                            selectedNotification: active_items,
                            isPushPreview: true,
                            isPreview: false
                        })
                        dispatch(notificationActions.getNotificationPreview(active_items.actorId))
                    }
                }
            }

            const { responseData } = this.props.user
            if (isNotNull(responseData) && isNotNull(responseData.success)) {
                if (responseData.success) {
                    iziToast.success({
                        position: 'topRight',
                        title: 'Success',
                        message: 'Confirmation link sent!',
                        displayMode: 1,
                    })
                    onSetNewState(this, { isResend: false })
                    dispatch({ type: userActionTypes.RESEND_VERIFICATION_SUCCESS, user: null })
                } else {
                    if (isNotNull(responseData.msg))
                        iziToast.warning({
                            position: 'topRight',
                            title: 'Error',
                            message: responseData.msg,
                            displayMode: 1,
                        })
                }
            }
            
            if (prevProps.forceExitOrder !== forceExitOrder && !forceExitOrder.loading) {
                const { status } = forceExitOrder
                if (isNotNull(status) && status.success) {
                    dispatch(notificationActions.getNotificationLists())

                    setTimeout(() => {                        
                        dispatch(walletActions.getWallets())
                    }, 2000)
                }
            }

            if (prevProps.forceCancelOrder !== forceCancelOrder && !forceCancelOrder.loading) {
                const { status } = forceCancelOrder
                if (isNotNull(status) && status.success) {
                    dispatch(notificationActions.getNotificationLists())
                }
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
        this.setState(this.baseState)
        iziToast.destroy()
    }

    initSpotWebSockets = async () => {
        if (this.mounted) {
            const props = this
            const hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(`${spotSockets}/spot`, {
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets
                })
                .configureLogging(signalR.LogLevel.None)
                .withAutomaticReconnect([0, 0, 10000])
                .build()

            hubConnection.serverTimeoutInMilliseconds = 120000

            this.spotHubConnection = hubConnection

            this.spotHubConnection.on("SpotMarketTicker", (payload) => {
                //console.log('spot: ', JSON.parse(payload))
                if (this.mounted && isNotNull(payload)) {
                    let data = JSON.parse(payload)
                    if (isNotNull(data)) {
                        SignalSpotWS({ props, data })
                    }
                }
            })

            await this.startSpotWebSocketConnection()
        }
    }

    async startSpotWebSocketConnection() {
        if (this.mounted) {
            try {
                const { user } = this.props.authentication            
                await this.spotHubConnection
                    .start()
                    .catch((err) => console.log(err))

                if (this.spotHubConnection.state === signalR.HubConnectionState.Connected) {
                    this.spotHubConnection.invoke("GetConnectionId", user.userName)
                        .then((connectionId) => {
                            //console.log("spot id: ", connectionId)
                        })
                        .catch((err) => console.error(err))
                }
            } catch (err) {
                //console.log(err)
                
                if (this.spotHubConnection.state === signalR.HubConnectionState.Disconnected) { 
                    // restart connection
                    setTimeout(async () => await this.startSpotWebSocketConnection(), 5000)
                }
            }
        }
    }

    initFuturesWebSockets = async () => {
        if (this.mounted) {
            const props = this
            const hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(`${futuresSockets}/futures`, {
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets
                })
                .configureLogging(signalR.LogLevel.None)
                .withAutomaticReconnect([0, 0, 10000])
                .build()

            hubConnection.serverTimeoutInMilliseconds = 120000
            
            this.futuresHubConnection = hubConnection

            await this.startFuturesWebSocketConnection()            

            this.futuresHubConnection.on("FuturesMarketTicker", (payload) => {
                //console.log('futures: ', payload)
                if (this.mounted && isNotNull(payload)) {
                    let data = JSON.parse(payload)
                    if (isNotNull(data)) {
                        SignalFuturesWS({ props, data })
                    }
                }
            })
        }
    }

    async startFuturesWebSocketConnection() {
        if (this.mounted) {
            try {
                const { user } = this.props.authentication
                await this.futuresHubConnection.start()
                
                if (this.futuresHubConnection.state === signalR.HubConnectionState.Connected) {
                    this.futuresHubConnection.invoke("GetConnectionId", user.userName)
                        .then((connectionId) => {
                            //console.log("futures id: ", connectionId)
                        })
                        .catch((err) => console.error(err))
                }                
            } catch (err) {
                console.log(err)

                if (this.futuresHubConnection.state === signalR.HubConnectionState.Disconnected) {
                    // restart connection
                    setTimeout(async () => await this.startFuturesWebSocketConnection(), 5000)
                }
            }
        }
    }

    onPushNotificationHandler = (data) => {
        if (isNotNull(data)) {
            let logoSrc = null

            switch (data.type) {
                default: {
                    logoSrc = getCoin(data.symbol)
                    logoSrc = `https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/${logoSrc}.png`

                    break
                }
                case 5: {
                    logoSrc = 'https://pf-icons.s3.eu-west-2.amazonaws.com/event-icon.png'
                    break
                }
                case 6: {
                    logoSrc = 'https://pf-icons.s3.eu-west-2.amazonaws.com/success-icon.png'
                    break
                }
                case 7: {
                    logoSrc = 'https://pf-icons.s3.eu-west-2.amazonaws.com/failed-icon.png'
                    break
                }
            }
            
            iziToast.show({
                id: data.id,
                class: "notifToast single",
                timeout: data.type === notiTypes.ORDER_FAILED ? false : 5000,
                theme: 'dark',
                title: `${data.message}`,
                close: true,
                progressBar: true,
                animateInside: true,
                drag: false,
                displayMode: 1,
                message: moment.utc(data.dateCreated).local().format('lll'),
                transitionIn: 'fadeInRight',
                transitionOut: 'flipOutX',
                position: window.innerWidth >= 765 ? 'topRight' : 'topCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
                iconUrl: logoSrc,
                zindex: 999,
                buttons: data.type === notiTypes.ORDER_FAILED 
                    ? [['<button>View</button>', (() => {
                            this.onViewMarketOrderResult(data)
                        })]] 
                    : [],
                onClosed: () => {
                    if (data.type === notiTypes.ORDER_FAILED) {
                        this.handleMarkAsRead(data.id)
                    }
                }
            })
        }
    }

    onPushNotificationPreviewHandler = (data, toast) => {
        const { dispatch } = this.props
        onSetNewState(this, {
            previewNotification: true,
            isPushPreview: true,
            selectedNotification: data,
            toastInstance: toast,
            isPreview: false
        })
        if (isNotNull(data) && isNotNull(data.actorId))
            dispatch(notificationActions.getNotificationPreview(data.actorId))

        dispatch(notificationActions.clear())
    }

    onMultipleCloseNotificationPreviewHandler = () => {
        const { dispatch } = this.props
        onSetNewState(this, {
            previewNotification: true,
            selectedNotification: null,
            toastInstance: false,
            isPreview: true,
            isPushPreview: false,
        })
        dispatch(notificationActions.clearNotificationPreview())
        dispatch(notificationActions.clear())
        dispatch({ type: notificationActionTypes.CLEAR_MARKED_NOTIFICATION })
    }

    onUpdateSelectedNotificationHandler = (data) => {
        const { dispatch } = this.props
        if (isNotNull(data)) {
            onSetNewState(this, {
                selectedNotification: data
            })

            if (isNotNull(data) && isNotNull(data.actorId))
                dispatch(notificationActions.getNotificationPreview(data.actorId))
        }
    }

    onViewMarketOrderResult = (data) => {
        onSetNewState(this, {
            selectedOrderResultReviewItem: {
                id: data.id,
                type: data.type,
                actorId: data.actorId,
                symbol: data.symbol,
                message: data.message,
                dateCreated: data.dateCreated,
                isDeleted: data.isDeleted,
                isRead: data.isRead
            }
        }, () => {            
            this.handleMarkAsRead(data.id)
        })
    }
    //#endregion

    //#region Email Confirmation Error
    isEmailConfirmationErrorRemoved = (isEmailRemoved, isSet, isRemove) => {
        if (isNotNull(isEmailRemoved)) {
            if (isEmailRemoved)
                onSetNewState(this, { isEmailPromt: false })
            else
                onSetNewState(this, { isEmailPromt: true })

            if (isNotNull(isSet)) {
                if (isSet) {
                    localStorage.setItem('emailError', true)
                    onSetNewState(this, { isEmailPromt: true })
                } else {
                    localStorage.setItem('emailError', false)
                    onSetNewState(this, { isEmailPromt: false })
                }
            }

            if (isNotNull(isRemove)) {
                localStorage.removeItem('emailError')
                onSetNewState(this, { isEmailPromt: false })
            }
        }
    }

    onResendConfirmationHandler = (email, e) => {
        e.preventDefault()
        const { dispatch } = this.props
        onSetNewState(this, { isResend: true })
        if (isNotNull(email)) {
            dispatch(userActions.resendVerification(email))
        }
    }

    onCloseEmailConfirmationHandler = () => {
        this.isEmailConfirmationErrorRemoved(true, false, null)
    }
    //#endregion

    //#region Handle Pages Viewer
    renderAnnouncement = (user) => {
        if (this.mounted === true && isNotNull(user) && !user.isPaid) {
            return (
                <Announcement user={user} />
            )
        }
    }

    handlePageViewer = (page, history, user) => {
        if (this.mounted) {
            const { isEmailPromt, viewNotificationItem } = this.state
            switch (page) {
                case pages.signals:
                    return (
                        <div className="main-content">
                            {/* {this.renderAnnouncement(user)} */}

                            <HomeContainer
                                history={history}
                                viewNotificationItem={viewNotificationItem}
                                onCreateQuickTradeHandler={this.onCreateQuickTradeHandler}
                                onViewAutoTradeErrorMessageHandler={this.onViewAutoTradeErrorMessageHandler}
                                onReviewEarlyClosedItem={this.handleClosedEarlyItemReview}
                                onReviewAutoEarlyClosedItem={this.handleAutoClosedEarlyItemReview}
                                onReviewOrderResultItem={this.handleOrderResultReview} />
                        </div>
                    )
                case pages.notification:
                    return <NotificationContainer 
                        history={history}
                        onReviewEarlyClosedItem={this.handleClosedEarlyItemReview}
                        onReviewAutoEarlyClosedItem={this.handleAutoClosedEarlyItemReview}
                        onReviewOrderResultItem={this.handleOrderResultReview} />
                case pages.wallet:
                    return <WalletContainer history={history} />
                case pages.statistic:
                    return <StatisticContainer history={history} />
                case pages.scanner.priceActionScanner:
                    return <ScannerContainer type="price_action" title="Price Action Scanner" {...this.props} />
                case pages.scanner.rsiScanner:
                    return <ScannerContainer type="relative_strength" title="Relative Strength Index" {...this.props} />
                case pages.orderHistory:
                    return <OrderContainer history={history} />
                case pages.setting.profile:
                case pages.setting.subscription:
                case pages.setting.autoTrade:
                case pages.setting.notificationSettings:
                    return <div className="main-content"><SettingContainer page={page} isEmailPromt={isEmailPromt} /></div>
                default:
                    return (
                        <div className="main-content">
                            {this.renderAnnouncement(user)}

                            <HomeContainer
                                history={history}
                                viewNotificationItem={viewNotificationItem}
                                onCreateQuickTradeHandler={this.onCreateQuickTradeHandler}
                                onViewAutoTradeErrorMessageHandler={this.onViewAutoTradeErrorMessageHandler}
                                onReviewEarlyClosedItem={this.handleClosedEarlyItemReview}
                                onReviewAutoEarlyClosedItem={this.handleAutoClosedEarlyItemReview}
                                onReviewOrderResultItem={this.handleOrderResultReview} />
                        </div>
                    )
            }
        }
    }
    //#endregion

    //#region Wallet Sidebar
    onCreateQuickTradeHandler = () => onSetNewState(this, {
        viewQuickTrade: false
    }, () => {
        onSetNewState(this, { viewQuickTrade: true })
    })

    handleWalletSidebarViewer = (page) => {
        if (this.mounted) {            
            const { isTokenFound } = this.state

            switch (page) {
                case pages.signals:
                case pages.setting.profile:
                case pages.setting.subscription:
                case pages.setting.autoTrade:
                case pages.setting.notificationSettings:
                case pages.scanner.priceActionScanner:
                case pages.scanner.rsiScanner: {
                    const isWalletHide = page === pages.scanner.priceActionScanner || page === pages.scanner.rsiScanner ? true : false
                    return (
                        <>
                            <div className="back-drop-wallets" onClick={this.onWalletBackdropHandler} />
                            <WalletSidebar 
                                isTokenFound={isTokenFound}
                                isWalletHide={isWalletHide}
                                authentication={this.props.authentication}
                                isEmailPromt={this.state.isEmailPromt}
                                viewQuickTrade={this.state.viewQuickTrade}
                            />
                        </>
                    )
                }
                default:
                    return null
            }
        }
    }
    //#endregion

    //#region Backdrop Handler
    onLayoutBackdropHandler = () => {
        jQuery(".sidebar-size").removeClass("show")
        jQuery("#sidebar-opt").removeClass("show")
        jQuery("#sidebar-opt-prof").removeClass("show")
        jQuery(".fsp").removeClass("show")
        jQuery("#sidebar-btn-bd").click()
    }

    onWalletBackdropHandler = () => {
        jQuery(".main-content").removeClass("show")
        jQuery(".wallets").removeClass("show")
        jQuery("#wallet-click").click()
    }
    //#endregion

    //#region Layout Modal
    onNotificationItemPreviewHandler = (isView) => onSetNewState(this, {
        viewNotificationItem: false
    }, () => {
        onSetNewState(this, { viewNotificationItem: isView })
    })

    onCloseNotificationPreviewSubmittedHandler = (isSuccess) => onSetNewState(this, {
        closedNotificationPreviewSubmitted: false,
        selectedNotification: null
    }, () => {
        onSetNewState(this, { closedNotificationPreviewSubmitted: isSuccess })
    })

    onCloseNotificationPreviewHandler = () => {
        const { dispatch } = this.props
        onSetNewState(this, {
            previewNotification: false,
            selectedNotification: null,
            toastInstance: null,
            isPreview: false,
            isPushPreview: false,
            closedNotificationPreviewSubmitted: false
        })
        dispatch(notificationActions.clearNotificationPreview())
        dispatch(notificationActions.clear())
        dispatch({ type: notificationActionTypes.CLEAR_MARKED_NOTIFICATION })
    }

    onNotificationPreviewCloseHandler = (data, isPreview, isPushPreview) => {
        const { dispatch, notificationLists } = this.props

        if (isNotNull(data) && isNotNull(notificationLists) &&
            isNotNull(notificationLists.notification_lists) &&
            isNotNull(notificationLists.notification_lists.data)) {
            const { id } = data

            if (data.isRead === false) {
                const responseData = {
                    "notification_id": data.id,
                    "isMarkedAll": false
                }
                dispatch(notificationActions.markNotificationAsRead(responseData))
            }

            let notification = [...notificationLists.notification_lists.data]
            _.forEach(notification, n => {
                if (isNotNull(n) && n.isRead === false && n.id === id)
                    n.isRead = true
            })
            let notification_lists = {
                data: notification
            }
            dispatch({ type: notificationActionTypes.GET_ALL_NOTIFICATION_LISTS_SUCCESS, notification_lists })

            if (isNotNull(isPreview) && isPreview === true) {
                onSetNewState(this, { closedNotificationPreviewSubmitted: true })
            }

            if (isNotNull(isPushPreview) && isPushPreview === true)
                this.onCloseNotificationPreviewHandler()
        }
    }

    onHasAgreedToWeeklyDisclaimerHandler = () => {
        const { dispatch } = this.props
        onSetNewState(this, { hasAgreedToWeekly: true })
        dispatch(userActions.agreeToWeeklyDisclaimer())
    }
    //#endregion

    onViewTradeManualHandler = (isOpen) => onSetNewState(this, {
        viewTradeManual: false
    }, () => {
        onSetNewState(this, { viewTradeManual: isOpen })
    })

    onViewAutoTradeErrorMessageHandler = (isOpen) => onSetNewState(this, {
        viewAutoTradeMessage: false
    }, () => {
        onSetNewState(this, { viewAutoTradeMessage: isOpen })
    })

    handleClosedEarlyItemReview = (data) => {
        onSetNewState(this, {
            selectedClosedEarlyReviewItem: {
                id: data.id,
                type: data.type,
                actorId: data.actorId,
                symbol: data.symbol,
                message: data.message,
                dateCreated: data.dateCreated,
                isDeleted: data.isDeleted,
                isRead: data.isRead
            }
        })
    }

    handleAutoClosedEarlyItemReview = (data) => {
        onSetNewState(this, {
            selectedAutoClosedEarlyReviewItem: {
                id: data.id,
                type: data.type,
                actorId: data.actorId,
                symbol: data.symbol,
                message: data.message,
                dateCreated: data.dateCreated,
                isDeleted: data.isDeleted,
                isRead: data.isRead
            }
        })
    }

    handleOrderResultReview = (data) => {
        onSetNewState(this, {
            selectedOrderResultReviewItem: {
                id: data.id,
                type: data.type,
                actorId: data.actorId,
                symbol: data.symbol,
                message: data.message,
                dateCreated: data.dateCreated,
                isDeleted: data.isDeleted,
                isRead: data.isRead
            }
        })
    }

    handleMarkAsRead = (id) => {
        const { dispatch, notificationLists } = this.props
        const responseData = {
            "notification_id": id,
            "isMarkedAll": false
        }
        dispatch(notificationActions.markNotificationAsRead(responseData))

        let notification = [...notificationLists.notification_lists.data]
        _.forEach(notification, n => {
            if (isNotNull(n) && n.isRead === false && n.id === id)
                n.isRead = true
        })
        let notification_lists = {
            data: notification
        }
        dispatch({ type: notificationActionTypes.GET_ALL_NOTIFICATION_LISTS_SUCCESS, notification_lists })
    }

    onCloseReviewItem = () => {
        onSetNewState(this, {
            selectedClosedEarlyReviewItem: null
        })
    }

    onCloseAutoClosedEarlyReviewItem = () => {
        onSetNewState(this, {
            selectedAutoClosedEarlyReviewItem: null
        })
    }

    onCloseOrderResultReviewItem = () => {
        onSetNewState(this, {
            selectedOrderResultReviewItem: null
        })
    }

    handleSidebar = () => {
        onSetNewState(this, function (prev) {
            if (prev.showSidebar) {
                jQuery(".sidebar-size").addClass("show")
                jQuery("#sidebar-opt").addClass("show")
                jQuery("#sidebar-opt-prof").addClass("show")
                jQuery(".fsp").addClass("show")
                jQuery(".to-hide-items").addClass("show")
                jQuery(".tooltip").addClass("showToolTip")
                jQuery("#customer-layout-wrapper").addClass("sidebar-open")

                let isWalletOpen = jQuery('.main-content').hasClass('show')
                if (isWalletOpen) {
                    jQuery(".main-content").removeClass('show')
                    jQuery("#sidebar-opt-prof").removeClass('show-wallets')
                    jQuery(".wallets").removeClass("show hide")
                    jQuery("#wallet-click").click()
                }
            } else {
                jQuery(".sidebar-size").removeClass("show")
                jQuery("#sidebar-opt").removeClass("show")
                jQuery("#sidebar-opt-prof").removeClass("show")
                jQuery(".fsp").removeClass("show")
                jQuery(".to-hide-items").removeClass("show")
                jQuery(".tooltip").removeClass("showToolTip")
                jQuery("#customer-layout-wrapper").removeClass("sidebar-open")
            }

            return { showSidebar: !prev.showSidebar }
        })
    }

    setTokenFound = (val) => onSetNewState(this, { isTokenFound: val })

    render() {
        const { 
            isTokenFound,
            isEmailPromt, 
            viewTradeManual, 
            selectedClosedEarlyReviewItem, 
            selectedAutoClosedEarlyReviewItem, 
            selectedOrderResultReviewItem } = this.state
        const { page, history } = this.props
        const { user } = this.props.authentication
        const { isDarkMode } = this.props.setThemeSettings

        let darkMode = true
        let isTradeAllowed = false

        if (isNotNull(user)) {
            const { isAutoTradingAllowed } = user
            if (isNotNull(isAutoTradingAllowed))
                isTradeAllowed = isAutoTradingAllowed
        }

        if (isNotNull(isDarkMode))
            darkMode = isDarkMode

        return (
            <>
                {
                    isNull(isDarkMode)
                        ? 
                            <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100vh', backgroundColor: '#0b1219', zIndex: '999999999', position: 'relative' }}>
                                <img srcSet={`/images/layout/loader-150.gif`} className="img-fluid" style={{ width: '100px', height: '100px' }} alt="loader" />
                            </div>
                        : 
                            <Theme mode={darkMode}>
                                <div className="overlays theme-main-wrapper" />

                                <LayoutWrapper id="layout-wrapper" className={`theme-main-wrapper ${isNotNull(isTokenFound) && !isTokenFound ? 'notif-permission' : ''}`}>
                                    { isSupported() && isNotNull(isTokenFound) && !isTokenFound && 
                                        <NotificationPermissionAlert />
                                    }

                                    <Sidebar 
                                        position='left' 
                                        isTokenFound={isTokenFound}
                                        isEmailPromt={isEmailPromt}
                                        isTradeManualOpen={viewTradeManual}
                                        openTradeManual={this.onViewTradeManualHandler}
                                        onCreateQuickTradeHandler={this.onCreateQuickTradeHandler}
                                    />

                                    <div className="blur-dashboard">
                                        <MainContent role="main" className={`content ${isEmailPromt ? "show-email " : ""} ${isNotNull(isTokenFound) && !isTokenFound ? 'notif-permission' : ''}`} id="sidebar-opt">
                                            <div className="back-drop" onClick={this.onLayoutBackdropHandler} />
                                            
                                            {/* <Header 
                                                page={page} 
                                                history={history} 
                                                isEmailPromt={isEmailPromt} 
                                                notificationItemPreview={this.onNotificationItemPreviewHandler}
                                                onReviewEarlyClosedItem={this.handleClosedEarlyItemReview}
                                                onReviewAutoEarlyClosedItem={this.handleAutoClosedEarlyItemReview}
                                                onReviewOrderResultItem={this.handleOrderResultReview} /> */}

                                            <TopNavBar className={`${isNotNull(isTokenFound) && !isTokenFound ? 'has-notif-permission' : ''}`}>
                                                <button type="button" 
                                                    className='sidebar-toggle-btn'
                                                    onClick={this.handleSidebar}>
                                                    <FontAwesomeIcon icon={faBars} />
                                                </button>
                                                
                                                <NavLink to={`/dashboard`} className='brand'>
                                                    <img src='/images/brand/pf-logo.png' alt="ProfitFarmers Logo" />
                                                </NavLink>
                                                <div className='nav-menu-wrapper'>
                                                    <Bell 
                                                        history={history} 
                                                        sourcePage="any" 
                                                        isTokenFound={isTokenFound} 
                                                        setTokenFound={this.setTokenFound}
                                                        notificationItemPreview={this.onNotificationItemPreviewHandler}
                                                        onReviewEarlyClosedItem={this.handleClosedEarlyItemReview}
                                                        onReviewAutoEarlyClosedItem={this.handleAutoClosedEarlyItemReview}
                                                        onReviewOrderResultItem={this.handleOrderResultReview} />
                                                    <LoggedInUser />
                                                </div>
                                            </TopNavBar>

                                            {this.handlePageViewer(page, history, user)}
                                            {this.handleWalletSidebarViewer(page)}
                                            {this.props.children}
                                        </MainContent>
                                    </div>
                                </LayoutWrapper>

                                {/* This modal handles, Agreement and Notification */}
                                <LayoutModalView
                                    self={this}
                                    state={this.state}
                                    isTradeAllowed={isTradeAllowed}
                                    authentication={this.props.authentication}
                                    closeNotificationPreview={this.onCloseNotificationPreviewHandler}
                                    onNotificationPreviewCloseHandler={this.onNotificationPreviewCloseHandler}
                                    onCloseNotificationPreviewSubmittedHandler={this.onCloseNotificationPreviewSubmittedHandler}
                                    hasAgreedToWeeklyDisclaimer={this.onHasAgreedToWeeklyDisclaimerHandler}
                                    updateSelectedNotificationHandler={this.onUpdateSelectedNotificationHandler}
                                    openTradeManual={this.onViewTradeManualHandler}
                                    onViewAutoTradeErrorMessageHandler={this.onViewAutoTradeErrorMessageHandler}
                                />

                                <TradeClosedEarlyContainer />

                                { isNotNull(selectedClosedEarlyReviewItem) && 
                                    <>
                                        <ClosedTradeReviewContainer 
                                            data={selectedClosedEarlyReviewItem} 
                                            eventHandlers={{
                                                onCloseReviewItem: this.onCloseReviewItem.bind(this)
                                            }} />
                                    </>
                                }

                                { isNotNull(selectedAutoClosedEarlyReviewItem) && 
                                    <>
                                        <AutoClosedTradeReviewContainer 
                                            data={selectedAutoClosedEarlyReviewItem}
                                            eventHandlers={{
                                                onCloseReviewItem: this.onCloseAutoClosedEarlyReviewItem.bind(this)
                                            }}
                                        />
                                    </>
                                }

                                { isNotNull(selectedOrderResultReviewItem) && 
                                    <>
                                        <OrderResultReviewContainer 
                                            data={selectedOrderResultReviewItem} 
                                            eventHandlers={{
                                                onCloseReviewItem: this.onCloseOrderResultReviewItem.bind(this)
                                            }} />
                                    </>
                                }

                            </Theme>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    const {
        authentication, userTheme, setThemeSettings,
        userAgreement, notificationLists, priceActions,
        relativeStrengthAllItems, watchLists, user,
        userDevice, cryptoWallet, balanceWallet,
        signals, signalDetails, openOrderDetails,
        forceExitOrder, forceCancelOrder, onboardingUser
    } = state
    return {
        authentication,
        userTheme,
        user,
        setThemeSettings,
        userAgreement,
        notificationLists,
        priceActions,
        relativeStrengthAllItems,
        watchLists,
        userDevice,
        cryptoWallet,
        balanceWallet,
        signals,
        signalDetails,
        openOrderDetails,
        forceExitOrder,
        forceCancelOrder,
        onboardingUser
    }
}

const connectedLayoutContainer = connect(mapStateToProps)(LayoutContainer)
export { connectedLayoutContainer as LayoutContainer }