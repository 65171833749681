import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { messagesActions } from '../../redux/actions'
import _ from 'lodash'
import { userRoles } from '../../constants/userRoles'
import { isNotNull, isNull, onSetNewState } from '../../utils'

class MessageBoardMenuLink extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isAdmin: undefined,
            hasFreshMessages: undefined
        }

        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        const { dispatch } = this.props
        dispatch(messagesActions.getList(1, "", ""))
    }

    componentDidUpdate() {
        if (this.mounted) {
            const { isLoading, isAdmin, hasFreshMessages } = this.state
            const { authentication, messageBoardItemList } = this.props
            const { user } = authentication

            if (typeof isAdmin === 'undefined' && 
                isNotNull(user) && 
                isNotNull(user.roles)) {
                    const { roles } = user
                    let userRole = _.find(roles, (e) => {
                        return e === userRoles.ADMIN
                    })

                    onSetNewState(this, {
                        isAdmin: isNotNull(userRole) && userRole === userRoles.ADMIN
                    })
            }

            if (isLoading && 
                isNull(hasFreshMessages) && 
                isNotNull(messageBoardItemList) && 
                !messageBoardItemList.loading) {
                    const { dataList } = messageBoardItemList
                    if (isNotNull(dataList) && isNotNull(dataList.data)) {
                        const { data } = dataList
                        if (isNotNull(data)) {
                            let hasAny = _.find(data, ['isFresh', true])
                            if (hasAny) {
                                onSetNewState(this, {
                                    hasFreshMessages: true,
                                    isLoading: false
                                })
                            } else {
                                onSetNewState(this, {
                                    hasFreshMessages: false,
                                    isLoading: false
                                })
                            }
                        }
                    }
                }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    render() {
        const { isLoading, isAdmin,  hasFreshMessages } = this.state
        const { version } = this.props

        if (!isLoading && 
            isNotNull(hasFreshMessages)) {
            return (
                <>
                    { isNotNull(version) && version === 'v1' && 
                        <li className='sub-menu-item'>
                            <NavLink to="/messages/board" className="menus sub-menu-item-link" activeClassName="active">
                                <span className='sub-menu-icon'><i className='fas fa-bullhorn'></i> { !isAdmin && hasFreshMessages && <span className='has-new'></span> }</span>
                                <span className='sub-menu-text'>Latest Updates</span>
                            </NavLink>
                        </li>
                    }

                    { !isNotNull(version) &&                         
                        <li className='sidebar-submenu-item'>
                            <NavLink to="/messages/board" className="sidebar-submenu-item-link" activeClassName="active">
                                <span className='submenu-item-link-icon'><i className='fas fa-bullhorn'></i> { !isAdmin && hasFreshMessages && <span className='has-new'></span> }</span>
                                <span className='submenu-item-link-text'>Latest Updates</span>
                            </NavLink>
                        </li>
                    }
                </>
            )
        }

        return (<></>)
    }
}

function mapStateToProps(state) {
    const { authentication, messageBoardItemList } = state
    return {
        authentication,
        messageBoardItemList
    }
}

const connectedMessageBoardMenuLink = connect(mapStateToProps)(MessageBoardMenuLink)
export { connectedMessageBoardMenuLink as MessageBoardMenuLink }