import { userActionTypes, formActionTypes } from '../CONSTANTS'
import { userServices } from '../../services'
import { isNotNull, encryptText, decryptText, toCamelCase } from '../../utils'

function getMe() {
    return dispatch => {
        dispatch(request())
        
        userServices.getMe().then(user => {
            let userInfo = decryptText(user)
            let parsedData = JSON.parse(userInfo, toCamelCase)
            dispatch(success(parsedData))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: userActionTypes.GET_REQUEST } }
    function success(user) { return { type: userActionTypes.GET_SUCCESS, user } }
    function failure(error) { return { type: userActionTypes.GET_FAILURE, error } }
}

function getId() {
    return dispatch => {
        dispatch(request())

        userServices.getId()
            .then(res => {
                if (isNotNull(res) && res.success) {
                    const { data } = res
                    let userInfo = decryptText(data)
                    dispatch(success(JSON.parse(userInfo)))
                }
            }, error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: userActionTypes.GET_ID_REQUEST } }
    function success(response) { return { type: userActionTypes.GET_ID_SUCCESS, response } }
    function failed(error) { return { type: userActionTypes.GET_ID_FAILED, error } }
}

function getProfile() {
    return dispatch => {
        dispatch(request())

        userServices.getProfile().then(res => {
            dispatch(success(res))
        }, err => {
            dispatch(failure(err))
        })    
    }

    function request() { return { type: userActionTypes.GET_PROFILE_REQUEST } }
    function success(res) { return { type: userActionTypes.GET_PROFILE_SUCCESS, res } }
    function failure(err) { return { type: userActionTypes.GET_PROFILE_FAILURE, err } }
}

function getProfileByToken(accessToken) {
    return dispatch => {
        dispatch(request())

        userServices.getProfileByToken(accessToken).then(res => {
            dispatch(success(res))
        }, err => {
            dispatch(failure(err))
        })    
    }

    function request() { return { type: userActionTypes.GET_PROFILE_REQUEST } }
    function success(res) { return { type: userActionTypes.GET_PROFILE_SUCCESS, res } }
    function failure(err) { return { type: userActionTypes.GET_PROFILE_FAILURE, err } }
}

function getProfileCompletionStatus() {
    return dispatch => {
        dispatch(request())

        userServices.getProfileCompletionStatus()
            .then(res => dispatch(success(res)), error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: userActionTypes.GET_PROFILE_COMPLETION_STATUS_REQUEST } }
    function success(response) { return { type: userActionTypes.GET_PROFILE_COMPLETION_STATUS_SUCCESS, response } }
    function failed(error) { return { type: userActionTypes.GET_PROFILE_COMPLETION_STATUS_FAILED, error } }
}

function saveProfile(data, fileName) {
    return dispatch => {
        dispatch(request())
        
        userServices.saveProfile(data, fileName).then(response => {
            if (!response.success)
                dispatch(failure(response.msg))
            else {
                userServices.getMe().then(user => {
                    dispatch(success(response.msg, user))
                    dispatch({ type: userActionTypes.GET_SUCCESS, user })
                })
            }
        }).catch(error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: userActionTypes.SAVE_PROFILE_REQUEST } }
    function success(response, user) { return { type: userActionTypes.SAVE_PROFILE_SUCCESS, response, user } }
    function failure(error) { return { type: userActionTypes.SAVE_PROFILE_FAILURE, error } }
}

function editProfile(data) {
    return dispatch => {
        dispatch(request())
        
        userServices.editProfile(data)
            .then(res => {
                if (isNotNull(res)) {
                    if (res.success) {
                        const { data } = res
                        let userInfo = decryptText(data)
                        let parsedData = JSON.parse(userInfo, toCamelCase)
                        dispatch(success(parsedData))
                    } else {
                        dispatch(failure(res.msg))
                    }
                }
        }).catch(error => {
            dispatch(failure(error))
        })
    }
    
    function request() { return { type: formActionTypes.EDIT_PROFILE_REQUEST } }
    function success(response) { return { type: formActionTypes.EDIT_PROFILE_SUCCESS, response } }
    function failure(error) { return { type: formActionTypes.EDIT_PROFILE_FAILURE, error } }
}

function changePassword(data) {
    return dispatch => {
        dispatch(request())

        if (isNotNull(data.oldPassword))
            data.oldPassword = encryptText(data.oldPassword)

        if (isNotNull(data.newPassword))
            data.newPassword = encryptText(data.newPassword)

        if (isNotNull(data.confirmPassword))
            data.confirmPassword = encryptText(data.confirmPassword)

        userServices.changePassword(data).then(response => {
            if (isNotNull(response.success)) {
                if (!response.success)
                    dispatch(failure(response.msg))
                else
                    dispatch(success(response.msg))
            }
        }).catch(error => {
            dispatch(failure(error))
        })
    }
    function request() { return { type: formActionTypes.CHANGE_PASSWORD_REQUEST } }
    function success(response) { return { type: formActionTypes.CHANGE_PASSWORD_SUCCESS, response } }
    function failure(error) { return { type: formActionTypes.CHANGE_PASSWORD_FAILURE, error } }
}

function register(data) {
    return dispatch => {
        dispatch(request())
        
        userServices.register(data).then(response => {
            if (isNotNull(response)) {
                const { msg, success } = response
                if (success)
                    dispatch(success_request(response))
                else
                    dispatch(failure(msg))
            }

        }).catch(error => dispatch(failure(error)))

        function request() { return { type: userActionTypes.REGISTER_REQUEST } }
        function success_request(user) { return { type: userActionTypes.REGISTER_SUCCESS, user } }
        function failure(error) { return { type: userActionTypes.REGISTER_FAILURE, error } }

    }

}

function resendVerification(data) {
    return dispatch => {
        dispatch(request())
        
        userServices.resendVerification(data)
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failure(error)))
    }
    function request() { return { type: userActionTypes.RESEND_VERIFICATION_REQUEST } }
    function success(user) { return { type: userActionTypes.RESEND_VERIFICATION_SUCCESS, user } }
    function failure(error) { return { type: userActionTypes.RESEND_VERIFICATION_FAILURE, error } }
}

function agreeToWeeklyDisclaimer() {
    return dispatch => {
        dispatch(request())

        userServices.agreeToWeeklyDisclaimer()
            .then(device => dispatch(success(device)))
            .catch(error => dispatch(failure(error)))

        function request() { return { type: userActionTypes.UPDATE_USER_WEEKLY_DISCLAIMER_REQUEST } }
        function success(hasAgreedToWeeklyTerms) { return { type: userActionTypes.UPDATE_USER_WEEKLY_DISCLAIMER_SUCCESS, hasAgreedToWeeklyTerms } }
        function failure(error) { return { type: userActionTypes.UPDATE_USER_WEEKLY_DISCLAIMER_FAILURE, error } }
    }
}

function loginAuto() {
    return dispatch => {
        dispatch(request())

        userServices.loginAuto()
            .then(user => dispatch(isNotNull(user) ? success(user) : failure(user)))
            .catch(error => dispatch(failure(error)))
    }

    function request() { return { type: userActionTypes.GET_REQUEST } }
    function success(user) { return { type: userActionTypes.GET_SUCCESS, user } }
    function failure(error) { return { type: userActionTypes.GET_FAILURE, error } }
}

function loginByToken(accessToken) {
    return dispatch => {
        dispatch(request())

        userServices.loginByToken(accessToken)
            .then(user => dispatch(isNotNull(user) ? success(user) : failure(user)))
            .catch(error => dispatch(failure(error)))
    }

    function request() { return { type: userActionTypes.GET_REQUEST } }
    function success(user) { return { type: userActionTypes.GET_SUCCESS, user } }
    function failure(error) { return { type: userActionTypes.GET_FAILURE, error } }
}

function resetPassword(data) {
    return dispatch => {
        dispatch(request())
        
        userServices.resetPassword(data).then(response => {
            if (isNotNull(response.success)) {
                if (!response.success)
                    dispatch(failure(response.msg))
                else
                    dispatch(success(response))
            } else {
                if (isNotNull(response.errors))
                    dispatch(failure(response.errors))
            }
        }).catch(error => {
            dispatch(failure(error))
        })
    }
    function request() { return { type: formActionTypes.RESET_PASSWORD_REQUEST } }
    function success(response) { return { type: formActionTypes.RESET_PASSWORD_SUCCESS, response } }
    function failure(error) { return { type: formActionTypes.RESET_PASSWORD_FAILURE, error } }
}

function changeEmail(data) {
    return dispatch => {
        dispatch(request())
        
        userServices.changeEmail(data).then(response => {
            if (isNotNull(response.success))
                dispatch(success(response))
        }).catch(error => {
            dispatch(failure(error))
        })
    }
    function request() { return { type: formActionTypes.CHANGE_EMAIL_REQUEST } }
    function success(response) { return { type: formActionTypes.CHANGE_EMAIL_SUCCESS, response } }
    function failure(error) { return { type: formActionTypes.CHANGE_EMAIL_FAILURE, error } }
}

export const userActions = {
    getMe,
    getId,
    getProfile,
    getProfileByToken,
    getProfileCompletionStatus,
    saveProfile,
    editProfile,
    changePassword,
    register,
    resendVerification,
    agreeToWeeklyDisclaimer,
    loginAuto,
    loginByToken,
    resetPassword,
    changeEmail
}