import styled from 'styled-components'
import { mediaQueries } from '../../constants/mediaQueries'

export const MainWrapper = styled.main`
    width: 100%;
    padding-top: 56px;

    ${mediaQueries.largeDevices} {
        padding-top: 56px;
    }

    ${mediaQueries.extraLargeDevices} {
        padding-top: 56px;
    }
`