import React, { useEffect, useState }  from 'react'
import ReactPlayer from 'react-player/lazy'
import { Carousel } from 'react-bootstrap'
import { mediaDeviceSizes } from '../../../../constants/mediaQueries'
import { getSize } from '../../../../utils'
import { IntroSectionStyle } from '../styles'
import { CheckoutButton } from '../../../../styles'

export const SMIntro = () => {
    let [device, setDevice] = useState(null)

    useEffect(() => {
        setDevice(getSize(window.innerWidth))
    }, [])

    const onScrollTo = (e) => {
        e.preventDefault() 
        document.getElementById('nextSection').scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
        })
    }

    return (
        <IntroSectionStyle className='up-win-trial'>
            <div className='container'>
                <div className='content long-txt'>
                    <h1>SIGN UP NOW AND <span className='price'>SAVE 65%</span> ON PROFITFARMERS 
                        <br />PRO'S INSANE TRADING POWER
                        <br />PLUS GET EXCLUSIVE BONUS ITEMS</h1>
                    <p>This is your <strong className='rd-txt'>LAST CHANCE OFFER</strong>. Are you ready to <strong>5x</strong> your account? Get 365 days of access to our elite signals, copy-trading, auto-trading, market scanners and market analysis. <span className='grn-txt'>Only $5 per day</span>, this deal will <strong>NEVER</strong> happen again.</p>
                </div>
                <div className='video-wrapper'>
                    <ReactPlayer 
                        url={'https://vimeo.com/756677485/6c9d0a0c10'}
                        controls={true} 
                        width='100%' 
                        volume={0} />
                </div>

                <div className='checkout-btn'>
                    <CheckoutButton 
                        position="centered"
                        type="button" 
                        onClick={(e) => onScrollTo(e)}>
                        <span>Learn More</span>
                    </CheckoutButton>
                </div>

                <div className='trust-pilot-rating'>
                    <span className='score'>4.6 Trustscore on</span>
                    <span className='tp-logo'>
                        <img src='/images/offers/trustpilot-icon.svg' className='icon-logo' alt='ProfitFarmers - TrustPilot Icon Logo' />
                        <img src='/images/offers/trustpilot-text-light.svg' className='text-logo' alt="ProfitFarmers - TrustPilot Text Logo" />
                        <img src='/images/offers/trustpilot-rating-stars.png' className='rating-stars' alt='ProfitFarmers - TrustPilot rating score' />
                    </span>
                </div>

                <div className='featured-logo-container'>
                    <span>As Featured On</span>
                    <div className='featured-logos'>
                        { (device === mediaDeviceSizes.xs) &&
                            <Carousel controls={false}>
                                <Carousel.Item interval={1500}>
                                    <div className='logo-carousel'>
                                        <img src='/images/trial-offer/ambcrypto-logo.svg' alt='TrustPilot Score' />
                                        <img src='/images/trial-offer/cryptonewsflash-logo.svg' alt='TrustPilot Score' />
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item interval={1500}>
                                    <div className='logo-carousel'>
                                        <img src='/images/trial-offer/ambcrypto-logo.svg' alt='TrustPilot Score' />
                                        <img src='/images/trial-offer/ambcrypto-logo.svg' alt='TrustPilot Score' />
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item interval={1500}>
                                    <div className='logo-carousel'>
                                        <img src='/images/trial-offer/ambcrypto-logo.svg' alt='TrustPilot Score' />
                                        <img src='/images/trial-offer/ambcrypto-logo.svg' alt='TrustPilot Score' />
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item interval={1500}>
                                    <img src='/images/trial-offer/ambcrypto-logo.svg' alt='TrustPilot Score' />
                                </Carousel.Item>
                            </Carousel>
                        }

                        { !(device === mediaDeviceSizes.xs) &&
                            <><div className='logo-col'>
                                <img src='/images/trial-offer/ambcrypto-logo.svg' alt='TrustPilot Score' />
                            </div>
                            <div className='logo-col'>
                                <img src='/images/trial-offer/cryptonewsflash-logo.svg' alt='TrustPilot Score' />
                            </div>
                            <div className='logo-col'>
                                <img src='/images/trial-offer/btcmanager-logo.svg' alt='TrustPilot Score' />
                            </div>
                            <div className='logo-col'>
                                <img src='/images/trial-offer/crypto-insider-logo.svg' alt='TrustPilot Score' />
                            </div>
                            <div className='logo-col'>
                                <img src='/images/trial-offer/btc-echo-logo.svg' alt='TrustPilot Score' />
                            </div>
                            <div className='logo-col'>
                                <img src='/images/trial-offer/coingape-logo.svg' alt='TrustPilot Score' />
                            </div>
                            <div className='logo-col'>
                                <img src='/images/trial-offer/kointimes-logo.png' alt='TrustPilot Score' />
                            </div></>
                        }    
                    </div>
                </div>
            </div>
        </IntroSectionStyle>
    )
}