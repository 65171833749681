import { Card, Container, Row, SafeAnchor } from "react-bootstrap";
import { BodyContainer, BodyWrapper, FormWrapper, OnboardingStyle, TitleWrapper } from "../Payment/styled_components";

export const GetStartedView = (props) => {
    const { email, isSending } = props.state
    const onResendConfirmationLink = email => props.onResendConfirmationEmailHandler(email)

    return (
        <BodyWrapper className="onRegistration">
            <BodyContainer className="onRegistration payment-bg">
                <OnboardingStyle className="registration-complete">
                    <Container>
                        <Row className="center">
                            <Card className="custom-card welcome">
                                <img src="/images/logo/top-header.png" alt="" className="img-fluid col-md-10 col-lg-10 col-xl-9 mt-3 p-0" />

                                <Card.Body>
                                    <Row className="center">
                                        <FormWrapper className="col col-md-10 col-lg-10 col-xl-9">

                                            <TitleWrapper >
                                                <p className="text-center">
                                                    Please confirm your email to get started setting up your account. We sent an email verification link to 
                                                    <strong> {email}</strong>. <br /><br />
                                                    {
                                                        isSending ?
                                                            <SafeAnchor>
                                                               <i className="fas fa-spinner fa-pulse" /> Resending confirmation link..
                                                            </SafeAnchor>
                                                            :
                                                            <SafeAnchor onClick={() => onResendConfirmationLink(email)}>
                                                                Resend confirmation link
                                                            </SafeAnchor>
                                                    }
                                                </p>
                                            </TitleWrapper>

                                        </FormWrapper>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Row>
                    </Container>
                </OnboardingStyle>
            </BodyContainer>
        </BodyWrapper>
    );
}