import React from 'react'
import { connect } from 'react-redux'
import { TradeNowBtnStyle } from '../../styled_components'
import { Btn } from '../../../../styles/buttons'

class SignalDetailsFooter extends React.PureComponent {
    render() {
        const { hasConnectedExchange, onMakeATrade } = this.props
        const { loading } = this.props.signalDetails

        return (
            <div className="modal-footer-wrapper">
                { !loading && 
                    <div className="signal-details-actions">
                        <div className="main-content-col">
                            <TradeNowBtnStyle>
                                <Btn variant="primary" 
                                    className="trade-now-btn trade-now-dashboard" 
                                    onClick={onMakeATrade}>
                                    <span>{hasConnectedExchange ? 'Trade Now' : 'Plan Trade'}</span>
                                </Btn>
                            </TradeNowBtnStyle>
                        </div>
                        <div className="aside-content-col"></div>
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { signalDetails } = state
    return {
        signalDetails
    }
}

const connectedSignalDetailsFooter = connect(mapStateToProps)(SignalDetailsFooter)
export { connectedSignalDetailsFooter as SignalDetailsFooter }