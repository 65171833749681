import { notificationActionTypes } from '../CONSTANTS'
import { notificationServices, signalDetailsServices } from '../../services'

function allowNotifications(token) {
    return dispatch => {
        dispatch(request())

        notificationServices.allowNotifications(token)
            .then(response => {
                dispatch(success(response))
            }, error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: notificationActionTypes.ALLOW_NOTIFICATION_REQUEST } }
    function success(response) { return { type: notificationActionTypes.ALLOW_NOTIFICATION_SUCCESS, response } }
    function failed(error) { return { type: notificationActionTypes.ALLOW_NOTIFICATION_FAILED, error } }
}

function clear() {
    return dispatch => {
        dispatch({
            type: notificationActionTypes.ACTIVE_NOTIFICATION,
            data: null
        })
    }
}

function setActiveMarkedId(notifId) {
    return dispatch => {
        dispatch({
            type: notificationActionTypes.ACTIVE_MARKED_ID,
            notifId
        })
    }
}

function setActiveNotification(data) {
    return dispatch => {
        dispatch({
            type: notificationActionTypes.ACTIVE_NOTIFICATION,
            data
        })
    }
}

function getNotificationLists() {
    return dispatch => {
        dispatch(request())

        notificationServices.getNotificationLists().then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: notificationActionTypes.GET_ALL_NOTIFICATION_LISTS_REQUEST } }
    function success(notification_lists) { return { type: notificationActionTypes.GET_ALL_NOTIFICATION_LISTS_SUCCESS, notification_lists } }
    function failure(error) { return { type: notificationActionTypes.GET_ALL_NOTIFICATION_LISTS_FAILURE, error } }
}

function markNotificationAsRead(data) {
    return dispatch => {
        dispatch(request())

        notificationServices.markNotificationAsRead(data).then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: notificationActionTypes.MARKED_NOTIFICATION_AS_READ_REQUEST } }
    function success(response) { return { type: notificationActionTypes.MARKED_NOTIFICATION_AS_READ_SUCCESS, response } }
    function failure(error) { return { type: notificationActionTypes.MARKED_NOTIFICATION_AS_READ_FAILURE, error } }
}

function getNotificationSettings() {
    return dispatch => {
        dispatch(request())

        notificationServices.getNotificationSettings()
            .then(response => {
                dispatch(success(response))
            }, error => {
                dispatch(failure(error))
            })
    }

    function request() { return { type: notificationActionTypes.GET_NOTIFICATION_SETTINGS_REQUEST } }
    function success(notification_settings) { return { type: notificationActionTypes.GET_NOTIFICATION_SETTINGS_SUCCESS, notification_settings } }
    function failure(error) { return { type: notificationActionTypes.GET_NOTIFICATION_SETTINGS_FAILURE, error } }

}

function saveNotificationTypeChanges(data) {
    return dispatch => {
        dispatch(request())

        notificationServices.saveNotificationTypeChanges(data).then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failed(error))
        })
    }

    function request() { return { type: notificationActionTypes.SAVE_NOTIFICATION_TYPE_SETTINGS_REQUEST } }
    function success(response) { return { type: notificationActionTypes.SAVE_NOTIFICATION_TYPE_SETTINGS_SUCCESS, response } }
    function failed(error) { return { type: notificationActionTypes.SAVE_NOTIFICATION_TYPE_SETTINGS_FAILED, error } }
}

function saveNotificationSettings(data) {
    return dispatch => {
        dispatch(request())

        notificationServices.saveNotificationSettings(data).then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: notificationActionTypes.SAVE_NOTIFICATION_SETTINGS_REQUEST } }
    function success(response) { return { type: notificationActionTypes.SAVE_NOTIFICATION_SETTINGS_SUCCESS, response } }
    function failure(error) { return { type: notificationActionTypes.SAVE_NOTIFICATION_SETTINGS_FAILURE, error } }
}

function getNotificationPreview(sid) {
    return dispatch => {
        dispatch(request())

        signalDetailsServices.getById(sid).then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: notificationActionTypes.GET_NOTIFICATION_PREVIEW_REQUEST } }
    function success(notificationPreview) { return { type: notificationActionTypes.GET_NOTIFICATION_PREVIEW_SUCCESS, notificationPreview } }
    function failure(error) { return { type: notificationActionTypes.GET_NOTIFICATION_PREVIEW_FAILURE, error } }
}

function clearNotificationPreview() {
    return dispatch => {
        dispatch({
            type: notificationActionTypes.CLEAR_NOTIFICATION_PREVIEW
        })
    }
}

export const notificationActions = {
    allowNotifications,
    getNotificationSettings,
    saveNotificationTypeChanges,
    saveNotificationSettings,
    getNotificationLists,
    markNotificationAsRead,
    setActiveMarkedId,
    setActiveNotification,
    clear,
    getNotificationPreview,
    clearNotificationPreview
}