
import glamorous from 'glamorous'

export const BellContentWrapper = glamorous.div({
    '& button': {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        fontSize: '20px',
        border: 'none',
        width: '36px',
        height: '36px',
        textAlign: 'center',
        borderRadius: '50%',
        background: 'none',
        backgroundImage: 'none',
        outline: 'none',
        boxShadow: 'none',
        backgroundColor: '#23303E',

        '& .counter': {
            position: 'absolute',
            top: '7px',
            right: '6px',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: '#D81D68'
        },

        '&:focus,:visited,:hover': {
            boxShadow: 'none',
            border: 'none',
            outline: 'none',
            color: '#ced0dd'
        },

        '& svg': {
            display: 'inline-block',
            pointerEvents: 'none',
            color: '#b8cde3',
            width: '14px',
            height: '20px'
        }
    }
})