import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const LastChanceStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    background-color: transparent;
    padding: 40px 0;

    .container {
        .text-with-img {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;

            .text-col {
                width: 100%;

                .section-title {
                    font-weight: 800;
                    color: #FFFFFF;
                    text-align: center;
                    font-size: 30px;
                    margin-bottom: 20px;                    
                }

                p {
                    color: #FFFFFF;
                    text-align: center;
                }

                .offer-validity-timer {
                    ul {
                        margin: 20px 0;
                        padding: 0;
                        list-style-type: none;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;

                        li {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            padding: 0 15px;
                            color: #FFFFFF;
                            line-height: 1;

                            .counter {
                                font-size: 33px;
                                font-weight: 600;
                            }

                            small {
                                font-size: 12px;
                                letter-spacing: 1px;
                            }
                        }
                    }
                }

                .price {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 15px;

                    .crashed-out-price {
                        display: inline-block;
                        color: #516475;
                        position: relative;
                        font-size: 48px;
                        font-weight: 600;
                        width: 97px;
                        line-height: 1;

                        &:before {
                            position: absolute;
                            content: "";
                            left: 0;
                            top: 45%;
                            right: 0;
                            border-top: 3px solid;
                            border-color: #FF3030;
                            -webkit-transform: rotate(168deg);
                            -moz-transform: rotate(168deg);
                            -ms-transform: rotate(168deg);
                            -o-transform: rotate(168deg);
                            transform: rotate(168deg);
                        } 
                    }

                    .new-price {
                        color: #FFFFFF;
                        font-size: 70px;
                        font-weight: 600;
                        line-height: 1;
                        margin-left: auto;
                        margin-right: 15px;
                    }

                    .price-tag {
                        margin-right: 0;
                        
                        > img {
                            width: 150px;
                        }
                    }
                }

                .discount-tag {
                    background-color: #FF3030;
                    padding: 10px 12px;
                    color: #FFFFFF;
                    text-align: center;
                    font-size: 17px;
                    font-weight: 600;
                    margin-bottom: 20px;
                }
            }

            .img-wrapper {
                width: 100%;
                text-align: center;

                img {
                    width: 85%;
                    margin: 0 auto 15px;
                }
            }
        }

        .direction {
            display: block;
            margin: 20px;
            height: 36px;

            .arrows {
                width: 60px;
                height: 72px;
                position: absolute;
                left: 50%;
                margin-left: -30px;
                bottom: 20px;
            }

            .arrows path {
                stroke: #2994D1;
                fill: transparent;
                stroke-width: 1px;	
                animation: arrow 2s infinite;
                -webkit-animation: arrow 2s infinite; 
            }

            .arrows path.a1 {
                animation-delay:-1s;
                -webkit-animation-delay:-1s; /* Safari 和 Chrome */
            }

            .arrows path.a2 {
                animation-delay:-0.5s;
                -webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
            }

            .arrows path.a3 {	
                animation-delay:0s;
                -webkit-animation-delay:0s; /* Safari 和 Chrome */
            }
        }
    }

    &.ret {
        .container {
            .text-with-img {
                .text-col {
                    .price {
                        .crashed-out-price {
                            width: 122px;
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        .container {
            .text-with-img {
                .text-col {
                    .offer-validity-timer {
                        ul {
                            margin: 30px 0 40px;

                            li {
                                .counter {
                                    font-size: 83px;
                                }

                                small {
                                    font-size: 15px;
                                }
                            }
                        }
                    }

                    .discount-tag {
                        font-size: 25px;
                        margin-bottom: 25px;
                    }
                }

                .img-wrapper {
                    img {
                        width: 60%;
                    }
                }
            }

            .direction {
                height: 72px;
            }
        }
    }

    ${mediaQueries.largeDevices} {
        .container {
            .text-with-img {
                flex-direction: row;

                .img-wrapper {
                    width: calc(100% - 510px);

                    img {
                        width: 95%;
                    }
                }

                .text-col {
                    width: 510px;

                    .section-title {                        
                        text-align: left;
                        max-width: 520px;
                        font-weight: 900;
                        letter-spacing: 1px;
                    }

                    p {
                        font-size: 16px;
                        text-align: left;

                        &.text-md {
                            font-size: 20px;
                        }
                    }

                    .offer-validity-timer {
                        ul {
                            li {
                                .counter {
                                    font-size: 72px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        padding: 60px 0;

        .container {
            .text-with-img {
                .text-col {
                    .section-title {
                        font-size: 64px;
                    }

                    p {
                        font-size: 18px;
                    }
                }
            }
        }

        &.juiced-up {
            .container {
                .text-with-img {
                    .text-col {
                        .section-title {
                            font-size: 38px;
                        }
                    }
                }
            }
        }
    }

    @keyframes arrow
    {
        0% { opacity:0 }
        40% { opacity:1 }
        80% { opacity:0 }
        100% { opacity:0 }
    }

    @-webkit-keyframes arrow /*Safari and Chrome*/
    {
        0% { opacity:0 }
        40% { opacity:1 }
        80% { opacity:0 }
        100% { opacity:0 }
    }
`