import { statisticActionTypes } from '../CONSTANTS'
import { statisticServices } from '../../services'
import { decryptText, toCamelCase } from '../../utils'

function getNetPeakGains(period) {
    return dispatch => {
        dispatch(request())

        statisticServices.getNetPeakGains(period)
            .then(response => {
                dispatch(success(response))
            }, error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: statisticActionTypes.GET_NET_PEAK_GAINS_REQUEST } }
    function success(response) { return { type: statisticActionTypes.GET_NET_PEAK_GAINS_SUCCESS, response }}
    function failed(error) { return { type: statisticActionTypes.GET_NET_PEAK_GAINS_FAILED, error }}
}

function getMonthlyPeakGains() {
    return dispatch => {
        dispatch(request())

        statisticServices.getMonthlyPeakGains()
            .then(response => {
                dispatch(success(response))
            }, error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: statisticActionTypes.GET_MONTHLY_PEAK_GAINS_REQUEST } }
    function success(response) { return { type: statisticActionTypes.GET_MONTHLY_PEAK_GAINS_SUCCESS, response } }
    function failed(error) { return { type: statisticActionTypes.GET_MONTHLY_PEAK_GAINS_FAILED, error } }
}

function getTopWinners() {
    return dispatch => {
        dispatch(request())

        statisticServices.getTopWinners()
            .then(response => {                
                let resultData = decryptText(response.data)
                let parsedData = JSON.parse(resultData, toCamelCase)
                dispatch(success(parsedData))
            }, error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: statisticActionTypes.GET_TOP_WINNERS_REQUEST } }
    function success(response) { return { type: statisticActionTypes.GET_TOP_WINNERS_SUCCESS, response }}
    function failed(error) { return { type: statisticActionTypes.GET_TOP_WINNERS_FAILED, error } }
}

function getStatistics(data) {
    return dispatch => {
        dispatch(request())

        statisticServices.getStatistics(data).then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: statisticActionTypes.GET_STATISTICS_REQUEST } }
    function success(statistics) { return { type: statisticActionTypes.GET_STATISTICS_SUCCESS, statistics } }
    function failure(error) { return { type: statisticActionTypes.GET_STATISTICS_FAILURE, error } }
}

function getStrategies() {
    return dispatch => {
        dispatch(request())

        statisticServices.getStrategies().then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: statisticActionTypes.STRATEGIES_FILTER_REQUEST } }
    function success(strategies) { return { type: statisticActionTypes.STRATEGIES_FILTER_SUCCESS, strategies } }
    function failure(error) { return { type: statisticActionTypes.STRATEGIES_FILTER_FAILURE, error } }
}

function getPeriodRange() {
    return dispatch => {
        dispatch(request())

        statisticServices.getPeriodRange().then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: statisticActionTypes.PERIODRANGE_FILTER_REQUEST } }
    function success(period_range) { return { type: statisticActionTypes.PERIODRANGE_FILTER_SUCCESS, period_range } }
    function failure(error) { return { type: statisticActionTypes.PERIODRANGE_FILTER_FAILURE, error } }
}

export const statisticActions = {
    getNetPeakGains,
    getMonthlyPeakGains,
    getTopWinners,
    getStatistics,
    getStrategies,
    getPeriodRange
}