import React from 'react'
import { PowerBreakdownStyle } from './styles/PowerBreakdownStyle'

export const PowerBreakdownSection = () => {
    return (
        <PowerBreakdownStyle>
            <div className='container'>
                <div className='section-header'>
                    <h2 className='section-title'>How ProfitFarmers Pro Empowers You to 10x Your Account or More</h2>
                </div>

                <ul className='propower-list'>
                    <li className='more-signals'>
                        <div className='thumbnail'>
                            <img src='/images/subscriptions/more-signals.png' alt='' />
                        </div>
                        <div className='text-content'>
                            <p>Potentially hundreds more signals per month. 300+ is not uncommon during bull runs and breakouts.</p>
                        </div>
                    </li>
                    <li className='target-2'>
                        <div className='thumbnail'>
                            <img src='/images/subscriptions/target-2.png' alt='' />
                        </div>
                        <div className='text-content'>
                            <p>T2 is the unsung hero that you're going to be best friends with. Breakout from the lower Risk:Reward plays and strike gold ~50% of the time on each completed signal. In March 2022, Target 2 even offered a face melting +49% gain per trade. And that's only T2. Think about what T3 and T4 might bring.</p>
                        </div>
                    </li>
                    <li>
                        <div className='thumbnail'>
                            <img src='/images/subscriptions/highest-avg-gains.png' alt='' />
                        </div>
                        <div className='text-content'>
                            <p>Unlock all the highest average gains at T3 (+31%) and T4 (+57%) and at least 6x your reward profile without increasing risk. Almost 20% of our completed signals go to T4. If you get 100 more signals a month, that's 20 high-powered signals alone. 200 more? That's 40 MORE signals with average gains of +57% at T4. Are you seeing the power yet?</p>
                        </div>
                    </li>
                    <li>
                        <div className='thumbnail'>
                            <img src='/images/subscriptions/breakeven-stoploss.png' alt='' />
                        </div>
                        <div className='text-content'>
                            <p>Add the #1 most powerful feature to your arsenal, and truly become unstoppable. Break-Even Stop-loss removes all emotion, limits your losses without limiting profits, and saves you hours of time and unlimited stress. You'll never trade scared again.</p>
                        </div>
                    </li>
                </ul>
            </div>
        </PowerBreakdownStyle>
    )
}