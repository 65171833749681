const reminderActionTypes = { 
    GET_REMINDERS_REQUEST: 'GET_REMINDERS_REQUEST',
    GET_REMINDERS_SUCCESS: 'GET_REMINDERS_SUCCESS',
    GET_REMINDERS_FAILED: 'GET_REMINDERS_FAILED',

    SAVE_REMINDER_REQUEST: 'SAVE_REMINDER_REQUEST',
    SAVE_REMINDER_SUCCESS: 'SAVE_REMINDER_SUCCESS',
    SAVE_REMINDER_FAILED: 'SAVE_REMINDER_FAILED',
}

const tradeDoctorSessionActionTypes = {
    GET_TRADEDOC_SESSION_REQUEST: 'GET_TRADEDOC_SESSION_REQUEST',
    GET_TRADEDOC_SESSION_SUCCESS: 'GET_TRADEDOC_SESSION_SUCCESS',
    GET_TRADEDOC_SESSION_FAILED: 'GET_TRADEDOC_SESSION_FAILED',

    SAVE_TRADEDOC_SESSION_REQUEST: 'SAVE_TRADEDOC_SESSION_REQUEST',
    SAVE_TRADEDOC_SESSION_SUCCESS: 'SAVE_TRADEDOC_SESSION_SUCCESS',
    SAVE_TRADEDOC_SESSION_FAILED: 'SAVE_TRADEDOC_SESSION_FAILED'
}

const messagesActionTypes = {
    GET_LIST_REQUEST: 'GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'GET_LIST_SUCCESS',
    GET_LIST_FAILED: 'GET_LIST_FAILED',
    
    LOAD_MORE_REQUEST: 'LOAD_MORE_REQUEST',
    LOAD_MORE_SUCCESS: 'LOAD_MORE_SUCCESS',
    LOAD_MORE_FAILED: 'LOAD_MORE_FAILED',

    RESET_LIST: 'RESET_LIST',

    ADD_HEADER_PHOTO_REQUEST: 'ADD_HEADER_PHOTO_REQUEST',
    ADD_HEADER_PHOTO_SUCCESS: 'ADD_HEADER_PHOTO_SUCCESS',
    ADD_HEADER_PHOTO_FAILED: 'ADD_HEADER_PHOTO_FAILED',

    SAVE_MESSAGE_REQUEST: 'SAVE_MESSAGE_REQUEST',
    SAVE_MESSAGE_SUCCESS: 'SAVE_MESSAGE_SUCCESS',
    SAVE_MESSAGE_FAILED: 'SAVE_MESSAGE_FAILED',
    SAVE_MESSAGE_RESET: 'SAVE_MESSAGE_RESET',

    EDIT_MESSAGE_REQUEST: 'EDIT_MESSAGE_REQUEST',
    EDIT_MESSAGE_SUCCESS: 'EDIT_MESSAGE_SUCCESS',
    EDIT_MESSAGE_FAILED: 'EDIT_MESSAGE_FAILED',
    EDIT_MESSAGE_RESET: 'EDIT_MESSAGE_RESET',

    UPDATE_MESSAGE_LIST: 'UPDATE_MESSAGE_LIST'
}

const offersActionTypes = {
    SKIP_OFFER_REQUEST: 'SKIP_OFFER_REQUEST',
    SKIP_OFFER_SUCCESS: 'SKIP_OFFER_SUCCESS',
    SKIP_OFFER_FAILED: 'SKIP_OFFER_FAILED'
}

const signalStatesActionTypes = {
    GET_STATES_REQUEST: 'GET_STATES_REQUEST',
    GET_STATES_SUCCESS: 'GET_STATES_SUCCESS',
    GET_STATES_FAIL: 'GET_STATES_FAIL'
}

const signalActionTypes = {
    GET_SIGNALS_REQUEST: 'GET_SIGNALS_REQUEST',
    GET_SIGNALS_SUCCESS: 'GET_SIGNALS_SUCCESS',
    GET_SIGNALS_FAIL: 'GET_SIGNALS_FAIL',
    GET_NEW_SIGNALS_REQUEST: 'GET_NEW_SIGNALS_REQUEST',
    GET_NEW_SIGNALS_SUCCESS: 'GET_NEW_SIGNALS_SUCCESS',
    GET_NEW_SIGNALS_FAIL: 'GET_NEW_SIGNALS_FAIL',
    UPDATE_SIGNAL_STATE: 'UPDATE_SIGNAL_STATE',
    UPDATE_SIGNAL: 'UPDATE_SIGNAL',
    RESET: 'RESET',
    CLEAR_SIGNALS: 'CLEAR_SIGNALS'
}

const signalDetailsActionTypes = {
    GET_BY_ID_REQUEST: 'GET_BY_ID_REQUEST',
    GET_BY_ID_SUCCESS: 'GET_BY_ID_SUCCESS',
    GET_BY_ID_FAIL: 'GET_BY_ID_FAIL',
    UPDATE_SIGNAL_DETAILS: 'UPDATE_SIGNAL_DETAILS'
}

const followedSignalActionTypes = {
    GET_FOLLOWED_SIGNALS_REQUEST: 'GET_FOLLOWED_SIGNALS_REQUEST',
    GET_FOLLOWED_SIGNALS_SUCCESS: 'GET_FOLLOWED_SIGNALS_SUCCESS',
    GET_FOLLOWED_SIGNALS_FAIL: 'GET_FOLLOWED_SIGNALS_FAIL',
    FOLLOW_SIGNAL_REQUEST: 'FOLLOW_SIGNALS_REQUEST',
    FOLLOW_SIGNAL_SUCCESS: 'FOLLOW_SIGNAL_SUCCESS',
    FOLLOW_SIGNAL_FAIL: 'FOLLOW_SIGNAL_FAIL',
}

const authActionTypes = {
    LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    LOGIN_FAIL: 'USER_LOGIN_FAIL',
    LOGOUT: 'USER_LOGOUT',
    LOGIN_CLEAR: 'LOGIN_ERROR_CLEAR',

    AUTO_LOGIN_REQUEST: 'AUTO_LOGIN_REQUEST',
    AUTO_LOGIN_SUCCESS: 'AUTO_LOGIN_SUCCESS',
    AUTO_LOGIN_FAILED: 'AUTO_LOGIN_FAILED',

    VERIFY_CODE_REQUEST: 'VERIFY_CODE_REQUEST',
    VERIFY_CODE_SUCCESS: 'VERIFY_CODE_SUCCESS',
    VERIFY_CODE_FAILED: 'VERIFY_CODE_FAILED',

    RESEND_VERIFICATION_CODE_REQUEST: 'RESEND_VERIFICATION_CODE_REQUEST',
    RESEND_VERIFICATION_CODE_SUCCESS: 'RESEND_VERIFICATION_CODE_SUCCESS',
    RESEND_VERIFICATION_CODE_FAILED: 'RESEND_VERIFICATION_CODE_FAILED',

    GET_REQUEST: 'USER_GET_REQUEST',
    GET_SUCCESS: 'USER_GET_SUCCESS',
    GET_FAILURE: 'USER_GET_FAILURE',

    CLEAR_AUTH: 'CLEAR_AUTHENTICATION'
}

const onboardingActionTypes = {
    GET_ONBOARDING_STATE_REQUEST: 'GET_ONBOARDING_STATE_REQUEST',
    GET_ONBOARDING_STATE_SUCCESS: 'GET_ONBOARDING_STATE_SUCCESS',
    GET_ONBOARDING_STATE_FAILED: 'GET_ONBOARDING_STATE_FAILED',

    GET_ONBOARDING_USER_PROFILE_REQUEST: 'GET_ONBOARDING_USER_PROFILE_REQUEST',
    GET_ONBOARDING_USER_PROFILE_SUCCESS: 'GET_ONBOARDING_USER_PROFILE_SUCCESS',
    GET_ONBOARDING_USER_PROFILE_FAILED: 'GET_ONBOARDING_USER_PROFILE_FAILED',

    GET_ONBOARDING_USER_TRADING_EXP_DETAILS_REQUEST: 'GET_ONBOARDING_USER_TRADING_EXP_DETAILS_REQUEST',
    GET_ONBOARDING_USER_TRADING_EXP_DETAILS_SUCCESS: 'GET_ONBOARDING_USER_TRADING_EXP_DETAILS_SUCCESS',
    GET_ONBOARDING_USER_TRADING_EXP_DETAILS_FAILED: 'GET_ONBOARDING_USER_TRADING_EXP_DETAILS_FAILED',

    GET_ONBOARDING_USER_FINANCIAL_DETAILS_REQUEST: 'GET_ONBOARDING_USER_FINANCIAL_DETAILS_REQUEST',
    GET_ONBOARDING_USER_FINANCIAL_DETAILS_SUCCESS: 'GET_ONBOARDING_USER_FINANCIAL_DETAILS_SUCCESS',
    GET_ONBOARDING_USER_FINANCIAL_DETAILS_FAILED: 'GET_ONBOARDING_USER_FINANCIAL_DETAILS_FAILED',

    GET_ONBOARDING_USER_WALLET_BALANCES_REQUEST: 'GET_ONBOARDING_USER_WALLET_BALANCES_REQUEST',
    GET_ONBOARDING_USER_WALLET_BALANCES_SUCCESS: 'GET_ONBOARDING_USER_WALLET_BALANCES_SUCCESS',
    GET_ONBOARDING_USER_WALLET_BALANCES_FAILED: 'GET_ONBOARDING_USER_WALLET_BALANCES_FAILED',

    CONFIGURE_EXCHANGE_INTEGRATION_REQUEST: 'CONFIGURE_EXCHANGE_INTEGRATION_REQUEST',
    CONFIGURE_EXCHANGE_INTEGRATION_SUCCESS: 'CONFIGURE_EXCHANGE_INTEGRATION_SUCCESS',
    CONFIGURE_EXCHANGE_INTEGRATION_FAILED: 'CONFIGURE_EXCHANGE_INTEGRATION_FAILED',

    CONFIGURE_TRADING_BALANCE_REQUEST: 'CONFIGURE_TRADING_BALANCE_REQUEST',
    CONFIGURE_TRADING_BALANCE_SUCCESS: 'CONFIGURE_TRADING_BALANCE_SUCCESS',
    CONFIGURE_TRADING_BALANCE_FAILED: 'CONFIGURE_TRADING_BALANCE_FAILED',

    SAVE_ONBOARDING_USER_PROFILE_REQUEST: 'SAVE_ONBOARDING_USER_PROFILE_REQUEST',
    SAVE_ONBOARDING_USER_PROFILE_SUCCESS: 'SAVE_ONBOARDING_USER_PROFILE_SUCCESS',
    SAVE_ONBOARDING_USER_PROFILE_FAILED: 'SAVE_ONBOARDING_USER_PROFILE_FAILED',

    SAVE_ONBOARDING_USER_TRADING_EXP_DETAILS_REQUEST: 'SAVE_ONBOARDING_USER_TRADING_EXP_DETAILS_REQUEST',
    SAVE_ONBOARDING_USER_TRADING_EXP_DETAILS_SUCCESS: 'SAVE_ONBOARDING_USER_TRADING_EXP_DETAILS_SUCCESS',
    SAVE_ONBOARDING_USER_TRADING_EXP_DETAILS_FAILED: 'SAVE_ONBOARDING_USER_TRADING_EXP_DETAILS_FAILED',
    
    SAVE_ONBOARDING_USER_FINANCIAL_DETAILS_REQUEST: 'SAVE_ONBOARDING_USER_FINANCIAL_DETAILS_REQUEST',
    SAVE_ONBOARDING_USER_FINANCIAL_DETAILS_SUCCESS: 'SAVE_ONBOARDING_USER_FINANCIAL_DETAILS_SUCCESS',
    SAVE_ONBOARDING_USER_FINANCIAL_DETAILS_FAILED: 'SAVE_ONBOARDING_USER_FINANCIAL_DETAILS_FAILED',

    SAVE_ONBOARDING_USER_EXCHANGE_KEYS_REQUEST: 'SAVE_ONBOARDING_USER_EXCHANGE_KEYS_REQUEST',
    SAVE_ONBOARDING_USER_EXCHANGE_KEYS_SUCCESS: 'SAVE_ONBOARDING_USER_EXCHANGE_KEYS_SUCCESS',
    SAVE_ONBOARDING_USER_EXCHANGE_KEYS_FAILED: 'SAVE_ONBOARDING_USER_EXCHANGE_KEYS_FAILED',

    SET_TRADING_BALANCE_REQUEST: 'SET_TRADING_BALANCE_REQUEST',
    SET_TRADING_BALANCE_SUCCESS: 'SET_TRADING_BALANCE_SUCCESS',
    SET_TRADING_BALANCE_FAILED: 'SET_TRADING_BALANCE_FAILED',

    SET_ONBOARDING_COMPLETE_REQUEST: 'SET_ONBOARDING_COMPLETE_REQUEST',
    SET_ONBOARDING_COMPLETE_SUCCESS: 'SET_ONBOARDING_COMPLETE_SUCCESS',
    SET_ONBOARDING_COMPLETE_FAILED: 'SET_ONBOARDING_COMPLETE_FAILED',

    SKIP_ONBOARDING_REQUEST: 'SKIP_ONBOARDING_REQUEST',
    SKIP_ONBOARDING_SUCCESS: 'SKIP_ONBOARDING_SUCCESS',
    SKIP_ONBOARDING_FAILED: 'SKIP_ONBOARDING_FAILED'
}

const themeActionTypes = {
    GET_THEME_REQUEST: 'GET_THEME_REQUEST',
    GET_THEME_SUCCESS: 'GET_THEME_SUCCESS',
    GET_THEME_FAILURE: 'GET_THEME_FAILURE',

    SAVE_THEME_REQUEST: 'SAVE_THEME_REQUEST',
    SAVE_THEME_SUCCESS: 'SAVE_THEME_SUCCESS',
    SAVE_THEME_FAILURE: 'SAVE_THEME_FAILURE',

    SET_THEME_SETTINGS: 'SET_THEME_SETTINGS'
}

const userActionTypes = {
    GET_REQUEST: 'USER_GET_REQUEST',
    GET_SUCCESS: 'USER_GET_SUCCESS',
    GET_FAILURE: 'USER_GET_FAILURE',

    GET_ID_REQUEST: 'GET_ID_REQUEST',
    GET_ID_SUCCESS: 'GET_ID_SUCCESS',
    GET_ID_FAILED: 'GET_ID_FAILED',

    GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

    GET_PROFILE_COMPLETION_STATUS_REQUEST: 'GET_PROFILE_COMPLETION_STATUS_REQUEST',
    GET_PROFILE_COMPLETION_STATUS_SUCCESS: 'GET_PROFILE_COMPLETION_STATUS_SUCCESS',
    GET_PROFILE_COMPLETION_STATUS_FAILED: 'GET_PROFILE_COMPLETION_STATUS_FAILED',

    SAVE_PROFILE_REQUEST: 'SAVE_PROFILE_REQUEST',
    SAVE_PROFILE_SUCCESS: 'SAVE_PROFILE_SUCCESS',
    SAVE_PROFILE_FAILURE: 'SAVE_PROFILE_FAILURE',

    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',

    LOGOUT: 'USER_LOGOUT',

    RESEND_VERIFICATION_REQUEST: 'RESEND_VERIFICATION_REQUEST',
    RESEND_VERIFICATION_SUCCESS: 'RESEND_VERIFICATION_SUCCESS',
    RESEND_VERIFICATION_FAILURE: 'RESEND_VERIFICATION_FAILURE',

    UPDATE_USER_WEEKLY_DISCLAIMER_REQUEST: 'UPDATE_USER_WEEKLY_DISCLAIMER_REQUEST',
    UPDATE_USER_WEEKLY_DISCLAIMER_SUCCESS: 'UPDATE_USER_WEEKLY_DISCLAIMER_SUCCESS',
    UPDATE_USER_WEEKLY_DISCLAIMER_FAILURE: 'UPDATE_USER_WEEKLY_DISCLAIMER_FAILURE',
}

const leadActionTypes = {
    LEAD_CHANGE_PASSWORD_REQUEST: 'LEAD_CHANGE_PASSWORD_REQUEST',
    LEAD_CHANGE_PASSWORD_SUCCESS: 'LEAD_CHANGE_PASSWORD_SUCCESS',
    LEAD_CHANGE_PASSWORD_FAILURE: 'LEAD_CHANGE_PASSWORD_FAILURE',
    LEAD_CHANGE_PASSWORD_CLEAR: 'LEAD_CHANGE_PASSWORD_CLEAR',
}

const deviceActionTypes = {
    SIGNOUT_DEVICES_REQUEST: 'SIGNOUT_DEVICES_REQUEST',
    SIGNOUT_DEVICES_SUCCESS: 'SIGNOUT_DEVICES_SUCCESS',
    SIGNOUT_DEVICES_FAILURE: 'SIGNOUT_DEVICES_FAILURE',

    VALIDATE_SIGNOUT_DEVICES_REQUEST: 'VALIDATE_SIGNOUT_DEVICES_REQUEST',
    VALIDATE_SIGNOUT_DEVICES_SUCCESS: 'VALIDATE_SIGNOUT_DEVICES_SUCCESS',
    VALIDATE_SIGNOUT_DEVICES_FAILURE: 'VALIDATE_SIGNOUT_DEVICES_FAILURE',
}

const forgotPassActionTypes = {
    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
}

const notificationActionTypes = {
    ALLOW_NOTIFICATION_REQUEST: 'ALLOW_NOTIFICATION_REQUEST',
    ALLOW_NOTIFICATION_SUCCESS: 'ALLOW_NOTIFICATION_SUCCESS',
    ALLOW_NOTIFICATION_FAILED: 'ALLOW_NOTIFICATION_FAILED',

    GET_ALL_NOTIFICATION_LISTS_REQUEST: 'ALL_NOTIFICATION_LISTS_REQUEST',
    GET_ALL_NOTIFICATION_LISTS_SUCCESS: 'ALL_NOTIFICATION_LISTS_SUCCESS',
    GET_ALL_NOTIFICATION_LISTS_FAILURE: 'ALL_NOTIFICATION_LISTS_FAILURE',

    MARKED_NOTIFICATION_AS_READ_REQUEST: 'MARKED_NOTIFICATION_AS_READ_REQUEST',
    MARKED_NOTIFICATION_AS_READ_SUCCESS: 'MARKED_NOTIFICATION_AS_READ_SUCCESS',
    MARKED_NOTIFICATION_AS_READ_FAILURE: 'MARKED_NOTIFICATION_AS_READ_FAILURE',
    ACTIVE_MARKED_ID: 'ACTIVE_MARKED_ID',
    CLEAR_MARKED_NOTIFICATION: 'CLEAR_MARKED_NOTIFICATION',
    ACTIVE_NOTIFICATION: 'ACTIVE_NOTIFICATION',

    GET_NOTIFICATION_PREVIEW_REQUEST: 'GET_NOTIFICATION_PREVIEW_REQUEST',
    GET_NOTIFICATION_PREVIEW_SUCCESS: 'GET_NOTIFICATION_PREVIEW_SUCCESS',
    GET_NOTIFICATION_PREVIEW_FAILURE: 'GET_NOTIFICATION_PREVIEW_FAILURE',

    CLEAR_NOTIFICATION_PREVIEW: 'CLEAR_NOTIFICATION_PREVIEW',

    GET_NOTIFICATION_SETTINGS_REQUEST: 'NOTIFICATION_SETTINGS_REQUEST',
    GET_NOTIFICATION_SETTINGS_SUCCESS: 'NOTIFICATION_SETTINGS_SUCCESS',
    GET_NOTIFICATION_SETTINGS_FAILURE: 'NOTIFICATION_SETTINGS_FAILURE',

    SAVE_NOTIFICATION_TYPE_SETTINGS_REQUEST: 'SAVE_NOTIFICATION_TYPE_SETTINGS_REQUEST',
    SAVE_NOTIFICATION_TYPE_SETTINGS_SUCCESS: 'SAVE_NOTIFICATION_TYPE_SETTINGS_SUCCESS',
    SAVE_NOTIFICATION_TYPE_SETTINGS_FAILED: 'SAVE_NOTIFICATION_TYPE_SETTINGS_FAILED',

    SAVE_NOTIFICATION_SETTINGS_REQUEST: 'SAVE_NOTIFICATION_SETTINGS_REQUEST',
    SAVE_NOTIFICATION_SETTINGS_SUCCESS: 'SAVE_NOTIFICATION_SETTINGS_SUCCESS',
    SAVE_NOTIFICATION_SETTINGS_FAILURE: 'SAVE_NOTIFICATION_SETTINGS_FAILURE',

    UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION'
}

const sidebarActionTypes = {
    SHOW_SIDEBAR: 'SHOW_SIDEBAR'
}

const tradingActionTypes = {
    TRADING_GET_AUTOLOGIN_REQUEST: 'TRADING_GET_AUTOLOGIN_REQUEST',
    TRADING_GET_AUTOLOGIN_SUCCESS: 'TRADING_GET_AUTOLOGIN_SUCCESS',
    TRADING_GET_AUTOLOGIN_FAILURE: 'TRADING_GET_AUTOLOGIN_FAILURE',

    TRADING_REQUEST_SETTINGS: 'TRADING_REQUEST_SETTINGS',
    TRADING_SUCCESS_SETTINGS: 'TRADING_SUCCESS_SETTINGS',
    TRADING_FAILURE_SETTINGS: 'TRADING_FAILURE_SETTINGS',

    TRADING_GET_KEYS_REQUEST: 'TRADING_GET_KEYS_REQUEST',
    TRADING_GET_KEYS_SUCCESS: 'TRADING_GET_KEYS_SUCCESS',
    TRADING_GET_KEYS_FAILURE: 'TRADING_GET_KEYS_FAILURE',

    TRADING_GET_EXCHANGE_REQUEST: 'TRADING_GET_EXCHANGE_REQUEST',
    TRADING_GET_EXCHANGE_SUCCESS: 'TRADING_GET_EXCHANGE_SUCCESS',
    TRADING_GET_EXCHANGE_FAILURE: 'TRADING_GET_EXCHANGE_FAILURE',

    EXISTING_TRAILING_USER_REQUEST: 'EXISTING_TRAILING_USER_REQUEST',
    EXISTING_TRAILING_USER_SUCCESS: 'EXISTING_TRAILING_USER_SUCCESS',
    EXISTING_TRAILING_USER_FAILURE: 'EXISTING_TRAILING_USER_FAILURE',

    TRADING_SEND_PASSWORD_REQUEST: 'TRADING_SEND_PASSWORD_REQUEST',
    TRADING_SEND_PASSWORD_SUCCESS: 'TRADING_SEND_PASSWORD_SUCCESS',
    TRADING_SEND_PASSWORD_FAILURE: 'TRADING_SEND_PASSWORD_FAILURE',

    SAVE_AUTO_CLOSE_TRADE_REQUEST: 'SAVE_AUTO_CLOSE_TRADE_REQUEST',
    SAVE_AUTO_CLOSE_TRADE_SUCCESS: 'SAVE_AUTO_CLOSE_TRADE_SUCCESS',
    SAVE_AUTO_CLOSE_TRADE_FAILED: 'SAVE_AUTO_CLOSE_TRADE_FAILED',

    TRADING_SAVE_KEYS_REQUEST: 'TRADING_SAVE_KEYS_REQUEST',
    TRADING_SAVE_KEYS_SUCCESS: 'TRADING_SAVE_KEYS_SUCCESS',
    TRADING_SAVE_KEYS_FAILURE: 'TRADING_SAVE_KEYS_FAILURE',

    TRADING_REQUEST_DISABLE: 'TRADING_REQUEST_DISABLE',
    TRADING_SUCCESS_DISABLE: 'TRADING_SUCCESS_DISABLE',
    TRADING_FAILURE_DISABLE: 'TRADING_FAILURE_DISABLE',

    TRADING_REMOVE_KEYS_REQUEST: 'TRADING_REMOVE_KEYS_REQUEST',
    TRADING_REMOVE_KEYS_SUCCESS: 'TRADING_REMOVE_KEYS_SUCCESS',
    TRADING_REMOVE_KEYS_FAILURE: 'TRADING_REMOVE_KEYS_FAILURE',

    CLEAR: 'CLEAR',
    DISABLE_CLEAR: 'DISABLE_CLEAR',
    CLEAR_API_KEY: 'CLEAR_API_KEY',

    GET_TRADE_DETAILS_REQUEST: 'GET_TRADE_DETAILS_REQUEST',
    GET_TRADE_DETAILS_SUCCESS: 'GET_TRADE_DETAILS_SUCCESS',
    GET_TRADE_DETAILS_FAILURE: 'GET_TRADE_DETAILS_FAILURE',
    CLEAR_TRADE_DETAILS: 'CLEAR_TRADE_DETAILS'
}

const tradeOrderActionTypes = {
    SET_LEVERAGE_REQUEST: 'SET_LEVERAGE_REQUEST',
    SET_LEVERAGE_SUCCESS: 'SET_LEVERAGE_SUCCESS',
    SET_LEVERAGE_FAILURE: 'SET_LEVERAGE_FAILURE',
    SET_LEVERAGE_CLEAR: 'SET_LEVERAGE_CLEAR',
    SET_MARGIN_TYPE_REQUEST: 'SET_MARGIN_TYPE_REQUEST',
    SET_MARGIN_TYPE_SUCCESS: 'SET_MARGIN_TYPE_SUCCESS',
    SET_MARGIN_TYPE_FAILURE: 'SET_MARGIN_TYPE_FAILURE',
    SET_MARGIN_TYPE_CLEAR: 'SET_MARGIN_TYPE_CLEAR',
    CREATE_ORDER_REQUEST: 'CREATE_ORDER_REQUEST',
    CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
    CREATE_ORDER_FAILURE: 'CREATE_ORDER_FAILURE',
    CREATE_ORDER_CLEAR: 'CREATE_ORDER_CLEAR',
    CREATE_ORDER_WITH_LEVERAGE_REQUEST: 'CREATE_ORDER_WITH_LEVERAGE_REQUEST',
    CREATE_ORDER_WITH_LEVERAGE_SUCCESS: 'CREATE_ORDER_WITH_LEVERAGE_SUCCESS',
    CREATE_ORDER_WITH_LEVERAGE_FAILURE: 'CREATE_ORDER_WITH_LEVERAGE_FAILURE',
    CREATE_ORDER_WITH_LEVERAGE_CLEAR: 'CREATE_ORDER_CLEAR',
}

const checkListActionTypes = {
    CHECKLIST_REQUEST: 'GET_CHECKLIST_REQUEST',
    CHECKLIST_SUCCESS: 'GET_CHECKLIST_SUCCESS',
    CHECKLIST_FAILURE: 'GET_CHECKLIST_FAIURE',
    CHECKLIST_UPDATE: 'GET_CHECKLIST_UPDATE',

    CHECKLIST_SAVE_REQUEST: 'SAVE_CHECKLIST_REQUEST',
    CHECKLIST_SAVE_SUCCESS: 'SAVE_CHECKLIST_SUCCESS',
    CHECKLIST_SAVE_FAILURE: 'SAVE_CHECKLIST_FAIURE',

    CHECKLIST_SET: 'CHECKLIST_SET',
    CHECKLIST_CLEAR: 'CHECKLIST_CLEAR',
    CHECKLIST_MESSAGE: 'CHECKLIST_MESSAGE',
    CHECKLIST_STEPS: 'CHECKLIST_STEPS'
}

const statisticActionTypes = {
    GET_NET_PEAK_GAINS_REQUEST: 'GET_NET_PEAK_GAINS_REQUEST',
    GET_NET_PEAK_GAINS_SUCCESS: 'GET_NET_PEAK_GAINS_SUCCESS',
    GET_NET_PEAK_GAINS_FAILED: 'GET_NET_PEAK_GAINS_FAILED',

    GET_MONTHLY_PEAK_GAINS_REQUEST: 'GET_MONTHLY_PEAK_GAINS_REQUEST',
    GET_MONTHLY_PEAK_GAINS_SUCCESS: 'GET_MONTHLY_PEAK_GAINS_SUCCESS',
    GET_MONTHLY_PEAK_GAINS_FAILED: 'GET_MONTHLY_PEAK_GAINS_FAILED',

    GET_STATISTICS_REQUEST: "GET_STATISTICS_REQUEST",
    GET_STATISTICS_SUCCESS: "GET_STATISTICS_SUCCESS",
    GET_STATISTICS_FAILURE: "GET_STATISTICS_FAILURE",

    STRATEGIES_FILTER_REQUEST: "STRATEGIES_FILTER_REQUEST",
    STRATEGIES_FILTER_SUCCESS: "STRATEGIES_FILTER_SUCCESS",
    STRATEGIES_FILTER_FAILURE: "STRATEGIES_FILTER_FAILURE",

    PERIODRANGE_FILTER_REQUEST: "PERIODRANGE_FILTER_REQUEST",
    PERIODRANGE_FILTER_SUCCESS: "PERIODRANGE_FILTER_SUCCESS",
    PERIODRANGE_FILTER_FAILURE: "PERIODRANGE_FILTER_FAILURE",
    
    GET_TOP_WINNERS_REQUEST: 'GET_TOP_WINNERS_REQUEST',
    GET_TOP_WINNERS_SUCCESS: 'GET_TOP_WINNERS_SUCCESS',
    GET_TOP_WINNERS_FAILED: 'GET_TOP_WINNERS_FAILED'
}

const PAscannerActionTypes = {
    GET_ALL_REQUEST: 'PRICE_ACTION_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'PRICE_ACTION_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'PRICE_ACTION_GET_ALL_FAILURE',
    GET_WATCHLISTS_REQUEST: 'PRICE_ACTION_GET_WATCHLISTS_REQUEST',
    GET_WATCHLISTS_SUCCESS: 'PRICE_ACTION_GET_WATCHLISTS_SUCCESS',
    GET_WATCHLISTS_FAILURE: 'PRICE_ACTION_GET_WATCHLISTS_FAILURE',
    SAVE_WATCHLIST_REQUEST: 'SAVE_WATCHLIST_REQUEST',
    SAVE_WATCHLIST_SUCCESS: 'SAVE_WATCHLIST_SUCCESS',
    SAVE_WATCHLIST_FAILURE: 'SAVE_WATCHLIST_FAILURE',
    GET_TRADEGUIDE_REQUEST: 'GET_TRADEGUIDE_REQUEST',
    GET_TRADEGUIDE_SUCCESS: 'GET_TRADEGUIDE_SUCCESS',
    GET_TRADEGUIDE_FAILURE: 'GET_TRADEGUIDE_FAILURE'
}

const RSIscannerActionTypes = {
    GET_ALL_REQUEST: 'RELATIVE_STRENGTH_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'RELATIVE_STRENGTH_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'RELATIVE_STRENGTH_GET_ALL_FAILURE',
    GET_ALLITEMS_REQUEST: 'RELATIVE_STRENGTH_GET_ALLITEMS_REQUEST',
    GET_ALLITEMS_SUCCESS: 'RELATIVE_STRENGTH_GET_ALLITEMS_SUCCESS',
    GET_ALLITEMS_FAILURE: 'RELATIVE_STRENGTH_GET_ALLITEMS_FAILURE',
    GET_UPDATE_SUCCESS: 'RELATIVE_STRENGTH_UPDATE_SUCCESS'
}

const walletActionTypes = {
    BALANCE_WALLET_REQUEST: 'BALANCE_WALLET_REQUEST',
    BALANCE_WALLET_SUCCESS: 'BALANCE_WALLET_SUCCESS',
    BALANCE_WALLET_FAILURE: 'BALANCE_WALLET_FAILURE',
    BALANCE_WALLET_UPDATE: 'BALANCE_WALLET_UPDATE',

    GET_BINANCE_REQUEST: 'BINANCE_GET_REQUEST',
    GET_BINANCE_SUCCESS: 'BINANCE_GET_SUCCESS',
    GET_BINANCE_FAILURE: 'BINANCE_GET_FAILURE',

    GET_BINANCE_FUTURE_REQUEST: 'BINANCE_GET_REQUEST',
    GET_BINANCE_FUTURE_SUCCESS: 'BINANCE_GET_SUCCESS',
    GET_BINANCE_FUTURE_FAILURE: 'BINANCE_GET_FAILURE',

    GET_ALL_REQUEST: 'WALLET_GET_REQUEST',
    GET_ALL_SUCCESS: 'WALLET_GET_SUCCESS',
    GET_ALL_FAILURE: 'WALLET_GET_FAILURE',
    WALLET_UPDATE: 'UPDATE_WALLET',

    GET_ALL_FUTURE_REQUEST: 'FUTURE_WALLET_GET_REQUEST',
    GET_ALL_FUTURE_SUCCESS: 'FUTURE_WALLET_GET_SUCCESS',
    GET_ALL_FUTURE_FAILURE: 'FUTURE_WALLET_GET_FAILURE',
    FUTURE_WALLET_UPDATE: 'UPDATE_FUTURE_WALLET',

    CREATE_MARKET_REQUEST: 'QUICK_TRADE_CREATE_MARKET_REQUEST',
    CREATE_MARKET_SUCCESS: 'QUICK_TRADE_CREATE_MARKET_SUCCESS',
    CREATE_MARKET_FAILURE: 'QUICK_TRADE_CREATE_MARKET_FAILURE',

    CLEAR_BALANCE_WALLET: 'CLEAR_BALANCE_WALLET',
    CLEAR_WALLET_SIDEBAR: 'CLEAR_WALLET_SIDEBAR'
}

const orderActionTypes = {
    FUTURES_ORDER_HISTORY_REQUEST: 'FUTURES_ORDER_HISTORY_REQUEST',
    FUTURES_ORDER_HISTORY_SUCCESS: 'FUTURES_ORDER_HISTORY_SUCCESS',
    FUTURES_ORDER_HISTORY_FAILURE: 'FUTURES_ORDER_HISTORY_FAILURE',

    ORDER_HISTORY_REQUEST: 'ORDER_HISTORY_REQUEST',
    ORDER_HISTORY_SUCCESS: 'ORDER_HISTORY_SUCCESS',
    ORDER_HISTORY_FAILURE: 'ORDER_HISTORY_FAILURE',

    FUTURES_OPEN_ORDER_REQUEST: 'FUTURES_OPEN_ORDER_REQUEST',
    FUTURES_OPEN_ORDER_SUCCESS: 'FUTURES_OPEN_ORDER_SUCCESS',
    FUTURES_OPEN_ORDER_FAILURE: 'FUTURES_OPEN_ORDER_FAILURE',

    OPEN_ORDER_REQUEST: 'OPEN_ORDER_REQUEST',
    OPEN_ORDER_SUCCESS: 'OPEN_ORDER_SUCCESS',
    OPEN_ORDER_FAILURE: 'OPEN_ORDER_FAILURE',

    ORDER_DETAILS_REQUEST: 'ORDER_DETAILS_REQUEST',
    ORDER_DETAILS_SUCCESS: 'ORDER_DETAILS_SUCCESS',
    ORDER_DETAILS_FAILED: 'ORDER_DETAILS_FAILED',

    ORDER_RESULT_REQUEST: 'ORDER_RESULT_REQUEST',
    ORDER_RESULT_SUCCESS: 'ORDER_RESULT_SUCCESS',
    ORDER_RESULT_FAILED: 'ORDER_RESULT_FAILED',

    CANCEL_REQUEST: 'TRADE_CANCEL_REQUEST',
    CANCEL_SUCCESS: 'TRADE_CANCEL_SUCCESS',
    CANCEL_FAILURE: 'TRADE_CANCEL_FAILURE',

    EXIT_ORDER_REQUEST: 'EXIT_ORDER_REQUEST',
    EXIT_ORDER_SUCCESS: 'EXIT_ORDER_SUCCESS',
    EXIT_ORDER_FAILRUE: 'EXIT_ORDER_FAILRUE',

    FORCE_EXIT_ORDER_REQUEST: 'FORCE_EXIT_ORDER_REQUEST',
    FORCE_EXIT_ORDER_SUCCESS: 'FORCE_EXIT_ORDER_SUCCESS',
    FORCE_EXIT_ORDER_FAILED: 'FORCE_EXIT_ORDER_FAILED',

    FORCE_CANCEL_ORDER_REQUEST: 'FORCE_CANCEL_ORDER_REQUEST',
    FORCE_CANCEL_ORDER_SUCCESS: 'FORCE_CANCEL_ORDER_SUCCESS',
    FORCE_CANCEL_ORDER_FAILED: 'FORCE_CANCEL_ORDER_FAILED',

    ORDER_POSITION_REQUEST: 'ORDER_POSITION_REQUEST',
    ORDER_POSITION_SUCCESS: 'ORDER_POSITION_SUCCESS',
    ORDER_POSITION_FAILRUE: 'ORDER_POSITION_FAILRUE',

    CLOSE_ORDER_POSITION_REQUEST: 'CLOSE_ORDER_POSITION_REQUEST',
    CLOSE_ORDER_POSITION_SUCCESS: 'CLOSE_ORDER_POSITION_SUCCESS',
    CLOSE_ORDER_POSITION_FAILRUE: 'CLOSE_ORDER_POSITION_FAILRUE',
}

const formActionTypes = {
    EDIT_PROFILE_REQUEST: 'EDIT_PROFILE_REQUEST',
    EDIT_PROFILE_SUCCESS: 'EDIT_PROFILE_SUCCESS',
    EDIT_PROFILE_FAILURE: 'EDIT_PROFILE_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    CHANGE_EMAIL_REQUEST: 'CHANGE_EMAIL_REQUEST',
    CHANGE_EMAIL_SUCCESS: 'CHANGE_EMAIL_SUCCESS',
    CHANGE_EMAIL_FAILURE: 'CHANGE_EMAIL_FAILURE',
}

const subscriptionActionTypes = {
    GET_DETAILS_REQUEST: 'GET_DETAILS_REQUEST',
    GET_DETAILS_SUCCESS: 'GET_DETAILS_SUCCESS',
    GET_DETAILS_FAILED: 'GET_DETAILS_FAILED',

    CANCEL_SUB_REQUEST: 'CANCEL_SUB_REQUEST',
    CANCEL_SUB_SUCCESS: 'CANCEL_SUB_SUCCESS',
    CANCEL_SUB_FAILURE: 'CANCEL_SUB_FAILURE',
}

const planActionTypes = {
    GET_PLAN_REQUEST: 'PLAN_GET_REQUEST',
    GET_PLAN_SUCCESS: 'PLAN_GET_SUCCESS',
    GET_PLAN_FAILURE: 'PLAN_GET_FAILURE',

    GET_TRIAL_PLAN_REQUEST: 'GET_TRIAL_PLAN_REQUEST',
    GET_TRIAL_PLAN_SUCCESS: 'GET_TRIAL_PLAN_SUCCESS',
    GET_TRIAL_PLAN_FAILED: 'GET_TRIAL_PLAN_FAILED',

    GET_SIX_MONTH_PLAN_REQUEST: 'GET_SIX_MONTH_PLAN_REQUEST',
    GET_SIX_MONTH_PLAN_SUCCESS: 'GET_SIX_MONTH_PLAN_SUCCESS',
    GET_SIX_MONTH_PLAN_FAILED: 'GET_SIX_MONTH_PLAN_FAILED'
}

const visitorActionTypes = {
    GET_VISITOR_ID_REQUEST: 'GET_VISITOR_ID_REQUEST',
    GET_VISITOR_ID_SUCCESS: 'GET_VISITOR_ID_SUCCESS',
    GET_VISITOR_ID_FAILURE: 'GET_VISITOR_ID_FAILURE'
}

const confirmEmailTypes = {
    CONFIRM_EMAIL_REQUEST: 'CONFIRM_EMAIL_REQUEST',
    CONFIRM_EMAIL_SUCCESS: 'CONFIRM_EMAIL_SUCCESS',
    CONFIRM_EMAIL_FAILURE: 'CONFIRM_EMAIL_FAILURE',
}

const announcementActionTypes = {
    GET_ACTIVE_ANNOUNCEMENT_REQUEST: 'GET_ACTIVE_ANNOUNCEMENT_REQUEST',
    GET_ACTIVE_ANNOUNCEMENT_SUCCESS: 'GET_ACTIVE_ANNOUNCEMENT_SUCCESS',
    GET_ACTIVE_ANNOUNCEMENT_FAILURE: 'GET_ACTIVE_ANNOUNCEMENT_FAILURE'
}

const surveyActionTypes = {
    GET_SURVEY: 'GET_SURVEY',
    UPDATE_SURVEY: 'UPDATE_SURVEY',
    UPDATE_ANSWER: 'UPDATE_ANSWER',
    UPDATE_STEP: 'UPDATE_STEP',
    RESET_SURVEY: 'RESET_SURVEY',
    SAVE_SURVEY_REQUEST: 'SAVE_SURVEY_REQUEST',
    SAVE_SURVEY_SUCCESS: 'SAVE_SURVEY_SUCCESS',
    SAVE_SURVEY_FAILURE: 'SAVE_SURVEY_FAILURE'
}

const messageTypeActionTypes = {
    GET_MSG_TYPES_REQUEST: 'GET_MSG_TYPES_REQUEST',
    GET_MSG_TYPES_SUCCESS: 'GET_MSG_TYPES_SUCCESS',
    GET_MSG_TYPES_FAILED: 'GET_MSG_TYPES_FAILED',

    GET_AUDIENCE_TYPES_REQUEST: 'GET_AUDIENCE_TYPES_REQUEST',
    GET_AUDIENCE_TYPES_SUCCESS: 'GET_AUDIENCE_TYPES_SUCCESS',
    GET_AUDIENCE_TYPES_FAILED: 'GET_AUDIENCE_TYPES_FAILED'
}

export {
    reminderActionTypes,
    tradeDoctorSessionActionTypes,
    messageTypeActionTypes,
    messagesActionTypes,
    offersActionTypes,
    signalActionTypes,
    signalDetailsActionTypes,
    followedSignalActionTypes,
    signalStatesActionTypes,
    authActionTypes,
    onboardingActionTypes,
    themeActionTypes,
    userActionTypes,
    leadActionTypes,
    deviceActionTypes,
    notificationActionTypes,
    sidebarActionTypes,
    tradingActionTypes,
    tradeOrderActionTypes,
    checkListActionTypes,
    statisticActionTypes,
    PAscannerActionTypes,
    RSIscannerActionTypes,
    walletActionTypes,
    orderActionTypes,
    formActionTypes,
    subscriptionActionTypes,
    planActionTypes,
    visitorActionTypes,
    confirmEmailTypes,
    announcementActionTypes,
    forgotPassActionTypes,
    surveyActionTypes
}
