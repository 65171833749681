import glamorous from 'glamorous'

export const UserAvatar = glamorous.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '25px',

    '& .img-wrapper': {
        display: 'block',
        width: '70px',
        height: '70px',
        borderRadius: '50%',
        overflow: 'hidden',

        '& img': {
            width: '100%'
        }
    }
})