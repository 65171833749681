import styled from 'styled-components';

export const NotificationBadge = styled.span`
	width: 20px;
	height: 20px;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
    overflow: hidden;
    background: #bd577e;
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 10px;
    pointer-events: none;
    color: #fff;
    font-weight: 600;
    border: 1px solid #963d5f;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.5);
`;

export const NotificationBellWrapper = styled.div`
    position: relative;
`;
