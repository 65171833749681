import React from 'react'
import { Modal } from 'react-bootstrap'
import './styles/early-closed-trade-modal.scss'
import { OpenOrderDetails } from './OpenOrderDetails'
import { isNotNull } from '../../../../utils'

export const ClosedTradeNotificationModal = (props) => {
    const { showClosedEarlyModal, closedEarlyNotifications } = props.state

    const renderModalBody = () => {
        const { onMarkAsRead } = props.eventHandlers
        if (isNotNull(closedEarlyNotifications) && closedEarlyNotifications.length > 0) {
            return (
                <>
                    { closedEarlyNotifications.length === 1 && 
                        <h2 className="title">Warning: we are closing this trade early! - TAKE ACTION - {closedEarlyNotifications[0].symbol }</h2>
                    }

                    { closedEarlyNotifications.length > 1 && 
                        <h2 className="title">Warning: we are closing these trades early! - TAKE ACTION</h2>
                    }

                    <p>Hey there ProfitFarmer,</p>

                    { closedEarlyNotifications.length === 1 && 
                        <p>We don't like the look of this one and will be closing the trade early:</p>
                    }

                    { closedEarlyNotifications.length > 1 && 
                        <p>We don't like the look of these ones and will be closing the trades early:</p>
                    }

                    <OpenOrderDetails 
                        data={closedEarlyNotifications}
                        onMarkAsRead={onMarkAsRead} />
                </>
            )   
        }
    }

    return (
        <Modal
            id="trade-closed-early-modal"
            className="trade-closed-early-modal"
            show={showClosedEarlyModal}
            aria-labelledby="tradeClosedEarlyModal" 
            aria-hidden="true" 
            backdrop={'static'}
            keyboard={false}
            centered>
            <Modal.Body>
                {renderModalBody()}
            </Modal.Body>
        </Modal>
    )
}