import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { onboardingActions } from '../../redux/actions'
import { routes } from '../../navigation/CONSTANTS'
import { PrimaryButton } from '../../styles'
import { ProgressBar } from '../ProgressBar'
import { 
    IntroWrapper, 
    BasicInfo, 
    TradingExperience, 
    FinancialDetails,
    BinanceApiSettings,
    GenerateExchangeKeys,
    ExchangeSecurityVerification,
    ExchangeKeysForm,
    ApiRestrictionSettings,
    AdjustTradingBalance,
    CompleteOnboarding } from './'
import { mediaDeviceSizes } from '../../constants/mediaQueries'
import { onboardingScreen } from '../../constants/onboardingScreen'
import { onboardingState } from '../../constants/onboardingState'
import { getSize, isNotNull, isNull, onSetNewState } from '../../utils'
import { LogIntoBinance } from './LogIntoBinance'

class Onboarding extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            hasStartedTheProcess: false,
            onboardingStates: [{
                key: 1,
                title: 'Your Profile'
            }, {
                key: 2,
                title: 'Trading Experience'
            }, {
                key: 3,
                title: 'Financial Details'
            }, {
                key: 4,
                title: 'Connect PF to Exchange'
            }, {
                key: 5,
                title: 'Adjust Trading Balance'
            }, {
                key: 6,
                title: 'Complete'
            }],
            currentState: null,
            currentStep: onboardingScreen.BASIC_INFO,
            isUserProfileInitialized: false,
            userProfile: {
                firstName: null,
                lastName: null,
                dateOfBirth: null,
                gender: null,
                countryOfResidence: null,
                howDidYouHearAboutUs: null
            }, 
            isTradingExperienceInitialized: false,
            tradingExperience: {
                expLevel: null,
                hasFuturesTradingExp: 'NO',
                tradingPurpose: null,
                capital: null,
                riskLevel: null,
                otherAssets: []
            },
            isFinancialDetailsInitialized: false,
            financialDetails: {
                sourceOfIncome: null,
                occupation: null,
                annualNetIncome: null
            },
            isSkippingApiConnection: false,
            isExchangeKeysInitialized: false,
            exchangeKeys: {
                apiKey: '',
                secretKey: ''
            },
            isSkippingBalanceAdjustment: false
        }

        this.baseState = this.state
        this.mounted = false
        this.deviceScreenSize = getSize(window.innerWidth)
    }

    componentDidMount() {
        this.mounted = true
        const { dispatch } = this.props
        dispatch(onboardingActions.getOnboardingState())
    }

    componentDidUpdate() {
        if (this.mounted) {
            const { 
                dispatch, 
                onboardingUser, 
                onboardingUserProfile,
                onboardingUserTradingExpDetails,
                onboardingUserFinancialDetails } = this.props

            const { 
                hasStartedTheProcess, 
                currentState, 
                isUserProfileInitialized,
                isTradingExperienceInitialized,
                isFinancialDetailsInitialized } = this.state

            let { userProfile, tradingExperience, financialDetails } = this.state

            if (isNotNull(onboardingUser) && !onboardingUser.loading) {
                const { status } = onboardingUser
                if (isNotNull(status) && isNull(currentState)) {
                    const { state } = status
                    if (parseInt(state) === onboardingState.COMPLETE) {
                        window.location.href = routes.dashboard
                    } else {
                        dispatch(onboardingActions.getBasicInfo())

                        if (parseInt(state) > 1)
                            dispatch(onboardingActions.getTradingExpDetails())

                        if (parseInt(state) > 2)
                            dispatch(onboardingActions.getFinancialDetails())

                        if (parseInt(state) > 5)
                            dispatch(onboardingActions.getTradingBalances())

                        switch (state) {
                            default: {
                                onSetNewState(this, {
                                    currentState: 1,
                                    currentStep: onboardingScreen.BASIC_INFO
                                })
                                break
                            }
                            case onboardingState.TRADING_EXP: {
                                onSetNewState(this, {
                                    currentState: 2,
                                    currentStep: onboardingScreen.TRADING_EXP
                                })
                                break
                            }
                            case onboardingState.FINANCIAL_DETAILS: {
                                // dispatch

                                onSetNewState(this, {
                                    currentState: 3,
                                    currentStep: onboardingScreen.FINANCIAL_DETAILS
                                })
                                break
                            }
                            case onboardingState.CONNECT_TO_BINANCE: {
                                onSetNewState(this, {
                                    currentState: 4,
                                    currentStep: onboardingScreen.LOGIN_BINANCE_ACCOUNT
                                })
                                break
                            }
                            case onboardingState.ENTER_EXCHANGE_KEYS: {
                                onSetNewState(this, {
                                    currentState: 4,
                                    currentStep: onboardingScreen.ENTER_EXCHANGE_KEYS
                                })
                                break
                            } 
                            case onboardingState.API_RESTRICTION_SETTINGS: {
                                onSetNewState(this, {
                                    currentState: 4,
                                    currentStep: onboardingScreen.API_RESTRICTION_SETTINGS
                                })
                                break
                            }
                            case onboardingState.ADJUST_TRADING_BALANCE: {
                                onSetNewState(this, {
                                    currentState: 5,
                                    currentStep: onboardingScreen.ADJUST_TRADING_BALANCE
                                })
                                break
                            }
                        }

                        if (!hasStartedTheProcess && state > 0) {
                            onSetNewState(this, {
                                hasStartedTheProcess: true
                            })
                        }
                    }
                }
            }
            
            if (isNotNull(onboardingUserProfile) && !isUserProfileInitialized) {
                const { loading, profile } = onboardingUserProfile
                if (!loading && isNotNull(profile)) {                            
                    if (isNotNull(profile.firstName) && userProfile.firstName !== profile.firstName)
                        userProfile.firstName = profile.firstName

                    if (isNotNull(profile.lastName) && userProfile.lastName !== profile.lastName)
                        userProfile.lastName = profile.lastName
                        
                    if (isNotNull(profile.gender) && userProfile.gender !== profile.gender)
                        userProfile.gender = profile.gender

                    if (isNotNull(profile.dateOfBirth) && userProfile.dateOfBirth !== profile.dateOfBirth)
                        userProfile.dateOfBirth = profile.dateOfBirth

                    if (isNotNull(profile.countryOfResidence) && userProfile.countryOfResidence !== profile.countryOfResidence)
                        userProfile.countryOfResidence = profile.countryOfResidence

                    if (isNotNull(profile.howDidYouHearAboutUs) && userProfile.howDidYouHearAboutUs !== profile.howDidYouHearAboutUs)
                        userProfile.howDidYouHearAboutUs = profile.howDidYouHearAboutUs

                    onSetNewState(this, {
                        userProfile,
                        isUserProfileInitialized: true
                    })                        
                }
            }

            if (isNotNull(onboardingUserTradingExpDetails) && !isTradingExperienceInitialized) {
                const { loading, trading_exp } = onboardingUserTradingExpDetails
                if (!loading && isNotNull(trading_exp)) {
                    if (isNotNull(trading_exp.expLevel) && tradingExperience.expLevel !== trading_exp.expLevel)
                        tradingExperience.expLevel = trading_exp.expLevel

                    if (isNotNull(trading_exp.hasFuturesTradingExp) && tradingExperience.hasFuturesTradingExp !== trading_exp.hasFuturesTradingExp)
                        tradingExperience.hasFuturesTradingExp = trading_exp.hasFuturesTradingExp

                    if (isNotNull(trading_exp.tradingPurpose) && tradingExperience.tradingPurpose !== trading_exp.tradingPurpose)
                        tradingExperience.tradingPurpose = trading_exp.tradingPurpose

                    if (isNotNull(trading_exp.capital) && tradingExperience.capital !== trading_exp.capital)
                        tradingExperience.capital = trading_exp.capital

                    if (isNotNull(trading_exp.riskLevel) && tradingExperience.riskLevel !== trading_exp.riskLevel)
                        tradingExperience.riskLevel = trading_exp.riskLevel

                    if (isNotNull(trading_exp.otherAssets) && tradingExperience.otherAssets !== trading_exp.otherAssets)
                        tradingExperience.otherAssets = trading_exp.otherAssets

                    onSetNewState(this, {
                        tradingExperience,
                        isTradingExperienceInitialized: true
                    })
                }
            }

            if (isNotNull(onboardingUserFinancialDetails) && !isFinancialDetailsInitialized) {
                const { loading, financial_details } = onboardingUserFinancialDetails
                if (!loading && isNotNull(financial_details)) {
                    if (isNotNull(financial_details.sourceOfIncome) && financialDetails.sourceOfIncome !== financial_details.sourceOfIncome)
                        financialDetails.sourceOfIncome = financial_details.sourceOfIncome

                    if (isNotNull(financial_details.occupation) && financialDetails.occupation !== financial_details.occupation)
                        financialDetails.occupation = financial_details.occupation

                    if (isNotNull(financial_details.annualNetIncome) && financialDetails.annualNetIncome !== financial_details.annualNetIncome)
                        financialDetails.annualNetIncome = financial_details.annualNetIncome

                    onSetNewState(this, {
                        financialDetails,
                        isFinancialDetailsInitialized: true
                    })
                }
            }
        }
    }

    handleClickGetStarted = (e) => {
        e.preventDefault()
        
        onSetNewState(this, {
            hasStartedTheProcess: true
        })
    }

    handleScreenNavigation = (data, screenName) => {
        if (isNotNull(screenName)) {
            switch(screenName) {
                default:
                case onboardingScreen.BASIC_INFO: {
                    onSetNewState(this, {
                        currentState: 1,
                        currentStep: screenName
                    })
                    break
                }
                case onboardingScreen.TRADING_EXP: {
                    let { userProfile } = this.state
                    if (isNotNull(data)) {
                        const { 
                            firstName,
                            lastName,
                            dateOfBirth,
                            gender,
                            countryCode,
                            howDidYouHearAboutUs
                        } = data

                        userProfile.firstName = firstName
                        userProfile.lastName = lastName
                        userProfile.dateOfBirth = dateOfBirth
                        userProfile.gender = gender
                        userProfile.countryOfResidence = countryCode
                        userProfile.howDidYouHearAboutUs = howDidYouHearAboutUs
                    }
                        
                    onSetNewState(this, {
                        currentState: 2,
                        currentStep: screenName,
                        userProfile
                    })
                    break
                }
                case onboardingScreen.FINANCIAL_DETAILS: {
                    let newState = {
                        currentState: 3,
                        currentStep: screenName
                    }
                    
                    if (isNotNull(data))
                        newState.tradingExperience = data

                    onSetNewState(this, newState)
                    break
                }
                case onboardingScreen.LOGIN_BINANCE_ACCOUNT: {
                    let newState = {
                        currentState: 4,
                        currentStep: screenName
                    }
                    
                    if (isNotNull(data))
                        newState.financialDetails = data
                        
                    onSetNewState(this, newState)
                    break
                }
                case onboardingScreen.BINANCE_API_SETTINGS:
                case onboardingScreen.GENERATE_EXCHANGE_API:
                case onboardingScreen.SECURITY_VERIFICATION: 
                case onboardingScreen.ENTER_EXCHANGE_KEYS: {
                    onSetNewState(this, {
                        currentState: 4,
                        currentStep: screenName
                    })
                    break
                }
                case onboardingScreen.API_RESTRICTION_SETTINGS: {
                    let newState = {
                        currentState: 4,
                        currentStep: screenName
                    }
                    
                    if (isNotNull(data))
                        newState.exchangeKeys = data

                    onSetNewState(this, newState)
                    break
                }
                case onboardingScreen.ADJUST_TRADING_BALANCE: {
                    onSetNewState(this, {
                        currentState: 5,
                        currentStep: screenName
                    })
                    break
                }
                case onboardingScreen.COMPLETE: {
                    onSetNewState(this, {
                        currentState: 6,
                        currentStep: screenName
                    })
                    break
                }
            }
        }
    }

    handlePreviousStep = (screenName) => {
        if (isNotNull(screenName)) {
            switch(screenName) {
                default:
                case onboardingScreen.BASIC_INFO: {
                    onSetNewState(this, {
                        currentState: 1,
                        currentStep: screenName
                    })
                    break
                }
                case onboardingScreen.TRADING_EXP: {
                    onSetNewState(this, {
                        currentState: 2,
                        currentStep: screenName
                    })
                    break
                }
                case onboardingScreen.FINANCIAL_DETAILS: {
                    onSetNewState(this, {
                        currentState: 3,
                        currentStep: screenName
                    })
                    break
                }
                case onboardingScreen.LOGIN_BINANCE_ACCOUNT:
                case onboardingScreen.BINANCE_API_SETTINGS: {
                    onSetNewState(this, {
                        currentState: 4,
                        currentStep: screenName
                    })
                    break
                }
            }
        }
    }

    handleClickSkip = (e) => {
        e.preventDefault()

        const { dispatch } = this.props
        dispatch(onboardingActions.skipOnboarding())
    }

    handleSkipApiConnection = (e) => {
        e.preventDefault()
        
        onSetNewState(this, {
            isSkippingApiConnection: true
        })
    }

    hideApiConnectionSkipWarning = () => onSetNewState(this, {
        isSkippingApiConnection: false
    })

    handleSkipAdjustBalance = (e) => {
        e.preventDefault()

        onSetNewState(this, {
            isSkippingBalanceAdjustment: true
        })
    }

    hideAdjustBalanceSkipWarning = () => onSetNewState(this, {
        isSkippingBalanceAdjustment: false
    })

    renderWelcomingContent = () => (
        <IntroWrapper id="intro">
            <h1 className='title'>Complete your account setup</h1>

            <div className='iframe-wrapper fade-in'>
                <iframe
                    height="100%"
                    width="100%"
                    title="Get Started Checklist"
                    src="https://www.youtube.com/embed/tR2n34MxHos"
                    frameBorder="0"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen />
            </div>
                
            <p>
                Welcome to ProfitFarmers, it's great to have you here! 
                You need to finish account setup before you can use our awesome trading signals and tools.
            </p>

            <PrimaryButton 
                type="button" 
                size="sm" 
                animation="tada" 
                animationDelay="4s" 
                animationRepeat="1" 
                onClick={(e) => this.handleClickGetStarted(e)}>
                <span>Let's go!</span>
            </PrimaryButton>
        </IntroWrapper>
    )

    renderForm = () => {
        const { 
            currentStep, 
            userProfile, 
            tradingExperience,
            financialDetails,
            exchangeKeys } = this.state
        if (isNotNull(currentStep)) {
            switch(currentStep) {
                default:
                case onboardingScreen.BASIC_INFO: return (<BasicInfo data={userProfile} eventHandlers={{
                    navigateTo: this.handleScreenNavigation.bind(this)
                }} />)
                case onboardingScreen.TRADING_EXP: return (<TradingExperience data={tradingExperience} eventHandlers={{
                    navigateTo: this.handleScreenNavigation.bind(this)
                }} />)
                case onboardingScreen.FINANCIAL_DETAILS: return (<FinancialDetails data={financialDetails} eventHandlers={{
                    navigateTo: this.handleScreenNavigation.bind(this)
                }} />)
                case onboardingScreen.LOGIN_BINANCE_ACCOUNT: return (<LogIntoBinance eventHandlers={{
                    navigateTo: this.handleScreenNavigation.bind(this),
                    handleSkip: this.handleSkipApiConnection.bind(this)
                }} />)
                case onboardingScreen.BINANCE_API_SETTINGS: return (<BinanceApiSettings eventHandlers={{
                    navigateTo: this.handleScreenNavigation.bind(this),
                    handleSkip: this.handleSkipApiConnection.bind(this)
                }} />)
                case onboardingScreen.GENERATE_EXCHANGE_API: return (<GenerateExchangeKeys eventHandlers={{
                    navigateTo: this.handleScreenNavigation.bind(this),
                    handleSkip: this.handleSkipApiConnection.bind(this)
                }} />)
                case onboardingScreen.SECURITY_VERIFICATION: return (<ExchangeSecurityVerification eventHandlers={{
                    navigateTo: this.handleScreenNavigation.bind(this),
                    handleSkip: this.handleSkipApiConnection.bind(this)
                }} />)
                case onboardingScreen.ENTER_EXCHANGE_KEYS: return (<ExchangeKeysForm data={exchangeKeys} eventHandlers={{
                    navigateTo: this.handleScreenNavigation.bind(this),
                    handleSkip: this.handleSkipApiConnection.bind(this)
                }} />)
                case onboardingScreen.API_RESTRICTION_SETTINGS: return (<ApiRestrictionSettings eventHandlers={{
                    navigateTo: this.handleScreenNavigation.bind(this),
                    handleSkip: this.handleSkipApiConnection.bind(this)
                }} />)
                case onboardingScreen.ADJUST_TRADING_BALANCE: return (<AdjustTradingBalance eventHandlers={{
                    navigateTo: this.handleScreenNavigation.bind(this),
                    handleSkip: this.handleSkipAdjustBalance.bind(this)
                }} />)
                case onboardingScreen.COMPLETE: return (<CompleteOnboarding eventHandlers={{
                    navigateTo: this.handleScreenNavigation.bind(this)
                }} />)
            }
        }
    }

    render() {
        const { 
            hasStartedTheProcess, 
            onboardingStates, 
            currentState, 
            isSkippingApiConnection, 
            isSkippingBalanceAdjustment } = this.state

        return (
            <>
                { isNotNull(currentState) && 
                    currentState <= 1 && 
                    !hasStartedTheProcess && this.renderWelcomingContent()}

                { hasStartedTheProcess  && 
                    <ProgressBar 
                        data={onboardingStates}
                        currentState={currentState} />
                }
                
                { hasStartedTheProcess && this.renderForm() }                

                <Modal
                    id="skip-api-conn"
                    show={isSkippingApiConnection}
                    backdrop={true} 
                    keyboard={false} 
                    centered>
                    <Modal.Body>
                        <h3 className='alert text-center'>Warning!</h3>
                        <p>If you do not connect a Binance.com account with your ProfitFarmers account the following functions will not be available:</p>
                        <ol>
                            <li>Copy-Trading</li>
                            <li>Advanced Trading Terminal</li>
                            <li>Wallet Balances</li>
                            <li>Open orders and Order history</li>
                        </ol>

                        <p>We <b>highly recommend</b> that you complete your API connection in order to make the most of your ProfitFarmers account.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type="button" 
                            className='custom-btn secondary-btn'
                            onClick={(e) => this.handleClickSkip(e)}>Skip for now</button>

                        <button 
                            type="button" 
                            className='custom-btn primary-btn'
                            onClick={(e) => this.hideApiConnectionSkipWarning(e)}>Complete API connection</button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    id="skip-adj-bal"
                    show={isSkippingBalanceAdjustment}
                    backdrop={true} 
                    keyboard={false} 
                    centered>
                    <Modal.Body>
                        <h3 className='alert text-center'>Warning!</h3>
                        <p>In order to copy-trade signals you will need either a USDT balance or a BTC balance depending on the coin pair. 
                            We recommend starting your account with a balance in both so that you are ready to trade any signals that appear.</p>
                        <p>You may adjust your USDT and/or BTC balances at any time.</p>
                        <p>You can fund your Binance account by purchasing BTC or USDT directly from their homepage or by sending funds from other sources to your deposit wallet addresses within Binance.</p>
                        <p>Futures trading will require you to fund your Binance Futures wallet.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type="button" 
                            className='custom-btn secondary-btn'
                            onClick={(e) => this.handleClickSkip(e)}>Skip for now</button>

                        <button 
                            type="button" 
                            className='custom-btn primary-btn'
                            onClick={(e) => this.hideAdjustBalanceSkipWarning(e)}>Back to Balances</button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    let { 
        onboardingUser, 
        onboardingUserProfile,
        onboardingUserTradingExpDetails,
        onboardingUserFinancialDetails } = state
    return {
        onboardingUser,
        onboardingUserProfile,
        onboardingUserTradingExpDetails,
        onboardingUserFinancialDetails
    }
}

const connectedOnboarding = connect(mapStateToProps)(Onboarding)
export { connectedOnboarding as Onboarding }