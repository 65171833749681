import React from 'react'
import { connect } from 'react-redux'
import { Form, InputGroup } from 'react-bootstrap'
import jQuery from 'jquery'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { Form as FinalForm, Field } from 'react-final-form'
import newFormDecorator from 'final-form-focus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faArrowDown, faSync, faArrowRight, faArrowLeft, faShare } from '@fortawesome/free-solid-svg-icons'
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import Slider from '@material-ui/core/Slider'
import _ from 'lodash'
import { TextButton, PrimaryButton } from '../../styles'
import { FormBodyContent, FormInfoText, FormActions, GifLoader } from './'
import { getSize, isNull, isNotNull, onSetNewState } from '../../utils'
import { onboardingScreen } from '../../constants/onboardingScreen'
import { mediaDeviceSizes } from '../../constants/mediaQueries'
import { routes } from '../../navigation/CONSTANTS'
import { onboardingActions } from '../../redux/actions'

class AdjustTradingBalance extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isConnecting: true,
            isConnected: false,
            isLoadingBalances: true,
            isSocketConnected: false,
            hasSpotBalance: false,
            hasFuturesBalance: false,
            cryptoCurrency: {
                btc: 'BTC',
                usdt: 'USDT'
            },
            coin: 'BTC/USDT',
            sourceCoin: 'USDT',
            targetCoin: 'BTC',
            orderType: 'MARKET_SELL',
            exchange: 'binance',            
            usdt: null,
            btc: null,
            futuresUsdt: null,
            hasSetDefaultValues: false,
            tradeAmount: 0.00,
            tradeAmountPercentage: 0,
            estimatedValue: 0.00,
            hasCustomError: false,
            customErrorMsg: ''
        }
        this.baseState = this.state
        this.mounted = false

        this.formDecorator = newFormDecorator()
        this.muiTheme = createTheme({
            overrides: {
                MuiSlider: {
                    thumb: {
                        color: "#15bd6f",
                        marginTop: -4
                    },
                    track: {
                        height: 5,
                        color: '#15bd6f'
                    },
                    rail: {
                        height: 5,
                        color: 'black'
                    },
                    mark: {
                        height: 8,
                        width: 8,
                        color: 'black',
                        borderRadius: '100%',
                        marginTop: -2,
                        marginLeft: -3
                    }
                }
            }
        })
        this.marks = [
            {
                value: 25,
            },
            {
                value: 50,
            },
            {
                value: 75,
            }
        ]

        this.btcUsdtPrice = null
        this.btcUsdtBidPrice = null
        this.tickerSocket = null

        this.screenSize = getSize(window.innerWidth)
    }

    componentDidMount() {
        this.mounted = true
        const { dispatch } = this.props
        dispatch(onboardingActions.getTradingBalances())

        this.startTickerConnection()

        const { 
            cryptoCurrency,
            usdt, 
            btc, 
            sourceCoin, 
            tradeAmount, 
            tradeAmountPercentage } = this.state

        // initialize preset data
        if (parseFloat(tradeAmount) === 0 && parseFloat(tradeAmountPercentage) === 0) {
            let defaultTradeAmount = 0
            let defaultTradeAmountPercentage = 0

            if (sourceCoin === cryptoCurrency.btc && btc > 0) {
                defaultTradeAmount = btc / 2
                defaultTradeAmountPercentage = Math.round((defaultTradeAmount * 100) / parseFloat(btc), 0)
            } 
            
            if (sourceCoin === cryptoCurrency.usdt && usdt > 0) {
                defaultTradeAmount = usdt / 2
                defaultTradeAmountPercentage = Math.round((defaultTradeAmount * 100) / parseFloat(usdt), 0)
            }
            
            onSetNewState(this, {
                tradeAmount: parseFloat(defaultTradeAmount),
                tradeAmountPercentage: defaultTradeAmountPercentage
            })
        }

        jQuery('#trade-amount').select()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.mounted) {
            const { 
                isConnecting, 
                isLoadingBalances, 
                isSocketConnected, 
                hasSetDefaultValues,
                cryptoCurrency,
                usdt, 
                btc, 
                sourceCoin, 
                estimatedValue, 
                tradeAmount, 
                tradeAmountPercentage } = this.state
            const { 
                userWalletBalances, 
                setTradingBalance, 
                eventHandlers,
                skipOnboarding } = this.props

            if (isNotNull(userWalletBalances) && 
                isNotNull(userWalletBalances.loading) && 
                !userWalletBalances.loading) {
                    const { wallet_balances } = userWalletBalances
                    if (isNotNull(wallet_balances)) {
                        const { success, walletBalances } = wallet_balances
                        if (isConnecting) {
                            if (isNotNull(success)) {
                                onSetNewState(this, {
                                    isConnecting: false,
                                    isConnected: success
                                })
                            }
                        }

                        if (isNotNull(walletBalances) && isLoadingBalances) {
                            const { spotWallet, futuresWallet } = walletBalances
                            if (isNotNull(spotWallet)) {
                                const { data } = spotWallet
                                if (spotWallet.success && isNotNull(data)) {
                                    let sourceCoin = ''
                                    let orderType = ''
                                    let btcBalance = 0
                                    let btcWallet = _.find(data, i => i.name === 'BTC')
                                    if (isNotNull(btcWallet) && isNotNull(btcWallet.balance)) {
                                        btcBalance = _.round(parseFloat(btcWallet.balance.free), 8)
                                    }
                                    
                                    let usdtBalance = 0
                                    let usdtWallet = _.find(data, i => i.name === 'USDT')
                                    if (isNotNull(usdtWallet) && isNotNull(usdtWallet.balance) && parseFloat(usdtWallet.balance.free) > 0) {
                                        if (parseFloat(usdtWallet.balance.free) > 1) {
                                            usdtBalance = _.round(parseFloat(usdtWallet.balance.free), 2)                                            
                                            usdtBalance = parseFloat(usdtBalance).toFixed(2)
                                        } else
                                            usdtBalance = parseFloat(usdtWallet.balance.free).toFixed(8)
                                    }

                                    if (parseFloat(btcBalance) > 0 && parseFloat(usdtBalance) > 0) {
                                        sourceCoin = cryptoCurrency.btc
                                        orderType = 'MARKET_SELL'
                                    } else {
                                        if (parseFloat(btcBalance) > 0 && parseFloat(usdtBalance) === 0) {
                                            sourceCoin = cryptoCurrency.btc
                                            orderType = 'MARKET_SELL'
                                        } else {
                                            if (parseFloat(btcBalance) === 0 && parseFloat(usdtBalance) > 0) {
                                                sourceCoin = cryptoCurrency.usdt
                                                orderType = 'MARKET_BUY'
                                            }
                                        }
                                    }
    
                                    onSetNewState(this, {
                                        hasSpotBalance: true,
                                        btc: parseFloat(btcBalance),
                                        usdt: parseFloat(usdtBalance),
                                        sourceCoin,
                                        orderType
                                    })
                                }
                            }
    
                            if (isNotNull(futuresWallet)) {
                                const { data } = futuresWallet
                                if (futuresWallet.success && isNotNull(data)) {                                    
                                    let usdtBalance = 0
                                    let usdtWallet = _.find(data, i => i.name === 'USDT')
                                    if (isNotNull(usdtWallet)) {
                                        if (parseFloat(usdtWallet.balance.free) > 1) {
                                            usdtBalance = _.round(parseFloat(usdtWallet.balance.free), 2)                                            
                                            usdtBalance = parseFloat(usdtBalance).toFixed(2)
                                        }
                                        else
                                            usdtBalance = parseFloat(usdtWallet.balance.free).toFixed(8)
                                    }
    
                                    onSetNewState(this, {
                                        hasFuturesBalance: true,
                                        futuresUsdt: parseFloat(usdtBalance)
                                    })
                                }
                            }

                            onSetNewState(this, {
                                isLoadingBalances: false
                            })
                        }
                    }
            }
                
            if (isSocketConnected && 
                isNotNull(this.btcUsdtPrice) && 
                isNotNull(usdt) && 
                isNotNull(btc) && 
                !isLoadingBalances) {
                if (parseFloat(usdt) > 0 && parseFloat(btc) > 0) {
                    if (isNotNull(eventHandlers)) {
                        eventHandlers.navigateTo(null, onboardingScreen.COMPLETE)
                    }
                } else {
                    // initialize preset data
                    if (!hasSetDefaultValues) {
                        if (parseFloat(tradeAmount) === 0 && parseFloat(tradeAmountPercentage) === 0) {
                            let defaultTradeAmount = 0
                            let defaultTradeAmountPercentage = 0
        
                            if (sourceCoin === cryptoCurrency.btc && btc > 0) {
                                defaultTradeAmount = btc / 2
                                defaultTradeAmountPercentage = Math.round((defaultTradeAmount * 100) / parseFloat(btc), 0)
                            
                                onSetNewState(this, {
                                    hasSetDefaultValues: true,
                                    tradeAmount: defaultTradeAmount,
                                    tradeAmountPercentage: defaultTradeAmountPercentage
                                })
                            } 
                            
                            if (sourceCoin === cryptoCurrency.usdt && usdt > 0) {
                                defaultTradeAmount = usdt / 2
                                defaultTradeAmountPercentage = Math.round((defaultTradeAmount * 100) / parseFloat(usdt), 0)
                            
                                onSetNewState(this, {
                                    hasSetDefaultValues: true,
                                    tradeAmount: defaultTradeAmount,
                                    tradeAmountPercentage: defaultTradeAmountPercentage
                                })
                            }
                        }
                    }

                    if (prevState.tradeAmount !== tradeAmount && 
                        sourceCoin === cryptoCurrency.btc) {
                        let estimate = parseFloat(tradeAmount) * parseFloat(this.btcUsdtPrice)
                        if (estimatedValue !== estimate) {
                            onSetNewState(this, {
                                estimatedValue: estimate
                            })
                        }
                    }

                    if (prevState.tradeAmount !== tradeAmount && 
                        sourceCoin === cryptoCurrency.usdt) {
                        let estimate = parseFloat(tradeAmount) / parseFloat(this.btcUsdtPrice)
                        if (estimatedValue !== estimate) {
                            onSetNewState(this, {
                                estimatedValue: estimate
                            })
                        }
                    }
                }
            }

            if (prevProps.setTradingBalance !== setTradingBalance && 
                !setTradingBalance.loading) {
                    const { orderResult } = setTradingBalance
                    if (isNotNull(orderResult)) {
                        const { success, errMsg } = orderResult
                        if (success && isNotNull(eventHandlers)) {
                            eventHandlers.navigateTo(null, onboardingScreen.COMPLETE)
                        } else {
                            onSetNewState(this, {
                                hasCustomError: true,
                                customErrorMsg: errMsg
                            })
                        }
                    }
            }

            if (isNotNull(skipOnboarding) && prevProps.skipOnboarding !== skipOnboarding && !skipOnboarding.loading) {
                const { response } = skipOnboarding
                if (isNotNull(response) && response.success) window.location.href = routes.dashboard
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    startTickerConnection = () => {
        let channelName = 'wss://stream.binance.com:9443/ws/!ticker@arr'        
        this.tickerSocket = new W3CWebSocket(channelName)
        this.tickerSocket.onopen = () => {
            onSetNewState(this, {
                isSocketConnected: true
            })
        }
        this.tickerSocket.onclose = () => {
            if (this.mounted) {
                setTimeout(() => {
                    this.tickerSocket = new W3CWebSocket(channelName)
                }, 3000)
            }
        }
        this.tickerSocket.onerror = () => {
            if (this.mounted) {
                setTimeout(() => {
                    this.tickerSocket = new W3CWebSocket(channelName)
                }, 3000)
            }
        }
        this.tickerSocket.onmessage = (response) => {
            let { data } = response
            if (isNotNull(data)) {
                data = JSON.parse(data)

                const btcUsdtTickerData = _.find(data, c => c.s === 'BTCUSDT')
                if (isNotNull(btcUsdtTickerData)) {
                    this.btcUsdtPrice = parseFloat(btcUsdtTickerData.c)
                    this.btcUsdtBidPrice = _.round(parseFloat(btcUsdtTickerData.b), 2).toFixed(2)
                }
            }
        }
    }

    onChangeSource = (e) => {
        const { cryptoCurrency, usdt, btc } = this.state
        let newSourceCoin = e.target.value
        let newTargetCoin = newSourceCoin === cryptoCurrency.btc ? 'USDT' : 'BTC'
        let newOrderType = newSourceCoin === cryptoCurrency.btc ? 'MARKET_SELL' : 'MARKET_BUY'
        let newDefaultTradeAmount = 0
        let newDefaultTradeAmountPercentage = 0

        if (newSourceCoin === cryptoCurrency.btc) {
            if (btc > 0) {
                newDefaultTradeAmount = btc / 2
                newDefaultTradeAmountPercentage = Math.round((newDefaultTradeAmount * 100) / parseFloat(btc), 0)
            }
        } else {
            if (usdt > 0) {
                newDefaultTradeAmount = usdt / 2
                newDefaultTradeAmountPercentage = Math.round((newDefaultTradeAmount * 100) / parseFloat(usdt), 0)
            }
        }

        onSetNewState(this, {
            sourceCoin: newSourceCoin,
            targetCoin: newTargetCoin,
            orderType: newOrderType,
            tradeAmount: newDefaultTradeAmount,
            tradeAmountPercentage: newDefaultTradeAmountPercentage
        })
    }

    onRangeChangeHandler = (e, newValue) => {
        e.preventDefault()

        onSetNewState(this, {
            hasCustomError: false,
            customErrorMsg: ''
        })

        if (!isNaN(newValue)) {
            const { cryptoCurrency, sourceCoin, usdt, btc } = this.state
            let newTradeAmount = 0
            if (sourceCoin === cryptoCurrency.btc && btc > 0) {
                newTradeAmount = parseFloat(btc) * (parseFloat(newValue) / 100)
                newTradeAmount = newTradeAmount.toFixed(8)
            }

            if (sourceCoin === cryptoCurrency.usdt && usdt > 0) {
                newTradeAmount = parseFloat(usdt) * (parseFloat(newValue) / 100)
                if (newTradeAmount < 1)
                    newTradeAmount = parseFloat(newTradeAmount.toFixed(8))
                else
                    newTradeAmount = parseFloat(newTradeAmount.toFixed(2))
            }
            
            if (isNotNull(newTradeAmount)) {
                onSetNewState(this, {
                    tradeAmount: newTradeAmount,
                    tradeAmountPercentage: newValue
                })
            }

            if (newValue >= 100) {
                onSetNewState(this, {
                    hasCustomError: true,
                    customErrorMsg: 'You need to have balances in both USDT and BTC'
                })
            } else {
                onSetNewState(this, {
                    hasCustomError: false,
                    customErrorMsg: ''
                })
            }
        }
    }

    onSetAmount = (e) => {
        e.preventDefault()

        onSetNewState(this, {
            hasCustomError: false,
            customErrorMsg: ''
        })

        const { usdt, btc, sourceCoin } = this.state
        if (isNotNull(sourceCoin)) {
            let newValue = e.target.value
            if (isNull(newValue) || newValue === '')
                newValue = 0
            else
                newValue = e.target.value //parseFloat(e.target.value)

            if (isNotNull(newValue)) {
                let percentage = 0
                let { hasCustomError, customErrorMsg } = this.state

                if (sourceCoin === 'BTC') {
                    if (parseFloat(btc) > 0) {
                        percentage = Math.round((parseFloat(newValue) * 100) / parseFloat(btc), 0)

                        if (parseFloat(newValue) > parseFloat(btc)) {
                            hasCustomError = true
                            customErrorMsg = "Value must be less than your current BTC balance"
                        } else {
                            hasCustomError = false
                            customErrorMsg = ""
                        }
    
                        if (parseFloat(newValue) === parseFloat(btc)) {
                            hasCustomError = true
                            customErrorMsg = "You need to have balances in both USDT and BTC"
                        } else {
                            hasCustomError = false
                            customErrorMsg = ""
                        }
                    } else {
                        if (parseFloat(newValue) > 0) {
                            hasCustomError = true
                            customErrorMsg = "You have insufficient balance"
                        } else {
                            hasCustomError = false
                            customErrorMsg = ''
                        }
                    }
                }
                
                if (sourceCoin === 'USDT') {
                    if (parseFloat(usdt) > 0) {
                        percentage = Math.round((parseFloat(newValue) * 100) / parseFloat(usdt), 0)

                        if (parseFloat(newValue) > parseFloat(usdt)) {
                            hasCustomError = true
                            customErrorMsg = "Value must be less than your current USDT balance"
                        } else if (parseFloat(newValue) === parseFloat(usdt)) {
                            hasCustomError = true
                            customErrorMsg = "You need to have balances in both USDT and BTC"
                        } else {
                            hasCustomError = false
                            customErrorMsg = ""
                        }
                    } else {
                        if (parseFloat(newValue) > 0) {
                            hasCustomError = true
                            customErrorMsg = "You have insufficient balance"
                        } else {
                            hasCustomError = false
                            customErrorMsg = ''
                        }
                    }
                }

                onSetNewState(this, {
                    tradeAmount: newValue,
                    tradeAmountPercentage: percentage,
                    hasCustomError,
                    customErrorMsg
                })
            }
        }
    }

    onConvert = (values) => {
        const { dispatch } = this.props
        const { ...data } = this.state

        let volume = 0
        if (isNotNull(this.btcUsdtBidPrice)) {
            if (data.sourceCoin === data.cryptoCurrency.usdt) {
                volume = _.round(parseFloat(data.tradeAmount) / parseFloat(this.btcUsdtBidPrice), 8)
            } else {
                let amount = parseFloat(data.tradeAmount)
                if (amount >= 100)
                    volume = amount.toFixed(2) * 1
                else if (amount >=1 && amount <= 99)
                    volume = amount.toFixed(4) * 1
                else
                    volume = amount.toFixed(5) * 1
            }

            let order = {
                order_type: data.orderType,
                exchange: data.exchange,
                coin: data.coin,
                volume: volume
            }
            dispatch(onboardingActions.setTradingBalance(order))
        }
    }

    onValidate = (values) => {
        const { cryptoCurrency, sourceCoin, usdt, btc } = this.state
        const errors = {}

        if (sourceCoin === cryptoCurrency.usdt) {
            if (parseFloat(values.tradeAmount) > parseFloat(usdt))
                errors.tradeAmount = "Value must be less than your current USDT balance"

            if (parseFloat(values.tradeAmount) === parseFloat(usdt))
                errors.tradeAmount = "You need to have balances in both USDT and BTC"
        } else {
            if (parseFloat(values.tradeAmount) > parseFloat(btc))
                errors.tradeAmount = "Value must be less than your current BTC balance"

            if (parseFloat(values.tradeAmount) === parseFloat(btc))
                errors.tradeAmount = "You need to have balances in both USDT and BTC"
        }

        return errors
    }

    refreshWallet = () => {
        onSetNewState(this, {
            isLoadingBalances: true
        }, () => {
            const { dispatch } = this.props
            dispatch(onboardingActions.getTradingBalances())
        })
    }

    handleClickNext = () => {
        //e.preventDefault()

        const { eventHandlers } = this.props
        if (isNotNull(eventHandlers)) {
            eventHandlers.navigateTo(null, onboardingScreen.COMPLETE)
        }
    }

    handleClickBack = (e) => {
        e.preventDefault()

        const { eventHandlers } = this.props
        if (isNotNull(eventHandlers)) {
            eventHandlers.navigateTo(null, onboardingScreen.API_RESTRICTION_SETTINGS)
        }
    }

    showSkipWarning = (e) => {
        const { eventHandlers } = this.props
        eventHandlers.handleSkip(e)
    }

    renderNoBalanceAtAll = () => (
        <>
            <div className='form with-converter'>
                <h2 className='form-title'>Fund your Binance account (Final step!)</h2>
                <h3 className='form-instruction'>You need to add USDT and BTC to your Binance account</h3>

                <Form.Group className='detailed-instructions'>
                    <ol>
                        <li>Fund your Binance SPOT wallet by purchasing BTC or USDT or by sending funds from other sources to your spot deposit wallet addresses within Binance.</li>
                        <li>Click here to <span className='inline-btn' onClick={(e) => this.refreshWallet(e)}>Refresh</span> your displayed balances after purchasing or depositing.</li>
                    </ol>
                </Form.Group>

                <FormInfoText type="list">
                    <label><strong>Notes:</strong></label>
                    <ul>
                        <li>You can purchase BTC and/or USDT directly from Binance.com or other services such as coinbase.com or crypto.com. Alternatively you can send funds from other wallets into your connected Binance.com deposit wallets.</li>
                        <li>If you have already funded your Binance FUTURES wallet instead, you can skip this step and move your funds to SPOT at another time / as you see fit.</li>
                    </ul>
                </FormInfoText>

                <Form.Group className='mb-0 notice-wrapper'>
                    <p className='notice'>
                        <FontAwesomeIcon size="xs" className='icon' icon={faExclamationCircle} /> 
                        <span>Rest assured that we do not have the ability to withdraw your funds</span>
                    </p>
                </Form.Group>
            </div>
        </>
    )

    renderNeedBalance = () => (
        <>
            <div className='form with-converter'>
                <h2 className='form-title'>Fund your account (final step!)</h2>
                <h3 className='form-instruction'>Please ensure you have a SPOT USDT and BTC balance</h3>

                <Form.Group className='detailed-instructions'>
                    <ol>
                        <li>Click convert and allocate funds to both USDT and BTC.</li>
                        <li>Click Next to complete account setup.</li>
                    </ol>
                </Form.Group>

                <FormInfoText type="list">
                    <label><strong>Notes:</strong></label>
                    <ul>
                        <li>The convert tool uses a market buy/sell order to change {`USDT<>BTC`} at current market value.</li>
                        <li>Signals are powered by either BTC or USDT depending on the coinpair to trade. It’s up to you how you split your funds. You will need at least $150 worth of BTC or USDT to copy a single signal. You can also skip this step if you prefer.</li>
                    </ul>
                </FormInfoText>

                <Form.Group className='mb-0 notice-wrapper'>
                    <p className='notice'>
                        <FontAwesomeIcon size="xs" className='icon' icon={faExclamationCircle} /> 
                        <span>Rest assured that we do not have the ability to withdraw your funds</span>
                    </p>
                </Form.Group>
            </div>
        </>
    )

    renderDynamicContent = () => {
        let { usdt, btc } = this.state
        usdt = parseFloat(usdt)
        btc = parseFloat(btc)

        if ((usdt === 0 && btc > 0) || (usdt > 0 && btc === 0) || (usdt > 0 && btc > 0)) {
            return (
                <>
                    { this.renderNeedBalance() }
                </>
            )
        } else {
            return (
                <>
                    { this.renderNoBalanceAtAll() }
                </>
            )
        }
    }

    renderLoader = () => (
        <>
            <div style={{ width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="pf-spinner xs" style={{ marginTop: '-48px', marginRight: '45px' }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        </> 
    )

    renderConnectionLoader = () => (
        <GifLoader>
            <p>Please wait whilst we check your connection to Binance.com...</p>
            <div className='graphic'>
                { (this.screenSize === mediaDeviceSizes.xs || this.screenSize === mediaDeviceSizes.sm) && 
                    <img src="/images/form-graphics/connection-loader-sm.gif" alt="Connecting to Binance.com" style={{ width: '100%' }} />
                }
                
                { !(this.screenSize === mediaDeviceSizes.xs || this.screenSize === mediaDeviceSizes.sm) && 
                    <img src="/images/form-graphics/connection-loader.gif" alt="Connecting to Binance.com" style={{ width: '100%' }} />
                }
            </div>
        </GifLoader>
    )

    renderAmountSlider = () => {
        const { tradeAmountPercentage } = this.state

        return (
            <ThemeProvider theme={this.muiTheme}>
                <Slider
                    value={tradeAmountPercentage}
                    onChange={this.onRangeChangeHandler}
                    defaultValue={0}
                    aria-labelledby="range-slider"
                    valueLabelDisplay="auto"
                    valueLabelFormat={value => <span>{`${value}%`}</span>}
                    marks={this.marks}
                    step={1}
                    min={0}
                    max={100}
                />
            </ThemeProvider>
        )
    }

    render() {
        let { ...state } = this.state
        let sourceCoinLogo = ''
        let targetSymbolLogo = ''

        if (isNotNull(state.sourceCoin)) {
            if (state.sourceCoin === state.cryptoCurrency.btc) {                
                sourceCoinLogo = 'btc'
                targetSymbolLogo = 'usdt'
            } else {
                sourceCoinLogo = 'usdt'
                targetSymbolLogo = 'btc'
            }
        } else {
            sourceCoinLogo = 'btc'
            targetSymbolLogo = 'usdt'
        }

        return (
            <>
                { state.isConnecting && this.renderConnectionLoader() }

                { !state.isConnecting && state.isConnected && 
                    <>
                        <FormBodyContent className='with-conversion-tool'>
                            { this.renderDynamicContent() }

                            <div className='quick-trade-form'>
                                { state.isLoadingBalances && this.renderLoader() }

                                { !state.isLoadingBalances && 
                                    <FinalForm 
                                        onSubmit={this.onConvert} 
                                        validate={this.onValidate} 
                                        decorators={[this.formDecorator]} 
                                        initialValues={{...state}} 
                                        render={({ handleSubmit }) => {
                                            if (isNotNull(state.usdt))
                                                state.usdt = parseFloat(state.usdt)
                                    
                                            if (isNotNull(state.btc))
                                                state.btc = parseFloat(state.btc).toFixed(8)                                                

                                            return (
                                                <Form onSubmit={handleSubmit} autoComplete="off">
                                                    { isNotNull(state.usdt) && 
                                                        isNotNull(state.btc) && 
                                                        ((parseFloat(state.usdt) > 0 && parseFloat(state.btc) === 0) || (parseFloat(state.btc) > 0 && parseFloat(state.usdt) === 0)) && 
                                                        <>
                                                            <div className='wallet-card'>
                                                                <Form.Group>
                                                                    <InputGroup>
                                                                        <InputGroup.Prepend className='as-label'>Source</InputGroup.Prepend>
                                                                        <div style={{ marginLeft: 'auto' }}>                                                                    
                                                                            <div className='currency-selection'>
                                                                                <span className='symbol-logo'>
                                                                                    <img src={`https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/${sourceCoinLogo}.png`} alt="base-crypto-logo" />
                                                                                </span>
                                                                                
                                                                                <Field name='sourceCoin' type='text'>
                                                                                    {({ input, meta }) => (
                                                                                        <>
                                                                                            <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>                                                            
                                                                                                <Form.Control 
                                                                                                    as="select" 
                                                                                                    defaultValue={state.sourceCoin}
                                                                                                    onChange={(e) => this.onChangeSource(e)}>
                                                                                                    <option value="BTC">BTC</option>
                                                                                                    <option value="USDT">USDT</option>
                                                                                                </Form.Control>
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </Field>
                                                                            </div>
                                                                        </div>
                                                                    </InputGroup>
                                                                </Form.Group>
                                                                
                                                                <Form.Group>
                                                                    <InputGroup>
                                                                        <InputGroup.Prepend className='as-label'>Current balance</InputGroup.Prepend>
                                                                        <InputGroup.Prepend className='as-button'>
                                                                            <PrimaryButton 
                                                                                type="button"
                                                                                onClick={(e) => this.refreshWallet(e)}>
                                                                                <FontAwesomeIcon icon={faSync} />
                                                                            </PrimaryButton>
                                                                        </InputGroup.Prepend>
                                                                        <div className='as-value'>
                                                                            <span>{ state.sourceCoin === 'BTC' ? `${parseFloat(state.btc).toFixed(8)}` : `${parseFloat(state.usdt).toFixed(2)}`}</span>
                                                                        </div>
                                                                    </InputGroup>
                                                                </Form.Group>

                                                                <Form.Group className='mb-0'>
                                                                    <Form.Label>Amount you convert</Form.Label>
                                                                    
                                                                    { this.renderAmountSlider() }

                                                                    <Field name='tradeAmount' type='text'>
                                                                        {({ input, meta }) => (
                                                                            <>
                                                                                <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                                                                    <input 
                                                                                        id="trade-amount" 
                                                                                        name={input.name}
                                                                                        className='form-control amount-to-trade' 
                                                                                        placeholder={state.sourceCoin === 'BTC' ? '0.00000000' : '0.00'}
                                                                                        autoComplete="off" 
                                                                                        value={input.value}
                                                                                        onChange={(e) => this.onSetAmount(e)} />

                                                                                        { ((meta.error && meta.touched) || state.hasCustomError) && 
                                                                                            <Form.Text>
                                                                                                { !state.hasCustomError && 
                                                                                                    <>{meta.error}</>
                                                                                                }

                                                                                                { state.hasCustomError && 
                                                                                                    <>{state.customErrorMsg}</>
                                                                                                }
                                                                                            </Form.Text>
                                                                                        }
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </Field>
                                                                </Form.Group>
                                                            </div>

                                                            <div className='wallet-card'>
                                                                <span className='trade-icon'>
                                                                    <FontAwesomeIcon icon={faArrowDown} />
                                                                </span>

                                                                <Form.Group>
                                                                    <InputGroup>
                                                                        <InputGroup.Prepend className='as-label'>Target</InputGroup.Prepend>
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            <div className='currency-selection'>
                                                                                <span className='symbol-logo'>
                                                                                    <img src={`https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/${targetSymbolLogo}.png`} alt="quote-crypto-logo" />
                                                                                </span>
                                                                                { targetSymbolLogo.toUpperCase() }
                                                                            </div>
                                                                        </div>
                                                                    </InputGroup>
                                                                </Form.Group>
                                                                <Form.Group className='mb-0'>
                                                                    <Form.Label>Estimated value you get</Form.Label>                                                            
                                                                    <p className='form-control amount-to-get readonly'>{ targetSymbolLogo === 'btc' ? state.estimatedValue.toFixed(8) : state.estimatedValue.toFixed(2) }</p>
                                                                </Form.Group>
                                                            </div>

                                                            <PrimaryButton 
                                                                type="submit" 
                                                                style={{ backgroundColor: '#2D79D6' }}>
                                                                <span>Convert</span>
                                                            </PrimaryButton>
                                                        </>
                                                    }
                                                    
                                                    { (isNotNull(state.usdt) && 
                                                        isNotNull(state.btc) && 
                                                        ((parseFloat(state.usdt) === 0 && parseFloat(state.btc) === 0) || (parseFloat(state.btc) > 0 && parseFloat(state.usdt) > 0))) && 
                                                        <>
                                                            <div className='wallet-card current'>
                                                                <label style={{ display: 'block', textAlign: 'center', marginBottom: '25px', fontSize: '15px' }}>Your current SPOT wallet balances</label>
                                                                <div className='currency-selection'>
                                                                    <span className='symbol-logo'>
                                                                        <img src='https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/btc.png' alt='BTC logo' />
                                                                    </span>
                                                                    <span className='value'>{ isNotNull(state.btc) && parseFloat(state.btc).toFixed(8) }</span>
                                                                    <span className='symbol'>BTC</span>
                                                                </div>
                                                                <div className='currency-selection'>
                                                                    <span className='symbol-logo'>
                                                                        <img src='https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/usdt.png' alt='USDT logo' />
                                                                    </span>
                                                                    <span className='value'>{ isNotNull(state.usdt) && parseFloat(state.usdt).toFixed(2) }</span>
                                                                    <span className='symbol'>USDT</span>
                                                                </div>
                                                            </div>

                                                            { state.hasFuturesBalance && 
                                                                <div className='wallet-card current'>
                                                                    <label style={{ display: 'block', textAlign: 'center', marginBottom: '25px', fontSize: '15px' }}>Your current FUTURES wallet balance</label>
                                                                    <div className='currency-selection'>
                                                                        <span className='symbol-logo'>
                                                                            <img src='https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/usdt.png' alt='USDT logo' />
                                                                        </span>
                                                                        <span className='value'>{ isNotNull(state.futuresUsdt) && parseFloat(state.futuresUsdt).toFixed(2) }</span>
                                                                        <span className='symbol'>USDT</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </Form>
                                            )
                                        }} />
                                }
                            </div>
                        </FormBodyContent>

                        <FormActions>
                            <TextButton 
                                textColor="#8A9BAE"
                                size="sm" 
                                className='default-btn back-btn'
                                onClick={(e) => this.handleClickBack(e)}>
                                    <FontAwesomeIcon size="xs" className='icon' icon={faArrowLeft} /> 
                                    <span>Back</span>
                            </TextButton>

                            <TextButton 
                                textColor="#8A9BAE"
                                size="auto" 
                                className='default-btn skip-btn' 
                                onClick={(e) => this.showSkipWarning(e)}>
                                    <FontAwesomeIcon size="xs" className='icon' icon={faShare} /> 
                                    <span>Skip</span>
                            </TextButton>

                            <PrimaryButton 
                                size="sm"
                                onClick={(e) => this.handleClickNext(e)} 
                                disabled={!(state.usdt > 0 && state.btc > 0)}>
                                    <FontAwesomeIcon size="xs" className='icon' icon={faArrowRight} /> 
                                    <span>Next</span>
                            </PrimaryButton>
                        </FormActions>
                    </>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    const { userWalletBalances, setTradingBalance, skipOnboarding } = state
    return {
        userWalletBalances,
        setTradingBalance,
        skipOnboarding
    }
}

const connectedAdjustTradingBalance = connect(mapStateToProps)(AdjustTradingBalance)
export { connectedAdjustTradingBalance as AdjustTradingBalance }