import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { ScrollToTopButton } from '../../styles'

export const ScrollToTopBtn = (props) => {
    return (
        <ScrollToTopButton onClick={props.eventHandlers}>
            <FontAwesomeIcon icon={faArrowUp} />
        </ScrollToTopButton>
    )
}