import React from 'react'
import { connect } from 'react-redux'
import { Form } from 'react-bootstrap'
import { Form as FinalForm, Field } from 'react-final-form'
import newFormDecorator from 'final-form-focus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import { onboardingActions } from '../../redux/actions'
import { customErrorCodes } from '../../constants/customErrorCodes'
import { isNull, isNotNull, onSetNewState } from '../../utils'

class AddExchangeKeysFrom extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            apiKey: '',
            secretKey: '',
            isSubmitting: false,
            hasCustomError: false,
            customErrorMsg: ''
        }
        this.baseState = this.state
        this.mounted = false
        this.formDecorator = newFormDecorator()
    }

    componentDidMount() {
        this.mounted = true
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { saveExchangeKeys, skipOnboarding, setConnected, exitForm } = this.props
            const { isSubmitting } = this.state

            if (prevProps.saveExchangeKeys !== saveExchangeKeys && 
                !saveExchangeKeys.loading && 
                isSubmitting) {
                    const { result } = saveExchangeKeys
                    if (isNotNull(result)) {
                        const { success } = result
                        if (success) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Successfully Connected',
                                allowOutsideClick: false,
                                showConfirmButton: false,
                                timer: 2000,
                                willClose: () => setConnected()
                            })
                        } else {
                            const { msg } = result
                            if (isNotNull(msg) && msg === customErrorCodes.INVALID_EXCHANGE_KEYS) {
                                onSetNewState(this, {
                                    hasCustomError: true,
                                    customErrorMsg: 'Invalid key'
                                })
                            }
                        }
                    }

                    onSetNewState(this, {
                        isSubmitting: false
                    })
                }

            if (isNotNull(skipOnboarding) && prevProps.skipOnboarding !== skipOnboarding && !skipOnboarding.loading) {
                const { response } = skipOnboarding
                if (isNotNull(response) && response.success) 
                    exitForm()
            }
        }
    }
    
    componentWillUnmount() {
        this.mounted = false
    }

    onValidate = (values) => {
        const errors = {}

        // validate api key
        if (isNull(values.apiKey))
            errors.apiKey = "API Key is required"
        else {
            onSetNewState(this, {
                apiKey: values.apiKey
            })
        }

        // validate secret key
        if (isNull(values.secretKey))
            errors.secretKey = "SECRET Key is required"
        else {
            if (values.secretKey === 'YOUCANTSEEME' && values.secretKey === 'youcantseeme')
                errors.secretKey = "Invalid SECRET key"
            else {
                onSetNewState(this, {
                    secretKey: values.secretKey
                })
            }
        }

        return errors
    }

    onSubmit = (values) => {
        const { dispatch } = this.props
        const { apiKey, secretKey, isSubmitting } = this.state

        if (!isSubmitting) {
            let data = {
                apiKey,
                secretKey
            }
            dispatch(onboardingActions.saveExchangeKeys(data))
            onSetNewState(this, {
                isSubmitting: true,
                secretKey: "YOUCANTSEEME"
            })
        }
    }

    onSkip = (e) => {
        e.preventDefault()
        const { dispatch } = this.props
        dispatch(onboardingActions.skipOnboarding())
    }

    render() {
        let { ...state } = this.state

        return (
            <FinalForm 
                onSubmit={this.onSubmit} 
                validate={this.onValidate}
                decorators={[this.formDecorator]}
                initialValues={{...state}}
                render={({ handleSubmit, form, submitting, pristine, values }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <h1>Connect to Binance</h1>
                            <p>Enter your API and Secret keys below</p>

                            <div className='exchange-form'>                                        
                                <Form.Group controlId="apiKey">
                                    <Field name="apiKey" type="text">
                                        {({ input, meta }) => (
                                            <>
                                                <div className={`input-wrapper ${(meta.error && meta.touched) || state.hasCustomError ? 'is-invalid' : ''}`}>
                                                    <Form.Control 
                                                        placeholder="Your API key"
                                                        {...input}
                                                        autoComplete="off"
                                                        disabled={state.isSubmitting} />

                                                    { ((meta.error && meta.touched) || state.hasCustomError) && 
                                                        <Form.Text>
                                                            { !state.hasCustomError && 
                                                                <>{meta.error}</>
                                                            }

                                                            { state.hasCustomError && 
                                                                <>{state.customErrorMsg}</>
                                                            }
                                                        </Form.Text>
                                                    }
                                                </div>
                                            </>
                                        )}
                                    </Field>
                                </Form.Group>

                                <Form.Group controlId="secretKey">
                                    <Field name="secretKey" type="password">
                                        {({ input, meta }) => (
                                            <>
                                                <div className={`input-wrapper ${(meta.error && meta.touched) || state.hasCustomError ? 'is-invalid' : ''}`}>
                                                    <Form.Control 
                                                        placeholder="Your SECRET key"
                                                        {...input}
                                                        autoComplete="off"
                                                        disabled={state.isSubmitting} />

                                                    { ((meta.error && meta.touched) || state.hasCustomError) && 
                                                        <Form.Text>
                                                            { !state.hasCustomError && 
                                                                <>{meta.error}</>
                                                            }

                                                            { state.hasCustomError && 
                                                                <>{state.customErrorMsg}</>
                                                            }
                                                        </Form.Text>
                                                    }
                                                </div>
                                            </>
                                        )}
                                    </Field>
                                </Form.Group>
                            </div>

                            <p><FontAwesomeIcon icon={faInfoCircle} /> <small>Make sure you copy the actual code and not ******. If you can no longer see the Secret Key in Binance, you have to delete the previous API and redo the process.</small></p>

                            <div className='exchange-form-actions'>
                                <button 
                                    type="submit" 
                                    className='submit-keys-btn' 
                                    disabled={state.isSubmitting}>
                                        { state.isSubmitting ? <span>Connecting...</span> : <span>Connect</span>}
                                    </button>

                                <button 
                                    type='button'
                                    className='skip-btn' 
                                    onClick={(e) => this.onSkip(e)}>
                                    Do it later
                                </button>
                            </div>
                        </Form>
                    )
                }} />
        )
    }
}

function mapStateToProps(state) {
    const { saveExchangeKeys, skipOnboarding } = state
    return {
        saveExchangeKeys,
        skipOnboarding
    }
}

const connectedAddExchangeKeys = connect(mapStateToProps)(AddExchangeKeysFrom)
export { connectedAddExchangeKeys as AddExchangeKeysFrom }