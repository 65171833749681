import { endpoints, baseUrl } from './CONSTANTS'

const { v3 } = endpoints

function forgotPassword(emailAddress) {
    const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({ emailAddress })
    }

    return fetch(`${baseUrl}${v3.auth.forgotPassword}`, options)
        .then(handleResponse)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error))
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        return data;
    })
}

export const forgotPassService = {
    forgotPassword,
}