import React from 'react'
import { connect } from 'react-redux'
import { routes } from './CONSTANTS'
import { PageNotFound } from '../styles'
import { history, Dimension, onSetNewState } from '../utils'

class NotFound extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight
        }
        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        window.addEventListener('resize', () => Dimension.updateDimensions(this))
        const { location, authentication } = this.props

        if (location === '' || location === '/') {
            let redirectUrl = routes.authentication.login
            if (authentication.loggedIn === true)
                redirectUrl = routes.dashboard

            history.push(redirectUrl)
        }
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)
        window.removeEventListener('resize', () => Dimension.updateDimensions(this))
        this.mounted = false
    }

    render() {
        const { height } = this.state
        return (
            <PageNotFound style={{ height: `${height}px` }} >
                <img src="/images/notfound.png" className="img-fluid" alt="not found" />
            </PageNotFound>
        )
    }
}

function mapStateToProps(state) {
    let { authentication } = state

    return {
        authentication
    }
}

const connectedNotFound = connect(mapStateToProps)(NotFound)
export { connectedNotFound as NotFound }
