import React from 'react'
import { connect } from 'react-redux'
import jQuery from 'jquery'
import _ from 'lodash'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { walletActions } from '../../redux/actions'
import { walletActionTypes } from '../../redux/CONSTANTS'
import { WalletView } from './WalletView'
import { isNotNull, isNull, Dimension, getCoinName, toBtcFormat, onSetNewState, addPageViewEvent, seo } from '../../utils'

class WalletContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            isDisable: false,
            balanceShow: false,
            btcValue: 0,
            btcAlmostEqualValue: 0,
            totalBalance: 0,
            currentSet: 20,
            totalItems: 0,
            binanceData: [],
            channel: 'wss://stream.binance.com:9443/ws/!ticker@arr',
            searchValue: null
        }
        this.mounted = false
        this.wsBinance24hrTicker = null
        this.baseState = this.state

        window.onscroll = _.debounce(() => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.scrollingElement.scrollHeight - 1)
                this.onScrollLoadNewWalletDataHandler()
        }, 100)
    }

    componentDidMount() {
        this.mounted = true
        const { dispatch } = this.props
        dispatch(walletActions.getBalance())

        if (this.state.binanceData.length <= 0)
            dispatch(walletActions.getBinance())

        this.initWallet()

        seo({
            title: "Wallet",
            metaDescription: ""
        })
        addPageViewEvent()

        window.addEventListener('resize', () => Dimension.updateDimensions(this))
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            if (prevProps.balanceWallet !== this.props.balanceWallet) {
                const { item, loader } = this.props.balanceWallet
                if (!loader) {
                    if (isNotNull(item)) {
                        const { data } = item
                        if (isNotNull(data)) {
                            const totalBalance = _.sumBy(data, b => parseFloat(b.balance.free))
                            onSetNewState(this, {
                                show: true,
                                isDisable: false,
                                totalItems: data.length,
                                totalBalance: totalBalance
                            })
                            jQuery(".wallet-toggle").addClass("active")
                        } else
                            onSetNewState(this, {
                                show: false,
                                isDisable: true
                            })
                    } else
                        onSetNewState(this, {
                            show: false,
                            isDisable: true
                        })
                }
            }

            if (prevProps.getBinance !== this.props.getBinance) {
                const { items } = this.props.getBinance
                if (isNotNull(items) && items.length > 0) {
                    let newLists = []
                    _.forEach(items, s => newLists.push(s))
                    onSetNewState(this, { binanceData: newLists })
                }
            }

        }
    }

    componentWillUnmount() {
        this.mounted = false
        if (this.wsBinance24hrTicker !== null) {
            this.wsBinance24hrTicker.close()
            // console.log('Websocket Wallet closed.')
        }
        window.removeEventListener('resize', () => Dimension.updateDimensions(this))
    }

    onScrollLoadNewWalletDataHandler = () => {
        const { currentSet, totalItems } = this.state
        const newSet = currentSet + 20
        if (currentSet <= totalItems)
            onSetNewState(this, { currentSet: newSet })
    }

    //#region Handle Binance 24hr ticker
    initWallet = () => {
        const { channel } = this.state
        this.wsBinance24hrTicker = new W3CWebSocket(channel)

        this.wsBinance24hrTicker.onopen = () => {
            // console.log('WebSocket Wallet Page Client Connected')
        }
        this.wsBinance24hrTicker.onclose = () => {
            this.wsBinance24hrTicker = new W3CWebSocket(channel)
            // console.log('WebSocket Wallet Page Client Closed')
        }
        this.wsBinance24hrTicker.onerror = () => {
            this.wsBinance24hrTicker = new W3CWebSocket(channel)
            // console.log('WebSocket Wallet Page Connection Error')
        }

        this.wsBinance24hrTicker.onmessage = (response) => {
            let { data } = response
            const { balanceWallet, dispatch } = this.props
            if (isNotNull(data)) {
                data = JSON.parse(data)

                if (isNotNull(balanceWallet) &&
                    isNotNull(balanceWallet.item) &&
                    isNotNull(balanceWallet.item.data)) {

                    // extract BTCUSDT info from websockets response data
                    const btcUsdtInfo = _.find(data, c => c.s.toUpperCase() === 'BTCUSDT')

                    // extract user's wallets
                    const wallets = [...balanceWallet.item.data]
                    _.forEach(wallets, wallet => {
                        // set the full name of the coin
                        wallet.coinName = getCoinName(wallet.name)

                        if (wallet.balance.free <= 0) {
                            // wallet has no balance
                            wallet.balance.usdt = 0
                            wallet.balance.btc = 0.00
                        } else { // wallet balance is greater than 0

                            // set default values for BTC and USDT
                            if (wallet.name === "BTC") {
                                wallet.balance.btc = 0.00
                                if (isNotNull(btcUsdtInfo) && isNotNull(btcUsdtInfo.c))
                                    wallet.balance.usdt = parseFloat(wallet.balance.free) * parseFloat(btcUsdtInfo.c)
                            }
                            else if (wallet.name === "USDT") {
                                if (isNotNull(btcUsdtInfo) && isNotNull(btcUsdtInfo.c))
                                    wallet.balance.btc = parseFloat(wallet.balance.free) / parseFloat(btcUsdtInfo.c)
                                wallet.balance.usdt = wallet.balance.free
                            }
                            else {
                                let coinName = wallet.name

                                let btcSymbolInfo = _.find(data, t => t.s.toUpperCase() === `${coinName}BTC`)
                                if (isNull(btcSymbolInfo)) {
                                    const binanceData = _.find(this.state.binanceData, q => q.symbol === `${coinName}BTC`)
                                    if (isNotNull(binanceData) && isNotNull(binanceData.lastPrice) && binanceData.lastPrice > 0)
                                        btcSymbolInfo = { c: binanceData.lastPrice }
                                }

                                // set calculated btc value
                                wallet.balance.btc = (isNotNull(btcSymbolInfo))
                                    ? parseFloat(wallet.balance.free) * parseFloat(btcSymbolInfo.c)
                                    : 0.00

                                let usdtSymbolInfo = _.find(data, t => t.s.toUpperCase() === `${coinName}USDT`)
                                if (isNull(usdtSymbolInfo)) {
                                    if (isNotNull(btcSymbolInfo)) {
                                        if (isNotNull(btcSymbolInfo.c) && isNotNull(btcUsdtInfo) && isNotNull(btcUsdtInfo.c))
                                            // product of symbol's lastPrice and BTCUSDT lastPrice
                                            usdtSymbolInfo = { c: parseFloat(btcSymbolInfo.c) * parseFloat(btcUsdtInfo.c) }

                                    } else {
                                        const binanceData = _.find(this.state.binanceData, q => q.symbol === `${coinName}USDT`)
                                        if (isNotNull(binanceData))
                                            usdtSymbolInfo = { c: binanceData.lastPrice }
                                    }
                                }

                                // set calculated usdt value
                                wallet.balance.usdt = (isNotNull(usdtSymbolInfo))
                                    ? parseFloat(wallet.balance.free) * parseFloat(usdtSymbolInfo.c)
                                    : 0
                            }
                        }
                    })

                    let totalUsd = _.sumBy(wallets, b => parseFloat(b.balance.usdt))
                    let balance_wallet = {
                        loading: false,
                        success: true,
                        msg: '',
                        data: wallets,
                        totalBtc: _.round(parseFloat(totalUsd) / parseFloat(btcUsdtInfo.c), 8),
                        totalUsdt: parseFloat(_.round(parseFloat(totalUsd) / parseFloat(btcUsdtInfo.c), 8)) * parseFloat(btcUsdtInfo.c)
                    }

                    let computedValue = _.round(toBtcFormat(balance_wallet.totalUsdt), 2).toFixed(2)

                    onSetNewState(this, {
                        btcValue: balance_wallet.totalBtc,
                        btcAlmostEqualValue: computedValue,
                        loading: true,
                    })
                    dispatch({ type: walletActionTypes.BALANCE_WALLET_UPDATE, balance_wallet })
                }
            }
        }
    }
    //#endregion

    onSearchHandler = (searchValue) => onSetNewState(this, { searchValue })

    onBalanceToggleHandler = () => onSetNewState(this, (prev) => {
        return ({ balanceShow: !prev.balanceShow, currentSet: 20 })
    })

    render() {
        return (
            <WalletView
                onSearch={this.onSearchHandler}
                onBalanceToggle={this.onBalanceToggleHandler}
                state={this.state}
                {...this.props}
            />
        )
    }
}

function mapStateToProps(state) {
    const { authentication, balanceWallet, getBinance } = state
    return {
        authentication,
        balanceWallet,
        getBinance
    }
}
const connectedWalletContainer = connect(mapStateToProps)(WalletContainer)
export { connectedWalletContainer as WalletContainer }