import { endpoints, baseUrl } from './CONSTANTS'
import { authServices } from './authService'

const { v3 } = endpoints

function getPriceAction(selectedPair) {
    selectedPair = (selectedPair === null) ? 'BTC' : selectedPair
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${v3.scanner.priceAction}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function getWatchLists() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${v3.scanner.watchlist.list}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function saveWatchlist(pairedCoin) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${v3.scanner.watchlist.addOrRemove(pairedCoin)}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function getTradeGuide(data) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        //headers: header.addAuthorizationHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${baseUrl}${v3.scanner.tradeGuide}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function getRSI() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${v3.scanner.rsi}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        if (res.status === 401)
            authServices.logout()
        else
            return data
    })
}

export const scannerServices = {
    getPriceAction,
    getWatchLists,
    saveWatchlist,
    getTradeGuide,
    getRSI
}