import React from 'react'
import { UserFeedbackStyle } from './styles/UserFeedbackStyle'

export const UserFeedbackSection = () => {
    return (
        <UserFeedbackStyle>
            <div className='container'>
                <ul className='feedback-list'>
                    <li>
                        <img src='/images/subscriptions/OLIVER.png' alt='' />
                    </li>
                    <li>
                        <img src='/images/subscriptions/LUKA.png' alt='' />
                    </li>
                    <li>
                        <img src='/images/subscriptions/RASMUS.png' alt='' />
                    </li>
                </ul>
            </div>
        </UserFeedbackStyle>
    )
}