import { Skeleton } from "@material-ui/lab"
import { ButtonGroup, Button as MaterialButton } from '@material-ui/core'
import { Tabs, Tab, SafeAnchor } from 'react-bootstrap'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListAlt } from '@fortawesome/free-regular-svg-icons'
import { OrderHistoryItemView } from './OrderHistoryItemView'
import { OpenOrderView } from './OpenOrderView'
import { Table } from '../../styles'
import {
    WalletWrapper, WalletContainer, WalletTitle,
    WalletDataContainer,
}
    from '../Wallet/styled_components'
import { FeedButton } from './styled_components/OrderStyle'
import { NoData } from './styled_components'
import { isNotNull } from '../../utils'
import { OrderPositionsItemView } from "./OrderPositionsItemView"

export const OrderView = (props) => {

    const { user } = props.authentication
    const $state = props.state

    // SPOT
    const { items, loading } = props.orderHistory
    const { listsItem, loader } = props.openOrder

    // FUTURES
    const { futures_order_history_item, futures_history_loading } = props.futuresOrderHistory
    const { futures_open_order_item, futures_open_order_loading } = props.futuresOpenOrder

    const { loading_positions } = props.orderPositions

    const {
        currentOpenSet,
        eventKey,
        currentHistorySet,
        currentFuturesHistorySet,
        currentFuturesOpenSet,
        feedValue,
        currentPositionSet,
        isLivePositions

    } = props.state

    const renderPreloader = () => (
        <>
            <Skeleton variant="rect" height={65} style={{
                width: '100%',
                marginBottom: '15px',
                backgroundColor: '#1f2b39',
                borderRadius: '2px',
                marginLeft: 'auto'
            }} className="skeleton" />

            <Skeleton variant="rect" style={{
                width: '100%',
                height: '660px',
                backgroundColor: '#1f2b39',
                borderRadius: '2px'
            }} className="skeleton" />
        </>
    )

    const renderTableHead = (eventKey) => (
        <thead className="order-thead">
            <tr>
                <th>ORDER NAME</th>
                {
                    eventKey === "orders" &&
                    <th></th>
                }
                <th>TYPE</th>
                <th>VOLUME</th>
                <th>PRICE</th>
                <th>CONDITION</th>
                <th>{eventKey === "orders" ? "OPEN DATE" : "COMMENT"}</th>
                <th>{eventKey === "orders" ? "EXPIRY" : "CLOSED DATE"}</th>
                {
                    eventKey === "orders" &&
                    <th><br /></th>
                }
            </tr>
        </thead>
    )

    const renderPositionsTableHeader = () => (
        <thead className="order-thead">
            <tr>
                <th>SYMBOL</th>
                <th>SIZE</th>
                <th>PRICE</th>
                <th>LIQ. PRICE</th>
                <th>MARGIN</th>
                <th>UNREALIZED ROE %)</th>
                <th></th>
            </tr>
        </thead>
    )

    const renderNoData = (hasData) => {
        if (!hasData)
            return (
                <NoData className="text-center p-5 m-0 no-data-wrapper">
                    No available data
                </NoData>
            )
    }

    const renderOpenItem = (listsItem, currentSet, feed = 'SPOT') => {
        const openOrder = {
            hasOperOrder: false,
            openOrderTable: null
        }

        if (isNotNull(listsItem)) {
            const { data } = listsItem
            if (isNotNull(data) && data.length > 0) {
                const filter = _.filter(data, (val, key) => key <= currentSet)
                const orderLists = _.orderBy(filter, o => new Date(o.dateOpen), 'desc')
                openOrder.hasOperOrder = true

                openOrder.openOrderTable = _.map(orderLists, (value, key) => {
                    return (
                        <OpenOrderView
                            key={key}
                            state={props.state}
                            onExpand={props.onExpand}
                            onDisplay={props.onDisplay}
                            onForceExitTrade={(orderType, value) => props.handleExitTradeOrder(orderType, value, feed)}
                            onCancelTrade={(orderType, value) => props.onCancelTrade(orderType, value, feed)}
                            {...value} />
                    )
                })
            } else
                openOrder.hasOperOrder = false
        }
        return openOrder
    }

    const renderHistoryItem = (items, currentSet) => {
        const orderHistory = {
            hasHistoryData: false,
            historyOrderTable: null
        }
        if (isNotNull(items)) {
            const { data } = items
            if (isNotNull(data)) {
                const filter = _.filter(data, (val, key) => key <= currentSet)
                orderHistory.hasHistoryData = true
                orderHistory.historyOrderTable = _.map(filter, (value, key) => {
                    return (
                        <OrderHistoryItemView
                            key={key}
                            state={props.state}
                            onExpand={props.onExpand}
                            onDisplay={props.onDisplay}
                            {...value} />
                    )
                })
            } else
                orderHistory.hasHistoryData = false
        }
        return orderHistory
    }

    const renderPositionsItem = (items, currentSet) => {
        const orderPositions = {
            hasOrderPositionData: false,
            orderPositionTable: null,
        }

        if (isNotNull(items)) {
            const { positions } = items
            if (isNotNull(positions)) {

                // remove pagination due to need to show immediately all the positions available
                const filter = positions.filter(val => parseFloat(val.positionAmt) > 0)

                orderPositions.hasOrderPositionData = filter.length > 0
                orderPositions.orderPositionTable = _.map(filter, (value, key) => {
                    return (
                        <OrderPositionsItemView
                            key={key}
                            id={key}
                            onClosePositionHandler={onClosePositionHandler}
                            state={props.state}
                            onExpand={props.onExpand}
                            onDisplay={props.onDisplay}
                            {...value} />
                    )
                })
            }
            else
                orderPositions.hasOrderPositionData = false
        }
        return orderPositions
    }

    const onClosePositionHandler = positionObject => {

        const positionMarketSell = {
            type: 'MARKET_SELL',
            data: {
                order_type: 'MARKET_SELL',
                exchange: 'binancefutures',
                coin: positionObject.symbol,
                volume: parseFloat(positionObject.positionAmt),
                live: isLivePositions
            }
        }
        const index = _.findIndex($state.orderPositionList.positions, o => o.symbol === positionObject.symbol)

        if (index >= 0)
            props.onClosePositionOrderHandler(positionMarketSell, index)
    }

    // SPOT
    const opendata = renderOpenItem(listsItem, currentOpenSet)
    const historyData = renderHistoryItem(items, currentHistorySet)

    // FUTURES
    const futuresHistoryData = renderHistoryItem(futures_order_history_item, currentFuturesHistorySet)
    const futuresOpenData = renderOpenItem(futures_open_order_item, currentFuturesOpenSet, 'FUTURES')

    // POSITIONS
    const orderPositionsData = renderPositionsItem($state.orderPositionList, currentPositionSet)

    return (
        <WalletWrapper className="theme-main-wrapper">
            <WalletContainer className="theme-history-lists">
                <WalletTitle className="theme-section-title order">
                    <FontAwesomeIcon icon={faListAlt} />Orders
                    {
                        !loading && !loader && !futures_history_loading && !futures_open_order_loading && !loading_positions &&
                        <div className="float-right" style={{ display: 'flex' }}>
                            <SafeAnchor onClick={() => props.onTriggerOrderRefresh()} className="btn btn-default feed-anchor">
                                <i className="fas fa-sync"></i>
                            </SafeAnchor>
                            <ButtonGroup className="feed-toggle" color="primary" aria-label="outlined primary button group">
                                <FeedButton as={MaterialButton} onClick={() => props.onSetFeedToggleHandler("SPOT")} active={feedValue} className={`feed-btn ${feedValue === "SPOT" ? 'selected' : ''}`}>SPOT</FeedButton>
                                <FeedButton as={MaterialButton} onClick={() => props.onSetFeedToggleHandler("FUTURES")} active={feedValue} className={`feed-btn ${feedValue === "FUTURES" ? 'selected' : ''}`}>FUTURES</FeedButton>
                            </ButtonGroup>
                        </div>
                    }
                </WalletTitle>

                {!loading && !loader && !futures_history_loading && !futures_open_order_loading && !loading_positions ?
                    <>
                        {isNotNull(user) &&
                            <>
                                { feedValue === "SPOT" ? 
                                    <Tabs defaultActiveKey={eventKey} transition={false} id="noanim-tab-example" className="tab-navigation">
                                        <Tab eventKey="orders" title="OPEN ORDERS" className="order-lists open-orders">
                                            <Table className="table mb-0">
                                                {renderTableHead("orders")}
                                                {opendata.openOrderTable}
                                            </Table>
                                            {renderNoData(opendata.hasOperOrder)}
                                        </Tab>

                                        <Tab eventKey="history" title="ORDER HISTORY" className="order-lists order-history">
                                            <Table className="table mb-0">
                                                {renderTableHead("history")}
                                                {historyData.historyOrderTable}
                                            </Table>
                                            {renderNoData(historyData.hasHistoryData)}
                                        </Tab>
                                    </Tabs>
                                    :
                                    <Tabs defaultActiveKey={eventKey} transition={false} id="noanim-tab-example1" className="tab-navigation">
                                        <Tab eventKey="positions" title="POSITIONS" className="order-lists position-orders">
                                            <Table className="table mb-0">
                                                {renderPositionsTableHeader()}
                                                {orderPositionsData.orderPositionTable}
                                            </Table>
                                            {renderNoData(orderPositionsData.hasOrderPositionData)}
                                        </Tab>
                                        <Tab eventKey="orders" title="OPEN ORDERS" className="order-lists open-orders">
                                            <Table className="table mb-0">
                                                {renderTableHead("orders")}
                                                {futuresOpenData.openOrderTable}
                                            </Table>
                                            {renderNoData(futuresOpenData.hasOperOrder)}
                                        </Tab>

                                        <Tab eventKey="history" title="ORDER HISTORY" className="order-lists order-history">
                                            <Table className="table mb-0">
                                                {renderTableHead("history")}
                                                {futuresHistoryData.historyOrderTable}
                                            </Table>
                                            {renderNoData(futuresHistoryData.hasHistoryData)}
                                        </Tab>
                                    </Tabs>
                                }
                            </>
                        }
                    </> : renderPreloader()
                }
            </WalletContainer>
        </WalletWrapper>
    )
}