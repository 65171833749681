import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import jQuery from 'jquery'
import { StatisticView } from './StatisticView'
import { statisticActions } from '../../redux/actions'
import { statisticActionTypes } from '../../redux/CONSTANTS'
import { Dimension, isNotNull, onSetNewState, addPageViewEvent, seo } from '../../utils'

class StatisticContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hasAccess: undefined,
            allStrategies: [],
            periodRange: 3, //ThisWeek Default 3
            selectedPeriod: "This Week",
            selectedStrategy: "All Strategies",
            totalTrade: 0,
            winTrade: 0,
            losingTrade: 0,
            winRate: 0,
            totalBreakevenTrade: 0,
            filter: 0,
            startDate: new Date(),
            endDate: new Date(),
            strategyFilterCount: 0,
            isStrategyChange: false,
            isEnable: true,
            isToggle: false
        }
        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        const { dispatch, authentication } = this.props
        
        if (isNotNull(authentication)) {
            const { user } = authentication
            if (isNotNull(user)) {
                if (user.isPaid) {
                    // Get Statistic Strategies
                    dispatch(statisticActions.getStrategies())
                    // Get Statistic PeriodRange
                    dispatch(statisticActions.getPeriodRange())
                    window.addEventListener('resize', () => Dimension.updateDimensions(this))

                    seo({
                        title: "Statistics",
                        metaDescription: ""
                    })
                    addPageViewEvent()
                } else {
                    window.location.href = '/subscription-plans/pro/subscribe'
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { statistics, strategiesFilter, periodRangeFilter, dispatch } = this.props
            if (prevProps.strategiesFilter !== strategiesFilter) {
                const { loader, filters } = strategiesFilter
                if (!loader) {
                    if (isNotNull(filters)) {
                        if (filters.length > 0)
                            onSetNewState(this, { strategyFilterCount: filters.length })
                        let allFilter = []
                        _.forEach(filters, filter => {
                            allFilter.push({ "key": filter.key })
                        })

                        const requestData = {
                            "periodRange": this.state.periodRange,
                            "startDate": "",
                            "endDate": "",
                            "strategies": allFilter
                        }

                        onSetNewState(this, { allStrategies: allFilter })
                        dispatch(statisticActions.getStatistics(requestData))
                    }
                }
            }

            if (prevProps.statistics !== statistics) {
                const { loader, items } = statistics
                if (!loader) {
                    if (isNotNull(items)) {
                        const { totalTrades, winningTrades, losingTrades, winRate, totalBreakevenTrades } = items

                        if (isNotNull(totalTrades) &&
                            isNotNull(winningTrades) &&
                            isNotNull(losingTrades) &&
                            isNotNull(winRate) &&
                            isNotNull(totalBreakevenTrades))
                            onSetNewState(this, {
                                totalTrade: totalTrades,
                                winTrade: winningTrades,
                                losingTrade: losingTrades,
                                winRate: winRate,
                                totalBreakevenTrade: totalBreakevenTrades,
                                isEnable: false
                            })
                        jQuery(".wallet-toggle").addClass("active")

                    } else
                        onSetNewState(this, {
                            totalTrade: 0,
                            winTrade: 0,
                            losingTrade: 0,
                            winRate: 0,
                            totalBreakevenTrade: 0
                        })
                }
            }

            if (prevProps.periodRangeFilter !== periodRangeFilter) {
                const { loader, filters } = periodRangeFilter
                if (!loader) {
                    if (isNotNull(filters)) {
                        const { periodRange } = this.state
                        const find = _.find(filters, f => f.key === periodRange)
                        if (isNotNull(find))
                            onSetNewState(this, { selectedPeriod: find.value })
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)
        const { dispatch } = this.props
        dispatch({ type: statisticActionTypes.GET_STATISTICS_SUCCESS })
        dispatch({ type: statisticActionTypes.STRATEGIES_FILTER_SUCCESS })
        dispatch({ type: statisticActionTypes.PERIODRANGE_FILTER_SUCCESS })
        window.removeEventListener('resize', () => Dimension.updateDimensions(this))
        this.mounted = false
    }

    onStrategyChangeHandler = () => onSetNewState(this, { isStrategyChange: true })

    onStrategySubmitHandler = (data) => {
        if (this.mounted) {
            const { startDate, periodRange, endDate, strategyFilterCount } = this.state
            const { dispatch } = this.props
            let selectedStrat = null
            const newLists = []

            //Get Strategy Checkbox Lists
            _.forEach(data, e => {
                if (e.value === true)
                    newLists.push({ "key": e.key })
            })

            //Check the selected strategies
            if (newLists.length <= 0)
                selectedStrat = "No Strategy"
            else if (newLists.length === 1) {
                let { filters } = this.props.strategiesFilter
                if (typeof filters !== 'undefined' && filters !== null && filters !== "") {

                    let id = null
                    _.forEach(data, e => {
                        if (e.value === true)
                            id = e.key
                    })

                    const findInfo = _.find(filters, e => e.key === id)
                    if (typeof findInfo !== 'undefined' && findInfo !== null)
                        selectedStrat = findInfo.value
                }

            } else if (newLists.length < strategyFilterCount)
                selectedStrat = `(${newLists.length}) Strategies`
            else if (newLists.length === strategyFilterCount)
                selectedStrat = "All Strategies"

            //Update the state
            onSetNewState(this, {
                isStrategyChange: false,
                allStrategies: newLists,
                selectedStrategy: selectedStrat
            })

            // Map the response
            const requestData = {
                "periodRange": periodRange,
                "startDate": startDate,
                "endDate": endDate,
                "strategies": newLists
            }

            //Request new statistics data
            dispatch(statisticActions.getStatistics(requestData))
        }
    }

    onPeriodChangeHandler = (data, selectedPeriod) => {
        if (this.mounted) {
            const { dispatch } = this.props
            const { allStrategies } = this.state

            const requestData = {
                "periodRange": data.periodRange,
                "startDate": data.startDate,
                "endDate": data.endDate,
                "strategies": allStrategies
            }

            onSetNewState(this, {
                selectedPeriod: selectedPeriod,
                periodRange: data.periodRange,
                startDate: data.startDate,
                endDate: data.endDate
            })

            dispatch(statisticActions.getStatistics(requestData))
        }
    }

    render() {
        return (
            <StatisticView
                self={this}
                state={this.state}
                onStrategyChange={this.onStrategyChangeHandler}
                onStrategySubmit={this.onStrategySubmitHandler}
                onPeriodChange={this.onPeriodChangeHandler}
                {...this.props}
            />
        )
    }

}

function mapStateToProps(state) {
    const { authentication, statistics, strategiesFilter, periodRangeFilter } = state
    return {
        authentication,
        statistics,
        strategiesFilter,
        periodRangeFilter
    }
}
const connectedStatisticContainer = connect(mapStateToProps)(StatisticContainer)
export { connectedStatisticContainer as StatisticContainer }