import { walletActionTypes } from '../CONSTANTS'

export function balanceWallet(state = {}, action) {
    switch (action.type) {
        case walletActionTypes.BALANCE_WALLET_REQUEST:
            return {
                ...state,
                loader: true
            }
        case walletActionTypes.BALANCE_WALLET_SUCCESS:
            return {
                ...state,
                loader: false,
                item: action.balance_wallet
            }
        case walletActionTypes.BALANCE_WALLET_FAILURE:
            return {
                ...state,
                loader: false,
                error: action.error
            }
        case walletActionTypes.BALANCE_WALLET_UPDATE:
            return {
                ...state,
                loader: false,
                item: action.balance_wallet
            }
        case walletActionTypes.CLEAR_BALANCE_WALLET:
            return {
                ...state,
                loader: false,
                item: null
            }
        default:
            return state
    }
}

export function getBinance(state = {}, action) {
    switch (action.type) {
        case walletActionTypes.GET_BINANCE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case walletActionTypes.GET_BINANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.binance_data
            }
        case walletActionTypes.GET_BINANCE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function getBinanceFutures(state = {}, action) {
    switch (action.type) {
        case walletActionTypes.GET_BINANCE_FUTURE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case walletActionTypes.GET_BINANCE_FUTURE_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.binance_data
            }
        case walletActionTypes.GET_BINANCE_FUTURE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function cryptoWallet(state = {}, action) {
    switch (action.type) {
        case walletActionTypes.GET_ALL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case walletActionTypes.GET_ALL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.crypto_wallet
            }
        case walletActionTypes.GET_ALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case walletActionTypes.WALLET_UPDATE:
            return {
                ...state,
                loading: false,
                items: action.crypto_wallet
            }
        case walletActionTypes.CLEAR_WALLET_SIDEBAR:
            return {
                ...state,
                loading: false,
                items: null
            }
        default:
            return state
    }
}

export function futureWallet(state = {}, action) {
    switch (action.type) {
        case walletActionTypes.GET_ALL_FUTURE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case walletActionTypes.GET_ALL_FUTURE_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.future_wallet
            }
        case walletActionTypes.GET_ALL_FUTURE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case walletActionTypes.FUTURE_WALLET_UPDATE:
            return {
                ...state,
                loading: false,
                items: action.future_wallet
            }
        case walletActionTypes.CLEAR_WALLET_SIDEBAR:
            return {
                ...state,
                loading: false,
                items: null
            }
        default:
            return state
    }
}

export function quickTrade(state = {}, action) {
    switch (action.type) {
        case walletActionTypes.CREATE_MARKET_REQUEST:
            return {
                ...state,
                loading: true
            }
        case walletActionTypes.CREATE_MARKET_SUCCESS:
            return {
                ...state,
                loading: false,
                quick_trade: action.response
            }
        case walletActionTypes.CREATE_MARKET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

