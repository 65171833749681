import { Component } from 'react'
import { connect } from 'react-redux'
import createDecorator from 'final-form-focus'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import jQuery from 'jquery'
import queryString from 'query-string'
import { userActions } from '../../redux/actions'
import { ResetPasswordView } from './ResetPasswordView'
import { isNotNull, addPageViewEvent, seo } from '../../utils'

class ResetPasswordContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            password: {
                type: "password",
                eyeIcon: faEye,
            },
            confirmPassword: {
                type: "password",
                eyeIcon: faEye
            },
            email: null,
            isSubmitted: false
        }

        this.mounted = false
        this.baseState = this.state
        this.decorators = createDecorator()
        this.initialPasswordHelperState = {
            letter: false,
            capital: false,
            number: false,
            special: false,
            size: false,
            match: false
        }
    }

    componentDidMount() {
        this.mounted = true

        const val = queryString.parse(this.props.location.search)
        let email = val.email
        this.setState({ email: email })

        jQuery('body').css('height', '100%')

        seo({
            title: "Reset Password",
            metaDescription: ""
        })
        addPageViewEvent()
    }

    componentDidUpdate(prevProps) {
        if (this.mounted === true) {
            const { history, forms } = this.props
            if (prevProps.forms !== forms) {
                let { loading, response } = forms
                if (!loading && isNotNull(response) && isNotNull(response.success) && response.success)
                    history.push('/account/reset-password-confirm')
            }
        }
    }

    componentWillUnmount() {
        this.setState(this.baseState)
        this.mounted = false
    }

    onSubmitResetPasswordHandler = (values) => {
        const { password, confirmPassword } = values
        const val = queryString.parse(this.props.location.search)
        const emailAddress = val.email
        const codeParams = val.code
        const code = codeParams.replace(/ /g, "+")

        if (password && confirmPassword) {
            const { dispatch } = this.props
            const newData = {
                emailAddress,
                password,
                confirmPassword,
                code
            }
            dispatch(userActions.resetPassword(newData))
        }
    }

    render() {
        return (
            <ResetPasswordView
                self={this}
                state={this.state}
                decorators={this.decorators}
                initialPasswordHelperState={this.initialPasswordHelperState}
                submit={this.onSubmitResetPasswordHandler}
            />
        )
    }
}


function mapStateToProps(state) {
    let { forms } = state
    return {
        forms
    }
}

const connectedResetPasswordContainer = connect(mapStateToProps)(ResetPasswordContainer)
export { connectedResetPasswordContainer as ResetPasswordContainer }
