import React from 'react'

export class AdvancedForm extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <>Advanced form</>
        )
    }
}