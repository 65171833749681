import { combineReducers } from 'redux'

import { authReducer, emailVerification, resendEmailVerification } from './authReducer'

import { 
    onboardingUser, 
    onboardingUserProfile, 
    onboardingUserTradingExpDetails, 
    onboardingUserFinancialDetails, 
    userWalletBalances,
    saveOnboardingUserProfile,
    saveOnboardingUserTradingExpDetails,
    saveOnboardingUserFinancialDetails,
    saveExchangeKeys,
    configureExchangeIntegration,
    configureTradingBalance,
    setTradingBalance,
    setToComplete,
    skipOnboarding } from './onboardingReducer'

import { onetimeOffer } from './offerReducer'

import { leads } from './leadReducer'

import { userTheme, setThemeSettings } from './themeReducer'

import { signalStates, signals, followedSignals, followSignal, signalsNew } from './signalReducer'

import { signalDetails } from './signalDetailsReducer'

import {
    notificationLists, notifications, saveNotificationTypeChanges,
    saveNotificationSettings, markedNotification, notificationPreviews
} from './notificationReducer'

import { showSidebar } from './sidebarReducer'

import { tradings, tradeDetails, saveAutoCloseTradeSettings } from './tradingReducer'

import { tradeOrder, tradeOrderLeverage, leverageSettings } from './tradeOrderReducer'

import { netPeakGains, monthlyNetPeakGains, topTradeWinners, statistics, strategiesFilter, periodRangeFilter } from './statisticReducer'

import {
    priceActions, watchLists,
    saveWatchlist, tradeGuide, relativeStrengthAllItems
} from './scannerReducer'

import { balanceWallet, cryptoWallet, futureWallet, getBinance, getBinanceFutures, quickTrade } from './walletReducer'

import { orderHistory, openOrder, openOrderDetails, orderResult, cancelOrder, exitOrder, forceExitOrder, forceCancelOrder, futuresOrderHistory, futuresOpenOrder, orderPositions, closeOrderPosition } from './orderReducer'

import { puid, userProfile, profileCompletion, saveProfile, user, userAgreement } from './userReducer'

import { forms } from './formReducer'

import { subscriptionDetails, cancelSubscription } from './subscriptionReducer'

import { plan, trialPlan, sixMonthPlan } from './planReducer'

import { userDevice } from './deviceReducer'

import { announcements } from './announcementReducer'

import { confirmEmail } from './confirmEmailReducer'

import { checkList, saveCheckList, checkListItems, checklistStatus } from './checkListsReducer'

import { forgotPass } from './forgotPassReducer'

import { cancellationSurvey } from './surveyReducer'

import { messageBoardItemList, newSetOfMessages, msgHeaderPhoto, saveMessageBoardItem, editMessageBoardItem } from './messagesReducer'

import { messageTypes, audienceTypes } from './typesReducer'

import { tradeDocSession, saveTradeDocSession } from './tradeDoctorSessionReducer'

import { reminder, saveReminder } from './reminderReducer'

const rootReducer = combineReducers({
    authentication: authReducer,
    puid,
    onboardingUser,
    onboardingUserProfile, 
    onboardingUserTradingExpDetails,
    onboardingUserFinancialDetails,
    userWalletBalances,
    saveOnboardingUserProfile,
    saveOnboardingUserTradingExpDetails, 
    saveOnboardingUserFinancialDetails,
    configureExchangeIntegration, 
    configureTradingBalance,
    setTradingBalance,
    setToComplete,
    saveExchangeKeys,
    skipOnboarding,
    emailVerification,
    resendEmailVerification,
    onetimeOffer,
    leads,
    userTheme,
    setThemeSettings,
    signalStates,
    signals,
    signalsNew,
    signalDetails,
    followedSignals,
    followSignal,
    notificationLists,
    markedNotification,
    notificationPreviews,
    notifications,
    saveNotificationTypeChanges,
    saveNotificationSettings,
    showSidebar,
    tradings,
    tradeOrder,
    tradeOrderLeverage,
    leverageSettings,
    tradeDetails,
    saveAutoCloseTradeSettings,
    netPeakGains,
    monthlyNetPeakGains,
    topTradeWinners,
    statistics,
    strategiesFilter,
    periodRangeFilter,
    priceActions,
    watchLists,
    saveWatchlist,
    tradeGuide,
    relativeStrengthAllItems,
    balanceWallet,
    cryptoWallet,
    futureWallet,
    quickTrade,
    getBinance,
    getBinanceFutures,
    orderHistory,
    openOrder,
    openOrderDetails,
    cancelOrder,
    saveProfile,
    forms,
    subscriptionDetails,
    cancelSubscription,
    plan,
    trialPlan,
    sixMonthPlan,
    user,
    userProfile,
    profileCompletion,
    userAgreement,
    confirmEmail,
    userDevice,
    announcements,
    forgotPass,
    checkList,
    saveCheckList,
    checkListItems,
    checklistStatus,
    exitOrder,
    forceExitOrder,
    forceCancelOrder,
    orderResult,
    futuresOrderHistory,
    futuresOpenOrder,
    orderPositions,
    closeOrderPosition,
    cancellationSurvey,
    messageBoardItemList,
    newSetOfMessages,
    msgHeaderPhoto,
    saveMessageBoardItem,
    editMessageBoardItem,
    messageTypes,
    audienceTypes,
    tradeDocSession,
    saveTradeDocSession,
    reminder,
    saveReminder
})

export default rootReducer
