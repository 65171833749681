export const listOfOccupation = [{
    groupName: 'Healthcare Practitioners and Technical Occupations',
    groupItems: [{
        id: 1,
        name: 'Chiropractor'
    }, {
        id: 2,
        name: 'Dentist'
    }, {
        id: 3,
        name: 'Dietitian or Nutritionist'
    }, {
        id: 4,
        name: 'Optometrist'
    }, {
        id: 5,
        name: 'Pharmacist'
    }, {
        id: 6,
        name: 'Physician'
    }, {
        id: 7,
        name: 'Physician Assistant'
    }, {
        id: 8,
        name: 'Podiatrist'
    }, {
        id: 9,
        name: 'Registered Nurse'
    }, {
        id: 10,
        name: 'Therapist'
    }, {
        id: 11,
        name: 'Veterinarian'
    }, {
        id: 12,
        name: 'Health Technologist or Technician'
    }, {
        id: 13,
        name: 'Other Healthcare Practitioners and Technical Occupation'
    }]
}, {
    groupName: 'Healthcare Support Occupations',
    groupItems: [{
        id: 14,
        name: 'Nursing, Psychiatric, or Home Health Aide'
    }, {
        id: 15,
        name: 'Occupational and Physical Therapist Assistant or Aide'
    }, {
        id: 16,
        name: 'Other Healthcare Support Occupation'
    }]
}, {
    groupName: 'Business, Executive, Management, and Financial Occupations',
    groupItems: [{
        id: 17,
        name: 'Chief Executive'
    }, {
        id: 18,
        name: 'General and Operations Manager'
    }, {
        id: 19,
        name: 'Advertising, Marketing, Promotions, Public Relations, and Sales Manager'
    }, {
        id: 20,
        name: 'Operations Specialties Manager (e.g., IT or HR Manager)'
    }, {
        id: 21,
        name: 'Construction Manager'
    }, {
        id: 22,
        name: 'Engineering Manager'
    }, {
        id: 23,
        name: 'Accountant, Auditor'
    }, {
        id: 24,
        name: 'Business Operations or Financial Specialist'
    }, {
        id: 25,
        name: 'Business Owner'
    }, {
        id: 26,
        name: 'Other Business, Executive, Management, Financial Occupation'
    }]
}, {
    groupName: 'Architecture and Engineering Occupations',
    groupItems: [{
        id: 27,
        name: 'Architect, Surveyor, or Cartographer'
    }, {
        id: 28,
        name: 'Engineer'
    }, {
        id: 29,
        name: 'Other Architecture and Engineering Occupation'
    }]
}, {
    groupName: 'Education, Training, and Library Occupations',
    groupItems: [{
        id: 30,
        name: 'Postsecondary Teacher (e.g., College Professor)'
    }, {
        id: 31,
        name: 'Primary, Secondary, or Special Education School Teacher'
    }, {
        id: 32,
        name: 'Other Teacher or Instructor'
    }, {
        id: 33,
        name: 'Other Education, Training, and Library Occupation'
    }]
}, {
    groupName: 'Other Professional Occupations',
    groupItems: [{
        id: 34,
        name: 'Arts, Design, Entertainment, Sports, and Media Occupations'
    }, {
        id: 35,
        name: 'Computer Specialist, Mathematical Science'
    }, {
        id: 36,
        name: 'Counselor, Social Worker, or Other Community and Social Service Specialist'
    }, {
        id: 37,
        name: 'Lawyer, Judge'
    }, {
        id: 38,
        name: 'Life Scientist (e.g., Animal, Food, Soil, or Biological Scientist, Zoologist)'
    }, {
        id: 39,
        name: 'Physical Scientist (e.g., Astronomer, Physicist, Chemist, Hydrologist)'
    }, {
        id: 40,
        name: 'Religious Worker (e.g., Clergy, Director of Religious Activities or Education)'
    }, {
        id: 41,
        name: 'Social Scientist and Related Worker'
    }, {
        id: 42,
        name: 'Other Professional Occupation'
    }]
}, {
    groupName: 'Office and Administrative Support Occupations',
    groupItems: [{
        id: 43,
        name: 'Supervisor of Administrative Support Workers'
    }, {
        id: 44,
        name: 'Financial Clerk'
    }, {
        id: 45,
        name: 'Secretary or Administrative Assistant'
    }, {
        id: 46,
        name: 'Material Recording, Scheduling, and Dispatching Worker'
    }, {
        id: 47,
        name: 'Other Office and Administrative Support Occupation'
    }]
}, {
    groupName: 'Services Occupations',
    groupItems: [{
        id: 48,
        name: 'Protective Service (e.g., Fire Fighting, Police Officer, Correctional Officer)'
    }, {
        id: 49,
        name: 'Chef or Head Cook'
    }, {
        id: 50,
        name: 'Cook or Food Preparation Worker'
    }, {
        id: 51,
        name: 'Food and Beverage Serving Worker (e.g., Bartender, Waiter, Waitress)'
    }, {
        id: 52,
        name: 'Building and Grounds Cleaning and Maintenance'
    }, {
        id: 53,
        name: 'Personal Care and Service (e.g., Hairdresser, Flight Attendant, Concierge)'
    }, {
        id: 54,
        name: 'Sales Supervisor, Retail Sales'
    }, {
        id: 55,
        name: 'Retail Sales Worker'
    }, {
        id: 56,
        name: 'Insurance Sales Agent'
    }, {
        id: 57,
        name: 'Sales Representative'
    }, {
        id: 58,
        name: 'Real Estate Sales Agent'
    }, {
        id: 59,
        name: 'Other Services Occupation'
    }]
}, {
    groupName: 'Agricultural, Maintenance, Repair, and Skilled Crafts Occupations',
    groupItems: [{
        id: 60,
        name: 'Construction and Extraction (e.g., Construction Laborer, Electrician)'
    }, {
        id: 61,
        name: 'Farming, Fishing, and Forestry'
    }, {
        id: 62,
        name: 'Installation, Maintenance, and Repair'
    }, {
        id: 63,
        name: 'Production Occupations'
    }, {
        id: 64,
        name: 'Other Agriculture, Maintenance, Repair, and Skilled Crafts Occupation'
    }]
}, {
    groupName: 'Transportation Occupations',
    groupItems: [{
        id: 65,
        name: 'Aircraft Pilot or Flight Engineer'
    }, {
        id: 66,
        name: 'Motor Vehicle Operator (e.g., Ambulance, Bus, Taxi, or Truck Driver)'
    }, {
        id: 67,
        name: 'Other Transportation Occupation'
    }]
}, {
    groupName: 'Other Occupations',
    groupItems: [{
        id: 68,
        name: 'Military'
    }, {
        id: 69,
        name: 'Homemaker'
    }, {
        id: 70,
        name: 'Other Occupation'
    }]
}]