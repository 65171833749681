import { signalStatesActionTypes, signalActionTypes, followedSignalActionTypes } from '../CONSTANTS'

export function signalStates(state = {}, action) {
    switch (action.type) {
        case signalStatesActionTypes.GET_STATES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case signalStatesActionTypes.GET_STATES_SUCCESS:
            return {
                ...state,
                loading: false,
                states: action.states
            }
        case signalStatesActionTypes.GET_STATES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function signals(state = {}, action) {
    switch (action.type) {
        case signalActionTypes.GET_SIGNALS_REQUEST:
            return {
                ...state,
                loading: true,
                signals: null
            }
        case signalActionTypes.GET_SIGNALS_SUCCESS:
            return {
                ...state,
                loading: false,
                signals: action.signals
            }
        case signalActionTypes.GET_SIGNALS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case signalActionTypes.UPDATE_SIGNAL:
            return {
                ...state,
                loading: false,
                signals: action.signals
            }
        case signalActionTypes.UPDATE_SIGNAL_STATE:
            return {
                ...state,
                loading: false,
                signals: action.signals
            }
        case signalActionTypes.RESET:
            return {
                ...state,
                loading: true,
                signals: null
            }
        case signalActionTypes.CLEAR_SIGNALS:
            return {
                ...state,
                loading: true,
                signals: null
            }
        default:
            return state
    }
}

export function signalsNew(state = {}, action) {
    switch (action.type) {
        case signalActionTypes.GET_NEW_SIGNALS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case signalActionTypes.GET_NEW_SIGNALS_SUCCESS:
            return {
                ...state,
                loading: false,
                signals: action.signals
            }
        case signalActionTypes.GET_NEW_SIGNALS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function followedSignals(state = {}, action) {
    switch (action.type) {
        case followedSignalActionTypes.GET_FOLLOWED_SIGNALS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case followedSignalActionTypes.GET_FOLLOWED_SIGNALS_SUCCESS:
            return {
                ...state,
                loading: false,
                followedSignals: action.followedSignals
            }
        case followedSignalActionTypes.GET_FOLLOWED_SIGNALS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function followSignal(state = {}, action) {
    switch (action.type) {
        case followedSignalActionTypes.FOLLOW_SIGNAL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case followedSignalActionTypes.FOLLOW_SIGNAL_SUCCESS:
            return {
                ...state,
                loading: false,
                isFollowed: action.response.success
            }
        case followedSignalActionTypes.FOLLOW_SIGNAL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}