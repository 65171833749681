import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { isNotNull } from '../../utils'
import { ReferralWrapper } from './styled_components'

class ViralLoops extends PureComponent {

    componentDidMount() {
        window.VL.load(this.props.publicToken, { autoLoadWidgets: !0 })
    }

    render() {
        const { formWidget, rewardWidget } = this.props

        return (
            <ReferralWrapper>
                {isNotNull(formWidget) && formWidget
                    ? <div data-vl-widget="embedForm" />
                    : ''
                }
                {isNotNull(rewardWidget) && rewardWidget
                    ? <div data-vl-widget="rewardStats" />
                    : ''
                }
            </ReferralWrapper>
        )
    }
}

export default ViralLoops

ViralLoops.propTypes = {
    publicToken: PropTypes.string.isRequired,
    formWidget: PropTypes.bool,
    rewardWidget: PropTypes.bool
}
