import { SecureYourFutureSectionStyle } from './styles'

export const SecureYourFutureSection = () => {
    return (
        <SecureYourFutureSectionStyle>
            <div className='container'>
                <div className='text-with-img'>
                    <div className='text'>
                        <h2 className='section-title'>It's Not Too Late To Secure Your Future</h2>
                        <p>Imagine you wake up in the not so distant future.</p>
                        <p>Fiat economies are collapsing, people are angry and finally ready to swallow a new monetary solution. Bitcoin seemed to complicated for them before, but now that they see the other choice is CBDC slavery, they are ready to make the change.</p>
                        <p>With the power of your ProfitFarmers PRO plan you've been patiently and systematically gorging on the crypto pumps that came up every couple of months, and by learning how to invest into altcoins.</p>
                        <p>You're so far ahead of the crowd that you barely even noticed that Bitcoin just reached $300,000.</p>
                    </div>
                    <div className='img'>
                        <img src='/images/layout/securing-future.png' alt='Crushing Crypto with ProfitFarmers' />
                    </div>
                </div>
                
                <div className='text-with-img reverse'>
                    <div className='img'>
                        <img src='/images/layout/dystopia-is-here.png' alt='' />
                    </div>
                    <div className='text'>
                        <p>Your friends keep asking you how you're still making money when all the 'normal' jobs have disappeared. You shrug your shoulders and tell them, for the 50th time, "I just focused on learning how to trade crypto and looked at the big picture".</p>
                        <p>You wave at them as they run off to their newest, most hated job - being an NPC in the metaverse.</p>
                        <p>"What shall I do today", you ask yourself. It's not easy to find the answer. The sky's the limit for the man ahead of the curve, after all.</p>
                    </div>
                </div>
            </div>
        </SecureYourFutureSectionStyle>
    )
}