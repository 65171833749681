import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Carousel, SafeAnchor } from 'react-bootstrap'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import CircularProgress  from '@mui/material/CircularProgress'
import _ from 'lodash'
import { TileWrapper } from '../../styles'
import { statisticActions, walletActions, userActions, onboardingActions } from '../../redux/actions' 
import { walletActionTypes } from '../../redux/CONSTANTS'
import { mediaDeviceSizes } from '../../constants/mediaQueries'
import { getSize, isNull, isNotNull, onSetNewState, getCoinName, toBtcFormat } from '../../utils'

class CryptoWallet extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            show: null,
            profileProgress: null,
            hasInitBalances: false,
            btc: 0,
            usdt: 0,
            busd: 0,
            btcValue: 0,
            btcAlmostEqualValue: 0,
            binanceData: [],
            channel: 'wss://stream.binance.com:9443/ws/!ticker@arr'
        }

        this.mounted = false
        this.wsBinance24hrTicker = null
        this.deviceScreenSize = getSize(window.innerWidth)
    }

    componentDidMount() {
        this.mounted = true
        const { ...state } = this.state
        const { dispatch, userWalletBalances } = this.props

        dispatch(userActions.getProfileCompletionStatus())

        if (isNull(userWalletBalances.loading)) {
            dispatch(onboardingActions.getTradingBalances())     
            dispatch(walletActions.getBalance())
        }       

        if (!state.hasInitBalances && isNotNull(userWalletBalances.loading)) {
            const { wallet_balances } = userWalletBalances
            if (isNotNull(wallet_balances)) {
                const { walletBalances } = wallet_balances

                if (isNotNull(walletBalances)) {
                    const { spotWallet } = walletBalances
                    if (isNotNull(spotWallet)) {
                        const { success, data } = spotWallet
                        if (success && isNotNull(data)) {
                            let btcBalance = 0
                            let btcWallet = _.find(data, i => i.name === 'BTC')
                            if (isNotNull(btcWallet)) {
                                btcBalance = _.round(parseFloat(btcWallet.balance.free), 8)
                            }
                            
                            let usdtBalance = 0
                            let usdtWallet = _.find(data, i => i.name === 'USDT')
                            if (isNotNull(usdtWallet)) {
                                if (parseFloat(usdtWallet.balance.free) > 1) {
                                    usdtBalance = _.round(parseFloat(usdtWallet.balance.free), 2)                                            
                                    usdtBalance = usdtBalance.toFixed(2)
                                }
                                else
                                    usdtBalance = parseFloat(usdtWallet.balance.free).toFixed(8)
                            }

                            let busdBalance = 0
                            let busdWallet = _.find(data, i => i.name === 'BUSD')
                            if (isNotNull(busdWallet)) {
                                busdBalance = _.round(parseFloat(busdWallet.balance.free), 5)
                            }

                            onSetNewState(this, {
                                btc: btcBalance,
                                usdt: usdtBalance,
                                busd: busdBalance
                            })
                        }
                    }

                    onSetNewState(this, {
                        hasInitBalances: true
                    })
                }
            }
        }
        
        this.initWallet()

        // statistics data request
        // dispatch(statisticActions.getStrategies())
        // dispatch(statisticActions.getPeriodRange())
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { profileProgress, hasInitBalances } = this.state
            const { profileCompletion, userWalletBalances } = this.props

            if (isNotNull(profileCompletion) && 
                !profileCompletion.loading && 
                isNull(profileProgress)) {
                    const { progress } = profileCompletion
                    if (isNotNull(progress)) {
                        onSetNewState(this, { 
                            show: parseInt(progress) < 100,
                            profileProgress: progress,
                        })
                    }
            }

            if (isNotNull(profileProgress) && 
                isNotNull(profileCompletion) && 
                !profileCompletion.loading && 
                prevProps.profileCompletion !== profileCompletion) {                    
                    const { progress } = profileCompletion
                    if (isNotNull(progress)) {
                        onSetNewState(this, { 
                            show: parseInt(progress) < 100,
                            profileProgress: progress,
                        })
                    }
                }

            if (!hasInitBalances && 
                isNotNull(userWalletBalances) && 
                prevProps.userWalletBalances !== userWalletBalances && 
                !userWalletBalances.loading) {
                    const { wallet_balances } = userWalletBalances
                    if (isNotNull(wallet_balances)) {
                        const { walletBalances } = wallet_balances

                        if (isNotNull(walletBalances)) {
                            const { spotWallet, futuresWallet } = walletBalances
                            if (isNotNull(spotWallet)) {
                                const { success, data } = spotWallet
                                if (success && isNotNull(data)) {
                                    let btcBalance = 0
                                    let btcWallet = _.find(data, i => i.name === 'BTC')
                                    if (isNotNull(btcWallet)) {
                                        btcBalance = _.round(parseFloat(btcWallet.balance.free), 8)
                                    }
                                    
                                    let usdtBalance = 0
                                    let usdtWallet = _.find(data, i => i.name === 'USDT')
                                    if (isNotNull(usdtWallet)) {
                                        if (parseFloat(usdtWallet.balance.free) > 1) {
                                            usdtBalance = _.round(parseFloat(usdtWallet.balance.free), 2)                                            
                                            usdtBalance = usdtBalance.toFixed(2)
                                        }
                                        else
                                            usdtBalance = parseFloat(usdtWallet.balance.free).toFixed(8)
                                    }

                                    let busdBalance = 0
                                    let busdWallet = _.find(data, i => i.name === 'BUSD')
                                    if (isNotNull(busdWallet)) {
                                        busdBalance = _.round(parseFloat(busdWallet.balance.free), 5)
                                    }
    
                                    onSetNewState(this, {
                                        btc: btcBalance,
                                        usdt: usdtBalance,
                                        busd: busdBalance
                                    })
                                }
                            }

                            onSetNewState(this, {
                                hasInitBalances: true
                            })
                        }
                    }
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false

        if (this.wsBinance24hrTicker !== null) {
            this.wsBinance24hrTicker.close()
        }
    }

    initWallet = () => {
        const { channel } = this.state
        this.wsBinance24hrTicker = new W3CWebSocket(channel)

        this.wsBinance24hrTicker.onopen = () => {
            // console.log('WebSocket Wallet Page Client Connected')
        }
        this.wsBinance24hrTicker.onclose = () => {
            this.wsBinance24hrTicker = new W3CWebSocket(channel)
        }
        this.wsBinance24hrTicker.onerror = () => {
            this.wsBinance24hrTicker = new W3CWebSocket(channel)
        }

        this.wsBinance24hrTicker.onmessage = (response) => {
            let { data } = response
            const { balanceWallet, dispatch } = this.props
            if (isNotNull(data)) {
                data = JSON.parse(data)

                if (isNotNull(balanceWallet) &&
                    isNotNull(balanceWallet.item) &&
                    isNotNull(balanceWallet.item.data)) {

                    // extract BTCUSDT info from websockets response data
                    const btcUsdtInfo = _.find(data, c => c.s.toUpperCase() === 'BTCUSDT')

                    // extract user's wallets
                    const wallets = [...balanceWallet.item.data]
                    _.forEach(wallets, wallet => {
                        // set the full name of the coin
                        wallet.coinName = getCoinName(wallet.name)

                        if (wallet.balance.free <= 0) {
                            // wallet has no balance
                            wallet.balance.usdt = 0
                            wallet.balance.btc = 0.00
                        } else { // wallet balance is greater than 0

                            // set default values for BTC and USDT
                            if (wallet.name === "BTC") {
                                wallet.balance.btc = 0.00
                                if (isNotNull(btcUsdtInfo) && isNotNull(btcUsdtInfo.c))
                                    wallet.balance.usdt = parseFloat(wallet.balance.free) * parseFloat(btcUsdtInfo.c)
                            }
                            else if (wallet.name === "USDT") {
                                if (isNotNull(btcUsdtInfo) && isNotNull(btcUsdtInfo.c))
                                    wallet.balance.btc = parseFloat(wallet.balance.free) / parseFloat(btcUsdtInfo.c)
                                wallet.balance.usdt = wallet.balance.free
                            }
                            else {
                                let coinName = wallet.name

                                let btcSymbolInfo = _.find(data, t => t.s.toUpperCase() === `${coinName}BTC`)
                                if (isNull(btcSymbolInfo)) {
                                    const binanceData = _.find(this.state.binanceData, q => q.symbol === `${coinName}BTC`)
                                    if (isNotNull(binanceData) && isNotNull(binanceData.lastPrice) && binanceData.lastPrice > 0)
                                        btcSymbolInfo = { c: binanceData.lastPrice }
                                }

                                // set calculated btc value
                                wallet.balance.btc = (isNotNull(btcSymbolInfo))
                                    ? parseFloat(wallet.balance.free) * parseFloat(btcSymbolInfo.c)
                                    : 0.00

                                let usdtSymbolInfo = _.find(data, t => t.s.toUpperCase() === `${coinName}USDT`)
                                if (isNull(usdtSymbolInfo)) {
                                    if (isNotNull(btcSymbolInfo)) {
                                        if (isNotNull(btcSymbolInfo.c) && isNotNull(btcUsdtInfo) && isNotNull(btcUsdtInfo.c))
                                            // product of symbol's lastPrice and BTCUSDT lastPrice
                                            usdtSymbolInfo = { c: parseFloat(btcSymbolInfo.c) * parseFloat(btcUsdtInfo.c) }

                                    } else {
                                        const binanceData = _.find(this.state.binanceData, q => q.symbol === `${coinName}USDT`)
                                        if (isNotNull(binanceData))
                                            usdtSymbolInfo = { c: binanceData.lastPrice }
                                    }
                                }

                                // set calculated usdt value
                                wallet.balance.usdt = (isNotNull(usdtSymbolInfo))
                                    ? parseFloat(wallet.balance.free) * parseFloat(usdtSymbolInfo.c)
                                    : 0
                            }
                        }
                    })

                    let totalUsd = _.sumBy(wallets, b => parseFloat(b.balance.usdt))
                    let balance_wallet = {
                        loading: false,
                        success: true,
                        msg: '',
                        data: wallets,
                        totalBtc: _.round(parseFloat(totalUsd) / parseFloat(btcUsdtInfo.c), 8),
                        totalUsdt: parseFloat(_.round(parseFloat(totalUsd) / parseFloat(btcUsdtInfo.c), 8)) * parseFloat(btcUsdtInfo.c)
                    }

                    let computedValue = _.round(toBtcFormat(balance_wallet.totalUsdt), 2).toFixed(2)

                    onSetNewState(this, {
                        btcValue: balance_wallet.totalBtc,
                        btcAlmostEqualValue: computedValue,
                        loading: true,
                    })
                    dispatch({ type: walletActionTypes.BALANCE_WALLET_UPDATE, balance_wallet })
                }
            }
        }
    }

    render() {
        const { onClickCompleteMyProfile } = this.props
        const { show, profileProgress, btc, usdt, busd, btcValue, btcAlmostEqualValue } = this.state

        return (
            <TileWrapper type="profile-completion" className={ show ? 'profile-completion' : 'crypto-wallet'}>
                { show && 
                    <>
                        <div className='kyc-progress-wrapper'>
                            {/* <div className='kyc-progress'>
                                <CircularProgress className='circular-progress' variant="determinate" value={profileProgress} thickness={2} />
                                <label>{ isNotNull(profileProgress) && `${parseInt(profileProgress)}%`}</label>
                            </div> */}
                            <div className='kyc-text'>
                                <h5>Account Setup Incomplete</h5>
                                <p>Connect your Binance account to unlock all features</p>
                            </div>
                        </div>
                        <button type="button" onClick={() => onClickCompleteMyProfile()}>
                            <span className='text'>Complete My Profile</span>
                        </button>
                    </>
                }
                
                { !show && 
                    <>
                        <SafeAnchor href='/wallet/balance'>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </SafeAnchor>

                        <h5>
                            <span>Your Crypto Wallet <br /><small>Est. value: {btcValue} BTC = ${btcAlmostEqualValue}</small></span>
                        </h5>
                        
                        <ul className='results'>
                            <li className='usdt'>
                                <label>
                                    <span className='symbol-logo'>
                                        <img src='https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/usdt.png' alt='BTC logo' />
                                    </span>
                                    <span>USDT</span>
                                </label>
                                <span>{ isNotNull(usdt) && parseFloat(usdt).toFixed(2) }</span>
                            </li>
                            <li className='btc'>
                                <label>
                                    <span className='symbol-logo'>
                                        <img src='https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/btc.png' alt='BTC logo' />
                                    </span>
                                    <span>BTC</span>
                                </label>
                                <span>{ isNotNull(btc) && parseFloat(btc).toFixed(8)}</span>
                            </li>
                            <li className='busd'>
                                <label>
                                    <span className='symbol-logo'>
                                        <img src='https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/busd.png' alt='BTC logo' />
                                    </span>
                                    <span>BUSD</span>
                                </label>
                                <span>
                                    { isNotNull(busd) && 
                                        <>
                                            { parseFloat(busd) > 1 && <>{parseFloat(busd).toFixed(2)}</>}
                                            { parseFloat(busd) < 1 && <>{parseFloat(busd).toFixed(5)}</>}
                                        </>
                                    }
                                </span>
                            </li>
                        </ul>
                    </>
                }
            </TileWrapper>
        )
    }
}

function mapStateToProps(state) {
    const { profileCompletion, balanceWallet, userWalletBalances } = state
    return {
        profileCompletion,
        balanceWallet,
        userWalletBalances
        // statistics,
        // strategiesFilter,
        // periodRangeFilter
    }
}

const connectedCryptoWallet = connect(mapStateToProps)(CryptoWallet)
export { connectedCryptoWallet as CryptoWallet }