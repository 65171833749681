const onboardingScreen = {
    BASIC_INFO: 'BASIC_INFO',
    TRADING_EXP: 'TRADING_EXP',
    FINANCIAL_DETAILS: 'FINANCIAL_DETAILS',
    LOGIN_BINANCE_ACCOUNT: 'LOGIN_BINANCE_ACCOUNT',
    BINANCE_API_SETTINGS: 'BINANCE_API_SETTINGS',
    GENERATE_EXCHANGE_API: 'GENERATE_EXCHANGE_API',
    SECURITY_VERIFICATION: 'SECURITY_VERIFICATION',
    ENTER_EXCHANGE_KEYS: 'ENTER_EXCHANGE_KEYS',
    API_RESTRICTION_SETTINGS: 'API_RESTRICTION_SETTINGS',
    ADJUST_TRADING_BALANCE: 'ADJUST_TRADING_BALANCE',
    COMPLETE: 'COMPLETE'
}

export {
    onboardingScreen
}