import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const TechnoTigerSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    padding: 40px 0 130px;
    background-color: #060B0F;

    .container {
        .text-with-img-wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 25px;
            
            .text-col {
                width: 100%;

                .section-title {
                    color: #fff;
                    text-align: center;
                    font-size: 28px;
                    margin-bottom: 25px;

                    > small {
                        font-weight: 500;
                    }

                    > span {
                        font-size: 25px;
                        max-width: 100%;
                    }
                }

                p {
                    color: #fff;
                    font-size: 16px;
                }
            }

            .img-wrapper {
                width: 100%;

                img {
                    width: 100%;
                }
            }
        }

        .tigers-vs-pro-traders {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            background: transparent linear-gradient(-90deg,#0c3326 0%,#060b0f 100%) 0% 0% no-repeat padding-box;
            background-image: springgreen;
            background-repeat: no-repeat;
            background-size: contain;
            width: 100%;
            max-width: 1090px;
            padding: 35px 25px;
            margin-bottom: 25px;
            border: 1px solid #2f8f6e;

            .the-logo {
                margin-bottom: 12px;
            }
            
            .do-list {
                margin: 0;
                padding: 0;
                list-style-type: none;
                display: flex;
                flex-direction: column;
            }

            .tigers-vs-pro-traders-divider { 
                width: 90px;
                height: 50px;
                background-image: url(/images/layout/versus.svg);
                background-repeat: no-repeat;
                background-size: 85%;
                background-position: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                transform: rotate(90deg);
                margin-bottom: 30px;
                //height: 100%;
            }

            .tigers-do,
            .pro-traders-do {                
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                width: 100%;
                height: 100%;

                .do-list {
                    color: #fff;
                    font-size: 14px;
                    text-align: center;

                    li {
                        margin-bottom: 12px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .tigers-do {
                align-items: center;
                margin-bottom: 30px;
            }

            .pro-traders-do {
                align-items: center;
            }
        }

        .centered-text {
            text-align: center;
            color: #fff;
            max-width: 840px;
            margin: 0 auto;
        }
    }

    &::after {
        display: block;
        width: 100%;
        height: 121px;
        position: absolute;
        bottom: 0;
        left: 0;
        content: ' ';
        background-image: url(/images/layout/techno-tiger-bg.png);
        background-repeat: no-repeat;
        background-size: contain;
    }

    ${mediaQueries.mediumDevices} {
        padding: 40px 0 250px;

        .container {
            .text-with-img-wrapper {
                flex-direction: row;
                padding-bottom: 91px;
                margin-bottom: 0px;

                .text-col {
                    width: 60%;
                    z-index: 1;

                    .section-title {
                        text-align: left;
                        font-size: 28px;

                        > span {
                            font-size: 30px;
                        }
                    }
                }

                .img-wrapper {
                    text-align: center;
                    width: 40%;

                    img {
                        width: 150%;
                        margin-left: -100px;
                    }
                }

                    &::after {
                        display: block;
                        position: absolute;
                        content: ' ';
                        left: 0;
                        bottom: 0;
                        background-image: url(/images/layout/techno-tiger-pointer.png);
                        background-repeat: no-repeat;
                        height: 91px;
                        width: 50%;
                        background-size: 100%;
                }
            }

            .tigers-vs-pro-traders {
                flex-direction: row;
                background: transparent linear-gradient(180deg,#0c3326 0%,#060b0f 100%) 0% 0% no-repeat padding-box;

                .tigers-vs-pro-traders-divider { 
                    width: 100px;
                    height: 63px;
                    transform: rotate(0deg);
                }

                .tigers-do {
                    align-items: flex-end;
                    margin-right: 30px;

                    .do-list {
                        align-items: flex-end;
                        text-align: right;
                    }
                }
                
                .pro-traders-do {
                    align-items: flex-start;
                    margin-left: 30px;

                    .do-list {
                        text-align: left;
                    }
                }  
            }
        }

        &::after {
            height: 249px;
        }
    }

    ${mediaQueries.largeDevices} {
        .container {
            .text-with-img-wrapper {
                flex-direction: row;

                .text-col,
                .img-wrapper {
                    width: 50%;
                }

                .text-col {
                    .section-title {
                        margin-bottom: 15px;

                        > span {
                            font-size: 52px;
                            max-width: 400px;
                        }
                    }
                }

                .img-wrapper {
                    img {
                        width: 120%;
                    }
                }
            }

            .tigers-vs-pro-traders {
                /*background-image: url(/images/layout/tigers-vs-pro-traders.svg);
                background-repeat: no-repeat;
                background-size: contain;
                border: none;
                height: 339px;*/

                .tigers-do,
                .pro-traders-do {
                    width: calc(50% - 50px);
                    align-self: flex-start;
                }
            }
        }

        &::after {
            height: 311px;
        }
    }

    ${mediaQueries.extraLargeDevices} {
        padding: 60px 10px 270px;

        &::after {
            height: 298px;
        }
    }

    ${mediaQueries.superExtraLargeDevices} {
        padding: 60px 0px 410px;
        background-image: url(/images/layout/techno-tiger-bg.png);
        background-position: bottom center;
        background-size: contain;
        background-repeat: no-repeat;

        .container {
            .text-with-img-wrapper {
                margin-bottom: 25px;

                .text-col {
                    .section-title {
                        font-size: 38px;
                    }
                }

                .img-wrapper {
                    img {
                        width: 100%;
                        margin-left: 0;
                    }
                }
            }

            .tigers-vs-pro-traders {
                background-image: url(/images/layout/tigers-vs-pro-traders.svg);
                background-repeat: no-repeat;
                background-size: contain;
                border: none;
            }
        }

        &::after {
            display: none;
        }
    }
`