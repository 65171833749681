import styled from 'styled-components';
import { color } from '../../../styles/CONSTANTS';

export const WalletContainer = styled.div`
	max-width: 100%;
	margin: 0 auto;
	position: relative;

	&.filters {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 40px;
		width: 100%;

		h2 {
			margin-bottom: 0;
		}

		.filter-lists {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding: 0;
		}

		.filters-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0;
		}
	}

	&.banners {
		padding: 20px 0px;
		position: relative;
		display: grid;
		height: auto;
		width: auto;
		min-width: auto;
		grid-template-columns: repeat(5, 1fr);
		grid-template-rows: auto;
		column-gap: 20px;
		row-gap: 20px;

		@media screen and (min-width: 768px) and (max-width: 1280px) {
			display: grid !important;
			grid-template-columns: repeat(3, 1fr);
		}

		.card {
			text-align: center;
			padding: 15px 20px;
			border-radius: 4px;
			background: #0B1422;
			margin-bottom: 15px;
			min-height: 95px;
			border: 1px solid #1E2A37;

			small {
				text-align: left;
				color: #6E7885;
				font-weight: 600;
			}

			h2 {
				text-align: right;
			    margin-right: 15px;
				color: #fff;
				font-weight: 600;
			    font-size: 35px;
			    margin-bottom: 0;
			}

			@media screen and (min-width: 320px) and (max-width: 766px) {
				margin-bottom: 5px;
			}
		}
	}
`;

export const WalletContainerWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 25px;
`;

export const WalletTitle = styled.h2`
	font-size: 34px;
	font-weight: 500;
	margin-bottom: 20px;

	.feed-anchor {
		display: flex;
		align-items: center;
		margin-right: 15px;
		box-shadow: 0.3rem 0.3rem 0.6rem #0f1922, -0.2rem -0.2rem 0.5rem #223040;
		border-radius: 9px;
		padding: 5px 15px;
		color: #16b46c;

		&:focus {
			box-shadow: unset;
		}
	}

	.feed-toggle {
		width: 100%;
		box-shadow: 0.3rem 0.3rem 0.6rem #0f1922, -0.2rem -0.2rem 0.5rem #223040;
		border-radius: 9px;
		padding: 5px;
	}

	svg {
		font-size: 12px;
		color: #15bd6f;
		margin-right: 10px;

		@media screen and (min-width: 766px) and (max-width: 992px) {
			font-size: 25px;
		}

		@media screen and (min-width: 993px) and (max-width: 1024px) {
			font-size: 28px;
		}

		@media screen and (min-width: 1025px) and (max-width: 3000px) {
			font-size: 30px;
		}
	}

	&.order {
		@media screen and (min-width: 320px) and (max-width: 766px) {
			font-size: 20px;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			.float-right {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: auto;

				a {
					font-size: 14px;
    				margin: 0 !important;
				}

				button {
					font-size: 9px;
				}
			}
		}
	}
`;

export const WalletDataContainer = styled.div`
	h2 {
		font-size: 20px;
		font-weight: 400;
		margin-top: 100px;
	}
`;

export const EstimatedContainer = styled.div`
  	position: relative;
`;

export const EstimatedValTitle = styled.small`
	font-size: 16px;
	color: #6E7885;
	font-weight: 500;
`;

export const EstimatedValueContainer = styled.div`
  	display: flex;
	justify-content: space-between;
`;

export const EstimatedValue = styled.label`
	font-size: 24px;
	font-weight: 500;

	span {
		font-size: 14px;
		padding-right: 3px;
	}
`;

export const EstimatedConvertedValue = styled.small`
	font-size: 24px;
	color: #96a1b7;
	font-weight: 500;

	span {
		font-size: 14px;
	}
`;

export const WalletTableContainer = styled.div`
	position: relative;

	table {
		font-size: 13px;
		background-color: #0E1824;
		color: #ced0dd;
		margin-bottom: 0;
		border-radius: 4px;
		table-layout: fixed;

		thead {
			tr {
				th {
					background: transparent;
					color: #6E7885;
					border: none;
					font-size: 12px;
					font-weight: 600;
					line-height: 20px;
					text-align: center;
					padding: 20px;
				}
			}
		}

		tbody {
			tr {
				&.custom-splitter {
					background-color: #1e2733;
				}
			}

			&+tbody {
				border-top: 2px solid #1f2a35;
			}
		}

		td {
			border-top: none;
			border-bottom: 1px solid #1f2a35;
			text-align: center;
			vertical-align: middle;
			font-size: 15px;
			font-weight: 500;
			padding: 20px;

			.wallet-logo {
				width: 36px;
				height: 36px;
				margin-right: 0;
			}

			.coinSymbolName {
			    margin-left: 15px;
				font-size: 16px;
    			font-weight: 500;
				text-align: left;
			}
		}

		thead, tbody tr {
			display: table;
			width: 100%;
			table-layout: fixed;

			&.no-data {
				td {
					border-bottom: none;
				}
			}
		}
	}
`;

export const WalletPaginationWrapper = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	user-select: none;
	padding-left: 15px;

	svg {
		cursor: pointer;
	}

	&.pagination-wrapper {
	  justify-content: flex-end;
	  margin-bottom: 15px;
	  position: absolute;
	  top: -45px;
	  right: 15px;
	  z-index: 4;

	  &.bottom-wrapper {
		bottom: -50px;
		top: unset;
	  }

	  #leftArrow {
		margin-left: 5px;
	  }

	  svg {
		  cursor: pointer;

		  &:first-child {
			color: ${color.primaryBG};
		  }
	  }
	}
`;

export const WalletHeaderContainer = styled.div`
	display: flex;
	flex-direction: column;

	&.quicktrade {
		align-items: center;
		margin-bottom: 15px;

		button {
			background-color: #3F9F32 !important;
			color: #fff !important;
			display: inline-block;
			font-weight: 400;
			text-align: center;
			border: 1px solid #61B755 !important;
			font-size: 14px !important;
			padding: .230rem .55rem !important;
			margin-left: 0;
		}
	}
`;

export const ShowWalletBalance = styled.label`
    display: block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    text-align: center;
    margin-bottom: 0;
    color: #545659;
    pointer-events: none;

	&.statistics {
		min-width: 160px;
	    text-align: right;
		color: #778698 !important;
	}

    &.active {
        pointer-events: auto;
		color: #778698;
		font-size: 15px;
		font-weight: 500;
    }

    i {
        position: relative;
        display: inline-block;
        margin-right: .5rem;
        width: 38px;
        height: 21px;
        background-color: #383e4e;
        border-radius: 23px;
        vertical-align: text-bottom;
        transition: all 0.3s linear;

        &.active {
            &::before {
                background-color: #19212b;
            }

            &::after {
                background-color: #ccd4de;
            }
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 33px;
            height: 17px;
            background-color: #545659;
            border-radius: 11px;
            transform: translate3d(2px,2px,0) scale3d(1,1,1);
            transition: all 0.25s linear;
        }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 16px;
            height: 16px;
            background-color: #707274;
            border-radius: 11px;
            box-shadow: 0 0px 2px 2px rgba(0,0,0,0.24);
            transform: translate3d(2px,2px,0);
            transition: all 0.2s ease-in-out;
        }
    }

    input {
        display: none;

        &:checked + i {
            background-color: #15bd6f;

            &::before {
                transform: translate3d(14px, 2px, 0) scale3d(0, 0, 0);
            }

            &::after {
                transform: translate3d(20px, 2px, 0);
                background-color: #25313f;
            }
        }
    }

    &:active {

        i {
            &::after {
                width: 32px;
                transform: translate3d(2px,2.3px,0);
            }
        }

        input {

            &:checked + i {

                &::after {
                    width: 32px;
                    transform: translate3d(3px,2px,0);
                }
            }
        }
    }
`;

export const WalletBalanceWrapper = styled.div`
	margin-right: 20px;
`;

export const WalletSearchTabWrapper = styled.div`
	margin-left: auto;
	display: flex;
    justify-content: center;
    align-items: center;
`;

export const WalletSearchTitle = styled.p`
  margin: 0;
`;

export const WalletSearchContainer = styled.div`
	input {
		background-color: #272F3A !important;
		border-color: #394B63;
		font-size: 15px;
		font-weight: 500;
		color: #fff !important;

		::placeholder {
		color: #ced0dd !important;
		}

		@media screen and (max-width: 992px) {
			width: 140px;
		}
	}
`;

export const WalletWrapper = styled.div`
  	padding: 40px 30px;
  	min-height: calc(100vh - 60px);
	background: transparent;
	// background: #131E2B;

  	&.followed-signals {
		min-height: auto;
  	}

 	.pagination {
 	 	margin-bottom: 5px;
 		margin-left: auto;
   	}

  	nav {
		border: none;
		position: relative;
		z-index: 3;
		background: #131E2B;
		padding: 15px 0;

		.nav-link {
		    color: #6E7885;
			font-weight: 400;
			font-size: 16px;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			display: flex;
			align-items: center;
			margin-right: 25px;
			padding: 8px 0;
			border-bottom: 3px solid transparent;
			border-top: none;
			border-left: none;
			border-right: none;

			&.active {
				color: #B5BECB;
				font-weight: 600;
				background: transparent;
				border-left: none;
				border-top: none;
				border-right: none;
				border-bottom: 3px solid #15BD6F;
			}

			&:hover {
				border-left: none;
				border-top: none;
				border-right: none;
				border-bottom: 3px solid #15BD6F;
			}

			&:focus {
				outline: 0;
			}
		}

		&.tab-navigation {
			@media screen and (min-width: 320px) and (max-width: 766px) {
				display: flex;
				justify-content: space-evenly;
				align-items: center;
				width: 100%;
				margin: 15px 0;

					a {
						padding: 8px 8px;
						margin-right: 0;
						width: auto;
						font-size: 11px;
					}
			}
		}

		

		@media screen and (min-width: 320px) and (max-width: 414px) {
			padding: 0;
			justify-content: space-between;
			align-items: center;
		    margin-bottom: 20px;

			.nav-link {
				font-size: 15px;
			}
		}
	}

	.tab-content {
		position: relative;
		border-color: #1f2a35;
		border-width: 3px;
		border-style: solid;
	}

	@media screen and (min-width: 320px) and (max-width: 766px) {
  		padding: 20px 15px;

		nav {
			.nav-link {
				padding: 8px 16px;
				margin-right: 0;
			}
		}

		${WalletContainer} {
			display: block;
			margin-bottom: 5px;

			${WalletTitle} {
				font-size: 20px;
			}

			.statistics-strat {
				display: none;
			}

			&.filters {
				.filter-lists {
					justify-content: space-between;
					margin-top: 15px;
					flex-direction: column;

					.filter {
						width: 100%;
					}
				}
			}

			&.banners {
				.row {
					display: grid;
    				grid-template-columns: auto auto;

					.card {
						padding: 15px 12px;

						h2 {
							margin: 10px 0 0;
							font-size: 30px;
						}
					}
				}
			}

			${WalletContainerWrapper} {
				display: block;

				${WalletSearchTabWrapper} {
					justify-content: space-between;
    				margin-top: 15px;
				}
			}

			${WalletDataContainer} {
				${EstimatedContainer} {
					${WalletHeaderContainer} {
						justify-content: space-between;
					    align-items: baseline;
					    flex-direction: column;
					}
				}

				${WalletTableContainer} {
					table {
						thead {
							display: none;
						}

						tbody {
							&.wallet-table {
								.expanded-wrapper {
					                display: flex;
					                justify-content: space-between;
					                align-items: center;
					                margin-bottom: 15px;
					                font-size: 13px;

					                .col-sm-6 {
					                    padding: 0;

					                    &.col-md-8 {
					                        text-align: start;
					                        color: #6E7885;
					                        font-weight: 600;
					                    }

					                    &.col-md-4 {
					                        text-align: end;

											.wallet-logo {
												width: 30px;
												height: 30px;
											    margin-right: 0;
											}

											.coinSymbolName {
											    text-align: right;
											}
					                    }
					                }
					            }

								h5 {
									font-size: 16px;
    								padding: 0 15px;
								}
							}
						}
					}
				}
			}
		}
	}

  	@media screen and (min-width: 767px) and (max-width: 1024px) {
		${WalletContainer} {
			display: block;
			margin-bottom: 5px;

			${WalletTitle} {
				font-size: 25px;
			}

			.statistics-strat {
				display: none;
			}

			&.filters {
				.filters-title {
					max-width: 100%;
					width: 100%;
				}

				.filter-lists {
					justify-content: flex-end;
				    margin: 15px 0 25px;
					max-width: 100%;
					width: 100%;
				}
			}

			&.banners {
				.row {
					.card {
						padding: 15px 12px;
						margin-bottom: 15px;

						h2 {
							margin: 10px 0 0;
							font-size: 30px;
						}
					}
				}
			}

			${WalletContainerWrapper} {
				display: block;

				${WalletSearchTabWrapper} {
					justify-content: space-between;
    				margin-top: 15px;
				}
			}

			${WalletDataContainer} {
				${EstimatedContainer} {
					${WalletHeaderContainer} {
						justify-content: space-between;
					    align-items: center;
					    flex-direction: row;
					}
				}

				${WalletTableContainer} {
					table {
						thead {
							display: none;
						}

						tbody {
							&.wallet-table {
								.expanded-wrapper {
					                display: flex;
					                justify-content: space-between;
					                align-items: center;
					                margin-bottom: 15px;
					                font-size: 13px;

					                .col-sm-6 {
					                    padding: 0;

					                    &.col-md-8 {
					                        text-align: start;
					                        color: #6E7885;
					                        font-weight: 600;
					                    }

					                    &.col-md-4 {
					                        text-align: end;

											.wallet-logo {
												width: 30px;
												height: 30px;
											    margin-right: 0;
											}

											.coinSymbolName {
											    text-align: right;
											}
					                    }
					                }
					            }
							}
						}
					}
				}
			}
		}
  	}

  	@media screen and (min-width: 1025px) and (max-width: 1199px) {
	  	${WalletContainer} {
			display: block;
			margin-bottom: 5px;

		  	${WalletTitle} {
			  	font-size: 28px;
		  	}

			&.filters {
				.filters-title {
					max-width: 100%;
					width: 100%;
				}

				.filter-lists {
					justify-content: flex-end;
				    margin: 15px 0 25px;
					max-width: 100%;
					width: 100%;
				}
			}

		  	${WalletDataContainer} {
			  	${EstimatedContainer} {
				  	${WalletSearchTabWrapper} {
					  	${WalletSearchContainer} {
						  	> input {
							  	font-size: 13px;
						  	}
					  	}
				  	}

				  	${WalletPaginationWrapper} {
					  	font-size: 12px;
				  	}
			  	}

			  	${WalletTableContainer} {
				  	table {
					  	font-size: 12px;

					  	.wallet-logo {
						  	width: 25px;
						  	height: 25px;
					  	}
				  	}
			  	}
		  	}
	  	}
  	}

  	@media screen and (min-width: 1200px) and (max-width: 1440px) {
  		${WalletContainer} {
	  		max-width: 1348px;

	  		${WalletTitle} {
			  	font-size: 28px;
		  	}

			.statistics-strat {
				font-size: 14px;
			}

		  	${WalletDataContainer} {
			  	${EstimatedContainer} {
				  	${WalletSearchTabWrapper} {
					  	${WalletSearchContainer} {
						  	> input {
							  	font-size: 14px;
						  	}
					  	}
				  	}

				  	${WalletPaginationWrapper} {
					  	font-size: 13px;
				  	}
			  	}

			  	${WalletTableContainer} {
				  	table {
					  	font-size: 13px;

					  	.wallet-logo {
						  	width: 25px;
						  	height: 25px;
					  	}
				  	}
			  	}
		  	}
	  	}
  	}

	@media screen and (min-width: 1441px) and (max-width: 1749px) {
	  	${WalletContainer} {
		  	max-width: 1348px;

		  	${WalletTitle} {
			  	font-size: 30px;
		  	}

		  	${WalletDataContainer} {
			  	${EstimatedContainer} {
				  	${WalletSearchTabWrapper} {
					  	${WalletSearchContainer} {
						  	> input {
							  	font-size: 15px;
						  	}
					  	}
				  	}

				  	${WalletPaginationWrapper} {
					  	font-size: 14px;
				  	}
			  	}

			  	${WalletTableContainer} {
				  	table {
					  	font-size: 14px;

					  	.wallet-logo {
						  	width: 30px;
						  	height: 30px;
					  	}
				  	}
			  	}
		  	}
	  	}
	}
`;
