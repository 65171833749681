import glamorous from 'glamorous'
import { mediaQueries } from '../../../constants/mediaQueries'

export const HeaderSectionStyle = glamorous.section({
    position: 'relative',
    marginTop: '-28px',
    padding: '40px 0 30px',
    overflow: 'hidden',

    '& .container': {
        width: '100%',
        maxWidth: '100%',

        '& .header-content-wrapper': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            maxWidth: '100%',
            margin: '0 auto',
            
            '& .header-text-content': {
                display: 'block',
                textAlign: 'center',

                '& h1': {
                    fontSize: '27px',
                    fontWeight: 600,
                    maxWidth: '300px',
                    lineHeight: '1.4',
                    marginBottom: 0,

                    '& small': {
                        display: 'block',
                        fontSize: '14px',
                        fontWeight: 400,
                        marginBottom: '10px'
                    }
                },

                '& .mobile-none': {
                    display: 'none',

                    '& h3': {
                        fontSize: '15px',
                        fontWeight: 400,
                        lineHeight: '1.7',
                        marginBottom: '25px'
                    },

                    '& .pro-features-summary': {
                        margin: 0,
                        padding: 0,
                        listStyleType: 'none',
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',

                        '& li': {
                            '& .icon-wrapper': {
                                marginBottom: '3px',

                                '& img': {
                                    height: '100%'
                                }
                            }
                        }
                    }
                }
            },

            '& .header-img-content': {
                position: 'relative',
                display: 'block',
                width: '100%',
                height: '310px',
                backgroundImage: 'url(/images/subscriptions/header-img.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '310px',
                backgroundPosition: 'center',
                marginBottom: '15px',

                '&:before': {
                    position: 'absolute',
                    display: 'block',
                    content: ' ',
                    width: '490px',
                    height: '360px',
                    backgroundImage: 'url(/images/subscriptions/header-bg.svg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    marginLeft: 'calc(50% - 245px)',
                    marginTop: '-18px',
                    zIndex: '-1'
                }
            },

            '& .header-sub-text-content': {
                display: 'block',
                textAlign: 'center',

                '& h3': {                    
                    fontSize: '15px',
                    fontWeight: 300,
                    maxWidth: '340px',
                    lineHeight: '1.7',
                    marginBottom: '25px'
                },

                '& .pro-features-summary': {
                    margin: '0 auto',
                    padding: 0,
                    listStyleType: 'none',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',

                    '& .icon-wrapper': {
                        marginBottom: '10px',
                        height: '30px',

                        '& img': {
                            height: '100%'
                        }
                    },

                    '& .icon-text': {
                        display: 'block',
                        lineHeight: '1.3',
                        width: '110px'
                    }
                }
            }
        }
    },

    [mediaQueries.mediumDevices]: {
        padding: '20px 0 40px',

        '& .container': {
            '& .header-content-wrapper': {
                flexDirection: 'row',
                maxWidth: '100%',

                '& .header-text-content': {
                    width: '60%',
                    textAlign: 'left',
                    paddingLeft: '25px',

                    '& h1': {
                        fontSize: '25px',
                        maxWidth: '283px',
                        marginBottom: '15px',

                        '& small': {
                            fontSize: '15px'
                        }
                    },

                    '& .mobile-none': {
                        display: 'block',
                        
                        '& .pro-features-summary': {
                            maxWidth: '451px',

                            '& li': {
                                '& .icon-wrapper': {
                                    height: '30px'
                                }
                            }
                        }
                    }
                },

                '& .header-img-content': {
                    width: '40%'
                },

                '& .header-sub-text-content': {
                    '&.mobile-only': {
                        display: 'none'
                    }
                }
            }
        }
    },

    [mediaQueries.largeDevices]: {
        padding: '0 0 40px',

        '& .container': {
            '& .header-content-wrapper': {
                maxWidth: '920px',

                '& .header-text-content': {
                    width: '400px',
                    paddingLeft: 0,

                    '& h1': {
                        fontSize: '30px',
                        maxWidth: '340px',

                        '& small': {
                            fontSize: '16px'
                        }
                    },

                    '& .mobile-none': {
                        display: 'block',
                        
                        '& h3': {
                            fontSize: '15px'
                        }
                    }
                },

                '& .header-img-content': {
                    width: 'calc(100% - 400px)',
                    height: '422px',
                    backgroundSize: 'contain',

                    '&::before': {
                        width: '690px',
                        height: '1083px',
                        left: 'calc(50% - 345px)',
                        top: 'calc(50% - 511px)',
                        marginLeft: 'unset',
                        marginTop: 'unset'
                    }
                }
            }
        }
    },

    [mediaQueries.extraLargeDevices]: {
        padding: '10px 0 30px',

        '& .container': {
            '& .header-content-wrapper': {
                maxWidth: '1200px',

                '& .header-text-content': {
                    width: '535px',

                    '& h1': {
                        fontSize: '48px',
                        maxWidth: '100%',

                        '& small': {
                            fontSize: '22px'
                        }
                    },

                    '& .mobile-none': {
                        '& h3': {
                            fontSize: '20px'
                        },

                        '& .pro-features-summary': {
                            '& li': {
                                '& .icon-wrapper': {
                                    height: '40px'
                                }
                            }
                        }
                    }
                },

                '& .header-img-content': {
                    width: 'calc(100% - 535px)',
                    height: '620px',

                    '&::before': {
                        width: '1090px',
                        left: 'calc(50% - 545px)'
                    }
                }
            }
        }
    },

    [mediaQueries.superExtraLargeDevices]: {
        padding: '30px 0 60px'
    },

    [mediaQueries.fourK]: {
        padding: '30px 0 60px'
    }
})