import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { HeaderSectionStyle } from './styles/HeaderStyle'

export const HeaderSection = () => {
    return (
        <HeaderSectionStyle>
            <div className='container'>
                <div className='header-content-wrapper'>
                    <div className='header-text-content'>
                        <h1 className='header-title'>
                            <small>Want to become a real pro trader?</small>
                            <span>Upgrade And Unlock Life-Changing Trading Powers</span>
                        </h1>

                        <div className='mobile-none'>
                            <h3>Upgrade now, and there's nothing stopping you from doubling, 5x'ing, or 10x'ing your account or more.</h3>
                            <ul className='pro-features-summary'>
                                <li>
                                    <div className='icon-wrapper'>
                                        <img src='/images/subscriptions/5x-icon.svg' alt='5x Nore Signals' />
                                    </div>
                                    <span className='icon-text'>More Signals</span>
                                </li>
                                <li>
                                    <div className='icon-wrapper'>
                                        <img src='/images/subscriptions/4x-icon.svg' alt='4x More Targets' />
                                    </div>
                                    <span className='icon-text'>More Targets</span>
                                </li>
                                <li>
                                    <div className='icon-wrapper'>
                                        <img src='/images/subscriptions/be-sl-icon.svg' alt='Breakeven Stop-loss' />
                                    </div>
                                    <span className='icon-text'>Breakeven Stop-loss</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='header-img-content'></div>

                    <div className='header-sub-text-content mobile-only'>
                        <h3>Upgrade now, and there's nothing stopping you from doubling, 5x'ing, or 10x'ing your account or more.</h3>
                        <ul className='pro-features-summary'>
                            <li>
                                <div className='icon-wrapper'>
                                    <img src='/images/subscriptions/5x-icon.svg' alt='5x Nore Signals' />
                                </div>
                                <span className='icon-text'>More Signals</span>
                            </li>
                            <li>
                                <div className='icon-wrapper'>
                                    <img src='/images/subscriptions/4x-icon.svg' alt='4x More Targets' />
                                </div>
                                <span className='icon-text'>More Targets</span>
                            </li>
                            <li>
                                <div className='icon-wrapper'>
                                    <img src='/images/subscriptions/be-sl-icon.svg' alt='Breakeven Stop-loss' />
                                </div>
                                <span className='icon-text'>Breakeven Stop-loss</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </HeaderSectionStyle>
    )
}