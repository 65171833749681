import React, { useEffect, useState }  from 'react'
import { Carousel } from 'react-bootstrap'
import { TestimonialsStyle } from './styles/TestimonialsStyle'
import { mediaDeviceSizes } from '../../constants/mediaQueries'
import { getSize } from '../../utils'

export const TestimonialsSection = () => {
    let [device, setDevice] = useState(null)

    useEffect(() => {
        setDevice(getSize(window.innerWidth))
    }, [])

    return (
        <TestimonialsStyle>
            <div className='container'>
                <h3 className='section-title'>
                    Hear It From Real Users<br />
                    <small>
                        Verified by 
                        <span className='tp-logo-wrapper'>
                            <img src='/images/offers/trustpilot-icon.svg' alt='' />
                            <img src='/images/offers/trustpilot-text-light.svg' alt='' />
                        </span>
                    </small>
                </h3>

                { (device === mediaDeviceSizes.xs || device === mediaDeviceSizes.sm) && 
                    <Carousel>
                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>The Holy Grail of crypto</h6>
                                    <p>I won't lie and over exaggerate, this is what I have personally been looking for. I've tried many paid signals, groups, indicators and a bunch of other garbages, lost money both on that and trades. But ProfitFarmers is a platform that I would recommend to anyone no matter the experience and knowledge.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Luka</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/luka.png' alt='Testimonial - Luka' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>New to cryptotrading - up 20 % after 1 week</h6>
                                    <p>So finally I got into the whole crypto-trading! I had been reading a lot and thought to myself, that profitfarmers seemed legit. I could not find any other platform with as transperent and good educational material. Today a week after the purchase I'm up to 20 % from a initial investment of &euro;5000. &euro;1K euro is pretty good in one week!</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Nicklas Olsen</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Nicklas-Olsen-Trustpilot.png' alt='Testimonial - Nicklas' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>aah finally.</h6>
                                    <p>I've been using profitfarmers since the beginning of the year and basically, im just really happy that i can just set my entry zone and targets, and im good to go. I dont even have to think of where to put my stop loss. Its ALMOST automatic trading. I still look at charts to see how my trades are going, but significantly less since i started using the platform.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Francisco J.</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Carlo-Jimenez-Trustpilot.png' alt='Testimonial - Francisco' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>A well-performing platform with great potential</h6>
                                    <p>I like using this tool, but wouldn't recommend to someone who's never bought some crypto before.</p>
                                    <p>I've been trading in crypto for 3 years now but I just used to buy and let the crypto sit there. With PF, I get to make money with money that I already spent</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Ulrich Dossou</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Ulrich-Dossou-Trustpilot.png' alt='Testimonial - Ulrich' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>First used their telegram signals and…</h6>
                                    <p>First used their telegram signals and then decided on paying for a subscription. Now a month into my membership I can confidently say it’s giving me a huge advantage for finding good trades. To the mooon</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Rasmus</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Rasmus-img.png' alt='Testimonial - Rasmus' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Crypto trading on steroids lol</h6>
                                    <p>Helps me save time staring at charts. I can quickly find good trade set ups by using their signals and their copy trading function is top notch. They are continuously coming with new upgrades to the system so I'm excited to continue farming some profits.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Chris</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Chris-Trustpilot-profile-pic.png' alt='Testimonial - Chris' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Great Solution for Begginers and Traders</h6>
                                    <p>Finally a solution for both traders and beginners, I started using it only for clicking and copying signals and I was already impressed. The level of accuracy when it comes to understanding and correcting your investments is really unique.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Facu Espinosa</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Facu-Espinosa-Truspilot.png' alt='Testimonial - Facu' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>I LOVE PROFITFARMERS!</h6>
                                    <p>I love the set up with each target, closed trades etc. It's also easy to navigate at the website and that kind of stuff. Very good for people who are new in this cryptocurrency world. I will definitely continue my journey with profitfarmers!</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Hooman Attaran</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Hooman-Attaran-Truspilot.png' alt='Testimonial - Hooman' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Help for absolut beginners</h6>
                                    <p>I had no idea about the crypto market, and as an absolut beginner I feel safe with ProfitFarmers. I am learning and experiencing without falling.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Rita</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Rita' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>I love this platform</h6>
                                    <p>I love this platform, I didn't know how to do any of this really. But when I stated understanding how it worked this platform helped me a lot. If you are new to crypto start with this platform and you will become intermediate quite fast. Definitely worth the investments have made so far <img className="emoji" alt="🙂" src="https://s.w.org/images/core/emoji/13.1.0/svg/1f642.svg" /></p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Oliver Le Roux</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Oliver-Le-Roux-Trustpilot.png' alt='Testimonial - Oliver' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Very interesting application</h6>
                                    <p>Takes time to learn like anything else in life. Be patient when using it. Don't make big trades to start with... go slowly forward until you know more about crypto trading. Once you understand the systems relation to Binance and the coins and start to know better about it, it is a very good application.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Hej Med</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Hej' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Great and really easy to use!</h6>
                                    <p>It is really great for beginners! This is the platform if you want to start at Crypto-trading!</p>
                                    <p>It has a really apprehensive UI and doesn't force any actions upon you. It uses a system, which monitors the market and then suggest some plans for you to use.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Niels R.</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Niels' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>I've been trading in crypto for 3 years now</h6>
                                    <p>and Profit Farmers has helped me gain even more. It took a few hours to fully master the tool, but totally worth it!</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Christine</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Christine' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>It's been really helpful</h6>
                                    <p>I'm not at all a pro trader but since I've traded a bit of stocks before, I decided to try PF. Really happy that I did, cause now I have a tool that takes most of the pain out of trading.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Signe Barner</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Signe' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Good Trading Signals But You Need To Practice</h6>
                                    <p>1st thing, yes the platform is real and legitimate so don't worry. A good amount of trading signals are coming almost every day as well as newsletter updates and other things.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Freak5000</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Freak5000' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>I was a bit skeptic in the beginning</h6>
                                    <p>but after I learned how to use the platform, read the signals and use the trading tools ProfitFarmers basically just made it sooooo super easy for me. I'm not a pro trader - but this is definitely a step closer to the feeling of being a pro trader, no doubt!</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Frida Rizali</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Frida' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Great Platform if you are new to Crypto - As I was</h6>
                                    <p>I came across ProfitFarmers and with my very sceptical head on (that's good in the Crypto Space) I took a look at what they had to offer. I then started on a monthly basis. I was impressed straightaway as I am a novice trader. The platform is easy to use, includes some great tools and the Buy Signals are realistic and spot on for the majority of the time.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Alan</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Alan' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>I love Profit Farmers</h6>
                                    <p>The signals have been accurate and I've enjoyed a high win rate using their signals. Even when a trade doesn't go as expected, the PF team communicates quickly and clearly to help you preserve capital, and usually the trades that get closed early are already well into profit...,</p>
                                    <p>The auto-trade functionality through Binance works like a dream.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Martin Oosthuizen</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Martin' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>ProfitFarmers, crypto tool that works</h6>
                                    <p>I was a bit skeptical at the beginning, even on my first few trades with Profitfarmers signals, but that was unfounded. I've been working with the signals for about 2 months and it seems to work - about 70% of my used signals were profitable</p>
                                    <p>Also the additional trading tools (RSI & Price - Scanner) are a benefit and very helpful in combination with the signals, makes it more powerful.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Jan Hortsman</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/jan-hortsman.png' alt='Testimonial - Jan' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>satisfied</h6>
                                    <p>I have been following your signals for almost a month now, and I must say that they are really very good I was very amazed in general and also on how the platform is organized. the whole procedure to start and to connect to binance is very well explained, 90% of the doubts are solved only by watching the videos made by them and for the remaining 10% the support always responds very quickly and kindly.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Valerio De Angelis</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Valerio' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>My experience as a Profitfarmer</h6>
                                    <p>The platform is beginner friendly, Tim's case study (Tim is another member of Profitfarmers) inspired and helped me a lot, it's available on the website, at first i was little bit confused and made some mistakes which caused me some little loses, but after i studied Tim's case study it all changed, never mind the gain overwhelmed the loses, thanks to them and lucky us.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Naja'atu Nuhu</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Najaatu-Nuhu.png' alt="Testimonial - Naja'atu" />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Sinds I have profitfarmer memberschip I…</h6>
                                    <p>Sinds I have profitfarmer memberschip I made in 3 weeks 20k with an account of 4k if you have a little bit of experience in trading then the signals are really helpful too get allot of profits and for beginners it is a great way too learn trading on a safer way</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Hanshofmans</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Hanshofmans.png' alt='Testimonial - Hanshofmans' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Highly recommend</h6>
                                    <p>I have been using ProfitFarmers for 2 weeks now, have to say that the signals are very accurate and profitable. I am a beginner at trading and lost money from trading by myself, and all the loss I've done was soon recovered by ProfitFarmers so I am very thankful for their service.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Xuan Ton</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/xuan.png' alt='Testimonial - Xuan' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Strong Recommendation</h6>
                                    <p>I've been using ProfitFarmers for a month now. In the beginning it took some work and effort to get into the system and get used to the settings. After watching the tutorials and adjusting the system to my needs I'm in the green. Strong recommendation to everyone who is not shy to educate himself on the matter.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Andreas W.</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Andreas' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Reliable company!</h6>
                                    <p>- Very easy to use</p>
                                    <p>- So far, a well performing AI trading system</p>
                                    <p>- Great and fast customer support (even on sunday!)</p>
                                    <p>Easy to use for new traders and also very helpfull for experienced traders.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Benny the Foxx</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt="Testimonial - Benny" />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>I've been looking for several years…</h6>
                                    <p>into various signalling services etc...</p>
                                    <p>ProfitFarmers, for me, has been fantastic so far. If you're looking for something that will make you rich overnight with no effort, look elsewhere (I would suggest starting in your dreams), this requires some understanding of how things work, and some time- but not too much.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Tim Griffith</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/cs-tim-griffith-img.png' alt='Testimonial - Tim' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>ProfitFarmers is the perfect crypto platform</h6>
                                    <p>for traders that are somewhat new to the game!</p>
                                    <p>The team is forthcoming, helpful, friendly and knowledgeable - they are always willing to help with your trading skills. Signals are good quality. Highly recommended!.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Juri</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Juri' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Very helpful,</h6>
                                    <p>knowledgeable and totally professional.</p>
                                    <p>A polite team who are a pleasure to deal with. Thank you ProfitFarmers.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Ian Holloway</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Ian-Holloway-Profile.jpg' alt='Testimonial - Ian' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>
                    </Carousel>
                }

                { (device === mediaDeviceSizes.md || device === mediaDeviceSizes.lg) && 
                    <Carousel>
                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>The Holy Grail of crypto</h6>
                                    <p>I won't lie and over exaggerate, this is what I have personally been looking for. I've tried many paid signals, groups, indicators and a bunch of other garbages, lost money both on that and trades. But ProfitFarmers is a platform that I would recommend to anyone no matter the experience and knowledge.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Luka</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/luka.png' alt='Testimonial - Luka' />
                                        </span>
                                    </div>
                                </li>
                                <li className='testimony'>
                                    <h6>New to cryptotrading - up 20 % after 1 week</h6>
                                    <p>So finally I got into the whole crypto-trading! I had been reading a lot and thought to myself, that profitfarmers seemed legit. I could not find any other platform with as transperent and good educational material. Today a week after the purchase I'm up to 20 % from a initial investment of &euro;5000. &euro;1K euro is pretty good in one week!</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Nicklas Olsen</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Nicklas-Olsen-Trustpilot.png' alt='Testimonial - Nicklas' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>aah finally.</h6>
                                    <p>I've been using profitfarmers since the beginning of the year and basically, im just really happy that i can just set my entry zone and targets, and im good to go. I dont even have to think of where to put my stop loss. Its ALMOST automatic trading. I still look at charts to see how my trades are going, but significantly less since i started using the platform.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Francisco J.</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Carlo-Jimenez-Trustpilot.png' alt='Testimonial - Francisco' />
                                        </span>
                                    </div>
                                </li>
                                <li className='testimony'>
                                    <h6>A well-performing platform with great potential</h6>
                                    <p>I like using this tool, but wouldn't recommend to someone who's never bought some crypto before.</p>
                                    <p>I've been trading in crypto for 3 years now but I just used to buy and let the crypto sit there. With PF, I get to make money with money that I already spent</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Ulrich Dossou</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Ulrich-Dossou-Trustpilot.png' alt='Testimonial - Ulrich' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>First used their telegram signals and…</h6>
                                    <p>First used their telegram signals and then decided on paying for a subscription. Now a month into my membership I can confidently say it’s giving me a huge advantage for finding good trades. To the mooon</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Rasmus</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Rasmus-img.png' alt='Testimonial - Rasmus' />
                                        </span>
                                    </div>
                                </li>
                                <li className='testimony'>
                                    <h6>Crypto trading on steroids lol</h6>
                                    <p>Helps me save time staring at charts. I can quickly find good trade set ups by using their signals and their copy trading function is top notch. They are continuously coming with new upgrades to the system so I'm excited to continue farming some profits.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Chris</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Chris-Trustpilot-profile-pic.png' alt='Testimonial - Chris' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Great Solution for Begginers and Traders</h6>
                                    <p>Finally a solution for both traders and beginners, I started using it only for clicking and copying signals and I was already impressed. The level of accuracy when it comes to understanding and correcting your investments is really unique.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Facu Espinosa</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Facu-Espinosa-Truspilot.png' alt='Testimonial - Facu' />
                                        </span>
                                    </div>
                                </li>
                                <li className='testimony'>
                                    <h6>I LOVE PROFITFARMERS!</h6>
                                    <p>I love the set up with each target, closed trades etc. It's also easy to navigate at the website and that kind of stuff. Very good for people who are new in this cryptocurrency world. I will definitely continue my journey with profitfarmers!</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Hooman Attaran</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Hooman-Attaran-Truspilot.png' alt='Testimonial - Hooman' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Help for absolut beginners</h6>
                                    <p>I had no idea about the crypto market, and as an absolut beginner I feel safe with ProfitFarmers. I am learning and experiencing without falling.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Rita</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Rita' />
                                        </span>
                                    </div>
                                </li>
                                <li className='testimony'>
                                    <h6>I love this platform</h6>
                                    <p>I love this platform, I didn't know how to do any of this really. But when I stated understanding how it worked this platform helped me a lot. If you are new to crypto start with this platform and you will become intermediate quite fast. Definitely worth the investments have made so far <img className="emoji" alt="🙂" src="https://s.w.org/images/core/emoji/13.1.0/svg/1f642.svg" /></p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Oliver Le Roux</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Oliver-Le-Roux-Trustpilot.png' alt='Testimonial - Oliver' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Very interesting application</h6>
                                    <p>Takes time to learn like anything else in life. Be patient when using it. Don't make big trades to start with... go slowly forward until you know more about crypto trading. Once you understand the systems relation to Binance and the coins and start to know better about it, it is a very good application.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Hej Med</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Hej' />
                                        </span>
                                    </div>
                                </li>
                                <li className='testimony'>
                                    <h6>Great and really easy to use!</h6>
                                    <p>It is really great for beginners! This is the platform if you want to start at Crypto-trading!</p>
                                    <p>It has a really apprehensive UI and doesn't force any actions upon you. It uses a system, which monitors the market and then suggest some plans for you to use.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Niels R.</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Niels' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>I've been trading in crypto for 3 years now</h6>
                                    <p>and Profit Farmers has helped me gain even more. It took a few hours to fully master the tool, but totally worth it!</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Christine</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Christine' />
                                        </span>
                                    </div>
                                </li>
                                <li className='testimony'>
                                    <h6>It's been really helpful</h6>
                                    <p>I'm not at all a pro trader but since I've traded a bit of stocks before, I decided to try PF. Really happy that I did, cause now I have a tool that takes most of the pain out of trading.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Signe Barner</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Signe' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Good Trading Signals But You Need To Practice</h6>
                                    <p>1st thing, yes the platform is real and legitimate so don't worry. A good amount of trading signals are coming almost every day as well as newsletter updates and other things.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Freak5000</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Freak5000' />
                                        </span>
                                    </div>
                                </li>
                                <li className='testimony'>
                                    <h6>I was a bit skeptic in the beginning</h6>
                                    <p>but after I learned how to use the platform, read the signals and use the trading tools ProfitFarmers basically just made it sooooo super easy for me. I'm not a pro trader - but this is definitely a step closer to the feeling of being a pro trader, no doubt!</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Frida Rizali</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Frida' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Great Platform if you are new to Crypto - As I was</h6>
                                    <p>I came across ProfitFarmers and with my very sceptical head on (that's good in the Crypto Space) I took a look at what they had to offer. I then started on a monthly basis. I was impressed straightaway as I am a novice trader. The platform is easy to use, includes some great tools and the Buy Signals are realistic and spot on for the majority of the time.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Alan</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Alan' />
                                        </span>
                                    </div>
                                </li>
                                <li className='testimony'>
                                    <h6>I love Profit Farmers</h6>
                                    <p>The signals have been accurate and I've enjoyed a high win rate using their signals. Even when a trade doesn't go as expected, the PF team communicates quickly and clearly to help you preserve capital, and usually the trades that get closed early are already well into profit...,</p>
                                    <p>The auto-trade functionality through Binance works like a dream.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Martin Oosthuizen</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Martin' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>ProfitFarmers, crypto tool that works</h6>
                                    <p>I was a bit skeptical at the beginning, even on my first few trades with Profitfarmers signals, but that was unfounded. I've been working with the signals for about 2 months and it seems to work - about 70% of my used signals were profitable</p>
                                    <p>Also the additional trading tools (RSI & Price - Scanner) are a benefit and very helpful in combination with the signals, makes it more powerful.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Jan Hortsman</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/jan-hortsman.png' alt='Testimonial - Jan' />
                                        </span>
                                    </div>
                                </li>
                                <li className='testimony'>
                                    <h6>satisfied</h6>
                                    <p>I have been following your signals for almost a month now, and I must say that they are really very good I was very amazed in general and also on how the platform is organized. the whole procedure to start and to connect to binance is very well explained, 90% of the doubts are solved only by watching the videos made by them and for the remaining 10% the support always responds very quickly and kindly.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Valerio De Angelis</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Valerio' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>My experience as a Profitfarmer</h6>
                                    <p>The platform is beginner friendly, Tim's case study (Tim is another member of Profitfarmers) inspired and helped me a lot, it's available on the website, at first i was little bit confused and made some mistakes which caused me some little loses, but after i studied Tim's case study it all changed, never mind the gain overwhelmed the loses, thanks to them and lucky us.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Naja'atu Nuhu</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Najaatu-Nuhu.png' alt="Testimonial - Naja'atu" />
                                        </span>
                                    </div>
                                </li>
                                <li className='testimony'>
                                    <h6>Sinds I have profitfarmer memberschip I…</h6>
                                    <p>Sinds I have profitfarmer memberschip I made in 3 weeks 20k with an account of 4k if you have a little bit of experience in trading then the signals are really helpful too get allot of profits and for beginners it is a great way too learn trading on a safer way</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Hanshofmans</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Hanshofmans.png' alt='Testimonial - Hanshofmans' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Highly recommend</h6>
                                    <p>I have been using ProfitFarmers for 2 weeks now, have to say that the signals are very accurate and profitable. I am a beginner at trading and lost money from trading by myself, and all the loss I've done was soon recovered by ProfitFarmers so I am very thankful for their service.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Xuan Ton</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/xuan.png' alt='Testimonial - Xuan' />
                                        </span>
                                    </div>
                                </li>
                                <li className='testimony'>
                                    <h6>Strong Recommendation</h6>
                                    <p>I've been using ProfitFarmers for a month now. In the beginning it took some work and effort to get into the system and get used to the settings. After watching the tutorials and adjusting the system to my needs I'm in the green. Strong recommendation to everyone who is not shy to educate himself on the matter.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Andreas W.</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Andreas' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Reliable company!</h6>
                                    <p>- Very easy to use</p>
                                    <p>- So far, a well performing AI trading system</p>
                                    <p>- Great and fast customer support (even on sunday!)</p>
                                    <p>Easy to use for new traders and also very helpfull for experienced traders.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Benny the Foxx</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt="Testimonial - Benny" />
                                        </span>
                                    </div>
                                </li>
                                <li className='testimony'>
                                    <h6>I've been looking for several years…</h6>
                                    <p>into various signalling services etc...</p>
                                    <p>ProfitFarmers, for me, has been fantastic so far. If you're looking for something that will make you rich overnight with no effort, look elsewhere (I would suggest starting in your dreams), this requires some understanding of how things work, and some time- but not too much.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Tim Griffith</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/cs-tim-griffith-img.png' alt='Testimonial - Tim' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>ProfitFarmers is the perfect crypto platform</h6>
                                    <p>for traders that are somewhat new to the game!</p>
                                    <p>The team is forthcoming, helpful, friendly and knowledgeable - they are always willing to help with your trading skills. Signals are good quality. Highly recommended!.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Juri</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Juri' />
                                        </span>
                                    </div>
                                </li>
                                <li className='testimony'>
                                    <h6>Very helpful,</h6>
                                    <p>knowledgeable and totally professional.</p>
                                    <p>A polite team who are a pleasure to deal with. Thank you ProfitFarmers.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Ian Holloway</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Ian-Holloway-Profile.jpg' alt='Testimonial - Ian' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>
                    </Carousel>
                }

                { (device === mediaDeviceSizes.xl) && 
                    <Carousel>
                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>The Holy Grail of crypto</h6>
                                    <p>I won't lie and over exaggerate, this is what I have personally been looking for. I've tried many paid signals, groups, indicators and a bunch of other garbages, lost money both on that and trades. But ProfitFarmers is a platform that I would recommend to anyone no matter the experience and knowledge.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Luka</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/luka.png' alt='Testimonial - Luka' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>New to cryptotrading - up 20 % after 1 week</h6>
                                    <p>So finally I got into the whole crypto-trading! I had been reading a lot and thought to myself, that profitfarmers seemed legit. I could not find any other platform with as transperent and good educational material. Today a week after the purchase I'm up to 20 % from a initial investment of &euro;5000. &euro;1K euro is pretty good in one week!</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Nicklas Olsen</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Nicklas-Olsen-Trustpilot.png' alt='Testimonial - Nicklas' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>aah finally.</h6>
                                    <p>I've been using profitfarmers since the beginning of the year and basically, im just really happy that i can just set my entry zone and targets, and im good to go. I dont even have to think of where to put my stop loss. Its ALMOST automatic trading. I still look at charts to see how my trades are going, but significantly less since i started using the platform.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Francisco J.</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Carlo-Jimenez-Trustpilot.png' alt='Testimonial - Francisco' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>A well-performing platform with great potential</h6>
                                    <p>I like using this tool, but wouldn't recommend to someone who's never bought some crypto before.</p>
                                    <p>I've been trading in crypto for 3 years now but I just used to buy and let the crypto sit there. With PF, I get to make money with money that I already spent</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Ulrich Dossou</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Ulrich-Dossou-Trustpilot.png' alt='Testimonial - Ulrich' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>First used their telegram signals and…</h6>
                                    <p>First used their telegram signals and then decided on paying for a subscription. Now a month into my membership I can confidently say it’s giving me a huge advantage for finding good trades. To the mooon</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Rasmus</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Rasmus-img.png' alt='Testimonial - Rasmus' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>Crypto trading on steroids lol</h6>
                                    <p>Helps me save time staring at charts. I can quickly find good trade set ups by using their signals and their copy trading function is top notch. They are continuously coming with new upgrades to the system so I'm excited to continue farming some profits.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Chris</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Chris-Trustpilot-profile-pic.png' alt='Testimonial - Chris' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Great Solution for Begginers and Traders</h6>
                                    <p>Finally a solution for both traders and beginners, I started using it only for clicking and copying signals and I was already impressed. The level of accuracy when it comes to understanding and correcting your investments is really unique.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Facu Espinosa</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Facu-Espinosa-Truspilot.png' alt='Testimonial - Facu' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>I LOVE PROFITFARMERS!</h6>
                                    <p>I love the set up with each target, closed trades etc. It's also easy to navigate at the website and that kind of stuff. Very good for people who are new in this cryptocurrency world. I will definitely continue my journey with profitfarmers!</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Hooman Attaran</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Hooman-Attaran-Truspilot.png' alt='Testimonial - Hooman' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>Help for absolut beginners</h6>
                                    <p>I had no idea about the crypto market, and as an absolut beginner I feel safe with ProfitFarmers. I am learning and experiencing without falling.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Rita</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Rita' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>I love this platform</h6>
                                    <p>I love this platform, I didn't know how to do any of this really. But when I stated understanding how it worked this platform helped me a lot. If you are new to crypto start with this platform and you will become intermediate quite fast. Definitely worth the investments have made so far <img className="emoji" alt="🙂" src="https://s.w.org/images/core/emoji/13.1.0/svg/1f642.svg" /></p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Oliver Le Roux</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Oliver-Le-Roux-Trustpilot.png' alt='Testimonial - Oliver' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>Very interesting application</h6>
                                    <p>Takes time to learn like anything else in life. Be patient when using it. Don't make big trades to start with... go slowly forward until you know more about crypto trading. Once you understand the systems relation to Binance and the coins and start to know better about it, it is a very good application.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Hej Med</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Hej' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>Great and really easy to use!</h6>
                                    <p>It is really great for beginners! This is the platform if you want to start at Crypto-trading!</p>
                                    <p>It has a really apprehensive UI and doesn't force any actions upon you. It uses a system, which monitors the market and then suggest some plans for you to use.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Niels R.</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Niels' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>I've been trading in crypto for 3 years now</h6>
                                    <p>and Profit Farmers has helped me gain even more. It took a few hours to fully master the tool, but totally worth it!</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Christine</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Christine' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>It's been really helpful</h6>
                                    <p>I'm not at all a pro trader but since I've traded a bit of stocks before, I decided to try PF. Really happy that I did, cause now I have a tool that takes most of the pain out of trading.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Signe Barner</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Signe' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>Good Trading Signals But You Need To Practice</h6>
                                    <p>1st thing, yes the platform is real and legitimate so don't worry. A good amount of trading signals are coming almost every day as well as newsletter updates and other things.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Freak5000</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Freak5000' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>I was a bit skeptic in the beginning</h6>
                                    <p>but after I learned how to use the platform, read the signals and use the trading tools ProfitFarmers basically just made it sooooo super easy for me. I'm not a pro trader - but this is definitely a step closer to the feeling of being a pro trader, no doubt!</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Frida Rizali</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Frida' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>Great Platform if you are new to Crypto - As I was</h6>
                                    <p>I came across ProfitFarmers and with my very sceptical head on (that's good in the Crypto Space) I took a look at what they had to offer. I then started on a monthly basis. I was impressed straightaway as I am a novice trader. The platform is easy to use, includes some great tools and the Buy Signals are realistic and spot on for the majority of the time.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Alan</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Alan' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>I love Profit Farmers</h6>
                                    <p>The signals have been accurate and I've enjoyed a high win rate using their signals. Even when a trade doesn't go as expected, the PF team communicates quickly and clearly to help you preserve capital, and usually the trades that get closed early are already well into profit...,</p>
                                    <p>The auto-trade functionality through Binance works like a dream.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Martin Oosthuizen</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Martin' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>ProfitFarmers, crypto tool that works</h6>
                                    <p>I was a bit skeptical at the beginning, even on my first few trades with Profitfarmers signals, but that was unfounded. I've been working with the signals for about 2 months and it seems to work - about 70% of my used signals were profitable</p>
                                    <p>Also the additional trading tools (RSI & Price - Scanner) are a benefit and very helpful in combination with the signals, makes it more powerful.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Jan Hortsman</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/jan-hortsman.png' alt='Testimonial - Jan' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>satisfied</h6>
                                    <p>I have been following your signals for almost a month now, and I must say that they are really very good I was very amazed in general and also on how the platform is organized. the whole procedure to start and to connect to binance is very well explained, 90% of the doubts are solved only by watching the videos made by them and for the remaining 10% the support always responds very quickly and kindly.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Valerio De Angelis</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Valerio' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>My experience as a Profitfarmer</h6>
                                    <p>The platform is beginner friendly, Tim's case study (Tim is another member of Profitfarmers) inspired and helped me a lot, it's available on the website, at first i was little bit confused and made some mistakes which caused me some little loses, but after i studied Tim's case study it all changed, never mind the gain overwhelmed the loses, thanks to them and lucky us.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Naja'atu Nuhu</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Najaatu-Nuhu.png' alt="Testimonial - Naja'atu" />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Sinds I have profitfarmer memberschip I…</h6>
                                    <p>Sinds I have profitfarmer memberschip I made in 3 weeks 20k with an account of 4k if you have a little bit of experience in trading then the signals are really helpful too get allot of profits and for beginners it is a great way too learn trading on a safer way</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Hanshofmans</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Hanshofmans.png' alt='Testimonial - Hanshofmans' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>Highly recommend</h6>
                                    <p>I have been using ProfitFarmers for 2 weeks now, have to say that the signals are very accurate and profitable. I am a beginner at trading and lost money from trading by myself, and all the loss I've done was soon recovered by ProfitFarmers so I am very thankful for their service.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Xuan Ton</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/xuan.png' alt='Testimonial - Xuan' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>Strong Recommendation</h6>
                                    <p>I've been using ProfitFarmers for a month now. In the beginning it took some work and effort to get into the system and get used to the settings. After watching the tutorials and adjusting the system to my needs I'm in the green. Strong recommendation to everyone who is not shy to educate himself on the matter.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Andreas W.</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Andreas' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Reliable company!</h6>
                                    <p>- Very easy to use</p>
                                    <p>- So far, a well performing AI trading system</p>
                                    <p>- Great and fast customer support (even on sunday!)</p>
                                    <p>Easy to use for new traders and also very helpfull for experienced traders.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Benny the Foxx</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt="Testimonial - Benny" />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>I've been looking for several years…</h6>
                                    <p>into various signalling services etc...</p>
                                    <p>ProfitFarmers, for me, has been fantastic so far. If you're looking for something that will make you rich overnight with no effort, look elsewhere (I would suggest starting in your dreams), this requires some understanding of how things work, and some time- but not too much.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Tim Griffith</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/cs-tim-griffith-img.png' alt='Testimonial - Tim' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>ProfitFarmers is the perfect crypto platform</h6>
                                    <p>for traders that are somewhat new to the game!</p>
                                    <p>The team is forthcoming, helpful, friendly and knowledgeable - they are always willing to help with your trading skills. Signals are good quality. Highly recommended!.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Juri</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Juri' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>
                    </Carousel>
                }

                { (device === mediaDeviceSizes.xxl) && 
                    <Carousel>
                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>The Holy Grail of crypto</h6>
                                    <p>I won't lie and over exaggerate, this is what I have personally been looking for. I've tried many paid signals, groups, indicators and a bunch of other garbages, lost money both on that and trades. But ProfitFarmers is a platform that I would recommend to anyone no matter the experience and knowledge.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Luka</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/luka.png' alt='Testimonial - Luka' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>New to cryptotrading - up 20 % after 1 week</h6>
                                    <p>So finally I got into the whole crypto-trading! I had been reading a lot and thought to myself, that profitfarmers seemed legit. I could not find any other platform with as transperent and good educational material. Today a week after the purchase I'm up to 20 % from a initial investment of &euro;5000. &euro;1K euro is pretty good in one week!</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Nicklas Olsen</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Nicklas-Olsen-Trustpilot.png' alt='Testimonial - Nicklas' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>aah finally.</h6>
                                    <p>I've been using profitfarmers since the beginning of the year and basically, im just really happy that i can just set my entry zone and targets, and im good to go. I dont even have to think of where to put my stop loss. Its ALMOST automatic trading. I still look at charts to see how my trades are going, but significantly less since i started using the platform.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Francisco J.</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Carlo-Jimenez-Trustpilot.png' alt='Testimonial - Francisco' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>A well-performing platform with great potential</h6>
                                    <p>I like using this tool, but wouldn't recommend to someone who's never bought some crypto before.</p>
                                    <p>I've been trading in crypto for 3 years now but I just used to buy and let the crypto sit there. With PF, I get to make money with money that I already spent</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Ulrich Dossou</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Ulrich-Dossou-Trustpilot.png' alt='Testimonial - Ulrich' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>First used their telegram signals and…</h6>
                                    <p>First used their telegram signals and then decided on paying for a subscription. Now a month into my membership I can confidently say it’s giving me a huge advantage for finding good trades. To the mooon</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Rasmus</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Rasmus-img.png' alt='Testimonial - Rasmus' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>Crypto trading on steroids lol</h6>
                                    <p>Helps me save time staring at charts. I can quickly find good trade set ups by using their signals and their copy trading function is top notch. They are continuously coming with new upgrades to the system so I'm excited to continue farming some profits.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Chris</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Chris-Trustpilot-profile-pic.png' alt='Testimonial - Chris' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>Great Solution for Begginers and Traders</h6>
                                    <p>Finally a solution for both traders and beginners, I started using it only for clicking and copying signals and I was already impressed. The level of accuracy when it comes to understanding and correcting your investments is really unique.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Facu Espinosa</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Facu-Espinosa-Truspilot.png' alt='Testimonial - Facu' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>I LOVE PROFITFARMERS!</h6>
                                    <p>I love the set up with each target, closed trades etc. It's also easy to navigate at the website and that kind of stuff. Very good for people who are new in this cryptocurrency world. I will definitely continue my journey with profitfarmers!</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Hooman Attaran</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Hooman-Attaran-Truspilot.png' alt='Testimonial - Hooman' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Help for absolut beginners</h6>
                                    <p>I had no idea about the crypto market, and as an absolut beginner I feel safe with ProfitFarmers. I am learning and experiencing without falling.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Rita</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Rita' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>I love this platform</h6>
                                    <p>I love this platform, I didn't know how to do any of this really. But when I stated understanding how it worked this platform helped me a lot. If you are new to crypto start with this platform and you will become intermediate quite fast. Definitely worth the investments have made so far <img className="emoji" alt="🙂" src="https://s.w.org/images/core/emoji/13.1.0/svg/1f642.svg" /></p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Oliver Le Roux</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Oliver-Le-Roux-Trustpilot.png' alt='Testimonial - Oliver' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>Very interesting application</h6>
                                    <p>Takes time to learn like anything else in life. Be patient when using it. Don't make big trades to start with... go slowly forward until you know more about crypto trading. Once you understand the systems relation to Binance and the coins and start to know better about it, it is a very good application.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Hej Med</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Hej' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>Great and really easy to use!</h6>
                                    <p>It is really great for beginners! This is the platform if you want to start at Crypto-trading!</p>
                                    <p>It has a really apprehensive UI and doesn't force any actions upon you. It uses a system, which monitors the market and then suggest some plans for you to use.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Niels R.</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Niels' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>I've been trading in crypto for 3 years now</h6>
                                    <p>and Profit Farmers has helped me gain even more. It took a few hours to fully master the tool, but totally worth it!</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Christine</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Christine' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>It's been really helpful</h6>
                                    <p>I'm not at all a pro trader but since I've traded a bit of stocks before, I decided to try PF. Really happy that I did, cause now I have a tool that takes most of the pain out of trading.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Signe Barner</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Signe' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>Good Trading Signals But You Need To Practice</h6>
                                    <p>1st thing, yes the platform is real and legitimate so don't worry. A good amount of trading signals are coming almost every day as well as newsletter updates and other things.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Freak5000</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Freak5000' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>I was a bit skeptic in the beginning</h6>
                                    <p>but after I learned how to use the platform, read the signals and use the trading tools ProfitFarmers basically just made it sooooo super easy for me. I'm not a pro trader - but this is definitely a step closer to the feeling of being a pro trader, no doubt!</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Frida Rizali</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Frida' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Great Platform if you are new to Crypto - As I was</h6>
                                    <p>I came across ProfitFarmers and with my very sceptical head on (that's good in the Crypto Space) I took a look at what they had to offer. I then started on a monthly basis. I was impressed straightaway as I am a novice trader. The platform is easy to use, includes some great tools and the Buy Signals are realistic and spot on for the majority of the time.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Alan</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Alan' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>I love Profit Farmers</h6>
                                    <p>The signals have been accurate and I've enjoyed a high win rate using their signals. Even when a trade doesn't go as expected, the PF team communicates quickly and clearly to help you preserve capital, and usually the trades that get closed early are already well into profit...,</p>
                                    <p>The auto-trade functionality through Binance works like a dream.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Martin Oosthuizen</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Martin' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>ProfitFarmers, crypto tool that works</h6>
                                    <p>I was a bit skeptical at the beginning, even on my first few trades with Profitfarmers signals, but that was unfounded. I've been working with the signals for about 2 months and it seems to work - about 70% of my used signals were profitable</p>
                                    <p>Also the additional trading tools (RSI & Price - Scanner) are a benefit and very helpful in combination with the signals, makes it more powerful.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Jan Hortsman</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/jan-hortsman.png' alt='Testimonial - Jan' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>satisfied</h6>
                                    <p>I have been following your signals for almost a month now, and I must say that they are really very good I was very amazed in general and also on how the platform is organized. the whole procedure to start and to connect to binance is very well explained, 90% of the doubts are solved only by watching the videos made by them and for the remaining 10% the support always responds very quickly and kindly.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Valerio De Angelis</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Valerio' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>My experience as a Profitfarmer</h6>
                                    <p>The platform is beginner friendly, Tim's case study (Tim is another member of Profitfarmers) inspired and helped me a lot, it's available on the website, at first i was little bit confused and made some mistakes which caused me some little loses, but after i studied Tim's case study it all changed, never mind the gain overwhelmed the loses, thanks to them and lucky us.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Naja'atu Nuhu</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Najaatu-Nuhu.png' alt="Testimonial - Naja'atu" />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>Sinds I have profitfarmer memberschip I…</h6>
                                    <p>Sinds I have profitfarmer memberschip I made in 3 weeks 20k with an account of 4k if you have a little bit of experience in trading then the signals are really helpful too get allot of profits and for beginners it is a great way too learn trading on a safer way</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Hanshofmans</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Hanshofmans.png' alt='Testimonial - Hanshofmans' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>Highly recommend</h6>
                                    <p>I have been using ProfitFarmers for 2 weeks now, have to say that the signals are very accurate and profitable. I am a beginner at trading and lost money from trading by myself, and all the loss I've done was soon recovered by ProfitFarmers so I am very thankful for their service.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Xuan Ton</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/xuan.png' alt='Testimonial - Xuan' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>Strong Recommendation</h6>
                                    <p>I've been using ProfitFarmers for a month now. In the beginning it took some work and effort to get into the system and get used to the settings. After watching the tutorials and adjusting the system to my needs I'm in the green. Strong recommendation to everyone who is not shy to educate himself on the matter.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Andreas W.</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Andreas' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='testimony-list'>
                                <li className='testimony'>
                                    <h6>Reliable company!</h6>
                                    <p>- Very easy to use</p>
                                    <p>- So far, a well performing AI trading system</p>
                                    <p>- Great and fast customer support (even on sunday!)</p>
                                    <p>Easy to use for new traders and also very helpfull for experienced traders.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Benny the Foxx</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt="Testimonial - Benny" />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>I've been looking for several years…</h6>
                                    <p>into various signalling services etc...</p>
                                    <p>ProfitFarmers, for me, has been fantastic so far. If you're looking for something that will make you rich overnight with no effort, look elsewhere (I would suggest starting in your dreams), this requires some understanding of how things work, and some time- but not too much.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Tim Griffith</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/cs-tim-griffith-img.png' alt='Testimonial - Tim' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>ProfitFarmers is the perfect crypto platform</h6>
                                    <p>for traders that are somewhat new to the game!</p>
                                    <p>The team is forthcoming, helpful, friendly and knowledgeable - they are always willing to help with your trading skills. Signals are good quality. Highly recommended!.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Juri</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/default.png' alt='Testimonial - Juri' />
                                        </span>
                                    </div>
                                </li>

                                <li className='testimony'>
                                    <h6>Very helpful,</h6>
                                    <p>knowledgeable and totally professional.</p>
                                    <p>A polite team who are a pleasure to deal with. Thank you ProfitFarmers.</p>
                                    <div className='author'>
                                        <span className='user-name'>
                                            <label>Ian Holloway</label>
                                            <img src='/images/offers/trustpilot-5-stars.png' alt='TrustPilot Score' />
                                        </span>
                                        <span className='user-avatar'>
                                            <img src='/images/testimonials/Ian-Holloway-Profile.jpg' alt='Testimonial - Ian' />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>
                    </Carousel>
                }
            </div>
        </TestimonialsStyle>
    )
}