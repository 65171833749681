import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const CommunityInvitation = styled.div`
    position: relative;
    background-image: url('/images/community/community-bg.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    border-radius: 10px;
    padding: 40px 30px 30px;
    color: #0F0F0F;

    .btn-exit {
        position: absolute;
        right: 10px;
        top: 10px;
        display: inline-block;
        width: 30px;
        height: 30px;
        background: none;
        outline: none;
        border: 2px solid #0F0F0F;
        border-radius: 15px;
        box-shadow: none;
        background-color: transparent;
        color: #0F0F0F;
        text-align: center;

        svg {
            margin-bottom: -1px;
        }
    }

    .invitation-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .invitation-header {
            width: 100%;
            margin: 0 auto 25px;

            img {
                width: 100%;
            }
        }

        .invitation-body {
            width: 100%;
            padding-left: 0;

            h2 {
                font-size: 24px;
                font-weight: 800;
                max-width: 100%;
                border-bottom: 1px solid #3E3E3E;
                padding-bottom: 15px;
                margin-bottom: 15px;
                text-align: center;
            }

            p {
                font-weight: 600;
                text-align: center;
            }

            .invitation-footer {
                display: flex;
                flex-direction: column;
                align-items: center;

                button {
                    background: none;
                    background-color: #141414;
                    border: none;
                    outline: none;
                    box-shadow: none;
                    color: #fff;
                    padding: 8px 16px;
                    margin-bottom: 10px;
                }

                .form-group {
                    margin-bottom: 0;

                    .form-check {
                        .form-check-input {
                            margin-top: 6px;
                        }

                        label {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        .invitation-content {
            flex-direction: row;

            .invitation-header {
                width: 257px;
            }

            .invitation-body {
                width: calc(100% - 257px);
                padding-left: 25px;

                h2 {
                    font-size: 27px;
                    max-width: 100%;
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        .invitation-content {
            .invitation-body {
                h2 {
                    font-size: 28px;
                    max-width: 100%;
                }

                p {
                    font-size: 18px;
                }

                .invitation-footer {
                    button {
                        font-size: 20px;
                        font-weight: 700;
                        padding: 16px 20px;
                    }
                }
            }
        }
    }
`