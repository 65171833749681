import styled from 'styled-components'

export const FooterContainer = styled.footer`
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
  display: none;
  h4 {
    color: #d2d2d2;
    font-size: 16px;
    margin: 10px 0 20px;
  }
  .footer-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-wrap: wrap;
    img {
      height: 25px;
      padding: 0 6.5px;
    }
  }
`
