import React from 'react'
import {
    NotificationContentWrapper, CoinsImg, OrderStateIcon, NotificationContentContainer,
    NotificationContent, NotificationTime, UnReadBadge,
    NotificationCard
} from '../../pages/Notification/styled_components'
import { getSpecificHistoryTime, setCoinLogo, setDefaultImage, isNotNull, getCoin } from '../../utils'

export class NotificationItems extends React.PureComponent {
    handleMarkAsRead = (data, e) => {
        if (isNotNull(data)) {
            const { actorId, isRead, type } = data

            // handle marked as read notifications
            if (isNotNull(isRead) && isRead === false)
                this.props.handleRead(data, e)

            // // redirect notification
            if (isNotNull(actorId) && isNotNull(type)) {
                this.props.handleRedirect(data)
                this.props.hide()
            }
        }
    }

    renderIcon = () => {
        const { type, symbol } = this.props
        if (isNotNull(type)) {
            switch (type) {
                default: {
                    let logoSrc = getCoin(symbol)
                    let imgSrc = setCoinLogo(logoSrc)
                    return (<CoinsImg src={imgSrc} className={logoSrc} icon={logoSrc} onError={setDefaultImage} />)
                }
                case 5: {
                    return (
                        <OrderStateIcon className="automation">
                            <i className="fas fa-bolt"></i>
                        </OrderStateIcon>
                    )
                }
                case 6: {
                    return (
                        <OrderStateIcon className="success">
                            <i className="fas fa-check"></i>
                        </OrderStateIcon>
                    )
                }
                case 7: {
                    return (
                        <OrderStateIcon className="failed">
                            <i className="fas fa-exclamation"></i>
                        </OrderStateIcon>
                    )
                }
            }
        }
    }

    render() {
        const { isRead, message, dateCreated, selectedClass } = this.props
        let date = getSpecificHistoryTime(dateCreated)

        return (
            <NotificationContentWrapper>
                <NotificationCard unRead={isRead} onClick={(e) => this.handleMarkAsRead(this.props, e)} className={`${selectedClass} ${isRead} notification-card`}>
                    {this.renderIcon()}
                    <NotificationContentContainer>
                        <NotificationContent>{message}</NotificationContent>
                        <NotificationTime unRead={isRead}>
                            {date}
                        </NotificationTime>
                    </NotificationContentContainer>
                    <UnReadBadge unRead={isRead} />
                </NotificationCard>
            </NotificationContentWrapper>
        )
    }
}
