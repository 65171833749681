import React from 'react'
import { connect } from 'react-redux'
import { tradingActions } from '../../redux/actions'
import { tradingServices } from '../../services'
import { isNotNull, isNull, onSetNewState } from '../../utils'
import { tradingActionTypes } from '../../redux/CONSTANTS'

class TerminalLoginButton extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isPremiumAccess: undefined,
            autoLogin: {
                link: null,
                expiry: null,
                locked: false
            },
            hasConfiguredTradingTerminal: null
        }

        this.mounted = false
    }
    
    componentDidMount() {
        this.mounted = true
        const { dispatch, authentication } = this.props
        const { user } = authentication

        if (isNotNull(user)) {
            dispatch(tradingActions.getAutologin())

            onSetNewState(this, { 
                isPremiumAccess: user.isPaid,
                hasConfiguredTradingTerminal: user.isAutoTradingAllowed
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { dispatch, authentication } = this.props
            const { user } = authentication
            const { isLoading, isPremiumAccess, hasConfiguredTradingTerminal } = this.state

            if (isLoading && prevProps.hasConfiguredTradingTerminal !== hasConfiguredTradingTerminal) {
                onSetNewState(this, {
                    isLoading: false
                })
            }

            if (isNull(isPremiumAccess) && 
                isNotNull(user)) {
                dispatch(tradingActions.getAutologin())

                onSetNewState(this, { 
                    isPremiumAccess: user.isPaid
                })
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    handleClick = (e) => {
        e.preventDefault()
        
        const { dispatch } = this.props
        const { isLoading, isPremiumAccess, autoLogin, hasConfiguredTradingTerminal } = this.state

        if (!isPremiumAccess)
            window.location.href = '/subscription-plans/pro/subscribe'

        if (!isLoading) {
            onSetNewState(this, {
                isLoading: true
            }, () => {
                if (hasConfiguredTradingTerminal) {
                    if (autoLogin.expiry <= Date.now()) {
                        tradingServices.getAutologin()
                            .then(res => {
                                let newLink = ''
                                if (isNotNull(res) && isNotNull(res.loginUrl))
                                    newLink = res.loginUrl.replace('www.', 'profitfarmers.')

                                autoLogin.link = newLink
                                autoLogin.expiry = res.expiry

                                onSetNewState(this, {
                                    autoLogin
                                }, () => {
                                    dispatch({ type: tradingActionTypes.TRADING_GET_AUTOLOGIN_SUCCESS, autoLogin })
                                    this.openTradingTerminal(autoLogin.link)

                                    onSetNewState(this, {
                                        isLoading: false
                                    })
                                })
                            })
                    } else {
                        this.openTradingTerminal(autoLogin.link)

                        onSetNewState(this, {
                            isLoading: false
                        })
                    }
                }
            })            
        }
    }

    openTradingTerminal = (terminalLink) => {
        if (isNotNull(terminalLink)) {
            const openWindow = window.open(terminalLink, '_blank')
            if (openWindow)
                window.focus()
            else {
                window.setTimeout(() => {
                    if (openWindow)
                        openWindow.focus()
                }, 100)
            }
        }
    }

    render() {
        const { isLoading, isPremiumAccess } = this.state
        return (
            <button type="button" className='menu-btn' onClick={(e) => this.handleClick(e)} disabled={isLoading}>
                <span className='icon'>
                    <i className="fas fa-desktop"></i>
                </span>
                <span className='text'>Trade Manually { isNotNull(isPremiumAccess) && !isPremiumAccess && <small className='pro-feature'>PRO</small>}</span>
            </button>
        )
    }
}

function mapStateToProps(state) {
    const { authentication } = state
    return {
        authentication
    }
}

const connectedTerminalLoginButton = connect(mapStateToProps)(TerminalLoginButton)
export { connectedTerminalLoginButton as TerminalLoginButton }