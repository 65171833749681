import React from 'react'
import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import './styles/early-closed-trade-modal.scss'
import { OrderResultDetails } from './OrderResultDetails'
import { isNotNull } from '../../../../utils'

export const OrderResultReviewModal = (props) => {
    const { showModal, data } = props.state
    const { onClose } = props.eventHandlers

    const renderModalBody = () => {
        if (isNotNull(data)) {
            return (
                <>
                    <div className="close" style={{ zIndex: '1' }} onClick={(e) => onClose(e)}>
                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                    </div>

                    <OrderResultDetails 
                        data={data}
                        eventHandlers={{
                            close: (e) => onClose(e)
                        }} />
                </>
            )
        }
    }

    return (
        <Modal
            id="order-result-review-modal"
            className={isNotNull(data) && data.message.includes('succeeded') ? 'trade-closed-early-review-modal' : 'trade-closed-early-modal' }
            show={showModal}
            aria-labelledby="orderResultReviewModal" 
            aria-hidden="true" 
            backdrop={'static'}
            keyboard={false}
            centered>
            <Modal.Body>
                {renderModalBody()}
            </Modal.Body>
        </Modal>
    )
}