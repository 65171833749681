import { tradeDoctorSessionActionTypes } from '../CONSTANTS'
import { tradeDoctorSessionServices } from '../../services'

function getSessionInfo() {
    return dispatch => {
        dispatch(request())

        tradeDoctorSessionServices.getSessionInfo()
            .then(res => dispatch(success(res)), error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: tradeDoctorSessionActionTypes.GET_TRADEDOC_SESSION_REQUEST } }
    function success(response) { return { type: tradeDoctorSessionActionTypes.GET_TRADEDOC_SESSION_SUCCESS, response } }
    function failed(error) { return { type: tradeDoctorSessionActionTypes.GET_TRADEDOC_SESSION_FAILED, error } }
}

function saveSessionInfo(data) {
    return dispatch => {
        dispatch(request())

        tradeDoctorSessionServices.updateSessionInfo(data)
            .then(res => dispatch(success(res)), error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: tradeDoctorSessionActionTypes.SAVE_TRADEDOC_SESSION_REQUEST } }
    function success(response) { return { type: tradeDoctorSessionActionTypes.SAVE_TRADEDOC_SESSION_SUCCESS, response } }
    function failed(error) { return { type: tradeDoctorSessionActionTypes.SAVE_TRADEDOC_SESSION_FAILED, error } }
}

export const tradeDoctorSessionActions = {
    getSessionInfo,
    saveSessionInfo
}