import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import {
    Button, Form, InputGroup,
    Container, Row, Col, Card
} from 'react-bootstrap'
import { Form as FormFinal, Field } from "react-final-form"

import { PasswordHelper } from '../../../../components'

import {
    LeadMainWrapper, LeadWrapper, LeadContainer,
    LeadTitleWrapper, LeadTitle, LeadMessage,
    LeadFormWrapper, LeadFormText,
    LeadFormTitle, LeadMainContainer, LogoContainer, LogoWrapper,
    Logo
} from './styled_components/LeadSecurityStyle'

import { isNull, passwordValidator } from '../../../../utils'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const LeadSecuritView = (props) => {
    const { user } = props.authentication
    const $this = props.self
    const $state = props.state

    let oldPassCriteria = {
        letter: false,
        capital: false,
        number: false,
        special: false,
        size: false
    }

    let newPassCriteria = {
        letter: false,
        capital: false,
        number: false,
        special: false,
        size: false
    }

    let confirmPassCriteria = {
        letter: false,
        capital: false,
        number: false,
        special: false,
        size: false,
        match: false
    }

    const onViewPasswordAsText = (fieldName) => {
        let getState = $state
        getState[fieldName] = {
            eyeIcon: getState[fieldName].eyeIcon === faEye ? faEyeSlash : faEye,
            type: getState[fieldName].type === "password" ? "text" : "password"
        }
        $this.setState(getState)
    }

    const onValidate = values => {
        const errors = {}

        if (isNull(values.oldPassword)) {
            errors.oldPassword = 'Old Password is required'
            oldPassCriteria = {
                letter: false,
                capital: false,
                number: false,
                special: false,
                size: false
            }
        }
        else {
            oldPassCriteria.letter = passwordValidator.hasLowercase(values.oldPassword)
            oldPassCriteria.capital = passwordValidator.hasUppercase(values.oldPassword)
            oldPassCriteria.number = passwordValidator.hasNumeric(values.oldPassword)
            oldPassCriteria.special = passwordValidator.hasSpecialChar(values.oldPassword)
            oldPassCriteria.size = passwordValidator.isWithinMinAndMaxLength(values.oldPassword)

            if (!(oldPassCriteria.letter &&
                oldPassCriteria.capital &&
                oldPassCriteria.number &&
                oldPassCriteria.special &&
                oldPassCriteria.size))
                errors.oldPassword = "Invalid password"
        }

        if (isNull(values.newPassword)) {
            errors.newPassword = 'New Password is required'
            newPassCriteria = {
                letter: false,
                capital: false,
                number: false,
                special: false,
                size: false
            }
        }
        else {
            newPassCriteria.letter = passwordValidator.hasLowercase(values.newPassword)
            newPassCriteria.capital = passwordValidator.hasUppercase(values.newPassword)
            newPassCriteria.number = passwordValidator.hasNumeric(values.newPassword)
            newPassCriteria.special = passwordValidator.hasSpecialChar(values.newPassword)
            newPassCriteria.size = passwordValidator.isWithinMinAndMaxLength(values.newPassword)

            if (!(newPassCriteria.letter &&
                newPassCriteria.capital &&
                newPassCriteria.number &&
                newPassCriteria.special &&
                newPassCriteria.size))
                errors.newPassword = "Invalid password"
        }

        if (isNull(values.confirmPassword)) {
            errors.confirmPassword = 'Cofirm Password is required'
            confirmPassCriteria = {
                letter: false,
                capital: false,
                number: false,
                special: false,
                size: false,
                match: false
            }
        } else {
            confirmPassCriteria.letter = passwordValidator.hasLowercase(values.confirmPassword)
            confirmPassCriteria.capital = passwordValidator.hasUppercase(values.confirmPassword)
            confirmPassCriteria.number = passwordValidator.hasNumeric(values.confirmPassword)
            confirmPassCriteria.special = passwordValidator.hasSpecialChar(values.confirmPassword)
            confirmPassCriteria.size = passwordValidator.isWithinMinAndMaxLength(values.confirmPassword)
            confirmPassCriteria.match = values.newPassword !== values.confirmPassword ? false : true

            if (!(confirmPassCriteria.letter &&
                confirmPassCriteria.capital &&
                confirmPassCriteria.number &&
                confirmPassCriteria.special &&
                confirmPassCriteria.size &&
                confirmPassCriteria.match))
                errors.confirmPassword = "Invalid password"
        }

        return errors
    }

    const onSubmit = async values => {
        await sleep(300)
        props.submit(values)
        $this.setState({
            isSubmitted: true
        })
    }

    const onSpaceKeyPress = (e) => {
        var keyCode = (e.which) ? e.which : e.keyCode;
        if (keyCode === 32)
            e.preventDefault()
    }

    return (
        <LeadMainWrapper>
            <LeadMainContainer>
                <LogoWrapper className="top-header">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <LogoContainer className="col-md-6 col-lg-4">
                                    <Logo className="img-fluid" src="/images/logo/top-header.png" alt="" />
                                </LogoContainer>
                            </Col>
                        </Row>
                    </Container>
                </LogoWrapper>
                <main>
                    <Container>
                        <Row className="lead-changePass-row">
                            <Col sm={12} md={10} lg={10}>
                                <Card>
                                    <Card.Body>
                                        <LeadWrapper>
                                            <LeadContainer>
                                                <LeadTitleWrapper>
                                                    <LeadTitle>You are almost there!</LeadTitle>
                                                    <LeadMessage>When you created your ProfitFarmers account, we didn’t ask you to set a password. It is time to do that now.</LeadMessage>
                                                </LeadTitleWrapper>
                                            </LeadContainer>
                                            <LeadContainer>
                                                <LeadFormWrapper>
                                                    <FormFinal
                                                        onSubmit={onSubmit}
                                                        validate={onValidate}
                                                        decorators={[props.decorators]}
                                                        render={({ handleSubmit, form, pristine, values }) => (
                                                            <Form ref={forms => $this.leadForm = forms} onSubmit={(event) => { const promise = handleSubmit(event); promise && promise.then(() => { form.reset(values); }); return promise; }} className="mt-3" id="leadSecurity">
                                                                <Form.Group as={Row} controlId="formExistingPassword">
                                                                    <Form.Label column sm={4}>Default Password</Form.Label>
                                                                    <Col sm={6}>
                                                                        <Field name="oldPassword" type={$state.oldPassword.type}>
                                                                            {({ input, meta, type }) => (
                                                                                <>
                                                                                    <InputGroup ref={oldPassCriteria => $this.refOld = oldPassCriteria} className={`${meta.error && meta.touched ? 'invalid-container' : ''}`}>
                                                                                        <Form.Control
                                                                                            onKeyPress={onSpaceKeyPress}
                                                                                            maxLength="12"
                                                                                            type={type}
                                                                                            {...input}
                                                                                        />
                                                                                        <InputGroup.Prepend onClick={() => { onViewPasswordAsText("oldPassword") }}>
                                                                                            <InputGroup.Text>
                                                                                                <FontAwesomeIcon icon={$state.oldPassword.eyeIcon} className="eyeIcon" />
                                                                                            </InputGroup.Text>
                                                                                        </InputGroup.Prepend>
                                                                                        <PasswordHelper
                                                                                            show={meta.active && meta.touched && meta.error && meta.visited}
                                                                                            target={$this.refOld}
                                                                                            container={$this.refOld}
                                                                                            placement="bottom"
                                                                                            data={isNull(input.value) ? props.initialPasswordHelperState : oldPassCriteria}
                                                                                        />
                                                                                    </InputGroup>
                                                                                </>
                                                                            )}
                                                                        </Field>
                                                                    </Col>
                                                                </Form.Group>

                                                                <LeadFormText className="italized">
                                                                    This was sent into your email address. Copy that code and paste it into the box.
                                                                    </LeadFormText>

                                                                <LeadFormTitle>
                                                                    Choose new password
                                                                    </LeadFormTitle>

                                                                <LeadFormText>
                                                                    You’ll use your email address (<strong>{user.userName}</strong>) and a password to sign in to ProfitFarmers.
                                                                    </LeadFormText>

                                                                <Form.Group as={Row} controlId="formPassword">
                                                                    <Form.Label column sm={4}>New password</Form.Label>
                                                                    <Col sm={6}>
                                                                        <Field name="newPassword" type={$state.newPassword.type}>
                                                                            {({ input, meta, type }) => (
                                                                                <>
                                                                                    <InputGroup ref={newPass => $this.refNew = newPass} className={`${meta.error && meta.touched ? 'invalid-container' : ''}`}>
                                                                                        <Form.Control
                                                                                            onKeyPress={onSpaceKeyPress}
                                                                                            maxLength="12"
                                                                                            type={type}
                                                                                            {...input}
                                                                                        />
                                                                                        <InputGroup.Prepend onClick={() => { onViewPasswordAsText("newPassword") }}>
                                                                                            <InputGroup.Text>
                                                                                                <FontAwesomeIcon icon={$state.newPassword.eyeIcon} className="eyeIcon" />
                                                                                            </InputGroup.Text>
                                                                                        </InputGroup.Prepend>
                                                                                        <PasswordHelper
                                                                                            show={meta.active && meta.touched && meta.error && meta.visited}
                                                                                            target={$this.refNew}
                                                                                            container={$this.refNew}
                                                                                            placement="bottom"
                                                                                            data={isNull(input.value) ? props.initialPasswordHelperState : newPassCriteria}
                                                                                        />
                                                                                    </InputGroup>
                                                                                </>
                                                                            )}
                                                                        </Field>
                                                                    </Col>
                                                                </Form.Group>

                                                                <Form.Group as={Row} controlId="formConfirmPassword">
                                                                    <Form.Label column sm={4}>Re-enter new password</Form.Label>
                                                                    <Col sm={6}>
                                                                        <Field name="confirmPassword" type={$state.confirmPassword.type}>
                                                                            {({ input, meta, type }) => (
                                                                                <>
                                                                                    <InputGroup ref={confirmPass => $this.refConfirm = confirmPass} className={`${meta.error && meta.touched ? 'invalid-container' : ''}`}>
                                                                                        <Form.Control
                                                                                            onKeyPress={onSpaceKeyPress}
                                                                                            type={type}
                                                                                            maxLength="12"
                                                                                            {...input}
                                                                                        />
                                                                                        <InputGroup.Prepend onClick={() => { onViewPasswordAsText("confirmPassword") }}>
                                                                                            <InputGroup.Text>
                                                                                                <FontAwesomeIcon icon={$state.confirmPassword.eyeIcon} className="eyeIcon" />
                                                                                            </InputGroup.Text>
                                                                                        </InputGroup.Prepend>
                                                                                        <PasswordHelper
                                                                                            show={meta.active && meta.touched && meta.error && meta.visited}
                                                                                            target={$this.refConfirm}
                                                                                            container={$this.refConfirm}
                                                                                            placement="bottom"
                                                                                            confirm={true}
                                                                                            data={isNull(input.value) ? props.initialPasswordHelperState : confirmPassCriteria}
                                                                                        />
                                                                                    </InputGroup>
                                                                                </>
                                                                            )}
                                                                        </Field>
                                                                    </Col>
                                                                </Form.Group>

                                                                <Form.Group controlId="formBasicSubmit" className="lead-submit">
                                                                    {
                                                                        $state.isSubmitted
                                                                            ? <Button variant="primary" className="mt-3 mb-3 save-btn">
                                                                                <i className="fas fa-spinner fa-pulse"></i>
                                                                            </Button>
                                                                            : <Button type="submit" variant="primary" className="mt-3 mb-3 save-btn" disabled={pristine}>
                                                                                ALL DONE
                                                                            </Button>
                                                                    }
                                                                </Form.Group>
                                                            </Form>
                                                        )}
                                                    />
                                                </LeadFormWrapper>
                                            </LeadContainer>
                                        </LeadWrapper>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </main>
            </LeadMainContainer>
        </LeadMainWrapper>
    )
}