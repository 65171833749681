export const minOrderConfig = {
    "BTC": 0.0001,
    "USDT": 11, // instead of 10 move to 11 as per task https://app.asana.com/0/1154544479029366/1175068129176787
    "USDTFUTURES": 50
}

export const tradeFormErrorMessages = {
    quantity: {
        invalidInput: "Invalid quantity input",
        notEnough: "Quantity is too small to spread over the targets",
        mustBeAboveMinBtcOrder: (btc) => `Minimum order size for BTC paired coins is ₿${btc}`,
        mustBeAboveMinUsdOrder: (curr, usd) => `Minimum order size for ${curr} paired coins is ${curr === 'USDT' ? `$${usd}` : '11 BUSD'}`
    },
    entryPrice: {
        invalidInput: "Invalid entry price",
        long: {            
            mustBeLessThanOrEqualToAskPrice: "Entry price must be less than or equal to ask price",
            mustBeGreaterThanOrEqualToAskPrice: "Entry price must be greater than or equal to ask price"
        },
        short: {
            mustBeGreaterThanBidPrice: "Entry price must be greater than or equal to bid price"
        }
    },
    multiEntries: {
        invalidEntry: (index) => `Invalid entry price ${index + 1}`,
        long: {
            mustBeLessThanPreviousEntry: (index) => `Entry price ${index + 1} must be less than entry price ${index}`,
            mustBeLessThanOrEqualToAskPrice: (index) => `Entry price ${index + 1} must be less than or equal to ask price`,
            mustBeGreaterThanOrEqualToAskPrice: (index) => `Entry price ${index + 1} must be greater than or equal to ask price`
        },
        short: {
            mustBeGreaterThanBidPrice: (index) => `Entry price ${index + 1} must be greater than or equal to bid price`,
            mustBeGreaterThanPreviousEntry: (index) => `Entry price ${index + 1} must be greater than price ${index}`
        }
    },
    stopLossPrice: {
        invalidInput: "Invalid stop loss price",
        mustBeLessThanBidPrice: "Stop loss price must be less than bid price",
        mustBeLessThanLastPrice: "Stop loss price must be less than last price",
        mustBeGreaterThanLastPrice: "Stop loss price must be greater than last price",
        mustBeGreaterThanBidPrice: "Stop loss price must be greater than bid price",
        mustBeLessThanEntryPrice: "Stop loss price must be less than Entry Price",
        mustBeLessThanEntryPriceByIndex: (index) => `Stop loss price must be less than Entry Price ${index + 1}`,
        mustBeGreaterThanEntryPrice: "Stop loss price must be greater than Entry Price",
        mustBeGreaterThanEntryPriceByIndex: (index) => `Stop loss price must be greater than Entry Price ${index + 1}`,
    },
    multiTargets: {
        invalidTarget: (index) => `Invalid target price ${index + 1}`,
        mustBeGreaterThanBidPrice: (index) => `Target price ${index + 1} must be greater than bid price`,
        mustBeGreaterThanPreviousTarget: (index) => `Target price ${index + 1} must be greater than target price ${index}`,
        short: {
            mustBeLessThanBidPrice: (index) => `Target price ${index + 1} must be less than bid price`,
            mustBeLessThanPreviousTarget: (index) => `Target price ${index + 1} must be less than target price ${index}`
        }
    },
    singleTarget: {
        invalidTarget: "Invalid target price",
        mustBeGreaterThanBidPrice: "Target price must be greater than bid price",
        mustBeLessThanBidPrice: "Target price must be less than bid price"
    },
    balance: {
        notEnough: (wallet) => `You do not have enough ${wallet} balance`
    }
}

export const defaultTargetPricesQty = (strategy, totalTargets) => {
    if (totalTargets > 1) {
        switch (strategy) {
            default:
                return null 
            case "basic": {
                if (totalTargets === 2)
                    return [70, 30]

                if (totalTargets === 3)
                    return [60, 30, 10]

                if (totalTargets === 4)
                    return [40, 30, 15, 15]

                if (totalTargets === 5)
                    return [40, 25, 20, 10, 5]

                break
            }
            case "rrhunter" : {
                if (totalTargets === 2)
                    return [50, 50]

                break
            }
            case "sniper": {
                if (totalTargets === 2)
                    return [40, 60]

                break
            }
            case "stronguptrend": {
                if (totalTargets === 4)
                    return [15, 35, 15, 35]

                break
            }
            case "weakuptrend": {
                if (totalTargets === 4) 
                    return [30, 40, 10, 20]

                break
            }
            case "aggressivestoploss": {
                if (totalTargets === 2)
                    return [70, 30]

                if (totalTargets === 3)
                    return [60, 30, 10]

                if (totalTargets === 4)
                    return [40, 30, 15, 15]

                if (totalTargets === 5)
                    return [40, 25, 20, 10, 5]

                break
            }
            // case "def":
            //     if (totalTargets === 2)
            //         return [75, 25]

            //     if (totalTargets === 3)
            //         return [65, 25, 10]

            //     if (totalTargets === 4)
            //         return [40, 30, 15, 15]

            //     if (totalTargets === 5)
            //         return [50, 20, 15, 10, 5]

            //     break
            // case "std":
            //     if (totalTargets === 2)
            //         return [70, 30]

            //     if (totalTargets === 3)
            //         return [60, 30, 10]

            //     if (totalTargets === 4)
            //         return [40, 30, 15, 15]

            //     if (totalTargets === 5)
            //         return [40, 25, 20, 10, 5]

            //     break
            // case "agr":
            //     if (totalTargets === 2)
            //         return [60, 40]

            //     if (totalTargets === 3)
            //         return [50, 30, 20]

            //     if (totalTargets === 4)
            //         return [40, 30, 15, 15]

            //     if (totalTargets === 5)
            //         return [30, 25, 20, 15, 10]

            //     break
        }
    }
}

export const signalCloseState = {
    NO_STATE: 0,
    PENDING: 1,
    AUTO_CLOSE: 2,
    MANUAL_CLOSE: 3
}

export const signalState = {
    LIVE: 0,
    CLOSED: 1,
    OPEN: 4
}

export const stateFilter = {
    ALL: 0,
    FRESH_SIGNALS: 1,
    SIGNALS_IN_PROGRESS: 2,
    CLOSED_SIGNALS: 3,
    WATCHLISTED_SIGNALS: 4
}

export const exchangeFilter = {
    ALL: 0,
    SPOT: 1,
    FUTURES: 2
}