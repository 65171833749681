import styled from 'styled-components'

export const MenuItem = styled.ul`
    margin: 0;
    padding-left: 0px;
    list-style-type: none;

    .sub-menu-text {
        color: #7f98b7;
        font-size: 12px;
    }

    & > li {
        display: block;
        font-size: 14px;

        & > div,
        & > a {
            display: flex;
            align-items: center;
            text-decoration: none;

            & > span:first-child {
                color: #7f98b7;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                border-radius: 10px;
                text-align: center;
                overflow: hidden;
                margin-right: 0;

                & > img {
                    width: 100%;
                }
            }
        }
    }

    @media only screen and (min-width: 768px) {
        & > li {
            & > div,
            & > a {
                & > span:first-child {
                    margin-right: 10px;
                }
            }
        }
    }

    @media only screen and (min-width: 992px) {
        & > li {
            & > div,
            & > a {
                & > span:first-child {
                    margin-right: 15px;
                }
            }
        }
    }

    /* @media screen and (min-width: 320px) and (max-width: 414px) {
        .sub-menu-text {
            display: none;
        }
    }

    @media screen and (max-width: 766px) and (min-width: 320px) {
        justify-content: space-around !important;
    } */
`
