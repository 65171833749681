import styled from 'styled-components'

export const DropZoneWrapper = styled.section`
	p {
		cursor: pointer;
		padding: 20px;
		margin-bottom: 0;
		background: #1a232d;
		border-radius: 10px;
		border-style: dashed;
		border-color: #141921;
		color: #93b3ce;
		text-align: center;
	}
`

export const CropWrapper = styled.section`
	height: 300px;
	position: relative;
	margin-bottom: 100px;
	top: 15px;
`

export const CropContainer = styled.div``;

export const SliderControl = styled.div`
	position: absolute;
	bottom: -50px;
	right: 0;
	left: 0;
	margin: 0 auto;
	padding: 5px 2em;
	background-image: linear-gradient(90deg, #243240, #2c3e4d);
`

export const UpdateProfile = styled.div`
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	text-align: center;
	margin: 15px;
`

export const ModalCloseButton = styled.button`
	background-color: transparent;
	border: none;
	svg {
		color: #fff;
		width: auto !important;
		height: 20px;
		cursor: pointer;
		position: relative;
		top: 7px;
	}
`

export const FormProfilePreview = styled.div`
	width: 100px;
	height: 100px;
	overflow: hidden;
	position: relative;
`

export const FormProfileContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: #17212D !important;
    border: 1px solid #394B63;
    border-radius: 2px;
`

export const FormProfileWrapper = styled.div`
	padding: 25px;

	.col-form-label,
	.col-form-value {
		display: flex;
		align-items: center;
	}

	.col-form-label {
		color: #6E7885;
		font-size: 15px;
	}

	.profile-contents {
		-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	    animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

	    @-webkit-keyframes fade-in {
	        0% {
	            opacity: 0;
	        }
	        100% {
	            opacity: 1;
	        }
	    }
	    @keyframes fade-in {
	        0% {
	            opacity: 0;
	        }
	        100% {
	            opacity: 1;
	        }
	    }
	}
`