import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import iziToast from 'izitoast'
import jQuery from 'jquery'
import { checkListActions, sidebarActions } from '../../redux/actions'
import { ChecklistsView } from './ChecklistsView'
import { Dimension, isNotNull, isNull, onSetNewState } from '../../utils'

class Checklists extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            ids: [],
            stepTicked: null,
            onLoadVisibility: false
        }
        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        window.addEventListener('resize', () => Dimension.updateDimensions(this))
        const { dispatch } = this.props
        const { item, isLoading } = this.props.checkList
        let { progress, progressChecklist, progressChecklistMap } = this.props.checkListItems
        let prevProgress = progress
        let newLists = []
        let hasChanges = false

        if (!isLoading &&
            isNotNull(item) &&
            isNotNull(item.data) &&
            isNotNull(item.data.userRequirements)) {

            item.data.userRequirements.forEach((value, key) => {
                _.forEach(item.data.userRequirements, e => {
                    const find = _.find(newLists, n => n === e.checkListId)
                    if (isNull(find))
                        newLists.push(e.checkListId)
                })

                onSetNewState(this, { ids: newLists })

                if (value.isCompleted && progressChecklist[key] === 0) {
                    progress += progressChecklistMap[key]
                    progressChecklist[key] = 1
                    hasChanges = true
                }
            })

            progress = (hasChanges) ? progress : prevProgress
            if (this.props.checkListItems.progress !== progress)
                dispatch(checkListActions.setCheckListItems(progressChecklist, progress))
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.mounted === true) {
            const { item, isLoading } = this.props.checkList
            let { progress, progressChecklist, progressChecklistMap } = this.props.checkListItems
            const { isShowMessage } = this.props.checklistStatus
            let { stepTicked, onLoadVisibility } = this.state
            const { dispatch } = this.props
            let prevProgress = progress
            let hasChanges = false
            let newLists = []

            if (isNotNull(isLoading) &&
                !isLoading &&
                prevProps.checkList.isLoading &&
                isNotNull(item) &&
                isNotNull(item.data) &&
                isNotNull(item.data.userRequirements)) {

                item.data.userRequirements.forEach((value, key) => {
                    _.forEach(item.data.userRequirements, e => {
                        const find = _.find(newLists, n => n === e.checkListId)
                        if (isNull(find))
                            newLists.push(e.checkListId)
                    })

                    onSetNewState(this, { ids: newLists })

                    if (value.isCompleted && progressChecklist[key] === 0) {
                        progress += progressChecklistMap[key]
                        progressChecklist[key] = 1
                        hasChanges = true
                    }
                })

                progress = (hasChanges) ? progress : prevProgress
                if (prevProps.checkListItems.progress !== progress)
                    dispatch(checkListActions.setCheckListItems(progressChecklist, progress))
            }

            if (prevProps.saveCheckList !== this.props.saveCheckList) {
                let { loading, checkItem } = this.props.saveCheckList
                let { userRequirements } = this.props.checkList.item.data

                if (!loading) {
                    if (typeof checkItem !== 'undefined' && checkItem !== null && checkItem !== "") {
                        let { isUpdated } = checkItem

                        if (typeof isUpdated !== 'undefined' && isUpdated !== null && isUpdated !== "") {
                            if (isUpdated) {
                                if (typeof userRequirements !== 'undefined' && userRequirements !== null && userRequirements.length > 0) {
                                    let findChecklistItemIndex = _.findIndex(userRequirements, { checkListId: stepTicked })

                                    if (findChecklistItemIndex !== -1) {
                                        progress += progressChecklistMap[findChecklistItemIndex]
                                        progressChecklist[findChecklistItemIndex] = 1
                                        hasChanges = true

                                        progress = (hasChanges) ? progress : prevProgress

                                        if (prevProps.checkListItems.progress !== progress) {
                                            dispatch(checkListActions.setCheckListItems(progressChecklist, progress))
                                            dispatch(checkListActions.getCheckLists())
                                        }
                                    }
                                }
                            } else {
                                if (typeof userRequirements !== 'undefined' && userRequirements !== null && userRequirements.length > 0) {
                                    let findChecklistItemIndex = _.findIndex(userRequirements, { checkListId: stepTicked })

                                    jQuery(`#custom-inline-checkbox-${findChecklistItemIndex}`).attr('disabled', false)
                                    jQuery(`.custom-control-input.custom1`).attr('disabled', false)
                                }

                                let error = `<p class="error-title">Error</p> Please make sure you have BTC balance on your wallet.`
                                iziToast.error({
                                    position: 'topRight',
                                    class: 'custom-errors',
                                    messageColor: '#6E7885',
                                    message: error,
                                    displayMode: 1,
                                    backgroundColor: 'linear-gradient(90deg, rgba(19, 27, 38, 1) 88%, rgba(185, 63, 95, 0.5) 100%)',
                                    iconUrl: '/images/close-icon.png',
                                })
                            }
                        }
                    }
                } else {
                    if (typeof userRequirements !== 'undefined' && userRequirements !== null && userRequirements.length > 0) {
                        let indexOfCheckbox = _.findIndex(userRequirements, { checkListId: stepTicked })

                        if (indexOfCheckbox !== -1) {
                            jQuery(`#custom-inline-checkbox-${indexOfCheckbox}`).attr('disabled', true)
                            jQuery(`.custom-control-input.custom1`).attr('disabled', true)
                        }
                    }
                }
            }

            if (hasChanges) {
                if (!isShowMessage) {
                    dispatch(checkListActions.messageUpdate(true))
                }
            }

            if (isShowMessage) {
                jQuery(".message-tooltip.tooltip").addClass("show")
                jQuery(".message-tooltip.tooltip").css("z-index", "500")

                if (!isLoading) {
                    jQuery(".blur-dashboard").addClass("blur")
                    jQuery(".sidelinks").addClass("blur")

                    if (window.innerWidth > 766 && window.innerWidth < 1025) {
                        if (!onLoadVisibility) {
                            onLoadVisibility = true
                            dispatch(sidebarActions.showSidebar(false))

                            onSetNewState(this, {
                                onLoadVisibility
                            })
                        }
                    }
                }
            } else {
                jQuery(".message-tooltip.tooltip").removeClass("show")
                jQuery(".message-tooltip.tooltip").css("z-index", "-5")
                jQuery(".blur-dashboard").removeClass("blur")
                jQuery(".sidelinks").removeClass("blur")
            }
        }
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)
        window.removeEventListener('resize', () => Dimension.updateDimensions(this))
        this.mounted = false
    }

    onChangeHandler = (id) => {
        if (this.mounted) {
            const { dispatch } = this.props
            dispatch(checkListActions.saveChecklist(id, true))
            onSetNewState(this, {
                stepTicked: id
            })
        }
    }

    onMessageHandler = (event, isShow) => {
        if (this.mounted) {
            event.preventDefault()
            const { dispatch, checklistStatus } = this.props
            if (isNotNull(checklistStatus) && isNotNull(checklistStatus.isShowMessage))
                dispatch(checkListActions.messageUpdate(isShow))
        }
    }

    render() {
        return (
            <ChecklistsView
                self={this}
                state={this.state}
                changeHandler={this.onChangeHandler}
                messageHandler={this.onMessageHandler}
                {...this.props}
            />
        )
    }
}

function mapStateToProps(state) {
    const { checkList, saveCheckList, checkListItems, checklistStatus, cryptoWallet } = state
    return {
        checkList,
        saveCheckList,
        checkListItems,
        checklistStatus,
        cryptoWallet
    }
}

const connectedChecklists = connect(mapStateToProps)(Checklists)
export { connectedChecklists as Checklists }