import { NavLink } from 'react-router-dom'
import { routes } from '../../navigation/CONSTANTS'
import { SettingsMenuWrapper } from './styled_components'
import { MenuItem } from '../../styles'
import { isNotNull } from '../../utils'

export const SettingMenuView = (props) => {
    const { user } = props
    const getPathName = new URL(window.location).pathname
    const setActive = (getPathName === '/account/profile') ? 'active' : ''
    const setAutoTradeActive = (getPathName === '/account/auto-trade') ? 'active' : ''

    return (
        <SettingsMenuWrapper>
            <MenuItem className="sub-menu">
                <li className="sub-menu-item">
                    <NavLink to={routes.setting.profile} className={`sub-menu-item-link ${setActive}`}>
                        <span className="sub-menu-icon">
                            <i className="fas fa-user"></i>
                        </span>
                        <span className="sub-menu-text">Profile Info</span>
                    </NavLink>
                </li>

                <li className="sub-menu-item">
                    <NavLink to={routes.setting.subscription} className="sub-menu-item-link" activeClassName="active">
                        <span className="sub-menu-icon">
                            <i className="fas fa-gem"></i>
                        </span>
                        <span className="sub-menu-text">Subscription</span>
                    </NavLink>
                </li>

                <li className="sub-menu-item">
                    <NavLink to={routes.setting.autoTrade} className={`sub-menu-item-link ${setAutoTradeActive}`} activeClassName="active">
                        <span className="sub-menu-icon">
                            <i className="fas fa-hand-holding-usd"></i>
                        </span>
                        <span className="sub-menu-text">Auto-trade</span>
                    </NavLink>
                </li>

                <li className="sub-menu-item">
                    <NavLink to={routes.setting.notificationSettings} className="sub-menu-item-link" activeClassName="active">
                        <span className="sub-menu-icon">
                            <i className="fas fa-bell"></i>
                        </span>
                        <span className="sub-menu-text">Notifications</span>
                    </NavLink>
                </li>
            </MenuItem>
        </SettingsMenuWrapper>
    )
}