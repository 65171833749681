import React from 'react'
import { connect } from 'react-redux'
import jQuery from 'jquery'
import { NavLink, Link } from 'react-router-dom'
import Iframe from 'react-iframe'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight, faTimes } from "@fortawesome/free-solid-svg-icons"
import { Card, Button, Modal, SafeAnchor } from 'react-bootstrap'
import { GenericModal } from '../Modals'
import {
    themeActions, 
    sidebarActions, 
    authActions, 
    tradingActions, 
    checkListActions
} from '../../redux/actions'
import { tradingServices, blueprintService } from '../../services'
import { tradingActionTypes } from '../../redux/CONSTANTS'
import { SideBarWrapper } from './styled_components'
import { MessageBoardMenuLink } from '../../components'
import { isNotNull, Dimension, onSetNewState, isNull } from '../../utils'
import ViralLoops from '../ViralLoops/ViralLoops'
import { CloseButton } from '../../styles'
import { viralLoopsKey } from '../../services/CONSTANTS'

class Sidebar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showSidebar: true,
            saveProfileImg: '',
            isTradeEnable: false,
            isTradeBtnDisable: true,
            loadTradeLink: false,
            showTradeModal: false,
            autoLogin: {
                link: null,
                expiry: null,
                locked: false,
            },
            isChecklistCompleted: true,
            isToShowMarketUpdatesModal: false,
            isToShowReferralModal: false
        }
        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        window.addEventListener('resize', () => Dimension.updateDimensions(this))

        const { dispatch, checkList } = this.props
        const { user } = this.props.authentication
        if (isNotNull(user)) {
            dispatch(tradingActions.getAutologin())

            setTimeout(() => {
                localStorage.setItem('saveProfile', user.avatarUrl)
                setTimeout(() => {
                    const saveProfile = localStorage.getItem('saveProfile')
                    onSetNewState(this, { saveProfileImg: saveProfile })
                }, 500)
            }, 500)

            onSetNewState(this, {
                isTradeEnable: user.isAutoTradingAllowed,
                isTradeBtnDisable: false
            })
        }

        if (isNotNull(checkList)) {
            const { isLoading, item } = checkList
            if (!isLoading)
                this.checklistHandler(item)
        }

        this.sidebarHandler()
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { authentication, checkList, isTradeManualOpen } = this.props
            this.sidebarHandler()

            if (authentication.loading === false && prevProps.authentication.loading === true) {
                const { user } = authentication
                if (isNotNull(user)) {
                    onSetNewState(this, {
                        isTradeEnable: user.isAutoTradingAllowed,
                        isTradeBtnDisable: false
                    })
                }
            }

            if (prevProps.checkList !== checkList) {
                const { isLoading, item } = checkList
                if (!isLoading)
                    this.checklistHandler(item)
            }

            if (prevProps.isTradeManualOpen === false && isTradeManualOpen === true)
                this.manualTradingHandler()
        }
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)
        window.removeEventListener('resize', () => Dimension.updateDimensions(this))
        this.mounted = false
    }

    sidebarHandler = () => {
        const { sidebar } = this.props.showSidebar
        let { showSidebar } = this.state
        
        if (isNotNull(sidebar) && sidebar !== showSidebar) {
            showSidebar = sidebar
            onSetNewState(this, { showSidebar })

            if (showSidebar) {
                jQuery(".sidebar-size").addClass("show")
                jQuery("#sidebar-opt").addClass("show")
                jQuery("#sidebar-opt-prof").addClass("show")
                jQuery(".fsp").addClass("show")
                jQuery(".to-hide-items").addClass("show")
                jQuery(".tooltip").addClass("showToolTip")
            } else {
                jQuery(".sidebar-size").removeClass("show")
                jQuery("#sidebar-opt").removeClass("show")
                jQuery("#sidebar-opt-prof").removeClass("show")
                jQuery(".fsp").removeClass("show")
                jQuery(".to-hide-items").removeClass("show")
                jQuery(".tooltip").removeClass("showToolTip")
            }
        }
    }

    hideSidebar = (e) => {
        e.preventDefault()
        const { dispatch } = this.props
        const { sidebar } = this.props.showSidebar
        dispatch(sidebarActions.showSidebar(!sidebar))
    }

    logoutHandler = () => {
        const { dispatch } = this.props
        dispatch(authActions.logout())
    }

    setThemeHandler = () => {
        const { theme } = this.props.userTheme
        const { dispatch } = this.props

        if (isNotNull(theme) && isNotNull(theme.isDarkMode)) {
            if (theme.isDarkMode)
                dispatch(themeActions.saveTheme(false))
            else
                dispatch(themeActions.saveTheme(true))
        }
    }

    manualTradingHandler = () => {
        const { autoLogin, isTradeEnable } = this.state
        const { dispatch, authentication } = this.props
        if (isNotNull(authentication)) {
            const { user } = authentication
            if (isNotNull(user)) {
                if (user.isPaid) {
                    onSetNewState(this, {
                        loadTradeLink: true,
                        isTradeBtnDisable: true
                    }, () => {
                        if (isTradeEnable) {
                            if (autoLogin.expiry <= Date.now())
                                tradingServices.getAutologin().then(response => {
                                    let newLink = ''
                                    if (isNotNull(response) && isNotNull(response.loginUrl))
                                        newLink = response.loginUrl.replace("www.", "profitfarmers.")
            
                                    autoLogin.link = newLink
                                    autoLogin.expiry = response.expiry
                                    onSetNewState(this, {
                                        autoLogin
                                    }, () => {
                                        dispatch({ type: tradingActionTypes.TRADING_GET_AUTOLOGIN_SUCCESS, autoLogin })
                                        this.autoLoginHandler(autoLogin.link)
                                        onSetNewState(this, {
                                            isTradeBtnDisable: false,
                                            loadTradeLink: false
                                        })
                                    })
                                })
                            else
                                onSetNewState(this, {
                                    isTradeBtnDisable: false,
                                    loadTradeLink: false
                                }, () => this.autoLoginHandler(autoLogin.link))
                        } else {
                            this.props.openTradeManual(false)
                            onSetNewState(this, {
                                showTradeModal: true
                            })
                        }
                    })
                } else {
                    window.location.href = '/subscription-plans/pro/subscribe'
                }
            }
        }
    }

    viewCourseHandler = async () => {
        const { authentication } = this.props
        if (isNotNull(authentication)) {
            const { user } = authentication
            if (isNotNull(user) && isNotNull(user.roles)) {
                const { userName, roles } = user
                let userRole = roles[0]
                let res = await blueprintService.setRole(userName, userRole.toLowerCase())
                if (isNotNull(res) && res.success) {
                    window.open('https://www.profitfarmers.com/academy/the-pro-trader-5x-blueprint', '_blank')
                }
            }
        }
    }

    autoLoginHandler = (url) => {
        this.props.openTradeManual(false)

        const openWindow = window.open(url, '_blank')
        if (openWindow)
            window.focus()
        else
            window.setTimeout(() => {
                if (openWindow)
                    openWindow.focus()
            }, 100)
    }

    checklistHandler = (item) => {
        const { dispatch } = this.props
        if (isNotNull(item) && isNotNull(item.data) && isNotNull(item.data.isStepsCompleted)) {
            onSetNewState(this, { isChecklistCompleted: item.data.isStepsCompleted })
            dispatch(checkListActions.stepsCompleted(item.data.isStepsCompleted))
        }
    }

    onHideAutoTradingModalHandler = () => onSetNewState(this, {
        showTradeModal: false,
        loadTradeLink: false,
        isTradeBtnDisable: false
    })

    onShowMarketUpdatesHandler = () => onSetNewState(this, {
        isToShowMarketUpdatesModal: true
    })

    onHideMarketUpdatesModalHandler = () => onSetNewState(this, {
        isToShowMarketUpdatesModal: false
    })

    renderSurvey = (user, stepsCompleted) => {
        if (isNotNull(user)) {
            if (isNotNull(stepsCompleted) &&
                stepsCompleted &&
                isNotNull(user.paymentStatus) &&
                isNotNull(user.paymentStatusTxt) &&
                user.paymentStatus !== 6 &&
                user.paymentStatusTxt !== "PENDING" &&
                (
                    isNull(user.tag) ||
                    (isNotNull(user.tag) && user.tag !== "moonbag") ||
                    (isNotNull(user.tag) && user.tag === "moonbag" && user.paymentStatus !== 3)
                )
            ) {
                return (
                    <div className="survey-link">
                        <a href="https://forms.gle/NjTnhyg897BCZcep8" target="_blank" className="sub-menu-item-link" rel="noopener noreferrer">
                            <span className="sub-menu-text">Take Survey and Win<br /> 1 Month Membership</span>
                            <span className="survey-link-icons">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-angle-double-right"></i>
                            </span>
                        </a>
                    </div>
                )
            }

            if (isNotNull(user) && 
                isNotNull(user.isPaid) &&
                isNotNull(user.tag) &&
                user.tag === "moonbag" &&
                isNotNull(user.paymentStatus) &&
                user.paymentStatus !== 0 &&
                user.paymentStatus !== 5 &&
                ((user.isPaid && isNotNull(stepsCompleted) && stepsCompleted) || !user.isPaid))
                return (
                    <div className="survey-link">
                        <a href="https://forms.gle/6ei3EaKabom47GUp9" target="_blank" className="sub-menu-item-link" rel="noopener noreferrer">
                            <span className="sub-menu-text">Take Survey and Win<br /> 1 Month Membership</span>
                            <span className="survey-link-icons">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-angle-double-right"></i>
                            </span>
                        </a>
                    </div>
                )
        }
    }

    onReferralHandler = () => onSetNewState(this, (prev) => ({
        isToShowReferralModal: !prev.isToShowReferralModal
    }))

    render() {
        const { showSidebar, isTradeBtnDisable, loadTradeLink, showTradeModal, isToShowMarketUpdatesModal, isToShowReferralModal } = this.state
        const { position, isEmailPromt, isTokenFound } = this.props
        const { user } = this.props.authentication

        return (
            <>
                { position === 'left' &&
                    <>
                        <SideBarWrapper className={`sidebar sidebar-size theme-sidebar ${isEmailPromt ? "show-email " : ""} ${isNotNull(isTokenFound) && !isTokenFound ? 'notif-permission': ''}`}>
                            <div className="sidebar-menu">
                                <Card className="sidelinks">
                                    <Card.Body>
                                        <ul className="sidebar-menu-lists">
                                            <li className="sidebar-menu-items side-bar-signals">
                                                <NavLink to="/dashboard" className="menus signal-link" activeClassName="active">
                                                    <span className="sub-menu-icon"><i className="fas fa-th-large"></i></span>
                                                    <span className="sub-menu-text">Dashboard</span>
                                                </NavLink>

                                                <Button id="sidebar-btn" className="btn-sidebar btn-none" onClick={(e) => this.hideSidebar(e)}>
                                                    <FontAwesomeIcon icon={showSidebar ? faArrowLeft : faArrowRight} className="icon" />
                                                </Button>
                                            </li>

                                            <div className="to-hide-items">
                                                { /*Tools*/}
                                                <li className="sidebar-menu-items">
                                                    <div className="sidebar-menu-content-title">
                                                        <span className="sub-menu-text">Tools</span>
                                                    </div>
                                                    <div className="sidebar-menu-content-item-wrapper">
                                                        <ul className="sidebar-menu-content-lists sub-menu">                                                            
                                                            <li className="sub-menu-item">
                                                                <NavLink to="/signals" className="menus sub-menu-item-link pa-link" activeClassName="active">
                                                                    <span className="sub-menu-icon"><i className="fas fa-seedling"></i></span>
                                                                    <span className="sub-menu-text">Signals</span>
                                                                </NavLink>
                                                            </li>

                                                            { isNotNull(user) &&
                                                                <>
                                                                    <li className="sub-menu-item">
                                                                        <Button variant="none" className="sub-menu-item-link" disabled={isTradeBtnDisable} onClick={this.manualTradingHandler}>
                                                                            { loadTradeLink &&
                                                                                <span className="sub-menu-text with-loader"><i className="fas fa-spinner fa-pulse"></i> Trade Manually</span>
                                                                            }

                                                                            { !loadTradeLink &&
                                                                                <>
                                                                                    <span className="sub-menu-icon"><i className="fas fa-desktop"></i></span>
                                                                                    <span className="sub-menu-text">Trade Manually { !user.isPaid && <small className='pro-feature'>PRO</small>}</span>
                                                                                </>
                                                                            }
                                                                        </Button>
                                                                    </li>

                                                                    <li className="sub-menu-item">
                                                                        <NavLink to="/scanners/price-action" className="menus sub-menu-item-link pa-link" activeClassName="active">
                                                                            <span className="sub-menu-icon"><i className="fas fa-chart-bar"></i></span>
                                                                            <span className="sub-menu-text">Price Action Scanner { !user.isPaid && <small className='pro-feature'>PRO</small>}</span>
                                                                        </NavLink>
                                                                    </li>

                                                                    <li className="sub-menu-item">
                                                                        <NavLink to="/scanners/relative-strength-index" className="menus sub-menu-item-link rsi-link" activeClassName="active">
                                                                            <span className="sub-menu-icon"><i className="fas fa-chart-area"></i></span>
                                                                            <span className="sub-menu-text">RSI Scanner { !user.isPaid && <small className='pro-feature'>PRO</small>}</span>
                                                                        </NavLink>
                                                                    </li>

                                                                    { user.isPaid &&                                                                     
                                                                        <li className="sub-menu-item">
                                                                            <Button variant='none' className='sub-menu-item-link' onClick={this.viewCourseHandler}>
                                                                                <span className='sub-menu-icon'><i className='fas fa-map'></i></span>
                                                                                <span className='sub-menu-text'>Pro Trader 5x Blueprint</span>
                                                                            </Button>

                                                                            {/* <SafeAnchor 
                                                                                href='https://www.profitfarmers.com/academy/the-pro-trader-5x-blueprint' 
                                                                                target="_blank" 
                                                                                rel="noreferrer" 
                                                                                className="menus sub-menu-item-link">
                                                                                <span className='sub-menu-icon'><i className='fas fa-map'></i></span>
                                                                                <span className='sub-menu-text'>Pro Trader 5x Blueprint</span>
                                                                            </SafeAnchor> */}
                                                                        </li>
                                                                    }     

                                                                    <MessageBoardMenuLink version="v1" />
                                                                </>
                                                            }

                                                            {/* <li className="sub-menu-item">
                                                                <Button variant="none" className="sub-menu-item-link" onClick={this.onShowMarketUpdatesHandler} style={{ outline: 'none', boxShadow: 'none' }}>
                                                                    <span className="sub-menu-icon"><i className="fas fa-bullhorn"></i></span>
                                                                    <span className="sub-menu-text">Market Updates</span>
                                                                </Button>
                                                            </li> */}
                                                        </ul>
                                                    </div>
                                                </li>

                                                { /*My Account*/}
                                                <li className="sidebar-menu-items">
                                                    <div className="sidebar-menu-content-title">
                                                        <span className="sub-menu-text">Trade History</span>
                                                    </div>
                                                    <div className="sidebar-menu-content-item-wrapper">
                                                        <ul className="sidebar-menu-content-lists sub-menu" id="profileMenuItem">
                                                            <li className="sub-menu-item">
                                                                <NavLink to="/orders" className="menus sub-menu-item-link settings-link" activeClassName="active">
                                                                    <span className="sub-menu-icon"><i className="fas fa-file-invoice"></i></span>
                                                                    <span className="sub-menu-text">Trade Orders</span>
                                                                </NavLink>
                                                            </li>

                                                            { isNotNull(user) && 
                                                                <li className="sub-menu-item">
                                                                    <NavLink to={ user.isPaid ? '/trade/statistics' : '/subscription-plans/pro/subscribe' } className="menus sub-menu-item-link settings-link" activeClassName="active">
                                                                        <span className="sub-menu-icon"><i className="fas fa-percent"></i></span>
                                                                        <span className="sub-menu-text">PF Statistics { !user.isPaid && <small className='pro-feature'>PRO</small>}</span>
                                                                    </NavLink>
                                                                </li>
                                                            }

                                                            {/* { stepsCompleted &&
                                                                <li className="sub-menu-item">
                                                                    <Button variant="none" className="sub-menu-item-link" onClick={this.onReferralHandler} style={{ outline: 'none', boxShadow: 'none' }}>
                                                                        <span className="sub-menu-text">Invite a friend!</span>
                                                                    </Button>
                                                                </li>
                                                            } */}
                                                        </ul>
                                                    </div>
                                                </li>

                                                {/* Help Centre */}
                                                <li className="sidebar-menu-items">
                                                    <div className="sidebar-menu-content-title">
                                                        <span className="sub-menu-text">Help Centre</span>
                                                    </div>
                                                    <div className="sidebar-menu-content-item-wrapper">
                                                        <ul className="sidebar-menu-content-lists sub-menu">
                                                            { isNotNull(user) &&                                                             
                                                                <li className="sub-menu-item">
                                                                    <a href={ user.isPaid ? 'https://pro-support.profitfarmers.com/article/254-contacting-customer-support' : 'https://support.profitfarmers.com/article/255-customer-support-for-free-members' } target="_blank" rel="noreferrer" className="sub-menu-item-link">
                                                                        <span className="sub-menu-icon"><i className="fas fa-headphones-alt"></i></span>
                                                                        <span className="sub-menu-text">Contact Support</span>
                                                                    </a>
                                                                </li>
                                                            }

                                                            { isNotNull(user) && 
                                                                <li className="sub-menu-item">
                                                                    <a href={`https://${user.isPaid ? 'pro-support' : 'support'}.profitfarmers.com/`} target="_blank" rel="noreferrer" className="sub-menu-item-link">
                                                                        <span className="sub-menu-icon"><i className="fas fa-lightbulb"></i></span>
                                                                        <span className="sub-menu-text">Knowledge Base</span>
                                                                    </a>
                                                                </li>
                                                            }

                                                            { isNotNull(user) && 
                                                                <li className="sub-menu-item">
                                                                    <a href="https://pro-support.profitfarmers.com/article/266-signal-strategies" target="_blank" rel="noreferrer" className="sub-menu-item-link">
                                                                        <span className="sub-menu-icon"><i className="fas fa-book-open"></i></span>
                                                                        <span className="sub-menu-text">Signal Strategy Guides</span>
                                                                    </a>
                                                                </li>
                                                            }

                                                            {/* <li className="sub-menu-item">
                                                                <a href="https://profitfarmers.freshdesk.com/support/solutions/47000401033" target="_blank" rel="noreferrer" className="sub-menu-item-link">
                                                                    <span className="sub-menu-text">FAQ</span>
                                                                </a>
                                                            </li>

                                                            <li className="sub-menu-item">
                                                                <a href="https://profitfarmers.freshdesk.com/support/solutions/folders/47000587987" target="_blank" rel="noreferrer" className="sub-menu-item-link">
                                                                    <span className="sub-menu-text">How to Videos</span>
                                                                </a>
                                                            </li> */}
                                                        </ul>
                                                    </div>
                                                </li>

                                                { /* Community */ }
                                                { /*<li className='sidebar-menu-items' style={{ marginTop: 'auto' }}>
                                                    <div className='sidebar-menu-content-title'>
                                                        <span className='sub-menu-text'>Community</span>
                                                    </div>
                                                    <div className='sidebar-menu-content-item-wrapper'>
                                                        <ul className='sidebar-menu-content-lists sub-menu'>
                                                            { isNotNull(user) && 
                                                                <li className="sub-menu-item">
                                                                    <a href={ user.isPaid && !user.isWithinTrialPeriod ? 'https://docs.google.com/forms/d/e/1FAIpQLSe0GwPFWzzmnrjYLHwyE7Ug4MwOEFAYzTEdriiFvEWTdZrgpA/viewform' : void(0) } target="_blank" rel="noreferrer" className="sub-menu-item-link">
                                                                        <span className="sub-menu-icon"><i className="fa fa-users" /></span>
                                                                        <span className="sub-menu-text">Join Our Community { (!user.isPaid || (user.isPaid && user.isWithinTrialPeriod)) && <small className='pro-feature'>PRO</small>}</span>
                                                                    </a>
                                                                </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </li> */}

                                                {/* <li className="sidebar-menu-items last">
                                                    {this.renderSurvey(user, stepsCompleted)}
                                                </li> */}
                                            </div>
                                        </ul>
                                    </Card.Body>
                                </Card>
                            </div>
                        </SideBarWrapper>

                        {/* Auto Trading Modal Here */}
                        <GenericModal
                            className="autoTradingModal"
                            show={showTradeModal}
                            onHide={this.onHideAutoTradingModalHandler}
                            bodyContent={() => (
                                <>
                                    <div className="close" onClick={this.onHideAutoTradingModalHandler} style={{ zIndex: '1' }}>
                                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                    </div>
                                    <div className="overlay"></div>
                                    <img src="/images/alert.png" className="img-fluid" alt="" />
                                    <h4> Exchange Keys are not configured </h4>
                                    <p><span>To setup your Exchange API Keys,</span> <Link to="/account/auto-trade" onClick={() => this.onHideAutoTradingModalHandler()}>go to settings</Link>.</p>
                                </>
                            )}
                        />

                        <GenericModal
                            className="market-updates-modal"
                            show={isToShowMarketUpdatesModal}
                            onHide={this.onHideMarketUpdatesModalHandler}
                            bodyContent={() => (
                                <>
                                    <div className="close" onClick={this.onHideMarketUpdatesModalHandler} style={{ zIndex: '1' }}>
                                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                    </div>
                                    <Iframe
                                        id="iframe-market-updates"
                                        className="market-updates-iframe"
                                        url="https://www.profitfarmers.com/market-updates"
                                        display="initial"
                                        position="relative" />
                                </>
                            )} />


                        <Modal
                            className={`generic-modal referral`}
                            show={isToShowReferralModal}
                            onHide={this.onReferralHandler}
                            centered>
                            {isNotNull(isToShowReferralModal) && isToShowReferralModal &&
                                <>
                                    <Modal.Header>
                                        <h3 className="title">Invite a friend</h3>
                                        <CloseButton onClick={() => this.onReferralHandler()} className="custom-close">
                                            <FontAwesomeIcon icon={faTimes} className="icon" />
                                        </CloseButton>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <ViralLoops
                                            publicToken={viralLoopsKey}
                                            formWidget={true}
                                            rewardWidget={true}
                                        />
                                    </Modal.Body>
                                </>
                            }
                        </Modal>
                    </>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    const { authentication, setThemeSettings, userTheme, showSidebar, checkList, checklistStatus } = state
    return {
        authentication,
        setThemeSettings,
        userTheme,
        showSidebar,
        checkList,
        checklistStatus
    }
}

const connectedSidebar = connect(mapStateToProps)(Sidebar)
export { connectedSidebar as Sidebar }