import { tradingActionTypes } from '../CONSTANTS'

export function tradings(state = { loading: false, isSaving: false, isDeleting: false, loader: false }, action) {
    switch (action.type) {
        case tradingActionTypes.TRADING_GET_AUTOLOGIN_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case tradingActionTypes.TRADING_GET_AUTOLOGIN_SUCCESS: {
            return {
                ...state,
                loading: false,
                autologin: action.autologin
            }
        }

        case tradingActionTypes.TRADING_REQUEST_SETTINGS:
            return {
                ...state,
                loading: true,
                error: null
            }
        case tradingActionTypes.TRADING_SUCCESS_SETTINGS:
            return {
                ...state,
                loading: false,
                settings: action.settings
            }
        case tradingActionTypes.TRADING_FAILURE_SETTINGS:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case tradingActionTypes.TRADING_GET_KEYS_REQUEST: {
            return {
                ...state,
                isKeyLoading: true,
                loading: true,
                keys_error: null
            }
        }

        case tradingActionTypes.TRADING_GET_KEYS_SUCCESS: {
            return {
                ...state,
                isKeyLoading: false,
                loading: false,
                keys: action.keys
            }
        }

        case tradingActionTypes.EXISTING_TRAILING_USER_REQUEST:
            return {
                ...state,
                loader: true,
                error: null
            }

        case tradingActionTypes.EXISTING_TRAILING_USER_SUCCESS:
            return {
                ...state,
                loader: false,
                existingTrailUser: action.existingTrailUser
            }

        case tradingActionTypes.EXISTING_TRAILING_USER_FAILURE:
            return {
                ...state,
                loader: false,
                existingError: action.existingError
            }

        case tradingActionTypes.TRADING_SEND_PASSWORD_REQUEST:
            return {
                ...state,
                loader: true,
                error: null
            }

        case tradingActionTypes.TRADING_SEND_PASSWORD_SUCCESS:
            return {
                ...state,
                loader: false,
                response: action.response
            }

        case tradingActionTypes.TRADING_SEND_PASSWORD_FAILURE:
            return {
                ...state,
                loader: false,
                failure: action.failure
            }

        case tradingActionTypes.TRADING_GET_KEYS_FAILURE: {
            return {
                ...state,
                isKeyLoading: false,
                loading: false,
                keys_error: action.keys_error
            }
        }

        case tradingActionTypes.TRADING_GET_EXCHANGE_REQUEST: {
            return {
                ...state,
                loading: true,
                error: null
            }
        }

        case tradingActionTypes.TRADING_GET_EXCHANGE_SUCCESS: {
            return {
                ...state,
                loading: false,
                exchange: action.exchange
            }
        }

        case tradingActionTypes.TRADING_GET_EXCHANGE_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }

        case tradingActionTypes.CLEAR: {
            return {
                ...state,
                failure: null
            }
        }

        case tradingActionTypes.TRADING_SAVE_KEYS_REQUEST: {
            return {
                ...state,
                loading: true,
                isSaving: true,
                error: null
            }
        }

        case tradingActionTypes.TRADING_SAVE_KEYS_SUCCESS: {
            return {
                ...state,
                loading: false,
                isSaving: false,
                keys: action.keys
            }
        }

        case tradingActionTypes.TRADING_SAVE_KEYS_FAILURE: {
            return {
                ...state,
                loading: false,
                save_error: action.save_error
            }
        }

        case tradingActionTypes.TRADING_REQUEST_DISABLE: {
            return {
                ...state,
                loading: true,
                isSaving: true,
                error: null
            }
        }

        case tradingActionTypes.TRADING_SUCCESS_DISABLE: {
            return {
                ...state,
                loading: false,
                isSaving: false,
                disable: action.disable
            }
        }

        case tradingActionTypes.TRADING_FAILURE_DISABLE: {
            return {
                ...state,
                loading: false,
                isSaving: false,
                error: action.error
            }
        }

        case tradingActionTypes.TRADING_REMOVE_KEYS_REQUEST: {
            return {
                ...state,
                loading: true,
                isDeleting: true,
                rem_keys: null
            }
        }

        case tradingActionTypes.TRADING_REMOVE_KEYS_SUCCESS: {
            return {
                ...state,
                loading: false,
                isDeleting: false,
                remove: action.remove
            }
        }

        case tradingActionTypes.TRADING_REMOVE_KEYS_FAILURE: {
            return {
                ...state,
                loading: false,
                isDeleting: false,
                rem_keys: action.rem_keys
            }
        }

        case tradingActionTypes.DISABLE_CLEAR: {
            const { ...remainingState } = state
            return {
                ...remainingState
            }
        }

        default:
            return state
    }
}

export function tradeDetails(state = {}, action) {
    switch (action.type) {
        case tradingActionTypes.GET_TRADE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case tradingActionTypes.GET_TRADE_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                orderDetails: action.orderDetails,
            }
        case tradingActionTypes.GET_TRADE_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.err
            }
        case tradingActionTypes.CLEAR_TRADE_DETAILS:
            return {
                ...state,
                loading: true,
                orderDetails: null
            }
        default:
            return state
    }
}

export function saveAutoCloseTradeSettings(state = {}, action) {
    switch (action.type) {
        case tradingActionTypes.SAVE_AUTO_CLOSE_TRADE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case tradingActionTypes.SAVE_AUTO_CLOSE_TRADE_SUCCESS: 
            return {
                ...state,
                loading: false,
                save_auto_close_trade_settings: action.response
            }
        case tradingActionTypes.SAVE_AUTO_CLOSE_TRADE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}