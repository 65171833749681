import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import * as signalR from '@microsoft/signalr'
import jQuery from 'jquery'
import Swal from 'sweetalert2'
import { planActions } from '../../redux/actions'
import { fsWebhook } from '../../services/CONSTANTS'
import { isNotNull, isNull, onSetNewState } from '../../utils'
import { HeaderSection } from './HeaderSection'
import { LearnHowToSection } from './LearnHowToSection'
import { CheckoutSection } from './CheckoutSection'
import { FeaturesComparisonSection } from './FeaturesComparisonSection'
import { UserFeedbackSection } from './UserFeedbackSection'
import { PowerBreakdownSection } from './PowerBreakdownSection'
import { TheFounderSection } from './TheFounderSection'
import { CaseStudiesSection } from './CaseStudySection'
import { MoneyBackGuaranteeSection } from './MoneyBackGuaranteeSection'
import { TestimonialsSection } from './TestimonialsSection'
import { ProfitableTradingSection } from './ProfitableTradingSection'
import { WhyUpgradeSection } from './WhyUpgradeSection'
import { ImagineThreeMonthsSection } from './ImagineThreeMonthsSection'

class ProPlans extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            show: null,
            userInfo: null,
            subscriptionPlans: null
        }

        this.baseState = this.state
        this.mounted = false
        this.fsHubConnection = null
        
        this.startListening()
        this.initializeHubConnection()
    }

    componentDidMount() {
        this.mounted = true
        const { dispatch } = this.props
        dispatch(planActions.get())
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { authentication, plan } = this.props
            const { show, userInfo, subscriptionPlans } = this.state

            if (isNull(subscriptionPlans) && 
                isNotNull(plan) && 
                prevProps.plan !== plan && 
                !plan.loading) {
                    let subscriptionPlanList = plan.plans
                    if (isNotNull(subscriptionPlanList.Fastspring)) {
                        onSetNewState(this, {
                            subscriptionPlans: subscriptionPlanList.Fastspring
                        })
                    }
                }

            if (isNull(userInfo) && 
                isNotNull(authentication.user)) {
                    const { ...data } = authentication.user
                    onSetNewState(this, {
                        userInfo: {
                            id: data.userId,
                            customerId: data.customerId,
                            leadId: data.leadId,
                            isLead: data.isLead,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            userName: data.userName,
                            hasSubscribed: data.isPaid
                        }
                    })
                }

            if (isNull(show) && 
                isNotNull(userInfo) && 
                isNotNull(subscriptionPlans)) {
                    onSetNewState(this, {
                        show: true
                    }, async () => {
                        await this.startFsWebhookConnection(userInfo.userName)
                    })
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
        onSetNewState(this, this.baseState)

        if (this.fsHubConnection !== null) {
            if (!this.fsHubConnection.hasOwnProperty('close'))
                this.fsHubConnection.stop()
        }
    }

    startListening = () => {
        window.onPaymentReceived = data => {
            if (isNotNull(data)) {
                Swal.fire({
                    title: 'Payment successful, please wait while we setup your account.',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    didOpen: () => Swal.showLoading()
                })
            }
        }
    }

    initializeHubConnection = () => {
        let hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${fsWebhook}/fs`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .configureLogging(signalR.LogLevel.None)
            .withAutomaticReconnect([0,0,5000])
            .build()
        hubConnection.serverTimeoutInMilliseconds = 120000

        this.fsHubConnection = hubConnection
        this.fsHubConnection.on('OnPaymentMade', (payload) => {
            const { ...purchase } = payload
            const { userInfo } = this.state

            if (isNotNull(purchase) && 
                isNotNull(userInfo) && 
                purchase.userId === userInfo.id) {
                window.dataLayer.push({
                    event: 'purchase',
                    user_id: purchase.userId,
                    product: purchase.product,
                    subscriptionPlanId: purchase.subscriptionPlanId,
                    subscriptionRef: purchase.subscriptionRef,
                    amount: purchase.amount,
                    isRenewal: purchase.isRenewal
                })

                jQuery(document).find('#fscCanvas').remove()
                Swal.close()
                window.location = "/dashboard"
            }
        })
    }

    startFsWebhookConnection = async (username) => {
        if (this.mounted) {
            try {
                await this.fsHubConnection
                    .start()
                    .catch(err => console.log(err))

                if (this.fsHubConnection.state === signalR.HubConnectionBuilder.Connected) {
                    this.fsHubConnection.invoke("GetConnectionId", username)
                        .then((connectionId) => {
                            console.log('conn: ', connectionId)
                        })
                        .catch(err => console.log(err))
                }
            }
            catch (err) {
                if (this.fsHubConnection.state === signalR.HubConnectionState.Disconnected) {
                    setTimeout(async () => {
                        await this.startFsWebhookConnection(username)
                    }, 3000)
                }
            }
        }
    }

    render() {
        const { show, userInfo, subscriptionPlans } = this.state
        if (isNotNull(show) && show && !userInfo.hasSubscribed) {
            if (userInfo.hasSubscribed) {
                return (<Redirect to={{ pathname: '/dashboard' }} />)
            } else {
                return (
                    <>
                        <HeaderSection />

                        <LearnHowToSection />

                        <CheckoutSection 
                            subscriptionPlans={subscriptionPlans} 
                            userInfo={userInfo} />

                        <FeaturesComparisonSection />

                        <UserFeedbackSection />

                        <PowerBreakdownSection />

                        <TheFounderSection />

                        <CheckoutSection 
                            className="overlapping"
                            subscriptionPlans={subscriptionPlans} 
                            userInfo={userInfo} />

                        <ProfitableTradingSection />

                        <CaseStudiesSection />

                        <WhyUpgradeSection />

                        <MoneyBackGuaranteeSection />

                        <ImagineThreeMonthsSection />

                        <CheckoutSection 
                            subscriptionPlans={subscriptionPlans} 
                            userInfo={userInfo} />

                        <TestimonialsSection />
                    </>
                )
            }
        } else {
            return (<></>)
        }
    }
}

function mapStateToProps(state) {
    const { authentication, plan } = state
    return {
        authentication,
        plan
    }
}

const connectedProPlans = connect(mapStateToProps)(ProPlans)
export { connectedProPlans as ProPlans }