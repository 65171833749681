import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const ProStatsSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    background-color: #0B1219;
    padding: 60px 0;
    background-image: url(images/trial-offer/ellipse-gray.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    .container {
        .section-title {
            font-weight: 700;
            color: #FFFFFF;
            text-align: center;
        }

        .text-with-img {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;

            .astronaut-with-logo {
                display: none;
                position: relative;

                img {
                    position: absolute;
                    width: 100%;
                    left: calc(50% - 253px);
                }

                /*&:before {
                    display: block;
                    position: absolute;
                    width: 898px;
                    height: 484px;
                    top: -16px;
                    left: calc(50% = 436px);
                    content:'';
                    background: transparent radial-gradient(closest-side at 50% 50%, #0A9E5A 0%, #0F172100 100%) 0% 0% no-repeat padding-box;
                }*/
            }
        }

        .numbers {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            text-align: center;
            width: 100%;

            .percentages {
                display: flex;
                flex-direction: row;
                margin-bottom: 0;

                .value {
                    display: flex;
                    flex-direction: column;
                    width: 50%;
                    text-align: center;

                    > span {
                        color: #FFFFFF;
                        font-size: 22px;
                        margin-bottom: 5px;
                        font-family: 'Noto Sans';

                        .icon {
                            margin-right: 12px;

                            img {
                                height: 36px;
                            }
                        }
                    }

                    > .label {
                        font-family: 'Montserrat';
                        font-weight: 300;
                        font-size: 17px;

                        &.green {
                            color: #11D487;
                        }

                        &.red {
                            color: #FC6786;
                        }
                    }
                }
            }

            .counter {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 10px;

                .value {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #FFFFFF;
                    background-color: rgba(255, 255, 255, 0.1);
                    border: 1px solid #9AB9D5;
                    border-top-left-radius: 25px;
                    border-bottom-right-radius: 25px;
                    padding: 10px 15px;
                    width: auto;
                    margin-bottom: 12px;

                    .icon {
                        width: 55px;
                        margin-right: 12px;

                        img {
                            width: 100%;
                        }
                    }

                    .text {
                        display: flex;
                        flex-direction: column;
                        line-height: 1.2;
                    }

                    &:first-child {
                        margin-right: 8px;
                    }
                }
            }

            .top-signals-carousel {
                margin-bottom: 25px;
                
                .carousel-inner {
                    width: 100%;
                    height: 343px;

                    .carousel-item {
                        text-align: center;
                    }
                }

                .carousel-control-prev {
                    left: -21px;
                }

                .carousel-control-next {
                    right: -21px;
                }
            }

            .top-signals {
                display: flex;
                margin-bottom: 15px;
            }

            p {
                font-size: 17px;
                color: #FFFFFF;
            }
        }
    }

    &.up-w-trial,
    &.up-win-trial {        
        &::before {
            display: block;
            position: absolute;
            content: '';
            width: 100%;            
            height: 915px;
            background: transparent radial-gradient(closest-side at 50% 50%,#203448 0%,#0b1219 100%) 0% 0% no-repeat padding-box;
            top: -432px;
            left: 0;
            z-index: 0;
        }

        .container {
            position: relative;

            .section-title {
                font-size: 24px;
                margin-bottom: 25px;
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        .container {
            .section-title {
                font-size: 32px;
            }

            .text-with-img {
                .numbers {
                    width: 100%;
                    align-items: center;

                    .percentages {
                        width: 100%;
                        
                        .value {
                            > span {
                                font-size: 42px;
                                font-weight: 600;

                                &.label {
                                    font-size: 20px;
                                    font-weight: 400;
                                }
                            }
                        }
                    }

                    .counter {
                        width: 100%;
                        flex-direction: row;
                        justify-content: space-around;

                        .value {
                            width: auto;
                            font-size: 23px;
                        }
                    }

                    .top-signals {
                        img {
                            width: 138px;
                        }
                    }

                    p {
                        font-size: 20px;
                    }
                }
            }
        }

        &.up-w-trial,
        &.up-win-trial {
            .container {
                .section-title {
                    font-size: 32px;
                }

                .numbers {
                    .percentages {
                        
                    }
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        .container {
            .text-with-img {
                flex-direction: row;
                align-items: center;

                .astronaut-with-logo {
                    display: flex;
                    width: 365px;
                    height: 300px;

                    img {
                        left: -25px;
                    }
                }

                .numbers {
                    width: calc(100% - 365px);

                    .top-signals {
                        img {
                            width: 120px;
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        .container {
            .section-title {
                margin-bottom: -5px;
            }

            .text-with-img {
                .astronaut-with-logo {
                    display: block;
                    margin-right: 20px;
                    width: 484px;
                    height: 440px;

                    img {
                        top: 40px;
                        left: -10px;
                    }
                }

                .numbers {
                    width: 606px;

                    .percentages {
                        justify-content: space-between;
                        margin-bottom: 15px;

                        .value {
                            width: auto;

                            > span {
                                font-size: 60px;
                                margin-bottom: -8px;

                                > .label {
                                    font-size: 20px;
                                    font-weight: 400;
                                }
                            }
                        }
                    }

                    .counter {
                        justify-content: space-between;

                        .value {
                            font-size: 40px;
                            font-weight: 500;
                            padding: 10px 25px;
                            width: 50%;

                            .icon {
                                margin-right: 15px;
                            }

                            small {
                                font-size: 15px;
                                font-weight: 300;
                            }

                            &:first-child {
                                margin-right: 15px;
                            }
                        }
                    }

                    .top-signals-carousel {
                        display: none;
                    }

                    .top-signals {
                        img {
                            width: 118px;
                            margin-right: 5px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }

        &.up-w-trial,
        &.up-win-trial {
            .container {
                .section-title {
                    font-weight: 500;
                    margin-bottom: 15px;
                }

                .text-with-img {
                    flex-direction: row-reverse;

                    .astronaut-with-logo {
                        width: calc(100% - 520px);

                        img {
                            left: auto;
                        }
                    }

                    .numbers {
                        width: 520px;
                        margin-right: 50px;

                        .percentages {
                            .value {
                                > span {
                                    font-size: 50px;

                                    &.label {
                                        font-size: 20px;
                                    }
                                }
                            }
                        }

                        .counter {
                            .value {
                                padding: 10px;
                            }
                        }

                        .top-signals {
                            img {
                                width: 101px;
                            }
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.superExtraLargeDevices} {
        padding: 80px 0 60px;

        .container {
            .text-with-img {
                .astronaut-with-logo {
                    img {
                        top: 0px;
                        left: -80px;
                        width: 115%;
                    }
                }
            }
        }
    }
`