import styled from 'styled-components';

export const NotificationTime = styled.small`
    color: ${ props => props.unRead === false ? '#2E89FF' : '#ced0dd'};
    width: 100%;
`;

export const NotificationContent = styled.p`
    margin-bottom: 0;
`;

export const NotificationTitle = styled.h6`
    padding-bottom: 5px;
    margin-bottom: 0;
`;

export const NotificationContentContainer = styled.div`
    width: calc(100% - 67px);

    ${NotificationTitle},
    ${NotificationContent},
    ${NotificationTime} {
        pointer-events: none;
        width: 100%;
    }
`;

export const CoinsImg = styled.img`
    width: 32px;
    height: 32px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-size: 99%;
    border-radius: 50%;
    background-color: #283544;
    box-shadow: 0 2px 5px 1px rgba(0,0,0,0.3);
    pointer-events: none;
`;

export const UnReadBadge = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: ${ props => props.unRead === false ? '#2E89FF' : 'transparent'};
    margin-left: 10px;
`;

export const NotificationCard = styled.div`
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 15px;
    background-color: ${ props => props.unRead === false ? '#293442' : '#19212b'};

    &.all {
        background-color: ${ props => props.unRead === false ? '#32455f !important' : '#172331 !important'};

        &:hover {
            background-color: #32455f !important;
        }

        ${NotificationContentContainer} {
            ${NotificationTime} {
                font-weight: ${ props => props.unRead === false ? '600' : '400'};
            }
        }
    }

    &:hover {
        background-color: #293442;
        opacity: 0.9;
    }

    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
    }
`;

export const NotificationContentWrapper = styled.li`
    display: flex;
    align-items: center;
    padding: 8px 8px 0;

    &:last-child {
        padding-bottom: 8px;
    }
`;
