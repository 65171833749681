import { Form, Button } from 'react-bootstrap'
import _ from 'lodash'
import { Filter } from '../styled_components'
import { isNotNull } from '../../../utils'

export const ScannerHeader = (props) => {
    const { type, filterValue, coinTargetFilter, isWatched, selectedMoved } = props
    const rsiPeakList = [
        { name: "RSI 7/85/15", value: "rsi7" },
        { name: "RSI 14/70/30", value: "rsi14" }
    ]
    let disabled = true
    const filterList = [
        { name: "ALL", value: "ALL" },
        { name: "BTC", value: "BTC" },
        { name: "USDT", value: "USDT" },
        { name: "ETH", value: "ETH" },
    ]

    const filterMap = filterList.map((item, index) => (
        <option value={item.value} key={index}>{item.name}</option>
    ))

    const filterMoveLists = [
        { name: "Down Move", value: "down" },
        { name: "Up Move", value: "up" }
    ]

    const filterMoveMap = filterMoveLists.map((item, index) => (
        <option value={item.value} key={index}>{item.name}</option>
    ))

    const renderPeakStatsFilter = (type, props, rsiPeakList) => {
        switch (type) {
            case "relative_strength": {
                const { loading } = props.relativeStrengthAllItems
                let filterRSIList = []

                if (!loading && rsiPeakList)
                    rsiPeakList.forEach(e => {
                        filterRSIList.push({ name: e.name, value: e.value })
                    })

                if (isNotNull(filterRSIList))
                    return filterRSIList.map((item, index) => (
                        <option value={item.value} key={index}>{item.name}</option>
                    ))

                break
            }
            case "price_action": {
                const { loading, items } = props.priceActions
                let peakStats = null
                if (isNotNull(items)) {
                    if (!loading && items)
                        _.forEach(items, (value) => {
                            if (isNotNull(value.peakStats))
                                peakStats = Object.keys(value.peakStats)
                        })

                    let filterPeaksStatsList = []
                    if (isNotNull(peakStats))
                        peakStats.forEach(e => {
                            filterPeaksStatsList.push({ name: e.toString().toUpperCase(), value: e.toString().toUpperCase() })
                        })

                    if (isNotNull(filterPeaksStatsList))
                        return filterPeaksStatsList.map((item, index) => (
                            <option value={item.value} key={index}>{item.name}</option>
                        ))
                }
                break
            }
            default:
                break
        }
    }

    switch (type) {
        case "relative_strength": {
            const { loading } = props.relativeStrengthAllItems
            if (!loading)
                disabled = false
            break
        }
        case "price_action": {
            const { loading } = props.priceActions
            if (!loading)
                disabled = false
            break
        }
        default:
            disabled = true
            break
    }

    let filterPeakStatsMap = renderPeakStatsFilter(type, props, rsiPeakList)

    return (
        <Filter className="scanner-headers">
            <li className="form-inline">
                <Form>
                    <Form.Group>
                        <Form.Control as="select" disabled={disabled} onChange={(e) => props.onChangeFilter(e)} value={isNotNull(filterValue) ? filterValue : ""}>
                            {filterPeakStatsMap}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </li>
            {isNotNull(type) && type === "price_action" &&
                <li className="form-inline">
                    <Form>
                        <Form.Group>
                            <Form.Control as="select" disabled={disabled} onChange={(e) => props.onMoveChange(e)} value={isNotNull(selectedMoved) ? selectedMoved : ""}>
                                {filterMoveMap}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </li>
            }
            <li className="form-inline">
                <Form>
                    <Form.Group>
                        <Button variant="light" disabled={disabled} onClick={(e) => props.onWatchFilterChange(e)}>{isWatched ? "Show All" : "Watch List"}</Button>
                    </Form.Group>
                </Form>
            </li>
            <li className="form-inline">
                <Form>
                    <Form.Group>
                        <Form.Control as="select" disabled={disabled} onChange={(e) => props.onCoinTargetFilterChange(e)} value={isNotNull(coinTargetFilter) ? coinTargetFilter : ""}>
                            {filterMap}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </li>
        </Filter>
    )
}