import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import jQuery from 'jquery'
import Swal from 'sweetalert2'
import _ from 'lodash'
import * as signalR from '@microsoft/signalr'
import { authActions, planActions, userActions } from './../../redux/actions'
import { PaymentView } from './PaymentView'
import { InjectFastSpring, isNotNull, onSetNewState, addPageViewEvent, seo } from '../../utils'

class PaymentContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            accessToken: '',
            paymentReceived: false,
            userId: '',
            userName: '',
            firstName: '',
            lastName: '',
            isRedirectedToPayment: false,
            isRedirectToDashboard: false,
            contentLoaded: false,
            isPriceLoading: true,
            subscription: [
                {
                    id: '0',
                    name: 'Monthly',
                    val: 'monthly',
                    price: 265
                }, {
                    id: '1',
                    name: 'Quarterly',
                    val: 'quarterly',
                    price: 699
                }, {
                    id: '2',
                    name: 'Yearly',
                    val: 'yearly',
                    price: 2659
                }
            ],
        }

        this.mounted = false
        this.baseState = this.state
        this.fastspringHubConnection = null
    }

    componentDidMount() {
        this.mounted = true
        const { dispatch } = this.props

        const params = queryString.parse(this.props.location.search)
        if (isNotNull(params) && isNotNull(params.token)) {
            // try to authenticate the token
            const token = params.token //params.token.replace(/ /g, "+")
            if (isNotNull(token)) {
                dispatch(authActions.autoLoginByToken(token))
            }
        } else {
            dispatch(userActions.getProfile())
        }

        InjectFastSpring()

        window.onPaymentReceived = order => {
            if (isNotNull(order)) {
                Swal.fire({
                    title: 'Payment successful, please wait while we setup your account',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    didOpen: () => Swal.showLoading()
                })
            }
        }

        this.initFastSpringSocket()

        seo({
            title: "Payment",
            metaDescription: ""
        })
        addPageViewEvent()
    }

    async componentDidUpdate(prevProps, prevState) {
        const { dispatch, authentication, userProfile, plan } = this.props
        const { paymentReceived, contentLoaded, subscription } = this.state

        if (isNotNull(authentication) && prevProps.authentication !== authentication) {
            const { loggedIn, autoLoggedIn } = authentication
            if ((isNotNull(loggedIn) && loggedIn) || 
                (isNotNull(autoLoggedIn) && autoLoggedIn)) {
                dispatch(userActions.getProfile())
            }
        }

        if (isNotNull(userProfile) && prevProps.userProfile !== userProfile && !userProfile.loading) {
            if (isNotNull(userProfile.profile)) {                
                // retrieve subscription plans list
                dispatch(planActions.get())

                const { userId, userName, firstName, lastName } = userProfile.profile 
                if (isNotNull(userId) && userId !== prevState.userId) {
                    onSetNewState(this, {
                        userId
                    })
                }

                if (isNotNull(userName) && contentLoaded === false) {
                    await this.startSocketConnection(userName)
                    onSetNewState(this, {
                        userName, 
                        contentLoaded: true 
                    })
                }

                if (isNotNull(firstName)) {
                    onSetNewState(this, {
                        firstName: firstName
                    })
                }

                if (isNotNull(lastName)) {
                    onSetNewState(this, {
                        lastName: lastName
                    })
                }
            } else
                window.location.href = "/forbidden"
        }

        if (prevProps.plan !== plan && !plan.loading) {
            const subscriptionPlans = plan.plans
            if (isNotNull(subscriptionPlans) && isNotNull(subscriptionPlans.Fastspring)) {
                subscriptionPlans.Fastspring.forEach(f => {
                    if (f.normalizedName.indexOf("COMBINATION") >= 0) {
                        if (f.normalizedName.indexOf("MONTHLY") >= 0) {
                            subscription[0].id = f.id
                            subscription[0].price = f.amount
                            subscription[0].refKey = f.refKey
                        } else if (f.normalizedName.indexOf("QUARTERLY") >= 0) {
                            subscription[1].id = f.id
                            subscription[1].price = parseFloat(f.amount / 3).toFixed(0)
                            subscription[1].originalPrice = f.amount
                            subscription[1].refKey = f.refKey
                        } else if (f.normalizedName.indexOf("YEARLY") >= 0) {
                            subscription[2].id = f.id
                            subscription[2].originalPrice = parseFloat(f.amount / 3).toFixed(0)
                            subscription[2].price = f.amount
                            subscription[2].refKey = f.refKey
                        }
                    }

                    onSetNewState(this, {
                        subscription,
                        isPriceLoading: false
                    })
                })
            }
        }
        
        if (prevState.paymentReceived !== paymentReceived && paymentReceived) {
            const { dispatch } = this.props
            dispatch(userActions.getMe())
            onSetNewState(this, {
                isRedirectedToPayment: true
            })
        }
    }

    componentWillUnmount() {
        if (isNotNull(this.restartConnectionTimeout))
            clearTimeout(this.restartConnectionTimeout)

        this.setState(this.baseState)
        this.mounted = false
    }

    initFastSpringSocket = async () => {
        if (this.mounted) {
            // configure signalr connection for payment notification
            const hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(`https://webhks.profitfarmers.com/fs`, {
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets
                })
                .configureLogging(signalR.LogLevel.Information)
                .withAutomaticReconnect([0, 0, 10000])
                .build()

            hubConnection.serverTimeoutInMilliseconds = 120000

            this.fastspringHubConnection = hubConnection
            this.fastspringHubConnection.on("OnPaymentMade", (payload) => {
                const { userId } = this.state
                if (isNotNull(payload) && 
                    isNotNull(userId) && 
                    payload === userId) {
                        jQuery(document).find("#fscCanvas").remove()
                        Swal.close()                    
                        onSetNewState(this, { 
                            paymentReceived: true
                            //isRedirectedToPayment: true 
                        })
                }
            })
        }
    }

    async startSocketConnection(username) {
        if (this.mounted) {
            try {
                await this.fastspringHubConnection
                    .start()
                    .catch(err => console.log(err))

                if (this.fastspringHubConnection.state === signalR.HubConnectionState.Connected) {
                    this.fastspringHubConnection.invoke("GetConnectionId", username)
                        .then((connectionId) => {
                            console.log('conn: ', connectionId)
                        })
                        .catch(err => console.log(err))
                }
            } catch (err) {
                if (this.fastspringHubConnection.state === signalR.HubConnectionState.Disconnected) {
                    // restart connection
                    setTimeout(async () => 
                        await this.startSocketConnection(username), 5000)
                }
            }
        }
    }

    onPaymentHandler = (e, plan = null) => {
        const { name } = e.target
        if (isNotNull(name)) {            
            this.submitPayment(plan)
        }
    }

    submitPayment = (plan) => {
        const { userId, userName, firstName, lastName, subscription } = this.state
        if (isNotNull(userId) && isNotNull(userName) && isNotNull(firstName)) {
            const value = _.find(subscription, q => q.val === plan)
            if (isNotNull(value)) {
                const session = {
                    reset: true,
                    products: [{
                        path: value.refKey,
                        quantity: 1
                    }],
                    paymentContact: {
                        email: userName,
                        firstName: firstName,
                        lastName: lastName
                    },
                    tags: {
                        userId: userId,
                        sPlanId: value.id,
                        //customerId: user.cid,
                        leadId: null,
                        isLead: false
                    },
                    checkout: true
                }
                window.fastspring.builder.push(session)
            }
        }
    }

    render() {
        const { isRedirectedToPayment, contentLoaded } = this.state

        return (
            <PaymentView
                isRedirectedToPayment={isRedirectedToPayment}
                isContentLoaded={contentLoaded}
                onPaymentHandler={this.onPaymentHandler}
                context={this}
                state={this.state}
                {...this.props}>

            </PaymentView>
        )
    }
}

function mapStateToProps(state) {
    const { authentication, userProfile, plan, confirmEmail } = state
    return {
        authentication,
        userProfile,
        plan,
        confirmEmail,
    }
}
const connectedPaymentContainer = connect(mapStateToProps)(PaymentContainer)
export { connectedPaymentContainer as PaymentContainer }