import { Skeleton } from '@material-ui/lab'
import _ from 'lodash'
import { Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar } from '@fortawesome/free-solid-svg-icons'
import { StrategyFilter, PeriodRange } from './components'
import { StatisticWrapper, StatisticContainaner } from './styled_components'
import { Table, CustomToggle, InlineFilter } from '../../styles'
import { isNotNull } from '../../utils'

export const StatisticView = (props) => {
    const {
        totalTrade, winTrade, losingTrade,
        winRate, totalBreakevenTrade, selectedPeriod,
        selectedStrategy, isStrategyChange, isEnable,
        isToggle } = props.state
    const { user } = props.authentication
    const { items, loader } = props.statistics

    let loading = true
    let statisticItems = null
    if (!loader) {
        if (isNotNull(items)) {
            loading = false
            const { strategies } = items
            if (isNotNull(strategies)) {
                let filterStats = _.filter(strategies, (val) => {
                    return isToggle ? strategies : val.strategy.totalTrades > 0
                })

                if (filterStats.length > 0) {
                    const orderByStatsLists = _.orderBy(filterStats, val => val.strategy.name, "asc")
                    statisticItems = orderByStatsLists.map((val, key) => {
                        return (
                            (
                                <tr key={key} className="expanded-data">
                                    { window.innerWidth >= 1024 &&
                                        <>
                                            <td>{val.strategy.name}</td>
                                            <td>{val.strategy.totalTrades}</td>
                                            <td>{val.strategy.winningTrades}</td>
                                            <td>{val.strategy.losingTrades}</td>
                                            <td>{val.strategy.totalBreakevenTrades}</td>
                                            <td>{val.strategy.winRate}%</td>
                                            <td>{val.strategy.avgWinPercentagePerTrade}%</td>
                                        </>
                                    }

                                    { window.innerWidth < 1024 &&
                                        <td colSpan={9}>
                                            <div className="expanded-wrapper">
                                                <span className="col-sm-6 col-md-8">STRATEGY</span>
                                                <span className="col-sm-6 col-md-4">{val.strategy.name}</span>
                                            </div>

                                            <div className="expanded-wrapper">
                                                <span className="col-sm-6 col-md-8">TOTAL TRADES</span>
                                                <span className="col-sm-6 col-md-4">{val.strategy.totalTrades}</span>
                                            </div>

                                            <div className="expanded-wrapper">
                                                <span className="col-sm-6 col-md-8">WINNING TRADES</span>
                                                <span className="col-sm-6 col-md-4">{val.strategy.winningTrades}</span>
                                            </div>

                                            <div className="expanded-wrapper">
                                                <span className="col-sm-6 col-md-8">LOSING TRADES</span>
                                                <span className="col-sm-6 col-md-4">{val.strategy.losingTrades}</span>
                                            </div>

                                            <div className="expanded-wrapper">
                                                <span className="col-sm-6 col-md-8">CLOSED EARLY TRADES</span>
                                                <span className="col-sm-6 col-md-4">{val.strategy.totalBreakevenTrades}</span>
                                            </div>

                                            <div className="expanded-wrapper">
                                                <span className="col-sm-6 col-md-8">WIN RATE</span>
                                                <span className="col-sm-6 col-md-4">{val.strategy.winRate}%</span>
                                            </div>

                                            <div className="expanded-wrapper">
                                                <span className="col-sm-6 col-md-8">AVG WIN% PER TRADE</span>
                                                <span className="col-sm-6 col-md-4">{val.strategy.avgWinPercentagePerTrade}%</span>
                                            </div>
                                        </td>
                                    }
                                </tr>
                            )
                        )
                    })
                } else {
                    if (strategies.length <= 0)
                        statisticItems = (
                            <tr>
                                <td colSpan="7">
                                    <h5 className="my-3 text-center">No Strategy Selected</h5>
                                </td>
                            </tr>
                        )
                    else
                        statisticItems = (
                            <tr>
                                <td colSpan="7">
                                    <h5 className="my-3 text-center">No Statistic Data Available</h5>
                                </td>
                            </tr>
                        )
                }
            } else
                statisticItems = (
                    <tr>
                        <td colSpan="7">
                            <h5 className="my-3 text-center">No Statistic Data Available</h5>
                        </td>
                    </tr>
                )
        }
    }

    const preloader = (
        <>
            <Skeleton variant="rect" height={75} style={{
                width: '100%',
                marginBottom: '15px',
                backgroundColor: '#1f2b39',
                borderRadius: '2px',
                marginLeft: 'auto'
            }} className="skeleton" />

            <Skeleton variant="rect" style={{
                width: '100%',
                height: '625px',
                backgroundColor: '#1f2b39',
                border: '1px solid #27313f',
                borderRadius: '4px'
            }} className="skeleton" />
        </>
    )

    const onHandleToggle = () => {
        props.self.setState((prev) => {
            return ({ isToggle: !prev.isToggle })
        })
    }

    return (
        <StatisticWrapper className="theme-statistics theme-main-wrapper">
            <StatisticContainaner className="filters">
                <div className="filters-title">
                    <h2 className="filter-title theme-section-title">
                        <FontAwesomeIcon icon={faChartBar} /> Statistics
                    </h2>
                    { window.innerWidth < 1024 &&
                        <CustomToggle onChange={!isEnable ? onHandleToggle : null} className="wallet-toggle statistics">
                            <input type="checkbox" />
                            <i className="wallet-toggle" />
                            {isToggle ? "Hide No Data" : "Show All"}
                        </CustomToggle>
                    }
                </div>

                {isNotNull(user) &&
                    <div className="filter-lists">
                        { window.innerWidth >= 1024 &&
                            <CustomToggle onChange={!isEnable ? onHandleToggle : null} className="wallet-toggle statistics mr-3">
                                <input type="checkbox" />
                                <i className="wallet-toggle" />
                                {isToggle ? "Hide No Data" : "Show All"}
                            </CustomToggle>
                        }

                        <InlineFilter className="filter mr-md-3 stat">
                            <li className="form-inline">
                                <div className="form-group">
                                    <label className="statistics-strat">Strategy</label>
                                    <StrategyFilter
                                        onStrategyChange={props.onStrategyChange}
                                        onStrategySubmit={props.onStrategySubmit}
                                        selectedFilter={selectedStrategy}
                                        isChange={isStrategyChange}
                                        isEnable={isEnable} />
                                </div>
                            </li>
                        </InlineFilter>

                        <PeriodRange
                            onPeriodChange={props.onPeriodChange}
                            selectedFilter={selectedPeriod}
                            isEnable={isEnable} />
                    </div>
                }
            </StatisticContainaner>

            { loading && preloader }

            { !loading && isNotNull(user) &&
                <>
                    <StatisticContainaner className="banners">
                        <Card>
                            <small>TOTAL TRADES</small>
                            <h2>{totalTrade}</h2>
                        </Card>

                        <Card>
                            <small>WINNING TRADES</small>
                            <h2>{winTrade}</h2>
                        </Card>

                        <Card>
                            <small>LOSING TRADES</small>
                            <h2>{losingTrade}</h2>
                        </Card>

                        <Card>
                            <small>CLOSED EARLY TRADES</small>
                            <h2>{totalBreakevenTrade}</h2>
                        </Card>
                        <Card>
                            <small>WIN RATE</small>
                            <h2>{winRate}%</h2>
                        </Card>
                    </StatisticContainaner>

                    <StatisticContainaner className="table-statistics-content">
                        <Table className="table table-statistics">
                            <thead className="statistics-thead">
                                <tr>
                                    <th>STRATEGY</th>
                                    <th>TOTAL TRADES</th>
                                    <th>WINNING TRADES</th>
                                    <th>LOSING TRADES</th>
                                    <th>CLOSED EARLY TRADES</th>
                                    <th>WIN RATE</th>
                                    <th>AVG WIN% PER TRADE</th>
                                </tr>
                            </thead>
                            <tbody className="statistics">
                                {statisticItems}
                            </tbody>
                        </Table>
                    </StatisticContainaner>
                </>
            }
        </StatisticWrapper>
    )

}