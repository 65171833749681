import _ from 'lodash'
import { Skeleton } from "@material-ui/lab"
import { PriceActionItem } from '../components'
import { PreloaderWrapper, Preloader, PreloaderLogo, PreloaderItem, ErrorMessage } from '../styled_components'
import { isNotNull } from '../../../utils'

export const PriceActionView = (props) => {

    const { coinPair,
        s1, s2, s3, s4, s5, s6, s7, s8,
        price, volume, marketCap,
        peakGains, liveFibo, d3move, selectedItem, paFilter,
        selectedMoved, type } = props
    const { loading, items } = props.priceActions
    let selectedValue = null
    let isSelected = false
    let priceActionItems = null

    const onGetFilterWaveData = (filterValue, value) => {
        let selectedValue = ""

        switch (value) {
            case "pg":
                selectedValue = "pg"
                break
            case "v":
                selectedValue = "v"
                break
            case "hhcmp":
                selectedValue = "hhcmp"
                break
            case "llcmp":
                selectedValue = "llcmp"
                break
            case "pl":
                selectedValue = "pl"
                break
            default:
                break
        }

        switch (filterValue) {
            case "H6":
                return `peakStats.h6.${selectedValue}`
            case "H12":
                return `peakStats.h12.${selectedValue}`
            case "D1":
                return `peakStats.d1.${selectedValue}`
            case "D2":
                return `peakStats.d2.${selectedValue}`
            case "D3":
                return `peakStats.d3.${selectedValue}`
            case "D7":
                return `peakStats.d7.${selectedValue}`
            case "D15":
                return `peakStats.d15.${selectedValue}`
            case "D30":
                return `peakStats.d30.${selectedValue}`
            case "D100":
                return `peakStats.d100.${selectedValue}`
            case "D200":
                return `peakStats.d200.${selectedValue}`
            case "D500":
                return `peakStats.d500.${selectedValue}`
            default:
                break
        }
    }

    const onSortHandler = (lists, selectedValue, sort) => {
        let selectedSort = [selectedValue]
        switch (selectedValue) {
            case "n":
                selectedSort = [selectedValue]
                break
            default:
                selectedSort = [selectedValue, 'n']
                break
        }
        return _.orderBy(lists, selectedSort, [sort, sort])
    }

    const renderPriceActionLists = (selectedItem, sort, paLists, props) => {
        const { targetFilter, paFilter, isWatched, currentSet } = props
        let { selectedValue, sortValue } = ""
        let watchedLists = []

        if (isNotNull(paLists)) {
            const { data } = paLists
            if (isNotNull(data)) {
                data.forEach(e => {
                    if (e.isWatched)
                        watchedLists.push(e)
                })
            }
        }

        if (typeof selectedItem !== "undefined" && typeof sort !== "undefined") {
            if (selectedItem == null) {
                selectedValue = 'n'
                sortValue = 'asc'
            } else {
                selectedValue = selectedItem
                sortValue = sort ? 'desc' : 'asc'
            }
        }

        if (isNotNull(paLists)) {
            let allLists = paLists.filter(e => {
                return !isWatched ? e : e.isWatched === true
            })

            let filtered = allLists.filter(function (data) {
                return targetFilter === "All" ? paLists : data.n.endsWith(targetFilter)
            })

            if (targetFilter === "ALL") {
                if (allLists.length > 0) {
                    let list = selectedValue === "cp"
                        ? _.orderBy(allLists, item => parseFloat(item.cp), [sortValue])
                        : onSortHandler(allLists, selectedItem, sortValue)

                    let paginated = _.filter(list, (val, key) => key <= currentSet)
                    return paginated.map((value, key) => {
                        return (
                            <PriceActionItem
                                show={props.onShowModal}
                                close={props.onCloseModal}
                                paFilter={paFilter}
                                key={key}
                                selectedMoved={selectedMoved}
                                watchListed={value.isWatched}
                                handleChange={(value, flag) => props.onWatch(value, flag, type)}
                                onExpand={(e) => props.onExpand(e)}
                                onDisplay={(e) => props.onDisplay(e)}
                                {...value}
                            />
                        )
                    })
                }
                else
                    return renderErrorMessage("No Coin Pair Watched")
            }
            else {
                if (filtered.length > 0) {
                    let list = selectedValue === "cp"
                        ? _.orderBy(filtered, item => parseFloat(item.cp), [sortValue])
                        : onSortHandler(filtered, selectedItem, sortValue)

                    let paginated = _.filter(list, (val, key) => key <= currentSet)
                    return paginated.map((value, key) => {
                        return (
                            <PriceActionItem
                                show={props.onShowModal}
                                close={props.onCloseModal}
                                paFilter={paFilter}
                                key={key}
                                selectedMoved={selectedMoved}
                                watchListed={value.isWatched}
                                handleChange={(value, flag) => props.onWatch(value, flag, type)}
                                onExpand={(e) => props.onExpand(e)}
                                onDisplay={(e) => props.onDisplay(e)}
                                {...value}
                            />
                        )
                    })
                } else
                    return renderErrorMessage("No Coin Pair Watched")
            }

        }

    }

    const renderErrorMessage = (message) => {
        return (
            <ErrorMessage className="scanner-error">{message}</ErrorMessage>
        )
    }

    const renderPreloader = () => {
        const preloaderData = [0, 1, 2, 3, 4]
        return _.map(preloaderData, (val, key) => {
            return (
                <PreloaderWrapper key={key} className="preloader-wrapper">
                    <Preloader>
                        <PreloaderLogo>
                            <Skeleton variant="rect" style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: 'linear-gradient(15deg,#334156,#385065)',
                                borderRadius: '100%',
                            }} className="skeleton" />
                        </PreloaderLogo>
                        <PreloaderItem>
                            <Skeleton variant="rect" style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: 'linear-gradient(15deg,#334156,#385065)',
                                border: '1px solid #27313f',
                                borderRadius: '4px',
                                boxShadow: '0px 3px 3px 2px rgba(0,0,0,0.08)'
                            }} className="skeleton" />
                        </PreloaderItem>
                    </Preloader>
                </PreloaderWrapper>
            )
        })
    }

    switch (selectedItem) {
        case "coinPair":
            selectedValue = 'n'
            isSelected = coinPair
            break
        case "s1":
            selectedValue = 'sm5'
            isSelected = s1
            break
        case "s2":
            selectedValue = 'sm15'
            isSelected = s2
            break
        case "s3":
            selectedValue = 'sm30'
            isSelected = s3
            break
        case "s4":
            selectedValue = 'sh1'
            isSelected = s4
            break
        case "s5":
            selectedValue = 'sh4'
            isSelected = s5
            break
        case "s6":
            selectedValue = 'sh6'
            isSelected = s6
            break
        case "s7":
            selectedValue = 'sh12'
            isSelected = s7
            break
        case "s8":
            selectedValue = 'sd1'
            isSelected = s8
            break
        case "price":
            selectedValue = 'cp'
            isSelected = price
            break
        case "volume":
            selectedValue = onGetFilterWaveData(paFilter, "v")
            isSelected = volume
            break
        case "marketCap":
            selectedValue = 'mc'
            isSelected = marketCap
            break
        case "peakGains":
            selectedValue = onGetFilterWaveData(paFilter, selectedMoved === "up" ? "pg" : "pl")
            isSelected = peakGains
            break
        case "liveFibo":
            selectedValue = onGetFilterWaveData(paFilter, selectedMoved === "up" ? "hhcmp" : "llcmp")
            isSelected = liveFibo
            break
        case "d3move":
            selectedValue = 'pm'
            isSelected = d3move
            break
        default:
            selectedValue = 'n'
            isSelected = false
            break
    }

    if (!loading) {
        if (isNotNull(items)) {
            if (items) {
                priceActionItems = renderPriceActionLists(selectedValue, isSelected, items, props)
            } else
                priceActionItems = renderErrorMessage("Internal Server Error")
        } else
            priceActionItems = renderErrorMessage("Internal Server Error")
    }

    return (
        <div className="scanner-wrapper">
            {loading &&
                <>
                    {renderPreloader()}
                </>
            }
            {!loading &&
                <>
                    {priceActionItems}
                </>
            }
        </div>
    )
}