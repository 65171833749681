import styled from 'styled-components';

export const ThemeSwitchWrapper = styled.span`
	display: flex;
  	align-items: center;
	margin-right: 10px;

	.theme-switch {
		display: block;
	    cursor: pointer;
	    -webkit-tap-highlight-color: transparent;
	    margin-bottom: 0;
	    color: #778698;
	    pointer-events: none;
	    font-size: 14px;

	    &.active {
	        pointer-events: auto;
	        color: #94a7c1;
	    }

	    i.theme-toggle {
	        position: relative;
	        display: inline-block;
	        margin: 0 15px;
	        width: 38px;
	        height: 20px;
	        background-color: #202A35;
	        border-radius: 23px;
	        vertical-align: text-bottom;
	        transition: all 0.3s linear;

	        &.active {
	            &::before {
	                background-color: #19212b;
	            }

	            &::after {
	                background-color: #ccd4de;
	            }
	        }

	        &::before {
	            content: "";
	            position: absolute;
	            left: 0;
	            width: 33px;
	            height: 17px;
	            background-color: #19212b;
	            border-radius: 11px;
	            transform: translate3d(2px,2px,0) scale3d(1,1,1);
	            transition: all 0.25s linear;
	        }

	        &::after {
	            content: "";
	            position: absolute;
	            left: 0;
	            width: 16px;
	            height: 16px;
	            background-color: #4a4a4a;
	            border-radius: 11px;
	            box-shadow: 0 0px 2px 2px rgba(0,0,0,0.24);
	            transform: translate3d(2px,2px,0);
	            transition: all 0.2s ease-in-out;
	        }
	    }

	    input {
	        display: none;

	        &:checked + i.theme-toggle {
	            background-color: #3f6654 !important;

	            &.active {
	                background-color: #15bd6f !important;
	            }

	            &::before {
	                transform: translate3d(14px, 2px, 0) scale3d(0, 0, 0);
	            }

	            &::after {
	                transform: translate3d(20px, 2px, 0);
	                background-color: #25313f;
	            }
	        }
	    }

	    &:active {

	        i.theme-toggle {
	            &::after {
	                width: 32px;
	                transform: translate3d(2px,2.3px,0);
	            }
	        }

	        input {

	            &:checked + i.theme-toggle {

	                &::after {
	                    width: 32px;
	                    transform: translate3d(3px,2px,0);
	                }
	            }
	        }
	    }

	    .input-cont {
	        display: flex;
	        justify-content: space-between;
	        align-items: center;
            //margin: 10px 25px;

			.toggle-label {
				color: #9fabc3;
				display: flex;
				justify-content: center;
				align-items: center;
			}

	        @media screen and (min-width: 320px) and (max-width: 766px) {
	            /* flex-direction: column-reverse;
	            align-items: flex-end; */
				margin: 0;
	        }

			i.icon-theme {
				font-size: 18px;
			}
	    }
	}

	&.version-switcher {
		.theme-switch {
			display: flex;
			justify-content: center;
			align-items: center;

			.input-cont {
				margin: 0;

				.toggle-wrapper {
					cursor: pointer;
					display: flex;
					justify-content: center;
					align-items: center;

					i.theme-toggle {
						margin: 0 10px;
					}
				}

				.toggle-label {
					.beta {
						margin-right: 8px;
						padding: 1px 4px;
						font-weight: 800;
						font-size: 12px;
						background-color: #F1A92E !important;
						color: #27323e;
						border-radius: 3px;
						text-transform: uppercase;
					}
				}
			}
		}

		.toggle-tooltip {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: -23px;
    		margin-top: -17px;

			.icon-question {
				margin: auto 10px;
			}
		}
		
	}

	@media screen and (min-width: 320px) and (max-width: 1023px) {
	 	&.theme-desktop {
	 		display: none;
		}
	}

	@media screen and (min-width: 1024px) {
		&.theme-mobile {
			display: none;
		}
	}
`;

export const NotificationBadge = styled.span`
	width: 20px;
	height: 20px;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
    overflow: hidden;
    background: red;
    position: absolute;
    top: -1px;
    right: -1px;
    font-size: 10px;
    border: 1px solid #3a4b61;
`;

export const NotificationWrapper = styled.div`

	.dropdown {

		#top-notification {
			border: none;
			background-color: transparent;
			display: flex;
			justify-content: center;
			align-items: center;

			svg {
				font-size: 22px;
			}

			&::after {
				display: none;
			}

			&:focus,
			&:hover,
			&:active {
				outline: none;
				box-shadow: none;
			}

			.dropdown-menu {

			}
		}
	}
`;

export const AvatarBlock = styled.div`
	width: auto;
	padding-bottom: 0;
	display: flex !important;
	justify-content: flex-end;
	align-items: center;

	.account-profile {
		display: flex;
		justify-content: center;
		align-items: center;

		.user-avatar {
		  	display: inline-block;
		    width: 32px;
		    height: 32px;
		    border-radius: 15px;
		    background-color: #283544;
		    /* box-shadow: 0 3px 1px 1px rgba(0,0,0,.3); */
		    overflow: hidden;

	    	img {
	    		width: 100%;
	    	}
	  	}
	}

  	.user-name {
		white-space: nowrap;
		margin-left: -5px;
		font-size: 15px;
		font-weight: 500;
		margin-left: 15px;

		@media screen and (min-width: 320px) and (max-width: 766px) {
			display: none;
		}
  	}
`

export const HeaderWrapper = styled.header`
	height: 56px;
  	display: flex;
  	align-items: center;
  	justify-content: flex-end;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	background-color: #0b1219;
	border-bottom: 2px solid #0a0f16;	
    box-shadow: 0 1px 8px rgb(0 0 0 / 30%);

	.notif-profile {
		display: flex;
	    justify-content: center;
	    align-items: center;
	}

  	.main-navbar {
	  	position: relative;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	    -ms-flex-align: center;
	    align-items: center;
	    -ms-flex-pack: justify;
	    justify-content: space-between;
	    padding: 0 15px;
		width: 100%;

		@media screen and (min-width: 320px) and (max-width: 766px) {
			padding: 0 15px;
		}

		ul {
			align-items: center;
			list-style-type: none !important;

			@media screen and (min-width: 320px) and (max-width: 1024px) {
				&.header-logo-wrapper {
					position: absolute;
					left: 35%;
					right: 35%;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}

    	& > ul > li:last-child {

	    	& .toggle-btn {
	    		background: 0 0;
	  			border: none;
	  			color: #96a1b7;
	    	}
    	}

	    & > ul > .nav-item-divider {
	    	display: flex;
		    border-right: 1px solid #4e6073;
		    height: 18px;
		    align-self: center;
		    margin: 0 15px 0 10px;
	    }

		.btn-sidebar {
			&:hover {
			    background: transparent;
			}

			width: 30px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			@media screen and (min-width: 767px) and (max-width: 1199px) {
				width: 350px;
				align-items: center;
				justify-content: start;

				svg {
					width: 30px;
					height: 30px;
					padding: 5px;
				}

				.version-switcher {
					margin-left: 15px;
					.input-cont {
						/* margin: 0 10px 0 0; */
					}
				}
			}
			
		}
	}

  	.nav-link {
  		color: #ced0dd !important;
  	}

	.switch-label {
		display: flex;
		
		.toggle-label {
			.beta {
				margin-right: 8px;
				padding: 1px 4px;
				font-weight: 800;
				font-size: 12px;
				background-color: #F1A92E !important;
				color: #27323e;
				border-radius: 3px;
				text-transform: uppercase;
			}
		}

		.toggle-tooltip {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: -23px;
    		margin-top: -17px;

			.icon-question {
				margin: auto 10px;
			}
		}
	}

	.logout-wrapper {
		display: flex;
		align-items: center;

		.logout-text {
			font-size: 16px;
			font-weight: 500;
		}

		i {
			line-height: 0 !important;
		}
	}

	button {
		background: 0 0;
		border-color: transparent;
		color: #96a1b7;
		padding: 0;

		&:hover {
			border-radius: 6px !important;
			border-color: #2E3B4C !important;
			box-shadow: unset !important;
			border-color: transparent !important;
			color: #F2F2F2;
		}
		&:focus {
			background-color: unset !important;
			border-color: #2E3B4C !important;
			border-color: transparent !important;
			box-shadow: unset !important;
		}
		&:active {
			background-color: unset !important;
			border-color: #2E3B4C !important;
			border-color: transparent !important;
			box-shadow: unset !important;
		}
	}

	@media screen and (min-width: 320px) and (max-width: 1023px) {
		left: 0;
		height: 48.86px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		transition: all 300ms ease-in-out;

		.main-navbar {
			ul {
				flex-direction: row;
				align-items: center;

				li {
					&:last-child {
						/* padding-top: 10px; */
					}
				}
			}

			${AvatarBlock} {
				font-size: 10px;
				display: flex;
				justify-content: flex-end;
				align-items: center;

				.user-name {
					font-size: 14px;
					margin-left: 8px;
					width: 70px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}

				.user-avatar {
					width: 25px;
					height: 25px;
					border-radius: 100%;
				}
			}

			.logout-wrapper {
				font-size: 14px;

				.logout-text {
					font-size: 14px;
				}
			}
		}
	}

	@media screen and (min-width: 1024px) and (max-width: 1199px) {
		left: 0px;
		height: 47px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		transition: all 300ms ease-in-out;

		.main-navbar {

			ul {

				li {
					&:last-child {
						/* padding-top: 10px; */
					}
				}
			}

			${AvatarBlock} {
				font-size: 10px;
				display: flex;
				justify-content: flex-end;
				align-items: center;

				.user-name {
					font-size: 14px;
					margin-left: 8px;
					width: 70px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}

				.user-avatar {
					width: 25px;
					height: 25px;
					border-radius: 100%;
				}
			}

			.logout-wrapper {
				font-size: 14px;

				.logout-text {
					font-size: 14px;
				}
			}
		}
	}

	@media screen and (min-width: 1200px) and (max-width: 1440px) {
		left: 0;
		height: 51px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		transition: all 300ms ease-in-out;

		#sidebar-btn {
			padding: 2px 8px;
		}

		.main-navbar {
			ul {
				li {
					&:last-child {
						/* padding-top: 8px; */
					}
				}
			}

			${AvatarBlock} {
				font-size: 14px;
				display: flex;
				justify-content: flex-end;
				align-items: center;

				.user-name {
					font-size: 14px;
					margin-left: 10px;
				}

				.user-avatar {
					width: 28px;
					height: 28px;
				}
			}

			.logout-wrapper {
				font-size: 14px;

				.logout-text {
					font-size: 14px;
				}
			}
		}
	}

	@media screen and (min-width: 1441px) and (max-width: 1749px) {
		left: 0;
		height: 55px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		transition: all 300ms ease-in-out;

		.main-navbar {

			ul {
				li {
					&:last-child {
						/* padding-top: 15px; */
					}
				}
			}

			${AvatarBlock} {
				font-size: 14px;
				display: flex;
				justify-content: flex-end;
				align-items: center;

				.user-name {
					font-size: 14px;
					margin-left: 8px;
				}

				.user-avatar {
					// width: 20px;
					// height: 20px;
				}


			}

			.logout-wrapper {
				// font-size: 11px;

				.logout-text {
					font-size: 14px;
				}
			}
		}
	}

	@media screen and (min-width: 1750px) and (max-width: 3000px) {
		left: 0;
		height: 59px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		transition: all 300ms ease-in-out;

		.main-navbar {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 59px;

			ul {
				/* margin-top: -3px; */

				li {
					&:last-child {
						/* padding-top: 15px; */
					}
				}
			}

			${AvatarBlock} {
				font-size: 14px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				padding: .5rem !important;

				.user-name {
					font-size: 15px;
					margin-left: 8px;
				}
			}

			.logout-wrapper {
				padding: 0;

				.logout-text {
					font-size: 15px;
				}
			}
		}
	}
`;

export const HeaderLogo = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	a {
		display: flex;
	   	justify-content: center;
	   	align-items: center;
		font-size: 18px;
	    color: #fff !important;
	    margin-right: 0;

		img {
			height: 32px;
			width: 32px;
			margin-right: 10px;
			border-radius: 50%;
		}
	}

	.version-switch {
		margin-left: 15px;
		
		label {
			margin: 0;
		}
	}

	@media screen and (min-width: 320px) and (max-width: 1199px) {
		a {
			img {
				margin-right: 0;
			}

			.app-name {
				display: none;
			}
		}
	}

	@media screen and (min-width: 1200px) and (max-width: 1440px) {
		a {
			img {
				height: 28px;
				width: 28px;
			}

			.app-name {
	    		font-size: 16px;
			}
		}
	}
`;
