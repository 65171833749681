import { useEffect, useState } from 'react'
import moment from 'moment'
import { SafeAnchor } from 'react-bootstrap'
import { DynamicCheckout } from '../../../../components'
import { ThreeSixFiveDaysSectionStyle } from '../styles'

export const SixMonths = (props) => {    
    let [remainingDays, setRemainingDays] = useState('')
    let [remainingHours, setRemainingHours] = useState('')
    let [remainingMinutes, setRemainingMinutes] = useState('')
    let [remainingSeconds, setRemainingSeconds] = useState('')

    useEffect(() => {
        let expiry = props.validUntil

        const second = 1000,
            minute = second * 60,
            hour = minute * 60,
            day = hour * 24;

        let today = new Date(),
            dd = String(today.getDate()).padStart(2, "0"),
            mm = String(today.getMonth() + 1).padStart(2, "0"),
            yyyy = today.getFullYear(),
            nextYear = yyyy + 1,
            dayMonth = moment(expiry).format('MM/DD/')
    
        today = mm + "/" + dd + "/" + yyyy
        if (today > expiry) {
            expiry = dayMonth + nextYear
        }

        const countDown = new Date(expiry).getTime()
        const x = setInterval(function() {    
            const now = new Date().getTime(),
                distance = countDown - now

            setRemainingDays(Math.floor(distance / (day)))
            setRemainingHours(Math.floor((distance % (day)) / (hour)))
            setRemainingMinutes(Math.floor((distance % (hour)) / (minute)))
            setRemainingSeconds(Math.floor((distance % (minute)) / second))

            if (distance < 0) {
                clearInterval(x)
            }
            //seconds
        }, 1000)

        return () => clearInterval(x)
    }, [])

    return (
        <ThreeSixFiveDaysSectionStyle>
            <div className='container'>
                <h2 className='section-title'>
                    <small>6 Months of ProfitFarmers Pro:</small><br />
                    Was <span className='crashed-out-price'>$2,800</span> Now Just <span className='new-price'>$899</span>
                </h2>

                <h4 className='lc-tag'>THIS IS YOUR LAST CHANCE OFFER - CONSIDER IT CAREFULLY</h4>

                <p>Be ready for <strong>furious crypto pumps</strong> any time of the year for <strong className='daily-rate'>just $5 per day.</strong></p>

                <p className='countr-txt'>This price ends in <strong>[<span id='365-days'>{remainingDays}</span>D <span id='365-hours'>{remainingHours}</span>h <span id='365-minutes'>{remainingMinutes}</span>m <span id='365-seconds'>{remainingSeconds}</span>s]</strong></p>

                <ul className='three-easy-steps'>
                    <li className='easy-step'>
                        <span className='icon'>
                            <img src='/images/layout/pay-now.png' alt='Pay Now and Secure Your Offer' />
                        </span>
                        <span className='desc'>Pay Now and<br />Secure Your Offer</span>
                    </li>
                    <li className='easy-step'>
                        <span className='icon'>
                            <img src='/images/layout/bonus-items.png' alt='Membership Extended and Bonus Items Delivered' />
                        </span>
                        <span className='desc'>Membership<br />Extended and Bonus <br />Items Delivered</span>
                    </li>
                    <li className='easy-step'>
                        <span className='icon'>
                            <img src='/images/layout/crushing-crypto.png' alt='Start Crushing Crypto' />
                        </span>
                        <span className='desc'>Start Crushing<br />Crypto</span>
                    </li>
                </ul>

                <div className='checkout-btn'>
                    <DynamicCheckout 
                        text="Secure My LAST CHANCE Offer"
                        position="centered" 
                        userInfo={props.userInfo} 
                        offer={props.subscriptionPlan} />
                </div>

                <p className='little-note'>Note: Crypto payments are welcome, please <SafeAnchor href="https://www.profitfarmers.com/crypto-payment/" rel="noreferrer">contact our support team directly to organize this</SafeAnchor></p>
            </div>
        </ThreeSixFiveDaysSectionStyle>
    )
}