import { useEffect, useState } from 'react'
import moment from 'moment'
import { SafeAnchor } from 'react-bootstrap'
import { DynamicCheckout } from '../../../components'
import { isNotNull } from '../../../utils'
import { GorgeOnCryptoSectionStyle } from './styles'

export const GorgeOnCryptoSection = (props) => {
    let [remainingDays, setRemainingDays] = useState('')
    let [remainingHours, setRemainingHours] = useState('')
    let [remainingMinutes, setRemainingMinutes] = useState('')
    let [remainingSeconds, setRemainingSeconds] = useState('')

    let [isEarlyBirdOffer, setIsEarlyBirdOffer] = useState(false)
    let [isFinalYearlyOffer, setIsFinalYearlyOffer] = useState(false)

    let parsedDate = Date.parse(props.validUntil)
    let expiry = new Date(parsedDate)
        expiry = moment(expiry).format('MM/DD/yyyy h:mm:ss')

    const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

    let today = new Date(),
        dd = String(today.getDate()).padStart(2, "0"),
        mm = String(today.getMonth() + 1).padStart(2, "0"),
        yyyy = today.getFullYear(),
        nextYear = yyyy + 1,
        dayMonth = moment(expiry).format('MM/DD/')

    today = mm + "/" + dd + "/" + yyyy
    if (today > expiry) {
        expiry = dayMonth + nextYear
    }

    const countDown = new Date(expiry).getTime(),
        x = setInterval(function() {    
            const now = new Date().getTime(),
                distance = countDown - now

            setRemainingDays(Math.floor(distance / (day)))
            setRemainingHours(Math.floor((distance % (day)) / (hour)))
            setRemainingMinutes(Math.floor((distance % (hour)) / (minute)))
            setRemainingSeconds(Math.floor((distance % (minute)) / second))

            if (isNotNull(remainingDays)) {
                if (parseInt(remainingDays) >= 14) {
                    if (!isEarlyBirdOffer)
                        setIsEarlyBirdOffer(true)

                    if (isFinalYearlyOffer)
                        setIsFinalYearlyOffer(false)
                } else {                        
                    if (isEarlyBirdOffer)
                        setIsEarlyBirdOffer(false)
                    
                    if (!isFinalYearlyOffer)  
                        setIsFinalYearlyOffer(true)
                }
            }
            if (distance < 0) {
                clearInterval(x)
            }
            //seconds
        }, 1000)

    return (
        <GorgeOnCryptoSectionStyle>
            <div className='container'>
                <h2 className='section-title'>
                    <span>Gorge On Crypto Profits All Year Round - Only <span className='crashed-out-price'>$4,300</span> Now Just <span className='new-price'>{isEarlyBirdOffer && <>$1,499</>}{isFinalYearlyOffer && <>$1,799</>}</span></span>
                </h2>
                <p>You can save more than {isEarlyBirdOffer && <>$2,800</>}{isFinalYearlyOffer && <>$2,500</>} and receive all of the bonus items listed above but only if you act now!</p>

                { isEarlyBirdOffer && <h3>This price will increase +20% in</h3>}
                { isFinalYearlyOffer && <h3>This price ends in</h3>}

                <div className='offer-validity-timer'>
                    <ul>
                        <li>
                            <span id='g-days' className='counter'>{parseInt(remainingDays) < 10 ? '0': ''}{remainingDays}</span>
                            <small>DAYS</small>
                        </li>
                        <li>
                            <span id='g-hours' className='counter'>{parseInt(remainingHours) < 10 ? '0': ''}{remainingHours}</span>
                            <small>HOURS</small>
                        </li>
                        <li>
                            <span id='g-minutes' className='counter'>{parseInt(remainingMinutes) < 10 ? '0': ''}{remainingMinutes}</span>
                            <small>MINUTES</small>
                        </li>
                        <li>
                            <span id='g-seconds' className='counter'>{parseInt(remainingSeconds) < 10 ? '0': ''}{remainingSeconds}</span>
                            <small>SECONDS</small>
                        </li>
                    </ul>
                </div>

                <div className='checkout-btn'>
                    { isEarlyBirdOffer && 
                        <DynamicCheckout 
                            text="Upgrade Now"
                            position="centered" 
                            userInfo={props.userInfo} 
                            offer={props.subscriptionPlan} />
                    }

                    { isFinalYearlyOffer && 
                        <DynamicCheckout 
                            text="Upgrade Now"
                            position="centered" 
                            userInfo={props.userInfo} 
                            offer={props.finalYearlyOffer} />
                    }
                </div>

                <p className='little-note'>Note: Crypto payments are welcome, please <SafeAnchor href="https://www.profitfarmers.com/crypto-payment/" rel="noreferrer">contact our support team directly to organize this</SafeAnchor></p>
            </div>
        </GorgeOnCryptoSectionStyle>
    )
}