import { userActionTypes } from '../CONSTANTS'

export function puid(state={}, action) {
    switch(action.type) {
        case userActionTypes.GET_ID_REQUEST:
            return {
                ...state,
                loading: true
            }
        case userActionTypes.GET_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.response
            }
        case userActionTypes.GET_ID_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function userProfile(state = {}, action) {
    switch (action.type) {
        case userActionTypes.GET_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }
        case userActionTypes.GET_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                profile: action.res
            }
        case userActionTypes.GET_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.msg
            }
        default:
            return state
    }
}

export function profileCompletion(state={}, action) {
    switch(action.type) {
        case userActionTypes.GET_PROFILE_COMPLETION_STATUS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case userActionTypes.GET_PROFILE_COMPLETION_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                progress: action.response.progress
            }
        case userActionTypes.GET_PROFILE_COMPLETION_STATUS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function saveProfile(state = {}, action) {
    switch (action.type) {
        case userActionTypes.SAVE_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }
        case userActionTypes.SAVE_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.user,
                response: action.response
            }
        case userActionTypes.SAVE_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function user(state = { loading: false }, action) {

    switch (action.type) {
        case userActionTypes.REGISTER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case userActionTypes.REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                userData: action.user
            };
        case userActionTypes.REGISTER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case userActionTypes.RESEND_VERIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case userActionTypes.RESEND_VERIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                responseData: action.user
            };
        case userActionTypes.RESEND_VERIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        // case userActionTypes.CLEAR_USER:
        //     return {
        //         ...state,
        //         loading: false,
        //         error: null
        //     }
        default:
            return state
    }
}

export function userAgreement(state = {}, action) {
    switch (action.type) {
        case userActionTypes.UPDATE_USER_WEEKLY_DISCLAIMER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case userActionTypes.UPDATE_USER_WEEKLY_DISCLAIMER_SUCCESS:
            return {
                ...state,
                loading: false,
                hasAgreedToWeeklyDisclaimer: action.hasAgreedToWeeklyTerms
            }
        case userActionTypes.UPDATE_USER_WEEKLY_DISCLAIMER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}