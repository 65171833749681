import _ from 'lodash'
import { Skeleton } from "@material-ui/lab"
import { RelativeStrengthIndexItem } from '../components'
import { PreloaderWrapper, Preloader, PreloaderLogo, PreloaderItem, ErrorMessage } from '../styled_components'
import { isNotNull } from '../../../utils'

export const RSIView = (props) => {
    const { coinPair,
        s1, s2, s3, s4, s5, s6, s7, s8,
        price, volume, marketCap,
        peakGains, liveFibo, d3move, selectedItem, rsiFilter, type } = props
    const { loading, items } = props.relativeStrengthAllItems
    let selectedValue = null
    let isSelected = false
    let rsiItems = null

    const onGetSelectedFilter = (rsiFilterValue, rsiItem) => {
        return `${rsiFilterValue}.${rsiItem}`
    }

    const onSortHandler = (lists, selectedValue, sort) => {
        let selectedSort = [selectedValue]
        switch(selectedValue) {
            case "n":
                selectedSort = [selectedValue]
                break
            default:
                selectedSort = [selectedValue, 'n']
                break
        }
        return _.orderBy(lists, selectedSort, [sort, sort])
    }

    const renderRsiLists = (selectedItem, sort, rsiLists, props) => {
        const { targetFilter, rsiFilter, isWatched, currentSet } = props
        let { selectedValue, sortValue } = ""
        let watchedLists = []

        if (isNotNull(rsiLists)) {
            rsiLists.forEach(e => {
                if (e.isWatched)
                    watchedLists.push(e)
            })
        }

        if (typeof selectedItem !== "undefined" && typeof sort !== "undefined") {
            if (selectedItem == null) {
                selectedValue = 'n'
                sortValue = 'asc'
            } else {
                selectedValue = selectedItem
                sortValue = sort ? 'desc' : 'asc'
            }
        }

        if (isNotNull(rsiLists)) {
            let allLists = rsiLists.filter(e => {
                return !isWatched ? e : e.isWatched === true
            })

            let filtered = allLists.filter(function (data) {
                return targetFilter === "All" ? rsiLists : data.n.endsWith(targetFilter)
            })

            if (targetFilter === "ALL") {
                if (allLists.length > 0) {
                    let list = selectedValue === "cp"
                        ? _.orderBy(allLists, item => parseFloat(item.cp), [sortValue])
                        : onSortHandler(allLists, selectedItem, sortValue )

                    let paginated = _.filter(list, (val, key) => key <= currentSet)

                    return paginated.map((value, key) => {
                        return (
                            <RelativeStrengthIndexItem
                                rsiFilter={rsiFilter}
                                key={key}
                                watchListed={value.isWatched}
                                handleChange={(value, flag) => props.onWatch(value, flag, type)}
                                onExpand={(e) => props.onExpand(e)}
                                onDisplay={(e) => props.onDisplay(e)}
                                {...value}
                            />
                        )
                    })
                }
                else
                    return renderErrorMessag("No Coin Pair Watched")
            } else {
                if (filtered.length > 0) {
                    let list = selectedValue === "cp"
                        ? _.orderBy(filtered, item => parseFloat(item.cp), [sortValue])
                        : onSortHandler(filtered, selectedItem, sortValue )

                    let paginated = _.filter(list, (val, key) => key <= currentSet)
                    return paginated.map((value, key) => {
                        return (
                            <RelativeStrengthIndexItem
                                rsiFilter={rsiFilter}
                                key={key}
                                watchListed={value.isWatched}
                                handleChange={(value, flag) => props.onWatch(value, flag, type)}
                                onExpand={(e) => props.onExpand(e)}
                                onDisplay={(e) => props.onDisplay(e)}
                                {...value}
                            />
                        )
                    })
                } else
                    return renderErrorMessag("No Coin Pair Watched")
            }

        }
    }

    const renderErrorMessag = (message) => {
        return (
            <ErrorMessage className="scanner-error">{message}</ErrorMessage>
        )
    }

    const renderPreloader = () => {
        const preloaderData = [0, 1, 2, 3, 4]
        return _.map(preloaderData, (val, key) => {
            return (
                <PreloaderWrapper key={key} className="preloader-wrapper">
                    <Preloader>
                        <PreloaderLogo>
                            <Skeleton variant="rect" style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: 'linear-gradient(15deg,#334156,#385065)',
                                borderRadius: '100%',
                            }} className="skeleton" />
                        </PreloaderLogo>
                        <PreloaderItem>
                            <Skeleton variant="rect" style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: 'linear-gradient(15deg,#334156,#385065)',
                                border: '1px solid #27313f',
                                borderRadius: '4px',
                                boxShadow: '0px 3px 3px 2px rgba(0,0,0,0.08)'
                            }} className="skeleton" />
                        </PreloaderItem>
                    </Preloader>
                </PreloaderWrapper>
            )
        })
    }

    switch (selectedItem) {
        case "coinPair":
            selectedValue = 'n'
            isSelected = coinPair
            break
        case "s1":
            selectedValue = onGetSelectedFilter(rsiFilter, "rsi5m")
            isSelected = s1
            break
        case "s2":
            selectedValue = onGetSelectedFilter(rsiFilter, "rsi15m")
            isSelected = s2
            break
        case "s3":
            selectedValue = onGetSelectedFilter(rsiFilter, "rsi30m")
            isSelected = s3
            break
        case "s4":
            selectedValue = onGetSelectedFilter(rsiFilter, "rsi1h")
            isSelected = s4
            break
        case "s5":
            selectedValue = onGetSelectedFilter(rsiFilter, "rsi4h")
            isSelected = s5
            break
        case "s6":
            selectedValue = onGetSelectedFilter(rsiFilter, "rsi6h")
            isSelected = s6
            break
        case "s7":
            selectedValue = onGetSelectedFilter(rsiFilter, "rsi12h")
            isSelected = s7
            break
        case "s8":
            selectedValue = onGetSelectedFilter(rsiFilter, "rsi1d")
            isSelected = s8
            break
        case "price":
            selectedValue = 'cp'
            isSelected = price
            break
        case "volume":
            selectedValue = 'v'
            isSelected = volume
            break
        case "marketCap":
            selectedValue = 'mc'
            isSelected = marketCap
            break
        case "peakGains":
            selectedValue = 'n'
            isSelected = peakGains
            break
        case "liveFibo":
            selectedValue = 'fm'
            isSelected = liveFibo
            break
        case "d3move":
            selectedValue = 'pm'
            isSelected = d3move
            break
        default:
            selectedValue = 'n'
            isSelected = false
            break
    }

    if (!loading) {
        if (isNotNull(items)) {
            if (items) {
                rsiItems = renderRsiLists(selectedValue, isSelected, items, props)
            } else
                rsiItems = renderErrorMessag("Internal Server Error")
        } else
            rsiItems = renderErrorMessag("Internal Server Error")
    }

    return (
        <>
            {loading &&
                <>
                    {renderPreloader()}
                </>
            }
            {!loading &&
                <>
                    {rsiItems}
                </>
            }
        </>
    )
}