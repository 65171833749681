import styled from 'styled-components';
import { device } from '../../../../../styles/CONSTANTS';

export const ErrorText = styled.div`
    color: red;
    font-size: 12px;
    display: none;

    &.invalid {
        display: block;
    }
`

export const LeadFormText = styled.p`
	margin-bottom: 1.5rem;
	font-weight: 500;

    &.italized {
        text-align: center;
        font-style: italic;
        font-size: 13px;
        margin-bottom: 60px;
    }
`

export const LeadFormTitle = styled.h4`
    margin-bottom: 1.5rem;
`

export const LeadFormWrapper = styled.div`
    #leadSecurity {
        .form-group {
            &.row {
                width: 680px;
                margin: 0 auto 15px;

                @media screen and (min-width: 320px) and (max-width: 1024px) {
                    width: 100%;
                }
            }

            display: flex;
            align-items: baseline;
            justify-content: center;
            margin-bottom: 1.5rem;

            &:last-child {
                margin-bottom: 0;
            }

            .form-label {
                font-size: 14px;
				color: #000;
				font-weight: 500;
            }

            .input-group {
                border-width: 1px;
				border-style: solid;
				border-color: rgb(226, 224, 223);
				border-radius: 6px;
				background-color: rgb(250, 250, 250) !important;
                height: 45px;
                display: flex;
                align-items: center;

                input {
					color: #000;
					font-weight: 600;
                    font-size: 14px;
                    background-color: transparent;
                    border: none;

                    &:hover,
                    &:focus,
                    &:visited {
                        box-shadow: none;
                        outline: none;
                    }
                }

                .input-group-prepend {
                    .input-group-text {
                        background-color: transparent;
                        border: none;
						cursor: pointer;
						color: #000;
                        &:hover {
                            color: #000;
                        }
                    }
				}

				.password-validation {
					max-width: 100%;
					font-size: 13px;

					.arrow {
						left: 5px !important;
					}

					.validation-title {
						font-size: 13px;
					}
	
					.password-validation-lists {
						list-style: none;
						padding: 0;
						margin-bottom: 0;
						
						.validation-icons {
							text-align: center;
	
							i {
								width: 30px;
	
								&.error {
									color: red;
								}
		
								&.success {
									color: #15bd6f;
								}
							}
						}
	
						.validation-text {
	
						}
					}
				}
			}
            

            &.lead-submit {
                display: flex;
                justify-content: center;
                margin-top: 45px;

                .save-btn {
                    background-color: rgb(21, 189, 111);
					color: rgb(255, 255, 255);
					font-weight: 600;
					font-size: 18px;
					display: block;
					border-width: 1px;
					border-style: solid;
					border-color: rgb(97, 183, 85);
					border-image: initial;
					margin: auto;
					transition: transform 0.2s ease 0s;
					min-width: 200px;
					padding: 8px 80px;

					@media (min-width: 1024px) {
						&:hover {
							color: rgb(255, 255, 255);
							transform: scale(1.3);
							backface-visibility: hidden;
							overflow: auto;
						}
					}

                    &:disabled {
                        transform: scale(1);
						backface-visibility: hidden;
						overflow: auto;
                    }
                }
            }
        }
    }
`

export const LeadNote = styled.small``

export const LeadMessage = styled.p`
	margin-bottom: 1.5rem;
	font-weight: 500;
`

export const LeadTitle = styled.h2`
	font-size: 36px;
	text-transform: uppercase;
	margin-bottom: 40px;
	text-align: center;
	color: rgb(63, 159, 50);
	font-weight: 700;
`

export const LeadTitleWrapper = styled.div``

export const LeadContainer = styled.div``

export const LeadWrapper = styled.div`
	color: #212529;
	width: 100%;
`

export const LogoWrapper = styled.header`
	&.onboarding {
			display: none;
			@media ${device.tablet} {
			display: block;
			}
	}
`

export const LogoContainer = styled.div`
  	margin: auto;
	padding: 15px 20px;
	  
	@media ${device.mobileXL} {
    	padding: 15px 45px;
    	text-align: center;
  	}

  	img {
		@media ${device.mobileXL} {
			width: 380px;
		}
		@media ${device.tablet} {
				width: 100%;
		}
	}

	@media ${device.tablet} {
    	padding: 0;
    	margin: 25px auto;
  	}
`

export const Logo = styled.img``

export const LeadMainContainer = styled.div``

export const LeadMainWrapper = styled.div`
	font-family: Montserrat, sans-serif;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 9999;
	overflow: auto;
	height: 100%;

    .lead-changePass-row {
        justify-content: center;
		align-items: center;
		margin-bottom: 25px;

        .card {
			width: 100%;
            border: none;
            box-shadow: rgba(202, 202, 202, 0.71) 0px 0px 20px;

            .card-body {
				width: 100%;
				padding: 2.5rem;
            }
        }
	}

	@media screen and (min-width: 1200px) and (max-width: 1660px) and (max-height: 899px) {
		${LeadMainContainer} {

			.lead-changePass-row {
				.card {
					.card-body {
						padding: 15px 2.5rem 2rem;

						${LeadWrapper} {
							// overflow-y: auto;
							font-size: 14px;
							display: flex;
							align-items: center;
							flex-direction: column;
				
							${LeadContainer} {
								width: 100%;

								${LeadTitleWrapper} {
									${LeadTitle} {
										font-size: 24px;
										margin: 25px 0;
									}
				
									${LeadMessage} {
										font-size: 14px;
										margin-bottom: 1rem;
									}
								}
				
								${LeadFormWrapper} {

									.form-group.row {
										width: 100%;
									}
				
									${LeadFormText} {
										&.italized {
											margin-bottom: 40px;
										}
									}
				
									${LeadFormTitle} {
										margin-bottom: 1rem;
										font-size: 18px;
									}
				
									.form-group {
										&.lead-submit {
											margin-top: 20px !important;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	@media screen and (min-width: 1024px) and (max-width: 1552px) and (max-height: 799px) {
		${LeadMainContainer} {
			height: 100% !important;
		}
	}

	@media screen and (min-height: 600px) and (max-height: 1024px) and (orientation: portrait){
		${LeadMainContainer} {
			height: 100% !important;
		}
	}
	
	@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
		${LeadMainContainer} {
			height: unset !important;
		}
	}

    @media screen and (min-width: 320px) and (max-width: 1024px) {
		${LeadMainContainer} {
			height: 100%;

			.lead-changePass-row {
				.card {
					.card-body {
						padding: 15px;

						${LeadWrapper} {
							overflow-y: auto;
							font-size: 14px;
							display: flex;
							align-items: center;
							flex-direction: column;
				
							${LeadContainer} {
								${LeadTitleWrapper} {
									${LeadTitle} {
										font-size: 24px;
										margin: 25px 0;
									}
				
									${LeadMessage} {
										font-size: 14px;
										margin-bottom: 1rem;
									}
								}
				
								${LeadFormWrapper} {

									.form-group.row {
										width: 100%;
									}
				
									${LeadFormText} {
										&.italized {
											margin-bottom: 40px;
										}
									}
				
									${LeadFormTitle} {
										margin-bottom: 1rem;
										font-size: 18px;
									}
				
									.form-group {
										&.lead-submit {
											margin-top: 20px !important;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`