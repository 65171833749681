import { endpoints, baseUrl } from './CONSTANTS'
import { authServices } from './authService'

const { v3 } = endpoints

function getOnboardingState() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    try {
        return fetch(`${baseUrl}${v3.user.onboarding.getState}`, requestOpts)
            .then(handleResponse)
            .then(response => {
                return Promise.resolve(response)
            })
            .catch(error => Promise.reject(error))
    } catch(e) {
        return Promise.reject(e)
    }
}

function getBasicInfo() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    try {
        return fetch(`${baseUrl}${v3.user.onboarding.getBasicInfo}`, requestOpts)
            .then(handleResponse)
            .then(response => {
                return Promise.resolve(response)
            })
            .catch(error => Promise.reject(error))
    } catch(e) {
        return Promise.reject(e)
    }
}

function getTradingExpDetails() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    try {
        return fetch(`${baseUrl}${v3.user.onboarding.getTradingExpDetails}`, requestOpts)
            .then(handleResponse)
            .then(response => {
                return Promise.resolve(response)
            })
            .catch(error => Promise.reject(error))
    } catch(e) {
        return Promise.reject(e)
    }
}

function getFinancialDetails() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    try {
        return fetch(`${baseUrl}${v3.user.onboarding.getFinancialDetails}`, requestOpts)
            .then(handleResponse)
            .then(response => {
                return Promise.resolve(response)
            })
            .catch(error => Promise.reject(error))
    } catch(e) {
        return Promise.reject(e)
    }
}

function getTradingBalances() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    try {
        return fetch(`${baseUrl}${v3.user.onboarding.getTradingBalances}`, requestOpts)
            .then(handleResponse)
            .then(response => {
                return Promise.resolve(response)
            })
            .catch(error => Promise.reject(error))
    } catch(e) {
        return Promise.reject(e)
    }
}

function saveBasicInfo(data) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(data)
    }

    return fetch(`${baseUrl}${v3.user.onboarding.saveBasicInfo}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

function saveTradingExpDetails(data) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(data)
    }

    return fetch(`${baseUrl}${v3.user.onboarding.saveTradingExpDetails}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

function saveFinancialDetails(data) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(data)
    }

    return fetch(`${baseUrl}${v3.user.onboarding.saveFinancialDetails}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

function saveExchangeKeys(data) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(data)
    }

    return fetch(`${baseUrl}${v3.user.onboarding.saveExchangeKeys}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

function configureExchangeIntegration() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    try {
        return fetch(`${baseUrl}${v3.user.onboarding.configureExchangeKeys}`, requestOpts)
            .then(handleResponse)
            .then(response => {
                return Promise.resolve(response)
            })
            .catch(error => Promise.reject(error))
    } catch(e) {
        return Promise.reject(e)
    }
}

function configureTradingBalance() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    try {
        return fetch(`${baseUrl}${v3.user.onboarding.configureTradingBalance}`, requestOpts)
            .then(handleResponse)
            .then(response => {
                return Promise.resolve(response)
            })
            .catch(error => Promise.reject(error))
    } catch(e) {
        return Promise.reject(e)
    }
}

function setTradingBalance(data) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(data)
    }

    return fetch(`${baseUrl}${v3.user.onboarding.setTradingBalance}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

function setToComplete() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    try {
        return fetch(`${baseUrl}${v3.user.onboarding.setToComplete}`, requestOpts)
            .then(handleResponse)
            .catch(error => Promise.reject(error))
    } catch(e) {
        return Promise.reject(e)
    }
}

function skipOnboarding() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    try {
        return fetch(`${baseUrl}${v3.user.onboarding.skipOnboarding}`, requestOpts)
            .then(handleResponse)
            .then(response => {
                return Promise.resolve(response)
            })
            .catch(error => Promise.reject(error))
    } catch(e) {
        return Promise.reject(e)
    }
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        if (res.status === 401)
            authServices.logout()
        else
            return data
    })
}

export const onboardingServices = {
    getOnboardingState,
    getBasicInfo,
    getTradingExpDetails,
    getFinancialDetails,
    getTradingBalances,
    saveBasicInfo,
    saveTradingExpDetails,
    saveFinancialDetails,
    saveExchangeKeys,
    configureExchangeIntegration,
    configureTradingBalance,
    setTradingBalance,
    setToComplete,
    skipOnboarding
}