import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap'
import moment from 'moment'
import { ProStatsSectionStyle } from './styles'
import { mediaDeviceSizes } from '../../../constants/mediaQueries'
import { getSize, isNull, isNotNull } from '../../../utils'

export const ProStatsSection = (props) => {
    let [device, setDevice] = useState(null)
     
    let [remainingDays, setRemainingDays] = useState('')

    let [isEarlyBirdOffer, setIsEarlyBirdOffer] = useState(false)
    let [isFinalYearlyOffer, setIsFinalYearlyOffer] = useState(false)

    useEffect(() => {
        setDevice(getSize(window.innerWidth))
    }, [])

    let parsedDate = Date.parse(props.validUntil)
    let expiry = new Date(parsedDate)
        expiry = moment(expiry).format('MM/DD/yyyy h:mm:ss')

    const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

    let today = new Date(),
        dd = String(today.getDate()).padStart(2, "0"),
        mm = String(today.getMonth() + 1).padStart(2, "0"),
        yyyy = today.getFullYear(),
        nextYear = yyyy + 1,
        dayMonth = moment(expiry).format('MM/DD/')

    today = mm + "/" + dd + "/" + yyyy
    if (today > expiry) {
        expiry = dayMonth + nextYear
    }

    const countDown = new Date(expiry).getTime(),
        x = setInterval(function() {    
            const now = new Date().getTime(),
                distance = countDown - now

            setRemainingDays(Math.floor(distance / (day)))
            setRemainingHours(Math.floor((distance % (day)) / (hour)))
            setRemainingMinutes(Math.floor((distance % (hour)) / (minute)))
            setRemainingSeconds(Math.floor((distance % (minute)) / second))

            if (isNotNull(remainingDays)) {
                if (parseInt(remainingDays) >= 14) {
                    if (!isEarlyBirdOffer)
                        setIsEarlyBirdOffer(true)

                    if (isFinalYearlyOffer)
                        setIsFinalYearlyOffer(false)
                } else {                        
                    if (isEarlyBirdOffer)
                        setIsEarlyBirdOffer(false)
                    
                    if (!isFinalYearlyOffer)  
                        setIsFinalYearlyOffer(true)
                }
            }

            if (distance < 0) {
                clearInterval(x)
            }
            //seconds
        }, 1000)

    return(
        <ProStatsSectionStyle 
            className={`${props.page === 'upgrade' && isNull(props.withinTrial) ? 'up-w-trial': ''} ${props.page === 'upgrade' && isNotNull(props.withinTrial) && props.withinTrial ? 'up-win-trial': ''}`}>
            <div className='container'>
                <div className='text-with-img'>
                    <div className='astronaut-with-logo'>
                        <img src={`/images/${props.page === "trial" ? 'trial-offer': 'layout'}/spotify-premium-tml.png`} alt='ProfitFarmers Logo' />
                    </div>
                    <div className='numbers'>
                        { props.page === "upgrade" && 
                            <h2 className='section-title'>12 Months of Opportunity</h2>
                        }

                        { props.page !== "upgrade" && 
                            <h2 className='section-title'>PRO PROFITFARMERS RESULTS</h2>    
                        }
                        
                        <div className='percentages'>
                            <div className='value'>
                                <span>
                                    <span className='icon'>
                                        <img src='/images/offers/uptrend.svg' alt='' />
                                    </span>
                                    48,500%
                                </span>
                                <span className='label green'>GAINS</span>
                            </div>
                            <div className='value'>
                                <span>
                                    <span className='icon'>
                                        <img src='/images/offers/downtrend.svg' alt='' />
                                    </span>
                                    { props.page === "upgrade" 
                                        ? 
                                            <>
                                                {isEarlyBirdOffer && <>65%</>}
                                                {isFinalYearlyOffer && <>60%</>}
                                            </> 
                                        : <>50%</>
                                    }
                                </span>
                                <span className='label red'>CHEAPER PRICE</span>
                            </div>
                        </div>

                        <div className='counter'>
                            <div className='value'>
                                <span className='icon'>
                                    <img src='/images/offers/signals-produced.svg' alt='' />
                                </span>
                                <span className='text'>
                                    2,574
                                    <small>Signals Produced</small>
                                </span>
                            </div>
                            <div className='value'>
                                <span className='icon'>
                                    <img src='/images/offers/peak-gains.svg' alt='' />
                                </span>
                                <span className='text'>
                                    48,500%
                                    <small>Total Peak Gains</small>
                                </span>
                            </div>
                        </div>

                        { (device === mediaDeviceSizes.xs || device === mediaDeviceSizes.sm) && 
                            <Carousel className='top-signals-carousel'>
                                <Carousel.Item>
                                    <img src='/images/offers/signal-1.png' alt='Top signal' />        
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src='/images/offers/signal-2.png' alt='Top signal' />      
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src='/images/offers/signal-3.png' alt='Top signal' />     
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src='/images/offers/signal-4.png' alt='Top signal' />     
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src='/images/offers/signal-5.png' alt='Top signal' />      
                                </Carousel.Item>
                            </Carousel>
                        }

                        { !(device === mediaDeviceSizes.xs || device === mediaDeviceSizes.sm) && 
                            <div className='top-signals'>
                                <img src='/images/offers/signal-1.png' alt='Top signal' />
                                <img src='/images/offers/signal-2.png' alt='Top signal' />
                                <img src='/images/offers/signal-3.png' alt='Top signal' />
                                <img src='/images/offers/signal-4.png' alt='Top signal' />
                                <img src='/images/offers/signal-5.png' alt='Top signal' />
                            </div>
                        }

                        <p>50+ signals with over 100% gains</p>
                    </div>
                </div>
            </div>
        </ProStatsSectionStyle>
    )
}