import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as faStarFull } from '@fortawesome/free-solid-svg-icons'
import { faStar, faClock } from '@fortawesome/free-regular-svg-icons'
import { SignalStatus } from '../../../../components/SignalContents'
import { Target, SignalProgress } from '.'
import {
    SignalStyle, 
    SignalOverlay, 
    SignalHeader,
    TargetBarsWrapper, 
    SignalInstructions, 
    FollowSignalBtn
} from '../../styled_components'
import { CoinPairLogo } from '../../../../styles'
import {
    isNotNull, 
    Dimension,
    setCoinLogo, 
    setDefaultImage, 
    getFromNow, 
    getFromSym, 
    onSetNewState, 
    ScientificToDecimal, 
    toUsdFormat, 
    toBtcFormat
} from '../../../../utils'

export class OpenSignal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isWatchlisted: false,
        }
        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        window.addEventListener('resize', () => Dimension.updateDimensions(this))

        const { isWatchlisted } = this.props.data
        onSetNewState(this, { isWatchlisted })
    }

    componentDidUpdate(prevProps, prevState) {
        const { isWatchlisted } = this.props.data
        if (prevState.isWatchlisted !== isWatchlisted) {
            onSetNewState(this, { isWatchlisted })
        } 
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)
        window.removeEventListener('resize', () => Dimension.updateDimensions(this))
        this.mounted = false
    }

    onFollowSignal = (e, data) => {
        e.stopPropagation()

        this.props.onFollowSignalHandler(data.id)
        this.setState({
            isWatchlisted: !this.state.isWatchlisted
        })
    }

    onSelect = (e, data) => {
        e.stopPropagation()
        this.props.onSelectHandler(data)
    }

    renderTargetBars = (signalId, targetPrice, data, currentState, midEntry, targetPrices, stopLossPrice) => {
        if (isNotNull(data) && isNotNull(targetPrices)) {
            return data.map((value, key) => {
                let index = key + 1 
                let tp = targetPrices[key]
                return (
                    <Target 
                        key={key} 
                        value={value} 
                        target={index}
                        signalId={signalId} 
                        targetPrice={targetPrice} 
                        tp={tp}
                        currentState={currentState} 
                        midEntry={midEntry}
                        stopLossPrice={stopLossPrice} />
                )
            })
        }
    }

    renderStatus = (signalId, isActive, tradeDirection, currentTradeState, signalState, entryStartPrice, entryEndPrice, targetPrice) => (        
        <SignalStatus 
            signalId={signalId}
            isActive={isActive}
            currentTradeState={currentTradeState}
            signalState={signalState}
            tradeDirection={tradeDirection} 
            entryStartPrice={entryStartPrice} 
            entryEndPrice={entryEndPrice} 
            targetPrice={targetPrice} />
    )

    renderWatchTooltip = (id, isFollowed) => {
        if (isFollowed) {
            return (
                <Tooltip id={`tooltip-${id}`}>
                    Remove from watchlist
                </Tooltip>
            );
        } else {
            return (
                <Tooltip id={`tooltip-${id}`}>
                    Add to watchlist
                </Tooltip>
            );
        }
    }

    render() {
        let selectedValue = parseInt(this.props.filter)
        const { user, data } = this.props
        const { isWatchlisted } = this.state
        let { 
            id, 
            isActive, 
            symbol, 
            feed, 
            strategy, 
            direction, 
            signalState,
            entryStartPrice, 
            entryEndPrice, 
            targetZone, 
            stopLossPrice,
            leverage, 
            datePublished, 
            toSym, 
            targetBarFills, 
            state, 
            isFree,
            targetPrices } = data
        let fromSym = getFromSym(symbol, toSym)
        let midEntry = null

        // initialize entry zone price format
        // try to reformat prices as USD/USDT
        if (entryStartPrice !== '????' && entryEndPrice !== '????') {
            midEntry = (parseFloat(entryStartPrice) + parseFloat(entryEndPrice)) / 2

            if (fromSym === 'USDT') {
                if (parseFloat(entryStartPrice) < 1 && parseFloat(entryEndPrice) < 1) {
                    entryStartPrice = toBtcFormat(entryStartPrice)
                    entryEndPrice = toBtcFormat(entryEndPrice)
                } else {
                    entryStartPrice = toUsdFormat(entryStartPrice)
                    entryEndPrice = toUsdFormat(entryEndPrice)
                }
            } else {
                entryStartPrice = toBtcFormat(entryStartPrice)
                entryEndPrice = toBtcFormat(entryEndPrice)
            }
        }

        // let newEntryPriceFormat = toUsdPriceFormat(entryStartPrice, entryEndPrice, symbol)
        // if (isNotNull(newEntryPriceFormat)) {
        //     if (isNotNull(newEntryPriceFormat.startPrice))
        //         entryStartPrice = newEntryPriceFormat.startPrice

        //     if (isNotNull(newEntryPriceFormat.endPrice))
        //         entryEndPrice = newEntryPriceFormat.endPrice
        // }

        // initialize target zone price format
        let targetPrice = '' //0.00
        let targetIndex = 0

        if (isNotNull(targetZone)) {
            if (isNotNull(targetZone.startPrice)) {
                targetPrice = targetZone.startPrice
    
                if (fromSym === 'USDT') {
                    if (parseFloat(targetZone.startPrice) < 1) 
                        targetPrice = toBtcFormat(targetZone.startPrice)
                    else
                        targetPrice = toUsdFormat(targetZone.startPrice)
                } else {
                    targetPrice = toBtcFormat(targetZone.startPrice)
                }
            } else {
                if (targetZone.startPriceTxt === '????')
                    targetPrice = targetZone.startPriceTxt
            }

            // let newTargetPriceFormat = toUsdPriceFormat(targetZone.startPrice, targetZone.endPriceTxt, symbol)
            // if (isNotNull(newTargetPriceFormat) && isNotNull(newTargetPriceFormat.startPrice)) {
            //     targetPrice = newTargetPriceFormat.startPrice
            // }

            targetIndex = targetZone.index
        }

        let coinLogo = null
        if (isNotNull(toSym))
            coinLogo = setCoinLogo(toSym)
        else
            coinLogo = setCoinLogo('default')

        if (selectedValue !== 3) {
            return (
                <SignalStyle className={`hoverable theme-signal-wrapper ${ ((isNotNull(user) && !user.isPaid && !isFree) || (isNotNull(user) && user.isPaid)) ? 'non-free' : ''}`}>
                    <div className="relative" onClick={(e) => this.onSelect(e, data)}>
                        <SignalOverlay coin={toSym} />

                        { isNotNull(user) && !user.isPaid && !isFree && 
                            <span className='ribbon pro'>
                                <span className='ribbon-text'>PRO</span>
                            </span>
                        }

                        <SignalHeader className="theme-signal-header" direction={direction}>
                            <div className="coinpair-wrapper">
                                <CoinPairLogo
                                    src={coinLogo}
                                    className={toSym}
                                    icon={toSym}
                                    onError={setDefaultImage} />

                                <div className="coinpair-info">
                                    <h3 className="coinpair">
                                        <span>{symbol}</span>
                                        <small className="last-price strategy">{feed}</small> <small className="last-price strategy">{strategy}</small>
                                    </h3>
                                </div>
                            </div>

                            <div className="direction-wrapper">
                                <ul className="trade-tags">
                                    <li>
                                        <span className="direction">{direction}</span>
                                    </li>

                                    {leverage > 1 &&
                                        <li>
                                            <span className="leverage">
                                                <span className="leverage-value">
                                                    <span className="lev">{leverage}x</span> Leverage
                                                </span>
                                            </span>
                                        </li>
                                    }
                                </ul>

                                <TargetBarsWrapper>
                                    {this.renderTargetBars(id, targetPrice, targetBarFills, state, midEntry, targetPrices, stopLossPrice)}
                                </TargetBarsWrapper>
                            </div>
                        </SignalHeader>

                        <SignalInstructions className="theme-signal-price-range">
                            <div className="price-range">
                                <label className="entry-label">
                                    <span className="price-range-icons">
                                        <i className="fas fa-database"></i>
                                    </span>
                                    Last price
                                </label>

                                <div className="last-price-value">
                                    { ((isNotNull(user) && user.isPaid) || (isNotNull(user) && !user.isPaid && isFree)) && 
                                        <span id={`signal-${id}`} className={`last-price-${symbol}`}></span>
                                    }
                                    
                                    { isNotNull(user) && !user.isPaid && !isFree && 
                                        <span>????</span>
                                    }
                                </div>
                            </div>

                            <div className="price-range">
                                <label className="entry-label">
                                    <span className="price-range-icons">
                                        <i className="fas fa-sign-in-alt"></i>
                                    </span>
                                    Entry
                                </label>

                                <div className="entry-value">
                                    <span><span>{entryStartPrice} - {entryEndPrice}</span></span>
                                </div>
                            </div>

                            <div className="price-range">
                                <label className="target-label">
                                    <span className="price-range-icons">
                                        <i className="fas fa-bullseye"></i>
                                    </span>
                                    <span className="target-text">
                                        Target
                                    </span>

                                    {targetBarFills.length > 1 &&
                                        <span className={`target-index target-index-${symbol}`}>{`( ${targetIndex} )`}</span>
                                    }
                                </label>
                                <div className="target-value">
                                    <span>
                                        <span>{ScientificToDecimal(targetPrice)}</span>
                                    </span>
                                </div>
                            </div>
                        </SignalInstructions>

                        <div className="trade-progress theme-signal-trade-progress">
                            <div className="trade-status">
                                {this.renderStatus(id, isActive, direction, state, signalState, entryStartPrice, entryEndPrice, targetPrice)}
                            </div>

                            <div className="trade-progress-value">
                                <SignalProgress state={state.item1} data={this.props.data} />
                            </div>
                        </div>
                    </div>

                    <div className="trade-actions theme-signal-trade-actions">
                        <div className="ts">
                            <span><FontAwesomeIcon icon={faClock} /> </span>
                            {getFromNow(datePublished)}
                        </div>

                        <div className="watchlist-actions">
                            {/* <div className="isTraded-wrapper">
                                {isOrdered &&
                                    <img src="/images/icon-traded.png" alt="Trade ordered" className="img-fluid" />
                                }
                            </div> */}
                            <OverlayTrigger
                                placement={window.innerWidth < 766 ? "left" : "right"}
                                delay={{ show: 150, hide: 400 }}
                                overlay={this.renderWatchTooltip(id, isWatchlisted)}>
                                <FollowSignalBtn onClick={(e) => this.onFollowSignal(e, data)} isWatchlisted={isWatchlisted}>
                                    {
                                        <FontAwesomeIcon icon={isWatchlisted ? faStarFull : faStar} />
                                    }
                                </FollowSignalBtn>
                            </OverlayTrigger>
                        </div>
                    </div>
                </SignalStyle>
            )
        }
    }
}