import { messagesActionTypes } from '../CONSTANTS'
import { messagesServices } from '../../services'

function getList(page, searchKeyword, selectedType) {
    return dispatch => {
        dispatch(request())

        messagesServices.getList(page, searchKeyword, selectedType)
            .then(res => dispatch(success(res)), error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: messagesActionTypes.GET_LIST_REQUEST } }
    function success(response) { return { type: messagesActionTypes.GET_LIST_SUCCESS, response } }
    function failed(error) { return { type: messagesActionTypes.GET_LIST_FAILED, error } }
}

function getMore(page, searchKeyword, selectedType) {
    return dispatch => {
        dispatch(request())

        messagesServices.getList(page, searchKeyword, selectedType)
            .then(res => dispatch(success(res)), error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: messagesActionTypes.LOAD_MORE_REQUEST } }
    function success(response) { return { type: messagesActionTypes.LOAD_MORE_SUCCESS, response } }
    function failed(error) { return { type: messagesActionTypes.LOAD_MORE_FAILED, error } }
}

function addHeaderPhoto(data, fileName) {
    return dispatch => {
        dispatch(request())
        
        messagesServices.addHeaderPhoto(data, fileName)
            .then(res => {
                if (res.success)
                    dispatch(success(res.location))
                else {
                    dispatch(failed("Something went wrong..."))
                }
            }, error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: messagesActionTypes.ADD_HEADER_PHOTO_REQUEST } }
    function success(response) { return { type: messagesActionTypes.ADD_HEADER_PHOTO_SUCCESS, response } }
    function failed(error) { return { type: messagesActionTypes.ADD_HEADER_PHOTO_FAILED, error } }
}

function replaceHeaderPhoto(data, fileName, oldFileUrl) {    
    return dispatch => {
        dispatch(request())
        
        messagesServices.replaceHeaderPhoto(data, fileName, oldFileUrl)
            .then(res => {
                if (res.success)
                    dispatch(success(res.location))
                else {
                    dispatch(failed("Something went wrong..."))
                }
            }, error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: messagesActionTypes.ADD_HEADER_PHOTO_REQUEST } }
    function success(response) { return { type: messagesActionTypes.ADD_HEADER_PHOTO_SUCCESS, response } }
    function failed(error) { return { type: messagesActionTypes.ADD_HEADER_PHOTO_FAILED, error } }
}

function saveMessage(data) {
    return dispatch => {
        dispatch(request())

        messagesServices.saveMessage(data)
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failed(error)))
    }
    
    function request() { return { type: messagesActionTypes.SAVE_MESSAGE_REQUEST } }
    function success(response) { return { type: messagesActionTypes.SAVE_MESSAGE_SUCCESS, response } }
    function failed(error) { return { type: messagesActionTypes.SAVE_MESSAGE_FAILED, error } }
}

function editMessage(data) {
    return dispatch => {
        dispatch(request())

        messagesServices.editMessage(data)
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failed(error)))
    }
    
    function request() { return { type: messagesActionTypes.EDIT_MESSAGE_REQUEST } }
    function success(response) { return { type: messagesActionTypes.EDIT_MESSAGE_SUCCESS, response } }
    function failed(error) { return { type: messagesActionTypes.EDIT_MESSAGE_FAILED, error } }
}

function updateList(data) {
    return dispatch => {
        dispatch({
            type: messagesActionTypes.UPDATE_MESSAGE_LIST,
            data
        })
    }
}

function clearList() {
    return dispatch => {
        dispatch({
            type: messagesActionTypes.RESET_LIST
        })
    }
}

function resetAddMessageState() {
    return dispatch => {
        dispatch({
            type: messagesActionTypes.SAVE_MESSAGE_RESET
        });
    }
}

function resetState() {
    return dispatch => {
        dispatch({
            type: messagesActionTypes.EDIT_MESSAGE_RESET
        });
    }
}

export const messagesActions = {
    getList,
    getMore,
    addHeaderPhoto,
    replaceHeaderPhoto,
    saveMessage,
    editMessage,
    updateList,
    clearList,
    resetAddMessageState,
    resetState
}