import React, { useEffect } from 'react'
import { TrialOfferContainer } from '../../components'
import { InjectFastSpring, addPageViewEvent, seo } from '../../utils'

export const TrialOfferPage = (props) => {
    useEffect(() => {
        seo({
            title: "Trial Offer",
            metaDescription: ""
        })
        addPageViewEvent()

        document.body.classList.add('offers')
        
        InjectFastSpring()
    }, [])

    return (<TrialOfferContainer />)
}