import styled from 'styled-components'

export const NoData = styled.p`
	background: #131E2B;
	position: relative;
`

export const ProfitAndLossLabel = styled.span`

    &.danger-label {
        color: rgb(185 63 95 / .6);
    }

    &.success-label {
        color: #adf94b;
    }
`

export const FeedButton = styled.button`
    &.feed-btn {
        width: 100%;
        border-color: transparent !important;
        background-color: transparent !important;
        color: #6a7b8f !important;

        &.selected {            
            box-shadow: inset 0.2rem 0.2rem 0.5rem #0f1922, inset -0.2rem -0.2rem 0.5rem #223040;
            color: #15bd6f !important;
        }

        &:active,
        &:focus {
            outline: none;
            color: #15bd6f !important;
        }

        &:hover {
            color: #15bd6f !important;
        }
    }

    &.market-btn {
        border-color: #dc3545;
        background-color: #de374770;
        color: #fff;

        &:active,
        &:focus {
            outline: none;
        }
        &:hover {
            border-color: #dc3545;
            background-color: #de374770;
            color: #fff;
        }
    }

    &.limit-btn {    
        border-color: #504c4dbd;
        background-color: #0e182491;
        color: #b1abab;

        &:active,
        &:focus {
            outline: none;
        }
        &:hover {
            border-color: #504c4dbd;
            background-color: #0e182491;
            color: #b1abab;
        }

    }
`