import _ from 'lodash'
import jQuery from 'jquery'
import { isNotNull, isNull } from './object'

export const priceFormat = (value1, value2, symbol) => {
    if (isNotNull(symbol)) {
        if (_.endsWith(symbol, "USDT") || _.endsWith(symbol, "USD")) {
            let price = {
                startPrice: undefined,
                endPrice: undefined
            }

            if (isNotNull(value1) && isNull(value2)) {
                if (value1 >= 1 && value1 <= 99)
                    price.startPrice = parseFloat(value1).toFixed(4) * 1;
                else if (value1 < 1)
                    price.startPrice = parseFloat(value1).toFixed(5) * 1;
                else //if (value1 >= 100)
                    price.startPrice = parseFloat(value1).toFixed(2) * 1;
            }

            if (isNotNull(value1) && isNotNull(value2)) {
                if (value1 >= 1 && value1 <= 99)
                    price.startPrice = parseFloat(value1).toFixed(4) * 1;
                else if (value1 < 1)
                    price.startPrice = parseFloat(value1).toFixed(5) * 1;
                else
                    price.startPrice = parseFloat(value1).toFixed(5) * 1;


                if (value2 >= 1 && value2 <= 99)
                    price.endPrice = parseFloat(value2).toFixed(4) * 1;
                else if (value2 < 1)
                    price.endPrice = parseFloat(value2).toFixed(5) * 1;
                else
                    price.endPrice = parseFloat(value2).toFixed(5) * 1;
            }

            return price;
        }
    }
}

export const toUsdFormat = (value) => {
    if (isNotNull(value)) {
        const newValue = parseFloat(value)

        if (value < 1)
            return parseFloat(newValue).toFixed(5) * 1
        else if (value >= 1 && value <= 99)
            return parseFloat(newValue).toFixed(4) * 1
        else
            return parseFloat(newValue).toFixed(2) * 1
    }
}

export const toBtcFormat = (value, fixedValue = 8) => {
    if (fixedValue === 8) {
        const calculatedValue = parseFloat(value).toFixed(8) * 1;
        if (isNaN(calculatedValue))
            return 0;
        else
            return calculatedValue.toString().includes("e") ? parseFloat(value).toFixed(8) : calculatedValue;
    }
    else
        return parseFloat(value).toFixed(fixedValue);
}

export const getFixedPrice = (price) => {
    let newPrice = 0
    if (parseFloat(price) <= 0)
        return "0.00"
    else {
        var result = (price - Math.floor(price)) !== 0
        if (result) {
            newPrice = parseFloat(price).toFixed(2)
        }
        else {
            newPrice = price
        }
        return newPrice
    }
}

export const price_checker = (balance, price, coinName) => {
    let newPrice = 0
    if (isNotNull(balance)) {
        if (parseFloat(balance) <= 0) {
            jQuery(`.usdt-${coinName}`).html("0.00")
            return 0.00
        }
        else {
            if (parseFloat(balance) > 0 && parseFloat(price) <= 0) {
                jQuery(`.usdt-${coinName}`).html("--")
                return 0.00
            } else {
                var result = (parseFloat(price) - Math.floor(price)) !== 0
                if (result)
                    newPrice = parseFloat(price).toFixed(2)
                else
                    newPrice = price

                jQuery(`.usdt-${coinName}`).html(newPrice)
                return newPrice
            }
        }
    }
}

export const toUsdPriceFormat = (value1, value2, symbol) => {

    const validatePrice = (value) => {
        if (isNotNull(value)) {
            const newValue = parseFloat(value)

            if (value < 1)
                return parseFloat(newValue).toFixed(5) * 1
            else if (value >= 1 && value < 100)
                return parseFloat(newValue).toFixed(4) * 1
            else
                return parseFloat(newValue).toFixed(2) * 1
        }
    }

    if (isNotNull(symbol) && (_.endsWith(symbol, "USDT") || _.endsWith(symbol, "USD"))) {
        const price = {
            startPrice: isNotNull(value1) ? validatePrice(value1) : undefined,
            endPrice: isNotNull(value2) ? validatePrice(value2) : undefined
        }
        return price;
    }
}