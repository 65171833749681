import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faExclamationCircle, faLock, faEye, faEyeSlash, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Form as FormFinal, Field } from 'react-final-form'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { PasswordHelper } from '../../components'
import { FormContainer, FormQuickLinks } from '../../components/Auth'
import { Brand, Layout, Error } from '../../styles'
import { emailValidator, isNull, passwordValidator, sleep } from '../../utils'

export const ResetPasswordView = props => {
    const $state = props.state
    const $this = props.self

    let passwordCriteria = {
        letter: false,
        capital: false,
        number: false,
        special: false,
        size: false
    }

    let confirmPassCriteria = {
        letter: false,
        capital: false,
        number: false,
        special: false,
        size: false,
        match: false
    }    
    
    useEffect(() => {
        document.body.classList.add('authentication')
    }, [])

    const onViewPasswordAsText = (fieldName) => {
        let getState = $state
        getState[fieldName] = {
            eyeIcon: getState[fieldName].eyeIcon === faEye ? faEyeSlash : faEye,
            type: getState[fieldName].type === "password" ? "text" : "password"
        }
        $this.setState(getState)
    }

    const onSpaceKeyPress = (e) => {
        var keyCode = (e.which) ? e.which : e.keyCode
        if (keyCode === 32)
            e.preventDefault()
    }

    const onFormSubmit = async values => {
        await sleep(300)

        props.submit(values)
        
        $this.setState({
            isSubmitted: true
        })
    }

    const onFormValidate = async values => {
        const errors = {}

        if (!emailValidator.isValid(values.resetEmail))
            errors.resetEmail = "Please enter a valid email address"

        if (isNull(values.password)) {
            errors.password = 'New Password is required'
            passwordCriteria = {
                letter: false,
                capital: false,
                number: false,
                special: false,
                size: false
            }
        }
        else {
            passwordCriteria.letter = passwordValidator.hasLowercase(values.password)
            passwordCriteria.capital = passwordValidator.hasUppercase(values.password)
            passwordCriteria.number = passwordValidator.hasNumeric(values.password)
            passwordCriteria.special = passwordValidator.hasSpecialChar(values.password)
            passwordCriteria.size = passwordValidator.isWithinMinAndMaxLength(values.password)

            if (!(passwordCriteria.letter &&
                passwordCriteria.capital &&
                passwordCriteria.number &&
                passwordCriteria.special &&
                passwordCriteria.size))
                errors.password = "Invalid password"
        }

        if (isNull(values.confirmPassword)) {
            errors.confirmPassword = 'Cofirm Password is required'
            confirmPassCriteria = {
                letter: false,
                capital: false,
                number: false,
                special: false,
                size: false,
                match: false
            }
        } else {
            confirmPassCriteria.letter = passwordValidator.hasLowercase(values.confirmPassword)
            confirmPassCriteria.capital = passwordValidator.hasUppercase(values.confirmPassword)
            confirmPassCriteria.number = passwordValidator.hasNumeric(values.confirmPassword)
            confirmPassCriteria.special = passwordValidator.hasSpecialChar(values.confirmPassword)
            confirmPassCriteria.size = passwordValidator.isWithinMinAndMaxLength(values.confirmPassword)
            confirmPassCriteria.match = values.password !== values.confirmPassword ? false : true

            if (!(confirmPassCriteria.letter &&
                confirmPassCriteria.capital &&
                confirmPassCriteria.number &&
                confirmPassCriteria.special &&
                confirmPassCriteria.size &&
                confirmPassCriteria.match))
                errors.confirmPassword = "Invalid password"
        }

        return errors
    }

    const renderResetForm = () => (
        <FormFinal
            initialValues={{
                resetEmail: $state.email
            }}
            onSubmit={onFormSubmit}
            validate={onFormValidate}
            decorators={[props.decorators]}
            render={({ handleSubmit, submitting }) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                        <Field name="resetEmail" type="email">
                            {({ input, meta, type }) => (
                                <>
                                    <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faEnvelope} className="icon" />
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>

                                            <Form.Control
                                                type={type}
                                                placeholder="Email address"
                                                autoComplete="off"
                                                readOnly
                                                className="form-control resetEmail"
                                                {...input}
                                            />
                                        </InputGroup>
                                    </div>
                                    <Error className={`${meta.error && meta.touched ? 'invalid' : ''}`}>
                                        {meta.error && meta.touched && (
                                            <>
                                                <FontAwesomeIcon icon={faExclamationCircle} className="icon" />
                                                <span>{meta.error}</span>
                                            </>
                                        )}
                                    </Error>
                                </>
                            )}
                        </Field>
                    </Form.Group>

                    <Form.Group controlId="formPassword">
                        <Field name="password" type={$state.password.type}>
                            {({ input, meta, type }) => (
                                <div ref={newPass => $this.refNew = newPass} className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faLock} className="faLock" />
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>

                                        <Form.Control
                                            type={type}
                                            maxLength="12"
                                            placeholder="Enter new password"
                                            autoComplete="off"
                                            onKeyPress={onSpaceKeyPress}
                                            {...input}
                                        />

                                        <InputGroup.Append onClick={() => { onViewPasswordAsText("password") }}>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={$state.password.eyeIcon} className="eyeIcon" />
                                            </InputGroup.Text>
                                        </InputGroup.Append>

                                        <PasswordHelper
                                            show={meta.active && meta.touched && meta.error && meta.visited}
                                            target={$this.refNew}
                                            container={$this.refNew}
                                            placement="bottom"
                                            data={isNull(input.value) ? props.initialPasswordHelperState : passwordCriteria}
                                        />
                                    </InputGroup>
                                </div>
                            )}
                        </Field>
                    </Form.Group>

                    <Form.Group controlId="formConfirmPassword">
                        <Field name="confirmPassword" type={$state.confirmPassword.type}>
                            {({ input, meta, type }) => (
                                <div ref={confirmPass => $this.refConfirm = confirmPass} className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faLock} className="faLock" />
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>

                                        <Form.Control
                                            type={type}
                                            maxLength="12"
                                            placeholder="Re-enter new password"
                                            autoComplete="off"
                                            onKeyPress={onSpaceKeyPress}
                                            {...input}
                                        />

                                        <InputGroup.Append onClick={() => { onViewPasswordAsText("confirmPassword") }}>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={$state.confirmPassword.eyeIcon} className="eyeIcon" />
                                            </InputGroup.Text>
                                        </InputGroup.Append>

                                        <PasswordHelper
                                            show={meta.active && meta.touched && meta.error && meta.visited}
                                            target={$this.refConfirm}
                                            container={$this.refConfirm}
                                            placement="bottom"
                                            confirm={true}
                                            data={isNull(input.value) ? props.initialPasswordHelperState : confirmPassCriteria}
                                        />
                                    </InputGroup>
                                </div>
                            )}
                        </Field>
                    </Form.Group>

                    {
                        $state.isSubmitted
                            ? <Button variant="primary" className="auth-btn">
                                <i className="fas fa-spinner fa-pulse"></i>
                            </Button>
                            : <Button type="submit" disabled={submitting} variant="primary" className="auth-btn">
                                Change Password
                            </Button>
                    }
                </Form >
            )}
        />
    )

    return (
        <Layout>
            <FormContainer>
                <Brand imageUrl={`/images/brand/pf-logo.png`} />

                <h1 className='title'>
                    <strong>Create New Password</strong>
                </h1>

                {renderResetForm()}            
                
                <FormQuickLinks>
                    <Link to={`/account/login`}>
                        <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: '8px'}} />
                        <span>Cancel</span>
                    </Link>
                </FormQuickLinks>
            </FormContainer>
        </Layout>
    )
}