import styled from 'styled-components'

export const MessageBoardForm = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 30px;
    min-height: calc(100vh - 120px);

    .form-horizontal {
        width: 100%;

        #header-photo-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            margin-bottom: 15px;
            background-color: #fff;
            max-height: 380px;
            overflow: hidden;
            width: 100%;
            max-width: 1008px;

            .drop-zone-wrapper {
                width: 100%;
                cursor: pointer;

                p {
                    display: block;
                    padding: 25px;
                    font-size: 24px;
                    color: #ccd4dd;
                    margin: 0;
                    text-align: center;
                }
            }

            .header-photo-preview {
                display: block;
                width: 100%;
                background-color: #fff;

                img {
                    width: 100%;
                }
            }
        }

        #editor {
            min-width: 1008px;
            min-height: 500px;
            background-color: #fff;
            border-radius: 10px;
        }

        .form-group {
            .form-label {
                font-size: 14px;
            }

            .input-wrapper {
                &.is-invalid {
                    .form-control {
                        border-color: #993956 !important;
                    }
                }

                .MuiInputBase-root {
                    height: calc(1.5em + 1rem + 2px);
                    padding: 0.5rem 1rem;
                    font-size: 1.25rem;
                    line-height: 1.5;
                    background-color: #fff;
                    border-color: #eee;
                    border-radius: 10px;
                    color: #161f29 !important;
                }

                .MuiButtonBase-root {
                    background-color: #eef4fd !important;
                    color: #222f3e !important;
                }

                .form-text {
                    color: #df5481;
                    font-size: 14px;
                }
            }
        }
    }
`