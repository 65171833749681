import ReactPlayer from 'react-player/lazy'
import { BearMarketResultsSectionStyle } from './styles'

export const AdvancedSignalsAlgorithmsSection = (props) => {
    return (
        <BearMarketResultsSectionStyle>
            <div className='container'>
                <h2 className='section-title'>ProfitFarmers Advanced Signals Algorithms Are Always Watching</h2>

                <ul className='snapvids'>
                    <li>
                        <ReactPlayer 
                            url={'https://vimeo.com/793267914/a8908ceac8'}
                            controls={true} 
                            width='100%' 
                            volume={0} />

                        <p className='video-title'>APTUSDT - Result +360% in 6 days</p>
                    </li>
                    <li>
                        <ReactPlayer 
                            url={'https://vimeo.com/794776299/63f59092b3'}
                            controls={true} 
                            width='100%' />

                        <p className='video-title'>PROSBUSD - Result +20% in a day</p>
                    </li>
                    <li>
                        <ReactPlayer 
                            url={'https://vimeo.com/794838376/826227551a'}
                            controls={true} 
                            width='100%' />

                        <p className='video-title'>HIGHUSDT - Result +50% in 14 hours</p>
                    </li>
                </ul>
                
                <p className='section-description'>We cashed in on the 2023 boom whilst everyone else was running scared, calling for Bitcoin to $10,000 after the FTX debacle.</p>
            </div>
        </BearMarketResultsSectionStyle>
    )
}