import styled from 'styled-components'

export const Section = styled.div`
    padding: 40px 30px;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .title-wrapper {
            display: flex;
            position: relative;
            width: 195px;
            align-items: center;

            .text {
                display: flex;
                font-size: 34px;
                font-weight: 500;
                margin-bottom: 0;
                justify-content: center;
                align-items: center;
            }

            .ico {
                font-size: 30px;
                color: #15bd6f;
                margin-right: 10px;

                @media only screen and (min-width: 768px) {
                    font-size: 25px;
                }

                @media only screen and (min-width: 1024px) {
                    font-size: 26px;
                }

                @media only screen and (min-width: 1360px) {
                    font-size: 30px;
                }
            }



            @media only screen and (min-width: 320px) AND (max-width: 766px) {
                width: 140px;
            }

            @media only screen and (min-width: 767px) AND (max-width: 1199px) {
                width: 160px;
            }
        
            @media only screen and (min-width: 1200px) {
                width: 170px;
            }
        
            @media only screen and (min-width: 1441px) {
                width: 180px;
            }
        }
    }

    &.lighter {
        background-color: #0e1824;
        border-bottom: 1px solid #0b131d;
    }

    @media screen and (min-width: 360px)  and (max-width: 766px) {
        padding: 20px 15px;
    }

    @media screen and (max-width: 1440px) and (min-width: 1200px){
        padding: 30px 210px 30px 15px;
    }

    @media only screen and (min-width: 1441px) {
        padding: 30px 241px 30px 22px;
    }    
`