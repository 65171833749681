import styled from 'styled-components'

export const Error = styled.div`
    color: #eb5d7d;
    font-size: 12px;
    line-height: 10px;
    padding-top: 10px;
    display: none;

    & span {
        padding-left: 5px;
    }

    &.invalid {
        display: block;
    }

    &.sm {
        span {
            font-size: 13px;
            margin-left: 0;
        }
    }

    &.settings {
        color: #da3969;
        padding-left: 5px;
        font-weight: 600;
        font-size: 14px;
    }
`
