import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const LadderingModeSectionStyle = styled.section`
    position: relative;
    width: 100%;
    display: block;
    padding: 40px 5px;
    background-image: linear-gradient(180deg, #124857, #15324E);

    .container {
        .text-with-img {
            display: flex;
            justify-content: center;
            align-items: center;

            .text-col {
                img {
                    margin-bottom: 15px;
                    max-width: 226px;
                    width: 150px;
                }

                .section-title {
                    font-weight: 800;
                    color: #FFFFFF;
                }

                p {
                    color: #9AB9D5;
                    font-family: 'Noto Sans';
                    font-weight: 300;

                    .white-text {
                        color: #FFFFFF;
                    }
                }

                .benefits-list {
                    list-style-type: none;
                    padding-left: 50px;
                    font-family: 'Noto Sans';
                    color: #FFFFFF;
                    font-weight: 300;

                    .list-item {
                        margin-bottom: 20px;
                        position: relative;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &:before {
                            content: '';
                            position: absolute;
                            width: 32px;
                            height: 32px;
                            top: calc(50% - 16px);
                            left: -50px;
                            background-image: url(/images/trial-offer/check-icon.png);
                            background-repeat: no-repeat;
                            background-size: contain;;
                        }
                    }
                }
            }

            .image-col {
                display: none;

                img {
                    width: 100%;
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        .container {
            .text-with-img {
                .text-col {
                    width: 50%;

                    .section-title {
                        font-size: 40px;
                    }

                    .benefits-list {
                        .list-item {
                            margin-bottom: 30px;
                        }
                    }
                }

                .image-col {
                    display: flex;
                    width: 50%;
                    position: relative;

                    &:before {
                        content:'';
                        position: absolute;
                        width: 300px;
                        height: 250px;
                        background-image: url(images/trial-offer/ellipse-green.svg);
                        background-size: contain;
                        background-repeat: no-repeat;
                        top: 0;
                        left: 0;
                    }

                    &:after {
                        content:'';
                        position: absolute;
                        width: 300px;
                        height: 230px;
                        background-image: url(images/trial-offer/ellipse-green.svg);
                        background-size: contain;
                        background-repeat: no-repeat;
                        bottom: 0;
                        left: -110px;
                    }

                    img {
                        z-index: 1;
                    }
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        .container {
            .text-with-vid {
                .text-col {
                    img {
                        width: 200px;
                    }

                    .benefits-list {
                        margin-top: 35px;

                        .list-item {
                            margin-bottom: 35px;
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.superExtraLargeDevices} {
        .container {

        }
    }
`