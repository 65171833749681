import { leadActionTypes } from '../CONSTANTS'

export function leads(state = {}, action) {
    var { response, error, ...oldState } = state
    switch (action.type) {
        case leadActionTypes.LEAD_CHANGE_PASSWORD_REQUEST:
            return {
                loading: true
            }
        case leadActionTypes.LEAD_CHANGE_PASSWORD_SUCCESS:
            return {
                ...oldState,
                loading: false,
                response: action.response
            }
        case leadActionTypes.LEAD_CHANGE_PASSWORD_FAILURE:
            return {
                ...oldState,
                loading: false,
                error: action.error
            }
        case leadActionTypes.LEAD_CHANGE_PASSWORD_CLEAR:
            return {
                loading: false
            }
        default:
            return state
    }
}