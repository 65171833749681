import { announcementActionTypes } from '../CONSTANTS'

export function announcements(state = {}, action) {
    switch (action.type) {
        case announcementActionTypes.GET_ACTIVE_ANNOUNCEMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case announcementActionTypes.GET_ACTIVE_ANNOUNCEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                active_announcement: action.active_announcement
            }
        case announcementActionTypes.GET_ACTIVE_ANNOUNCEMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}