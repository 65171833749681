import glamorous from 'glamorous'
import { mediaQueries } from '../../constants/mediaQueries'
import { isNotNull } from '../../utils'

const getWidth = (cols) => {
    if (cols === 5) {
        return '196px'
    }

    return '245px'
}

export const MainMenuWrapper = glamorous.div({
    display: 'block',
    width: '100%',
    marginBottom: 0,

    [mediaQueries.mediumDevices]: {
        marginBottom: '15px'
    }
})

export const MainMenu = glamorous.ul({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '25px',
    listStyleType: 'none',
    padding: 0,
    margin: 0,

    [mediaQueries.extraSmallDevices]: {
        flexDirection: 'column',
        justifyContent: 'flex-start'
    }
})

export const MainMenuItem = glamorous.li({
    position: 'relative',
    borderRadius: '35px',
    overflow: 'hidden',
    boxShadow: '0px 3px 18px #00000061',
    transition: 'all ease .6s',
    
    '& a, .menu-btn': {        
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '196px',
        padding: '12px',
        position: 'relative',
        backgroundColor: '#143862',
        borderRadius: '35px',
        boxShadow: '0 0 66px 61px #121e2c inset',
        border: '2px solid #173349',
        textDecoration: 'none',
        transition: 'all ease .7s',

        '&::before': {
            position: 'absolute',
            content: ' ',
            display: 'block',
            top: '-128px',
            left: 0,
            width: '100%',
            height: '200px',
            background: 'transparent radial-gradient(closest-side at 50% 50%, #13B078 0%, #23395000 100%) 0% 0% no-repeat padding-box',
            opacity: '0.3',
            zIndex: 0,
            transition: 'all ease 1s',
        },

        '& .icon': {
            display: 'inline-block',
            width: '64px',
            height: '64px',
            textAlign: 'center',

            '& svg, & i': {
                color: '#a3cfff',
                fontSize: '48px',
                transition: 'color ease .5s'
            }
        },

        '& .text': {
            color: '#b3ceed',
            textAlign: 'center',
            fontSize: '15px',
            fontWeight: 500
        }
    },

    '&:hover': {
        boxShadow: '0px 3px 18px rgba(19, 176, 120, .2)',

        '& a, .menu-btn': {
            backgroundColor: '#37e3ff',
            borderColor: '#194f62',

            '&::before': {
                opacity: '.4',
                top: '-120px',
                transition: 'all ease .7s',
            },

            '& .icon': {
                '& svg': {
                    color: '#fff'
                }
            },

            '& .text': {
                color: '#fff',
                transition: 'color ease 1s'
            }
        }
    },

    [mediaQueries.extraSmallDevices]: {
        width: '100%',
        borderRadius: '9px',
        marginBottom: '10px',

        '& a, .menu-btn': {
            width: '100%',
            height: '60px',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            borderRadius: '9px',
            padding: '0 25px',

            '& .icon': {
                width: '26px',
                height: '26px',
                marginRight: '15px',

                '& svg': {
                    fontSize: '26px'
                }
            }
        }
    },

    [mediaQueries.mediumDevices]: {
        width: '138px',
        borderRadius: '20px',
        marginBottom: 0,

        '& a, .menu-btn': {
            justifyContent: 'flex-start',
            width: '138px',
            height: '138px',
            borderRadius: '20px',
            padding: '35px 0 0',

            '&::before': {
                top: '-70px',
                height: '140px'
            },

            '& .icon': {
                width: '40px',
                height: '40px',

                '& svg': {
                    fontSize: '32px',
                    color: '#d5e9ff'
                }
            },

            '& .text': {
                fontSize: '14px',
                color: '#deeeff'
            }
        }
    },

    [mediaQueries.largeDevices]: {
        width: '186px',
        borderRadius: '25px',

        '& a, .menu-btn': {
            justifyContent: 'center',
            width: '186px',
            height: '180px',
            borderRadius: '25px',
            padding: 0,

            '& .icon': {
                width: '54px',
                height: '54px',

                '& svg': {
                    fontSize: '40px'
                }
            },

            '& .text': {
                fontSize: '15px'
            }
        }
    },

    [mediaQueries.extraLargeDevices]: {

    }
}, ({ cols }) => ({
    '& a, .menu-btn': {
        width: isNotNull(cols) ? getWidth(cols) : '196px'
    },

    [mediaQueries.mediumDevices]: {
        width: isNotNull(cols) ? '175px' : '138px',

        '& a, .menu-btn': {
            width: isNotNull(cols) ? '175px' : '138px'
        }
    },

    [mediaQueries.largeDevices]: {
        width: isNotNull(cols) ? '237px' : '186px',

        '& a, .menu-btn': {
            width: isNotNull(cols) ? '237px' : '186px'
        }
    }
}))