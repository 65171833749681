import styled from 'styled-components'
import { device } from '../../../styles/CONSTANTS'

const deviceMinHeight = `(min-height: 731px)`

export const LockedPricingWrapper = styled.div`
	font-size: 16px;
	position: relative;
	color: #ced0dd;
	background-color: #0b131d;

	&.sm {
		background-image: none;
		overflow: auto;
		margin-bottom: 50px;

		@media ${device.mobileM} {
			max-height: 200px;
		}

		@media ${deviceMinHeight} {
			max-height: none;
		}

		.section-block {
			padding: 0;

			.section-block-inner {
				max-width: none;
				width: 100%;

				.section-header {
					.locked-content {
						.title {
							font-size: 24px;
							margin-bottom: 15px;
							color: #DCBC12;
						}

						.sub-title {
							padding: 0;
							font-size: 12px;
							margin-bottom: 5px;
							text-align: center;
							letter-spacing: 0.7px;
							line-height: 24px;
							font-weight: 500;
						}
					}
				}

				.section-body {
					display: flex;
					justify-content: center;
					width: 100%;

					.lock-btn {
						position: fixed;
						bottom: 20px;
						width: 100%;
						padding: 0 30px;

						@media ${device.deviceR}{
							bottom: 25px;
						}

						@media ${device.tablet} {
							bottom: 100px;
						}
					}
				}
			}
		}
	}

	& {
		.section-block {

			padding: 40px 30px;
			display: block;

			& .section-block-inner {
				max-width: 1078px;
				margin: 0 auto;

				& {
					.section-header {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 20px;

						& .locked-content {
							text-align: center;

							& {
								.title {
									font-size: 35px;
									font-weight: 800;
									margin-bottom: 30px;
								}

								.sub-title {
									padding: 0 80px;
									margin-top: 0;
									margin-bottom: 1rem;
								}
							}
						}
					}

					.section-body {
						& .lock-btn {
							display: flex;
							justify-content: center;
							align-items: center;

							& {

								.btn:not(:disabled):not(.disabled) {
									cursor: pointer;
								}

								.btn {
									display: inline-block;
									font-weight: 400;
									text-align: center;
									white-space: nowrap;
									vertical-align: middle;
									-webkit-user-select: none;
									-moz-user-select: none;
									-ms-user-select: none;
									user-select: none;
									border: 1px solid transparent;
									padding: .375rem .75rem;
									font-size: 1rem;
									line-height: 1.5;
									border-radius: .25rem;
									webkit-transition: -webkit-transform .2s;
									-webkit-transition: transform .2s;
									transition: transform .2s;
								}

								.btn-deposit {
									border-bottom: 4px solid #527722;
									font-size: 16px;
									font-weight: 700;
									padding: 10px;
									text-transform: uppercase;
									width: 200px;
								}

								button {
									color: #007bff;
									text-decoration: none;
									background-color: transparent;
									-webkit-text-decoration-skip: objects;


									&:hover {
										-webkit-transform: scale(1.3);
										-ms-transform: scale(1.3);
										transform: scale(1.3);
									}
								}

							}
						}
					}
				}
			}

		}
	}

	@media screen and (min-width: 320px) and (max-width: 766px) {
		font-size: 14px;

		.section-block {
			padding: 35px 20px;

			.section-block-inner {
				.section-header {
					.locked-content {
						.title {
							font-size: 26px;
						}

						.sub-title {
							padding: 0;
						}
					}
				}
			}
		}
	}

	@media screen and (min-width: 1024px) and (max-width: 1199px) {
		.section-block {
			.section-block-inner {
				max-width: 770px;

				.section-header {
					.locked-content {
						.title {
							font-size: 30px;
						}
						.sub-title {
							padding: 0 50px;
						}
					}
				}
			}
		}
	}

	@media screen and (min-width: 1200px) and (max-width: 1440px) {
		.section-block {
			.section-block-inner {
				max-width: 772px;
			}
		}
	}

	@media screen and (min-width: 1441px) and (max-width: 1749px) {
		.section-block {
			.section-block-inner {
				max-width: 907px;
			}
		}
	}

`