import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faChartLine, 
    faTimes,
    faBars,
    faGraduationCap,
    faBookOpenReader,
    faArrowTrendUp } from '@fortawesome/free-solid-svg-icons'
import { 
    DashboardPageLayout, 
    TopNavBar, 
    MainSidebar,
    MainWrapper, 
    SectionBlock, 
    Container, 
    RowBlock, 
    SectionHeader,
    CloseButton,
    NetPeakGainsStatistics,
    TopWinnersWrapper} from '../../styles'
import { 
    Bell, 
    LoggedInUser, 
    Profile, 
    CryptoWallet,
    Messages,
    SymbolOverView, 
    TerminalLoginButton, 
    HelpLinks, 
    RollingPeriodNetPeakGains,
    MonthlyPeakGains,
    FeaturedContent,
    TopWinners,
    Academy, 
    CaseStudies,
    GuideArticles,
    NotificationPermissionAlert,
    StatisticsMenuLink,
    ScannerMenuLinks,
    BlueprintMenuLink, 
    MessageBoardMenuLink,
    CommunityMenuLink,
    OnboardingModal,
    Tour, 
    BlackFridayTrial,
    TradeDoctorSessionBooking,
    JoinCommunityInvitation } from '../../components'    
import ViralLoops from '../../components/ViralLoops/ViralLoops'
import { viralLoopsKey } from '../../services/CONSTANTS'
import { mediaDeviceSizes } from '../../constants/mediaQueries'
import { addPageViewEvent, isNotNull, seo, getSize, isSupported } from '../../utils'

export const DashBoardPage = (props) => {
    let history = props.history
    let [referralVisibility, setReferralVisibility] = useState(false)
    let [device, setDevice] = useState(null)
    let [sidebarPane, setSidebarVisibility] = useState(null)

    const handleReferralVisibility = (e) => setReferralVisibility(!referralVisibility)
    const closeReferralPopup = () => setReferralVisibility(false)
    const [isTokenFound, setTokenFound] = useState(null)
    const [tourEnabled, setTourEnabled] = useState(false)
    let [completeProfile, setCompleteProfile] = useState(false)

    const injectChart = () => {
        const script = document.createElement("script")
        script.type = "text/javascript"
        script.async = true
        script.src = "https://s3.tradingview.com/tv.js"

        document.body.appendChild(script)
    }

    useEffect(() => {
        seo({
            title: "Dashboard",
            metaDescription: ""
        })
        addPageViewEvent()
        
        document.body.classList.remove('offers')
        document.body.classList.remove('onboarding')
        document.body.classList.remove('upgrade-to-pro')
        document.body.classList.remove('messages')
        document.body.classList.add('inner-pages')
        
        injectChart()
        
        let deviceSize = getSize(window.innerWidth)
        setDevice(deviceSize)

        if (deviceSize === mediaDeviceSizes.xs || deviceSize === mediaDeviceSizes.sm || device === mediaDeviceSizes.lg)
            setSidebarVisibility(false)
        else
            setSidebarVisibility(true)
    }, [])

    const toggleSidebar = () => {
        let newState = !sidebarPane
        setSidebarVisibility(newState)
    }

    const handleTour = () => {
        setTourEnabled(true)
    }

    const onCompleteTour = () => setTourEnabled(false)

    const onClickCompleteMyProfile = () => {
        setCompleteProfile(true)
    }

    const handleResetProfileState = () => {
        if (completeProfile)
            setCompleteProfile(false)

        if (tourEnabled)
            setTourEnabled(false)
    }
    
    return (
        <>
            { isSupported() && isNotNull(isTokenFound) && !isTokenFound && 
                <NotificationPermissionAlert />
            }

            <DashboardPageLayout 
                id="page-wrapper" 
                className={`${sidebarPane && (device === mediaDeviceSizes.xs || device === mediaDeviceSizes.sm || device === mediaDeviceSizes.md || device === mediaDeviceSizes.lg) ? 'show': ''} ${isNotNull(isTokenFound) && !isTokenFound ? ' notif-permission' : ''}`}>
                <div className="back-drop" />

                <TopNavBar className={ isNotNull(isTokenFound) && !isTokenFound ? 'has-notif-permission' : '' }>
                    { (device === mediaDeviceSizes.xs || device === mediaDeviceSizes.sm || device === mediaDeviceSizes.md || device === mediaDeviceSizes.lg) && 
                        <button 
                            type="button" 
                            className='sidebar-toggle-btn'
                            onClick={() => toggleSidebar()} >
                            <FontAwesomeIcon icon={faBars} />
                        </button>
                    }

                    <NavLink to={`/dashboard`} className='brand'>
                        <img src='/images/brand/pf-logo.png' alt="ProfitFarmers Logo" /> 
                    </NavLink>
                    
                    <div className='nav-menu-wrapper'>
                        <Tour 
                            enabled={tourEnabled} 
                            onToggleSidebar={() => toggleSidebar()}
                            onCompleteTour={() => onCompleteTour()} />

                        <Bell 
                            isTokenFound={isTokenFound} 
                            setTokenFound={setTokenFound}
                            history={history} />

                        <LoggedInUser />
                    </div>
                </TopNavBar>

                <MainSidebar id="tour-sidebar" className={`${ sidebarPane && (device === mediaDeviceSizes.xs || device === mediaDeviceSizes.sm || device === mediaDeviceSizes.md || device === mediaDeviceSizes.lg) ? 'show': ''} ${isNotNull(isTokenFound) && !isTokenFound ? ' has-notif-permission' : ''}`}>
                    <ul className='sidebar-menu-list'>
                        <li className='sidebar-menu-item'>
                            <NavLink to="/dashboard" className="menu-item-link active">
                                <span className='menu-item-link-icon'><i className='fas fa-th-large'></i></span>
                                <span className='menu-item-link-text'>Dashboard</span>
                            </NavLink>
                        </li>
                        <li className='sidebar-menu-item'>
                            <div className='sidebar-menu-title'>
                                <span className='menu-title-text'>Tools</span>
                            </div>
                            <ul className='sidebar-submenu-list'>
                                <li className='sidebar-submenu-item'>
                                    <NavLink to="/signals" className="sidebar-submenu-item-link">
                                        <span className='submenu-item-link-icon'><i className='fas fa-seedling'></i></span>
                                        <span className='submenu-item-link-text'>Signals</span>
                                    </NavLink>                                
                                </li>
                                
                                <li className='sidebar-submenu-item'>
                                    <TerminalLoginButton />
                                </li>

                                <ScannerMenuLinks />
                                <li className='sidebar-submenu-item'>
                                    <BlueprintMenuLink />
                                </li>
                                <MessageBoardMenuLink />
                            </ul>
                        </li>
                        <li className='sidebar-menu-item'>
                            <div className='sidebar-menu-title'>
                                <span className='menu-title-text'>Trade History</span>
                            </div>
                            <ul className='sidebar-submenu-list'>
                                <li className='sidebar-submenu-item'>
                                    <NavLink to="/orders" className="sidebar-submenu-item-link">
                                        <span className='submenu-item-link-icon'><i className='fas fa-file-invoice'></i></span>
                                        <span className='submenu-item-link-text'>Trade Orders</span>
                                    </NavLink>                                
                                </li>
                                
                                <StatisticsMenuLink />
                            </ul>
                        </li>
                        <li className='sidebar-menu-item' id="tour-help-centre">
                            <div className='sidebar-menu-title'>
                                <span className='menu-title-text'>Help Centre</span>
                            </div>
                            
                            <HelpLinks />
                        </li>
                        {/* <li className='sidebar-menu-item' id="trader-community">
                            <div className='sidebar-menu-title'>
                                <span className='menu-title-text'>Community</span>
                            </div>
                            <ul className='sidebar-submenu-list'>
                                <CommunityMenuLink />
                            </ul>
                        </li> */}
                    </ul>
                </MainSidebar>
                
                <MainWrapper>
                    {/* <BlackFridayTrial /> */}

                    <SectionBlock>
                        <Container>
                            <RowBlock className='profile-info'>
                                <Profile eventHandlers={handleReferralVisibility} />
                                <CryptoWallet onClickCompleteMyProfile={() => onClickCompleteMyProfile()} />
                            </RowBlock>

                            <RowBlock align="start">
                                <NetPeakGainsStatistics id='tour-npg'>
                                    <RollingPeriodNetPeakGains />

                                    <div className='header'>
                                        <h2 className='with-legend'>
                                            <span>Monthly Net Peak Gains <small className='help-txt'>(Maximum Opportunity)</small></span>
                                            <label>
                                                <span className='legend'></span>
                                                <span className='label-text'>Total Peak Gains</span>
                                            </label>
                                        </h2>
                                    </div>

                                    <MonthlyPeakGains />
                                </NetPeakGainsStatistics>
                                
                                <TopWinnersWrapper>
                                    <FeaturedContent />
                                    <TopWinners />
                                </TopWinnersWrapper>
                            </RowBlock>
                        </Container>
                    </SectionBlock>

                    <SectionBlock theme="light-dark">
                        <Container>
                            <Messages />
                        </Container>
                    </SectionBlock>

                    <SectionBlock>
                        <Container>
                            <RowBlock className='as-block' id="tour-academy">
                                <SectionHeader>
                                    <h2 className='section-title'>
                                        <FontAwesomeIcon icon={faGraduationCap} className='icon' />
                                        <span className='text academy-title'>Watch the 3 Steps to Success</span>
                                        <span className='separator'>|</span>
                                        <a className='header-link' href='https://www.profitfarmers.com/academy/' target='_blank'>Go to Academy</a>
                                    </h2>
                                </SectionHeader>

                                <Academy />
                            </RowBlock>
                        </Container>
                    </SectionBlock>

                    <SectionBlock theme="light-dark">
                        <Container>                            
                            <RowBlock className='as-block' id="tour-case-studies">
                                <SectionHeader>
                                    <h2 className='section-title'>
                                        <FontAwesomeIcon icon={faBookOpenReader} className='icon' />
                                        <span className='text'>Case Studies</span>
                                        <span className='separator'>|</span>
                                        <a className='header-link' href='https://www.profitfarmers.com/case-studies/' target='_blank'>Go to Member Stories</a>
                                    </h2>
                                </SectionHeader>
                                
                                <CaseStudies />
                            </RowBlock>
                        </Container>
                    </SectionBlock>

                    <SectionBlock>
                        <Container>                            
                            <RowBlock className='as-block' id="tour-guides">
                                <SectionHeader>
                                    <h2 className='section-title'>
                                        <FontAwesomeIcon icon={faArrowTrendUp} className='icon' />
                                        <span className='text'>Level up Your Game</span>
                                    </h2>
                                </SectionHeader>
                                
                                <GuideArticles />
                            </RowBlock>
                        </Container>
                    </SectionBlock>

                    <SectionBlock theme="light-dark">
                        <Container>                            
                            <RowBlock className='as-block'>
                                <SectionHeader>
                                    <h2 className='section-title'>
                                        <FontAwesomeIcon icon={faChartLine} className='icon' />
                                        <span className='text'>Trading Charts</span>
                                    </h2>
                                </SectionHeader>

                                <SymbolOverView />
                            </RowBlock>
                        </Container>
                    </SectionBlock>
                </MainWrapper>

                <OnboardingModal 
                    takeTour={handleTour} 
                    onCompleteMyProfile={completeProfile} 
                    handleResetProfileState={handleResetProfileState} />

                <Modal 
                    className='generic-modal referral'
                    show={referralVisibility}
                    centered>
                        <Modal.Header>
                            <h3 className="title">Invite a friend</h3>
                            <CloseButton onClick={() => closeReferralPopup() } className="custom-close">
                                <FontAwesomeIcon icon={faTimes} className="icon" />
                            </CloseButton>
                        </Modal.Header>
                        <Modal.Body>
                            <ViralLoops
                                publicToken={viralLoopsKey}
                                formWidget={true}
                                rewardWidget={true}
                            />
                        </Modal.Body>
                </Modal>

                <TradeDoctorSessionBooking />

                {/* <JoinCommunityInvitation /> */}
            </DashboardPageLayout>
        </>
    )
}