import { endpoints, baseUrl } from './CONSTANTS'
import { authServices } from './authService'

const { v3 } = endpoints

function getNetPeakGains(period) {
    const requestOpts = {
        method: 'GET',
        headers: { 
            'Accept': "application/json",
            'Content-Type': "application/json"
        },
        credentials: 'include'
    }

    return fetch(`${baseUrl}${v3.system.statistics.netPeakGains(period)}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

function getMonthlyPeakGains() {
    const requestOpts = {
        method: 'GET',
        headers: { 
            'Accept': "application/json",
            'Content-Type': "application/json"
        },
        credentials: 'include'
    }

    return fetch(`${baseUrl}${v3.system.statistics.monthlyPeakGains}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

function getTopWinners() {
    const requestOpts = {
        method: 'GET',
        headers: { 
            'Accept': "application/json",
            'Content-Type': "application/json"
        },
        credentials: 'include'
    }

    return fetch(`${baseUrl}${v3.system.statistics.topWinners}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

function getStatistics(data) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(data)
    }

    return fetch(`${baseUrl}${v3.system.statistics.data}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function getStrategies() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    return fetch(`${baseUrl}${v3.system.strategies}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function getPeriodRange() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    return fetch(`${baseUrl}${v3.system.periodRanges}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        if (res.status === 401)
            authServices.logout()
        else
            return data
    })
}

export const statisticServices = {
    getNetPeakGains,
    getMonthlyPeakGains,
    getTopWinners,
    getStatistics,
    getStrategies,
    getPeriodRange
}