import React from 'react'
import { connect } from 'react-redux'
import jQuery from 'jquery'
import queryString from 'query-string'
import { Form, InputGroup } from 'react-bootstrap'
import { TextButton } from '../../styles'
import { VerifyCodeFormWrapper } from './EmailVerificationStyle'
import { FormQuickLinks } from '../../components/Auth'
import { authActions } from '../../redux/actions'
import { isNull, isNotNull, onSetNewState, encryptText, decryptText } from '../../utils'

class EmailVerification extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isSubmitting: false,
            email: 'someone@example.com',
            code: '',
            codeIsValid: null
        }
        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true

        // read user email
        const searchQuery = this.props.location.search.replace('?','')
        if (isNotNull(searchQuery)) {
            const parsedData = queryString.parse(searchQuery)
            if (isNotNull(parsedData)) {
                const { email, ri, rl } = parsedData

                if (isNotNull(email)) {
                    onSetNewState(this, {
                        email
                    })
                }

                if (isNotNull(ri) && isNotNull(rl)) {
                    let uid = decryptText(ri.replace(/\s/g, '+'))

                    if (rl) {                       
                        window.dataLayer.push({
                            event: 'referred_sign_up',
                            user_id: uid
                        }) 
                    } else {
                        window.dataLayer.push({
                            event: 'sign_up',
                            user_id: uid
                        })
                    }
                }
            }
        } else
            window.location.href = '/forbidden'

        jQuery('#verify-code-form').on('submit', (e) => {
            e.preventDefault()

            jQuery('.verification-code').prop('disabled', true)
            onSetNewState(this, {
                isSubmitting: true
            }, () => {
                let validationResult = this.isValid()
                if (validationResult) this.verifyCode()
                else {
                    onSetNewState(this, {
                        isSubmitting: false
                    })
                    jQuery('.verification-code').prop('disabled', false)
                }
            })
        })

        const inputElements = [...document.querySelectorAll('input.verification-code')]
        inputElements.forEach((ele, index) => {
            ele.addEventListener('keyup', (e) => {
                // if the keycode is backspace & the current field is empty
                // focus the input before the current
                // then the event happens 
                // which will clear the 'before' input box
                if (e.keyCode === 8 && e.target.value === '') 
                    inputElements[Math.max(0, index-1)].focus()

                let totalEmptyFields = inputElements.length
                for (let i =0; i < inputElements.length; i++) {
                    if (typeof inputElements[i] !== 'undefined' && inputElements[i].value !== '') {                    
                        totalEmptyFields--
                    }
                }

                if (totalEmptyFields < 1) {
                    jQuery('#verify-code-form').submit()
                }
            })

            ele.addEventListener('input', (e) => {
                // take the first character of the input
                const [first,...rest] = e.target.value
                // first will be undefined when backspace was entered, so set the input to ""
                e.target.value = first ?? ''

                const lastInputBox = index === inputElements.length-1
                const insertedContent = first !== undefined
                if (insertedContent && !lastInputBox) {
                    // continue to input the rest of the string
                    inputElements[index+1].focus()
                    inputElements[index+1].value = rest.join('')
                    inputElements[index+1].dispatchEvent(new Event('input'))
                }
            })
        })

        jQuery('#code-1').select()
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            if (prevProps.emailVerification !== this.props.emailVerification) {
                const { verificationResult } = this.props.emailVerification
                if (isNotNull(verificationResult)) {
                    onSetNewState(this, {
                        isSubmitting: false
                    }, () => {
                        const { success, errorCode } = verificationResult
                        if (!success && errorCode === 'INVALID_CODE')  {
                            onSetNewState(this,{ codeIsValid: false })
                            jQuery('.verification-code').addClass('invalid-input')
                            jQuery('#code-1').select()
                        } else {
                            // to reset the form
                            this.resetVerifyCodeForm()
                            window.location.href = `https://www.profitfarmers.com/login?returnUrl=https://app.profitfarmers.com&e=${encryptText(this.state.email)}`
                        }

                        jQuery('.verification-code').prop('disabled', false)
                    })
                }
            }

            if (prevProps.resendEmailVerification !== this.props.resendEmailVerification) {
                const { loading, resendCodeResult } = this.props.resendEmailVerification
                if (isNotNull(loading)) {
                    if (loading) {
                        onSetNewState(this, {
                            isSubmitting: true
                        })
                    } else {
                        onSetNewState(this, {
                            isSubmitting: false
                        })
                    }

                    if (isNotNull(resendCodeResult) && resendCodeResult.success) {
                        this.resetVerifyCodeForm()
                    }
                }
            }
        }

        // setTimeout(() => {
        //     onSetNewState(this, {
        //         isSubmitting: false
        //     })

        //     // to reset the form
        //     this.resetVerifyCodeForm()
        // }, 3000)
    }

    // onKeyPress = (evt) => {
    //     //console.log(evt)
    //     // let ctrlDown = false,
    //     //     ctrlKey = 17,
    //     //     cmdKey = 91,
    //     //     vKey = 86,
    //     //     cKey = 67

    //     // if ((evt.ctrlKey || evt.metaKey) && evt.keyCode == vKey) {
    //     //     console.log('pasting...')
    //     //     console.log(`${evt.target.value}${evt.key}`)
    //     // }

    //     const newValue = `${evt.target.value}${evt.key}`
    //     if (!(/^\d+\d?$/).test(newValue))
    //         evt.preventDefault()
    // }

    isValid = () => {
        let code1 = jQuery('#code-1')
        let code2 = jQuery('#code-2')
        let code3 = jQuery('#code-3')
        let code4 = jQuery('#code-4')
        let code5 = jQuery('#code-5')
        let code6 = jQuery('#code-6')
        
        if (isNull(code1.val())) {            
            code1.addClass('invalid-input')
            code1.select()
            return false
        } else {
            if (code1.hasClass('invalid-input'))
                code1.removeClass('invalid-input')

            if (isNull(code2.val())) {
                code2.addClass('invalid-input')
                code2.select()
                return false
            } else {
                if (code2.hasClass('invalid-input'))
                    code2.removeClass('invalid-input')

                if (isNull(code3.val())) {                    
                    code3.addClass('invalid-input')
                    code3.select()
                    return false
                } else {
                    if (code3.hasClass('invalid-input'))
                        code3.removeClass('invalid-input')

                    if (isNull(code4.val())) {                        
                        code4.addClass('invalid-input')
                        code4.select()
                        return false
                    } else {
                        if (code4.hasClass('invalid-input'))
                            code4.removeClass('invalid-input')

                        if (isNull(code5.val())) {                            
                            code5.addClass('invalid-input')
                            code5.select()
                            return false
                        } else {
                            if (code5.hasClass('invalid-input'))
                                code5.removeClass('invalid-input')
                                
                            if (isNull(code6.val())) {                                
                                code6.addClass('invalid-input')
                                code6.select()
                                return false
                            } else {
                                if (code6.hasClass('invalid-input'))
                                    code6.removeClass('invalid-input')

                                return true
                            }
                        }
                    }
                }
            }
        }
    }

    verifyCode = () => {
        // let code1 = jQuery('#code-1').val()
        // let code2 = jQuery('#code-2').val()
        // let code3 = jQuery('#code-3').val()
        // let code4 = jQuery('#code-4').val()
        // let code5 = jQuery('#code-5').val()
        // let code6 = jQuery('#code-6').val()

        // let fullCode = code1.concat(code2, code3, code4, code5, code6)
        
        const fullCode = [...document.getElementsByTagName('input')]
            .filter(({name})=>name)
            .map(({value})=>value)
            .join('')
        if (isNotNull(fullCode)) {
            const { dispatch } = this.props
            const { email } = this.state
            dispatch(authActions.verifyCode(email, fullCode))
        }
    }

    clearVerifyCodeForm = () => {
        onSetNewState(this, {
            code: '',
            codeIsValid: null
        }, () => {
            jQuery('#code-2').val('')
            jQuery('#code-3').val('')
            jQuery('#code-4').val('')
            jQuery('#code-5').val('')
            jQuery('#code-6').val('')
            jQuery('.verification-code').removeClass('invalid-input')
            jQuery('#code-1').select()
        })
    }

    resetVerifyCodeForm = () => {
        onSetNewState(this, {
            code: '',
            codeIsValid: null
        }, () => {
            jQuery('.verification-code').val('')
            jQuery('.verification-code').removeClass('invalid-input')
            jQuery('#code-1').select()
        })
    }

    handleResendCode = (e) => {
        e.preventDefault()
        
        const { email } = this.state
        const { dispatch } = this.props
        dispatch(authActions.resendVerificationCode(email))
    }

    renderLoader = () => {        
        return (
            <>
                <div style={{ width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="pf-spinner xs" style={{ marginTop: '-48px', marginRight: '45px' }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            </> 
        )
    }

    render() {
        const { isSubmitting, email } = this.state

        return (
            <>
                <h1 className='title'><strong>Verify it's you</strong></h1>
                <p className='description'>We sent verification code to <strong>{email}</strong>. Please check your inbox and enter the code below.</p>
                
                <VerifyCodeFormWrapper>
                    <Form id="verify-code-form">
                        <Form.Group>
                            <InputGroup>
                                <Form.Control id="code-1" name="Code1" type='number' min="0" max="9" className="verification-code" autoComplete='off' />
                                <Form.Control id="code-2" name="Code2" type='number' min="0" max="9" className="verification-code" autoComplete='off' />
                                <Form.Control id="code-3" name="Code3" type='number' min="0" max="9" className="verification-code" autoComplete='off' />
                                <Form.Control id="code-4" name="Code4" type='number' min="0" max="9" className="verification-code" autoComplete='off' />
                                <Form.Control id="code-5" name="Code5" type='number' min="0" max="9" className="verification-code" autoComplete='off' />
                                <Form.Control id="code-6" name="Code6" type='number' min="0" max="9" className="verification-code" autoComplete='off' />
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </VerifyCodeFormWrapper>

                { !isSubmitting && 
                    <FormQuickLinks>
                        <p>Didn't receive an email? <TextButton onClick={(e) => this.handleResendCode(e)}>Resend code</TextButton></p>
                    </FormQuickLinks>
                }

                { isSubmitting && this.renderLoader()}
            </>
        )
    }
}

function mapStateToProps(state) {
    let { emailVerification, resendEmailVerification } = state
    return {
        emailVerification,
        resendEmailVerification
    }
}

const connectedEmailVerification = connect(mapStateToProps)(EmailVerification)
export { connectedEmailVerification as EmailVerification }