import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const IntroSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    padding: 55px 10px 40px;
    background-color: #0B1219;
    background-image: url(/images/trial-offer/introsection-bg.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;

    .container {
        .pf-logo {
            display: block;
            margin: 0 auto 10px;
            /* position: absolute;
            top: 40px;
            left: calc(50% - 110px); */
        }
        
        .tml-row {
            display: flex;
            justify-content: center;
            margin-bottom: 25px;
            
            .tml-column {
                padding: 10px 10px 5px;
                text-align: center;
                
                span {
                    font-style: italic;
                    font-size: 14px;
                }
            }
        }

        .content {
            text-align: center;
            color: #ffffff;
            width: 100%;

            h1 {
                font-weight: 800;
                font-size: 30px;
                margin: 0 auto;
                max-width: 360px;
            
                .crashed-out-price {
                    display: inline-block;
                    color: #516475;
                    position: relative;
                    width: 97px;

                    &:before {
                        position: absolute;
                        content: "";
                        left: 0;
                        top: 45%;
                        right: 0;
                        border-top: 3px solid;
                        border-color: #FF3030;
                        -webkit-transform: rotate(18deg);
                        -moz-transform: rotate(18deg);
                        -ms-transform: rotate(18deg);
                        -o-transform: rotate(18deg);
                        transform: rotate(18deg);
                    } 
                }

                .price {
                    color: #FF3030;
                }
            }

            p {
                font-size: 13px;

                .grn-txt {
                    color: #16BD70;
                    font-weight: 700;
                }

                .rd-txt {
                    color: #FF3030;
                }
            }
        }

        .video-wrapper {
            margin-bottom: 40px;

            > div {
                display: block;
                margin: 0 auto;
                height: 220px !important;
                background-color: #000;

                iframe {
                    width: 100%;
                }
            }
        }

        .trust-pilot-rating {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;

            .score {
                margin-bottom: 10px;
            }

            .tp-logo {
                display: flex;
                justify-content: center;
                align-items: center;

                .icon-logo {
                    width: 30px;
                    height: 100%;
                    margin-top: -12px;
                }
                
                .text-logo {
                    width: 100px;
                    margin-right: 10px;
                    height: 100%;
                }
                
                .rating-stars {
                    width: 100px;
                    height: 100%;
                }
            }
        }

        .featured-logo-container {
            text-align: center;
            font-weight: bold;

            .featured-logos {
                margin-top: 25px;

                .carousel {
                    .carousel-indicators {
                        bottom: -45px;

                        li {
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                        }
                    }

                .carousel-item {
                    padding: 0px 0px 20px;

                        .logo-carousel {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                                                        
                            img {
                                width: 50%;
                                padding: 0px 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.up-w-trial,
    &.up-win-trial {
        padding: 40px 10px;

        .container {
            .pf-logo,
            .tml-row {
                display: none;
            }
        }
    }

    &.up-win-trial {
        padding: 20px 10px 40px;

        .container {
            .content {
                h1 {
                    font-size: 19px;
                    font-weight: 700;
                    max-width: 100%;
                    margin: 0 auto 8px;
                }
            }
        }
    }

    ${mediaQueries.smallDevices} {
        .container {
            max-width: 600px;
            .tml-row {
                .tml-column {
                    width: unset;
                }
            }

            .content {
                h1 {
                    font-size: 50px;
                }

                p {
                    font-size: 16px;
                }
            }

            .video-wrapper {
                > div {
                    height: 360px !important;
                }
            }

            .featured-logo-container {
                .featured-logos {
                    margin-top: 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    .logo-col {
                        padding: 0 5px;

                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        .container {
            max-width: 768px;

            .content {
                h1 {
                    font-size: 45px;
                    max-width: 100%;

                    .crashed-out-price {
                        width: 112px;
                    }
                }

                p {
                    font-size: 18px;
                }
            }

            .trust-pilot-rating {
                flex-direction: row;

                .score {
                    margin-right: 5px;
                    font-size: 14px;
                }

                .tp-logo {
                    .icon-logo {
                        width: 25px;
                        margin-right: -5px;
                    }

                    .text-logo {
                        width: 90px;
                    }

                    .rating-stars {
                        width: 80px;
                    }
                }
            }
        }

        &.up-w-trial,
        &.up-win-trial {
            .container {
                .tml-row {
                    display: flex;
                }

                .content {                    
                    h1 {
                        max-width: 700px;
                    }
                }
            }
        }

        &.ret {
            .container {
                .content {
                    h1 {
                        font-size: 35px;
                    }
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        .container {
            max-width: 960px;

            .content {
                h1 {
                    max-width: 700px;
                }
            }

            .video-wrapper {
                > div {
                    width: 783px !important;
                    height: 450px !important;
                }
            }
        }

        &.up-w-trial {
            padding-top: 80px;
        }

        &.up-win-trial {
            padding-top: 20px;
        }

        &.up-w-trial,
        &.up-win-trial {
            .container {
                .content {
                    h1 {
                        max-width: 700px;
                    }

                    &.long-txt {
                        h1 {
                            font-size: 35px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        .container {
            max-width: 1140px;

            .content {
                h1 {
                    font-size: 65px;
                    max-width: 100%;

                    .crashed-out-price {
                        width: 160px;
                    }
                }

                p {
                    padding: 0 100px;
                }
            }

            .featured-logo-container {
                .featured-logos {
                    .logo-col {
                        padding: 0 12px;
                    }
                }
            }
        }

        &.up-w-trial,
        &.up-win-trial {
            .container {
                .content {
                    h1 {
                        max-width: 100%;
                    }

                    &.long-txt {
                        h1 {
                            font-size: 48px;
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.superExtraLargeDevices} {
        .container {
            max-width: 1300px;

            .content {
                h1 {
                    padding: 0 25px;
                }

                p {
                    padding: 0 200px;
                }
            }

            .featured-logo-container {
                .featured-logos{
                    .logo-col {
                        padding: 0 15px;
                    }
                }
            }
        }

        &.up-w-trial,
        &.up-win-trial {
            .container {
                .content {
                    h1 {
                        max-width: 1200px;
                    }
                }
            }
        }
    }
`