import styled from 'styled-components'

export const SignalListStyle = styled.div`
    position: relative;
    display: grid;
    height: auto;
    width: auto;
    min-width: auto;
    grid-template-columns: 378px 378px 378px;
    grid-template-rows: auto;
    grid-gap: 20px;
    box-sizing: border-box;
    padding: 0;
    overflow: hidden;

    @media screen and (min-width: 320px) and (max-width: 766px) {
        display: block;

        &.slider {
            &.loader {
                .skeleton {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 25px;
                }
            }
        }
    }

    @media screen and (min-width: 767px) and (max-width: 1023px) {
        grid-template-columns: 330px 330px;
        justify-content: center;

        &.slider {
            &.loader {
                grid-template-columns: 330px 330px;

                .skeleton {
                    width: 330px;
                    margin-right: 0;
                }
            }
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        grid-template-columns: 370px 370px;
        justify-content: center;

        &.slider {
            &.loader {
                grid-template-columns: 370px 370px;

                .skeleton {
                    width: 370px;
                    margin-right: 0;
                }
            }
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        grid-template-columns: 348px 348px;
		justify-content: center;

		&.slider {
			&.loader {
				grid-template-columns: 348px 348px;

				.skeleton {
					width: 348px;
					margin-right: 0
				}
			}
		}
    }

    @media screen and (min-width: 1441px) and (max-width: 1499px) {
        grid-template-columns: 352px 352px;
        grid-gap: 12px;

         &.slider {
           &.loader {
                 grid-template-columns: 352px 352px;

               .skeleton {
                   width: 352px;
                   margin-right: 0
               }
           }
         }
   }

    @media screen and (min-width: 1500px) and (max-width: 1551px) {
        grid-template-columns: 316px 316px 316px;
        grid-gap: 15px;

        &.slider {
            &.loader {
                grid-template-columns: 316px 316px 316px;

                .skeleton {
                    width: 316px;
                    margin-right: 0
                }
            }
        }
    }

   @media screen and (min-width: 1552px) and (max-width: 1679px) {
       grid-template-columns: 332px 332px 332px;
       grid-gap: 15px;

         &.slider {
           &.loader {
                 grid-template-columns: 332px 332px 332px;

               .skeleton {
                   width: 332px;
                   margin-right: 0
               }
           }
         }
   }

    @media screen and (min-width: 1680px) and (max-width: 1749px) {
        grid-template-columns: 340px 340px 340px;

        &.slider {
            &.loader {
                grid-template-columns: 340px 340px 340px;

                .skeleton {
                    width: 340px;
                    margin-right: 0
                }
            }
        }
    }

    @media only screen and (min-width: 1750px) {
        grid-template-columns: 380px 380px 380px;

        &.slider {
            &.loader {
                grid-template-columns: 380px 380px 380px;

                .skeleton {
                    width: 380px;
                    margin-right: 0
                }
            }
        }
    }

    .skeleton {
        margin-right: 20px;
        width: 378px;
        height: 380px;
        float: left;
        background-image: linear-gradient(15deg,#334156,#385065);
        border: 1px solid #27313f;
        border-radius: 4px;
        box-hadow: 0px 3px 3px 2px rgba(0,0,0,0.08);
    }
`