import styled from 'styled-components'
import { mediaQueries } from '../../constants/mediaQueries'

const getBg = (variant) => {
    switch(variant) {
        default:
            return '#14293D'
        case 'warning': {
            return '#000'
        }
        case 'danger': {
            return '#292330'
        }
        case 'success': {
            return '#1A3631'
        }
    }
}

const getTextColor = (variant) => {
    switch(variant) {
        default:
            return '#247DBF'
        case 'warning': {
            return '#ffeeba'
        }
        case 'danger': {
            return '#B93F5F'
        }
        case 'success': {
            return '#13B079'
        }
    }
}

export const AlertMsg = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 8px 25px;
    background-color: ${props => getBg(props.variant)};
    color: ${props => getTextColor(props.variant)};
    font-size: 14px;
    z-index: 100;
    text-align: center;
    height: 79px;

    span {
        width: 100%;
        letter-spacing: 1px;
    }
 
    ${mediaQueries.mediumDevices} {
        flex-direction: row;
        height: 36px;
        padding: 8px 10px;
        
        span {
            width: auto;
            font-size: 12px;
            letter-spacing: unset;

            &:first-child {
                margin-right: 4px;
            }
        }
    }

    ${mediaQueries.largeDevices} {
        span {
            font-size: 13px;
            letter-spacing: 1px;
        }
    }

    ${mediaQueries.extraLargeDevices} {
        span {
            font-size: 14px;
        }
    }
`