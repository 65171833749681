import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { userServices } from '../../services'
import { userActions } from '../../redux/actions'
import { userActionTypes } from '../../redux/CONSTANTS'
import { GetStartedView } from './GetStartedView'
import { isNotNull, toastSuccess, toastWarning, addPageViewEvent, seo } from '../../utils'

class GetStartedContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            redirect_target: 0,
            remaining_seconds: 5,
            email: null,
            isSending: false,
            sendingLoaderLock: true,
        }
    }

    componentDidMount() {
        // remove trailing question mark
        const sanitizedResult = this.props.location.search.replace('?', '')
        const result = queryString.parse(sanitizedResult)
        const { dispatch } = this.props
        const { email } = result
        this.setState({ email: email })

        userServices.logout().then(() => dispatch({ type: userActionTypes.LOGOUT, user: null }))

        seo({
            title: "Verify Your Email",
            metaDescription: ""
        })
        addPageViewEvent()
    }

    componentDidUpdate() {
        const { responseData } = this.props.user
        if (isNotNull(responseData)) {
            const { msg, success } = responseData
            const { sendingLoaderLock } = this.state

            if (isNotNull(success)) {
                if (success) {
                    if (isNotNull(msg))
                        toastSuccess('Confirmation link sent!')
                }
                else {
                    if (isNotNull(msg))
                        toastWarning(msg)
                }

                if (!sendingLoaderLock)
                    this.setState({ isSending: false, sendingLoaderLock: true })
            }
        }
    }

    goToOnboarding = () => window.location.href = '/payment'

    redirectToOnboarding() {
        setTimeout(() => {
            let { remaining_seconds, redirect_target } = this.state;

            if (remaining_seconds !== redirect_target) {
                remaining_seconds--;
                this.setState({ remaining_seconds });
            }
            else
                this._goToOnboarding();

        }, 1000);
    }

    onResendConfirmationEmailHandler = value => {
        const { dispatch } = this.props;

        if (isNotNull(value)) {
            this.setState({ isSending: true, sendingLoaderLock: false })

            dispatch(userActions.resendVerification(value))
        }
    }

    render() {
        return (
            <GetStartedView
                self={this}
                onResendConfirmationEmailHandler={this.onResendConfirmationEmailHandler}
                state={this.state}
                {...this.props}
            />
        )
    }
}


function mapStateToProps(state) {
    let { user } = state;
    return {
        user,
    }
}

const connectedGetStartedContainer = connect(mapStateToProps)(GetStartedContainer);
export { connectedGetStartedContainer as GetStartedContainer };
