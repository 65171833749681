import React from 'react'
import { connect } from 'react-redux'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment'
import Moment from 'react-moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as faStarFull } from "@fortawesome/free-solid-svg-icons"
import { faStar, faClock } from "@fortawesome/free-regular-svg-icons"
import { Line } from "react-chartjs-2"
import {
    SignalStyle, SignalHeader,
    SignalHeaderContent, SignalInstructions, FollowSignalBtn
} from '../../styled_components'
import { CoinPairLogo } from '../../../../styles'
import {
    isNotNull,
    setCoinLogo, setDefaultImage,
    getFromSym,
    toBtcFormat, toUsdFormat, 
    Dimension,
    isNull
} from '../../../../utils'
import { signalCloseState } from '../../../../utils/CONSTANTS'
class ClosedSignal extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            id: '',
            symbol: '',
            strategy: '',
            direction: null,
            lastPrice: 0.00,
            entryStartPrice: 0.00,
            entryEndPrice: 0.00,
            stopLossPrice: 0.00,
            isWatchlisted: false,
            tradeResult: null,
            datePublished: null,
            dateClosed: null,
            tradeClosedAt: null,
            lastTargetHitPrice: null,
            closedPriceAt: null,
            isOrdered: false,
            duration: null,
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [
                        {
                            display: false,
                            ticks: {
                                beginAtZero: false,
                                display: false
                            },
                            gridLines: {
                                display: false
                            }
                        }
                    ],

                    xAxes: [
                        {
                            display: false,
                            ticks: {
                                display: false
                            },
                            gridLines: {
                                display: false
                            }
                        }
                    ]
                },
                legend: {
                    display: false
                },
                elements: {
                    point: {
                        radius: 0
                    }
                }
            }
        }

        this.chartReference = null
    }

    componentDidMount() {
        window.addEventListener('resize', () => Dimension.updateDimensions(this))
        let { id, lastPrice, symbol, toSym, strategy, direction,
            entryStartPrice, entryEndPrice, stopLossPrice,
            leverage, datePublished, dateClosed, isOrdered, closedPriceAt, lastTargetHitPrice, isWatchlisted } = this.props.data
        let fromSym = getFromSym(symbol, toSym)

        // initialize entry zone price format
        // try to reformat prices as USD/USDT
        if (fromSym === 'USDT') {
            if (parseFloat(entryStartPrice) < 1 && parseFloat(entryEndPrice) < 1) {
                entryStartPrice = toBtcFormat(entryStartPrice)
                entryEndPrice = toBtcFormat(entryEndPrice)
            } else {
                entryStartPrice = toUsdFormat(entryStartPrice)
                entryEndPrice = toUsdFormat(entryEndPrice)
            }
        } else {
            entryStartPrice = toBtcFormat(entryStartPrice)
            entryEndPrice = toBtcFormat(entryEndPrice)
        }

        // let newEntryPriceFormat = priceFormat(entryStartPrice, entryEndPrice, symbol)
        // if (isNotNull(newEntryPriceFormat)) {
        //     if (isNotNull(newEntryPriceFormat.startPrice))
        //         entryStartPrice = newEntryPriceFormat.startPrice

        //     if (isNotNull(newEntryPriceFormat.endPrice))
        //         entryEndPrice = newEntryPriceFormat.endPrice
        // }

        let duration = null
        let tradeClosedAt = null
        if (isNotNull(datePublished) && isNotNull(dateClosed)) {
            tradeClosedAt = moment.utc(moment(new Date(dateClosed)).format('YYYY-MM-DD HH:mm:ss')).format('DD/MM @ LT')

            let dateStarted = moment.utc(moment(new Date(datePublished)).format('YYYY-MM-DD HH:mm:ss'))
            let dateEnded = moment.utc(moment(new Date(dateClosed)).format('YYYY-MM-DD HH:mm:ss'))
            duration = moment.duration(moment(dateEnded).diff(dateStarted)).humanize()
        }

        this.setState({
            id: id,
            symbol: symbol,
            strategy: strategy,
            direction: direction,
            lastPrice: lastPrice,
            entryStartPrice: entryStartPrice,
            entryEndPrice: entryEndPrice,
            stopLossPrice: stopLossPrice,
            leverage: leverage,
            isOrdered: isOrdered,
            datePublished: datePublished,
            dateClosed,
            tradeClosedAt: tradeClosedAt,
            closedPriceAt: closedPriceAt,
            lastTargetHitPrice: lastTargetHitPrice,
            duration: duration,
            isWatchlisted
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isWatchlisted !== this.props.isWatchlisted) {
            this.setState({
                isWatchlisted: this.props.isWatchlisted
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => Dimension.updateDimensions(this))
    }

    onFollowSignal(e, data) {
        e.stopPropagation()
        this.props.onFollowSignalHandler(data.id)
        this.setState({
            isWatchlisted: !this.state.isWatchlisted
        })
    }

    onSelect = (e, data) => {
        e.stopPropagation()
        this.props.onSelectHandler(data)
    }

    renderWatchTooltip(id, isFollowed) {
        if (isFollowed) {
            return (
                <Tooltip id={`tooltip-${id}`}>
                    Remove from watchlist
                </Tooltip>
            );
        } else {
            return (
                <Tooltip id={`tooltip-${id}`}>
                    Add to watchlist
                </Tooltip>
            );
        }
    }

    renderProfitsChartData = (canvas) => {
        const ctx = canvas.getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
        gradient.addColorStop(0, 'rgb(53, 197, 135, 1)');
        gradient.addColorStop(1, 'rgb(53, 197, 135, 0)');

        return {
            labels: ['S', 'M', 'T', 'W', 'Th', 'F', 'S', 'M', 'T', 'W', 'Th', 'F', 'S'],
            datasets: [
                {
                    fill: true,
                    backgroundColor: gradient, // Put the gradient here as a fill color
                    borderColor: '#149158',
                    data: [10, 35, 20, 50, 40, 80, 60, 50, 70, 105, 180, 100, 250],
                    lineTension: 0.2,
                }
            ]
        }
    }

    renderLossChartData = (canvas) => {
        const ctx = canvas.getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
        gradient.addColorStop(0, 'rgb(120, 29, 55, 1)');
        gradient.addColorStop(1, 'rgb(120, 29, 55, 0)');

        return {
            labels: ['S', 'M', 'T', 'W', 'Th', 'F', 'S', 'M', 'T', 'W', 'Th', 'F', 'S'],
            datasets: [
                {
                    fill: true,
                    backgroundColor: gradient, // Put the gradient here as a fill color
                    borderColor: '#64162e',
                    data: [20, 99, 60, 79, 32, 40, 55, 40, 20, 14, 8, 2, 0],
                    lineTension: 0.2,
                }
            ]
        }
    }

    renderBreakevenChartData = (canvas) => {
        const ctx = canvas.getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
        gradient.addColorStop(0, 'rgb(89, 104, 112, 1)');
        gradient.addColorStop(1, 'rgb(89, 104, 112, 0)');

        return {
            labels: ['S', 'M', 'T', 'W', 'Th', 'F', 'S', 'M', 'T', 'W', 'Th', 'F', 'S'],
            datasets: [
                {
                    fill: true,
                    backgroundColor: gradient, // Put the gradient here as a fill color
                    borderColor: '#596870',
                    data: [8, 20, 80, 35, 50, 20, 8, 8, 8, 8, 8, 8, 8],
                    lineTension: 0.2,
                }
            ]
        }
    }

    renderTradeProgress = (data) => {
        const { tradeResult } = data
        let progress = null
        if (isNotNull(tradeResult) && isNotNull(tradeResult.item1)) {
            switch (tradeResult.item1) {
                case "profits":
                    progress = (
                        <>
                            <span>{`+${tradeResult.item3}`}</span>
                            <small className="duration">{tradeResult.item4}</small>
                        </>
                    )
                    break;
                case "loss":
                    progress = (
                        <>
                            <span>{`${tradeResult.item3}`}</span>
                            <small className="duration">{tradeResult.item4}</small>
                        </>
                    )
                    break;
                default:
                    progress = (
                        <>
                            <span></span>
                            <small className="duration"></small>
                        </>
                    )
                    break;
            }
        }

        const selectedClass = isNotNull(tradeResult) && isNotNull(tradeResult.item1)
            ? tradeResult.item1 === 'profits' ? 'positive' : 'negative'
            : "breakeven"

        return (
            <h3 className={`progress-value ${selectedClass}`}>
                {progress}
            </h3>
        )
    }

    render() {
        let { data } = this.props
        let { toSym, tradeResult, closeState, isActive } = data
        let { id, symbol, strategy, direction,
            entryStartPrice, entryEndPrice, stopLossPrice,
            lastTargetHitPrice, isOrdered,
            isWatchlisted, chartOptions, duration, dateClosed, closedPriceAt } = this.state

        let coinLogo = null
        if (isNotNull(toSym))
            coinLogo = setCoinLogo(toSym)

        // if (!isNotNull(tradeResult))
        //     return null

        if (isActive && closeState === signalCloseState.PENDING)
            return null

        if (isNull(tradeResult) && closeState === signalCloseState.AUTO_CLOSE)
            return null

        const manualClosedClass = isNotNull(tradeResult) && isNotNull(tradeResult.item1) ? tradeResult.item1 : 'breakeven'

        return (
            <SignalStyle
                className={`hoverable trade-closed theme-signal-wrapper ${manualClosedClass}`}
                type={manualClosedClass}>

                <div className="relative" onClick={(e) => this.onSelect(e, data)}>
                    <SignalHeader className={`closed-trade ${manualClosedClass}`}
                        direction={direction}>

                        <SignalHeaderContent>
                            <div className="coin-contents">
                                <CoinPairLogo
                                    src={coinLogo}
                                    className={toSym}
                                    icon={toSym}
                                    onError={setDefaultImage} />

                                <div className="coinpair-info">
                                    <h3 className="coinpair">
                                        <span>{symbol}</span>
                                        <small className="last-price">{strategy}</small>
                                    </h3>
                                </div>
                            </div>

                            <div className="direction-wrapper">
                                <ul className="trade-tags">
                                    <li>
                                        <span className="direction"
                                            type={manualClosedClass}>{direction}</span>
                                    </li>
                                </ul>
                            </div>
                        </SignalHeaderContent>
                    </SignalHeader>

                    <div className="closed-tag">
                        <span>{closeState !== signalCloseState.MANUAL_CLOSE || (closeState === signalCloseState.MANUAL_CLOSE && isNotNull(tradeResult)) ? 'Trade Closed' : 'Trade Closed Early' }</span>
                    </div>

                    <div className={`trade-progress ${manualClosedClass}`}>
                        <div className="trade-progress-value">
                            {this.renderTradeProgress(data)}
                        </div>
                    </div>

                    <div className="graph">
                        <Line
                            ref={reference => (this.chartReference = reference)}
                            data={isNotNull(tradeResult) && isNotNull(tradeResult.item1)
                                ? tradeResult.item1 === 'profits'
                                    ? this.renderProfitsChartData
                                    : this.renderLossChartData
                                : this.renderBreakevenChartData}
                            options={chartOptions}
                            height={50} />
                    </div>

                    <SignalInstructions className="closed-trade"
                        direction={direction}
                        type={manualClosedClass}>

                        <div className="price-range">
                            <label className="entry-label">
                                Entry
                                </label>

                            <div className="target-value">
                                <span><span className={`${entryStartPrice.length > 8 ? 'long-text' : ''}`}>{entryStartPrice} - {entryEndPrice}</span></span>
                            </div>
                        </div>

                        <div className="price-range">
                            {isNotNull(tradeResult) && isNotNull(tradeResult.item1) && tradeResult.item1 === 'profits' &&
                                <>
                                    {isNotNull(lastTargetHitPrice) ?
                                        <>
                                            <label className="target-label">
                                                <span className="target-text last-target-hit">Last Target Hit</span>
                                            </label>
                                            <div className="target-value">
                                                <span>{lastTargetHitPrice}</span>
                                            </div>
                                        </> :
                                        <>
                                            <label className="target-label">
                                                <span className="target-text price-closed">Price Closed</span>
                                            </label>
                                            <div className="target-value">
                                                <span>{closedPriceAt}</span>
                                            </div>
                                        </>
                                    }
                                </>
                            }

                            {isNull(tradeResult) && closeState === signalCloseState.MANUAL_CLOSE &&
                                <>
                                    {isNotNull(lastTargetHitPrice) ?
                                        <>
                                            <label className="target-label">
                                                <span className="target-text last-target-hit">Last Target Hit</span>
                                            </label>
                                            <div className="target-value">
                                                <span>{lastTargetHitPrice}</span>
                                            </div>
                                        </> :
                                        <>
                                            <label className="target-label">
                                                <span className="target-text price-closed">Price Closed</span>
                                            </label>
                                            <div className="target-value">
                                                <span>{closedPriceAt}</span>
                                            </div>
                                        </>
                                    }
                                </>
                            }

                            {isNotNull(tradeResult) && isNotNull(tradeResult.item1) && tradeResult.item1 === 'loss' &&
                                <>
                                    <label className="target-label">
                                        <span className="target-text">Stop Loss</span>
                                    </label>
                                    <div className="target-value">
                                        <span>{stopLossPrice}</span>
                                    </div>
                                </>
                            }
                        </div>

                        <div className="price-range">
                            <label className="entry-label">Duration</label>
                            <div className="last-price-value">
                                <span className="last-price">{duration}</span>
                            </div>
                        </div>
                    </SignalInstructions>
                </div>

                <div className={`trade-actions ${manualClosedClass}`}>
                    <div className="ts">
                        <span><FontAwesomeIcon icon={faClock} /> Trade closed:</span>
                        <Moment format="MMM D, YYYY @ HH:mm" utc local>{dateClosed}</Moment>
                    </div>
                    <div className="watchlist-actions">
                        <div className="isTraded-wrapper">
                            {isOrdered &&
                                <img src="/images/icon-traded.png" className="img-fluid" alt="trade icon" />
                            }
                        </div>

                        <OverlayTrigger
                            placement={window.innerWidth < 766 ? "left" : "right"}
                            delay={{ show: 150, hide: 400 }}
                            overlay={this.renderWatchTooltip(id, isWatchlisted)}>
                            <FollowSignalBtn onClick={(e) => this.onFollowSignal(e, data)} isWatchlisted={isWatchlisted}>
                                {
                                    <FontAwesomeIcon icon={isWatchlisted ? faStarFull : faStar} />
                                }
                            </FollowSignalBtn>
                        </OverlayTrigger>
                    </div>
                </div>
            </SignalStyle>
        )
    }
}

function mapStateToProps(state) {
    const { signals } = state
    return {
        signals
    }
}

const connectedClosedSignal = connect(mapStateToProps)(ClosedSignal)
export { connectedClosedSignal as ClosedSignal }