import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const MoreUserInfoStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 35px 20px 20px;

    .info-form-wrapper {
        h1 {
            text-align: center;
            font-size: 21px;
            font-weight: 600;
            color: #0D141E;
        }

        p {
            text-align: center;
            font-size: 14px;
            color: #515F6E;

            svg,
            small {
                color: #38557a;
            }

            &.sub-text {
                font-size: 14px;
                max-width: 100%;
                margin: 0 auto 25px;
            }
        }

        .user-info-form {
            width: 100%;
            margin: 0 auto;

            .required {
                color: #993999;
                font-size: 14px;
            }

            .form-group {
                .input-wrapper {
                    &.is-invalid {
                        .form-control {
                            border-color: #993956;
                        }
                    }

                    .form-text {
                        color: #df5481;
                        font-size: 14px;
                    }
                }
            }

            label {
                color: #223346;
            }
        }

        .user-info-form-actions {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-top: 25px;

            button {
                display: inline-block;
                border: none;
                outline: none;
                margin-bottom: 5px;
                
                &:last-child {
                    margin-bottom: 0;
                }
            }

            .submit-keys-btn {
                background: #1273EB 0% 0% no-repeat padding-box;
                color: #fff;
                font-size: 18px;
                font-weight: 600;
                border-radius: 32px;
                padding: 13px 30px;
                width: auto;
                height: 54px;
                transition: all 0.5s ease 0s;

                &:hover {
                    transform: scale(1.05);
                    backface-visibility: hidden;
                    overflow: auto;
                }
            }

            .skip-btn {
                font-size: 15px;
                font-weight: 500;
                background: transparent 0% 0% no-repeat padding-box;
                padding: 8px 20px;
                height: auto;
                color: #4e74a3;
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        .info-form-wrapper {
            h1 {
                font-size: 32px;
            }

            p {
                &.sub-text {
                    max-width: 460px;
                }
            }

            .user-info-form {
                width: 567px;
            }
        }
    }

    ${mediaQueries.largeDevices} {

    }
`