import React, { useEffect } from 'react'
import { Brand, Layout } from '../../../styles'
import { FormContainer } from '../../../components/Auth'

export const ResetPasswordConfirmationView = props => {
    useEffect(() => {
        document.body.classList.add('authentication')
    }, [])

    return (
        <Layout>
            <FormContainer>                
                <Brand imageUrl={`/images/brand/pf-logo.png`} />

                <h1 className='title'>
                    <strong>Password has been reset successfully!</strong>
                </h1>

                <p className='description'>You will be redirected to to login page shortly.</p>
            </FormContainer>
        </Layout>
    )
}