import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const PackageBreakdownSectionStyle = styled.section`    
    position: relative;
    display: block;
    width: 100%;
    background-color: transparent;
    padding: 40px 0;

    .container {
        .em {
            color: #15bd6f;
        }

        .package-breakdown-wrapper {
            .package-breakdown {
                margin: 0 auto 25px;
                padding: 0;
                list-style-type: none;
                max-width: 870px;

                .package-breakdown-item {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 25px 0;

                    .thumbnail {
                        position: unset;
                        width: 230px;
                        margin-bottom: 25px;

                        img {
                            width: 230px;
                        }
                    }

                    .description {
                        margin-left: 0;
                        width: 100%;

                        h6 {
                            text-align: center;
                            color: #fff;
                            font-size: 16px;
                            font-weight: 300;
                        }

                        p {
                            text-align: center;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .price-tag-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .img-wrapper {
                width: 100%;

                img {
                    width: 100%;
                    margin: 0 auto;
                }
            }

            .text-col {
                width: 100%;

                .original-price {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    line-height: 1;

                    .crashed-out-price {
                        color: #516475;
                        position: relative;
                        font-size: 60px;
                        font-weight: 700;

                        &:before {
                            position: absolute;
                            content: "";
                            left: 0;
                            top: 45%;
                            right: 0;
                            border-top: 3px solid;
                            border-color: #FF3030;
                            -webkit-transform: rotate(-18deg);
                            -moz-transform: rotate(-18deg);
                            -ms-transform: rotate(-18deg);
                            -o-transform: rotate(-18deg);
                            transform: rotate(-18deg);
                        } 
                    }

                    > label {
                        width: 80px;
                        color: #fff;
                        font-size: 24px;
                        font-weight: 600;
                        margin-left: 20px;
                        margin-bottom: 0;
                    }
                }

                .new-price {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;

                    .new-price-value {
                        color: #516475;
                        font-size: 48px;
                        font-weight: 600;
                    }

                    .new-price-desc {
                        color: #fff;
                        margin-left: 10px;
                    }
                }

                h4 {
                    color: #fff;
                    font-size: 21px;
                    font-weight: 400;
                }

                p {
                    color: #fff;
                    font-size: 15px;
                }

                .discounted-price {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    line-height: 1;
                    margin-bottom: 13px;

                    .discounted-price-value {
                        color: #fff;
                        font-size: 52px;
                        font-weight: 600;
                        margin-right: 10px;
                    }
                }

                .impt {
                    background-color: #FE2944;
                    padding: 10px 12px;
                    color: #FFFFFF;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        .container {
            .package-breakdown-wrapper {
                .package-breakdown {                    
                    margin: 0 auto 60px;
                }
            }

            .price-tag-wrapper {
                flex-direction: row;

                .img-wrapper {                    
                    width: calc(100% - 434px);

                    img {
                        width: 95%;
                    }
                }

                .text-col {                    
                    width: 434px;

                    .original-price {
                        justify-content: flex-start;

                        .crashed-out-price {
                            font-size: 76px;
                        }
                    }

                    .discounted-price {
                        font-size: 64px;

                        .discounted-price-value {
                            &.six {
                                font-size: 80px;
                            }
                        }
                    }

                    .impt {
                        font-size: 16px;

                        &.six {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        .container {
            .package-breakdown-wrapper {
                .package-breakdown {
                    .package-breakdown-item {
                        flex-direction: row;

                        .thumbnail {
                            margin-bottom: 0;
                        }

                        .description {
                            margin-left: 25px;
                            width: calc(100% - 230px);

                            h6 {
                                text-align: left;
                                font-size: 22px;
                                font-weight: 500;
                            }

                            p {
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {

    }
`