import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const ThePathWellTroddenSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    padding: 60px 0;
    background-color: #060B11;

    .container {
        text-align: center;
        color: #fff;       

        .em {
            color: #15bd6f;
            font-weight: 500;
        }

        .hl-red {
            color: #FF3030;
        }

        .section-title {
            color: #fff;
            font-size: 36px;
            font-weight: 600;
            max-width: 700px;
            margin: 0 auto 25px;
        }

        p {
            margin: 0 auto 25px;
            max-width: 820px;
        }
        
        h3 {
            font-weight: 600;
        }

        .carousel {
            width: 100%;
            margin: 0 auto 75px;

            .carousel-inner {
                .carousel-item {
                    width: 100%;
                    min-height: 300px;
                    background-image: url(/images/layout/carousel-feedback-bg.png);
                    background-repeat: no-repeat;
                    background-size: cover;
                    border: 1px solid rgba(255, 255, 255, 0.2);
                    border-radius: 10px;

                    .text-with-img {
                        display: flex;
                        align-items: center;

                        .img {
                            width: 340px;

                            img {
                                width: 100%;
                            }
                        }

                        .text {
                            width: calc(100% - 340px);
                            text-align: left;
                            padding: 15px 20px;

                            p {
                                margin-bottom: 0;

                                &.trade-history-img {
                                    display: block;
                                    margin-top: 10px;

                                    img {
                                        height: 100px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .carousel-control-prev,
            .carousel-control-next {
                display: none;
            }

            .carousel-indicators {
                bottom: -50px;

                li {
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #48596D;

                    &.active {
                        background-color: #16BD70;
                    }
                }
            }
        }

        .trustpilot-comments-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            .luka,
            .hans {
                width: 100%;
            }

            .luka,
            .hans {
                h6 {
                    height: 30px;
                    margin-bottom: 25px;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {

    }

    ${mediaQueries.largeDevices} {
        .container {
            .carousel {
                width: 800px;
            }

            .trustpilot-comments-wrapper {
                flex-direction: row;

                .luka {
                    width: 42%;
                }

                .hans {
                    width: 58%;
                }

                .luka,
                .hans {
                    img {
                        width: auto;
                        height: 320px;
                    }
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {

    }
`