import { routes } from '../navigation/CONSTANTS'
import { baseUrl, endpoints } from './CONSTANTS'
import { isNotNull } from '../utils'

const { v3, auth, account } = endpoints

function login(data) {
    const requestOpts = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(data)
    }
    
    return fetch(`${baseUrl}${v3.auth.login}`, requestOpts)
        .then(handleResponse)
        .then(res => {
            localStorage.setItem("user.authorized", res.success)
            return Promise.resolve(res)
        })
        .catch(error => {
            return Promise.reject({ error })
        })
}

function autoLoginByToken(accessToken) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(accessToken)
    }

    return fetch(`${baseUrl}${v3.auth.autoLogin}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            localStorage.setItem("user.authorized", res.success)
            return Promise.resolve(res)                
        })
        .catch(err => {
            return Promise.reject({ err })
        })
}

function verifyCode(userEmail, code) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }

    return fetch(`${baseUrl}${v3.auth.confirmEmailByCode(userEmail, code)}`, requestOptions)
        .then(handleResponse)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error))
}

function resendVerificationCode(userEmail) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    }

    return fetch(`${baseUrl}${v3.auth.resendVerificationCode(userEmail)}`, requestOptions)
        .then(handleResponse)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error))
}

function logout() {
    // clear out localStorage
    localStorage.removeItem('profitfarmers.token')
    localStorage.removeItem('profitfarmers.user')
    localStorage.removeItem('profitfarmers.type')
    localStorage.removeItem('profitfarmers.sessionexpired')
    localStorage.removeItem('emailError')
    localStorage.removeItem('user.authorized')

    const requestOpts = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include'
    }
    
    return fetch(`${baseUrl}${v3.auth.logout}`, requestOpts)
        .then(() => {
            window.location.href = routes.authentication.login
        }).catch(error => {
            return Promise.reject({ error })
        })
}

function refresh() {
    const accessToken = JSON.parse(localStorage.getItem("profitfarmers.token"))
    if (isNotNull(accessToken) && isNotNull(accessToken.access)) {
        const requestOpts = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token: accessToken.access.token })
        }
        return fetch(`${baseUrl}${auth.refreshToken}`, requestOpts)
            .then(handleResponse)
            .then(res => {
                if (isNotNull(res) && isNotNull(res.access) && isNotNull(res.access.token)) {

                    res.access.expires_at = parseInt(res.access.expires_at)

                    const now = Date.now()
                    let expirationDate = parseInt(now + ((res.access.expires_at - 180) * 1000))
                    res.access.expires_at = expirationDate

                    //store the token to local storage
                    localStorage.setItem('profitfarmers.token', JSON.stringify(res))

                    return Promise.resolve({ status: 'ok' })
                } else
                    return Promise.reject('An error occured.')
            }).catch(error => {
                return Promise.reject('An error occured.')
            })
    }
    else
        return Promise.reject('An error occured.')
}

function loginFromMobile(access_token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${access_token}` }
    }

    return fetch(`${baseUrl}${account.externalLogin}`, requestOptions)
        .then(handleResponse)
        .then(r => {
            console.log(r)
            let { access, user } = r
            if (isNotNull(access) && isNotNull(access.token)) {
                let token = {
                    access: {
                        token: access.token,
                        expires_at: parseInt(Date.now() + ((access.expires_at - 180) * 1000))
                    }
                }
                if (user) {
                    localStorage.setItem('profitfarmers.token', JSON.stringify(token))
                    localStorage.setItem('profitfarmers.user', JSON.stringify(user))
                    return Promise.resolve(user)
                } else
                    return Promise.reject({
                        status: 'not ok'
                    })
            } else
                return Promise.reject({
                    status: 'not ok'
                })
        }).catch(error => {
            return Promise.reject(error)
        })
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        if (res.status === 401) {
            logout()
        }
        else
            return data
    })
}

export const authServices = {
    login,
    autoLoginByToken,
    verifyCode,
    resendVerificationCode,
    logout,
    refresh,
    loginFromMobile
}
