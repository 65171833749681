import React from 'react'
import { connect } from 'react-redux'
import { faEye } from "@fortawesome/free-solid-svg-icons"
import newFormDecorator from 'final-form-focus'
import iziToast from 'izitoast'
import { userActions } from '../../../../redux/actions'
import { SecurityView } from './SecurityView'
import { isNotNull, addPageViewEvent, seo } from '../../../../utils'

class SecurityContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSecurityEdit: false,
            oldPassword: {
                type: "password",
                eyeIcon: faEye
            },
            newPassword: {
                type: "password",
                eyeIcon: faEye
            },
            confirmPassword: {
                type: "password",
                eyeIcon: faEye
            }
        }
        this.initialPasswordHelperState = {
            letter: false,
            capital: false,
            number: false,
            special: false,
            size: false,
            match: false
        }
        this.formDecorator = newFormDecorator()
    }

    componentDidMount() {
        seo({
            title: "Account Security Settings",
            metaDescription: ""
        })
        addPageViewEvent()
    }

    componentDidUpdate(prevProps) {
        const { forms } = this.props
        if (isNotNull(forms) && prevProps.forms !== forms && !forms.loading) {
            const { response, error } = forms

            if (isNotNull(response)) {
                if (response === 'New password set') {
                    this.setState({ isSecurityEdit: false })
                    iziToast.success({
                        position: 'topRight',
                        title: 'Success',
                        message: 'Your password has been successfully changed',
                        displayMode: 1,
                    })
                }
            } else {
                if (isNotNull(error)) {
                    if (error === 'Invalid default password') {
                        iziToast.error({
                            position: 'topRight',
                            title: 'Error',
                            message: "Invalid current password",
                            displayMode: 1,
                        })
                    } else {
                        iziToast.error({
                            position: 'topRight',
                            title: 'Error',
                            message: error,
                            displayMode: 1,
                        })
                    }                    
                }
            }
        }
    }

    onChangePasswordHandler = (data) => {
        const { dispatch } = this.props
        dispatch(userActions.changePassword(data))
    }

    render() {
        return (
            <SecurityView
                self={this}
                state={this.state}
                decorators={this.formDecorator}
                onChangePassword={this.onChangePasswordHandler}
                initialPasswordHelperState={this.initialPasswordHelperState}
                {...this.props}
            />
        )
    }
}

function mapStateToProps(state) {
    const { forms } = state
    return {
        forms
    }
}
const connectedSecurityContainer = connect(mapStateToProps)(SecurityContainer)
export { connectedSecurityContainer as SecurityContainer }