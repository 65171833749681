import styled from 'styled-components'
import { device } from '../../../styles/CONSTANTS'

export const Main = styled.main`
    position: relative;
    font-family: 'Montserrat', sans-serif;

    .card {
        width: 85%;
        box-shadow: 0 0 20px #cacacab5;
        border: none;
        /* overflow: hidden; */
        margin-bottom: 25px;

        @media screen and (min-width: 320px) and (max-width: 766px) {
            width: 97%;
        }

        .auth-row {
            margin: 0;
            width: 100%;

            .auth-col {
                padding: 0;
            }
        }

        &.mobile-aside {
            display: block;

            @media ${device.tablet} {
                display: none;
            }

            .mobile-aside-wrapper {
                display: block;
                text-align: center;
                padding: 15px;

                a {
                    color: #3F9F32;
                    font-weight: 600;
                    text-decoration: underline;
                }

                .mobile-title {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: .5rem;
                    text-align: center;
                }   
            }
        }
    }


`
