import styled from 'styled-components'

export const Layout = styled.main`
    display: flex;
    position: relative;
    font-family: Montserrat, sans-serif;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`