import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const OnboardingModalContent = styled.div`
    .modal-footer {        
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: none;
        width: 100%;

        .onboarding-actns {
            display: flex;
            align-items: center;
            justify-content: center;

            &.l-col {
                flex-direction: column;
            }

            .prev-btn {
                visibility: hidden;
            }

            button {
                margin-bottom: 4px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .next-btn,
            .skip-btn {
                display: inline-block;
                border: none;
                outline: none;
            }

            .next-btn {
                background: #1273EB 0% 0% no-repeat padding-box;
                color: #fff;
                font-size: 18px;
                font-weight: 600;
                border-radius: 20px;
                padding: 5px 24px;
                height: 40px;
                transition: all 0.5s ease 0s;

                &:hover {
                    transform: scale(1.05);
                    backface-visibility: hidden;
                    overflow: auto;
                }
            }

            .skip-btn {
                font-size: 14px;
                font-weight: 500;
                background: transparent 0% 0% no-repeat padding-box;
                padding: 8px 20px;
                height: auto;
                color: #4e74a3;
            }
        }

        .steps {
            display: flex;
            align-items: center;
            justify-content: center;
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                display: block;
                width: 5px;
                height: 5px;
                background-color: #DFE7F0;
                overflow: hidden;
                border-radius: 2.5px;
                margin-right: 8px;

                .active {
                    background-color: #15bd6f;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    
    ${mediaQueries.mediumDevices} {
        .modal-footer {
            .onboarding-actns {
                button {
                    margin-bottom: 10px;
                }

                .next-btn {
                    padding: 13px 30px;
                    height: 54px;
                    border-radius: 32px;
                }

                .skip-btn {
                    font-size: 15px;
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {

    }

    @-webkit-keyframes fadeIn {
        100% {
            opacity: 1;
            filter: blur(0);
        }
    }

    @keyframes fadeIn {
        100% {
            opacity: 1;
            filter: blur(0);
        }
    }
`