import { WelcomeMessageBlock } from '../styles'

export const WelcomeMessage = (props) => {
    return (
        <WelcomeMessageBlock>            
            { !props.showTour && 
                <>
                    <div className='header-photo welcome'>
                        <img src='/images/layout/welcome-header.png' alt='Welcome to ProfitFarmers' />
                    </div>
                    <div className='text-content'>
                        <h1>Welcome to ProfitFarmers!</h1>
                        <p>Connect your Binance exchange account to start rocking our awesome trading signals and tools</p>
                    </div>
                </>
            }

            { props.showTour && 
                <>
                    <div className='header-photo tour'>
                        <img src='/images/layout/tour.png' alt='ProfitFarmers dashboard' />
                    </div>
                    <div className='text-content'>                        
                        <h1>Take a quick tour?</h1>
                        <p>Let us help you get familiar with your dashboard</p>
                    </div>
                </>
            }
        </WelcomeMessageBlock>
    )
}