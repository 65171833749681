import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const BlueprintSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    padding: 40px 5px;
    background-image: linear-gradient(-360deg, #4053FD, #5BA3DB);

    .container {
        display: flex;
        flex-direction: column-reverse;

        .text-col {
            color: #FFFFFF;
            text-align: center;

            .section-title {
                font-weight: 800;
                font-size: 22px;
            }

            p {
                font-weight: 300;
                font-family: 'Noto Sans';
            }

            h5 {
                font-weight: 400;
                font-family: 'Noto Sans';
                font-size: 18px;
                margin-bottom: 25px;
            }

            .schedule-list {
                text-align: left;
                list-style-type: none;

                .item {
                    position: relative;
                    margin-bottom: 18px;
                    font-size: 14px;
                    font-family: 'Noto Sans';
                    font-weight: 200;
                    display: flex;

                    &:before {
                       content: '';
                       position: absolute;
                       width: 18px;
                       height: 18px;
                       top: 4px;
                       left: -28px;
                       background-image: url(/images/trial-offer/calendar-icon.svg);
                       background-size: contain;
                       background-repeat: no-repeat;
                    }
                }
            }

            .checkout-btn {
                button {
                    font-size: 15px;
                }
            }
        }

        .image-col {
            margin-bottom: 25px;
            text-align: center;

            img {
                width: 250px;
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        background-image: linear-gradient(90deg, #4053FD, #5BA3DB);

        .container {
            flex-direction: row;
            align-items: center;

            .text-col {
                text-align: left;
                z-index: 1;

                .section-title {
                    font-size: 35px;
                }

                .checkout-btn {
                    button {
                        font-size: 18px;
                    }
                }
            }

            .image-col {
                position: absolute;
                right: 10px;
                bottom: -30px;

                img {
                    opacity: 0.2;
                    width: 350px;
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        .container {
            .text-col {
                width: 55%;

                .section-title {
                    font-size: 40px;
                }

                .schedule-list {
                    .item {
                        font-size: 15px;
                    }
                }
            }

            .image-col {
                position: relative;
                width: 45%;

                img {
                    opacity: 1;
                    width: 450px;
                }
            }
        }
    }

    ${mediaQueries.superExtraLargeDevices} {
        .container {
            .image-col {
                padding-left: 50px;
                img {
                    width: 480px;
                }
            }
        }
    }
`