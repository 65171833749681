import { messageTypeActionTypes } from '../CONSTANTS'
import { typesServices } from '../../services'

function getMessageTypes() {
    return dispatch => {
        dispatch(request())

        typesServices.getMessageTypes()
            .then(res => dispatch(success(res)), error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: messageTypeActionTypes.GET_MSG_TYPES_REQUEST } }
    function success(response) { return { type: messageTypeActionTypes.GET_MSG_TYPES_SUCCESS, response } }
    function failed(error) { return { type: messageTypeActionTypes.GET_MSG_TYPES_FAILED, error } }
}

function getAudienceTypes() {
    return dispatch => {
        dispatch(request())

        typesServices.getAudienceTypes()
            .then(res => dispatch(success(res)), error => {
                dispatch(failed(error))
            })
    }

    function request() { return { type: messageTypeActionTypes.GET_AUDIENCE_TYPES_REQUEST } }
    function success(response) { return { type: messageTypeActionTypes.GET_AUDIENCE_TYPES_SUCCESS, response } }
    function failed(error) { return { type: messageTypeActionTypes.GET_AUDIENCE_TYPES_FAILED, error } }
}

export const typesAction = {
    getMessageTypes,
    getAudienceTypes
}