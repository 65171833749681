import { isNotNull } from './object'

export const isLogin = () => {  
    let authInfo = localStorage.getItem("user.authorized")
    return authInfo === 'true'
    // const userInfo = JSON.parse(localStorage.getItem('profitfarmers.user'))
    // if (isNotNull(userInfo) && isNotNull(userInfo.userId))
    //     return true

    //return false
}

export const getRole = () => {
    const userInfo = JSON.parse(localStorage.getItem('profitfarmers.user'))
    return isNotNull(userInfo) && isNotNull(userInfo.role) ? userInfo.role : 'Invalid'
}

export * from './history'
export * from './object'
export * from './header'
export * from './window'
export * from './passwordValidator'
export * from './emailValidator'
export * from './string'
export * from './price'
export * from './deviceType'
export * from './checkToken'
export * from './history-time'
export * from './title'
export * from './dimension'
export * from './img'
export * from './title'
export * from './time'
export * from './coins'
export * from './nameValidator'
export * from './injectFastSpring'
export * from './izitToast'
export * from './cookie'
export * from './formTimeout'
export * from './tradeKeyValidator'
export * from './state'
export * from './priceValidator'
export * from './number'
export * from './queryString'
export * from './domain'
export * from './analytics'
export * from './encrypt'
export * from './cloudflare'
export * from './seo'
export * from './screen'
export * from './jsonFormatter'
export * from './notifications'