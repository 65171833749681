import React from 'react'
import { SafeAnchor } from 'react-bootstrap'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { isNotNull, isNull, onSetNewState } from '../../utils'

class CommunityMenuLink extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isPremiumAccess: null,
            isTrialPeriod: null
        }

        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true

        const { isLoading, isPremiumAccess } = this.state
        const { authentication } = this.props
        const { user } = authentication
        if (isLoading && 
            isNull(isPremiumAccess) && 
            isNotNull(user)) {
                onSetNewState(this, {
                    isPremiumAccess: user.isPaid,
                    isTrialPeriod: user.isWithinTrialPeriod
                })
            }
    }

    componentDidUpdate() {
        if (this.mounted) {
            const { isLoading, isPremiumAccess } = this.state
            const { authentication } = this.props
            const { user } = authentication
            
            if (isLoading && 
                isNull(isPremiumAccess) && 
                isNotNull(user)) {
                onSetNewState(this, {
                    isPremiumAccess: user.isPaid,
                    isTrialPeriod: user.isWithinTrialPeriod
                })
            }
            
            if (isLoading && isNotNull(isPremiumAccess)) {
                onSetNewState(this, {
                    isLoading: false
                })
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    render() {
        const { isLoading, isPremiumAccess, isTrialPeriod } = this.state
        if (isLoading) {
            return null
        }

        if (isPremiumAccess) {
            return (
                <li className='sidebar-submenu-item'>
                    <SafeAnchor 
                        href={ !isTrialPeriod 
                            ? 'https://docs.google.com/forms/d/e/1FAIpQLSe0GwPFWzzmnrjYLHwyE7Ug4MwOEFAYzTEdriiFvEWTdZrgpA/viewform' 
                            : 'https://docs.google.com/forms/d/1S5FzEJDEQo7QoiDe1SRN0Q3ZJXW0hGF7842BDKZHUq0/viewform?edit_requested=true' }
                        target="_blank" 
                        rel="noreferrer" 
                        className='sidebar-submenu-item-link'>
                        <span className='submenu-item-link-icon'><i className="fa fa-users" /></span>
                        <span className='submenu-item-link-text'>Join Our Community</span>
                    </SafeAnchor>
                </li>
            )
        }

        return null
    }
}

function mapStateToProps(state) {
    const { authentication } = state
    return {
        authentication
    }
}

const connectedCommunityMenuLink = connect(mapStateToProps)(CommunityMenuLink)
export { connectedCommunityMenuLink as CommunityMenuLink }