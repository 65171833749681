import React from 'react'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import iziToast from 'izitoast'
import { userActions } from '../../../../redux/actions'
import { userActionTypes } from '../../../../redux/CONSTANTS'
import { ProfileView } from './ProfileView'
import { isNotNull, onSetNewState, addPageViewEvent, seo } from '../../../../utils'

class ProfileContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isProfileEdit: false,
            showUploadModal: false,
            showProfileLoad: false,
            isImageSave: true,
            imagePrev: null,
            imageFileName: '',
            imgExt: '',
            imageSrc: '',
            oldImage: '',
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 1,
            croppedImage: null,
            setCroppedImage: null,
            croppedAreaPixels: null,
            setCroppedAreaPixels: null
        }
        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        const { userInfo } = this.props
        if (isNotNull(userInfo) && isNotNull(userInfo.profileAvatar))
            onSetNewState(this, { imageSrc: userInfo.profileAvatar })

        seo({
            title: "Account Profile Settings",
            metaDescription: ""
        })
        addPageViewEvent()
    }

    componentDidUpdate(prevProps) {
        const { dispatch, saveProfile, forms, userInfo } = this.props

        if (isNotNull(saveProfile) &&
            isNotNull(saveProfile.loading) &&
            prevProps.saveProfile.loading === true &&
            saveProfile.loading === false) {
            if (isNotNull(saveProfile.error)) {
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Upload failed',
                    text: saveProfile.error,
                })
            } else {
                if (isNotNull(saveProfile.user)) {
                    iziToast.success({
                        position: 'topRight',
                        title: 'Success',
                        message: 'Profile Update Success!',
                        displayMode: 1,
                    })

                    onSetNewState(this, { isProfileEdit: false })
                    Swal.close()
                }
            }
        }

        if (isNotNull(forms) &&
            isNotNull(forms.loading) &&
            prevProps.forms.loading === true &&
            forms.loading === false) {
                const { response } = forms
                onSetNewState(this, { showProfileLoad: false, isProfileEdit: false })

                if (isNotNull(forms.msg)) {
                    if (forms.msg === 'Successfuly Updated') {
                        iziToast.success({
                            position: 'topRight',
                            title: 'Success',
                            message: 'Profile Update Success!',
                        })
                    }
                }
                
                if (isNotNull(userInfo)) {
                    userInfo.firstName = response.firstName
                    userInfo.lastName = response.lastName
                    userInfo.dateOfBirth = response.dateOfBirth
                    userInfo.gender = response.gender
                    userInfo.countryOfResidence = response.countryOfResidence
                    
                    if (userInfo.profileAvatar !== response.avatarUrl)
                        userInfo.profileAvatar = response.avatarUrl
                    
                    dispatch({ type: userActionTypes.GET_SUCCESS, user: userInfo })
                }
        }
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)
        this.mounted = false
    }

    onAvatarUploadHandler = () => {
        const { dispatch } = this.props
        const { setCroppedImage, imageFileName } = this.state
        Swal.fire({
            title: 'Uploading your photo...',
            timerProgressBar: true,
            showConfirmButton: false,
            allowOutsideClick: false,
            willOpen: () => Swal.showLoading()
        })
        dispatch(userActions.saveProfile(setCroppedImage, imageFileName))
    }

    onSubmitProfileHandler = data => {
        const { dispatch } = this.props
        onSetNewState(this, { showProfileLoad: true })
        dispatch(userActions.editProfile(data))
    }

    render() {
        return (
            <ProfileView
                self={this}
                state={this.state}
                submitProfile={this.onSubmitProfileHandler}
                uploadAvatar={this.onAvatarUploadHandler}
                {...this.props}
            />
        )
    }
}

function mapStateToProps(state) {
    const { authentication, saveProfile, forms } = state
    return {
        authentication,
        saveProfile,
        forms
    }
}
const connectedProfileContainer = connect(mapStateToProps)(ProfileContainer)
export { connectedProfileContainer as ProfileContainer }