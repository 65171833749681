import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { TradeDoctorSessionsStyle } from '../styles'

export const TradeDoctorSessions = () => {
    return (
        <TradeDoctorSessionsStyle>
            <div className='container'>
                <div className='text-with-img'>
                    <div className='img-wrapper'>
                        <img src='/images/layout/trade-doc-sessions.png' alt='' />
                    </div>
                    
                    <div className='text-col'>
                        <h2 className='section-title'>Get Your Personal Trade Doctor Session NOW</h2>
                        <p>Come back to PRO and let our in-house Trade Doctor diagnose your platform usage problems across 2 personal sessions.</p>

                        <p>Here's the benefits of having 1 on 1 sessions with our Trade Doctor:</p>
                        <ul className='sessions-benefits'>
                            <li>
                                <FontAwesomeIcon icon={faCheck} />
                                <span>Fortifying your trading plan/strategy with personalized help</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} />
                                <span>Improve your probabilities through discussion of charts and using tools</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} />
                                <span>Learn to use Technical Analysis alongside our trading signals</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} />
                                <span>Master our features and tools</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} />
                                <span>Second opinions will help you improve dramatically</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} />
                                <span>Hand holding towards becoming a long-term profitable trader</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} />
                                <span>Great for people who need to learn the basics OR coaching on specific points</span>
                            </li>
                        </ul>
                        <p>Avoid your previous mistakes and get yourself back on track, ready to trade the next market explosion to perfection.</p>
                    </div>
                </div>
            </div>
        </TradeDoctorSessionsStyle>
    )
}