import styled from 'styled-components';
import {
    NotificationContentWrapper, CoinsImg, NotificationContentContainer,
    NotificationContent as Content, NotificationCard
} from '../../../components/NotificationBell/styled_components'
import { NotificationContentLists } from '../styled_components'

export const NotificationContent = styled.p`
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 40px;
    }
`

export const NotificationTitle = styled.h5`
    padding: 15px 10px;
    font-size: 19px;
    background-color: rgb(185 63 95 / 0.1);
    border-radius: 2px;
    color: #B93F5F;
    text-align: center;
`

export const NotificationSeperator = styled.div`
    width: 100%;
    height: 50px;
    position: relative;
    border-top: 1px solid #33465d;
    margin-top: 35px;

    .back-line {
        position: absolute;
        top: -12px;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        z-index: 12;

        .or-text {
            padding: 10px;
            background: #192431;
            font-weight: 500;
        }
    }
`

export const NotificationContainer = styled.div`
    margin-bottom: 20px;

    &.backdrop-sticky {
        position: sticky;
        top: -1px;
        background: #192431;
        z-index: 100;
    }

    .card {
        background-color: #1f2935;

        .card-body {
            padding: 10px;

            .table {
                color: #fff;
                margin-bottom: 0;

                tr {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    td {
                        padding: .25rem .75rem;
                        display: flex;
                        align-items: center;

                        a.btn-close-order,
                        button.btn-close-order {
                            padding: 10px 15px !important;
                            background-color: #6b2b3c !important;
                            border-color: #b93f5f !important;
                            color: #fff;
                        }

                        &:first-child {
                            color: #7c8da2;
                            text-transform: uppercase;
                            font-size: 13px;
                        }

                        &:last-child {
                            text-align: right;
                        }
                    }
                }
        
                .preloader {
                    padding: 0;
                    border: none;
                }
            }
        }
    }

    

    &.btn-wrapper {
        margin-bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            background-color: #3F9F32 !important;
            color: #fff !important;
            text-align: center;
            border: 1px solid #61B755 !important;
            font-size: 14px !important;
            margin-left: 0;
            border-radius: 2px;
            padding: 12px 15px;
            text-transform: uppercase;
            font-weight: 500;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

            &.btn-sell {
                padding: 10px 15px !important;
                background-color: #d2aa2fed !important;
                border-color: #ccb823 !important;
            }

            &.btn-cancel {
                padding: 10px 15px !important;
                background-color: #6b2b3c !important;
                border-color: #b93f5f !important;
            }

            &.btn-status {
                padding: 10px 15px !important;
                background-color: #1c293a !important;
                border-color: #3e434a !important;
                cursor: unset;
            }
        }
    }
    
`

export const NotificationPanels = styled.div`
    transition: 0.5s;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;

    &.right-panel {
        left: 0;

        ${NotificationContentLists} {
            height: 100%;
        }

        &.hidden {
            left: -800px;;
        }

        @media screen and (min-width: 320px) and (max-width: 414px) {
            ${NotificationContentLists} {
                &::-webkit-scrollbar {
                    width: 6px;
                }

                ${NotificationContentWrapper} {
                    padding: 0;
                    margin-bottom: 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    ${NotificationCard} {
                        ${CoinsImg} {
                            width: 25px;
                            height: 25px;
                        }
                        ${NotificationContentContainer} {
                            width: calc(100% - 35px);
        
                            ${Content} {
                                font-size: 14px
                            }
                        }
                    }
                }
            }
        }
    }

    &.left-panel {
        right: 0;
        padding: 0 15px;
        overflow-y: auto;
        &.hidden {
            right: -800px;
        }

        &::-webkit-scrollbar {
            width: 10px;
        }
    
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: #94a7c1;
            outline: 1px solid #383e4e;
            border-radius: 10px;
        }

        @media screen and (min-width: 320px) and (max-width: 414px) {
            font-size: 14px;
            padding: 0;

            ${NotificationContainer} {
                ${NotificationTitle} {
                    font-size: 16px;
                }
                &.btn-wrapper {
                    button {
                        padding: 8px 0;
                        width: 100%;
                    }
                }
            }
        }
    }
`

export const NotificationPushWrapper = styled.div`
    padding: 15px;

    .header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        height: 45px;

        .btn-sidebar {
            color: rgb(110, 120, 133);
            background-color: rgb(32, 44, 57);
            font-size: 12px;
            z-index: 20;
            border-color: rgb(32, 44, 57);
            padding: 5px 10px;
            border-radius: 2px;

            &:hover,
            &:focus {
                color: rgb(110, 120, 133);
                box-shadow: none;
                background-color: rgb(14, 24, 36) !important;
                border-color: rgb(14, 24, 36) !important;
                outline: none;
            }
        }
    }

    .body-wrapper {
        position: relative;
        overflow: hidden;
        min-height: 60vh;

        @media screen and (min-width: 1024px) and (max-width: 1199px) {
            min-height: 45vh;
        }
    }

    @media screen and (min-width: 320px) and (max-width: 414px) {
        padding: 0px;

        .header-wrapper {
            
            span {
                font-size: 14px;
            }

            .btn-sidebar {
                padding: 3px 8px;
            }
        }

        .body-wrapper {
        
        }
    }
`