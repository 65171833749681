import React from 'react'
import { connect } from 'react-redux'
import jQuery from 'jquery'
import Swal from 'sweetalert2'
import * as signalR from '@microsoft/signalr'
import _ from 'lodash'
import { planActions } from '../../redux/actions'
import { PricingView } from './PricingView'
import { isNotNull, InjectFastSpring, onSetNewState } from '../../utils'

class PricingForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isPriceLoaded: true,
            userId: '',
            userName: '',
            firstName: '',
            lastName: '',
            vipSubscription: [
                {
                    id: '0',
                    name: 'Monthly',
                    val: 'monthly',
                    price: 0,
                    refKey: ''
                }, {
                    id: '1',
                    name: 'Quarterly',
                    val: 'quarterly',
                    price: 0,
                    refKey: ''
                }, {
                    id: '2',
                    name: 'Yearly',
                    val: 'yearly',
                    price: 2659
                }
            ]
        }
        this.baseState = this.state
        this.mounted = false
        this.fastspringHubConnection = null
    }

    UNSAFE_componentWillMount() {
        InjectFastSpring()

        window.onPaymentReceived = data => {
            if (isNotNull(data)) {
                // open modal to show customer that we are processing his/her account

                Swal.fire({
                    title: 'Payment successful, please wait while we setup your acount.',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    didOpen: () => Swal.showLoading()
                })
            }
        }
    }

    componentDidMount() {
        this.mounted = true
        const { dispatch } = this.props
        dispatch(planActions.get())

        this.initFastSpringSocket()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.mounted) {
            const { plan } = this.props
            const { user } = this.props.authentication

            if (isNotNull(plan) &&
                isNotNull(plan.loading) &&
                prevProps.plan.loading === true &&
                plan.loading === false) {
                const subscriptionPlan = plan.plans
                if (isNotNull(subscriptionPlan) && isNotNull(subscriptionPlan.Fastspring)) {
                    const { vipSubscription } = this.state
                    _.forEach(subscriptionPlan.Fastspring, f => {
                        if (f.normalizedName.indexOf("COMBINATION") >= 0) {
                            // subscription in VIP
                            if (f.normalizedName.indexOf("MONTHLY") >= 0) {
                                vipSubscription[0].id = f.id
                                vipSubscription[0].price = f.amount
                                vipSubscription[0].refKey = f.refKey
                            }
                            else if (f.normalizedName.indexOf("QUARTERLY") >= 0) {
                                vipSubscription[1].id = f.id
                                vipSubscription[1].price = parseFloat(f.amount / 3).toFixed(0)
                                vipSubscription[1].originalPrice = f.amount
                                vipSubscription[1].refKey = f.refKey
                            }
                            else if (f.normalizedName.indexOf("YEARLY") >= 0) {
                                vipSubscription[2].id = f.id
                                vipSubscription[2].price = parseFloat(f.amount / 12).toFixed(0)
                                vipSubscription[2].originalPrice = f.amount
                                vipSubscription[2].refKey = f.refKey
                            }
                        }
                    })
                    onSetNewState(this, {
                        vipSubscription,
                        isPriceLoaded: false
                    })
                }
            }

            if (isNotNull(user)) {
                const { userId, firstName, lastName, userName } = user
                if (isNotNull(userId) && userId !== prevState.userId) {
                    this.setState({ userId })
                }

                if (isNotNull(firstName) && firstName !== prevState.firstName) {
                    this.setState({ firstName })
                }

                if (isNotNull(lastName) && lastName !== prevState.lastName) {
                    this.setState({ lastName })
                }
                
                if (isNotNull(userName) && userName !== prevState.userName) {
                    this.setState({ userName }, async () => {                            
                        await this.startSocketConnection(userName)
                    })
                }
            }
        }
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)
        
        if (this.fastspringHubConnection !== null) {
            if (!this.fastspringHubConnection.hasOwnProperty('close')) {
                this.fastspringHubConnection.stop()
            }
        }
        this.mounted = false
    }

    initFastSpringSocket = async () => {
        if (this.mounted) {
            const { closeModal } = this.props

            // configure signalr connection for payment notification
            const hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(`https://webhks.profitfarmers.com/fs`, {
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets
                })
                .configureLogging(signalR.LogLevel.Information)
                .withAutomaticReconnect([0, 0, 10000])
                .build()
                
            hubConnection.serverTimeoutInMilliseconds = 120000

            this.fastspringHubConnection = hubConnection
            this.fastspringHubConnection.on("OnPaymentMade", (payload) => {
                console.log('payload: ', payload)
                const { userId } = this.state
                if (isNotNull(payload) && 
                    isNotNull(userId) && 
                    payload === userId) {
                        jQuery(document).find("#fscCanvas").remove()
                        Swal.close()
                        Swal.fire('Subscribed!', 'Thank you for subscribing Profitfarmers', 'success')
                        
                        Swal.fire({
                            title: 'Subscribed',
                            text: 'Thank you for subscribing Profitfarmers',
                            icon: 'success'
                        }).then(function () {
                            window.location = "/dashboard"
                        })
                        
                        closeModal()
                }
            })
        }
    }

    async startSocketConnection(username) {
        if (this.mounted) {
            try {
                await this.fastspringHubConnection
                    .start()
                    .catch(err => console.log(err))

                if (this.fastspringHubConnection.state === signalR.HubConnectionBuilder.Connected) {
                    this.fastspringHubConnection.invoke("GetConnectionId", username)
                        .then((connectionId) => {
                            console.log('conn: ', connectionId)
                        })
                        .catch(err => console.log(err))
                }
            }
            catch (err) {
                if (this.fastspringHubConnection.state === signalR.HubConnectionState.Disconnected) {
                    setTimeout(async () => {
                        await this.startSocketConnection(username)
                    }, 5000)
                }
            }
        }
    }

    onFastSpringPayHandler = (e, _period) => {
        const { name } = e.target

        if (isNotNull(name)) {
            this.submitPayment(_period)
        }
    }

    submitPayment = (plan) => {
        const { userId, firstName, lastName, userName, vipSubscription } = this.state        
        if (isNotNull(userId) && isNotNull(userName) && isNotNull(firstName)) {
            const value = _.find(vipSubscription, q => q.val === plan)
            if (isNotNull(value)) {
                const session = {
                    reset: true,
                    products: [{
                        path: value.refKey,
                        quantity: 1
                    }],
                    paymentContact: {
                        email: userName,
                        firstName: firstName,
                        lastName: lastName
                    },
                    tags: {
                        userId: userId,
                        sPlanId: value.id,
                        //customerId: user.customerId,
                        leadId: null,
                        isLead: false
                    },
                    checkout: true
                }
                window.fastspring.builder.push(session)
            }
        }
    }

    render() {
        return (
            <PricingView
                fastSpringPay={this.onFastSpringPayHandler}
                state={this.state}
                {...this.props}
            />
        )
    }
}

function mapStateToProps(state) {
    const { authentication, plan } = state
    return {
        authentication,
        plan
    }
}

const connectedPricingForm = connect(mapStateToProps)(PricingForm)
export { connectedPricingForm as PricingForm }