import React from 'react'
import { FreeVsProSectionStyle } from './styles'
import { DynamicCheckout } from '../../../components'

export const FreeVsProSection = (props) => {
    return(
        <FreeVsProSectionStyle>
            <div className='container'>
                <h2 className='section-title'>HERE’S A QUICK REMINDER OF WHY YOU NEED TO GO PRO</h2>

                <div className='free-pro-cont'>
                    <div className='free-pro-row freeAcc'>
                        <h3 className='title'>FREE ACCOUNT</h3>
                        <ul className='features-list free-list'>
                            <li className='list-item'>Limited Signals</li>
                            <li className='list-item'>1st take profit target only</li>
                            <li className='list-item'>Limited Market Analysis</li>
                            <li className='list-item'>Limited Features</li>
                            <li className='list-item'>No Education</li>
                        </ul>
                    </div>
                    <div className='free-pro-row proAcc'>
                        <h3 className='title'>PRO ACCOUNT</h3>
                        <ul className='features-list pro-list'>
                            <li className='list-item'>ALL Signals<br /><small>-Potentially 50-100+ more per month</small></li>
                            <li className='list-item'>ALL 4 take profit targets<br /><small>-Unlock 20%-30%-100%+ gains</small></li>
                            <li className='list-item'>ALL Market Analysis<br /><small>-Profitable Mrket Intel</small></li>
                            <li className='list-item'>ALL PRO trade features<br /><small>-Automations to make trading less complicated</small></li>
                            <li className='list-item'>Pro Trader <strong>BLUEPRINT</strong> and more!<br /><small>-Learn how to 5x your account</small></li>
                        </ul>
                    </div>
                </div>

                <div className='checkout-btn'>
                    <DynamicCheckout 
                        text="Unlock 21 days of PRO Now - Just $21"
                        position="centered" 
                        userInfo={props.userInfo} 
                        offer={props.subscriptionPlan} />
                </div>
            </div>
        </FreeVsProSectionStyle>
    )
}