import React from 'react'
import { SafeAnchor } from 'react-bootstrap'
import _ from 'lodash'
import jQuery from 'jquery'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons'
import { MessageList } from '../../../styles'
import { isNotNull, isNull, onSetNewState } from '../../../utils'

const rssfeedType = 'rssfeed'

export class ListOfMessages extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isAdmin: undefined,
            isLoading: true,
            selectedType: '',
            data: null
        }
        this.baseState = this.state
        this.mounted = false

        //this.initRssFeed()
    }
    
    componentDidMount() {
        this.mounted = true
        const { isAdmin } = this.props
        if (isNotNull(isAdmin)) {
            onSetNewState(this, {
                isAdmin
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.mounted) {
            const { isLoading } = this.state
            const { isAdmin, data, selectedMessageType } = this.props

            if (typeof this.state.isAdmin === 'undefined' && 
                isNotNull(isAdmin)) {
                    onSetNewState(this, {
                        isAdmin
                    })
                }

            if (!isLoading && 
                prevState.selectedType !== selectedMessageType) {
                onSetNewState(this, {
                    isLoading: true,
                    selectedType: selectedMessageType,
                    data: null
                })
            }

            if (isLoading) {
                if (isNull(this.state.data) && isNotNull(data)) {
                    onSetNewState(this, {
                        data,
                        isLoading: false
                    })
                } else {
                    if (this.state.data !== data) {
                        onSetNewState(this, {
                            data,
                            isLoading: false
                        })
                    } else {
                        onSetNewState(this, {
                            isLoading: false
                        })
                    }
                }
            } else {
                if (isNotNull(this.state.data) && this.state.data !== data) {
                    onSetNewState(this, {
                        data
                    })
                }
            }
        }
    }

    componentWillUnmount() {
        this.setState(this.baseState)
        this.mounted = false
    }

    initRssFeed = async () => {
        let { messageItems } = this.state
        const rssUrl = "https://cointelegraph.com/rss/tag/bitcoin"
        const urlRegex = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/
        
        if (urlRegex.test(rssUrl)) {
            const res = await fetch(`https://api.allorigins.win/get?url=${rssUrl}`)
            if (isNotNull(res)) {
                //console.log('res: ', await res.text())
                const { contents } = await res.json()
                if (isNotNull(contents)) {
                    const feed = new window.DOMParser().parseFromString(contents, "text/xml")
                    const items = feed.querySelectorAll("item")
                    //console.log('items: ', items)
                    if (isNotNull(items) && items.length > 0) {
                        [...items].map((el) => {
                            const link = el.querySelector("link").innerHTML
                            const title = jQuery(el).find('title').text()
                            //console.log('title: ', title)
                            const fullDesc = jQuery(el).find('description').text()
                            const descNodes = new window.DOMParser().parseFromString(fullDesc, "text/html")
                            const imgEl = descNodes.body.firstChild.innerHTML
                            const desc = descNodes.body.lastElementChild.innerHTML
                            const pubDate = moment(new Date(el.querySelector("pubDate").innerHTML)).utc()
    
                            messageItems.push({
                                img: imgEl,
                                type: rssfeedType,
                                typeInTxt: 'RSS Feed',
                                link,
                                title,
                                description: desc,
                                datePublished: pubDate
                            })
                        })

                        onSetNewState(this, {
                            messageItems
                        })
                    }
                }
            }
        }
    }

    renderItems = () => {
        let { data } = this.state
        const { isAdmin } = this.state
        const { onSelectMessage, onEditMessage, onDeleteMessage } = this.props

        if (isNotNull(data)) {
            if (data.length > 0) {
                data = _.orderBy(data, ['dateCreated'], ['desc'])
                const mappedItems = _.map(data, (item, key) => {                
                    let messageType
                    let typeInText
                    switch(item.type) {
                        case 0: {
                            messageType = 'marketanalysis'
                            typeInText = 'Market Analysis'
                            break
                        }
                        case 1:
                        default: {
                            messageType = 'general'
                            typeInText = 'General'
                            break
                        }
                        case 2: {
                            messageType = 'promotions'
                            typeInText = 'Promotions'
                            break
                        }
                    }
    
                    return (    
                        <li key={key}>
                            { item.type === rssfeedType && 
                                <SafeAnchor className='msg-wrapper rss' href={item.link} target="_blank">
                                    <div className='msg-txt-content'>
                                        <h5 className='header-texts'>
                                            <span>{item.title}</span><br />
                                            <small className='msg-ts'>{ item.type === rssfeedType ? moment(item.dateCreated).format('LL') : item.dateCreated}</small> <span className='dot'></span> <small className={`msg-type ${messageType}`}>{typeInText}</small>
                                        </h5>
                                        <p>{ isNotNull(item.shortDescription) ? item.shortDescription : (<em>No description</em>)}</p>
                                    </div>
                                    <div className='msg-thumbnail' dangerouslySetInnerHTML={{__html: item.img}}></div>
                                </SafeAnchor>
                            }
        
                            { item.type !== rssfeedType && 
                                <div className='msg-wrapper' onClick={(e) => onSelectMessage(e, item)}>
                                    <div className='msg-thumbnail'>
                                        <img src={`/images/layout/def-tn-${messageType}.jpg`} />
                                    </div>
                                    <div className='msg-txt-content'>                                        
                                        <h5 className='header-texts'>{item.title}</h5>
                                        <div className='stamp'><small className='msg-ts'>{moment(item.dateCreated).format('LL')}</small> <span className='dot'></span> <small className={`msg-type ${messageType}`}>{typeInText}</small></div>
                                        <p>{item.shortDescription}</p>
                                    </div>
                                </div>
                            }
    
                            { isNotNull(isAdmin) ?
                                <>
                                    { isAdmin && 
                                        <ol className='actions-list'>
                                            <li>
                                                <button type="button" className='action-btn mod' onClick={(e) => onEditMessage(e, item)}>
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </li>
                                            {/* <li>
                                                <button type="button" className='action-btn rem' onClick={(e) => onDeleteMessage(e, item.id)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </li> */}
                                        </ol>
                                    }

                                    { !isAdmin && item.isFresh && 
                                        <ol className='actions-list'>
                                            <li>
                                                <span className='fresh-msg'>New</span>
                                            </li>
                                        </ol>
                                    }
                                </> : <></>
                            }
                        </li>
                    )
                })
    
                return mappedItems
            }
            else 
                return (<li className='empty'><p>No result</p></li>)
        }
    }

    render() {
        const { isLoading } = this.state

        return (
            <>
                <MessageList> 
                    { !isLoading && this.renderItems() }
                </MessageList>
            </>
        )
    }
}