import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries';

export const AgreeButton = styled.button`
	background: #167753 !important;
    border-radius: 0px !important;
    border: none !important;
    border-bottom: 5px solid #16b46c !important;
	padding: 10px 30px !important;

	&:hover {
		background: #167753;
    	border: none;
	}

	&:focus {
		background: #167753;
    	border: none;
	}
`

export const ModalBodySectionWrapper = styled.section`
	background: rgb(25, 36, 49);
	padding: 20px 45px;
	border: 4px solid #1c2c3e;
	border-radius: 0px;

	.header-wrapper {
		border-bottom: 2px solid #1c2c3e;

		.risk-text {
			font-size: 32px;
			font-weight: 100;
			margin: 20px 15px;
    		vertical-align: -webkit-baseline-middle;
		}
	}

	.message-wrapper {
		margin-top: 25px;
		font-size: 15px;

		.fa-ul {
			margin-left: 3.5em;
			.fa-li {
				left: -40px !important;
			}
		}
	}

	.footer-wrapper {
		padding: 12px 0px;
		border-top: 2px solid #1c2c3e;

		.remember-text {
			color: #b93f5f;
			font-size: 20px;

			&.text {
				font-weight: 500;
			}
		}
	}

`

export const MainContent = styled.main`
	position: relative;
	margin-top: 56px;
	margin-left: 0;
	min-height: 480px;
	overflow: hidden;
	transition: all 300ms ease-in-out;

	.main-content {
		padding: 0;
		transition: all 300ms ease-in-out;

		&.settings {
			padding: 0;
		}

		.floating-button {
			position: fixed;
			bottom: 10px;
			right: 10px;
			font-size: 18px;
			/* background-color: #27323e; */
			color: #fff;
		    background-color: #16B46C;
		    border: none;
			width: 45px;
			height: 45px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
		    z-index: 9999;

			&.active {
				opacity: 0;
			}
		}
	}

	&.show {
		.back-drop {
			display: block;
			cursor: pointer;
		}
	}

	&.notif-permission {
		margin-top: 135px;
	}

	${mediaQueries.mediumDevices} {
		&.notif-permission {
			margin-top: 92px;
		}
	}

	@media only screen and (min-width: 992px) {
		margin-left: 60px;
	}

	@media only screen and (min-width: 1200px) {
		margin-left: 0;
		padding-left: 242px;
		
		.main-content {
			padding: 0;
		}

		&.show {
			.main-content {
				padding: 0 212px 0 242px;
			}

			.back-drop {
				display: none;
			}
		}
	}

	@media only screen and (min-width: 1440px) {
		margin-left: 0;
		padding-left: 278px;

		&.show {
			.main-content {
				margin-left: 278px;
				margin-right: 222px;
				padding: 0;
			}
		}
	}

	@media screen and (min-width: 320px) and (max-width: 1199px) {
		.main-content {
			&.hide {
				padding: 0 !important;
			}

			&.show {
				.back-drop-wallets {
					display: block;
				}
			}
		}
	}

	@media screen and (min-width: 1024px) and (max-width: 1199px) {
		&.hide {
			margin-left: 0;
			transition: all 300ms ease-in-out;
		}

		.main-content {
			&.hide {
				padding: 0;
			}

			&.show {
				.back-drop-wallets {
					display: block;
				}
			}
		}
	}

	@media screen and (min-width: 1025px) and (max-width: 1199px) {
			&.main-content {
				&.show {
					.back-drop-wallets {
						display: block;
					}
				}
			}
	}
`;

export const LayoutWrapper = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-size: 16px !important;
    position: relative;
	background: transparent;
    //background: #131E2B;
    color: #ced0dd;

    .blur-dashboard {
        position: relative;

		&.blur {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			-webkit-filter: blur(2px);
			-moz-filter: blur(2px);
			-o-filter: blur(2px);
			-ms-filter: blur(2px);
			filter: blur(2px);
			pointer-events: none;
		}
    }

    .back-drop {
		display: none;
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background: #00000094;
		z-index: 16;
		transition: all 300ms ease-in-out;
	}

    .back-drop-wallets {
		display: none;
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background: #00000094;
		z-index: 11;
		transition: all 300ms ease-in-out;
	}

	.positive {
		color: #00ed81 !important; //#adf94b !important;
	}

	.negative {
		color: #d25d87 !important;
	}

	.relative {
		position: relative;
	}

	#sidebar-opt-prof {
		margin-top: 0;
	}

	@media screen and (min-width: 320px) and (max-width: 1024px) {
		&.sidebar-open {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}

	@media screen and (min-width: 1025px) and (max-width: 1199px) {
		&.sidebar-open {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
`
