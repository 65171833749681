import { Row, Col, Card, Accordion } from 'react-bootstrap'
import { browserName, isBrowser } from 'react-device-detect'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import _ from 'lodash'
import NotificationTypes from './NotificationTypes'
import { SettingsContentWrapper, SettingsTitle } from '../../styled_components'
import {
    NotificationWrapper, NotificationContainer,
    NotificationTypesWrapper, ToggleButton,
    NotificationItemToggle, NotificationItems, NotificationLists } from './styled_components'
import { isNotNull } from '../../../../utils'

export const NotificationView = (props) => {
    const { userInfo } = props
    const { allowPush, allowSoundOnPush, 
        isUpdatingPushNotifSettings, isUpdatingPushSoundSettings } = props.state
    const { notification_settings, loading } = props.notifications

    const onToggleHandler = (index) => {
        if (isNotNull(index)) {
            const { toggles } = props.state
            toggles[index] = toggles[index] ? false : true
            props.self.setState({ toggles })
        }
    }

    const handleWatchlistedOnly = (id, type, value) => {        
        const { notification_settings } = props.notifications
        if (isNotNull(notification_settings)) {
            const { data } = notification_settings
            if (isNotNull(data) && isNotNull(data.types)) {
                let typeIndex = _.findIndex(data.types, t => t.type === type)
                if (typeIndex !== -1) {
                    data.types[typeIndex].isWatchlistedOnly = value
                    props.onChangeTypeSettings(data.types[typeIndex])
                }
            }
        }
    }

    const handleTypePushSettings = (e, id, value) => {   
        e.preventDefault()
         
        const { notification_settings } = props.notifications
        if (isNotNull(notification_settings)) {
            const { data } = notification_settings
            if (isNotNull(data) && isNotNull(data.types)) {
                let typeIndex = _.findIndex(data.types, t => t.Id === id)
                if (typeIndex !== -1) {
                    data.types[typeIndex].isPushAllowed = value
                    props.onChangePushTypeSettings(data.types[typeIndex])
                }
            }
        }
    }

    const handleTypeEmailSettings = (e, id, value) => {    
        e.preventDefault()
         
        const { notification_settings } = props.notifications
        if (isNotNull(notification_settings)) {
            const { data } = notification_settings
            if (isNotNull(data) && isNotNull(data.types)) {
                let typeIndex = _.findIndex(data.types, t => t.Id === id)
                if (typeIndex !== -1) {
                    data.types[typeIndex].isEmailAllowed = value
                    props.onChangeEmailTypeSettings(data.types[typeIndex])
                }
            }
        }
    }

    const handlePushSettings = (e, id, value) => {
        e.preventDefault()

        const { data } = notification_settings
        let settings = {
            id,
            allowEmail: data.allowEmail,
            allowPush: value,
            allowSoundOnPush: data.allowSoundOnPush,
            deviceToken: data.deviceToken
        }
        props.onChangePushSettings(settings)
    }

    const handleSoundSettings = (e, id, value) => {
        e.preventDefault()

        const { data } = notification_settings
        let settings = {
            id,
            allowEmail: data.allowEmail,
            allowPush: data.allowPush,
            allowSoundOnPush: value,
            deviceToken: data.deviceToken
        }
        props.onChangePushSoundSettings(settings)
    }

    // const handleEmailSettings = (e, id, value) => {
    //     e.preventDefault()

    //     const { data } = notification_settings
    //     let settings = {
    //         id,
    //         allowEmail: value,
    //         allowPush: data.allowPush,
    //         allowSoundOnPush: data.allowSoundOnPush,
    //         deviceToken: data.deviceToken
    //     }
    //     props.onChangePushSettings(settings)
    // }

    const renderLoader = () => (
        <>
            <div className="pf-spinner xs" style={{ marginTop: '-45px', marginRight: '32px' }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </>
    )

    const renderPush = (allowPush, allowSoundOnPush) => {
        if (isNotNull(notification_settings)) {
            const { data } = notification_settings
            let browserIcon = 'chrome'

            switch (browserName) {
                default: {
                    browserIcon = "default"
                    break
                }
                case 'Chrome': {
                    browserIcon = "chrome"
                    break
                }
                case 'Firefox': {
                    browserIcon = "firefox"
                    break
                }
                case 'Brave': {
                    browserIcon = "brave"
                    break
                }
                case 'Edge': {
                    browserIcon = "edge"
                    break
                }
            }

            return (
                <>
                    <Accordion key={`push-notification-acc`}>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey={`push-notification`}>
                                <div className="header-wrapper">
                                    { isBrowser && 
                                        <>
                                            <i className="fas fa-globe" />                                
                                            <span className="emphasized-title">Browser</span>
                                        </>
                                    }

                                    { !isBrowser && 
                                        <>
                                            <span className="push-icon-wrapper">
                                                <div className="push-icon">
                                                    <div className="push-circle"></div>
                                                </div>

                                                <span className="emphasized-title">Push</span>
                                            </span>
                                        </>
                                    }
                                </div>
                                <FontAwesomeIcon icon={faCaretDown} />
                            </Accordion.Toggle>
                            
                            <Accordion.Collapse id={`push-notification-acc`} eventKey={`push-notification`}>
                                <Card.Body>
                                    <div className="notification-type">
                                        <h6>Push Notifications</h6>
                                        <NotificationLists className="sub-notif-lists">
                                            <NotificationItems>
                                                <span className="item-label">
                                                    <span><i className={`browser-icon ${browserIcon}`} /> { browserName } Push Notifications</span>
                                                </span>
                                                
                                                { isUpdatingPushNotifSettings && renderLoader()}
                                                { !isUpdatingPushNotifSettings && 
                                                    <>                                                        
                                                        <NotificationItemToggle onClick={(e) => handlePushSettings(e, data.id, !allowPush)}>
                                                            <ToggleButton className={`notification-toggle ${allowPush ? 'active' : '' }`}>
                                                                <span className="toggle-label">{allowPush ? 'On' : 'Off'} </span>
                                                                <div className="input-cont">
                                                                    <input type="checkbox" 
                                                                        id={`parent-push-${data.id}`}
                                                                        checked={allowPush} 
                                                                        readOnly />
                                                                    <i className={`notification-toggle ${allowPush ? 'active' : ''}`} />
                                                                </div>
                                                            </ToggleButton>
                                                        </NotificationItemToggle>
                                                    </>
                                                }
                                            </NotificationItems>
                                        </NotificationLists>

                                        <h6 className="mt-3">Sounds</h6>
                                        <NotificationLists className="sub-notif-lists">
                                            <NotificationItems>
                                                <span className="item-label">
                                                    <span>Play a sound when each new notification is received</span>
                                                </span>

                                                { isUpdatingPushSoundSettings && renderLoader()}
                                                { !isUpdatingPushSoundSettings && 
                                                    <>                                                    
                                                        <NotificationItemToggle onClick={(e) => handleSoundSettings(e, data.id, !allowSoundOnPush)}>
                                                            <ToggleButton className={`notification-toggle ${allowSoundOnPush ? 'active' : '' }`}>
                                                                <span className="toggle-label">{allowSoundOnPush ? 'On' : 'Off'} </span>
                                                                <div className="input-cont">
                                                                    <input type="checkbox" 
                                                                        id={`push-sound-${data.id}`}
                                                                        checked={allowSoundOnPush} 
                                                                        readOnly />
                                                                    <i className={`notification-toggle ${allowSoundOnPush ? 'active' : ''}`} />
                                                                </div>
                                                            </ToggleButton>
                                                        </NotificationItemToggle>
                                                    </>
                                                }
                                            </NotificationItems>
                                        </NotificationLists>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </>
            )
        }
    }

    const renderEmail = () => {
        if (isNotNull(notification_settings)) {
            return (
                <>
                    <Accordion key={`email-notification-acc`}>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey={`email-notification`}>
                                <div className="header-wrapper">
                                    <i className="far fa-envelope" />                                
                                    <span className="emphasized-title">Email</span>
                                </div>
                                <FontAwesomeIcon icon={faCaretDown} />
                            </Accordion.Toggle>
    
                            <Accordion.Collapse id={`email-notification-acc`} eventKey={`email-notification`}>
                                <Card.Body>
                                    <div className="email-notification-recipient">
                                        <i className="fas fa-envelope"></i> <span>{userInfo.email}</span>
                                    </div>
    
                                    {/* <div className="notification-type">
                                        <h6>Your Email Notifications</h6>
                                        <NotificationLists className="sub-notif-lists">
                                            <EmailByNotificationType
                                                state={props.state} 
                                                loading={loading} 
                                                notification_settings={notification_settings}                                                 
                                                onChangeEmailSettings={handleTypeEmailSettings} />
                                        </NotificationLists>
                                    </div> */}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </>
            )
        }
    }

    const renderNotification = (loading, notification_settings) => {
        return (
            <NotificationWrapper>
                <NotificationContainer>
                    <SettingsTitle>Types Of Notifications To Allow</SettingsTitle>

                    <NotificationTypesWrapper className="types mb-3">
                        <NotificationTypes
                            state={props.state} 
                            loading={loading} 
                            userInfo={userInfo} 
                            notification_settings={notification_settings}
                            toggles={props.state.toggles}
                            toggle={onToggleHandler}
                            watchlistedHandler={handleWatchlistedOnly}
                            onChangePushSettings={handleTypePushSettings}
                            onChangeEmailSettings={handleTypeEmailSettings}
                        />
                    </NotificationTypesWrapper>
                </NotificationContainer>

                <NotificationContainer>
                    <SettingsTitle>How You Get Notifications</SettingsTitle>

                    <NotificationTypesWrapper>
                        {renderPush(allowPush, allowSoundOnPush)}
                        {renderEmail()}
                    </NotificationTypesWrapper>
                </NotificationContainer>
            </NotificationWrapper>
        )
    }

    return (
        <SettingsContentWrapper className="account-settings-wrapper">
            <Row>
                <Col>
                    <Card>
                        <Card.Body className="auto-trade-settings notif" style={{ borderTopLeftRadius: '9px', borderTopRightRadius: '9px' }}>
                            {/* {loading === true && renderPreloader()} */}
                            {/* {loading === false && renderNotification(loading, notification_settings)} */}
                            {renderNotification(loading, notification_settings)}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </SettingsContentWrapper>
    )
} 