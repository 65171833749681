import React from 'react'
import { SaveMoreSectionStyle } from './styles'
import { DynamicCheckout } from '../..'

export const SaveMoreSection = (props) => {
    return(
        <SaveMoreSectionStyle>
            <div className='container'>
                <h2 className='section-title'>Save More Than $300 on PRO Access and Get The 5x Blueprint Now</h2>
                <p>Listen, it’s just $1 per day. Need we say more? Make the choice to invest in yourself and commit to learning a lifelong skill - profitable trading. Our software makes it easier than ever before and tons of people can attest to the fact that it takes much less time than you think.</p>
                <p>Save &gt;$300, get your hands on ‘The Pro Trader 5x Blueprint’ or settle for a free account and miss out on the opportunity of a lifetime.</p>
                <p>The choice is yours…</p>

                <div className='checkout-btn'>
                    <DynamicCheckout 
                        text="Take 21 day Pro trial for $21"
                        position="centered" 
                        userInfo={props.userInfo} 
                        offer={props.subscriptionPlan} />

                    { props.page === "trial" && 
                        <button 
                            type="button" 
                            className='skip-trial-btn'
                            onClick={(e) => props.onSkipTrial(e)}>Continue with (low powered) Free account</button>
                    }                    
                </div>
            </div>            
        </SaveMoreSectionStyle>
    )
}