import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const ThreeYearStatsSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    background-color: #0B1219;
    padding: 60px 0;

    &::before {
        display: block;
        width: 100%;
        height: 500px;
        position: absolute;
        top: -1px;
        left: 0;
        content: ' ';
        background-image: url(/images/layout/gradient-bg.png);
        background-repeat: no-repeat;
        background-position: -400px center;
        z-index: 0;
    }
    
    .container {
        position: relative;

        .section-title {
            font-size: 28px;
            font-weight: 700;
            color: #FFFFFF;
            text-align: center;
            max-width: 100%;
            margin: 0 auto 25px;
        }

        .stats-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .stats-peak-gains,
            .stats-peak-gains-breakdown {
                display: flex;
                width: 100%;
            }

            .stats-peak-gains {
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-bottom: 25px;

                .rocket-img {
                    width: 80px;

                    img {
                        width: 100%;
                    }
                }

                .value {
                    font-size: 44px;
                    font-weight: 700;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 1.2;

                    span { 
                        font-size: 32px;
                        color: #15bd6f; 
                    }
                }
            }

            .stats-peak-gains-breakdown {
                flex-direction: column;
                
                .percentages {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 0;

                    .value {
                        display: flex;
                        flex-direction: column;
                        width: 50%;
                        text-align: center;

                        > span {
                            color: #FFFFFF;
                            font-size: 22px;
                            margin-bottom: 5px;
                            font-family: 'Noto Sans';

                            .icon {
                                margin-right: 12px;

                                img {
                                    height: 36px;
                                }
                            }
                        }

                        > .label {
                            font-family: 'Montserrat';
                            font-weight: 300;
                            font-size: 17px;

                            &.green {
                                color: #11D487;
                            }

                            &.red {
                                color: #FC6786;
                            }
                        }
                    }
                }

                .counter {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 10px;

                    .value {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #FFFFFF;
                        background-color: rgba(255, 255, 255, 0.1);
                        border: 1px solid #9AB9D5;
                        border-top-left-radius: 25px;
                        border-bottom-right-radius: 25px;
                        padding: 10px 15px;
                        width: auto;
                        margin-bottom: 12px;

                        .icon {
                            width: 55px;
                            margin-right: 12px;

                            img {
                                width: 100%;
                            }
                        }

                        .text {
                            display: flex;
                            flex-direction: column;
                            line-height: 1.2;
                        }

                        &:first-child {
                            margin-right: 8px;
                        }
                    }
                }

                .top-signals-carousel {
                    margin-bottom: 25px;
                    
                    .carousel-inner {
                        width: 100%;
                        height: 343px;

                        .carousel-item {
                            text-align: center;
                        }
                    }

                    .carousel-control-prev {
                        left: -21px;
                    }

                    .carousel-control-next {
                        right: -21px;
                    }
                }

                .top-signals {
                    display: flex;
                    margin-bottom: 15px;
                }

                p {
                    display: block;
                    text-align: center;
                    font-size: 17px;
                    color: #FFFFFF;
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        &::before {
            background-position: -100px center;
        }

        .container {
            .section-title {
                font-size: 32px;
            }

            .stats-content {
                flex-direction: row;

                .stats-peak-gains { 
                    width: 40%;
                    margin-right: 15px;
                }

                .stats-peak-gains-breakdown {
                    width: 60%;

                    .percentages {
                        width: 100%;
                        
                        .value {
                            > span {
                                font-size: 42px;
                                font-weight: 600;

                                &.label {
                                    font-size: 20px;
                                    font-weight: 400;
                                }
                            }
                        }
                    }

                    .counter {
                        width: 100%;
                        flex-direction: row;
                        justify-content: space-around;

                        .value {
                            width: auto;
                            font-size: 23px;
                            margin-bottom: 0;
                        }
                    }

                    .top-signals {
                        img {
                            width: 83px;
                        }
                    }

                    p {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        .container {
            .stats-content { 
                .stats-peak-gains { 
                    width: 40%;
                    margin-right: 15px;

                    .rocket-img {
                        width: 100px;
                    }

                    .value {
                        font-size: 56px;
                        line-height: 1;

                        span { 
                            font-size: 38px;
                        }
                    }
                }

                .stats-peak-gains-breakdown {
                    width: 60%;

                    .percentages {
                        width: 100%;
                        
                        .value {
                            > span {
                                font-size: 42px;
                                font-weight: 600;

                                &.label {
                                    font-size: 20px;
                                    font-weight: 400;
                                }
                            }
                        }
                    }

                    .counter {
                        width: 100%;
                        flex-direction: row;
                        justify-content: space-around;

                        .value {
                            width: auto;
                            font-size: 23px;
                            margin-bottom: 0;
                        }
                    }

                    .top-signals {
                        img {
                            width: 109px;
                        }
                    }

                    p {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        .container {
            .section-title {
                font-size: 42px;
                margin-bottom: 40px;
                max-width: 1000px;
            }

            .stats-content {
                .stats-peak-gains {
                    width: 45%;
                    margin-bottom: 0;

                    .rocket-img {
                        width: 210px;
                    }
                    
                    .value {
                        font-size: 80px;

                        span {
                            font-size: 60px;
                        }
                    }
                }

                .stats-peak-gains-breakdown {
                    width: 55%;

                    .percentages {
                        justify-content: space-between;
                        margin-bottom: 15px;

                        .value {
                            width: auto;

                            > span {
                                font-size: 60px;
                                margin-bottom: -8px;

                                > .label {
                                    font-size: 20px;
                                    font-weight: 400;
                                }
                            }
                        }
                    }

                    .counter {
                        justify-content: space-between;

                        .value {
                            font-size: 40px;
                            font-weight: 500;
                            padding: 10px 25px;
                            width: 50%;

                            .icon {
                                margin-right: 15px;
                            }

                            small {
                                font-size: 15px;
                                font-weight: 300;
                            }

                            &:first-child {
                                margin-right: 15px;
                            }
                        }
                    }

                    .top-signals-carousel {
                        display: none;
                    }

                    .top-signals {
                        img {
                            width: 115px;
                            margin-right: 5px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
`