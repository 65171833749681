import React from 'react'
import { LadderingModeSectionStyle } from './styles'

export const LadderingModeSection = () => {
    return (
        <LadderingModeSectionStyle>
            <div className='container'>
                <div className='text-with-img'>
                    <div className='text-col'>
                        <img src='/images/trial-offer/hot-feature.png'/>
                        <h2 className='section-title'>LADDERING MODE</h2>
                        <p><span className='white-text'>Enter trades professionally at the flip of a switch</span> and make getting into trades like Loop Ring Coin (LRC) a walk in the park.</p>
                        <p>Activating laddering mode asks your trading co-pilot to <span className='white-text'>buy into a trade signal at 5 different entry prices</span> spread throughout the entry zone.</p>
                        <p>Here are just some of the benefits:</p>

                        <ul className='benefits-list'>
                            <li className='list-item'>Get a good average entry price without sweating</li>
                            <li className='list-item'>Reduce the need for Technical Analysis (TA)</li>
                            <li className='list-item'>Create complex trade plans in under 30seconds</li>
                            <li className='list-item'>Customize your entry ladder to suit your game plan</li>
                        </ul>
                    </div>
                    <div className='image-col'>
                        <img src='/images/trial-offer/laddering-mode.png'/>
                    </div>
                </div>
            </div>
        </LadderingModeSectionStyle>
    )
}