import { statisticActionTypes } from '../CONSTANTS'

export function netPeakGains(state = {}, action) {
    switch (action.type) {
        case statisticActionTypes.GET_NET_PEAK_GAINS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case statisticActionTypes.GET_NET_PEAK_GAINS_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.response
            }
        case statisticActionTypes.GET_NET_PEAK_GAINS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function monthlyNetPeakGains(state = {}, action) {
    switch (action.type) {
        case statisticActionTypes.GET_MONTHLY_PEAK_GAINS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case statisticActionTypes.GET_MONTHLY_PEAK_GAINS_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.response
            }
        case statisticActionTypes.GET_MONTHLY_PEAK_GAINS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function topTradeWinners(state={}, action) {
    switch (action.type) {
        case statisticActionTypes.GET_TOP_WINNERS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case statisticActionTypes.GET_TOP_WINNERS_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.response
            }
        case statisticActionTypes.GET_TOP_WINNERS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function statistics(state = {}, action) {
    switch (action.type) {
        case statisticActionTypes.GET_STATISTICS_REQUEST:
            return {
                ...state,
                loader: true
            }
        case statisticActionTypes.GET_STATISTICS_SUCCESS:
            return {
                ...state,
                loader: false,
                items: action.statistics
            }
        case statisticActionTypes.GET_STATISTICS_FAILURE:
            return {
                ...state,
                loader: false,
                error: action.error
            }
        default:
            return state
    }
}

export function strategiesFilter(state = {}, action) {
    switch (action.type) {
        case statisticActionTypes.STRATEGIES_FILTER_REQUEST:
            return {
                ...state,
                loader: true
            }
        case statisticActionTypes.STRATEGIES_FILTER_SUCCESS:
            return {
                ...state,
                loader: false,
                filters: action.strategies
            }
        case statisticActionTypes.STRATEGIES_FILTER_FAILURE:
            return {
                ...state,
                loader: false,
                error: action.error
            }
        default:
            return state
    }
}

export function periodRangeFilter(state = {}, action) {
    switch (action.type) {
        case statisticActionTypes.PERIODRANGE_FILTER_REQUEST:
            return {
                ...state,
                loader: true
            }
        case statisticActionTypes.PERIODRANGE_FILTER_SUCCESS:
            return {
                ...state,
                loader: false,
                filters: action.period_range
            }
        case statisticActionTypes.PERIODRANGE_FILTER_FAILURE:
            return {
                ...state,
                loader: false,
                error: action.error
            }
        default:
            return state
    }
}
