import React from 'react'
import { connect } from 'react-redux'
import { Form, SafeAnchor, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft, faShare } from '@fortawesome/free-solid-svg-icons'
import { SRLWrapper } from 'simple-react-lightbox'
import { TextButton, PrimaryButton } from '../../styles'
import { FormBodyContent, FormInfoText, FormActions } from './'
import { onboardingScreen } from '../../constants/onboardingScreen'
import { routes } from '../../navigation/CONSTANTS'
import { isNotNull } from '../../utils'

class LogIntoBinance extends React.Component {
    constructor(props) {
        super(props)

        this.state = {}
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { skipOnboarding } = this.props
            
            if (isNotNull(skipOnboarding) && prevProps.skipOnboarding !== skipOnboarding && !skipOnboarding.loading) {
                const { response } = skipOnboarding
                if (isNotNull(response) && response.success) window.location.href = routes.dashboard
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    handleClickNext = (e) => {
        e.preventDefault()

        const { eventHandlers } = this.props
        if (isNotNull(eventHandlers)) {
            eventHandlers.navigateTo(null, onboardingScreen.BINANCE_API_SETTINGS)
        }
    }

    handleClickBack = (e) => {
        e.preventDefault()

        const { eventHandlers } = this.props
        if (isNotNull(eventHandlers)) {
            eventHandlers.navigateTo(null, onboardingScreen.FINANCIAL_DETAILS)
        }
    }

    showSkipWarning = (e) => {
        const { eventHandlers } = this.props
        eventHandlers.handleSkip(e)
    }

    render() {
        const lightboxOptions = {
            buttons: {
                showAutoplayButton: false,
                showThumbnailsButton: false
            }
        }

        return (
            <>
                <FormBodyContent className='api-connection'>
                    <div className='graphic with-preview video'>
                        <iframe
                            height="100%"
                            width="100%"
                            title="Get Started Checklist"
                            src="https://www.youtube.com/embed/NhCycW1yEKg"
                            frameBorder="0"
                            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen />
                    </div>

                    <div className='form'>
                        <h2 className='form-title'>Connect PF to Binance.com</h2>
                        <h3 className='form-instruction'>Create or login to Binance.com account</h3>

                        <Form.Group className='detailed-instructions'>
                            <ol>
                                <li><SafeAnchor href="https://www.binance.com/en/register?ref=42030218" target="_blank" rel="noreferrer">Click here to create Binance.com</SafeAnchor> account and save money on Binance trading fees!</li>
                                <li>Once complete, navigate to the Binance.com home screen</li>
                            </ol>
                        </Form.Group>
                    </div>
                </FormBodyContent>

                <FormInfoText>
                    <p>Already have a Binance.com account? Please open a separate browser window and login now.</p>
                </FormInfoText>

                <FormActions>
                    <TextButton 
                        textColor="#8A9BAE"
                        size="sm" 
                        className='default-btn back-btn'
                        onClick={(e) => this.handleClickBack(e)}>
                            <FontAwesomeIcon size="xs" className='icon' icon={faArrowLeft} /> 
                            <span>Back</span>
                    </TextButton>

                    <TextButton 
                        textColor="#8A9BAE"
                        size="auto" 
                        className='default-btn skip-btn' 
                        onClick={(e) => this.showSkipWarning(e)}>
                            <FontAwesomeIcon size="xs" className='icon' icon={faShare} /> 
                            <span>Skip</span>
                    </TextButton>

                    <PrimaryButton 
                        type="submit" 
                        size="sm"
                        onClick={(e) => this.handleClickNext(e)}>
                            <FontAwesomeIcon size="xs" className='icon' icon={faArrowRight} /> 
                            <span>Next</span>
                    </PrimaryButton>
                </FormActions>
            </>
        )
    }
}

function mapStateToProps(state) {
    const { skipOnboarding } = state
    return {
        skipOnboarding
    }
}

const connectedLogIntoBinance = connect(mapStateToProps)(LogIntoBinance)
export { connectedLogIntoBinance as LogIntoBinance }