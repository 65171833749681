import { tradeOrderActionTypes } from '../CONSTANTS'

export function leverageSettings(state = {}, action) {
    switch (action.type) {
        case tradeOrderActionTypes.SET_LEVERAGE_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case tradeOrderActionTypes.SET_LEVERAGE_SUCCESS: {
            return {
                ...state,
                loading: false,
                items: action.response
            }
        }
        case tradeOrderActionTypes.SET_LEVERAGE_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case tradeOrderActionTypes.SET_LEVERAGE_CLEAR: {
            return {
                ...state,
                loading: false,
                items: null
            }
        }
        default:
            return state
    }
}

export function tradeOrder(state = {}, action) {
    switch (action.type) {
        case tradeOrderActionTypes.CREATE_ORDER_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case tradeOrderActionTypes.CREATE_ORDER_SUCCESS: {
            return {
                ...state,
                loading: false,
                items: action.response
            }
        }
        case tradeOrderActionTypes.CREATE_ORDER_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case tradeOrderActionTypes.CREATE_ORDER_CLEAR: {
            return {
                ...state,
                loading: false,
                items: null
            }
        }
        default:
            return state
    }
}

export function tradeOrderLeverage(state = {}, action) {
    switch (action.type) {
        case tradeOrderActionTypes.CREATE_ORDER_WITH_LEVERAGE_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case tradeOrderActionTypes.CREATE_ORDER_WITH_LEVERAGE_SUCCESS: {
            return {
                ...state,
                loading: false,
                response: action.response
            }
        }
        case tradeOrderActionTypes.CREATE_ORDER_WITH_LEVERAGE_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.response
            }
        }
        case tradeOrderActionTypes.CREATE_ORDER_WITH_LEVERAGE_CLEAR: {
            return {
                ...state,
                loading: false,
                response: null
            }
        }
        default:
            return state
    }
}