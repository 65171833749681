import styled from 'styled-components'

export const TargetBarsWrapper = styled.div`
    display: flex;
    width: 120px;
    justify-content: flex-end;
`

export const TargetBar = styled.span`
    position: relative;
    width: 28px;
    height: 26px;
    margin-right: 6px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-size: 11px;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid ${props => props.fill < 100 ? '#1c2f46' : '#17212d' };
    color: ${props => props.fill < 100 ? '#36465c' : '#17212d' };
    background-color: ${props => props.fill < 100 ? 'rgba(0,0,0,0.2)' : '#f1a92e'};
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3) inset;

    .tick {
        position: absolute;
        z-index: 2;
        color: #000;
        width: 10px;
        height: 12px;
        font-size: 9px;
        padding: 1px;
        border-radius: 6px;
        text-align: center;
        font-weight: 800;
        bottom: 7px;
    }

    .line {
        display: none;
    }

    .value {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: ${ props => props.fill + `%`};
        transition: height linear 0.8s;
        background-color: ${ props => props.fill < 100 ? `#182029` : `#efb30c` };
        /* border-bottom: ${ props => props.fill < 100 ? `1px solid #fff` : `1px solid #f7b909` }; */
    }

    &.bad-rr {
        border-bottom: 3px solid #B33E5D;
    }

    &.fair-rr {
        border-bottom: 3px solid #C9684D;
    }

    &.good-rr {
        border-bottom: 3px solid #15BC6F;
    }

    &:last-child {
        margin-right: 0;
    }

    @media screen and (min-width: 320px) and (max-width: 359px) {
        width: 26px;
    }

    @media screen and (min-width: 1200px) and (max-width: 1550px) {
        width: 22px;
    }

    @media screen and (min-width: 1551px) and (max-width: 1749px) {
        width: 24px;
    }
`