import styled from 'styled-components'

const getTextColor = (type) => {
    switch (type) {
        default:
            return "";
        case "primary":
            return '#FFFFFF !important';
        case "secondary":
            return "#FFFFFF !important";
        case "danger":
            return "#ff5757 !important";
        case "default":
            return "#fff !important";
    }
};

const getBackgroundColor = (type) => {
    switch (type) {
        default:
            return "";
        case "primary":
            return '#3F9F32 !important';
        case "secondary":
            return "#475973 !important";
        case "danger":
            return "transparent !important";
        case "default":
            return "#2e3b4c !important";
    }
};

const getBorder = (type) => {
    switch (type) {
        default:
            return "transparent";
        case "primary":
            return '#61B755';
        case "secondary":
            return "#577094";
        case "danger":
            return "1px solid #b93f3f !important";
    }
}

export const Btn = styled.button`
    background-color: ${props => getBackgroundColor(props.variant)};
    color: ${props => getTextColor(props.variant)};
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid ${props => getBorder(props.variant)} !important;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:disabled {
        background-color: rgb(43, 60, 75) !important;
        color: #556371 !important;
        border: 1px solid #616161 !important;
    }

    &:focus {
        outline: none;
    }
`

export const CloseButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    background-color: #993956;
    border: none;
    padding: 2px 19px;
    border-radius: 2px;
    font-size: 18px;
    z-index: 20;

    &:hover {
        background-color: #aa4664;
        box-shadow: none;
    }

    &:active,
    &:focus {
        background-color: #aa4664;
        box-shadow: none;
    }

    &.msg-modal-close-btn {
        position: fixed;
        top: 30px;
        right: 30px;
    }
`