export const listOfReferrers = [
    { id: 'crypto-news-flash', name: 'Crypto-news-flash.de' },
    { id: 'btc-echo', name: 'BTC-ECHO.de' },
    { id: 'crypto-insiders', name: 'Crypto-Insiders.nl' },
    { id: 'valforex', name: 'Valforex' },
    { id: 'scamwarning', name: 'Scamwarning.org' },
    { id: 'coincodecap', name: 'Coincodecap' }, 
    { id: 'coinmonks', name: 'Coinmonks' },
    { id: 'personal-recommendation', name: 'Personal recommendation' },
    { id: 'google-search', name: 'Google search' }, 
    { id: 'youtube', name: 'Youtube' }, 
    { id: 'instagram', name: 'Instagram' },
    { id: 'twitter', name: 'Twitter' },
    { id: 'facebook', name: 'Facebook' }, 
    { id: 'other', name: 'Other' }
]