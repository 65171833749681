import { deviceType } from "react-device-detect";

export const getDeviceType = () => {
    switch (deviceType.toUpperCase()) {
        case "MOBILE":
            return 4
        case "TABLET":
            return 3
        default:
            return 0
    }
}