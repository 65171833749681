import glamorous from 'glamorous'
import { mediaQueries } from '../../constants/mediaQueries'

export const FormContainer = glamorous.div({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '0 25px',

    '& h1.title': {
        color: '#fff',
        marginTop: '30px',
        marginBottom: '30px'
    },

    '& p.description': {
        color: '#d7e3e1',
        fontSize: '18px',
        textAlign: 'center',
        letterSpacing: '1px',
        lineHeight: '1.6'
    },

    '& .alert': {
        backgroundColor: '#11272a',
        color: '#13b079',
        borderRadius: '10px',
        textAlign: 'center',
        padding: '8px',

        '& svg': {
            marginRight: '10px',
            color: '#23d381',
            fontSize: '24px',
            marginBottom: '-3px'
        }
    },
    
    '& .form-group': {
        marginBottom: '10px',

        '& .input-wrapper': {
            position: 'relative',
            border: '2px solid #0f1823',
            borderRadius: '24px',
            backgroundColor: '#0b131c',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 7px',
            boxShadow: '0 1px 15px 7px rgb(0 0 0 / 30%) inset',

            '&.is-invalid': {
                border: '1px solid #8f3b4e !important'
            },

            '& .input-group': {
                '& .form-control': {
                    background: 'none',
                    border: 'none',
                    color: '#d5d5d5',
                    fontWeight: 600,
                    fontSize: '14px',
                    boxShadow: 'none'
                },

                '& .input-group-append, .input-group-prepend': {
                    '& .input-group-text': {
                        background: 'none',
                        border: 'none'
                    }
                },
                
                '& .input-group-append': {
                    '& .input-group-text': {
                        cursor: 'pointer'
                    }
                },

                '& .input-group-prepend .input-group-text': {
                    color: '#15bd6f'
                }
            }
        }
    },

    '& .auth-btn': {
        fontSize: '16px',
        transition: 'transform 0.2s ease 0s',
        width: '100%',
        marginTop: '25px',
        padding: '6px 12px',
        borderRadius: '24px',
        backgroundColor: '#1ea164',
        border: '2px solid #23d381',

        '&.verify-btn': {
            width: 'auto !important'
        },

        '&:hover': {
            color: '#fff',
            transform: 'scale(1.05)',
            backfaceVisibility: 'hidden',
            overflow: 'auto'
        },
        
        '&:disabled': {
            cursor: 'not-allowed',

            '&:hover': {
                transform: 'none'
            }
        }
    },

    [mediaQueries.extraSmallDevices]: {
        '& h1.title': {
            fontSize: '22px'
        },

        '& p.description': {
            width: '90%',
            fontSize: '15px'
        },

        '& #verify-code-form': {
            '& .form-group': {
                '& .input-group': {
                    '& .form-control': {
                        height: '65px',
                        fontSize: '32px !important'
                    }
                }
            }
        }
    },

    [mediaQueries.mediumDevices]: {
        '& p.description': {
            width: '83%'
        },

        '& #verify-code-form': {
            '& .form-group': {
                '& .input-group': {
                    '& .form-control': {
                        height: 'calc(1.5em + .75rem + 2px)'
                    }
                }
            }
        }
    },

    [mediaQueries.largeDevices]: {
        '& p.description': {
            width: '590px'
        }
    },
    

})

export const FormQuickLinks = glamorous.div({
    display: 'block',
    fontSize: '14px',
    marginTop: '25px',
    color: '#8fa0b5',
    textAlign: 'center',
    
    '& a': {
        color: '#fff',
        fontWeight: 500,
        letterSpacing: '1px',

        '&:hover': {
            color: '#fff',
            textDecoration: 'none'
        }
    }
})