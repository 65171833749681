import Moment from 'react-moment'
import _ from 'lodash'
import { isNotNull } from '../../utils'

export const OrderHistoryItemView = (props) => {
    const { historyGroupId, coin, historyData } = props

    const renderHistoryItemData = (historyGroupId, historyData) => {
        if (isNotNull(historyData)) {
            const orderLists = _.orderBy(historyData, o => new Date(o.closedAt), 'asc')
            return orderLists.map((item, key) => {
                return (
                    <tr key={key} className="expanded-data" style={{ display: props.onDisplay(historyGroupId) }}>
                        
                        { window.innerWidth >= 1024 &&
                            <>
                                <td className="table-space"></td>
                                <td>{item.type}</td>
                                <td>{item.volume}</td>
                                <td>{item.price}</td>
                                <td dangerouslySetInnerHTML={{ __html: item.condition }}></td>
                                <td dangerouslySetInnerHTML={{ __html: item.comment }}></td>
                                <td><Moment format="MM/DD/YYYY hh:mm A" utc local>{item.closedAt}</Moment></td>
                            </>
                        }

                        { window.innerWidth < 1024 &&
                            <td colSpan={7}>
                                <div className="expanded-wrapper">
                                    <span className="col-sm-6 col-md-8">TYPE</span>
                                    <span className="col-sm-6 col-md-4">{item.type}</span>
                                </div>

                                <div className="expanded-wrapper">
                                    <span className="col-sm-6 col-md-8">VOLUME</span>
                                    <span className="col-sm-6 col-md-4">{item.volume}</span>
                                </div>

                                <div className="expanded-wrapper">
                                    <span className="col-sm-6 col-md-8">PRICE</span>
                                    <span className="col-sm-6 col-md-4">{item.price}</span>
                                </div>

                                <div className="expanded-wrapper">
                                    <span className="col-sm-6 col-md-8">CONDITION</span>
                                    <span dangerouslySetInnerHTML={{ __html: item.condition }} className="col-sm-6 col-md-4"></span>
                                </div>

                                <div className="expanded-wrapper">
                                    <span className="col-sm-6 col-md-8">OPEN DATE</span>
                                    <span className="col-sm-6 col-md-4"><Moment format="MM/DD/YYYY hh:mm A" utc local>{item.closedAt}</Moment></span>
                                </div>
                            </td>
                        }
                    </tr>
                )
            })
        }
    }

    return (
        <tbody className="order-table">
            <tr className="custom-splitter history" onClick={() => props.onExpand(historyGroupId)}>
                <td><b>{historyGroupId} </b><p className="coin-name">{coin}</p></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>

            {renderHistoryItemData(historyGroupId, historyData)}
        </tbody>
    )
}