import styled, { keyframes } from 'styled-components'
import { mediaQueries } from '../../constants/mediaQueries'

const pulseRed = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(255, 48, 109, 0.7);
    }  
    70% {
        box-shadow: 0 0 0 10px rgba(255, 48, 109, 0);
    }  
    100% {
        box-shadow: 0 0 0 0 rgba(255, 48, 109, 0);
    }
`

const pulseGold = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(188, 144, 56, 0.7);
    }  
    70% {
        box-shadow: 0 0 0 10px rgba(188, 144, 56, 0);
    }  
    100% {
        box-shadow: 0 0 0 0 rgba(188, 144, 56, 0);
    }
`

export const FeaturedContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    padding: 10px;
    border: 2px solid #18283c;
    background-color: #0C131B;
    box-shadow: 0px 3px 18px #00000061;
    border-radius: 9px;
    min-height: 279px;
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;
    background-repeat: no-repeat;
    background-size: cover;

    &:after {
        position: absolute;
        content: " ";
        display: block;
        bottom: -90px;
        left: 0;
        width: 100%;
        height: 200px;
        background: transparent radial-gradient(closest-side at 50% 50%, #E5C77E 0%, #E5C77E00 100%) 0% 0% no-repeat padding-box;
        opacity: 0.2;
        z-index: 0;
        transition: all ease 1s;
    }

    .carousel-btn {
        z-index: 1;
        transition: all ease-in-out .3s;

        /* &:hover {
            transform: scale(1.05);
        } */

        a {
            position: relative;
            color: #ffffff;
            font-weight: 600;
            padding: 15px 30px;
            background-image: linear-gradient(94deg,#BC9038 0%,#cf9b1e 51%,#6c4e11 100%);
            border: 1px solid #AE822C;
            border-radius: 33px;
            box-shadow: 0 0 7px 1px rgb(0 0 0 / 20%);
            animation: ${pulseGold} 2s infinite;

            &:hover {
                text-decoration: none;
                background-image: linear-gradient(94deg,#d99918 0%,#3b9d66 51%,#6c4e11 100%);
            }

            &:after {
                content: '';
                position: absolute;
                top: 2px;
                left: 2px;
                width: calc(100% - 4px);
                height: 50%;
                background: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.2));
                border-top-left-radius: 33px;
                border-top-right-radius: 33px;
                opacity: 0.5;
            }
        }

        .btn-text {
            position: relative;
            text-shadow: 0px 0px 4px rgba(0,0,0,0.3);
        }
    }

    &.for-upgrade {
        background-color: #0C131B;
        background-image: url(/images/layout/upgrade-cta-bg.png);
        border: 2px solid #18283c;
    }

    &.for-trial {
        background: transparent linear-gradient(90deg, #4053FD 0%, #5BA3DB 100%) 0% 0% no-repeat padding-box;
        border: 2px solid #18283C;
        position: relative;
        padding: 25px 10px;

        &:after {
            position: absolute;
            top: 0;
            left: 0;
            background-image: url(/images/layout/21-trial-offer.png);
            opacity: 0.7;
            background-size: contain;
            z-index: 0;
            height: 100%;
        }

        .carousel-btn {
            margin-bottom: 20px;

            a {                
                background-image: linear-gradient(94deg,#ff306d 0%,#FF3030 100%);
                border: 1px solid #b5d8ff;
                animation: ${pulseRed} 2s infinite;
            }
        }
    }

    &.for-expd-trial {
        background-color: #0C131B;
        background-image: url(/images/layout/upgrade-cta-bg.png);
        border: 2px solid #18283c;

        .carousel-btn {
            a {                
                background-image: linear-gradient(94deg,#ff306d 0%,#FF3030 100%);
                border: 1px solid #ff42bc;
                animation: ${pulseRed} 2s infinite;
            }
        }
    }

    &.def {
        justify-content: flex-start;
    }

    .upgrade-cta {
        padding: 0;

        .text-with-img {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .img {
                width: calc(100% - 200px);

                img {
                    width: 100%;
                }
            }

            .text {
                width: 200px;
                color: #ffffff;
                text-align: center;

                h4 {
                    font-size: 22px;
                    font-weight: 600;
                    margin-bottom: 5px;
                }

                .price-offer {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: center;
                    margin: 0 auto 6px;

                    .crashed-out-price {
                        color: #516475;
                        position: relative;
                        font-size: 17px;
                        font-weight: 500;
                        margin-right: 10px;

                        &:before {
                            position: absolute;
                            content: "";
                            left: 0;
                            top: 45%;
                            right: 0;
                            border-top: 2px solid;
                            border-color: #FF3030;
                            -webkit-transform: rotate(-18deg);
                            -moz-transform: rotate(-18deg);
                            -ms-transform: rotate(-18deg);
                            -o-transform: rotate(-18deg);
                            transform: rotate(-18deg);
                        } 
                    }

                    .new-price {
                        font-size: 27px;
                        font-weight: 700;
                        line-height: 1;
                    }
                }

                .discount-tag {
                    display: block;
                    width: 154px;
                    text-align: center;
                    background-color: #FE2944;
                    border-radius: 5px;
                    padding: 2px 8px;
                    margin: 0 auto 8px;
                    font-weight: 700;
                    letter-spacing: 1px;
                }

                .price-increase {
                    font-size: 13px;   
                }

                .offer-validity-timer {
                    margin-bottom: 0;

                    ul {
                        margin: 2px 0 5px;
                        padding: 0;
                        list-style-type: none;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;

                        li {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            padding: 0 4px;
                            color: #fff;
                            line-height: 1;
                            width: 49px;

                            .counter {
                                width: 36px;
                                font-size: 16px;
                                font-weight: 600;
                                color: #000;
                                background-color: #f5cd2f;
                                margin-bottom: 5px;
                                border-radius: 4px;
                                padding: 5px;
                            }

                            small {
                                font-size: 9px;
                                letter-spacing: 1px;
                            }
                        }
                    }
                }

                .counter-txt {
                    display: block;
                    text-align: center;
                    font-weight: 600;
                    color: #3a628f;
                }
            }
        }
    }

    .trial-cta {
        position: relative;
        z-index: 1;
        padding: 0;
        color: #fff;
        width: 220px;
        margin: 0 0 auto auto;

        h4 {
            font-weight: 600;
            font-size: 24px;
            margin-bottom: 0;
            line-height: 1;

            small {
                font-size: 12px;
            }
        }
        
        .price-details {
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            .new-price {
                font-size: 70px;
                font-weight: 800;
                line-height: 1;
            }

            .old-price {
                display: flex;
                flex-direction: column;

                .crashed-old-price {
                    font-size: 30px;
                    font-weight: 600;
                    display: inline-block;
                    color: #B5D8F9;
                    position: relative;
                    width: 76px;
                    line-height: 1;

                    &:before {
                        position: absolute;
                        content: "";
                        left: 0;
                        top: 45%;
                        right: 0;
                        border-top: 3px solid;
                        border-color: #FF3030;
                        -webkit-transform: rotate(168deg);
                        -moz-transform: rotate(168deg);
                        -ms-transform: rotate(168deg);
                        -o-transform: rotate(168deg);
                        transform: rotate(168deg);
                    } 
                }
            }
        }
    }

    .carousel-indicators li {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: #27D194;
    }

    .carousel-item {
        align-items: center;
        flex-direction: row-reverse;
        max-height: 208px;
        overflow: hidden;

        &.active {
            display: flex;
        }

        &.carousel-item-next {
            display: flex;
        }

        &.carousel-item-prev {
            display: flex;
        }

        img {
            width: 65%;
        }

        .carousel-caption {
            position: absolute;
            text-align: left;
            right: unset;
            bottom: unset;
            left: 5%;
            width: 45%;

            p {
                &.member-text {
                    margin-bottom: 5px;
                    font-size: 15px;
                }
                &.member-name {
                    font-size: 12px;
                    color: #8A9BAE;
                    align-items: center;

                    img {
                        width: 45%;
                        margin: -3px 0 0 5px;
                    }
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        margin-bottom: 10px;

        .upgrade-cta {
            .text-with-img {
                .img {
                    display: none;
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        margin-bottom: 15px;

        .upgrade-cta {
            .text-with-img {
                .img {
                    display: flex;
                }
            }
        }
    }
`