import { Button, Card, Container, Row } from "react-bootstrap"
import { BodyContainer, BodyWrapper, FormWrapper, OnboardingStyle, TitleWrapper, WelcomeImageWrapper, WelcomeText } from "../../styled_components"


export const PaymentCompleteView = props => {

    const $state = props.state
    const $this = props.context
    const { loading } = $this.props.authentication
    const { remainingSeconds } = $state

    const renderWelcome = () => (
        <Row className="center">
            <FormWrapper className="col col-md-10 col-lg-10 col-xl-9 p-2">
                <TitleWrapper>
                    <h6>Welcome to</h6>
                    <h2>PROFITFARMERS</h2>
                    <p>
                        Your plan begins today and renews automatically each month until changes are made to your account settings.
                    </p>
                </TitleWrapper>


                <WelcomeImageWrapper className="center">
                    <img className="img-fluid" alt="" src="/images/logo/welcome.png" />
                </WelcomeImageWrapper>
                <WelcomeText>
                    <p>
                        Your selected subscription plan may automatically renew unless you cancel. For futher details regarding automatic renewal, cancellations, and our refund policy, please review our <a href="https://www.profitfarmers.com/terms-of-use" rel="noreferrer" target="_blank" className="terms">Terms of Use.</a>
                    </p>
                    <div className="text-center">
                        {
                            !loading &&
                            <Button className="button-pay" onClick={() => props.onRedirectToDashboardHandler()} variant="Primary">
                                Let's get started
                            </Button>
                        }
                        {
                            loading &&
                            <Button disabled className="button-pay" onClick={() => props.onRedirectToDashboardHandler()} variant="Primary">
                                Let's get started
                            </Button>
                        }

                    </div>
                    <p>
                        You are being redirected, please wait...
                        <br />
                        If you haven't been redirected in {remainingSeconds} {(remainingSeconds === 1 || remainingSeconds === 0) ? 'second' : 'seconds'}, please click the button.
                        <br />
                    </p>
                </WelcomeText>
            </FormWrapper>
        </Row>
    )

    return (
        <BodyWrapper>
            <BodyContainer className="onRegistration">

                <OnboardingStyle className="payment-complete">
                    <Container>
                        <Row className="center">
                            <Card className="custom-card welcome">
                                <Card.Body>
                                    {renderWelcome()}
                                </Card.Body>
                            </Card>
                        </Row>
                    </Container>
                </OnboardingStyle>

            </BodyContainer>
        </BodyWrapper>
    )

}