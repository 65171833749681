import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import jQuery from 'jquery'
import { Form, Col, Dropdown } from 'react-bootstrap'
import { Form as FinalForm, Field } from 'react-final-form'
import newFormDecorator from 'final-form-focus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { TextButton, PrimaryButton } from '../../styles'
import { FormBodyContent, FormActions } from './'
import { onboardingScreen } from '../../constants/onboardingScreen'
import { onboardingActions } from '../../redux/actions'
import { isNull, isNotNull, onSetNewState } from '../../utils'
import { tradingExpQuestionnaire } from '../../utils/data'

class TradingExperience extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            questionnaire: [],
            expLevel: null,
            hasFuturesTradingExp: 'NO',
            tradingPurpose: null,
            capital: null,
            riskLevel: null,
            otherAssets: [],
            isSubmitting: false,
            data: null
        }
        this.baseState = this.state
        this.mounted = false
        this.formDecorator = newFormDecorator()
    }

    componentDidMount() {
        this.mounted = true
        onSetNewState(this, {
            questionnaire: tradingExpQuestionnaire
        })

        const { data } = this.props
        if (isNotNull(data)) {
            onSetNewState(this, {...data})
        }
        
        window.scrollTo(0, 0)
        jQuery('.focus-on').select()
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { saveOnboardingUserTradingExpDetails, eventHandlers } = this.props
            const { isSubmitting, data } = this.state
            
            if (prevProps.saveOnboardingUserTradingExpDetails !== saveOnboardingUserTradingExpDetails && 
                !saveOnboardingUserTradingExpDetails.loading && 
                isSubmitting && 
                isNotNull(data)) {
                    onSetNewState(this, {
                        isSubmitting: false
                    }, () => {
                        if (isNotNull(eventHandlers)) {
                            eventHandlers.navigateTo(data, onboardingScreen.FINANCIAL_DETAILS)
                        }
                    })
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    onSubmit = (values) => {
        const { dispatch } = this.props
        const { isSubmitting, ...selectedValues } = this.state

        if (!isSubmitting) {            
            let data = {
                expLevel: selectedValues.expLevel,
                hasFuturesTradingExp: selectedValues.hasFuturesTradingExp,
                tradingPurpose: selectedValues.tradingPurpose,
                capital: selectedValues.capital,
                riskLevel: selectedValues.riskLevel,
                otherAssets: selectedValues.otherAssets
            }
            dispatch(onboardingActions.saveTradingExpDetails(data))

            onSetNewState(this, {
                isSubmitting: true,
                data
            })
        }
    }

    onValidate = (values) => {
        const errors = {}

        // validate exp level
        if (isNull(values.expLevel))
            errors.expLevel = "Please select your trading experience"
        
        if (isNull(values.tradingPurpose))
            errors.tradingPurpose = "Please select a purpose"

        if (isNull(values.capital))
            errors.capital = "Please select an estimate of your capital"

        if (isNull(values.riskLevel))
            errors.riskLevel = "Please select a risk level"

        return errors
    }

    getValue = (fieldName) => {
        const { 
            expLevel,
            hasFuturesTradingExp,
            tradingPurpose,
            capital,
            riskLevel,
            otherAssets
         } = this.state

        switch(fieldName) {
            default:
            case 'expLevel': return expLevel
            case 'hasFuturesTradingExp': return hasFuturesTradingExp
            case 'tradingPurpose': return tradingPurpose
            case 'capital': return capital
            case 'riskLevel': return riskLevel
            case 'otherAssets': return otherAssets
        }
    }

    isSelected = (selectedValue) => {
        const { otherAssets } = this.state
        if (isNotNull(selectedValue) && 
            isNotNull(otherAssets) && otherAssets.length > 0) {
            let doesExists = _.find(otherAssets, (value, index) => {
                return value === selectedValue
            })
            if (doesExists)
                return true
        }

        return false
    }

    handleSelectAnswer = (e) => {
        switch(e.target.name) {
            default:
            case 'expLevel': {
                onSetNewState(this, {
                    expLevel: e.target.value
                })
                break
            }
            case 'hasFuturesTradingExp':  {
                const { hasFuturesTradingExp } = this.state
                onSetNewState(this, {
                    hasFuturesTradingExp: hasFuturesTradingExp === 'YES' ? 'NO' : 'YES'
                })
                break
            }
            case 'tradingPurpose':  {
                onSetNewState(this, {
                    tradingPurpose: e.target.value
                })
                break
            }
            case 'capital':  {
                onSetNewState(this, {
                    capital: e.target.value
                })
                break
            }
            case 'riskLevel':  {
                onSetNewState(this, {
                    riskLevel: e.target.value
                })
                break
            }
        }
    }

    handleSelectMultiAnswer = (e) => {
        let selectedValue = e.target.name
        let isSelected = e.target.checked
        let { otherAssets } = this.state
        if (isNotNull(otherAssets) && otherAssets.length > 0) {
            let doesExists = _.find(otherAssets, (value, index) => {
                return value === selectedValue
            })
            if (doesExists) {
                if (!isSelected)
                    _.remove(otherAssets, (item) => item === selectedValue)
            } else {
                if (isSelected)
                    otherAssets.push(selectedValue)
            }
            
            onSetNewState(this, {
                otherAssets
            })
        } else {
            otherAssets.push(selectedValue)
            onSetNewState(this, {
                otherAssets
            })
        }
    }

    handleClickBack = (e) => {
        e.preventDefault()

        const { isSubmitting } = this.state
        if (!isSubmitting) {
            const { eventHandlers } = this.props
            if (isNotNull(eventHandlers)) {
                eventHandlers.navigateTo(null, onboardingScreen.BASIC_INFO)
            }
        }
    }

    renderQuestions = () => {
        let mappedQuestions = []
        const { questionnaire } = this.state
        if (isNotNull(questionnaire) && questionnaire.length > 0) {
            let questionNo = 0
            mappedQuestions = _.map(questionnaire, (value, key) => {
                questionNo++

                let thisValue = this.getValue(value.name)

                switch (value.type) {
                    default:
                    case 'dropdown': {
                        let mappedAnswers = _.map(value.answers, (item, itemKey) => {
                            return (<option key={itemKey} value={item.answerKey}>{item.answerText}</option>)
                        })

                        let selectionPlaceholder = ""
                        switch(value.name) {
                            default: 
                            case 'expLevel': {
                                selectionPlaceholder = "Select your trading experience"
                                break
                            }
                            case 'tradingPurpose': {
                                selectionPlaceholder = "Select a purpose"
                                break
                            }
                            case 'capital': {
                                selectionPlaceholder = "Select an estimate"
                                break
                            }
                            case 'riskLevel': {
                                selectionPlaceholder = "Select a risk level"
                                break
                            }
                        }

                        return (
                            <Form.Group key={key} controlId={value.id}>
                                <Col xs={12} sm={12} md={12} lg={12} className="p-0">
                                    <Form.Label>{questionNo}. {value.question}<span className='required'>*</span></Form.Label>
                                    <Field name={value.name} type="text">
                                        {({ input, meta }) => (
                                            <>
                                                <div 
                                                    className={`input-wrapper half ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                                    <Form.Control 
                                                        as="select" 
                                                        name={value.name} 
                                                        className={questionNo === 1 ? 'focus-on' : '' }
                                                        value={thisValue} 
                                                        onChange={(e) => this.handleSelectAnswer(e)}>
                                                        <option value="">-- select --</option>
                                                        {mappedAnswers}
                                                    </Form.Control>

                                                    { meta.error && meta.touched && 
                                                        <Form.Text>
                                                            {meta.error}
                                                        </Form.Text>
                                                    }
                                                </div>
                                            </>
                                        )}
                                    </Field>
                                </Col>
                            </Form.Group>
                        )
                    }
                    case 'close-ended': {
                        let thisValue = this.getValue(value.name)
                        let mappedAnswers = _.map(value.answers, (item, itemKey) => {
                            return (
                                <Field key={itemKey} type="radio" name={value.name} value={item.answerKey}>
                                    {({ input, meta }) => (
                                        <>
                                            <div className={`input-wrapper mr-5 ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                                <Form.Check 
                                                    {...input} 
                                                    id={`${value.name}-${item.answerKey}`} 
                                                    name={value.name} 
                                                    label={item.answerText} 
                                                    onChange={(e) => this.handleSelectAnswer(e)}
                                                    value={thisValue} 
                                                    checked={thisValue === item.answerKey} />                                               

                                                { meta.error && meta.touched && 
                                                    <Form.Text>
                                                        {meta.error}
                                                    </Form.Text>
                                                }
                                            </div>
                                        </>
                                    )}
                                </Field>
                            )
                        })

                        return (
                            <Form.Group key={key} controlId={value.id}>
                                <Col xs={12} sm={12} md={12} lg={12} className="p-0">
                                    <Form.Label>{questionNo}. {value.question}</Form.Label>
                                    <div className='d-flex close-ended'>
                                        {mappedAnswers}
                                    </div>
                                </Col>
                            </Form.Group>
                        )
                    }
                    case 'multi-select': {
                        let multiSelectLabel = "-- select --"
                        if (thisValue.length > 0) {
                            let selection = []

                            _.forEach(thisValue, (item, index) => {
                                let answer = _.find(value.answers, (v, i) => {
                                    return v.answerKey === item
                                })

                                if (answer) {
                                    selection.push(answer.answerText)
                                }
                            })

                            multiSelectLabel = selection.join(", ")
                        }

                        return (
                            <Form.Group key={key} controlId={value.id}>
                                <Col xs={12} sm={12} md={12} lg={12} className="p-0">
                                    <Form.Label>{questionNo}. {value.question}</Form.Label>
                                    <Field name={value.name} type="text">
                                        {({ input, meta }) => (
                                            <>
                                                <div 
                                                    className={`input-wrapper multi-selection ${meta.error && meta.touched ? 'is-invalid' : ''}`}>                                                    
                                                    <Dropdown>
                                                        <Dropdown.Toggle id="dropdown-assets">
                                                            <span className='selected-options'>{multiSelectLabel}</span>    
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            { value.answers.map((answerValue, item) => (
                                                                <div className='dropdown-menuitem' key={item}>
                                                                    <Form.Check 
                                                                        custom
                                                                        type="checkbox"
                                                                        id={`${value.name}-${answerValue.answerKey}`}
                                                                        name={answerValue.answerKey} 
                                                                        label={answerValue.answerText}
                                                                        onChange={this.handleSelectMultiAnswer} 
                                                                        checked={this.isSelected(answerValue.answerKey)}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </>
                                        )}
                                    </Field>
                                </Col>
                            </Form.Group>
                        )
                    }
                }
            })
        }

        return (
            <>{mappedQuestions}</>
        )
    }

    renderLoader = () => (
        <>
            <div style={{ width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="pf-spinner btn"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        </> 
    )

    render() {
        let { ...state } = this.state

        return (
            <FinalForm 
                onSubmit={this.onSubmit}
                validate={this.onValidate} 
                decorators={[this.formDecorator]} 
                initialValues={{
                    ...state
                }} 
                render={({ handleSubmit }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <FormBodyContent className='trading-exp-content'>
                                <div className='graphic trading-exp'>
                                    {/* <img src='../images/form-graphics/onboarding-trading-exp.png' /> */}
                                </div>

                                <div className='form trading-exp'>
                                    <h2 className='form-title'>Your trading experience</h2>
                                    <h3 className='form-instruction'>Tell us about your past trading experience</h3>

                                    {this.renderQuestions()}

                                    <Form.Group className='mb-0 notice-wrapper'>
                                        <p className='notice'>
                                            <FontAwesomeIcon size="xs" className='icon' icon={faExclamationCircle} /> 
                                            <span>Your privacy is our priority. All answers are confidential and we do not share with 3rd parties.<br />
                                            Your answers will help us send you the right education and information about our platform.</span>
                                        </p>
                                    </Form.Group>
                                </div>
                            </FormBodyContent>

                            <FormActions>
                                <TextButton 
                                    textColor="#8A9BAE"
                                    size="sm" 
                                    className='default-btn back-btn'
                                    onClick={(e) => this.handleClickBack(e)}
                                    disabled={state.isSubmitting}>
                                        <FontAwesomeIcon size="xs" className='icon' icon={faArrowLeft} /> 
                                        <span>Back</span>
                                </TextButton>

                                <PrimaryButton 
                                    type="submit" 
                                    size="sm"> 
                                        { state.isSubmitting && this.renderLoader() }
                                        { !state.isSubmitting && 
                                            <>
                                                <FontAwesomeIcon size="xs" className='icon' icon={faArrowRight} /> 
                                                <span>Next</span>
                                            </>
                                        }
                                </PrimaryButton>
                            </FormActions> 
                        </Form>
                    )
                }}
            />
        )  
    }
}

function mapStateToProps(state) {
    let { saveOnboardingUserTradingExpDetails } = state
    return {
        saveOnboardingUserTradingExpDetails
    }
}

const connectedTradingExperience = connect(mapStateToProps)(TradingExperience)
export { connectedTradingExperience as TradingExperience }