import { endpoints, baseUrl } from './CONSTANTS'
import { authServices } from './authService'
import { isNotNull } from '../utils'

const { v3 } = endpoints

function signOutDevices(data) {
    const requestOpts = {
        method: 'POST',        
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        //headers: header.addAuthorizationHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${baseUrl}${v3.auth.devices.signOutOthers}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            if (isNotNull(response) &&
                isNotNull(response.succeeded) &&
                response.succeeded === true) {
                // remove device type status
                localStorage.removeItem('profitfarmers.type')
            }
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function validateSignOut(deviceType) {
    const requestOpts = {
        method: 'GET',                
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${v3.auth.devices.validate(deviceType)}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        if (res.status === 401)
            authServices.logout()
        else
            return data
    })
}

export const deviceServices = {
    signOutDevices,
    validateSignOut
}