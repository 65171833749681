import _ from 'lodash'
import { isNotNull } from "./object"

export function setCoinLogo(coin) {
    let coinSrc = ""
    if (isNotNull(coin)) {
        if (coin.toLowerCase() === "adx") {
            coinSrc = "https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/adex.png"
        }
        else if (coin.toLowerCase() === "default") {
            coinSrc = "https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/generic2.png"
        }
        else {
            coinSrc = `https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/${coin.toLowerCase()}.png`
        }
    }
    return coinSrc
}

export function setDefaultImage(e) {
    e.target.src = "https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/generic2.png"
}

export const setDefaultProfileImage = (e) => {
    e.target.src = "/images/def-user.png"
}

export const getCoin = (coinName) => {
    let coin = "generic2"
    if (isNotNull(coinName)) {
        if (_.endsWith(coinName, "USDT") || _.endsWith(coinName, "USDT"))
            coin = coinName.replace("USDT", "")

        if (_.endsWith(coinName, "BTC"))
            coin = coinName.replace("BTC", "")

        if (coinName === "BTCBBTC")
            coin = "BTCB"
    } else
        coin = "generic2"
    return coin.toLowerCase()
}