import { Component } from 'react'
import { connect } from 'react-redux'
import jQuery from 'jquery'
import queryString from 'query-string'
import iziToast from 'izitoast'
import { authActionTypes } from '../../redux/CONSTANTS'
import { userActions } from '../../redux/actions'
import { ChangeEmailView } from './ChangeEmailView'
import { isNotNull, onSetNewState, addPageViewEvent, seo } from '../../utils'

class ChangeEmailContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: null,
            newEmail: null,
            isSubmitted: false
        }

        this.mounted = false
        this.baseState = this.state
    }

    componentDidMount() {
        this.mounted = true
        
        seo({
            title: "Change Email Address",
            metaDescription: ""
        })
        addPageViewEvent()

        const { dispatch } = this.props
        dispatch({ type: authActionTypes.CLEAR_AUTH })
        localStorage.clear()

        const val = queryString.parse(this.props.location.search)
        this.setState({ email: val.email })
        
        jQuery('body').css('height', '100%')
    }

    componentDidUpdate(prevProps) {
        if (this.mounted === true) {
            const { history, forms } = this.props
            if (prevProps.forms !== forms) {
                const { loading, response, error } = forms
                if (isNotNull(error)) {
                    onSetNewState(this, { isSubmitted: false })
                    iziToast.error({
                        position: 'topRight',
                        title: 'Error',
                        message: "Unkown error occurred! Please try again!",
                        displayMode: 1,
                    })
                }

                if (!loading && isNotNull(response) && isNotNull(response.success)) {
                    if (response.success) {
                        const email = this.state.newEmail
                        iziToast.success({
                            timeout: 3000,
                            position: 'topRight',
                            title: 'Success',
                            message: `${response.msg}. Redirecting to Login Page`,
                            displayMode: 1,
                            onClosing: () => {
                                localStorage.setItem('rememberMe', true);
                                localStorage.setItem('user', email)
                                history.push('/account/login')
                            }
                        })
                    }
                    else {
                        onSetNewState(this, { isSubmitted: false })
                        iziToast.error({
                            position: 'topRight',
                            title: 'Error',
                            message: `${isNotNull(response.msg) ? response.msg : "Unkown error occurred! Please try again!"}`,
                            displayMode: 1,
                        })
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        this.setState(this.baseState)
        this.mounted = false
    }

    onSubmitChangeEmailHandler = (data) => {
        onSetNewState(this, { isSubmitted: true })
        const val = queryString.parse(this.props.location.search)
        const userId = val.userId
        const codeParams = val.code
        const code = codeParams.replace(/ /g, "+")

        if (isNotNull(data) && isNotNull(data.newEmail)) {
            const requestData = {
                userId,
                email: data.newEmail,
                code
            }
            onSetNewState(this, {newEmail: data.newEmail})
            this.props.dispatch(userActions.changeEmail(requestData))
        }
    }

    render() {
        return (
            <ChangeEmailView
                state={this.state}
                submit={this.onSubmitChangeEmailHandler}
            />
        )
    }
}


function mapStateToProps(state) {
    let { forms } = state
    return {
        forms
    }
}

const connectedChangeEmailContainer = connect(mapStateToProps)(ChangeEmailContainer)
export { connectedChangeEmailContainer as ChangeEmailContainer }
