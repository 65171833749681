import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card, Collapse, Form, Col, FormLabel, Alert, OverlayTrigger, Tooltip, SafeAnchor } from 'react-bootstrap'
import { ThemeProvider } from '@material-ui/styles'
import { createTheme } from '@material-ui/core/styles'
import { Slider } from '@material-ui/core'
import _ from 'lodash'
import Swal from 'sweetalert2'
import jQuery from 'jquery'
import { tradeOrderActions } from '../../../../redux/actions'
import { OverlayTooltip } from '../../../../components'
import { TakeProfitForm } from './TakeProfitForm'
import {
    TradeFormBody, TradeFormInner, FormHorizontal, FormTitle,
    ToggleButton, TradeFormActions, TradeFormConfirmBtn, TargetPriceInput, TargetAllocationInput
} from '../../styled_components'
import { ThemeSwitchWrapper } from '../../../../components/PageHeader/styled_components'
import { onSetNewState, isNull, isNotNull, toUsdFormat, toBtcFormat, validatePrice, ScientificToDecimal } from '../../../../utils'
import { minOrderConfig, tradeFormErrorMessages, defaultTargetPricesQty } from '../../../../utils/CONSTANTS'
import { faListCheck } from '@fortawesome/free-solid-svg-icons'

class BasicForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isSubmitting: false,
            isShowingTradeSummary: false,
            tradeStrategy: null,
            baseOrderDetails: null,
            isToUseLastPrice: false,
            // form sections
            showStopBuyConfig: true,
            showTargetPricesConfig: false,
            showStopLossConfig: false,
            showAdvancedConfig: false,
            // wallet section
            hasWallet: null,
            // input fields
            leverage: null,
            isValidatingLeverage: false,
            isNotValidLeverage: false,
            leverageErrMsg: "",
            marginType: "",
            isValidatingMarginType: false,
            isNotValidMarginType: false,
            marginTypeErrMsg: "",
            // stop buy section
            usdQtyElement: null,
            usdQtyValue: "",
            isNotValidUsdQty: false,
            usdtQtyErrTitle: "",
            usdQtyErrMsg: "",
            isBuyNow: false,
            isLadderingEnabled: false,
            isEntryPriceInitialized: false,
            entryPriceValue: "",
            baseLadderedEntryPrices: [],
            entryPrices: [],
            isNotValidEntryPrice: false,
            entryPriceErrMsg: "",
            hasLadderedEntriesError: false,
            ladderedEntriesErrMsg: "",
            isValidLadderedEntryPrices: false,
            hasLadderedEntriesQtyError: false,
            ladderedEntriesQtyErrorMsg: "",
            altQtyValue: 0,
            btcValue: 0,
            isNotValidAltQty: false,
            altQtyErrMsg: "",
            // take profit section
            baseTargetPrices: [],
            targetPrices: [],
            isValidatingTargets: false,
            isToValidateTargets: false,
            // stop loss section
            stopLossPriceValue: "",
            isNotValidStopLossPrice: false,
            stopLossPriceErrMsg: "",
            isBreakevenApplicable: true,
            enableBreakeven: false,
            breakevenHasBeenOverrided: false,
            priceTypeValue: 'loading...',
            breakevenTypeValue: '',
            breakeventTypeHasBeenOverrided: false,
            isToUpdateTargetsPrices: false,
            isValidQuantity: false,
            isValidEntryPrice: false,
            isValidTargetPrices: false,
            isValidStopLoss: false
        }
        this.baseState = this.state

        this.qtyInUsdtTypingInterval = null
        this.entryPriceTypingInterval = null
        this.entryPriceQtyTypingInterval = null
        this.qtyInAltTypingInterval = null
        this.targetPriceTypingInterval = null
        this.stopLossPriceTypingInterval = null
        this.leverageTypingInterval = null
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
    }

    componentDidUpdate(prevProps, prevState) {
        const { 
            fromSym, 
            symbol, 
            isPremiumUser, 
            isFree,
            feed, 
            lastPrice, 
            btcUsdtLastPrice, 
            direction, 
            orderDetails, 
            isSendingOrder, 
            leverageSettings, 
            dispatch,
            eventHandlers,
            signalInfo } = this.props
        const { primaryOrder, secondaryOrder } = orderDetails 

        const { 
            usdQtyValue, 
            targetPrices, 
            breakevenHasBeenOverrided,
            breakeventTypeHasBeenOverrided } = this.state

        let { 
            isToUseLastPrice, 
            altQtyValue, 
            isLadderingEnabled, 
            tradeStrategy, 
            leverage, 
            isValidatingLeverage, 
            baseOrderDetails,
            isEntryPriceInitialized, 
            entryPriceValue,
            baseTargetPrices, 
            stopLossPriceValue,
            isSubmitting,
            isShowingTradeSummary,
            enableBreakeven,
            breakevenTypeValue } = this.state

        let basePrimaryOrder = null

        if (isSubmitting !== isSendingOrder) {
            onSetNewState(this, {
                isSubmitting: isSendingOrder
            })
        }
        
        if (isShowingTradeSummary !== isSendingOrder) {
            onSetNewState(this, {
                isShowingTradeSummary: isSendingOrder
            })
        }

        if (isNull(baseOrderDetails)) {
            if (isNotNull(feed) &&
                isNotNull(direction) &&
                isNotNull(orderDetails) &&
                primaryOrder.stopPrice !== 0) {
                let { order1, order2 } = secondaryOrder

                onSetNewState(this, {
                    baseOrderDetails: _.cloneDeep(orderDetails),
                    entryPriceValue: primaryOrder.stopPrice,
                })

                if (feed.toUpperCase() === "SPOT") {
                    onSetNewState(this, {
                        stopLossPriceValue: order2.stopPrice,
                        enableBreakeven: order2.breakeven,
                        priceTypeValue: order2.priceType
                    }, () => {
                        eventHandlers.onChangeMyStoplossPriceHandler(order2.stopPrice)
                    })
                } else {
                    if (direction.toUpperCase() === "BUY") {
                        onSetNewState(this, {
                            stopLossPriceValue: order1.stopPrice,
                            enableBreakeven: order1.breakeven,
                            priceTypeValue: order1.priceType,
                            marginType: "CROSSED"
                        }, () => {
                            eventHandlers.onChangeMyStoplossPriceHandler(order1.stopPrice)
                        })
                    } else {
                        onSetNewState(this, {
                            stopLossPriceValue: order2.stopPrice,
                            enableBreakeven: order2.breakeven,
                            priceTypeValue: order2.priceType,
                            marginType: "CROSSED"
                        }, () => {
                            eventHandlers.onChangeMyStoplossPriceHandler(order2.stopPrice)
                        })
                    }
                }
            }
        } else {
            basePrimaryOrder = baseOrderDetails.primaryOrder
        }

        if (isNull(tradeStrategy)) {
            if (isNotNull(orderDetails.tradeStrategy)) {
                onSetNewState(this, {
                    tradeStrategy: orderDetails.tradeStrategy,
                    baseTargetPrices: []
                })
            }
        } else {
            if (tradeStrategy !== orderDetails.tradeStrategy) {
                if (entryPriceValue !== primaryOrder.stopPrice) {
                    onSetNewState(this, {
                        entryPriceValue: primaryOrder.stopPrice,
                        altQtyValue
                    }, () => {
                        this.onCalculateTotalAltCoin(parseFloat(primaryOrder.stopPrice))
                    })
                }

                switch(orderDetails.tradeStrategy) {
                    default: {
                        isLadderingEnabled = true
                        break
                    }
                    case 'sniper': {
                        if (isLadderingEnabled)
                            isLadderingEnabled = false 
                        else 
                            isLadderingEnabled = true

                        break
                    }
                    case 'stronguptrend': {
                        isLadderingEnabled = true
                        break
                    }
                }

                onSetNewState(this, {
                    tradeStrategy: orderDetails.tradeStrategy,
                    baseTargetPrices: [],
                    isToUpdateTargetsPrices: true,
                    isLadderingEnabled
                }, () => {
                    if (isLadderingEnabled) this.onCalculateLadderedEntries()
                    this.onSetDefaultBreakevenValue(orderDetails.tradeStrategy)
                    this.onSetDefaultBreakevenTypeValue(orderDetails.tradeStrategy)
                    this.onSetDefaultStoploss(
                        signalInfo.feed, 
                        signalInfo.direction, 
                        orderDetails.tradeStrategy,
                        fromSym,
                        signalInfo.entryStartPrice, 
                        signalInfo.entryEndPrice,
                        signalInfo.stopLoss)
                })
            }
        }

        if (isNull(leverage) && isNotNull(orderDetails.leverage)) {
            onSetNewState(this, {
                leverage: orderDetails.leverage
            }, () => {
                dispatch(tradeOrderActions.setLeverage(symbol, Number(orderDetails.leverage)))
            })
        }

        if (!isEntryPriceInitialized) {
            if (isNull(entryPriceValue) && isNotNull(primaryOrder.stopPrice) && primaryOrder.stopPrice !== 0) {
                onSetNewState(this, {
                    isEntryPriceInitialized: true,
                    entryPriceValue: primaryOrder.stopPrice,
                    isLadderingEnabled: isNotNull(tradeStrategy) && tradeStrategy === 'sniper' ? false : true,
                    isToUseLastPrice: false,
                }, () => {
                    this.onCalculateTotalAltCoin(lastPrice)
                })
            }
        } else {
            if (isNotNull(basePrimaryOrder) && basePrimaryOrder.stopPrice !== primaryOrder.stopPrice) {
                basePrimaryOrder = _.clone(primaryOrder)
                baseOrderDetails.primaryOrder = basePrimaryOrder

                onSetNewState(this, {
                    entryPriceValue: parseFloat(primaryOrder.stopPrice),
                    baseOrderDetails
                }, () => { 
                    this.onValidateEntryPriceHandler(parseFloat(primaryOrder.stopPrice))                   
                    this.onCalculateTotalAltCoin(parseFloat(primaryOrder.stopPrice))
                })
            }
        }

        if (isNotNull(feed) && isNotNull(direction) && baseTargetPrices.length === 0) {
            let { order1, order2 } = secondaryOrder
            let targetPrices = null

            if (feed.toUpperCase() === "SPOT") {
                let { targets } = order1
                targetPrices = targets
            } else {
                if (direction.toUpperCase() === "BUY") {
                    let { targets } = order2
                    targetPrices = targets
                } else {
                    let { targets } = order1
                    targetPrices = targets
                }
            }

            if (isNotNull(targetPrices)) {
                let showCompleteTargets = true

                if (isFree && !isPremiumUser)
                    showCompleteTargets = false

                // console.log('showCompleteTargets: ', showCompleteTargets)
                // console.log('targetPrices: ', targetPrices)

                if (showCompleteTargets) {
                    targetPrices.forEach((i) => {
                        baseTargetPrices.push({
                            id: i.id,
                            index: i.index,
                            stopPrice: i.stopPrice,
                            quantity: i.quantity,
                            isNotValid: false,
                            errMsg: '',
                            isQtyNotValid: false
                        })
                    })
                } else {
                    let singleTarget = targetPrices[0]
                    if (isNotNull(singleTarget)) {
                        baseTargetPrices.push({
                            id: singleTarget.id,
                            index: singleTarget.index,
                            stopPrice: singleTarget.stopPrice,
                            quantity: 100,
                            isNotValid: false,
                            errMsg: '',
                            isQtyNotValid: false
                        })
                    }
                }

                onSetNewState(this, {
                    baseTargetPrices
                })
            }
        }

        if (isNotNull(targetPrices)) {
            if (targetPrices.length < 2) {
                if (enableBreakeven) {
                    onSetNewState(this, {
                        isBreakevenApplicable: false,
                        enableBreakeven: false,
                        breakevenTypeValue: '',
                        breakevenHasBeenOverrided: false,
                        breakeventTypeHasBeenOverrided: false
                    })
                }
            } else {
                if (!enableBreakeven) {
                    if (!breakevenHasBeenOverrided) {
                        onSetNewState(this, {
                            isBreakevenApplicable: true,
                            enableBreakeven: true,
                            breakevenTypeValue: ''
                        })
                    }
                } else {
                    if (isNotNull(orderDetails.tradeStrategy) && !breakeventTypeHasBeenOverrided) {
                        switch(orderDetails.tradeStrategy) {
                            default: {
                                if (breakevenTypeValue !== '') {
                                    onSetNewState(this, {
                                        breakevenTypeValue: ''
                                    })
                                }
                                break
                            }
                            case 'sniper': {
                                if (breakevenTypeValue !== 1) {
                                    onSetNewState(this, {
                                        breakevenTypeValue: 1
                                    })
                                }
                                break
                            }
                            case 'weakuptrend': {
                                if (breakevenTypeValue !== 2) {
                                    onSetNewState(this, {
                                        breakevenTypeValue: 2
                                    })
                                }
                                break
                            }
                        }
                    }
                }
            }
        }

        if (isNotNull(leverageSettings) && isValidatingLeverage) {
            const { items } = leverageSettings
            if (isNotNull(items)) {
                const { success, data } = items
                if (success) {
                    onSetNewState(this, {
                        isNotValidLeverage: false,
                        isValidatingLeverage: false
                    })
                    dispatch(tradeOrderActions.clearLeverage())
                } else {
                    onSetNewState(this, {
                        isNotValidLeverage: true,
                        isValidatingLeverage: false,
                        leverageErrMsg: data
                    })
                    dispatch(tradeOrderActions.clearLeverage())
                }
            }
        }

        if (prevState.isLadderingEnabled !== isLadderingEnabled) {
            this.onCalculateLadderedEntries()
        }
        
        if (isToUseLastPrice && 
            prevProps.lastPrice !== lastPrice) {
            if (isNotNull(usdQtyValue) && isNotNull(lastPrice)) {
                if (fromSym === 'BTC') {
                    if (isNotNull(btcUsdtLastPrice) && isNotNull(lastPrice)) {
                        let totalCoinValue = parseFloat(usdQtyValue / btcUsdtLastPrice)
                        if (totalCoinValue > 0) {
                            totalCoinValue = totalCoinValue / lastPrice
                        }
    
                        altQtyValue = toBtcFormat(totalCoinValue) //toBtcFormat(totalCoinValue)
                    }
                } else {
                    if (isNotNull(lastPrice)) {
                        let totalCoinValue = parseFloat(usdQtyValue) / lastPrice
                        altQtyValue = toBtcFormat(totalCoinValue) //toBtcFormat(totalCoinValue) //toUsdFormat()
                    }
                }
                
                onSetNewState(this, {
                    altQtyValue
                })
            }
        } 
    }

    componentWillUnmount() {
        clearTimeout(this.qtyInUsdtTypingInterval)
        this.qtyInUsdtTypingInterval = null

        clearTimeout(this.qtyInAltTypingInterval)
        this.qtyInAltTypingInterval = null

        clearTimeout(this.entryPriceTypingInterval)
        this.entryPriceTypingInterval = null

        clearTimeout(this.targetPriceTypingInterval)
        this.targetPriceTypingInterval = null

        clearTimeout(this.leverageTypingInterval)
        this.leverageTypingInterval = null

        this.mounted = false
    }

    onToggleTargetDetails = () => {
        let { showTargetPricesConfig } = this.state
        onSetNewState(this, {
            showTargetPricesConfig: !showTargetPricesConfig
        })
    }

    onToggleStopLossDetails = () => {
        let { showStopLossConfig } = this.state
        onSetNewState(this, {
            showStopLossConfig: !showStopLossConfig
        })
    }

    onToggleAdvancedOptions = () => {
        let { showAdvancedConfig } = this.state
        onSetNewState(this, {
            showAdvancedConfig: !showAdvancedConfig
        })
    }

    onChangeSliderHandler = (e, newValue) => {
        const { symbol, dispatch } = this.props

        if (this.leverageTypingInterval)
            clearTimeout(this.leverageTypingInterval)

        if (isNaN(newValue)) {
            onSetNewState(this, {
                leverage: 1
            })
        }

        if (Number(newValue) < 1) {
            onSetNewState(this, {
                leverage: 1
            })
        } else {
            onSetNewState(this, {
                leverage: Number(newValue),
                isValidatingLeverage: true
            }, () => {
                this.leverageTypingInterval = setTimeout(() => {
                    dispatch(tradeOrderActions.setLeverage(symbol, Number(newValue)))
                }, 800)
            })
        }
    }

    onChangeQtyInUsdtHandler = (e) => {
        e.preventDefault()
        
        const { hasConnectedExchange, wallet, fromSym } = this.props

        if (this.qtyInUsdtTypingInterval)
            clearTimeout(this.qtyInUsdtTypingInterval)

        const newValue = e.target.value
        let { btcValue } = this.state

        console.log('hasConnectedExchange: ', hasConnectedExchange)
        console.log('wallet: ', wallet)
        
        if (isNull(wallet)) {
            if (hasConnectedExchange) {
                if (isNotNull(newValue)) {
                    const { btcUsdtLastPrice, fromSym } = this.props
                    if (isNotNull(fromSym) && isNotNull(btcUsdtLastPrice)) {
                        // if symbol ends with BTC
                        if (fromSym === 'BTC') {
                            // calculating total qty in USD
                            btcValue = toBtcFormat(parseFloat(newValue / btcUsdtLastPrice))
                        }
                        else
                            btcValue = 0
                    }

                    onSetNewState(this, {
                        hasWallet: false,
                        usdQtyValue: newValue,
                        btcValue
                    })

                    this.qtyInUsdtTypingInterval = setTimeout(() => {
                        this.onValidateQtyInUsdtHandler(newValue, true)
                    }, 800)
                }
            } else {
                if (isNotNull(newValue)) {
                    const { btcUsdtLastPrice, fromSym } = this.props
                    if (isNotNull(fromSym) && isNotNull(btcUsdtLastPrice)) {
                        // if symbol ends with BTC
                        if (fromSym === 'BTC') {
                            // calculating total qty in USD
                            btcValue = toBtcFormat(parseFloat(newValue / btcUsdtLastPrice))
                        }
                        else
                            btcValue = 0
                    }

                    onSetNewState(this, {
                        hasWallet: true,
                        usdQtyValue: newValue,
                        btcValue
                    })

                    this.qtyInUsdtTypingInterval = setTimeout(() => {
                        this.onValidateQtyInUsdtHandler(newValue, true)
                    }, 800)
                }
            }
        } else {
            if (isNotNull(newValue)) {
                const { btcUsdtLastPrice, fromSym } = this.props
                if (isNotNull(fromSym) && isNotNull(btcUsdtLastPrice)) {
                    // if symbol ends with BTC
                    if (fromSym === 'BTC') {
                        // calculating total qty in USD
                        btcValue = toBtcFormat(parseFloat(newValue / btcUsdtLastPrice))
                    }
                    else
                        btcValue = 0
                }
    
                onSetNewState(this, {
                    hasWallet: true,
                    usdQtyValue: newValue,
                    btcValue
                })
    
                this.qtyInUsdtTypingInterval = setTimeout(() => {
                    this.onValidateQtyInUsdtHandler(newValue, true)
                }, 800)
            } else
                onSetNewState(this, {
                    hasWallet: true,
                    usdtQtyErrTitle: isNotNull(fromSym) && fromSym !== 'BTC' ? `Invalid ${fromSym} amount` : "Invalid USDT amount",
                    usdQtyErrMsg: tradeFormErrorMessages.quantity.invalidInput,
                    isNotValidUsdQty: true,
                    altQtyValue: newValue,
                    usdQtyValue: newValue,
                    btcValue: 0
                })
        }
    }

    onValidateQtyInUsdtHandler = async (value = "", updateAlt) => {
        const { lastPrice, btcUsdtLastPrice, fromSym, wallet, orderDetails } = this.props
        const { entryPriceValue, leverage, targetPrices, isLadderingEnabled, isBuyNow } = this.state

        let feed = ""
        if (isNotNull(orderDetails) && isNotNull(orderDetails.feed))
            feed = orderDetails.feed.toUpperCase()

        if (value === "") {
            jQuery('#totalInUsdt').focus()
        } else {
            let hasError = false
            let errTitle = ""
            let errMsg = ""

            if (!hasError && isNaN(parseFloat(value))) {
                hasError = true
                errTitle =  fromSym === 'USDT' ? "Invalid USDT amount" : "Invalid BUSD amount"
                errMsg = tradeFormErrorMessages.quantity.invalidInput
            }

            if (!hasError && (parseFloat(value) <= 0)) {
                hasError = true
                errTitle = fromSym === 'USDT' ? "Invalid USDT amount" : "Invalid BUSD amount"
                errMsg = tradeFormErrorMessages.quantity.invalidInput
            }

            if (!hasError && isNotNull(feed)) {
                if (feed === "FUTURES") {
                    if (fromSym.includes("USD")) {
                        if (!hasError && (parseFloat(value) < minOrderConfig.USDTFUTURES)) {
                            hasError = true
                            errTitle = "Amount too low"
                            errMsg = tradeFormErrorMessages.quantity.mustBeAboveMinUsdOrder(fromSym, minOrderConfig.USDTFUTURES)
                        }
                        
                        if (!hasError && ((parseFloat(value) / targetPrices.length) < minOrderConfig.USDTFUTURES)) {
                            hasError = true
                            errTitle = "Amount too low"
                            errMsg = tradeFormErrorMessages.quantity.notEnough
                        }
                        
                        if (!hasError && 
                            fromSym === 'USDT' &&
                            isNotNull(wallet) &&
                            isNotNull(wallet.usdt) &&
                            isNotNull(wallet.usdt.balance)) {
                            let totalCost = parseFloat(value) / parseFloat(leverage)
                            if (totalCost > parseFloat(wallet.usdt.balance.free)) {
                                hasError = true
                                errTitle = "Not enough balance"
                                errMsg = tradeFormErrorMessages.balance.notEnough("USDT")
                            }
                        }

                        if (!hasError && 
                            fromSym === 'BUSD' && 
                            isNotNull(wallet) &&
                            isNotNull(wallet.busd) &&
                            isNotNull(wallet.busd.balance)) {
                            let totalCost = parseFloat(value) / parseFloat(leverage)
                            if (totalCost > parseFloat(wallet.busd.balance.free)) {
                                hasError = true
                                errTitle = "Not enough balance"
                                errMsg = tradeFormErrorMessages.balance.notEnough("BUSD")
                            }
                        }
                    }
                } else {
                    if (fromSym.includes("USD")) {
                        if (!hasError && (parseFloat(value) < minOrderConfig.USDT)) {
                            hasError = true
                            errTitle = "Amount too low"
                            errMsg = tradeFormErrorMessages.quantity.mustBeAboveMinUsdOrder(fromSym, minOrderConfig.USDT)
                        }

                        if (!hasError && ((parseFloat(value) / targetPrices.length) < minOrderConfig.USDT)) {
                            hasError = true
                            errTitle = "Amount too low"
                            errMsg = tradeFormErrorMessages.quantity.notEnough
                        }

                        if (!hasError && 
                            fromSym === 'USDT' && 
                            isNotNull(wallet) &&
                            isNotNull(wallet.usdt) &&
                            isNotNull(wallet.usdt.balance)) {
                            if (parseFloat(value) > parseFloat(wallet.usdt.balance.free)) {
                                hasError = true
                                errTitle = "Not enough balance"
                                errMsg = tradeFormErrorMessages.balance.notEnough("USDT")
                            }
                        }

                        if (!hasError && 
                            fromSym === 'BUSD' && 
                            isNotNull(wallet) &&
                            isNotNull(wallet.busd) &&
                            isNotNull(wallet.busd.balance)) {
                            if (parseFloat(value) > parseFloat(wallet.busd.balance.free)) {
                                hasError = true
                                errTitle = "Not enough balance"
                                errMsg = tradeFormErrorMessages.balance.notEnough("BUSD")
                            }
                        }
                    } else {
                        if (isNotNull(fromSym) && isNotNull(btcUsdtLastPrice)) {
                            // if symbol ends with BTC
                            if (fromSym === 'BTC') {
                                // calculating total qty in USD
                                let btcValue = toBtcFormat(parseFloat(value / btcUsdtLastPrice))
                                if (!hasError && (parseFloat(btcValue) < minOrderConfig.BTC)) {
                                    hasError = true
                                    errTitle = "Amount too low"
                                    errMsg = tradeFormErrorMessages.quantity.mustBeAboveMinBtcOrder(minOrderConfig.BTC)
                                }

                                if (!hasError && ((parseFloat(btcValue) / targetPrices.length) < minOrderConfig.BTC)) {
                                    hasError = true
                                    errTitle = "Amount too low"
                                    errMsg = tradeFormErrorMessages.quantity.notEnough
                                }

                                if (!hasError &&
                                    isNotNull(wallet) &&
                                    isNotNull(wallet.btc) &&
                                    isNotNull(wallet.btc.balance)) {
                                    if (parseFloat(btcValue) > parseFloat(wallet.btc.balance.free)) {
                                        hasError = true
                                        errTitle = "Not enough balance"
                                        errMsg = tradeFormErrorMessages.balance.notEnough("BTC")
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if (!isLadderingEnabled && !isBuyNow)
                this.onCalculateTotalAltCoin(entryPriceValue, updateAlt)
            else
                this.onCalculateTotalAltCoin(lastPrice, updateAlt)

            // if (isLadderingEnabled)
            //     this.onCalculateTotalAltCoin(lastPrice, updateAlt)
            // else {
            //     if (isBuyNow)
            //         this.onCalculateTotalAltCoin(lastPrice, updateAlt, true)
            //     else
            //         this.onCalculateTotalAltCoin(entryPriceValue, updateAlt)
            // }

            onSetNewState(this, {
                usdtQtyErrTitle: errTitle,
                usdQtyErrMsg: errMsg,
                isNotValidUsdQty: hasError,
                //altQtyValue,
                isValidQuantity: !hasError,
                //isToValidateTargets: true // TODO move this into entry validation
            })

            if (!hasError) {
                if (isLadderingEnabled)
                    this.onValidateLadderedEntryPrices()
                else
                    await this.onValidateEntryPriceHandler(parseFloat(entryPriceValue))
            }

            return hasError
        }
    }

    onEnableBuyNow = (e) => {
        const { lastPrice, eventHandlers } = this.props
        const { entryPriceValue, isBuyNow, isToUseLastPrice, isLadderingEnabled } = this.state
        let newValue = !isBuyNow
        let newSetting = !isToUseLastPrice

        onSetNewState(this, {
            isBuyNow: newValue,
            isToUseLastPrice: newSetting
        }, () => {
            this.onValidateEntryPriceHandler(entryPriceValue)

            if (!isLadderingEnabled) {
                if (newValue) {
                    eventHandlers.onChangeMyEntryPriceHandler(lastPrice)
                } else {
                    eventHandlers.onChangeMyEntryPriceHandler(entryPriceValue)
                }
            }
        })
    }

    onEnableLaddering = (e) => {
        const { lastPrice, eventHandlers } = this.props
        const { entryPriceValue, isLadderingEnabled, isToUseLastPrice } = this.state 

        let newValue = !isLadderingEnabled
        let newSetting = !isToUseLastPrice
        onSetNewState(this, {
            isLadderingEnabled: newValue,
            isToUseLastPrice: newSetting
        }, () => {
            if (newValue) {
                this.onCalculateTotalAltCoin(lastPrice)
            }
            else
                this.onCalculateTotalAltCoin(entryPriceValue)
                eventHandlers.onChangeMyEntryPriceHandler(entryPriceValue)
        })
    }

    onChangeEntryPriceHandler = (e) => {
        e.preventDefault()

        const { usdQtyValue } = this.state
        const { eventHandlers } = this.props

        if (isNull(usdQtyValue)) {
            this.onValidateQtyInUsdtHandler()
        } else {
            if (this.entryPriceTypingInterval)
                clearTimeout(this.entryPriceTypingInterval)

            let newValue = e.target.value
            onSetNewState(this, {
                entryPriceValue: newValue
            }, () => {
                eventHandlers.onChangeMyEntryPriceHandler(newValue)
                this.onCalculateTotalAltCoin(newValue)
            })

            let { isEntryPriceInitialized } = this.state
            if (isEntryPriceInitialized && (isNull(newValue) || isNaN(newValue))) {
                onSetNewState(this, {
                    isNotValidEntryPrice: true,
                    entryPriceErrMsg: tradeFormErrorMessages.entryPrice.invalidInput
                })

                return
            }

            this.entryPriceTypingInterval = setTimeout(() => {
                if (isNotNull(newValue) && !isNaN(newValue)) {
                    this.onValidateEntryPriceHandler(newValue)
                }
            }, 800)
        }
    }

    onValidateEntryPriceHandler = async (value) => {
        const { askPrice, bidPrice, orderDetails, direction } = this.props
        const { isBuyNow, usdQtyValue } = this.state
        const { primaryOrder } = orderDetails
        let dir = direction.toLowerCase()

        if (isNull(usdQtyValue)) {
            this.onValidateQtyInUsdtHandler()
        } else {
            let hasError = false
            let errMsg = ""

            if (!hasError && (parseFloat(value) <= 0)) {
                hasError = true
                errMsg = tradeFormErrorMessages.entryPrice.invalidInput
            }

            if (!hasError) {
                if (!isBuyNow) {
                    if (dir === 'buy') {
                        if (primaryOrder.stopCondition === 'lte') {
                            if (!hasError && (parseFloat(value) > askPrice)) {
                                hasError = true
                                errMsg = tradeFormErrorMessages.entryPrice.long.mustBeLessThanOrEqualToAskPrice
                            }
                        } else {
                            if (!hasError && (parseFloat(value) < askPrice)) {
                                hasError = true
                                errMsg = tradeFormErrorMessages.entryPrice.long.mustBeGreaterThanOrEqualToAskPrice
                            }
                        }
                    } else {
                        if (!hasError && (parseFloat(value) < bidPrice)) {
                            hasError = true
                            errMsg = tradeFormErrorMessages.entryPrice.short.mustBeGreaterThanBidPrice
                        }
                    }                
                }
            }

            onSetNewState(this, {
                entryPriceErrMsg: errMsg,
                isNotValidEntryPrice: hasError,
                isValidEntryPrice: !hasError,
                isToValidateTargets: !hasError ? true : false
            })

            return hasError
        }
    }

    onChangeLadderedEntryPriceHandler = (e, index) => {
        e.preventDefault()
        
        const { usdQtyValue } = this.state
        if (isNull(usdQtyValue)) {
            this.onValidateQtyInUsdtHandler()
        } else {
            let { entryPrices } = this.state

            if (this.entryPriceTypingInterval)
                clearTimeout(this.entryPriceTypingInterval)

            let newValue = e.target.value
            let editingEntry = _.find(entryPrices, (entry) => {
                return entry.index === index
            })
            if (editingEntry) {
                editingEntry.stopPrice = newValue
                onSetNewState(this, {
                    entryPrices
                })
            }
            
            this.entryPriceTypingInterval = setTimeout(() => {
                this.onValidateLadderedEntryPriceHandler(newValue, index)
            }, 800)
        }
    }

    onValidateLadderedEntryPriceHandler = (value, entryIndex) => {
        const { askPrice, bidPrice, direction, orderDetails } = this.props
        const { primaryOrder } = orderDetails
        const { entryPrices, stopLossPriceValue } = this.state
        
        let hasInvalid = false

        for (let index = 0; index < entryPrices.length; index++) {
            entryPrices[index].isNotValid = false
            entryPrices[index].errMsg = ""
        }

        let index = entryIndex
        if (entryPrices.length > 1) {
            while (index < entryPrices.length) {
                if (index === 0) {
                    if (isNaN(parseFloat(entryPrices[index].stopPrice))) {
                        entryPrices[index].isNotValid = true
                        entryPrices[index].errMsg = tradeFormErrorMessages.multiEntries.invalidEntry(index)
                        break
                    } else {
                        if (direction.toLowerCase() === 'buy') {
                            if (primaryOrder.stopCondition === 'lte') {
                                // stop price must be less than or equal to ask price
                                if (parseFloat(entryPrices[index].stopPrice) > askPrice) {
                                    entryPrices[index].isNotValid = true
                                    entryPrices[index].errMsg = tradeFormErrorMessages.multiEntries.long.mustBeLessThanOrEqualToAskPrice(index)
                                    break
                                }
                            } else {
                                // stop price must be greater than or equal to ask price
                                if (parseFloat(entryPrices[index].stopPrice) < askPrice) {
                                    entryPrices[index].isNotValid = true
                                    entryPrices[index].errMsg = tradeFormErrorMessages.multiEntries.long.mustBeGreaterThanOrEqualToAskPrice(index)
                                    break
                                }
                            }

                            // stop price must be greater than stop loss
                            if (parseFloat(entryPrices[index].stopPrice) < parseFloat(stopLossPriceValue)) {
                                entryPrices[index].isNotValid = true
                                entryPrices[index].errMsg = `Entry price ${index + 1} must be greater than Stoploss`
                                break
                            } else {
                                entryPrices[index].isNotValid = false
                                entryPrices[index].errMsg = ''
                            }
                        } else {
                            // stop price must be greater than bid price
                            if (parseFloat(entryPrices[index].stopPrice) < bidPrice) {
                                entryPrices[index].isNotValid = true
                                entryPrices[index].errMsg = tradeFormErrorMessages.multiEntries.short.mustBeGreaterThanBidPrice(index)
                                break
                            }
                            
                            // stop price must be less than stop loss
                            if (parseFloat(entryPrices[index].stopPrice) > parseFloat(stopLossPriceValue)) {
                                entryPrices[index].isNotValid = true
                                entryPrices[index].errMsg = `Entry price ${index + 1} must be less than Stoploss`
                                break
                            } else {
                                entryPrices[index].isNotValid = false
                                entryPrices[index].errMsg = ''
                            }
                        }
                    }
                } else {
                    // entry price must be lesser than the previous entry
                    let previousEntry = entryPrices[index - 1].stopPrice
                    if (isNaN(parseFloat(previousEntry))) {
                        break
                    } else {
                        if (direction.toLowerCase() === 'buy') {
                            // stop price must be less than the previous entry
                            if (parseFloat(entryPrices[index].stopPrice) > parseFloat(previousEntry)) {
                                entryPrices[index].isNotValid = true
                                entryPrices[index].errMsg = tradeFormErrorMessages.multiEntries.long.mustBeLessThanPreviousEntry(index)
                                break
                            }

                            // stop price must be greater than stop loss
                            if (parseFloat(entryPrices[index].stopPrice) < parseFloat(stopLossPriceValue)) {
                                entryPrices[index].isNotValid = true
                                entryPrices[index].errMsg = `Entry price ${index + 1} must be greater than Stoploss`
                                break
                            } else {
                                entryPrices[index].isNotValid = false
                                entryPrices[index].errMsg = ''
                            }
                        } else {
                            // stop price must be greater than previous entry
                            if (parseFloat(entryPrices[index].stopPrice) < parseFloat(previousEntry)) {
                                entryPrices[index].isNotValid = true
                                entryPrices[index].errMsg = tradeFormErrorMessages.multiEntries.short.mustBeGreaterThanPreviousEntry(index)
                                break
                            }

                            // stop price must be less than stop loss
                            if (parseFloat(entryPrices[index].stopPrice) > parseFloat(stopLossPriceValue)) {
                                entryPrices[index].isNotValid = true
                                entryPrices[index].errMsg = `Entry price ${index + 1} must be less than Stoploss`
                                break
                            } else {
                                entryPrices[index].isNotValid = false
                                entryPrices[index].errMsg = ''
                            }
                        }
                    }
                }

                index++
            }
        } else {
            let currentEntry = entryPrices[index]
            if (isNaN(parseFloat(currentEntry.stopPrice))) {
                currentEntry.isNotValid = true
                currentEntry.errMsg = tradeFormErrorMessages.multiEntries.invalidEntry(index)
            } else {
                if (parseFloat(currentEntry.stopPrice) <= askPrice) {
                    currentEntry.isNotValid = true
                    currentEntry.errMsg = tradeFormErrorMessages.multiEntries.mustBeLessThanOrEqualToAskPrice(index)
                }
            }
        }

        hasInvalid = _.find(entryPrices, (currentObject) => {
            return currentObject.isNotValid === true
        })

        onSetNewState(this, {
            entryPrices,
            isToValidateTargets: !hasInvalid ? true : false
        })

        return hasInvalid
    }

    onChangeLadderedEntryPriceQtyHandler = (e, index) => {
        e.preventDefault()
        
        const { usdQtyValue } = this.state
        const { eventHandlers } = this.props

        if (isNull(usdQtyValue)) {
            this.onValidateQtyInUsdtHandler()
        } else {
            if (this.entryPriceQtyTypingInterval) 
                clearTimeout(this.entryPriceQtyTypingInterval)

            let newValue = e.target.value
            let { entryPrices } = this.state

            let currentEntry = _.find(entryPrices, (entry) => {
                return entry.index === index
            })
            if (currentEntry) {
                currentEntry.quantity = isNotNull(newValue) && newValue !== '' && newValue !== '.' ? parseFloat(newValue) : ''

                onSetNewState(this, {
                    entryPrices
                })

                let avg = []
                entryPrices.forEach((i) => {
                    let q = parseFloat(i.stopPrice) * (parseInt(i.quantity) / 100)
                    avg.push({ q })
                })

                let avgEntryPrice = _.sumBy(avg, (o) => {
                    return parseFloat(o.q)
                })
                if (isNotNull(avgEntryPrice)) {
                    eventHandlers.onChangeMyEntryPriceHandler(avgEntryPrice)
                }

                this.entryPriceQtyTypingInterval = setTimeout(() => {
                    this.onValidateLadderedEntryPriceQtyHandler(newValue, index)
                }, 800)
            }
        }
    }

    onValidateLadderedEntryPriceQtyHandler = (value, entryIndex) => {
        const { fromSym } = this.props 
        const { usdQtyValue, entryPrices } = this.state
        let { hasLadderedEntriesError, ladderedEntriesErrMsg } = this.state
        hasLadderedEntriesError = false
        ladderedEntriesErrMsg = ""

        // reset quantity validations
        for (let index = 0; index < entryPrices.length; index++) {
            entryPrices[index].isQtyNotValid = false
        }

        let index = 0
        if (entryPrices.length > 1) {
            let isAmountTooLow = false
            let total = _.sumBy(entryPrices, 'quantity')
            let allocatedQty = 0 
            //let entryIndex = ''
            while (index < entryPrices.length) {
                let empty = _.find(entryPrices, (currentObject) => {
                    return currentObject.quantity === ""
                })
                if (empty) {
                    hasLadderedEntriesError = true
                    break
                } else {
                    let currentEntry = entryPrices[index]
                    if (value === 0) {
                        hasLadderedEntriesError = true
                        break
                    } else {
                        if (fromSym.includes('USD')) {
                            allocatedQty = currentEntry.quantity / 100
                            allocatedQty *= usdQtyValue

                            if ((parseFloat(allocatedQty) < minOrderConfig.USDT)) {
                                isAmountTooLow = true
                                //entryIndex = index
                                entryPrices[index].isQtyNotValid = true

                                break
                            } else {
                                isAmountTooLow = false
                                entryPrices[index].isQtyNotValid = false

                                if (total < 100 || total > 100) {
                                    hasLadderedEntriesError = true
                                    break
                                }
                            }
                        } else {
                            if (isNotNull(fromSym) && isNotNull(btcUsdtLastPrice)) {
                                // if symbol ends with BTC
                                if (fromSym === 'BTC') {
                                    allocatedQty = currentEntry.quantity / 100
                                    allocatedQty *= usdQtyValue
                                    // calculating total qty in USD
                                    allocatedQty = toBtcFormat(parseFloat(allocatedQty / btcUsdtLastPrice))
                                    if ((parseFloat(allocatedQty) < minOrderConfig.BTC)) {
                                        isAmountTooLow = true
                                        //entryIndex = index
                                        entryPrices[index].isQtyNotValid = true

                                        break
                                    } else {
                                        isAmountTooLow = false
                                        entryPrices[index].isQtyNotValid = false

                                        if (total < 100 || total > 100) {
                                            hasLadderedEntriesError = true
                                            break
                                        }
                                    }
                                }
                            }
                        }

                        // if (total < 100 || total > 100) {
                        //     hasLadderedEntriesError = true
                        // }
                    }
                }

                index++
            }

            if (isAmountTooLow) {
                let amountTooLowErrMsg = ""
                if (fromSym.includes('USD')) {
                    amountTooLowErrMsg = `Sorry, the minimum order size for ${fromSym} paired coins is ${fromSym === 'USDT' ? '$11' : '11 BUSD' }. 
                        You only have ${parseFloat(allocatedQty) > 1 ? parseFloat(allocatedQty).toFixed(2) : parseFloat(allocatedQty).toFixed(5)} ${fromSym} allocated in Entry Price ${entryIndex}. 
                        Please increase the total quantity or increase the % allocation of Entry Price ${entryIndex + 1} and try again.`
                } else {
                    amountTooLowErrMsg = `Sorry, the minimum order size for BTC paired coins is ₿0.0001.
                        You only have ₿${parseFloat(allocatedQty).toFixed(8)} allocated in Entry Price ${entryIndex}. 
                        Please increase the total quantity or increase the % allocation of Entry Price ${entryIndex} and try again.`
                }
                onSetNewState(this, {
                    hasLadderedEntriesError: false,
                    ladderedEntriesErrMsg: "",
                    hasLadderedEntriesQtyError: true,
                    ladderedEntriesQtyErrorMsg: amountTooLowErrMsg
                })
            } else {
                if (hasLadderedEntriesError) {
                    ladderedEntriesErrMsg = "Total entry price quantities should be equal to 100%"
    
                    for (let i = 0; i < entryPrices.length; i++) {
                        entryPrices[i].isQtyNotValid = true
                    }
    
                    onSetNewState(this, {
                        entryPrices,
                        hasLadderedEntriesError,
                        ladderedEntriesErrMsg,
                        isValidLadderedEntryPrices: false
                    })
                } else {
                    for (let i = 0; i < entryPrices.length; i++) {
                        entryPrices[i].isQtyNotValid = false
                    }
    
                    onSetNewState(this, {
                        entryPrices,
                        hasLadderedEntriesError: false,
                        ladderedEntriesErrMsg: "",
                        isValidLadderedEntryPrices: true,
                        isToValidateTargets: true
                    })
                }
            }
        } else {
            let currentEntry = entryPrices[0]

            if (isNaN(parseFloat(value))) {
                currentEntry.isQtyNotValid = true
                hasLadderedEntriesError = true

                onSetNewState(this, {
                    entryPrices,
                    hasLadderedEntriesError,
                    ladderedEntriesErrMsg: "Total entry price quantities should be equal to 100%",
                    isValidLadderedEntryPrices: false
                })
            } else {
                if (parseFloat(value) < 100 || parseFloat(value) > 100) {
                    currentEntry.isQtyNotValid = true
                    hasLadderedEntriesError = true
                    
                    onSetNewState(this, {
                        entryPrices,
                        hasLadderedEntriesError,
                        ladderedEntriesErrMsg: "Total entry price quantities should be equal to 100%",
                        isValidLadderedEntryPrices: false
                    })
                } else {
                    for (let index = 0; index < entryPrices.length; index++) {
                        entryPrices[index].isQtyNotValid = false
                    }

                    onSetNewState(this, {
                        entryPrices,
                        hasLadderedEntriesError: false,
                        ladderedEntriesErrMsg: "",
                        isValidLadderedEntryPrices: true,
                        isToValidateTargets: true
                    })
                }
            }
        }
    }

    onRemoveLadderedEntryPrice = (e, index) => {
        e.preventDefault()

        const { eventHandlers } = this.props
        const { usdQtyValue, tradeStrategy } = this.state
        
        if (isNull(usdQtyValue)) {
            this.onValidateQtyInUsdtHandler()
        } else {
            let { entryPrices } = this.state

            _.remove(entryPrices, (currentObject) => {
                return currentObject.index === index
            })

            switch(tradeStrategy) {
                default: {
                    let allocatable = 100
                    let totalEntries = entryPrices.length
                    let quotient = Math.floor(allocatable/totalEntries)

                    for (let index = 0; index < totalEntries; index++) {
                        if (allocatable === 100) {
                            allocatable -= quotient
                            entryPrices[index].quantity = quotient
                        } else {
                            let difference = allocatable - quotient
                            if (difference < quotient) {
                                entryPrices[index].quantity = allocatable
                            } else {
                                allocatable -= quotient 
                                entryPrices[index].quantity = quotient
                            }
                        }
                    }

                    break
                }
                case 'rrhunter':
                case 'stronguptrend':
                case 'weakuptrend':
                case 'moonbag': {
                    if (entryPrices.length === 4) {
                        entryPrices[0].quantity = 25
                        entryPrices[1].quantity = 25
                        entryPrices[2].quantity = 25
                        entryPrices[3].quantity = 25
                    } else {
                        let allocatable = 100
                        let totalEntries = entryPrices.length
                        let quotient = Math.floor(allocatable/totalEntries)

                        for (let index = 0; index < totalEntries; index++) {
                            if (allocatable === 100) {
                                allocatable -= quotient
                                entryPrices[index].quantity = quotient
                            } else {
                                let difference = allocatable - quotient
                                if (difference < quotient) {
                                    entryPrices[index].quantity = allocatable
                                } else {
                                    allocatable -= quotient 
                                    entryPrices[index].quantity = quotient
                                }
                            }
                        }
                    }

                    break
                }
                case 'aggressivestoploss': {
                    if (entryPrices.length === 3) {
                        entryPrices[0].quantity = 30
                        entryPrices[1].quantity = 40
                        entryPrices[2].quantity = 30
                    } else {
                        let allocatable = 100
                        let totalEntries = entryPrices.length
                        let quotient = Math.floor(allocatable/totalEntries)

                        for (let index = 0; index < totalEntries; index++) {
                            if (allocatable === 100) {
                                allocatable -= quotient
                                entryPrices[index].quantity = quotient
                            } else {
                                let difference = allocatable - quotient
                                if (difference < quotient) {
                                    entryPrices[index].quantity = allocatable
                                } else {
                                    allocatable -= quotient 
                                    entryPrices[index].quantity = quotient
                                }
                            }
                        }
                    }

                    break
                }
                case 'heikoaggressive': {
                    if (entryPrices.length === 2) {
                        entryPrices[0].quantity = 40
                        entryPrices[1].quantity = 60
                    } else {
                        let allocatable = 100
                        let totalEntries = entryPrices.length
                        let quotient = Math.floor(allocatable/totalEntries)

                        for (let index = 0; index < totalEntries; index++) {
                            if (allocatable === 100) {
                                allocatable -= quotient
                                entryPrices[index].quantity = quotient
                            } else {
                                let difference = allocatable - quotient
                                if (difference < quotient) {
                                    entryPrices[index].quantity = allocatable
                                } else {
                                    allocatable -= quotient 
                                    entryPrices[index].quantity = quotient
                                }
                            }
                        }
                    }

                    break
                }
            }

            // let allocatable = 100
            // let totalEntries = entryPrices.length
            // let quotient = Math.floor(allocatable/totalEntries)

            // for (let index = 0; index < totalEntries; index++) {
            //     if (allocatable === 100) {
            //         allocatable -= quotient
            //         entryPrices[index].quantity = quotient
            //     } else {
            //         let difference = allocatable - quotient
            //         if (difference < quotient) {
            //             entryPrices[index].quantity = allocatable
            //         } else {
            //             allocatable -= quotient 
            //             entryPrices[index].quantity = quotient
            //         }
            //     }
            // }

            let avgEntryPrice = _.sumBy(entryPrices, (o) => {
                return parseFloat(o.stopPrice)
            })
            if (isNotNull(avgEntryPrice)) {
                avgEntryPrice /= entryPrices.length
                eventHandlers.onChangeMyEntryPriceHandler(avgEntryPrice)
            }

            // for (let index = 0; index < entryPrices.length; index++) {
            //     let defaultQty = 100 / entryPrices.length
            //     entryPrices[index].quantity = defaultQty
            // }
            
            if (entryPrices.length < 2) {
                onSetNewState(this, {
                    isLadderingEnabled: false,
                    hasLadderedEntriesError: false,
                    ladderedEntriesErrMsg: ""
                })
            } else
                this.onValidateLadderedEntriesQtyHandler()
        }
    }

    onAddLadderedEntryPrice = (e) => {
        e.preventDefault()

        const { eventHandlers } = this.props
        const { usdQtyValue, tradeStrategy } = this.state

        if (isNull(usdQtyValue)) {
            this.onValidateQtyInUsdtHandler()
        } else {
            const { baseLadderedEntryPrices } = this.state
            let { entryPrices } = this.state
            
            onSetNewState(this, {
                hasLadderedEntriesError: false,
                ladderedEntriesErrMsg: ""
            })

            if (isNotNull(baseLadderedEntryPrices) && isNotNull(entryPrices)) {
                for (let index = 0; index < baseLadderedEntryPrices.length; index++) {
                    let exists = _.find(entryPrices, (currentObject) => {
                        return currentObject.index === baseLadderedEntryPrices[index].index
                    })
                    if (isNull(exists)) {
                        let entryPriceInfo = baseLadderedEntryPrices[index]
                        entryPriceInfo.quantity = ""
                        entryPrices.splice(index, 0, entryPriceInfo)
                        break
                    }
                }

                if (entryPrices.length === 5) {
                    entryPrices[0].quantity = 20
                    entryPrices[1].quantity = 20
                    entryPrices[2].quantity = 20
                    entryPrices[3].quantity = 20
                    entryPrices[4].quantity = 20

                    // switch(tradeStrategy) {
                    //     default: {
                    //         entryPrices[0].quantity = 20
                    //         entryPrices[1].quantity = 20
                    //         entryPrices[2].quantity = 20
                    //         entryPrices[3].quantity = 20
                    //         entryPrices[4].quantity = 20
                    //         break
                    //     }
                    //     case 'def': {
                    //         entryPrices[0].quantity = 5
                    //         entryPrices[1].quantity = 10
                    //         entryPrices[2].quantity = 15
                    //         entryPrices[3].quantity = 20
                    //         entryPrices[4].quantity = 50
                    //         break
                    //     }
                    //     case 'agr': {
                    //         entryPrices[0].quantity = 30
                    //         entryPrices[1].quantity = 30
                    //         entryPrices[2].quantity = 20
                    //         entryPrices[3].quantity = 10
                    //         entryPrices[4].quantity = 10
                    //         break
                    //     }
                    // }
                } else {
                    switch(tradeStrategy) {
                        default: {
                            let allocatable = 100
                            let totalEntries = entryPrices.length
                            let quotient = Math.floor(allocatable/totalEntries)

                            for (let index = 0; index < totalEntries; index++) {
                                if (allocatable === 100) {
                                    allocatable -= quotient
                                    entryPrices[index].quantity = quotient
                                } else {
                                    let difference = allocatable - quotient
                                    if (difference < quotient) {
                                        entryPrices[index].quantity = allocatable
                                    } else {
                                        allocatable -= quotient 
                                        entryPrices[index].quantity = quotient
                                    }
                                }
                            }
    
                            break
                        }
                        case 'rrhunter':
                        case 'stronguptrend':
                        case 'weakuptrend':
                        case 'moonbag': {
                            if (entryPrices.length === 4) {
                                entryPrices[0].quantity = 25
                                entryPrices[1].quantity = 25
                                entryPrices[2].quantity = 25
                                entryPrices[3].quantity = 25
                            } else {
                                let allocatable = 100
                                let totalEntries = entryPrices.length
                                let quotient = Math.floor(allocatable/totalEntries)

                                for (let index = 0; index < totalEntries; index++) {
                                    if (allocatable === 100) {
                                        allocatable -= quotient
                                        entryPrices[index].quantity = quotient
                                    } else {
                                        let difference = allocatable - quotient
                                        if (difference < quotient) {
                                            entryPrices[index].quantity = allocatable
                                        } else {
                                            allocatable -= quotient 
                                            entryPrices[index].quantity = quotient
                                        }
                                    }
                                }
                            }
    
                            break
                        }
                        case 'aggressivestoploss': {
                            if (entryPrices.length === 3) {
                                entryPrices[0].quantity = 30
                                entryPrices[1].quantity = 40
                                entryPrices[2].quantity = 30
                            } else {
                                let allocatable = 100
                                let totalEntries = entryPrices.length
                                let quotient = Math.floor(allocatable/totalEntries)

                                for (let index = 0; index < totalEntries; index++) {
                                    if (allocatable === 100) {
                                        allocatable -= quotient
                                        entryPrices[index].quantity = quotient
                                    } else {
                                        let difference = allocatable - quotient
                                        if (difference < quotient) {
                                            entryPrices[index].quantity = allocatable
                                        } else {
                                            allocatable -= quotient 
                                            entryPrices[index].quantity = quotient
                                        }
                                    }
                                }
                            }
    
                            break
                        }
                        case 'heikoaggressive': {
                            if (entryPrices.length === 2) {
                                entryPrices[0].quantity = 40
                                entryPrices[1].quantity = 60
                            } else {
                                let allocatable = 100
                                let totalEntries = entryPrices.length
                                let quotient = Math.floor(allocatable/totalEntries)

                                for (let index = 0; index < totalEntries; index++) {
                                    if (allocatable === 100) {
                                        allocatable -= quotient
                                        entryPrices[index].quantity = quotient
                                    } else {
                                        let difference = allocatable - quotient
                                        if (difference < quotient) {
                                            entryPrices[index].quantity = allocatable
                                        } else {
                                            allocatable -= quotient 
                                            entryPrices[index].quantity = quotient
                                        }
                                    }
                                }
                            }
    
                            break
                        }
                    }

                    // let allocatable = 100
                    // let totalEntries = entryPrices.length
                    // let quotient = Math.floor(allocatable/totalEntries)

                    // for (let index = 0; index < totalEntries; index++) {
                    //     if (allocatable === 100) {
                    //         allocatable -= quotient
                    //         entryPrices[index].quantity = quotient
                    //     } else {
                    //         let difference = allocatable - quotient
                    //         if (difference < quotient) {
                    //             entryPrices[index].quantity = allocatable
                    //         } else {
                    //             allocatable -= quotient 
                    //             entryPrices[index].quantity = quotient
                    //         }
                    //     }
                    // }
                }

                let avgEntryPrice = _.sumBy(entryPrices, (o) => {
                    return parseFloat(o.stopPrice)
                })
                if (isNotNull(avgEntryPrice)) {
                    avgEntryPrice /= entryPrices.length
                    eventHandlers.onChangeMyEntryPriceHandler(avgEntryPrice)
                }
                
                this.onValidateLadderedEntryPrices()
            }
        }
    }

    onValidateLadderedEntriesQtyHandler = (value) => {
        const { entryPrices } = this.state
        let { hasLadderedEntriesError, ladderedEntriesErrMsg } = this.state
        hasLadderedEntriesError = false
        ladderedEntriesErrMsg = ""

        for (let index = 0; index < entryPrices.length; index++) {
            entryPrices[index].isQtyNotValid = false
        }

        let total = _.sumBy(entryPrices, 'quantity')
        
        let index = 0
        if (entryPrices.length > 1) {
            while (index <= entryPrices.length) {
                let empty = _.find(entryPrices, (currentObject) => {
                    return currentObject.quantity === ""
                })
                if (empty) {
                    hasLadderedEntriesError = true
                    break
                } else {
                    if (value === 0) {
                        hasLadderedEntriesError = true
                    } else {
                        if (total < 100 || total > 100) {
                            hasLadderedEntriesError = true
                        }
                    }
                }

                index++
            }

            if (hasLadderedEntriesError) {
                ladderedEntriesErrMsg = "Total entry price quantities should be equal to 100%"

                for (let index = 0; index < entryPrices.length; index++) {
                    entryPrices[index].isQtyNotValid = true
                }

                onSetNewState(this, {
                    entryPrices,
                    hasLadderedEntriesError,
                    ladderedEntriesErrMsg,
                    isValidLadderedEntryPrices: false
                })
            } else {
                for (let index = 0; index < entryPrices.length; index++) {
                    entryPrices[index].isQtyNotValid = false
                }

                onSetNewState(this, {
                    entryPrices,
                    hasLadderedEntriesError: false,
                    ladderedEntriesErrMsg: "",
                    isToValidateTargets: true,
                    isValidLadderedEntryPrices: true
                })
            }
        } else {
            let currentEntry = entryPrices[0]

            if (isNaN(parseFloat(value))) {
                currentEntry.isQtyNotValid = true
                hasLadderedEntriesError = true

                onSetNewState(this, {
                    entryPrices,
                    hasLadderedEntriesError,
                    ladderedEntriesErrMsg: "Total entry price quantities should be equal to 100%",
                    isValidLadderedEntryPrices: false
                })
            } else {
                if (parseFloat(value) < 100 || parseFloat(value) > 100) {
                    currentEntry.isQtyNotValid = true
                    hasLadderedEntriesError = true

                    onSetNewState(this, {
                        entryPrices,
                        hasLadderedEntriesError,
                        ladderedEntriesErrMsg: "Total entry price quantities should be equal to 100%",
                        isValidLadderedEntryPrices: false
                    })
                } else {
                    for (let index = 0; index < entryPrices.length; index++) {
                        entryPrices[index].isQtyNotValid = false
                    }

                    onSetNewState(this, {
                        entryPrices,
                        hasLadderedEntriesError: false,
                        ladderedEntriesErrMsg: "",
                        isValidLadderedEntryPrices: true,
                        isToValidateTargets: true
                    })
                }
            }
        }
    }

    onValidateLadderedEntryPrices = () => {
        const { fromSym, askPrice, bidPrice, direction, orderDetails } = this.props        
        const { primaryOrder } = orderDetails
        const { usdQtyValue, stopLossPriceValue } = this.state
        let { isLadderingEnabled, entryPrices, hasLadderedEntriesError, ladderedEntriesErrMsg } = this.state

        if (isNull(usdQtyValue)) {
            this.onValidateQtyInUsdtHandler()
        } else {
            if (isLadderingEnabled && entryPrices.length > 1) {            
                hasLadderedEntriesError = false
                ladderedEntriesErrMsg = ""
    
                onSetNewState(this, {
                    hasLadderedEntriesError,
                    ladderedEntriesErrMsg
                })
    
                let index = 0
    
                let hasInvalidEntryPrice = false
                while (index < entryPrices.length) {
                    if (index === 0) {
                        if (isNaN(parseFloat(entryPrices[index].stopPrice))) {
                            entryPrices[index].isNotValid = true
                            entryPrices[index].errMsg = tradeFormErrorMessages.multiEntries.invalidEntry(index)
                            hasInvalidEntryPrice = true
                            break
                        } else {
                            if (direction.toLowerCase() === 'buy') {
                                if (primaryOrder.stopCondition === 'lte') {
                                    // stop price must be less than or equal to ask price
                                    if (parseFloat(entryPrices[index].stopPrice > askPrice)) {
                                        entryPrices[index].isNotValid = true
                                        entryPrices[index].errMsg = tradeFormErrorMessages.multiEntries.long.mustBeLessThanOrEqualToAskPrice(index)            
                                        hasInvalidEntryPrice = true
                                        break
                                    }
                                } else {
                                    // stop price must be greater than or equal to ask price
                                    if (parseFloat(entryPrices[index].stopPrice < askPrice)) {
                                        entryPrices[index].isNotValid = true
                                        entryPrices[index].errMsg = tradeFormErrorMessages.multiEntries.long.mustBeGreaterThanOrEqualToAskPrice(index)            
                                        hasInvalidEntryPrice = true
                                        break
                                    }
                                }
                                
                                // stop price must be greater than stop loss
                                if (parseFloat(entryPrices[index].stopPrice) < parseFloat(stopLossPriceValue)) {
                                    entryPrices[index].isNotValid = true
                                    entryPrices[index].errMsg = `Entry price ${index + 1} must be greater than Stoploss`
    
                                    hasInvalidEntryPrice = true
                                    break
                                } else {
                                    entryPrices[index].isNotValid = false
                                    entryPrices[index].errMsg = ''
                                }
                            } else {
                                // stop price must be greater than bid price
                                if (parseFloat(entryPrices[index].stopPrice < bidPrice)) {
                                    entryPrices[index].isNotValid = true
                                    entryPrices[index].errMsg = tradeFormErrorMessages.multiEntries.short.mustBeGreaterThanBidPrice(index)
            
                                    hasInvalidEntryPrice = true
                                    break
                                }
                                
                                // stop price must be less than stop loss
                                if (parseFloat(entryPrices[index].stopPrice) > parseFloat(stopLossPriceValue)) {
                                    entryPrices[index].isNotValid = true
                                    entryPrices[index].errMsg = `Entry price ${index + 1} must be less than Stoploss`
    
                                    hasInvalidEntryPrice = true
                                    break
                                } else {
                                    entryPrices[index].isNotValid = false
                                    entryPrices[index].errMsg = ''
                                }
                            }
                        }
                    } else {
                        let previousEntry = entryPrices[index - 1].stopPrice
                        if (isNaN(parseFloat(previousEntry))) {
                            entryPrices[index].isNotValid = true
                            entryPrices[index].errMsg = tradeFormErrorMessages.multiEntries.invalidEntry(index)
    
                            hasInvalidEntryPrice = true
                            break
                        } else {
                            if (direction.toLowerCase() === 'buy') {
                                // stop price must be less than the previous entry
                                if (parseFloat(entryPrices[index].stopPrice) > parseFloat(previousEntry)) {
                                    entryPrices[index].isNotValid = true
                                    entryPrices[index].errMsg = tradeFormErrorMessages.multiEntries.long.mustBeLessThanPreviousEntry(index)
            
                                    hasInvalidEntryPrice = true
                                    break
                                }
    
                                // stop price must be greater than stop loss
                                if (parseFloat(entryPrices[index].stopPrice) < parseFloat(stopLossPriceValue)) {
                                    entryPrices[index].isNotValid = true
                                    entryPrices[index].errMsg = `Entry price ${index + 1} must be greater than Stoploss`
    
                                    hasInvalidEntryPrice = true
                                    break
                                } else {
                                    entryPrices[index].isNotValid = false
                                    entryPrices[index].errMsg = ''
                                }
                            } else {
                                // stop price must be greater than previous entry
                                if (parseFloat(entryPrices[index].stopPrice) < parseFloat(previousEntry)) {
                                    entryPrices[index].isNotValid = true
                                    entryPrices[index].errMsg = tradeFormErrorMessages.multiEntries.short.mustBeGreaterThanPreviousEntry(index)
            
                                    hasInvalidEntryPrice = true
                                    break
                                }
                                
                                // stop price must be less than stop loss
                                if (parseFloat(entryPrices[index].stopPrice) > parseFloat(stopLossPriceValue)) {
                                    entryPrices[index].isNotValid = true
                                    entryPrices[index].errMsg = `Entry price ${index + 1} must be less than Stoploss`
    
                                    hasInvalidEntryPrice = true
                                    break
                                } else {
                                    entryPrices[index].isNotValid = false
                                    entryPrices[index].errMsg = ''
                                }
                            }
                        }
                    }
    
                    index++
                }
    
                if (hasInvalidEntryPrice) {
                    onSetNewState(this, {
                        entryPrices
                    })
                } else {
                    for (let index = 0; index < entryPrices.length; index++) {
                        entryPrices[index].isQtyNotValid = false
                    }
    
                    // reset index counter
                    index = 0
                    
                    // sum up quantity
                    let isAmountTooLow = false
                    let total = _.sumBy(entryPrices, 'quantity')
                    let allocatedQty = 0 
                    let entryIndex = ''
                    while (index < entryPrices.length) {
                        let empty = _.find(entryPrices, (currentObject) => {
                            return currentObject.quantity === ""
                        })
                        if (empty) {
                            hasLadderedEntriesError = true
                            break
                        } else {
                            let currentEntry = entryPrices[index]
                            if (currentEntry.quantity === 0) {
                                hasLadderedEntriesError = true
                                break
                            } else {
                                if (fromSym.includes('USD')) {
                                    allocatedQty = currentEntry.quantity / 100
                                    allocatedQty *= usdQtyValue

                                    if ((parseFloat(allocatedQty) < minOrderConfig.USDT)) {
                                        isAmountTooLow = true
                                        entryIndex = index
                                        entryPrices[index].isQtyNotValid = true

                                        break
                                    } else {
                                        isAmountTooLow = false
                                        entryPrices[index].isQtyNotValid = false

                                        if (total < 100 || total > 100) {
                                            hasLadderedEntriesError = true
                                            break
                                        }
                                    }
                                } else {
                                    if (isNotNull(fromSym) && isNotNull(btcUsdtLastPrice)) {
                                        // if symbol ends with BTC
                                        if (fromSym === 'BTC') {
                                            allocatedQty = currentEntry.quantity / 100
                                            allocatedQty *= usdQtyValue
                                            // calculating total qty in USD
                                            allocatedQty = toBtcFormat(parseFloat(allocatedQty / btcUsdtLastPrice))
                                            if ((parseFloat(allocatedQty) < minOrderConfig.BTC)) {
                                                isAmountTooLow = true
                                                entryIndex = index
                                                entryPrices[index].isQtyNotValid = true

                                                break
                                            } else {
                                                isAmountTooLow = false
                                                entryPrices[index].isQtyNotValid = false
        
                                                if (total < 100 || total > 100) {
                                                    hasLadderedEntriesError = true
                                                    break
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
    
                        index++
                    }

                    if (isAmountTooLow) {
                        let amountTooLowErrMsg = ""
                        if (fromSym.includes('USD')) {
                            amountTooLowErrMsg = `Sorry, the minimum order size for ${fromSym} paired coins is ${fromSym === 'USDT' ? '$11' : '11 BUSD' }. 
                                You only have ${parseFloat(allocatedQty) > 1 ? parseFloat(allocatedQty).toFixed(2) : parseFloat(allocatedQty).toFixed(5)} ${fromSym} allocated in Entry Price ${entryIndex + 1}. 
                                Please increase the total quantity or increase the % allocation of Entry Price ${entryIndex + 1} and try again.`
                        } else {
                            amountTooLowErrMsg = `Sorry, the minimum order size for BTC paired coins is ₿0.0001.
                                You only have ₿${parseFloat(allocatedQty).toFixed(8)} allocated in Entry Price ${entryIndex + 1}. 
                                Please increase the total quantity or increase the % allocation of Entry Price ${entryIndex + 1} and try again.`
                        }
                        onSetNewState(this, {
                            hasLadderedEntriesQtyError: true,
                            ladderedEntriesQtyErrorMsg: amountTooLowErrMsg
                        })
                    } else {
                        onSetNewState(this, {
                            hasLadderedEntriesQtyError: false,
                            ladderedEntriesQtyErrorMsg: ""
                        })

                        if (hasLadderedEntriesError) {
                            ladderedEntriesErrMsg = "Total entry price quantities should be equal to 100%"
        
                            for (let i = 0; i < entryPrices.length; i++) {
                                entryPrices[i].isQtyNotValid = true
                            }
        
                            onSetNewState(this, {
                                entryPrices,
                                hasLadderedEntriesError,
                                ladderedEntriesErrMsg
                            })
                        } else {
                            onSetNewState(this, {
                                isValidLadderedEntryPrices: true,
                                isToValidateTargets: true
                            })
                        }
                    }
                }
            }
        }
    }

    onCalculateLadderedEntries = () => {
        const { signalInfo, direction, fromSym, lastPrice, eventHandlers } = this.props
        const { tradeStrategy } = this.state

        if (isNotNull(tradeStrategy) && 
            isNotNull(signalInfo.entryStartPrice) && 
            isNotNull(signalInfo.entryEndPrice)) {
                let dir = direction.toLowerCase()
                let diff = signalInfo.entryEndPrice - signalInfo.entryStartPrice
                diff = diff / 4

                let newBaseEntries = []
                let newEntries = []
                let startingEntryPrice = dir === 'buy' ? parseFloat(signalInfo.entryEndPrice) : parseFloat(signalInfo.entryStartPrice)
                
                switch(tradeStrategy) {
                    default: {
                        for (let i = 0; i < 5; i++) {
                            if (i > 0) {
                                if (dir === 'buy')
                                    startingEntryPrice -= parseFloat(diff)
                                else
                                    startingEntryPrice += parseFloat(diff)
                            }
        
                            let ladderedStopPrice = null
                            if (fromSym === 'BTC')
                                ladderedStopPrice = toBtcFormat(startingEntryPrice)
                            else {
                                if (parseFloat(startingEntryPrice) < 1)
                                    ladderedStopPrice = toBtcFormat(startingEntryPrice)
                                else
                                    ladderedStopPrice = toUsdFormat(startingEntryPrice)
                            }
        
                            let data = {
                                id: `entry-${i}`,
                                index: i,
                                //stopPrice: parseFloat(startingEntryPrice) < 1 ? startingEntryPrice.toFixed(8) : startingEntryPrice.toFixed(5),
                                stopPrice: ladderedStopPrice,
                                quantity: 0,
                                isNotValid: false,
                                isQtyNotValid: false,
                                errMsg: ''
                            }
        
                            newEntries.push(data)
                            newBaseEntries.push(data)
                        }

                        newEntries[0].quantity = 20
                        newEntries[1].quantity = 20
                        newEntries[2].quantity = 20
                        newEntries[3].quantity = 20
                        newEntries[4].quantity = 20

                        break
                    }
                    case 'rrhunter':
                    case 'stronguptrend':
                    case 'weakuptrend':
                    case 'moonbag': {
                        for (let i = 0; i < 5; i++) {
                            if (i > 0) {
                                if (dir === 'buy')
                                    startingEntryPrice -= parseFloat(diff)
                                else
                                    startingEntryPrice += parseFloat(diff)
                            }
        
                            let ladderedStopPrice = null
                            if (fromSym === 'BTC')
                                ladderedStopPrice = toBtcFormat(startingEntryPrice)
                            else {
                                if (parseFloat(startingEntryPrice) < 1)
                                    ladderedStopPrice = toBtcFormat(startingEntryPrice)
                                else
                                    ladderedStopPrice = toUsdFormat(startingEntryPrice)
                            }
        
                            let data = {
                                id: `entry-${i}`,
                                index: i,
                                stopPrice: ladderedStopPrice,
                                quantity: 0,
                                isNotValid: false,
                                isQtyNotValid: false,
                                errMsg: ''
                            }
        
                            if (i < 4) {
                                newEntries.push(data)
                            }
                            newBaseEntries.push(data)
                        }

                        newEntries[0].quantity = 25
                        newEntries[1].quantity = 25
                        newEntries[2].quantity = 25
                        newEntries[3].quantity = 25

                        break
                    }
                    case 'aggressivestoploss': {
                        for (let i = 0; i < 5; i++) {
                            if (i > 0) {
                                if (dir === 'buy')
                                    startingEntryPrice -= parseFloat(diff)
                                else
                                    startingEntryPrice += parseFloat(diff)
                            }
        
                            let ladderedStopPrice = null
                            if (fromSym === 'BTC')
                                ladderedStopPrice = toBtcFormat(startingEntryPrice)
                            else {
                                if (parseFloat(startingEntryPrice) < 1)
                                    ladderedStopPrice = toBtcFormat(startingEntryPrice)
                                else
                                    ladderedStopPrice = toUsdFormat(startingEntryPrice)
                            }
        
                            let data = {
                                id: `entry-${i}`,
                                index: i,
                                stopPrice: ladderedStopPrice,
                                quantity: 0,
                                isNotValid: false,
                                isQtyNotValid: false,
                                errMsg: ''
                            }
        
                            if (i < 3) {
                                newEntries.push(data)
                            }
                            newBaseEntries.push(data)
                        }

                        newEntries[0].quantity = 30
                        newEntries[1].quantity = 40
                        newEntries[2].quantity = 30

                        break
                    }
                    case 'heikoaggressive': {
                        for (let i = 0; i < 5; i++) {
                            if (i > 0) {
                                if (dir === 'buy')
                                    startingEntryPrice -= parseFloat(diff)
                                else
                                    startingEntryPrice += parseFloat(diff)
                            }
        
                            let ladderedStopPrice = null
                            if (fromSym === 'BTC')
                                ladderedStopPrice = toBtcFormat(startingEntryPrice)
                            else {
                                if (parseFloat(startingEntryPrice) < 1)
                                    ladderedStopPrice = toBtcFormat(startingEntryPrice)
                                else
                                    ladderedStopPrice = toUsdFormat(startingEntryPrice)
                            }
        
                            let data = {
                                id: `entry-${i}`,
                                index: i,
                                stopPrice: ladderedStopPrice,
                                quantity: 0,
                                isNotValid: false,
                                isQtyNotValid: false,
                                errMsg: ''
                            }
        
                            if (i < 2) {
                                newEntries.push(data)
                            }
                            newBaseEntries.push(data)
                        }

                        newEntries[0].quantity = 40
                        newEntries[1].quantity = 60

                        break
                    }
                    // case 'def': {
                    //     newEntries[0].quantity = 5
                    //     newEntries[1].quantity = 10
                    //     newEntries[2].quantity = 15
                    //     newEntries[3].quantity = 20
                    //     newEntries[4].quantity = 50
                    //     break
                    // }
                    // case 'agr': {
                    //     newEntries[0].quantity = 30
                    //     newEntries[1].quantity = 30
                    //     newEntries[2].quantity = 20
                    //     newEntries[3].quantity = 10
                    //     newEntries[4].quantity = 10
                    //     break
                    // }
                }

                let avg = []
                newEntries.forEach((i) => {
                    let q = parseFloat(i.stopPrice) * (parseInt(i.quantity) / 100)
                    avg.push({ q })
                })

                let avgEntryPrice = _.sumBy(avg, (o) => {
                    return parseFloat(o.q)
                })
                // let avgEntryPrice = _.sumBy(newEntries, (o) => {
                //     return parseFloat(o.stopPrice)
                // })
                if (isNotNull(avgEntryPrice)) {
                    //console.log('avgEntryPrice: ', avgEntryPrice)
                    //avgEntryPrice /= newEntries.length
                    
                    eventHandlers.onChangeMyEntryPriceHandler(avgEntryPrice)
                }

                onSetNewState(this, {
                    baseLadderedEntryPrices: newBaseEntries,
                    entryPrices: newEntries
                }, () => {
                    this.onValidateLadderedEntryPrices()
                })
        }
    }

    onChangeQtyInAltHandler = (e) => {
        e.preventDefault()
        let { usdQtyValue, altQtyValue, btcValue, entryPriceValue, isLadderingEnabled } = this.state

        if (usdQtyValue === '') {
            e.preventDefault()

            onSetNewState(this, {
                usdQtyErrMsg: tradeFormErrorMessages.quantity.invalidInput,
                isNotValidUsdQty: true,
                altQtyValue: '',
                btcValue: 0
            })
            return
        }

        const newValue = e.target.value
        if (isNotNull(newValue)) {
            altQtyValue = newValue

            const { btcUsdtLastPrice, lastPrice, fromSym } = this.props

            // if (isNotNull(fromSym) && isNotNull(lastPrice) && isNotNull(btcUsdtLastPrice)) {
            //     // if symbol ends with BTC
            //     if (fromSym === 'BTC') {
            //         // calculating total qty in USD
            //         btcValue = parseFloat(newValue * lastPrice) //toBtcFormat(parseFloat(newValue * lastPrice))
            //         usdQtyValue = parseFloat(btcValue) * parseFloat(btcUsdtLastPrice) //toUsdFormat(parseFloat(btcValue) * parseFloat(btcUsdtLastPrice))
            //     } else {
            //         usdQtyValue = newValue * lastPrice //toUsdFormat(newValue * lastPrice)
            //         btcValue = 0
            //     }
            // }

            if (isNotNull(fromSym) && isNotNull(btcUsdtLastPrice)) {
                // if symbol ends with BTC
                if (fromSym === 'BTC') {
                    // calculating total qty in USD
                    if (isLadderingEnabled) 
                        btcValue = toBtcFormat(parseFloat(newValue * lastPrice))
                    else
                        btcValue = toBtcFormat(parseFloat(newValue * entryPriceValue)) //toBtcFormat(parseFloat(newValue * lastPrice))

                    usdQtyValue = (parseFloat(btcValue) * parseFloat(btcUsdtLastPrice)).toFixed(0) //toUsdFormat(parseFloat(btcValue) * parseFloat(btcUsdtLastPrice))
                } else {
                    if (isLadderingEnabled)
                        usdQtyValue = newValue * lastPrice
                    else
                        usdQtyValue = (newValue * entryPriceValue).toFixed(0) //toUsdFormat(newValue * lastPrice)

                    btcValue = 0
                }
            }
            
            onSetNewState(this, {
                altQtyValue,
                usdQtyValue,
                btcValue
            })

            this.qtyInAltTypingInterval = setTimeout(() => {
                if (isNotNull(usdQtyValue) && !isNaN(usdQtyValue))
                    this.onValidateQtyInUsdtHandler(usdQtyValue, false)
            }, 800)
        } else
            onSetNewState(this, {
                usdQtyErrMsg: tradeFormErrorMessages.quantity.invalidInput,
                isNotValidUsdQty: true,
                altQtyValue: newValue,
                usdQtyValue: newValue,
                btcValue: 0
            })
    }

    onCalculateTotalAltCoin = (newStopPrice, isToUpdateTotalAlt = false) => {
        const { fromSym, btcUsdtLastPrice } = this.props
        const { usdQtyValue } = this.state
        let { altQtyValue } = this.state

        if (isToUpdateTotalAlt && isNotNull(newStopPrice)) {
            if (fromSym === 'BTC') {
                if (isNotNull(btcUsdtLastPrice) && isNotNull(newStopPrice)) {
                    let totalCoinValue = parseFloat(usdQtyValue / btcUsdtLastPrice)
                    if (totalCoinValue > 0) {
                        totalCoinValue = totalCoinValue / newStopPrice
                    }

                    altQtyValue = toBtcFormat(totalCoinValue) //toBtcFormat(totalCoinValue)
                }
            } else {
                if (isNotNull(newStopPrice)) {
                    let totalCoinValue = parseFloat(usdQtyValue) / newStopPrice
                    altQtyValue = toBtcFormat(totalCoinValue) //toBtcFormat(totalCoinValue) //toUsdFormat()
                }
            }
            
            onSetNewState(this, {
                altQtyValue
            })
        }
    }

    onChangeTargetPricesHandler = async (targets) => {
        const { eventHandlers } = this.props
        let { usdQtyValue, targetPrices } = this.state
        targetPrices = targets

        onSetNewState(this, {
            targetPrices,
            isValidatingTargets: false,
            isValidTargetPrices: true
        }, () => {
            eventHandlers.onChangeMyTargetPrices(targetPrices)
        })

        if (isNotNull(usdQtyValue)) {
            await this.onValidateQtyInUsdtHandler(usdQtyValue, true)
        }
    }

    onSetTargetPriceValidityStatus = async (isNotValid) => {
        onSetNewState(this, {
            //isValidatingTargets: false,
            isValidTargetPrices: !isNotValid
        })

        if (isNotValid) {
            onSetNewState(this, {
                showTargetPricesConfig: true
            })
        }
    }

    onDoneChangingTargetPricesHandler = () => {
        onSetNewState(this, {
            isToUpdateTargetsPrices: false
        })
    }

    onDoneValidatingTargets = () => {
        const { stopLossPriceValue } = this.state

        onSetNewState(this, {
            isToValidateTargets: false
        }, () => {
            this.onValidateStopLossPriceHandler(stopLossPriceValue)
        })
    }

    onSetDefaultStoploss = (feed, direction, strategy, fromSym, entryStartPrice, entryEndPrice, originalStoploss) => {
        if (isNotNull(feed) && 
            isNotNull(direction) && 
            isNotNull(strategy)) {
                const { eventHandlers } = this.props
                let { stopLossPriceValue } = this.state

                direction = direction.toUpperCase()
                entryStartPrice = parseFloat(entryStartPrice)
                entryEndPrice = parseFloat(entryEndPrice)

                let newStopPrice = ''
                    
                if (strategy === 'aggressivestoploss') {
                    let fivePercent = ((entryEndPrice - entryStartPrice) * 0.05)
                    if (direction === 'BUY') {
                        newStopPrice = entryStartPrice - fivePercent
                    } else {
                        newStopPrice = entryEndPrice + fivePercent
                    }
                } else if (strategy === 'heikoaggressive') {
                    let diff = entryEndPrice - entryStartPrice
                        diff /= 4

                    if (direction === 'BUY') {
                        newStopPrice = (entryEndPrice - (parseFloat(diff) * 3))
                    } else {
                        newStopPrice = (entryStartPrice + (parseFloat(diff) * 3))
                    }
                } else {
                    newStopPrice = parseFloat(originalStoploss)
                }

                if (newStopPrice !== '') {
                    if (fromSym === 'BTC')
                        newStopPrice = toBtcFormat(newStopPrice)
                    else {
                        if (parseFloat(newStopPrice) < 1)
                            newStopPrice = toBtcFormat(newStopPrice)
                        else
                            newStopPrice = toUsdFormat(newStopPrice)
                    }

                    stopLossPriceValue = newStopPrice
                }

                onSetNewState(this, {
                    stopLossPriceValue
                }, () => {
                    eventHandlers.onChangeMyStoplossPriceHandler(stopLossPriceValue)
                    this.onValidateStopLossPriceHandler(stopLossPriceValue)
                })
        }
    }

    onChangeStopLossPriceHandler = (e) => {
        e.preventDefault()

        const { eventHandlers } = this.props
        const { usdQtyValue } = this.state

        if (isNull(usdQtyValue)) {
            this.onValidateQtyInUsdtHandler(0)
        } else {
            if (this.stopLossPriceTypingInterval)
                clearTimeout(this.stopLossPriceTypingInterval)

            let newValue = e.target.value
            onSetNewState(this, {
                stopLossPriceValue: newValue
            })

            this.stopLossPriceTypingInterval = setTimeout(() => {
                eventHandlers.onChangeMyStoplossPriceHandler(newValue)
                this.onValidateStopLossPriceHandler(newValue)
            }, 800)
        }
    }

    onValidateStopLossPriceHandler = (value) => {
        const { lastPrice, bidPrice, direction } = this.props
        const { isLadderingEnabled, isBuyNow, entryPriceValue, entryPrices, showStopLossConfig } = this.state
        let dir = direction.toLowerCase()

        let hasError = false
        let errMsg = ""

        if (!hasError && (isNull(value) || isNaN(value))) {
            hasError = true
            errMsg = tradeFormErrorMessages.entryPrice.invalidInput
        }

        if (!hasError && (parseFloat(value) <= 0)) {
            hasError = true
            errMsg = tradeFormErrorMessages.stopLossPrice.invalidInput
        }

        if (dir === 'buy') {
            if (!hasError && value >= bidPrice) {
                hasError = true
                errMsg = tradeFormErrorMessages.stopLossPrice.mustBeLessThanBidPrice
            }

            if (!hasError) {
                if (isLadderingEnabled) {
                    let lowestEntry = _.minBy(entryPrices, (o) => { return o.stopPrice })
                    if (value >= lowestEntry.stopPrice) {
                        hasError = true
                        errMsg = tradeFormErrorMessages.stopLossPrice.mustBeLessThanEntryPriceByIndex(lowestEntry.index)
                    }
                } else {
                    if (isBuyNow) {
                        if (isNotNull(lastPrice) && value >= parseFloat(lastPrice)) {
                            hasError = true
                            errMsg = tradeFormErrorMessages.stopLossPrice.mustBeLessThanLastPrice
                        }
                    } else {
                        if (isNotNull(entryPriceValue) && value >= parseFloat(entryPriceValue)) {
                            hasError = true
                            errMsg = tradeFormErrorMessages.stopLossPrice.mustBeLessThanEntryPrice
                        }
                    }
                }
            }
        } else {
            if (!hasError && value <= bidPrice) {
                hasError = true
                errMsg = tradeFormErrorMessages.stopLossPrice.mustBeGreaterThanBidPrice
            }

            if (!hasError) {
                if (isLadderingEnabled) {
                    let lowestEntry = _.maxBy(entryPrices, (o) => { return o.stopPrice })
                    if (value <= lowestEntry.stopPrice) {
                        hasError = true
                        errMsg = tradeFormErrorMessages.stopLossPrice.mustBeGreaterThanEntryPriceByIndex(lowestEntry.index)
                    }
                } else {
                    if (isBuyNow) {
                        if (isNotNull(lastPrice) && value <= parseFloat(lastPrice)) {
                            hasError = true
                            errMsg = tradeFormErrorMessages.stopLossPrice.mustBeGreaterThanLastPrice
                        }
                    } else {
                        if (isNotNull(entryPriceValue) && value <= parseFloat(entryPriceValue)) {
                            hasError = true
                            errMsg = tradeFormErrorMessages.stopLossPrice.mustBeGreaterThanEntryPrice
                        }
                    }
                }
            }
        }

        if (hasError && !showStopLossConfig) {
            onSetNewState(this, {
                showStopLossConfig: true
            })
        }

        onSetNewState(this, {
            isNotValidStopLossPrice: hasError,
            stopLossPriceErrMsg: errMsg,
            isValidStopLoss: !hasError
        })

        return hasError
    }

    onChangeBreakevenHandler = (e) => {
        e.preventDefault()

        let { enableBreakeven } = this.state
        onSetNewState(this, {
            enableBreakeven: !enableBreakeven,
            breakevenHasBeenOverrided: true
        })
    }

    onSetDefaultBreakevenValue = (strategy) => {
        if (isNotNull(strategy)) {
            onSetNewState(this, {
                enableBreakeven: strategy === 'stronguptrend' ? false : true,
                breakevenHasBeenOverrided: true
            })
        }
    }

    onChangePriceTypeHandler = (e) => {
        e.preventDefault()

        let newValue = e.target.value
        onSetNewState(this, {
            priceTypeValue: newValue
        })
    }

    onChangeBreakevenTypeHandler = (e) => {
        e.preventDefault()
        
        let newValue = e.target.value
        onSetNewState(this, {
            breakevenTypeValue: newValue,
            breakeventTypeHasBeenOverrided: true
        })
    }

    onSetDefaultBreakevenTypeValue = (strategy) => {
        if (isNotNull(strategy)) {
            let val
            if (strategy === 'sniper') {
                val = 1
            } else if (strategy === 'weakuptrend') {
                val = 2
            } else {
                val = ''
            }

            onSetNewState(this, {
                breakevenTypeValue: val
            })
        }
    }

    onChangeMarginModeHandler = (e) => {
        e.preventDefault()

        const { symbol, dispatch } = this.props
        let newValue = e.target.value
        onSetNewState(this, {
            marginType: newValue
        })
        dispatch(tradeOrderActions.setMarginType(symbol, newValue))
    }

    onCheckIfUsingUnmodifiedBasicSetup = (entryPrices, targetPrices, stoploss, isLaddering) => {
        const { fromSym, signalInfo } = this.props
        const { tradeStrategy, baseTargetPrices } = this.state

        if (tradeStrategy === 'basic') {
            if (isNotNull(signalInfo.entryStartPrice) && 
                isNotNull(signalInfo.entryEndPrice)) {
                    let dir = signalInfo.direction.toLowerCase()
                    let diff = parseFloat(signalInfo.entryEndPrice) - parseFloat(signalInfo.entryStartPrice)
                        diff /= 4

                    let ladEntries = []
                    let startingEntryPrice = dir === 'buy' ? parseFloat(signalInfo.entryEndPrice) : parseFloat(signalInfo.entryStartPrice)
                    
                    for (let i = 0; i < 5; i++) {
                        if (i > 0) {
                            if (dir === 'buy')
                                startingEntryPrice -= parseFloat(diff)
                            else
                                startingEntryPrice += parseFloat(diff)
                        }
    
                        let ladderedStopPrice = null
                        if (fromSym === 'BTC')
                            ladderedStopPrice = toBtcFormat(startingEntryPrice)
                        else {
                            if (parseFloat(startingEntryPrice) < 1)
                                ladderedStopPrice = toBtcFormat(startingEntryPrice)
                            else
                                ladderedStopPrice = toUsdFormat(startingEntryPrice)
                        }
    
                        let data = {
                            id: `entry-${i}`,
                            index: i,
                            stopPrice: ladderedStopPrice,
                            quantity: 20,
                            isNotValid: false,
                            isQtyNotValid: false,
                            errMsg: ''
                        }
    
                        ladEntries.push(data)
                    }

                    if (_.isEqual(entryPrices, ladEntries) && 
                        _.isEqual(targetPrices, baseTargetPrices) && 
                        _.isEqual(parseFloat(stoploss), parseFloat(signalInfo.stopLoss))) {
                            return true
                        }
            }
        }
        
        return false
    }

    onSubmitTradeOrderHandler = async () => {
        const { isSubmitting, isValidQuantity, isBuyNow, isLadderingEnabled, isValidEntryPrice, isValidLadderedEntryPrices, isValidTargetPrices, isValidStopLoss,
            usdQtyValue, entryPriceValue, altQtyValue, entryPrices, targetPrices, stopLossPriceValue,
            enableBreakeven, priceTypeValue, breakevenTypeValue, leverage } = this.state

        // check if USDT quantity input is valid
        if (isNotNull(usdQtyValue)) {
            //await this.onValidateQtyInUsdtHandler(usdQtyValue)
            //console.log('isValidQuantity: ', isValidQuantity)
            if (isValidQuantity) {
                //console.log('isLadderingEnabled: ', isLadderingEnabled)
                if (isLadderingEnabled) {
                    //console.log('isValidLadderedEntryPrices: ', isValidLadderedEntryPrices)
                    if (isValidLadderedEntryPrices) {
                        //console.log('isValidTargetPrices: ', isValidTargetPrices)
                        if (isValidTargetPrices) {
                            //if (isValidStopLoss && !isNotValidLeverage) {
                            //console.log('isValidStopLoss: ', isValidStopLoss)
                            if (isValidStopLoss) {
                                if (!isSubmitting) {
                                    onSetNewState(this, {
                                        isSubmitting: true
                                    }, () => {
                                        const { onSubmitTraderOrderHandler } = this.props.eventHandlers
                                        let order = {
                                            leverage,
                                            usdQtyValue: parseFloat(usdQtyValue),
                                            altQtyValue: parseFloat(altQtyValue),
                                            isBuyNow,
                                            hasLadderedEntries: isLadderingEnabled,
                                            entryPrices,
                                            targetPrices,
                                            stopLossPriceValue: parseFloat(stopLossPriceValue),
                                            enableBreakeven,
                                            priceTypeValue,
                                            breakevenTypeValue
                                        }

                                        let isUsingDefault = this.onCheckIfUsingUnmodifiedBasicSetup(entryPrices, targetPrices, parseFloat(stopLossPriceValue), true)
                                        if (isUsingDefault) {
                                            Swal.fire({
                                                title: 'Are you sure?',
                                                html: "<p>You didn't make any changes to the trade form, usually this is not the best idea. You can try other strategies from the drop down menu or make your own changes by editing the form in order to create a trade with a good Risk:Reward Ratio.</p> <p>If you are certain you want to proceed with the basic setup then please confirm, otherwise go back and make changes.</p>",
                                                icon: 'warning',
                                                showCancelButton: true,
                                                cancelButtonText: "Go Back",
                                                confirmButtonText: 'Confirm Trade Setup'
                                            }).then((result) => {
                                                if (result.value) {
                                                    onSubmitTraderOrderHandler(order)
                                                } else {
                                                    Swal.close()
                                                }
                                            })
                                        } else {
                                            onSubmitTraderOrderHandler(order)
                                        }
                                    })
                                }
                            } else 
                                this.onValidateStopLossPriceHandler(stopLossPriceValue)
                        } else {
                            onSetNewState(this, {
                                isToValidateTargets: true
                            })
                        }
                    } else
                        this.onValidateLadderedEntryPrices()
                } else {
                    // check if entry price input is valid
                    if (isNotNull(entryPriceValue)) {
                        //await this.onValidateEntryPriceHandler(entryPriceValue)
                        //console.log('no lad isValidEntryPrice: ', isValidEntryPrice)
                        if (isValidEntryPrice) {
                            //console.log('no lad isValidTargetPrices: ', isValidTargetPrices)
                            if (isValidTargetPrices) {
                                //if (isValidStopLoss && !isNotValidLeverage) {
                                    //console.log('no lad isValidStopLoss: ', isValidStopLoss)
                                if (isValidStopLoss) {
                                    if (!isSubmitting) {
                                        onSetNewState(this, {
                                            isSubmitting: true
                                        }, () => {
                                            const { onSubmitTraderOrderHandler } = this.props.eventHandlers
                                            let order = {
                                                leverage,
                                                usdQtyValue: parseFloat(usdQtyValue),
                                                altQtyValue: parseFloat(altQtyValue),
                                                isBuyNow,
                                                hasLadderedEntries: isLadderingEnabled,
                                                entryPriceValue: parseFloat(entryPriceValue),
                                                targetPrices,
                                                stopLossPriceValue: parseFloat(stopLossPriceValue),
                                                enableBreakeven,
                                                priceTypeValue,
                                                breakevenTypeValue
                                            }
                                            onSubmitTraderOrderHandler(order)
                                        })
                                    }
                                } else 
                                    this.onValidateStopLossPriceHandler(stopLossPriceValue)
                            } else {
                                onSetNewState(this, {
                                    isToValidateTargets: true
                                })
                            }
                        } else 
                            await this.onValidateEntryPriceHandler(entryPriceValue)
                    } else
                        this.onValidateEntryPriceHandler(0)
                }
            } else 
                await this.onValidateQtyInUsdtHandler(usdQtyValue)
        }
        else
            this.onValidateQtyInUsdtHandler(0)
    }

    onShowTradeSummaryHandler = async () => {
        const { isShowingTradeSummary, isValidQuantity, isBuyNow, isLadderingEnabled, isValidEntryPrice, isValidLadderedEntryPrices, isValidTargetPrices, isValidStopLoss,
            usdQtyValue, entryPriceValue, altQtyValue, entryPrices, targetPrices, stopLossPriceValue,
            enableBreakeven, priceTypeValue, breakevenTypeValue, leverage } = this.state

        if (isNotNull(usdQtyValue)) {
            if (isValidQuantity) {
                if (isLadderingEnabled) {
                    if (isValidLadderedEntryPrices) {
                        if (isValidTargetPrices) {
                            if (isValidStopLoss) {
                                if (!isShowingTradeSummary) {
                                    onSetNewState(this, {
                                        isShowingTradeSummary: true
                                    }, () => {
                                        const { onShowTradeSummaryHandler } = this.props.eventHandlers
                                        let order = {
                                            leverage,
                                            usdQtyValue: parseFloat(usdQtyValue),
                                            altQtyValue: parseFloat(altQtyValue),
                                            isBuyNow,
                                            hasLadderedEntries: isLadderingEnabled,
                                            entryPrices,
                                            targetPrices,
                                            stopLossPriceValue: parseFloat(stopLossPriceValue),
                                            enableBreakeven,
                                            priceTypeValue,
                                            breakevenTypeValue
                                        }

                                        let isUsingDefault = this.onCheckIfUsingUnmodifiedBasicSetup(entryPrices, targetPrices, parseFloat(stopLossPriceValue), true)
                                        if (isUsingDefault) {
                                            Swal.fire({
                                                title: 'Are you sure?',
                                                html: "<p>You didn't make any changes to the trade form, usually this is not the best idea. You can try other strategies from the drop down menu or make your own changes by editing the form in order to create a trade with a good Risk:Reward Ratio.</p> <p>If you are certain you want to proceed with the basic setup then please confirm, otherwise go back and make changes.</p>",
                                                icon: 'warning',
                                                showCancelButton: true,
                                                cancelButtonText: "Go Back",
                                                confirmButtonText: 'Confirm Trade Setup'
                                            }).then((result) => {
                                                if (result.value) {
                                                    onShowTradeSummaryHandler(order)
                                                } else {
                                                    Swal.close()
                                                }
                                            })
                                        } else {
                                            onShowTradeSummaryHandler(order)
                                        }
                                    })
                                }
                            } else
                                this.onValidateStopLossPriceHandler(stopLossPriceValue)
                        } else {
                            onSetNewState(this, {
                                isToValidateTargets: true
                            })
                        }
                    } else
                        this.onValidateLadderedEntryPrices()
                } else {
                    if (isNotNull(entryPriceValue)) {
                        if (isValidEntryPrice) {
                            if (isValidTargetPrices) {
                                if (isValidStopLoss) {
                                    if (!isShowingTradeSummary) {
                                        onSetNewState(this, {
                                            isShowingTradeSummary: true
                                        }, () => {
                                            const { onShowTradeSummaryHandler } = this.props.eventHandlers
                                            let order = {
                                                leverage,
                                                usdQtyValue: parseFloat(usdQtyValue),
                                                altQtyValue: parseFloat(altQtyValue),
                                                isBuyNow,
                                                hasLadderedEntries: isLadderingEnabled,
                                                entryPriceValue: parseFloat(entryPriceValue),
                                                targetPrices,
                                                stopLossPriceValue: parseFloat(stopLossPriceValue),
                                                enableBreakeven,
                                                priceTypeValue,
                                                breakevenTypeValue
                                            }
                                            onShowTradeSummaryHandler(order)
                                        })
                                    }
                                } else
                                    this.onValidateStopLossPriceHandler(stopLossPriceValue)
                            } else {
                                onSetNewState(this, {
                                    isToValidateTargets: true
                                })
                            }
                        } else
                            await this.onValidateEntryPriceHandler(entryPriceValue)
                    } else
                        this.onValidateEntryPriceHandler(0)
                }
            } else
                await this.onValidateQtyInUsdtHandler(usdQtyValue)
        }
        else
            this.onValidateQtyInUsdtHandler(0)
    }

    renderEntryPriceSection = (toSym, fromSym) => {
        const { hasConnectedExchange, wallet, feed, lastPrice } = this.props
        let { hasWallet, usdQtyValue, isBuyNow, isLadderingEnabled, isNotValidUsdQty, usdtQtyErrTitle, usdQtyErrMsg, altQtyValue,
            entryPriceValue, entryPrices, isNotValidEntryPrice, entryPriceErrMsg, hasLadderedEntriesError, ladderedEntriesErrMsg, 
            btcValue, hasLadderedEntriesQtyError, ladderedEntriesQtyErrorMsg } = this.state

        const renderUsdtTooltip = (
            <Tooltip id='tooltip-right' style={{ zIndex: '9999' }}>
                This is an estimate and may change slightly if your trade order executes at a higher or lower price than intended (slippage).
            </Tooltip>
        )

        return (
            <section id="buy_details">
                { !hasConnectedExchange &&
                    <div>
                        <Alert variant="danger" style={{ padding: '8px 12px', marginBottom: '10px' }}>
                            <p style={{ marginBottom: 0 }}>
                                <b>Oops!</b> We cannot retrieve wallet balance info. Please make sure that you have configured your <Link to="/account/auto-trade" style={{ fontWeight: 600, color: '#007bff' }}>auto-trade settings</Link> correctly.
                            </p>
                        </Alert>
                    </div>
                }

                { isLadderingEnabled && hasLadderedEntriesError && 
                    <div>
                        <Alert variant="danger" style={{ padding: '8px 12px' }}>
                            <p style={{ marginBottom: 0 }}>
                                {ladderedEntriesErrMsg}
                            </p>
                        </Alert>
                    </div>
                }

                { isNotNull(fromSym) && 
                    <>
                        { fromSym === 'BTC' && 
                            <>
                                { isLadderingEnabled && 
                                    <Form.Group>
                                        <Form.Row>
                                            <FormLabel className="col-sm-12 col-md-4 col-lg-4 p-0 mb-0">
                                                Total in USDT ($)
                                                <OverlayTrigger 
                                                    placement="right" 
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderUsdtTooltip}>
                                                    <span style={{ marginLeft: '5px' }}><i className="fas fa-info-circle"></i></span>
                                                </OverlayTrigger>
                                            </FormLabel>
                                            <Col sm={12} md={8} lg={8} className="p-0">
                                                <div className="relative">
                                                    <div className="input-group total">
                                                        <OverlayTooltip
                                                            identifer='tf-quantity'
                                                            title={usdtQtyErrTitle}
                                                            alignment={'left'}
                                                            showTooltip={isNotValidUsdQty}
                                                            message={usdQtyErrMsg}
                                                            renderComponent={
                                                                (target) => {
                                                                    return (
                                                                        <input
                                                                            ref={target}
                                                                            type="text"
                                                                            id="totalInUsdt"
                                                                            className={`form-control total coin-quantity ${isNotValidUsdQty ? 'error' : ''}`}
                                                                            placeholder="Enter amount"
                                                                            autoComplete="off"
                                                                            tabIndex={1}
                                                                            value={usdQtyValue}
                                                                            onKeyPress={(e) => { validatePrice(e) }}
                                                                            onChange={(e) => { this.onChangeQtyInUsdtHandler(e) }} />
                                                                    )
                                                                }
                                                            }
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text">USDT</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Form.Row>
                                    </Form.Group>
                                }

                                { !isLadderingEnabled && 
                                    <Form.Group>
                                        <Form.Row className="justify-content-between align-items-start">
                                            <Col sm={12} md={5} lg={5} className="mb-2">
                                                <FormLabel className="col-sm-12 p-0">
                                                    Total in USDT ($) 
                                                    <OverlayTrigger 
                                                        placement="right" 
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={renderUsdtTooltip}>
                                                        <span style={{ marginLeft: '5px' }}><i className="fas fa-info-circle"></i></span>
                                                    </OverlayTrigger>
                                                </FormLabel>
                                                <Col sm={12} className="p-0">
                                                    <div className="input-group total">
                                                        <OverlayTooltip
                                                            identifer='tf-quantity'
                                                            title={usdtQtyErrTitle}
                                                            alignment={'left'}
                                                            showTooltip={isNotValidUsdQty}
                                                            message={usdQtyErrMsg}
                                                            renderComponent={
                                                                (target) => {
                                                                    return (
                                                                        <input
                                                                            ref={target}
                                                                            type="text"
                                                                            id="totalInUsdt"
                                                                            className={`form-control total coin-quantity ${isNotValidUsdQty ? 'error' : ''}`}
                                                                            placeholder="Enter amount"
                                                                            autoComplete="off"
                                                                            tabIndex={1}
                                                                            value={usdQtyValue}
                                                                            onKeyPress={(e) => { validatePrice(e) }}
                                                                            onChange={(e) => { this.onChangeQtyInUsdtHandler(e) }} />
                                                                    )
                                                                }
                                                            }
                                                        />
                    
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">USDT</span>
                                                        </div>
                                                    </div>
                    
                                                    { // only show if not in USD or USDT values
                                                        isNotNull(btcValue) && btcValue !== 0 &&
                                                        <Col sm={12} className="text-right" style={{ padding: '5px 0 0 0' }}>
                                                            <small className="btc-value">≈ {btcValue} <span>{fromSym === 'BTC' ? 'BTC' : 'USDT'}</span></small>
                                                        </Col>
                                                    }
                                                </Col>
                                            </Col>
                    
                                            { isNotNull(feed) && feed.toUpperCase() === "SPOT" && 
                                                <Col sm={12} md={5} lg={5} className="mb-2">
                                                    <FormLabel className="col-sm-12 p-0">Coin Quantity</FormLabel>
                                                    <Col sm={12} className="p-0">
                                                        <div className="relative">
                                                            <div className="input-group quantity">
                                                                <input
                                                                    type="text"
                                                                    name='buy'
                                                                    className={`form-control coin-quantity`}
                                                                    placeholder="Amount"
                                                                    autoComplete="off"
                                                                    tabIndex={3}
                                                                    value={altQtyValue}
                                                                    onKeyPress={(e) => { validatePrice(e) }}
                                                                    onChange={(e) => this.onChangeQtyInAltHandler(e)} />
                    
                                                                <div className={`input-group-append`}>
                                                                    <span className="input-group-text">{toSym}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Col>
                                            }
                                        </Form.Row>
                                    </Form.Group>
                                }
                            </>
                        }

                        { fromSym === 'USDT' && 
                            <Form.Group>
                                <Form.Row>
                                    <FormLabel className="col-sm-12 col-md-4 col-lg-4 p-0 mb-0">
                                        Total in USDT ($)
                                        <OverlayTrigger 
                                            placement="right" 
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderUsdtTooltip}>
                                            <span style={{ marginLeft: '5px' }}><i className="fas fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </FormLabel>
                                    <Col sm={12} md={8} lg={8} className="p-0">
                                        <div className="relative">
                                            <div className="input-group total">
                                                <OverlayTooltip
                                                    identifer='tf-quantity'
                                                    title={usdtQtyErrTitle}
                                                    alignment={'left'}
                                                    showTooltip={isNotValidUsdQty}
                                                    message={usdQtyErrMsg}
                                                    renderComponent={
                                                        (target) => {
                                                            return (
                                                                <input
                                                                    ref={target}
                                                                    type="text"
                                                                    id="totalInUsdt"
                                                                    className={`form-control total coin-quantity ${isNotValidUsdQty ? 'error' : ''}`}
                                                                    placeholder="Enter amount"
                                                                    autoComplete="off"
                                                                    tabIndex={1}
                                                                    value={usdQtyValue}
                                                                    onKeyPress={(e) => { validatePrice(e) }}
                                                                    onChange={(e) => { this.onChangeQtyInUsdtHandler(e) }} />
                                                            )
                                                        }
                                                    }
                                                />

                                                <div className="input-group-append">
                                                    <span className="input-group-text">USDT</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                        }

                        { fromSym === 'BUSD' && 
                            <Form.Group>
                                <Form.Row>
                                    <FormLabel className="col-sm-12 col-md-4 col-lg-4 p-0 mb-0">
                                        Total in BUSD
                                        <OverlayTrigger 
                                            placement="right" 
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderUsdtTooltip}>
                                            <span style={{ marginLeft: '5px' }}><i className="fas fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </FormLabel>
                                    <Col sm={12} md={8} lg={8} className="p-0">
                                        <div className="relative">
                                            <div className="input-group total">
                                                <OverlayTooltip
                                                    identifer='tf-quantity'
                                                    title={usdtQtyErrTitle}
                                                    alignment={'left'}
                                                    showTooltip={isNotValidUsdQty}
                                                    message={usdQtyErrMsg}
                                                    renderComponent={
                                                        (target) => {
                                                            return (
                                                                <input
                                                                    ref={target}
                                                                    type="text"
                                                                    id="totalInUsdt"
                                                                    className={`form-control total coin-quantity ${isNotValidUsdQty ? 'error' : ''}`}
                                                                    placeholder="Enter amount"
                                                                    autoComplete="off"
                                                                    tabIndex={1}
                                                                    value={usdQtyValue}
                                                                    onKeyPress={(e) => { validatePrice(e) }}
                                                                    onChange={(e) => { this.onChangeQtyInUsdtHandler(e) }} />
                                                            )
                                                        }
                                                    }
                                                />

                                                <div className="input-group-append">
                                                    <span className="input-group-text">BUSD</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                        }
                    </>
                }

                { !isLadderingEnabled && 
                    <Form.Group>
                        <Form.Row>
                            <FormLabel className="col-sm-12 col-md-4 col-lg-4 p-0 mb-0">Entry Price</FormLabel>
                            <Col sm={12} md={8} lg={8} className="p-0">
                                <div className="relative">
                                    <div className="input-group">
                                        <OverlayTooltip
                                            identifer='tf-entryPrice'
                                            showTooltip={!isNotValidUsdQty && isNotValidEntryPrice ? true : false}
                                            message={entryPriceErrMsg}
                                            title={'Entry price error'}
                                            alignment={'left'}
                                            renderComponent={
                                                (target) => {
                                                    return (
                                                        <input
                                                            ref={target}
                                                            type="text"
                                                            name='buy'
                                                            className={`form-control entryPrice-basic ${entryPriceErrMsg ? 'error' : ''}`}
                                                            placeholder="Entry Price"
                                                            autoComplete="off"
                                                            tabIndex={2}
                                                            value={ScientificToDecimal(entryPriceValue)} 
                                                            disabled={isBuyNow} 
                                                            onKeyPress={(e) => { validatePrice(e) }}
                                                            onChange={(e) => this.onChangeEntryPriceHandler(e)} />
                                                    )
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                    </Form.Group>
                }

                { isLadderingEnabled && hasLadderedEntriesQtyError &&
                    <div>
                        <Alert variant="danger" style={{ padding: '8px 12px', marginBottom: '10px' }}>
                            <p style={{ marginBottom: 0 }}>
                                {ladderedEntriesQtyErrorMsg}
                            </p>
                        </Alert>
                    </div>
                }
                
                { isLadderingEnabled && isNotNull(entryPrices) && 
                    entryPrices.map((item, key) => {
                        return (
                            <Form.Group key={key}>
                                <Form.Row>
                                    <FormLabel className="col-sm-12 col-md-4 col-lg-4 mb-0 pl-0">Entry Price {key + 1}</FormLabel>
                                    <Col sm={12} md={8} lg={8} className="pl-0">
                                        <div className="relative">
                                            <div className="input-group">
                                                <OverlayTooltip 
                                                    title={'Entry Price error'}
                                                    alignment={'right'}
                                                    showTooltip={item.isNotValid}
                                                    message={item.errMsg} 
                                                    renderComponent={
                                                        (entry) => {
                                                            return (
                                                                <input 
                                                                    ref={entry} 
                                                                    type="text" 
                                                                    name={item.id} 
                                                                    className={`form-control multitarget target-index${key} ${item.isNotValid ? 'error' : ''}`} 
                                                                    placeholder="Entry Price" 
                                                                    value={ScientificToDecimal(item.stopPrice)} 
                                                                    disabled={isBuyNow && (key + 1) === 1} 
                                                                    onKeyPress={(e) => { validatePrice(e) }}
                                                                    onChange={(e) => { this.onChangeLadderedEntryPriceHandler(e, item.index) }} />
                                                            )
                                                        }
                                                    } 
                                                />
                                                
                                                <div className="input-group-append" style={{ width: '48px', paddingLeft: '1px', paddingRight: '1px' }}>
                                                    <TargetPriceInput 
                                                        type="text" 
                                                        id={`qty-entry-${key}`} 
                                                        name={item.id} 
                                                        className={`form-control qty ${item.isQtyNotValid ? 'error' : ''}`} 
                                                        placeholder="Qty" 
                                                        value={item.quantity} 
                                                        onKeyPress={(e) => { validatePrice(e) }} 
                                                        onChange={(e) => { this.onChangeLadderedEntryPriceQtyHandler(e, item.index) }} />
                                                </div>

                                                <div className="input-group-append">
                                                    <TargetAllocationInput className="input-group-text">
                                                        <i className="fas fa-percent"></i>
                                                    </TargetAllocationInput>
                                                </div>

                                                { entryPrices.length > 1 &&
                                                    <>
                                                        { (isBuyNow && (key + 1) === 1) && 
                                                            <span className="text-danger p-2"
                                                                style={{ minWidth: '27px' }}></span>
                                                        }

                                                        { !(isBuyNow && (key + 1) === 1) && 
                                                            <span className="text-danger p-2"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={(e) => this.onRemoveLadderedEntryPrice(e, item.index)}>
                                                                <i className="fas fa-times"></i>
                                                            </span>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                        )
                    })
                }

                { isLadderingEnabled && isNotNull(entryPrices) && entryPrices.length < 5 &&
                    <Form.Group>
                        <Form.Row>
                            <div className="text-right col-sm-12">
                                <SafeAnchor onClick={(e) => this.onAddLadderedEntryPrice(e)} >
                                    <small style={{ color: '#15BD6F', textTransform: 'uppercase' }}>
                                        + ADD ENTRY
                                    </small>
                                </SafeAnchor>
                            </div>
                        </Form.Row>
                    </Form.Group>
                }
            </section>
        )
    }

    renderTargetPriceSection = () => {
        let { 
            usdQtyValue, 
            isNotValidUsdQty, 
            altQtyValue, 
            baseTargetPrices,
            isValidatingTargets, 
            isToUpdateTargetsPrices, 
            entryPriceValue, 
            isToValidateTargets,
            isBuyNow,
            isLadderingEnabled } = this.state

        const { 
            isPremiumUser,
            isFree,
            direction, 
            symbol, 
            bidPrice, 
            askPrice, 
            lastPrice,
            tradeStrategy, 
            signalInfo, 
            orderDetails,
            myEntryPrice, 
            myStoplossPrice } = this.props

        let feed = ""
        if (isNotNull(orderDetails) && isNotNull(orderDetails.feed))
            feed = orderDetails.feed.toUpperCase()

        let targets = []
        if (isNotNull(tradeStrategy) && isNotNull(signalInfo)) {
            let defaultQuantities = isNotNull(tradeStrategy)
                ? defaultTargetPricesQty(tradeStrategy, signalInfo.targets.length)
                : null

            signalInfo.targets.forEach((i) => {
                targets.push({
                    id: i.id,
                    index: i.index,
                    stopPrice: i.startPrice,
                    relativeStopPrice: "",
                    quantity: isNotNull(defaultQuantities) ? defaultQuantities[i.index - 1] : 0
                })
            })
        }

        return (
            <section id="target_details" className="mb-3">
                <TakeProfitForm 
                    isPremiumUser={isPremiumUser} 
                    isFree={isFree}  
                    tradeStrategy={tradeStrategy}
                    direction={direction}
                    feed={feed} 
                    symbol={symbol}
                    bidPrice={bidPrice}
                    askPrice={askPrice}
                    lastPrice={lastPrice}
                    isBuyNow={isBuyNow}
                    isLadderingEnabled={isLadderingEnabled}
                    usdQtyValue={usdQtyValue}
                    isNotValidUsdQty={isNotValidUsdQty}
                    altQtyValue={altQtyValue}
                    entryPriceValue={entryPriceValue} 
                    myEntryPrice={myEntryPrice} 
                    myStoplossPrice={myStoplossPrice}
                    originalTargets={targets}
                    targetPrices={baseTargetPrices}
                    isToUpdateTargetsPrices={isToUpdateTargetsPrices}
                    isValidatingTargets={isValidatingTargets}
                    isToValidateTargets={isToValidateTargets} 
                    eventHandlers={{
                        onValidateQtyInUsdtHandler: this.onValidateQtyInUsdtHandler.bind(this),
                        onChangeTargetPricesHandler: this.onChangeTargetPricesHandler.bind(this),
                        onSetTargetPriceValidityStatus: this.onSetTargetPriceValidityStatus.bind(this),
                        onDoneChangingTargetPricesHandler: this.onDoneChangingTargetPricesHandler.bind(this),
                        onDoneValidatingTargets: this.onDoneValidatingTargets.bind(this)
                    }} />
            </section>
        )
    }

    renderStopLossPriceSection = () => {
        const { lastPrice, isPremiumUser, direction } = this.props
        let { 
            stopLossPriceValue, 
            isNotValidStopLossPrice, 
            stopLossPriceErrMsg, 
            isBreakevenApplicable, 
            enableBreakeven, 
            isLadderingEnabled, 
            isBuyNow,
            entryPriceValue, 
            entryPrices } = this.state

        const renderOverlayText = (type) => {
            const text = type === "TARGET" ? "Percentage Gain per target" : "Stop Loss percentage"
            return (
                <Tooltip className="percentage-tooltip" style={{ zIndex: '9999' }}>
                    <i className="fas fa-info-circle"></i>
                    <span className="ml-2">{text}</span>
                </Tooltip >
            )
        }

        const renderStopLossCalculation = (direction, stopLoss, entryPrice) => {
            if (isNotNull(direction) && isNotNull(stopLoss) && isNotNull(entryPrice)) {
                const sl = parseFloat(stopLoss)
                
                if (isLadderingEnabled && isNotNull(entryPrices) && entryPrices.length > 1) {
                    let sum = _.sumBy(entryPrices, e => parseFloat(e.stopPrice))
                    let averageEntryPrice = sum / entryPrices.length
                    
                    if (direction.toUpperCase() === "BUY") {
                        let result = (((sl - averageEntryPrice) / sl) * 100).toFixed(2)
                        //const total = _.round(((loss - entry) / loss) * 100, 2)
                        //const isPositive = total > 0 ? "-" : ""
                        return (<span className="stoploss-calculation">{`${result}%`}</span>)
                    } else {
                        let result = (((averageEntryPrice - sl) / averageEntryPrice) * 100).toFixed(2) 
                        return (<span className="stoploss-calculation">{`${result}%`}</span>)
                    }
                } else {
                    const entry = isBuyNow ? parseFloat(lastPrice) : parseFloat(entryPrice)

                    if (direction.toUpperCase() === "BUY") {
                        let result = (((sl - entry) / sl) * 100).toFixed(2)
                        //const total = _.round(((loss - entry) / loss) * 100, 2)
                        //const isPositive = total > 0 ? "-" : ""
                        return (<span className="stoploss-calculation">{`${result}%`}</span>)
                    } else {
                        let result = (((entry - sl) / entry) * 100).toFixed(2) 
                        return (<span className="stoploss-calculation">{`${result}%`}</span>)
                    }
                }
            } else
                return (<span className="stoploss-calculation">--</span>)
        }

        let breakevenHelpLink = isPremiumUser 
            ? 'https://pro-support.profitfarmers.com/article/54-how-to-use-profitfarmers-chapter-3-part-3-breakeven-stop-loss-feature' 
            : 'https://support.profitfarmers.com/article/179-how-to-use-profitfarmers-chapter-3-part-3-breakeven-stop-loss-feature'

        const renderBreakevenTooltip = (
            <Tooltip id='breakeven-tooltip-right' style={{ zIndex: '9999' }}>
                This is a smart feature that automatically moves your stop loss as your trade progresses. <SafeAnchor href={breakevenHelpLink} target="_blank" rel="noreferrer">Learn more</SafeAnchor>
            </Tooltip>
        )

        return (
            <section id="stop_loss_details" className="mb-3">
                <Form.Group>
                    <Form.Row>
                        <FormLabel className="col-sm-5 col-lg-4">Stop Loss Price</FormLabel>
                        <Col sm={7} lg={8}>
                            <div className="relative">
                                <div className={`input-group`}>
                                    <OverlayTooltip
                                        title={'Stoploss error'}
                                        alignment={'right'}
                                        showTooltip={isNotValidStopLossPrice}
                                        message={stopLossPriceErrMsg}
                                        renderComponent={
                                            (stoploss) => {
                                                return (
                                                    <input
                                                        ref={stoploss}
                                                        type="text"
                                                        name={'stoploss'}
                                                        className={`form-control stoploss-price ${isNotValidStopLossPrice ? 'error' : ''}`}
                                                        placeholder="Stop Loss Price"
                                                        value={ScientificToDecimal(stopLossPriceValue)}
                                                        onKeyPress={(e) => { validatePrice(e) }}
                                                        onChange={(e) => { this.onChangeStopLossPriceHandler(e) }} />
                                                )
                                            }
                                        }
                                    />
                                    <div className="input-group-append">
                                        <OverlayTrigger
                                            placement="top-end"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderOverlayText("STOPLOSS")}
                                        >
                                            <TargetAllocationInput className="input-group-text">
                                                {renderStopLossCalculation(direction, stopLossPriceValue, entryPriceValue)}
                                            </TargetAllocationInput>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Form.Row>
                </Form.Group>

                <Form.Group className="breakeven-group" style={{ marginBottom: '0' }}>
                    <Form.Row className="breakeven-wrapper">
                        <FormLabel htmlFor="" className="breakeven col-sm-5 col-lg-4">
                            Breakeven
                            <OverlayTrigger 
                                placement="right" 
                                delay={{ show: 250, hide: 800 }}
                                overlay={renderBreakevenTooltip}>
                                <span style={{ marginLeft: '5px' }}><i className="fas fa-info-circle"></i></span>
                            </OverlayTrigger>
                        </FormLabel>
                        <Col sm={7} lg={8} className="toggle-switch">
                            { isBreakevenApplicable && 
                                <ToggleButton className={`trade-order-toggle active`}>
                                    <div className="input-cont">
                                        <span className="toggle-wrapper">
                                            <input
                                                type="checkbox"
                                                checked={enableBreakeven}
                                                onChange={(e) => { this.onChangeBreakevenHandler(e) }}
                                            />
                                            <i className={`trade-order-toggle active`} />
                                            <span className="toggle-label"> {enableBreakeven ? 'Yes' : 'No'} </span>
                                        </span>
                                    </div>
                                </ToggleButton>
                            }
                            
                            { !isBreakevenApplicable && 
                                <small style={{ color: '#c7bd8b'}}><em>Not applicable for trades with only 1 target price</em></small>
                            }
                        </Col>
                    </Form.Row>
                </Form.Group>
            </section>
        )
    }

    renderLeverageSection = () => {
        let { leverage, isNotValidLeverage, leverageErrMsg } = this.state

        const muiTheme = createTheme({
            overrides: {
                MuiSlider: {
                    thumb: {
                        color: "#15bd6f",
                        marginTop: -4
                    },
                    track: {
                        height: 5,
                        color: '#15bd6f'
                    },
                    rail: {
                        height: 5,
                        color: 'black'
                    },
                    mark: {
                        height: 8,
                        width: 8,
                        color: 'black',
                        borderRadius: '100%',
                        marginTop: -2,
                        marginLeft: -3
                    }
                }
            }
        })

        const marks = [
            {
                value: 25,
            },
            {
                value: 50,
            },
            {
                value: 75,
            },
            {
                value: 100,
            }
        ]

        const renderLeverageTooltip = (
            <Tooltip id='leverage-tooltip-right' style={{ zIndex: '9999', width: '320px' }}>
                After picking a leverage level, please enter the dollar value with the leverage applied into the trade quantity box. The trade quantity is the total size of your trade regardless of which leverage multiple you choose. <SafeAnchor href="https://profitfarmers.freshdesk.com/support/solutions/articles/47001183206-risks-of-using-leverage-when-trading" target="_blank" rel="noreferrer">Learn more</SafeAnchor>
                <br /><br />
                eg. To use 10x leverage on a $100 account, input $1000 into the trade quantity and choose a leverage multiple of at least 10x
            </Tooltip>
        )

        return (
            <>
                { isNotNull(leverage) &&
                    <Form.Group>
                        <Form.Row>
                            <FormLabel className="col-sm-12 col-md-4 col-lg-4">
                                Leverage 
                                <OverlayTrigger 
                                    placement="right" 
                                    delay={{ show: 250, hide: 800 }}
                                    overlay={renderLeverageTooltip}>
                                    <span style={{ marginLeft: '5px' }}><i className="fas fa-info-circle"></i></span>
                                </OverlayTrigger>
                            </FormLabel>
                            <Col sm={12} md={8} lg={8}>
                                <div className="input-group">
                                    <OverlayTooltip
                                        identifer='tf-leverage'
                                        showTooltip={isNotValidLeverage}
                                        title={'Invalid leverage'}
                                        message={leverageErrMsg}
                                        alignment={'left'}
                                        renderComponent={
                                            (lev) => {
                                                return (
                                                    <div className="form-control d-flex align-items-center">
                                                        <ThemeProvider theme={muiTheme}>
                                                            <Slider
                                                                aria-labelledby="leverage-slider"
                                                                valueLabelDisplay="auto"
                                                                value={typeof leverage === 'number' ? leverage : 1}
                                                                min={1}
                                                                max={125}
                                                                marks={marks}
                                                                onChange={this.onChangeSliderHandler} />
                                                        </ThemeProvider>
                                                    </div>
                                                )
                                            }
                                        }
                                    />
                                    <div className="input-group-append" style={{ minWidth: '60px' }}>
                                        <span
                                            className="input-group-text"
                                            style={{
                                                display: 'block',
                                                width: '100%',
                                                color: 'gold',
                                                fontWeight: '600',
                                                textAlign: 'center',
                                                lineHeight: '26px'
                                            }}>{`${leverage}x`}</span>
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                    </Form.Group>
                }
            </>
        )
    }

    renderOtherSettingsSection = () => {
        const { isPremiumUser, priceTypeOptions, breakEvenTypeOptions, marginModeOptions, orderDetails } = this.props
        const { marginType, enableBreakeven, breakevenTypeValue } = this.state

        let priceTypeHelpLink = isPremiumUser 
            ? 'https://pro-support.profitfarmers.com/article/89-what-does-price-type-to-follow-mean' 
            : 'https://support.profitfarmers.com/article/238-what-does-price-type-to-follow-mean'

        const renderBreakevenTypeTooltip = (
            <Tooltip id='breakeven-type-tooltip-right' style={{ zIndex: '9999' }}>
                Behaviour of Break-Even when Take Profit targets are hit
            </Tooltip>
        )

        const renderPriceTypeTooltip = (
            <Tooltip id='price-type-tooltip-right' style={{ zIndex: '9999' }}>
                <SafeAnchor href={priceTypeHelpLink} target="_blank" rel="noreferrer">Learn more</SafeAnchor>
            </Tooltip>
        )
        
        const renderCrossedTooltip = (
            <Tooltip id='crossed-tooltip-right' style={{ zIndex: '9999', width: '320px' }}>
                In Cross Margin mode, the entire margin balance is shared across open positions to avoid liquidation. If Cross Margin is enabled, the trader risks losing their entire margin balance along with any open positions in the event of a liquidation. Any realized PnL from another position can aid a losing position that is close to being liquidated.                   
            </Tooltip>
        )

        const renderIsolatedTooltip = (
            <Tooltip id='isolated-tooltip-right' style={{ zIndex: '9999', width: '320px' }}>
                If a trader’s position is liquidated in Isolated Margin mode, instead of their entire margin balance, only the Isolated Margin balance gets liquidated.
            </Tooltip>
        )

        return (
            <section>
                <Form.Group>
                    <Form.Row>
                        <FormLabel className="col-sm-12 col-md-4 col-lg-4 mb-0">
                            Price Type
                            <OverlayTrigger 
                                placement="right" 
                                delay={{ show: 250, hide: 800 }}
                                overlay={renderPriceTypeTooltip}>
                                <span style={{ marginLeft: '5px' }}><i className="fas fa-info-circle"></i></span>
                            </OverlayTrigger>
                        </FormLabel>
                        <Col sm={12} md={8} lg={8}>
                            <select className="form-control" onChange={(e) => { this.onChangePriceTypeHandler(e) }}>
                                { isNotNull(priceTypeOptions) &&
                                    priceTypeOptions.map((item, key) =>
                                        (<option key={key} value={item.value}>{item.name}</option>))
                                }
                            </select>
                        </Col>
                    </Form.Row>
                </Form.Group>

                { enableBreakeven && 
                    <>
                        <Form.Group style={{ marginBottom: '1rem' }}>
                            <Form.Row>
                                <FormLabel className="col-sm-12 col-md-4 col-lg-4 mb-0">
                                    Breakeven Type
                                    <OverlayTrigger 
                                        placement="right" 
                                        delay={{ show: 250, hide: 800 }}
                                        overlay={renderBreakevenTypeTooltip}>
                                        <span style={{ marginLeft: '5px' }}><i className="fas fa-info-circle"></i></span>
                                    </OverlayTrigger>
                                </FormLabel>
                                <Col sm={12} md={8} lg={8}>
                                    <select className="form-control" value={breakevenTypeValue} onChange={(e) => { this.onChangeBreakevenTypeHandler(e) }}>
                                        { isNotNull(breakEvenTypeOptions) && 
                                            breakEvenTypeOptions.map((item, key) => 
                                                (<option key={key} value={item.value}>{item.name}</option>))
                                        }
                                    </select>
                                </Col>
                            </Form.Row>
                        </Form.Group>
                    </>
                }

                { isNotNull(orderDetails) && 
                    isNotNull(orderDetails.feed) && 
                    orderDetails.feed !== 'SPOT' &&
                    <>
                        <Form.Group style={{ marginBottom: '1rem' }}>
                            <Form.Row>
                                <FormLabel className="col-sm-12 col-md-4 col-lg-4">
                                    Margin Mode 
                                    { marginType === 'CROSSED' && 
                                        <OverlayTrigger 
                                            placement="right" 
                                            delay={{ show: 250, hide: 800 }}
                                            overlay={renderCrossedTooltip}>
                                            <span style={{ marginLeft: '5px' }}><i className="fas fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    }

                                    { marginType === 'ISOLATED' && 
                                        <OverlayTrigger 
                                            placement="right" 
                                            delay={{ show: 250, hide: 800 }}
                                            overlay={renderIsolatedTooltip}>
                                            <span style={{ marginLeft: '5px' }}><i className="fas fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    }
                                </FormLabel>
                                <Col sm={12} md={8} lg={8}>
                                    <select className="form-control" onChange={(e) => { this.onChangeMarginModeHandler(e) }}>
                                        { isNotNull(marginModeOptions) && 
                                            marginModeOptions.map((item, key) => 
                                                (<option key={key} value={item.value}>{item.name}</option>))
                                        }
                                    </select>
                                </Col>
                            </Form.Row>
                        </Form.Group>
                        
                        {this.renderLeverageSection()}
                    </>
                }
            </section>
        )
    }    

    renderLadderingTooltip = () => {
        const { isPremiumUser } = this.props
        let helpLink = isPremiumUser 
            ? 'https://pro-support.profitfarmers.com/article/92-what-is-laddering-and-how-do-you-ladder-entry-prices' 
            : 'https://support.profitfarmers.com/article/240-what-is-laddering-and-how-do-you-ladder-entry-prices'

        return (
            <Tooltip id='laddering-tooltip-right' style={{ zIndex: '9999' }}>
                Laddering entries is a technique where instead of buying at a single price, you could buy coins at different prices. <SafeAnchor href={helpLink} target="_blank" rel="noreferrer">Learn more</SafeAnchor>
            </Tooltip>
        )
    }

    render() {
        const { askPrice, bidPrice, toSym, fromSym, signalInfo, hasConnectedExchange } = this.props
        const { isBuyNow, isLadderingEnabled } = this.state
        let { isSubmitting, isShowingTradeSummary, showStopBuyConfig, showTargetPricesConfig, showStopLossConfig, showAdvancedConfig } = this.state

        return (
            <div className="create-order-column basic">
                <TradeFormBody className="basic-trade-block mt-2">
                    <Card className="with-form">
                        <TradeFormInner className="card-body basic">
                            <FormHorizontal>
                                <FormTitle
                                    aria-controls="buy_details"
                                    aria-expanded={showStopBuyConfig}>
                                    <span><i className="fa fa-sign-in-alt pr-2"></i> {isNotNull(signalInfo) && signalInfo.direction} details</span>
                                    <span className="float-right">
                                        <ThemeSwitchWrapper className="switch-button theme-desktop">
                                            <label className="theme-switch active">
                                                <span className="input-cont">
                                                    <span className="toggle-wrapper">
                                                        <input
                                                            type="checkbox" 
                                                            checked={isBuyNow}
                                                            onChange={this.onEnableBuyNow}
                                                        />

                                                        <i className={`theme-toggle active`} />
                                                    </span>
                                                    <span className="toggle-label">
                                                        Market {isNotNull(signalInfo) && signalInfo.direction}
                                                    </span>
                                                </span>
                                            </label>
                                        </ThemeSwitchWrapper>

                                        <ThemeSwitchWrapper className="switch-button theme-desktop">
                                            <label className="theme-switch active">
                                                <span className="input-cont">
                                                    <span className="toggle-wrapper">
                                                        <input
                                                            type="checkbox" 
                                                            checked={isLadderingEnabled}
                                                            onChange={this.onEnableLaddering}
                                                        />

                                                        <i className={`theme-toggle active`} />
                                                    </span>
                                                    <span className="toggle-label">
                                                        Laddering 
                                                        <OverlayTrigger 
                                                            placement="left" 
                                                            delay={{ show: 250, hide: 800 }}
                                                            overlay={this.renderLadderingTooltip()}>
                                                            <span style={{ marginLeft: '5px' }}><i className="fas fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </span>
                                                </span>
                                            </label>
                                        </ThemeSwitchWrapper>

                                        {/* <Button variant="primary" size="sm">
                                            <span>Laddering</span>
                                        </Button> */}

                                        {/* <span className="mr-2 lastprice">
                                            <img src="/images/price-range/server-outline.svg" alt="Lastprice Icon" className="mr-1 img-fluid" />
                                            Last price:
                                        </span>
                                        <span className="text-white">{isNotNull(lastPrice) ? lastPrice : "loading..."}</span> */}
                                    </span>
                                </FormTitle>
                                <Collapse in={showStopBuyConfig}>
                                    {this.renderEntryPriceSection(toSym, fromSym)}
                                </Collapse>

                                <FormTitle>
                                    <span><i className="fa fa-bullseye pr-2"></i> Target details</span>
                                    <span
                                        className="float-right"
                                        aria-controls="target_details"
                                        aria-expanded={showTargetPricesConfig}
                                        onClick={() => this.onToggleTargetDetails()}>
                                        {
                                            showTargetPricesConfig
                                                ? <small>- HIDE DETAILS</small>
                                                : <small>+ SHOW DETAILS</small>
                                        }
                                    </span>
                                </FormTitle>
                                <Collapse in={showTargetPricesConfig}>
                                    {this.renderTargetPriceSection()}
                                </Collapse>

                                <FormTitle>
                                    <span><i className="fa fa-stop-circle pr-2"></i> Stop Loss details</span>
                                    <span
                                        className="float-right"
                                        aria-controls="stop_loss_details"
                                        aria-expanded={showStopLossConfig}
                                        onClick={() => this.onToggleStopLossDetails()}>
                                        {
                                            showStopLossConfig
                                                ? <small>- HIDE DETAILS</small>
                                                : <small>+ SHOW DETAILS</small>
                                        }
                                    </span>
                                </FormTitle>
                                <Collapse in={showStopLossConfig}>
                                    {this.renderStopLossPriceSection()}
                                </Collapse>

                                <FormTitle>
                                    <span><i className="fas fa-cogs pr-2"></i> Advanced Options</span>
                                    <span
                                        className="float-right" 
                                        aria-controls="advanced_options" 
                                        aria-expanded={showAdvancedConfig} 
                                        onClick={() => this.onToggleAdvancedOptions()}>
                                        {
                                            showAdvancedConfig 
                                                ? <small>- HIDE</small> 
                                                : <small>+ SHOW</small>
                                        }
                                    </span>
                                </FormTitle>
                                <Collapse in={showAdvancedConfig}>
                                    {this.renderOtherSettingsSection()}
                                </Collapse>
                            </FormHorizontal>
                        </TradeFormInner>
                    </Card>

                    <Form.Group className="button-confirm">
                        <TradeFormActions>
                            { hasConnectedExchange &&
                                <TradeFormConfirmBtn
                                    id="basic-trade-confirm"
                                    className="btn btn-success basic-trade-btn"
                                    disabled={isNotNull(bidPrice) && isNotNull(askPrice) && isSubmitting}
                                    onClick={(e) => this.onSubmitTradeOrderHandler(e)}>
                                    <span>CONFIRM TRADE</span>
                                </TradeFormConfirmBtn>
                            }

                            { !hasConnectedExchange &&
                                <TradeFormConfirmBtn
                                    id="basic-trade-confirm"
                                    className="btn btn-success basic-trade-btn"
                                    disabled={isNotNull(bidPrice) && isNotNull(askPrice) && isShowingTradeSummary}
                                    onClick={(e) => this.onShowTradeSummaryHandler(e)}>
                                    <span>TRADE SUMMARY</span>
                                </TradeFormConfirmBtn>
                            }
                        </TradeFormActions>
                    </Form.Group>
                </TradeFormBody>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { leverageSettings } = state
    return {
        leverageSettings
    }
}

const connectedBasicForm = connect(mapStateToProps)(BasicForm)
export { connectedBasicForm as BasicForm } 