import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const GuideArticlesStyle = styled.div`
    display: flex;
    width: 100%;

    .guide-article-list {
        display: flex;
        flex-wrap: no-wrap;
        overflow-x: auto;
        overflow-y: hidden;
        margin: 0;
        padding-inline-start: 0;
        padding-bottom: 10px;

        ::-webkit-scrollbar {
            height: 8px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #2C3B4D !important;
            background-image: unset;
        }

        ::-webkit-scrollbar-track {
            background-color: #131D2A;
        }

        .guide-article-list-item {
            list-style-type: none;
            margin-right: 10px;
            background-color: #1A2839;
            border: 1px solid #293E54;
            border-radius: 10px;
            transition: all .4s;

            &:hover {
                filter: brightness(108%);
                transform: scale(.96);
                box-shadow: 0px 3px 18px #00000061;
            }

            .guide-article-list-item-link {
                transition: all .3s;

                img {
                    width: 252px;
                    /* width: 100%; */
                    height: 135px;
                    object-fit: cover;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }

                .guide-article-item-details {
                    position: relative;
                    display: inline-block;
                    padding: 10px 15px 0;

                    .guide-article-item-category {
                        color: #8A9BAE;
                        font-size: 14px;
                        margin-bottom: 10px;
                    }

                    .guide-article-item-title {
                        color: #FFFFFF;
                        font-size: 15px;
                    }
                }
            }

            //trade and strategy icons
            &.trade-guides {
                .guide-article-item-details:before {
                    content: '';
                    position: absolute;
                    width: 45px;
                    height: 45px;
                    background-image: url(/images/guide-articles/trade-guides-icon.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    top: -22.5px;
                    right: 10px;
                }
            }

            &.strategy-guides {
                .guide-article-item-details:before {
                    content: '';
                    position: absolute;
                    width: 45px;
                    height: 45px;
                    background-image: url(/images/guide-articles/strategy-guide-icon.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    top: -22.5px;
                    right: 10px;
                }
            }
        }
    }
`