import React from 'react'
import { connect } from 'react-redux'
import jQuery from 'jquery'
import _ from 'lodash'
import iziToast from 'izitoast'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { WalletSidebarView } from './WalletSidebarView'
import { walletActionTypes } from '../../redux/CONSTANTS'
import { walletActions, orderActions } from '../../redux/actions'
import { isQuickTradeLive } from '../../services/CONSTANTS'
import { isNotNull, getCoinName, price_checker, isNull, Dimension, onSetNewState } from '../../utils'
class WalletSidebarContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isDisable: true,
            walletShow: true,
            balanceShow: false,
            searchValue: null,
            binanceData: [],
            isAutoTradeConfigured: null,
            totalBalance: null,
            isActive: null,
            channel: 'wss://stream.binance.com:9443/ws/!ticker@arr',
            currentSet: 20,
            totalItems: 0,
            feed: true,
            quickTradeShow: false,
            sourceCoin: "USDT",
            targetCoin: "BTC",
            calculation: null,
            orderType: "MARKET_SELL",
            exchange: "binance",
            coin: "BTC/USDT",
            live: isQuickTradeLive,
            quickTradeLastPrice: null,
            quickTradeBidPrice: null,
            quickTradeAskPrice: null
        }
        this.baseState = this.state
        this.wsBinance24hrTicker = null
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        window.addEventListener('resize', () => Dimension.updateDimensions(this))
        const { dispatch } = this.props
        const { user } = this.props.authentication

        this.onWalletDisableHandler(user)
        
        if (isNotNull(user)) {
            dispatch(walletActions.getWallets())
            dispatch(walletActions.getFutureWallets())
            dispatch(orderActions.getOrderPositions())
        }

        // if (isNotNull(user) && isNotNull(user.tradingApiKey))
        //     dispatch(tradingActions.getSettings())

        if (this.state.binanceData.length <= 0)
            dispatch(walletActions.getBinance())

        this.initWallet()
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { isAutoTradeConfigured, totalBalance } = this.state
            const { dispatch, tradings, cryptoWallet } = this.props

            if (prevProps.authentication !== this.props.authentication) {
                const { user } = this.props.authentication
                this.onWalletDisableHandler(user)

                if (isNotNull(user) && isNull(cryptoWallet.loading)) {
                    dispatch(walletActions.getWallets())
                    dispatch(walletActions.getFutureWallets())
                    dispatch(orderActions.getOrderPositions())
                }
            }

            if (isNull(totalBalance) && isNotNull(cryptoWallet)) {
                const { items, loading } = cryptoWallet
                if (!loading && isNotNull(items) && isNotNull(items.data)) {
                    const totalBalance = _.sumBy(items.data, bal => parseFloat(bal.balance.free))
                    onSetNewState(this, {
                        isDisable: false,
                        totalBalance,
                        totalItems: items.data.length,
                        isActive: "active"
                    })
                }
            }

            if (isNull(isAutoTradeConfigured) && 
                isNotNull(tradings) && 
                isNotNull(tradings.settings)) {
                const { settings } = tradings
                if (isNotNull(settings.data) &&
                    isNotNull(settings.data.id))
                    onSetNewState(this, { isAutoTradeConfigured: true })
            }

            if (prevProps.viewQuickTrade === false && this.props.viewQuickTrade === true)
                this.onShowQuickTradeHandler()

            if (prevProps.getBinance !== this.props.getBinance) {
                const { items } = this.props.getBinance
                if (isNotNull(items) && items.length > 0) {
                    let newLists = []
                    _.forEach(items, s => newLists.push(s))
                    onSetNewState(this, { binanceData: newLists })
                }
            }
        }
    }

    componentWillUnmount() {
        if (this.wsBinance24hrTicker !== null) {
            this.wsBinance24hrTicker.close()
            this.wsBinance24hrTicker = null
        }
        onSetNewState(this, this.baseState)
        window.removeEventListener('resize', () => Dimension.updateDimensions(this))
        this.mounted = false
    }

    onScrollLoadNewWalletDataHandler = () => {
        const { currentSet, totalItems } = this.state
        const newSet = currentSet + 20
        if (currentSet <= totalItems)
            onSetNewState(this, { currentSet: newSet })
    }

    onWalletDisableHandler = (user) => {
        if (isNotNull(user)) {
            onSetNewState(this, { isDisable: false })
        }
    }

    onWalletSyncHandler = () => {
        const { dispatch } = this.props
        onSetNewState(this, { binanceData: null, binanceFutureData: null, totalBalance: null })
        dispatch({ type: walletActionTypes.GET_ALL_SUCCESS })
        dispatch({ type: walletActionTypes.GET_ALL_FUTURE_SUCCESS })
        dispatch(walletActions.getWallets())
        dispatch(walletActions.getFutureWallets())
        dispatch(walletActions.getBinance())
        dispatch(orderActions.getOrderPositions())
    }

    onWalletHandler = (isMobile) => {
        onSetNewState(this, function (prev) {
            if (prev.walletShow) {
                jQuery(".main-content").addClass('show')
                jQuery("#sidebar-opt-prof").addClass('show-wallets')
                jQuery('.wallets').addClass('hide')

                if (isNotNull(isMobile) && isMobile === true) {
                    jQuery('body').addClass('wallet-open')
                    jQuery(".sidebar-size").removeClass("show")
                    jQuery("#sidebar-opt").removeClass("show")
                    jQuery("#sidebar-opt-prof").removeClass("show")
                    jQuery(".fsp").removeClass("show")
                    jQuery(".to-hide-items").removeClass("show")
                    jQuery(".tooltip").removeClass("showToolTip")
                }
            } else {
                jQuery(".main-content").removeClass('show')
                jQuery("#sidebar-opt-prof").removeClass('show-wallets')
                jQuery('.wallets').removeClass('hide')

                if (isNotNull(isMobile) && isMobile === true)
                    jQuery('body').removeClass('wallet-open')
            }

            return { walletShow: !prev.walletShow }
        })
    }

    onQuickTradeChangeHandler = (source, target) => {
        const { cryptoWallet } = this.props
        // const wallet = this.state.feed === true ? cryptoWallet : futureWallet
        const wallet = cryptoWallet
        if (isNotNull(wallet) && isNotNull(wallet.items) && isNotNull(wallet.items.data)) {
            const findInfo = _.find(wallet.items.data, f => f.name === target)
            if (isNotNull(findInfo)) {
                const name = findInfo.name
                if (name === "BTC")
                    onSetNewState(this, { quickTradeLastPrice: _.round(parseFloat(findInfo.balance.free), 8) })
                if (name === "USDT")
                    onSetNewState(this, { quickTradeLastPrice: _.round(parseFloat(findInfo.balance.free), 2).toFixed(2) })
            }
        }

        if (source === "BTC" && target === "USDT")
            onSetNewState(this, {
                calculation: "0",
                sourceCoin: "USDT",
                targetCoin: "BTC",
                orderType: "MARKET_BUY"
            })
        if (source === "USDT" && target === "BTC")
            onSetNewState(this, {
                calculation: "0.00",
                sourceCoin: "BTC",
                targetCoin: "USDT",
                orderType: "MARKET_SELL"
            })
    }

    onShowQuickTradeHandler = () => {
        const { cryptoWallet } = this.props
        // const wallet = this.state.feed === true ? cryptoWallet : futureWallet
        const wallet = cryptoWallet
        if (isNotNull(wallet) && isNotNull(wallet.items)) {
            const { data, msg } = wallet.items
            if (isNotNull(data)) {
                const findInfo = _.find(data, f => f.name === this.state.sourceCoin)
                if (isNotNull(findInfo)) {
                    const name = findInfo.name

                    if (name === "BTC")
                        onSetNewState(this, { quickTradeLastPrice: _.round(parseFloat(findInfo.balance.free), 8) })
                    if (name === "USDT")
                        onSetNewState(this, { quickTradeLastPrice: _.round(parseFloat(findInfo.balance.free), 2).toFixed(2) })

                    onSetNewState(this, { quickTradeShow: true })

                    if (this.state.sourceCoin === "BTC" && this.state.targetCoin === "USDT")
                        onSetNewState(this, {
                            calculation: `0.00`,
                            orderType: "MARKET_SELL"
                        })
                    if (this.state.sourceCoin === "USDT" && this.state.targetCoin === "BTC")
                        onSetNewState(this, {
                            calculation: `0`,
                            orderType: "MARKET_BUY"
                        })

                }
            } else {
                let error = `<p class="error-title">Error</p> ${msg}`;

                iziToast.error({
                    class: 'custom-errors',
                    position: 'topRight',
                    message: error,
                    messageColor: '#6E7885',
                    timeout: 3000,
                    backgroundColor: 'linear-gradient(90deg, rgba(19, 27, 38, 1) 88%, rgba(185, 63, 95, 0.5) 100%)',
                    iconUrl: '/images/close-icon.png',
                    displayMode: 1
                })
            }
        }
    }

    onCalculationHandler = (source, target, price) => {
        if (source === "BTC" && target === "USDT") {
            onSetNewState(this, {
                calculation: `${price <= 0 ? "0.00" : price}`
            })
        }
        if (source === "USDT" && target === "BTC") {
            onSetNewState(this, {
                calculation: `${price}`
            })
        }
    }

    initWallet = () => {
        const { channel } = this.state
        this.wsBinance24hrTicker = new W3CWebSocket(channel)

        this.wsBinance24hrTicker.onopen = () => { }

        this.wsBinance24hrTicker.onclose = () => {
            if (this.mounted)
                setTimeout(() => {
                    this.wsBinance24hrTicker = new W3CWebSocket(channel)
                }, 3000)
        }
        
        this.wsBinance24hrTicker.onerror = () => {
            if (this.mounted)
                setTimeout(() => {
                    this.wsBinance24hrTicker = new W3CWebSocket(channel)
                }, 3000)
        }

        this.wsBinance24hrTicker.onmessage = (response) => {
            let { data } = response
            const { cryptoWallet, dispatch } = this.props
            if (isNotNull(data)) {
                data = JSON.parse(data)

                if (isNotNull(cryptoWallet) &&
                    isNotNull(cryptoWallet.items) &&
                    isNotNull(cryptoWallet.items.data)) {

                    // extract BTCUSDT info from websockets response data
                    const btcUsdtInfo = _.find(data, c => c.s.toUpperCase() === 'BTCUSDT')
                    if (isNotNull(btcUsdtInfo)) {
                        // BTCUSDT has information
                        // set bid and ask prices
                        onSetNewState(this, {
                            quickTradeBidPrice: _.round(parseFloat(btcUsdtInfo.b), 2).toFixed(2),
                            quickTradeAskPrice: _.round(parseFloat(btcUsdtInfo.a), 2).toFixed(2)
                        })
                    }

                    // extract user's wallets
                    const wallets = [...cryptoWallet.items.data]
                    _.forEach(wallets, wallet => {
                        wallet.balance.free = parseFloat(wallet.balance.free)
                        if (parseFloat(wallet.balance.free) <= 0) {
                            // wallet has no balance
                            wallet.balance.usdt = price_checker(0, 0, wallet.name)
                        } else { // wallet balance is greater than 0
                            // set the full name of the coin
                            wallet.coinName = getCoinName(wallet.name)

                            // set default values for BTC and USDT
                            if (wallet.name === "BTC") {
                                if (isNotNull(btcUsdtInfo) && isNotNull(btcUsdtInfo.c))
                                    wallet.balance.usdt = price_checker(wallet.balance.free, (parseFloat(wallet.balance.free) * parseFloat(btcUsdtInfo.c)), wallet.name)
                            }
                            else if (wallet.name === "USDT")
                                wallet.balance.usdt = parseFloat(wallet.balance.free)
                            else {
                                let coinName = wallet.name

                                let btcSymbolInfo = _.find(data, t => t.s.toUpperCase() === `${coinName}BTC`)
                                if (isNull(btcSymbolInfo)) {
                                    const binanceData = _.find(this.state.binanceData, q => q.symbol === `${coinName}BTC`)
                                    if (isNotNull(binanceData) && binanceData.lastPrice > 0)
                                        btcSymbolInfo = { c: binanceData.lastPrice }
                                }

                                let usdtSymbolInfo = _.find(data, t => t.s.toUpperCase() === `${coinName}USDT`)
                                if (isNull(usdtSymbolInfo)) {
                                    if (isNotNull(btcSymbolInfo)) {
                                        if (isNotNull(btcSymbolInfo.c) && isNotNull(btcUsdtInfo) && isNotNull(btcUsdtInfo.c)) {
                                            // product of symbol's lastPrice and BTCUSDT lastPrice
                                            usdtSymbolInfo = { c: parseFloat(btcSymbolInfo.c) * parseFloat(btcUsdtInfo.c) }
                                        }
                                    } else {
                                        const binanceData = _.find(this.state.binanceData, q => q.symbol === `${coinName}USDT`)
                                        if (isNotNull(binanceData)) {
                                            usdtSymbolInfo = { c: binanceData.lastPrice }
                                        }
                                    }
                                }

                                // set calculated usdt value
                                if (isNotNull(usdtSymbolInfo)) {
                                    if (isNotNull(usdtSymbolInfo.c))
                                        wallet.balance.usdt = price_checker(wallet.balance.free, (parseFloat(wallet.balance.free) * parseFloat(usdtSymbolInfo.c)), coinName)
                                    else
                                        wallet.balance.usdt = price_checker(wallet.balance.free, 0, coinName)
                                } else
                                    wallet.balance.usdt = price_checker(wallet.balance.free, 0, coinName)
                            }
                        }
                    })
                    let crypto_wallet = {
                        data: wallets
                    }
                    dispatch({ type: walletActionTypes.WALLET_UPDATE, crypto_wallet })
                }
            }
        }
    }

    render() { 
        return (
            <WalletSidebarView 
                self={this}
                syncWallet={this.onWalletSyncHandler}
                handleWallet={this.onWalletHandler}
                handleCalculation={this.onCalculationHandler}
                handleQuickTradeChange={this.onQuickTradeChangeHandler}
                showQuickTrade={this.onShowQuickTradeHandler}
                loadNewSet={this.onScrollLoadNewWalletDataHandler}
                state={this.state}
                {...this.props}
            />
        )
    }
}

function mapStateToProps(state) {
    const { cryptoWallet, futureWallet, getBinance, tradings, orderPositions } = state
    return {
        cryptoWallet,
        futureWallet,
        getBinance,
        tradings,
        orderPositions
    }
}

const connectedWalletSidebarContainer = connect(mapStateToProps)(WalletSidebarContainer)
export { connectedWalletSidebarContainer as WalletSidebar }