import React from 'react'
import { SignUpStepsSectionStyle } from './styles'

export const SignUpStepsSection = () => {
    return(
        <SignUpStepsSectionStyle>
            <div className='container'>
                <h2 className='section-title'>SIGN UP NOW AND START TRADING LIKE A PRO!</h2>

                <div className='steps-container'>
                    <div className='step-col'>
                        <img className='step-img' src='/images/trial-offer/sign-up-img.png'/>
                        <p className='step-title'>Start 21 Day PRO Trial</p>
                        <small>It's just $21, less than you spend on pizza</small>
                    </div>
                    <div className='step-col'>
                        <img className='step-img' src='/images/trial-offer/connect-binance-img.png'/>
                        <p className='step-title'>Connect Your Account</p>
                        <small>Connect your Binance.com account. Just generate an API key and connect your account to our platform. Skip this step if you don’t have a Binance.com account yet.</small>
                    </div>
                    <div className='step-col'>
                        <img className='step-img' src='/images/trial-offer/blueprint-5x-img.png'/>
                        <p className='step-title'>Learn the 5x Blueprint and Start Trading</p>
                        <small>Instant access the PRO academy and all PRO trading signals and features</small>
                    </div>
                </div>
            </div>
        </SignUpStepsSectionStyle>
    )
}