import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGem } from '@fortawesome/free-solid-svg-icons'
import { PrimaryButton } from '../../styles'
import { isNull, isNotNull, onSetNewState } from '../../utils'

export class GenericCheckoutForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            subscriptionId: null,
            subscriptionRefKey: null,
            subscriptionPrice: null,
            userId: null,
            customerId: null,
            leadId: null,
            isLead: null,
            userName: null,
            firstName: null,
            lastName: null
        }

        this.mounted = false
        this.baseState = this.state
    }

    componentDidMount() {
        this.mounted = true
        const { userId, subscriptionId } = this.state
        const { userInfo, subscriptionInfo } = this.props
        
        if (isNull(userId) && isNotNull(userInfo)) {
                onSetNewState(this, {
                    userId: userInfo.id,
                    customerId: userInfo.customerId,
                    leadId: userInfo.leadId,
                    isLead: userInfo.isLead,
                    userName: userInfo.userName,
                    firstName: userInfo.firstName,
                    lastName: userInfo.lastName
                })
        }

        if (isNull(subscriptionId) && isNotNull(subscriptionInfo)) {
                onSetNewState(this, {
                    subscriptionId: subscriptionInfo.id,
                    subscriptionRefKey: subscriptionInfo.refKey,
                    subscriptionPrice: subscriptionInfo.price
                })
            }
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {

        }
    }

    componentWillUnmount() {
        this.mounted = false
        this.setState(this.baseState)
    }

    handlePayment = (e) => {
        e.preventDefault()
                
        const { ...data } = this.state
        if (isNotNull(data.userId)) {
            // create new payment session
            const newPaymentSession = {
                reset: true,
                products: [{
                    path: data.subscriptionRefKey,
                    quantity: 1
                }],
                paymentContact: {
                    email: data.userName,
                    firstName: data.firstName,
                    lastName: data.lastName
                },
                tags: {
                    userId: data.userId,
                    customerId: data.customerId,
                    leadId: data.leadId,
                    isLead: data.isLead,
                    sPlanId: data.subscriptionId
                },
                checkout: true
            }
            window.fastspring.builder.push(newPaymentSession)
        }
    }

    render() {
        return (
            <PrimaryButton 
                customBg="gradient"
                type="button"
                onClick={(e) => this.handlePayment(e)}>
                    <FontAwesomeIcon icon={faGem} />
                    <span>Upgrade to PRO NOW</span>
            </PrimaryButton>
        )
    }
}