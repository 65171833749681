import React from 'react'
import { ProTrialDaysSectionStyle } from './styles'

export const ProTrialDaysSection = () => {
    return (
        <ProTrialDaysSectionStyle>
            <div className='container'>
                <div className='image-text-row'>
                    <div className='text-col'>
                        <h2 className='section-title'>$21 - 21 DAY PRO TRIAL PLUS THE PRO TRADER 5X BLUEPRINT</h2>
                        <p>For just $1 per day get access to everything you need to become a profitable trader</p>
                    </div>
                    <div className='image-col'>
                        <img src='/images/trial-offer/day-pro-trial-blueprint.png' />
                    </div>                    
                </div>
                <p className='text'>If you have aspirations to become the next millionaire crypto trader then the <strong>FREE</strong> account isn’t enough. You’ll need more <strong>POWER</strong> from your software, and to download some <strong>KNOWLEDGE</strong> directly from our elite trading team.</p>
            </div>
        </ProTrialDaysSectionStyle>
    )
}