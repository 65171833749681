import React from 'react'
import { connect } from 'react-redux'
import { Card, Table } from 'react-bootstrap'
import Moment from 'react-moment'
import { orderActions } from '../../../../redux/actions'
import { isNotNull, setCoinLogo, setDefaultImage, onSetNewState, isNull } from '../../../../utils'

class OrderResultDetails extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            reviewItem: null,
            details: null
        }
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true

        const { data } = this.props
        if (isNotNull(data)) {
            onSetNewState(this, {
                reviewItem: data
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.mounted) {
            const { dispatch, orderResult } = this.props
            const { isLoading, reviewItem, details } = this.state

            if (isNotNull(reviewItem) && isNull(details) && !isLoading) {
                onSetNewState(this, {
                    isLoading: true
                }, () => {
                    dispatch(orderActions.getOrderResult(reviewItem.actorId))
                })
            }
            
            if (isNotNull(orderResult) && !orderResult.loading) {
                const { order_result } = orderResult
                if (isNotNull(order_result) && prevState.details !== order_result) {
                    onSetNewState(this, {                         
                        isLoading: false,
                        details: order_result
                    })
                }
            }
        }
    }

    componentWillUnmount() {
        onSetNewState(this, {
            isLoading: false,
            reviewItem: null,
            details: null
        })
        this.mounted = false
    }

    renderSuccessfulOrderResult = () => {
        const { reviewItem, details } = this.state
        const { data } = details
        let coinLogo = data.coin.substring(data.coin.indexOf('/') + 1)

        return (
            <>
                <h2 className="trigger-title success">{reviewItem.message}</h2>
                <p>Your {data.type} order: {data.group_id} on {data.exchange === 'binance' ? 'Binance' : 'Binance Futures'} has been executed successfully.</p>

                <Card style={{ marginTop: '25px' }}>
                    <Card.Body>
                        <h3>Order Details</h3>
                        <Table borderless={true}>
                            <tbody>
                                <tr>
                                    <td className="align-right">Order ID</td>
                                    <td>{data.group_id}</td>
                                </tr>
                                <tr>
                                    <td className="align-right">Volume</td>
                                    <td>{data.volume}</td>
                                </tr>
                                <tr>
                                    <td className="align-right">Coin</td>
                                    <td>
                                        <div className="signal-info">
                                            <i className="coin-icon">
                                                <img 
                                                    src={setCoinLogo(coinLogo)} 
                                                    alt="coin-logo"
                                                    onError={setDefaultImage} />
                                            </i>
                                            {data.coin}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-right">Price</td>
                                    <td>{data.close_price}</td>
                                </tr>
                                <tr>
                                    <td className="align-right">Time</td>
                                    <td>
                                        <Moment format="MMM D, YYYY HH:mm" >
                                            {data.closedAt}
                                        </Moment> UTC
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </>
        )
    }

    renderFailedOrderResult = () => {
        const { reviewItem, details } = this.state
        const { data, err } = details
        let coinLogo = data.coin.substring(data.coin.indexOf('/') + 1)

        return (
            <>                
                <h2 className="trigger-title failed">{reviewItem.message}</h2>
                <p>Your {data.type} order: {data.group_id} on {data.exchange === 'binance' ? 'Binance' : 'Binance Futures'} has been failed with the error: <span style={{ color: '#dd4449', fontWeight: '600' }}>{err.msg}</span>.</p>
                <p><b style={{ color: '#993956', fontWeight: '600' }}>TAKE ACTION</b>: Please review this trade and close this manually via your exchange or the advanced trading terminal.</p>

                <Card style={{ marginTop: '25px' }}>
                    <Card.Body>
                        <h3>Order Details</h3>
                        <Table borderless={true}>
                            <tbody>
                                <tr>
                                    <td className="align-right">Order ID</td>
                                    <td>{data.group_id}</td>
                                </tr>
                                <tr>
                                    <td className="align-right">Volume</td>
                                    <td>{data.volume}</td>
                                </tr>
                                <tr>
                                    <td className="align-right">Coin</td>
                                    <td>
                                        <div className="signal-info">
                                            <i className="coin-icon">
                                                <img 
                                                    src={setCoinLogo(coinLogo)} 
                                                    alt="coin-logo"
                                                    onError={setDefaultImage} />
                                            </i>
                                            {data.coin}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </>
        )
    }

    renderLoader = () => {
        return (
            <>
                <div style={{ width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="pf-spinner xs" style={{ marginTop: '-48px', marginRight: '45px' }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            </>
        )
    }

    render() {
        const { reviewItem, isLoading, details } = this.state

        return (
            <>
                { isLoading && 
                    <>
                        {this.renderLoader()}
                    </>
                }

                { !isLoading && isNotNull(reviewItem) && isNotNull(details) && 
                    <>
                        { details.success && 
                            <>                                
                                {this.renderSuccessfulOrderResult()}
                            </>
                        }
                        
                        { !details.success && 
                            <>                                
                                {this.renderFailedOrderResult()}
                            </>
                        }
                    </>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    const { orderResult } = state
    return {
        orderResult
    }
}

const connectedOrderResultDetails = connect(mapStateToProps)(OrderResultDetails)
export { connectedOrderResultDetails as OrderResultDetails }