import { forgotPassActionTypes } from '../CONSTANTS'
import { forgotPassService } from '../../services'

function forgotPassword(data) {
    return dispatch => {
        dispatch(request())

        forgotPassService.forgotPassword(data)
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failure(error)))
    }

    function request() { return { type: forgotPassActionTypes.FORGOT_PASSWORD_REQUEST }}
    function success(response) { return { type: forgotPassActionTypes.FORGOT_PASSWORD_SUCCESS, response }}
    function failure(error) { return { type: forgotPassActionTypes.FORGOT_PASSWORD_FAILURE, error }}
}

export const forgotPassAction = {
    forgotPassword,
}
