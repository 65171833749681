import { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { RouterConfig } from './navigation/RouterConfig'
import { history } from './utils/history'
import store from './redux/store'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/scss/common.scss'
import './assets/scss/lights.scss'
import 'izitoast/dist/css/iziToast.min.css'
import './assets/scss/dark.scss'
import NetworkDetector from './hoc/NetworkDetector'
//import LogRocket from 'logrocket'

//LogRocket.init('mt8kkr/profitfarmers')

function App() {

    useEffect(() => {
        /**
         * YOUR  CONFIGURATION GOES HERE
        */
    }, [])

    return (
        <Provider store={store}>
            <Router 
                history={history}>
                <RouterConfig />
            </Router>
        </Provider>
    );
}

export default NetworkDetector(App)
