import styled from 'styled-components'

export const InlineFilter = styled.ul`
    margin: 0 0 15px;
    padding-left: 0;
    list-style-type: none;

    &.stat {
        .form-group {
            width: 100%;
        }
    }

    .form-group {
        margin-bottom: 0;
        border-radius: 9px;
    }

    li {
        margin-right: 20px;

        label {
            margin-right: 10px;
            margin-bottom: 0;
            color: #6a7b8f;

            &.statistics-strat {
                color: #778698;
                margin-right: 15px;
            }
        }

        &:last-child {
            margin-right: 0
        }

        select {
            font-size: 16px;
            color: rgb(206, 208, 221) !important;
            background-color: #223346 !important;
            border: 1px solid #26384c !important;
            border-radius: 6px;

            &:focus {                
                box-shadow: none;
            }
        }

        .multi-selection {
            .dropdown {
                .btn {
                    font-size: 16px;
                    color: rgb(206, 208, 221) !important;
                    background-color: #223346 !important;
                    border: 1px solid #26384c !important;
                    border-radius: 6px;
                }

                .dropdown-menu {
                    background-color: #223346;
                    min-width: 180px;

                    .dropdown-menuitem {
                        .custom-control-label {
                            display: block;
                            padding: 6px 20px 6px 25px;
                            cursor: pointer;
                            color: #fff;

                            &::before {
                                background-color: #1a2633;
                                border: #0e151d solid 1px;
                            }

                            &::before, 
                            &::after {
                                left: -3px;
                                top: 9px;
                            }
                        }

                        .custom-checkbox {
                            cursor: pointer;

                            .custom-control-input {
                                &:checked~.custom-control-label::before {
                                    border-color: #23d280;
                                    background-color: #1ea164;
                                    box-shadow: 0 0 0 2px rgb(35 210 128 / 40%);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 768px) {
        margin-bottom: 0;
    }

    @media screen and (max-width: 766px) and (min-width: 360px) {
        li {
            label {
                display: none;
            }

            select {
                font-size: 14px !important;
            }
        }
    }
`