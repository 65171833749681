import glamorous from 'glamorous'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const TradesInActionSectionStyle = glamorous.section({
    position: 'relative',
    display: 'block',
    width: '100%',
    overflow: 'hidden',
    backgroundColor: '#0B1219',
    padding: '60px 0',
    
    '& .container': {
        maxWidth: '100%',
        margin: '0 auto',
        textAlign: 'center', 
        color: '#fff',

        '& .section-title': {
            color: '#fff',
            fontSize: '22px',
            fontWeight: 800,
            maxWidth: '100%',
            margin: '0 auto 15px',
            textTransform: 'uppercase'
        },

        '& .snapvids': {
            display: 'block',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '0 auto 20px',
            listStyleType: 'none',
            padding: 0,

            '& li': {
                padding: '10px',
                width: '100%',
                marginBottom: '15px',

                '& > div': {
                    marginBottom: '15px'
                },

                '& > p': {
                    fontSize: '14px',
                    marginBottom: 0
                }
            }
        },

        '& .section-description': {
            fontSize: '15px'
        }
    },

    [mediaQueries.mediumDevices]: {
        '& .container': {
            '& .section-title': {
                fontSize: '26px',
                maxWidth: '620px'
            },

            '& .snapvids': {
                display: 'flex',

                '& li': {
                    width: '246px',

                    '& > div': {
                        height: '236px !important'
                    }
                }
            }
        }
    },

    [mediaQueries.largeDevices]: {
        '& .container': {
            maxWidth: '980px',

            '& .section-title': {
                fontSize: '30px',
                maxWidth: '720px'
            },

            '& .snapvids': {
                zIndex: 1,

                '& li': {
                    width: '315px',
                    position: 'relative',

                    '&:first-child': {
                        '&:before': {
                            content: ' ',
                            position: 'absolute',
                            bottom: '-80px',
                            left: '-150px',
                            width: '350px',
                            height: '270px',
                            backgroundImage: 'url(images/trial-offer/ellipse-gray.svg)',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            zIndex: 0
                        }
                    },

                    '&:last-child': {
                        '&:after': {
                            content: ' ',
                            position: 'absolute',
                            top: '-100px',
                            right: '-150px',
                            width: '350px',
                            height: '270px',
                            backgroundImage: 'url(images/trial-offer/ellipse-green.svg)',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            zIndex: 0
                        }
                    },

                    '& > div': {
                        height: '305px !important'
                    }
                }
            },

            '& .section-description': {
                fontSize: '20px',
                maxWidth: '760px',
                margin: '0 auto'
            }
        }
    },

    [mediaQueries.extraLargeDevices]: {
        padding: '80px 0',
        
        '& .container': {
            maxWidth: '1200px',

            '& .section-title': {
                fontSize: '40px',
                maxWidth: '1000px',
                margin: '0 auto 30px'
            },

            '& .snapvids': {
                '& li': {
                    width: '373px',

                    '& > div': {
                        height: '360px !important'
                    },

                    '& > p': {
                        fontSize: '16px'
                    }
                }
            },

            '& .section-description': {
                maxWidth: '800px'
            }
        }
    }

    /*[mediaQueries.superExtraLargeDevices]: {
        '& .container': {
            '& .snapvids': {
                '&:before': {
                    content: ' ',
                    position: 'absolute',
                    bottom: '100px',
                    left: '250px',
                    width: '300px',
                    height: '220px',
                    backgroundImage: 'url(images/trial-offer/ellipse-gray.svg)',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 0
                },
        
                '&:after': {
                    content: ' ',
                    position: 'absolute',
                    top: '150px',
                    right: '250px',
                    width: '300px',
                    height: '220px',
                    backgroundImage: 'url(images/trial-offer/ellipse-green.svg)',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 0
                }
            }
        }
    }*/
})