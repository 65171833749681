import ReactPlayer from 'react-player/lazy'
import { EpicOpportunitiesSectionStyle } from './styles'

export const EpicOpportunitiesSection = () => {
    return (
        <EpicOpportunitiesSectionStyle>
            <div className='container'>
                <h2 className='section-title'>No Matter the Market Cycle - Let ProfitFarmers PRO Find You Epic Opportunities</h2>

                <ul className='snapvids'>
                    <li>
                        <ReactPlayer 
                            url={'https://vimeo.com/688281053/080098008c'}
                            controls={true} 
                            width='100%' 
                            volume={0} />

                        <p className='video-title'>ALPINEUSDT - Result +150% in 5 days</p>
                    </li>
                    <li>
                        <ReactPlayer 
                            url={'https://vimeo.com/693919602/5d8b61aff2'}
                            controls={true} 
                            width='100%' />

                        <p className='video-title'>GMTUSDT - Result +134% in 4 days</p>
                    </li>
                    <li>
                        <ReactPlayer 
                            url={'https://vimeo.com/693823755/ab5581ec26'}
                            controls={true} 
                            width='100%' />

                        <p className='video-title'>GMTBTC - Result +135% in 4 days</p>
                    </li>
                </ul>
                
                <p className='section-description'>Bull or Bear it doesn't matter! These trades happened in March 2022 when the market was DEAD for the average trader. ProfitFarmers is a totally different TECHNO-BEAST.</p>
            </div>
        </EpicOpportunitiesSectionStyle>
    )
}