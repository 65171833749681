export const ROOT = "/"

export const app = {
    title: "ProfitFarmers",
    logo: "/images/pf-logo.png"
}

export const routes = {
    authentication: {
        login: 'https://www.profitfarmers.com/login', //'/account/login',
        oldLoginUrl: '/account/login',
        loginWithReturnUrl: '/account/login?returnUrl=',
        register: '/account/register',
        verifyEmail: '/account/verify',
        forgotPassword: '/account/forgot-password',
        forgotPasswordConfirmation: '/account/forgot-password-confirm',
        resetPassword: '/account/reset-password',
        autoLogin: '/account/auto-login',
        resetPasswordConfirmation: '/account/reset-password-confirm',
        getStarted: '/confirm-email', //`/account/get-started`,
        tradeAuto: `/trade-order`,
        changeEmail: `/account/change-email`
    },
    offers: {
        oneTime: '/trial-offer'
        // oneTime: '/one-time-offer'
    },
    pricing: {
        subscriptionPlans: '/subscription-plans',
    },
    payment: {
        page: '/payment',
        complete: '/payment/complete',
        cancel: '/payment/cancel'
    },
    account: {
        getStarted: '/account/setup'
    },
    home: '/home',
    signals: '/signals',
    dashboard: '/dashboard',
    notification: '/notifications',
    messages: {
        board: '/messages/board'
    },
    wallet: '/wallet/balance',
    statistic: '/trade/statistics',
    scanner: {
        priceActionScanner: '/scanners/price-action',
        rsiScanner: '/scanners/relative-strength-index'
    },
    orderHistory: '/orders',
    setting: {
        profile: '/account/profile',
        subscription: '/account/subscription',
        autoTrade: '/account/auto-trade',
        notificationSettings: '/account/notification'
    },
    moonbag: '/moonbag',
    subscriptions: {
        pro: '/subscription-plans/pro/subscribe'
    }
}

export const pages = {
    authentication: {
        login: "LOGIN",
        signup: "SIGNUP",
        fogotPassword: "FORGOTPASSWORD",
        changeEmail: "CHANGEEMAIL"
    },
    dashboard: "HOME",
    signals: "SIGNALS",
    notification: "NOTIFICATION",
    payment: "PAYMENT",
    wallet: "WALLET",
    statistic: "STATISTIC",
    orderHistory: "ORDERHISTORY",
    scanner: {
        priceActionScanner: "PRICEACTIONSCANNER",
        rsiScanner: "RELATIVESTREGNTHINDEXSCANNER"
    },
    setting: {
        profile: "PROFILE",
        subscription: "SUBSCRIPTION",
        autoTrade: 'AUTOTRADE',
        notificationSettings: 'NOTIFICATIONSETTINGS'
    },
    moonbag: 'MOONBAGTHANKYOUPAGE'
}
