import React, { Component } from 'react'
import { connect } from 'react-redux'
import jQuery from 'jquery'
import { Container, Row, Col, SafeAnchor, Button } from 'react-bootstrap'
import { authActionTypes } from '../../redux/CONSTANTS'
import { authActions, userActions } from '../../redux/actions'
import { isNotNull, onSetNewState, addPageViewEvent, seo } from '../../utils'
import './scss/moonbag.scss'

class MoonbagContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAuthenticating: true,
            isAuthenticated: false
        }
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true

        seo({
            title: "Moonbag",
            metaDescription: ""
        })
        addPageViewEvent()

        const { dispatch } = this.props
        dispatch({ type: authActionTypes.CLEAR_AUTH })
        const promise = new Promise((resolve) => {
            localStorage.clear()
            resolve(true)
        }).then((result) => {
            if (isNotNull(result) && result) {
                const params = (new URL(document.location)).searchParams
                let token = params.get("token")
                if (isNotNull(token)) {
                    //token = token.replace(/ /g, "+")
                    dispatch(authActions.autoLoginByToken(token))
                }
            }
        })

        jQuery("body").addClass("moonbag")
    }

    componentDidUpdate(prevProps) {
        const { isAuthenticating } = this.state
        const { dispatch, authentication } = this.props
        if (prevProps.authentication !== this.props.authentication) {
            const { autoLoggedIn } = authentication
            if (isAuthenticating) {
                if (isNotNull(autoLoggedIn) && autoLoggedIn) {
                    onSetNewState(this, { 
                        isAuthenticating: false,
                        isAuthenticated: true
                    }, () => {
                        dispatch(userActions.getMe())
                    })
                }
            }
        }
    }

    componentWillUnmount() {
        jQuery("body").removeClass("moonbag")
        this.mounted = false
    }

    render() {
        const { isAuthenticating, isAuthenticated } = this.state

        return (
            <main className="main-moonbag">
                { isAuthenticating && 
                    <>loading...</>
                } 

                { !isAuthenticating && isAuthenticated && 
                    <Container>
                        <Row>
                            <Col>
                                <div className="title-wrapper">
                                    <h1><span className="gold">Congratulations!</span><br />Your <span className="gold">Moon Bag Kit</span> will arrive in your <span className="gold">inbox</span>.</h1>
                                </div>
                                <div className="video-wrapper">
                                    <h3>Here’s a welcome video from the Founder!</h3>
                                    <Row className="video-row">
                                        <Col md={9} lg={7}>
                                            <iframe
                                                height="100%"
                                                width="100%"
                                                title="Moonbag welcome kit"
                                                src="https://player.vimeo.com/video/519841037?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1#t="
                                                frameBorder="0"
                                                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture;"
                                                allowFullScreen />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="telegram-wrapper">
                                    <h2>Join our telegram channel to start receiving our trading signals immediately.</h2>
                                    <div className="btn-wrapper">
                                        <SafeAnchor as={Button} href="https://t.me/freepfsignals" className="btn-telegram" target="_blank">Get Signals Now</SafeAnchor>
                                    </div>
                                </div>

                                <div className="steps-wrapper">
                                    <h2>Not sure what to do next?</h2>
                                    <div className="steps-definition">
                                        <ol>
                                            <li>Search your inbox for an email from ProfitFarmers</li>
                                            <li>Add our email address to your list of ‘Safe Senders’ - Don’t get left behind!</li>
                                            <li>Download the Moon Bag Guide From your email and get started farmin’ 🤠</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="cta-wrapper">
                                    <h2><SafeAnchor href="/dashboard" disabled={!isAuthenticated}>Go to ProfitFarmers Web App</SafeAnchor></h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                }
            </main>
        )
    }
}


function mapStateToProps(state) {
    let { authentication } = state
    return {
        authentication
    }
}

const connectedMoonbag = connect(mapStateToProps)(MoonbagContainer)
export { connectedMoonbag as MoonbagContainer }
