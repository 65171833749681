import React from 'react'
import { connect } from 'react-redux'
import * as signalR from '@microsoft/signalr'
import _ from 'lodash'
import jQuery from 'jquery'
import queryString from 'query-string'
import { signalActions, signalDetailsActions, tradeOrderActions, checkListActions, sidebarActions } from '../../redux/actions'
import { signalActionTypes } from '../../redux/CONSTANTS'
import { feedUrl, signalFeedToken } from '../../services/CONSTANTS'
import { stateFilter, signalCloseState } from '../../utils/CONSTANTS'
import { onSetNewState, isNotNull, Dimension, isNull, addPageViewEvent, pushEvent, seo } from '../../utils'
import { HomeView } from './HomeView'

class HomeContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            signalStateFilter: {
                isLoading: false,
                options: [],
                selectedValue: null
            },
            signalExchangeFilter: {
                options: [
                    { key: 0, name: 'All' },
                    { key: 1, name: 'Spot' },
                    { key: 2, name: 'Futures' }
                ],
                selectedValue: 0
            },
            tradeResultFilter: {
                options: [
                    { key: 'WINNERS', name: 'Winners' },
                    { key: 'LOSERS', name: 'Losers' },
                    { key: 'EARLY_CLOSED', name: 'Closed Early' }
                ],
                selectedValueTexts: [ 'Winners', 'Losers' ],
                selectedValues: [ 'WINNERS', 'LOSERS' ]
            },
            page: 1,
            currentSet: 12,
            totalSignalSize: 0,
            hasNewSignalSetLoaded: false,
            followedSignals: {},
            hasSignalSelection: false,
            selectedSignal: null,
            selectedFeed: '',
            enableTradeForm: false,
            isSendingOrder: false,
            orderDetails: null,
            tradeSummaryOnly: null,
            showTradeErrorMessage: false,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            showSubscribeNow: false,
            willTradeFromMobileApp: false,
            viewNotificationDetails: false,
            hasNoNewSignals: false,
            signalsInitialized: false
        }
        this.baseState = this.state

        this.signalFeedHubConnection = null
        this.spotTrackerHubConnection = null
        this.futuresTrackerHubConnection = null
        this.signalUpdatesHubConnection = null
        this.tradeOrderSucceeded = null
        this.errorValue = null
        this.mounted = false

        window.onscroll = _.debounce(() => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.scrollingElement.scrollHeight - 1)
                this.onLoadSignalSet()
        }, 100)
    }

    componentDidMount() {
        this.mounted = true
        
        seo({
            title: "Signals",
            metaDescription: ""
        })
        
        addPageViewEvent()

        window.addEventListener('resize', () => Dimension.updateDimensions(this))

        const { dispatch } = this.props
        const { willTradeFromMobileApp } = this.state

        dispatch(tradeOrderActions.clear())
        dispatch(signalActions.getSignalStates())

        this.handleViewSignalNotification()

        // showing trade form from mobile app
        let pageParams = (new URL(document.location)).searchParams
        if (isNotNull(pageParams)) {
            let tappedSignalId = pageParams.get("trade-signal-id")
            if (isNotNull(tappedSignalId) && !willTradeFromMobileApp) {
                onSetNewState(this, {
                    willTradeFromMobileApp: true
                }, () => {
                    dispatch(signalDetailsActions.getSignalDetails(tappedSignalId))
                })
            }
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        const { followedSignals } = this.props.followedSignals
        const { dispatch, authentication, signalDetails, tradeOrder, tradeOrderLeverage, signalsNew } = this.props
        const { tradeSummaryOnly, signalsInitialized, signalStateFilter, isSendingOrder, enableTradeForm, selectedSignal, selectedFeed, willTradeFromMobileApp, viewNotificationDetails } = this.state
        const { user } = authentication

        if (isNotNull(user)) {
            if (this.signalFeedHubConnection === null) {
                // initialize signal feed hub connection
                await this.initSignalFeedHubConnection()
            }

            if (this.futuresTrackerHubConnection === null) {
                // initialize futures signal tracker hub connection
                await this.initFuturesTrackerConnection()
            }

            if (this.spotTrackerHubConnection === null) {
                // initialize spot signal tracker hub connection
                await this.initSpotTrackerConnection()
            }
        }

        if (prevState.selectedFeed !== selectedFeed) {
            console.log('selection: ', selectedFeed)
            onSetNewState(this, {
                selectedFeed
            })
        }

        // reinitialzie signal state filter
        if (prevProps.signalStates !== this.props.signalStates && 
            isNull(signalStateFilter.selectedValue) && 
            isNotNull(user)) {
            const { states } = this.props.signalStates
            if (user.isPaid) {
                onSetNewState(this, {
                    signalStateFilter: {
                        isLoading: false,
                        options: states,
                        selectedValue: 1
                    }
                })
            } else {
                let newStates = _.filter(states, (value) => {
                    return value.key !== 3 && value.key !== 0
                })

                onSetNewState(this, {
                    signalStateFilter: {
                        isLoading: false,
                        options: newStates,
                        selectedValue: 2
                    }
                })
            }
        }

        if (signalStateFilter.selectedValue != null && 
            !signalsInitialized) {
                onSetNewState(this, {
                    signalsInitialized: true
                }, () => {
                    dispatch(signalActions.clearSignals())
                    dispatch(signalActions.getSignals(
                        this.state.signalStateFilter.selectedValue,
                        this.state.signalExchangeFilter.selectedValue,
                        this.state.tradeResultFilter.selectedValues,
                        1))
                    dispatch(signalActions.getFollowedSignals())
                })
            }

        // setting followed signals
        if (isNotNull(followedSignals) && prevState.followedSignals !== followedSignals)
            onSetNewState(this, {
                followedSignals: followedSignals
            })

        if (prevState.hasSignalSelection !== this.state.hasSignalSelection)
            onSetNewState(this, {
                hasSignalSelection: this.state.hasSignalSelection
            })

        if (prevProps.viewNotificationItem === false && this.props.viewNotificationItem === true) {
            this.handleViewSignalNotification()
        }

        if (willTradeFromMobileApp) {
            if (isNotNull(signalDetails) && signalDetails !== _.isEmpty({})) {
                const { item } = signalDetails
                if (isNull(selectedSignal) && isNotNull(item)) {
                    onSetNewState(this, {
                        selectedSignal: item,
                        enableTradeForm: true
                    })
                }
            }
        } else if (viewNotificationDetails && isNull(selectedSignal) && isNotNull(signalDetails)) {
            const { loading, item } = signalDetails
            if (!loading && isNotNull(item)) {
                onSetNewState(this, {
                    selectedSignal: item,
                    hasSignalSelection: true
                })
            }
        } else {
            if (prevState.selectedSignal !== this.state.selectedSignal) {
                if (isNotNull(this.state.selectedSignal) && isNotNull(signalDetails)) {
                    const { item } = signalDetails
                    if (isNotNull(item) && item.id !== this.state.selectedSignal.id) {
                        dispatch(signalDetailsActions.getSignalDetails(this.state.selectedSignal.id))
                    } else {
                        dispatch(signalDetailsActions.getSignalDetails(this.state.selectedSignal.id))
                    }
                }
            }
        }

        if (isSendingOrder) {
            if (isNotNull(tradeOrder) && isNotNull(tradeOrder.items)) {
                if (tradeOrder.items.success && enableTradeForm) {
                    const { orderDetails } = this.state
                    const { signals } = this.props.signals

                    onSetNewState(this, {
                        enableTradeForm: false
                    }, () => {
                        if (isNotNull(orderDetails) && isNotNull(signals)) {
                            const { dispatch } = this.props

                            let signalIndex = _.findIndex(signals, s => s.id === orderDetails.signalId)
                            if (signalIndex !== -1) {
                                signals[signalIndex].isWatchlisted = true
                                signals[signalIndex].isOrdered = true
                                dispatch(signalActions.updateSignalState(signals))
                            }
                        }
                    })
                } else {
                    onSetNewState(this, {
                        isSendingOrder: false
                    })
                }
            }

            if (isNotNull(tradeOrderLeverage) && isNotNull(tradeOrderLeverage.response)) {
                if (tradeOrderLeverage.response.success && enableTradeForm) {
                    const { orderDetails } = this.state
                    const { signals } = this.props.signals

                    onSetNewState(this, {
                        enableTradeForm: false
                    }, () => {
                        if (isNotNull(orderDetails) && isNotNull(signals)) {
                            const { dispatch } = this.props

                            let signalIndex = _.findIndex(signals, s => s.id === orderDetails.signalId)
                            if (signalIndex !== -1) {
                                signals[signalIndex].isWatchlisted = true
                                signals[signalIndex].isOrdered = true
                                dispatch(signalActions.updateSignalState(signals))
                            }
                        }
                    })
                } else {
                    onSetNewState(this, {
                        isSendingOrder: false
                    })
                }
            }
            
            if (isNotNull(tradeSummaryOnly)) {
                if (tradeSummaryOnly && enableTradeForm) {
                    this.tradeOrderSucceeded = true
                    onSetNewState(this, {
                        enableTradeForm: false
                    })
                }
                
                if (tradeSummaryOnly && !enableTradeForm) {
                    onSetNewState(this, {
                        isSendingOrder: false
                    })
                }
            }
        }

        // New Set of Signals
        if (prevProps.signalsNew !== signalsNew) {
            if (isNotNull(signalsNew) && !signalsNew.loading) {
                if (isNotNull(signalsNew.signals) && signalsNew.signals.length > 0) {
                    const { signals } = this.props.signals
                    if (isNotNull(signals)) {
                        const diff = _.differenceWith(signalsNew.signals, signals, _.isEqual)
                        if (isNotNull(diff))
                            signals.push(...diff)
                        dispatch({ type: signalActionTypes.GET_SIGNALS_SUCCESS, signals })
                        this.setState({ hasNewSignalSetLoaded: false, hasNoNewSignals: false })
                    }
                } else {
                    this.setState({ 
                        hasNewSignalSetLoaded: false, 
                        hasNoNewSignals: true 
                    })
                }                
            }
        }

        // Get Total Signals
        if (prevProps.signals !== this.props.signals) {
            const { signals } = this.props.signals;
            if (isNotNull(signals) && signals.length > 0) {
                onSetNewState(this, { totalSignalSize: signals.length })
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
        this.setState(this.baseState)

        if (this.state.isSignalStateFilterLoaded) {
            onSetNewState(this, {
                isSignalStateFilterLoaded: false
            })
        }

        if (isNotNull(this.signalFeedHubConnection))
            this.signalFeedHubConnection = null

        if (isNotNull(this.signalUpdatesHubConnection))
            this.signalUpdatesHubConnection = null

        window.removeEventListener('resize', () => Dimension.updateDimensions(this))
    }

    onLoadSignalSet = () => {
        const { dispatch } = this.props
        const { user } = this.props.authentication
        const { page, currentSet, hasNewSignalSetLoaded, totalSignalSize, hasNoNewSignals } = this.state

        if (isNotNull(user)) {
            if (isNotNull(currentSet) && isNotNull(totalSignalSize) && totalSignalSize >= currentSet) {
                onSetNewState(this, { hasNewSignalSetLoaded: false, hasNoNewSignals: false })
            }
    
            if (hasNewSignalSetLoaded === false && hasNoNewSignals === false) {
                const _page = page + 1
                onSetNewState(this, { page: _page, currentSet: currentSet + 12, hasNewSignalSetLoaded: true })
    
                dispatch(signalActions.getNewSignalSet(
                    this.state.signalStateFilter.selectedValue,
                    this.state.signalExchangeFilter.selectedValue, 
                    this.state.tradeResultFilter.selectedValues, 
                    _page, 
                    12))
            }
        }
    }

    async initSignalFeedHubConnection() {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${feedUrl}/signals`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
                accessTokenFactory: () => signalFeedToken
            })
            .configureLogging(signalR.LogLevel.None)
            .withAutomaticReconnect([0, 0, 10000])
            .build()

        hubConnection.serverTimeoutInMilliseconds = 120000

        this.signalFeedHubConnection = hubConnection

        this.signalFeedHubConnection.onclose(async () => {
            await this.startSignalsFeedConnection()
        })

        this.signalFeedHubConnection.on("publishsignal", (payload) => {
            const { signalStateFilter, signalExchangeFilter } = this.state

            if (isNotNull(payload) && isNotNull(signalStateFilter) && isNotNull(signalExchangeFilter)) {
                const { selectedValue } = signalStateFilter
                const { dispatch, authentication } = this.props
                const { user } = authentication
                let { signals } = this.props.signals

                if (isNotNull(user) && isNotNull(selectedValue)) {
                    if (isNotNull(signals)) {                        
                        switch (parseInt(signalExchangeFilter.selectedValue)) {
                            default: {
                                let exists = _.find(signals, ['id', payload.id])
                                if (!exists) {
                                    if (parseInt(selectedValue) === stateFilter.ALL ||
                                        parseInt(selectedValue) === stateFilter.FRESH_SIGNALS) {                                            
                                        if (payload.isFree) {
                                            if (isNotNull(user) && user.isPaid) {
                                                signals.unshift(payload)
                                            } else {
                                                let newSignal = payload
                                                
                                                if (newSignal.targetZone.index > 1) {                                                        
                                                    newSignal.targetZone.startPrice = null
                                                    newSignal.targetZone.startPriceTxt = '????'
                                                }

                                                newSignal.potentialGains = '????%'
                                                signals.unshift(newSignal)
                                            }

                                            signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                            dispatch(signalActions.updateSignals(signals))
                                        } else {
                                            if (isNotNull(user) && user.isPaid) {
                                                signals.unshift(payload)
                                            } else {
                                                let newSignal = payload

                                                let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                                newSignal.symbol = `????${quoteSymbol}`

                                                newSignal.entryStartPrice = '????'
                                                newSignal.entryEndPrice = '????'

                                                if (newSignal.targetZone.index > 1) {                                                        
                                                    newSignal.targetZone.startPrice = null
                                                    newSignal.targetZone.startPriceTxt = '????'
                                                }

                                                newSignal.stopLossPrice = '????'
                                                newSignal.toSym = ''
                                                signals.unshift(newSignal)
                                            }

                                            signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                            dispatch(signalActions.updateSignals(signals))
                                        }
                                    }
                                }
        
                                break
                            }
                            case 1: {
                                if (payload.feed.toLowerCase() === 'spot') {
                                    let exists = _.find(signals, ['id', payload.id])
                                    if (!exists) {
                                        if (parseInt(selectedValue) === stateFilter.ALL ||
                                            parseInt(selectedValue) === stateFilter.FRESH_SIGNALS) {
                                            if (payload.isFree) {
                                                if (isNotNull(user) && user.isPaid) {
                                                    signals.unshift(payload)
                                                } else {
                                                    let newSignal = payload
                                                    
                                                    if (newSignal.targetZone.index > 1) {                                                        
                                                        newSignal.targetZone.startPrice = null
                                                        newSignal.targetZone.startPriceTxt = '????'
                                                    }
    
                                                    newSignal.potentialGains = '????%'
                                                    signals.unshift(newSignal)
                                                }
    
                                                signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                                dispatch(signalActions.updateSignals(signals))
                                            } else {
                                                if (isNotNull(user) && user.isPaid) {
                                                    signals.unshift(payload)
                                                } else {
                                                    let newSignal = payload
    
                                                    let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                                    newSignal.symbol = `????${quoteSymbol}`
    
                                                    newSignal.entryStartPrice = '????'
                                                    newSignal.entryEndPrice = '????'
    
                                                    if (newSignal.targetZone.index > 1) {                                                        
                                                        newSignal.targetZone.startPrice = null
                                                        newSignal.targetZone.startPriceTxt = '????'
                                                    }
    
                                                    newSignal.stopLossPrice = '????'
                                                    newSignal.toSym = ''
                                                    signals.unshift(newSignal)
                                                }
    
                                                signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                                dispatch(signalActions.updateSignals(signals))
                                            }
                                        }
                                    }
                                }
                                break
                            }
                            case 2: {
                                if (payload.feed.toLowerCase() === 'futures') {
                                    let exists = _.find(signals, ['id', payload.id])
                                    if (!exists) {
                                        if (parseInt(selectedValue) === stateFilter.ALL ||
                                            parseInt(selectedValue) === stateFilter.FRESH_SIGNALS) {
                                            if (payload.isFree) {
                                                if (isNotNull(user) && user.isPaid) {
                                                    signals.unshift(payload)
                                                } else {
                                                    let newSignal = payload
                                                    
                                                    if (newSignal.targetZone.index > 1) {                                                        
                                                        newSignal.targetZone.startPrice = null
                                                        newSignal.targetZone.startPriceTxt = '????'
                                                    }
    
                                                    newSignal.potentialGains = '????%'
                                                    signals.unshift(newSignal)
                                                }
    
                                                signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                                dispatch(signalActions.updateSignals(signals))
                                            } else {
                                                if (isNotNull(user) && user.isPaid) {
                                                    signals.unshift(payload)
                                                } else {
                                                    let newSignal = payload
    
                                                    let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                                    newSignal.symbol = `????${quoteSymbol}`
    
                                                    newSignal.entryStartPrice = '????'
                                                    newSignal.entryEndPrice = '????'
    
                                                    if (newSignal.targetZone.index > 1) {                                                        
                                                        newSignal.targetZone.startPrice = null
                                                        newSignal.targetZone.startPriceTxt = '????'
                                                    }
    
                                                    newSignal.stopLossPrice = '????'
                                                    newSignal.toSym = ''
                                                    signals.unshift(newSignal)
                                                }
    
                                                signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                                dispatch(signalActions.updateSignals(signals))
                                            }
                                        }
                                    }
                                }
                                break
                            }
                        }
                    } else {
                        switch (parseInt(signalExchangeFilter.selectedValue)) {
                            default: {
                                signals = []

                                if (payload.isFree) {
                                    if (isNotNull(user) && user.isPaid) {
                                        signals.unshift(payload)
                                    } else {
                                        let newSignal = payload
                                        
                                        if (newSignal.targetZone.index > 1) {                                                        
                                            newSignal.targetZone.startPrice = null
                                            newSignal.targetZone.startPriceTxt = '????'
                                        }

                                        newSignal.potentialGains = '????%'
                                        signals.unshift(newSignal)
                                    }

                                    signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                    dispatch(signalActions.updateSignals(signals))
                                } else {
                                    if (isNotNull(user) && user.isPaid) {
                                        signals.unshift(payload)
                                    } else {
                                        let newSignal = payload

                                        let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                        newSignal.symbol = `????${quoteSymbol}`

                                        newSignal.entryStartPrice = '????'
                                        newSignal.entryEndPrice = '????'

                                        if (newSignal.targetZone.index > 1) {                                                        
                                            newSignal.targetZone.startPrice = null
                                            newSignal.targetZone.startPriceTxt = '????'
                                        }

                                        newSignal.stopLossPrice = '????'
                                        newSignal.toSym = ''
                                        signals.unshift(newSignal)
                                    }

                                    signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                    dispatch(signalActions.updateSignals(signals))
                                }

                                break
                            }
                            case 1: {
                                if (payload.feed.toLowerCase() === 'spot') {
                                    signals = []
                                    
                                    if (payload.isFree) {
                                        if (isNotNull(user) && user.isPaid) {
                                            signals.unshift(payload)
                                        } else {
                                            let newSignal = payload
                                            
                                            if (newSignal.targetZone.index > 1) {                                                        
                                                newSignal.targetZone.startPrice = null
                                                newSignal.targetZone.startPriceTxt = '????'
                                            }
    
                                            newSignal.potentialGains = '????%'
                                            signals.unshift(newSignal)
                                        }
    
                                        signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                        dispatch(signalActions.updateSignals(signals))
                                    } else {
                                        if (isNotNull(user) && user.isPaid) {
                                            signals.unshift(payload)
                                        } else {
                                            let newSignal = payload
    
                                            let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                            newSignal.symbol = `????${quoteSymbol}`
    
                                            newSignal.entryStartPrice = '????'
                                            newSignal.entryEndPrice = '????'
    
                                            if (newSignal.targetZone.index > 1) {                                                        
                                                newSignal.targetZone.startPrice = null
                                                newSignal.targetZone.startPriceTxt = '????'
                                            }
    
                                            newSignal.stopLossPrice = '????'
                                            newSignal.toSym = ''
                                            signals.unshift(newSignal)
                                        }
    
                                        signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                        dispatch(signalActions.updateSignals(signals))
                                    }
                                }
                                break
                            }
                            case 2: {
                                if (payload.feed.toLowerCase() === 'futures') {
                                    signals = []
                                    
                                    if (payload.isFree) {
                                        if (isNotNull(user) && user.isPaid) {
                                            signals.unshift(payload)
                                        } else {
                                            let newSignal = payload
                                            
                                            if (newSignal.targetZone.index > 1) {                                                        
                                                newSignal.targetZone.startPrice = null
                                                newSignal.targetZone.startPriceTxt = '????'
                                            }
    
                                            newSignal.potentialGains = '????%'
                                            signals.unshift(newSignal)
                                        }
    
                                        signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                        dispatch(signalActions.updateSignals(signals))
                                    } else {
                                        if (isNotNull(user) && user.isPaid) {
                                            signals.unshift(payload)
                                        } else {
                                            let newSignal = payload
    
                                            let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                            newSignal.symbol = `????${quoteSymbol}`
    
                                            newSignal.entryStartPrice = '????'
                                            newSignal.entryEndPrice = '????'
    
                                            if (newSignal.targetZone.index > 1) {                                                        
                                                newSignal.targetZone.startPrice = null
                                                newSignal.targetZone.startPriceTxt = '????'
                                            }
    
                                            newSignal.stopLossPrice = '????'
                                            newSignal.toSym = ''
                                            signals.unshift(newSignal)
                                        }
    
                                        signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                        dispatch(signalActions.updateSignals(signals))
                                    }
                                }
                                break
                            }
                        }
                    }
                }
            }
        })

        this.signalFeedHubConnection.on("CloseTrade", (payload) => {
            const { signalStateFilter, selectedSignal } = this.state

            if (isNotNull(payload)) {
                const { selectedValue } = signalStateFilter
                let { dispatch } = this.props
                let { signals } = this.props.signals
                let signalIndex = _.findIndex(signals, s => s.id === payload.id)

                if (isNotNull(selectedValue)) {
                    switch (parseInt(selectedValue)) {
                        case stateFilter.FRESH_SIGNALS: {
                            if (signalIndex !== -1) {
                                signals.splice(signalIndex, 1)
                                dispatch(signalActions.updateSignals(signals))
                            }
                            break
                        }
                        default: {
                            if (signalIndex !== -1) {
                                signals[signalIndex] = payload
                            } else {
                                signals.unshift(payload)
                            }

                            dispatch(signalActions.updateSignals(signals))
                            break
                        }
                    }
                }

                if (isNotNull(selectedSignal) && selectedSignal.id === payload.id) {
                    signals[signalIndex] = payload
                    dispatch(signalActions.updateSignals(signals))
                }
            }
        })

        // start signals feed connection
        await this.startSignalsFeedConnection()
    }

    async startSignalsFeedConnection() {
        try {
            const { user } = this.props.authentication

            await this.signalFeedHubConnection
                .start()
                .catch((err) => {
                    //console.log(err)
                })

            if (isNotNull(this.signalFeedHubConnection) &&
                isNotNull(this.signalFeedHubConnection.state) &&
                this.signalFeedHubConnection.state === signalR.HubConnectionState.Connected) {
                this.signalFeedHubConnection.invoke("GetConnectionId", user.userName)
                    .then(function () {
                        //console.log('connectionId: ', connectionId)
                    })
                    .catch(function () {
                        //console.log(err)
                    })
            }
        } catch (err) {
            //console.log(err)

            if (isNotNull(this.signalFeedHubConnection) &&
                isNotNull(this.signalFeedHubConnection.state) &&
                this.signalFeedHubConnection.state === signalR.HubConnectionState.Disconnected) {
                // restart connection
                setTimeout(async () =>
                    await this.startSignalsFeedConnection(), 3000)
            }
        }
    }

    async initSpotTrackerConnection() {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl("wss://spotfeed.profitfarmers.com/spot-signals", {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .configureLogging(signalR.LogLevel.None)
            .withAutomaticReconnect([0, 0, 10000])
            .build()

        hubConnection.serverTimeoutInMilliseconds = 120000

        this.spotTrackerHubConnection = hubConnection

        this.spotTrackerHubConnection.onclose(async (e) => {
            //console.log(e)
            await this.startSpotTrackerConnection()
        })

        this.spotTrackerHubConnection.on("activatesignal", (payload) => {
            let { signals } = this.props.signals
            if (isNotNull(signals)) {
                const { dispatch } = this.props
                const { selectedSignal } = this.state

                let signalIndex = _.findIndex(signals, s => s.id === payload)
                if (signalIndex !== -1) {
                    signals[signalIndex].isActive = true
                    dispatch(signalActions.updateSignalState(signals))
                }

                if (isNotNull(selectedSignal) && selectedSignal.id === payload.id) {
                    selectedSignal.isActive = true
                }
            }
        })

        this.spotTrackerHubConnection.on("changestate", (payload) => {
            //console.log('payload: ', payload)
            //console.log('updating...')
            const { signalStateFilter, signalExchangeFilter } = this.state
            let { selectedSignal } = this.state

            if (isNotNull(payload) && isNotNull(signalStateFilter) && isNotNull(signalExchangeFilter)) {
                const { dispatch, authentication } = this.props
                const { user } = authentication

                const { selectedValue } = signalStateFilter
                let { signals } = this.props.signals

                if (isNotNull(signals) && isNotNull(selectedValue) && isNotNull(signalExchangeFilter.selectedValue)) {
                    let selectedExchange = ''
                    switch (parseInt(signalExchangeFilter.selectedValue)) {
                        default: {
                            selectedExchange = 'ALL'
                            break
                        }
                        case 1: {
                            selectedExchange = 'SPOT'
                            break
                        }
                        case 2: {
                            selectedExchange = 'FUTURES'
                            break
                        }
                    }

                    if (selectedExchange !== '') {
                        switch (parseInt(selectedValue)) {
                            default:
                            case stateFilter.ALL: {                               
                                if (selectedExchange === 'ALL' || (selectedExchange === payload.feed)) {  
                                    if (parseInt(payload.closeState) === signalCloseState.PENDING) {
                                        let exists = _.find(signals, ['id', payload.id])
                                        if (!exists) {
                                            if (payload.isFree) {
                                                if (isNotNull(user) && user.isPaid) {
                                                    signals.unshift(payload)
                                                } else {
                                                    let newSignal = payload
                                                    
                                                    if (newSignal.targetZone.index > 1) {                                                        
                                                        newSignal.targetZone.startPrice = null
                                                        newSignal.targetZone.startPriceTxt = '????'
                                                    }

                                                    newSignal.potentialGains = '????%'
                                                    signals.unshift(newSignal)
                                                }

                                                signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                                dispatch(signalActions.updateSignals(signals))
                                            } else {
                                                if (isNotNull(user) && user.isPaid) {
                                                    signals.unshift(payload)
                                                } else {
                                                    let newSignal = payload

                                                    let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                                    newSignal.symbol = `????${quoteSymbol}`

                                                    newSignal.entryStartPrice = '????'
                                                    newSignal.entryEndPrice = '????'

                                                    if (newSignal.targetZone.index > 1) {                                                        
                                                        newSignal.targetZone.startPrice = null
                                                        newSignal.targetZone.startPriceTxt = '????'
                                                    }

                                                    newSignal.stopLossPrice = '????'
                                                    newSignal.toSym = ''
                                                    signals.unshift(newSignal)
                                                }

                                                signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                                dispatch(signalActions.updateSignals(signals))
                                            }
                                        } else {
                                            let signalIndex = _.findIndex(signals, s => s.id === payload.id)
                                            
                                            let newSignal = payload
                                            if (payload.isFree) {
                                                if (isNotNull(user) && !user.isPaid) {                                                    
                                                    if (newSignal.targetZone.index > 1) {                                                        
                                                        newSignal.targetZone.startPrice = null
                                                        newSignal.targetZone.startPriceTxt = '????'
                                                    }

                                                    newSignal.potentialGains = '????%'
                                                }
                                            } else {
                                                if (isNotNull(user) && !user.isPaid) {
                                                    let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                                    newSignal.symbol = `????${quoteSymbol}`

                                                    newSignal.entryStartPrice = '????'
                                                    newSignal.entryEndPrice = '????'

                                                    if (newSignal.targetZone.index > 1) {                                                        
                                                        newSignal.targetZone.startPrice = null
                                                        newSignal.targetZone.startPriceTxt = '????'
                                                    }

                                                    newSignal.stopLossPrice = '????'
                                                    newSignal.toSym = ''
                                                }
                                            }

                                            signals[signalIndex] = newSignal
                                            dispatch(signalActions.updateSignalState(signals))
                                        }
                                    }
                                }
                                break
                            }
                            case stateFilter.FRESH_SIGNALS: {
                                if (selectedExchange === 'ALL' || (selectedExchange === payload.feed)) {
                                    if (parseInt(payload.closeState) === signalCloseState.PENDING) {
                                        let exists = _.find(signals, ['id', payload.id])
                                        if (!exists) {
                                            if (payload.state.item1 === "s-asl" ||
                                                payload.state.item1 === "s-wez" ||
                                                payload.state.item1 === "s-lez" ||
                                                payload.state.item1 === "s-at1" ||
                                                payload.state.item1 === "s-t1h") {                                                    
                                                    let newSignal = payload

                                                    if (payload.isFree) {
                                                        if (isNotNull(user) && !user.isPaid) {                                                            
                                                            if (newSignal.targetZone.index > 1) {                                                        
                                                                newSignal.targetZone.startPrice = null
                                                                newSignal.targetZone.startPriceTxt = '????'
                                                            }
        
                                                            newSignal.potentialGains = '????%'
                                                        }
                                                    } else {
                                                        if (isNotNull(user) && !user.isPaid) {
                                                            let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                                            newSignal.symbol = `????${quoteSymbol}`
        
                                                            newSignal.entryStartPrice = '????'
                                                            newSignal.entryEndPrice = '????'
        
                                                            if (newSignal.targetZone.index > 1) {                                                        
                                                                newSignal.targetZone.startPrice = null
                                                                newSignal.targetZone.startPriceTxt = '????'
                                                            }
        
                                                            newSignal.stopLossPrice = '????'
                                                            newSignal.toSym = ''
                                                        }
                                                    }

                                                    signals.unshift(newSignal)
                                                    signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                                    dispatch(signalActions.updateSignals(signals))
                                            }
                                        } else {
                                            let signalIndex = _.findIndex(signals, s => s.id === payload.id)
                                            if (payload.state.item1 === "s-asl" ||
                                                payload.state.item1 === "s-wez" ||
                                                payload.state.item1 === "s-lez" ||
                                                payload.state.item1 === "s-at1" ||
                                                payload.state.item1 === "s-t1h") {
                                                    let newSignal = payload

                                                    if (payload.isFree) {
                                                        if (isNotNull(user) && !user.isPaid) {                                                            
                                                            if (newSignal.targetZone.index > 1) {                                                        
                                                                newSignal.targetZone.startPrice = null
                                                                newSignal.targetZone.startPriceTxt = '????'
                                                            }
        
                                                            newSignal.potentialGains = '????%'
                                                        }
                                                    } else {
                                                        if (isNotNull(user) && !user.isPaid) {
                                                            let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                                            newSignal.symbol = `????${quoteSymbol}`
        
                                                            newSignal.entryStartPrice = '????'
                                                            newSignal.entryEndPrice = '????'
        
                                                            if (newSignal.targetZone.index > 1) {                                                        
                                                                newSignal.targetZone.startPrice = null
                                                                newSignal.targetZone.startPriceTxt = '????'
                                                            }
        
                                                            newSignal.stopLossPrice = '????'
                                                            newSignal.toSym = ''
                                                        }
                                                    }

                                                    signals[signalIndex] = newSignal
                                                    dispatch(signalActions.updateSignalState(signals))
                                            } else {
                                                signals.splice(signalIndex, 1)
                                                dispatch(signalActions.updateSignalState(signals))
                                            }
                                        }
                                    }
                                }
                                break
                            }
                            case stateFilter.SIGNALS_IN_PROGRESS: {
                                if (selectedExchange === 'ALL' || (selectedExchange === payload.feed)) {
                                    if (parseInt(payload.closeState) === signalCloseState.PENDING) {
                                        let exists = _.find(signals, ['id', payload.id])
                                        if (!exists) {
                                            if (payload.state.item1 === "s-t1h" ||
                                                payload.state.item1 === "s-t2h" ||
                                                payload.state.item1 === "s-t3h") {
                                                    let newSignal = payload

                                                    if (payload.isFree) {
                                                        if (isNotNull(user) && !user.isPaid) {                                                            
                                                            if (newSignal.targetZone.index > 1) {                                                        
                                                                newSignal.targetZone.startPrice = null
                                                                newSignal.targetZone.startPriceTxt = '????'
                                                            }
        
                                                            newSignal.potentialGains = '????%'
                                                        }
                                                    } else {
                                                        if (isNotNull(user) && !user.isPaid) {
                                                            let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                                            newSignal.symbol = `????${quoteSymbol}`
        
                                                            newSignal.entryStartPrice = '????'
                                                            newSignal.entryEndPrice = '????'
        
                                                            if (newSignal.targetZone.index > 1) {                                                        
                                                                newSignal.targetZone.startPrice = null
                                                                newSignal.targetZone.startPriceTxt = '????'
                                                            }
        
                                                            newSignal.stopLossPrice = '????'
                                                            newSignal.toSym = ''
                                                        }
                                                    }

                                                    signals.unshift(newSignal)
                                                    signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                                    dispatch(signalActions.updateSignals(signals))
                                            }
                                        } else {
                                            let signalIndex = _.findIndex(signals, s => s.id === payload.id)
                                            if (payload.state.item1 === "s-t1h" ||
                                                payload.state.item1 === "s-t2h" ||
                                                payload.state.item1 === "s-t3h") {
                                                    let newSignal = payload

                                                    if (payload.isFree) {
                                                        if (isNotNull(user) && !user.isPaid) {                                                            
                                                            if (newSignal.targetZone.index > 1) {                                                        
                                                                newSignal.targetZone.startPrice = null
                                                                newSignal.targetZone.startPriceTxt = '????'
                                                            }
        
                                                            newSignal.potentialGains = '????%'
                                                        }
                                                    } else {
                                                        if (isNotNull(user) && !user.isPaid) {
                                                            let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                                            newSignal.symbol = `????${quoteSymbol}`
        
                                                            newSignal.entryStartPrice = '????'
                                                            newSignal.entryEndPrice = '????'
        
                                                            if (newSignal.targetZone.index > 1) {                                                        
                                                                newSignal.targetZone.startPrice = null
                                                                newSignal.targetZone.startPriceTxt = '????'
                                                            }
        
                                                            newSignal.stopLossPrice = '????'
                                                            newSignal.toSym = ''
                                                        }
                                                    }

                                                    signals[signalIndex] = newSignal
                                                    dispatch(signalActions.updateSignalState(signals))
                                            } else {
                                                signals.splice(signalIndex, 1)
                                                dispatch(signalActions.updateSignalState(signals))
                                            }
                                        }
                                    }
                                }
                                break
                            }
                            case stateFilter.WATCHLISTED_SIGNALS: {
                                if (selectedExchange === 'ALL' || (selectedExchange === payload.feed)) {
                                    if (payload.isWatchlisted) {                                        
                                        if (parseInt(payload.closeState) === signalCloseState.PENDING) {
                                            let exists = _.find(signals, ['id', payload.id])
                                            if (!exists) {
                                                let newSignal = payload

                                                if (payload.isFree) {
                                                    if (isNotNull(user) && !user.isPaid) {                                                            
                                                        if (newSignal.targetZone.index > 1) {                                                        
                                                            newSignal.targetZone.startPrice = null
                                                            newSignal.targetZone.startPriceTxt = '????'
                                                        }
    
                                                        newSignal.potentialGains = '????%'
                                                    }
                                                } else {
                                                    if (isNotNull(user) && !user.isPaid) {
                                                        let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                                        newSignal.symbol = `????${quoteSymbol}`
    
                                                        newSignal.entryStartPrice = '????'
                                                        newSignal.entryEndPrice = '????'
    
                                                        if (newSignal.targetZone.index > 1) {                                                        
                                                            newSignal.targetZone.startPrice = null
                                                            newSignal.targetZone.startPriceTxt = '????'
                                                        }
    
                                                        newSignal.stopLossPrice = '????'
                                                        newSignal.toSym = ''
                                                    }
                                                }

                                                signals.unshift(newSignal)
                                                signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                                dispatch(signalActions.updateSignals(signals))
                                            } else {
                                                let signalIndex = _.findIndex(signals, s => s.id === payload.id)
                                                let newSignal = payload

                                                if (payload.isFree) {
                                                    if (isNotNull(user) && !user.isPaid) {                                                            
                                                        if (newSignal.targetZone.index > 1) {                                                        
                                                            newSignal.targetZone.startPrice = null
                                                            newSignal.targetZone.startPriceTxt = '????'
                                                        }
    
                                                        newSignal.potentialGains = '????%'
                                                    }
                                                } else {
                                                    if (isNotNull(user) && !user.isPaid) {
                                                        let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                                        newSignal.symbol = `????${quoteSymbol}`
    
                                                        newSignal.entryStartPrice = '????'
                                                        newSignal.entryEndPrice = '????'
    
                                                        if (newSignal.targetZone.index > 1) {                                                        
                                                            newSignal.targetZone.startPrice = null
                                                            newSignal.targetZone.startPriceTxt = '????'
                                                        }
    
                                                        newSignal.stopLossPrice = '????'
                                                        newSignal.toSym = ''
                                                    }
                                                }

                                                signals[signalIndex] = newSignal
                                                dispatch(signalActions.updateSignalState(signals))
                                            }
                                        }
                                    }
                                }
                                break
                            }
                        }
                    }
                }

                if (isNotNull(selectedSignal) && selectedSignal.id === payload.id) {
                    selectedSignal = payload
                    onSetNewState(this, {
                        selectedSignal
                    })
                }
            }
        })

        this.spotTrackerHubConnection.on("close", (payload) => {
            const { signalStateFilter, selectedSignal } = this.state

            if (isNotNull(payload)) {
                const { selectedValue } = signalStateFilter
                let { dispatch } = this.props
                let { signals } = this.props.signals
                let signalIndex = _.findIndex(signals, s => s.id === payload.id)

                if (isNotNull(selectedValue)) {
                    switch (parseInt(selectedValue)) {
                        case stateFilter.FRESH_SIGNALS:
                        case stateFilter.SIGNALS_IN_PROGRESS: {
                            if (signalIndex !== -1) {
                                signals.splice(signalIndex, 1)
                                dispatch(signalActions.updateSignalState(signals))
                            }
                            break
                        }
                        case stateFilter.WATCHLISTED_SIGNALS: {
                            if (payload.isWatchlisted) {
                                if (signalIndex !== -1) {
                                    signals[signalIndex] = payload
                                } else {
                                    signals.unshift(payload)
                                }
    
                                dispatch(signalActions.updateSignalState(signals))
                            }

                            break
                        }
                        default: {
                            if (signalIndex !== -1) {
                                signals[signalIndex] = payload
                            } else {
                                signals.unshift(payload)
                            }

                            dispatch(signalActions.updateSignalState(signals))
                            break
                        }
                    }
                }

                if (isNotNull(selectedSignal) && selectedSignal.id === payload.id) {
                    signals[signalIndex] = payload
                    dispatch(signalActions.updateSignalState(signals))
                }
            }
        })

        // start signal tracker connection
        await this.startSpotTrackerConnection()
    }

    async startSpotTrackerConnection() {
        if (this.mounted) {
            try {
                const { user } = this.props.authentication
                await this.spotTrackerHubConnection
                    .start()
                    .catch((err) => console.log(err))

                if (isNotNull(this.spotTrackerHubConnection) &&
                    isNotNull(this.spotTrackerHubConnection.state) &&
                    this.spotTrackerHubConnection.state === signalR.HubConnectionState.Connected) {
                    this.spotTrackerHubConnection.invoke("GetConnectionId", user.userName)
                        .then(function (connectionId) {
                            //console.log('connection id from signal tracker: ', connectionId)
                        })
                        .catch((err) => console.error(err))
                }
            } catch (err) {
                console.log(err)

                if (isNotNull(this.spotTrackerHubConnection) &&
                    isNotNull(this.spotTrackerHubConnection.state) &&
                    this.spotTrackerHubConnection.state === signalR.HubConnectionState.Disconnected) {
                    // restart the connection
                    setTimeout(async () =>
                        await this.startSpotTrackerConnection(), 3000)
                }
            }
        }
    }

    async initFuturesTrackerConnection() {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl("wss://futuresfeed.profitfarmers.com/futures-signals", {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .configureLogging(signalR.LogLevel.None)
            .withAutomaticReconnect([0, 0, 10000])
            .build()

        hubConnection.serverTimeoutInMilliseconds = 120000

        this.futuresTrackerHubConnection = hubConnection

        this.futuresTrackerHubConnection.onclose(async (e) => {
            //console.log(e)
            await this.startFuturesTrackerConnection()
        })

        this.futuresTrackerHubConnection.on("activatesignal", (payload) => {
            let { signals } = this.props.signals
            if (isNotNull(signals)) {
                const { dispatch } = this.props
                const { selectedSignal } = this.state

                let signalIndex = _.findIndex(signals, s => s.id === payload)
                if (signalIndex !== -1) {
                    signals[signalIndex].isActive = true
                    dispatch(signalActions.updateSignalState(signals))
                }

                if (isNotNull(selectedSignal) && selectedSignal.id === payload.id) {
                    selectedSignal.isActive = true
                }
            }
        })

        this.futuresTrackerHubConnection.on("changestate", (payload) => {
            const { signalStateFilter, signalExchangeFilter } = this.state
            let { selectedSignal } = this.state

            if (isNotNull(payload) && isNotNull(signalStateFilter) && isNotNull(signalExchangeFilter)) {
                const { dispatch, authentication } = this.props
                const { user } = authentication

                const { selectedValue } = signalStateFilter
                let { signals } = this.props.signals

                if (isNotNull(signals) && isNotNull(selectedValue) && isNotNull(signalExchangeFilter.selectedValue)) {
                    let selectedExchange = ''
                    switch (parseInt(signalExchangeFilter.selectedValue)) {
                        default: {
                            selectedExchange = 'ALL'
                            break
                        }
                        case 1: {
                            selectedExchange = 'SPOT'
                            break
                        }
                        case 2: {
                            selectedExchange = 'FUTURES'
                            break
                        }
                    }

                    if (selectedExchange !== '') {
                        switch (parseInt(selectedValue)) {
                            default: 
                            case stateFilter.ALL: {                                
                                if (selectedExchange === 'ALL' || (selectedExchange === payload.feed)) {
                                    if (parseInt(payload.closeState) === signalCloseState.PENDING) {
                                        let exists = _.find(signals, ['id', payload.id])
                                        if (!exists) {
                                            if (payload.isFree) {
                                                if (isNotNull(user) && user.isPaid) {
                                                    signals.unshift(payload)
                                                } else {
                                                    let newSignal = payload
                                                    
                                                    if (newSignal.targetZone.index > 1) {                                                        
                                                        newSignal.targetZone.startPrice = null
                                                        newSignal.targetZone.startPriceTxt = '????'
                                                    }

                                                    newSignal.potentialGains = '????%'
                                                    signals.unshift(newSignal)
                                                }

                                                signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                                dispatch(signalActions.updateSignals(signals))
                                            } else {
                                                if (isNotNull(user) && user.isPaid) {
                                                    signals.unshift(payload)
                                                } else {
                                                    let newSignal = payload

                                                    let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                                    newSignal.symbol = `????${quoteSymbol}`

                                                    newSignal.entryStartPrice = '????'
                                                    newSignal.entryEndPrice = '????'

                                                    if (newSignal.targetZone.index > 1) {                                                        
                                                        newSignal.targetZone.startPrice = null
                                                        newSignal.targetZone.startPriceTxt = '????'
                                                    }

                                                    newSignal.stopLossPrice = '????'
                                                    newSignal.toSym = ''
                                                    signals.unshift(newSignal)
                                                }

                                                signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                                dispatch(signalActions.updateSignals(signals))
                                            }
                                        } else {
                                            let signalIndex = _.findIndex(signals, s => s.id === payload.id)

                                            let newSignal = payload
                                            if (payload.isFree) {
                                                if (isNotNull(user) && !user.isPaid) {                                                    
                                                    if (newSignal.targetZone.index > 1) {                                                        
                                                        newSignal.targetZone.startPrice = null
                                                        newSignal.targetZone.startPriceTxt = '????'
                                                    }

                                                    newSignal.potentialGains = '????%'
                                                }
                                            } else {
                                                if (isNotNull(user) && !user.isPaid) {
                                                    let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                                    newSignal.symbol = `????${quoteSymbol}`

                                                    newSignal.entryStartPrice = '????'
                                                    newSignal.entryEndPrice = '????'

                                                    if (newSignal.targetZone.index > 1) {                                                        
                                                        newSignal.targetZone.startPrice = null
                                                        newSignal.targetZone.startPriceTxt = '????'
                                                    }

                                                    newSignal.stopLossPrice = '????'
                                                    newSignal.toSym = ''
                                                }
                                            }
                                            signals[signalIndex] = newSignal
                                            dispatch(signalActions.updateSignalState(signals))
                                        }
                                    }
                                }
                                break
                            }
                            case stateFilter.FRESH_SIGNALS: {
                                if (selectedExchange === 'ALL' || (selectedExchange === payload.feed)) {
                                    if (parseInt(payload.closeState) === signalCloseState.PENDING) {
                                        let exists = _.find(signals, ['id', payload.id])
                                        if (!exists) {
                                            if (payload.state.item1 === "s-asl" ||
                                                payload.state.item1 === "s-wez" ||
                                                payload.state.item1 === "s-lez" ||
                                                payload.state.item1 === "s-at1" ||
                                                payload.state.item1 === "s-t1h") {
                                                    let newSignal = payload

                                                    if (payload.isFree) {
                                                        if (isNotNull(user) && !user.isPaid) {                                                            
                                                            if (newSignal.targetZone.index > 1) {                                                        
                                                                newSignal.targetZone.startPrice = null
                                                                newSignal.targetZone.startPriceTxt = '????'
                                                            }
        
                                                            newSignal.potentialGains = '????%'
                                                        }
                                                    } else {
                                                        if (isNotNull(user) && !user.isPaid) {
                                                            let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                                            newSignal.symbol = `????${quoteSymbol}`
        
                                                            newSignal.entryStartPrice = '????'
                                                            newSignal.entryEndPrice = '????'
        
                                                            if (newSignal.targetZone.index > 1) {                                                        
                                                                newSignal.targetZone.startPrice = null
                                                                newSignal.targetZone.startPriceTxt = '????'
                                                            }
        
                                                            newSignal.stopLossPrice = '????'
                                                            newSignal.toSym = ''
                                                        }
                                                    }

                                                    signals.unshift(newSignal)
                                                    signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                                    dispatch(signalActions.updateSignals(signals))
                                            }
                                        } else {
                                            let signalIndex = _.findIndex(signals, s => s.id === payload.id)
                                            if (payload.state.item1 === "s-asl" ||
                                                payload.state.item1 === "s-wez" ||
                                                payload.state.item1 === "s-lez" ||
                                                payload.state.item1 === "s-at1" ||
                                                payload.state.item1 === "s-t1h") {
                                                    let newSignal = payload

                                                    if (payload.isFree) {
                                                        if (isNotNull(user) && !user.isPaid) {                                                            
                                                            if (newSignal.targetZone.index > 1) {                                                        
                                                                newSignal.targetZone.startPrice = null
                                                                newSignal.targetZone.startPriceTxt = '????'
                                                            }
        
                                                            newSignal.potentialGains = '????%'
                                                        }
                                                    } else {
                                                        if (isNotNull(user) && !user.isPaid) {
                                                            let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                                            newSignal.symbol = `????${quoteSymbol}`
        
                                                            newSignal.entryStartPrice = '????'
                                                            newSignal.entryEndPrice = '????'
        
                                                            if (newSignal.targetZone.index > 1) {                                                        
                                                                newSignal.targetZone.startPrice = null
                                                                newSignal.targetZone.startPriceTxt = '????'
                                                            }
        
                                                            newSignal.stopLossPrice = '????'
                                                            newSignal.toSym = ''
                                                        }
                                                    }

                                                    signals[signalIndex] = newSignal
                                                    dispatch(signalActions.updateSignalState(signals))
                                            } else {
                                                signals.splice(signalIndex, 1)
                                                dispatch(signalActions.updateSignalState(signals))
                                            }
                                        }
                                    }
                                }
                                break
                            }
                            case stateFilter.SIGNALS_IN_PROGRESS: {
                                if (selectedExchange === 'ALL' || (selectedExchange === payload.feed)) {
                                    if (parseInt(payload.closeState) === signalCloseState.PENDING) {
                                        let exists = _.find(signals, ['id', payload.id])
                                        if (!exists) {
                                            if (payload.state.item1 === "s-t1h" ||
                                                payload.state.item1 === "s-t2h" ||
                                                payload.state.item1 === "s-t3h") {
                                                    let newSignal = payload

                                                    if (payload.isFree) {
                                                        if (isNotNull(user) && !user.isPaid) {                                                            
                                                            if (newSignal.targetZone.index > 1) {                                                        
                                                                newSignal.targetZone.startPrice = null
                                                                newSignal.targetZone.startPriceTxt = '????'
                                                            }
        
                                                            newSignal.potentialGains = '????%'
                                                        }
                                                    } else {
                                                        if (isNotNull(user) && !user.isPaid) {
                                                            let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                                            newSignal.symbol = `????${quoteSymbol}`
        
                                                            newSignal.entryStartPrice = '????'
                                                            newSignal.entryEndPrice = '????'
        
                                                            if (newSignal.targetZone.index > 1) {                                                        
                                                                newSignal.targetZone.startPrice = null
                                                                newSignal.targetZone.startPriceTxt = '????'
                                                            }
        
                                                            newSignal.stopLossPrice = '????'
                                                            newSignal.toSym = ''
                                                        }
                                                    }

                                                    signals.unshift(newSignal)
                                                    signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                                    dispatch(signalActions.updateSignals(signals))
                                            }
                                        } else {
                                            let signalIndex = _.findIndex(signals, s => s.id === payload.id)
                                            if (payload.state.item1 === "s-t1h" ||
                                                payload.state.item1 === "s-t2h" ||
                                                payload.state.item1 === "s-t3h") {
                                                    let newSignal = payload

                                                    if (payload.isFree) {
                                                        if (isNotNull(user) && !user.isPaid) {                                                            
                                                            if (newSignal.targetZone.index > 1) {                                                        
                                                                newSignal.targetZone.startPrice = null
                                                                newSignal.targetZone.startPriceTxt = '????'
                                                            }
        
                                                            newSignal.potentialGains = '????%'
                                                        }
                                                    } else {
                                                        if (isNotNull(user) && !user.isPaid) {
                                                            let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                                            newSignal.symbol = `????${quoteSymbol}`
        
                                                            newSignal.entryStartPrice = '????'
                                                            newSignal.entryEndPrice = '????'
        
                                                            if (newSignal.targetZone.index > 1) {                                                        
                                                                newSignal.targetZone.startPrice = null
                                                                newSignal.targetZone.startPriceTxt = '????'
                                                            }
        
                                                            newSignal.stopLossPrice = '????'
                                                            newSignal.toSym = ''
                                                        }
                                                    }

                                                    signals[signalIndex] = newSignal
                                                    dispatch(signalActions.updateSignalState(signals))
                                            } else {
                                                signals.splice(signalIndex, 1)
                                                dispatch(signalActions.updateSignalState(signals))
                                            }
                                        }
                                    }
                                }
                                break
                            }
                            case stateFilter.WATCHLISTED_SIGNALS: {
                                if (selectedExchange === 'ALL' || (selectedExchange === payload.feed)) {
                                    if (payload.isWatchlisted) {                                        
                                        if (parseInt(payload.closeState) === signalCloseState.PENDING) {
                                            let exists = _.find(signals, ['id', payload.id])
                                            if (!exists) {
                                                let newSignal = payload

                                                if (payload.isFree) {
                                                    if (isNotNull(user) && !user.isPaid) {                                                            
                                                        if (newSignal.targetZone.index > 1) {                                                        
                                                            newSignal.targetZone.startPrice = null
                                                            newSignal.targetZone.startPriceTxt = '????'
                                                        }
    
                                                        newSignal.potentialGains = '????%'
                                                    }
                                                } else {
                                                    if (isNotNull(user) && !user.isPaid) {
                                                        let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                                        newSignal.symbol = `????${quoteSymbol}`
    
                                                        newSignal.entryStartPrice = '????'
                                                        newSignal.entryEndPrice = '????'
    
                                                        if (newSignal.targetZone.index > 1) {                                                        
                                                            newSignal.targetZone.startPrice = null
                                                            newSignal.targetZone.startPriceTxt = '????'
                                                        }
    
                                                        newSignal.stopLossPrice = '????'
                                                        newSignal.toSym = ''
                                                    }
                                                }

                                                signals.unshift(newSignal)
                                                signals = _.orderBy(signals, ['datePublished'], ['desc'])
                                                dispatch(signalActions.updateSignals(signals))
                                            } else {
                                                let signalIndex = _.findIndex(signals, s => s.id === payload.id)
                                                let newSignal = payload

                                                if (payload.isFree) {
                                                    if (isNotNull(user) && !user.isPaid) {                                                            
                                                        if (newSignal.targetZone.index > 1) {                                                        
                                                            newSignal.targetZone.startPrice = null
                                                            newSignal.targetZone.startPriceTxt = '????'
                                                        }
    
                                                        newSignal.potentialGains = '????%'
                                                    }
                                                } else {
                                                    if (isNotNull(user) && !user.isPaid) {
                                                        let quoteSymbol = payload.symbol.endsWith('USDT') ? 'USDT' : 'BTC'
                                                        newSignal.symbol = `????${quoteSymbol}`
    
                                                        newSignal.entryStartPrice = '????'
                                                        newSignal.entryEndPrice = '????'
    
                                                        if (newSignal.targetZone.index > 1) {                                                        
                                                            newSignal.targetZone.startPrice = null
                                                            newSignal.targetZone.startPriceTxt = '????'
                                                        }
    
                                                        newSignal.stopLossPrice = '????'
                                                        newSignal.toSym = ''
                                                    }
                                                }
                                                signals[signalIndex] = newSignal
                                                dispatch(signalActions.updateSignalState(signals))
                                            }
                                        }
                                    }
                                }
                                break
                            }
                        }
                    }
                }

                if (isNotNull(selectedSignal) && selectedSignal.id === payload.id) {
                    selectedSignal = payload
                    onSetNewState(this, {
                        selectedSignal
                    })
                }
            }
        })

        this.futuresTrackerHubConnection.on("close", (payload) => {
            const { signalStateFilter, selectedSignal } = this.state

            if (isNotNull(payload)) {
                const { selectedValue } = signalStateFilter
                let { dispatch } = this.props
                let { signals } = this.props.signals
                let signalIndex = _.findIndex(signals, s => s.id === payload.id)

                if (isNotNull(selectedValue)) {
                    switch (parseInt(selectedValue)) {
                        case stateFilter.FRESH_SIGNALS:
                        case stateFilter.SIGNALS_IN_PROGRESS: {
                            if (signalIndex !== -1) {
                                signals.splice(signalIndex, 1)
                                dispatch(signalActions.updateSignalState(signals))
                            }
                            break
                        }
                        case stateFilter.WATCHLISTED_SIGNALS: {
                            if (payload.isWatchlisted) {
                                if (signalIndex !== -1) {
                                    signals[signalIndex] = payload
                                } else {
                                    signals.unshift(payload)
                                }
    
                                dispatch(signalActions.updateSignalState(signals))
                            }

                            break
                        }
                        default: {
                            if (signalIndex !== -1) {
                                signals[signalIndex] = payload
                            } else {
                                signals.unshift(payload)
                            }

                            dispatch(signalActions.updateSignalState(signals))
                            break
                        }
                    }
                }

                if (isNotNull(selectedSignal) && selectedSignal.id === payload.id) {
                    signals[signalIndex] = payload
                    dispatch(signalActions.updateSignalState(signals))
                }
            }
        })

        // start signal tracker connection
        await this.startFuturesTrackerConnection()
    }

    async startFuturesTrackerConnection() {
        if (this.mounted) {
            try {
                const { user } = this.props.authentication
                await this.futuresTrackerHubConnection
                    .start()
                    .catch((err) => console.log(err))

                if (isNotNull(this.futuresTrackerHubConnection) &&
                    isNotNull(this.futuresTrackerHubConnection.state) &&
                    this.futuresTrackerHubConnection.state === signalR.HubConnectionState.Connected) {
                    this.futuresTrackerHubConnection.invoke("GetConnectionId", user.userName)
                        // .then(function (connectionId) {
                        //     console.log('connection id from futures signal tracker: ', connectionId)
                        // })
                        .catch((err) => console.error(err))
                }
            } catch (err) {
                if (isNotNull(this.futuresTrackerHubConnection) &&
                    isNotNull(this.futuresTrackerHubConnection.state) &&
                    this.futuresTrackerHubConnection.state === signalR.HubConnectionState.Disconnected) {
                    // restart the connection
                    setTimeout(async () =>
                        await this.startFuturesTrackerConnection(), 3000)
                }
            }
        }
    }

    handleViewSignalNotification = () => {
        const { history, dispatch } = this.props
        let val = queryString.parse(window.location.search, {
            parseBooleans: true,
            parseNumbers: true
        })
        if (isNotNull(val.actn)) {
            switch (val.actn) {
                default: {
                    if (isNotNull(val.sId)) {
                        onSetNewState(this, {
                            selectedSignal: null,
                            viewNotificationDetails: true
                        }, () => {                            
                            dispatch(signalDetailsActions.getSignalDetails(val.sId))
                            history.push('/signals')
                        })
                    }
                    break
                }
                case 'mc': {
                    history.push('/signals')
                    const { onReviewEarlyClosedItem } = this.props
                    onReviewEarlyClosedItem({
                        id: val.id,
                        type: val.type,
                        actorId: val.actorId,
                        symbol: val.symbol,
                        message: val.message,
                        dateCreated: val.dateCreated,
                        isDeleted: val.isDeleted,
                        isRead: val.isRead
                    })
                    break
                }
                case 'tg': {
                    history.push('/signals')
                    const { onReviewAutoEarlyClosedItem } = this.props
                    onReviewAutoEarlyClosedItem({
                        id: val.id,
                        type: val.type,
                        actorId: val.actorId,
                        symbol: val.symbol,
                        message: val.message,
                        dateCreated: val.dateCreated,
                        isDeleted: val.isDeleted,
                        isRead: val.isRead
                    })
                    break
                }
                case 'tr': {
                    history.push('/signals')
                    const { onReviewOrderResultItem } = this.props
                    onReviewOrderResultItem({
                        id: val.id,
                        type: val.type,
                        actorId: val.actorId,
                        symbol: val.symbol,
                        message: val.message,
                        dateCreated: val.dateCreated,
                        isDeleted: val.isDeleted,
                        isRead: val.isRead
                    })
                    break
                }
            }
        }
    }

    onFollowSignalHandler = (signalId) => {
        const { dispatch } = this.props
        dispatch(signalActions.followSignal(signalId))

        const { signalStateFilter } = this.state
        let { signals } = this.props.signals
        if (isNotNull(signalStateFilter) && isNotNull(signals)) {
            const { selectedValue } = signalStateFilter
            if (parseInt(selectedValue) === 4) {
                let signalIndex = _.findIndex(signals, s => s.id === signalId)
                if (signalIndex !== -1) {
                    signals.splice(signalIndex, 1)
                    dispatch(signalActions.updateSignalState(signals))
                }
            }
        }
    }

    // onSubmitTradeOrderWithLeverageHandler = (symbol, value, orderDetails) => {
    //     onSetNewState(this, {
    //         isSendingOrder: true,
    //         orderDetails
    //     })

    //     const { dispatch } = this.props
    //     dispatch(tradeOrderActions.createWithLeverage(symbol, value, orderDetails))
    // }

    onSubmitTradeOrderHandler = (orderDetails) => {
        onSetNewState(this, {
            isSendingOrder: true,
            orderDetails
        })

        const { dispatch } = this.props
        dispatch(tradeOrderActions.create(orderDetails))
    }

    onShowTradeSummaryHandler = (orderDetails) => {
        onSetNewState(this, {
            isSendingOrder: true,
            tradeSummaryOnly: true,
            orderDetails
        })
    }

    onClearTradeOrderStateHandler = () => {
        const { dispatch } = this.props
        dispatch(tradeOrderActions.clear())
    }

    onCheckListsHandler = (isDispalyError) => {
        const { dispatch } = this.props

        if (isDispalyError === true) {
            const { sidebar } = this.props.showSidebar
            if (window.innerWidth > 766 && window.innerWidth < 1025) {
                jQuery(".sidebar-size").addClass("show")
                jQuery("#sidebar-opt").addClass("show")
                jQuery("#sidebar-opt-prof").addClass("show")
                jQuery(".fsp").addClass("show")
                jQuery(".to-hide-items").addClass("show")
                jQuery(".tooltip").addClass("showToolTip")
            } else if (window.innerWidth > 1199) {
                if (!sidebar)
                    dispatch(sidebarActions.showSidebar(true))
            }
            dispatch(checkListActions.messageUpdate(true))
        } else {
            if (isNotNull(this.props.checkList)) {
                const { item, isLoading } = this.props.checkList
                if (!isLoading &&
                    isNotNull(item) &&
                    isNotNull(item.data) &&
                    isNotNull(item.data.isStepsCompleted) &&
                    !item.data.isStepsCompleted)
                    dispatch(checkListActions.getCheckLists())
            }
        }
    }

    onClearSignalHandler = () => {
        const { dispatch } = this.props
        dispatch(signalActions.clear())
    }

    onGetSignalsHandler = () => {
        const { dispatch } = this.props
        onSetNewState(this, { hasNewSignalSetLoaded: false, page: 1, currentSet: 12, hasNoNewSignals: false })
        dispatch(signalActions.clearSignals())
        dispatch(signalActions.getSignals(
            this.state.signalStateFilter.selectedValue,
            this.state.signalExchangeFilter.selectedValue,
            this.state.tradeResultFilter.selectedValues,
            1))
    }

    render() {
        const { 
            signalStateFilter, 
            signalExchangeFilter, 
            tradeResultFilter, 
            hasSignalSelection, 
            selectedSignal,
            selectedFeed, followedSignals, enableTradeForm, windowWidth,
            showSubscribeNow, isSendingOrder, orderDetails,
            hasNewSignalSetLoaded,
            tradeSummaryOnly } = this.state
        const {
            tradeOrder, tradeOrderLeverage, tradings, signals,
            onCreateQuickTradeHandler, onViewAutoTradeErrorMessageHandler,
            checkListItems, checkList, userTheme, authentication, onboardingUser } = this.props
        const { user } = authentication
        const { settings } = tradings

        return (
            <HomeView
                self={this}
                user={user} 
                onboardingUser={onboardingUser}
                userTheme={userTheme}
                settings={settings}
                signalStateFilter={signalStateFilter}
                signalExchangeFilter={signalExchangeFilter} 
                tradeResultFilter={tradeResultFilter} 
                signals={signals}
                followedSignals={followedSignals.data}
                hasSignalSelection={hasSignalSelection}
                selectedFeed={selectedFeed}
                selectedSignal={selectedSignal}
                enableTradeForm={enableTradeForm}
                isSendingOrder={isSendingOrder}
                tradeOrder={tradeOrder}
                tradeOrderLeverage={tradeOrderLeverage}
                orderDetails={orderDetails}
                windowWidth={windowWidth}
                hasNewSignalSetLoaded={hasNewSignalSetLoaded}
                onFollowSignalHandler={this.onFollowSignalHandler}
                onCreateQuickTradeHandler={onCreateQuickTradeHandler}
                showSubscribeNow={showSubscribeNow}
                onViewAutoTradeErrorMessageHandler={onViewAutoTradeErrorMessageHandler}
                tradeSummaryOnly={tradeSummaryOnly}
                eventHandlers={{
                    onSubmitTradeOrderHandler: this.onSubmitTradeOrderHandler.bind(this),
                    onShowTradeSummaryHandler: this.onShowTradeSummaryHandler.bind(this),
                    onClearTradeOrderStateHandler: this.onClearTradeOrderStateHandler.bind(this)
                }}
                checkListItems={checkListItems}
                checkList={checkList}
                onCheckListsHandler={this.onCheckListsHandler}
                onClearSignalHandler={this.onClearSignalHandler}
                onGetSignalsHandler={this.onGetSignalsHandler}
            />
        )
    }
}

function mapStateToProps(state) {
    const { authentication, signalStates, signals, signalsNew,
        signalDetails, followedSignals, tradeOrder, tradeOrderLeverage,
        tradings, checkListItems, checkList, showSidebar,
        userTheme, futureWallet, onboardingUser } = state

    return {
        authentication,
        signalStates,
        signals,
        signalsNew,
        signalDetails,
        followedSignals,
        tradeOrder,
        tradeOrderLeverage,
        tradings,
        checkListItems,
        checkList,
        showSidebar,
        userTheme,
        futureWallet,
        onboardingUser
    }
}

const connectedHomeContainer = connect(mapStateToProps)(HomeContainer)
export { connectedHomeContainer as HomeContainer }
