import React from 'react'
import { OpenSignal } from './OpenSignal'
import { ClosedSignal } from './ClosedSignal'
import { isNotNull, onSetNewState } from '../../../../utils'
import { stateFilter, signalCloseState, signalState } from '../../../../utils/CONSTANTS'

export class SignalsList extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            signalStateFilter: null,
            signals: []
        }
        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true

        this.setState({
            signalStateFilter: this.props.filter
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.signalStateFilter !== this.state.signalStateFilter) {
            this.setState({
                signalStateFilter: this.props.filter
            })
        }

        if (prevProps.data !== this.props.data) {
            const { signals } = this.props.data
            onSetNewState(this, {
                signals
            })
        }
    }

    componentWillUnmount() {
        this.setState(this.baseState)
        this.mounted = false
    }

    renderSignals = () => {
        const { filter, signalExchangeFilter, followedSignals, onSelectHandler, onFollowSignalHandler } = this.props
        const { signals } = this.props.data
        const { selectedValue } = signalExchangeFilter
        let selectedExchangeFilter = ''
        switch (parseInt(selectedValue)) {
            default:
            case 0: {
                selectedExchangeFilter = 'ALL'
                break
            }
            case 1: {
                selectedExchangeFilter = 'SPOT'
                break
            }
            case 2: {
                selectedExchangeFilter = 'FUTURES'
                break
            }
        }

        if (isNotNull(signals) && signals.length > 0 && selectedExchangeFilter !== '') {
            switch (parseInt(filter)) {
                default:
                case stateFilter.ALL: {
                    return signals.map((value, key) => {
                        if (isNotNull(value)) {
                            // check if the selected exchange filter matches to signals' feed
                            if (selectedExchangeFilter === 'ALL' || selectedExchangeFilter === value.feed) {
                                if (value.closeState === signalCloseState.PENDING) {    
                                    return (
                                        <OpenSignal
                                            user={this.props.user}
                                            onboardingUser={this.props.onboardingUser}
                                            key={`${key}:${value.id}`}
                                            filter={this.props.filter}
                                            data={value}
                                            followedSignals={followedSignals}
                                            onFollowSignalHandler={onFollowSignalHandler}
                                            onSelectHandler={onSelectHandler} />
                                    )
                                }
                                
                                return (
                                    <ClosedSignal
                                        key={`${key}:${value.id}`}
                                        filter={this.props.filter}
                                        data={value}
                                        followedSignals={followedSignals}
                                        onFollowSignalHandler={onFollowSignalHandler}
                                        onSelectHandler={onSelectHandler} />
                                )
                            }
                        }

                        return null
                    })
                }
                case stateFilter.WATCHLISTED_SIGNALS: {
                    return signals.map((value, key) => {
                        if (isNotNull(value)) {
                            // check if the selected exchange filter matches to signals' feed
                            if (selectedExchangeFilter === 'ALL' || selectedExchangeFilter === value.feed) {                                
                                // check if the signal is watchlisted
                                if (value.isWatchlisted) {
                                    if (value.closeState === signalCloseState.PENDING) {    
                                        return (
                                            <OpenSignal
                                                user={this.props.user} 
                                                key={`${key}:${value.id}`}
                                                filter={this.props.filter}
                                                data={value}
                                                followedSignals={followedSignals}
                                                onFollowSignalHandler={onFollowSignalHandler}
                                                onSelectHandler={onSelectHandler} />
                                        )
                                    }
                                    
                                    return (
                                        <ClosedSignal
                                            key={`${key}:${value.id}`}
                                            filter={this.props.filter}
                                            data={value}
                                            followedSignals={followedSignals}
                                            onFollowSignalHandler={onFollowSignalHandler}
                                            onSelectHandler={onSelectHandler} />
                                    )
                                }
                            }
                        }

                        return null
                    })
                }
                case stateFilter.FRESH_SIGNALS:
                case stateFilter.SIGNALS_IN_PROGRESS: {                    
                    return signals.map((value, key) => {
                        if (isNotNull(value)) {
                            // check if the selected exchange filter matches to signals' feed
                            if (selectedExchangeFilter === 'ALL' || selectedExchangeFilter === value.feed) {                     
                                // display signal if signal is not closed yet       
                                if (value.closeState === signalCloseState.PENDING) {
                                    return (
                                        <OpenSignal 
                                            user={this.props.user} 
                                            key={`${key}:${value.id}`}
                                            filter={this.props.filter}
                                            data={value}
                                            followedSignals={followedSignals}
                                            onFollowSignalHandler={onFollowSignalHandler}
                                            onSelectHandler={onSelectHandler} />
                                    )
                                }
                            }
                        }

                        return null
                    })
                }
                case stateFilter.CLOSED_SIGNALS: {
                    return signals.map((value, key) => {
                        if (isNotNull(value)) {
                            // check if the selected exchange filter matches to signals' feed
                            if (selectedExchangeFilter === 'ALL' || selectedExchangeFilter === value.feed) {
                                if (value.signalState === signalState.CLOSED) {
                                    return (
                                        <ClosedSignal
                                            key={`${key}:${value.id}`}
                                            filter={this.props.filter}
                                            data={value}
                                            followedSignals={followedSignals}
                                            onFollowSignalHandler={onFollowSignalHandler}
                                            onSelectHandler={onSelectHandler} />
                                    )
                                }
                            }
                        }

                        return null
                    })
                }
            }
        } else
            return (<></>)
    }

    render() {
        return (<>{this.renderSignals()}</>)
    }
}