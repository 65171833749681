import { notificationActionTypes } from '../CONSTANTS'

export function notificationLists(state = {}, action) {
    switch (action.type) {
        case notificationActionTypes.GET_ALL_NOTIFICATION_LISTS_REQUEST:
            return {
                ...state,
                loader: true
            }
        case notificationActionTypes.GET_ALL_NOTIFICATION_LISTS_SUCCESS:
            return {
                ...state,
                loader: false,
                notification_lists: action.notification_lists,
            }
        case notificationActionTypes.GET_ALL_NOTIFICATION_LISTS_FAILURE:
            return {
                ...state,
                loader: false,
                error: action.error
            }
        case notificationActionTypes.ACTIVE_NOTIFICATION:
            return {
                ...state,
                loader: false,
                active_items: action.data
            }
        case notificationActionTypes.UPDATE_NOTIFICATION:
            return {
                ...state,
                loader: false,
                isUpdated: action.isUpdated
            }
        default:
            return state
    }
}

export function markedNotification(state = {}, action) {
    switch (action.type) {
        case notificationActionTypes.MARKED_NOTIFICATION_AS_READ_REQUEST:
            return {
                ...state,
                loading: true
            }
        case notificationActionTypes.MARKED_NOTIFICATION_AS_READ_SUCCESS:
            return {
                ...state,
                loading: false,
                item: action.response,
            }
        case notificationActionTypes.MARKED_NOTIFICATION_AS_READ_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case notificationActionTypes.ACTIVE_MARKED_ID:
            return {
                ...state,
                loading: false,
                active_id: action.notifId
            }
        case notificationActionTypes.CLEAR_MARKED_NOTIFICATION:
            return {
                ...state,
                loading: true,
                item: null,
                active_id: null
            }
        default:
            return state
    }
}

export function notificationPreviews(state = {}, action) {
    switch (action.type) {
        case notificationActionTypes.GET_NOTIFICATION_PREVIEW_REQUEST:
            return {
                ...state,
                loading: true
            }
        case notificationActionTypes.GET_NOTIFICATION_PREVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                isLastPrice: false,
                item: action.notificationPreview
            }
        case notificationActionTypes.GET_NOTIFICATION_PREVIEW_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case notificationActionTypes.CLEAR_NOTIFICATION_PREVIEW:
            return {
                ...state,
                loading: true,
                isLastPrice: true
            }
        default:
            return state
    }
}

export function notifications(state = {}, action) {
    switch (action.type) {
        case notificationActionTypes.GET_NOTIFICATION_SETTINGS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case notificationActionTypes.GET_NOTIFICATION_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                notification_settings: action.notification_settings,
            }
        case notificationActionTypes.GET_NOTIFICATION_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function saveNotificationTypeChanges(state = {}, action) {
    switch (action.type) {
        case notificationActionTypes.SAVE_NOTIFICATION_TYPE_SETTINGS_REQUEST:
            return {
                ...state,
                loader: true
            }
        case notificationActionTypes.SAVE_NOTIFICATION_TYPE_SETTINGS_SUCCESS:
            return {
                ...state,
                loader: false,
                notification_type_settings: action.response,
            }
        case notificationActionTypes.SAVE_NOTIFICATION_TYPE_SETTINGS_FAILED:
            return {
                ...state,
                loader: false,
                error: action.error
            }
        default:
            return state
    }
}

export function saveNotificationSettings(state = {}, action) {
    switch (action.type) {
        case notificationActionTypes.SAVE_NOTIFICATION_SETTINGS_REQUEST:
            return {
                ...state,
                loader: true
            }
        case notificationActionTypes.SAVE_NOTIFICATION_SETTINGS_SUCCESS:
            return {
                ...state,
                loader: false,
                save_notification_settings: action.response,
            }
        case notificationActionTypes.SAVE_NOTIFICATION_SETTINGS_FAILURE:
            return {
                ...state,
                loader: false,
                error: action.error
            }
        default:
            return state
    }
}
