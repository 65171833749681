import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const ImagineThreeMonthsStyle = styled.section`
    position: relative;
    color: #fff;
    padding: 30px 0;

    .container {
        .text-content {
            text-align: center;

            h2 {
                font-size: 25px;
                margin-bottom: 30px;
            }

            p {
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        padding: 60px 0;

        .container {
            .text-content {
                h2 {
                    font-size: 30px;
                    padding: 0px 100px;
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        background-image: url(/images/subscriptions/astro-2.png);
        background-repeat: no-repeat;
        background-size: 550px;
        background-position: 120% 100%;

        .container {
            .text-content {
                width: 60%;
                text-align: left;
                
                h2 {
                    padding: 0 80px 0 0;
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        background-size: 600px;
        background-position: 110% 100%;

        .container {
            .text-content {
                h2 {
                    font-size: 40px;
                    padding: 0;
                }
            }
        }
    }

    ${mediaQueries.superExtraLargeDevices} {
        background-size: 660px;
        background-position: 93% 100%;

        .container {
            .text-content {
                width: 62%;

                h2 {
                    font-size: 38px;
                    padding: 0 80px 0 0;
                }
            }
        }
    }
`