import React from 'react'
import { SafeAnchor } from 'react-bootstrap'
import { AcademyStyle } from "./style/AcademyStyle"

export class Academy extends React.Component {

    render() {
        
        return (
            <AcademyStyle>
                <ul className='academy-videos-list'>
                    <li className="academy-videos-list-item">
                        <SafeAnchor href='https://www.profitfarmers.com/blog/chapter-2-part-1-the-basics/' target="_blank" className="academy-videos-list-item-link">
                            <img src='../images/academy/pf-basics.png' alt="PF Basics" />
                        </SafeAnchor>
                    </li>

                    <li className="academy-videos-list-item">
                        <SafeAnchor href='https://www.profitfarmers.com/blog/chapter-2-part-3-signals-and-trade-plans/' target="_blank" className="academy-videos-list-item-link">
                            <img src='../images/academy/signals-and-trade-plans.png' alt="Signals and Trade Plan" />
                        </SafeAnchor>
                    </li>

                    <li className="academy-videos-list-item">
                        <SafeAnchor href='https://www.profitfarmers.com/blog/chapter-2-part-6-laddering-entry-prices/' target="_blank" className="academy-videos-list-item-link">
                            <img src='../images/academy/laddering-entry-price.png' alt="Laddering Entry Price" />
                        </SafeAnchor>
                    </li>

                    <li className="academy-videos-list-item">
                        <SafeAnchor href='https://www.profitfarmers.com/blog/chapter-2-part-4-how-do-signals-work-and-what-is-an-entry-zone/' target="_blank" className="academy-videos-list-item-link">
                            <img src='../images/academy/how-signals-work-and-entry-zone.png' alt="How Signals Work and Entry zone" />
                        </SafeAnchor>
                    </li>

                    <li className="academy-videos-list-item">
                        <SafeAnchor href='https://www.profitfarmers.com/blog/chapter-3-part-2-general-hints-and-tips/' target="_blank" className="academy-videos-list-item-link">
                            <img src='../images/academy/general-hints-and-tips.png' alt="General Hints and Tips" />
                        </SafeAnchor>
                    </li>

                    <li className="academy-videos-list-item">
                        <SafeAnchor href='https://www.profitfarmers.com/blog/chapter-3-part-5-tips-for-people-with-less-time-or-trading-skills/' target="_blank" className="academy-videos-list-item-link">
                            <img src='../images/academy/tips-for-less-time-trading-skills.png' alt="Tips for Less Time Trading Skill" />
                        </SafeAnchor>
                    </li>
                </ul>
            </AcademyStyle>
        )
    }
}