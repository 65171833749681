import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const TheFounderStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    padding: 30px 0;
    color: #fff;
    background-color: #162331;
    background-image: url(/images/subscriptions/payment-bg.png);
    background-size: 2400px 632px;
    background-position: 45px 400px;
    background-repeat: no-repeat;

    &:before {
        position: absolute;
        background-color: #162331;
        content: ' ';
        width: 100%;
        height: 60px;
        clip-path: ellipse(82% 100% at 50% 100%);
        top: -60px;
    }

    &:after {
        position: absolute;
        background-color: #162331;
        content: ' ';
        width: 100%;
        height: 175px;
        clip-path: ellipse(58% 55% at 48% 44%);
        bottom: -132px;
        z-index: 1;
    }

    .container {        
        position: relative;

        .gains {
            position: absolute;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            
            &.dogeusdt {
                background-image: url('/images/subscriptions/dogeusdt.png');
                width: 224px;
                height: 82px;
                top: -123px;
                left: calc(50% - 112px);
            }

            &.filusdt {
                background-image: url('/images/subscriptions/filusdt.png');
                width: 224px;
                height: 82px;
                bottom: -123px;
                left: calc(50% - 112px);
                z-index: 2;
            }
        }

        .green-text {
            color: #15BD6F !important;
        }
        
        .gray-text{
            color: #85A1BE;
        }

        .break-line {
            border-top: .5px solid #394F6A;
            width: 80%;
            margin: 40px auto;
        }

        .section-header {
            text-align: center;
            margin-bottom: 50px !important;

            .section-title {
                font-size: 26px;
                font-weight: 600;
                margin-bottom: 15px;

            }

            p {
                color: #85A1BE;
            }

            h3 {
                font-size: 18px;
            }
        }

        .text-with-image {
            position: relative;
            display: flex;
            flex-direction: column;
            margin: auto;
            align-items: center;
            max-width: 100%;
            
            .text-content {
                margin-bottom: 30px;
                text-align: center;

                .quote-text {
                    font-size: 20px;
                    font-weight: 600;
                }
            }

            .img-content {
                position: relative;
                margin-bottom: 30px;
                text-align: center;

                .greet-text-arrow {
                    display: inline-grid;
                    position: absolute;
                    right: 10px;
                    top: 30px;

                    .arrow-svg {
                        width: 70px;
                    }
                }

                img {
                    width: 90%; 
                }

                .founder-name-tag {
                    background: linear-gradient(111deg, #238C7A 0%, #121C27 26%, #12222A 74%, #17634F 100%);
                    border: 2px solid #21BA76;
                    border-radius: 50px;
                    position: absolute;
                    width: 280px;
                    bottom: 38px;
                    left: calc(50% - 140px);
                    padding: 8px 5px;

                    .founder-name {
                        font-weight: 600;
                        font-size: 20px;
                        margin-bottom: unset;
                    }

                    .founder-title {
                        font-size: 12px;
                        margin-bottom: unset;
                    }
                }
            }

            &.img-on-left {

                .text-content {

                    .pro-benefits-list {
                        list-style-type: none;
                        padding-inline-start: 10px;
                        text-align: left;

                        li {
                            position: relative;
                            padding: 10px 5px 8px 39px;
                            color: #85A1BE;
                            font-size: 14px;

                            svg {
                                position: absolute;
                                top: 12px;
                                left: 14px;
                                margin-right: 10px;
                                margin-bottom: -2px;
                                color: #15bd6f;
                                font-size: 18px;
                            }
                        }
                    }
                }

                .img-content {
                    
                    img {
                        width: 95%;
                    }
                }
            }

            &.avg-gain-target {

                .text-content {
                    margin-bottom: 0;

                    p {
                        margin-bottom: 20px;
                        font-size: 15px;

                        &:first-child {
                            margin-bottom: 10px;

                        }
                    }

                    .quote-text {
                        margin-bottom: 20px;
                    }

                    &.quote-bg::before {
                        transform: scaleY(-1);
                    }
                }

                .img-content {
                    margin-bottom: 0;

                    img {
                        width: 100%;
                    }
                    em {
                        font-size: 12px;
                    }
                }

            }

            &.meme-section {
                align-items: start;

                .text-content {
                    p {
                        font-size: 15px;
                    }
                }
            }

            &.founder-last-section {
                .text-content {
                    p {
                        font-size: 15px;
                    }
                }
            }
        }

        .life-changing {
            padding-bottom: 50px;

            .text-content {
                h3 {
                    font-size: 26px;
                    margin-bottom: 25px;
                }

                .gray-text {
                    margin-bottom: 25px;
                
                    span {
                        color: #fff;
                    }
                }
            }

            .img-content {
                text-align: center;
                img {
                    width: 95%;
                }
            }
        }

        .hows-section {
            p {

                &:first-child {
                    font-size: 28px;
                    margin-bottom: 40px;
                }
            }
            
            .hows-list {
                list-style-type: none;
                margin: 0 0 40px 0;
                padding: 0;

                .list-col {
                    padding: 0px 5px 0px 10px;
                    position: relative;

                    ul {
                        list-style-type: none;
                        margin: 0;

                        li {
                            margin-bottom: 15px;

                            img {
                                position: absolute;
                                left: 12px;
                                color: #15bd6f;
                                width: 25px;
                                margin-top: 3px;
                            }
                        }
                    }                    
                }
            }

            .chart-section {
                h3 {
                    font-size: 23px;
                    margin-bottom: 25px;
                }

                .chart-two-col {
                    margin-bottom: 40px;

                    .chart-image {
                        text-align: center;
                        margin-bottom: 15px;

                        img {
                            width: 100%;
                            margin-bottom: 15px;
                        }
                    }
                }

                .chart-one-col {
                    .chart-image {
                        
                        display: block;
                        text-align: center;
                        margin-bottom: 25px;

                        &:last-child {
                            .quote-bg {
                                position: relative;
                                font-size: 20px;
                                line-height: 1.3;
                            }
                        }
               
                        img {
                            width: 100%;
                            margin-bottom: 15px;
                        }
                    }  
                }
            }
        }
    }

    ${mediaQueries.extraSmallDevices} {
        .container {
            .text-with-image {
                .text-content {
                    order: 2;
                }

                .img-content {
                    order: 1;
                }

                &.avg-gain-target {                    
                    .text-content {
                        order: unset;
                    }
                    
                    .img-content {
                        order: unset;
                    }
                }
            }

            .life-changing {
                .text-content {
                    h3 {
                        font-size: 23px;

                        br {
                            display: none;
                        }
                    }
                }  
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        &:before {
            clip-path: ellipse(62% 100% at 50% 100%);
        }

        &:after {
            clip-path: ellipse(57% 55% at 48% 44%);
            bottom: -49px;
            height: 88px;
        }

        .container {
            .section-header {
                .section-title {
                    font-size: 30px;
                    max-width: 590px;
                    margin: 0 auto 25px;
                }

                p {
                    font-size: 18px;
                    margin: 0 auto 25px;
                    max-width: 670px;
                }

                h3 {
                    font-size: 24px;
                }
            }

            .text-with-image {
                flex-direction: row;
                margin-bottom: 20px;

                .text-content {
                    width: 48%;
                    text-align: left;
                }

                .img-content {
                    width: 52%;
                }

                &.img-on-left {
                    .text-content {
                        h3 {
                            font-size: 26px;
                        }
                    }
                }

                &.avg-gain-target {
                    .text-content {
                        &.quote-bg::after {
                            content:'';
                            background-image: url(/images/subscriptions/avg-gain-per-target-arrow.svg);
                            position: absolute;
                            background-repeat: no-repeat;
                            background-size: contain;
                            width: 225px;
                            height: 43px;
                            bottom: 0;
                            left: 100px;
                        }
                    }

                    .img-content {                        
                        img {
                            width: 90%;
                        }
                    }
                }

                &.meme-section {
                    .text-content {
                        position: relative;

                        &::after {
                            content:'';
                            background-image: url(/images/subscriptions/avg-gain-per-target-arrow.svg);
                            position: absolute;
                            background-repeat: no-repeat;
                            background-size: contain;
                            width: 225px;
                            height: 43px;
                            bottom: -25px;
                            right: -25px;
                        }
                    }
                }
            }

            .hows-section {                
                .hows-list {
                    display: flex;
                    
                    .list-col {
                        flex-direction: column;
                        width: 50%;
                    }
                }

                .chart-section {
                    p {
                        margin-bottom: 30px;
                    }

                    .chart-two-col {
                        display: flex;
                        flex-direction: row;

                        .chart-image {
                            width: 50%;
                            padding: 0px 18px;
                        }
                    }

                    .chart-one-col {
                        .chart-image {

                            &:last-child {
                                .quote-bg {
                                    font-size: 30px;

                                    &::before {
                                        content: '';
                                        position: absolute;
                                        background-image: url(/images/subscriptions/quote.svg);
                                        background-size: contain;
                                        background-repeat: no-repeat;
                                        top: 0px;
                                        left: 0px;
                                        width: 50px;
                                        height: 40px;
                                        transform: scaleY(-1);
                                    }
                                }
                            }
                            
                            img {
                                width: 80%;
                                margin-bottom: 30px;
                            }  
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        padding: 40px 0;

        &:before {
            height: 167px;
            top: -75px;
            clip-path: ellipse(69% 100% at 50% 100%);
        }

        &:after {
            height: 94px;
            bottom: -33px;
            clip-path: ellipse(58% 55% at 48% 44%);
        }

        .container {
            .gains {
                &.dogeusdt {
                    width: 324px;
                    height: 98px;
                    top: -156px;
                    left: calc(50% - 162px);
                }
            }

            .section-header {
                width: 960px;
                margin: auto;

                .section-title {
                    font-size: 30px;
                }  

                p {
                    max-width: 100%;
                }
            }

            .text-with-image {
                padding: 0px 20px;

                .text-content {
                    position: relative;
                    padding-left: 44px;
                    padding-right: 10px;
                }

                .quote-bg {
                    &::before {
                        content: '';
                        position: absolute;
                        background-image: url(/images/subscriptions/quote.svg);
                        background-size: contain;
                        background-repeat: no-repeat;
                        top: -15px;
                        left: -20px;
                        width: 50px;
                        height: 40px;
                    }
                }

                .img-content {
                    .greet-text-arrow {
                        right: 0px;
                        top: 35px;

                        em {
                            font-size: 20px;
                            margin-left: 20px;
                        }

                        .arrow-svg {
                            width: 90px;
                        }
                    }

                    .founder-name-tag {
                        right: -18px;
                        left: unset;
                        bottom: 160px;
                    }
                }

                &.img-on-left {
                    .text-content {
                        padding-left: 20px;
                        padding-right: 0px;

                        h3 {
                            margin-left: 24px;
                        }

                        .pro-benefits-list {
                            font-size: 16px;
                        }
                    }
                }

                &.avg-gain-target {
                    margin-bottom: 40px;

                    .text-content {
                        .quote-text {
                            font-size: 27px;
                        }

                        &.quote-bg::after {
                            left: unset;
                            right: 20px;
                            width: 270px;
                            height: 52px;
                            bottom: -30px;
                        }
                    }
                }

                &.meme-section {
                    .text-content {
                        &::after {
                            right: 8px;
                            transform: rotate(10deg);
                        }
                    }
                }

                &.founder-last-section {
                    .quote-bg {
                        &::before {
                            transform: scaleY(-1);
                        }
                    }
                }
            }

            .hows-section {
                margin-bottom: 50px;

                .hows-list {
                    .list-col {
                        ul {
                            li {
                                margin-bottom: 30px; 

                                img {
                                    margin-top: -2px;
                                    width: 28px;
                                }
                            }
                        }
                    }
                }
                
                .chart-section {
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        background-image: url(/images/subscriptions/quote.svg);
                        background-size: contain;
                        background-repeat: no-repeat;
                        top: -15px;
                        left: 0px;
                        width: 50px;
                        height: 40px;
                        transform: scaleY(-1);
                    }

                    h3 {
                        font-size: 28px;
                        margin-bottom: 20px;
                    }

                    .chart-one-col {
                        .chart-image {
                            &:last-child {
                                .quote-bg {
                                    padding: 0 65px;
                                    font-size: 35px;

                                    &::before {
                                        left: 75px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .last-text {
                padding: 0 80px;
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        background-position: -215px 400px;

        .container {
            .gains {
                &.dogeusdt {
                    height: 112px;
                }   
            }

            .text-with-image {
                .text-content {
                    font-size: 18px;
                    padding-left: 80px;
                    padding-right: 20px;

                    p {
                        margin-bottom: 28px;

                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }

                    .quote-text {
                        font-size: 23px;
                    }
                }

                .quote-bg {
                    &::before {
                        width: 67px;
                        height: 54px;
                        top: -23px;
                        left: -10px;
                    }
                }

                .img-content {
                    img {
                        width: 85%;
                    }

                    .greet-text-arrow {
                        right: 28px;
                        top: 55px;

                        .arrow-svg {
                            width: 100px;
                        }
                    }

                    .founder-name-tag {
                        right: -11px;
                    }
                }

                &.img-on-left {
                    .text-content {
                        .pro-benefits-list li {
                           font-size: 16px;
                        }
                    }
                }

                &.avg-gain-target {
                    .text-content {
                        p {
                            font-size: 18px;
                        }

                        .quote-text {
                            font-size: 28px;
                            line-height: 1.3;
                        }

                        &.quote-bg::before {
                            width: 60px;
                            height: 48px;
                            top: -24px;
                            left: 2px;
                        }
                    }

                    .img-content {
                        padding-left: 30px;

                        img {
                            width: 95%;
                        }
                    }
                }

                &.founder-free-users {
                    .img-content {                        
                        img {
                            width: 90%;
                        }
                    }
                }

                &.meme-section {
                    .text-content {
                        &::after {
                            bottom: -50px;
                        }
                    }
                }

                &.founder-last-section {
                    .quote-bg {
                        &::before {
                            top: -25px;
                            left: -65px;
                            transform: scaleY(-1);
                        }
                    }
                }  
            }

            .life-changing {
                .text-content {
                    padding: 0px 150px;
                }
            }

            .hows-section {
                .hows-list {
                    .list-col {
                        ul {
                            padding-left: 45px;
                            padding-right: 75px;
                        }
                    }
                }

                .chart-section {
                    &::before {
                        left: 80px;
                        width: 60px;
                        height: 49px;
                    }

                    .chart-one-col {
                        .chart-image {
                            &:last-child {
                                h3 {
                                 padding: 0 120px;
                                }
                                
                                .quote-bg {
                                    padding: 0 107px;
                                    font-size: 38px;

                                    &::before {
                                        left: 110px;
                                        width: 60px;
                                        height: 49px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.superExtraLargeDevices} {
        background-position: -330px 400px;
    }
`