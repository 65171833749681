import glamorous from 'glamorous'
import * as glamor from 'glamor'

const shake = glamor.css.keyframes({
    '8%,41%': { 
        transform: 'translateX(-10px)'
    },
    '25%, 58%': {
        transform: 'translateX(10px)'
    },
    '75%': {
        transform: 'translateX(-5px)'
    },
    '92%': {
        transform: 'translateX(5px)'
    },
    '0%, 100%': {
        transform: 'translateX(0)'
    }
})

export const VerifyCodeFormWrapper = glamorous.div({
    padding: '20px',

    '& .input-group': {
        maxWidth: '420px',
        border: '2px solid #102328',
        borderRadius: '10px'
    },

    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
    },

    '& input[type=number]': {
        MozAppearance: 'textfield'
    },

    '& .form-control': {
        fontSize: '42px',
        backgroundColor: '#0e151e',
        border: '2px solid #060e0a',
        textAlign: 'center',
        color: '#fff',
        lineHeight: 1,
        boxShadow: 'none',

        '&.invalid-input': {            
            border: '3px solid #8f3b4e !important',
            animation: `${shake} .5s linear`
        },

        '&:disabled': {
            color: '#454e5a'
        }
    }
})