import styled from 'styled-components'

export const ScrollToTopButton = styled.button`
    position: fixed;
    right: 30px;
    bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    background-color: #13b078;
    padding: 10px;
    margin: 12px;
    z-index: 999;
    border-radius: 50%;
    border: none;
    outline: none;
    background-image: none;
    box-shadow: 0 0 0 12px rgb(0 0 0 / 20%);
    border: 3px solid rgba(255,255,255,0.3);

    svg {
        font-size: 20px;
        color: #0b1219;
    }
`