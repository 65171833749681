import styled from 'styled-components'
import { mediaQueries } from '../../constants/mediaQueries'

export const MessageBoardList = styled.ul`
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    
    li.msg-board-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border: 2px solid #18283c;
        box-shadow: 0px 3px 18px #00000061;
        border-radius: 9px;
        padding: 25px;
        margin-bottom: 15px;
        min-height: 140px;

        .message-wrapper {
            width: 100%;
            padding-right: 0;

            .message-title {
                margin-top: 8px;
                margin-bottom: 10px;
                font-size: 18px;
                color: #fff;
                line-height: 1.5;
            }

            .text-wrapper {
                p {
                    font-size: 15px;
                    margin-bottom: 15px;
                    color: #8c9db1;
                    font-weight: 500;
                }

                ul.bullet-list {
                    margin-top: 15px;
                    list-style-type: square;

                    li {
                        font-style: italic;
                        font-size: 15px;
                    }
                }

                ul.no-list-style {
                    margin-top: 15px;
                    list-style-type: none;
                    margin-bottom: 15px;

                    li {
                        font-size: 15px;
                    }
                }
            }

            &.full {
                width: 100%;
            }
        }

        .message-action {
            width: 100%;
            text-align: right;
            margin-top: 13px;
            padding-top: 12px;

            a {
                display: inline-block;
                padding: 10px 16px;
                background-color: #13b078;
                text-decoration: none;
                color: #fff;
                border-radius: 22px;
                transition: all ease .5s;

                &:hover {
                    transform: scale(1.1);
                    border-radius: 24.2px;
                }
            }
        }

        &:nth-child(odd) {
            background-color: #14202e;
        }
    }

    ${mediaQueries.mediumDevices} {
        li.msg-board-item {
            .message-action {
                text-align: left;
            }
        }
    }

    ${mediaQueries.largeDevices} {
        li.msg-board-item {
            flex-direction: row;

            .message-wrapper {
                width: calc(100% - 150px);
                margin-bottom: 0;
                padding-right: 15px;
            }

            .message-action {
                width: 150px
            }
        }
    }
`