import { authServices } from './authService';
import { baseUrl, endpoints } from './CONSTANTS';

const { account } = endpoints

function confirmEmail(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(data)
    };

    return fetch(`${baseUrl}${account.confirmEmail}`, requestOptions)
        .then(handleResponse)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error))

}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text)

        //if the token is expired
        if (response.status === 401)
            authServices.logout()
        else
            return data

    });
}

export const confirmEmailService = {
    confirmEmail
};