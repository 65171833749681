const customErrorCodes = {
    EXISTING_SUBSCRIBER: 'EXISTING_SUBSCRIBER',
    REGISTERED_EMAIL: 'REGISTERED_EMAIL',
    INVALID_USERNAME: 'INVALID_USERNAME',
    INVALID_USERNAME_OR_PASSWORD: 'INVALID_USERNAME_OR_PASSWORD',
    INVALID_CODE: 'INVALID_CODE',
    UNVERIFIED_EMAIL: 'UNVERIFIED_EMAIL',
    INVALID_EXCHANGE_KEYS: 'INVALID_EXCHANGE_KEYS'
}

export {
    customErrorCodes
}