import styled from 'styled-components'

export const RecapSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    padding: 60px 0;
    background-image: url(/images/trial-offer/introsection-bg.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;

    .container {
        color: #fff;

        .em {
            color: #27E38E;
        }

        .section-title {
            font-size: 40px;
            font-weight: 800;
            margin: 0 auto 25px;
            max-width: 620px;
            text-align: center;
            text-transform: uppercase;
        }

        p {
            display: block;
            text-align: center;
            margin: 0 auto 25px;
            max-width: 600px;
            
            &.little-note {
                max-width: 100%;
                font-size: 16px;
            }
        }

        .special-offer {
            list-style-type: none;
            margin: 0 auto 25px;
            padding: 0;
            max-width: 550px;

            li {
                svg {
                    color: #27E38E;
                    margin-right: 10px;
                    font-size: 19px;
                }

                span {
                    font-size: 19px;
                    font-weight: 500;
                }
            }
        }

        .price-tag-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .img-wrapper {
                width: calc(100% - 434px);

                img {
                    width: 95%;
                    margin: 0 auto;
                }
            }

            .text-col {
                width: 434px;

                .original-price {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    line-height: 1;

                    .crashed-out-price {
                        color: #516475;
                        position: relative;
                        font-size: 76px;
                        font-weight: 700;

                        &:before {
                            position: absolute;
                            content: "";
                            left: 0;
                            top: 45%;
                            right: 0;
                            border-top: 3px solid;
                            border-color: #FF3030;
                            -webkit-transform: rotate(-18deg);
                            -moz-transform: rotate(-18deg);
                            -ms-transform: rotate(-18deg);
                            -o-transform: rotate(-18deg);
                            transform: rotate(-18deg);
                        } 
                    }

                    > label {
                        width: 80px;
                        color: #fff;
                        font-size: 24px;
                        font-weight: 600;
                        margin-left: 20px;
                        margin-bottom: 0;
                    }
                }

                .new-price {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;

                    .new-price-value {
                        color: #516475;
                        font-size: 48px;
                        font-weight: 600;
                    }

                    .new-price-desc {
                        color: #fff;
                        margin-left: 10px;
                    }
                }

                h4 {
                    color: #fff;
                    font-size: 21px;
                    font-weight: 400;
                }

                p {
                    color: #fff;
                    font-size: 15px;
                    text-align: left;
                }

                .discounted-price {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    line-height: 1;
                    margin-bottom: 13px;

                    .discounted-price-value {
                        color: #fff;
                        font-size: 64px;
                        font-weight: 600;
                        margin-right: 10px;
                    }
                }

                .impt {
                    background-color: #FE2944;
                    padding: 10px 12px;
                    color: #FFFFFF;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 500;
                }
            }

            &.ret {
                .img-wrapper {
                    width: calc(100% - 392px);
                }

                .text-col {
                    width: 392px;
                }
            }
        }

        .offer-validity-timer {
            ul {
                margin: 30px 0 40px;
                padding: 0;
                list-style-type: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                li {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 0 15px;
                    color: #FFFFFF;
                    line-height: 1;

                    .counter {
                        font-size: 60px;
                        font-weight: 600;
                    }

                    small {
                        font-size: 12px;
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }
`