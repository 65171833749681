import glamorous from 'glamorous'
import { mediaQueries } from '../../constants/mediaQueries'

export const DropdownWrapper = glamorous.div({
    paddingLeft: '30px',

    '& > div': {
        display: 'flex',
        alignItems: 'center',
        
        '& button': {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            position: 'relative',
            background: '#1A2431 0% 0% no-repeat padding-box',
            backgroundColor: '#1A2431',
            border: 'none',
            borderRadius: '6px',
            outline: 'none',
            cursor: 'pointer',
            width: '30px',
            height: '30px',
            boxShadow: '0px 3px 18px #00000061',

            '& svg': {
                color: '#13B078'
            }
        },

        '& .my-profile': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: 'auto',
            maxWidth: '160px',
            marginRight: '10px',

            '& .avatar': {
                width: '36px',
                height: '36px',
                borderRadius: '50%',
                overflow: 'hidden',
                textAlign: 'center',
                marginRight: '12px',

                '& img': {
                    width: '100%'
                }
            },

            '& .label': {
                display: 'flex',
                flexDirection: 'column',

                '& small': {
                    fontSize: '12px'
                },

                '& .my-name': {
                    color: '#fff',
                    fontSize: '14px'
                }
            }
        }
    },

    [mediaQueries.extraSmallDevices]: {
        paddingLeft: '10px',
        
        '& > div': {            
            '& .my-profile': {
                display: 'none'
            }
        }
    }
})