import React from 'react'
import { Carousel, SafeAnchor } from 'react-bootstrap'
import { CaseStudyStyle } from './styles/CaseStudyStyle'

export const CaseStudiesSection = () => {
    return (
        <CaseStudyStyle>
            <div className='container'>
                <Carousel>
                    <Carousel.Item className='bernhard'>
                        <div className='heading'>
                            <img src='/images/offers/profitfarmers-case-study-logo.png' alt='ProfitFarmers Case Study Logo' />
                        </div>

                        <div className='body'>
                            <div className='subject'>
                                <span className='name'>Bernhard Wagner</span>
                                <img src='/images/offers/DE-flag.png' alt='UK Flag' /> <br />
                                <img src='/images/offers/line-break.png' alt='' />
                            </div>

                            <p className='feedback'>
                                Plants $400, Harvest $8,000! How ProfitFarmers enabled a Master Gardener to Farm Crypto Profits without breaking a sweat.
                            </p>
                            
                            <SafeAnchor href='https://www.profitfarmers.com/case-studies/bernhard-wagner/' target="_blank" rel="noreferrer">Read Full Story</SafeAnchor>
                        </div>
                    </Carousel.Item>

                    <Carousel.Item className='jaime'>
                        <div className='heading'>
                            <img src='/images/offers/profitfarmers-case-study-logo.png' alt='ProfitFarmers Case Study Logo' />
                        </div>

                        <div className='body'>
                            <div className='subject'>
                                <span className='name'>Jaime 'Gondola' Oliveira</span>
                                <img src='/images/offers/DE-flag.png' alt='DE Flag' /> <br />
                                <img src='/images/offers/line-break.png' alt='' />
                            </div>

                            <p className='feedback'>
                                How a Software Engineer Used ProfitFarmers to take back his most valuable asset: <i>Time</i>
                            </p>
                            
                            <SafeAnchor href='https://www.profitfarmers.com/case-studies/jaime-oliveira/' target="_blank" rel="noreferrer">Read Full Story</SafeAnchor>
                        </div>
                    </Carousel.Item>

                    <Carousel.Item className='tim'>
                        <div className='heading'>
                            <img src='/images/offers/profitfarmers-case-study-logo.png' alt='ProfitFarmers Case Study Logo' />
                        </div>

                        <div className='body'>
                            <div className='subject'>
                                <span className='name'>Tim Griffith</span>
                                <img src='/images/offers/UK-flag.png' alt='UK Flag' /> <br />
                                <img src='/images/offers/line-break.png' alt='' />
                            </div>

                            <p className='feedback'>
                                How ProfitFarmers Helped Tim grow his trading account from $2,800 to $14,000 in 30 days with 0 experience
                            </p>

                            <SafeAnchor href='https://www.profitfarmers.com/case-studies/tim-griffith/' target="_blank" rel="noreferrer">Read Full Story</SafeAnchor>
                        </div>
                    </Carousel.Item>

                    <Carousel.Item className='hooman'>
                        <div className='heading'>
                            <img src='/images/offers/profitfarmers-case-study-logo.png' alt='ProfitFarmers Case Study Logo' />
                        </div>

                        <div className='body'>
                            <div className='subject'>
                                <span className='name'>Hooman Attaran</span>
                                <img src='/images/offers/DK-flag.png' alt='UK Flag' /> <br />
                                <img src='/images/offers/line-break.png' alt='' />
                            </div>

                            <p className='feedback'>
                                From Stocks to Betting to Pro-Trading:<br />
                                How Hooman Turned $5000 into $14,300
                            </p>
                            
                            <SafeAnchor href='https://www.profitfarmers.com/case-studies/hooman-attaran/' target="_blank" rel="noreferrer">Read Full Story</SafeAnchor>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </div>
        </CaseStudyStyle>
    )
}