import { endpoints, baseUrl } from './CONSTANTS'
import { authServices } from './authService'

const { v3 } = endpoints

function skipOffer() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    try {
        return fetch(`${baseUrl}${v3.system.offers.skip}`, requestOpts)
            .then(handleResponse)
            .then(response => {
                return Promise.resolve(response)
            })
            .catch(error => Promise.reject(error))
    } catch(e) {
        return Promise.reject(e)
    }
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        if (res.status === 401)
            authServices.logout()
        else
            return data
    })
}

export const offerServices = {
    skipOffer
}