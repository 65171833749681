import React, { useState } from 'react'
import { Collapse, Card } from 'react-bootstrap'
import { FaqSectionStyle } from './styles'
import { isNull, isNotNull } from '../../../utils'

export const FaqSection = (props) => {
    const [isForMe, setIsForMe] = useState(false)
    const onToggleIsForMe = () => setIsForMe(!isForMe)
    
    const [isLockedToContract, setIsLockedToContract] = useState(false)
    const onToggleIsLockedToContract = () => setIsLockedToContract(!isLockedToContract)

    const [hasTutorialsToLearn, setHasTutorialsToLearn] = useState(false)
    const onToggleHasTutorialsToLearn = () => setHasTutorialsToLearn(!hasTutorialsToLearn)

    const [isOpenForUsa, setIsOpenForUsa] = useState(false)
    const onToggleIsOpenForUsa = () => setIsOpenForUsa(!isOpenForUsa)

    const [isTrialCostRefundable, setIsTrialCostRefundable] = useState(false)
    const onToggleIsTrialCostRefundable = () => setIsTrialCostRefundable(!isTrialCostRefundable)

    const [doINeedToBinance, setDoINeedBinance] = useState(false)
    const onToggleDoINeedBinance = () => setDoINeedBinance(!doINeedToBinance)

    const [howLong, setHowLong] = useState(false)
    const onToggleHowLong = () => setHowLong(!howLong)

    const [noFutures, setNoFutures] = useState(false)
    const onToggleFutures = () => setNoFutures(!noFutures)

    return(
        <FaqSectionStyle>
            <div className='container'>
                <h2 className='section-title'>Frequently Asked Questions:</h2>

                { isNull(props.withinTrial) && 
                    <Card>
                        <Card.Header onClick={() => onToggleIsForMe()}>
                            <span>Is ProfitFarmers PRO right for me?</span>
                            <span
                                className="toggle-icon"
                                aria-controls="is_pf_for_me"
                                aria-expanded={isForMe}>
                                {
                                    isForMe
                                        ? <small>-</small>
                                        : <small>+</small>
                                }
                            </span>
                        </Card.Header>

                        <Collapse in={isForMe}>
                            <Card.Body>
                                <p>Are you interested in becoming a profitable trade? Do you want to use software to make it easier than ever before? Do you want access to an elite trading team? If yes, then you’re in the right place!</p>
                            </Card.Body>
                        </Collapse>
                    </Card>
                }

                <Card>
                    <Card.Header onClick={() => onToggleIsLockedToContract()}>
                        <span>Am I locked into a contract?</span>
                        <span
                            className="toggle-icon"
                            aria-controls="is_locked_into_a_contract"
                            aria-expanded={isLockedToContract}>
                            {
                                isLockedToContract
                                    ? <small>-</small>
                                    : <small>+</small>
                            }
                        </span>
                    </Card.Header>

                    <Collapse in={isLockedToContract}>
                        <Card.Body>
                            <p>No, we promise that you can easily cancel anytime before the end of your trial within the app or by messaging our support team. If you cancel before your trial period expires you will not be charged for a monthly plan.</p>
                        </Card.Body>
                    </Collapse>
                </Card>

                { isNull(props.withinTrial) && 
                    <>
                        <Card>
                            <Card.Header onClick={() => onToggleHasTutorialsToLearn()}>
                                <span>Do you have tutorials to learn the platform?</span>
                                <span
                                    className="toggle-icon"
                                    aria-controls="has_tutorials_to_learn"
                                    aria-expanded={hasTutorialsToLearn}>
                                    {
                                        hasTutorialsToLearn
                                            ? <small>-</small>
                                            : <small>+</small>
                                    }
                                </span>
                            </Card.Header>

                            <Collapse in={hasTutorialsToLearn}>
                                <Card.Body>
                                    <p>Yes, The Pro Trader 5x Blueprint will give you a crash course in how to use the platform, the features and how to trade safely and profitably.</p>
                                </Card.Body>
                            </Collapse>
                        </Card>

                        <Card>
                            <Card.Header onClick={() => onToggleIsOpenForUsa()}>
                                <span>I’m in the USA, can I use this platform?</span>
                                <span
                                    className="toggle-icon"
                                    aria-controls="is_locked_into_a_contract"
                                    aria-expanded={isOpenForUsa}>
                                    {
                                        isOpenForUsa
                                            ? <small>-</small>
                                            : <small>+</small>
                                    }
                                </span>
                            </Card.Header>

                            <Collapse in={isOpenForUsa}>
                                <Card.Body>
                                    <p>Yes and No. To use all of the copy trading features you will need to connect a Binance.com account. Americans cannot access this platform, so you would need to trade manually by copying the signal data into your USA based exchange. Harder, but possible.</p>
                                </Card.Body>
                            </Collapse>
                        </Card>

                        <Card>
                            <Card.Header onClick={() => onToggleIsTrialCostRefundable()}>
                                <span>Is the trial cost refundable if I don’t like it?</span>
                                <span
                                    className="toggle-icon"
                                    aria-controls="is_locked_into_a_contract"
                                    aria-expanded={isTrialCostRefundable}>
                                    {
                                        isTrialCostRefundable
                                            ? <small>-</small>
                                            : <small>+</small>
                                    }
                                </span>
                            </Card.Header>

                            <Collapse in={isTrialCostRefundable}>
                                <Card.Body>
                                    <p>No, the low price covers the costs of maintaining the platform and services so that you and others can test them out.</p>
                                </Card.Body>
                            </Collapse>
                        </Card>

                        <Card>
                            <Card.Header onClick={() => onToggleDoINeedBinance()}>
                                <span>Do I need a Binance.com Account?</span>
                                <span
                                    className="toggle-icon"
                                    aria-controls="is_locked_into_a_contract"
                                    aria-expanded={doINeedToBinance}>
                                    {
                                        doINeedToBinance
                                            ? <small>-</small>
                                            : <small>+</small>
                                    }
                                </span>
                            </Card.Header>

                            <Collapse in={doINeedToBinance}>
                                <Card.Body>
                                    <p>Yes you do if you want to make use of our copy-trade feature and other automations. Without a Binance.com account you will need to make your trades manually in your chosen exchange. You’ll still be able to use our signals and market analysis, but not the automations.</p>
                                </Card.Body>
                            </Collapse>
                        </Card>
                    </>
                }

                { isNotNull(props.withinTrial) && props.withinTrial && 
                    <>
                        <Card>
                            <Card.Header onClick={() => onToggleIsForMe()}>
                                <span>I'm still fairly new to trading, is this right for me?</span>
                                <span
                                    className="toggle-icon"
                                    aria-controls="new_to_trading"
                                    aria-expanded={isForMe}>
                                    {
                                        isForMe
                                            ? <small>-</small>
                                            : <small>+</small>
                                    }
                                </span>
                            </Card.Header>

                            <Collapse in={isForMe}>
                                <Card.Body>
                                    <p>Absolutely! Many of our PRO's came to ProfitFarmers with zero experience but were still able to make great things happen after they applied the ideas found in the 5x Blueprint.</p>
                                </Card.Body>
                            </Collapse>
                        </Card>

                        <Card>
                            <Card.Header onClick={() => onToggleHowLong()}>
                                <span>For how long is this Promo available?</span>
                                <span
                                    className="toggle-icon"
                                    aria-controls="how_long_is_this_promo_available"
                                    aria-expanded={howLong}>
                                    {
                                        howLong
                                            ? <small>-</small>
                                            : <small>+</small>
                                    }
                                </span>
                            </Card.Header>

                            <Collapse in={howLong}>
                                <Card.Body>
                                    <p>This is a <strong>LAST CHANCE</strong> offer for 5x Blueprint students. This offer will not be possible to obtain once the timer runs out! Act NOW or you will miss out!</p>
                                </Card.Body>
                            </Collapse>
                        </Card>

                        <Card>
                            <Card.Header onClick={() => onToggleFutures()}>
                                <span>I don't have a futures (leverage) account. Can I still use your signals?</span>
                                <span
                                    className="toggle-icon"
                                    aria-controls="can_i_still_use_your_signals"
                                    aria-expanded={noFutures}>
                                    {
                                        noFutures
                                            ? <small>-</small>
                                            : <small>+</small>
                                    }
                                </span>
                            </Card.Header>

                            <Collapse in={noFutures}>
                                <Card.Body>
                                    <p>Yes. Our platform is dynamic and will allow you to trade SPOT instead of futures as long as a matching coin pair exists on the spot market.</p>
                                </Card.Body>
                            </Collapse>
                        </Card>
                    </>
                }
            </div> 
        </FaqSectionStyle>
    )
}