import React from 'react'
import { connect } from 'react-redux'
import { ClosedTradeReviewModal } from './ClosedTradeReviewModal'
import { notificationActions } from '../../../../redux/actions' 
import { isNotNull, isNull, onSetNewState } from '../../../../utils'

class ClosedTradeReviewContainer extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            closedEarlyNotifications: null,
            totalClosedEarly: 0,
            showModal: false,
            data: null
        }

        this.mounted = false
        this.notificationHubFeedConnection = null
    }

    componentDidMount() {
        this.mounted = true

        const { data } = this.state
        if (isNull(data)) {
            onSetNewState(this, {
                data: this.props.data,
                showModal: true
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.mounted) {
            const { data, showModal } = this.state
            if (isNotNull(this.props.data) && isNull(data) && !showModal) {
                onSetNewState(this, {
                    data: this.props.data,
                    showModal: true
                })
            }
        }
    }

    handleCloseReviewModal = (e) => {
        e.preventDefault()
        const { dispatch, eventHandlers } = this.props
        dispatch(notificationActions.getNotificationLists())

        onSetNewState(this, {
            closedEarlyNotifications: null,
            totalClosedEarly: 0,
            data: null,
            showModal: false
        }, () => {
            eventHandlers.onCloseReviewItem()
        })
    }

    render() {
        return (
            <ClosedTradeReviewModal 
                state={this.state} 
                eventHandlers={{
                    onClose: this.handleCloseReviewModal.bind(this)
                }} />
        )
    }
}

function mapStateToProps(state) {
    const { authentication, notificationLists } = state
    return {
        authentication,
        notificationLists
    }
}

const connectedClosedTradeReviewContainer = connect(mapStateToProps)(ClosedTradeReviewContainer)
export { connectedClosedTradeReviewContainer as ClosedTradeReviewContainer }