import styled from 'styled-components'

const getCryptoBg=(coin) => {
    if (typeof coin !== 'undefined' && coin !== null)
        return `../../images/cryptocoins/large/${coin}.png`;
    return '';
};

const getBorderColor = (type) => {
    switch (type) {
        case "profits"://profits
            return '#149158';
        case "loss":
            return "#64162e";
        default:
            return "#24303f";
    }
};

const getLinearBackground = (type) => {
    switch (type) {
        case "profits":
            return 'linear-gradient(180deg,rgb(7 13 20) 20%,#006a39 80%)';
        case "loss":
            return "linear-gradient(180deg,rgb(7 13 20) 20%,#3a091d 80%)";
        default:
            return "linear-gradient(180deg,#283544 0%,#0c121a 76%)";
    }
};

const getColor = (type) => {
    switch (type) {
        case "profits":
            return '#2d8f69';
        case "loss":
            return "#9d1d45";
        default:
            return "#768389";
    }
}

const getBackground = (type) => {
    switch (type) {
        case "profits":
            return '#15bd6f1f';
        case "loss":
            return "#b93f5f2e";
        default:
            return "#0e151d";
    }
}

const getDirectionColor = (type) => {
    switch (type) {
        case "profits"://profits
            return '#16AF6A';
        default:
            return "#263442";
    }
}

const getTextShadow = (type) => {
    switch (type) {
        case "profits"://profits
            return '1px 1px 12px #00ff4e';
        case "loss":
            return "1px 1px 12px #b00031";
        default:
            return "1px 1px 12px #768389";
    }
}

const getBackgroundHoverable = (type) => {
    switch (type) {
        case "profits"://profits
            return '#006a39';
        case "loss":
            return "#3a091d";
        default:
            return "#0c121a";
    }
}

export const SignalStyle = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    float: left;
    height: 100%;
    min-height: 380px;
    width: 380px;
    margin-right: 20px;
    /* background-image: linear-gradient(15deg, #2e3b4e, #324658); */
    background-color: #17212d;
    border-radius: 9px;
    cursor: pointer;
    overflow: hidden;

    .trade-progress,
    .trade-actions {
        padding: 12px 20px;

        @media screen and (min-width: 1200px) and (max-width: 1440px) {
            padding: 10px;
        }

        @media screen and (min-width: 1441px) and (max-width: 1650px) {
            padding: 10px;
        }
    }

    .trade-progress {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: transparent; //#17222F;
        border-top: 2px solid rgba(0,0,0,0.2);

        .trade-status {
            width: calc(100% - 150px);

            span {
                position: relative;
                top: auto;
                display: flex;
                padding: 5px 12px 7px;
                text-align: center;
                border-radius: 2px;
                border-bottom: 3px solid rgba(0,0,0,0.2);
                font-size: 13px;
                min-height: 45px;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                letter-spacing: 2px;
                line-height: 1.3;

                @media screen and (min-width: 767px) and (max-width: 1024px) {
                    font-size: 11px;
                }

                @media screen and (min-width: 1025px) and (max-width: 1440px) {
                    max-width: 140px;
                }

                @media screen and (min-width: 1581px) and (max-width: 1599px) {
                    max-width: 160px;
                }

                @media screen and (min-width: 1600px) and (max-width: 1679px) {
                    max-width: 165px;
                }
            }

            @media screen and (min-width: 767px) and (max-width: 1749px) {
                width: calc(100% - 140px);

                span {
                    font-size: 12px;
                    padding: 5px 15px;
                }
            }
        }

        .trade-progress-value {
            width: calc(100% - 165px);
            padding-left: 10px;

            @media screen and (min-width: 767px) and (max-width: 1749px) {
                width: unset;
            }

            .progress-value {
                text-align: right;
                font-size: 26px;
                margin-bottom: 0;
                line-height: .9;

                @media screen and (min-width: 767px) and (max-width: 1024px) {
                    font-size: 18px;
                }

                @media screen and (min-width: 1200px) and (max-width: 1360px) {
                    font-size: 24px;
                }

                @media screen and (min-width: 1360px) and (max-width: 1440px) {
                    font-size: 22px;
                }

                @media screen and (min-width: 1441px) and (max-width: 1749px) {
                    font-size: 24px;
                }

                span {
                    display: block;
                    //font-family: BinancePlex, Arial, PingFangSC-Regular, 'Microsoft YaHei', sans-serif!important
                }

                small {
                    font-size: 13px;
                    text-transform: uppercase;
                    font-weight: 500;

                    @media screen and (min-width: 767px) and (max-width: 1024px) {
                        font-size: 11px;
                    }
                }
            }
        }
    }

    .trade-actions {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: transparent; //#17212d;
        border-top: 2px solid rgba(0,0,0,0.2);

        .ts {
            font-size: 13px;
            color: #607794;

            span {
                margin-right: 5px;
            }
        }

        .watchlist-actions {
            text-align: right;
            font-size: 13px;
            display: flex;
            justify-content: center;
            align-items: center;

            a {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }

            .isTraded-wrapper {
                width: 21px;
                height: 21px;
                margin-right: 8px;
            }
        }

        .ts,
        .watchlist-actions {
            width: auto;
        }
    }

    .trade-direction {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 15px;
        text-transform: uppercase;
        color: #16191d;
        font-size: 16px;
        background-color: #627186;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top: 1px solid #252f3c;
        font-size: 14px;
        font-weight: 500;
        position: relative;

        .follow-signal {
            transition: background-color .2s ease,transform .2s ease-in-out;
            position: absolute;
            z-index: -1;
            bottom: 0px;
            background: #19212b;
            color: #ced0dd;
            padding: 5px 15px;
            border: 2px solid #2c3849;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            border-top: none;
            box-sizing: border-box;
            margin: 0 auto;
            right: 0;
            left: 0;
            min-width: 40px;
            max-width: 123px;
            text-align: center;
            text-transform: capitalize;
            box-shadow: 0px 3px 3px 2px rgba(0,0,0,0.08);

            svg {
                margin-right: 6px;
            }

            &:hover {
                color: #DCBC12;
            }

            &.active {
                color: #DCBC12;

                &:hover {
                    color: #ced0dd;
                }
            }

        }
    }

    .ribbon {
        padding: 0 0 10px 0;
        position: absolute;
        right: 20px;
        top: 0;
        width: 40px;

        .ribbon-text {
            background-color: #70243B;
            background: linear-gradient(#ad3559 0%,#70243B 100%);
            color: #f7f7f7;
            padding: 0.5em 4px;
            text-align: center;
            text-shadow: -1px 0px 1px rgb(0 0 0 / 50%);
            font-size: 13px;
            font-weight: 600;
            display: block;
            border-right: 2px solid #70243B;
            border-left: 2px solid #70243B;
            box-shadow: 0 2px 13px 4px rgb(0 0 0 / 40%);

            &:after {
                border-left: calc(40px / 2) solid #70243B;
                border-right: calc(40px / 2) solid #70243B;
                border-bottom: 10px solid transparent;
                bottom: 0;
                content: '';
                height: 0;
                left: 0;
                position: absolute;
                width: 0;
            }
        }
    }

    &:hover {
        border-color: #8fb148;
    }

    &.hoverable {
        /* background-image: linear-gradient(15deg, #334256, #36475d); */
        background-color: #01060c;
        border: 2px solid #149158;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);

        .price-ranges {
            background-color: #314154;
        }
    }

    &.non-free {
        background-color: #070d14;
        border: 2px solid #18283c;

        &::before {
            position: absolute;
            content: '';
            display: block;
            top: -100px;
            left: 0;
            width: 100%;
            height: 510px;
            background: transparent radial-gradient(closest-side at 50% 50%, #436995 0%,#23395000 100%) 0% 0% no-repeat padding-box;
            opacity: 0.2;
            z-index: 0;
        }
    }

    &.trade-closed {
        cursor: default;

        .relative {
            cursor: pointer;
            background-image: ${props => getLinearBackground(props.type)};

            .closed-tag {
                background: ${props => getBackground(props.type)};
                width: 100%;
                padding: 4px 15px;
                color: ${props => getColor(props.type)};
                text-align: center;
                text-transform: uppercase;
                text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
                letter-spacing: 1px;

                span {
                    font-size: 15px;
                    font-weight: 700;
                    text-align: center;
                }
            }

            .direction-wrapper {
                .direction {
                    padding: 5px 15px;
                    background-color: ${props => getDirectionColor(props.type)} !important;
                }
            }
        }

        &.hoverable {
            background: ${props => getBackgroundHoverable(props.type)};
            border-radius: 4px;
            border-color: ${props => props.type === 'breakeven' ? "#3d4a54" : "#18283c" };
        }

        .closed-trade {
            .trade-tags {
                .direction {
                    background-color: #263442;
                }
            }
        }

        .trade-progress {
            background-color: transparent;
            border-left: none;
            border-right: none;
            padding-top: 15px;
            position: absolute;
            left: 0;
            right: 0;

            &.profits {
                border-top: 1px solid #25795F;
            }

            &.loss {
                border-top: 1px solid #400a24;
            }

            .trade-progress-value {
                width: 100%;

                .progress-value {
                    text-align: center;
                    font-weight: 700;
                    color: ${props => props.type === 'profits' ? `#00ff8c` : `#ff2f66` } !important;
                    line-height: .9;
                    text-shadow: 1px 1px 12px rgba(0, 0, 0, 0.6);

                    span {
                        text-shadow: ${props => getTextShadow(props.type)} !important;
                    }

                    small {
                        text-shadow: ${props => getTextShadow(props.type)} !important;
                        letter-spacing: 2px;
                    }
                }
            }
        }

        .trade-actions {
            color: #fff;

            &.profits {
                border-top: 2px solid #1eb973;
                background-image: linear-gradient(180deg,rgb(55 153 108) 20%,#006a39 80%);
            }

            &.loss {
                border-top: 2px solid #8d3559;
                background-image: linear-gradient(180deg,rgb(106 30 61) 20%,#3a091d 80%);
            }

            &.breakeven {
                border-top: 2px solid #768389;
                background-image: linear-gradient(180deg,#4a5861 0%,#35414c 76%);
            }

            .ts {
                color: #fff !important;
            }

            .watchlist-actions {
                a {
                    &:last-child {
                        color: #fff !important;
                    }
                }
            }
        }

        .graph {
            padding-top: 20px;
        }
    }

    .slide-in-fwd-bottom {
        -webkit-animation: slide-in-fwd-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-in-fwd-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-fwd-bottom {
        0% {
            -webkit-transform: translateZ(-1400px) translateY(800px);
            transform: translateZ(-1400px) translateY(800px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
            opacity: 1;
        }
    }
    @keyframes slide-in-fwd-bottom {
        0% {
            -webkit-transform: translateZ(-1400px) translateY(800px);
            transform: translateZ(-1400px) translateY(800px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
            opacity: 1;
        }
    }

    .puff-out-center {
        -webkit-animation: puff-out-center 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) both;
        animation: puff-out-center 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) both;
    }

    @-webkit-keyframes puff-out-center {
        0% {
            -webkit-transform: scale(1);
                    transform: scale(1);
            -webkit-filter: blur(0px);
                    filter: blur(0px);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(2);
                    transform: scale(2);
            -webkit-filter: blur(4px);
                    filter: blur(4px);
            opacity: 0;
        }
    }
    @keyframes puff-out-center {
        0% {
            -webkit-transform: scale(1);
                    transform: scale(1);
            -webkit-filter: blur(0px);
                    filter: blur(0px);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(2);
                    transform: scale(2);
            -webkit-filter: blur(4px);
                    filter: blur(4px);
            opacity: 0;
        }
    }

    @media screen and (min-width: 320px) and (max-width: 374px) {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 25px;

        .trade-progress {
            padding: 10px;

            .trade-progress-value {
                width: unset;
            }
        }
    }

    @media screen and (min-width: 375px) and (max-width: 766px) {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 25px;
    }

    @media screen and (min-width: 767px) and (max-width: 1023px) {
        width: 330px;
        margin-right: 0px;
    }

    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        width: 370px;
        margin-right: 0px;
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        width: 348px;
        margin-right: 0px;
    }

    @media screen and (min-width: 1441px) and (max-width: 1499px) {
        width: 352px;
        margin-right: 0px;
    }

    @media screen and (min-width: 1500px) and (max-width: 1551px) {
        width: 316px;
        margin-right: 0px;
    }

    @media screen and (min-width: 1552px) and (max-width: 1679px) {
        width: 332px;
        margin-right: 0px;
    }

    @media screen and (min-width: 1680px) and (max-width: 1749px) {
        width: 340px;
        margin-right: 0px;
    }

    @media only screen and (min-width: 1750px) {
        width: 380px;
        margin-right: 0px;
    }
`

export const SignalOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    opacity: .05;
    background-position: 210px -18px;
    overflow: hidden;
    background-size: 45%;
    background-image: url(${props => getCryptoBg(props.coin)});

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        background-position: 165px -15px;
        background-size: 50%;
    }

    @media screen and (min-width: 1441px) and (max-width: 1749px) {
        background-position: 170px -15px;
        background-size: 50%;
    }
`

export const SignalHeaderContent = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .coin-contents {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    img {
        width: 45px;
        height: 45px;
        border-radius: 100%;
    }
`

export const SignalHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: unset;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: transparent; //#0e1824;
    border-bottom: 1px solid #0a121b;

    .direction-wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .trade-tags {
            margin: 0;
            padding-left: 0;
            display: inline-flex;
            list-style-type: none;

            li {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }

                .direction {
                    background-color: ${ props => props.direction === "Buy" ? '#4a9340' : '#cf5c5c'};
                }

                .leverage {
                    background: rgba(0,0,0,0.3); // #182029;

                    .leverage-value {
                        .lev {
                            color: #ff815c;
                        }
                    }
                }

                .strategy {
                    background-color: #23303e;
                }

                .direction,
                .strategy {
                    letter-spacing: 0px;
                    text-transform: uppercase;
                    padding: 5px 8px;
                    width: auto;
                    color: #fff;
                    text-align: center;
                    font-weight: bold;
                    border-radius: 5px;
                }

                .leverage {
                    letter-spacing: 1px;
                    padding: 5px 8px;
                    width: auto;
                    color: #c8daef;
                    text-align: center;
                    border-radius: 5px;

                    .icon {
                        font-size: 11px;
                        margin-right: 8px;
                    }
                }
            }

            @media screen and (min-width: 360px) and (max-width: 766px) {
                li {
                    font-size: 12px;
                }
            }

            @media screen and (min-width: 767px) and (max-width: 1023px) {
                li {
                    font-size: 12px;
                    margin-top: 4px;
                    margin-right: 5px;
                }
            }

            @media screen and (min-width: 1024px) and (max-width: 1359px) {
                top: -10px;

                li {
                    font-size: 12px;
                    margin-right: 5px;
                }
            }

            @media screen and (min-width: 1360px) and (max-width: 1550px) {
                top: -10px;

                li {
                    font-size: 11px;
                    margin-right: 5px;
                }
            }

            @media screen and (min-width: 1551px) and (max-width: 1680px) {
                top: -10px;

                li {
                    font-size: 13px;
                    margin-right: 5px;
                }
            }

            @media screen and (min-width: 1681px) and (max-width: 3000px) {
                li {
                    font-size: 13px;
                }
            }
        }
    }

    .coinpair-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .coin-contents {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        img {
            width: 45px;
            height: 45px;
            border-radius: 100%;
        }
    }

    .crypto-coin-logo {
        width: 35px;
        height: 35px;
        box-shadow: none;
        background-color: transparent;
        margin-top: 3px;
    }

    .coinpair-info,
    .progress-info {
        .progress-value {
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
        }
    }

    .coinpair-info {
        .coinpair {
            display: block;
            font-size: 23px;
            color: #fff;

            span {
                display: block;
            }

            .last-price {
                display: inline-block;
                width: auto;
                font-size: 12px;
                margin-top: 4px;
                background-color: rgba(0,0,0,0.2); // #1b2632;
                color: #c8daef;
                text-shadow: 0 0 black;
                padding: 5px;
                border-radius: 5px;
                letter-spacing: 0.8px;

                &.strategy {
                    padding: 5px 8px;
                }
            }
        }
    }

    .progress-info {
        .progress-value {
            font-size: 33px;
            font-weight: 800;
            text-align: right;
            text-shadow: 1px 4px 5px rgba(0, 0, 0, 0.4);
            letter-spacing: 2px;

            .duration {
                font-size: 12px;
                text-transform: uppercase;
            }
        }
    }

    &.closed-trade {
        background-color: transparent;
        padding: 20px 15px 0;

        &.profits {
            border-bottom: 1px solid #206552;
        }

        &.loss {
            border-bottom: 1px solid #4a1c32;
        }

        ${SignalHeaderContent} {
            justify-content: space-between;
        }
    }

    @media screen and (min-width: 320px) and (max-width: 359px) {
        padding: 15px 10px;

        .coinpair-info {
            .coinpair {
                font-size: 20px;

                .last-price {
                    font-size: 11px;
                    margin-top: 2px;
                }
            }
        }

        .progress-info {
            .progress-value {
                font-size: 20px;
                letter-spacing: 1.5px;

                .duration {
                    font-size: 10px;
                }
            }
        }

        .direction-wrapper {
            .trade-tags {
                li {
                    margin-right: 5px;
                    font-size: 11px;
                }
            }
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        padding: 15px 10px;

        .coinpair-info {
            .coinpair {
                font-size: 20px;

                .last-price {
                    font-size: 11px;
                    margin-top: 2px;
                }
            }
        }

        .progress-info {
            .progress-value {
                font-size: 20px;
                letter-spacing: 1.5px;

                .duration {
                    font-size: 10px;
                }
            }
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        padding: 15px 10px;

        .coinpair-info {
            .coinpair {
                font-size: 20px;

                .last-price {
                    font-size: 12px;
                    margin-top: 4px;
                }
            }
        }

        .progress-info {
            .progress-value {
                font-size: 23px;
                letter-spacing: 2px;

                .duration {
                    font-size: 9px;
                    margin-top: 4px;
                }
            }
        }
    }

    @media screen and (min-width: 1441px) and (max-width: 1550px) {
        padding: 15px 10px !important;

        .coinpair-info {
            .coinpair {
                font-size: 20px;

                .last-price {
                    font-size: 11px;
                    margin-top: 3px;
                }
            }
        }

        .progress-info {
            .progress-value {
                font-size: 26px;
                letter-spacing: 2px;

                .duration {
                    font-size: 10px;
                    margin-top: 3px;
                }
            }
        }

        .coinpair-wrapper {
            img {
                margin-right: 8px;
            }
        }

        &.closed-trade {
            padding-bottom: 0 !important;
        }
    }

    @media screen and (min-width: 1551px) and (max-width: 1749px) {
        padding: 15px 10px !important;

        .coinpair-info {
            .coinpair {
                font-size: 22px;

                .last-price {
                    font-size: 12px;
                    margin-top: 3px;
                }
            }
        }

        .progress-info {
            .progress-value {
                font-size: 26px;
                letter-spacing: 2px;

                .duration {
                    font-size: 10px;
                    margin-top: 3px;
                }
            }
        }

        .coinpair-wrapper {
            img {
                margin-right: 8px;
            }
        }

        &.closed-trade {
            padding-bottom: 0 !important;
        }
    }
`

export const SignalInstructions = styled.div`
    padding: 20px;
    background-color: #0e1824;
    position: relative;

    hr {
        border: 1px solid #283240;
        margin: 1.2rem 0;
    }

    &.sm {
        width: 100%;
        background: transparent;
        padding: 0 0 25px;
    }

    .trade-tags {
        position: absolute;
        top: -13px;
        margin: 0;
        padding-left: 0;
        display: inline-flex;
        list-style-type: none;

        li {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }

            .direction {
                background-color: ${ props => props.direction === "Buy" ? '#4a9440' : '#cf5c5c'};
            }

            .leverage {
                background: #182029;

                .leverage-value {
                    font-weight: bold;
                }
            }

            .strategy {
                background-color: #23303e;
            }

            .direction,
            .strategy {
                letter-spacing: 2px;
                text-transform: uppercase;
                padding: 5px 10px;
                width: auto;
                border-radius: 15px;
                color: #fff;
                text-align: center;
            }

            .leverage {
                letter-spacing: 1px;
                padding: 5px 15px;
                width: auto;
                border-radius: 15px;
                color: #fff;
                text-align: center;

                .icon {
                    font-size: 11px;
                    margin-right: 8px;
                }
            }
        }

        @media screen and (min-width: 767px) and (max-width: 1023px) {
            li {
                font-size: 12px;
                margin-top: 4px;
                margin-right: 5px;
            }
        }

        @media screen and (min-width: 1024px) and (max-width: 1680px) {
            top: -10px;

            li {
                font-size: 12px;
                margin-right: 5px;
            }
        }

        @media screen and (min-width: 1681px) and (max-width: 3000px) {
            li {
                font-size: 13px;
            }
        }
    }

    .trade-direction {
        letter-spacing: 2px;
        width: 80px;
        text-transform: uppercase;
    }

    .since-entry {
        font-size: 12px;
        position: absolute;
        top: -17px;
        left: 15px;
        background-color: ${ props => props.direction === 'Buy' ? '#cf5c5c' : '#2e63d0'};
        padding: 5px 10px;
        width: auto;
        border-radius: 4px;
        color: #fff;
        text-align: center;
    }

    .price-range {
        display: flex;
        margin-bottom: 10px;
        min-height: 32px;
        align-items: center;

        &.sm {
            align-items: center;

            .price-range-entry {
                display: flex;
                align-items: center;
            }

            .entry-label,
            .target-label {
                font-size: 13px;
                width: 110px;
                margin-right: 5px;
            }

            .last-price-value,
            .entry-value,
            .target-value {
                background: transparent;
                border: none;

                span {
                    font-size: 16px;
                    text-shadow: 0 3px 2px #000;
                }

                @media screen and (min-width: 1200px) and (max-width: 1440px) {
                    span {
                        font-size: 14px;
                    }
                }
            }

            .target-value {
                margin: 0;
                width: 100%;
                padding: 10px 25px;

                span {
                    display: grid;
                    grid-gap: 10px 10px;
                    grid-template-columns: auto auto;
                    text-shadow: 0 3px 2px #000;

                    .target-value {
                        margin: 0;
                        width: 100%;
                    }
                }
            }

            @media screen and (min-width: 1024px) and (max-width: 1025px) {
                .entry-label,
                .target-label {
                    font-size: 11px;
                }

                .last-price-value,
                .entry-value,
                .target-value {
                    span {
                        font-size: 11px;
                    }
                }
            }

            @media screen and (min-width: 1200px) and (max-width: 1440px) {
                margin-bottom: 0;

                .target-value {
                    padding: 5px 25px;
                }
            }
        }

        .entry-label,
        .target-label {
            width: 130px;
            margin-bottom: 0;
            margin-right: 10px;
            text-transform: uppercase;
            font-size: 13px;
            line-height: 24px;
            color: #B5BECB;
            cursor: inherit;
            display: flex;
            align-items: center;

            @media screen and (min-width: 767px) and (max-width: 1440px) {
                font-size: 12px;
            }
        }

        .target-label {
            .target-text {
                margin-right: 5px;

                &.last-target-hit {
                    font-size: 13px;
                    line-height: 1;

                    @media screen and (min-width: 1200px) and (max-width: 1440px) {
                        font-size: 12px;
                    }

                    @media screen and (min-width: 1441px) and (max-width: 1749px) {
                        font-size: 12px;
                        line-height: 1;
                    }
                }

                &.price-closed {
                    @media screen and (min-width: 1750px) and (max-width: 3000px) {
                        font-size: 13px;
                        line-height: 1;
                    }
                }
            }
            .target-index {
                color: #fdd501;
            }
        }

        .last-price-value,
        .entry-value,
        .target-value {
            width: calc(100% - 65px);
            text-align: right;
            background-color:#0e1824;
            border-radius: 5px;
            padding: 3px 5px;
            border: 1px solid #25364A;
            margin: 0 auto;

            span {
                font-size: 16px;
                font-weight: 500;
                // binance font style
                //letter-spacing: 1px;
                //font-weight: 500;
                //font-family: BinancePlex, Arial, PingFangSC-Regular, 'Microsoft YaHei', sans-serif!important
            }

            &.stoploss {
                color: #B93F5F;
                font-weight: 500;
            }

            @media screen and (min-width: 320px) and (max-width: 766px) {
                width: calc(100% - 80px);

                span {
                    font-size: 13px;
                }
            }

            @media screen and (min-width: 767px) and (max-width: 1023px) {
                span {
                    font-size: 13px;
                }
            }

            @media screen and (min-width: 1024px) and (max-width: 1199px) {
                span {
                    font-size: 13px;
                }
            }

            @media screen and (min-width: 1200px) and (max-width: 1360px) {
                span {
                    font-size: 13px;
                }
            }

            @media screen and (min-width: 1361px) and (max-width: 1440px) {
                span {
                    font-size: 13px;
                }
            }

            @media screen and (min-width: 1441px) and (max-width: 1749px) {
                span {
                    font-size: 13px;
                }
            }
        }

        .last-price-value {
            color: #fff;
            min-height: 32px;
        }

        .target-value {
            color: gold;
        }

        .entry-value {
            color: #16B46C;
            padding: 3px 5px;
        }

        &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none;
        }

        .price-length {
            position: absolute;
            font-size: 13px;
            color: #627186;
            font-weight: 800;
            letter-spacing: 2px;
            text-shadow: 1px 4px 5px rgba(0,0,0,0.4);

            &.entry {
                left: 75px;
                bottom: 57px;
            }

            &.target {
                left: 75px;
                bottom: 13px;
            }
        }

        .price-range-icons {
            font-size: 13px;
            width: 20px;
            color: #607794;

            @media screen and (min-width: 1441px) and (max-width: 1749px) {
                width: 16px;
            }
        }
    }

    &.closed-trade {
        border-color: ${props => props.type === 'profits' ? `#2c5645` : `#59435a`};
        background-color: transparent;
        padding: 20px 12px 15px;

        .trade-tags {
            li {
                .direction {
                    background-color: #263442 !important;
                }

                @media screen and (min-width: 767px) and (max-width: 1023px) {
                    font-size: 12px;
                    margin-top: 4px;
                }

                @media screen and (min-width: 1024px) and (max-width: 1680px) {
                    font-size: 13px;
                }

                @media screen and (min-width: 1750px) and (max-width: 3000px) {
                    font-size: 13px;
                }
            }
        }

        .closed-tag {
            position: absolute;
            right: 1px;
            width: 137px;
            background-color: #202C39;
            top: -40px;
            padding: 3px 15px;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            color: ${props => props.type === 'profits' ? `#15BD6F` : `#B93F5F` };
            font-weight: 600;

            span {
                font-size: 14px;
            }
        }

        .price-range {
            .entry-label,
            .target-label {
                width: 160px;
                font-size: 13px;
                color: #fff;
                text-transform: uppercase;

                @media screen and (min-width: 767px) and (max-width: 1199px) {
                    font-size: 12px;
                }

                @media screen and (min-width: 1200px) and (max-width: 1440px) {
                    font-size: 13px;
                }

                @media screen and (min-width: 1441px) and (max-width: 1749px) {
                    font-size: 12px;
                }
            }

            .last-price-value,
            .entry-value,
            .target-value {
                color: #fff;
                background-color: ${props => props.type === 'profits' ? `transparent` : `transparent` };
                border: 2px solid ${props => getBorderColor(props.type)};
                text-align: center;

                span {
                    font-size: 14px;
                }

                @media screen and (min-width: 320px) and (max-width: 766px) {
                    width: calc(100% - 70px);

                    span {
                        font-size: 13px;
                    }
                }

                @media screen and (min-width: 767px) and (max-width: 1023px) {
                    width: calc(100% - 70px);

                    span {
                        font-size: 13px;
                    }
                }

                @media screen and (min-width: 1024px) and (max-width: 1199px) {
                    span {
                        font-size: 14px;
                    }
                }

                @media screen and (min-width: 1200px) and (max-width: 1360px) {
                    span {
                        font-size: 14px;
                    }
                }

                @media screen and (min-width: 1361px) and (max-width: 1440px) {
                    span {
                        font-size: 14px;
                    }
                }

                @media screen and (min-width: 1441px) and (max-width: 1550px) {
                    span {
                        font-size: 13px;
                    }
                }

                @media screen and (min-width: 1551px) and (max-width: 1749px) {
                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 320px) and (max-width: 359px) {
        padding: 20px 10px;

        &.closed-trade {
            padding: 15px 10px 15px;

            .closed-tag {
                span {
                    font-size: 12px;
                }
            }
        }

        .since-entry {
            font-size: 11px;
            top: -15px;
            left: 10px;
            padding: 5px 10px;
            width: auto;
        }

        .price-range {
            padding-bottom: 5px;
            margin-bottom: 5px;

            .entry-label,
            .target-label {
                font-size: 12px;
                margin-right: 0px;
                line-height: 1;
            }

            .last-price-value,
            .entry-value,
            .target-value {
                padding: 0px 5px 2px;
                width: calc(100% - 40px);

                span {
                    font-size: 12px;
                }
            }
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        padding: 15px 8px;

        &.sm {
            padding: 0 0 15px;
        }

        &.closed-trade {
            padding: 15px 8px 15px;

            .closed-tag {
                span {
                    font-size: 13px;
                }
            }
        }

        .since-entry {
            font-size: 11px;
            top: -15px;
            left: 10px;
            padding: 5px 10px;
            width: auto;
        }

        .price-range {
            padding-bottom: 5px;
            margin-bottom: 5px;

            .entry-label,
            .target-label {
                font-size: 13px;
                margin-right: 0px;
                line-height: 1;
            }

            .last-price-value,
            .entry-value,
            .target-value {
                width: calc(100% - 85px);

                span {
                    font-size: 14px;
                }
            }
        }
    }

    @media screen and (min-width: 1441px) and (max-width: 1550px) {
        padding: 15px 8px;

        &.sm {
            padding: 0 0 5px;
        }

        &.closed-trade {
            padding: 15px 6px 10px;

            .closed-tag {
                span {
                    font-size: 12px;
                }
            }

            .price-range {
                .last-price-value,
                .entry-value,
                .target-value {
                    width: calc(100% - 35px);
                }
            }
        }

        .since-entry {
            font-size: 12px;
            top: -16px;
            left: 9px;
            padding: 5px 10px;
            width: auto;
        }

        .price-range {
            padding-bottom: 5px;
            margin-bottom: 5px;

            .entry-label,
            .target-label {
                font-size: 12px !important;
                margin-right: 0px;
                line-height: 1;
            }

            .last-price-value,
            .entry-value,
            .target-value {
                width: calc(100% - 35px);
                padding: 3px;

                span {
                    font-size: 14px !important;
                }
            }
        }
    }

    @media screen and (min-width: 1551px) and (max-width: 1749px) {
        padding: 15px 10px;

        &.sm {
            padding: 0 0 5px;
        }

        &.closed-trade {
            padding: 15px 10px 10px;

            .closed-tag {
                span {
                    font-size: 12px;
                }
            }

            .price-range {
                .last-price-value,
                .entry-value,
                .target-value {
                    width: calc(100% - 45px);
                }
            }
        }

        .since-entry {
            font-size: 12px;
            top: -16px;
            left: 9px;
            padding: 5px 10px;
            width: auto;
        }

        .price-range {
            padding-bottom: 5px;
            margin-bottom: 5px;

            .entry-label,
            .target-label {
                font-size: 13px !important;
                margin-right: 0px;
                line-height: 1;
            }

            .last-price-value,
            .entry-value,
            .target-value {
                width: calc(100% - 60px);

                span {
                    font-size: 14px !important;
                }
            }
        }
    }
`

export const FollowSignalBtn = styled.a`
    color: ${props => props.isWatchlisted ? '#ffc107 !important' : '#57697f !important' }
`