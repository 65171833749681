import { isNotNull } from './index'

const isValid = (password) => {
    var regex = /(?=^.{6,12}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/;
    return regex.test(password);
}

const isGreaterThanMinLength = (password) => (typeof password != 'undefined') && (password.length >= 6);

const hasLowercase = (password) => {
    if (!isNotNull(password))
        return false;

    var regex = /[a-z]/
    return regex.test(password)
}

const hasUppercase = (password) => {
    if (!isNotNull(password))
        return false

    var regex = /[A-Z]/
    return regex.test(password)
}

const hasNumeric = (password) => {
    if (!isNotNull(password))
        return false

    var regex = /[0-9]/
    return regex.test(password)
}

const hasSpecialChar = (password) => {
    if (!isNotNull(password))
        return false

    var regex = /[^A-Za-z 0-9]/
    return regex.test(password)
}

const isWithinMinAndMaxLength = (password) => {
    if (!isNotNull(password))
        return false

    var regex = /.{6,12}/
    return regex.test(password)
}

export const passwordValidator = {
    isValid,
    isGreaterThanMinLength,
    hasLowercase,
    hasUppercase,
    hasNumeric,
    hasSpecialChar,
    isWithinMinAndMaxLength
}
