import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { AlertBlock } from '../../styles'
import { isNull, isNotNull, onSetNewState } from '../../utils'

class BlackFridayTrial extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isPremiumAccess: null,
            hasTrialHistory: null
        }

        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true

        const { isPremiumAccess } = this.state
        const { authentication } = this.props
        const { user } = authentication
        if (isNull(isPremiumAccess) && 
            isNotNull(user)) {
                onSetNewState(this, {
                    isPremiumAccess: user.isPaid,
                    hasTrialHistory: user.hasTrialHistory
                })
            }
    }

    componentDidUpdate() {
        if (this.mounted) {
            const { isPremiumAccess } = this.state
            const { authentication } = this.props
            const { user } = authentication

            if (isNull(isPremiumAccess) && 
                isNotNull(user)) {
                onSetNewState(this, {
                    isPremiumAccess: user.isPaid,
                    hasTrialHistory: user.hasTrialHistory
                })
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    render() {
        const { isPremiumAccess, hasTrialHistory } = this.state
        if (isNotNull(isPremiumAccess) && 
            isNotNull(hasTrialHistory) && 
            !isPremiumAccess && 
            !hasTrialHistory) {
            return (
                <AlertBlock className='black-friday'>
                    <p>ALERT: Black Friday 21 Day Pro Trial  + Pro Trader 5x Blueprint Training - Just $21- Capacity is Limited and Seats are Filling up Fast - Act Now!</p>
                    <NavLink to='/subscription-plans/pro/subscribe'>Learn More</NavLink>
                </AlertBlock>
            )
        }

        return (<></>)
    }
}

function mapStateToProps(state) {
    const { authentication } = state
    return { 
        authentication
    }
}

const connectedBlackFridayTrial = connect(mapStateToProps)(BlackFridayTrial)
export { connectedBlackFridayTrial as BlackFridayTrial }