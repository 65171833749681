import glamorous from 'glamorous'

export const SymbolIcon = glamorous.img({
    width: '32px',
    height: '32px',
    marginRight: '15px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '99%',
    borderRadius: '50%',
    backgroundColor: '#283544',
    boxShadow: '0 2px 5px 1px rgba(0,0,0,0.3)',
    pointerEvents: 'none'
})