import React from 'react'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGem } from '@fortawesome/free-solid-svg-icons'
import { PrimaryButton } from '../../styles'
import { 
    isNull, 
    isNotNull,
    onSetNewState } from '../../utils'

export class OneTimeOfferCheckout extends React.Component {
    constructor(props) {
        super(props) 

        this.state = {
            isLoading: true,
            text: 'Upgrade to ProfitFarmers PRO',
            subscriptionPlanId: null,
            subscriptionRef: null,
            subscriptionPlanAmount: 499,
            userId: null,
            customerId: null,
            leadId: null,
            isLead: null,
            userName: null,
            firstName: null,
            lastName: null
        }

        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        const { userInfo, text } = this.props
        const { userId } = this.state

        if (isNotNull(text)) {
            onSetNewState(this, {
                text
            })
        }

        if (isNull(userId) && isNotNull(userInfo)) {
            onSetNewState(this, {
                userId: userInfo.id,
                customerId: userInfo.customerId,
                leadId: userInfo.leadId,
                isLead: userInfo.isLead,
                userName: userInfo.userName,
                firstName: userInfo.firstName,
                lastName: userInfo.lastName
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.mounted) {
            const { userInfo, dataList } = this.props
            const { isLoading, userId, subscriptionPlanId } = this.state

            if (isNull(subscriptionPlanId) && 
                isNotNull(dataList)) {
                let subscriptionPlan = _.pick(_.find(dataList, s => {
                    return s.normalizedName.indexOf('ONETIME499OFFER') >= 0
                }), 'id', 'refKey') 
                
                if (isNotNull(subscriptionPlan)) {
                    onSetNewState(this, {
                        subscriptionPlanId: subscriptionPlan.id,
                        subscriptionRef: subscriptionPlan.refKey
                    })
                }
            }

            if (isNull(userId) && 
                isNotNull(userInfo)) {
                onSetNewState(this, {
                    userId: userInfo.id,
                    customerId: userInfo.customerId,
                    leadId: userInfo.leadId,
                    isLead: userInfo.isLead,
                    userName: userInfo.userName,
                    firstName: userInfo.firstName,
                    lastName: userInfo.lastName
                })
            }

            if (isLoading && isNotNull(subscriptionPlanId) && isNotNull(userId)) {
                onSetNewState(this, {
                    isLoading: false
                })
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
        this.setState(this.baseState)
    }

    handlePayment = (e) => {
        e.preventDefault()
        
        const { ...data } = this.state
        if (isNotNull(data.userId)) {
            // create new payment session
            const newPaymentSession = {
                reset: true,
                products: [{
                    path: data.subscriptionRef,
                    quantity: 1
                }],
                paymentContact: {
                    email: data.userName,
                    firstName: data.firstName,
                    lastName: data.lastName
                },
                tags: {
                    userId: data.userId,
                    customerId: data.customerId,
                    leadId: data.leadId,
                    isLead: data.isLead,
                    sPlanId: data.subscriptionPlanId
                },
                checkout: true
            }
            window.fastspring.builder.push(newPaymentSession)
        }
    }

    renderLoader = () => (
        <>
            <div style={{ width: '100%', height: '33px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0 }}>
                <div className="pf-spinner xs" style={{ marginTop: '-48px', marginRight: '45px' }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        </> 
    )

    render() {
        const { isLoading, text } = this.state

        return (
            <PrimaryButton 
                customBg="gradient"
                type="button"
                onClick={(e) => this.handlePayment(e)}>
                    { isLoading && this.renderLoader() }
                    { !isLoading && 
                        <>
                            <FontAwesomeIcon icon={faGem} />
                            <span>{text}</span>
                        </>
                    }
            </PrimaryButton>
        )
    }
}