import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Popover, Overlay } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faWallet, faSignOutAlt, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { DropdownWrapper } from './DropdownStyle'
import { AccountMenu } from '../../styles'
import { authActions, onboardingActions, userActions, tradingActions } from '../../redux/actions'
import { onboardingState } from '../../constants/onboardingState'
import { isNull, isNotNull, onSetNewState } from '../../utils'

class LoggedInUser extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isInit: false,
            isLoadingSettings: null,
            name: null,
            avatarUrl: null,
            show: false,
            targetEl: null
        }

        this.baseState = this.state
        this.mounted = false
        this.element = React.createRef()
    }

    componentDidMount() {
        this.mounted = true

        const { ...state } = this.state
        const { 
            dispatch,
            onboardingUser, 
            puid } = this.props
        
        if (isNull(onboardingUser.status))
            dispatch(onboardingActions.getOnboardingState())
            
        //if (isNull(authentication.user))
        dispatch(userActions.getMe())
            
        if (isNull(puid.loading))
            dispatch(userActions.getId())
            
        if (!state.isInit && isNotNull(puid.data)) {
            const { data } = puid
            if (isNotNull(data.FirstName)) {
                let name = `${data.FirstName} ${isNotNull(data.LastName) ? data.LastName.charAt(0) : ''}`
                onSetNewState(this, {
                    name,
                    avatarUrl: data.AvatarUrl,
                    isInit: true
                })
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { isInit, isLoadingSettings } = this.state
            const { dispatch, authentication, onboardingUser, puid, tradings } = this.props
            const { user } = authentication
            
            if (!isInit && isNotNull(onboardingUser) && prevProps.onboardingUser !== onboardingUser) {
                const { status } = onboardingUser
                if (isNotNull(status)) {
                    dispatch(userActions.getId())
                    // const { hasSkipped, state } = status
                    // if (parseInt(state) !== onboardingState.COMPLETE && !hasSkipped) {
                    //     window.location.href = '/'
                    // } else {
                    //     dispatch(userActions.getId())
                    // }

                    onSetNewState(this, { isInit: true })
                }
            }
            
            if (isNotNull(puid) && !puid.loading) {
                const { data } = puid
                if (isNotNull(data)) {
                    if (isNull(this.state.name) && isNotNull(data.FirstName)) {
                        let name = `${data.FirstName} ${isNotNull(data.LastName) ? data.LastName.charAt(0) : ''}`
                        onSetNewState(this, {
                            name
                        })
                    }

                    if (isNull(this.state.avatarUrl) && isNotNull(data.AvatarUrl)) {
                        onSetNewState(this, {
                            avatarUrl: data.AvatarUrl
                        })
                    }
                }
            }

            if (isNotNull(user) && 
                isNotNull(user.tradingApiKey) && 
                isNull(isLoadingSettings)) {
                    onSetNewState(this, {
                        isLoadingSettings: true
                    }, () => {                        
                        dispatch(tradingActions.getSettings())
                    })
            }
            
            if (isNotNull(tradings) && isNotNull(tradings.settings) && isLoadingSettings) {
                onSetNewState(this, {
                    isLoadingSettings: false
                })
            }
        }   
    }
    
    componentWillUnmount() {
        this.mounted = false
        this.setState(this.baseState)
        this.element = null
    }

    setDefaultAvatar = (e) => {
        e.target.src = '/assets/images/def-user.png'
    }
    
    handleShow = event => onSetNewState(this, { targetEl: event.target.parentElement, show: !this.state.show })

    handleHide = () => onSetNewState(this, {
        show: false
    })

    handleLogout = () => {
        const { dispatch } = this.props
        dispatch(authActions.logout())
    }

    renderMenu = () => {
        return (
            <Popover id={`usermenu-popover-positioned-bottom`} className='account-menu'>
                <Popover.Content>
                    <AccountMenu>
                        <li>
                            <NavLink to="/account/profile">
                                <span className='icon'>
                                    <FontAwesomeIcon icon={faUserCircle} />
                                </span>
                                <span className='text'>Account Settings</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/wallet/balance">
                                <span className='icon'>
                                    <FontAwesomeIcon icon={faWallet} />
                                </span>
                                <span className='text'>My Wallet</span>
                            </NavLink>
                        </li>
                        <li>
                            <button type="button" onClick={this.handleLogout} className="logout-btn">
                                <span className='icon'>
                                    <FontAwesomeIcon icon={faSignOutAlt} />
                                </span>
                                <span className="text">Log Out</span>
                            </button>
                        </li>
                    </AccountMenu>
                </Popover.Content>
            </Popover>
        )
    }

    render() {
        const { name, avatarUrl, show, targetEl } = this.state

        return (
            <DropdownWrapper>
                <div>
                    <div className='my-profile'>                        
                        <span className='avatar'>
                            { isNotNull(avatarUrl) &&                             
                                <img src={avatarUrl} alt="My Avatar" onError={this.setDefaultAvatar} />
                            }
                        </span>
                        <span className='label'>
                            <small>Signed in as</small>
                            <span className='my-name'>{isNotNull(name) && name}</span>
                        </span>
                    </div>
                    <div ref={this.element}>
                        <button type='button' onClick={this.handleShow}>
                            <FontAwesomeIcon icon={faCaretDown} />
                        </button>
                        <Overlay 
                            show={show} 
                            onHide={this.handleHide}
                            rootClose={true} 
                            container={this.element.current} 
                            target={targetEl}
                            placement="bottom">
                                {this.renderMenu()}  
                        </Overlay>
                    </div>
                </div>
            </DropdownWrapper>
        )
    }
}

function mapStateToProps(state) {
    const { onboardingUser, puid, authentication, tradings } = state
    return {
        onboardingUser,
        puid,
        authentication,
        tradings
    }
}

const connectedLoggedInUser = connect(mapStateToProps)(LoggedInUser)
export { connectedLoggedInUser as LoggedInUser }