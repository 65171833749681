import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const PremiumFeaturesSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    padding: 40px 10px;
    background-image: linear-gradient(65deg, #0B1219, #1D2E41);

    .container {
        .section-title {
            font-weight: 800;
            color: #FFFFFF;
            text-align: center;
            font-size: 20px;
            margin-bottom: 30px;
        }

        .carousel {
            padding-left: 5px;

            .features-list {
                display: flex;
                list-style-type: none;
                padding: 0;
                margin: 0 auto;

                .item {
                    padding: 15px 10px;
                    text-align: center;

                    .thumbnail {
                        width: 295px;
                        display: block;
                        margin: 0 auto 20px;

                        img {
                            width: 100%;
                        }
                    }

                    h5 {
                        color: #FFFFFF;
                        font-size: 18px;
                    }
                }
            }

            .carousel-indicators {
                bottom: -30px;

                li {
                    width: 7px;
                    height: 7px;
                    border-radius: 50%;
                    background-color: #172533;

                    &.active {
                        background-color: #15BD6F;
                    }
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        .container {
            .section-title {
                font-size: 22px;
            }

            .feature-thumbnails-list {
                display: flex;
                max-width: 690px;
                margin: 0 auto;
                padding: 0;
                list-style-type: none;

                .feature {
                    margin-right: 10px;
                    z-index: 1;
                    
                    .thumbnail {
                        width: 165px;
                        margin-bottom: 15px;

                        img {
                            width: 100%;
                        }
                    }
                    
                    h5 {
                        font-size: 15px;
                        color: #FFFFFF;
                        text-align: center;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {

        &:before {
            content: '';
            position: absolute;
            top: 20px;
            left: -50px;
            width: 300px;
            height: 220px;
            background-image: url(images/trial-offer/ellipse-gray.svg);
            background-size: contain;
            background-repeat: no-repeat;
            z-index: 0;
        }

        &:after {
            content: '';
            position: absolute;
            top: 55px;
            right: 0px;
            width: 300px;
            height: 220px;
            background-image: url(images/trial-offer/ellipse-green.svg);
            background-size: contain;
            background-repeat: no-repeat;
            z-index: 0;
        }
        
        .container {
            .section-title {
                font-size: 28px;
            }

            .feature-thumbnails-list {
                max-width: 980px;
                justify-content: center;

                .feature {
                    margin-right: 15px;
                    
                    .thumbnail {
                        width: 221px;
                    }
                    
                    h5 {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        &:before {
            left: 200px;
            width: 400px;
            height: 320px;
        }

        &:after {
            right: 245px;
            width: 400px;
            height: 320px;
        }
        
        .container {
            .section-title {
                font-size: 35px;
                margin-bottom: 35px;
                padding: 0 180px;
            }

            .feature-thumbnails-list {
                max-width: 1140px;

                .feature {    
                    .thumbnail {
                        width: 266px;
                    }
                    
                    h5 {
                        font-size: 16px;
                    }
                }
            }
        }
    }
`
