import glamorous from 'glamorous'
import { isNotNull } from '../../utils'
import { mediaQueries } from '../../constants/mediaQueries'

const calculateProgress = (completedSteps) => {
    switch(completedSteps) {
        default: return '0%'
        case 1: return '20%'
        case 2: return '40%'
        case 3: return '60%'
        case 4: return '80%'
        case 5: return '100%'
    }
}

export const ProgressBarWrapper = glamorous.div({
    display: 'block',

    '& .progress-track': {
        display: 'block',
        position: 'relative',
        height: '4px',
        margin: '0 auto',
        backgroundColor: '#090F16',
        boxShadow: '0.3rem 0.3rem 0.6rem #0f1922, -0.2rem -0.2rem 0.5rem #223040',

        '& .thumb': {
            position: 'absolute',
            display: 'block',
            height: '4px',
            width: 'auto',
            maxWidth: '1000px',
            backgroundColor: '#13B078',
            backgroundImage: 'linear-gradient(45deg, #13b078 90%, #26aae3)',
            transition: 'width .8s ease-in-out'
        }
    },
    
    [mediaQueries.extraSmallDevices]: {
        position: 'fixed',
        bottom: '-6px',
        margin: 0,
        zIndex: 2,
        width: '170px',

        '& .progress-track': {
            display: 'none'
        }
    },

    [mediaQueries.smallDevices]: {
        position: 'fixed',
        bottom: '-6px',
        margin: 0,
        zIndex: 2,
        width: '170px',

        '& .progress-track': {
            display: 'none'
        }
    },

    [mediaQueries.mediumDevices]: {
        position: 'fixed',
        bottom: '-6px',
        margin: 0,
        zIndex: 2,
        width: '300px',

        '& .progress-track': {
            display: 'none'
        }        
    },

    [mediaQueries.largeDevices]: {
        bottom: '-9px'
    },

    [mediaQueries.extraLargeDevices]: {
        position: 'relative',
        width: '1200px',
        margin: '86px 0 50px',

        '& .progress-track': {
            display: 'block',
            width: '1000px',
        }
    }
}, ({ completedSteps }) => ({
    '& .progress-track': {
        '& .thumb': {
            width: isNotNull(completedSteps) ? calculateProgress(completedSteps) : '0%'
        }
    }
}))

export const ProgressBarSteps = glamorous.ul({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    listStyleType: 'none',
    margin: 0,
    padding: 0,

    '& .step': {
        position: 'relative',
        paddingTop: '30px',
        width: '200px',
        fontSize: '14px',
        textAlign: 'center',

        '&::before': {
            position: 'absolute',
            content: ' ',
            display: 'block',
            top: '-13px',
            left: 'calc(50% - 13px)',
            zIndex: 1,
            transition: 'all 3s ease',
            boxShadow: '0.3rem 0.3rem 0.6rem #0f1922, -0.2rem -0.2rem 0.5rem #223040'
        },

        '&.active': {
            '&::before': {                
                backgroundColor: '#27c2eb',
                boxShadow: '0 0 0 4px rgba(39, 194, 235, 0.2)'
            }
        },

        '&.completed': {
            color: '#13B078',

            '&::before': {
                backgroundColor: '#13B078',
                boxShadow: '0 0 0 4px rgba(19, 176, 120, 0.2)'
            }
        },

        '& .icon': {
            position: 'absolute',
            display: 'inline-block',
            width: '13px',
            height: '13px',
            top: '-8px',
            left: 'calc(50% - 8px)',
            zIndex: 1,
            color: '#fff'
        },
    },
    
    [mediaQueries.extraSmallDevices]: {
        '& .step': {
            '&::before': {
                backgroundColor: '#bfc9d5',
                width: '23px',
                height: '4px',
                borderRadius: 0
            },

            '& > span': {
                display: 'none'
            },

            '&.active,&.completed': {
                '&::before': {
                    backgroundColor: '#13B078',
                    boxShadow: 'none'
                }
            },

            '& .icon': {
                display: 'none'
            }
        }
    },

    [mediaQueries.smallDevices]: {
        '& .step': {
            '&::before': {
                backgroundColor: '#bfc9d5',
                width: '23px',
                height: '4px',
                borderRadius: 0
            },

            '& > span': {
                display: 'none'
            },

            '&.active,&.completed': {
                '&::before': {
                    backgroundColor: '#13B078',
                    boxShadow: 'none'
                }
            },

            '& .icon': {
                display: 'none'
            }
        }
    },

    [mediaQueries.mediumDevices]: {
        '& .step': {
            '&::before': {
                backgroundColor: '#bfc9d5',
                width: '36px',
                height: '6px',
                borderRadius: 0
            },

            '& > span': {
                display: 'none'
            },

            '&.active,&.completed': {
                '&::before': {
                    backgroundColor: '#13B078',
                    boxShadow: 'none'
                }
            },

            '& .icon': {
                display: 'none'
            }
        }
    },

    [mediaQueries.extraLargeDevices]: {
        '& .step': {
            '&::before': {
                backgroundColor: '#090F16',
                width: '23px',
                height: '23px',
                borderRadius: '50%'

            },

            '& > span': {
                display: 'inline-block'
            },

            '&.active': {
                '&::before': {
                    backgroundColor: '#27c2eb',
                    boxShadow: '0 0 0 4px rgba(39, 194, 235, 0.2)'
                }
            },

            '&.completed': {
                '&::before': {
                    backgroundColor: '#13B078',
                    boxShadow: '0 0 0 4px rgba(19, 176, 120, 0.2)'
                }
            },

            '& .icon': {
                display: 'inline-block'
            }
        }
    }
})