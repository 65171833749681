import { PackageBreakdownSectionStyle } from '../styles'

export const SMPackageBreakdown = () => { 
    return (
        <PackageBreakdownSectionStyle>
            <div className='container'>
                <div className='package-breakdown-wrapper'>                    
                    <ul className='package-breakdown'>
                        <li className='package-breakdown-item'>
                            <div className='thumbnail item-1'>
                                <img src='/images/layout/12-months-subscription-to-pf-pro.png' alt='12 Months Subscription to PF PRO' />
                            </div>
                            <div className='description'>
                                <h6><strong>12 Months Subscription to PF PRO</strong> <span className='em'> - Value $2999</span></h6>
                                <p>The ultimate crypto trading 'heads-up'.</p>
                                <p>Access to all of our most loved pro trade features and highly accurate signals. Our AI is ALWAYS watching the market, so you don't have to.</p>
                            </div>
                        </li>
                        <li className='package-breakdown-item'>
                            <div className='thumbnail item-2'>
                                <img src='/images/layout/elite-trading-strategies-pack.png' alt='Elite Trading Strategies Pack' />
                            </div>
                            <div className='description'>
                                <h6><strong>Elite Trading Strategies Pack</strong> <span className='em'> - Value $499</span></h6>
                                <p>Elevate your trading game.</p>
                                <p>These easy to understand and hyper effective trading strategies will make you a king of the charts without busting your brain.</p>
                            </div>
                        </li>
                        <li className='package-breakdown-item'>
                            <div className='thumbnail item-3'>
                                <img src='/images/layout/trade-doctor-sessions.png' alt='2 Trade Doctor Sessions' />
                            </div>
                            <div className='description'>
                                <h6><strong>2 Trade Doctor Sessions</strong> <span className='em'> - Value $499</span></h6>
                                <p>Direct help exactly when you need it.</p>
                                <p>Let our in-house trade doctor diagnose your platform usage problems and spare you from injury.</p>
                            </div>
                        </li>
                        <li className='package-breakdown-item'>
                            <div className='thumbnail item-4'>
                                <img src='/images/layout/crypto-trading-booster-pack.png' alt='Crypto Trading Booster Pack' />
                            </div>
                            <div className='description'>
                                <h6><strong>Crypto Trading Booster Pack</strong> <span className='em'> - Value $99</span></h6>
                                <p>Avoid mistakes and nail the basics.</p>
                                <p>44 pages of everything we wish we knew before we started PRO trading.</p>
                            </div>
                        </li>
                        <li className='package-breakdown-item'>
                            <div className='thumbnail item-5'>
                                <img src='/images/layout/7-steps-to-1000x-altcoins.png' alt='7 Steps to 1000x Altcoins' />
                            </div>
                            <div className='description'>
                                <h6><strong>7 Steps to 1000x Altcoins</strong> <span className='em'> - Value $99</span></h6>
                                <p>Generational wealth on tap.</p>
                                <p>Now you can source, vet and cash in from the regular Altcoin booms.</p>
                            </div>
                        </li>
                        <li className='package-breakdown-item'>
                            <div className='thumbnail item-6'>
                                <img src='/images/layout/dark-edition-spotify-playlist.png' alt='DarkEdition Spotify Playlist' />
                            </div>
                            <div className='description'>
                                <h6><strong>DarkEdition Spotify Playlist</strong> <span className='em'> - Value $49</span></h6>
                                <p>Adjust your frequencies.</p>
                                <p>Unleash your trading profitability with the power of sound. Techno for your soul.</p>
                            </div>
                        </li>
                        <li className='package-breakdown-item'>
                            <div className='thumbnail item-7'>
                                <img src='/images/layout/trader-education-cards.png' alt='Trader Education Cards' />
                            </div>
                            <div className='description'>
                                <h6><strong>Trader Education Cards</strong> <span className='em'> - Value $49</span></h6>
                                <p>Nourish your eyes.</p>
                                <p>More than 100 infographics with great trading tips, tricks, strategies and crypto education.</p>
                            </div>
                        </li>
                        <li className='package-breakdown-item'>
                            <div className='thumbnail item-8'>
                                <img src='/images/layout/first-in-line-access-to-market-intelligence.png' alt='First in line access to Market Intelligence' />
                            </div>
                            <div className='description'>
                                <h6><strong>First in line access to Market Intelligence</strong> <span className='em'> - Invaluable</span></h6>
                                <p>You benefit from knowing exactly what our trading wizards are thinking during all market cycles.</p>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className='price-tag-wrapper'>
                    <div className='img-wrapper'>
                        <img src='/images/layout/the-package.png' alt='Exclusive Price for PRO Trader' />
                    </div>
                    <div className='text-col'>
                        <div className='original-price'>
                            <span className='crashed-out-price'>$2,800</span>
                            <label>TOTAL VALUE</label>
                        </div>
                        <h4 style={{ marginTop: '10px' }}>Exclusive price for Pro Trader 5x Blueprint graduates</h4>
                        <div className='discounted-price'>
                            <span className='discounted-price-value six'>$899</span>
                            <span><img src='/images/layout/only-tag.svg' alt='' /></span>
                        </div>
                        <h6 className='impt six'>This is your final chance to get an absolutely insane discount and start your journey to 5x success.</h6>
                        <p>We cannot possibly make a better deal than this so make the most of it whilst it's available!</p>
                    </div>
                </div>
            </div>
        </PackageBreakdownSectionStyle>
    )
}