import { useState } from 'react'
import moment from 'moment'
import { LimitedOfferStyle } from './styles'
import { isNotNull } from '../../utils'

export const LimitedOffer = (props) => {
    let [discountInUsd, setDiscountInUsd] = useState('')
    let [remainingDays, setRemainingDays] = useState('')
    let [remainingHours, setRemainingHours] = useState('')
    let [remainingMinutes, setRemainingMinutes] = useState('')
    let [remainingSeconds, setRemainingSeconds] = useState('')

    let parsedDate = Date.parse(props.validUntil)
    let expiry = new Date(parsedDate)
        expiry = moment(expiry).format('MM/DD/yyyy h:mm:ss')
    
    const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

    let today = new Date(),
        dd = String(today.getDate()).padStart(2, "0"),
        mm = String(today.getMonth() + 1).padStart(2, "0"),
        yyyy = today.getFullYear(),
        nextYear = yyyy + 1,
        dayMonth = moment(expiry).format('MM/DD/')

    today = mm + "/" + dd + "/" + yyyy
    if (today > expiry) {
        expiry = dayMonth + nextYear
    }

    const countDown = new Date(expiry).getTime(),
        x = setInterval(function() {    
            const now = new Date().getTime(),
                distance = countDown - now

            setRemainingDays(Math.floor(distance / (day)))
            setRemainingHours(Math.floor((distance % (day)) / (hour)))
            setRemainingMinutes(Math.floor((distance % (hour)) / (minute)))
            setRemainingSeconds(Math.floor((distance % (minute)) / second))

            if (isNotNull(remainingDays) && remainingDays !== '') {
                if (parseInt(remainingDays) >= 14) {
                    setDiscountInUsd('$2,800')
                } else if (parseInt(remainingDays) < 14) {
                    if (discountInUsd !== '$2,500') {
                        setDiscountInUsd('$2,500')
                    }
                }
            }
            
            if (distance < 0) {
                clearInterval(x)
            }
            //seconds
        }, 1000)

    return (
        <LimitedOfferStyle>
            <p>ATTN - Your exclusive <strong>Pro Trader 5x Blueprint</strong> discount ends in <strong>[<span id="days">{remainingDays}</span>D <span id="hours">{remainingHours}</span>h <span id="minutes">{remainingMinutes}</span>m <span id="seconds">{remainingSeconds}</span>s]</strong>. Save over {discountInUsd} before time runs out!</p>
        </LimitedOfferStyle>
    )
}