import { messagesActionTypes } from '../CONSTANTS'

export function messageBoardItemList(state={}, action) {
    switch(action.type) {
        case messagesActionTypes.GET_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case messagesActionTypes.GET_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                dataList: action.response
            }
        case messagesActionTypes.GET_LIST_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case messagesActionTypes.RESET_LIST:
            return {
                ...state,
                loading: false,
                dataList: null
            }
        case messagesActionTypes.UPDATE_MESSAGE_LIST:
            return {
                ...state,
                loading: false,
                dataList: action.data
            }
        default: return state
    }
}

export function newSetOfMessages(state={}, action) {
    switch(action.type) {
        case messagesActionTypes.LOAD_MORE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case messagesActionTypes.LOAD_MORE_SUCCESS:
            return {
                ...state,
                loading: false,
                dataList: action.response
            }
        case messagesActionTypes.LOAD_MORE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function msgHeaderPhoto(state={}, action) {
    switch(action.type) {
        case messagesActionTypes.ADD_HEADER_PHOTO_REQUEST:
            return {
                ...state,
                loading: true
            }
        case messagesActionTypes.ADD_HEADER_PHOTO_SUCCESS:
            return {
                ...state,
                loading: false,
                location: action.response
            }
        case messagesActionTypes.ADD_HEADER_PHOTO_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function saveMessageBoardItem(state={}, action) {
    switch(action.type) {
        case messagesActionTypes.SAVE_MESSAGE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case messagesActionTypes.SAVE_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.response
            }
        case messagesActionTypes.SAVE_MESSAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case messagesActionTypes.SAVE_MESSAGE_RESET:
            return {
                ...state,
                loading: false,
                result: null
            }
        default: return state
    }
}

export function editMessageBoardItem(state={}, action) {
    switch(action.type) {
        case messagesActionTypes.EDIT_MESSAGE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case messagesActionTypes.EDIT_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                editResult: action.response
            }
        case messagesActionTypes.EDIT_MESSAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case messagesActionTypes.EDIT_MESSAGE_RESET:
            return {
                ...state,
                loading: true,
                editResult: null
            }
        default: return state
    }
}