import { planActionTypes } from '../CONSTANTS'

export function plan(state = {}, action) {
    switch (action.type) {
        case planActionTypes.GET_PLAN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case planActionTypes.GET_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                plans: action.plans
            }
        case planActionTypes.GET_PLAN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function trialPlan(state = {}, action) {
    switch (action.type) {
        case planActionTypes.GET_TRIAL_PLAN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case planActionTypes.GET_TRIAL_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                offer: action.trialPlan
            }
        case planActionTypes.GET_TRIAL_PLAN_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: 
            return state
    }
}

export function sixMonthPlan(state={}, action) {
    switch (action.type) {
        case planActionTypes.GET_SIX_MONTH_PLAN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case planActionTypes.GET_SIX_MONTH_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                offer: action.sixMonthPlan
            }
        case planActionTypes.GET_SIX_MONTH_PLAN_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: 
            return state
    }
}
