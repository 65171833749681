import moment from 'moment'
export function getHistoryTime(passedDate) {

    var datePublishedFromNowString = ''

    passedDate = moment.utc(passedDate).local().format('YYYY-MM-DD HH:mm:ss')
    let setTo = moment.utc(moment()).local().format('YYYY-MM-DD HH:mm:ss')
    let setFrom = moment.utc(moment(passedDate)).local().format('YYYY-MM-DD HH:mm:ss')
    let setDuration = moment.duration(moment(setTo).diff(setFrom))

    let hours = 0
    let minutes = 0
    //Get Days and subtract from duration
    let days = setDuration.asDays()
    setDuration.subtract(moment.duration(days, 'days'))

    let daysSplit = days.toString().split('.')

    
    hours = (daysSplit[1]) ? parseFloat('.' + daysSplit[1]) * 24 : 0
    let hoursSplit = hours.toString().split('.')
    minutes = (hoursSplit[1]) ? parseFloat('.' + hoursSplit[1]) * 60 : 0

    let datePublishedFromNow = {
        days: parseInt(days),
        hours: parseInt(hours),
        minutes: parseInt(minutes)
    }

    let hasAgo = false

    if (datePublishedFromNow.days > 0) {
        datePublishedFromNowString += (datePublishedFromNow.days === 1) ? '1 day' : `${datePublishedFromNow.days} days`
        hasAgo = true
    }

    if (datePublishedFromNow.hours > 0) {
        if (datePublishedFromNow.days > 0) {
            datePublishedFromNowString += (datePublishedFromNow.minutes === 0) ? ' and ' + datePublishedFromNow.hours : ', ' + datePublishedFromNow.hours
            datePublishedFromNowString += (datePublishedFromNow.hours === 1) ? ' hour' : ' hrs'
        } else {
            datePublishedFromNowString += (datePublishedFromNow.hours === 1) ? 'An hour' : `${datePublishedFromNow.hours} hrs`
        }
        hasAgo = true
    }

    if (datePublishedFromNow.minutes > 0) {
        if (datePublishedFromNow.hours > 0) {
            datePublishedFromNowString += ` and ${datePublishedFromNow.minutes}`
            datePublishedFromNowString += (datePublishedFromNow.minutes === 1) ? ' minute' : ' mins'
        } else {
            if (datePublishedFromNow.days === 0) {
                datePublishedFromNowString += (datePublishedFromNow.minutes === 1) ? 'A minute' : ` ${datePublishedFromNow.minutes} mins`
            } else {
                datePublishedFromNowString += ' and ' + datePublishedFromNow.minutes
                datePublishedFromNowString += (datePublishedFromNow.minutes === 1) ? ' minute' : ' mins'
            }
        }
        hasAgo = true
    }

    if (hasAgo)
        datePublishedFromNowString += ' ago'
    else {
        datePublishedFromNowString += 'A few seconds ago'
    }


    if (datePublishedFromNowString === ' ago')
        datePublishedFromNowString = 'A few seconds ago'


    return datePublishedFromNowString
}

export function getSpecificHistoryTime(passedDate) {

    var datePublishedFromNowString = ''

    passedDate = moment.utc(passedDate).local().format('YYYY-MM-DD HH:mm:ss')
    let setTo = moment.utc(moment()).local().format('YYYY-MM-DD HH:mm:ss')
    let setFrom = moment.utc(moment(passedDate)).local().format('YYYY-MM-DD HH:mm:ss')
    let setDuration = moment.duration(moment(setTo).diff(setFrom))

    let hours = 0
    let minutes = 0
    //Get Days and subtract from duration
    let days = setDuration.asDays()
    setDuration.subtract(moment.duration(days, 'days'))

    let daysSplit = days.toString().split('.')

    hours = (daysSplit[1]) ? parseFloat('.' + daysSplit[1]) * 24 : 0
    let hoursSplit = hours.toString().split('.')
    minutes = (hoursSplit[1]) ? parseFloat('.' + hoursSplit[1]) * 60 : 0

    let datePublishedFromNow = {
        days: parseInt(days),
        hours: parseInt(hours),
        minutes: parseInt(minutes)
    }

    let hasAgo = false

    if (datePublishedFromNow.days > 0) {
        datePublishedFromNowString += (datePublishedFromNow.days === 1) ? '1 day' : `${datePublishedFromNow.days} days`
        hasAgo = true
    }

    if (datePublishedFromNow.hours > 0)
        if (datePublishedFromNow.days <= 0) {
            datePublishedFromNowString += (datePublishedFromNow.hours === 1) ? 'An hour' : `${datePublishedFromNow.hours} hrs`
            hasAgo = true
        }

    if (datePublishedFromNow.minutes > 0)
        if (datePublishedFromNow.days <= 0 && datePublishedFromNow.hours <= 0) {
            datePublishedFromNowString += (datePublishedFromNow.minutes === 1) ? 'A minute' : ` ${datePublishedFromNow.minutes} mins`
            hasAgo = true
        }

    if (hasAgo)
        datePublishedFromNowString += ' ago'
    else
        datePublishedFromNowString += 'A few seconds ago'

    if (datePublishedFromNowString === ' ago')
        datePublishedFromNowString = 'A few seconds ago'

    return datePublishedFromNowString
}