import ReactPlayer from 'react-player/lazy'
import { BearMarketResultsSectionStyle } from './styles'

export const BearMarketResultsSection = (props) => {
    return (
        <BearMarketResultsSectionStyle>
            <div className='container'>
                <h2 className='section-title'>Bear Market? What Bear Market? +330% In 4 Days</h2>

                <ul className='snapvids'>
                    <li>
                        <ReactPlayer 
                            url={'https://vimeo.com/749059987/8011489368'}
                            controls={true} 
                            width='100%' 
                            volume={0} />

                        <p className='video-title'>MIRUSDT - Result +150% in 1 day</p>
                    </li>
                    <li>
                        <ReactPlayer 
                            url={'https://vimeo.com/750226890/df96f8a6e7'}
                            controls={true} 
                            width='100%' />

                        <p className='video-title'>RVNUSDT - Result +140% in 4 days</p>
                    </li>
                    <li>
                        <ReactPlayer 
                            url={'https://vimeo.com/749481947/55f3416f97'}
                            controls={true} 
                            width='100%' />

                        <p className='video-title'>ANCUSDT - Result +40% in 4 hours</p>
                    </li>
                </ul>
                
                <p className='section-description'>These trades happened in September 2022 during the worst bear market most crypto traders had ever seen. This is why a long-term PRO membership is key, you must be ready at any time!</p>
            </div>
        </BearMarketResultsSectionStyle>
    )
}