import { Row, Col, Card, Button, Modal, SafeAnchor } from 'react-bootstrap'
import { Skeleton } from '@material-ui/lab'
import jQuery from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { endpoints } from '../../../../services/CONSTANTS'
import { subscriptionStatus } from '../../../../constants/subscriptionStatus'
import { subscriptionNames } from '../../../../constants/subscriptionNamesConstants'
import { paymentGateway } from '../../../../constants/paymentGateway'
import { PricingForm, CustomSurvey } from '../../../../components'
import { SettingsContentWrapper } from '../../styled_components'
import { SubscriptionPlanWrapper, SubsTextHeader, SubsButtonWrapper } from './styled_components'
import { isNotNull, isNull } from '../../../../utils'

const { v3 } = endpoints

export const SubscriptionView = (props) => {
    const { loading, userInfo } = props
    const { showCancelSub, showSubscribeNow, isCancel } = props.state
    const $this = props.self

    const onShowCancel = () => $this.setState((prev) => {
        return ({ showCancelSub: !prev.showCancelSub })
    })

    const onHideCancel = () => {
        props.onHideCancel()
    }

    const onSubscribeNow = () => $this.setState((prev) => {
        return ({ showSubscribeNow: !prev.showSubscribeNow })
    })

    const onShowHandler = () => {
        const subs = {
            isShow: false,
            selectedClass: "",
            size: "xl",
            closeButton: false
        }
        if (showSubscribeNow === true) {
            subs.isShow = true
            subs.selectedClass = "onboarding-modal"
            subs.size = 'xl'
            subs.closeButton = true
        }
        else {
            subs.isShow = false
            subs.selectedClass = ""
            subs.size = null
            subs.closeButton = false
        }
        return subs
    }

    const onDeselectHandler = () => {
        if (showSubscribeNow === true)
            return onSubscribeNow()
    }

    const renderPreloader = () => {
        return (
            <Skeleton variant="rect" height={250} style={{
                backgroundImage: 'linear-gradient(15deg,#334156,#385065)',
                border: '1px solid #27313f',
                borderRadius: '4px'
            }} className="skeleton" />
        )
    }

    const renderActiveSubscription = () => {
        const { 
            status, 
            sname,
            spPrice, 
            nextBillingDate,
            paymentSource,
            isWithinTrialPeriod } = userInfo

        return (
            <div className='current-plan-info-wrapper'>
                <div className='current-plan-info'>                    
                    <h3>{ isNotNull(isWithinTrialPeriod) && isWithinTrialPeriod ? <>Trial Plan</> : <>Pro Plan</>}</h3>
                    <p>{ isNotNull(isWithinTrialPeriod) && isWithinTrialPeriod ? <>21 days of PRO access to the life-changing trading powers</> : <>Full access to the life-changing trading powers</>}</p>
                    
                    {/* { (status === subscriptionStatus.ACTIVE) && (sname.toUpperCase() !== subscriptionNames.LIFETIME) && 
                        <label style={{ marginTop: '15px' }}>Next Payment: <span>${spPrice} USD on {nextBillingDate}</span></label>
                    } */}
                </div>

                { (sname.toUpperCase() !== subscriptionNames.LIFETIME) && 
                    <div className='action-btns'>
                        { (status === subscriptionStatus.PENDING_DEACTIVATION || status === subscriptionStatus.CANCELLED)
                            ? 
                                <button type="button" className='subscription-action-btn cancel' disabled={true}>
                                    Cancelled
                                </button>
                            : 
                                <>
                                    { isNotNull(paymentSource) && paymentSource !== paymentGateway.CRYPTO && 
                                        <button type="button" className='subscription-action-btn cancel' onClick={() => onShowCancel()}>
                                            Cancel Subscription
                                        </button>
                                    }
                                </>
                        }
                    </div>
                }
            </div>
        )
    }

    const renderExpiredSubscription = () => {
        return (
            <div className='current-plan-info-wrapper'>
                <div className='current-plan-info'>                    
                    <h3>Pro Plan</h3>
                    <p>Full access to the life-changing trading powers</p>
                    <label>Your subscription plan has already expired</label>
                </div>
                
                <div className='action-btns'>
                    <SafeAnchor href={v3.system.subscriptions.pro} className='subscription-action-btn upgrade'>Renew Subscription</SafeAnchor>
                </div>
            </div>
        )
    }

    const renderFreeSubscription = () => {
        const { isPaid } = userInfo
        return (
            <ul className='subscription-plan-list'>
                <li className={`subscription-plan free ${!isPaid ? 'active' : ''}`}>
                    <span className='selector'>
                        <FontAwesomeIcon size='xs' icon={faCheck} />
                    </span>
                    <label>
                        <span>FREE <small>plan</small></span>
                        <span className='price'><strong>$0</strong>/mo</span>
                    </label>
                </li>
                <li className={`subscription-plan pro ${isPaid ? 'active' : ''}`}>
                    <span className='selector'>
                        <FontAwesomeIcon size='xs' icon={faCheck} />
                    </span>

                    <label>
                        <span>PRO <small>plan</small></span>
                        <span className='price'>from <strong>$399</strong>/mo</span>
                    </label>
                    <SafeAnchor href={v3.system.subscriptions.pro} className='btn'>Upgrade</SafeAnchor>
                </li>
            </ul>
        )
    }

    const renderSubscriptionPlans = () => {
        const { isPaid, doesHaveCustomerSubscription, status } = userInfo
        if (!isPaid) {            
            if (!doesHaveCustomerSubscription) {
                return (
                    <>{renderFreeSubscription()}</>
                )
            } else {
                if (status === subscriptionStatus.EXPIRED) {                    
                    return (
                        <>{renderExpiredSubscription()}</>
                    )
                } else {
                    return (
                        <>{renderFreeSubscription()}</>
                    )
                }
            }
        } else {
            return (
                <>{ renderActiveSubscription() }</>
            )
        }
    }

    const renderModalHeader = () => {
        if (showSubscribeNow === true)
            return (<></>)
    }

    const renderModalBody = () => {
        if (showSubscribeNow === true)
            return (
                <PricingForm closeModal={() => onSubscribeNow()} />
            )
    }

    const handleShow = onShowHandler()

    const onStepHandler = (data) => {
        props.updateStep(data)
        jQuery('.survey').scrollTop(0)
    }

    return (
        <>
            <SettingsContentWrapper className="account-settings-wrapper">
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                <h6>Your Current Plan</h6>
                            </Card.Header>
                            <Card.Body className="subscription">
                                { loading && renderPreloader() }

                                { !loading && 
                                    <SubscriptionPlanWrapper className="theme-subscription-plan">
                                        { renderSubscriptionPlans() }
                                    </SubscriptionPlanWrapper>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </SettingsContentWrapper>

            <Modal
                className={`generic-modal ${handleShow.selectedClass}`}
                show={handleShow.isShow}
                size={handleShow.size}
                onHide={onDeselectHandler}
                aria-labelledby="genericModal"
                aria-hidden="true"
                data-backdrop="true"
                centered
            >
                <Modal.Header closeButton={handleShow.closeButton}>
                    {renderModalHeader()}
                </Modal.Header>
                <Modal.Body>
                    {renderModalBody()}
                </Modal.Body>
            </Modal>

            <Modal
                className={`generic-modal survey`}
                show={showCancelSub}
                onHide={onHideCancel}
                centered
                size="lg"
            >
                <CustomSurvey
                    title="We're sad to see you go..."
                    hasClose={true}
                    updateSurvey={(e) => props.updateSurvey(e)}
                    updateAnswer={(e) => props.updateAnswer(e)}
                    updateStep={(e) => onStepHandler(e)}
                    nextBillingDate={isNotNull(userInfo) && isNotNull(userInfo.nextBillingDate)
                        ? userInfo.nextBillingDate
                        : ""
                    }
                    customerId={isNotNull(userInfo) && isNotNull(userInfo.customerId)
                        ? userInfo.customerId
                        : ""
                    }
                    onHide={() => onHideCancel()}
                    onSubmit={(e) => props.cancelSub(e)}
                    data={props.cancellationSurvey}
                    isCancel={isCancel}
                />
            </Modal>
        </>
    )
}