import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const AcademyStyle = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;;

    .academy-videos-list {
        display: flex;
        flex-wrap: no-wrap;
        overflow-x: auto;
        overflow-y: hidden;
        margin: 0;
        padding-inline-start: 0;
        padding-bottom: 10px;

        ::-webkit-scrollbar {
            height: 8px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #2C3B4D !important;
            background-image: unset;
        }

        ::-webkit-scrollbar-track {
            background-color: #131D2A;
        }

        .academy-videos-list-item {
            list-style-type: none;
            margin-right: 10px;

            &:last-child {
                margin-right: 0px;
            }

            .academy-videos-list-item-link { 
                img {
                    width: auto;
                    border-radius: 10px;
                    transition: all .4s;

                    &:hover {
                        filter: brightness(108%);
                        transform: scale(.96);
                        box-shadow: 0px 3px 18px #00000061;
                    }
                }
            }
        }
    }
`