import { endpoints, baseUrl } from './CONSTANTS'
import { authServices } from './authService'

const { v3 } = endpoints

async function saveSurvey(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        //headers: header.addAuthorizationHeader(),
        body: JSON.stringify(data)
    }

    try {
        const res = await fetch(`${baseUrl}${v3.user.feedback.cancelledMembership}`, requestOptions)
        const response = await handleResponse(res)
        return Promise.resolve(response)
    } catch (error) {
        return Promise.reject(error)
    }
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        if (res.status === 401)
            authServices.logout()
        else
            return data
    })
}

export const surveyServices = {
    saveSurvey
}