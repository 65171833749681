import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const SignUpStepsSectionStyle = styled.section`
    position: relative;
    display: block;
    padding: 40px 0;
    width: 100%;
    background-color: #E3ECF3;

    .container {
        .section-title {
            font-weight: 900;
            background: -webkit-linear-gradient(100deg,#15BD6F, #3B96E8);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-align: center;
            margin: auto auto 30px;
        }

        .steps-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            .step-col {
                margin-bottom: 30px;

                &:last-child {
                    .step-img {
                        margin-bottom: -12px;
                    }
                }
                
                .step-img {
                    width: 200px;
                    max-width: 280px;
                    margin-bottom: 18px;
                }

                .step-title {
                    font-weight: 800;
                    color: #0B1219;
                    font-size: 20px;
                    margin: auto auto 5px;
                }
                
                small {
                    font-size: 15px;
                    color: #0B1219;
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        .container {
            max-width: 760px;

            .section-title {
                font-size: 38px;
            }

            .steps-container {
                flex-direction: row;
                align-items: flex-start;

                .step-col {
                    width: 33.33%;
                    padding: 0 15px;

                    &:last-child {
                        .step-img {
                            margin-bottom: 18px;
                        }
                    }
                    
                    .step-img {
                        width: 160px;
                    }

                    .step-title {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        .container {
            max-width: 960px;

            .section-title {
                width: 600px;
            }

            .steps-container {
                .step-col {
                    position: relative;

                    &:before {
                        content:'';
                        position: absolute;
                        width: 100px;
                        height: 44px;
                        background-image: url(/images/trial-offer/green-arrow.png);
                        background-size: contain;
                        right: -55px;
                        top: 55px;
                    }

                    &:last-child {
                        &:before {
                            display: none;
                        }
                    }
                    
                    .step-img {
                        width: 180px;
                    }
                }
            }
        }
    }

    ${mediaQueries.superExtraLargeDevices} {
        padding: 60px 0;
        
        .container {
            max-width: 1250px;

            .steps-container {
                .step-col {
                    padding: 0 20px;

                    &:before {
                        width: 112px;
                        height: 56px;
                        right: -55px;
                        top: 90px;
                    }

                    .step-img {
                        width: 250px;
                    }

                    &:last-child {
                        .step-img {
                            width: 280px;
                            margin-bottom: -12px;
                        }

                        .step-title {
                            width: 280px;
                        }
                    }

                    .step-title {
                        font-size: 20px;
                    }
                }
            }
        }
    }
`