import styled from 'styled-components';

const getStateBg=(state) => {
    switch(state) {
        default:
            return '#a7851e';
        // case "s-asl":
        // case "s-wez":
        // case "s-lez":
        //     return "rgb(22 175 106 / .6)";
        case "s-asl":
            return "#18090d";
        case "s-wez":
        case "s-lez":
            return "rgb(11 18 25)"; //"rgb(22 175 106 / .6)";
            //return "rgb(96 165 7 / .6)";
        case "s-at1":
            return "#0b1219";
        case "loss":
            return '#D15D87';
        case "profits":
            return "#4ca23c";
        case "s-iaf":
            return "#0B1015";
        case "s-open":
            return "rgb(29 191 255 / .6)"
    }
};

const getStateTextColor=(state) => {
    switch(state) {
        default:
            return '#fff';
        case "s-wez":
            return "#a6bacf";
        case "s-asl":
            return "#dd5c7f";
        case "s-lez":
            return "#26d54c";
        case "s-at1":
            return "#ede600";
        case "loss":
        case "profits":
        case "s-iaf":
            return "#fff";
        // case "loss":
        //     return '#D15D87';
    }
};

const getBorder=(state) => {
    switch(state) {
        default:
            return '#524720';
        // case "s-asl":
        // case "s-wez":
        // case "s-lez":
        //     return "#16B46C";
        case "s-asl":
            return "#cd2752";        
        case "s-wez":
            return "#15BC6F"; //"#16B46C";
        case "s-lez":
            return "#30d526";
        case "s-at1":
            return "#ede600";
        case "s-iaf":
            return "#000";
        case "s-open":
            return "#1dbfff"
    }
};

export const SignalStatusStyle = styled.span`
    position: absolute;
    padding: 8px 15px;
    top: -15px;
    font-size: 13px;
    /* color: #fff; */
    color: ${ props => getStateTextColor(props.state) };
    letter-spacing: 2px;
    box-shadow: 0px 3px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    border-bottom: 3px solid ${ props => getBorder(props.state) } !important;
    background-color: ${ props => getStateBg(props.state) } ;
    text-shadow: 1px 1px 3px rgba(0,0,0,0.5);
    font-weight: 500;
`;
