import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const PowerBreakdownStyle = styled.section`
    position: relative;
    padding: 60px 0 100px;
    color: #fff;
    background-color: #0a1116;

    .container {
        overflow: hidden;

        & .section-header {
            margin-bottom: 25px;

            & .section-title {
                display: block;
                text-align: center;
                font-size: 23px;
                font-weight: 600;
            }
        }

        & .propower-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 25px 0;
            padding: 0;
            list-style-type: none;

            li {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 28px 0 0;

                .thumbnail {
                    position: relative;
                    width: 300px;
                    margin: 0 auto 15px;

                    img {
                        width: 100%;
                    }
                }

                .text-content {
                    width: 100%;
                    text-align: center;
                }

                &.more-signals,
                &.target-2 {
                    .thumbnail {
                        &::before {
                            display: block;
                            position: absolute;
                            content: ' ';
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center;
                            z-index: 0;
                        }

                        img {
                            position: relative;
                        }
                    }
                }

                &.more-signals {
                    .thumbnail {
                        &::before {
                            background-image: url(/images/subscriptions/more-signals-bg.svg);
                            width: 500px;
                            height: 240px;
                            left: calc(50% - 250px);
                            top: -48px;
                        }
                    }
                }

                &.target-2 {
                    .thumbnail {
                        &::before {
                            background-image: url(/images/subscriptions/target-2-bg.png);
                            width: 500px;
                            height: 340px;
                            left: calc(50% - 250px);
                            top: -94px;
                            opacity: 0.7;
                        }
                    }
                }
            }
        }

        .dogeGains {
            display: none;
            justify-content: center;
            
            img {
                max-width: 300px;
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        padding: 50px 20px 100px;

        & .container {
            .section-header {
                .section-title {
                     font-size: 30px;
                     padding: 0px 20px;
                 }
            }
            & .propower-list {
                li {
                    flex-direction: row;
                    align-items: flex-start;
                    margin: 25px 0 0;

                    .thumbnail {
                        margin-right: 15px;
                    }

                    .text-content {
                        width: calc(100% - 300px);
                        text-align: left;
                        align-self: center;
                    }

                    &.more-signals {
                        .thumbnail {
                            &::before {
                                width: 300px;
                                left: calc(50% - 150px);
                                top: -24px;
                            }
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        padding: 80px 50px 130px;
        background-color: transparent;

        .container {
            .section-header {
                .section-title {
                     font-size: 35px;
                     padding: 0px 100px;
                 }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        .container {
            .propower-list {
                margin-bottom: 50px;
            }

            .dogeGains {
                display: flex;
            } 
        } 
    }       


    ${mediaQueries.superExtraLargeDevices} {
        .container {
            max-width: 900px;

            .section-header {
                .section-title {
                     font-size: 32px;
                 }
            }
        }
    }
`