import { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import createDecorator from 'final-form-focus'
import { forgotPassAction } from '../../redux/actions'
import { ForgotPasswordView } from './ForgotPasswordView'
import { isNotNull, onSetNewState, addPageViewEvent, seo } from '../../utils'

class ForgotPasswordContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            resetEmail: null,
            isResetEmailLoading: false,
            isRedirectedToConfirmationPage: false,
        }

        this.mounted = false
        this.baseState = this.state
        this.decorators = createDecorator()
    }

    componentDidMount() {
        this.mounted = true 

        seo({
            title: "Forgot Password",
            metaDescription: ""
        })
        addPageViewEvent()
    }

    componentDidUpdate(prevProps) {
        const { forgotPass } = this.props
        if (prevProps.forgotPass.loading && forgotPass.loading === false) {
            const { success, msg } = forgotPass.response
            if (success && isNotNull(msg)) {
                onSetNewState(this, { isResetEmailLoading: false, isRedirectedToConfirmationPage: true })
                //toastSuccess(msg, "Success")
            }
        }
    }

    onSubmitForgotPasswordHandler(email) {
        const { dispatch } = this.props
        onSetNewState(this, { isResetEmailLoading: true })
        dispatch(forgotPassAction.forgotPassword(email))
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)
        this.mounted = false
    }

    render() {
        const { isRedirectedToConfirmationPage, isResetEmailLoading } = this.state
        if (isRedirectedToConfirmationPage && isResetEmailLoading === false)
            return <Redirect to={`/account/forgot-password-confirm`} />

        return (
            <ForgotPasswordView
                decorators={this.decorators}
                onSubmitForgotPasswordHandler={email => this.onSubmitForgotPasswordHandler(email)}
                context={this}
                state={this.state}
                {...this.props}
            />
        )
    }
}


function mapStateToProps(state) {
    let { forgotPass } = state
    return {
        forgotPass
    }
}

const connectedForgotPasswordContainer = connect(mapStateToProps)(ForgotPasswordContainer)
export { connectedForgotPasswordContainer as ForgotPasswordContainer }
