import React, { useEffect, useState} from 'react'
import moment from 'moment'
import _ from 'lodash'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle, faTimes } from "@fortawesome/free-solid-svg-icons"
import { Row, Col, Card, Button, Form, Image, Modal } from 'react-bootstrap'
import { Form as FormFinal, Field } from 'react-final-form'
import TextField from '@mui/material/TextField'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { SecurityContainer } from '../Security'
import { ProfileImageUpdate } from './ProfileImageUpdate'
import { SettingsContentWrapper, InputContainer, InputWrapper, UploadWrapper } from '../../styled_components'
import { FormProfileWrapper, FormProfileContainer, FormProfilePreview, ModalCloseButton } from './styled_components'
import { Error, FormButton } from '../../../../styles'
import { setDefaultProfileImage, isNull, isNotNull, nameValidator } from '../../../../utils'
import { listOfCountries } from '../../../../utils/data'

export const ProfileView = (props) => {
    const { userInfo } = props
    const { isProfileEdit, showUploadModal, showProfileLoad } = props.state
    const $this = props.self

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    let [parsedBirthdate, changeBirthdate] = useState(new Date())
    let [genderValue, setGender] = useState('')
    let [countryOfResidenceValue, setCountryOfResidence] = useState('')

    useEffect(() => {
        let parsedDate = Date.parse(userInfo.dateOfBirth)
        changeBirthdate(new Date(parsedDate))
        setGender(userInfo.gender)
        setCountryOfResidence(userInfo.countryOfResidence)
    }, [userInfo])

    const onProfileInfoUpdate = (isEdit) => {
        $this.setState({ isProfileEdit: !isEdit })
    }

    const onProfileValidate = values => {
        const errors = {}

        if (isNull(values.firstname))
            errors.firstname = "First name is required"
        else if (!nameValidator.isValid(values.firstname))
            errors.firstname = "Please enter a valid first name"

        if (isNull(values.lastname))
            errors.lastname = "Last name is required"
        else if (!nameValidator.isValid(values.lastname))
            errors.lastname = "Please enter a valid last name"            

        // validate date of birth
        // must be greater than 18
        if (isNull(values.dateOfBirth))
            errors.dateOfBirth = "Date of birth is required"
        else {
            let isValidDateFormat = moment(values.dateOfBirth, 'DD/MM/yyyy', true).isValid()
            if (!isValidDateFormat) {
                let newDate = new Date(values.dateOfBirth)
                isValidDateFormat = moment(newDate, 'DD/MM/YYYY', true).isValid()
                if (!isValidDateFormat)                    
                    errors.dateOfBirth = "Invalid date"
            } else {                
                let dob = moment(values.dateOfBirth, 'DD/MM/YYYY')
                let currentDate = new Date().toJSON().slice(0,10)+' 01:00:00'
                let age = ~~((Date.now(currentDate) - dob) / (31557600000))
                if (age < 18)
                    errors.dateOfBirth = "Age must be at least 18 years old"
            }
        }

        // validate gender
        if (isNull(values.gender))
            errors.gender = "Please select one"

        // validate country of residence
        if (isNull(values.countryOfResidence))
            errors.countryOfResidence = "Please select one"

        return errors
    }

    const onProfileSubmit = async values => {
        await sleep(300)
        const { 
            firstname, 
            lastname,
            dateOfBirth,
            gender,
            countryOfResidence } = values

        const newData = { 
            firstName: firstname, 
            lastName: lastname,
            dateOfBirth,
            gender,
            countryOfResidence 
        }
        newData.dateOfBirth = moment(newData.dateOfBirth).format('DD/MM/yyyy')
        props.submitProfile(newData)
    }

    const onEditImage = () => $this.setState({ showUploadModal: true })

    const onCloseModal = () => $this.setState({ showUploadModal: false })

    const handleDateChange = (e) => changeBirthdate(moment(e))

    const handleSelectGender = (e) => {
        setGender(e.target.value)
    }

    const handleSelectCountry = (e) => {
        setCountryOfResidence(e.target.value)
    }

    const renderGenderOptions = () => {
        const genderOptions = [{
            value: 'MALE',
            text: 'Male'
        }, {
            value: 'FEMALE',
            text: 'Female'
        }, {
            value: 'OTHER',
            text: 'Other'
        }, {
            value: 'NOT_TO_DISCLOSE',
            text: 'Prefer not to disclose'
        }]

        let mappedGenderOpts = _.map(genderOptions, (value, key) => {
            return (
                <option 
                    key={key} 
                    value={value.value}>{value.text}</option>
            )
        })                                          

        return (
            <Form.Control 
                as="select" 
                value={genderValue} 
                onChange={(e) => handleSelectGender(e)}>
                <option value="">-- select --</option>
                { mappedGenderOpts }                                 
            </Form.Control>
        )
    }

    const renderCountryOptions = () => {
        let mappedCountries = []
        if (isNotNull(listOfCountries)) {
            mappedCountries = _.map(listOfCountries, (value, key) => {
                return (
                    <option 
                        key={key} 
                        value={value.code}>{value.name}</option>
                )
            })
        }

        return (            
            <Form.Control 
                as="select" 
                value={countryOfResidenceValue} 
                onChange={(e) => handleSelectCountry(e)}>
                <option value="">-- select --</option>
                {mappedCountries}
            </Form.Control>
        )
    }

    const renderProfile = (isEdit, user) => {
        if (!isEdit) {
            let dateOfBirth = moment(user.dateOfBirth).format('DD/MM/yyyy') 
            return (
                <>
                    <Form.Group>
                        <Row>
                            <Col sm={3} md={3} lg={3} className="col-form-label">First Name</Col>
                            <Col sm={9} md={9} lg={9} className="col-form-value">
                                {user.firstName}
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group>
                        <Row>
                            <Col sm={3} md={3} lg={3} className="col-form-label">Last Name</Col>
                            <Col sm={9} md={9} lg={9} className="col-form-value">
                                {user.lastName}
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group>
                        <Row>
                            <Col sm={3} md={3} lg={3} className="col-form-label">Date of Birth</Col>
                            <Col sm={9} md={9} lg={9} className="col-form-value">
                                {dateOfBirth}
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group>
                        <Row>
                            <Col sm={3} md={3} lg={3} className="col-form-label">Gender</Col>
                            <Col sm={9} md={9} lg={9} className="col-form-value">
                                {user.gender}
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group>
                        <Row>
                            <Col sm={3} md={3} lg={3} className="col-form-label">Country of Residence</Col>
                            <Col sm={9} md={9} lg={9} className="col-form-value">
                                {user.countryOfResidence}
                            </Col>
                        </Row>
                    </Form.Group>
                </>
            )
        } else {

            return (
                <FormFinal
                    onSubmit={onProfileSubmit}
                    validate={onProfileValidate}
                    initialValues={{ 
                        firstname: user.firstName, 
                        lastname: user.lastName, 
                        email: user.email,
                        dateOfBirth: parsedBirthdate,
                        gender: genderValue,
                        countryOfResidence: countryOfResidenceValue
                    }}
                    render={({ handleSubmit, form, pristine, values }) => (
                        <Form onSubmit={(event) => {
                            const promise = handleSubmit(event);
                            promise && promise.then(() => { form.reset(values); });
                            return promise;
                        }}
                            className="mt-lg-4">
                            <Form.Group>
                                <Row>
                                    <Col sm={3} md={3} lg={3} className="col-form-label">Avatar</Col>
                                    <Col sm={9} md={9} lg={9}>
                                        <FormProfileContainer className="form-profile-container">
                                            <FormProfilePreview>
                                                <Image src={user.profileAvatar} fluid style={{ width: '100%' }} onError={(e) => setDefaultProfileImage(e)} />
                                            </FormProfilePreview>
                                            <UploadWrapper>
                                                <Button variant="primary" onClick={() => onEditImage()}>Add Photo</Button>
                                                <small>JPG OR PNG. Max Size of 700KB</small>
                                            </UploadWrapper>
                                        </FormProfileContainer>
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col sm={3} md={3} lg={3} className="col-form-label">First Name</Col>
                                    <Col sm={9} md={9} lg={9}>
                                        <Field name="firstname" type="text">
                                            {({ input, meta }) => (
                                                <InputContainer className={`${meta.error && meta.touched ? 'invalid-container' : ''}`}>
                                                    <InputWrapper className="default">
                                                        <Form.Control style={{ textTransform: 'capitalize' }}
                                                            {...input}
                                                        />
                                                    </InputWrapper>
                                                    <Error className={`${meta.error && meta.touched ? 'invalid' : ''}`}>
                                                        &nbsp; <FontAwesomeIcon icon={faExclamationCircle} className="icon" />
                                                        {meta.error && meta.touched && (<span> {meta.error} </span>)}
                                                    </Error>
                                                </InputContainer>
                                            )}
                                        </Field>
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col sm={3} md={3} lg={3} className="col-form-label">Last Name</Col>
                                    <Col sm={9} md={9} lg={9}>
                                        <Field name="lastname" type="text">
                                            {({ input, meta }) => (
                                                <InputContainer className={`${meta.error && meta.touched ? 'invalid-container' : ''}`}>
                                                    <InputWrapper className="default">
                                                        <Form.Control style={{ textTransform: 'capitalize' }}
                                                            {...input}
                                                        />
                                                    </InputWrapper>
                                                    <Error className={`${meta.error && meta.touched ? 'invalid' : ''}`}>
                                                        &nbsp; <FontAwesomeIcon icon={faExclamationCircle} className="icon" />
                                                        {meta.error && meta.touched && (<span> {meta.error} </span>)}
                                                    </Error>
                                                </InputContainer>
                                            )}
                                        </Field>
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col sm={3} md={3} lg={3} className="col-form-label">Date of Birth</Col>
                                    <Col sm={9} md={9} lg={9}>
                                        <Field name="dateOfBirth" type="date">
                                            {({ input, meta }) => (
                                                <>
                                                    <InputContainer className={`${meta.error && meta.touched ? 'invalid-container' : ''}`}>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                disableFuture 
                                                                openTo="year"
                                                                inputFormat='dd/MM/yyyy' 
                                                                views={['year', 'month', 'day']}
                                                                value={parsedBirthdate}
                                                                onChange={handleDateChange}
                                                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                                            />    
                                                        </LocalizationProvider>
                                                        <Error className={`${meta.error && meta.touched ? 'invalid' : ''}`}>
                                                            &nbsp; <FontAwesomeIcon icon={faExclamationCircle} className="icon" />
                                                            {meta.error && meta.touched && (<span> {meta.error} </span>)}
                                                        </Error>
                                                    </InputContainer>
                                                </>
                                            )}
                                        </Field>
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col sm={3} md={3} lg={3} className="col-form-label">Gender</Col>
                                    <Col sm={9} md={9} lg={9}>
                                        <Field name="gender" type="text">
                                            {({ input, meta }) => (
                                                <>
                                                    <InputContainer className={`${meta.error && meta.touched ? 'invalid-container' : ''}`}>
                                                        <InputWrapper className="default">
                                                            { renderGenderOptions() }
                                                        </InputWrapper>

                                                        { meta.error && meta.touched && 
                                                            <Form.Text>
                                                                {meta.error}
                                                            </Form.Text>
                                                        }
                                                    </InputContainer>
                                                </>
                                            )}
                                        </Field>
                                    </Col>
                                </Row>
                            </Form.Group>                            

                            <Form.Group>
                                <Row>
                                    <Col sm={3} md={3} lg={3} className="col-form-label">Country of Residence</Col>
                                    <Col sm={9} md={9} lg={9}>
                                        <Field name="countryOfResidence" type="text">
                                            {({ input, meta }) => (
                                                <>
                                                    <InputContainer className={`${meta.error && meta.touched ? 'invalid-container' : ''}`}>
                                                        <InputWrapper className="default">
                                                            { renderCountryOptions() }
                                                        </InputWrapper>

                                                        { meta.error && meta.touched && 
                                                            <Form.Text>
                                                                {meta.error}
                                                            </Form.Text>
                                                        }
                                                    </InputContainer>
                                                </>
                                            )}
                                        </Field>
                                    </Col>
                                </Row>
                            </Form.Group>

                            <FormButton className="text-center">
                                {
                                    showProfileLoad === false ?
                                        <Button type="submit" variant="primary" className="mt-3 mb-3 save-btn">
                                            Save Changes
                                        </Button>
                                        :
                                        <Button type="click" variant="primary" className="mt-3 mb-3 save-btn">
                                            <i className="fas fa-spinner fa-pulse"></i> Saving
                                        </Button>
                                }
                            </FormButton>
                        </Form>
                    )}

                />
            )
        }
    }

    return (
        <>
            <SettingsContentWrapper className="account-settings-wrapper">
                <Row>
                    <Col bsPrefix="col-sm-12">
                        <Card>
                            <Card.Header>
                                <h6 className="mb-0">Profile Info</h6>

                                <Button variant="primary" className="edit-button" onClick={() => onProfileInfoUpdate(isProfileEdit)}>
                                    {isProfileEdit ? "Cancel" : "Edit"}
                                </Button>
                            </Card.Header>
                            <Card.Body>
                                <FormProfileWrapper className="profile-contents">
                                    {renderProfile(isProfileEdit, userInfo)}
                                </FormProfileWrapper>
                            </Card.Body>
                        </Card>

                        <SecurityContainer userInfo={userInfo} sleep={sleep} />
                    </Col>
                </Row>
            </SettingsContentWrapper>

            <Modal className="profile-upload-modal" show={showUploadModal} aria-labelledby="contained-modal-title-vcenter" centered onHide={() => onCloseModal()}>
                <Modal.Header style={{
                    backgroundImage: 'linear-gradient(90deg,#243240,#2c3e4d)',
                    boxShadow: '0px 3px 3px 2px rgba(0,0,0,0.08)',
                    color: '#ced0dd',
                    borderBottom: '1px solid #2a3b4a'
                }}>
                    <Modal.Title>Select A Photo</Modal.Title>
                    <ModalCloseButton onClick={() => onCloseModal()}><FontAwesomeIcon icon={faTimes} /></ModalCloseButton>
                </Modal.Header>
                <Modal.Body style={{
                    backgroundImage: 'linear-gradient(90deg,#243240,#2c3e4d)',
                    boxShadow: '0px 3px 3px 2px rgba(0,0,0,0.08)',
                    color: '#ced0dd',
                    position: 'relative'
                }}>
                    <ProfileImageUpdate
                        {...props}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}