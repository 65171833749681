import React from 'react'
import { WhyUpgradeStyle } from './styles/WhyUpgradeStyle'

export const WhyUpgradeSection = () => {
    return (
        <WhyUpgradeStyle>
            <div className='container text-center'>
                <div className='section-title'>
                    <h2 className='font-weight-bold white-text'>Why Upgrade to Pro?</h2>
                    <span className='white-text'>(In Case it Wasn't Obvious Already)</span>
                </div>

                <div className='icon-box'>
                    <div className='icon-box-col'>
                        <img src='/images/subscriptions/icon-1.png' className='icon-img' alt='' />
                        <h3 className='icon-title white-text'>5x MORE opportunities. 5x LESS variance:</h3>
                        <span className='icon-description'>Stop letting luck dictate your trading day. 500% more trades puts you in complete control of your future and drastically reduces trade variance (luck). 5x more signals is like injecting pure HGH into your dash. It could be a signal frenzy!</span>
                    </div>
                    <div className='icon-box-col'>
                        <img src='/images/subscriptions/icon-2.png' className='icon-img' alt='' />
                        <h3 className='icon-title white-text'>Unleash maximum gains:</h3>
                        <span className='icon-description'>Level-up to making a REAL living trading with the highest ROI targets. T1 is child’s play. T2 averages 2x the gains. T3 is 3x. T4 is almost 6x more than T1. When you over 6x your risk:reward profile, you’ll be unstoppable.</span>
                    </div>
                </div>
                
                <div className='icon-box'>
                    <div className='icon-box-col'>
                        <img src='/images/subscriptions/icon-3.png' className='icon-img' alt='' />
                        <h3 className='icon-title white-text'>Unchain yourself from the screen:</h3>
                        <span className='icon-description'>Automatically lock-in profits on auto-pilot by letting Break-Even Stop-Loss takeover. Once your trade hits the next target, your stop-loss moves up. Never watch a winner turn into a loser again. Our members swear this is worth the price alone.</span>
                    </div>
                    <div className='icon-box-col'>
                        <img src='/images/subscriptions/icon-4.png' className='icon-img' alt='' />
                        <h3 className='icon-title white-text'>Waited on hand and foot:</h3>
                        <span className='icon-description'>Pro support is a lifesaver. Paid members rave about how fast, supportive, and knowledgeable our staff are. They can even help diagnose what went wrong with your trade (ask about our trade doctor!). </span>
                    </div>
                </div>

                <div className='icon-box'>
                    <div className='icon-box-col one-col'>
                        <img src='/images/subscriptions/icon-5.png' className='icon-img' alt='' />
                        <h3 className='icon-title white-text'>Full Market Updates:</h3>
                        <span className='icon-description'>Our market analysts have been dominating the space for years. You’ll get their best, most important updates directly in your inbox. Trade analysis, investment opportunities, which coins to avoid, and even new exciting crypto projects to keep an eye on. Without these, you’re adrift at sea. With them, you know exactly where to go.</span>
                    </div>
                </div>
            </div>
        </WhyUpgradeStyle>
    )
}
