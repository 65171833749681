import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const ProfitableTradingStyle = styled.section`
    position: relative;
    color: #fff;
    padding: 40px 10px;

    .container {
        h2 {
            font-size: 20px;
        }

        .text-with-image {
            .text-content {
                padding: 30px 20px;
                border: 1px solid #394F6A;
                border-radius: 15px;
                background: linear-gradient(356deg, #131B24 0%, #1D2833 100%);

                p {
                    margin-bottom: 30px;
                    color: #B4C4D5;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .img-content {
                img {
                    width: 100%;
                }
            }
        }
    }

    ${mediaQueries. mediumDevices} {

        .container {
            h2 {
                font-size: 30px;
                margin-bottom: 40px;
            }

            .text-with-image {
                display: flex;
                flex-direction: row;
                align-items: center;
                
                .text-content {
                    padding: 30px 40px;
                    width: 60%;
                }
                
                .img-content {
                    width: 40%;
                    text-align: center;

                    img {
                        width: 600px;
                        margin-left: -200px;
                    }
                }
            }
        }
    }

    ${mediaQueries. largeDevices} {
        
        .container {
            h2 {
                padding: 0 40px;
            }
        }
    }

    ${mediaQueries. extraLargeDevices} {
        padding: 60px 0 60px;

        .container {
            h2 {
                font-size: 40px;
            }

            .text-with-image {
                .text-content {
                    width: 52%;
                }
                
                .img-content {
                    width: 48%;
                    
                    img {
                        width: 650px;
                    }
                }
            }
        }
    }
`