import React from 'react'
import { connect } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {
    PeriodRangeWrapper, DatePickerWrapper,
    DatePickerContainer, DatePickerLabel, DatePickerDivider,
    DatePickerStart
} from './styled_components/PeriodRangeStyle'
import { isNotNull, onSetNewState } from '../../../../utils'

class PeriodRange extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            startChange: false,
            endChange: true,
        }
        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
    }

    componentWillUnmount() {
        this.mounted = false
        onSetNewState(this, this.baseState)
    }

    onStartDateChangeHandler = date => {
        if (this.mounted)
            onSetNewState(this, {
                startDate: date,
                endDate: date,
                endChange: false
            })
    }

    onEndDateChangeHandler = date => {
        if (this.mounted) {
            onSetNewState(this, {
                endDate: date,
                endChange: true
            })

            const data = {
                periodRange: 0,
                startDate: this.state.startDate,
                endDate: date
            }
            this.props.onPeriodChange(data, "From To")
        }
    }

    onPeriodChangeHandler = value => {
        if (this.mounted) {
            onSetNewState(this, {
                startDate: new Date(),
                endDate: new Date(),
                endChange: true
            })

            const data = {
                periodRange: value.key,
                startDate: null,
                endDate: null
            }

            this.props.onPeriodChange(data, value.value)
        }
    }

    render() {
        const { loader, filters } = this.props.periodRangeFilter
        const { selectedFilter, isEnable } = this.props

        let dropdownItem = null
        if (!loader && isNotNull(filters)) {

            dropdownItem = filters.map((val, key) => {
                if (val.value === "From To" && val.key === 0) {
                    return (
                        <DatePickerWrapper key={key}>
                            <DatePickerContainer>
                                <DatePickerLabel>From</DatePickerLabel>
                                <DatePickerStart>
                                    <DatePicker
                                        disabled={this.state.startChange}
                                        selected={this.state.startDate}
                                        onChange={this.onStartDateChangeHandler}
                                        popperModifiers={{
                                            offset: {
                                                enabled: true,
                                                offset: "5px, 10px"
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: false,
                                                boundariesElement: "viewport"
                                            }
                                        }}
                                    />
                                    <DatePickerDivider />
                                </DatePickerStart>

                            </DatePickerContainer>
                            <DatePickerContainer>
                                <DatePickerLabel>To</DatePickerLabel>
                                <DatePicker
                                    disabled={this.state.endChange}
                                    selected={this.state.endDate}
                                    onChange={this.onEndDateChangeHandler}
                                    minDate={this.state.startDate}
                                    popperPlacement="bottom-end"
                                    popperModifiers={{
                                        offset: {
                                            enabled: true,
                                            offset: "5px, 10px"
                                        },
                                        preventOverflow: {
                                            enabled: true,
                                            escapeWithReference: false,
                                            boundariesElement: "viewport"
                                        }
                                    }}
                                />
                            </DatePickerContainer>
                        </DatePickerWrapper>
                    )
                } else
                    return (
                        <Dropdown.Item key={key} onClick={() => this.onPeriodChangeHandler(val)}>{val.value}</Dropdown.Item>
                    )
            })
        }

        return (
            <PeriodRangeWrapper>
                <Dropdown >
                    <Dropdown.Toggle id="dropdown-date" disabled={isEnable}>
                        {selectedFilter}
                    </Dropdown.Toggle>
                    <Dropdown.Menu alignRight>
                        {dropdownItem}
                    </Dropdown.Menu>
                </Dropdown>
            </PeriodRangeWrapper>
        )
    }

}

function mapStateToProps(state) {
    const { periodRangeFilter } = state
    return {
        periodRangeFilter
    }
}

const connectedPeriodRange = connect(mapStateToProps)(PeriodRange)
export { connectedPeriodRange as PeriodRange }