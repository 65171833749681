import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { notificationActions } from '../../../../redux/actions'
import { notificationActionTypes } from '../../../../redux/CONSTANTS'
import { ClosedTradeNotificationModal } from './ClosedTradeNotificationModal'
import { isNotNull, onSetNewState } from '../../../../utils'

class TradeClosedEarlyContainer extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            closedEarlyNotifications: null,
            totalClosedEarly: 0,
            showClosedEarlyModal: false
        }

        this.mounted = false
        this.notificationHubFeedConnection = null
        this.notificationSound = null
    }

    async componentDidMount() {
        this.mounted = true

        const { dispatch, authentication } = this.props
        const { user } = authentication
        if (isNotNull(user)) {
            // Get Notifications
            dispatch(notificationActions.getNotificationLists())
        }

        this.notificationSound = document.getElementById('closed-signal-audio')
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.mounted) {
            const { notificationLists } = this.props
            const { totalClosedEarly, showClosedEarlyModal } = this.state

            if (isNotNull(notificationLists)) {
                if (prevProps.notificationLists !== notificationLists) {
                    const { loader, notification_lists } = notificationLists
                    if (!loader && 
                        isNotNull(notification_lists) && 
                        isNotNull(notification_lists.data) && 
                        notification_lists.data.length > 0) {
                            let totalUnreadClosedEarly = 0
                            let closedEarlyTrades = []
                            
                            _.forEach(notification_lists.data, n => {
                                if (n.type === 4 && n.isRead === false) {
                                    totalUnreadClosedEarly++
                                    closedEarlyTrades.push(n)
                                }
                            })
    
                            onSetNewState(this, {
                                totalClosedEarly: totalUnreadClosedEarly,
                                closedEarlyNotifications: closedEarlyTrades
                            })
                        }
                }
            }
            

            if (prevState.totalClosedEarly !== totalClosedEarly ) { 
                if (parseInt(totalClosedEarly) > 0 && !showClosedEarlyModal) {
                    onSetNewState(this, {
                        showClosedEarlyModal: true
                    })
                } else {
                    onSetNewState(this, {
                        showClosedEarlyModal: false
                    })
                }
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    onMarkAsRead = (id) => {
        const { dispatch, notificationLists } = this.props
        const responseData = {
            "notification_id": id,
            "isMarkedAll": false
        }
        dispatch(notificationActions.markNotificationAsRead(responseData))

        let notification = [...notificationLists.notification_lists.data]
        _.forEach(notification, n => {
            if (isNotNull(n) && n.isRead === false && n.id === id)
                n.isRead = true
        })
        let notification_lists = {
            data: notification
        }
        dispatch({ type: notificationActionTypes.GET_ALL_NOTIFICATION_LISTS_SUCCESS, notification_lists })
    }

    render() {
        return (
            <ClosedTradeNotificationModal 
                state={this.state}
                eventHandlers={{
                    onMarkAsRead: this.onMarkAsRead.bind(this)}} />
        )
    }
}

function mapStateToProps(state) {
    const { authentication, notificationLists } = state
    return {
        authentication,
        notificationLists
    }
}

const connectedTradeClosedEarlyContainer = connect(mapStateToProps)(TradeClosedEarlyContainer)
export { connectedTradeClosedEarlyContainer as TradeClosedEarlyContainer }