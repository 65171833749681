import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faListUl } from "@fortawesome/free-solid-svg-icons"
import { Container, Row, Col, Navbar, Nav, Image, OverlayTrigger, Tooltip, SafeAnchor } from 'react-bootstrap'
import jQuery from 'jquery'
import { NotificationBell } from '../../components'
import { NavBar } from '../../styles/navigations'
import { authActions, themeActions } from '../../redux/actions'
import { HeaderWrapper, HeaderLogo, ThemeSwitchWrapper, AvatarBlock } from './styled_components'
import { pages, app } from '../../navigation/CONSTANTS'
import { isLiveVersion } from '../../services/CONSTANTS'
import { isNotNull, isNull, Dimension, setDefaultProfileImage, getSubDomain, onSetNewState } from '../../utils'

class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showSidebar: true,
            isBetaVersion: getSubDomain()
        }
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        window.addEventListener('resize', () => Dimension.updateDimensions(this))
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => Dimension.updateDimensions(this))
        this.mounted = false
    }

    setDefaultProfileImage = (e) => {
        e.target.src = "/images/def-user.png"
    }

    handleSidebar = () => {
        onSetNewState(this, function (prev) {
            if (prev.showSidebar) {
                jQuery(".sidebar-size").addClass("show")
                jQuery("#sidebar-opt").addClass("show")
                jQuery("#sidebar-opt-prof").addClass("show")
                jQuery(".fsp").addClass("show")
                jQuery(".to-hide-items").addClass("show")
                jQuery(".tooltip").addClass("showToolTip")
                jQuery("#customer-layout-wrapper").addClass("sidebar-open")

                let isWalletOpen = jQuery('.main-content').hasClass('show')
                if (isWalletOpen) {
                    jQuery(".main-content").removeClass('show')
                    jQuery("#sidebar-opt-prof").removeClass('show-wallets')
                    jQuery(".wallets").removeClass("show hide")
                    jQuery("#wallet-click").click()
                }
            } else {
                jQuery(".sidebar-size").removeClass("show")
                jQuery("#sidebar-opt").removeClass("show")
                jQuery("#sidebar-opt-prof").removeClass("show")
                jQuery(".fsp").removeClass("show")
                jQuery(".to-hide-items").removeClass("show")
                jQuery(".tooltip").removeClass("showToolTip")
                jQuery("#customer-layout-wrapper").removeClass("sidebar-open")
            }

            return { showSidebar: !prev.showSidebar }
        })
    }

    handleLogout = () => {
        const { dispatch } = this.props

        dispatch(authActions.logout())
    }

    handleSetTheme = () => {
        const { theme } = this.props.userTheme
        const { dispatch } = this.props
        if (isNotNull(theme)) {
            const { isDarkMode } = theme
            if (isNotNull(isDarkMode)) {
                if (isDarkMode)
                    dispatch(themeActions.saveTheme(false))
                else
                    dispatch(themeActions.saveTheme(true))
            }
        }
    }

    onVersionChangeHandler = (event) => {
        const accessToken = JSON.parse(localStorage.getItem("profitfarmers.token"))
        const subDomain = {
            beta: "test",
            live: "staging"
        }

        if (isLiveVersion) {
            subDomain.beta = "beta"
            subDomain.live = "app"
        }

        if (isNotNull(accessToken) && isNotNull(accessToken.access)) {
            const isBeta = event.target.checked
            onSetNewState(this, { isBetaVersion: isBeta })
            if (isNotNull(isBeta)) {
                if (isBeta)
                    window.location.href = `https://${subDomain.beta}.profitfarmers.com/account/auto-login?token=${accessToken.access.token}`
                else
                    window.location.href = `https://${subDomain.live}.profitfarmers.com/account/auto-login?token=${accessToken.access.token}`
            }
        }
    }

    renderSwitchVersion = () => {
        const { isBetaVersion } = this.state

        return (
            <>
                <ThemeSwitchWrapper className="version-switcher mr-0">
                    <label className="theme-switch active">
                        <div className="input-cont">
                            <span className="toggle-wrapper">
                                <input
                                    type="checkbox"
                                    defaultChecked={isBetaVersion}
                                    onChange={(e) => this.onVersionChangeHandler(e)}
                                />

                                <i className={`theme-toggle active`} />
                            </span>
                        </div>
                    </label>
                </ThemeSwitchWrapper>

                <span className="switch-label">
                    <span className="toggle-label">
                        <span className="beta">Beta</span>
                    </span>
                    
                    <div className="toggle-tooltip">
                        <OverlayTrigger placement="right" delay={(window.innerWidth > 766 && { show: 200, hide: 1000 })} overlay={
                            <Tooltip id='tooltip-right' className="signals-tooltip">
                                Enable experimental features.
                                        <SafeAnchor href="https://profitfarmers.freshdesk.com/en/support/solutions/articles/47001183207-beta-mode-current-features" target="_blank" className="ml-1">
                                    Learn more
                                        </SafeAnchor>
                            </Tooltip>
                        }>
                            <span className="icon-question">
                                <i className="fas fa-info-circle"></i>
                            </span>
                        </OverlayTrigger>
                    </div>
                </span>
            </>
        )
    }

    render() {
        const { page, history, isEmailPromt, } = this.props

        switch (page) {
            case pages.login:
            default:
                return (
                    <NavBar style={{ marginTop: '45px' }}>
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <Col md={6} lg={4} className="brand-wrapper">
                                        <img src="/images/brand/full-logo.png" alt="" className="img-fluid" />
                                    </Col>
                                </Col>
                            </Row>
                        </Container>
                    </NavBar>
                )
            case pages.payment:
                return (<>Onboarding</>)
            case pages.signals:
            case pages.wallet:
            case pages.notification:
            case pages.statistic:
            case pages.scanner.priceActionScanner:
            case pages.scanner.rsiScanner:
            case pages.orderHistory:
            case pages.setting.profile:
            case pages.setting.subscription:
            case pages.setting.autoTrade:
            case pages.setting.notificationSettings: {
                const { user } = this.props.authentication
                const { isDarkMode } = this.props.setThemeSettings

                var profileAvatar = null
                var userLastName = " "
                var darkMode = true

                if (isNotNull(user)) {
                    var userFirstName = user.firstName.slice(0, 1).toUpperCase() + user.firstName.slice(1, user.firstName.length)
                    userLastName = isNull(user.lastName) ? " " : " " + user.lastName[0].toUpperCase()
                    profileAvatar = user.avatarUrl
                }

                if (isNotNull(isDarkMode))
                    darkMode = isDarkMode

                return (
                    <HeaderWrapper className={`header-bar has-gradient-bg sidebar-size theme-top-navbar ${isEmailPromt ? "show-email" : ""} `}>
                        <Navbar className="main-navbar" expand="lg">
                            {window.innerWidth <= 1199 &&
                                <div id="sidebar-btn-bd" className="btn-sidebar btn-none mr-auto" onClick={this.handleSidebar}>
                                    <FontAwesomeIcon icon={faListUl} />
                                    {/* {window.innerWidth > 766 && window.innerWidth <= 1199 && isNotNull(stepsCompleted) && stepsCompleted && isNotNull(user) && user.isPaid &&
                                        <>{this.renderSwitchVersion()}</>
                                    } */}
                                </div>
                            }
                            <Nav as="ul" className="header-logo-wrapper">
                                <Nav.Item as="li">
                                    <HeaderLogo>
                                        <Link to="/dashboard" className="navbar-brand">
                                            <Image src={app.logo} fluid />
                                            <span className="app-name">{app.title}</span>
                                        </Link>

                                    </HeaderLogo>
                                </Nav.Item>
                            </Nav>

                            <Nav className="ml-auto" as="ul">
                                {/* {window.innerWidth > 1199 && isNotNull(stepsCompleted) && stepsCompleted && isNotNull(user) && user.isPaid &&
                                    <>{this.renderSwitchVersion()}</>
                                } */}

                                <ThemeSwitchWrapper className="theme-desktop">
                                    <label className="theme-switch active">
                                        <div className="input-cont">
                                            <span className="toggle-label">
                                                <i className="fas fa-sun icon-theme"></i>
                                            </span>
                                            <span className="toggle-wrapper">
                                                <input
                                                    type="checkbox"
                                                    defaultChecked={darkMode}
                                                    onChange={this.handleSetTheme}
                                                />

                                                <i className={`theme-toggle active`} />
                                            </span>

                                            <span className="toggle-label">
                                                <i className="far fa-moon icon-theme"></i>
                                            </span>
                                        </div>
                                    </label>
                                </ThemeSwitchWrapper>

                                <span className="notif-profile">
                                    <Nav.Item as="li" className="notif-bell">
                                        <NotificationBell 
                                            history={history} 
                                            notificationItemPreview={this.props.notificationItemPreview} 
                                            onReviewClosedEarlyItem={this.props.onReviewEarlyClosedItem}
                                            onReviewAutoClosedEarlyItem={this.props.onReviewAutoEarlyClosedItem}
                                            onReviewOrderResultItem={this.props.onReviewOrderResultItem} />
                                    </Nav.Item>
                                    <Nav.Item as="li" className="ml-3">
                                        <AvatarBlock className="avatar-block">
                                            <Link to="/account/profile" className="account-profile">
                                                <span className="user-avatar">
                                                    <Image src={`${profileAvatar}?${Date.now()}`} alt="user avatar" onError={(e) => setDefaultProfileImage(e)} />
                                                </span>
                                            </Link>
                                            <span className="user-name">{userFirstName}{userLastName}</span>
                                        </AvatarBlock>
                                    </Nav.Item>
                                </span>

                                {window.innerWidth > 766 &&
                                    <>
                                        <li className="nav-item-divider"></li>

                                        <Nav.Item as="li">
                                            <div>
                                                <button type="submit" onClick={this.handleLogout} className="toggle-btn ml-auto">
                                                    <span className="logout-wrapper">
                                                        {/*<i className="fas fa-sign-out-alt"></i>*/}
                                                        <span className="logout-text">Log out</span>
                                                    </span>
                                                </button>
                                            </div>
                                        </Nav.Item>
                                    </>
                                }
                            </Nav>
                        </Navbar>
                    </HeaderWrapper>
                )
            }
        }
    }
}

function mapStateToProps(state) {
    const { authentication, userTheme, setThemeSettings, checklistStatus } = state
    return {
        authentication,
        setThemeSettings,
        userTheme,
        checklistStatus
    }
}

const connectedHeader = connect(mapStateToProps)(Header)
export {
    connectedHeader as Header
}
