
import { ButtonGroup, Button as MaterialButton } from '@material-ui/core'
import { isNotNull } from "../../utils"
import { FeedButton, ProfitAndLossLabel } from "./styled_components"

export const OrderPositionsItemView = props => {
    const { symbol, id } = props

    const renderPositionItem = (id, ctxProperty) => {

        if (isNotNull(ctxProperty)) {
            return (
                <tr key={`${ctxProperty.id}-${symbol}-${ctxProperty.positionAmt}`} className="expanded-data">

                    { window.innerWidth >= 1024 &&
                        <>
                            <td><p className="coin-name">{symbol}</p></td>
                            <td>{ctxProperty.positionAmt}</td>
                            <td>Entry: {ctxProperty.entryPrice}<br /> Mark: {ctxProperty.markPrice}</td>
                            <td>{ctxProperty.liquidationPrice}</td>
                            <td>{ctxProperty.leverage} ({ctxProperty.marginType})</td>
                            <td>PNL:
                                <ProfitAndLossLabel className={Math.sign(parseFloat(ctxProperty.unRealizedProfit)) >= 0 ? 'success-label' : 'danger-label'}>
                                    {` ${ctxProperty.unRealizedProfit}`}
                                </ProfitAndLossLabel>
                            </td>
                            <td>
                                <ButtonGroup className="feed-toggle" color="primary" aria-label="outlined primary button group">
                                    {/* <FeedButton as={MaterialButton} onClick={() => console.log("limit")} className="limit-btn">LIMIT</FeedButton> */}
                                    <FeedButton as={MaterialButton} onClick={() => props.onClosePositionHandler(props)} className="market-btn">CLOSE POSITION</FeedButton>
                                </ButtonGroup>
                            </td>
                        </>
                    }

                    {
                        window.innerWidth < 1024 &&
                        <td colSpan={7}>
                            <div className="expanded-wrapper">
                                <span className="col-sm-6 col-md-8">SYMBOL</span>
                                <span className="col-sm-6 col-md-4">{ctxProperty.symbol}</span>
                            </div>

                            <div className="expanded-wrapper">
                                <span className="col-sm-6 col-md-8">SIZE</span>
                                <span className="col-sm-6 col-md-4">{ctxProperty.positionAmt}</span>
                            </div>

                            <div className="expanded-wrapper">
                                <span className="col-sm-6 col-md-8">PRICE</span>
                                <span className="col-sm-6 col-md-4">Entry: {ctxProperty.entryPrice} | Mark: {ctxProperty.markPrice}</span>
                            </div>

                            <div className="expanded-wrapper">
                                <span className="col-sm-6 col-md-8">LIQ. PRICE</span>
                                <span className="col-sm-6 col-md-4">{ctxProperty.liquidationPrice}</span>
                            </div>

                            <div className="expanded-wrapper">
                                <span className="col-sm-6 col-md-8">MARGIN</span>
                                <span className="col-sm-6 col-md-4">{ctxProperty.leverage} ({ctxProperty.marginType})</span>
                            </div>

                            <div className="expanded-wrapper">
                                <span className="col-sm-6 col-md-8">UNREALIZED ROE %)</span>
                                <span className="col-sm-6 col-md-4">{ctxProperty.unRealizedProfit}</span>
                            </div>

                            <div className="expanded-wrapper">
                                <div>ACTIONS</div>
                                <div style={{ textAlign: 'right' }}>
                                    <ButtonGroup className="feed-toggle" color="primary" aria-label="outlined primary button group">
                                        <FeedButton as={MaterialButton} onClick={() => props.onClosePositionHandler(props)} className="market-btn">CLOSE POSITION</FeedButton>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </td>
                    }
                </tr >
            )
        }
    }

    return (
        <tbody className="order-table">
            
            {renderPositionItem(id, props)}
        </tbody>
    )
}