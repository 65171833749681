import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const ConnectToExchangeStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    max-height: 100%;

    .help-section,
    .exchange-form-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .help-section {
        display: flex;
        align-items: flex-start;
        width: 100%;
        height: auto;
        background-color: #2A3B4A;
        padding: 30px 25px;

        h3, ol {            
            color: #fff !important;
            letter-spacing: 0.8px;
        }

        h3 {
            font-size: 15px;
        }

        ol {
            margin: 0;
            padding: 0 0 0 18px;
            font-size: 12px;
            line-height: 1.5;
        }

        .help-video-wrapper {
            display: block;
            width: 100%;
            margin: 0 auto 20px;
            border-radius: 6px;
            outline: 2px solid #29323c;
            background-color: #080b0e;
            box-shadow: 0 0 20px 12px rgba(0, 0, 0, 0.3);
            overflow: hidden;

            iframe {
                display: block;
                width: 100%;
                min-height: 195px;
                background-color: #080b0e;
            }
        }
    }

    .exchange-form-container {  
        display: flex;
        flex-direction: column;
        align-items: center;   
        justify-content: center;   
        width: 100%;
        height: auto;
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        /* border-top-left-radius: 10px;
        border-bottom-left-radius: 10px; */

        .exchange-logo {
            display: flex;
            margin: 10px auto 15px;
        }
        
        .exchange-form-wrapper {
            h1 {
                text-align: center;
                font-size: 21px;
                font-weight: 600;
                color: #0D141E;
            }

            p {
                text-align: center;
                font-size: 14px;
                color: #515F6E;

                svg,
                small {
                    color: #38557a;
                }
            }

            form {
                .exchange-form {
                    width: 100%;
                    margin: 0 auto;

                    .required {
                        color: #993999;
                        font-size: 14px;
                    }

                    .form-group {
                        .input-wrapper {
                            &.is-invalid {
                                .form-control {
                                    border-color: #993956;
                                }
                            }

                            .form-text {
                                color: #df5481;
                                font-size: 14px;
                            }
                        }
                    }
                }
                
                p {
                    font-size: 15px;
                }
            }

            .exchange-form-actions {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                button {
                    display: inline-block;
                    border: none;
                    outline: none;
                    margin-bottom: 5px;
                    
                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:disabled {
                        opacity: 0.4;
                    }
                }

                .submit-keys-btn {
                    background: #1273EB 0% 0% no-repeat padding-box;
                    color: #fff;
                    font-size: 18px;
                    font-weight: 600;
                    border-radius: 32px;
                    padding: 13px 30px;
                    width: auto;
                    height: 54px;
                    transition: all 0.5s ease 0s;

                    &:hover {
                        transform: scale(1.05);
                        backface-visibility: hidden;
                        overflow: auto;
                    }

                    &:disabled {
                        &:hover {
                            transform: none;
                        }
                    }
                }

                .skip-btn {
                    font-size: 15px;
                    font-weight: 500;
                    background: transparent 0% 0% no-repeat padding-box;
                    padding: 8px 20px;
                    height: auto;
                    color: #4e74a3;
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        flex-direction: row;
        max-height: 477px;

        .exchange-form-container {
            width: calc(100% - 440px);
            height: 498px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            .exchange-logo {
                margin: 0 auto 15px;
            }

            .exchange-form-wrapper {
                h1 {
                    font-size: 32px;
                }

                form {
                    .exchange-form {
                        width: 480px;
                    }

                    p {
                        font-size: 14px;
                    }
                }
            }
        }
        
        .help-section {
            width: 440px;
            height: 498px;
            padding: 30px 25px;

            .help-video-wrapper {
                iframe {
                    min-height: 209px;
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {

    }
`