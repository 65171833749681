import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const WeeklyTotalMarketCapSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    background-color: #fff;
    padding: 60px 0;

    .container {
        text-align: center;
        color: #000000;

        .section-title {
            font-size: 40px;
            font-weight: 800;
            color: #091017;
            text-align: center;
            margin: 0 auto 25px;
            max-width: 900px;

            small {
                font-weight: 800;
            }
        }

        .mc-chart {
            display: block;
            margin: 0 auto 25px;
            width: 100%;

            img {
                width: 100%;
            }
        }

        h3 {
            font-weight: 700;
        }

        .blurb-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 0 auto 25px;
            padding: 0;
            list-style-type: none;
            max-width: 560px;

            li {
                display: flex;
                align-items: flex-start;
                margin-bottom: 15px;

                svg {
                    margin-right: 10px;
                }

                span {
                    display: inline-block;
                    max-width: 504px;
                    text-align: left;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {

    }

    ${mediaQueries.largeDevices} {
        .container {
            .mc-chart {
                width: 760px;
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {

    }
`