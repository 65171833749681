import { useState } from 'react'
import moment from 'moment'
import { SafeAnchor } from 'react-bootstrap'
import { DynamicCheckout } from '../../../components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { isNotNull } from '../../../utils'
import { RecapSectionStyle } from './styles'

export const RecapSection = (props) => {
    let [remainingDays, setRemainingDays] = useState('')
    let [remainingHours, setRemainingHours] = useState('')
    let [remainingMinutes, setRemainingMinutes] = useState('')
    let [remainingSeconds, setRemainingSeconds] = useState('')

    let [isEarlyBirdOffer, setIsEarlyBirdOffer] = useState(false)
    let [isFinalYearlyOffer, setIsFinalYearlyOffer] = useState(false)

    let parsedDate = Date.parse(props.validUntil)
    let expiry = new Date(parsedDate)
        expiry = moment(expiry).format('MM/DD/yyyy h:mm:ss')

    const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

    let today = new Date(),
        dd = String(today.getDate()).padStart(2, "0"),
        mm = String(today.getMonth() + 1).padStart(2, "0"),
        yyyy = today.getFullYear(),
        nextYear = yyyy + 1,
        dayMonth = moment(expiry).format('MM/DD/')

    today = mm + "/" + dd + "/" + yyyy
    if (today > expiry) {
        expiry = dayMonth + nextYear
    }

    const countDown = new Date(expiry).getTime(),
        x = setInterval(function() {    
            const now = new Date().getTime(),
                distance = countDown - now

            setRemainingDays(Math.floor(distance / (day)))
            setRemainingHours(Math.floor((distance % (day)) / (hour)))
            setRemainingMinutes(Math.floor((distance % (hour)) / (minute)))
            setRemainingSeconds(Math.floor((distance % (minute)) / second))

            if (isNotNull(remainingDays)) {
                if (parseInt(remainingDays) >= 14) {
                    if (!isEarlyBirdOffer)
                        setIsEarlyBirdOffer(true)

                    if (isFinalYearlyOffer)
                        setIsFinalYearlyOffer(false)
                } else {                        
                    if (isEarlyBirdOffer)
                        setIsEarlyBirdOffer(false)
                    
                    if (!isFinalYearlyOffer)  
                        setIsFinalYearlyOffer(true)
                }
            }
            if (distance < 0) {
                clearInterval(x)
            }
            //seconds
        }, 1000)

    return (
        <RecapSectionStyle>
            <div className='container'>
                <h2 className='section-title'>Secure Your Crypto Trading Future Now For Just <span className='em'>{isEarlyBirdOffer && <>$4</>}{isFinalYearlyOffer && <>$5</>} Per Day</span></h2>
                <p>Let's recap.</p>
                <p>You've already gotten access to <strong>The Pro Trader 5x Blueprint</strong>. You have the knowledge it takes to start making trading a serious long-term income.</p>
                <p>Taking this special offer today will give you:</p>
                
                <ul className='special-offer'>
                    <li>
                        <FontAwesomeIcon icon={faCheck} />
                        <span>12 Months Subscription to ProfitFarmers PRO - <span className='em'>$2,999</span></span>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faCheck} />
                        <span>The Elite Trading Strategies Pack - <span className='em'>$499</span></span>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faCheck} />
                        <span>2 Trade Doctor Sessions - <span className='em'>$499</span></span>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faCheck} />
                        <span>The Crypto Trading Booster Pack - <span className='em'>$99</span></span>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faCheck} />
                        <span>7 Steps to 1000x Altcoins - <span className='em'>$99</span></span>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faCheck} />
                        <span>The Dark Edition Spotify Playlist - <span className='em'>$49</span></span>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faCheck} />
                        <span>Trader Education Cards - <span className='em'>$49</span></span>
                    </li>
                </ul>

                <p>And you'll be first in line to escape, and benefit from, the CBDC vs Bitcoin hellscape that's evolving.</p>

                <div className='price-tag-wrapper'>
                    <div className='img-wrapper'>
                        <img src='/images/layout/the-package.png' alt='Exclusive Price for PRO Trader' />
                    </div>
                    <div className='text-col'>
                        <div className='original-price'>
                            <span className='crashed-out-price'>$4,300</span>
                            <label>TOTAL VALUE</label>
                        </div>

                        { isEarlyBirdOffer && 
                            <>
                                <div className='new-price'>
                                    <span className='new-price-value'>$1,799</span>
                                    <span className='new-price-desc'>Exclusive price for Pro Trader<br />5x Blueprint graduates</span>
                                </div>
                                
                                <h4>But wait, there's more!</h4>
                                <p>There's an additional -20% ($300) Early Bird discount for students that upgrade.</p>
                            </>
                        }
                        
                        { isFinalYearlyOffer && 
                            <>
                                <h4 style={{ marginTop: '10px' }}>Exclusive price for Pro Trader 5x Blueprint graduates</h4>
                                <h5>That's right...ACT NOW and PAY JUST</h5>
                            </>
                        }

                        <div className='discounted-price'>
                            <span className='discounted-price-value'>{ isEarlyBirdOffer && <>$1,499</> }{ isFinalYearlyOffer && <>$1,799</>}</span>
                            <span><img src='/images/layout/only-tag.svg' alt='' /></span>
                        </div>

                        <h6 className='impt'>When you secure your offer before times run out!</h6>
                    </div>
                </div>

                <div className='offer-validity-timer'>
                    <ul>
                        <li>
                            <span id='rc-days' className='counter'>{parseInt(remainingDays) < 10 ? '0': ''}{remainingDays}</span>
                            <small>DAYS</small>
                        </li>
                        <li>
                            <span id='rc-hours' className='counter'>{parseInt(remainingHours) < 10 ? '0': ''}{remainingHours}</span>
                            <small>HOURS</small>
                        </li>
                        <li>
                            <span id='rc-minutes' className='counter'>{parseInt(remainingMinutes) < 10 ? '0': ''}{remainingMinutes}</span>
                            <small>MINUTES</small>
                        </li>
                        <li>
                            <span id='rc-seconds' className='counter'>{parseInt(remainingSeconds) < 10 ? '0': ''}{remainingSeconds}</span>
                            <small>SECONDS</small>
                        </li>
                    </ul>
                </div>

                <div className='checkout-btn'>
                    { isEarlyBirdOffer && 
                        <DynamicCheckout 
                            text="Sign Up Now"
                            position="centered" 
                            userInfo={props.userInfo} 
                            offer={props.subscriptionPlan} />
                    }

                    { isFinalYearlyOffer && 
                        <DynamicCheckout 
                            text="Sign Up Now"
                            position="centered" 
                            userInfo={props.userInfo} 
                            offer={props.finalYearlyOffer} />
                    }
                </div>

                <p className='little-note'>Note: Crypto payments are welcome, please <SafeAnchor href="https://www.profitfarmers.com/crypto-payment/" rel="noreferrer">contact our support team directly to organize this</SafeAnchor></p>
            </div>
        </RecapSectionStyle>
    )
}