import React from 'react'
import { connect } from 'react-redux'
import iziToast from 'izitoast'
import { subscriptionActions, surveyActions } from '../../../../redux/actions'
import { SubscriptionView } from './SubscriptionView'
import { isNotNull, onSetNewState, addPageViewEvent, seo } from '../../../../utils'

class SubscriptionContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showCancelSub: false,
            showSubscribeNow: false,
            btnDisable: false,
            isCancel: false
        }
        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        this.props.dispatch(surveyActions.getSurvey())   

        seo({
            title: "Account Subscription Settings",
            metaDescription: ""
        })
        addPageViewEvent()
    }

    componentDidUpdate(prevProps) {
        const { cancelSubscription } = this.props
        if (isNotNull(cancelSubscription) &&
            isNotNull(cancelSubscription.loading) &&
            prevProps.cancelSubscription.loading === true &&
            cancelSubscription.loading === false &&
            isNotNull(cancelSubscription.subscription)) {
            const { success } = cancelSubscription.subscription
            if (success)
                iziToast.success({
                    position: 'topRight',
                    title: 'Success',
                    message: 'Subscription Cancelled!',
                    displayMode: 1,
                })
            else
                iziToast.warning({
                    position: 'topRight',
                    title: 'Warning',
                    message: 'Something went wrong, Please try again!',
                    displayMode: 1,
                })

            onSetNewState(this, {
                showCancelSub: false,
                isCancel: false,
                btnDisable: false
            })
        }
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)
        this.props.dispatch(surveyActions.resetSurvey())
        this.mounted = false
    }

    onCancelSubscriptionHandler = (data) => {
        const { dispatch } = this.props
        onSetNewState(this, { btnDisable: true, isCancel: true })
        dispatch(subscriptionActions.cancelSubscription())
        dispatch(surveyActions.saveSurvey(data))
    }

    onUpdateSurvey = (data) => {
        if (isNotNull(data))
            this.props.dispatch(surveyActions.updateSurvey(data))
    }

    onUpdateStep = (step) => {
        if (isNotNull(step))
            this.props.dispatch(surveyActions.updateStep(step))
    }

    onHideCancelHandler = () => {
        onSetNewState(this, { showCancelSub: false })
        this.props.dispatch(surveyActions.resetSurvey())
    }

    onUpdateAnswer = (data) => {
        if (isNotNull(data))
            this.props.dispatch(surveyActions.updateAnswer(data))
    }

    render() {
        return (
            <SubscriptionView
                self={this}
                state={this.state}
                cancelSub={this.onCancelSubscriptionHandler}
                updateSurvey={this.onUpdateSurvey}
                updateAnswer={this.onUpdateAnswer}
                updateStep={this.onUpdateStep}
                onHideCancel={this.onHideCancelHandler}
                {...this.props}
            />
        )
    }
}

function mapStateToProps(state) {
    const { cancelSubscription, cancellationSurvey } = state
    return {
        cancelSubscription,
        cancellationSurvey
    }
}
const connectedSubscriptionContainer = connect(mapStateToProps)(SubscriptionContainer)
export { connectedSubscriptionContainer as SubscriptionContainer }