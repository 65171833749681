import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const LimitedOfferStyle = styled.div`
    display: block;
    width: 100%;
    background: transparent linear-gradient(270deg, #0B1117 0%, #8F274B 53%, #0B1117 100%) 0% 0% no-repeat padding-box;
    padding: 10px;
    text-align: center;
    color: #fff;
    
    p {
        margin-bottom: 0;
    }
`