const onboardingState = {
    BASIC_INFO: 0,
    TRADING_EXP: 1,
    FINANCIAL_DETAILS: 2,
    CONNECT_TO_BINANCE: 3,
    ENTER_EXCHANGE_KEYS: 4,
    API_RESTRICTION_SETTINGS: 5,
    ADJUST_TRADING_BALANCE: 6,
    COMPLETE: 7
}

export {
    onboardingState
}