import React, { useRef } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"

export function OverlayTooltip({ message, showTooltip, renderComponent, title, identifer }) {
    const target = useRef(null)

    return (
        <>
            {/* render input component */}
            { renderComponent(target) }

            { showTooltip &&
                <div id="trade-forms-error" className={identifer}>
                    <div className="header-wrapper">
                        <div className="popover-title-header">
                            <span>
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                            </span>

                            <h3 className="popover-title">{title}</h3>
                        </div>
                    </div>

                    {/* render error message */}
                    <small>{ message }</small>
                </div>
            }
        </>
    )
}