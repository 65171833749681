import React from 'react'
import { connect } from 'react-redux'
import { Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft, faShare } from '@fortawesome/free-solid-svg-icons'
import { SRLWrapper } from 'simple-react-lightbox'
import { TextButton, PrimaryButton } from '../../styles'
import { FormBodyContent, FormInfoText, FormActions } from './'
import { isNotNull, onSetNewState } from '../../utils'
import { onboardingScreen } from '../../constants/onboardingScreen'
import { onboardingActions } from '../../redux/actions'
import { routes } from '../../navigation/CONSTANTS'

class ApiRestrictionSettings extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isProceeding: false
        }
        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { configureTradingBalance, skipOnboarding, eventHandlers } = this.props
            const { isProceeding } = this.state

            if (prevProps.configureTradingBalance !== configureTradingBalance && 
                !configureTradingBalance.loading && 
                isProceeding) {
                    const { result } = configureTradingBalance
                    if (isNotNull(result) && result.success && isNotNull(eventHandlers)) {
                        eventHandlers.navigateTo(null, onboardingScreen.ADJUST_TRADING_BALANCE)
                    }
                }

            if (isNotNull(skipOnboarding) && prevProps.skipOnboarding !== skipOnboarding && !skipOnboarding.loading) {
                const { response } = skipOnboarding
                if (isNotNull(response) && response.success) window.location.href = routes.dashboard
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    handleClickNext = (e) => {
        e.preventDefault()

        const { dispatch } = this.props
        dispatch(onboardingActions.configureTradingBalance())
        onSetNewState(this, {
            isProceeding: true
        })
    }

    // handleClickBack = (e) => {
    //     e.preventDefault()

    //     const { eventHandlers } = this.props
    //     if (isNotNull(eventHandlers)) {
    //         eventHandlers.navigateTo(null, onboardingScreen.ENTER_EXCHANGE_KEYS)
    //     }
    // }

    showSkipWarning = (e) => {
        const { eventHandlers } = this.props
        eventHandlers.handleSkip(e)
    }

    render() {
        const lightboxOptions = {
            buttons: {
                showAutoplayButton: false,
                showThumbnailsButton: false
            }
        }

        return (
            <>
                <FormBodyContent className='api-connection'>
                    <div className='graphic with-preview notransform'>
                        <SRLWrapper options={lightboxOptions}>
                            <img src="/images/form-graphics/step-5.gif" alt="Generate exchange API keys in Binance.com" style={{ width: '100%' }} />
                        </SRLWrapper>
                    </div>
    
                    <div className='form'>
                        <h2 className='form-title'>Connect PF to Binance.com</h2>
                        <h3 className='form-instruction'>Edit your API restriction settings</h3>
    
                        <Form.Group className='detailed-instructions'>
                            <ol>
                                <li>Click Edit Restrictions button</li>
                                <li>Tick 'Enable Spot and Margin Trading'</li>
                                <li>
                                    Tick 'Restrict access to trusted IP's only' and enter the IP address <strong className='highlighted'>46.101.72.75</strong> and click confirm
                                    <br /><em>(this is optional but does increase your security)</em>
                                </li>
                                {/* <li>Tick 'Enable Futures' (optional see note below)</li> */}
                                <li>Click the 'Save' button in the top right corner</li>
                                <li>Complete final security verification</li>
                            </ol>
                        </Form.Group>
                    </div>
                </FormBodyContent>
    
                <FormInfoText type="list">
                    <label><strong>Note: </strong></label>
                    <ul>
                        <li>
                            DO NOT tick 'Futures Trading' at this stage. If Futures trading is allowed in your country, you can configure this after basic account setup is complete by navigating to the settings area.
                        </li>
                        {/* <li>
                            If Binance Futures trading is currently banned in your country of residence, 
                            ticking the Enable Futures button may cause you to see an error message or a request for additional verification.
                        </li> */}
                    </ul>
                </FormInfoText>
    
                <FormActions>           
                    {/* <TextButton 
                        textColor="#8A9BAE"
                        size="sm" 
                        onClick={(e) => this.handleClickBack(e)}>
                        Back
                    </TextButton> */} 

                    <TextButton 
                        textColor="#8A9BAE"
                        size="auto" 
                        className='default-btn skip-btn' 
                        onClick={(e) => this.showSkipWarning(e)}>
                            <FontAwesomeIcon size="xs" className='icon' icon={faShare} /> 
                            <span>Skip</span>
                    </TextButton>
    
                    <PrimaryButton 
                        type="submit" 
                        size="sm"
                        onClick={(e) => this.handleClickNext(e)}>
                            <FontAwesomeIcon size="xs" className='icon' icon={faArrowRight} /> 
                            <span>Next</span>
                    </PrimaryButton>
                </FormActions>
            </>
        )
    }
}

function mapStateToProps(state) {
    const { configureTradingBalance, skipOnboarding } = state
    return {
        configureTradingBalance,
        skipOnboarding
    }
}

const connectedApiRestrictionSettings = connect(mapStateToProps)(ApiRestrictionSettings)
export { connectedApiRestrictionSettings as ApiRestrictionSettings }