import styled from 'styled-components'

export const TheTimeline = styled.div`
    position: relative;
    height: 100%;
    padding: 0 25px 0 100px;

    ol, ul {
        list-style-type: none;
    }

    ul {
        position: relative;
        margin: 0;
        padding: 0;
        height: 80px;

        > li {
            position: relative;
            padding: 10px 16px;
            background-color: #0e1822;
            width: auto;
            display: table;
            border-radius: 9px;
            margin-bottom: 10px;
            color: #c1cad5;
            box-shadow: 0 0 16px 2px rgb(0 0 0 / 48%) inset;
            cursor: pointer;
            border: 1px solid transparent;
        }
    }

    &::before {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 4px;
        content: ' ';
        background-color: #2a3746;
    }
`