import React, { useEffect } from 'react'
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { Brand, Layout } from '../../../../styles'
import { FormContainer, FormQuickLinks } from '../../../../components/Auth'

export const ForgotPasswordConfirmationView = props => {
    useEffect(() => {
        document.body.classList.add('authentication')
    }, [])

    return (
        <Layout>
            <FormContainer>
                <Brand imageUrl={`/images/brand/pf-logo.png`} />

                <h1 className='title'>
                    <strong>Check your email!</strong>
                </h1>

                <p className='description'>We've sent you an email to reset your password. If you can't see this email in your inbox, make sure you check any spam folders.</p>

                <FormQuickLinks>
                    <Link to={`/account/login`}>
                        <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: '8px'}} />
                        <span>Back to login</span>
                    </Link>
                </FormQuickLinks>
            </FormContainer>
        </Layout>
    )
}