import styled from 'styled-components'
import { color } from '../../../styles/CONSTANTS'

export const ModalButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	button {
		&:first-child {
			 background-color: #993956cc !important;
			color: #d8cfcf !important;
			display: inline-block;
			font-weight: 400;
			text-align: center;
			border: 1px solid #993956 !important;
			padding: .375rem .75rem;
			margin-right: 7px;

			&:hover {
				color: #d8cfcf !important;
				background-color: #993956cc !important;
				border-color:  #993956 !important;
			}
		}

		&:last-child {
			background-color: ${color.secondaryBG} !important;
			color: #FFFFFF !important;
			display: inline-block;
			font-weight: 400;
			text-align: center;
			border: 1px solid ${color.secondaryBorder} !important;
			padding: .375rem .75rem;

			&:hover {
				color: #fff !important;
				background-color: ${color.secondaryBG} !important;
				border-color: ${color.secondaryBorder} !important;
			}
		}
	}
`

export const ModalWrapper = styled.div`
	min-height: 164px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	p {
		font-size: 16px !important;
	}
`

export const FormSecurityWrapper = styled.div`
	padding: 25px;

	.col-form-label,
	.col-form-value {
		display: flex;
		align-items: center;
	}

	.col-form-label {
		color: #6E7885;
		font-size: 15px;
	}

	.col-form-title {
		display: flex;
		align-items: flex-start;
		color: #6E7885;
		font-size: 15px;
	}

	.security-contents {
		-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
		animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

		@-webkit-keyframes fade-in {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}
		@keyframes fade-in {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}
	}

	.pass-helper {
		max-width: 100%;
		font-size: 13px;
		width: 100%;
		background-color: #0E1824;
		font-family: 'Montserrat', sans-serif;
		border: 1px solid #27323e;
	
		.arrow {
			left: 5px !important;

			&::after {
				border-bottom-color: #0e1824;
				border-top-color: #27323e;
			}
		}
	
		.pass-helper-title {
			font-size: 13px;
			background-color: #0E1824;
			border-bottom: 1px solid #27323e;

			&::before {
				border-bottom: 1px solid #0e1824;
			}
		}

		.pass-helper-body {
			background-color: #27323e;
			color: #fff;

			.pass-helper-criteria-list {
				list-style: none;
				padding: 0;
				margin-bottom: 0;
		
				.pass-helper-icons {
					text-align: center;
		
					i {
						width: 30px;
		
						&.error {
							color: red;
						}
		
						&.success {
							color: #15bd6f;
						}
					}
				}
			}
		}
	}
`

export const UploadWrapper = styled.div`
	display: inline-grid;
	margin-left: 18px;
	text-align: center;

	button {
		border: 2px solid #577094;
		border-radius: 4px;
		background-color: #475973;
		font-size: 14px;
		padding: 5px 5px;
		margin-bottom: 15px;

		&:active {
		  background-color: transparent !important;
		  border-color: #EFF2F7 !important;
		}
		&:hover {
		  background-color: transparent !important;
		  border-color: #EFF2F7 !important;
		}
		&:focus {
		  background-color: transparent !important;
		  border-color: #EFF2F7 !important;
		  box-shadow: unset !important;
		}

	}
	small {
		font-size: 10.5px;
		line-height: 13px;
		color: #AFB8E1;
	}
`

export const InputWrapper = styled.div`
	display: flex;
	position: relative;

	&.default {
		input {
			background-color: #17212D !important;
			border: 1px solid #2E3E51;
			color: #dbdcdf;
			height: 45px;
			font-size: 14px;
			border-radius: 4px;
	
			&:focus {
				color: #dbdcdf;
				background-color: #17212D !important;
				border: 1px solid #15BD6F80 !important;
				box-shadow: none !important;
			}
		}

		select {
			background-color: #223346 !important;
			border: 1px solid #3C5778;
			color: #dbdcdf;
			height: 45px;
			font-size: 14px;
			border-radius: 4px;
	
			&:focus {
				color: #dbdcdf;
				background-color: #17212D !important;
				border: 1px solid #15BD6F80 !important;
				box-shadow: none !important;
			}
		}
	}
	
	
	.input-group {
		background-color: #17212D !important;
		border: 1px solid #2E3E51;
		border-radius: 4px;
		height: 45px;
		overflow: hidden;


		&:focus-within {
			color: #dbdcdf;
			background-color: #17212D !important;
			border: 1px solid #15BD6F80 !important;
			box-shadow: none !important;
		}

		input {
			border: none;
			color: #dbdcdf;
			height: 45px;
			font-size: 14px;
			background: transparent;

			&:focus {
				color: #dbdcdf;
				box-shadow: none !important;
			}
		}

		.input-group-append {

			.input-group-text {
				background: transparent;
				justify-content: center;
				width: 46px;
				border: none;

				svg {
					&.eyeIcon {
						cursor: pointer;
						color: #fff;
					}
				}
			}
		}
	}
`

export const InputContainer = styled.div``

export const SettingsTitle = styled.h6`
	padding: 20px 25px;
	background: #27323e;
	font-size: 16px;
	margin-bottom: 0;
	color: #fff;
`

export const SettingsGroupWrapper = styled.div`
	padding: 25px;

	.btn-submit {
		cursor: pointer;
		border-radius: 2px;
		padding: 8px 10px;
		min-width: 200px;
		background-color: #15BD6F !important;
		border: 1px solid #15BD6F !important;

		&:disabled {
			background-color: rgb(25,36,49) !important;
			color: #556371 !important;
			border: 1px solid #263442 !important;
			cursor: unset;
		}
	}

	.btn-delete {
		min-width: 135px;
		cursor: pointer;
		border-radius: 2px;
		padding: 8px 10px;
		background: #993956 !important;
		border: 1px solid #993956 !important;

		&:disabled {
			background-color: rgb(25,36,49) !important;
			color: #556371 !important;
			border: 1px solid #263442 !important;
			cursor: unset;
		}
	}

	.col-form-label {
		label {
			color: #9da9b9;
		}
	}

	input,
	select {
		height: 45px;
		background-color: #0b1219 !important;
		border: 1px solid #2d3e50 !important;
		border-radius: 2px;

		&:focus {
			background-color: #0b1219 !important;
			border: 1px solid #15BD6F80 !important;
			box-shadow: none !important;
		}
	}

	&.notification {
		max-height: 500px;
		overflow: auto;

		&::-webkit-scrollbar-track {
			background-color: #192431;
		}

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background-color: #273346;
		}
	}

	.toggle-switch {
		position: relative;
		width: 75px;
		display: inline-block;
		vertical-align: middle;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		text-align: left;
	&-checkbox {
		display: none;
	}
	&-label {
		display: block;
		overflow: hidden;
		cursor: pointer;
		border: 0 solid #ccc;
		border-radius: 20px;
		margin: 0;
	}
	&-inner {
		display: block;
		width: 200%;
		margin-left: -100%;
		transition: margin 0.3s ease-in 0s;
		&:before,
		&:after {
			display: block;
			float: left;
			width: 50%;
			height: 34px;
			padding: 0;
			line-height: 34px;
			font-size: 14px;
			color: white;
			font-weight: bold;
			box-sizing: border-box;
		}
		&:before {
			content: attr(data-yes);
			text-transform: uppercase;
			padding-left: 10px;
			background-color: #f90;
			color: #fff;
		}
	}
	&-disabled {
		background-color: #ccc;
		cursor: not-allowed;
		&:before {
			background-color: #ccc;
			cursor: not-allowed;
		}
	}
	&-inner:after {
		content: attr(data-no);
		text-transform: uppercase;
		padding-right: 10px;
		background-color: #ccc;
		color: #fff;
		text-align: right;
	}
	&-switch {
		display: block;
		width: 24px;
		margin: 5px;
		background: #fff;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 40px;
		border: 0 solid #ccc;
		border-radius: 20px;
		transition: all 0.3s ease-in 0s;
	}
	&-checkbox:checked + &-label {
		.toggle-switch-inner {
			margin-left: 0;
		}
		.toggle-switch-switch {
			right: 0px;
		}
	}
	&.small-switch {
		width: 40px;
		.toggle-switch-inner {
			&:after,
			&:before {
				content: "";
				height: 20px;
				line-height: 20px;
			}
		}
		.toggle-switch-switch {
			width: 16px;
			right: 20px;
			margin: 2px;
		}
	}
	@media screen and (max-width: 991px) {
		transform: scale(0.9);
	}
	@media screen and (max-width: 767px) {
		transform: scale(0.825);
	}
	@media screen and (max-width: 575px) {
		transform: scale(0.75);
	}
}`

export const SettingsContentWrapper = styled.div`
	min-height: 720px;
	/* background: #17212d; */

	.card {
		background-image: none;
		border: 2px solid #202c39;
		background-color: transparent;
		margin-bottom: 15px;
		border-radius: 9px;
	}

	@media screen and (min-width: 320px) and (max-width: 414px) {
		min-height: 450px;
		margin: 0;

		&.settings-trade {
			min-height: unset;
		}

		.card {
			.card-header {
				padding: 12px;
				min-height: 55px;

				h6 {
					font-size: 14px;
				}

				.edit-button {
					font-size: 13px;
				}
			}

			.card-body {
				&.subscription {
					padding: 25px !important;
				}
			}
		}
	}

	@media screen and (min-width: 415px) and (max-width: 1023px) {
		min-height: 450px;
		margin: 0;

		&.settings-trade {
			min-height: unset;
		}

		.card {
			.card-header {
				padding: 12px;
				min-height: 55px;

				h6 {
					font-size: 14px;
				}

				.edit-button {
					font-size: 13px;
				}
			}
			
			.card-body {
				&.subscription {
					padding: 25px !important;
				}
			}
		}
	}

	@media screen and (min-width: 1200px) and (max-width: 1440px) {
		min-height: 650px;
		margin: 0 10px 0 0;

		.card {
			.card-header {
				padding: 15px;
				min-height: 55px;

				h6 {
					font-size: 14px;
				}

				.edit-button {
					font-size: 13px;
				}
			}
			.card-body {

			}
		}
	}

	@media screen and (min-width: 1441px) and (max-width: 1700px) {
		min-height: 650px;
		margin: 0 10px;

		.card {
			.card-header {
				padding: 15px;
				min-height: 55px;

				h6 {
					font-size: 14px;
				}

				.edit-button {
					font-size: 13px;
				}
			}

			.card-body {

			}
		}
	}
`

export const SettingsMenuWrapper = styled.div`
	display: block;
	width: 100%;

	.sub-menu {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 15px;
		padding: 0;
		width: 100%;

		li {
			position: relative;
			padding: 0;
			border-bottom: none;

			a {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				padding: 15px 0;
				color: #fff;
				border-bottom: 1px solid #070b10;

				.sub-menu-icon,
				.sub-menu-text {
					color: #a9b7ca;
				}

				&:hover,
				&.active {
					.sub-menu-text {
						color: #fff;
					}
				}

				&:last-child {
					border-right: none;
				}

				.sub-menu-icon {
					width: 25px;
					height: 25px;
					color: #6E7885;

					i {
						font-size: 20px;
					}
				}

				.sub-menu-text {
					display: none;
					font-size: 16px;
					color: #6E7885;
				}

				&.active {
					.sub-menu-icon {
						color: #15bd6f;
					}

					.sub-menu-text {
						color: #fff;
					}
				}
			}

			&:last-child {
				a {
					border-bottom: none;
				}
			}
		}
	}

	@media only screen and (min-width: 768px) {
		.sub-menu {
			li {
				a {
					justify-content: start;
					align-items: start;
					flex-direction: row;
						border-bottom: none;

					.sub-menu-text {
						display: block;
					}
				}
			}
		}
	}

	@media only screen and (min-width: 992px) {
		.sub-menu {
			li {
				a {
					padding: 10px 0;
				}
			}
		}
	}

	/* @media screen and (min-width: 320px) and (max-width: 766px) {
		.sub-menu {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin-bottom: 15px;
			padding: 0 10px;

			li {
				border-bottom: 1px solid #070b10;

				a {
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;

					.sub-menu-text {
						font-size: 12px;
						font-weight: 500;
					}

					.sub-menu-icon {
						margin-right: 0;

						i {
							font-size: 16px;
						}
					}
				}
			}
		}
	}

	@media screen and (min-width: 767px) and (max-width: 1023px) {
		.sub-menu {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin-bottom: 15px;

			li {

				a {
					display: flex;
					justify-content: start;
					align-items: start;
					flex-direction: row;

					.sub-menu-text {
						font-size: 17px;
						font-weight: 500;
					}

					.sub-menu-icon {
						margin-right: 10px;

						i {
							font-size: 16px;
						}
					}
				}
			}
		}
	}

	@media screen and (min-width: 1024px) and (max-width: 1199px) {
		.sub-menu {
			li {
				a {
					.sub-menu-text {
						font-size: 14px;
						font-weight: 500;
					}

					.sub-menu-icon {
						i {
							font-size: 20px;
						}
					}
				}
			}
		}
	}

	@media screen and (min-width: 1200px) and (max-width: 1440px) {
		.sub-menu {
			padding: 0 8px;

			li {
				a {
					.sub-menu-text {
						font-size: 14px;
						font-weight: 500;
					}

					.sub-menu-icon {
						i {
							font-size: 20px;
						}
					}
				}
			}
		}
	}

	@media screen and (min-width: 1441px) and (max-width: 1700px) {
		.sub-menu {
			padding: 0 15px;

			li {
				a {
					.sub-menu-text {
						font-size: 14px;
						font-weight: 500;
					}

					.sub-menu-icon {
						i {
							font-size: 20px;
						}
					}
				}
			}
		}
	} */
`

export const SettingsBodyBlockInner = styled.div`
	.card {
		background-image: linear-gradient(90deg,#2A3746,#2E3B4C);
		border-color: #1f2a35;
		border-radius: 9px !important;
		border: none;

		.card-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: #192431;
			border-top-left-radius: 9px !important;
			border-top-right-radius: 9px !important;
			padding: 12px 25px;
			min-height: 65px;
			color: #fff;

			h6 {
				margin-bottom: 0;
			}

			.edit-button {
				background: #192431;
				border: 1px solid #263442;
				font-size: 15px;
				width: 100px;
				border-radius: 2px;

				&:focus {
					box-shadow: none;
				}

				&:hover {
					color: #fff !important;
					background-color: #475973 !important;
					border-color: #577094 !important;
				}
			}
		}

		.card-body {
			background: #192431;
			padding: 0;
			border-bottom-left-radius: 9px;
			border-bottom-right-radius: 9px;

			&.subscription {
				padding: 30px;
			}

			&.auto-trade-settings {
				overflow: auto;

				&.notif {
					max-height: none;
				}
			}

			&::-webkit-scrollbar-track {
				background-color: #192431;
			}

			&::-webkit-scrollbar {
				width: 6px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				background-color: #273346;
			}
		}
	}
`

export const SettingsBodyBlock = styled.div`
	max-width: 960px;
	margin: 0 auto;

	@media screen and (min-width: 767px) and (max-width: 1023px) {
		max-width: 660px;
	}

	@media screen and (min-width: 1024px) and (max-width: 1199px) {
		max-width: 700px;
	}

	@media screen and (min-width: 1200px) and (max-width: 1440px) {
		max-width: 750px;
	}

	@media screen and (min-width: 1441px) and (max-width: 1749px) {
		max-width: 850px;
	}

	@media screen and (min-width: 1750px) and (max-width: 3000px) {

	}
`

export const SettingsBodyWrapper = styled.div`
	display: block;
`

export const Body = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	flex-direction: column;
	margin-bottom: 25px;

	@media screen and (min-width: 320px) and (max-width: 1023px) {
		margin-bottom: 20px;
		justify-content: center;
		// flex-direction: row;
	}

`

export const CardBody = styled.div`
	@media screen and (min-width: 320px) and (max-width: 1023px) {
		&.auto-trade-settings {
			max-height: calc(${props => props.height}px - 312px) !important;
		}
	}
`

export const SettingsWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	min-height: calc(100vh - 56px);

	/* @media screen and (min-width: 320px) and (max-width: 1023px) {
		margin: 20px auto;

		.settings-name {
			display: none;
		}
	} */

	@media only screen and (min-width: 1200px) {
		min-height: calc(100vh - 57px);
		//margin-left: 257px;
		padding-right: 211px;
	}
`

export const SettingsNavSideBar = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 58px;
	padding: 40px 0;
	background-color: #0e1823;

	h1 {
		display: none;
		width: 100%;
		font-size: 24px;
		padding-bottom: 16px;
		margin-bottom: 20px;
		border-bottom: 1px solid #27323e;
	}

	.avatar {
		display: none;
		width: 100%;
		margin: 25px 0;

		.profile-avatar,
		.profile-label {
			display: block;
		}

		.profile-avatar {
			width: 60px;
			height: 60px;
			border-radius: 60px;
			overflow: hidden;
			margin: 0 auto 15px;
		}

		.profile-label {
			text-align: center;

			.second-name {
				margin-left: 6px;
			}
		}
	}

	@media only screen and (min-width: 768px) {		
		width: 215px;
		padding: 15px;

		h1 {			
			display: block;
			font-size: 18px;
		}

		.avatar {
			display: block;
		}
	}

	@media only screen and (min-width: 992px) {		
		padding: 20px;
		width: 230px;

		h1 {
			font-size: 20px;
		}

		.avatar {
			.profile-avatar {
				width: 80px;
				height: 80px;
			}
		}
	}

	@media only screen and (min-width: 1200px) {	
		width: 250px;

		h1 {			
			display: block;
			font-size: 28px;
		}

	}
`

export const SettingsMainContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 30px 15px 30px 73px;
	overflow-y: scroll;
    height: calc(100vh - 56px);

	.col {
		h2 {
			font-size: 17px;
		}
	}

	@media only screen and (min-width: 768px) {
		padding-left: 230px;

		.col {
			h2 {
				font-size: 24px;
			}
		}
	}

	@media only screen and (min-width: 992px) {
		padding-left: 245px;
	}

	@media only screen and (min-width: 1200px) {
		padding-left: 275px;
	}
`

export const SettingsBackgroundColor = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #131E2B;
	z-index: -1;
`

export const SettingsContainer = styled.div`
	position: relative;
	margin-top: 56px;
	overflow: hidden;
`