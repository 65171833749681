import Moment from 'react-moment'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import _ from 'lodash'
import { StatusColor } from '../../styles'
import { orderTypes } from '../../constants/orderConstants'
import { isNotNull } from '../../utils'

export const OpenOrderView = (props) => {
    const { coin, groupId, item } = props

    const renderOpenOrderItemData = (groupId, items) => {
        if (isNotNull(items)) {
            const orderLists = _.orderBy(items, o => new Date(o.openedAt), 'desc')
            return orderLists.map((i, key) => {
                return (
                    <tr key={`${key}-root`} className="expanded-data">
                        {window.innerWidth >= 1024 &&
                            <>
                                <td className="table-space"></td>
                                <td><StatusColor className="status" type={i.status}>{i.status}</StatusColor></td>
                                <td>{i.type}</td>
                                <td>{i.volume}</td>
                                <td>{i.price}</td>
                                <td dangerouslySetInnerHTML={{ __html: i.condition }}></td>
                                <td><Moment format="MM/DD/YYYY hh:mm A" utc local>{i.openedAt}</Moment></td>
                                <td>
                                    {i.expiry !== null && i.expiry !== 'undefined' && i.expiry !== ""
                                        ? <Moment format="MM/DD/YYYY hh:mm A" utc local>{i.expiry}</Moment>
                                        : <>{i.expiry}</>
                                    }
                                </td>
                                <td>
                                    <div className="order-action-btns">
                                        { i.status !== 'PENDING' && 
                                            <>                                                
                                                <OverlayTrigger placement="top" overlay={
                                                    <Tooltip id='force-exit-order-tooltip'>
                                                        { i.type === orderTypes.STOP_BUY_ORDER && 
                                                            <>Force Enter Order</>
                                                        }

                                                        { i.type !== orderTypes.STOP_BUY_ORDER && 
                                                            <>Force Exit Order</>
                                                        }
                                                    </Tooltip>}>
                                                    <button className={`btn btn-sm mr-2 ${i.type === orderTypes.STOP_BUY_ORDER ? 'btn-force-buy' : 'btn-force-sell' }`} 
                                                        onClick={() => props.onForceExitTrade(i.type, item)}>
                                                            <i className={`fas ${i.type === orderTypes.STOP_BUY_ORDER ? 'fa-sign-in-alt' : 'fa-sign-out-alt'}`}></i>
                                                    </button>
                                                </OverlayTrigger>
                                            </>
                                        }
                                        
                                        { i.status !== 'PENDING' && 
                                            <>
                                                <OverlayTrigger placement="top" overlay={
                                                    <Tooltip id='cancel-order-tooltip'>
                                                        Cancel Order
                                                    </Tooltip>}>
                                                    <button className="btn btn-danger btn-sm"
                                                        onClick={() => props.onCancelTrade(i.type, item)}>
                                                        <i className="fas fa-times"></i>
                                                    </button>
                                                </OverlayTrigger>
                                            </>
                                        }
                                    </div>
                                </td>
                            </>
                        }

                        {window.innerWidth < 1024 &&
                            <td colSpan={9}>
                                <div className="expanded-wrapper">
                                    <span className="col-sm-6 col-md-8">STATUS</span>
                                    <StatusColor className="status col-sm-6 col-md-4" type={i.status}>{i.status}</StatusColor>
                                </div>

                                <div className="expanded-wrapper">
                                    <span className="col-sm-6 col-md-8">TYPE</span>
                                    <span className="col-sm-6 col-md-4">{i.type}</span>
                                </div>

                                <div className="expanded-wrapper">
                                    <span className="col-sm-6 col-md-8">VOLUME</span>
                                    <span className="col-sm-6 col-md-4">{i.volume}</span>
                                </div>

                                <div className="expanded-wrapper">
                                    <span className="col-sm-6 col-md-8">PRICE</span>
                                    <span className="col-sm-6 col-md-4">{i.price}</span>
                                </div>

                                <div className="expanded-wrapper">
                                    <span className="col-sm-6 col-md-8">CONDITION</span>
                                    <span dangerouslySetInnerHTML={{ __html: i.condition }} className="col-sm-6 col-md-4"></span>
                                </div>

                                <div className="expanded-wrapper">
                                    <span className="col-sm-6 col-md-8">OPEN DATE</span>
                                    <span className="col-sm-6 col-md-4"><Moment format="MM/DD/YYYY hh:mm A" utc local>{i.openedAt}</Moment></span>
                                </div>

                                <div className="expanded-wrapper">
                                    <span className="col-sm-6 col-md-8">EXPIRY</span>
                                    <span className="col-sm-6 col-md-4"><Moment format="MM/DD/YYYY hh:mm A" utc local>{i.expiry}</Moment></span>
                                </div>

                                <div className="expanded-wrapper">
                                    <div>ACTIONS</div>
                                    <div className="order-action-btns" style={{ textAlign: 'right' }}>                                            
                                        { i.status !== 'PENDING' && 
                                            <>                                                
                                                <OverlayTrigger placement="top" overlay={
                                                    <Tooltip id='force-exit-order-tooltip'>
                                                        { i.type === orderTypes.STOP_BUY_ORDER && 
                                                            <>Force Enter Order</>
                                                        }
                                                        
                                                        { i.type !== orderTypes.STOP_BUY_ORDER && 
                                                            <>Force Exit Order</>
                                                        }
                                                    </Tooltip>}>
                                                    <button className={`btn btn-sm mr-2 ${i.type === orderTypes.STOP_BUY_ORDER ? 'btn-force-buy' : 'btn-force-sell' }`} 
                                                        onClick={() => props.onForceExitTrade(i.type, item)}>
                                                            { i.type === orderTypes.STOP_BUY_ORDER && 
                                                                <>Force Enter Order</>
                                                            }

                                                            { i.type !== orderTypes.STOP_BUY_ORDER && 
                                                                <>Force Exit Order</>
                                                            }
                                                        </button>
                                                </OverlayTrigger>
                                            </>
                                        }
                                        
                                        { i.status !== 'PENDING' && 
                                            <>
                                                <OverlayTrigger placement="top" overlay={
                                                    <Tooltip id='cancel-order-tooltip'>
                                                        Cancel Order
                                                    </Tooltip>}>
                                                    <button className="btn btn-danger btn-sm"
                                                        onClick={() => props.onCancelTrade(i.type, item)}>
                                                        Cancel
                                                    </button>
                                                </OverlayTrigger>
                                            </>
                                        }
                                    </div>
                                </div>
                            </td>
                        }
                    </tr>
                )
            })
        }
    }

    return (
        <tbody className="order-table">
            <tr className="custom-splitter">
                <td onClick={() => props.onExpand(groupId)}><b>{groupId} </b><p className="coin-name">{coin}</p></td>
                <td onClick={() => props.onExpand(groupId)}></td>
                <td onClick={() => props.onExpand(groupId)}></td>
                <td onClick={() => props.onExpand(groupId)}></td>
                <td onClick={() => props.onExpand(groupId)}></td>
                <td onClick={() => props.onExpand(groupId)}></td>
                <td onClick={() => props.onExpand(groupId)}></td>
                <td onClick={() => props.onExpand(groupId)}></td>
                <td className="text-center cancel-trade">
                    
                </td>
            </tr>

            {renderOpenOrderItemData(groupId, item)}
        </tbody>
    )
}