import { useState }  from 'react'
import moment from 'moment'
import { isNotNull } from '../../../utils'
import { JumpStartSectionStyle } from './styles'

export const JumpStartSection = (props) => {
    let [remainingDays, setRemainingDays] = useState('')
    let [remainingHours, setRemainingHours] = useState('')
    let [remainingMinutes, setRemainingMinutes] = useState('')
    let [remainingSeconds, setRemainingSeconds] = useState('')

    let [isEarlyBirdOffer, setIsEarlyBirdOffer] = useState(false)
    let [isFinalYearlyOffer, setIsFinalYearlyOffer] = useState(false)

    let parsedDate = Date.parse(props.validUntil)
    let expiry = new Date(parsedDate)
        expiry = moment(expiry).format('MM/DD/yyyy h:mm:ss')

    const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

    let today = new Date(),
        dd = String(today.getDate()).padStart(2, "0"),
        mm = String(today.getMonth() + 1).padStart(2, "0"),
        yyyy = today.getFullYear(),
        nextYear = yyyy + 1,
        dayMonth = moment(expiry).format('MM/DD/')
  
    today = mm + "/" + dd + "/" + yyyy
    if (today > expiry) {
        expiry = dayMonth + nextYear
    }

    const countDown = new Date(expiry).getTime(),
        x = setInterval(function() {    
            const now = new Date().getTime(),
                distance = countDown - now

            setRemainingDays(Math.floor(distance / (day)))
            setRemainingHours(Math.floor((distance % (day)) / (hour)))
            setRemainingMinutes(Math.floor((distance % (hour)) / (minute)))
            setRemainingSeconds(Math.floor((distance % (minute)) / second))

            if (isNotNull(remainingDays)) {
                if (parseInt(remainingDays) >= 14) {
                    if (!isEarlyBirdOffer)
                        setIsEarlyBirdOffer(true)

                    if (isFinalYearlyOffer)
                        setIsFinalYearlyOffer(false)
                } else {                        
                    if (isEarlyBirdOffer)
                        setIsEarlyBirdOffer(false)
                    
                    if (!isFinalYearlyOffer)  
                        setIsFinalYearlyOffer(true)
                }
            }

            if (distance < 0) {
                clearInterval(x)
            }
            //seconds
        }, 1000)

    return (
        <JumpStartSectionStyle>
            <div className='container'>
                <div className='text-with-img' id="nextSection">
                    <div className='img-wrapper'>
                        <img src='/images/layout/juiced-up.png' alt='Juiced-Up, Jacked-Up & Jump Started' />
                    </div>
                    <div className='text-col'>
                        <h2 className='section-title'>Juiced-Up, Jacked-Up & Jump Started</h2>
                        <p>Exclusively available to our <strong>PRO Trader 5x Blueprint students</strong>.</p>

                        <div className='offer-validity-timer'>
                            <ul>
                                <li>
                                    <span className='counter'>{parseInt(remainingDays) < 10 ? '0': ''}{remainingDays}</span>
                                    <small>DAYS</small>
                                </li>
                                <li>
                                    <span className='counter'>{parseInt(remainingHours) < 10 ? '0': ''}{remainingHours}</span>
                                    <small>HOURS</small>
                                </li>
                                <li>
                                    <span className='counter'>{parseInt(remainingMinutes) < 10 ? '0': ''}{remainingMinutes}</span>
                                    <small>MINUTES</small>
                                </li>
                                <li>
                                    <span className='counter'>{parseInt(remainingSeconds) < 10 ? '0': ''}{remainingSeconds}</span>
                                    <small>SECONDS</small>
                                </li>
                            </ul>
                        </div>

                        <h3 className='discount-tag'>
                            { isEarlyBirdOffer && <>Early-Birds save an additional 20%!</>}
                            { isFinalYearlyOffer && <>HURRY! LIMITED TIME ONLY.</>}
                        </h3>
                        <p className='text-md'>Keep your trading game on <strong>HIGH ALERT</strong> for the next <strong>12 months</strong> with our all in one <strong>Techno-Beast-Mode</strong> solution.</p>
                    </div>
                </div>

                <div className='direction'>
                    <svg className="arrows">
                        <path className="a1" d="M0 0 L30 32 L60 0"></path>
                        <path className="a2" d="M0 20 L30 52 L60 20"></path>
                        <path className="a3" d="M0 40 L30 72 L60 40"></path>
                    </svg>
                </div>
            </div>
        </JumpStartSectionStyle>
    )
}