import React from 'react'
import { Link } from 'react-router-dom'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import Moment from 'react-moment'
import { SignalStatus } from '../../../../components/SignalContents'
import { SignalProgress } from '../Signals'
import { SignalStatusContent } from '../../styled_components'
import { CoinPairLogo } from '../../../../styles'
import {
    isNotNull, onSetNewState, setDefaultImage, setCoinLogo,
    isNull, toUsdFormat, toBtcFormat, ScientificToDecimal
} from '../../../../utils'
import { tradingServices } from '../../../../services'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";

export class TradeOrderSummary extends React.Component {    
    constructor(props) {
        super(props)

        this.state = {
            feed: '',
            direction: '',
            toSym: '',
            symbol: '',
            lastPrice: '',
            isLoadingLastPrice: false,
            state: null,
            datePublished: '',
            orderDetails: null
        }
        this.baseState = this.state

        this.priceFeedInterval = null
        this.wsClient = null
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true

        const { dispatch, userInfo, selectedSignal, orderDetails, tradeSummaryOnly } = this.props
        let { feed, symbol } = this.state

        if (isNotNull(selectedSignal)) {
            symbol = selectedSignal.symbol

            onSetNewState(this, {
                direction: selectedSignal.direction,
                toSym: selectedSignal.toSym,
                symbol,
                state: selectedSignal.state,
                datePublished: selectedSignal.datePublished
            })
        }

        if (isNotNull(orderDetails)) {
            feed = orderDetails.feed
            onSetNewState(this, {
                feed,
                orderDetails
            })
        }

        if (isNotNull(symbol) && isNotNull(feed)) {
            this.initSignalPriceFeed(symbol, feed)
        }
    }

    componentDidUpdate() {

    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)

        if (isNotNull(this.wsClient)) {
            this.wsClient.close()
            this.wsClient = null
        }

        clearTimeout(this.priceFeedInterval)
        this.priceFeedInterval = null

        this.mounted = false
    }

    initSignalPriceFeed = (symbol, feed) => {
        if (isNull(this.wsClient)) {
            let baseUrl = feed === 'SPOT'
                ? 'wss://stream.binance.com:9443/ws/'
                : 'wss://fstream.binance.com/ws/'
            let streamName = `${baseUrl}${symbol.toLowerCase()}@ticker`
            this.wsClient = new W3CWebSocket(streamName)

            // connect to binance websockets
            // this.wsClient.onopen = () => {
            //     console.log(`Listening to ${symbol} price feed`)
            // }

            // try to reconnect when connection is closed
            this.wsClient.onclose = () => {
                this.priceFeedInterval = setTimeout(() => {
                    this.wsClient = null
                    this.initSignalPriceFeed(symbol, feed)
                }, 3000)
            }

            // try to reconnect when connection throws an error
            this.wsClient.onerror = () => {
                this.priceFeedInterval = setTimeout(() => {
                    this.wsClient = null
                    this.initSignalPriceFeed(symbol, feed)
                }, 3000)
            }

            this.wsClient.onmessage = (response) => {
                let { data } = response
                if (isNotNull(data)) {
                    data = JSON.parse(data)
                    if (data.e === "24hrTicker") {
                        let { lastPrice } = this.state

                        if (isNotNull(symbol) && symbol === data.s) {
                            let isUsdSignal = (symbol.endsWith("USDT") || symbol.endsWith("USD")) ? true : false

                            if (parseFloat(lastPrice) !== parseFloat(data.c)) {
                                onSetNewState(this, {
                                    lastPrice: isUsdSignal ? toUsdFormat(data.c) : toBtcFormat(data.c)
                                })
                            }
                        }
                    }
                }
            }
        }
    }

    openTradingTerminal = () => {
        const { userInfo } = this.props
        if (userInfo.isPaid) {
            tradingServices.getAutologin().then(response => {
                if (isNotNull(response) && 
                    isNotNull(response.loginUrl)) {
                    let terminalLink = response.loginUrl.replace("www.", "profitfarmers.")
                    
                    const openWindow = window.open(terminalLink, "_blank")
                    if (openWindow) {
                        window.focus()
                    } else {
                        window.setTimeout(() => {
                            if (openWindow) openWindow.focus()
                        }, 100)
                    }
                }
            })
        }
    }
    
    copyText= (val) => {
        navigator.clipboard.writeText(val).then(() => {
            // Handle successful copy
            //console.log('Text copied to clipboard');
        }).catch(err => {
            // Handle error
            console.error('Failed to copy text: ', err);
        });
    }
    
    renderCoinInfo = () => {
        const { feed, toSym, symbol, state } = this.state
        const { selectedSignal } = this.props

        let coinLogo = null
        if (isNotNull(toSym))
            coinLogo = setCoinLogo(toSym)

        let exchange = ''
        if (isNotNull(feed)) {
            if (feed.toUpperCase() === 'SPOT')
                exchange = "ON BINANCE"
            else
                exchange = "ON BINANCE FUTURES"
        }

        return (
            <>
                <div className="coin-info">
                    <div className="d-flex align-items-center">
                        <CoinPairLogo
                            src={coinLogo}
                            className={toSym}
                            icon={toSym}
                            onError={setDefaultImage} />

                        <div className="symbol">
                            <span className="coin-name">{symbol}</span>
                            <small>{exchange}</small>
                        </div>
                    </div>

                    <SignalStatusContent className="trade-progress-wrapper">
                        <div className="trade-progress">
                            <div className="trade-status">
                                { isNotNull(selectedSignal) &&
                                    <>
                                        <SignalStatus
                                            state={state}
                                            isActive={selectedSignal.isActive} />
                                    </>
                                }
                            </div>
                        </div>
                    </SignalStatusContent>
                </div>
            </>
        )
    }

    renderTradeProgress = () => {
        const { state } = this.state
        return (
            <>
                <div className="trade-progress-value">
                    {isNotNull(state) &&
                        <SignalProgress state={state.item1}
                            data={this.props.selectedSignal} />
                    }
                </div>
            </>
        )
    }

    renderEntryOrderDetails = () => {
        const { toSym, lastPrice, orderDetails } = this.state

        let qtyInUsdt = ''
        let hasLadderedEntries = false
        let entryPrice = ''
        let entryPrices = null
        let volume = ''

        if (isNotNull(orderDetails)) {
            qtyInUsdt = orderDetails.qtyInUsdt
            volume = orderDetails.volume

            const { primaryOrder } = orderDetails
            if (orderDetails.hasLadderedEntries) {
                hasLadderedEntries = true
                entryPrices = primaryOrder.targets.map((item, key) => {
                    return (
                        <li key={key}>
                            { parseInt(item.quantity) !== 0 && 
                                <>
                                    { (key + 1) === 1 && 
                                        <>
                                            { orderDetails.isMarketBuy && 
                                                <span><span className="target-index">E{key + 1}</span> MARKET BUY</span>
                                            }

                                            { !orderDetails.isMarketBuy && 
                                                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                    <span className="target-index">E{key + 1}</span> {item.quantity}% @ 
                                                    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                        <span>{ScientificToDecimal(item.stopPrice)}</span>
                                                        <FontAwesomeIcon
                                                            icon={faCopy}
                                                            size="sm"
                                                            style={{
                                                                padding: '5px 10px',
                                                                cursor: 'pointer',
                                                                border: '1px solid #3c5777',
                                                                textAlign: 'center',
                                                                borderRadius: '6px',
                                                                color: '#7199c9',
                                                                marginLeft: '8px'
                                                            }}
                                                            onClick={(e) => this.copyText(item.stopPrice) } />
                                                    </span>
                                                </span>
                                            }
                                        </>
                                    }

                                    { (key + 1) > 1 && 
                                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            <span className="target-index">E{key + 1}</span> {item.quantity}% @ 
                                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                <span>{ScientificToDecimal(item.stopPrice)}</span>
                                                <FontAwesomeIcon
                                                    icon={faCopy}
                                                    size="sm"
                                                    style={{
                                                        padding: '5px 10px',
                                                        cursor: 'pointer',
                                                        border: '1px solid #3c5777',
                                                        textAlign: 'center',
                                                        borderRadius: '6px',
                                                        color: '#7199c9',
                                                        marginLeft: '8px'
                                                    }}
                                                    onClick={(e) => this.copyText(item.stopPrice) } />
                                            </span>
                                        </span>
                                    }
                                </>
                            }
                        </li>
                    )
                })
            }
            else {
                if (orderDetails.isMarketBuy) 
                    entryPrice = "MARKET BUY"
                else
                    entryPrice = ScientificToDecimal(primaryOrder.stopPrice)
            }
        }

        return (
            <>
                <div className="signal-info">
                    <div className="signal signal-lastprice">
                        <span>Last price</span>
                        <small className="value">{ScientificToDecimal(lastPrice)}</small>
                    </div>

                    <div className="signal signal-usdt">
                        <span>Total in USDT</span>
                        <small className="value" style={{ display: 'flex', alignItems: 'center' }}>
                            <span>{qtyInUsdt}</span>
                            <FontAwesomeIcon 
                                icon={faCopy} 
                                size="sm" 
                                style={{ 
                                    padding: '5px 10px', 
                                    cursor: 'pointer',
                                    border: '1px solid #3c5777', 
                                    textAlign: 'center', 
                                    borderRadius: '6px', 
                                    color: '#7199c9',
                                    marginLeft: '8px' 
                                }}
                                onClick={(e) => this.copyText(qtyInUsdt) } />
                        </small>
                    </div>

                    <div className="signal signal-qty">
                        <span>Projected Coin Quantity</span>
                        <div>
                            <small className="value" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <span><span className="signal-mtl">{toSym}</span> {volume}</span>
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    size="sm"
                                    style={{
                                        padding: '5px 10px',
                                        cursor: 'pointer',
                                        border: '1px solid #3c5777',
                                        textAlign: 'center',
                                        borderRadius: '6px',
                                        color: '#7199c9',
                                        marginLeft: '8px'
                                    }}
                                    onClick={(e) => this.copyText(volume) } />
                            </small>
                        </div>
                    </div>

                    { !hasLadderedEntries && 
                        <div className="signal signal-entryprice">
                            <span>Entry price</span>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <span>{entryPrice}</span>
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    size="sm"
                                    style={{
                                        padding: '5px 10px',
                                        cursor: 'pointer',
                                        border: '1px solid #3c5777',
                                        textAlign: 'center',
                                        borderRadius: '6px',
                                        color: '#7199c9',
                                        marginLeft: '8px'
                                    }}
                                    onClick={(e) => this.copyText(entryPrice) } />
                            </div>
                        </div>
                    }
                </div>

                { hasLadderedEntries && 
                    <>
                        <div className="target-info">
                            <div>
                                <i className="fas fa-sign-in-alt icon"></i>
                                <span>Entries</span>
                            </div>

                            <ul>
                                {entryPrices}
                            </ul>
                        </div>
                    </>
                }
            </>
        )
    }

    renderTakeProfitOrderDetails = () => {
        const { feed, direction, orderDetails } = this.state

        let targets = null

        if (isNotNull(orderDetails) &&
            isNotNull(feed) &&
            isNotNull(direction)) {
            const { secondaryOrder } = orderDetails

            if (orderDetails.tradeStrategy === 'opt') {
                if (feed.toUpperCase() === "SPOT") {
                    if (direction.toUpperCase() === "BUY") {
                        const { order1 } = secondaryOrder
                        targets = order1.targets.map((item, key) => {
                            return (
                                <li key={key}>
                                    { parseInt(item.quantity) !== 0 && 
                                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            <span className="target-index">T{key + 1}</span> {item.quantity}% @ 
                                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                <span>{ScientificToDecimal(item.stopPrice)}</span>
                                                <FontAwesomeIcon
                                                    icon={faCopy}
                                                    size="sm"
                                                    style={{
                                                        padding: '5px 10px',
                                                        cursor: 'pointer',
                                                        border: '1px solid #3c5777',
                                                        textAlign: 'center',
                                                        borderRadius: '6px',
                                                        color: '#7199c9',
                                                        marginLeft: '8px'
                                                    }}
                                                    onClick={(e) => this.copyText(item.stopPrice) } />
                                            </span>
                                        </span>
                                    }
                                </li>
                            )
                        })
                    } else {
                        const { order1 } = secondaryOrder
                        targets = order1.targets.map((item, key) => {
                            return (
                                <li key={key}>
                                    { parseInt(item.quantity) !== 0 && 
                                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            <span className="target-index">T{key + 1}</span> {item.quantity}% @ 
                                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                <span>{ScientificToDecimal(item.stopPrice)}</span>
                                                <FontAwesomeIcon
                                                    icon={faCopy}
                                                    size="sm"
                                                    style={{
                                                        padding: '5px 10px',
                                                        cursor: 'pointer',
                                                        border: '1px solid #3c5777',
                                                        textAlign: 'center',
                                                        borderRadius: '6px',
                                                        color: '#7199c9',
                                                        marginLeft: '8px'
                                                    }}
                                                    onClick={(e) => this.copyText(item.stopPrice) } />
                                            </span>
                                        </span>
                                    }
                                </li>
                            )
                        })
                    }
                } else {
                    if (direction.toUpperCase() === "BUY") {
                        const { order2 } = secondaryOrder
                        targets = order2.targets.map((item, key) => {
                            return (
                                <li key={key}>
                                    { parseInt(item.quantity) !== 0 && 
                                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            <span className="target-index">T{key + 1}</span> {item.quantity}% @ 
                                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                <span>{ScientificToDecimal(item.stopPrice)}</span>
                                                <FontAwesomeIcon
                                                    icon={faCopy}
                                                    size="sm"
                                                    style={{
                                                        padding: '5px 10px',
                                                        cursor: 'pointer',
                                                        border: '1px solid #3c5777',
                                                        textAlign: 'center',
                                                        borderRadius: '6px',
                                                        color: '#7199c9',
                                                        marginLeft: '8px'
                                                    }}
                                                    onClick={(e) => this.copyText(item.stopPrice) } />
                                            </span>
                                        </span>
                                    }
                                </li>
                            )
                        })
                    } else {
                        const { order1 } = secondaryOrder
                        targets = order1.targets.map((item, key) => {
                            return (
                                <li key={key}>
                                    { parseInt(item.quantity) !== 0 && 
                                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            <span className="target-index">T{key + 1}</span> {item.quantity}% @ 
                                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                <span>{ScientificToDecimal(item.stopPrice)}</span>
                                                <FontAwesomeIcon
                                                    icon={faCopy}
                                                    size="sm"
                                                    style={{
                                                        padding: '5px 10px',
                                                        cursor: 'pointer',
                                                        border: '1px solid #3c5777',
                                                        textAlign: 'center',
                                                        borderRadius: '6px',
                                                        color: '#7199c9',
                                                        marginLeft: '8px'
                                                    }}
                                                    onClick={(e) => this.copyText(item.stopPrice) } />
                                            </span>
                                        </span>
                                    }
                                </li>
                            )
                        })
                    }
                }
            } else {
                if (feed.toUpperCase() === "SPOT") {
                    if (direction.toUpperCase() === "BUY") {
                        const { order1 } = secondaryOrder
                        targets = order1.targets.map((item, key) => {
                            return (
                                <li key={key}>
                                    { parseInt(item.quantity) !== 0 && 
                                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            <span className="target-index">T{key + 1}</span> {item.quantity}% @ 
                                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                <span>{ScientificToDecimal(item.stopPrice)}</span>
                                                <FontAwesomeIcon
                                                    icon={faCopy}
                                                    size="sm"
                                                    style={{
                                                        padding: '5px 10px',
                                                        cursor: 'pointer',
                                                        border: '1px solid #3c5777',
                                                        textAlign: 'center',
                                                        borderRadius: '6px',
                                                        color: '#7199c9',
                                                        marginLeft: '8px'
                                                    }}
                                                    onClick={(e) => this.copyText(item.stopPrice) } />
                                            </span>
                                        </span>
                                    }
                                </li>
                            )
                        })
                    } else {
                        const { order1 } = secondaryOrder
                        targets = order1.targets.map((item, key) => {
                            return (
                                <li key={key}>
                                    { parseInt(item.quantity) !== 0 && 
                                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            <span className="target-index">T{key + 1}</span> {item.quantity}% @ 
                                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                <span>{ScientificToDecimal(item.stopPrice)}</span>
                                                <FontAwesomeIcon
                                                    icon={faCopy}
                                                    size="sm"
                                                    style={{
                                                        padding: '5px 10px',
                                                        cursor: 'pointer',
                                                        border: '1px solid #3c5777',
                                                        textAlign: 'center',
                                                        borderRadius: '6px',
                                                        color: '#7199c9',
                                                        marginLeft: '8px'
                                                    }}
                                                    onClick={(e) => this.copyText(item.stopPrice) } />
                                            </span>
                                        </span>
                                    }
                                </li>
                            )
                        })
                    }
                } else {
                    if (direction.toUpperCase() === "BUY") {
                        const { order2 } = secondaryOrder
                        targets = order2.targets.map((item, key) => {
                            return (
                                <li key={key}>
                                    { parseInt(item.quantity) !== 0 && 
                                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            <span className="target-index">T{key + 1}</span> {item.quantity}% @ 
                                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                <span>{ScientificToDecimal(item.stopPrice)}</span>
                                                <FontAwesomeIcon
                                                    icon={faCopy}
                                                    size="sm"
                                                    style={{
                                                        padding: '5px 10px',
                                                        cursor: 'pointer',
                                                        border: '1px solid #3c5777',
                                                        textAlign: 'center',
                                                        borderRadius: '6px',
                                                        color: '#7199c9',
                                                        marginLeft: '8px'
                                                    }}
                                                    onClick={(e) => this.copyText(item.stopPrice) } />
                                            </span>
                                        </span>
                                    }
                                </li>
                            )
                        })
                    } else {
                        const { order1 } = secondaryOrder
                        targets = order1.targets.map((item, key) => {
                            return (
                                <li key={key}>
                                    { parseInt(item.quantity) !== 0 && 
                                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            <span className="target-index">T{key + 1}</span> {item.quantity}% @ 
                                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                <span>{ScientificToDecimal(item.stopPrice)}</span>
                                                <FontAwesomeIcon
                                                    icon={faCopy}
                                                    size="sm"
                                                    style={{
                                                        padding: '5px 10px',
                                                        cursor: 'pointer',
                                                        border: '1px solid #3c5777',
                                                        textAlign: 'center',
                                                        borderRadius: '6px',
                                                        color: '#7199c9',
                                                        marginLeft: '8px'
                                                    }}
                                                    onClick={(e) => this.copyText(item.stopPrice) } />
                                            </span>
                                        </span>
                                    }
                                </li>
                            )
                        })
                    }
                }
            }
        }

        return (
            <>
                <div className="target-info">
                    <div>
                        <i className="fa fa-bullseye icon"></i>
                        <span>Target(s)</span>
                    </div>

                    <ul>
                        {targets}
                    </ul>
                </div>
            </>
        )
    }

    renderStopLossOrderDetails = () => {
        const { feed, direction, orderDetails } = this.state

        let content = null

        if (isNotNull(orderDetails) &&
            isNotNull(feed) &&
            isNotNull(direction)) {
            const { secondaryOrder } = orderDetails

            if (feed.toUpperCase() === "SPOT") {
                if (direction.toUpperCase() === "BUY") {
                    const { order2 } = secondaryOrder
                    content = (
                        <>
                            <span className="value" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <span>{ScientificToDecimal(order2.stopPrice)}</span>
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    size="sm"
                                    style={{
                                        padding: '5px 10px',
                                        cursor: 'pointer',
                                        border: '1px solid #3c5777',
                                        textAlign: 'center',
                                        borderRadius: '6px',
                                        color: '#7199c9',
                                        marginLeft: '8px'
                                    }}
                                    onClick={(e) => this.copyText(order2.stopPrice) } />
                            </span>
                        </>
                    )
                } else {
                    const { order2 } = secondaryOrder
                    content = (
                        <>
                            <span className="value" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <span>{ScientificToDecimal(order2.stopPrice)}</span>
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    size="sm"
                                    style={{
                                        padding: '5px 10px',
                                        cursor: 'pointer',
                                        border: '1px solid #3c5777',
                                        textAlign: 'center',
                                        borderRadius: '6px',
                                        color: '#7199c9',
                                        marginLeft: '8px'
                                    }}
                                    onClick={(e) => this.copyText(order2.stopPrice) } />
                            </span>
                        </>
                    )
                }
            } else {
                if (direction.toUpperCase() === "BUY") {
                    const { order1 } = secondaryOrder
                    content = (
                        <>
                            <span className="value" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <span>{ScientificToDecimal(order1.stopPrice)}</span>
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    size="sm"
                                    style={{
                                        padding: '5px 10px',
                                        cursor: 'pointer',
                                        border: '1px solid #3c5777',
                                        textAlign: 'center',
                                        borderRadius: '6px',
                                        color: '#7199c9',
                                        marginLeft: '8px'
                                    }}
                                    onClick={(e) => this.copyText(order1.stopPrice) } />
                            </span>
                        </>
                    )
                } else {
                    const { order2 } = secondaryOrder
                    content = (
                        <>
                            <span className="value" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <span>{ScientificToDecimal(order2.stopPrice)}</span>
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    size="sm"
                                    style={{
                                        padding: '5px 10px',
                                        cursor: 'pointer',
                                        border: '1px solid #3c5777',
                                        textAlign: 'center',
                                        borderRadius: '6px',
                                        color: '#7199c9',
                                        marginLeft: '8px'
                                    }}
                                    onClick={(e) => this.copyText(order2.stopPrice) } />
                            </span>
                        </>
                    )
                }
            }
        }
        return (
            <>
                <div className="signal stoploss-info">
                    <div>
                        <i className="fas fa-stop-circle icon"></i>
                        <span>Stop Loss</span>
                    </div>

                    <small className="stop-value">{content}</small>
                </div>
            </>
        )
    }

    render() {
        const { feed } = this.state
        let { tradeSummaryOnly } = this.props
        if (typeof tradeSummaryOnly === 'undefined' || tradeSummaryOnly === null)
            tradeSummaryOnly = false

        return (
            <>                
                <div className="success-container">
                    <h1 className="title" style={{ marginTop: '15px' }}>Trade plan created!</h1>
                    <h2 className="subtitle">What happens next?</h2>

                    <div className="success-banner">
                        <h5>
                            <img src="/images/custom-info.png" alt="" />
                            { tradeSummaryOnly 
                                ? 'You need to execute your trade orders.' 
                                : 'Your trade orders will be activated if your price triggers are met.' }
                        </h5>

                        { tradeSummaryOnly &&
                            <p>Visit your exchange directly or use our advanced trading terminal to execute your trades. You can copy and paste the details below. If you need guidance using the advanced trading terminal, please visit the help centre or Chapter 4 of the <br />'<a href="https://www.profitfarmers.com/academy/#how-to-use">How to Use</a>' section in our trade academy.</p>
                        }

                        { !tradeSummaryOnly &&
                            <p>If the price of the coin does not reach your chosen entry price then you will not buy any coins. The signal will still be tracked in your watchlist.</p>
                        }
                    </div>
                    <p>
                        For trade alerts and updates please <Link to="/account/notification">configure your notifications.</Link>
                    </p>
                </div>

                <div className="trade-summary-contents">
                    <h5 className="summary-title">TRADE SUMMARY</h5>

                    <div className="coin-wrapper">
                        {this.renderCoinInfo()}
                        {this.renderTradeProgress()}
                        {this.renderEntryOrderDetails()}
                        <hr />
                        {this.renderTakeProfitOrderDetails()}
                        <hr />
                        {this.renderStopLossOrderDetails()}
                        <hr />

                        { tradeSummaryOnly && 
                            <div className="signal signal-created">
                                <span style={{ display: 'block', textAlign: 'center', width: '100%' }}>
                                    <Link className="trade-history" onClick={(e) => this.openTradingTerminal(e)}>Open Advanced Trading Terminal <i className="fa fa-arrow-right icon-arrow"></i></Link>
                                </span>
                            </div>
                        }

                        { !tradeSummaryOnly &&
                            <div className="signal signal-created">
                                <span>Created: &nbsp;
                                    <span className="date-created">
                                        <Moment format="MMM D YYYY HH:mm" utc local withTitle>
                                            {this.state.datePublished}
                                        </Moment>
                                    </span>
                                </span>
                                    {
                                        isNotNull(feed) && feed.toUpperCase === "FUTURES" ?
                                            <Link to="/orders" className="trade-history">View trade history <i className="fa fa-arrow-right icon-arrow"></i></Link> :
                                            <Link to={`/orders?feed=${feed.toUpperCase()}`} className="trade-history">View trade history <i className="fa fa-arrow-right icon-arrow"></i></Link>
                                    }
                                </div>    
                        }
                    </div>
                </div>
            </>
        )
    }
}