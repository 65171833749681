import { reminderActionTypes } from '../CONSTANTS'

export function reminder(state = {}, action) {
    switch(action.type) {
        case reminderActionTypes.GET_REMINDERS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case reminderActionTypes.GET_REMINDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.response
            }
        case reminderActionTypes.GET_REMINDERS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function saveReminder(state = {}, action) {
    switch(action.type) {
        case reminderActionTypes.SAVE_REMINDER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case reminderActionTypes.SAVE_REMINDER_SUCCESS:
            return {
                ...state,
                loading: false,
                saveResult: action.response
            }
        case reminderActionTypes.SAVE_REMINDER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}