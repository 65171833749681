import { tradeDoctorSessionActionTypes } from '../CONSTANTS'

export function tradeDocSession(state = {}, action) {
    switch(action.type) {
        case tradeDoctorSessionActionTypes.GET_TRADEDOC_SESSION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case tradeDoctorSessionActionTypes.GET_TRADEDOC_SESSION_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.response
            }
        case tradeDoctorSessionActionTypes.GET_TRADEDOC_SESSION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function saveTradeDocSession(state = {}, action) {
    switch(action.type) {
        case tradeDoctorSessionActionTypes.SAVE_TRADEDOC_SESSION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case tradeDoctorSessionActionTypes.SAVE_TRADEDOC_SESSION_SUCCESS:
            return {
                ...state,
                loading: false,
                saveResult: action.response
            }
        case tradeDoctorSessionActionTypes.SAVE_TRADEDOC_SESSION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}