import styled from 'styled-components'

export const StatisticContainaner = styled.div`
	max-width: 100%;
	margin: 0 auto;
	position: relative;

	&.filters {
		display: flex;
		justify-content: space-between;
		align-items: center;
		/* margin-bottom: 15px; */
		margin-bottom: 40px;
		width: 100%;

		h2 {
			margin-bottom: 0;
		}

		.filter-lists {
			display: flex;
			/* justify-content: space-between; */
			justify-content: flex-end;
			align-items: center;
			padding: 0;
		}

		.filters-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0;

			.filter-title {
				font-size: 34px;
				font-weight: 500;
				// margin-bottom: 20px;

				svg {
					font-size: 12px;
					color: #15bd6f;
					margin-right: 10px;

					@media screen and (min-width: 766px) and (max-width: 992px) {
						font-size: 25px;
					}

					@media screen and (min-width: 993px) and (max-width: 1024px) {
						font-size: 28px;
					}

					@media screen and (min-width: 1025px) and (max-width: 3000px) {
						font-size: 30px;
					}
				}

				@media screen and (min-width: 320px) and (max-width: 766px) {
					font-size: 20px;
				}

				@media screen and (min-width: 767px) and (max-width: 1024px) {
					font-size: 25px;
				}

				@media screen and (min-width: 1025px) and (max-width: 1199px) {
					font-size: 28px;
				}

				@media screen and (min-width: 1200px) and (max-width: 1440px) {
					font-size: 28px;
				}

				@media screen and (min-width: 1441px) and (max-width: 1749px) {
					font-size: 30px;
				}
			}
		}

		@media screen and (min-width: 320px) and (max-width: 766px) {
			margin-bottom: 0px;
		}
	}

	&.banners {
		padding: 20px 0px;
		position: relative;
		display: grid;
		height: auto;
		width: auto;
		min-width: auto;
		grid-template-columns: repeat(5, 1fr);
		grid-template-rows: auto;
		column-gap: 20px;
		row-gap: 20px;

		@media screen and (min-width: 768px) and (max-width: 1280px) {
			display: grid !important;
			grid-template-columns: repeat(3, 1fr);
		}

		.card {
			text-align: center;
			padding: 15px 20px;
			border-radius: 4px;
			background: #0B1422;
			margin-bottom: 15px;
			min-height: 95px;
			border: 1px solid #1E2A37;

			small {
				text-align: left;
				color: #6E7885;
				font-weight: 600;
			}

			h2 {
				text-align: right;
			    margin-right: 15px;
				color: #fff;
				font-weight: 600;
			    font-size: 35px;
			    margin-bottom: 0;
			}

			@media screen and (min-width: 320px) and (max-width: 766px) {
				margin-bottom: 5px;
			}
		}
	}

	@media screen and (min-width: 320px) and (max-width: 766px) {
		display: block !important;
		margin-bottom: 5px;

		.statistics-strat {
			display: none;
		}

		&.filters {
			.filter-lists {
				justify-content: space-between;
				margin-top: 15px;
				flex-direction: column;

				.filter {
					width: 100%;
				}
			}
		}

		&.banners {
			.row {
				display: grid;
				grid-template-columns: auto auto;

				.card {
					padding: 15px 12px;

					h2 {
						margin: 10px 0 0;
						font-size: 30px;
					}
				}
			}
		}
	}

	@media screen and (min-width: 767px) and (max-width: 1024px) {
		display: block !important;
		margin-bottom: 5px !important;

		.statistics-strat {
			display: none;
		}

		&.filters {
			.filters-title {
				max-width: 100%;
				width: 100%;
			}

			.filter-lists {
				justify-content: flex-end;
				margin: 15px 0 25px;
				max-width: 100%;
				width: 100%;
			}
		}

		&.banners {
			.row {
				.card {
					padding: 15px 12px;
					margin-bottom: 15px;

					h2 {
						margin: 10px 0 0;
						font-size: 30px;
					}
				}
			}
		}
	}

	@media screen and (min-width: 1025px) and (max-width: 1199px) {
		display: block !important;
		margin-bottom: 5px !important;

		&.filters {
			.filters-title {
				max-width: 100%;
				width: 100%;
			}

			.filter-lists {
				justify-content: flex-end;
				margin: 15px 0 25px;
				max-width: 100%;
				width: 100%;
			}
		}
	}

	@media screen and (min-width: 1200px) and (max-width: 1440px) {
		max-width: 1348px;

		.statistics-strat {
			font-size: 14px;
		}
	}

	@media screen and (min-width: 1441px) and (max-width: 1749px) {
		max-width: 1348px;
	}
`;

export const StatisticWrapper = styled.div`
	padding: 40px 30px;
	min-height: calc(100vh - 60px);
	background: transparent;
	// background: #131E2B;

	@media screen and (min-width: 320px) and (max-width: 766px) {
		padding: 20px 15px;
	}
`