import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const EpicOpportunitiesSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    background-color: #060B0F;
    padding: 60px 0;

    .container {
        max-width: 100%;
        margin: 0 auto;
        text-align: center;
        color: #fff;

        .section-title {
            color: #fff;
            font-size: 22px;
            font-weight: 800;
            max-width: 100%;
            margin: 0 auto 15px;
        }

        .snapvids {
            display: block;
            align-items: center;
            justify-content: space-between;
            margin: 0 auto 20px;
            list-style-type: none;
            padding: 0;

            li {
                padding: 10px;
                width: 100%;
                margin-bottom: 15px;

                > div {
                    margin-bottom: 15px;
                    height: 328px !important;
                }

                > p {
                    font-size: 14px;
                    margin-bottom: 0;
                }
            }
        }

        .section-description {
            font-size: 15px;
        }
    }

    ${mediaQueries.mediumDevices} {
        .container {
            .section-title {
                font-size: 26px;
                max-width: 620px;
            }

            .snapvids {
                display: flex;

                li {
                    width: 246px;

                    > div {
                        height: 236px !important;
                    }
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        .container {
            max-width: 980px;

            .section-title {
                font-size: 32px;
                max-width: 800px;
            }

            .snapvids {
                z-index: 1;

                li {
                    width: 315px;
                    position: relative;

                    &:first-child {
                        &:before {
                            content: ' ';
                            position: absolute;
                            bottom: -80px;
                            left: -150px;
                            width: 350px;
                            height: 270px;
                            background-image: url(/images/trial-offer/ellipsis-gray.svg);
                            background-size: contain;
                            background-repeat: no-repeat;
                            z-index: 0;
                        }
                    }

                    &:last-child {
                        &:after {
                            content: ' ';
                            position: absolute;
                            top: -100px;
                            right: -150px;
                            width: 350px;
                            height: 270px;
                            background-image: url(/images/trial-offer/ellipsis-green-svg);
                            background-size: contain;
                            background-repeat: no-repeat;
                            z-index: 0;
                        }
                    }

                    > div {
                        height: 305px !important;
                    }
                }
            }

            .section-description {
                font-size: 20px;
                max-width: 760px;
                margin: 0 auto;
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        padding: 80px 0;

        .container {
            max-width: 1200px;

            .section-title {
                font-size: 40px;
                max-width: 700px;
                margin: 0 auto 10px;
            }

            .snapvids {
                li {
                    width: 373px;

                    > div {
                        height: 360px !important;
                    }

                    > p {
                        font-size: 16px;
                    }
                }
            }

            .section-description {
                max-width: 800px;
            }
        }
    }
`