import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const UserFeedbackStyle = styled.section`
    position: relative;

    & .container {
        .feedback-list {
            display: none;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            padding: 0;
            list-style-type: none;
            
            li {
                text-align: center;

                img {
                    width: 90%
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        & .container {
            & .feedback-list {
                display: flex;
            }
        }
    }
`