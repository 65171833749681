import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Dropdown } from 'react-bootstrap'
import {
    PeriodRangeWrapper
} from '../PeriodRange/styled_components/PeriodRangeStyle'
import { isNotNull, onSetNewState } from '../../../../utils'

class StrategyFilter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            strategyItems: new Map()
        }
        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            if (prevProps.strategiesFilter !== this.props.strategiesFilter) {
                let { loader, filters } = this.props.strategiesFilter
                if (!loader) {
                    if (typeof filters !== 'undefined' && filters !== null && filters !== "") {
                        _.forEach(filters, e => {
                            onSetNewState(this, prevState => ({ strategyItems: prevState.strategyItems.set(e.key, true) }))
                        })
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)
        this.mounted = false
    }

    onStrategyChangeHandler = (event) => {
        if (this.mounted) {
            const item = event.target.name
            const isChecked = event.target.checked
            onSetNewState(this, prevState => ({ strategyItems: prevState.strategyItems.set(item, isChecked) }))

            let { strategyItems } = this.state
            const oldList = []
            const newLists = []

            strategyItems.forEach((value, key) => {
                oldList.push({ "key": key, "value": value })
            })

            if (oldList.length > 0) {

                oldList.forEach(data => {
                    if (data.key === item) {
                        newLists.push({ "key": data.key, "value": isChecked })
                    } else {
                        newLists.push(data)
                    }
                })

                this.props.onStrategyChange(newLists)
            }
        }
    }

    //Select All Strategy Filter
    onAllStrategyHandler = (e) => {
        if (this.mounted) {
            e.preventDefault()
            let { strategyItems } = this.state
            const newLists = []

            strategyItems.forEach((value, key) => {
                onSetNewState(this, prevState => ({ strategyItems: prevState.strategyItems.set(key, true) }))
                newLists.push({ "key": key, "value": true })
            })

            if (newLists.length > 0)
                this.props.onStrategySubmit(newLists)
        }
    }

    //Submit Strategy Filter Update
    onSubmitHandler = (e) => {
        e.preventDefault()

        let { strategyItems } = this.state
        const newLists = []

        strategyItems.forEach((value, key) => {
            newLists.push({ "key": key, "value": value })
        })

        if (newLists.length > 0)
            this.props.onStrategySubmit(newLists)
    }

    render() {
        const { loader, filters } = this.props.strategiesFilter
        const { selectedFilter, isChange, isEnable } = this.props
        let dropdownItem = null

        if (!loader && isNotNull(filters)) {
            const orderByStrategyLists = _.orderBy(filters, val => val.value, "asc")
            dropdownItem = orderByStrategyLists.map((val, key) => {
                return (
                    <div key={key} className="strategy-filter">
                        <input name={val.key} type="checkbox" id={val.key} onChange={this.onStrategyChangeHandler} checked={this.state.strategyItems.get(val.key)} />
                        <label htmlFor={val.key}>{val.value}</label>
                    </div>
                )
            })
        }


        return (
            <PeriodRangeWrapper>
                <Dropdown >
                    <Dropdown.Toggle id="dropdown-date" disabled={isEnable}>
                        {selectedFilter}
                    </Dropdown.Toggle>
                    <Dropdown.Menu alignRight>
                        <div className="strategy-lists">
                            {
                                isChange ?
                                    <Dropdown.Item onClick={(e) => this.onSubmitHandler(e)} className="submit mb-4">Done</Dropdown.Item>
                                    :
                                    <Dropdown.Item onClick={(e) => this.onAllStrategyHandler(e)} className="submit mb-4">All Strategies</Dropdown.Item>
                            }
                            {dropdownItem}
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </PeriodRangeWrapper>
        )
    }

}

function mapStateToProps(state) {
    const { strategiesFilter } = state
    return {
        strategiesFilter
    }
}

const connectedStrategyFilter = connect(mapStateToProps)(StrategyFilter)
export { connectedStrategyFilter as StrategyFilter }
