import styled from 'styled-components'

export const TradeDivider = styled.div`
    border-bottom: 2px solid #172432;
    margin: 5px 0 0px;
`
export const WalletBalanceWrapper = styled.div`
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    @media screen and (min-width: 320px) and (max-width: 766px) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        /* flex-direction: column; */
        /* padding: 10px 15px 5px; */
        background: #0F1B29;
        border-top: 1px solid #1C2C3E;
        padding: 4px;
        width: 100%;

        &.advanced-wallet {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
        }

        p {
            margin-bottom: 0;
            text-transform: uppercase;
            color: #B5BECB;
            font-weight: 500;
            padding: 10px 8px;

            &.order-wallet-title {
                /* border-bottom: 1px solid #1f2b39; */
                /* width: 100%; */
                font-size: 14px;
            }

            &.coin-signs {
                /* color: #2274FF; */
                color: #53AE94;
                width: 100%;
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: #142336;
                padding: 10px 5px !important;
                margin-right: 4px;

                &.btc {
                    text-align: right;
                    /* border-left: 1px solid #2d3c4e; */
                    margin-right: 0 !important;
                    color: #FE9900 !important;
                }

                .wallet-value {
                    padding: 3px 6px;
                    font-size: 11px;
                    background: #162c4c;
                    font-weight: 600;
                    border-radius: 2px;

                    &:first-child {
                        background: rgb(83 174 148 / .1) !important;
                    }

                    &:last-child {
                        background: rgb(254 153 0 / .1) !important;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 767px) and (max-width: 1199px) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        background: #0F1B29;
        border-top: 1px solid #1C2C3E;
        padding: 4px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        &.advanced-wallet {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
        }

        p {
            margin-bottom: 0;
            text-transform: uppercase;
            color: #B5BECB;
            font-weight: 500;
            padding: 10px 8px;

            &.order-wallet-title {
                /* border-bottom: 1px solid #1f2b39; */
                /* width: 100%; */
                font-size: 14px;
                min-width: 100px;
            }

            &.coin-signs {
                /* color: #2274FF; */
                color: #53AE94;
                width: 100%;
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: #142336;
                padding: 10px 5px !important;
                margin-right: 4px;

                &.btc {
                    text-align: right;
                    /* border-left: 1px solid #2d3c4e; */
                    margin-right: 0 !important;
                    color: #FE9900 !important;
                }

                .wallet-value {
                    padding: 3px 6px;
                    font-size: 11px;
                    background: #162c4c;
                    font-weight: 600;
                    border-radius: 2px;

                    &:first-child {
                        background: rgb(83 174 148 / .1) !important;
                    }

                    &:last-child {
                        background: rgb(254 153 0 / .1) !important;
                    }
                }
            }
        }
    }
`;

export const BalanceWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;


export const TradeFormHeaderWrapper = styled.div`
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    
    @media screen and (min-width: 320px) and (max-width: 1023px) {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 2px solid rgb(31, 43, 57);
    }
`

export const TradeFormHeading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    h2 {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 18px;
        margin-bottom: 0;

        svg {
            font-size: 14px;
            margin-left: 10px;
        }

        .success-close {
            display: none;
        }

        .success-close-display {
            display: block !important;
        }

        @media screen and (min-width: 320px) and (max-width: 1199px) {
            &.trading-title {
                display: none;
            }
        }

        @media screen and (min-width: 320px) and (max-width: 1024px) {
            justify-content: center;
            margin-bottom: 15px;
        }
    }

    &.hide {
        display: none;
    }

    @media screen and (min-width: 320px) and (max-width: 1199px) {
        display: block;
    }
`

export const TradeFormContainer = styled.div`
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding: 0;
    color: #6c7987;
    position: relative;
    height: 100%;
    background-color: #0B1219;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;

    &.active {
        -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes scale-in-center {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes scale-in-center {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }

    @media screen and (min-width: 360px) and (max-width: 1199px) {
        width: 100%;
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        width: 948px;
    }
`

export const TradeFormContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px;
    width: 630px;
    height: auto;
    overflow: hidden;

    &.adjust-size {
        padding: 35px 25px;
        width: 100%;

        .set-bg {
            width: 100%;
            padding: 0;

            #error,
            #success {
                width: 100%;
            }
        }
    }

    #show-advanced {
        &.hide {
            display: none;
        }
    }

    @media screen and (min-width: 320px) and (max-width: 766px) {
        padding: 15px;
        width: 100%;

        &.adjust-size {
            padding: 0 15px 15px;
        }
    }

    @media screen and (min-width: 767px) and (max-width: 1199px) {
        padding: 10px 20px 15px;
        width: 100%;

        &.adjust-size {
            padding: 0 20px 25px;
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        width: 600px;
        padding: 20px;

        &.adjust-size {
            height: auto !important;
            padding: 30px 25px;
        }
    }

    @media screen and (min-width: 1441px) and (max-width: 1650px) {
        &.adjust-size {
            height: auto !important;
        }
    }
`

export const TradeFormSignalDetailsStyle = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    position: absolute;
    right: 0;
    width: 369px;
    height: 100%;
    background-color: #0E1824;
    overflow: auto;

    .signal-info-wrapper {
        margin-bottom: 20px;
        padding: 0px 18px;

        .signal-title {
            font-size: 15px;
            margin-top: 15px;
            margin-bottom: 10px;
            padding-bottom: 15px;
            font-weight: 500;
            border-bottom: 2px solid #1f2b39;
        }
    }

    &::-webkit-scrollbar {
        width: 6px;
        background: #1f2b39;
        color: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #3f506b;
        border-radius: 6px;
    }

    @media screen and (min-width: 320px) and (max-width: 1199px) {
        display: none !important;
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        width: 350px;
    }
`

export const StrategySelector = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 6px;

    label {
        padding-top: calc(.375rem + 1px);
        padding-bottom: calc(.375rem + 1px);
        margin-bottom: 0;
        font-size: 15px;
        color: #B5BECB;
        font-weight: 500;

        @media screen and (min-width: 320px) and (max-width: 768px) {
            font-size: 14px !important;
            padding-bottom: 0;
        }
    
        @media screen and (min-width: 1024px) and (max-width: 1440px) {
            font-size: 14px !important;
            padding-top: 10px;
        }
    }

    .form-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;

        select {
            color: white;
            background-color: #223346;
            border: none;
            font-size: 13px;
            text-transform: uppercase;
            border-radius: 6px;
            padding: 0 10px;
            border: 1px solid #3C5778;

            @media screen and (min-width: 767px) and (max-width: 1199px) {
                font-size: 14px;
            }

            &:focus {
                box-shadow: none;
                background-color: #0E1824;
                color: white;
            }

            &.sm {
                font-size: 13px;
            }
        }

        @media screen and (min-width: 320px) and (max-width: 766px) {
            select {
                font-size: 12px;
            }
        }
    }

    &.sm {
        font-size: 13px;
        padding: 10px;
    }

    &.md {
        font-size: 16px;
        padding: 10px;
    }

    @media screen and (min-width: 320px) and (max-width: 1199px) {
        justify-content: space-between;
        width: 100%
    }
`

export const CoinPairingWrapper = styled.div`
    margin-top: 20px;

    .coinpair-wrapper {
        padding-bottom: 0;
        margin-bottom: 0;

        .form-row {
            justify-content: space-between;
            margin-bottom: 0.5rem;
        }

        .pairing-label {
            display: flex;
            align-items: center;
            margin-bottom: 0 !important;
            font-size: 15px;
            padding: 0;

            .coinpair-title {
                padding: 8px 10px;
                background: #0e1824;
                color: #fff;
                margin-right: 8px;
                min-height: 38px;

                &.mobile {
                    display: none;
                }
            }
        }

        .pairing-prices-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            font-weight: 500;

            .coinpair-title {
                padding: 8px 10px;
                background-color: #060b10;
                color: #fff;
                margin-right: 8px;
                min-height: 38px;
                height: 89px;
                width: 40%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                border-radius: 9px;
            }

            .pairing-prices-content {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
            }

            .pairing-prices {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;

                .coin-pairing {
                    padding: 6px 10px;
                    width: 100%;

                    &.bid {
                        background-color: rgb(33 113 195 / 10%);
                        margin-right: 10px;
                        border-radius: 6px;
                    }

                    &.ask {
                        background: rgb(153 57 86 / 10%);
                        border-radius: 6px;
                    }

                    &.price {
                        background: #0e151e;
                        border-radius: 6px;
                    }

                    .coinpair-details {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .bidprice {
                            background: rgb(0 0 0 / 30%);
                            padding: 3px 10px;
                            color: #3b98cc;
                            font-size: 11px;
                            font-weight: 600;
                            letter-spacing: .5px;
                            border-radius: 4px;
                        }

                        .askprice {
                            background: rgb(0 0 0 / 30%);
                            padding: 3px 10px;
                            color: #B93F5F;
                            font-size: 11px;
                            font-weight: 600;
                            letter-spacing: .5px;
                            border-radius: 4px;
                        }

                        .lastprice {
                            padding: 3px 10px;
                            font-size: 12px;
                            letter-spacing: .5px;
                            background: rgb(0 0 0 / 30%);
                            color: #bccad1;
                            border-radius: 4px;
                        }

                        .tradeBidPrice {
                            color: #98b2c5;
                            letter-spacing: 1px;
                        }

                        .tradeAskPrice {
                            color: #c9a3ae;
                            letter-spacing: 1px;
                        }

                        .tradeLastPrice {
                            font-size: 16px;
                            font-weight: 600;
                            color: #fff;
                            letter-spacing: 1px;
                        }

                        .bidprice,
                        .askprice {
                            margin-right: 8px;
                        }

                        .price {
                            cursor: pointer;
                            color: #fff;

                            @keyframes fadeIn {
                                from {
                                    opacity: 0;
                                }
                                to {
                                    opacity: 1;
                                }
                            }

                            &.fadeIn {
                                opacity: 1;
                                animation-iteration-count: 1;
                                animation-timing-function: ease-in;
                                animation-duration: 0.5s;
                                animation-name: fadeIn;
                            }
                        }
                    }
                }
            }
        }

        .blended-rr-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 200px;
            background-color: #0D191D;
            padding: 6px 10px;
            border-radius: 6px;
            margin-right: 5px;

            .blended-rr-title {
                background-color: #122423;
                color: #15B866;
                padding: 3px 10px;
                font-size: 13px;
                font-weight: 600;
                border-radius: 4px;
            }

            .blended-rr-value {
                font-weight: 600;
                font-size: 15px;
                color: #E4E4E4;
            }
        }
    }

    &.hide {
        display: none;
    }

    @media screen and (min-width: 320px) and (max-width: 766px) {
        margin-top: 0px;

        .coinpair-wrapper {
            margin-top: 5px;

            .pairing-label {
                padding: 0 5px;
                margin-bottom: 10px;
                justify-content: space-between;

                .coinpair-title {
                    &.mobile {
                        display: block !important;
                        margin-right: 0;
                    }
                }
            }

            .pairing-prices-wrapper {
                .coinpair-title {
                    &.desktop {
                        display: none;
                    }
                }

                .pairing-prices {
                    .coin-pairing {
                        padding: 8px !important;
                        font-size: 13px !important;

                        .coinpair-details {
                            .bidprice,
                            .askprice {
                                padding: 3px 8px !important;
                                font-size: 10px !important;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        .coinpair-wrapper {
            .pairing-prices-wrapper {
                font-size: 12px;

                .pairing-prices {
                    .coin-pairing {
                        padding: 8px !important;

                        .coinpair-details {
                            .bidprice,
                            .askprice {
                                padding: 3px 8px;
                                font-size: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
`

export const TradeFormLabel = styled.label`
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 5px;
    font-size: 15px;
    color: #B5BECB;
    font-weight: 500;

    @media screen and (min-width: 320px) and (max-width: 768px) {
        font-size: 14px !important;
        padding-bottom: 0;
    }

    @media screen and (min-width: 1024px) and (max-width: 1440px) {
        font-size: 14px !important;
        padding-top: 10px;
    }
`

export const TradeFormTypeToggle = styled.div`
    width: 100%;
    padding: 15px 0;
    border-bottom: 2px solid #1f2b39;
    margin-bottom: 15px;

    @media screen and (min-width: 320px) and (max-width: 766px) {
        padding: 10px 0px;
        margin-bottom: 10px;
    }

    @media screen and (min-width: 767px) and (max-width: 1199px) {
        margin-bottom: 0;
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        padding: 10px 0 15px;
        margin-bottom: 10px;
    }

    @media screen and (min-width: 1441px) and (max-width: 1650px) {
        padding: 10px 0 15px;
        margin-bottom: 10px;
    }
`

export const TradeFormTypeToggleBtn = styled.label`
    display: block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    margin-bottom: 0;
    color: #fff;
    font-size: 16px;
    padding: 0 25px;

    &.active {
        pointer-events: auto;
        color: #94a7c1;
    }

    i {
        position: relative;
        display: inline-block;
        margin-right: .5rem;
        width: 38px;
        height: 21px;
        background-color: #383e4e;
        border-radius: 23px;
        vertical-align: text-bottom;
        transition: all 0.3s linear;

        &.active {
            &::before {
                background-color: #19212b;
            }

            &::after {
                background-color: #ccd4de;
            }
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 33px;
            height: 17px;
            background-color: #545659;
            border-radius: 11px;
            transform: translate3d(2px,2px,0) scale3d(1,1,1);
            transition: all 0.25s linear;
        }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 16px;
            height: 16px;
            background-color: #707274;
            border-radius: 11px;
            box-shadow: 0 0px 2px 2px rgba(0,0,0,0.24);
            transform: translate3d(2px,2px,0);
            transition: all 0.2s ease-in-out;
        }
    }

    input {
        display: none;

        &:checked + i {
            background-color: #15BD6F;

            &::before {
                transform: translate3d(14px, 2px, 0) scale3d(0, 0, 0);
            }

            &::after {
                transform: translate3d(20px, 2px, 0);
                background-color: #25313F;
            }
        }
    }

    &:active {
        i {
            &::after {
                width: 32px;
                transform: translate3d(2px,2.3px,0);
            }
        }

        input {

            &:checked + i {

                &::after {
                    width: 32px;
                    transform: translate3d(3px,2px,0);
                }
            }
        }
    }

    @media screen and (min-width: 320px) and (max-width: 1199px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        flex-direction: row-reverse;
        font-size: 14px;
        color: #B5BECB;
        font-weight: 500;
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        font-size: 15px;
    }
`

export const TradeFormBody = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    float: left;
    width: 580px;
    height: 100%;

    &.basic-trade-block {
        width: 100%;

        #accordion {
            padding-right: 10px;
        }

        .button-confirm {
            margin-bottom: 0;
            margin-top: 20px;
        }

        @media screen and (min-width: 320px) and (max-width: 1199px) {
            #accordion {
                padding: 0;
            }

            .button-confirm {
                margin-bottom: 0 !important;
                margin-top: 0;
                position: fixed;
                bottom: 50px;
                left: 0;
                right: 0;
                padding: 0;
            }
        }
    }

    .form-group {
        .addTarget {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            a {
                color: #99d54f;
            }
        }

        .form-control {
            background-color: #0E151E;
            border-color: #213247;
            color: #dbdcdf;
            font-size: 14px;
            border-radius: 6px;
            height: 40px;

            &:focus {
                color: #f2f7fd;
                background-color: #17212d;
                border: 1px solid #15BD6F80;
                outline: 0;
                box-shadow: 0 0 0 0.1rem #15bd6f26;
            }

            &.error {
                border: 1px solid #C8315E !important;
                -webkit-animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
                animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
                z-index: 100;

                @-webkit-keyframes shake-horizontal {
                    0%,
                    100% {
                        -webkit-transform: translateX(0);
                        transform: translateX(0);
                    }

                    10%,
                    30%,
                    50%,
                    70% {
                        -webkit-transform: translateX(-10px);
                        transform: translateX(-10px);
                    }

                    20%,
                    40%,
                    60% {
                        -webkit-transform: translateX(10px);
                        transform: translateX(10px);
                    }

                    80% {
                        -webkit-transform: translateX(8px);
                        transform: translateX(8px);
                    }

                    90% {
                        -webkit-transform: translateX(-8px);
                        transform: translateX(-8px);
                    }
                }

                @keyframes shake-horizontal {
                    0%,
                    100% {
                        -webkit-transform: translateX(0);
                        transform: translateX(0);
                    }

                    10%,
                    30%,
                    50%,
                    70% {
                        -webkit-transform: translateX(-10px);
                        transform: translateX(-10px);
                    }

                    20%,
                    40%,
                    60% {
                        -webkit-transform: translateX(10px);
                        transform: translateX(10px);
                    }

                    80% {
                        -webkit-transform: translateX(8px);
                        transform: translateX(8px);
                    }

                    90% {
                        -webkit-transform: translateX(-8px);
                        transform: translateX(-8px);
                    }
                }
            }
        }

        .form-control:disabled, .form-control[readonly] {
            background-color: #233240;
            border-radius: 2px;
            box-shadow: none;
            color: #3c5265;
            cursor: not-allowed;
        }
    }

    &:last-child {
        margin-right: 0;
    }

    .card {
        background-color: transparent;
        border: none;
        border-radius: 0;

        .success-checkmark {
            width: 80px;
            height: 115px;
            zoom: 2;
            margin: 0 auto;

            .check-icon {
                width: 80px;
                height: 80px;
                position: relative;
                border-radius: 50%;
                box-sizing: content-box;
                border: 4px solid #4CAF50;

                &::before {
                    top: 3px;
                    left: -2px;
                    width: 30px;
                    transform-origin: 100% 50%;
                    border-radius: 100px 0 0 100px;
                }

                &::after {
                    top: 0;
                    left: 30px;
                    width: 60px;
                    transform-origin: 0 50%;
                    border-radius: 0 100px 100px 0;
                    animation: rotate-circle 4.25s ease-in;
                }

                &::before, &::after {
                    content: '';
                    height: 100px;
                    position: absolute;
                    background: transparent;
                    transform: rotate(-45deg);
                }

                .icon-line {
                    height: 5px;
                    background-color: #4CAF50;
                    display: block;
                    border-radius: 2px;
                    position: absolute;
                    z-index: 10;

                    &.line-tip {
                        top: 46px;
                        left: 14px;
                        width: 25px;
                        transform: rotate(45deg);
                        animation: icon-line-tip 0.75s;
                    }

                    &.line-long {
                        top: 38px;
                        right: 8px;
                        width: 47px;
                        transform: rotate(-45deg);
                        animation: icon-line-long 0.75s;
                    }
                }

                .icon-circle {
                    top: -4px;
                    left: -4px;
                    z-index: 10;
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    position: absolute;
                    box-sizing: content-box;
                    border: 4px solid rgba(76, 175, 80, .5);
                }

                .icon-fix {
                    top: 8px;
                    width: 5px;
                    left: 26px;
                    z-index: 1;
                    height: 85px;
                    position: absolute;
                    transform: rotate(-45deg);
                    background-color: transparent;
                }
            }
        }

        @keyframes rotate-circle {
            0% {
                transform: rotate(-45deg);
            }
            5% {
                transform: rotate(-45deg);
            }
            12% {
                transform: rotate(-405deg);
            }
            100% {
                transform: rotate(-405deg);
            }
        }

        @keyframes icon-line-tip {
            0% {
                width: 0;
                left: 1px;
                top: 19px;
            }
            54% {
                width: 0;
                left: 1px;
                top: 19px;
            }
            70% {
                width: 50px;
                left: -8px;
                top: 37px;
            }
            84% {
                width: 17px;
                left: 21px;
                top: 48px;
            }
            100% {
                width: 25px;
                left: 14px;
                top: 45px;
            }
        }

        @keyframes icon-line-long {
            0% {
                width: 0;
                right: 46px;
                top: 54px;
            }
            65% {
                width: 0;
                right: 46px;
                top: 54px;
            }
            84% {
                width: 55px;
                right: 0px;
                top: 35px;
            }
            100% {
                width: 47px;
                right: 8px;
                top: 38px;
            }
        }

        .card-body {
            padding: 15px 30px;
        }

        &.with-form {
            border-radius: 15px;

            .card-body {
                padding: 0;
                overflow-y: auto;
                overflow-x: hidden;
                max-height: 550px;

                &::-webkit-scrollbar-track {
                    background-color: #192431;
                }

                &::-webkit-scrollbar {
                    width: 6px;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background-color: #273346;
                }

                .form-horizontal {
                    .error-wrapper {
                        padding: 10%;

                        h1 {
                            font-weight: bolder;
                            color: #D25D87;
                            margin: 30% auto 0 auto;
                            text-align: center;

                            @media screen and (min-width: 320px) and (max-width: 766px) {
                                margin: 50% auto 0;
                            }
                        }

                        label {
                            color: #9DA7CC;
                            font-weight: bolder;
                            margin-top: 10%;
                        }

                        p {
                            color: #fff;
                            font-size: 13px;
                        }
                    }

                    .form-group {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &.success {
                background-color: transparent;

                img {
                    width: 45px;
                    height: 45px;
                }

                .card-body {
                    padding: 0;
                    max-height: unset;
                }
            }

            &.error {
                margin: 65px 0 0;

                .card-body {
                    padding: 0px;
                }

                img {
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    top: -11%;
                }

                .btn {
                    &.btn-default {
                        &.btn-close-error {
                            position: absolute;
                            width: 40%;
                            bottom: 0;
                            font-weight: bolder;
                            left: 30%;
                            background: #2D3B4D;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    &.in,
    &.out {
        -webkit-animation: slide-in-right 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-in-right 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-right {
        0% {
            -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes slide-in-right {
        0% {
            -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }

    @media screen and (min-width: 320px) and (max-width: 766px) {
        min-height: 1px;
        height: unset;

        &#orderForm {
            width: calc(${props => props.width}px - 20px);

            &:nth-child(4) {
                .button-action {
                    padding: 0 !important;
                }
            }
        }

        &#success {
            width: calc(${props => props.width}px - 30px);
        }

        &#error {
            width: calc(${props => props.width}px - 25px);
            min-height: 550px !important;
            height: 550px;
            margin-bottom: 0 !important;

            .card {
                height: 100%;

                .card-body {
                    padding-top: 50px;
                }
            }
        }
    }

    @media screen and (min-width: 767px) and (max-width: 1199px) {
        min-height: 1px;
        height: unset;

        &#orderForm {
            width: 590px;
        }

        &#error,
        &#success {
            width: 590px;
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        .card {
            &.with-form {
                .card-body {
                    max-height: 445px;
                }
            }
        }

        &#orderForm {
            width: 560px;
            min-height: unset;
        }
    }

    @media screen and (min-width: 1441px) and (max-width: 1650px) {
        .card {
            &.with-form {
                .card-body {
                    max-height: 500px;
                }
            }
        }

        &#orderForm {
            min-height: unset;
        }
    }
`

export const TradeFormInner = styled.div`
    padding-right: 10px !important;

    #trade-forms-error {
        font-family: 'Montserrat', sans-serif;
        min-width: 220px;
        position: absolute;
        // bottom: -85px;
        top: 55px;
        background: linear-gradient(180deg, rgba(12, 23, 36, 0.978029) 51%, rgba(6, 21, 41, 0.98083) 68%);
        color: #B1BAC7;
        z-index: 150;
        padding: 10px 15px;
        opacity: 1;
        border-radius: 4px;
        // transition: all 300ms ease-in-out;

        &::before {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 8px;
            border-style: solid;
            border-color: transparent transparent #0c1724 transparent;
        }

        .header-wrapper {
            display: flex;
            align-items: center;

            .popover-title-header {
                display: flex;
                align-items: center;
                margin-bottom: 6px;

                span {
                    background: #b93f5f52;
                    color: #B93F5F;
                    border-radius: 50%;
                    width: 25px;
                    height: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 5px;

                    svg {
                        font-size: 12px;
                        margin-bottom: 2px;
                    }
                }

                .popover-title {
                    color: #B93F5F;
                    font-size: 15px;
                    margin-bottom: 0;
                    line-height: 1;
                    font-weight: 600;
                }
            }

            button {
                background: transparent;
                border-color: transparent;
                line-height: 0;
                position: absolute;
                top: 15px;
                right: 10px;
                font-size: 15px;
                color: #3E4855;
                font-weight: 700;
                padding: 0;

                &:active,
                &:focus {
                    border: none !important;
                    box-shadow: none !important;
                }
            }
        }

        small {
            font-size: 13px;
        }

        @media screen and (min-width: 768px) and (max-width: 1199px) {
            top: 53px;
        }
    }

    .float-right {
        display: flex;
    }

    .switch-button {
        display: flex !important;
        padding: 2px 6px !important;
        background-color: #0e1824;
        border: 1px solid #213247;
        box-shadow: 1px 1px 12px rgb(0 0 0 / 30%) inset;
        border-radius: 6px;
        
        .input-cont {
            margin: 0 !important;

            .toggle-wrapper {
                .theme-toggle {
                    margin: 0 5px 0 0 !important;
                    background-color: #0d1722;

                    &.active {
                        &::before {
                            background-color: #060b10;
                        }
                    }
                }
            }

            .toggle-label {
                padding: 5px !important;

                &.enabled {
                    color: #15bd6f
                }
            }
        }

        &:last-child {
            margin-right: 0 !important;
        }
    }
    
    @media (max-width: 766px) {
        &.basic {
            max-height: unset !important;
            height: 100%;
        }
    }

    @media screen and (min-width: 767px) and (max-width: 1199px) {
        max-height: 450px !important;
    }
`

export const FormHorizontal = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    position: relative;

    .total-x-entry {
        display: flex;
        justify-content: center;
        /* align-items: flex-end; */
        align-items: center;
        margin-bottom: 0;
        color: #fff;
        font-size: 16px;
    }

    #buy_details, #target_details, #stop_loss_details {
        padding-left: 25px;
        padding-right: 5px;

        @media screen and (min-width: 320px) and (max-width: 766px) {
            padding-left: 0px;
            margin-bottom: 16px;
        }
    }

    #buy_details {
        padding-bottom: 20px;

        .total-x-entry {
            display: flex;
            justify-content: center;
            /* align-items: flex-end; */
            align-items: center;
            margin-top: 25px;
            color: #fff;
            font-size: 16px;
        }

        .btc-value {
            font-size: 15px;
            font-weight: 500;
            color: #B5BECB;

            span {
                color: #6E7885;
            }
        }
    }

    #target_details {
        .form-group {
            .form-row {
                .form-label {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .trr {
                        background-color: #122423;
                        border: 1px solid #152F20;
                        padding: 4px;
                        color: #169C4E;
                        font-weight: 600;
                        border-radius: 6px;
                        line-height: 30px;
                        font-size: 12px;
                        width: 58px;
                        height: 40px;
                        text-align: center;
                    }
                }
            }
        }
    }

    /*  default input groups styles  */
    .input-group {
        &.quantity {
            input,
            .form-control[readonly] {
                border: none;
                text-align: right;
                font-weight: 700;
                /* padding: 6px 70px 6px 12px; */

                &.error {
                    &:focus {
                        box-shadow: none !important;
                    }
                }
            }

            .input-group-append {
                background: #17212d;
                padding: 8px;

                .input-group-text {
                    font-size: 11px;
                    font-weight: 700;
                    border-radius: 1px;
                    background: #1A2531;
                    border: none;
                    color: #6E7885;
                    letter-spacing: .5px;
                }

                &.error {
                    /* border: 1px solid #C8315E !important; */
                    -webkit-animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
                    animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
                    z-index: 100;
                    /* border-left: none !important; */

                    @-webkit-keyframes shake-horizontal {
                        0%,
                        100% {
                            -webkit-transform: translateX(0);
                            transform: translateX(0);
                        }

                        10%,
                        30%,
                        50%,
                        70% {
                            -webkit-transform: translateX(-10px);
                            transform: translateX(-10px);
                        }

                        20%,
                        40%,
                        60% {
                            -webkit-transform: translateX(10px);
                            transform: translateX(10px);
                        }

                        80% {
                            -webkit-transform: translateX(8px);
                            transform: translateX(8px);
                        }

                        90% {
                            -webkit-transform: translateX(-8px);
                            transform: translateX(-8px);
                        }
                    }

                    @keyframes shake-horizontal {
                        0%,
                        100% {
                            -webkit-transform: translateX(0);
                            transform: translateX(0);
                        }

                        10%,
                        30%,
                        50%,
                        70% {
                            -webkit-transform: translateX(-10px);
                            transform: translateX(-10px);
                        }

                        20%,
                        40%,
                        60% {
                            -webkit-transform: translateX(10px);
                            transform: translateX(10px);
                        }

                        80% {
                            -webkit-transform: translateX(8px);
                            transform: translateX(8px);
                        }

                        90% {
                            -webkit-transform: translateX(-8px);
                            transform: translateX(-8px);
                        }
                    }
                }
            }
        }

        &.total {
            input {
                /* border-right: none; */
                padding: 6px 70px 6px 12px;
                background: #1a372f78;
                border: 1px solid #4ed10214;
                border-top-right-radius: 6px !important;
                border-bottom-right-radius: 6px !important;

                &:focus {
                    background: #1a372f78 !important;
                }
            }

            .input-group-append {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                padding: 8px;
                border-radius: 2px;
                z-index: 100;

                .input-group-text {
                    font-size: 11px;
                    font-weight: 700;
                    border-radius: 1px;
                    background: #346f5e3d;
                    border: none;
                    color: #15bc6f;
                    letter-spacing: .5px;
                }
            }
        }

        &.error {
            border: 1px solid #C8315E !important;
            -webkit-animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
            animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
            z-index: 100;

            @-webkit-keyframes shake-horizontal {
                0%,
                100% {
                    -webkit-transform: translateX(0);
                    transform: translateX(0);
                }

                10%,
                30%,
                50%,
                70% {
                    -webkit-transform: translateX(-10px);
                    transform: translateX(-10px);
                }

                20%,
                40%,
                60% {
                    -webkit-transform: translateX(10px);
                    transform: translateX(10px);
                }

                80% {
                    -webkit-transform: translateX(8px);
                    transform: translateX(8px);
                }

                90% {
                    -webkit-transform: translateX(-8px);
                    transform: translateX(-8px);
                }
            }

            @keyframes shake-horizontal {
                0%,
                100% {
                    -webkit-transform: translateX(0);
                    transform: translateX(0);
                }

                10%,
                30%,
                50%,
                70% {
                    -webkit-transform: translateX(-10px);
                    transform: translateX(-10px);
                }

                20%,
                40%,
                60% {
                    -webkit-transform: translateX(10px);
                    transform: translateX(10px);
                }

                80% {
                    -webkit-transform: translateX(8px);
                    transform: translateX(8px);
                }

                90% {
                    -webkit-transform: translateX(-8px);
                    transform: translateX(-8px);
                }
            }

            input {
                &:focus {
                    box-shadow: none !important;
                    border: none !important;
                }
            }
        }
    }

    .input-group-append {
        font-size: 14px;
        height: 40px;

        span {
            font-size: 14px;
        }

        .input-group-text {
            background: #1A2531;
            border-color: #202C39;
            color: #697789;
        }

        .form-control {
            border-radius: 0;
            background-color: #141e2a !important;
        }

        .toggle-btn {
            padding: 5px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #233040;
            box-shadow: 0 0 8px rgb(0 0 0 / 30%) inset;
            cursor: pointer;
            background-color: #0e2428;
            text-transform: uppercase;
            transition: box-shadow ease .3s;

            &.active {
                background-color: #22965f;
                color: #fff;
                font-weight: bold;
                box-shadow: 0 0 8px rgb(80 210 10) inset;
            }
        }

        &.gains {
            border-left: 1px solid #202C39 !important;

            span {
                width: 80px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
        }

        &:last-child {
            & > .input-group-text {
                border-top-right-radius: 6px !important;
            }
        }
    }

    .input-group-prepend {
        height: 40px;

        .input-group-text {
            background: #1A2531;
            border-color: #202C39;
        }
    }

    .form-group:last-child {
      margin-bottom: 0;
    }

    .form-group {
        .form-row {
            align-items: center;
        }
    }

    &.advanced {
        form {
            /* height: 500px;
            max-height: 500px; */
            height: 480px;
            max-height: 480px;
            overflow-y: auto;
            overflow-x: hidden;
            min-height: unset;
            padding-right: 15px;

            @media screen and (min-width: 320px) and (max-width: 1199px) {
                height: auto;
                max-height: none;
                min-height: 0;
                overflow: visible;
                padding-right: 0;
            }

            &::-webkit-scrollbar-track {
                background-color: #192431;
            }

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #273346;
            }
        }
    }

    .target-prices {
        display: flex;
        justify-content: space-between;
        align-items: center;

        label {
            padding: 0 !important;
            text-align: start;
            margin: 0;
            color: #B5BECB;
        }

        a {
            /* width: 100%; */
            text-align: right;
        }

        @media screen and (min-width: 320px) and (max-width: 766px) {
            /* a {
                width: 100%;
            } */
        }
    }

    .order-summary-titles {
        display: flex;
        align-items: center;

        h6 {
            margin-bottom: 0;
        }

        svg {
            font-size: 22px;
            color: #fff;
        }

        @media screen and (min-width: 320px) and (max-width: 766px) {
            h6 {
                font-size: 14px;
            }
        }
    }

    .btc-value {
        font-size: 14px;
        font-weight: 500;
        color: #B5BECB;

        span {
            color: #6E7885;
        }
    }

    .relative {
        position: relative;
        display: flex;

        .target-error {
            position: absolute;
            z-index: 10;
            left: 12px;
            font-size: 11px;
            color: #BF4565;
            font-weight: 700;
            top: 2px;
        }
    }

    .breakeven-group {
        .breakeven-wrapper {
            margin-bottom: 0;
            display: flex;
            align-items: center;

            label {
                margin-bottom: 0;
            }
        }
    }

    .price-type-label {
        margin-bottom: 0;
        display: flex;
        align-items: center;
    }

    @media screen and (min-width: 320px) and (max-width: 766px) {
        /* padding: 0 10px 0 0; */
        padding: 0;
        min-height: 1px !important;

        .lastprice {
            font-size: 14px;
        }

        .total-x-entry {
            margin: 20px 0 0 !important;
        }

        &.advanced {
             padding: 0 10px 0 0;
            /* width: calc(${props => props.width}px - 20px); */

            form {
                /* height: calc(${props => props.height}px - 450px);
                max-height: unset;
                padding: 10px 10px 20px 0; */
            }
        }

        .breakeven-group {
            margin-bottom: 5px;

            .breakeven-wrapper {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-right: 0;
                margin-bottom: 10px;
                /* padding: 10px 0; */

                .breakeven {
                    width: auto;
                    padding: 0 5px;
                }

                .breakeven-stop {
                    padding: 10px 0 0 !important;
                }

                .toggle-switch {
                    text-align: right;
                    width: auto;
                    padding: 0;
                }
            }
        }

        .price-type-label {
            margin-bottom: 5px;
        }

        #target_details {
            .input-group {
                .input-group-append {
                    /* width: 50px !important; */
                }
            }
        }
    }

    @media screen and (min-width: 767px) and (max-width: 1199px) {
        padding: 0 10px 0 0;
        /* min-height: 435px !important; */
        min-height: unset !important;

        .lastprice {
            font-size: 14px !important;
        }

        &.advanced {
            form {
                height: 100%;
                max-height: 100%;
                /* padding: 10px 10px 20px 0; */
                padding: 10px 0 20px;
            }

            .total-x-entry {
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .breakeven-group {
            /* margin-bottom: 5px; */

            .breakeven-wrapper {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-right: 0;
                /* padding: 10px 0; */

                .breakeven {
                    width: auto;
                }

                .breakeven-stop {
                    padding: 10px 0 0 !important;
                }

                .toggle-switch {
                    text-align: right;
                    width: auto;
                    padding: 0 10px;
                }
            }
        }
    }
`

export const FormTitle = styled.p`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #adbac7;
    font-size: 16px;
    padding: 10px 0;

    small {
        color: #15BD6F;
    }

    .lastprice {
        font-size: 16px;
        color: #B5BECB;
    }

    .buy-form {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            display: flex;
            align-items: center;
        }
    }

    .float-right {
        cursor: pointer;
    }

    .icon {
        font-size: 14px;
        margin-bottom: 1px;
    }

    @media screen and (min-width: 320px) and (max-width: 766px) {
        font-size: 14px;

        .lastprice {
            font-size: 14px !important;
        }
    }

    @media screen and (min-width: 767px) and (max-width: 1023px) {
        font-size: 14px;

    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        font-size: 15px;

        .lastprice {
            font-size: 15px;
        }
    }
`

export const TargetPriceInput = styled.input`
    border-radius: 0px;
    background: #182432 !important;
    border: 1px solid #202C39;
    text-align: center;

    &.invalid {
        border-color: #963955 !important;
        box-shadow: 0px 0px 10px rgb(185 63 95);
    }

    &.valid {
        border-color: #15bd6f !important;
        box-shadow: 0 0 0 0.2rem rgb(22 156 98 / 30%);
    }

    &.qty {
        padding: 6px 8px !important;
    }
`

export const TargetAllocationInput = styled.span`
    background: #182432 !important;
    border-left: none !important;
    border-radius: 0px !important;

    .stoploss-calculation {
        color: #B93F5F;
        font-weight: 600;
        cursor: default;
    }

    .target-gains {
        color: #16B46C;
        font-weight: 600;
        cursor: default;
    }
`

export const ToggleButton = styled.label`
    display: block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    margin-bottom: 0;
    color: #778698;
    pointer-events: none;
    font-size: 14px;

    &.active {
        pointer-events: auto;
        color: #94a7c1;
    }

    i {
        position: relative;
        display: inline-block;
        margin-right: .5rem;
        width: 38px;
        height: 21px;
        background-color: #202A35;
        border-radius: 23px;
        vertical-align: text-bottom;
        transition: all 0.3s linear;

        &.active {
            &::before {
                background-color: #19212b;
            }

            &::after {
                background-color: #ccd4de;
            }
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 33px;
            height: 17px;
            background-color: #19212b;
            border-radius: 11px;
            transform: translate3d(2px,2px,0) scale3d(1,1,1);
            transition: all 0.25s linear;
        }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 16px;
            height: 16px;
            background-color: #4a4a4a;
            border-radius: 11px;
            box-shadow: 0 0px 2px 2px rgba(0,0,0,0.24);
            transform: translate3d(2px,2px,0);
            transition: all 0.2s ease-in-out;
        }
    }

    input {
        display: none;

        &:checked + i {
            background-color: #3f6654;

            &.active {
                background-color: #15bd6f;
            }

            &::before {
                transform: translate3d(14px, 2px, 0) scale3d(0, 0, 0);
            }

            &::after {
                transform: translate3d(20px, 2px, 0);
                background-color: #25313f;
            }
        }
    }

    &:active {
        i {
            &::after {
                width: 32px;
                transform: translate3d(2px,2.3px,0);
            }
        }

        input {
            &:checked + i {

                &::after {
                    width: 32px;
                    transform: translate3d(3px,2px,0);
                }
            }
        }
    }

    .input-cont {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 10px 0;

        .toggle-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            .toggle-label {
                margin-right: 10px;
                color: #fff;
                font-size: 16px;
            }
        }

        @media screen and (min-width: 320px) and (max-width: 766px) {
            flex-direction: column-reverse;
            align-items: flex-end;
        }
    }

    .help-text {
        margin: 0 !important;
        text-align: start;
    }
`

export const TradeFormActions = styled.div`
    margin-top: auto;
    display: flex;
    justify-content: space-around;

    .targetButtons {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .btn {
        min-width: 160px;
        padding: 10px 35px;

        @media screen and (min-width: 767px) and (max-width: 1023px) {
            min-width: 130px;

            &.btn-success {
                padding: 10px;
                font-size: 12px;
            }
        }

        &:focus {
            border: none;
            box-shadow: none;
        }
    }

    .targetButtons {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-right: 25px;

        &.buy {
            margin-top: 0;
        }

        .next,
        .back {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #183337 !important;
            border: none !important;
            color: #15BD6F !important;
            padding: 5px 15px !important;
            min-width: 115px;
            font-size: 15px;
            font-weight: 600;
            border-radius: 2px;
            margin: 0 10px;

            &:active,
            &:focus {
                box-shadow: none !important;
            }
        }
    }

    .summaryButtons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        top: -35px;
        padding: 0 20px 0 0;

        .back {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #183337 !important;
            border: none !important;
            color: #15BD6F !important;
            padding: 5px 15px !important;
            min-width: 115px;
            font-size: 15px;
            font-weight: 600;
            border-radius: 2px;
            margin: 0;
            height: 30px;
        }

        .btn.btn-success {
            background: #15BD6F !important;
            border-radius: 2px;
            width: 255px;
        }

        @media screen and (min-width: 320px) and (max-width: 766px) {
            position: fixed;
            bottom: 60px;
            top: unset;
            padding: 0 30px 0 0;
            flex-direction: column-reverse;

            .back {
                width: 100%;
                margin-top: 10px;
                justify-content: center;

                svg {
                    margin-right: 15px;
                }
            }

            .btn.btn-success {
                width: 100%;
                font-size: 14px;
                padding: 10px;
                margin-right: 0;
            }
        }

        @media screen and (min-width: 767px) and (max-width: 1023px) {
            top: -50px;

            .btn.btn-success {
                width: 215px !important;
            }
        }

        @media screen and (min-width: 1024px) and (max-width: 1199px) {
            top: -35px;

            .btn.btn-success {
                width: 215px !important;

            }
        }

        @media screen and (min-width: 1200px) and (max-width: 1440px) {
            top: -65px;
        }

        @media screen and (min-width: 1441px) and (max-width: 1650px) {
            top: -55px;
        }
    }

    @media screen and (min-width: 320px) and (max-width: 766px) {
        flex-direction: column;
        padding: 15px;

        .targetButtons {
            .next {
                margin: 0;
                min-width: 155px;
                justify-content: center;

                span {
                    margin-right: 15px;
                }
            }

            .back {
                min-width: 155px;
                justify-content: center;

                span {
                    margin-left: 15px;
                }
            }
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        .targetButtons {
            padding-right: 10px;
        }
    }
`

export const TradeFormConfirmBtn = styled.button`
    &.basic-trade-btn {
        width: auto;
        padding: 10px 35px;
		background-color: #169E63 !important;
		border: 2px solid #23d381;
        border-radius: 23px;
        transition: all 0.5s ease 0s;

        &:hover {
            transform: scale(1.05);
        }
    }

    @media screen and (min-width: 320px) and (max-width: 766px) {
        &.basic-trade-btn {
            width: 100%;
            margin: 0 !important;
        }
    }
`

export const TradeFormSignalPeekView = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    float: left;
    height: 100%;
    min-height: 1px;
    width: 346px;
    margin-right: 20px;
    cursor: pointer;

    &.sm {
        width: 100%;
        display: flex;
        position: relative;
        justify-content: space-between;
        margin: 0;

        .overlay {
            background-position: 150px -10px;
            background-size: 50%;

            @media (min-width: 1200px) {
                background-position: 170px -10px;
            }
        }

        .trade-progress {
            &.sm {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 5px 0;

                .trade-status {
                    width: 100%;

                    span {
                        position: relative;
                        top: auto;
                        display: block;
                        padding: 10px;
                        text-align: center;
                        border-radius: 2px;
                        border: 2px solid rgba(0,0,0,0.2);
                        font-size: 15px;
                        text-transform: uppercase;
                        font-weight: 600;

                        /* &.s-wez,
                        &.s-lez {
                            background: #0E2329;
                            color: #15BD6F;
                        }

                        &.s-asl {
                            background: #99395645;
                            color: #ff6090;
                        } */
                    }
                }

                @media screen and (min-width: 1200px) and (max-width: 1440px) {
                    span {
                        font-size: 14px;
                    }
                }
            }
        }

        .trade-progress-value {
            width: auto;
            margin-bottom: 0;

            .progress-value {
                text-align: right;
                font-size: 16px;
                margin-bottom: 0;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                @media (min-width: 1200px) {
                    font-size: 20px;
                    text-shadow: 0 4px 2px #000;
                }

                span {
                    display: block;
                    font-weight: 500;
                    font-size: 24px;
                }

                small {
                    font-size: 13px;
                    font-weight: 500;
                }
            }

            @media screen and (min-width: 415px) and (max-width: 768px) {
                .progress-value {
                    span {
                        font-size: 20px;
                    }

                    small {
                        font-size: 10px;
                    }
                }
            }

            @media screen and (min-width: 1200px) and (max-width: 1440px) {
                .progress-value {
                    span {
                        font-size: 22px;
                    }

                    small {
                        font-size: 12px;
                    }
                }
            }
        }

        .signal-progress {
            padding: 30px 15px 15px;

            .coinpair-info {
                .coinpair {
                    font-size: 20px;

                    small {
                        &.last-price {
                            font-size: 13px;
                        }
                    }
                }
            }

            .progress-info {
                .progress-value {
                    font-size: 26px;

                    .duration {
                        font-size: 13px;
                    }
                }
            }
        }

        .price-ranges {
            padding-top: 25px;

            .since-entry {
                width: 185px;
                top: -19px;
                left: 10px;
                font-size: 10.5px;
                text-transform: capitalize;
            }

            .signal-strength {
                width: 90px;
                right: 0px;
            }

            .price-range {
                padding-bottom: 5px;
                margin-bottom: 5px;

                .entry-value,
                .target-value,
                .stop-loss {
                    span {
                        font-size: 14px;
                    }
                }

                &:last-child {
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border-bottom: none;
                }
            }
        }

        .trade-direction {
            font-size: 13px;
        }
    }
`

export const SignalProgressPeekView = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    margin-bottom: 15px;

    .coinpair-wrapper {
        display: flex;
        align-items: center;

        img {
            width: 45px;
            height: 45px;
            border-radius: 20px;
        }

        &.sm {
            img {
                width: 45px;
                height: 45px;

                @media (min-width: 1200px) {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }

    .crypto-coin-logo {
        width: 35px;
        height: 35px;
        box-shadow: none;
        background-color: transparent;
        margin-top: 3px;
    }

    .coinpair-info,
    .progress-info {
        .coinpair,
        .progress-value {
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
        }
    }

    .coinpair-info {
        .coinpair {
            font-size: 20px;
            color: #fff;

            .last-price {
                font-size: 12px;
                margin-top: 4px;
                background-color: #192431;
                padding: 5px 10px;
                border-radius: 2px;
            }

            &.sm {
                font-size: 20px;
            }

            @media screen and (min-width: 1200px) and (max-width: 1440px)  {
                .last-price {
                    font-size: 11px;
                }

                &.sm {
                    font-size: 20px;
                }
            }
        }
    }

    .progress-info {
        .progress-value {
            font-size: 33px;
            font-weight: 800;
            text-align: right;
            text-shadow: 1px 4px 5px rgba(0, 0, 0, 0.4);
            letter-spacing: 2px;

            .duration {
                font-size: 12px;
                text-transform: uppercase;
            }
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        padding: 12px 0px 15px;
    }

    @media screen and (min-width: 1441px) and (max-width: 1650px) {
        padding: 5px 0px 15px;
    }
`

export const TradeProgressValue = styled.h3`
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    font-size: 36px;
    font-weight: 800;
    text-align: right;
    text-shadow: 1px 4px 5px rgba(0,0,0,.4);
    letter-spacing: 2px;

    &.positive {
        color: #00ed81 !important;
    }

    .duration {
        font-size: 18px;
        text-transform: uppercase;
    }
`

export const SignalPriceRange = styled.div`
    padding: 20px 12px;
    background-color: #192431;
    position: relative;

    &.sm {
        width: 100%;
        background: transparent;
        padding: 0 0 25px;
    }

    .trade-tags {
        position: absolute;
        top: -13px;
        margin: 0;
        padding-left: 0;
        display: inline-flex;
        list-style-type: none;

        li {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }

            .direction {
                background-color: ${props => props.direction === "Buy" ? '#4a9440' : '#cf5c5c'};
            }

            .leverage {
                background: #182029;

                .leverage-value {
                    font-weight: bold;
                }
            }

            .strategy {
                background-color: #23303e;
            }

            .direction,
            .strategy {
                letter-spacing: 2px;
                text-transform: uppercase;
                padding: 5px 10px;
                width: auto;
                border-radius: 15px;
                color: #fff;
                text-align: center;
            }

            .leverage {
                letter-spacing: 1px;
                padding: 5px 15px;
                width: auto;
                border-radius: 15px;
                color: #fff;
                text-align: center;

                .icon {
                    font-size: 11px;
                    margin-right: 8px;
                }
            }
        }

        @media screen and (min-width: 767px) and (max-width: 1023px) {
            li {
                font-size: 12px;
                margin-top: 4px;
                margin-right: 5px;
            }
        }

        @media screen and (min-width: 1024px) and (max-width: 1680px) {
            top: -10px;

            li {
                font-size: 12px;
                margin-right: 5px;
            }
        }

        @media screen and (min-width: 1681px) and (max-width: 3000px) {
            li {
                font-size: 13px;
            }
        }
    }

    .trade-direction {
        letter-spacing: 2px;
        width: 80px;
        text-transform: uppercase;
    }

    .since-entry {
        font-size: 12px;
        position: absolute;
        top: -17px;
        left: 15px;
        background-color: ${props => props.direction === 'Buy' ? '#cf5c5c' : '#2e63d0'};
        padding: 5px 10px;
        width: auto;
        border-radius: 4px;
        color: #fff;
        text-align: center;
    }

    .price-range {
        display: flex;
        margin-bottom: 10px;
        min-height: 32px;
        align-items: center;
        overflow: hidden;

        &.sm {
            align-items: center;

            .price-range-entry {
                display: flex;
                align-items: center;
            }

            .entry-label {
                width: 135px !important; 
            }

            .width-value {
                background: #0e2329 !important;
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600; 
                text-shadow: 0 3px 2px #000;
            }

            .entry-label,
            .target-label {
                font-size: 13px;
                width: 110px;
                margin-right: 5px;
            }

            .last-price-value,
            .entry-value,
            .target-value {
                background: transparent;
                border: none;

                span {
                    font-size: 15px;
                    text-shadow: 0 3px 2px #000;
                }

                @media screen and (min-width: 1200px) and (max-width: 1440px) {
                    span {
                        font-size: 14px;
                    }
                }
            }

            .target-value {
                margin: 0;
                width: 100%;
                padding: 10px 0px 10px 25px;

                span {
                    display: grid;
                    grid-gap: 10px 10px;
                    grid-template-columns: auto auto;
                    text-shadow: 0 3px 2px #000;

                    .target-value {
                        margin: 0;
                        width: 100%;
                    }
                }
            }

            @media screen and (min-width: 1024px) and (max-width: 1025px) {
                .entry-label,
                .target-label {
                    font-size: 11px;
                }

                .last-price-value,
                .entry-value,
                .target-value {
                    span {
                        font-size: 11px;
                    }
                }
            }

            @media screen and (min-width: 1200px) and (max-width: 1440px) {
                margin-bottom: 0;

                .target-value {
                    padding: 5px 25px;
                }
            }
        }

        .entry-label,
        .target-label {
            width: 130px;
            margin-bottom: 0;
            margin-right: 10px;
            text-transform: uppercase;
            font-size: 13px;
            line-height: 24px;
            color: #B5BECB;
            cursor: inherit;
            display: flex;
            align-items: center;

            @media screen and (min-width: 767px) and (max-width: 1440px) {
                font-size: 12px;
            }
        }

        .target-label {
            .target-text {
                margin-right: 5px;

                &.last-target-hit {
                    font-size: 13px;
                    line-height: 1;

                    @media screen and (min-width: 1200px) and (max-width: 1440px) {
                        font-size: 12px;
                    }

                    @media screen and (min-width: 1441px) and (max-width: 1749px) {
                        font-size: 12px;
                        line-height: 1;
                    }
                }

                &.price-closed {
                    @media screen and (min-width: 1750px) and (max-width: 3000px) {
                        font-size: 13px;
                        line-height: 1;
                    }
                }
            }
            .target-index {
                color: #fdd501;
            }
        }

        .last-price-value,
        .entry-value,
        .target-value {
            width: calc(100% - 65px);
            text-align: right;
            background-color:#202C39;
            border-radius: 2px;
            padding: 3px 5px;
            border: 1px solid #25364A;
            margin: 0 auto;

            span {
                font-size: 16px;
                font-weight: 500;
            }

            &.stoploss {
                color: #B93F5F;
                font-weight: 500;
            }

            @media screen and (min-width: 320px) and (max-width: 766px) {
                width: calc(100% - 80px);

                span {
                    font-size: 13px;
                }
            }

            @media screen and (min-width: 767px) and (max-width: 1023px) {
                span {
                    font-size: 13px;
                }
            }

            @media screen and (min-width: 1024px) and (max-width: 1199px) {
                span {
                    font-size: 13px;
                }
            }

            @media screen and (min-width: 1200px) and (max-width: 1360px) {
                span {
                    font-size: 13px;
                }
            }

            @media screen and (min-width: 1361px) and (max-width: 1440px) {
                span {
                    font-size: 13px;
                }
            }

            @media screen and (min-width: 1441px) and (max-width: 1749px) {
                span {
                    font-size: 13px;
                }
            }
        }

        .last-price-value {
            color: #fff;
            min-height: 32px;
        }

        .target-value {
            color: gold;
        }

        .entry-value {
            color: #16B46C;
            padding: 3px 5px;
        }

        &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none;
        }

        .price-length {
            position: absolute;
            font-size: 13px;
            color: #627186;
            font-weight: 800;
            letter-spacing: 2px;
            text-shadow: 1px 4px 5px rgba(0,0,0,0.4);

            &.entry {
                left: 75px;
                bottom: 57px;
            }

            &.target {
                left: 75px;
                bottom: 13px;
            }
        }

        .price-range-icons {
            font-size: 13px;
            width: 20px;
            color: #607794;

            @media screen and (min-width: 1441px) and (max-width: 1749px) {
                width: 16px;
            }
        }
    }

    &.closed-trade {
        border-color: ${props => props.type === 'profits' ? `#2c5645` : `#59435a`};
        background-color: transparent;
        padding: 20px 12px 15px;

        .trade-tags {
            li {
                .direction {
                    background-color: #263442 !important;
                }

                @media screen and (min-width: 767px) and (max-width: 1023px) {
                    font-size: 12px;
                    margin-top: 4px;
                }

                @media screen and (min-width: 1024px) and (max-width: 1680px) {
                    font-size: 13px;
                }

                @media screen and (min-width: 1750px) and (max-width: 3000px) {
                    font-size: 13px;
                }
            }
        }

        .closed-tag {
            position: absolute;
            right: 1px;
            width: 137px;
            background-color: #202C39;
            top: -40px;
            padding: 3px 15px;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            color: ${props => props.type === 'profits' ? `#15BD6F` : `#B93F5F`};
            font-weight: 600;

            span {
                font-size: 14px;
            }
        }

        .price-range {
            .entry-label,
            .target-label {
                width: 160px;
                font-size: 13px;
                color: #fff;
                text-transform: uppercase;

                @media screen and (min-width: 767px) and (max-width: 1199px) {
                    font-size: 12px;
                }

                @media screen and (min-width: 1200px) and (max-width: 1440px) {
                    font-size: 13px;
                }

                @media screen and (min-width: 1441px) and (max-width: 1749px) {
                    font-size: 12px;
                }
            }

            .last-price-value,
            .entry-value,
            .target-value {
                color: #fff;
                background-color: ${props => props.type === 'profits' ? `transparent` : `transparent`};
                border: 2px solid ${props => props.type === 'profits' ? `#15BD6F` : `#D35D7A`};
                text-align: center;

                span {
                    font-size: 14px;
                }

                @media screen and (min-width: 320px) and (max-width: 766px) {
                    width: calc(100% - 70px);

                    span {
                        font-size: 13px;
                    }
                }

                @media screen and (min-width: 767px) and (max-width: 1023px) {
                    width: calc(100% - 70px);

                    span {
                        font-size: 13px;
                    }
                }

                @media screen and (min-width: 1024px) and (max-width: 1199px) {
                    span {
                        font-size: 14px;
                    }
                }

                @media screen and (min-width: 1200px) and (max-width: 1360px) {
                    span {
                        font-size: 14px;
                    }
                }

                @media screen and (min-width: 1361px) and (max-width: 1440px) {
                    span {
                        font-size: 14px;
                    }
                }

                @media screen and (min-width: 1441px) and (max-width: 1550px) {
                    span {
                        font-size: 13px;
                    }
                }

                @media screen and (min-width: 1551px) and (max-width: 1749px) {
                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 320px) and (max-width: 359px) {
        padding: 20px 10px;

        &.closed-trade {
            padding: 15px 10px 15px;

            .closed-tag {
                span {
                    font-size: 12px;
                }
            }
        }

        .since-entry {
            font-size: 11px;
            top: -15px;
            left: 10px;
            padding: 5px 10px;
            width: auto;
        }

        .price-range {
            padding-bottom: 5px;
            margin-bottom: 5px;

            .entry-label,
            .target-label {
                font-size: 12px;
                margin-right: 0px;
                line-height: 1;
            }

            .last-price-value,
            .entry-value,
            .target-value {
                padding: 0px 5px 2px;
                width: calc(100% - 40px);

                span {
                    font-size: 12px;
                }
            }
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        padding: 15px 8px;

        &.sm {
            padding: 0 0 15px;
        }

        &.closed-trade {
            padding: 15px 8px 15px;

            .closed-tag {
                span {
                    font-size: 13px;
                }
            }
        }

        .since-entry {
            font-size: 11px;
            top: -15px;
            left: 10px;
            padding: 5px 10px;
            width: auto;
        }

        .price-range {
            padding-bottom: 5px;
            margin-bottom: 5px;

            .entry-label,
            .target-label {
                font-size: 13px;
                margin-right: 0px;
                line-height: 1;
            }

            .last-price-value,
            .entry-value,
            .target-value {
                width: calc(100% - 85px);

                span {
                    font-size: 14px;
                }
            }
        }
    }

    @media screen and (min-width: 1441px) and (max-width: 1550px) {
        &.sm {
            padding: 0 0 5px;
        }

        &.closed-trade {
            padding: 15px 6px 10px;

            .closed-tag {
                span {
                    font-size: 12px;
                }
            }

            .price-range {
                .last-price-value,
                .entry-value,
                .target-value {
                    width: calc(100% - 35px);
                }
            }
        }

        .since-entry {
            font-size: 12px;
            top: -16px;
            left: 9px;
            padding: 5px 10px;
            width: auto;
        }

        .price-range {
            padding-bottom: 5px;
            margin-bottom: 5px;

            .entry-label,
            .target-label {
                font-size: 12px !important;
                margin-right: 0px;
                line-height: 1;
            }

            .last-price-value,
            .entry-value,
            .target-value {
                width: calc(100% - 35px);
                padding: 3px;

                span {
                    font-size: 14px !important;
                }
            }
        }
    }

    @media screen and (min-width: 1551px) and (max-width: 1749px) {
        padding: 15px 10px;

        &.sm {
            padding: 0 0 5px;
        }

        &.closed-trade {
            padding: 15px 10px 10px;

            .closed-tag {
                span {
                    font-size: 12px;
                }
            }

            .price-range {
                .last-price-value,
                .entry-value,
                .target-value {
                    width: calc(100% - 45px);
                }
            }
        }

        .since-entry {
            font-size: 12px;
            top: -16px;
            left: 9px;
            padding: 5px 10px;
            width: auto;
        }

        .price-range {
            padding-bottom: 5px;
            margin-bottom: 5px;

            .entry-label,
            .target-label {
                font-size: 13px !important;
                margin-right: 0px;
                line-height: 1;
            }

            .last-price-value,
            .entry-value,
            .target-value {
                width: calc(100% - 60px);

                span {
                    font-size: 14px !important;
                }
            }
        }
    }
`

export const SignalTargetPriceValue = styled.div`
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .target-index-sm {
        padding: 4px;
        border-radius: 2px;
        background-color: #ced0dd;
        color: #414753;
        margin-right: 10px;
        font-size: 11px !important;
        width: 26px;
        text-align: center;
        font-weight: 700 !important;
        grid-gap: 0 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        text-shadow: none !important;
    }

    .targets {
        color: #d5b713;
    }
`

export const WalletCard = styled.div`
    margin-bottom: 15px;

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        margin-bottom: 0;
    }
`

export const WalletCardInner = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 2px;
    padding: 15px;
    background: #0F1B29;

    &.btc {
        &::before {
            background-image: url(../../images/cryptocoins/large/btc.png);
        }
    }

    .balance-title {
        width: 100%;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 5px;
        color: #6E7885;
    }
`

export const WalletCoinBalance = styled.h3`
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;
    padding: 5px 0;
    font-size: 20px;
    margin-bottom: 0;

    span {
        color: #fff;
    }

    &.secondary-wallet-item {
        font-size: 20px !important;

        p {
            width: 38px !important;
            font-size: 20px;
        }
    }

    p {
        color: #2274FF;
        margin-bottom: 0;
        width: auto;
        font-size: 20px;
        margin-right: 5px;
    }

    small {
        color: #6E7885;
        font-size: 14px;
        font-weight: 600;
        margin-left: 8px;
    }
`

export const WalletCardAction = styled.div`
    width: 100%;
    text-align: right;
    padding: 0;
    margin-bottom: 6px;

    &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
        margin-left: auto;
        width: unset;
    }

    h3, p {
        margin-bottom: 0;

        span {
            font-size: 10px;
            color: #a7a9a9;
        }
    }

    .btn.btn-success {
        font-size: 15px;
        border-radius: 25px;
        padding: 5px 15px;
        background: #15bd6f;
    }
`