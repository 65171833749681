import { initializeApp } from 'firebase/app'
//import { getAnalytics } from 'firebase/analytics'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
}

const firebaseApp = initializeApp(firebaseConfig)
//const analytics = getAnalytics(firebaseApp)
const messaging = getMessaging(firebaseApp)

export const fetchToken = (setTokenFound) => {
    return getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_WEB_PUSH_CERT_KEY
    }).then((currentToken) => {
        if (currentToken) {
            //console.log('current token for client: ', currentToken)
            //alert(`current token: ${currentToken}`)
            setTokenFound(true)
            return currentToken
        } else {
            //console.log('no registration token available. request permission to generate one')
            setTokenFound(false)

            // shows on the UI that permission is required
        }
    }).catch((err) => {
        //alert(`err: ${err}`)
        //console.log('an error occured while retrieving token: ', err)
        return null
    })
}

export const onMessageListener = () => 
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload)
        })
    })