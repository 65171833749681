import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { SafeAnchor } from 'react-bootstrap'
import { addPageViewEvent, seo } from '../../utils'
import { Brand, Layout } from '../../styles'
import { Login, FormContainer, FormQuickLinks } from '../../components/Auth'
//import Cookies from 'js-cookie'

export const SignInPage = (props) => {
    useEffect(() => {
        seo({
            title: "Sign In",
            metaDescription: ""
        })
        addPageViewEvent()

        //console.log(Cookies.get('_ga', { domain: '.profitfarmers.com' }))

        document.body.classList.add('authentication')

        localStorage.removeItem('user.authorized')
        localStorage.removeItem('rememberMe')
        localStorage.removeItem('user')
    }, [])

    return (
        // <Layout>
        //     <FormContainer>
        //         <Brand imageUrl={`/images/brand/pf-logo.png`} />

        //         <Login {...props} />
                
        //         <FormQuickLinks>
        //             <p>Don't have an account? <SafeAnchor href="https://www.profitfarmers.com/signup/" className="btn-link">Create an account</SafeAnchor></p>
        //             <Link to="/account/forgot-password">Forgot password?</Link>
        //         </FormQuickLinks>
        //     </FormContainer>
        // </Layout>
        <Login {...props} />
    )
}