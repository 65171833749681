import styled from 'styled-components'
import { color } from '../CONSTANTS'

export const FormButton = styled.div`
	display: flex;
	justify-content: flex-end;

	button {
		color: #fff;
		border: none;
		font-size: 15px;
		font-weight: 600;

		a {
			color: white;
			text-decoration: none;
		}
	}

	.cancel-btn {
		background-color: ${color.secondaryBG} !important;
		color: #FFFFFF !important;
		display: inline-block;
		font-weight: 400;
		text-align: center;
		border: 1px solid ${color.secondaryBorder} !important;
		padding: .375rem .75rem;
	}

	.save-btn {
		background-color: #15BD6F !important;
		color: #FFFFFF !important;
		display: inline-block;
		font-weight: 400;
		text-align: center;
		border: 1px solid #15BD6F !important;
		border-radius: 2px;
		padding: 8px 10px;
		min-width: 200px;

		&:disabled {
			background-color: rgb(25, 36, 49) !important;
		    color: #556371 !important;
		    border: 1px solid #263442 !important;
		    cursor: unset;
		    border-radius: 2px;
		    padding: 8px 10px;
		    min-width: 200px;
		}
	}

	&.settings-button {
        position: absolute;
        bottom: 0;
        right: 6px;
        left: 0;
        background: #17212d;

		.auto-trade-buttons {
            padding: 15px 25px;
        }
	}
`