import _ from 'lodash'
import { isNotNull } from '../object'

export const surveyData = [
    {
        key: "q1",
        question: "Before you cancel, please let us know the reason you are leaving. Every bit of feedback helps!",
        options: [
            {
                key: 1,
                value: "I don't want my membership to auto renew"
            },
            {
                key: 2,
                value: "I'm having Technical issues"
            },
            {
                key: 3,
                value: "It's too expensive for me"
            },
            {
                key: 4,
                value: "I'm switching to another product"
            },
            {
                key: 5,
                value: "I'm struggling because I need more help using ProfitFarmers correctly"
            },
            {
                key: 6,
                value: "I'm struggling because I don't know enough about trading / Technical analysis"
            },
            {
                key: 7,
                value: "I don't have enough time"
            },
            {
                key: 8,
                value: "Your platform doesn't have the features I require"
            },
            {
                key: 9,
                value: "Your customer support wasn't good enough"
            },
            {
                key: 10,
                value: "Other (please explain below)"
            }
        ],
        answer: null
    },
    {
        key: "q2",
        question: "If you could change one thing about ProfitFarmers, what would it be?",
        options: [
            {
                key: 1,
                value: "Prefer not to say..."
            },
            {
                key: 2,
                value: "More education on how to choose signals"
            },
            {
                key: 3,
                value: "More education on technical analysis and trading methods"
            },
            {
                key: 4,
                value: "More education on how to use ProfiFarmers"
            },
            {
                key: 5,
                value: "Customer support"
            },
            {
                key: 6,
                value: "More automation"
            },
            {
                key: 7,
                value: "Less Signals"
            },
            {
                key: 8,
                value: "More Signals"
            },
            {
                key: 9,
                value: "RSI Scanner"
            },
            {
                key: 10,
                value: "Price Action Scanner"
            },
            {
                key: 11,
                value: "Trading Terminal"
            },
            {
                key: 12,
                value: "Mobile app for Iphone"
            },
            {
                key: 13,
                value: "Better Android Mobile app"
            },
            {
                key: 14,
                value: "Copy trading feature"
            },
            {
                key: 15,
                value: "Market updates"
            },
            {
                key: 16,
                value: "Better statistics/results of my trades"
            },
            {
                key: 17,
                value: "Less bugs"
            },
            {
                key: 18,
                value: "Better notifications"
            },
            {
                key: 19,
                value: "Others…"
            }
        ],
        answer: null
    },
    {
        key: "q3",
        options: [
            {
                key: 1,
                value: "Yes"
            },
            {
                key: 2,
                value: "No"
            },
            {
                key: 3,
                value: "Maybe"
            }
        ],
        answer: null
    }
]

export const getSurveyQuestion = (questionKey) => {
    if(isNotNull(questionKey)) {
        const findQuestion = _.find(surveyData, q => q.key === questionKey )
        if(isNotNull(findQuestion) && isNotNull(findQuestion.question))
            return findQuestion.question
    }
        
    return ""
}