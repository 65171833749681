import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const CaseStudiesStyle = styled.div`
    display: block;
    width: 100%;

    .carousel-indicators {
        bottom: -40px;
        
        li {
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background-color: #27D194;
        }    
    }
    
    //mobile and tablet css carousel
    .case-study-mobile-carousel {
        display: flex;
        width: 100%;
        margin-bottom: 35px;

        .case-study-mobile-carousel-item-link {
            img {
                width: 100%;
                border-radius: 10px;
                border: 1px solid #293E54;
                transition: all .4s;

                &:hover {
                    filter: brightness(110%);
                    transform: scale(.96);
                    box-shadow: 0px 3px 18px #00000061;
                }
            }
        }
    }

    //desktop css carousel
    .case-study-multi-carousel {
        width: 70%;

        .case-study-multi-carousel-item {
            display: flex;
            border-right: 1px solid #1F324A;

            .case-study-multi-carousel-item-link {
                margin: 0px 10px 10px 0px;
                width: 50%;

                img {
                    width: 100%;
                    border-radius: 10px;
                    border: 1px solid #293E54;
                    transition: all .4s;
                    
                    &:hover {
                        filter: brightness(110%);
                        transform: scale(.96);
                        box-shadow: 0px 3px 18px #00000061;
                    }
                }
            }

            &.one-item {
                .case-study-multi-carousel-item-link {
                    &:last-child {
                        position: relative;
                        cursor: default;
                        border: 1px solid #293E54;
                        border-radius: 10px;
                        background-image: url(/images/pf-logo.png);
                        background-size: 50px;
                        background-position: center;
                        background-repeat: no-repeat;
                        overflow: hidden;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(255, 255, 255, 0.03);
                            backdrop-filter: blur(5px);
                        }
                    }
                }
                
            }
        }
    }

    .video-testimony-carousel {
        margin-bottom: 35px;
        
        .video-testimony-carousel-item-link {
            img {
                width: 100%;
                transition: all .3s;

                &:hover {
                    filter: brightness(110%);
                    transform: scale(.96);
                    box-shadow: 0px 3px 18px #00000061;
                }
            }
        } 
    }

    ${mediaQueries.mediumDevices} {
        display: flex;
        margin-bottom: 20px;

        .case-study-mobile-carousel {
            width: 58%;
            margin-bottom: unset;
            padding: 0 15px 0 0px;
            border-right: 1px solid #1F324A;
        }

        .video-testimony-carousel {
            width: 50%;
            margin-bottom: unset;
            padding: 0 0 0 15px;
            align-items: center;
        }
    }

    ${mediaQueries.largeDevices} {
        margin-bottom: unset;

        .video-testimony-carousel {
            width: 30%;
        }
    }
    
`