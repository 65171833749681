import React from 'react'
import ReactPlayer from 'react-player/lazy'
import { PowerPFSectionStyle } from './styles'

export const PowerPFSection = () => {
    return(
        <PowerPFSectionStyle>
            <div className='container'>
                <div className='text-with-img'>
                    <div className='text-col'>
                        <h2 className='section-title'>THE POWER OF PROFITFARMERS:<br /><span className='gradient-text'>+472% IN REAL TIME</span></h2>
                        <p className='white-text'>It's time to show you just how powerful the trading system can be!</p>
                        <p>The ProfitFarmers Copy-Trading Co-Pilot spotted <span className='white-text'>5 entry zone</span> opportunities for Loop Ring Coin (LRC) pairings accross a <span className='white-text'>5 day period.</span></p>
                        <p>We made almost a <span className='white-text'>500% total peak gains</span> across these trades and it would have taken you <span className='white-text'>less than 5 minutes</span> to copy these trades!</p>
                        <p>Check it out in the video and ask yourself, how much profit could you have made?</p>
                    </div>
                    <div className='video-wrapper'>
                        <ReactPlayer 
                            url={'https://vimeo.com/644686342/8fa72414f2'}
                            controls={true} 
                            width='100%' 
                            volume={0} />
                    </div>
                </div>
            </div>
        </PowerPFSectionStyle>
    )
}