const orderTypes = {
    STOP_BUY_ORDER: 'Custom - STOP BUY - MARKET',
    MARKET_SELL: 'MARKET_SELL'
}

const orderExchanges = {
    BINANCE: 'binance',
    BINANCE_FUTURES: 'binancefutures'
}

const orderStates = {
    CLOSE: 'CLOSE'
}

export {
    orderTypes,
    orderExchanges,
    orderStates
}