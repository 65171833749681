import glamorous from 'glamorous'

export const OrderIcon = glamorous.span({
    display: 'inline-block',
    width: '32px',
    height: '32px',
    marginRight: '15px',
    boxShadow: '0 2px 5px 1px rgba(0,0,0,0.3)',
    pointerEvents: 'none',
    borderRadius: '50%',
    verticalAlign: 'middle',
    textAlign: 'center',
    lineHeight: '32px',
    fontSize: '18px',

    '&.success': {
        backgroundColor: 'rgba(22,280,208,0.1)',
        color: '#16b46c',
        border: '1px solid #16b46c'
    },

    '&.failed': {
        backgroundColor: 'rgba(153,57,86,0.1)',
        color: '#993956',
        border: '1px solid #993956'
    },

    '&.automation': {
        backgroundColor: 'rgba(241,189,108,0.1)',
        color: '#f1bd6c',
        border: '1px solid #f1bd6c'
    }
})