import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import * as signalR from '@microsoft/signalr'
import jQuery from 'jquery'
import Swal from 'sweetalert2'
import { Layout } from '../../styles'
import { routes } from '../../navigation/CONSTANTS'
import { onboardingActions, planActions, userActions } from '../../redux/actions'
import { fsWebhook } from '../../services/CONSTANTS'
import { isNotNull, isNull, onSetNewState } from '../../utils'
import { IntroSection } from '../Sections/SalesSections/IntroSection'
// import { PremiumFeaturesSection } from './Trial/PremiumFeaturesSection'
// import { BlueprintSection } from './Trial/BlueprintSection'
// import { ProTrialDaysSection } from './Trial/ProTrialDaysSection'
// import { FreeVsProSection } from './Trial/FreeVsProSection'
// import { ProStatsSection } from './Trial/ProStatsSection'
// import { PowerPFSection } from './Trial/PowerPFSection'
// import { LadderingModeSection } from './Trial/LadderingModeSection'
// import { TestimonialsSection } from '../Sections/SalesSections/TestimonialsSection'
// import { TradesInActionSection } from './OneTime/TradesInActionSection'
// import { CancelAnytimeSection } from './Trial/CancelAnytimeSection'
// import { StatsSection } from './OneTime/StatsSection'
// import { ImportantNoteSection } from './OneTime/ImportantNoteSection'
// import { ProVersionSection } from './OneTime/ProVersionSection'
// import { TrustScoreSection } from './OneTime/TrustScoreSection'
// import { CaseStudiesSection } from './OneTime/CaseStudiesSection'
// import { TradingAbilitiesSection } from './OneTime/TradingAbilitiesSection'
// import { ClaimYourOfferSection } from './OneTime/ClaimYourOfferSection'
// import { TheFounderSection } from './OneTime/TheFounderSection'
// import { MoneyBackGuaranteeSection } from './OneTime/MoneyBackGuaranteeSection'
// import { GetStartedWithFree } from './OneTime/GetStartedWithFree'
// import { FooterSection } from './OneTime/FooterSection'

class OneTimeOffer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            show: true,
            hasSkippedOnboarding: null,
            userInfo: null,
            subscriptionPlan: null
        }

        this.baseState = this.state
        this.mounted = false
        this.fsHubConnection = null

        this.startListening()
        this.initializeHubConnection()
    }

    componentDidMount() {
        this.mounted = true
        const { dispatch } = this.props
        dispatch(onboardingActions.getOnboardingState())
        dispatch(userActions.getMe())
        dispatch(planActions.getTrialOffer())
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.mounted) {
            const { authentication, trialPlan } = this.props
            const { show, userInfo, subscriptionPlan } = this.state

            if (isNull(subscriptionPlan) && 
                isNotNull(trialPlan) && 
                prevProps.trialPlan !== trialPlan && 
                !trialPlan.loading) {
                    if (isNotNull(trialPlan.offer)) {
                        onSetNewState(this, {
                            subscriptionPlan: trialPlan.offer
                        })
                    }
                }

            // if (isNull(subscriptionPlans) && 
            //     isNotNull(plan) && 
            //     prevProps.plan !== plan && 
            //     !plan.loading) {
            //         let subscriptionPlanList = plan.plans
            //         if (isNotNull(subscriptionPlanList.Fastspring)) {
            //             onSetNewState(this, {
            //                 subscriptionPlans: subscriptionPlanList.Fastspring
            //             })
            //         }
            // }

            if (isNull(userInfo) && 
                isNotNull(authentication.user)) {
                    const { ...data } = authentication.user
                    onSetNewState(this, {
                        userInfo: {
                            id: data.userId,
                            customerId: data.customerId,
                            leadId: data.leadId,
                            isLead: data.isLead,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            userName: data.userName,
                            hasSubscribed: data.isPaid
                        }
                    })
            }

            // if (isNotNull(userInfo) && 
            //     isNull(this.state.show) && 
            //     isNotNull(onboardingUser) && 
            //     !onboardingUser.loading) {
            //         //console.log('onboardingUser: ', onboardingUser)
            //     if (isNull(onboardingUser)) {
            //         onSetNewState(this, { show: true })
            //     } else {
            //         const { status } = onboardingUser
            //         //console.log('status: ', status)
            //         if (isNotNull(status) && !status.hasSkippedOneTimeOffer) {
            //             if (!userInfo.hasSubscribed)
            //                 onSetNewState(this, { show: true, hasSkippedOnboarding: status.hasSkipped })
            //             else
            //                 onSetNewState(this, { show: false, hasSkippedOnboarding: status.hasSkipped })
            //         } else
            //             onSetNewState(this, { show: false, hasSkippedOnboarding: status.hasSkipped })
            //     }
            // }
            
            if (isNotNull(show) && prevState.show !== show && isNotNull(userInfo)) {
                await this.startFsWebhookConnection(userInfo.userName)
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
        onSetNewState(this, this.baseState)

        if (this.fsHubConnection !== null) {
            if (!this.fsHubConnection.hasOwnProperty('close'))
                this.fsHubConnection.stop()
        }
    }

    startListening = () => {
        window.onPaymentReceived = data => {
            if (isNotNull(data)) {
                Swal.fire({
                    title: 'Payment successful, please wait while we setup your account.',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    didOpen: () => Swal.showLoading()
                })
            }
        }
    }

    initializeHubConnection = () => {
        let hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${fsWebhook}/fs`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect([0,0,10000])
            .build()
        hubConnection.serverTimeoutInMilliseconds = 120000

        this.fsHubConnection = hubConnection
        this.fsHubConnection.on('OnPaymentMade', (payload) => {
            const { ...purchase } = payload
            const { userInfo } = this.state
            if (isNotNull(purchase) && isNotNull(userInfo) && purchase.userId === userInfo.id) {
                window.dataLayer.push({
                    event: 'oneTimeOfferPurchase',
                    user_id: purchase.userId,
                    product: purchase.product,
                    subscriptionPlanId: purchase.subscriptionPlanId,
                    subscriptionRef: purchase.subscriptionRef,
                    amount: purchase.amount,
                    isRenewal: purchase.isRenewal
                })

                jQuery(document).find('#fscCanvas').remove()
                Swal.close()
                window.location = "/"
            }
        })
    }

    startFsWebhookConnection = async (username) => {
        if (this.mounted) {
            try {
                await this.fsHubConnection
                    .start()
                    .catch(err => console.log(err))

                if (this.fsHubConnection.state === signalR.HubConnectionBuilder.Connected) {
                    this.fsHubConnection.invoke("GetConnectionId", username)
                        .then((connectionId) => {
                            console.log('conn: ', connectionId)
                        })
                        .catch(err => console.log(err))
                }
            }
            catch (err) {
                if (this.fsHubConnection.state === signalR.HubConnectionState.Disconnected) {
                    setTimeout(async () => {
                        await this.startFsWebhookConnection(username)
                    }, 5000)
                }
            }
        }
    }

    render() {
        const { show, hasSkippedOnboarding, userInfo, subscriptionPlan } = this.state
        if (isNotNull(show)) {            
            if (show) {
                return(
                    <Layout>
                        {/* new stuff */}
                        <IntroSection subscriptionPlan={subscriptionPlan} userInfo={userInfo} />
                        {/* <PremiumFeaturesSection />
                        <BlueprintSection />
                        <ProTrialDaysSection />
                        <FreeVsProSection />
                        <ProStatsSection />
                        <PowerPFSection />
                        <LadderingModeSection />
                        <TestimonialsSection />
                        <TradesInActionSection />
                        <CancelAnytimeSection /> */}

                        {/* old stuff */}
                        {/* <StatsSection />
                        <ImportantNoteSection /> */}
                        {/* <ProVersionSection subscriptionPlans={subscriptionPlans} userInfo={userInfo} />
                        <TrustScoreSection subscriptionPlans={subscriptionPlans} userInfo={userInfo} /> */}
                        {/* <CaseStudiesSection />
                        <TradingAbilitiesSection />
                        <TestimonialsSection />
                        <TradesInActionSection /> */}
                        {/* <ClaimYourOfferSection subscriptionPlans={subscriptionPlans} userInfo={userInfo} /> */}
                        {/* <TheFounderSection />
                        <MoneyBackGuaranteeSection />
                        <GetStartedWithFree />
                        <FooterSection /> */}
                    </Layout>
                )
            } else {
                if (isNotNull(hasSkippedOnboarding) && hasSkippedOnboarding) {
                    return (<Redirect to={{
                        pathname: routes.dashboard,
                    }} />)
                } else {
                    return (<Redirect to={{
                        pathname: '/',
                    }} />)
                }
            }
        } else {
            return (<></>)
        }
    }
}

function mapStateToProps(state) {
    const { 
        onboardingUser, 
        authentication,
        plan, 
        trialPlan } = state

    return {
        onboardingUser,
        authentication,
        plan,
        trialPlan
    }
}

const connectedOneTimeOffer = connect(mapStateToProps)(OneTimeOffer)
export { connectedOneTimeOffer as OneTimeOffer }