import styled from 'styled-components'

export const Container = styled.div`
    max-width: 1180px;
    margin: 0 auto;

	.heading {
		display: flex;
		align-items: center;
		justify-content: space-between;
        margin-bottom: 20px;
        
        @media screen and (max-width: 766px) and (min-width: 320px) {
            margin-bottom: 0px;
            flex-direction: column;

            ul {
                width: 100%;

                li {
                    width: 100%;
                    flex-flow: row;
                    gap: 15px;

                    .form-group {
                        width: 100%;
                        margin: 0 !important;
                    }
                }
            }
        }

		.title-wrapper {
            display: flex;
            position: relative;
            width: 195px;
            align-items: center;

            .text {
                display: flex;
                font-size: 34px;
                font-weight: 500;
                margin-bottom: 0;
                justify-content: center;
                align-items: center;
            }

            .ico {
                font-size: 30px;
                color: #15bd6f;
                margin-right: 10px;

                @media only screen and (min-width: 768px) {
                    font-size: 25px;
                }

                @media only screen and (min-width: 1024px) {
                    font-size: 26px;
                }

                @media only screen and (min-width: 1360px) {
                    font-size: 30px;
                }
            }

            .icon-question {
                font-size: 16px !important;
                cursor: pointer;
                margin-left: 10px;
            }

            @media only screen and (min-width: 320px) AND (max-width: 766px) {
                width: 100%;
                margin-bottom: 15px;

                .icon-question {
                    font-size: 14px !important;
                    margin-left: 10px;
                    line-height: 25px;
                }

                .ico, .text {
                    font-size: 20px;
                }
            }

            @media only screen and (min-width: 767px) AND (max-width: 1199px) {
                width: 160px;

                .icon-question {
                    font-size: 14px !important;
                    line-height: 30px;
                }
            }
        
            @media only screen and (min-width: 1200px) {
                width: 170px;
            }
        
            @media only screen and (min-width: 1441px) {
                width: 180px;
            }
        }
    }

    @media screen and (max-width: 1023px) and (min-width: 767px) {
        max-width: 680px;
        margin: 0px auto 20px;
    }
    
    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        max-width: 770px !important;
        margin: 0 auto;
    }

    @media only screen and (min-width: 1200px) {
        max-width: 716px;
        margin: 0 auto;
    }

    @media only screen and (min-width: 1500px) {
        max-width: 1034px;
        margin: 0 auto;
    }

    @media only screen and (min-width: 1680px) {
        max-width: 1070px;
        margin: 0 auto;
    }

    @media only screen and (min-width: 1750px) {
        max-width: 1180px;
        margin: 0 auto;
    }
`