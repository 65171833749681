import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Skeleton } from '@material-ui/lab'
import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import jQuery from 'jquery'
import { GenericModal, UpgradeToProModal, PricingForm, ScrollToTopBtn } from '../../components'
import { Heading, SignalsList, SignalDetailsContent, SignalDetailsFooter, TradingTypeLabel, TradeForm, TradeOrderSummary } from './components'
import { Container, BodyContent, SignalListStyle, TradeFormHeading } from './styled_components'
import { Section } from '../../styles'
import { isNotNull, isNull, getSize } from '../../utils'
import { onboardingState } from '../../constants/onboardingState'
import { signalState } from '../../utils/CONSTANTS'
import { mediaDeviceSizes } from '../../constants/mediaQueries'

export const HomeView = (props) => {
    const { theme, isThemeLoading } = props.userTheme
    const $this = props.self
    const onDisplayGifPreloader = () => {
        let gif = props.windowWidth < 767 ? "/images/gifs/fresh-signal-mobile.gif" : "/images/gifs/fresh-signal-desktop.gif"
        if (!isThemeLoading && isNotNull(theme) && isNotNull(theme.isDarkMode)) {
            if (theme.isDarkMode === false)
                gif = props.windowWidth < 767 ? "/images/gifs/fresh-signal-mobile-light.gif" : "/images/gifs/fresh-signal-desktop-light.gif"
            else
                gif = props.windowWidth < 767 ? "/images/gifs/fresh-signal-mobile.gif" : "/images/gifs/fresh-signal-desktop.gif"
        }
        return gif
    }
    const gifPreloader = onDisplayGifPreloader()

    let [device, setDevice] = useState(null)
    const [sellModal, setSellModal] = useState(false)
    const [tradeOrderSummary, setTradeOrderSummary] = useState(false)
    const [futuresStatusAlert, setFuturesStatus] = useState({
        show: false,
        msg: ''
    })
    const [showApproachingStopLossWarning, setApproachingStopLossWarningVisibility] = useState(false)
    const [showAwaitingTarget1Warning, setAwaitingTarget1WarningVisibility] = useState(false)
    const [showChooseFeedModal, setChooseFeedModalVisibility] = useState(false)
    const [isMobileAndisChecklistNotCompleted, setMobileChecklists] = useState(false)
    const [showUpgrade, setUpgradeVisibility] = useState(false)
    const [showIsConnectedToExchange, setIsConnectedToExchange] = useState(false)

    const [showTopBtn, setShowTopBtn] = useState(false)

    useEffect(() => {        
        let deviceSize = getSize(window.innerWidth)
        setDevice(deviceSize)

        if (deviceSize === mediaDeviceSizes.xxl) {
            window.addEventListener('scroll', () => {
                if (window.scrollY > 600) {
                    setShowTopBtn(true)
                } else {
                    setShowTopBtn(false)
                }
            })
        }
        
        document.body.classList.remove('offers')
        document.body.classList.remove('onboarding')
        document.body.classList.remove('upgrade-to-pro')
        document.body.classList.remove('messages')
        document.body.classList.add('inner-pages')
    }, [])

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    const onResubScribeHandler = () => props.self.setState((prev) => {
        return ({ showSubscribeNow: !prev.showSubscribeNow })
    })

    const onChangeSignalStateFilter = (e) => {
        props.onClearSignalHandler()
        props.self.setState({
            signalStateFilter: {
                isLoading: props.signalStateFilter.isLoading,
                options: props.signalStateFilter.options,
                selectedValue: e.target.value
            }
        }, () => {
            props.onGetSignalsHandler()
        })
    }

    const onChangeSignalExchangeFilter = (e) => {
        props.onClearSignalHandler()
        props.self.setState({
            signalExchangeFilter: {
                options: props.signalExchangeFilter.options,
                selectedValue: e.target.value
            }
        }, () => {
            props.onGetSignalsHandler()
        })
    }

    const onChangeTradeResultSelections = (values, names) => {
        props.self.setState({
            tradeResultFilter: {
                options: props.tradeResultFilter.options,
                selectedValues: values,
                selectedValueTexts: names
            }
        }, () => {
            props.onGetSignalsHandler()
        })
    }

    const onSelectHandler = (data) => {
        if (isNotNull(props.user)) {
            const { isPaid } = props.user
            if (!isPaid) {
                if (!data.isFree) {
                    //window.location.href = '/subscription-plans/pro/subscribe'
                    if (data.signalState === signalState.LIVE) {
                        // show upgrade popup
                        setUpgradeVisibility(true)
                    } else {
                        props.self.setState({
                            hasSignalSelection: true,
                            selectedSignal: data
                        })
                    }
                } else {
                    props.self.setState({
                        hasSignalSelection: true,
                        selectedSignal: data
                    })
                }
            } else {
                props.self.setState({
                    hasSignalSelection: true,
                    selectedSignal: data
                })
            }
        }

        // props.self.setState({
        //     hasSignalSelection: true,
        //     selectedSignal: data
        // })

        // if (isNotNull(item) && isNotNull(item.data)) {
        //     if (!item.data.isStepsCompleted && window.innerWidth > 766) {
        //         if (nextStep !== 4) {
        //             props.onCheckListsHandler(true)
        //         } else
        //             props.self.setState({
        //                 hasSignalSelection: true,
        //                 selectedSignal: data
        //             })
        //     } else if (!item.data.isStepsCompleted && window.innerWidth < 766)
        //         setMobileChecklists(true)
        //     else
        //         props.self.setState({
        //             hasSignalSelection: true,
        //             selectedSignal: data
        //         })
        // }
    }

    const onDeselectHandler = () => {
        const { enableTradeForm } = props.self.state
        if (enableTradeForm) {
            props.self.setState({
                hasSignalSelection: false,
                viewNotificationDetails: false
            })
        } else {
            props.self.setState({
                hasSignalSelection: false,
                selectedFeed: '',
                selectedSignal: null,
                viewNotificationDetails: false
            })
        }
    }

    const onMakeATradeHandler = () => {
        const { isAutoTradingAllowed, autoTradingSettingsId } = props.user
        const { status } = props.onboardingUser
        let { direction, feed, state } = props.selectedSignal

        if (isNotNull(isAutoTradingAllowed)) {
            if (isAutoTradingAllowed || isNotNull(autoTradingSettingsId)) {
                if (isNotNull(status)) {
                    if (isNotNull(direction) && isNotNull(feed)) {
                        direction = direction.toUpperCase()
                        feed = feed.toUpperCase()

                        if (isNotNull(state)) {
                            const { item1 } = state

                            switch (item1) {
                                default: {
                                    if (feed === "FUTURES") {
                                        setChooseFeedModalVisibility(true)
                                    } else {
                                        props.self.setState({
                                            selectedFeed: "spot",
                                            enableTradeForm: true
                                        }, () => {
                                            props.self.setState({
                                                hasSignalSelection: false
                                            })
                                        })
                                    }

                                    break
                                }
                                case "s-asl": {
                                    // make sure that awaiting target 1 warning message is not visible
                                    setAwaitingTarget1WarningVisibility(false)
                                    setApproachingStopLossWarningVisibility(true)

                                    break
                                }
                                case "s-at1":
                                case "s-t1h":
                                case "s-t2h": {
                                    // make sure that approaching stop loss warning message is not visible
                                    setApproachingStopLossWarningVisibility(false)
                                    setAwaitingTarget1WarningVisibility(true)

                                    break
                                }
                            }
                        }
                    }
                    
                    // if (status.state < onboardingState.ADJUST_TRADING_BALANCE) {
                    //     setIsConnectedToExchange(true)
                    // } else {
                    //     if (isNotNull(direction) && isNotNull(feed)) {
                    //         direction = direction.toUpperCase()
                    //         feed = feed.toUpperCase()
                    //
                    //         if (isNotNull(state)) {
                    //             const { item1 } = state
                    //
                    //             switch (item1) {
                    //                 default: {
                    //                     if (feed === "FUTURES") {
                    //                         setChooseFeedModalVisibility(true)
                    //                     } else {
                    //                         props.self.setState({
                    //                             selectedFeed: "spot",
                    //                             enableTradeForm: true
                    //                         }, () => {
                    //                             props.self.setState({
                    //                                 hasSignalSelection: false
                    //                             })
                    //                         })
                    //                     }
                    //
                    //                     break
                    //                 }
                    //                 case "s-asl": {
                    //                     // make sure that awaiting target 1 warning message is not visible
                    //                     setAwaitingTarget1WarningVisibility(false)
                    //                     setApproachingStopLossWarningVisibility(true)
                    //
                    //                     break
                    //                 }
                    //                 case "s-at1":
                    //                 case "s-t1h":
                    //                 case "s-t2h": {
                    //                     // make sure that approaching stop loss warning message is not visible
                    //                     setApproachingStopLossWarningVisibility(false)
                    //                     setAwaitingTarget1WarningVisibility(true)
                    //
                    //                     break
                    //                 }
                    //             }
                    //         }
                    //     }
                    // }
                }
            }
            else {
                props.self.setState({
                    hasSignalSelection: false
                })

                // Display AutoTrade Settings Error
                props.onViewAutoTradeErrorMessageHandler(true)
            }
        }
    }

    const onContinueWithFeedSelection = (selectedFeed) => {
        jQuery('#trading-type-preloader').css('display', 'block')
        jQuery('#trading-type-content').css('display', 'none')

        setTimeout(() => {
            if (selectedFeed === 'futures') {
                if (isNotNull(props.settings) && isNotNull(props.settings.data))
                {
                    if (!props.settings.data.hasFuturesKey)
                        setFuturesStatus({ ...futuresStatusAlert, show: true })
                    else {
                        props.self.setState({
                            selectedFeed,
                            enableTradeForm: true
                        }, () => {
                            props.self.setState({
                                hasSignalSelection: false
                            })
                            setChooseFeedModalVisibility(false)
                        })
                    }
                }
            } else {
                props.self.setState({
                    selectedFeed,
                    enableTradeForm: true
                }, () => {
                    props.self.setState({
                        hasSignalSelection: false
                    })
                    setChooseFeedModalVisibility(false)
                })
            }
        }, 200)
    }

    const onContinueToCreateTrade = (state) => {    
        if (isNotNull(props.selectedSignal)) {
            let { feed } = props.selectedSignal
            if (isNotNull(feed)) {
                if (state === "asl")
                    setApproachingStopLossWarningVisibility(false)
                else
                    setAwaitingTarget1WarningVisibility(false)
    
                if (feed === "FUTURES") {
                    setChooseFeedModalVisibility(true)
                } else {
                    props.self.setState({
                        selectedFeed: "spot",
                        enableTradeForm: true
                    }, () => {
                        props.self.setState({
                            hasSignalSelection: false
                        })
                    })
                }
            } 
        }
    }

    const onSubmitTradeOrderHandler = async (symbol, orderDetails) => {
        const { eventHandlers } = props
        eventHandlers.onSubmitTradeOrderHandler(orderDetails)
    }
    
    const onShowTradeSummaryHandler = async (symbol, orderDetails) => {
        const { eventHandlers } = props
        eventHandlers.onShowTradeSummaryHandler(orderDetails)
    }

    const onCancelTradeHandler = () => {
        props.self.setState({
            selectedSignal: null,
            selectedFeed: '',
            enableTradeForm: false,
            willTradeFromMobileApp: false
        })
    }

    const onCloseTradeOrderSummaryHandler = () => {
        props.self.setState({
            selectedSignal: null,
            selectedFeed: '',
            orderDetails: null,
            willTradeFromMobileApp: false,
            tradeSummaryOnly: null
        })

        props.eventHandlers.onClearTradeOrderStateHandler()
        setTradeOrderSummary(false)
    }

    const onCloseOrderStatusHandler = () => {
        props.eventHandlers.onClearTradeOrderStateHandler()
        $this.tradeOrderSucceeded = null
    }

    const onCloseFuturesStatusHandler = () => {
        jQuery('#trading-type-preloader').css('display', 'none')
        jQuery('#trading-type-content').css('display', 'block')

        setFuturesStatus({ 
            ...futuresStatusAlert, 
            show: false 
        })
    }

    const handleCloseChooseFeedModal = () => setChooseFeedModalVisibility(false)

    const onCloseSellModalHandler = () => setSellModal(false)

    const onCloseApproachingStopLossWarningModalHandler = () => setApproachingStopLossWarningVisibility(false)

    const onCloseAwaitingTargetWarningModalHandler = () => setAwaitingTarget1WarningVisibility(false)

    const handleUpgradeVisibility = () => setUpgradeVisibility(false)

    const onSetIsConnectedToExchange = () => setIsConnectedToExchange(false)

    const renderSwitchForNoSignals = (selectedFilter) => {
        switch (parseInt(selectedFilter)) {
            default:
            case 2: {
                return (<h1 className="loader-msg">Currently there are no signals in progress</h1>)
            }
            case 3: {
                return (<h1 className="loader-msg">We do not have any closed trades yet</h1>)
            }
            case 4: {
                return (<h1 className="loader-msg">You do not have any signals on your watchlist</h1>)
            }
        }
    }

    const renderTradeStatusBody = () => (
        <>
            <div className="close"
                onClick={() => onCloseOrderStatusHandler()}
                style={{ zIndex: '1' }}>
                <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </div>

            <div className="icon-wrapper">
                <img src="/images/logo/trade-error.png" className="img-fluid" alt="" />
            </div>

            <h3>Trade Error!</h3>
            <p className="mb-4">{$this.errorValue}</p>
        </>
    )

    const renderSignals = () => (
        <BodyContent>
            {isNotNull(props.user) && isNotNull(props.signals) &&
                <>
                    {props.signals.loading &&
                        <>
                            <SignalListStyle className="slider loader">
                                <Skeleton className="skeleton" variant="rect" />
                                <Skeleton className="skeleton" variant="rect" />
                                <Skeleton className="skeleton" variant="rect" />
                                <Skeleton className="skeleton" variant="rect" />
                                <Skeleton className="skeleton" variant="rect" />
                                <Skeleton className="skeleton" variant="rect" />
                            </SignalListStyle>
                        </>
                    }

                    {!props.signals.loading &&
                        <>
                            { isNull(props.signals.signals) && 
                                <>
                                    <div className="gif-loader">
                                        <img src={gifPreloader} className="img-fluid" alt="gif preloader" />
                                    </div>
                                </> 
                            } 

                            { isNotNull(props.signals.signals) && 
                                <>
                                    {/* when there are no signals */}
                                    {props.signals.signals.length < 1 &&
                                        <>
                                            {/* selected filter is all */}
                                            {parseInt(props.signalStateFilter.selectedValue) === 0 &&
                                                <>
                                                    <h1 className="loader-msg">We do not have any signals yet</h1>
                                                </>
                                            }

                                            {/* selected filter is fresh signals */}
                                            {parseInt(props.signalStateFilter.selectedValue) === 1 &&
                                                <>
                                                    <div className="gif-loader">
                                                        <img src={gifPreloader} className="img-fluid" alt="gif preloader" />
                                                    </div>
                                                </>
                                            }

                                            {/* selected filter is fresh signals */}
                                            {parseInt(props.signalStateFilter.selectedValue) > 1 &&
                                                <>
                                                    {renderSwitchForNoSignals(props.signalStateFilter.selectedValue)}
                                                </>
                                            }
                                        </>
                                    }

                                    {/* when there are any signals */}
                                    {props.signals.signals.length > 0 &&
                                        <>
                                            <SignalListStyle className="slider">
                                                <SignalsList
                                                    user={props.user}
                                                    onboardingUser={props.onboardingUser}
                                                    filter={props.signalStateFilter.selectedValue} 
                                                    signalExchangeFilter={props.signalExchangeFilter} 
                                                    data={props.signals}
                                                    followedSignals={props.followedSignals}
                                                    onSelectHandler={onSelectHandler}
                                                    onFollowSignalHandler={props.onFollowSignalHandler} />
                                            </SignalListStyle>

                                            { props.hasNewSignalSetLoaded === true &&
                                                <p className="text-center"><i className="fas fa-spinner fa-pulse"></i> Fetching...</p>
                                            }

                                            { device === mediaDeviceSizes.xxl && showTopBtn && 
                                                <ScrollToTopBtn eventHandlers={goToTop} />
                                            }
                                        </>
                                    }
                                </>
                            }
                        </>
                    }
                </>
            }
        </BodyContent>
    )

    if (!props.isSendingOrder && isNotNull(props.tradeOrderLeverage)) {
        const { response } = props.tradeOrderLeverage
        if (isNotNull(response)) {
            if (response.success) {
                $this.tradeOrderSucceeded = response.success

                if (!tradeOrderSummary) 
                    setTradeOrderSummary(true)
            } else {
                $this.tradeOrderSucceeded = response.success

                if (!tradeOrderSummary)
                    $this.errorValue = response.data
            }
        }
    }

    if (!props.isSendingOrder &&
        isNotNull(props.tradeOrder) &&
        isNotNull(props.tradeOrder.items)) {
        if (props.tradeOrder.items.success) {
            $this.tradeOrderSucceeded = props.tradeOrder.items.success

            if (!tradeOrderSummary)
                setTradeOrderSummary(true)
        } else {
            $this.tradeOrderSucceeded = props.tradeOrder.items.success

            if (!tradeOrderSummary)
                $this.errorValue = props.tradeOrder.items.errorMessage
        }
    }
    
    if (!props.isSendingOrder && 
        isNotNull(props.tradeSummaryOnly) &&
        props.tradeSummaryOnly) {
        if (!tradeOrderSummary) {
            setTradeOrderSummary(true)
        }
    }

    return (
        <>
            <Section className="set-size">
                <Container className="signal-slider theme-signal-slider">
                    <Heading
                        user={props.user}
                        filter={props.signalStateFilter}
                        signalExchangeFilter={props.signalExchangeFilter} 
                        tradeResultFilter={props.tradeResultFilter} 
                        onChangeFilter={onChangeSignalStateFilter}
                        onChangeExchangeFilter={onChangeSignalExchangeFilter}
                        onChangeTradeResultFilter={onChangeTradeResultSelections}
                        isLoadingSignals={props.signals.loading}
                        windowWidth={props.windowWidth} />

                    {renderSignals()}                    
                </Container>
            </Section>

            {/* Signal Details Modal */}
            { isNotNull(props.user) && 
                isNotNull(props.selectedSignal) && 
                isNotNull(props.selectedSignal.state) && 
                isNotNull(props.selectedSignal.signalState) && 
                isNotNull(props.settings) && 
                <>
                    <GenericModal
                        className="signal-preview"
                        show={props.hasSignalSelection}
                        onHide={onDeselectHandler}
                        header={() => {
                            return (
                                <div className="close"
                                    onClick={() => onDeselectHandler()}
                                    style={{ zIndex: '1' }}>
                                    <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                </div>
                            )
                        }}
                        bodyContent={() => {
                            return (
                                <SignalDetailsContent
                                    isDarkMode={isNotNull(theme) ? theme.isDarkMode : false}
                                    signalInfo={props.selectedSignal}
                                    onHide={onDeselectHandler} />
                            )
                        }}
                        footer={() => {
                            switch (props.selectedSignal.signalState) {
                                case signalState.LIVE:
                                    {
                                        return (
                                            <>
                                                { (props.selectedSignal.state.item1 === 's-wez' || 
                                                    props.selectedSignal.state.item1 === 's-lez' || 
                                                    props.selectedSignal.state.item1 === 's-asl' || 
                                                    props.selectedSignal.state.item1 === 's-at1' || 
                                                    props.selectedSignal.state.item1 === 's-t1h' || 
                                                    props.selectedSignal.state.item1 === 's-t2h') && 
                                                    props.selectedSignal.isActive &&
                                                    <SignalDetailsFooter 
                                                        hasConnectedExchange={!(props.onboardingUser.status.state < onboardingState.ADJUST_TRADING_BALANCE)}
                                                        onMakeATrade={onMakeATradeHandler} />
                                                }
                                            </>
                                        )
                                    }
                                case signalState.OPEN:
                                    {
                                        return (
                                            <>
                                                { isNotNull(props.settings) && !props.selectedSignal.isActive &&
                                                    <SignalDetailsFooter
                                                        onMakeATrade={onMakeATradeHandler} />
                                                }
                                            </>
                                        )
                                    }
                                default: return null
                            }
                        }}
                    />
                </>
            }

            {/* Trade Form Modal */}
            { isNotNull(props.user) && 
                isNotNull(props.eventHandlers) &&
                isNotNull(props.enableTradeForm) &&
                isNotNull(props.selectedSignal) && 
                isNotNull(props.selectedFeed) && 
                isNotNull(props.isSendingOrder) &&
                isNotNull(props.onCreateQuickTradeHandler) &&
                <>
                    <GenericModal
                        className="trade-form"
                        show={props.enableTradeForm}
                        onHide={onCancelTradeHandler}
                        header={() => {
                            return (
                                <>
                                    <TradeFormHeading className="trading-title outcome-title">
                                        <h2>Trading Form <FontAwesomeIcon icon={faQuestionCircle} /></h2>
                                    </TradeFormHeading>

                                    <div className="close"
                                        onClick={() => onCancelTradeHandler()}
                                        style={{ zIndex: '1' }}>
                                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                    </div>
                                </>
                            )
                        }}
                        bodyContent={() => {
                            return (
                                <>
                                    <div className="close"
                                        onClick={() => onCancelTradeHandler()}
                                        style={{ zIndex: '1' }}>
                                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                    </div>

                                    <TradeForm                                    
                                        user={props.user}
                                        onboardingUser={props.onboardingUser}
                                        signalInfo={props.selectedSignal}
                                        selectedFeed={props.selectedFeed}
                                        isSendingOrder={props.isSendingOrder}
                                        eventHandlers={{
                                            onSubmitTradeOrderHandler,
                                            onShowTradeSummaryHandler,
                                            onCreateQuickTradeHandler: props.onCreateQuickTradeHandler
                                        }} />
                                </>
                            )
                        }} />
                </>
            }

            {/* Create Order Preloader */}
            { isNotNull(props.isSendingOrder) &&
                <>
                    <GenericModal
                        className="trade-order-loader"
                        show={props.isSendingOrder}
                        backdrop="static"
                        keyboard={false}
                        bodyContent={() => {
                            return (
                                <>
                                    <h2>Creating Trade Order...</h2>
                                    <div className="loader-con">
                                        <img srcSet={'../images/layout/loader-150.gif'} alt="Preloader" className="img-fluid" />
                                    </div>
                                </>
                            )
                        }} />
                </>
            }

            {/* Trade Order Status Modal */}
            { isNotNull(props.eventHandlers) &&
                <>
                    <GenericModal
                        className="trade-order-status"
                        show={isNotNull($this.tradeOrderSucceeded) && !$this.tradeOrderSucceeded}
                        onHide={() => onCloseOrderStatusHandler}
                        bodyContent={() => renderTradeStatusBody()}
                    />
                </>
            }

            {/* Trade Order Summary Modal */}
            { isNotNull($this.tradeOrderSucceeded) &&
                $this.tradeOrderSucceeded &&
                isNotNull(props.eventHandlers) &&
                isNotNull(props.selectedSignal) &&
                isNotNull(props.orderDetails) &&
                <>
                    <GenericModal
                        className="trade-order-summary"
                        show={true}
                        onHide={() => onCloseTradeOrderSummaryHandler()}
                        header={() => {
                            return (
                                <div className="close"
                                    onClick={() => onCloseTradeOrderSummaryHandler()}
                                    style={{ zIndex: '1' }}>
                                    <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                </div>
                            )
                        }}
                        bodyContent={() => {
                            return (
                                <>
                                    { window.innerWidth > 766 &&
                                        <div className="close"
                                            onClick={() => onCloseTradeOrderSummaryHandler()}
                                            style={{ zIndex: '1' }}>
                                            <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                        </div>
                                    }
                                    
                                    <TradeOrderSummary 
                                        userInfo={props.user}
                                        selectedSignal={props.selectedSignal}
                                        orderDetails={props.orderDetails}
                                        tradeSummaryOnly={props.tradeSummaryOnly}
                                    />
                                </>
                            )
                        }} />
                </>
            }

            {/* Futures Key Checker Modal */}
            { isNotNull(futuresStatusAlert) && isNotNull(props.onViewAutoTradeErrorMessageHandler) &&
                <>
                    <GenericModal
                        className="disabled-futures-trading-warning"
                        show={futuresStatusAlert.show}
                        onHide={onCloseFuturesStatusHandler}
                        bodyContent={() => {
                            return (
                                <>
                                    <div className="close"
                                        onClick={() => onCloseFuturesStatusHandler()}
                                        style={{ zIndex: '1' }}>
                                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                    </div>

                                    <div className="icon-warning">
                                        <i className="fas fa-exclamation-triangle"></i>
                                    </div>

                                    <h3>Turn On FUTURES trading?</h3>
                                    <p className="mb-4">To enable FUTURES trading in your account, <Link to="/account/auto-trade" onClick={() => props.onViewAutoTradeErrorMessageHandler(false)}>go to settings</Link> to add your Binance FUTURES api and secret keys.</p>
                                </>
                            )
                        }} />
                </>
            }

            {/* Resubscribe Modal */}
            { isNotNull(props.showSubscribeNow) &&
                <>
                    <GenericModal
                        className="onboarding-modal"
                        show={props.showSubscribeNow}
                        size="xl"
                        closeButton={true}
                        onHide={onResubScribeHandler}
                        header={() => (<></>)}
                        bodyContent={() => {
                            return (
                                <PricingForm closeModal={() => onResubScribeHandler()} />
                            )
                        }}
                    />
                </>
            }

            {/* Sell Modal */}
            <GenericModal
                id="autoTradingModal"
                className="autoTradingModal"
                show={sellModal}
                bodyClass="asl-waring"
                onHide={onCloseSellModalHandler}
                bodyContent={() => {
                    return (
                        <>
                            <div className="close" onClick={() => onCloseSellModalHandler()} style={{ zIndex: '1' }}>
                                <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                            </div>
                            <div className="icon-warning">
                                <i className="fas fa-info-circle text-info"></i>
                            </div>

                            <h3>Sorry!</h3>
                            <p className="mb-4">SHORT trades need to be completed manually within Binance</p>

                            <div className="asl-warning-buttons">
                                <button variant="primary" className="btn-yes" onClick={() => onCloseSellModalHandler()} style={{ color: '#fff' }}>
                                    <span>Ok, I Understand</span>
                                </button>
                            </div>
                        </>
                    )
                }}
            />

            {/* Mobile Checklists */}
            <Modal
                id="autoTradingModal"
                className="generic-modal autoTradingModal"
                show={isMobileAndisChecklistNotCompleted}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setMobileChecklists(false)}>
                <Modal.Body className="asl-waring signal-checklist">
                    <div className="close" onClick={() => setMobileChecklists(false)} style={{ zIndex: '1' }}>
                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                    </div>
                    <div className="icon-warning">
                        <i className="fas fa-exclamation-triangle"></i>
                    </div>

                    <p>Please configure your CHECKLIST in web app in order to trade in mobile.</p>
                </Modal.Body>
            </Modal>

            {/* Warning Message for making a trade with Approaching Stop Loss Signal */}
            { isNotNull(props.selectedSignal) && 
                <>
                    <GenericModal
                        id="asl-signal-warning-modal"
                        className="asl-warning-modal"
                        show={showApproachingStopLossWarning}
                        bodyClass="warning-message"
                        onHide={onCloseApproachingStopLossWarningModalHandler}
                        bodyContent={() => {
                            return (
                                <>
                                    <div className="close" onClick={() => onCloseApproachingStopLossWarningModalHandler()} style={{ zIndex: '1' }}>
                                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                    </div>

                                    <div className="icon-warning">
                                        <i className="fas fa-exclamation-triangle"></i>
                                    </div>

                                    <h3>Warning!</h3>
                                    <p className="mb-4">This coin is close to hitting the stop loss. <br /> Are you sure you want to proceed?</p>

                                    <div className="asl-warning-buttons">
                                        <button
                                            variant="primary"
                                            className="btn-yes"
                                            onClick={() => onContinueToCreateTrade("asl")}
                                            style={{ color: '#fff' }}>
                                            <span>Yes</span>
                                        </button>

                                        <button variant="default" className="btn-no" onClick={() => onCloseApproachingStopLossWarningModalHandler()} style={{ color: '#fff' }}>
                                            <span>No</span>
                                        </button>
                                    </div>
                                </>
                            )
                        }}
                    />
                </>
            }

            {/* Warning Message for making a trade with Awaiting Target 1 Signal */}
            { isNotNull(props.selectedSignal) && 
                <>
                    <GenericModal
                        id="at1-signal-warning-modal"
                        className="at1-warning-modal"
                        show={showAwaitingTarget1Warning}
                        bodyClass="warning-message"
                        onHide={onCloseAwaitingTargetWarningModalHandler}
                        bodyContent={() => {
                            return (
                                <>
                                    <div className="close" onClick={() => onCloseAwaitingTargetWarningModalHandler()} style={{ zIndex: '1' }}>
                                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                    </div>

                                    <h3>Reminder!</h3>
                                    <p className="mb-4">This signal may not be 'Fresh' as it has already left the entry zone or hit some targets, so you may be 'late to the party'. We don't recommend trading signals that already hit T1+.<br /> Are you sure you want to proceed? Not recommended unless if you are a Pro Trader!</p>

                                    <div className="asl-warning-buttons">
                                        <button
                                            variant="primary"
                                            className="btn-yes"
                                            onClick={() => onContinueToCreateTrade("at1")}
                                            style={{ color: '#fff' }}>
                                            <span>Yes</span>
                                        </button>

                                        <button variant="default" className="btn-no" onClick={() => onCloseAwaitingTargetWarningModalHandler()} style={{ color: '#fff' }}>
                                            <span>No</span>
                                        </button>
                                    </div>
                                </>
                            )
                        }}
                    />
                </>
            }

            {/* Choose Feed Modal */}
            { isNotNull(props.user) && 
                isNotNull(props.settings) && 
                <>
                    <GenericModal 
                        id="chooseFeedModal" 
                        className="choose-feed-modal" 
                        show={showChooseFeedModal} 
                        bodyClass={'choose-feed-modal-body'} 
                        onHide={handleCloseChooseFeedModal} 
                        bodyContent={() => {
                            return (
                                <>
                                    <div className="close" onClick={() => handleCloseChooseFeedModal()} style={{ zIndex: '1' }}>
                                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                    </div>
                                    
                                    <div id='trading-type-preloader' style={{ display: 'none' }}>
                                        <img srcSet={'/images/layout/loader-150.gif'} alt="" className="img-fluid" style={{ width: '80px', height: '80px' }} />
                                    </div>

                                    <div id="trading-type-content">
                                        <TradingTypeLabel traderInfo={props.user} />

                                        <div className="feed-options">
                                            <button
                                                variant="primary"
                                                className="trading-type-btn spot"
                                                onClick={() => onContinueWithFeedSelection("spot")}
                                                style={{ color: '#fff' }}>
                                                <span className="icon">
                                                    <img srcSet={'/images/icons/ICONS-05.svg'} alt="" className="img-fluid" />
                                                </span>
                                                <span>Spot</span>
                                            </button>

                                            <button 
                                                variant="default" 
                                                className="trading-type-btn futures" 
                                                onClick={() => onContinueWithFeedSelection("futures")} 
                                                style={{ color: '#fff' }}>
                                                <span className="icon">
                                                    <img srcSet={'/images/icons/ICONS-04.svg'} alt="" className="img-fluid" />
                                                </span>
                                                <span>Futures</span>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )
                        }} />
                </>
            }

            { /* Upgrade to PRO */}
            { isNotNull(props.user) && 
                <>
                    <GenericModal 
                        id="upgradeModal"
                        className="upgrade-to-pro-modal"
                        show={showUpgrade}
                        bodyClass={'upgrade-to-pro-modal-body'}
                        onHide={handleUpgradeVisibility} 
                        bodyContent={() => {
                            return (
                                <UpgradeToProModal />
                            )
                        }} />
                </>
            }

            { /* Not connected to exchange */ }
            { isNotNull(props.user) && 
                <>
                    <GenericModal 
                        id="not-connected-to-exchange" 
                        className="not-connected-to-exchange" 
                        show={showIsConnectedToExchange}
                        bodyClass="not-connected-to-exchange-body" 
                        onHide={onSetIsConnectedToExchange} 
                        bodyContent={() => {
                            return (
                                <>                                
                                    <div className="close" onClick={() => onSetIsConnectedToExchange()} style={{ zIndex: '1' }}>
                                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                    </div>

                                    <div className="icon-warning">
                                        <i className="fas fa-exclamation-triangle"></i>
                                    </div>

                                    <h3>Oops!</h3>
                                    <p className="mb-4">Your account setup is incomplete. Connect your Binance.com account to unlock all features.</p>

                                    <div className='action-btns'>
                                        <Link to="/account/auto-trade" onClick={() => onSetIsConnectedToExchange()}>Connect my Binance</Link>
                                    </div>
                                </>
                            )
                        }} />
                </>
            }
        </>
    )
}