import { endpoints, baseUrl } from './CONSTANTS'
import { authServices } from './authService'

const { v3 } = endpoints

function get() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}${v3.settings.subscriptionPlans.list}`, requestOpts)
            .then(handleResponse)
            .then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
    })
}

function getTrialOffer() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}${v3.settings.subscriptionPlans.trialOffer}`, requestOpts)
            .then(handleResponse)
            .then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
    })
}

function getSixMonthsOffer() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }
    
    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}${v3.settings.subscriptionPlans.sixMonthsOffer}`, requestOpts)
            .then(handleResponse)
            .then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
    })
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        if (res.status === 401)
            authServices.logout()
        else
            return data
    })
}

export const planServices = {
    get,
    getTrialOffer,
    getSixMonthsOffer
}