import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import * as signalR from '@microsoft/signalr'
import jQuery from 'jquery'
import Swal from 'sweetalert2'
import { routes } from '../../navigation/CONSTANTS'
import { onboardingActions, planActions, userActions, offerActions } from '../../redux/actions'
import { fsWebhook } from '../../services/CONSTANTS'
import { isNotNull, isNull, onSetNewState } from '../../utils'
import { Layout } from '../../styles'
import { IntroSection } from '../Sections/SalesSections/IntroSection'
import { PremiumFeaturesSection } from '../Sections/SalesSections/PremiumFeaturesSection'
import { BlueprintSection } from '../Sections/SalesSections/BlueprintSection'
import { ProTrialDaysSection } from '../Sections/SalesSections/ProTrialDaysSection'
import { FreeVsProSection } from '../Sections/SalesSections/FreeVsProSection'
import { ProStatsSection } from '../Sections/SalesSections/ProStatsSection'
import { PowerPFSection } from '../Sections/SalesSections/PowerPFSection'
import { LadderingModeSection } from '../Sections/SalesSections/LadderingModeSection'
import { TestimonialsSection } from '../Sections/SalesSections/TestimonialsSection'
import { TradesInActionSection } from '../Sections/SalesSections/TradesInActionSection'
import { CancelAnytimeSection } from '../Sections/SalesSections/CancelAnytimeSection'
import { SignUpStepsSection } from '../Sections/SalesSections/SignUpStepsSection'
import { TradeGuardianSection } from '../Sections/SalesSections/TradeGuardianSection'
import { ProEmpowerSection } from '../Sections/SalesSections/ProEmpowersSection'
import { BreakevenSection } from '../Sections/SalesSections/BreakevenSection'
import { CaseStudiesSection } from '../SubscriptionPlans/CaseStudySection'
import { SaveMoreSection } from '../Sections/SalesSections/SaveMoreSection'
import { FaqSection } from '../Sections/SalesSections/FaqSection'

class TrialOfferContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            show: undefined,
            isSkipping: false,
            hasSkippedOnboarding: null,
            userInfo: null,
            subscriptionPlan: null
        }

        this.baseState = this.state
        this.mounted = false
        this.fsHubConnection = null

        this.startListening()
        this.initializeHubConnection()
    }

    componentDidMount() {
        this.mounted = true
        const { dispatch } = this.props
        dispatch(onboardingActions.getOnboardingState())
        dispatch(userActions.getMe())
        dispatch(planActions.getTrialOffer())
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.mounted) {
            const { authentication, trialPlan, onetimeOffer } = this.props
            const { show, isSkipping, userInfo, subscriptionPlan } = this.state

            if (isNull(subscriptionPlan) && 
                isNotNull(trialPlan) && 
                prevProps.trialPlan !== trialPlan && 
                !trialPlan.loading) {
                    if (isNotNull(trialPlan.offer)) {
                        onSetNewState(this, {
                            subscriptionPlan: trialPlan.offer
                        })
                    }
                }

            // if (isNull(subscriptionPlans) && 
            //     isNotNull(plan) && 
            //     prevProps.plan !== plan && 
            //     !plan.loading) {
            //         let subscriptionPlanList = plan.plans
            //         if (isNotNull(subscriptionPlanList.Fastspring)) {
            //             onSetNewState(this, {
            //                 subscriptionPlans: subscriptionPlanList.Fastspring
            //             })
            //         }
            // }

            if (isNull(userInfo) && 
                isNotNull(authentication.user)) {
                    const { ...data } = authentication.user
                    onSetNewState(this, {
                        userInfo: {
                            id: data.userId,
                            customerId: data.customerId,
                            leadId: data.leadId,
                            isLead: data.isLead,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            userName: data.userName,
                            hasSubscribed: data.isPaid,
                            isWithinTrialPeriod: data.isWithinTrialPeriod
                        },
                        show: !data.hasTrialHistory && !data.isWithinTrialPeriod
                    })
            }

            // if (isNotNull(userInfo) && 
            //     isNull(this.state.show) && 
            //     isNotNull(onboardingUser) && 
            //     !onboardingUser.loading) {
            //         //console.log('onboardingUser: ', onboardingUser)
            //     if (isNull(onboardingUser)) {
            //         onSetNewState(this, { show: true })
            //     } else {
            //         const { status } = onboardingUser
            //         //console.log('status: ', status)
            //         if (isNotNull(status) && !status.hasSkippedOneTimeOffer) {
            //             if (!userInfo.hasSubscribed)
            //                 onSetNewState(this, { show: true, hasSkippedOnboarding: status.hasSkipped })
            //             else
            //                 onSetNewState(this, { show: false, hasSkippedOnboarding: status.hasSkipped })
            //         } else
            //             onSetNewState(this, { show: false, hasSkippedOnboarding: status.hasSkipped })
            //     }
            // }
            
            if (isNotNull(show) && prevState.show !== show && isNotNull(userInfo)) {
                await this.startFsWebhookConnection(userInfo.userName)
            }

            if (isNotNull(onetimeOffer) && 
                prevProps.onetimeOffer !== onetimeOffer && 
                !onetimeOffer.loading && isSkipping) {
                    const { result } = onetimeOffer
                    if (result.success) {
                        window.location = '/'
                    }
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
        onSetNewState(this, this.baseState)

        if (this.fsHubConnection !== null) {
            if (!this.fsHubConnection.hasOwnProperty('close'))
                this.fsHubConnection.stop()
        }
    }

    startListening = () => {
        window.onPaymentReceived = data => {
            if (isNotNull(data)) {
                Swal.fire({
                    title: 'Payment successful, please wait while we setup your account.',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    didOpen: () => Swal.showLoading()
                })
            }
        }
    }

    initializeHubConnection = () => {
        let hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${fsWebhook}/fs`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .configureLogging(signalR.LogLevel.None)
            .withAutomaticReconnect([0,0,5000])
            .build()
        hubConnection.serverTimeoutInMilliseconds = 120000

        this.fsHubConnection = hubConnection
        this.fsHubConnection.on('OnPaymentMade', (payload) => {
            const { ...purchase } = payload
            const { userInfo } = this.state

            if (isNotNull(purchase) && 
                isNotNull(userInfo) && 
                purchase.userId === userInfo.id) {

                window.dataLayer.push({
                    event: 'purchase',
                    user_id: purchase.userId,
                    product: purchase.product,
                    subscriptionPlanId: purchase.subscriptionPlanId,
                    subscriptionRef: purchase.subscriptionRef,
                    amount: purchase.amount,
                    isRenewal: purchase.isRenewal
                })

                window.dataLayer.push({
                    event: 'trialPurchase',
                    user_id: purchase.userId,
                    product: purchase.product,
                    subscriptionPlanId: purchase.subscriptionPlanId,
                    subscriptionRef: purchase.subscriptionRef,
                    amount: purchase.amount,
                    isRenewal: purchase.isRenewal
                })

                jQuery(document).find('#fscCanvas').remove()
                Swal.close()
                window.location = "/"
            }
        })
    }

    startFsWebhookConnection = async (username) => {
        if (this.mounted) {
            try {
                await this.fsHubConnection
                    .start()
                    .catch(err => console.log(err))

                if (this.fsHubConnection.state === signalR.HubConnectionBuilder.Connected) {
                    this.fsHubConnection.invoke("GetConnectionId", username)
                        .then((connectionId) => {
                            console.log('conn: ', connectionId)
                        })
                        .catch(err => console.log(err))
                }
            }
            catch (err) {
                if (this.fsHubConnection.state === signalR.HubConnectionState.Disconnected) {
                    setTimeout(async () => {
                        await this.startFsWebhookConnection(username)
                    }, 3000)
                }
            }
        }
    }

    handleSkipTrial = (e) => {
        e.preventDefault()
        
        onSetNewState(this, {                                                                                                                                                 
            isSkipping: true
        }, () => {
            const { dispatch } = this.props
            dispatch(offerActions.skipOffer())
        })
    }

    render() {
        const { show, hasSkippedOnboarding, userInfo, subscriptionPlan } = this.state
        if (isNotNull(show)) {
            if (show) {
                return (
                    <Layout>
                        <IntroSection 
                            page="trial"
                            subscriptionPlan={subscriptionPlan} 
                            userInfo={userInfo} />

                        <PremiumFeaturesSection />

                        <BlueprintSection 
                            subscriptionPlan={subscriptionPlan} 
                            userInfo={userInfo} />

                        <ProTrialDaysSection />

                        <FreeVsProSection 
                            subscriptionPlan={subscriptionPlan} 
                            userInfo={userInfo} />

                        <ProStatsSection page="trial" />
                        <PowerPFSection />
                        <LadderingModeSection />
                        <TestimonialsSection />
                        <TradesInActionSection />

                        <CancelAnytimeSection 
                            subscriptionPlan={subscriptionPlan} 
                            userInfo={userInfo} />
                        <SignUpStepsSection />
                        <TradeGuardianSection page="trial" />
                        <ProEmpowerSection />
                        <BreakevenSection />
                        <CaseStudiesSection />

                        <SaveMoreSection 
                            page="trial"
                            subscriptionPlan={subscriptionPlan} 
                            userInfo={userInfo} 
                            onSkipTrial={(e) => this.handleSkipTrial(e) } />

                        <FaqSection />
                    </Layout>
                )
            } else {
                if (isNotNull(hasSkippedOnboarding) && hasSkippedOnboarding) {
                    return (<Redirect to={{
                        pathname: routes.dashboard,
                    }} />)
                } else {
                    return (<Redirect to={{
                        pathname: '/',
                    }} />)
                }
            }
        } else {
            return (<></>)
        }
    }
}

function mapStateToProps(state) {
    const { 
        onboardingUser, 
        authentication,
        plan, 
        trialPlan,
        onetimeOffer } = state

    return {
        onboardingUser,
        authentication,
        plan,
        trialPlan,
        onetimeOffer
    }
}

const connectedTrialOfferContainer = connect(mapStateToProps)(TrialOfferContainer)
export { connectedTrialOfferContainer as TrialOfferContainer }