import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const CaseStudyStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    background-color: #111822;
    background-image: url('/images/offers/case-study-bkgnd.jpg');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.81;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 490px;
    padding: 0;

    .container {
        max-width: 100%;
        margin: 0 auto;
        padding: 0;
        text-align: center;

        .carousel {
            .carousel-inner {
                .carousel-item {
                    background-color: #00000000;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: right center;
                    min-width: 100%;
                    min-height: 490px;
                    text-align: left;
                    padding: 50px 30px;
                    color: #fff;

                    &.bernhard {
                        background-image: url('/images/offers/bernhard-wagner-fadedd.png')
                    }

                    &.jaime {
                        background-image: url('/images/offers/Jaime-O-fade.png')
                    }
                    
                    &.tim {
                        background-image: url('/images/offers/tim-griffith-fadeds.png')
                    }
                    
                    &.hooman {
                        background-image: url('/images/offers/Hooman-Case-Study-carousel.png')
                    }

                    .heading {
                        width: 178px;
                        margin-bottom: 20px;

                        img {
                            width: 100%;
                        }
                    }

                    .body {
                        max-width: 100%;

                        .subject {
                            font-size: 18px;
                            font-weight: 500;

                            .name {
                                margin-right: 15px;
                            }
                        }

                        .feedback {
                            font-size: 22px;
                            margin-bottom: 50px;
                        }

                        a {
                            display: table;
                            background-color: #179e64;
                            color: #fff;
                            padding: 15px 30px;
                            margin: 0 auto;
                            font-size: 16px;
                            text-transform: uppercase;
                            height: 50px;
                            border-radius: 25px;
                        }
                    }
                }
            }

            .carousel-control-prev {
                left: -11px;
            }

            .carousel-control-next {
                right: -11px;
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        background-size: cover;
        height: 438px;

        .container {
            max-width: 100%;

            .carousel {
                .carousel-inner {
                    padding: 0;

                    .carousel-item {
                        background-size: cover;
                        background-position: 200px center;
                        padding: 50px 60px;
                        min-height: 438px;

                        .body {
                            max-width: 70%;
                        }
                    }
                }

                .carousel-control-prev {
                    left: -29px;
                }

                .carousel-control-next {
                    right: -29px;
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        .container {
            .carousel {
                .carousel-inner {
                    .carousel-item {
                        background-size: contain;
                        background-position: right center;

                        .heading {
                            width: 200px;
                        }

                        .body {
                            a {
                                display: inline-block;
                                margin: 0;
                            }
                        }
                    }
                }

                .carousel-control-prev {
                    left: -48px;
                }

                .carousel-control-next {
                    right: -48px;
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        background-size: contain;
        height: 477px;

        .container {
            max-width: 1200px;

            .carousel {
                .carousel-inner {
                    .carousel-item {
                        min-height: 477px;
                        padding: 50px 0;

                        .heading {
                            width: 350px;
                        }

                        .body {
                            max-width: 56%;

                            .subject {
                                font-size: 24px;

                                .name {
                                    margin-right: 15px;
                                }
                            }

                            .feedback {
                                font-size: 28px;
                            }

                            a {
                                display: inline-block;
                                margin: 0;
                            }
                        }
                    }
                }

                .carousel-control-prev {
                    left: -180px;
                }

                .carousel-control-next {
                    right: -180px;
                }
            }
        }
    }
`