import React from 'react'
import ReactPlayer from 'react-player/lazy'
import { TradesInActionSectionStyle } from './styles/TradesInActionStyle'

export const TradesInActionSection = () => {
    return (
        <TradesInActionSectionStyle>
            <div className='container'>
                <h3 className='section-title'>Your Crypto Co-pilot Suggests, Plans and Executes Pro Trades Just Like These</h3>

                <ul className='snapvids'>
                    <li>
                        <ReactPlayer 
                            url={'https://vimeo.com/688281053/080098008c'}
                            controls={true} 
                            width='100%' 
                            volume={0} />

                        <p className='video-title'>ALPINEUSDT - Result +150% in 5 days</p>
                    </li>
                    <li>
                        <ReactPlayer 
                            url={'https://vimeo.com/693919602/5d8b61aff2'}
                            controls={true} 
                            width='100%' />

                        <p className='video-title'>GMTUSDT - Result +134% in 4 days</p>
                    </li>
                    <li>
                        <ReactPlayer 
                            url={'https://vimeo.com/693823755/ab5581ec26'}
                            controls={true} 
                            width='100%' />

                        <p className='video-title'>GMTBTC - Result +135% in 4 days</p>
                    </li>
                </ul>
                
                <p className='section-description'>These trades happened in March 2022 when the market was DEAD for the average trader. ProfitFarmers is a totally different BEAST.</p>
            </div>
        </TradesInActionSectionStyle>
    )
}