import styled from 'styled-components'
import { device } from '../../../styles/CONSTANTS'


export const LoadingWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
`

export const BodyWrapper = styled.div`
	height: 100%;
	background: #fff;
	overflow: hidden;

	&.onRegistration {
		height: unset;
	}
`

export const WelcomeImageWrapper = styled.div`
	margin: 20px 0;
`

export const WelcomeText = styled.div`
	text-align: center;
	color: #aaaaaa;
	font-size: 12px;

	p {
		margin-top: 0;
		margin-bottom: 1rem;
	}

	a {
		color: #3f9f32;
		text-decoration: underline;
		font-weight: 500;
	}
`

export const BodyContainer = styled.div`
	&.onLocked {
		padding: 10px;
	}

	&.onRegistration {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&.payment-bg {
		background: url('/images/pricing-bg.png');
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: bottom;
		height: 100%;
	}

	&.guarantee {
		.content-title {
			text-align: center;
			font-size: 35px;
			font-weight: 700;
			line-height: 1.2em;
			margin-bottom: 25px;
	
			@media screen and (min-width: 320px) and (max-width: 767px) {
				font-size: 26px;
			}
		}

		.img-container {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			margin: 0 auto;

			@media screen and (min-width: 320px) and (max-width: 767px) {
				padding-bottom: 30px;
				width: 85%;
			}

			@media screen and (min-width: 768px) and (max-width: 1199px) {
				&.onlocked {
					padding-bottom: 30px;
					width: 85%;
				}
			}
			
		}

		.content-body {
			p {
				text-align: justify;
				margin-bottom: 1.6rem;

				&:last-child {
					margin-bottom: 0
				}

				
			}

			@media screen and (min-width: 320px) and (max-width: 767px) {
				padding: 0 20px;
				
				p {
					font-size: 15px;
				}
			}

			@media screen and (min-width: 768px) and (max-width: 1199px) {
				&.onlocked {
					padding: 0 20px;
				}
			}
		}
	}

	
`

export const FeatureContents = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 25px;

	ul {
		list-style: none;
	    padding: 0;
	    display: grid;
	    grid-template-columns: 320px 320px 320px;
	    grid-gap: 40px 30px;

		li {
			text-align: center;
			color: #fff;
			padding: 0 10px;

			img {
				width: 56px;
			    height: 56px;
			    margin-bottom: 20px;
			}

			i {
				font-size: 45px;
				margin-bottom: 20px;
			}

			.list-title {
				font-size: 22px;
				font-weight: 500;
			}

			.list-description {
				font-size: 16px;
			}
		}
	}

	@media screen and (min-width: 320px) and (max-width: 1023px) {
		ul {
			grid-template-columns: auto;
			grid-gap: 30px;

			li {
				&.no-content {
					display: none;
				}

				img {
					width: 45px;
				    height: 45px;
				    margin-bottom: 10px;
				}

				i {
					font-size: 35px;
					margin-bottom: 10px;
				}

				.list-title {
				    font-size: 20px;
				    margin-bottom: 10px;
				}

				.list-description {
					font-size: 14px;
				}
			}
		}
	}

	@media screen and (min-width: 1024px) and (max-width: 1199px) {
		ul {
			grid-template-columns: auto auto auto;
			grid-gap: 30px;

			li {
				img {
					width: 45px;
				    height: 45px;
				    margin-bottom: 10px;
				}

				i {
					font-size: 35px;
					margin-bottom: 10px;
				}

				.list-title {
				    font-size: 20px;
				    margin-bottom: 10px;
				}

				.list-description {
					font-size: 14px;
				}
			}
		}
	}

	@media screen and (min-width: 1200px) and (max-width: 1440px) {
		ul {
			grid-gap: 25px;

			li {
				img {
					width: 45px;
				    height: 45px;
				    margin-bottom: 10px;
				}

				i {
					font-size: 35px;
					margin-bottom: 10px;
				}

				.list-title {
				    font-size: 20px;
				    margin-bottom: 10px;
				}

				.list-description {
					font-size: 14px;
				}
			}
		}
	}
`

export const FeatureContentsWrapper = styled.div`
	background-color: #3F9F32;
	min-height: 500px;
	padding: 50px 0;
	margin-top: -3px;

	/* &::after {
		content: '';
	    position: absolute;
	    background-repeat: no-repeat;
	    background-image: url("/images/right-side-bitcoin.png");
	    width: 310px;
	    height: 475px;
	    right: 0;
	    bottom: 0;
	} */

	.content-title {
		color: #fff;
		text-align: center;
		padding: 20px;
		margin-bottom: 0;
	    font-size: 35px;
	}

	@media screen and (min-width: 320px) and (max-width: 1199px) {
		padding: 0;
	    margin-top: -5px;

		&::after {
			display: none;
		}

		.content-title {
			font-size: 30px;
		}
	}

	@media screen and (min-width: 1200px) and (max-width: 1440px) {
		padding: 15px;

		&::after {
			zoom: 0.7;
		}

		.content-title {
			font-size: 32px;
		}
	}
`

export const WavesEffect = styled.div`
	background: url('/images/waves.png');
	background-repeat: no-repeat;
	/* background-size: 100%; */
	min-height: 80px;
	width: 100%;
	position: relative;

	@media screen and (min-width: 320px) and (max-width: 1199px) {
		background-size: cover;
		background-position: bottom;
		height: 100%;
	}
`

export const Guarantee = styled.div`
	background: url(/images/guarantee.png);
	background-repeat: no-repeat;
	width: 100%;
	position: absolute;
	background-size: 47%;
	top: -38px;
	bottom: 0;
	background-position: center;
	height: 173px;

	@media screen and (min-width: 320px) and (max-width: 766px) {
		background-size: 100%;
	}

	@media screen and (min-width: 767px) and (max-width: 1023px) {
		background-size: 80%;
	}

	&.payment {
		height: 225px;
		top: -61px;
		background-size: 32%;

		@media screen and (min-width: 320px) and (max-width: 766px) {
			background-size: 100%;
		}
	
		@media screen and (min-width: 767px) and (max-width: 1023px) {
			background-size: 80%;
		}

		@media screen and (min-width: 1024px) and (max-width: 1199px) {
			background-size: 45%;
		}
	}
`

export const OnboardingStyle = styled.div`
	font-family: Montserrat, sans-serif;
	height: 100%;

	.container {
		&.default-container {
			height: 100%;
		}
	}

	.custom-section {
	    margin-bottom: 25px;

		.card {
			border: none;
			background: #eaeaea;

			.fa-ul {
				.fa-li {
					&.fa-check {
						color: #0fca74;
					}
				}

				.text-muted {
					color: #8c87878c !important;
				}
			}

			&.popular {
				background: #fff;
				box-shadow: 0px 1px 20px 0px rgba(0,0,0,0.2);
			    min-height: 475px;
				margin-bottom: 25px;

				.price-seperator {
					border: 1px solid #0000002b;
				}

				&.monthly-yearly-card {
					h4 {
						font-size: 55px;
					    color: #03a84e;
					    height: 100px;
					    display: flex;
					    justify-content: center;
					    align-items: center;
					    flex-direction: column;
					}

					.price-subtext {
						height: auto;
						display: flex;
						flex-direction: column;
						justify-content: center;
						padding-bottom: 30px;

						.btn-wrapper {
							margin-bottom: 15px;
						}

						.additional-info {
							font-size: 13px;

							span {
								margin-top: 2px;
								color: #202B39;
								font-weight: 400;
							}

							.btn-link {
								img {	
									margin-top: 2px;
									border-radius: 6px;
								}
							}
						}
					}

					.yearly-subtext {
						margin-top: 24px;
					}

					.monthly-description {
						height: 140px;
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						font-size: 18px;
						padding-top: 15px;
					}
					.yearly-description {
						height: 140px;
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						padding-top: 15px;
					}

					.monthly-yearly-title {
						font-size: 23px;
						height: 110px;
						line-height: 110px;
						background: #F7F7F7;
					}
				}

				&.quarterly-card {
					border-color: rgb(25, 156, 9);
  					box-shadow: rgba(36, 134, 24, 0.84) 0px 0px 20px 5px;
					overflow: hidden;

					h4 {
						font-size: 55px;
						color: #03a84e;
					}

					.price-subtext {
						height: auto;
						display: flex;
						flex-direction: column;
						justify-content: center;
						padding-bottom: 30px;

						.btn-wrapper {
							margin-bottom: 15px;
						}

						.additional-info {
							font-size: 13px;

							.btn-link {
								img {	
									margin-top: 2px;
									border-radius: 8px;
								}
							}
						}
					}

					.quarterly-description {
						height: 140px;
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						padding-top: 15px;

						.text-green {
							color: #56A250;
							font-weight: 600;
						}
					}

					.saving-ribbon {
						transform: rotate(44deg);
						width: 210px;
						color: #fff;
						font-size: 13px;
						position: absolute;
						right: -62px;
						top: 28px;
						background: #de2323;
						padding: 3px;

						.for-life {
							font-weight: 100;
						}
					}

					.quarterly-price {
						font-size: 55px;
						color: #03a84e;
						height: 100px;
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;
					}

					.fa-ul {
						.fa-li {
							color: #47da35;
						}
					}
				}

				.per-month {
					font-size: 16px;
					font-weight: bold;
					color: #3f9f32;
				}

				.plan-description {
					border: 1px solid #84828252;
					border-radius: 6px;
					padding: 12px;
					margin: 0px 16px;
				}

				.icon {
					font-size: 18px;
					color: #23A455;
					margin-right: 10px;
				}

				.description-list {
					list-style: none;
				    padding: 0;
				    font-size: 17px;
				    font-weight: 500;
					margin: 0;
				}
			}

			.most-popular-badge {
				font-size: 13px;
			    background: #3f9f32;
			    color: #fff;
			    border: 2px solid #fff;
			    font-weight: 500;
			    width: auto;
			}

			.popular-text {
				border-bottom: none;
				height: 110px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				background: #3F9F32;
				color: #fff;

				.qrter-title {
					font-size: 23px;
					display: block;
				}
			}

			.annual-save {
				font-size: 14px;
				text-decoration: underline;
			}

			section {
				p {
					//border-bottom: 1px solid rgba(210, 210, 210, 0.5);
					//padding: 22px;
					color: black;
					font-weight: 600;
				}

				h4 {
					font-size: 28px;
					font-weight: 600;
					color: #23A455;
				}

				small {
					color: #b1acac;
					font-size: 14px;
					font-weight: 500;
				}

				.btn {
					&.btn-warning {
						&.btn-select {
							font-size: 25px;
							font-weight: 500;
							color: #212529;
							background: #E9ECEF;
							-webkit-transition: transform .2s;
							transition: transform .2s;
							border: none;
							width: 200px;
							padding: 10px;

							&.btn-popular {
								color: #ffffff;
								background: #DC3545;
								/* padding: 15px; */

								&:hover {
									background: #F2283B;
								}
							}

							&:focus {
    							box-shadow: none;
							}
						}
					}
				}
			}

			.card-body {
				padding: 0;
			}
		}
	}

	.button-pay {
		background: #3f9f32;
		color: #fff;
		font-weight: 700;
		font-size: 18px;
		letter-spacing: 2px;
		padding: 8px 30px;
		margin-bottom: 25px;
		webkit-transition: -webkit-transform .2s;
		-webkit-transition: transform .2s;
		transition: transform .2s;
		text-transform: uppercase;
		overflow: hidden;

		&.not-avail {
			background: #ab1934;
			opacity: 0.5;

			&:hover {
				transform: none;
				-webkit-transform: none;
			}
		}

		@media ${device.mobileS} {
			width: 100%;
			padding: 12px 0;
		}

		@media ${device.mobileM} {
			margin-bottom: 30px;
		}

		@media ${device.mobileSM} {
			font-size: 20px;
		}

		@media ${device.mobileR} {
			margin-bottom: 25px;
		}

		@media ${device.tablet} {
			width: auto !important;
			padding: 8px 30px;
		}

		@media ${device.laptop} {
			&:hover {
				color: #fff;
				-webkit-transform: scale(1.3);
				-ms-transform: scale(1.3);
				transform: scale(1.3);
				backface-visibility: hidden;
			}
		}
	}

	.button-pay:not(:disabled):not(.disabled) {
		cursor: pointer;
	}

	.premium-item {
		border-top-left-radius: 0.25rem !important;
		border-bottom-left-radius: 0.25rem !important;
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
		background: #fff;
		box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);

		.card-body {
			flex: 1 1 auto;
			padding: 1.25rem;
		}
	}

	.center {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	.card {
		position: relative;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		min-width: 0;
		word-wrap: break-word;
		background-color: transparent;
		background-clip: border-box;
		border: 1px solid rgba(0,0,0,.125);
		border-radius: .25rem;
	}

	.custom-card {
		padding: 20px;
		border: none;
		margin-bottom: 25px;

		/* @media ${device.mobileS} {
			width: 89%;
			max-height: 1100px;
		}
		@media ${device.mobileSM} {
			max-height: unset;
			padding: 0px;
		}
		@media ${device.tablet} {
			width: 100% !important;
			margin-bottom: 0;
			padding: 20px
		}
		@media ${device.tabletXL} {
			width: 100% !important;
			padding: 0px;
		}
		@media ${device.laptopL} {
			padding: 20px;
		} */

		.card-body {
			padding: 0;

			.premium-item-body {
				@media ${device.tablet} {
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
				}
			}
		}
	}

	.welcome {
		display: flex;
	    justify-content: center;
	    align-items: center;
		border: 1px solid #00000014;
	    background: #fff;
	    box-shadow: 0 0 10px 2px #0000000f;
		min-height: 325px;

		.card-body {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		@media ${device.mobileS} {
			width: 89%;
		}
		@media ${device.mobileSM} {

		}
		@media ${device.tablet} {
			width: 60% !important;
		}
	}

	.refund-content-wrapper {
		padding: 0 100px;

		@media screen and (min-width: 320px) and (max-width: 414px) {
			padding: 0;

			.partner-stamp {
				width: 50%;
			}
		}
	}

	.pre-flight-wrapper {
		display: flex;
		flex-direction: row;
		background-color: #fff;
		border-radius: 8px;
		box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
		margin: 60px auto;
		overflow: hidden;

		.thumbnail {
			width: 38%;

			img {
				width: 100%;
			}
		}

		.pre-flight-content {
			width: 62%;
			padding: 25px 30px 25px 40px;

			h4 {
				font-size: 20px;
			}

			p {
				font-size: 16px;
				margin-bottom: 8px;
			}

			ul {
				list-style-type: none;
				padding: 0;
				margin: 0;

				li {
					font-size: 14px;
					margin-bottom: 8px;

					i {
						margin-right: 10px;
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		@media screen and (min-width: 320px) and (max-width: 414px) {			
			flex-direction: column;
			margin-top: 30px;
			margin-bottom: 30px;

			.thumbnail,
			.pre-flight-content {
				width: 100%;
			}
		}
	}

	&.payment-complete {
	    display: flex;
		justify-content: center;
		align-items: center;
	}

	&.registration-complete {
		display: flex;
    	justify-content: center;
    	align-items: center;
	}

	@media screen and (min-width: 320px) and (max-width: 1023px) {
		padding: 0;
	}

	@media screen and (min-width: 1024px) and (max-width: 1199px) {
		padding: 15px;
	}

	@media screen and (min-width: 1200px) and (max-width: 1440px) {
		padding: 15px;
	}
`

export const BodyFeatures = styled.div`
	&.onLocked {
		${FeatureContentsWrapper} {
		    padding: 0 25px 25px;

			&::after {
				zoom: 0.7;

				@media screen and (min-width: 1200px) and (max-width: 1440px) {
					zoom: 0.6;
				}
			}
		}
	}
`

export const TitleWrapper = styled.div`
	text-align: center;

	h2 {
		&.pf-header {
			text-transform: none;
			font-size: 48px;
			font-weight: 600;
			color: #000;

			@media screen and (min-width: 320px) and (max-width: 1023px) {
				font-size: 20px;
			}

			@media screen and (min-width: 1024px) and (max-width: 1199px) {
				font-size: 30px;
			}
		}

		text-transform: uppercase;
		color: #3f9f32;
		font-weight: 700;
		margin-bottom: 15px;
		font-size: 2rem;


		@media ${device.mobileS} {
			font-size: 20px;
		}

		@media ${device.mobileM} {
			&.registration {
				font-size: 23.5px;
			}
		}

		@media ${device.mobileMS} {
			font-size: 30px;
		}

		@media ${device.mobileL} {
			font-size: 2rem;
		}

		@media ${device.tablet} {
			&.registration {
				font-size: 1.35rem;
			}
		}

		@media ${device.tabletL} {
			&.registration {
				font-size: 1.8rem;
			}
		}
	}

	p {
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 0;

		&.pf-subheader {
			color: #000;
			font-size: 22px;
			margin-bottom: 50px;
			overflow: hidden;
  			text-align: center;

			&:before {
  				right: 0.5em;
  				margin-left: -50%;
			}

			&:after {
				left: 0.5em;
  				margin-right: -50%;
			}

			&:before,
			&:after {
				background-color: #00000038;
			  	content: "";
			  	display: inline-block;
			  	height: 1px;
			  	position: relative;
			  	vertical-align: middle;
			  	width: 50%;
			}

			@media screen and (min-width: 320px) and (max-width: 1023px) {
				font-size: 16px;
				margin-bottom: 20px;

				&:before,
				&:after {
					display: none;
				}
			}

			@media screen and (min-width: 1024px) and (max-width: 1199px) {
				font-size: 20px;
			}
		}

		@media screen and (min-width: 320px) and (max-width: 1023px) {
			font-size: 14px;
			padding: 0 10px;
		}
	}
`

export const FormWrapper = styled.div`
	padding: 15px;

	@media screen and (min-width: 320px) and (max-width: 1023px) {
		padding: 0 !important;
	}
`
