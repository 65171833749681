import { Route, Switch } from 'react-router-dom'
import { ROOT, routes, pages } from './CONSTANTS'
import PrivateRoute from './PrivateRoute'
import { NotFound } from './NotFound'
import pageContainers from '../pages'
import { 
    SignInPage, 
    EmailVerificationPage,
    Initialize } from '../pages/Authentication'
import { TrialOfferPage } from '../pages/Sales/TrialOffer'
import { UpgradeOfferPage } from '../pages/Sales/UpgradeOffer'
import { SetupAccountPage } from '../pages/Onboarding'
import { DashBoardPage } from '../pages/Dashboard'
import { MessageBoardLayout } from '../pages/Messages'

export const RouterConfig = () => {
    return (
        <Switch>
            <Route exact path={routes.authentication.oldLoginUrl} component={SignInPage} />
            <Route path={routes.authentication.loginWithReturnUrl} component={SignInPage} />
            <Route exact path={routes.authentication.register} component={pageContainers.SignUpContainer} />
            <Route exact path={routes.authentication.verifyEmail} component={EmailVerificationPage} />
            <Route exact path={routes.authentication.getStarted} component={pageContainers.GetStartedContainer} />
            <Route exact path={routes.authentication.forgotPassword} component={pageContainers.ForgotPasswordContainer} />
            <Route exact path={routes.authentication.forgotPasswordConfirmation} component={pageContainers.ForgotPasswordConfirmationContainer} />
            <Route exact path={routes.authentication.resetPassword} component={pageContainers.ResetPasswordContainer} />
            <Route exact path={routes.authentication.resetPasswordConfirmation} component={pageContainers.ResetPasswordConfirmation} />
            <Route exact path={routes.authentication.autoLogin} component={pageContainers.AutoLoginContainer} />
            <Route exact path={routes.authentication.tradeAuto} component={pageContainers.TradeAutoContainer} />
            <Route exact path={routes.moonbag} component={pageContainers.MoonbagContainer} />
            <Route exact path={routes.authentication.changeEmail} component={pageContainers.ChangeEmailContainer} />

            <Route exact path={routes.pricing.subscriptionPlans} component={pageContainers.PaymentContainer} />

            <Route exact path={routes.payment.page} component={pageContainers.PaymentContainer} />
            <Route exact path={routes.payment.complete} component={pageContainers.PaymentCompleteContainer} />

            <PrivateRoute exact path={ROOT} component={Initialize} />
            <PrivateRoute exact path={routes.offers.oneTime} component={TrialOfferPage} />
            <PrivateRoute exact path={routes.account.getStarted} component={SetupAccountPage} />
            <PrivateRoute exact path={routes.dashboard} component={DashBoardPage} title="Dashboard" />
            <PrivateRoute exact path={routes.subscriptions.pro} component={UpgradeOfferPage} />
            <PrivateRoute exact path={routes.signals} component={pageContainers.LayoutContainer} page={pages.signals} title="Signals"/>
            <PrivateRoute exact path={routes.notification} component={pageContainers.LayoutContainer} page={pages.notification} />
            <PrivateRoute exact path={routes.messages.board} component={MessageBoardLayout} title="Message Board" />
            <PrivateRoute exact path={routes.wallet} component={pageContainers.LayoutContainer} page={pages.wallet} />
            <PrivateRoute exact path={routes.statistic} component={pageContainers.LayoutContainer} page={pages.statistic} />
            <PrivateRoute exact path={routes.scanner.priceActionScanner} component={pageContainers.LayoutContainer} page={pages.scanner.priceActionScanner} />
            <PrivateRoute exact path={routes.scanner.rsiScanner} component={pageContainers.LayoutContainer} page={pages.scanner.rsiScanner} />
            <PrivateRoute exact path={routes.orderHistory} component={pageContainers.LayoutContainer} page={pages.orderHistory} />
            <PrivateRoute exact path={routes.setting.profile} component={pageContainers.LayoutContainer} page={pages.setting.profile} />
            <PrivateRoute exact path={routes.setting.subscription} component={pageContainers.LayoutContainer} page={pages.setting.subscription} />
            <PrivateRoute exact path={routes.setting.autoTrade} component={pageContainers.LayoutContainer} page={pages.setting.autoTrade} />
            <PrivateRoute exact path={routes.setting.notificationSettings} component={pageContainers.LayoutContainer} page={pages.setting.notificationSettings} />
            
            <Route component={NotFound} />
        </Switch>
    )
}
