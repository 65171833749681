
import React from 'react'
import { connect } from 'react-redux'
import jQuery from 'jquery'
import _ from 'lodash'
import { Form, Col } from 'react-bootstrap'
import { Form as FinalForm, Field } from 'react-final-form'
import newFormDecorator from 'final-form-focus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { TextButton, PrimaryButton } from '../../styles'
import { FormBodyContent, FormActions } from './'
import { onboardingScreen } from '../../constants/onboardingScreen'
import { onboardingActions } from '../../redux/actions'
import { isNull, isNotNull, onSetNewState } from '../../utils'
import { listOfOccupation } from '../../utils/data'
import { onboardingState } from '../../constants/onboardingState'

class FinancialDetails extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sourceOfIncome: '',
            occupation: '',
            annualNetIncome: '',
            sourceOfIncomeOptions: [{
                value: 'EMPLOYED',
                text: 'Employed'
            }, {
                value: 'SELF_EMPLOYED',
                text: 'Self-employed (no company)'
            }, {
                value: 'COMPANY_DIRECTOR_OR_OWNER',
                text: 'Company Director/Owner'
            }, {
                value: 'CONTRACTOR',
                text: 'Contractor'
            }, {
                value: 'INVESTMENTS_OR_RETIRED',
                text: 'Investments/Retired'
            }],
            annualIncomeRanges: [{
                value: '0_TO_10K',
                text: '$0 - $10,000'
            }, {
                value: '10K_TO_50K',
                text: '$10,000 - $50,000'
            }, {
                value: '50K_TO_100K',
                text: '$50,000 - $100,000'
            }, {
                value: '100K_TO_200K',
                text: '$100,000 - $200,000'
            }, {
                value: '200K_TO_500K',
                text: '$200,000 - $500,000'
            }, {
                value: '500K_AND_ABOVE',
                text: '$500,000+'
            }],
            isSubmitting: false,
            data: null
        }
        this.baseState = this.state
        this.mounted = false
        this.formDecorator = newFormDecorator()
    }

    componentDidMount() {
        this.mounted = true
        const { data } = this.props

        if (isNotNull(data.annualNetIncome)) {
            onSetNewState(this, { annualNetIncome: data.annualNetIncome})
        }

        if (isNotNull(data.occupation)) {
            onSetNewState(this, { occupation: data.occupation })
        }

        if (isNotNull(data.sourceOfIncome)) {
            onSetNewState(this, { sourceOfIncome: data.sourceOfIncome})
        }

        window.scrollTo(0, 0)
        jQuery('.focus-on').select()
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { saveOnboardingUserFinancialDetails, eventHandlers } = this.props
            const { isSubmitting, data } = this.state
            
            if (prevProps.saveOnboardingUserFinancialDetails !== saveOnboardingUserFinancialDetails && 
                !saveOnboardingUserFinancialDetails.loading && 
                isSubmitting && 
                isNotNull(data)) {
                    const { result } = saveOnboardingUserFinancialDetails
                    if (isNotNull(result)) {
                        if (result.newState === onboardingState.ADJUST_TRADING_BALANCE) {
                            onSetNewState(this, {
                                isSubmitting: false
                            }, () => {
                                if (isNotNull(eventHandlers)) {
                                    eventHandlers.navigateTo(data, onboardingScreen.ADJUST_TRADING_BALANCE)
                                }
                            })
                        // } else if (result.newState === onboardingState.ENTER_EXCHANGE_KEYS) {
                        //     onSetNewState(this, {
                        //         isSubmitting: false
                        //     }, () => {
                        //         if (isNotNull(eventHandlers)) {
                        //             eventHandlers.navigateTo(data, onboardingScreen.ENTER_EXCHANGE_KEYS)
                        //         }
                        //     })
                        } else {
                            onSetNewState(this, {
                                isSubmitting: false
                            }, () => {
                                if (isNotNull(eventHandlers)) {
                                    eventHandlers.navigateTo(data, onboardingScreen.LOGIN_BINANCE_ACCOUNT)
                                }
                            })
                        }
                    }
                }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    handleSelectSourceOfIncome = (e) => {
        e.preventDefault()

        onSetNewState(this, {
            sourceOfIncome: e.target.value
        })
    }

    handleSelectOccupation = (e) => {
        e.preventDefault()

        onSetNewState(this, {
            occupation: e.target.value
        })
    }

    handleSelectAnnualIncome = (e) => {
        e.preventDefault()

        onSetNewState(this, {
            annualNetIncome: e.target.value
        })
    }

    onSubmit = (values) => {
        const { isSubmitting, sourceOfIncome, occupation, annualNetIncome } = this.state
        if (!isSubmitting) {            
            const { dispatch } = this.props
            let data = {
                sourceOfIncome,
                occupation,
                annualNetIncome
            }
            dispatch(onboardingActions.saveFinancialDetails(data))
            onSetNewState(this, {
                isSubmitting: true,
                data
            })
        }
    }

    onValidate = (values) => {
        const errors = {}
        
        // validate source of income
        if (isNull(values.sourceOfIncome))
            errors.sourceOfIncome = "Please select a source of income"
        else {
            onSetNewState(this, {
                sourceOfIncome: values.sourceOfIncome
            })
        }

        // validate occupation
        if (isNull(values.occupation))
            errors.occupation = "Please select an occupation"
        else {
            onSetNewState(this, {
                occupation: values.occupation
            })
        }

        // validate annual net income
        if (isNull(values.annualNetIncome) || (isNotNull(values.annualNetIncome) && 
            (values.annualNetIncome === '0' || values.annualNetIncome === 0)))
            errors.annualNetIncome = "Please select a range"
        else {
            onSetNewState(this, {
                annualNetIncome: values.annualNetIncome
            })
        }

        return errors
    }

    handleClickBack = (e) => {
        e.preventDefault()
        
        const { isSubmitting } = this.state
        if (!isSubmitting) {            
            const { eventHandlers } = this.props
            if (isNotNull(eventHandlers)) {
                eventHandlers.navigateTo(null, onboardingScreen.TRADING_EXP)
            }
        }
    }

    renderSourceOfIncomeOptions = () => {
        const { sourceOfIncome, sourceOfIncomeOptions } = this.state
        let mappedSourceOfIncome = []
        if (isNotNull(sourceOfIncomeOptions)) {
            mappedSourceOfIncome = _.map(sourceOfIncomeOptions, (value, key) => {
                return (
                    <option 
                        key={key}
                        value={value.value}>{value.text}</option>
                )
            })
        }

        return (
            <Form.Control 
                as="select" 
                className='focus-on'
                value={sourceOfIncome} 
                onChange={(e) => this.handleSelectSourceOfIncome(e)}>
                <option value="">-- select --</option>
                { mappedSourceOfIncome }
            </Form.Control>
        )
    }

    renderOccupationOptions = () => {
        const { occupation } = this.state
        let mappedOccupationGroups = []
        if (isNotNull(listOfOccupation)) {
            mappedOccupationGroups = _.map(listOfOccupation, (value, key) => {
                let mappedOccupations =_.map(value.groupItems, (itemValue, itemKey) => {
                    return (
                        <option 
                            key={itemKey} 
                            value={`o-${itemValue.id}`}>{`- ${itemValue.name}`}</option>
                    )
                })
                
                return (
                    <optgroup key={key} label={`${value.groupName}:`}>
                        {mappedOccupations}
                    </optgroup>
                )
            })
        }
        
        return (
            <Form.Control 
                as="select" 
                value={occupation} 
                onChange={(e) => this.handleSelectOccupation(e)}>
                <option value="">-- select --</option>
                { mappedOccupationGroups }
            </Form.Control>
        )
    }

    renderAnnualIncomeRangers = () => {
        const { annualNetIncome, annualIncomeRanges } = this.state
        let mappedAnnualIncomeRanges = []
        if (isNotNull(annualIncomeRanges)) {
            mappedAnnualIncomeRanges = _.map(annualIncomeRanges, (value, key) => {
                return (
                    <option
                        key={key} 
                        value={value.value}>{value.text}</option>
                )
            })
        }

        return (
            <Form.Control 
                as="select" 
                value={annualNetIncome} 
                onChange={(e) => this.handleSelectAnnualIncome(e)}>
                <option value="">-- select --</option>
                { mappedAnnualIncomeRanges }
            </Form.Control>
        )
    }

    renderLoader = () => (
        <>
            <div style={{ width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="pf-spinner btn"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        </> 
    )

    render() {
        let { ...state } = this.state

        return (
            <FinalForm 
                onSubmit={this.onSubmit} 
                validate={this.onValidate} 
                decorators={[this.formDecorator]} 
                initialValues={{...state}} 
                render={({ handleSubmit }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <FormBodyContent className='financial-details-content'>
                                <div className='graphic financial-details'>
                                    {/* <img src='../images/form-graphics/onboarding-financial-details.png' /> */}
                                </div>

                                <div className='form'>
                                    <h2 className='form-title'>Your finances</h2>
                                    <h3 className='form-instruction'>Tell us a little about your finances</h3>

                                    <Form.Row className='align-items-start'>
                                        <Form.Group as={Col} xs={12} sm={12} md={6} lg={6} controlId="sourceOfIncome">
                                            <Form.Label>Source of income<span className='required'>*</span></Form.Label>
                                            <Field name="sourceOfIncome" type="text">
                                                {({ input, meta }) => (
                                                    <>
                                                        <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                                            { this.renderSourceOfIncomeOptions() }
                                                            { meta.error && meta.touched && 
                                                                <Form.Text>
                                                                    {meta.error}
                                                                </Form.Text>
                                                            }
                                                        </div>
                                                    </>
                                                )}
                                            </Field>
                                        </Form.Group>

                                        <Form.Group as={Col} xs={12} sm={12} md={6} lg={6} controlId="occupation">
                                            <Form.Label>Occupation<span className='required'>*</span></Form.Label>
                                            <Field name="occupation" type="text">
                                                {({ input, meta }) => (
                                                    <>
                                                        <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                                            { this.renderOccupationOptions() }
                                                            { meta.error && meta.touched && 
                                                                <Form.Text>
                                                                    {meta.error}
                                                                </Form.Text>
                                                            }
                                                        </div>
                                                    </>
                                                )}
                                            </Field>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Group controlId="annualNetIncome">
                                        <Col xs={12} sm={12} md={6} lg={6} className="p-0">
                                            <Form.Label>Your net income per year<span className='required'>*</span></Form.Label>
                                            <Field name="annualNetIncome" type="text">
                                                {({ input, meta }) => (
                                                    <>
                                                        <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                                            { this.renderAnnualIncomeRangers() }
                                                            { meta.error && meta.touched && 
                                                                <Form.Text>
                                                                    {meta.error}
                                                                </Form.Text>
                                                            }
                                                        </div>
                                                    </>
                                                )}
                                            </Field>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group className='mb-0 notice-wrapper'>
                                        <p className='notice'>
                                            <FontAwesomeIcon size="xs" className='icon' icon={faExclamationCircle} /> 
                                            <span>Your privacy is our priority. All answers are confidential and we do not share with 3rd parties.<br />
                                            Your answers will help us send you the right education and information about our platform.</span>
                                        </p>
                                    </Form.Group>
                                </div>
                            </FormBodyContent>

                            <FormActions>
                                <TextButton 
                                    textColor="#8A9BAE"
                                    size="sm" 
                                    className='default-btn back-btn'
                                    onClick={(e) => this.handleClickBack(e)} 
                                    disabled={state.isSubmitting}>
                                        <FontAwesomeIcon size="xs" className='icon' icon={faArrowLeft} /> 
                                        <span>Back</span>
                                </TextButton>

                                <PrimaryButton 
                                    type="submit" 
                                    size="sm">
                                        { state.isSubmitting && this.renderLoader() }
                                        { !state.isSubmitting && 
                                            <>
                                                <FontAwesomeIcon size="xs" className='icon' icon={faArrowRight} /> 
                                                <span>Next</span>
                                            </>
                                        }
                                </PrimaryButton>
                            </FormActions> 
                        </Form>
                    )
                }} />
        )
    }
}

function mapStateToProps(state) {
    let { saveOnboardingUserFinancialDetails } = state
    return {
        saveOnboardingUserFinancialDetails
    }
}

const connectedFinancialDetails = connect(mapStateToProps)(FinancialDetails)
export { connectedFinancialDetails as FinancialDetails }