import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { 
    MessagesPageLayout, 
    TopNavBar, 
    MainSidebar, 
    MessagesInnerContent } from '../../styles'
import { 
    Bell, 
    LoggedInUser, 
    TerminalLoginButton,  
    HelpLinks, 
    NotificationPermissionAlert, 
    ScrollToTopBtn, 
    MessageBoardContainer,
    StatisticsMenuLink,
    ScannerMenuLinks,
    BlueprintMenuLink,
    MessageBoardMenuLink } from '../../components'
import { mediaDeviceSizes } from '../../constants/mediaQueries'
import { addPageViewEvent, isNotNull, seo, getSize, isSupported } from '../../utils'

export const MessageBoardLayout = (props) => {
    let history = props.history
    let [device, setDevice] = useState(null)
    let [sidebarPane, setSidebarVisibility] = useState(null)
    const [isTokenFound, setTokenFound] = useState(null)
    const [searchBarWidth, setSearchBarWidth] = useState(undefined)
    const [searchBarTop, setSearchBarTop] = useState(undefined)
    const [showTopBtn, setShowTopBtn] = useState(false)
    
    useEffect(() => {
        seo({
            title: "Message Board",
            metaDescription: ""
        })
        addPageViewEvent()

        document.body.classList.remove('offers')
        document.body.classList.remove('onboarding')
        document.body.classList.remove('upgrade-to-pro')
        document.body.classList.remove('inner-pages')
        document.body.classList.add('messages')
        
        let deviceSize = getSize(window.innerWidth)
        setDevice(deviceSize)

        if (deviceSize === mediaDeviceSizes.xs || deviceSize === mediaDeviceSizes.sm || device === mediaDeviceSizes.lg)
            setSidebarVisibility(false)
        else
            setSidebarVisibility(true)

        const searchBarEl = document.querySelector('.filters-bar').getBoundingClientRect()
        setSearchBarWidth(searchBarEl.width)
        setSearchBarTop(searchBarEl.top)

        if (deviceSize === mediaDeviceSizes.xxl) {
            window.addEventListener('scroll', () => {
                if (window.scrollY > 600) {
                    setShowTopBtn(true)
                } else {
                    setShowTopBtn(false)
                }
            })
        }
    }, [])

    useEffect(() => {
        if (!searchBarTop) return        
        
        window.addEventListener('scroll', isSticky)
        return () => window.removeEventListener('scroll', isSticky)
    }, [searchBarTop])

    const toggleSidebar = (e) => {
        let newState = !sidebarPane
        setSidebarVisibility(newState)
    }

    const isSticky = (e) => {
        const searchBarEl = document.querySelector('.filters-bar')        
        const boundingClientRect = document.querySelector('.filters-bar').getBoundingClientRect()
        const scrollTop = window.scrollY

        if (scrollTop >= searchBarTop - 10) {
            setSearchBarWidth(1175)
            searchBarEl.classList.add('is-sticky')
        } else {
            setSearchBarWidth('100%')
            searchBarEl.classList.remove('is-sticky')
        }
    }

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    return (
        <>
            { isSupported() && isNotNull(isTokenFound) && !isTokenFound && 
                <NotificationPermissionAlert />
            }

            <MessagesPageLayout 
                id="message-page-wrapper"
                className={`${sidebarPane && (device === mediaDeviceSizes.xs || device === mediaDeviceSizes.sm || device === mediaDeviceSizes.md || device === mediaDeviceSizes.lg) ? 'show': ''} ${isNotNull(isTokenFound) && !isTokenFound ? ' notif-permission' : ''}`}>
                    <div className="back-drop" />

                    <TopNavBar  className={ isNotNull(isTokenFound) && !isTokenFound ? 'has-notif-permission' : '' }>
                        { (device === mediaDeviceSizes.xs || device === mediaDeviceSizes.sm || device === mediaDeviceSizes.md || device === mediaDeviceSizes.lg) && 
                            <button 
                                type="button" 
                                className='sidebar-toggle-btn'
                                onClick={(e) => toggleSidebar(e)} >
                                <FontAwesomeIcon icon={faBars} />
                            </button>
                        }

                        <NavLink to={`/dashboard`} className='brand'>
                            <img src='/images/brand/pf-logo.png' alt="ProfitFarmers Logo" /> 
                        </NavLink>

                        <div className='nav-menu-wrapper'>
                            <Bell 
                                isTokenFound={isTokenFound} 
                                setTokenFound={setTokenFound}
                                history={history} />

                            <LoggedInUser />
                        </div>
                    </TopNavBar>

                    <MainSidebar className={`${ sidebarPane && (device === mediaDeviceSizes.xs || device === mediaDeviceSizes.sm || device === mediaDeviceSizes.md || device === mediaDeviceSizes.lg) ? 'show': ''} ${isNotNull(isTokenFound) && !isTokenFound ? ' has-notif-permission' : ''}`}>
                        <ul className='sidebar-menu-list'>
                            <li className='sidebar-menu-item'>
                                <NavLink to="/dashboard" className="menu-item-link">
                                    <span className='menu-item-link-icon'><i className='fas fa-th-large'></i></span>
                                    <span className='menu-item-link-text'>Dashboard</span>
                                </NavLink>
                            </li>
                            <li className='sidebar-menu-item'>
                                <div className='sidebar-menu-title'>
                                    <span className='menu-title-text'>Tools</span>
                                </div>
                                <ul className='sidebar-submenu-list'>
                                    <li className='sidebar-submenu-item'>
                                        <NavLink to="/signals" className="sidebar-submenu-item-link">
                                            <span className='submenu-item-link-icon'><i className='fas fa-seedling'></i></span>
                                            <span className='submenu-item-link-text'>Signals</span>
                                        </NavLink>                                
                                    </li>

                                    <li className='sidebar-submenu-item'>
                                        <TerminalLoginButton />
                                    </li>

                                    <ScannerMenuLinks />
                                    <li className='sidebar-submenu-item'>
                                        <BlueprintMenuLink />
                                    </li>
                                    <MessageBoardMenuLink />
                                </ul>
                            </li>
                            <li className='sidebar-menu-item'>
                                <div className='sidebar-menu-title'>
                                    <span className='menu-title-text'>Trade History</span>
                                </div>
                                <ul className='sidebar-submenu-list'>
                                    <li className='sidebar-submenu-item'>
                                        <NavLink to="/orders" className="sidebar-submenu-item-link">
                                            <span className='submenu-item-link-icon'><i className='fas fa-file-invoice'></i></span>
                                            <span className='submenu-item-link-text'>Trade Orders</span>
                                        </NavLink>                                
                                    </li>
                                    
                                    <StatisticsMenuLink />
                                </ul>
                            </li>
                            <li className='sidebar-menu-item'>
                                <div className='sidebar-menu-title'>
                                    <span className='menu-title-text'>Help Centre</span>
                                </div>
                                
                                <HelpLinks />
                            </li>
                        </ul>
                    </MainSidebar>

                    <MessagesInnerContent>
                        <MessageBoardContainer
                            searchBarWidth={searchBarWidth} />
                    </MessagesInnerContent>

                    { device === mediaDeviceSizes.xxl && showTopBtn && 
                        <ScrollToTopBtn eventHandlers={goToTop} />
                    }                              
            </MessagesPageLayout>
        </>
    )
}