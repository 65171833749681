import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const TradeDoctorSessionStyle = styled.div`
    position: relative;    
    background-image: url('/images/trade-doctor/td-session-bg.jpg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    border-radius: 10px;
    padding: 30px;
    color: #0F0F0F;

    .btn-exit {
        position: absolute;
        right: 10px;
        top: 10px;
        display: inline-block;
        width: 30px;
        height: 30px;
        background: none;
        outline: none;
        border: 2px solid #0F0F0F;
        border-radius: 15px;
        box-shadow: none;
        background-color: transparent;
        color: #0F0F0F;
        text-align: center;

        svg {
            margin-bottom: -1px;
        }
    }

    .session-details-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .trade-doc {
            width: 137px;
            margin: 0 auto 25px;

            img {
                width: 100%;
            }
        }
        
        .session-details {
            width: 100%;
            padding-left: 0;

            h2 {
                font-size: 24px;
                font-weight: 800;
                max-width: 100%;
                border-bottom: 1px solid #3E3E3E;
                padding-bottom: 15px;
                margin-bottom: 15px;
                text-align: center;
            }

            p {
                font-weight: 600;
                text-align: center;
            }

            .session-cover {
                border: 1px solid #202020;
                padding: 10px;
                border-radius: 6px;
                margin: 0 0 15px 0;

                h5 {
                    font-size: 14px;
                    font-weight: 700;
                }
                
                .session-agenda {
                    margin: 0;
                    padding: 0 0 0 5px;
                    list-style-type: none;

                    li {
                        display: flex;
                        align-items: flex-start;
                        line-height: 1.8;

                        svg {
                            font-size: 10px;
                            margin-right: 4px;
                            margin-top: 7px;
                        }

                        span {
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }
                }
            }

            .session-actions {
                display: flex;
                flex-direction: column;
                align-items: center;
                button {
                    background: none;
                    background-color: #141414;
                    border: none;
                    outline: none;
                    box-shadow: none;
                    color: #fff;
                    padding: 8px 16px;
                    margin-bottom: 10px;
                }

                .form-group {
                    margin-bottom: 0;

                    .form-check {
                        .form-check-input {
                            margin-top: 6px;
                        }

                        label {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        .session-details-wrapper {
            flex-direction: row;

            .trade-doc {
                width: 257px;
            }

            .session-details {
                width: calc(100% - 257px);
                padding-left: 25px;

                h2 {
                    font-size: 38px;
                    max-width: 340px;
                }
            }
        }
    }
`