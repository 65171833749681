import React from 'react'
import ReactPlayer from 'react-player/lazy'
import { TradeGuardianSectionStyle } from './styles'
import { isNull, isNotNull } from '../../../utils'

export const TradeGuardianSection = (props) => {
    return(
        <TradeGuardianSectionStyle 
            className={`${props.page === 'upgrade' && isNull(props.withinTrial) ? 'up-w-trial': ''} ${props.page === 'upgrade' && isNotNull(props.withinTrial) && props.withinTrial ? 'up-win-trial': ''}`}>
            <div className='container'>
                <div className='hot-feature'>                    
                    <img src='/images/trial-offer/hot-feature.png' alt='' />
                </div>
                <div className='text-with-vid'>
                    <div className='video-wrapper'>
                        <ReactPlayer 
                            url={'https://player.vimeo.com/video/689546842'}
                            controls={true} 
                            width='100%' 
                            volume={0} />
                    </div>
                    <div className='text-col'>
                        <h2 className='section-title'>TRADE GUARDIAN</h2>
                        <p><span className='white-text'>Reclaim your sleep and your sanity.</span></p>
                        <p>Activating Trade Guardian asks your trading co-pilot to <span className='white-text'>automatically exit any live trades if out system issues an early warning.</span> spread throughout the entry zone.</p>
                        <p>Here are just some of the benefits:</p>

                        <ul className='benefits-list'>
                            <li className='list-item'>Sleep peacefully - No need to wake up in the middle of the night</li>
                            <li className='list-item'>Increase time away from the charts - Get on with your day!</li>
                            <li className='list-item'>Decrease your loss size by exiting before stop-loss is triggered</li>
                            <li className='list-item'>Improve your mood and performance with a full sleep cycle</li>
                        </ul>
                    </div>
                </div>
            </div>
        </TradeGuardianSectionStyle>
    )
}