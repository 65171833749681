import { useEffect, useState } from 'react'
import _ from 'lodash'
import { Skeleton } from '@material-ui/lab'
import { SafeAnchor } from 'react-bootstrap'
import {
    NotificaitonPageWrapper, NotificationPageContainer,
    NotificationPageHeader, NotificationPageBody,
    LoaderWrapper, NotificationContentWrapper,
    NotificationCard, CoinsImg, NotificationContentContainer,
    NotificationContent, NotificationTime, UnReadBadge, OrderStateIcon,
    NotificationContentLists
} from './styled_components'
import { isNotNull, setCoinLogo, setDefaultImage, getCoin, getSpecificHistoryTime } from '../../utils'

export const NotificationView = (props) => {
    const { markedAllAsReadData, currentSet, isMarked } = props.state
    const { loader, notification_lists } = props.notificationLists
    let notificationLists = []
    const preloaderData = [0, 1, 2, 3, 4, 5, 6, 7]

    const [hasItems, setItemsStatus] = useState(null)

    const onHandleMarkAsRead = (data, e) => {
        e.preventDefault()
        if (isNotNull(data)) {
            const { actorId, isRead, type } = data

            // handle marked as read notifications
            if (isNotNull(isRead) && isRead === false)
                props.handleRead(data, e)

            // redirect to dashboard and view notification
            if (isNotNull(actorId) && isNotNull(type))
                props.handleRedirect(data)
        }
    }  

    const renderIcon = (type, symbol) => {
        if (isNotNull(type)) {
            switch (type) {
                default: {
                    let logoSrc = getCoin(symbol)
                    let imgSrc = setCoinLogo(logoSrc)
                    return (<CoinsImg src={imgSrc} className={logoSrc} icon={logoSrc} onError={setDefaultImage} />)
                }
                case 5: {
                    return (
                        <OrderStateIcon className="automation">
                            <i className="fas fa-bolt"></i>
                        </OrderStateIcon>
                    )
                }
                case 6: {
                    return (
                        <OrderStateIcon className="success">
                            <i className="fas fa-check"></i>
                        </OrderStateIcon>
                    )
                }
                case 7: {
                    return (
                        <OrderStateIcon className="failed">
                            <i className="fas fa-exclamation"></i>
                        </OrderStateIcon>
                    )
                }
            }
        }
    }

    let preloader = _.map(preloaderData, (val, key) => {
        return (
            <LoaderWrapper key={key}>
                <Skeleton variant="rect" style={{
                    width: '100%',
                    height: '100%',
                    backgroundImage: 'linear-gradient(15deg,#293442,#293442)',
                    border: '1px solid #293442',
                    borderRadius: '8px'
                }} className="skeleton" />
            </LoaderWrapper>
        )
    })

    if (!loader && isNotNull(notification_lists) && isNotNull(notification_lists.data)) {
        let orderLists = _.orderBy(notification_lists.data, o => new Date(o.dateCreated), 'desc')
        let filter = _.filter(orderLists, (val, key) => key <= currentSet)
        notificationLists = _.map(filter, (value, keys) => {
            if (isNotNull(value)) {
                let keyNum = value.id + keys + value.actorId
                let date = getSpecificHistoryTime(value.dateCreated)
                return (
                    <NotificationContentWrapper key={keyNum}>
                        <NotificationCard unRead={value.isRead} onClick={(e) => onHandleMarkAsRead(value, e)} className={`all ${value.isRead} notification-card`}>
                            {renderIcon(value.type, value.symbol)}
                            <NotificationContentContainer>
                                {/* <NotificationTitle>{notif_title}</NotificationTitle> */}
                                <NotificationContent>{value.message}</NotificationContent>
                                <NotificationTime unRead={value.isRead}>
                                    {date}
                                </NotificationTime>
                            </NotificationContentContainer>
                            <UnReadBadge unRead={value.isRead} />
                        </NotificationCard>
                    </NotificationContentWrapper>
                )
            } else
                return null
        })
    }  

    useEffect(() => {
        if (isNotNull(notification_lists) && isNotNull(notification_lists.data) && notification_lists.data.length > 0)
            setItemsStatus(true)
        else
            setItemsStatus(false)
    }, [])

    return (
        <NotificaitonPageWrapper className="notification-page-wrapper">
            <NotificationPageContainer>
                <NotificationPageHeader className="notification-header">
                    <h3>Your Notifications</h3>
                    { isNotNull(hasItems) && hasItems && 
                        <>
                            { isMarked === false
                                ? <SafeAnchor href="#" disabled={loader === false ? false : true} onClick={(e) => props.handleRead(markedAllAsReadData, e)}>Mark All as Read</SafeAnchor>
                                : <i className="fas fa-spinner fa-pulse"></i>
                            }
                        </>
                    }
                </NotificationPageHeader>

                <NotificationPageBody className="notification-body">
                    {
                        !loader &&
                        <>
                            {notificationLists.length > 0 &&
                                <div className="notif-title">
                                    <h6>Earlier</h6>
                                </div>
                            }
                        </>
                    }

                    <NotificationContentLists className="notification-page-list">
                        { loader &&
                            <>{preloader}</>
                        }

                        { !loader &&
                            <>
                                {notificationLists}
                            </>
                        }

                        { !loader && isNotNull(hasItems) && !hasItems && 
                            <p style={{ padding: '15px 0', textAlign: 'center' }}>You have no notifications right now. Come back later.</p>
                        }
                    </NotificationContentLists>
                </NotificationPageBody>
            </NotificationPageContainer>
        </NotificaitonPageWrapper>
    )
}