import styled from 'styled-components';

const getMoveColor = (move) => {
    switch (move) {
        case "down":
            return "#EB2436";
        default:
            return "#01bf86";
    }
}

export const PreloaderWatch = styled.div`
    position: absolute;
    width: 27px;
    height: 20px;
    right: -40px;
    display:none;
`

export const PreloaderItem = styled.div`
    height: 60px;
    width: 100%;
    padding: 10px 20px;
    padding-right: 0px;

    &.header {
        padding: 0;
        height: 30px;
    }
`

export const PreloaderLogo = styled.div`
    width: 55px;
    height: 50px;

    @media(max-width: 414px) {
        width: 45px;
        height: 40px;
    }
`

export const Preloader = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`

export const PreloaderWrapper = styled.div`
    background: #192431;
    // padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;

    padding: 10px 20px 0;
    &:last-child {
        padding-bottom: 20px;
    }

    ${Preloader} {
        margin-bottom: 10px;
        position: relative;

        &:last-child{
            margin-bottom: 0;
        }

    }
`

export const ErrorMessage = styled.div`
    font-size: 20px;
    padding-bottom: 5px;
    text-align: center;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        font-size: 15px;
    }

    @media (max-width: 414px) {
        font-size: 12px;
    }

`

export const MoreInfoValue = styled.span`
    font-weight: 600;

    &.move-data {
        color: ${props => getMoveColor(props.move)};
    }

    &.d3-data {
        color: ${props => props.color};
    }
`

export const MoreInfoTitle = styled.span`

`

export const SectionBlockInner = styled.div`
    max-width: 1348px;
    margin: 0 auto;

    @media (max-width: 414px) {
        padding-top: 20px !important;
    }
`

export const SectionHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: 414px) {
        flex-direction: column;
    }

    @media screen and (min-width: 320px) and (max-width: 1023px) {
        display: block;
    }
`

export const SectionTitle = styled.h2`
    font-size: 33px;
    font-weight: 500;
    margin-bottom: 0;
    display: flex;
    align-items: center;

    .icon {
        font-size: 12px;
        color: #15bd6f;
        margin-right: 10px;
    }

    @media (max-width: 765px) {
        font-size: 22px;
        margin-bottom: 15px;
    }

    @media screen and (min-width: 320px) and (max-width: 1023px) {
        margin-bottom: 20px;
    }

    .information {
        font-size: 16px;
        margin-left: 10px;
        cursor: pointer;
    }
`

export const MoreInfo = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0c1117;
    padding: 7px 15px;

    &:last-child {
        border-bottom: none;
    }
`

export const MoreInfoWrapper = styled.div`
    display: block;
    padding: 5px;
`

export const GridBlock = styled.div`
    width: 100%;
    padding: 2px;

    &.collapse {
        color: #ced0dd;
        &:not(.show) {
            display: none;
            visibility: hidden;
        }

        &.show {
            visibility: visible;
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 4000px) {
        display: none !important;
    }
`

export const CoinLogo = styled.div`
    margin-top: -21px;
    margin-left: 0;
    margin-right: 10px;
    width: 25px;
    height: 25px;

    img {
        border-radius: 100%;
        width: 100%;
    }
`

export const CoinName = styled.label`
    display: flex;
    margin-bottom: 0;
    color: #fff;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
        word-break: break-all;
    }
`

export const CoinInfoContainer = styled.div`
    display: flex;
    align-items: center;

`

export const Wave = styled.span`
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    text-align: center;
    margin: 0 auto 2px;
    border: 3px solid #10161d;
    vertical-align: middle;
    position: relative;
    overflow: hidden;

    &.big-drop {
        &.filled {
            background-color: #e1112a;
            box-shadow: 0px 0px 1px 2px #e1112a;
        }

        &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-color: #e1112a transparent;
            border-width: 0 6px 6px 0;
            border-style: solid;
            bottom: -5px;
            left: -5px;
            transform: rotate(0deg);
        }

        &::after {
            content: "";
            position: absolute;
            width: 25px;
            height: 25px;
            top: -3px;
            left: -3px;
            border-radius: 50%;
            box-shadow: 0 0 2px #e71b2e, 0 0 1px #e1112a, 0 0 1px #ea4c5f, 0 0 0px #ff4123, 0 0 10px #e1112a, 0 0 22px #e1112a, 0 0 1px #e1112a, 0 0 1px #ff3e56;
        }
    }

    &.small-drop {
        &.filled {
            background-color: #ff7474;
            box-shadow: 0px 0px 1px 2px #ff7474;
        }
    }

    &.neutral {
        &.filled {
            background-color: #323232;
            box-shadow: 0px 0px 1px 2px #323232;
        }
    }

    &.small-raise {
        &.filled {
            background-color: #c4da50;
            box-shadow: 0px 0px 1px 2px #c4da50;
        }
    }

    &.big-raise {
        &.filled {
            background-color: #00d100;
            box-shadow: 0px 0px 1px 2px #00d100;
        }

        &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-color: transparent #00d100;
            border-width: 0 6px 6px 0;
            border-style: solid;
            top: -5px;
            right: -5px;
            transform: rotate(0deg);
        }

        &::after {
            content: "";
            position: absolute;
            width: 25px;
            height: 25px;
            top: -3px;
            left: -3px;
            border-radius: 50%;
            box-shadow: 0 0 2px #00d100, 0 0 1px #00d100, 0 0 1px #00d100, 0 0 0px #00d100, 0 0 10px #00d100, 0 0 22px #00d100, 0 0 1px #00d100, 0 0 1px #00d100;
        }
    }

    &:after {
        animation: shine 5s ease-in-out  infinite;
        animation-fill-mode: forwards;
        content: "";
        position: absolute;
        top: -110%;
        left: -210%;
        width: 200%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);

        background: rgba(255, 255, 255, 0.13);
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.13) 0%,
          rgba(255, 255, 255, 0.13) 77%,
          rgba(255, 255, 255, 0.5) 92%,
          rgba(255, 255, 255, 0.0) 100%
        );
    }
`

export const WaveInfoContainer = styled.div`
    min-height: 42px;
    line-height: 42px;
`

export const SignalLiveFibo = styled.div`

`

export const SignalPeakGains = styled.div`
    span {
        &.move-data {
            color: ${props => getMoveColor(props.move)};
        }
    }
`

export const SignalMarketCap = styled.div`
    span {
        &.d3-data {
            color: ${props => props.color};
        }
    }
`

export const SignalVol = styled.div`

`

export const SignalPrice = styled.div`

`

export const ToggleButton = styled.button`
    background: none;
    width: 100%;
    height: 50px;
    border: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    color: #2f3c4c;
    font-size: 18px;
    line-height: 31px;
    margin-top: 0;
    padding: 0 3px;
    outline: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`

export const SignalI = styled.div`
    line-height: 42px;
    /* width: 50px; */
    width: 5%;

    &.hidden-m {
        display: none;
    }
`

export const SignalN = styled.div`
    width: 180px;
    padding: 0 15px;
`

export const DataActions = styled.div`
    &.has-btns {
        min-width: 50px;
    }
`

export const DataSignals = styled.div`
    position: relative;
    display: flex;
    /* width: 100%; */
    width: calc(100% - 4%);
    border: 2px solid transparent;
    margin-bottom: 10px;

    &.normal,
    &.strong,
    &.stronggreen,
    &.lightgreen,
    &.strongred,
    &.lightred,
    &.weak {
        border-radius: 30px;
        cursor: pointer;
        background-color: #0d1117;
        transition: all ease .4s;

        &:hover {
            background-color: #000;
        }
    }

    &.normal {
        border: 2px solid #63d242;
        box-shadow: inset 0 0 2px #17b7f8, inset 0 0 1px #3462e1, inset 0 0 1px #17b7f8, inset 0 0 0px #17b7f8, inset 0 0 1px #3462e1, 0 0 2px #3462e1, 0 0 1px #3462e1, 0 0 1px #17b7f8;
    }

    &.stronggreen,
    &.strong {
        box-shadow: inset 0 0 2px #0fd70f, inset 0 0 1px #0fd70f, inset 0 0 1px #0fd70f, inset 0 0 0px #0fd70f, inset 0 0 1px #0fd70f, 0 0 2px #0fd70f, 0 0 1px #0fd70f, 0 0 1px #0fd70f;
        background-color: #002b00;
    }

    &.lightgreen{
        box-shadow: inset 0 0 2px #439242, inset 0 0 1px #439242, inset 0 0 1px #439242, inset 0 0 0px #439242, inset 0 0 1px #439242, 0 0 2px #439242, 0 0 1px #439242, 0 0 1px #439242;
        background-color: #001000;
    }

    &.strongred,
    &.weak {
        box-shadow: inset 0 0 2px #ef0707, inset 0 0 1px #ef0707, inset 0 0 1px #ef0707, inset 0 0 0px #ef0707, inset 0 0 1px #ef0707, 0 0 2px #ef0707, 0 0 1px #ef0707, 0 0 1px #ef0707;
        background-color: #290000;
    }

    &.lightred {
        box-shadow: inset 0 0 2px #921f1f, inset 0 0 1px #921f1f, inset 0 0 1px #921f1f, inset 0 0 0px #921f1f, inset 0 0 1px #921f1f, 0 0 2px #921f1f, 0 0 1px #921f1f, 0 0 1px #921f1f;
        background-color: #080000;
    }

    @media screen and (min-width: 1200px) and (max-width: 1399px) {
        .h {
            font-size: 12px;
        }
    }

`

export const DataPreActions = styled.div`
    display: none;
    padding: 0 5px;
`

export const LoaderText = styled.span`
    font-size: 25px;
`

export const LoaderInnerWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`

export const LoaderWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: #000000eb;
    z-index: 62;
    left: 0;
    border-radius: 50px;
    display: none;

    &.show {
        display: block;
    }
`


export const DataSignalGrid = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    /* background-color: #19212b; */
    background-color: #192431;
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;

    .grid-col {
        padding: 2px;
        text-align: center;
    }

    ${SignalPrice},
    ${SignalVol},
    ${SignalMarketCap},
    ${SignalPeakGains},
    ${SignalLiveFibo} {
        /* width: 130px; */
        width: 10%;
        line-height: 42px;
    }

    &.grid-h {
        font-size: 15px;
        font-weight: 700;

        ${DataPreActions} {
            display: none;
        }

        ${DataSignals} {
            ${SignalN} {
                text-align: left;
                width: 184px;
                line-height: 30px;
            }

            ${SignalI} {
                line-height: 30px;
            }

            &:hover {
                border: 2px solid transparent;
                box-shadow: none;
            }
        }

        ${SignalPrice},
        ${SignalVol},
        ${SignalMarketCap},
        ${SignalPeakGains},
        ${SignalLiveFibo} {
            line-height: 30px;
        }
    }

`


export const DataSignalGridWrapper = styled.div`
    background-color: #192431;

    .watch-active {
        color: #8fb248;
    }

    .h {
        cursor: pointer;

        span {
            margin-right: 3px;
        }

        svg {
            font-size: 12px;
            display: none;

            &.show {
                display: inline-block;
            }

            @media(max-width: 414px) {
                font-size: 8px;
            }
        }



        &:hover {
            color: #3462e1;
        }
    }

    &.price-grid {
        ${DataSignalGrid} {
            ${DataSignals} {

                ${SignalI} {
                    width: 53px;

                    &.hidden-m {
                        display: block;
                    }
                }

                ${SignalN} {
                    width: 177px;
                    overflow: hidden;
                }

                ${SignalPrice},
                ${SignalVol},
                ${SignalMarketCap} {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin-left: 5px;

                    &:hover {
                        text-overflow: clip;
                        white-space: normal;
                        word-break: break-all;
                    }
                }

                .ele-clone {
                    height: 1px;
                    overflow: hidden;
                    white-space: nowrap;
                    position: fixed;
                }
            }
        }
    }

    &.rsi-grid {
        ${DataSignalGrid} {
            ${DataSignals} {
                ${SignalI} {
                    width: 20%;

                    &.border-red {
                        border-color: #e13434;
                        box-shadow: inset 0 0 2px #e71b2e, inset 0 0 1px #e1112a, inset 0 0 1px #ea4c5f, inset 0 0 0px #ff4123, inset 0 0 10px #e1112a, 0 0 22px #e1112a, 0 0 1px #e1112a, 0 0 1px #ff3e56;
                    }

                    &.border-blue {
                        border-color: #3462e1;
                        box-shadow: inset 0 0 2px #17b7f8, inset 0 0 1px #3462e1, inset 0 0 1px #17b7f8, inset 0 0 0px #17b7f8, inset 0 0 10px #3462e1, 0 0 22px #3462e1, 0 0 1px #3462e1, 0 0 1px #17b7f8;
                    }
                }

                ${SignalN},
                ${SignalI} {
                    border-bottom: 2px solid transparent;
                }

                @media(min-width: 1024px) {
                    ${SignalN} {
                        width: 18%;
                    }

                    ${SignalI} {
                        width: 5%;

                        &.hidden-m {
                            display: block;
                        }
                    }

                    ${SignalPrice},
                    ${SignalVol} {
                        overflow: hidden;
                        margin-left: 5px;
                    }

                    ${SignalPrice},
                    ${SignalVol},
                    ${SignalMarketCap},
                    ${SignalPeakGains},
                    ${SignalLiveFibo} {
                        display: block;
                        width: 10%;
                        line-height: 46px;
                    }


                }
            }

            ${SignalPrice},
            ${SignalVol},
            ${SignalMarketCap},
            ${SignalPeakGains},
            ${SignalLiveFibo} {
                display: none;
            }

            @media (min-width: 1024px) {

                &.grid-h {
                    ${DataSignals} {
                        ${SignalN} {
                            width: 18%;
                        }
                    }

                    ${SignalPrice},
                    ${SignalVol},
                    ${SignalMarketCap},
                    ${SignalPeakGains},
                    ${SignalLiveFibo} {
                        width: 10%;
                        line-height: 30px;
                    }
                }
            }
        }
    }

    &::last-child {
        ${DataSignalGrid} {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 320px) and (max-width: 1023px) {
        &.price-grid {
            ${DataSignalGrid} {
                ${DataPreActions} {
                    display: flex;
                    width: 10%;

                    ${ToggleButton} {
                        display:block;
                    }
                }
                ${DataSignals} {
                    width: 96%;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                    &.h {
                        width: 100%;
                    }

                    ${SignalN}, ${SignalI} {
                        font-size: 10px;
                    }

                    ${SignalN} {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                        width: 10%;

                        &.h {
                            width: 24%;
                        }

                        ${WaveInfoContainer} {
                            min-height: 42px;
                            line-height: 42px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;

                            ${CoinInfoContainer} {

                                ${CoinLogo} {
                                    width: 25px;
                                    height: 30px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin-top: -2px;
                                    margin-right: 0;
                                }

                                ${CoinName} {
                                    display: none;
                                }
                            }
                        }
                    }

                    ${SignalI} {
                        width: 10%;

                        &.h {
                            width: 13%
                        }

                        ${WaveInfoContainer} {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            ${Wave} {
                                width: 22px;
                                height: 22px;
                            }
                        }
                    }

                    ${SignalPrice},
                    ${SignalVol},
                    ${SignalMarketCap},
                    ${SignalPeakGains},
                    ${SignalLiveFibo} {
                        display: none;
                    }
                }

                ${DataActions} {
                    position: relative;

                    &.pa-m {
                        display: none;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1334px) {
        &.price-grid {
            ${DataSignalGrid} {
                
                ${DataSignals} {
                    width: 96%;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                    &.h {
                        width: 95%;
                    }

                    ${SignalPrice},
                    ${SignalVol},
                    ${SignalMarketCap},
                    ${SignalPeakGains},
                    ${SignalLiveFibo},
                    ${SignalN}, 
                    ${SignalI} {
                        font-size: 12px;
                    }

                    ${SignalN} {
                        width: 22%;
                    }

                    ${SignalI} {
                        width: 5%;
                    }
                }

                ${DataActions} {
                    width: 5%;
                }
            }
        }
    }

    @media screen and (min-width: 1335px) and (max-width: 4000px) {
        &.price-grid {
            ${DataSignalGrid} {
                
                ${DataSignals} {
                    width: 95%;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                    &.h {
                        width: 95%;
                    }

                    ${SignalPrice},
                    ${SignalVol},
                    ${SignalMarketCap},
                    ${SignalPeakGains},
                    ${SignalLiveFibo},
                    ${SignalN}, 
                    ${SignalI} {
                        font-size: 14px;
                    }

                    ${SignalN} {
                        width: 18%;
                    }

                    ${SignalI} {
                        width: 5%;
                    }
                }

                ${DataActions} {
                    width: 5%;
                }
            }
        }
    }

    @media (max-width: 768px) {
        &.rsi-grid {
            ${DataSignalGrid} {
                position: relative;
                ${DataPreActions} {
                    display:flex;
                    ${ToggleButton} {
                        display:block;
                    }
                }

                ${DataSignals} {
                    position: relative;
                    color: #ced0dd;

                    ${SignalN} {
                        width: 59px;
                        font-size: 13px;
                        padding: 0;

                        ${WaveInfoContainer} {
                            position: relative;
                            display: flex;

                            ${CoinInfoContainer} {

                                ${CoinName} {
                                    display: none;
                                }
                            }
                        }

                        &.rsi-m-c {
                            width: 90px;
                            text-align: center;
                        }

                        &.sn-m {
                            width: 50px;
                        }
                    }

                    ${SignalI} {
                        position: relative;
                        width: 20%;
                        font-size: 15px;

                        &.hidden-m {
                            display: none;
                        }

                        &.rsi-m {
                            font-size: 13px;
                        }
                    }

                    ${SignalPrice},
                    ${SignalVol},
                    ${SignalMarketCap},
                    ${SignalPeakGains},
                    ${SignalLiveFibo} {
                        position: relative;
                        display: none;
                    }
                }

                ${DataActions} {
                    position: relative;

                    &.pa-m {
                        display: none;
                    }
                }
            }
        }
    }

`
