import { PAscannerActionTypes, RSIscannerActionTypes } from '../CONSTANTS'
import { scannerServices } from '../../services'

//#region Price Action Scanner
function getPriceAction() {
    return dispatch => {
        dispatch(request())

        scannerServices.getPriceAction().then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: PAscannerActionTypes.GET_ALL_REQUEST } }
    function success(price_actions) { return { type: PAscannerActionTypes.GET_ALL_SUCCESS, price_actions } }
    function failure(error) { return { type: PAscannerActionTypes.GET_ALL_FAILURE, error } }
}

function getWatchLists() {
    return dispatch => {
        dispatch(request())

        scannerServices.getWatchLists().then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: PAscannerActionTypes.GET_WATCHLISTS_REQUEST } }
    function success(watch_lists) { return { type: PAscannerActionTypes.GET_WATCHLISTS_SUCCESS, watch_lists } }
    function failure(error) { return { type: PAscannerActionTypes.GET_WATCHLISTS_FAILURE, error } }
}

function saveWatchlist(pairedCoin) {
    return dispatch => {
        dispatch(request())

        scannerServices.saveWatchlist(pairedCoin).then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: PAscannerActionTypes.SAVE_WATCHLIST_REQUEST } }
    function success(response) { return { type: PAscannerActionTypes.SAVE_WATCHLIST_SUCCESS, response } }
    function failure(error) { return { type: PAscannerActionTypes.SAVE_WATCHLIST_FAILURE, error } }
}

function getTradeGuide(data) {
    return dispatch => {
        dispatch(request())

        scannerServices.getTradeGuide(data).then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: PAscannerActionTypes.GET_TRADEGUIDE_REQUEST } }
    function success(response) { return { type: PAscannerActionTypes.GET_TRADEGUIDE_SUCCESS, response } }
    function failure(error) { return { type: PAscannerActionTypes.GET_TRADEGUIDE_FAILURE, error } }
}

function updatePriceAction(price_actions) {
    return dispatch => {
        dispatch({ type: PAscannerActionTypes.GET_ALL_SUCCESS, price_actions });
    }
}

function updateWatch(watch_lists) {
    return dispatch => {
        dispatch({ type: PAscannerActionTypes.GET_WATCHLISTS_SUCCESS, watch_lists });
    }
}
//#endregion

//#region Relative Strength Index Scanner
function getRSI() {
    return dispatch => {
        dispatch(request());

        scannerServices.getRSI().then(response => {
            dispatch(success(response));
        }, error => {
            dispatch(failure(error));
        })
    }

    function request() { return { type: RSIscannerActionTypes.GET_ALLITEMS_REQUEST } }
    function success(get_relative_strengths) { return { type: RSIscannerActionTypes.GET_ALLITEMS_SUCCESS, get_relative_strengths } }
    function failure(error) { return { type: RSIscannerActionTypes.GET_ALLITEMS_FAILURE, error } }
}

function updateRSI(get_relative_strengths) {
    return dispatch => {
        dispatch({ type: RSIscannerActionTypes.GET_ALLITEMS_SUCCESS, get_relative_strengths });
    }
}
//#endregion

export const scannerActions = {
    getPriceAction,
    getWatchLists,
    saveWatchlist,
    getTradeGuide,
    updatePriceAction,
    updateWatch,
    getRSI,
    updateRSI
}