import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const ThreeSixFiveDaysSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    padding: 50px 10px 40px;
    background-color: #0B1219;
    background-image: url(/images/trial-offer/introsection-bg.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        color: #fff;

        .section-title {
            display: block;
            width: 100%;
            color: #fff;
            text-align: center;
            font-weight: 700;
            font-size: 28px;
            max-width: 340px;

            .crashed-out-price {
                color: #516475;
                position: relative;
                font-size: 36px;

                &:before {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 45%;
                    right: 0;
                    border-top: 3px solid;
                    border-color: #FF3030;
                    -webkit-transform: rotate(-18deg);
                    -moz-transform: rotate(-18deg);
                    -ms-transform: rotate(-18deg);
                    -o-transform: rotate(-18deg);
                    transform: rotate(-18deg);
                } 
            }

            .new-price {
                color: #E93459;
            }
        }

        .lc-tag {
            background-color: #FE2944;
            padding: 8px 25px;
            font-size: 16px;
        }

        p {
            text-align: center;
            font-size: 15px;

            &.countr-txt {
                font-size: 25px;
                margin-bottom: 0;
            }

            .daily-rate {
                color: #16BD70;
            }
        }

        .three-easy-steps {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 10px auto 0;
            padding: 0;
            list-style-type: none;
            width: 100%;

            .easy-step {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                position: relative;
                background-color: #32514A;
                border: 1px solid #9AB9D5;
                border-top-left-radius: 25px;
                border-bottom-right-radius: 25px;
                padding: 20px;
                width: 100%;
                height: auto;
                text-align: center;
                margin-right: 0;
                margin-bottom: 15px;

                .icon {
                    position: relative;
                    top: unset;
                    width: 74px;

                    img {
                        width: 100%;
                    }
                }

                .desc {
                    margin-top: 0;
                    font-size: 18px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .offer-validity-timer {
            ul {
                margin: 30px 0 40px;
                padding: 0;
                list-style-type: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                li {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 0 15px;
                    color: #FFFFFF;
                    line-height: 1;

                    .counter {
                        font-size: 60px;
                        font-weight: 600;
                    }

                    small {
                        font-size: 12px;
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        padding: 80px 10px 40px;

        .container {
            .section-title {
                font-size: 42px;
                max-width: 100%;
            }

            p {
                text-align: left;
            }

            .three-easy-steps {
                margin: 60px auto 30px;
                flex-direction: row;

                .easy-step {
                    flex-direction: column;
                    justify-content: flex-start;
                    margin-right: 28px;
                    margin-bottom: 0;
                    width: 291px;
                    height: 266px;

                    .icon {
                        position: absolute;
                        top: -50px;
                        width: auto;
                    }

                    .desc {
                        margin-top: 130px;
                        font-size: 18px;
                    }
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        .container {
        }
    }

    ${mediaQueries.extraLargeDevices} {
        .container {
            .section-title {
                font-size: 42px;
            }

            p {
                font-size: 16px;
            }
        }
    }
`