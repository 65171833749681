import styled from 'styled-components'
import { mediaQueries } from '../../constants/mediaQueries'

export const MessagePreviewStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    display: block;

    .msg-header-photo-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-height: 380px;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    .msg-title {
        display: block;
        width: 100%;
        text-align: left;
        padding: 25px 20px;
        background-color: #e8eff5;

        small {
            font-size: 12px;
            letter-spacing: 1px;

            &.msg-ts {
                color: #637789;
            }

            &.msg-type {
                padding: 4px 8px;
                border-radius: 13px;
                font-weight: 500;
                text-transform: uppercase;
                text-shadow: 0 0 black;

                &.marketanalysis {
                    background: rgb(101 182 255 / 22%) 0% 0% no-repeat padding-box;
                    color: #4893D6;
                }

                &.general {
                    background: rgb(255 101 163 / 22%) 0% 0% no-repeat padding-box;
                    color: #FF65A3;
                }

                &.promotions {
                    background: rgb(23 180 109 / 22%) 0% 0% no-repeat padding-box;
                    color: #17B46D;
                }

                &.rssfeed {
                    background-color: #382814;
                    color: #f5ad57;
                }
            }
        }

        .dot {
            display: inline-block;
            width: 4px;
            height: 4px;
            margin: 0 10px;
            border-radius: 2px;
            background-color: #687f9b;
            vertical-align: middle;
        }

        h3 {
            display: flex;
            align-items: center;
            color: #000;
            font-size: 22px;
            font-weight: 600;
            line-height: 1.2;
        }

        p {
            font-size: 14px !important;
            color: #253542;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .msg-body-wrapper {
        padding-left: 15px;
        padding-right: 15px;
        color: #161f29;

        p {
            display: block;

            a {
                overflow-wrap: break-word;

                img {
                    height: auto;
                }
            }

            img {
                width: 100%;
            }

            iframe {
                width: 100% !important;
                height: 284px !important;
            }

            &:first-child {
                margin-top: 25px;
            }

            &:last-child {
                padding-bottom: 30px;
            }
        }

        ol {
            color: #161f29 !important;
        }
    }

    .message-preview {
        margin-right: 15px;
    }

    ${mediaQueries.mediumDevices} {
        .msg-body-wrapper {
            p {
                iframe {
                    height: 344px !important;
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        .msg-header-photo-container {
            max-height: 100%;
        }

        .msg-body-wrapper {
            padding-left: 25px;
            padding-right: 25px;

            p {
                iframe {
                    height: 471px !important;
                }
            }
        }
    }
`