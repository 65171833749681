import { MoreUserInfoStyle } from '../styles'
import { TradingExpForm } from '../../../components'

export const MoreUserInfo = (props) => {
    return (
        <MoreUserInfoStyle>
            <div className='info-form-wrapper'>
                <TradingExpForm exitForm={props.close} />
            </div>
        </MoreUserInfoStyle>
    )
}