import React from 'react'
import { connect } from 'react-redux'
import newFormDecorator from 'final-form-focus'
import { browserName, osName, osVersion } from "react-device-detect";
import { faEye } from "@fortawesome/free-solid-svg-icons"
import iziToast from 'izitoast'
import jQuery from 'jquery'
import { authActions } from '../../redux/actions'
import { LogInView } from './LogInView'
import { browserWindow, isNotNull, getDeviceType, isNull, onSetNewState, addPageViewEvent, seo } from '../../utils'

class LogInContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            userName: '',
            userPass: '',
            passwordInputType: 'password',
            showPasswordAsText: false,
            eyeIcon: faEye,
            passwordCriteria: {
                letter: false,
                capital: false,
                number: false,
                special: false,
                size: false
            },
            isToRememberMe: false
        }

        this.baseState = this.state
        this.initialPasswordHelperState = {
            letter: false,
            capital: false,
            number: false,
            special: false,
            size: false,
            match: false
        }
        this.mounted = false
        this.formDecorator = newFormDecorator()
    }

    componentDidMount() {
        this.mounted = true

        seo({
            title: "Log In",
            metaDescription: ""
        })
        addPageViewEvent()

        const { authentication } = this.props
        if (isNotNull(authentication) && authentication.loggedIn) {
            window.location.href = "/dashboard"
        }

        // make sure that the localStorage is empty
        //this.onClearLocalStorageHandler()
        this.onGetRememberedUserHandler()

        window.addEventListener('resize', () => browserWindow.adjustDimensions(this))
        jQuery('body').css('height', '100%')
    }

    componentDidUpdate(prevProps) {
        const { dispatch, authentication } = this.props
        if (isNotNull(authentication)) {
            if (prevProps.authentication !== authentication) {
                const { error, user, loggedIn } = authentication
                if (isNotNull(error)) {
                    iziToast.error({
                        position: 'topRight',
                        title: 'Error',
                        message: 'Wrong Username or Password',
                    })
                    dispatch(authActions.clearLoginError())
                }

                if (isNotNull(loggedIn) && loggedIn === true && isNotNull(user)) {
                    const { role, isEmailConfirmed, subscriptionId, isPaid, isLead, leadSource } = user
                    if (isNotNull(role) && role === 'Customer') {
                        if (!isPaid && isNull(subscriptionId)) {
                            // Set email error message if user not confirmed yet
                            if (!isEmailConfirmed)
                                localStorage.setItem('emailError', true)

                            // const accessToken = JSON.parse(localStorage.getItem('profitfarmers.token'))
                            // if (isNotNull(accessToken) && isNotNull(accessToken.access)) {
                            //     if (isLead) {
                            //         if (isNotNull(leadSource) && leadSource === 'moonbag')
                            //             window.location.href = `/dashboard`
                            //         else
                            //             window.location.href = `/subscription-plans?token=${accessToken.access.token}`
                            //     } else
                            //         window.location.href = `/subscription-plans?token=${accessToken.access.token}`
                            // }
                            if (isLead) {
                                if (isNotNull(leadSource) && leadSource === 'moonbag')
                                    window.location.href = `/dashboard`
                                else
                                    window.location.href = `/subscription-plans`
                            } else
                                window.location.href = `/subscription-plans`
                        } else {
                            if (!isEmailConfirmed)
                                localStorage.setItem('emailError', true)

                            window.location.href = `/dashboard`
                        }
                    }
                }
            }
        }
        jQuery('body').css('height', '100%')
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)
        window.removeEventListener('resize', () => browserWindow.adjustDimensions(this))
        this.mounted = false
    }

    onClearLocalStorageHandler = () => {
        localStorage.removeItem('rememberMe')
        localStorage.removeItem('user')
        localStorage.removeItem('profitfarmers.token')
        localStorage.removeItem('profitfarmers.user')
        localStorage.removeItem('profitfarmers.type')
        localStorage.removeItem('profitfarmers.sessionexpired')
        localStorage.removeItem('emailError')
    }

    onGetRememberedUserHandler = () => {
        const isToRememberMe = localStorage.getItem('rememberMe') === 'true';
        const username = localStorage.getItem('user')
        const userName = isToRememberMe ? username : isNotNull(username) ? username : ''
        onSetNewState(this, { userName, isToRememberMe })
    }

    onSubmitLoginHandler = (data) => {
        const { username, password } = data
        if (isNotNull(username) && isNotNull(password)) {
            onSetNewState(this, { userName: username, userPass: password })
            const { isToRememberMe } = this.state
            localStorage.setItem('rememberMe', isToRememberMe);
            localStorage.setItem('user', isToRememberMe ? username : '')

            const { dispatch } = this.props;
            const requestData = {
                username,
                password,
                deviceType: getDeviceType(),
                browser: `${browserName}`,
                platform: `${osName} ${osVersion}`,
                token: ""
            }
            dispatch(authActions.login(requestData));
        }
    }

    render() {
        const { loading } = this.props.authentication

        return <LogInView
            self={this}
            decorators={this.formDecorator}
            state={this.state}
            initialPasswordHelperState={this.initialPasswordHelperState}
            isLoading={loading}
            submit={this.onSubmitLoginHandler} />
    }
}

function mapStateToProps(state) {
    let { authentication, username } = state
    return {
        authentication,
        username
    }
}

const connectedLogInContainer = connect(mapStateToProps)(LogInContainer)
export { connectedLogInContainer as LogInContainer }
