import { TechnoTigerSectionStyle } from './styles'

export const TechnoTigerSection = () => {
    return (
        <TechnoTigerSectionStyle>
            <div className='container'>
                <div className='text-with-img-wrapper'>
                    <div className='text-col'>
                        <h2 className='section-title'>
                            <small>Final 5x Blueprint Lesson:</small><br />
                            <span><strong>Trade Like a Techno-Tiger</strong></span>
                        </h2>

                        <p>Dear 5x Blueprint Student,</p>
                        <p>Are you sick of eating trading scraps? Or not even eating at all?</p>
                        <p>Forced to humiliate yourself by celebrating 1%-2% gains here and there, and hiding your losses.</p>
                        <p>Pressuring yourself day after day to find good money making opportunities even when you know you should be sitting on your hands.</p>
                        <p>What if we told you that the answer is simply to look to nature for inspiration (no we're not joking, almost all great inventions are reflections of nature!):</p>
                    </div>
                    <div className='img-wrapper'>
                        <img src='/images/layout/trade-like-a-techno-tiger.png' alt='Trade Like a Techno-Tiger' />
                    </div>
                </div>

                <div className='tigers-vs-pro-traders'>
                    <div className='tigers-do'>
                        <div className='the-logo'>
                            <img src='/images/layout/tigers-logo.svg' alt='Tigers Logo' />
                        </div>
                        <ul className='do-list'>
                            <li><span>Only Hunt Big Prey</span></li>
                            <li><span>Forget the Scraps - Long-Term Mindset</span></li>
                            <li><span>Hunt in Ideal Conditions</span></li>
                            <li><span>Conserve Energy</span></li>
                            <li><span>Gorge on Meat When They Can</span></li>
                            <li><span>Formidable Tools to Find and Execute Their Prey</span></li>
                        </ul>
                    </div>
                    <div className='tigers-vs-pro-traders-divider'></div>
                    <div className='pro-traders-do'>
                        <div className='the-logo'>
                            <img src='/images/layout/pro-traders-logo.svg' alt='PF Pro Traders Logo' />
                        </div>
                        <ul className='do-list'>
                            <li><span>Typical Average Gains &gt;15-30% Per Win</span></li>
                            <li><span>Long-Term Trading Plan - No Daily Grind</span></li>
                            <li><span>Patiently Ignore the Noise Knowing They Can Wait for Updates</span></li>
                            <li><span>Conserve Trading Capital For the Most Profitable Explosions</span></li>
                            <li><span>Record Gains of +1500% in Trade 1</span></li>
                            <li><span>Algorithms Scanning 24/7, Expert Analysis, Copy-Trade and Automations</span></li>
                        </ul>

                    </div>
                </div>

                <div className='centered-text'>
                    <h4>Think about it.</h4>
                    <p>How much easier is it to hunt when you have insane technology like trader night visions, thermals, scanners and an expert team to back you up?</p>
                    <p>Why grind everyday wasting time, money and energy hunting rabbits when you could be stalking fat, juicy buffalo?</p>
                    <p>F**k eating scraps.</p>
                    <h3><strong>It's time to go full on Techno-Tiger.</strong></h3>
                </div>
            </div>
        </TechnoTigerSectionStyle>
    )
}