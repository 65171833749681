import { endpoints, baseUrl } from './CONSTANTS'
import { authServices } from './authService'
import { isNotNull, encryptText } from '../utils'
import { isNull } from 'lodash'

const { v3, account } = endpoints

async function getMe() {
    const requestOpts = {
        method: "GET",
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    try {
        const res = await fetch(`${baseUrl}${v3.user.account.details}`, requestOpts)
        const response = await handleResponse(res)
        if (response) {
            const userInfo = JSON.parse(localStorage.getItem('profitfarmers.user'))
            if (isNotNull(userInfo) && isNotNull(userInfo.userId))
                return Promise.resolve(response)

            // remove existing user info
            localStorage.removeItem('profitfarmers.user')
            // add new user info
            localStorage.setItem('profitfarmers.user', JSON.stringify(response))
            return Promise.resolve(response)
        }
        else
            return Promise.reject('Unknown error occured')
    } catch (err) {
        return Promise.reject(err)
    }
}

function getId() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    try {
        return fetch(`${baseUrl}${v3.user.account.accountId}`, requestOpts)
            .then(handleResponse)
            .then(res => Promise.resolve(res))
            .catch(err => {
                return Promise.reject(err)
            })

    } catch (err) {
        return Promise.reject(err)
    }
}

function getProfile() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    try {
        return fetch(`${baseUrl}${v3.user.account.profile}`, requestOpts)
            .then(handleResponse)
            .then(res => {
                if (res) {
                    return Promise.resolve(res)
                }
                else
                    return Promise.reject({ status: 'not ok' })
            })
            .catch(err => {
                return Promise.reject(err)
            })

    } catch (err) {
        return Promise.reject(err)
    }
}

function getProfileByToken(accessToken) {
    const requestOpts = {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${accessToken}` }
    }

    try {
        return fetch(`${baseUrl}${v3.user.account.profile}`, requestOpts)
            .then(handleResponse)
            .then(res => {
                if (res) {
                    return Promise.resolve(res)
                }
                else
                    return Promise.reject({ status: 'not ok' })
            })
            .catch(err => {
                return Promise.reject(err)
            })

    } catch (err) {
        return Promise.reject(err)
    }
}

function getProfileCompletionStatus() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    try {
        return fetch(`${baseUrl}${v3.user.account.profileCompletion}`, requestOpts)
            .then(handleResponse)
            .then(res => Promise.resolve(res))
            .catch(err => {
                return Promise.reject(err)
            })

    } catch (err) {
        return Promise.reject(err)
    }
}

function saveProfile(data, fileName) {
    var formData = new FormData()
    formData.append("image", data, fileName)

    const requestOpts = {
        method: 'POST',
        credentials: 'include',
        //headers: header.addAuthorizationHeaderFile(),
        body: formData
    }

    return fetch(`${baseUrl}${v3.user.account.addAvatar}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function editProfile(data) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        //headers: header.addAuthorizationHeader(),
        body: JSON.stringify(data)
    }
    return fetch(`${baseUrl}${v3.user.account.updateBasicInfo}`, requestOpts)
        .then(handleResponse)
        .then(response => Promise.resolve(response))
        .catch(error => {
            return Promise.reject(error)
        })
}

function changePassword(data) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        //headers: header.addAuthorizationHeader(),
        body: JSON.stringify(data)
    }
    return fetch(`${baseUrl}${v3.user.security.changePassword}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function checkEmail(email) {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    return fetch(`${baseUrl}${account.checkEmail(email)}`, requestOpts)
        .then(handleResponse)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject({ error }))
}

function register(data) {
    if (isNotNull(data.emailAddress))
        data.email = encryptText(data.emailAddress)

    if (isNotNull(data.password))
        data.password = encryptText(data.password)

    if (isNotNull(data.confirmPassword))
        data.confirmPassword = encryptText(data.confirmPassword)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    }

    return fetch(`${baseUrl}${v3.user.account.register}`, requestOptions)
        .then(handleResponse)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject({ error }))
}

function logout() {
    // remove user from local storage
    localStorage.removeItem('profitfarmers.user')

    // remove also the token from the local storage
    localStorage.removeItem('profitfarmers.token')

    // remove also the token from the local storage
    localStorage.removeItem('profitfarmers.type')

    // remove also the session from the local storage
    localStorage.removeItem('profitfarmers.sessionexpired')

    // remove email error
    localStorage.removeItem('emailError')

    const requestOpts = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include'
    }
    
    return fetch(`${baseUrl}${v3.auth.logout}`, requestOpts)
        .then(handleResponse)
        .then(res => {
            return Promise.resolve({ status: 'ok' })
        }).catch(error => {
            return Promise.reject({ error })
        })
}

function resendVerification(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //body: JSON.stringify(data)
    }

    return fetch(`${baseUrl}${v3.user.account.resendEmailVerificationLink}?email=${encodeURIComponent(data)}`, requestOptions)
        .then(handleResponse)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject({ error }))
}

function agreeToWeeklyDisclaimer() {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}${v3.user.agreements.agreeOnWeeklyDisclaimer}`, requestOptions)
            .then(handleResponse)
            .then(response => {
                return isNotNull(response.hasAgreedToWeeklyTerms) ?
                    resolve(response.hasAgreedToWeeklyTerms) :
                    reject(response)

            })
            .catch(error => reject(error))
    })
}

function loginAuto() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    //return fetch(`${baseUrl}${account.getMe}`, requestOptions)
    return fetch(`${baseUrl}${v3.user.account.details}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user) {                
                // remove existing user info
                localStorage.removeItem('profitfarmers.user')
                // add new user info
                localStorage.setItem('profitfarmers.user', JSON.stringify(user))

                return Promise.resolve(user)
            }
            else
                return Promise.reject({ status: 'not ok' })
        })
        .catch(error => Promise.reject(error))
}

function loginByToken(accessToken) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${accessToken}` }
    }

    return fetch(`${baseUrl}${v3.user.account.details}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user) {                
                // remove existing user info
                localStorage.removeItem('profitfarmers.user')
                // add new user info
                localStorage.setItem('profitfarmers.user', JSON.stringify(user))
                
                return Promise.resolve(user)
            }
            else
                return Promise.reject({ status: 'not ok' })
        })
        .catch(error => Promise.reject(error))
}

function updateAnalyticStep(step) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    return fetch(`${baseUrl}${account.updateAnalyticStep}?analytic=${step}`, requestOptions)
        .then(handleResponse)
        .then(response => {

            const { success } = response

            return isNotNull(success) && success ?
                Promise.resolve('ok') : Promise.reject('An error occured.')
        })
        .catch(error => Promise.reject(error))

}

function resetPassword(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(data)
    }
    return fetch(`${baseUrl}${v3.auth.resetPassword}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function changeEmail(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(data)
    }
    return fetch(`${baseUrl}${account.changeEmail}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        if (res.status === 401)
            authServices.logout()
        else
            return data
    })
}

export const userServices = {
    getMe,
    getId,
    getProfile,
    getProfileByToken,
    getProfileCompletionStatus,
    checkEmail,
    saveProfile,
    editProfile,
    changePassword,
    register,
    logout,
    resendVerification,
    agreeToWeeklyDisclaimer,
    loginAuto,
    loginByToken,
    updateAnalyticStep,
    resetPassword,
    changeEmail
}
