import React, { Component } from 'react'

export default function (ComposedComponent) {
    class NetworkDetector extends Component {
        state = {
            isDisconnected: false,
            hasConnectivityIssues: false
        }

        componentDidMount() {
            this.handleConnectionChange()
            window.addEventListener('online', this.handleConnectionChange)
            window.addEventListener('offline', this.handleConnectionChange)
        }

        componentWillUnmount() {
            window.removeEventListener('online', this.handleConnectionChange)
            window.removeEventListener('offline', this.handleConnectionChange)
        }

        handleConnectionChange = () => {
            const condition = navigator.onLine ? 'online' : 'offline'
            if (condition === 'online') {
                console.log('ONLINE')
                const webPing = setInterval(() => {
                    fetch('https://www.google.com/', {
                        mode: 'no-cors',
                    })
                    .then(() => {
                        this.setState({ 
                            isDisconnected: false, 
                            hasConnectivityIssues: false 
                        }, () => {
                            return clearInterval(webPing)
                        })
                    }).catch(() => this.setState({ hasConnectivityIssues: true }))
                }, 2000)

                return
            }

            return this.setState({ isDisconnected: true })
        }

        render() {
            const { isDisconnected, hasConnectivityIssues } = this.state
            return (
                <>
                    { isDisconnected && (
                        <div className="internet-error" style={{
                            position: 'fixed',
                            zIndex: 9999999999,
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100vh',
                            backgroundColor: '#142431',
                            textAlign: 'center',
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '60px'
                        }}>
                            <p>Internet connection lost</p>
                        </div>
                    )}
                    
                    { hasConnectivityIssues && (
                        <div className="internet-error" style={{
                            position: 'fixed',
                            zIndex: 9999999999,
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100vh',
                            backgroundColor: '#142431',
                            textAlign: 'center',
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '60px'
                        }}>
                            <p>Internet Connectivity Issue</p>
                        </div>
                    )}

                    { !isDisconnected && !hasConnectivityIssues && (
                        <ComposedComponent {...this.props} />
                    )}
                </>
            )
        }
    }

    return NetworkDetector
}