import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { TradeFormHeaderWrapper, TradeFormHeading, StrategySelector } from '../../styled_components'
import { onSetNewState, isNotNull, Dimension } from '../../../../utils'

export class TradeFormHeader extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTradeStrategy: ''
        }
        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        window.addEventListener('resize', () => Dimension.updateDimensions(this))
        this.mounted = true
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.mounted) {
            const { selectedTradeStrategy } = this.props

            if (prevState.selectedTradeStrategy !== selectedTradeStrategy) {
                onSetNewState(this, {
                    selectedTradeStrategy: selectedTradeStrategy
                })
            }
        }
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)
        window.removeEventListener('resize', () => Dimension.updateDimensions(this))
        this.mounted = false
    }

    onSelectTradeStrategyHandler = (e) => {
        e.stopPropagation()

        const { onSelectTradeStrategyHandler } = this.props
        const { value } = e.target

        if (isNotNull(value)) {
            onSetNewState(this, {
                selectedTradeStrategy: value
            }, () => {
                onSelectTradeStrategyHandler(value)
            })
        }
    }

    render() {
        const { tradeStrategyOptions, orderDetails } = this.props
        let { selectedTradeStrategy } = this.state

        return (
            <TradeFormHeaderWrapper className="trade-title">
                {window.innerWidth > 1199 &&
                    <TradeFormHeading className="trading-title outcome-title">
                        <h2>{ isNotNull(orderDetails) && isNotNull(orderDetails.feed) ? orderDetails.feed : '' } Trading Form <FontAwesomeIcon icon={faQuestionCircle} /></h2>
                    </TradeFormHeading>
                }

                <StrategySelector>
                    <label htmlFor="trade-strategy" className="mr-3">Strategy</label>
                    <div className="form-group">
                        <select id="trade-strategy"
                            name="trade-strategy"
                            className="form-control"
                            value={selectedTradeStrategy}
                            onChange={(e) => this.onSelectTradeStrategyHandler(e)}>
                            {tradeStrategyOptions.map((item, _key) =>
                                (<option value={item.value} key={_key}>{item.name}</option>)
                            )}
                        </select>
                    </div>
                </StrategySelector>
            </TradeFormHeaderWrapper>
        )
    }
}