import React from 'react'
import { connect } from 'react-redux'
import { Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { reminderActions } from '../../redux/actions'
import { isNull, isNotNull, onSetNewState } from '../../utils'
import { CommunityInvitation } from './styles'

class JoinCommunityInvitation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isTrialPeriod: null,
            show: undefined,
            reminderId: null,
            doNotRemindAgain: false
        }
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
    }

    componentDidUpdate() {
        if (this.mounted) {
            const { isTrialPeriod, show, reminderId } = this.state
            const { dispatch, authentication, reminder } = this.props
            const { user } = authentication

            if (isNull(isTrialPeriod) && isNotNull(user)) {
                if (user.isWithinTrialPeriod) {
                    dispatch(reminderActions.getReminderInfo())
                }

                onSetNewState(this, {
                    isTrialPeriod: user.isWithinTrialPeriod
                })
            }

            if (isNotNull(isTrialPeriod) && isNotNull(reminder) && !reminder.loading && show === undefined) {
                const { result } = reminder
                if (isNotNull(result)) {
                    const { info } = result
                    let now = moment()

                    if (isNull(info) && isTrialPeriod) {
                        let sd = Date.parse(user.trialPeriodStartDate)
                        let trialStartDate = new Date(sd)
                            trialStartDate = moment(trialStartDate).format('MM/DD/yyyy h:mm:ss')
                        let duration = moment.duration(now.diff(trialStartDate))
                        let pastTime = duration.asDays()
                        if (pastTime > 4) {
                            onSetNewState(this, {
                                show: true,
                                doNotRemindAgain: false
                            })
                        }
                    } else {
                        if (isTrialPeriod) {
                            const { doNotShow, lastUpdate } = info
                            if (!doNotShow && isNotNull(lastUpdate)) {
                                let parsedDate = Date.parse(lastUpdate)
                                let lastChecked = new Date(parsedDate)
                                    lastChecked = moment(lastChecked).format('MM/DD/yyyy h:mm:ss')
                                let duration = moment.duration(now.diff(lastChecked))
                                let pastTime = duration.asDays()
                                if (pastTime > 24) {
                                    onSetNewState(this, {
                                        show: true,
                                        reminderId: info.id,
                                        doNotRemindAgain: doNotShow
                                    })
                                } else {
                                    if (isNull(reminderId)) {
                                        onSetNewState(this, {
                                            reminderId: info.id
                                        })
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    saveReminderChanges = () => { 
        const { dispatch } = this.props
        const { reminderId, doNotRemindAgain } = this.state

        let data = {
            id: isNotNull(reminderId) ? reminderId : '',
            doNotShow: doNotRemindAgain
        }
        dispatch(reminderActions.saveReminderInfo(data))

        onSetNewState(this, {
            show: false
        })
    }

    handleJoinCommunity = (e) => {
        e.preventDefault()

        window.open("https://docs.google.com/forms/d/1S5FzEJDEQo7QoiDe1SRN0Q3ZJXW0hGF7842BDKZHUq0/viewform?edit_requested=true", "_blank")
    }

    handleChangeReminderValue = (e) => {
        let isChecked = e.target.checked
        onSetNewState(this, {
            doNotRemindAgain: isChecked
        })
    }

    handleCloseModal = (e) => { 
        this.saveReminderChanges()
    }

    render() {
        const { show } = this.state

        return (
            <Modal 
                id="community-invitation" 
                className={`generic-modal community-invitation`}
                aria-hidden="true"
                data-backdrop="true"
                backdrop={true} 
                keyboard={false}
                show={show}
                centered>
                <CommunityInvitation>
                    <button type='button' className='btn-exit' onClick={e => this.handleCloseModal(e)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>

                    <div className='invitation-content'>
                        <div className='invitation-header'>
                            <img src="/images/community/trader-community.png" alt="ProfitFarmers Live Community" />
                        </div>
                        <div className='invitation-body'>
                            <h2>Join other ProfitFarmers in our Live Community</h2>
                            <p>Complete our application form and tell us your Discord username and we'll take care of the rest!</p>
                            
                            <div className='invitation-footer'>
                                <button type='button' className='btn btn-primary' onClick={e => this.handleJoinCommunity(e)}>
                                    <span>Join Now <FontAwesomeIcon icon={faArrowRight} /></span>
                                </button>
                                <div>
                                    <Form.Group controlId="dontRemindMe">
                                        <Form.Check 
                                            type="checkbox" 
                                            label="Don't remind me of this again"
                                            onChange={e => this.handleChangeReminderValue(e)} />
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </div>
                </CommunityInvitation>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    const { authentication, reminder } = state
    return {
        authentication,
        reminder
    }
}

const connectedJoinCommunityInvitation = connect(mapStateToProps)(JoinCommunityInvitation)
export { connectedJoinCommunityInvitation as JoinCommunityInvitation }