import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const FaqSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    padding: 60px 0;
    background-color: #12202D;

    .container {
        .section-title {
            font-weight: 800;
            color: #FFFFFF;
            margin-bottom: 25px;
            font-size: 25px;
        }

        .card {
            background-color: transparent;
            border: none;
            border-bottom: 1px solid #445C74;
            border-radius: 0;
            
            .card-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #11D487;
                border: none;
                padding: 15px 20px;
                font-weight: 500;
                cursor: pointer;
                line-height: 1;
                background-color: transparent;

                span {
                    &.toggle-icon {
                        display: inline-block;
                        width: 32px;
                        height: 32px;
                        font-size: 32px;
                        text-align: center;

                        small {
                            font-size: 32px;
                        }
                    }
                }
            }
            
            .card-body {
                font-family: 'Noto Sans';
                color: #FFFFFF;
                font-weight: 300;
                padding: 0 25px;
                margin: 0;
                
                p {
                    display: block;
                    width: 100%;
                    margin-bottom: 25px;
                    padding: 0;
                }
            }
        }
    }
`