import { LastChanceStyle } from '../styles'

export const RetLastChance = () => {
    return (
        <LastChanceStyle className='juiced-up ret'>
            <div className='container'>
                <div className='text-with-img' id="nextSection">
                    <div className='img-wrapper'>
                        <img src='/images/layout/ret-juiced-up.png' alt='Juiced-Up, Jacked-Up & Jump Started' />
                    </div>
                    <div className='text-col'>
                        <h2 className='section-title'>JUICED-UP, JACKED-UP & JUMP STARTED</h2>
                        <p>The standard price for 3 months of PRO access is <strong>$799</strong>.</p>
                        <p>But you can get <strong>6 months PLUS extra items</strong> for just</p>

                        <div className='price'>
                            <span className='crashed-out-price'>$799</span>
                            <span className='new-price'>$899</span>
                            <span className='price-tag'><img src='/images/layout/only-tag.svg' alt='' /></span>
                        </div>

                        <p>For just $100 you get double the length of time so that you can find and execute insanely profitable trades!</p>

                        <h3 className='discount-tag'>What are you waiting for!?</h3>
                        <p className='text-md'>Keep your trading game on <strong>HIGH ALERT</strong> for the next <strong>6 months</strong> with our all in one <strong>Techno-Beast-Mode</strong> solution.</p>
                    </div>
                </div>

                <div className='direction'>
                    <svg className="arrows">
                        <path className="a1" d="M0 0 L30 32 L60 0"></path>
                        <path className="a2" d="M0 20 L30 52 L60 20"></path>
                        <path className="a3" d="M0 40 L30 72 L60 40"></path>
                    </svg>
                </div>
            </div>
        </LastChanceStyle>
    )
}