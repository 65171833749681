import { useEffect } from 'react'
import { Layout, Brand } from '../../styles'
import { EmailVerification, FormContainer } from '../../components/Auth'
import { addPageViewEvent, seo } from '../../utils'

export const EmailVerificationPage = (props) => {
    useEffect(() => {
        seo({
            title: "Email Verification",
            metaDescription: ""
        })
        addPageViewEvent()
        document.body.classList.add('authentication')
    }, [])

    return (
        <Layout>
            <FormContainer>
                <Brand imageUrl={`/images/brand/pf-logo.png`} />
                
                <EmailVerification {...props} />
            </FormContainer>
        </Layout>
    )
}