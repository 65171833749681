import { endpoints, baseUrl } from './CONSTANTS'
import { authServices } from './authService'

const { v3 } = endpoints

function getSessionInfo() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    try {
        return fetch(`${baseUrl}${v3.system.sessions.getInfo}`, requestOpts)
            .then(handleResponse)
            .then(response => {
                return Promise.resolve(response)
            })
            .catch(error => Promise.reject(error))
    } catch (e) {
        return Promise.reject(e)
    }
}

function updateSessionInfo(data) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(data)
    }

    return fetch(`${baseUrl}${v3.system.sessions.save}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        if (res.status === 401)
            authServices.logout()
        else
            return data
    })
}

export const tradeDoctorSessionServices = {
    getSessionInfo,
    updateSessionInfo
}