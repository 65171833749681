import glamorous from 'glamorous'

export const NotificationList = glamorous.ul({
    padding: 0,
    margin: 0,
    listStyleType: 'none',

    '& li': {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        padding: '10px 40px 10px 20px',
        borderBottom: '2px solid #12202f',
        cursor: 'pointer',

        '& .msg': {
            display: 'flex',
            flexDirection: 'column',
            width: 'calc(100% - 32px)',

            '& .text': {
                color: '#b5c0cd'
            },

            '& .ts': {
                color: '#798491'
            }
        },

        '&:last-child': {
            borderBottom: 'none',
            borderBottomLeftRadius: '9px',
            borderBottomRightRadius: '9px'
        },

        '&.unread': {
            '&::after': {
                position: 'absolute',
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                content: ' ',
                backgroundColor: '#1baffe',
                right: '14px'
            }
        },

        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.3)'
        }
    }
})