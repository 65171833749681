import React from 'react'
import { connect } from 'react-redux'
import { SafeAnchor } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGem } from '@fortawesome/free-solid-svg-icons'
import { ModalContent } from './UpgradeToProModalStyle'

class UpgradeToProModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {

        }
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
    }

    componentDidUpdate() {
        if (this.mounted) {

        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    render() {
        return (
            <ModalContent>
                <h2 className='title'>Want to be a Long Term Profitable Trader?</h2>
                <p className='sub-title'>Pro members get 5x the signals, all take profit targets, enhanced Risk:Reward profile and additional features like the Break-Even Stop-Loss.</p>

                <div className='iframe-wrapper'>
                    {/* <iframe
                        height="100%"
                        width="100%"
                        title="Get Started Checklist"
                        src="https://player.vimeo.com/video/558407267?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1#t="
                        frameBorder="0"
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen /> */}
                    <img src='/images/subscriptions/upgrade-to-pro.png' alt='' />
                </div>

                <SafeAnchor href="/subscription-plans/pro/subscribe" rel="noreferrer" className='upgrade-btn'>
                    <FontAwesomeIcon icon={faGem} /> Learn More
                </SafeAnchor>
            </ModalContent>
        )
    }
}

function mapStateToProps(state) {
    const { authentication } = state
    return {
        authentication
    }
}

const connectedUpgradeToProModal = connect(mapStateToProps)(UpgradeToProModal)
export { connectedUpgradeToProModal as UpgradeToProModal }