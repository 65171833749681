import glamorous from 'glamorous'
import { mediaQueries } from '../../constants/mediaQueries'

const getWidth = (type) => {
    switch(type) {
        default: return '33.33%'
        case 'user-profile': return 'calc(100% - 420px)'
        case 'subscription':
        case 'profile-completion': {
            return '420px'
        }
    }
}

const getBg = (type) => {
    switch(type) {
        default: return '#0B1219 0% 0% no-repeat padding-box'
        case 'menu': return '#214D81 0% 0% no-repeat padding-box'
    }
}

export const TileWrapper = glamorous.div({
    display: 'flex',
    width: '420px',
    border: '2px solid #18283c',
    boxShadow: '0px 3px 18px #00000061',
    borderRadius: '9px',
    padding: 0,
    marginRight: '20px',
    minHeight: '147px',

    '&.user-profile': {
        position: 'relative',
        flexDirection: 'row',
        overflow: 'hidden',

        '&::before': {
            position: 'absolute',
            content: ' ',
            display: 'block',
            width: '100%',
            height: '300px',
            top: '-93px',
            left: '-249px',
            opacity: '0.2',
            zIndex: '0',
            background: 'transparent radial-gradient(closest-side at 50% 50%, #239196 0%, #0F1823 100%) 0% 0% no-repeat padding-box'
        },

        '& .summary': {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '60%',
            padding: '0 20px',

            '& > div': {
                display: 'flex',
                alignItems: 'center',
                position: 'relative',

                '& h2': {
                    fontSize: '18px',
                    marginBottom: 0,
                    marginLeft: '-10px',
        
                    '& span': {
                        color: '#fff',

                        '&.username': {
                            display: 'inline-block',
                            width: '200px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }
                    }
                },

                '& > a': {
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '40px',
                    height: '40px',
                    cursor: 'pointer',
                    right: 0,
                    top: 0,
                    backgroundColor: '#14202c',
                    borderRadius: '50%',
                    color: '#7e8fa1',
                    transition: 'all ease .5s',
        
                    '&:hover': {
                        backgroundColor: '#203244',
                        color: '#a5bed9'
                    }
                },

                '&.user-info': {
                    width: '100%',
                    minHeight: '76px',
                    // marginBottom: '17px',
                    // paddingBottom: '22px',
                    // borderBottom: '1px solid rgba(0,0,0,0.3)',

                    '& .img-wrapper': {
                        border: '4px solid #183143',
                        backgroundColor: 'rgba(0,0,0,0.3)',
                        boxShadow: '0 0 3px 4px rgb(0 0 0 / 10%)',
                        width: '55px',
                        height: '55px'
                    },

                    '& h2': {
                        '& .referral-link': {
                            display: 'block',
                            marginTop: '3px',
                            padding: '8px 12px',
                            backgroundColor: 'rgba(0,0,0,0.2)',
                            borderRadius: '6px',
                            fontSize: '14px',

                            '& svg': {
                                marginRight: '5px'
                            },

                            '& span': {
                                color: '#27d194'
                            }
                        }
                    }
                },

                '&.wallet-info': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
            
                    '& label': {
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: 0,
                        marginRight: '10px',
                        padding: '1px 8px 1px 1px',
                        backgroundColor: 'rgba(0,0,0,0.3)',
                        borderRadius: '19px',
                        borderTopRightRadius: '9px',
                        borderBottomRightRadius: '9px',

                        '& .symbol-logo': {
                            border: '2px solid #060a0e',
                            width: '36px',
                            height: '36px',
                            borderRadius: '50%'
                        },
            
                        '& .value': {
                            color: '#90a6bd',
                            letterSpacing: '1px',
                            fontSize: '14px'
                        },

                        '&:last-child': {
                            marginRight: 0
                        }
                    }
                },

                '&:last-child': {
                    borderRight: 'none',
                    paddingRight: 0,
                    marginRight: 0
                }
            }
        }
    },

    '& .my-subscription': {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        width: 'calc(100% - 380px)',
        backgroundColor: 'rgba(0,0,0,0.1)',
        borderRadius: '9px',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeft: '1px solid #0e1e26',

        '& > div': {
            display: 'block',
            width: '100%',
            marginBottom: 0,
        
            '& > label': {
                fontSize: '14px',
                marginBottom: '2px',
                display: 'block',
                textAlign: 'center'
            },

            '& > p': {
                textAlign: 'center',
                color: '#fff',

                '& .plan-icon': {
                    color: '#13b078'
                }
            }
        },

        '& .billing-date': {
            display: 'block',
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderRadius: '8px',
            padding: '8px',
            fontSize: '13px',
            textAlign: 'center'
        },

        '& a': {
            width: 'auto',
            margin: 0,
            fontSize: '16px',
            letterSpacing: 'unset',
            height: '42px',
            padding: '6px 20px',
            borderRadius: '21px',
            backgroundImage: 'linear-gradient(to top, #13b079 0%, #169568 100%)',
            border: '2px solid #23d381',
            color: '#fff',
            textDecoration: 'none',
            transition: 'all 0.5s ease 0s',

            '&:hover': {
                transform: 'scale(1.05)',
                backfaceVisibility: 'hidden'
            }
        }
    },

    '&.with-featured-plan': {
        backgroundImage: 'url(/images/features.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain'
    },

    '&.profile-completion,&.crypto-wallet': {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        color: '#fff',
        overflow: 'hidden',
        padding: '20px'
    },

    '&.profile-completion': {
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(32deg, #0f4589, #37dfa3)',
        
        '& .kyc-progress-wrapper': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%', 
            marginBottom: '22px',
            zIndex: 1,

            '& .kyc-progress': {
                display: 'none',
                position: 'relative',
                width: '90px',
                alignItems: 'center',
                justifyContent: 'center',

                '& .circular-progress': {
                    width: '85px !important',
                    height: '85px !important',
                    color: '#fff',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0,0,0,0.1)'
                },

                '& > label': {
                    position: 'absolute',
                    marginBottom: 0
                }
            },

            '& .kyc-text': {
                marginLeft: 0,
                width: '100%',
                textAlign: 'center',
                //marginLeft: '15px',
                // width: 'calc(100% - 90px)',

                '& h5': {
                    fontSize: '18px',
                    textShadow: '1px 1px 2px rgba(0,0,0,0.4)'
                },

                '& p': {
                    fontSize: '14px',
                    marginBottom: 0,
                    textShadow: '1px 1px 2px rgba(0,0,0,0.74)'
                }
            },
        },

        '& > button': {
            outline: 'none',
            border: 'none',
            backgroundColor: '#fff',
            padding: '7px 20px',
            borderRadius: '22px',
            color: '#1976d2',
            fontWeight: 500,
            zIndex: 1
        },

        '&::before': {
            position: 'absolute',
            content: ' ',
            width: '300px',
            height: '300px',
            borderRadius: '50%',
            backgroundColor: 'rgba(21,90,141,0.2)',
            right: '-58px',
            bottom: '-135px',
            zIndex: 0
        },

        '& .carousel': {
            '& .carousel-item': {
                minHeight: '138px'
            },

            '& .carousel-control-prev, .carousel-control-next, .carousel-indicators': {
                display: 'none'
            },

            '& .carousel-caption': {
                '& h3': {
                    fontSize: '20px'
                },
                
                '& p': {
                    fontSize: '14px',
                    marginBottom: 0
                }
            }
        }
    },

    '&.crypto-wallet': {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '12px 12px 0',
        minHeight: '123px',

        '& a': {
            position: 'absolute',
            padding: '5px',
            top: '10px',
            right: '15px',
            width: '32px',
            height: '28px',
            backgroundColor: '#14202c',
            borderRadius: '5px',
            textAlign: 'center',

            '& svg': {
                height: '15px',
                verticalAlign: 0
            }
        },

        '& h5': {
            display: 'block',
            textAlign: 'left',
            fontSize: '15px',
            letterSpacing: '1px',
            marginBottom: '15px',
            lineHeight: '1.5',

            '& small': {
                textTransform: 'uppercase',
                color: '#b9c7d5'
            }
        },

        '& .results': {
            margin: '0 -15px',
            padding: 0,
            listStyleType: 'none',

            '& li': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '8px 15px',

                '& label': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    textTransform: 'uppercase',
                    fontSize: '12px',
                    marginBottom: 0,
                    color: '#a0b7c1',

                    '& .symbol-logo': {
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        marginRight: '10px',

                        '& img': {
                            width: '100%'
                        }
                    }
                },

                '& span': {
                    fontWeight: 500,
                    fontSize: '13px',
                    letterSpacing: '1px'
                },

                '&.winning, &.usdt': {
                    backgroundImage: 'linear-gradient(to right, transparent 0%, rgb(20 168 116 / 10%) 100%)'
                },

                '&.losing': {
                    backgroundImage: 'linear-gradient(to right, transparent 0%, rgb(159 32 68 / 10%) 100%)'
                },

                '&.win-rate': {
                    backgroundImage: 'linear-gradient(to right, transparent 0%, #14ab76 100%)'
                },

                '&.btc': {
                    backgroundImage: 'linear-gradient(to right, transparent 0%, rgb(247 147 26 / 10%) 100%)',
                    paddingBottom: '9px'
                },

                '&.busd': {
                    backgroundImage: 'linear-gradient(to right, transparent 0%, rgb(237 184 11 / 10%) 100%)',
                    paddingBottom: '9px'
                }
            }
        }
    },

    '&:last-child': {
        marginRight: 0
    },

    [mediaQueries.extraSmallDevices]: {
        width: '100%',
        marginRight: 0,

        '&.user-profile': {
            flexDirection: 'column',
            marginBottom: '10px',

            '&::before': {
                left: 0
            },

            '& .summary': {
                width: '100%',
                padding: '12px',

                // '& > div': {
                //     '&.user-info': {
                //         paddingBottom: '12px',
                //         marginBottom: '12px'
                //     }
                // }
            }
        },

        '& .my-subscription': {
            width: '100%',
            padding: '5px 12px',

            '& > div': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',

                '& > label, > p': {
                    marginBottom: 0
                }
            }
        }
    },

    [mediaQueries.mediumDevices]: {
        '&.user-profile': {
            flexDirection: 'column',
            width: '55%',
            minHeight: '153px',
            marginBottom: 0,
            marginRight: '10px',

            '&::before': {
                left: 0
            },

            '& .summary': {
                width: '100%',
                padding: '12px',

                '& > div': {
                    '&.user-info': {
                        // paddingBottom: '12px',
                        // marginBottom: '12px',

                        '& > div': {
                            marginRight: '15px'
                        },

                        '& h2': {
                            marginLeft: 0
                        },

                        '& .img-wrapper': {
                            width: '60px',
                            height: '60px'
                        }
                    }
                }
            },

            '& .my-subscription': {
                width: '100%',
                padding: '8px 12px',

                '& > div': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
    
                    '& > label, > p': {
                        marginBottom: 0
                    }
                }
            }
        },

        '&.profile-completion, &.crypto-wallet': {
            width: '45%',
            minHeight: '153px',
            padding: '15px'
        },

        '&.crypto-wallet': {
            padding: '8px 15px 0',

            '& h5': {
                marginBottom: '8px'
            },

            '& .results': {
                '& li': {
                    padding: '10px 15px',

                    '&.busd': {
                        paddingBottom: '15px'
                    }
                }
            }
        },

        '&.profile-completion': {
            padding: '10px',

            '& .kyc-progress-wrapper': {    
                marginBottom: '8px',
                
                '& .kyc-progress': {
                    width: '46px',
    
                    '& .circular-progress': {
                        width: '46px !important',
                        height: '46px !important'
                    }
                },

                '& .kyc-text': {
                    '& h5': {
                        marginBottom: '6px'
                    }
                }
            },

            '& > button': {
                padding: '3px 20px'
            }
        }
    },

    [mediaQueries.largeDevices]: {
        '&.user-profile': {
            flexDirection: 'row',
            width: '60%',
            minHeight: '170px',
            marginRight: '15px',

            '& .summary': {
                width: '60%',
                padding: '12px 15px 12px 20px'
            },

            '& .my-subscription': {
                width: '40%',

                '& > div': {
                    display: 'block'
                }
            }
        },

        '&.profile-completion,&.crypto-wallet': {
            width: '40%',
            minHeight: '170px'
        },

        '&.crypto-wallet': {
            '& h5': {
                fontSize: '14px',
                lineHeight: '1.7',
                marginBottom: '3px'
            },

            '& .results': {
                '& li': {
                    padding: '7px 15px',

                    '&.busd': {
                        paddingBottom: '10px'
                    }
                }
            }
        },

        '&.profile-completion': {
            '& .kyc-progress-wrapper': {    
                marginBottom: '20px'
            }
        }
    },

    [mediaQueries.extraLargeDevices]: {
        '&.user-profile': {
            '& .summary': {
                '& > div': {
                    // '&.user-info': {
                    //     paddingBottom: '12px'
                    // },

                    '& .img-wrapper': {
                        width: '78px',
                        height: '78px'
                    }
                }
            }
        }
    }
}, ({ type }) => ({
    width: getWidth(type),
    background: getBg(type)
}))