import { authActionTypes } from '../CONSTANTS'
import { routes } from '../../navigation/CONSTANTS'
import { authServices } from '../../services'
import { isNotNull, encryptText } from '../../utils'
import { viralLoopsKey } from '../../services/CONSTANTS'

function login(data) {
    return dispatch => {
        dispatch(request())
        
        if (isNotNull(data.username))
            data.username = encryptText(data.username)

        if (isNotNull(data.password))
            data.password = encryptText(data.password)

        authServices.login(data)
            .then(res => {                
                dispatch(success(res))
            }).catch(error => {
                dispatch(fail(error))
            })
    }

    function request() { return { type: authActionTypes.LOGIN_REQUEST } }
    function success(response) { return { type: authActionTypes.LOGIN_SUCCESS, response } }
    function fail(error) { return { type: authActionTypes.LOGIN_FAIL, error } }
}

function autoLoginByToken(accessToken) {
    return dispatch => {
        dispatch(request())

        authServices.autoLoginByToken(accessToken)
            .then(res => {
                dispatch(success(res))
            })
            .catch(err => dispatch(failed(err)))
    }

    function request() { return { type: authActionTypes.LOGIN_REQUEST }}
    function success(response) { return { type: authActionTypes.LOGIN_SUCCESS, response }}
    function failed(err) { return { type: authActionTypes.LOGIN_FAIL, err }}
}

function verifyCode(userEmail, code) {
    return dispatch => {
        dispatch(request())

        authServices.verifyCode(userEmail, code)
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failed(error)))

        function request() { return { type: authActionTypes.VERIFY_CODE_REQUEST } }
        function success(response) { return { type: authActionTypes.VERIFY_CODE_SUCCESS, response } }
        function failed(error) { return { type: authActionTypes.VERIFY_CODE_FAILED, error } }
    }
}

function resendVerificationCode(userEmail) {
    return dispatch => {
        dispatch(request())

        authServices.resendVerificationCode(userEmail)
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failed(error)))

        function request() { return { type: authActionTypes.RESEND_VERIFICATION_CODE_REQUEST } }
        function success(response) { return { type: authActionTypes.RESEND_VERIFICATION_CODE_SUCCESS, response } }
        function failed(error) { return { type: authActionTypes.RESEND_VERIFICATION_CODE_FAILED, error } }
    }
}

function logout() {
    const campaign = window.VL.instances[viralLoopsKey]
    if (isNotNull(campaign))
        campaign.logout()

    return dispatch => {
        authServices
            .logout()
            .then(() => {
                dispatch({ type: authActionTypes.LOGOUT, user: null })
                window.location.href = `https://www.profitfarmers.com/wp-json/alwp/logout?redirectUrl=https://www.profitfarmers.com`
                //window.location.href = routes.authentication.login
            })
    }
}

function clearLoginError() {
    return dispatch => {
        dispatch({ type: authActionTypes.LOGIN_CLEAR })
    }
}

function loginFromMobile(access_token) {
    return dispatch => {
        dispatch(request())
        authServices.loginFromMobile(access_token).then(user => {
            dispatch(success(user))
        }, error => {
            dispatch(failure(error))
        })
    }

    function request() { return { type: authActionTypes.GET_REQUEST } }
    function success(user) { return { type: authActionTypes.GET_SUCCESS, user } }
    function failure(error) { return { type: authActionTypes.GET_FAILURE, error } }
}

export const authActions = {
    login,
    autoLoginByToken,
    verifyCode,
    resendVerificationCode,
    logout,
    clearLoginError,
    loginFromMobile
}
