import styled from 'styled-components'
import { buttonColors, borderColors, textColors } from '../CONSTANTS'

const selectBackgrounColor = (variant) => {
    if (variant === 'primary')
        return buttonColors.primary

    return buttonColors.secondary
}

const selectBorderColor = (variant) => {
    if (variant === 'primary')
        return borderColors.primary

    return borderColors.secondary
}

export const Button = styled.button`
    background-color: ${props => selectBackgrounColor(props.variant)};
    color: ${textColors.primary};
    border: 1px solid ${props => selectBorderColor(props.variant)};
    font-weight: 600;
    font-size: 18px;
    display: block;
    margin: 0 auto;
    padding: 10px 15px;
    min-width: 210px;
    text-align: center;
    cursor: pointer;
    line-height: 1.5;
    border-radius: .25rem;
    transition: transform .2s;
    -webkit-transition: -webkit-transform .2s;
    -webkit-transition: transform .2s;
    
    &:disabled {
        opacity: 0.4;
    }

    &.send-recovery-btn {
        @media (min-width: 768px)  {
        width: unset !important;
        padding: 8px 80px !important;
        }

        @media (min-width: 568px) {
            margin: 25px 0px !important;
        }
    }

`;
