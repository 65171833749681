import { Component } from 'react'
import { ForgotPasswordConfirmationView } from './ForgotPasswordConfirmationView'
import jQuery from 'jquery'

export class ForgotPasswordConfirmationContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }

        this.mounted = false
        this.baseState = this.state
    }


    componentDidMount() {
        this.mounted = true
        jQuery('body').css('height','100%')
    }

    componentDidUpdate(prevProps) {
        
    }

    componentWillUnmount() {

        this.setState(this.baseState)
        this.mounted = false
    }

    render() {
        return (
            <ForgotPasswordConfirmationView
                context={this}
                state={this.state}
                {...this.props}
            />
        )
    }
}

