import { Component } from 'react'
import jQuery from 'jquery'
import { ResetPasswordConfirmationView } from './ResetPasswordConfirmationView'

export class ResetPasswordConfirmation extends Component {
    componentDidMount() {
        jQuery('body').css('height', '100%')

        setTimeout(() => {
            window.location.href = '/account/login'
        }, 3000)
    }

    render() {
        return (
            <ResetPasswordConfirmationView />
        )
    }
}