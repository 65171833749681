import React from 'react'
import { connect } from 'react-redux'
import newFormDecorator from 'final-form-focus'
import iziToast from 'izitoast'
import { faEye } from "@fortawesome/free-solid-svg-icons"
import { userActions, leadActions } from '../../../../redux/actions'

import { LeadSecuritView } from './LeadSecuritView'
import { isNotNull, onSetNewState } from '../../../../utils'

class LeadSecurityContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            oldPassword: {
                type: "password",
                eyeIcon: faEye
            },
            newPassword: {
                type: "password",
                eyeIcon: faEye,
            },
            confirmPassword: {
                type: "password",
                eyeIcon: faEye
            },
            isSubmitted: false
        }
        this.baseState = this.state
        this.initialPasswordHelperState = {
            letter: false,
            capital: false,
            number: false,
            special: false,
            size: false,
            match: false
        }
        this.formDecorator = newFormDecorator()
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        // const { dispatch } = this.props
        // dispatch(userActions.getMe())
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { leads } = this.props
            if (prevProps.leads !== leads && isNotNull(leads)) {
                const { loading, response, error } = leads
                if (!loading) {
                    if (isNotNull(response)) {
                        const { success, msg } = response
                        if (isNotNull(success)) {
                            if (success) {
                                onSetNewState(this, { isSubmitted: false })
                                iziToast.success({
                                    position: 'topRight',
                                    title: 'Success',
                                    message: msg,
                                    displayMode: 1,
                                });
                                this.leadForm.reset()
                                const { dispatch } = this.props
                                dispatch(userActions.getMe())
                            } else {
                                onSetNewState(this, { isSubmitted: false })
                                iziToast.error({
                                    position: 'topRight',
                                    title: 'Error',
                                    message: msg,
                                    displayMode: 1,
                                });
                            }
                        }
                    }
                    if (isNotNull(error)) {
                        const { success, msg } = error
                        if (isNotNull(success)) {
                            if (!success) {
                                onSetNewState(this, { isSubmitted: false })
                                iziToast.error({
                                    position: 'topRight',
                                    title: 'Error',
                                    message: msg,
                                    displayMode: 1,
                                });
                            }
                        }
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)
        this.mounted = false
    }

    _handleSubmitSecurity = (values) => {
        const { dispatch } = this.props
        const { oldPassword, newPassword, confirmPassword } = values
        if (newPassword && confirmPassword) {
            const requestData = {
                oldPassword,
                newPassword,
                confirmPassword
            }
            dispatch(leadActions.leadChangePassword(requestData))
        }
    }

    render() {
        return <LeadSecuritView
            self={this}
            state={this.state}
            decorators={this.formDecorator}
            initialPasswordHelperState={this.initialPasswordHelperState}
            submit={this._handleSubmitSecurity}
            {...this.props}
        />
    }

}

function mapStateToProps(state) {
    const { authentication, leads } = state
    return {
        leads,
        authentication
    }
}
const connectedLeadSecurityContainer = connect(mapStateToProps)(LeadSecurityContainer)
export { connectedLeadSecurityContainer as LeadSecurityContainer }