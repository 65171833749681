import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'


export const CancelAnytimeSectionStyle = styled.section`
    position: relative;
    display: block;
    padding: 40px 0 20px;
    width: 100%;
    background-color: #121D2D;
    color: #FFFFFF;
    text-align: center;
    background-image: url(/images/trial-offer/cancel-anytime-bg.png);
    background-size: cover;
    background-position: center;

    .container {
        img {
            width: 50%;
            max-width: 212px;
            margin-bottom: 20px;
        }

        .section-title {
            font-weight: 800;
            margin: auto auto 20px;
            font-size: 28px;
        }
    }

    ${mediaQueries.largeDevices} {
        .container {
            .section-title {
                font-size: 38px;
                width: 500px;
            }

            .checkout-btn {
                margin-top: 45px;
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        padding: 60px 0 40px;

        .container {
            max-width: 900px;

            img {
                width: 180px;
                margin-bottom: 10px;
            }

            .section-title {
                font-size: 40px;
            }
            
            p {
                margin-bottom: 25px;
            }
        }
    }
`