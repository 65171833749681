import React from 'react'
import { BlueprintSectionStyle } from './styles'
import { DynamicCheckout } from '../../../components'

export const BlueprintSection = (props) => {
    return (
        <BlueprintSectionStyle>
            <div className='container'>
                <div className='text-col'>
                    <h2 className='section-title'>THE PRO TRADER 5X BLUEPRINT - FREE WITH YOUR 21 DAY TRIAL</h2>
                    <p>The trade wizards behind ProfitFarmers have created the ultimate crash course in becoming a long-term profitable trader. Combined with our software, this gives everybody (beginners and advanced traders) the opportunity to start making some real gains from the crypto market.</p>
                    <h5>Here’s what we cover and our suggested, low pressure, schedule:</h5>
                    
                    <ul className='schedule-list'>
                        <li className='item'>Day 1 (Today) - How to use the ProfitFarmers copy-trade platform and features</li>
                        <li className='item'>Day 3 - What are the market scanners and how to use them</li>
                        <li className='item'>Day 5 - Learn about risk management through the story of another successful trader</li>
                        <li className='item'>Day 7 - Understand how to be profitable long-term trader and how to calculate your potential profits</li>
                        <li className='item'>Day 9 - Introduction to Advanced Trading</li>
                        <li className='item'>Day 11 - Why a Long-Term Outlook Is Important When Trading Crypto Markets</li>
                        <li className='item'>Day 13 - The Pro Trader Knockout Punch Combination</li>
                        <li className='item'>Day 15 - Bonus Strategy Round!</li>
                        <li className='item'>Day 16-21 - Free time for refreshing lessons and catching some trades</li>
                    </ul>

                    <div className='checkout-btn'>
                        <DynamicCheckout 
                            text="Get The 5x Blueprint + 21 day Pro Trial - Only $21"
                            position="left" 
                            userInfo={props.userInfo} 
                            offer={props.subscriptionPlan} />
                     </div>

                </div>
                <div className='image-col'>
                    <img src='/images/trial-offer/blueprint-img.png'/>
                </div>
            </div>

        </BlueprintSectionStyle>
    )
}