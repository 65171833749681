import React from 'react'
import { OverlayTrigger, Tooltip, SafeAnchor } from 'react-bootstrap'

export class TradingTypeLabel extends React.Component {
    componentDidMount() {

    }

    render () {
        let { traderInfo } = this.props
        return (
            <>
                <h3>
                    Choose your trading type
                    <OverlayTrigger 
                        placement="bottom" 
                        delay={(window.innerWidth > 766 && { show: 200, hide: 1000 })}
                        overlay={
                            <Tooltip id='trading-type-tooltip' style={{ zIndex: '9999' }}>
                                This is a FUTURES signal. If you click SPOT, you will copy this signal in the SPOT market through your SPOT wallet in Binance. Learn more about trading FUTURES signals in the SPOT markets <SafeAnchor href={ traderInfo.isPaid ? 'https://pro-support.profitfarmers.com/article/95-spot-trading-futures-signals' : 'https://support.profitfarmers.com/article/219-spot-trading-futures-signals' } target="_blank" className="ml-1">here</SafeAnchor>.
                            </Tooltip>
                        }>
                        <span style={{ marginLeft: '10px', fontSize: '16px' }}><i className="fas fa-info-circle"></i></span>
                    </OverlayTrigger>
                </h3>
            </>
        )
    }
}