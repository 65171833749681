import React from 'react'
import jQuery from 'jquery'
import '../../assets/scss/themes.scss'

export default class Theme extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            mode: true
        }
    }

    componentDidMount() {
        const { mode } = this.props

        this.setState({ mode })

        if (!mode)
            jQuery('.theme-wrapper').addClass('light')
        else
            jQuery('.theme-wrapper').removeClass('light')
    }

    componentDidUpdate() {
        if (this.state.mode !== this.props.mode) {
            this.setState({
                mode: this.props.mode
            })

            if (!this.props.mode) {
                jQuery('.theme-wrapper').addClass('light')
            } else {
                jQuery('.theme-wrapper').removeClass('light')
            }
        }
    }

    render() {
        const { children } = this.props
        return (
            <div>
                {children}
            </div>
        )
    }
}