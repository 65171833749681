import React from 'react'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { ProgressBarWrapper, ProgressBarSteps } from './ProgressBarStyle'
import { isNotNull, onSetNewState } from '../../utils'

export class ProgressBar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            steps: [],
            currentState: 0,
            completedSteps: 0
        }

        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        
        const { data, currentState } = this.props
        if (isNotNull(data) && isNotNull(currentState)) {            
            onSetNewState(this, {
                steps: data,
                currentState
            })
        }
    }

    componentDidUpdate(prevState, prevProps) {
        if (this.mounted) {
            const { currentState } = this.props
            if (isNotNull(currentState) && prevProps.currentState !== currentState) {
                onSetNewState(this, {
                    currentState,
                    completedSteps: currentState - 1
                })
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    render() {
        let mappedSteps = []
        const { steps, currentState, completedSteps } = this.state        
        if (isNotNull(steps)) {
            mappedSteps = _.map(steps, (value, keys) => {
                let stepState = ''
                if (currentState === value.key)
                    stepState = 'active'
                else {
                    if (value.key < currentState)
                        stepState = 'completed'
                }

                return (
                    <li key={keys} className={`step ${stepState}`}>
                        {value.key < currentState && 
                            <FontAwesomeIcon size="xs" className='icon' icon={faCheck} />
                        }

                        <span className='step-title'>{value.title}</span>
                    </li>
                )
            })            
        }

        return (
            <ProgressBarWrapper completedSteps={completedSteps}>
                <span className='progress-track'>
                    <span className='thumb'></span>
                </span>

                <ProgressBarSteps>
                    {mappedSteps}
                </ProgressBarSteps>
            </ProgressBarWrapper>
        )
    }
}