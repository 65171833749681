import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import Cookies from 'js-cookie'
import newFormDecorator from 'final-form-focus'
import jQuery from 'jquery'
import Swal from 'sweetalert2'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { userActions } from '../../redux/actions'
import { SignUpView } from './SignUpView'
import { browserWindow, isNotNull, getCloudflareJsonData, onSetNewState, toastWarning } from '../../utils'

class SignUpContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            eyeIcon: faEye,
            passwordInputType: 'password',
            retypePasswordInputType: 'password',
            retypePasswordEyeIcon: faEye,
            checkingEmail: false,
            hasAgreedToAgreement: false,
            hasPreFlight: null,
            lockUserDevice: false,
            userEmail: null,
            errorBox: false,
            errorMessage: null,
            isLoading: false,
            // tracking data
            aid: '',
            bid: '',
            tid: '',
            ip: '',
            countryCode: ''
        }

        this.baseState = this.state
        this.formDecorator = newFormDecorator()
        this.mounted = false
    }

    componentDidMount() {
        window.location.href = "https://www.profitfarmers.com/signup";
        // this.mounted = true
        
        // const params = queryString.parse(window.location.search)
        // if (isNotNull(params)) {
        //     onSetNewState(this, {
        //         aid: params.a_aid,
        //         bid: params.a_bid,
        //         tid: params.tid
        //     }, () => {
        //         Cookies.set('PAPVisitorId', params.tid)
        //         Cookies.set('PAPAffiliateId', params.a_aid)
        //         Cookies.set('PAPBannerId', params.a_bid)
        //     })
        // } else {
        //     let visitorId = Cookies.get('PAPVisitorId')
        //     let affiliateId = Cookies.get('PAPAffiliateId')
        //     let bannerId = Cookies.get('PAPBannerId')

        //     if (isNotNull(visitorId))
        //         onSetNewState(this, {
        //             tid: visitorId
        //         })

        //     if (isNotNull(affiliateId))
        //         onSetNewState(this, {
        //             aid: affiliateId
        //         })

        //     if (isNotNull(bannerId))
        //         onSetNewState(this, {
        //             bid: bannerId
        //         })
        // }

        // // try to get user's ip address
        // this.getUserIpAddress()

        // // set body to 100 percent
        // window.addEventListener('resize', () => browserWindow.adjustDimensions(this))
        // jQuery('body').css('height', '100%')
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            // const { user } = this.props

            // if (user.loading === false && prevProps.user.loading === true) {
            //     if (isNotNull(user)) {
            //         const { userData, error, loading } = user

            //         if (isNotNull(error)) {
            //             const { msg } = error
            //             Swal.fire({
            //                 title: msg,
            //                 showConfirmButton: false,
            //                 timer: 1000,
            //             })
            //         }
            //         else {
            //             if (isNotNull(userData)) {
            //                 const { msg, success, redirectUri } = userData
            //                 console.log(success)

            //                 if (success)
            //                     window.location.href = redirectUri
                                
            //                 // if (isNotNull(success)) {
            //                 //     const { lockUserDevice, errorBox } = this.state
            //                 //     if (success && !lockUserDevice) {
            //                 //         window.location.href = redirectUri    
            //                 //         onSetNewState(this, { lockUserDevice: true })
            //                 //     } else {
            //                 //         if (isNotNull(msg) && msg !== 'Welcome to ProfitFarmers') {
            //                 //             if (isNotNull(msg) && !errorBox) {
    
            //                 //                 onSetNewState(this, {
            //                 //                     errorMessage: msg,
            //                 //                     errorBox: true
            //                 //                 })
    
            //                 //                 toastWarning(msg)
            //                 //             }
            //                 //         }
            //                 //     }
            //                 // }
            //             }
            //         }

            //         onSetNewState(this, { isLoading: loading });
            //     }
            // }
        }
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)
        window.removeEventListener('resize', () => browserWindow.adjustDimensions(this))
        this.mounted = false
    }

    getUserIpAddress = () => {
        fetch('https://www.cloudflare.com/cdn-cgi/trace')
            .then(async res => {
                const text = await res.text()
                return getCloudflareJsonData(text)
            })
            .then(res => {
                if (isNotNull(res) && isNotNull(res.ip)) {
                    console.log('cc res: ', res)
                    this.setState({ ip: res.ip })
                }
            })
    }

    onRegisterHandler = (data) => {
        const { dispatch } = this.props;
        if (isNotNull(data.emailAddress))
            onSetNewState(this, { userEmail: data.emailAddress });
            
        data.referralCode = ''
        data.referralSource = ''
        dispatch(userActions.register(data))
    }

    onToastWarning = (msg) => toastWarning(msg);

    render() {
        // return (
        //     <SignUpView
        //         self={this}
        //         state={this.state}
        //         toastWarning={this.onToastWarning}
        //         onRegisterHandler={this.onRegisterHandler}
        //         decorators={this.formDecorator}
        //         {...this.props}
        //     />
        // )

        return (
            <></>
        )
    }
}


function mapStateToProps(state) {
    let { authentication, getId, user } = state;
    return {
        authentication,
        getId,
        user,
    }
}

const connectedSignUpContainer = connect(mapStateToProps)(SignUpContainer);
export { connectedSignUpContainer as SignUpContainer };
