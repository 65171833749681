import React from 'react'
import { connect } from 'react-redux'
import * as signalR from '@microsoft/signalr'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes, faBullhorn, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { InputGroup, FormControl, DropdownButton, Dropdown } from 'react-bootstrap'
import _ from 'lodash'
import { CreateMessageForm, ListOfMessages } from '../../components'
import { MessagePreview } from '../../components/Lists/MessageBoard/MessagePreview'
import { messagesActions, typesAction } from '../../redux/actions'
import { userRoles } from '../../constants/userRoles'
import { msgTypes } from '../../constants/messageTypes'
import { apiAuthToken, baseUrl } from '../../services/CONSTANTS'
import { isNotNull, isNull, onSetNewState } from '../../utils'
import { messagesActionTypes } from '../../redux/CONSTANTS'

class MessageBoardContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isAdmin: undefined,
            isPro: undefined,
            isLoading: true,
            isEditing: false,
            showPreviewMessage: false,
            showCreateForm: false,
            page: 1,
            pageSize: 5,
            totalPageItems: 0,
            isFullyLoaded: false,
            isLoadingNewMessages: false,
            messageTypeOptions: [],
            audienceTypeOptions: [],
            searchKeyword: "",
            selectedType: "",            
            selectedTypeText: 'All',
            messageItems: [
                // {
                //     img: null,
                //     type: 'marketanalysis',
                //     typeInTxt: 'Market Analysis',
                //     link: null,
                //     title: 'DXY, EURO, BTC and ETH',
                //     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
                //     datePublished: new Date(2022, 6, 20),
                //     htmlContent: null
                // },
                // {
                //     img: null,
                //     type: 'general',
                //     typeInTxt: 'General',
                //     link: null,
                //     title: 'Support Schedule',
                //     description: 'Limited support during Easter holidays. Learn more about our schedule here.',
                //     datePublished: new Date(2022, 6, 19),
                //     htmlContent: null
                // },
                // {
                //     img: null,
                //     type: 'marketanalysis',
                //     typeInTxt: 'Market Analysis',
                //     link: null,
                //     title: 'ETH Clues - Breakdown Play',
                //     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
                //     datePublished: new Date(2022, 6, 17),
                //     htmlContent: null
                // },
                // {
                //     img: null,
                //     type: 'marketanalysis',
                //     typeInTxt: 'Market Analysis',
                //     link: null,
                //     title: 'Bitcoin is flashing reasons to exit/short',
                //     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
                //     datePublished: new Date(2022, 6, 14),
                //     htmlContent: null
                // },
                // {
                //     img: null,
                //     type: 'promotions',
                //     typeInTxt: 'Promotions',
                //     link: null,
                //     title: 'New Net Peak Gains Calculator + Huge Discounts!',
                //     description: "Behold the Net Peak Gains calculator! Now you can see exactly how big the trading opportunities are even during choppy markets! This is why we're loved by tra...",
                //     datePublished: new Date(2022, 6, 12),
                //     htmlContent: null
                // }
            ],
            selectedMessage: {
                headerPhotoUrl: null,
                title: null,
                shortDescription: null,
                htmlContent: null,
                dateCreated: null
            },
            editData: null
        }
        this.baseState = this.state

        this.mounted = false
        this.searchTimer = null
        this.msgHubConnection = null

        window.onscroll = _.debounce(() => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.scrollingElement.scrollHeight - 1)
                this.handleLoadNewSet()
        })
    }

    componentDidMount() {
        this.mounted = true

        const { dispatch } = this.props
        const { searchKeyword, selectedType } = this.state
        dispatch(typesAction.getMessageTypes())
        dispatch(typesAction.getAudienceTypes())
        dispatch(messagesActions.getList(1, searchKeyword, selectedType))
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.mounted) {
            const { 
                isAdmin, 
                isPro, 
                messageTypeOptions, 
                audienceTypeOptions, 
                messageItems,
                isFullyLoaded,
                isLoadingNewMessages } = this.state
            const { 
                dispatch,
                authentication, 
                messageTypes, 
                audienceTypes, 
                messageBoardItemList,
                newSetOfMessages } = this.props
            const { user } = authentication
        
            if (typeof isAdmin === 'undefined' && 
                isNotNull(user) && 
                isNotNull(user.roles)) {
                const { roles } = user
                let userRole = _.find(roles, (e) => {
                    return e === userRoles.ADMIN
                })
                onSetNewState(this, {
                    isAdmin: isNotNull(userRole) && userRole === userRoles.ADMIN
                })
            }
            
            if (isNotNull(messageTypes) && 
                !messageTypes.loading && 
                isNotNull(messageTypes.dataList)) {
                    let { types } = messageTypes.dataList
                    if (types !== messageTypeOptions) {
                        onSetNewState(this, {
                            messageTypeOptions: types
                        })
                    }
            }
            
            if (isNotNull(audienceTypes) && 
                !audienceTypes.loading && 
                isNotNull(audienceTypes.dataList)) {
                    let { types } = audienceTypes.dataList
                    if (types !== audienceTypeOptions) {
                        onSetNewState(this, {
                            audienceTypeOptions: types
                        })
                    }
                }

            if (isNotNull(messageBoardItemList) && 
                !messageBoardItemList.loading && 
                isNotNull(messageBoardItemList.dataList)) {
                    const { dataList } = messageBoardItemList
                    if (isNotNull(dataList) && isNotNull(dataList.data)) {
                        const { data } = dataList
                        if (isNotNull(data) && messageItems !== data) {
                            onSetNewState(this, {
                                messageItems: data,
                                isLoading: false
                            })
                        }
                    }
            }
            
            if (isLoadingNewMessages && 
                prevProps.newSetOfMessages !== newSetOfMessages && 
                isNotNull(messageBoardItemList.dataList)) {
                if (isNotNull(newSetOfMessages) && !newSetOfMessages.loading) { 
                    if (!isFullyLoaded && isNotNull(newSetOfMessages.dataList)) {
                        if (isNotNull(newSetOfMessages.dataList.data) && 
                            newSetOfMessages.dataList.data.length > 0) {
                            let { dataList } = messageBoardItemList
                            if (isNotNull(dataList) && isNotNull(dataList.data)) {
                                const diff = _.differenceWith(newSetOfMessages.dataList.data, dataList.data, _.isEqual)
                                
                                
                                if (isNotNull(diff)) {
                                    let data = [...dataList.data, ...diff]
                                    let response = {
                                        data,
                                        success: true
                                    }
                                    //dispatch(messagesActions.updateList(newData))
                                    dispatch({ type: messagesActionTypes.GET_LIST_SUCCESS, response })
                                    onSetNewState(this, {
                                        isLoadingNewMessages: false
                                    })
                                }
                            }
                        } else {
                            onSetNewState(this, {
                                isFullyLoaded: true,
                                isLoadingNewMessages: false
                            })
                        }                            
                    }
                }
            }

            if (isNotNull(user) && isNotNull(isAdmin)) {
                if (isNull(isPro)) {
                    onSetNewState(this, {
                        isPro: user.isPaid
                    })
                }

                if (!isAdmin && this.msgHubConnection === null) {
                    await this.initHubConnection()
                }
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
        this.setState(this.baseState)
        this.msgHubConnection = null
    }

    initHubConnection = async () => {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${baseUrl}/messages`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
                accessTokenFactory: () => apiAuthToken
            })
            .configureLogging(signalR.LogLevel.None)
            .withAutomaticReconnect([0,0,10000])
            .build()
        hubConnection.serverTimeoutInMilliseconds = 120000

        this.msgHubConnection = hubConnection
        this.msgHubConnection.onclose(async () => {
            await this.startHubConnection()
        })

        this.msgHubConnection.on("onMessageToAll", (payload) => {
            const { dispatch } = this.props
            let { dataList } = this.props.messageBoardItemList

            if (isNotNull(dataList) && isNotNull(dataList.data)) {
                let { data } = dataList
                if (isNotNull(data)) {
                    let exists = _.find(data, ['id', payload.id])
                    if (!exists) {
                        data.unshift(payload)
                        data = _.orderBy(data, ['dateCreated'], ['desc'])
                        dispatch(messagesActions.updateList(dataList))
                    }
                }
            }
        })
        
        this.msgHubConnection.on("onMessageToPro", (payload) => {
            const { isPro } = this.state
            const { dispatch } = this.props
            let { dataList } = this.props.messageBoardItemList

            if (isPro && isNotNull(dataList) && isNotNull(dataList.data)) {
                let { data } = dataList
                if (isNotNull(data)) {
                    let exists = _.find(data, ['id', payload.id])
                    if (!exists) {
                        data.unshift(payload)
                        data = _.orderBy(data, ['dateCreated'], ['desc'])
                        dispatch(messagesActions.updateList(dataList))
                    }
                }
            }
        })

        this.msgHubConnection.on("onMessageToBasic", (payload) => {            
            const { isPro } = this.state
            const { dispatch } = this.props
            let { dataList } = this.props.messageBoardItemList

            if (!isPro && isNotNull(dataList) && isNotNull(dataList.data)) {
                let { data } = dataList
                if (isNotNull(data)) {
                    let exists = _.find(data, ['id', payload.id])
                    if (!exists) {
                        data.unshift(payload)
                        data = _.orderBy(data, ['dateCreated'], ['desc'])
                        dispatch(messagesActions.updateList(dataList))
                    }
                }
            }
        })

        await this.startHubConnection()
    }

    startHubConnection = async () => {
        try {
            const { user } = this.props.authentication
            await this.msgHubConnection
                .start()
                .catch((err) => {
                    console.log(err)
                })

            if (isNotNull(this.msgHubConnection) &&
                isNotNull(this.msgHubConnection.state) &&
                this.msgHubConnection.state === signalR.HubConnectionState.Connected) {
                this.msgHubConnection.invoke("GetConnectionId", user.userName)
                    .then(function (connectionId) {
                        //console.log('msg connectionId: ', connectionId)
                    })
                    .catch(function () {
                        //console.log(err)
                    })
            }
        } catch (err) {
            //console.log(err)

            if (isNotNull(this.msgHubConnection) &&
                isNotNull(this.msgHubConnection.state) &&
                this.msgHubConnection.state === signalR.HubConnectionState.Disconnected) {
                // restart connection
                setTimeout(async () =>
                    await this.startHubConnection(), 3000)
            }
        }
    }

    handleLoadNewSet = () => {
        if (this.mounted) {
            const { dispatch } = this.props
            const { 
                page, 
                isLoadingNewMessages, 
                isFullyLoaded,
                searchKeyword, 
                selectedType,
                messageItems } = this.state

            if (!isLoadingNewMessages && !isFullyLoaded) {
                const newPage = isNotNull(messageItems) && messageItems.length > 0 ? page + 1 : 1
                onSetNewState(this, {
                    page: newPage,
                    isLoadingNewMessages: true
                }, () => {
                    dispatch(messagesActions.getMore(newPage, searchKeyword, selectedType))
                })
            }
        }
    }

    handleShowCreateForm = (e) => {
        e.preventDefault()

        onSetNewState(this, {
            showCreateForm: true
        })
    }

    handleHideCreateForm = () => {
        onSetNewState(this, {
            isEditing: false, 
            editData: null,
            showCreateForm: false
        })
    }

    handleSelectMessageType = (e, selectedValue, selectedValueText) => {
        e.preventDefault()

        const { selectedType, searchKeyword } = this.state
        if (isNotNull(selectedType) && selectedType === selectedValue)
            return
        else {
            if (selectedType === '' && selectedValue === '') 
                return
        }

        const { dispatch } = this.props
        onSetNewState(this, {
            isLoading: true,
            selectedType: selectedValue,
            selectedTypeText: selectedValueText,
            messageItems: null
        }, () => {
            //dispatch(messagesActions.clearList())
            dispatch(messagesActions.getList(1, searchKeyword, selectedValue))
        })
    }

    handleAddedMessage = (msg) => {
        const { messageItems } = this.state
        messageItems.unshift(msg)
    }

    handleEditedMessage = (msg) => {
        const { messageItems } = this.state
        let existingMsg = _.find(messageItems, (e) => {
            return e.id === msg.id
        })

        if (existingMsg) {
            existingMsg.audienceType = msg.audienceType
            existingMsg.dateCreated = msg.dateCreated
            existingMsg.headerPhotoUrl = msg.headerPhotoUrl
            existingMsg.htmlContent = msg.htmlContent
            existingMsg.shortDescription = msg.shortDescription
            existingMsg.title = msg.title
            existingMsg.type = msg.type
        }

        onSetNewState(this, {
            isEditing: false,
            showCreateForm: false,
            editData: null
        })
    }

    handleSelectMessage = (e, msg) => {
        e.preventDefault()

        let selectedMessage = {
            type: msg.type,
            headerPhotoUrl: msg.headerPhotoUrl,
            title: msg.title,
            shortDescription: msg.shortDescription,
            htmlContent: msg.htmlContent,
            dateCreated: msg.dateCreated
        }

        onSetNewState(this, {
            selectedMessage,
            showPreviewMessage: true
        })
    }

    handleEditMessage = (e, msg) => {
        e.preventDefault()

        onSetNewState(this, {
            showCreateForm: true,
            isEditing: true, 
            editData: msg
        })
    }

    handleDeleteMessage = (e, id) => {
        e.preventDefault()

        
    }

    handleHideMessagePreview = (e) => {
        e.preventDefault()

        let selectedMessage = {
            headerPhotoUrl: null,
            title: null,
            shortDescription: null,
            htmlContent: null,
            dateCreated: null
        }

        onSetNewState(this, {
            selectedMessage,
            showPreviewMessage: false
        })
    }

    handleSearch = (keyword) => {
        const { selectedType } = this.state

        const { dispatch } = this.props
        dispatch(messagesActions.clearList())

        onSetNewState(this, {
            messageItems: null,
            searchKeyword: keyword
        }, () => {
            dispatch(messagesActions.getList(1, keyword, selectedType))
        })  
    }

    handleOnKeyUp = (e) => {
        const { searchKeyword } = this.state
        let val = e.target.value

        if (isNotNull(searchKeyword) && 
            val === searchKeyword)
            return

        clearTimeout(this.searchTimer)    
        this.searchTimer = setTimeout(() => {
            onSetNewState(this, {
                isLoading: true
            }, () => {
                this.handleSearch(e.target.value)
            })
        }, 200)
    }

    renderMessageTypeSelection = () => {
        let { messageTypeOptions } = this.state
        const { selectedTypeText } = this.state

        if (isNotNull(messageTypeOptions)) {
            // let exists = _.find(messageTypeOptions, ['key', ''])
            // if (!exists) {                
            //     messageTypeOptions.unshift({
            //         key: '',
            //         value: 'All'
            //     })
            // }
    
            let mappedOptions = _.map(messageTypeOptions, (value, key) => {
                if (value.key !== msgTypes.PROMOTIONS) {
                    return (
                        <Dropdown.Item key={key} as="button" onClick={(e) => this.handleSelectMessageType(e, value.key, value.value)}>{value.value}</Dropdown.Item>
                    )
                }
            })
    
            return (
                <DropdownButton id="message-types-dropdown" title={selectedTypeText} menuAlign="right">
                    <Dropdown.Item as="button" onClick={(e) => this.handleSelectMessageType(e, '', 'All')}>All</Dropdown.Item>
                    {mappedOptions}
                </DropdownButton>
            )
        }
    }
    
    renderLoader = () => (
        <>
            <div style={{ width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0 }}>
                <div className="pf-spinner xs" style={{ marginTop: '-48px', marginRight: '45px' }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        </> 
    )

    render() {
        const { searchBarWidth } = this.props
        const { 
            isLoading,
            isLoadingNewMessages,
            isAdmin,
            isEditing,
            messageTypeOptions, 
            audienceTypeOptions, 
            selectedType, 
            messageItems, 
            showCreateForm,
            showPreviewMessage,        
            selectedMessage,
            editData } = this.state 

        const windowWidth = window.innerWidth

        return (
            <>
                <div className='page-header'>
                    <h2 className='page-title'>
                        <FontAwesomeIcon icon={faBullhorn} className='icon' />
                        <span className='text'>Message Board</span>
                        <OverlayTrigger placement='right' delay={(windowWidth > 1024 && { show: 200, hide: 1000 })} overlay={
                            <Tooltip id='msg-tooltip-right'>
                                This is where you can find market analysis, general updates and other news.
                            </Tooltip>
                        }>
                            <span className="icon-question">
                                <i className="fas fa-info-circle"></i>
                            </span>
                        </OverlayTrigger>
                    </h2>

                    { typeof isAdmin !== 'undefined' && isAdmin && 
                        <div className='page-actions'>
                            <button type="button" id='create-msg-btn' onClick={(e) => this.handleShowCreateForm(e)}>                                
                                <FontAwesomeIcon icon={faPlus} className='icon' />
                                <span className='btn-text'>New Message</span>
                            </button>
                        </div>
                    }                    
                </div>

                <div className='filters-bar' style={{ width: searchBarWidth }}>
                    <form>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="search-keyword-input">
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="search-keyword-input"
                                onKeyUp={(e) => this.handleOnKeyUp(e)} 
                                />

                            <InputGroup.Append>
                                {this.renderMessageTypeSelection()}
                            </InputGroup.Append>

                            {/* <InputGroup.Append>
                                <DropdownButton id="dropdown-item-button" title="This week" menuAlign="right" className='date-range'>
                                    <Dropdown.ItemText>DATE RANGE</Dropdown.ItemText>
                                    <Dropdown.Item as="button">
                                        <span>This week</span>
                                        <span className='date-range-value'>24 Jul - 30 Jul, 2022</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                        <span>This month</span>
                                        <span className='date-range-value'>1 Jul - 31 Jul, 2022</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                        <span>This quarter</span>
                                        <span className='date-range-value'>1 Jul - 30 Sep, 2022</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                        <span>This Year</span>
                                        <span className='date-range-value'>1 Jan - 31 Dec, 2022</span>
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item as="button">
                                        <span>Last week</span>
                                        <span className='date-range-value'>17 Jul - 23 Jul, 2022</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                        <span>Last month</span>
                                        <span className='date-range-value'>1 Jun - 30 Jun, 2022</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                        <span>Last quarter</span>
                                        <span className='date-range-value'>1 Apr - 30 Jun, 2022</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button">
                                        <span>Last Year</span>
                                        <span className='date-range-value'>1 Jan - 31 Dec, 2021</span>
                                    </Dropdown.Item>
                                </DropdownButton>
                            </InputGroup.Append> */}
                        </InputGroup>
                    </form>
                </div>

                <div style={{ display: 'block', width: '100%' }}>
                    {/* { isLoading && this.renderLoader() } */}

                    { !isLoading && 
                        <ListOfMessages 
                            isAdmin={isAdmin}
                            selectedMessageType={selectedType}
                            data={messageItems}
                            onSelectMessage={(e, msg) => this.handleSelectMessage(e, msg)} 
                            onEditMessage={(e, msg) => this.handleEditMessage(e, msg)}
                            onDeleteMessage={(e, msg) => this.handleDeleteMessage(e, id)} />
                    }

                    { isLoadingNewMessages && this.renderLoader() }
                </div>

                <Modal 
                    className='generic-modal new-board-message' 
                    tabIndex="-1" 
                    role="dialog" 
                    aria-hidden="true" 
                    show={showCreateForm} 
                    backdrop="static"
                    keyboard={false}
                    enforceFocus={false} 
                    animation={false}
                    centered>
                        <CreateMessageForm 
                            isEditing={isEditing} 
                            editData={editData} 
                            messageTypeOptions={messageTypeOptions} 
                            audienceTypeOptions={audienceTypeOptions} 
                            closeMessage={() => this.handleHideCreateForm()}
                            onAdded={(msg) => this.handleAddedMessage(msg)} 
                            onEdited={(msg) => this.handleEditedMessage(msg)} />
                </Modal>

                <Modal
                    className='generic-modal message-preview'
                    tabIndex="-1"
                    role="dialog" 
                    aria-hidden="true"
                    show={showPreviewMessage} 
                    backdrop="static" 
                    keyboard={false} 
                    centered>
                        { isNotNull(selectedMessage) && 
                            <Modal.Body>
                                <button 
                                    type="button" 
                                    className='custom-close-btn'
                                    onClick={(e) => this.handleHideMessagePreview(e)}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>

                                <MessagePreview data={selectedMessage} />
                            </Modal.Body>
                        }
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    const { 
        authentication, 
        messageTypes, 
        audienceTypes,
        messageBoardItemList,
        newSetOfMessages } = state
    return {
        authentication,
        messageTypes,
        audienceTypes,
        messageBoardItemList,
        newSetOfMessages
    }
}

const connectedMessageBoardContainer = connect(mapStateToProps)(MessageBoardContainer)
export { connectedMessageBoardContainer as MessageBoardContainer }