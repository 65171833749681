import styled from 'styled-components'
import { mediaQueries } from '../../constants/mediaQueries'

export const MessageList = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100%;

    > li {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        background-color: #0F1823;
        border: 2px solid #18283C;
        box-shadow: 0px 1px 7px #0000001f; 
        border-radius: 9px;
        margin-bottom: 8px;
        min-height: 112px;
        cursor: pointer;

        a {
            width: 100%;
            text-decoration: none;
            color: unset;
        }

        .msg-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;

            .msg-txt-content {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 12px 15px;

                .stamp {
                    margin-bottom: 13px;

                    small {
                        font-size: 12px;
                        letter-spacing: 1px;

                        &.msg-ts {
                            color: #7d92a5;
                        }

                        &.msg-type {
                            padding: 4px 8px;
                            border-radius: 13px;
                            font-weight: 500;
                            text-transform: uppercase;
                            text-shadow: 0 0 black;

                            &.marketanalysis {
                                background: rgb(101 182 255 / 22%) 0% 0% no-repeat padding-box;
                                color: #4893D6;
                            }

                            &.general {
                                background: rgb(255 101 163 / 22%) 0% 0% no-repeat padding-box;
                                color: #FF65A3;
                            }

                            &.promotions {
                                background: rgb(23 180 109 / 22%) 0% 0% no-repeat padding-box;
                                color: #17B46D;
                            }

                            &.rssfeed {
                                background-color: #382814;
                                color: #f5ad57;
                            }
                        }
                    }

                    .dot {
                        display: inline-block;
                        width: 4px;
                        height: 4px;
                        margin: 0 4px;
                        border-radius: 2px;
                        background-color: #687f9b;
                        vertical-align: middle;
                    }
                }

                .header-texts {
                    color: #e4eff9;
                    line-height: 1.3;
                    margin-bottom: 5px;
                    font-size: 16px;
                }

                p {
                    color: #bbd3eb;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 755px;
                    overflow: hidden;
                    height: 26px;
                    font-size: 14px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .msg-thumbnail {
                display: none;
                align-items: center;
                width: 100%;
                border-radius: 0;
                overflow: hidden;

                img {
                    width: 100%;
                }
            }

            /*.msg-txt-content {
                width: calc(100% - 323px)
            }*/
        }

        &.empty {
            align-items: center;
            justify-content: center;
            border-radius: 9px;

            p {
                display: block;
                margin-bottom: 0;
            }
        }

        .actions-list {
            position: absolute;
            top: 15px;
            right: 15px;
            display: inline-flex;
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                .fresh-msg {                    
                    background-color: #0b1219;
                    border-radius: 10px;
                    width: auto;
                    padding: 3px 8px;
                    color: #13b078;
                    font-size: 12px;
                }

                .action-btn {
                    background: none;
                    box-shadow: none;
                    outline: none;
                    border: none;
                    background-color: #0b1219;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    overflow: hidden;
                    padding: 5px;
                    font-size: 12px;

                    &.mod {
                        color: #13b078;
                    }

                    &.rem {
                        color: #993956;
                    }

                    &:hover {
                        background-color: #f0f0f0;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        > li {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            .msg-wrapper {
                flex-direction: row;

                .msg-thumbnail {
                    display: flex;
                    width: 249px;
                }

                .msg-txt-content {
                    width: calc(100% - 249px);
                    padding: 20px 20px 0px;

                    .stamp {
                        margin-bottom: 18px;

                        small {
                            font-size: 12px;

                            &.msg-type {
                                font-size: 10px;
                            }
                        }
                    }

                    .header-texts {
                        font-size: 18px;
                    }

                    p {
                        font-size: 13px;
                    }
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        > li {
            .msg-wrapper {
                .msg-thumbnail {
                    width: 280px;
                }

                .msg-txt-content {
                    width: calc(100% - 280px);

                    .stamp {
                        small {
                            font-size: 13px;

                            &.msg-type {
                                font-size: 12px;
                            }
                        }
                    }

                    .header-texts {
                        font-size: 20px;
                    }

                    p {
                        font-size: 15px;
                    }
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        > li {
        margin-bottom: 10px;

            .msg-wrapper {
                .msg-thumbnail {
                    width: 323px;
                }

                .msg-txt-content {
                    width: calc(100% - 323px);
                    padding: 25px 25px 0px;

                    .stamp {
                        margin-bottom: 25px;

                        small {
                            &.msg-type {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }
    
`