import styled from 'styled-components'

export const CustomVideoWrapper = styled.div`
    h2 {
        font-size: 32px;
        margin-bottom: 25px;
        text-align: center;
    }

    iframe {
        width: 824px;
        height: 472px;
        border-radius: 4px;
        outline: 8px solid #0a121b;
        background-color: #080b0e;
        margin: 0 auto;
        display: block;
    }

    @media screen and (min-width: 320px) and (max-width: 414px) {
        h2 {
            font-size: 16px;
            line-height: 1.6;
        }

        iframe {
            width: 342px;
            height: 187px;
            outline: 4px solid #0a121b;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        h2 {
            font-size: 30px;
            line-height: 1.6;
        }

        iframe {
            width: 627px;
            height: 348px;
            outline: 8px solid #0a121b;
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        h2 {
            font-size: 35px;
            line-height: 1.5;
        }

        iframe {
            width: 768px;
            height: 432px;
            outline: 8px solid #0a121b;
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        iframe {
            width: 719px;
            height: 414px;
        }
    }
    
    @media screen and (min-width: 1441px) and (max-width: 1500px) {
        h2 {
            font-size: 27px;
            margin-bottom: 30px;
        }

        iframe {
            width: 753px;
            height: 418px;
        }
    }

    @media screen and (min-width: 1501px) {
        h2 {
            font-size: 27px;
            margin-bottom: 40px;
        }

        iframe {
            width: 712px;
            height: 395px;
        }
    }
`