const notiTypes = {
    NEW_SIGNAL: 0,
    STOP_LOSS_HIT: 1,
    TARGET_HIT: 2,
    ALL_TARGETS_HIT: 3,
    MANUALLY_CLOSED: 4,
    AUTO_CLOSE_EARLY: 5,
    ORDER_SUCCEEDED: 6,
    ORDER_FAILED: 7,
    SYSTEM_UPDATE: 8,
    MESSAGE_BOARD: 9
}

export {
    notiTypes
}