import styled from 'styled-components'

export const SignalDetailsCloseBtn = styled.button`
    background: none;
    border: none;
    outline: none;
    color: #d0d2d4;
    font-size: 20px;
    position: absolute;
    cursor: pointer;
    z-index: 100;
    top: -15px;
    right: -15px;
    width: 50px;
    background-color: #993956;
    border-radius: 2px;
    line-height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    &:hover {
        color: #fff;
    }
`

export const CoinPairInfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 10px;
`

export const CoinPairInfoWrapperContent = styled.div`
    display: flex;
    align-items: center;
`

export const CryptoCoinName = styled.div`
    display: flex;
    flex-direction: column;

    span {
        font-size: 22px;
        font-weight: 500;
    }

    small {
        color: rgb(198, 201, 214);
        font-size: 14px;
    }
`

export const SignalStatusContent = styled.div`
    text-align: center;

    .trade-progress {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #192431;
        border-top: none;
        border-bottom: none;
        border-left: none;

        .trade-status {
            width: 215px;

            span {
                position: relative;
                top: auto;
                display: flex;
                padding: 8px 35px;
                text-align: center;
                border-radius: 2px;
                border-bottom: 3px solid rgba(0,0,0,0.2);
                font-size: 15px;
                min-height: 45px;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                letter-spacing: 0;
                line-height: 1.3;
            }
        }

        .trade-progress-value {
            width: calc(100% - 165px);
            padding-left: 10px;

            .progress-value {
                text-align: right;
                font-size: 22px;
                margin-bottom: 0;
                line-height: .9;

                span {
                    display: block;
                }

                small {
                    font-size: 12px;
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }
        }
    }
`

export const SignalTimestamp = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
    margin-bottom: 15px;

	span {
	  	font-size: 15px;
      	color: #B5BECB;
	}

	b {
	  	color: #fff;
	}
`

export const SignalStatusLastPrice = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

    .signal-status-lastprice-label {
        color: #B5BECB;
    }

    .signal-status-lastprice-value {
        color: #fff;
	  	text-shadow: 1px 4px 5px rgba(0, 0, 0, 0.4);
	  	font-weight: 500;
    }
`

export const SignalInstructionsSection = styled.div`
    padding: 15px 25px 0;
    font-size: 15px;

    hr {
        border: 1px solid #283240;
        margin: 1.2rem 0;
    }
`

export const SignalBreakPointsGroup = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;

    &.entries {
	  	.item-value {
			color: #3fa731;
	  	}
	}

	&.targets {
	  	.item-value {
			color: #d5b713;
	  	}
	}

	&.stoploss {
	  	.item-value {
			color: #B93F5F;
	  	}
	}

    &.entries,
	&.targets,
	&.stoploss {
	  	.item-value {
			text-shadow: 1px 4px 5px rgba(0, 0, 0, 0.4);
			font-weight: 600;
			letter-spacing: 1px;
	  	}
	}
`

export const SignalBreakPointsGrpLabel = styled.label`
	display: flex;
	align-items: center;
	justify-content: space-between;
    width: calc(100% - 383px);
	margin-bottom: 0;
    color: #B5BECB;

    &.last-price-label {
        width: 100% !important;
    }

    .price-range-wrapper {
        display: flex;
        align-items: center;

        .price-range-icons {
            width: 25px;
            color: rgb(96, 119, 148);

            @media screen and (min-width: 1441px) and (max-width: 1749px) {
                width: 22px;
            }
        }
    }
`

export const SignalBreakGroupCtr = styled.div`
	width: calc(100% - 120px);
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;

	&.targetLists {
		display: grid;
		grid-template-columns: auto auto;
		grid-gap: 10px 20px;
		padding: 10px 0;
	}

	.leverage-value {
		font-weight: bold;
	    color: #FF815C;
	}
`

export const SignalBreakGroupCtrItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

    .rr {
        font-size: 14px;
        margin-left: 4px;
        color: #8C96A2;
    }
`

export const SignalTargetPriceCounter = styled.span`
	padding: 4px;
	border-radius: 2px;
	background-color: ${(props) => props.fill === 100 ? '#f1a92e' : '#ced0dd'};
	color: ${props => props.fill < 100 ? '#414753' : '#17212d'} !important;
	margin-right: 10px;
	font-size: 11px;
	width: 28px;
	text-align: center;
	font-weight: 700;
`

export const MainContentCol = styled.div`
    width: 100%;
    min-height: 300px;
    padding: 20px 20px 0;
`

export const AsideContentCol = styled.div`
    width: 100%;
    min-height: 250px;
    background: ${props => props.isDarkMode ? '#0F1823' : '#DDE3ED !important'};
    background-color:  ${props => props.isDarkMode ? '#0F1823' : '#DDE3ED !important'};
    padding: 0;
`

export const SignalDetailsContentStyle = styled.div`
    display: flex;
    flex-direction: column-reverse;

    @media screen and (min-width: 320px) and (max-width: 766px) {
        display: block;

        ${MainContentCol} {
            width: 100%;
            padding: 0 12px;
            min-height: unset;

            ${SignalDetailsCloseBtn} {
                display: none;
            }

            ${CoinPairInfoWrapper} {
                padding: 0 0 15px;

                ${CoinPairInfoWrapperContent} {
                    ${CryptoCoinName} {
                        span {
                            font-size: 18px;
                        }

                        small {
                            font-size: 13px;
                        }
                    }
                }

                ${SignalStatusContent} {
                    .trade-progress {
                        .trade-status {
                            width: 130px;

                            span {
                                font-size: 12px;
                                padding: 8px;
                            }
                        }
                    }
                }
            }

            ${SignalTimestamp} {
                flex-direction: column;
                align-items: flex-start;

                span {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 14px;
                }
            }

            ${SignalInstructionsSection} {
                padding: 10px 5px;
                font-size: 13px;

                ${SignalBreakPointsGroup} {
                    &.targets {
                        align-items: flex-start;
                    }

                    ${SignalBreakPointsGrpLabel} {
                        width: calc(100% - 220px);
                    }

                    ${SignalBreakGroupCtr} {
                        &.targetLists {
                            grid-template-columns: auto;
                            padding: 0px;
                        }
                    }
                }
            }
        }

        ${AsideContentCol} {
            width: 100%;
            padding: 0 12px 12px;
            background: #192431;
            min-height: 250px;
        }
    }

    @media screen and (min-width: 767px) and (max-width: 1199px) {
        display: block;

        ${MainContentCol} {
            width: 100%;
            padding: 0 20px;
            min-height: 460px;

            ${SignalDetailsCloseBtn} {
                display: none;
            }

            ${CoinPairInfoWrapper} {
                padding: 0 0 15px;

                ${SignalStatusContent} {
                    .trade-progress {
                        .trade-status {
                            width: 190px;

                            span {
                                font-size: 14px;
                                padding: 8px;
                            }
                        }
                    }
                }
            }

            ${SignalTimestamp} {
                flex-direction: column;
                align-items: flex-start;

                span {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }

            ${SignalInstructionsSection} {
                ${SignalBreakPointsGroup} {
                    &.targets {
                        align-items: flex-start;
                    }

                    ${SignalBreakPointsGrpLabel} {
                        width: calc(100% - 330px);
                    }

                    ${SignalBreakGroupCtr} {
                        &.targetLists {
                            grid-template-columns: auto auto;
                            padding: 0px;
                        }
                    }
                }
            }
        }

        ${AsideContentCol} {
            width: 100%;
            padding: 0 20px 20px;
            background: #0F1823;
            min-height: 250px;
        }
    }
`