import styled from 'styled-components'
import { color } from '../../../../../styles/CONSTANTS'

export const SubsButtonWrapper = styled.div`
	margin-top: 20px;

	button {
		&.btn-danger {
			background-color: #993956cc;
		    color: #FFFFFF !important;
		    display: inline-block;
		    font-weight: 400;
		    text-align: center;
		    border: 1px solid #993956 !important;
	        padding: 8px 20px;
		    border-radius: 2px;
		    font-size: 18px !important;
		    width: 250px;

			&:hover {
				color: #fff !important;
				background-color: #993956 !important;
				border-color: #993956 !important;
			}
		}

		&.subs {
			background-color: #0E1824;
			color: #FFFFFF !important;
			display: inline-block;
			font-weight: 400;
			text-align: center;
			border: 1px solid #B5BECB !important;
			padding: 8px 20px;
		    border-radius: 2px;
		    font-size: 18px !important;
		    width: 250px;

			&:hover {
				color: #FFFFFF !important;
				background-color: ${color.primaryBorder} !important;
				border-color: ${color.primaryBorder} !important;
			}

			@media screen and (min-width: 320px) and (max-width: 766px) {
				width: 100%;
			}
		}

		&.cancelled {
			background: #17212d;
			border: none;
			color: #595c5f66;
			cursor: unset;
		
			&:focus {
				box-shadow: none;
			}

			&:active {
				background-color: #17212d !important;
				border: none !important;
				color: #595c5f66 !important;
				box-shadow: none !important;
			}
		}
	}
`

export const SubsTextHeader = styled.p`
	font-size: 18px;
	color: #fff;
	margin-bottom: 25px;

	span {
		font-size: 30px;
	}
`

export const SubscriptionPlanWrapper = styled.div`
	.billing-date {
		color: #6E7885;
	}

	.current-plan-info-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		
		.current-plan-info {
			text-align: center;
			width: auto;
			margin-bottom: 25px;

			p {
				margin-bottom: 0;
			}

			label {
				font-size: 15px;
				color: #616d7c;
			}
		}
		
		.action-btns {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 208px;

			a {
				text-decoration: none;
			}
			
			button, a {
				display: block;
				width: 100%;
				background: none;
				outline: none;
				border: none;
				box-shadow: none;
				font-weight: 600;
				transition: all 0.5s ease 0s;
				text-align: center;

				&.subscription-action-btn {
					background-color: #fff;
					padding: 8px 20px;
					border-radius: 9px;
					margin-bottom: 10px;

					&.upgrade {
						background-image: linear-gradient(to right, #15B16B  0, #00A3DA 100%);
						color: #fff;
					}

					&:disabled {
						background-color: rgba(0,0,0,0.1);
						color: #4d5a6a;
					}

					&:last-child {
						margin-bottom: 0
					}

					&:hover {						
						transform: scale(1.05);

						&:disabled {
							transform: unset;
						}

						&.upgrade {							
							background-image: linear-gradient(to right, #00A3DA  0, #15B16B 100%);
						}
					}
				}
			}
		}
	}

	.subscription-plan-list {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		margin: 0 auto;
		padding: 0;
		list-style-type: none;

		.subscription-plan {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 15px 25px;
			margin-bottom: 20px;
			margin-right: 0;
			width: 100%;
			height: auto;
			border: 2px solid #495b70;
			border-radius: 20px;
			box-shadow: 0.3rem 0.3rem 0.6rem #0f1922, -0.2rem -0.2rem 0.5rem #223040;

			.selector {
				display: none;
				position: absolute;
				width: 28px;
				height: 28px;
				top: -12px;
				right: -12px;
				background-color: #13b079;
				padding: 5px;
				text-align: center;
				border-radius: 14px;

				svg {
					margin-bottom: 2px;
					color: #fff
				}
			}

			label {
				display: flex;
				align-items: center;
				justify-content: space-between;
				color: #fff;
				font-weight: 600;
				font-size: 18px;
				margin-bottom: 0;

				span {
					small {
						color: #aab5c3;
					}
				}

				.price {
					font-size: 17px;
					font-weight: 400;

					strong {
						font-weight: 600;
					}
				}
			}
			
			.btn {
				display: block;
				width: auto;
				border: 2px solid #13b079;
				background: none;
				outline: none;
				box-shadow: none;
				background-color: #0b1219;
				color: #fff;
				margin: 10px 0 0 auto;
				padding: 10px 28px;
				border-radius: 24px;
				text-transform: uppercase;
            	transition: all 0.5s ease 0s;

				&:hover {					
					transform: scale(1.05)
				}
			}

			&.free {
				background: transparent linear-gradient(284deg,#0b1219 0%,#17212d 43%) 0% 0% no-repeat padding-box;
			}

			&.pro {
				background: transparent linear-gradient(284deg,#15bd6f9c 0%,#0b1219 43%) 0% 0% no-repeat padding-box;
				border-color: #1c2836;
			}

			&.active {
				border-color: #13b079;

				.selector {					
					display: block;
				}
			}
			
			&:last-child {
				margin-right: 0
			}
		}
	}

	@media only screen and (min-width: 768px) {
		padding: 0;

		.current-plan-info-wrapper {
			flex-direction: row;

			.current-plan-info {
				text-align: left;
				margin-bottom: 0;
			}
		}
		
		.subscription-plan-list {			
			flex-direction: column;

			.subscription-plan {
				width: 370px;

				label {
					font-size: 28px;
				}
			}
		}
	}

	@media only screen and (min-width: 992px) {
		.subscription-plan-list {
			flex-direction: row;

			.subscription-plan {
				width: 308px;
				padding: 18px 15px;
				margin-right: 20px;
				margin-bottom: 0;
				height: 140px;

				label {
					font-size: 22px;
				}
			}
		}
	}

	@media only screen and (min-width: 1440px) {
		.subscription-plan-list {
			.subscription-plan {
				width: 300px;
				padding: 18px 15px;

				label {
					font-size: 22px;
				}
			}
		}
	}
`