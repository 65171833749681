import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import jQuery from 'jquery'
import { Form, Col } from 'react-bootstrap'
import TextField from '@mui/material/TextField'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Form as FinalForm, Field } from 'react-final-form'
import newFormDecorator from 'final-form-focus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { PrimaryButton } from '../../styles'
import { FormBodyContent, FormActions } from './'
import { onboardingScreen } from '../../constants/onboardingScreen'
import { onboardingActions } from '../../redux/actions'
import { isNull, isNotNull, nameValidator, onSetNewState } from '../../utils'
import { listOfCountries, listOfReferrers } from '../../utils/data'

class BasicInfo extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            firstName: '',
            lastName: '',
            dateOfBirth: new Date(),
            gender: '',
            countryOfResidence: '',
            howDidYouHearAboutUs: '',
            genderOptions: [{
                value: 'MALE',
                text: 'Male'
            }, {
                value: 'FEMALE',
                text: 'Female'
            }, {
                value: 'OTHER',
                text: 'Other'
            }, {
                value: 'NOT_TO_DISCLOSE',
                text: 'Prefer not to disclose'
            }],
            isSubmitting: false,
            data: null
        }
        this.baseState = this.state
        this.mounted = false
        this.formDecorator = newFormDecorator()
    }

    componentDidMount() {
        this.mounted = true
        const { data } = this.props

        if (isNotNull(data)) {
            if (isNull(data.firstName))
                data.firstName = ''

            if (isNull(data.lastName))
                data.lastName = ''

            if (isNull(data.gender))
                data.gender = ''

            if (isNull(data.dateOfBirth))
                data.dateOfBirth = new Date()
            else {
                let parsedDate = Date.parse(data.dateOfBirth)
                data.dateOfBirth = new Date(parsedDate)
            }

            if (isNull(data.countryOfResidence))
                data.countryOfResidence = ''

            if (isNull(data.howDidYouHearAboutUs))
                data.howDidYouHearAboutUs = ''

            onSetNewState(this, {...data})
        }

        window.scrollTo(0, 0)
        jQuery('.focus-on').select()
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { saveOnboardingUserProfile, eventHandlers } = this.props
            const { isSubmitting, data } = this.state
            
            if (prevProps.saveOnboardingUserProfile !== saveOnboardingUserProfile && 
                !saveOnboardingUserProfile.loading && 
                isSubmitting && 
                isNotNull(data)) {
                    onSetNewState(this, {
                        isSubmitting: false
                    }, () => {
                        //console.log(JSON.stringify(data))

                        if (isNotNull(eventHandlers)) {
                            eventHandlers.navigateTo(data, onboardingScreen.TRADING_EXP)
                        }
                    })
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    handleDateChange = (e) => {
        onSetNewState(this, {
            dateOfBirth: moment(e)
        })
    }

    handleSelectGender = (e) => {
        e.preventDefault()

        onSetNewState(this, {
            gender: e.target.value
        })
    }

    handleSelectCountry = (e) => {
        e.preventDefault()

        onSetNewState(this, {
            countryOfResidence: e.target.value
        })
    }

    handleSelectReferrer = (e) => {
        e.preventDefault()

        onSetNewState(this, {
            howDidYouHearAboutUs: e.target.value
        })
    }

    onSubmit = (values) => {
        const { dispatch } = this.props
        const { 
            firstName,
            lastName,
            dateOfBirth,
            gender,
            countryOfResidence,
            howDidYouHearAboutUs
        } = this.state

        let data = {
            firstName,
            lastName,
            dateOfBirth,
            gender,
            countryCode: countryOfResidence,
            howDidYouHearAboutUs
        }

        let $thisDate = moment(data.dateOfBirth).format()
        data.dateOfBirth = moment(data.dateOfBirth).format('DD/MM/yyyy')
        dispatch(onboardingActions.saveBasicInfo(data))

        data.dateOfBirth = $thisDate
        onSetNewState(this, {
            isSubmitting: true,
            data
        })
    }

    onValidate = (values) => {
        const errors = {}

        // validate first name
        if (isNull(values.firstName))
            errors.firstName = "First name is required"
        else {
            if (!nameValidator.isValid(values.firstName))
                errors.firstName = "Please enter a valid first name"
            else {
                onSetNewState(this, {
                    firstName: values.firstName
                })
            }
        }

        // validate last name
        if (isNull(values.lastName))
            errors.lastName = "Last name is required"
        else {
            if (!nameValidator.isValid(values.lastName))
                errors.lastName = "Please enter a valid last name"
            else {
                onSetNewState(this, {
                    lastName: values.lastName
                })
            }
        }

        // validate date of birth
        // must be greater than 18
        if (isNull(values.dateOfBirth))
            errors.dateOfBirth = "Date of birth is required"
        else {
            let isValidDateFormat = moment(values.dateOfBirth, 'DD/MM/yyyy', true).isValid()
            if (!isValidDateFormat) {
                let newDate = new Date(values.dateOfBirth)
                isValidDateFormat = moment(newDate, 'DD/MM/YYYY', true).isValid()
                if (!isValidDateFormat)                    
                    errors.dateOfBirth = "Invalid date"
            } else {                
                let dob = moment(values.dateOfBirth, 'DD/MM/YYYY')
                let currentDate = new Date().toJSON().slice(0,10)+' 01:00:00'
                let age = ~~((Date.now(currentDate) - dob) / (31557600000))
                if (age < 18)
                    errors.dateOfBirth = "Age must be at least 18 years old"
            }
        }

        // validate gender
        if (isNull(values.gender))
            errors.gender = "Please select one"
        else {
            onSetNewState(this, {
                gender: values.gender
            })
        }

        // validate country of residence
        if (isNull(values.countryOfResidence))
            errors.countryOfResidence = "Please select one"
        else {
            onSetNewState(this, {
                countryOfResidence: values.countryOfResidence
            })
        }

        // validate howDidYouHearAboutUs
        if (isNull(values.howDidYouHearAboutUs))
            errors.howDidYouHearAboutUs = "Please select one"
        else {
            onSetNewState(this, {
                howDidYouHearAboutUs: values.howDidYouHearAboutUs
            })
        }

        return errors
    }

    renderGenderOptions = () => {
        let mappedGenderOpts = []

        const { gender, genderOptions } = this.state
        if (isNotNull(genderOptions)) {
            mappedGenderOpts = _.map(genderOptions, (value, key) => {
                return (
                    <option 
                        key={key} 
                        value={value.value}>{value.text}</option>
                )
            })    
        }                                           

        return (
            <Form.Control 
                as="select" 
                value={gender} 
                onChange={(e) => this.handleSelectGender(e)}>
                <option value="">-- select --</option>
                { mappedGenderOpts }                                 
            </Form.Control>
        )
    }

    renderCountryOptions = () => {
        let mappedCountries = []
        
        const { countryOfResidence } = this.state
        if (isNotNull(listOfCountries)) {
            mappedCountries = _.map(listOfCountries, (value, key) => {
                return (
                    <option 
                        key={key} 
                        value={value.code}>{value.name}</option>
                )
            })
        }

        return (            
            <Form.Control 
                as="select" 
                value={countryOfResidence} 
                onChange={(e) => this.handleSelectCountry(e)}>
                <option value="">-- select --</option>
                {mappedCountries}
            </Form.Control>
        )
    }

    renderReferrers = () => {
        let mappedReferrer = []
        
        const { howDidYouHearAboutUs } = this.state
        if (isNotNull(listOfReferrers)) {
            mappedReferrer = _.map(listOfReferrers, (value, key) => {
                return (
                    <option 
                        key={key} 
                        value={value.id}>{value.name}</option>
                )
            })
        }

        return (
            <Form.Control 
                as="select" 
                value={howDidYouHearAboutUs}
                onChange={(e) => this.handleSelectReferrer(e)}>
                <option value="">-- select --</option>
                { mappedReferrer }
            </Form.Control>
        )
    }

    render() {
        let { ...state } = this.state

        return (
            <FinalForm 
                onSubmit={this.onSubmit} 
                validate={this.onValidate} 
                decorators={[this.formDecorator]} 
                initialValues={{...state}}
                render={({ handleSubmit, pristine }) => {
                    return (
                        <Form onSubmit={handleSubmit} noValidate>
                            <FormBodyContent className='basic-info-content'>
                                <div className='graphic basic-info'>
                                    {/* <img src='../images/form-graphics/mp-onboarding-basic-info.png' alt="ProfitFarmers - Basic Info" /> */}
                                </div>

                                <div className='form'>
                                    <h2 className='form-title'>Basic Details</h2>
                                    <h3 className='form-instruction'>Create your profile by adding your personal details</h3>

                                    <Form.Row>
                                        <Form.Group as={Col} xs={12} sm={12} md={6} lg={6} controlId="firstName">
                                            <Form.Label>First name<span className='required'>*</span></Form.Label>
                                            <Field name="firstName" type="text">
                                                {({ input, meta }) => (
                                                    <>
                                                        <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                                            <Form.Control 
                                                                className='focus-on'
                                                                placeholder="Enter your first name"
                                                                {...input}
                                                                autoComplete="off" />

                                                            { meta.error && meta.touched && 
                                                                <Form.Text>
                                                                    {meta.error}
                                                                </Form.Text>
                                                            }
                                                        </div>
                                                    </>
                                                )}
                                            </Field>
                                        </Form.Group>

                                        <Form.Group as={Col} xs={12} sm={12} md={6} lg={6} controlId="lastName">
                                            <Form.Label>Last name<span className='required'>*</span></Form.Label>
                                            <Field name="lastName" type="text">
                                                {({ input, meta }) => (
                                                    <>
                                                        <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                                            <Form.Control 
                                                                placeholder="Enter your last name"
                                                                {...input}
                                                                autoComplete="off" />

                                                            { meta.error && meta.touched && 
                                                                <Form.Text>
                                                                    {meta.error}
                                                                </Form.Text>
                                                            }
                                                        </div>
                                                    </>
                                                )}
                                            </Field>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} xs={12} sm={12} md={4} lg={4} controlId="dateOfBirth">
                                            <Form.Label>Date of birth<span className='required'>*</span></Form.Label>
                                            <Field name="dateOfBirth" type="date">
                                                {({ input, meta }) => (
                                                    <>
                                                        <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    disableFuture 
                                                                    openTo="year"
                                                                    inputFormat='dd/MM/yyyy' 
                                                                    views={['year', 'month', 'day']}
                                                                    value={state.dateOfBirth}
                                                                    onChange={this.handleDateChange}
                                                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                                                />    
                                                            </LocalizationProvider>

                                                            { meta.error && meta.touched && 
                                                                <Form.Text>
                                                                    {meta.error}
                                                                </Form.Text>
                                                            }
                                                        </div>
                                                    </>
                                                )}
                                            </Field>
                                        </Form.Group>

                                        <Form.Group as={Col} xs={6} sm={6} md={4} lg={4} controlId="gender">
                                            <Form.Label>Gender<span className='required'>*</span></Form.Label>
                                            <Field name="gender" type="text">
                                                {({ input, meta }) => (
                                                    <>
                                                        <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                                            { this.renderGenderOptions() }
                                                        </div>
                                                    </>
                                                )}
                                            </Field>
                                        </Form.Group>

                                        <Form.Group as={Col} xs={6} sm={6} md={4} lg={4} controlId="countryOfResidence">
                                            <Form.Label>Country<span className='required'>*</span></Form.Label>
                                            <Field name="countryOfResidence" type="text">
                                                {({ input, meta }) => (
                                                    <>
                                                        <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                                            { this.renderCountryOptions() }
                                                        </div>
                                                    </>
                                                )}
                                            </Field>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Group controlId="howDidYouHearAboutUs">
                                        <Col xs={12} sm={12} md={8} lg={8} className="p-0">
                                            <Form.Label>How did you hear about ProfitFarmers?<span className='required'>*</span></Form.Label>
                                            <Field name="howDidYouHearAboutUs" type="text">
                                                {({ input, meta }) => (
                                                    <>
                                                        <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>                                                            
                                                            { this.renderReferrers() }
                                                        </div>
                                                    </>
                                                )}
                                            </Field>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group className='mb-0 notice-wrapper'>
                                        <p className='notice'>
                                            <FontAwesomeIcon size="xs" className='icon' icon={faExclamationCircle} /> 
                                            <span>Your privacy is our priority. All answers are confidential and we do not share with 3rd parties.</span>
                                        </p>
                                    </Form.Group>
                                </div>
                            </FormBodyContent> 

                            <FormActions>
                                <PrimaryButton 
                                    type="submit" 
                                    size="sm">
                                        <FontAwesomeIcon size="xs" className='icon' icon={faArrowRight} /> 
                                        <span>Next</span>
                                </PrimaryButton>
                            </FormActions> 
                        </Form>
                    )
                }} />
        )   
    }
}

function mapStateToProps(state) {
    let { saveOnboardingUserProfile } = state
    return {
        saveOnboardingUserProfile
    }
}

const connectedBasicInfo = connect(mapStateToProps)(BasicInfo)
export { connectedBasicInfo as BasicInfo }