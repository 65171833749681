// Pages
import { LogInContainer, AutoLoginContainer, TradeAutoContainer } from './LogIn'
import { HomeContainer } from '../pages/Home/HomeContainer'
import { LayoutContainer } from './Shared'
import { NotificationContainer } from './Notification'
import { WalletContainer } from './Wallet'
import { StatisticContainer } from './Statistic'
import { OrderContainer } from './Order'
import { SignUpContainer } from './SignUp'
import { GetStartedContainer } from './GetStarted'
import { PaymentContainer, PaymentCompleteContainer } from './Payment'
import { ForgotPasswordContainer, ForgotPasswordConfirmationContainer } from './ForgotPassword'
import { ResetPasswordConfirmation, ResetPasswordContainer } from './ResetPassword'
import { MoonbagContainer } from './Moonbag'
import { ChangeEmailContainer } from './ChangeEmail'
import { PricingContainer } from './Pricing'

const pageContainers = {
    LayoutContainer,
    LogInContainer,
    HomeContainer,
    NotificationContainer,
    WalletContainer,
    StatisticContainer,
    OrderContainer,
    SignUpContainer,
    GetStartedContainer,
    PaymentContainer,
    PaymentCompleteContainer,
    ForgotPasswordContainer,
    ForgotPasswordConfirmationContainer,
    ResetPasswordConfirmation,
    ResetPasswordContainer,
    AutoLoginContainer,
    TradeAutoContainer,
    MoonbagContainer,
    ChangeEmailContainer,
    PricingContainer
}

export default pageContainers
