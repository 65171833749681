import React, { useEffect, useState }  from 'react'
import { Carousel } from 'react-bootstrap'
import { ThePathWellTroddenSectionStyle } from './styles'
import { mediaDeviceSizes } from '../../../constants/mediaQueries'
import { getSize } from '../../../utils'

export const ThePathWellTroddenSection = () => {
    let [device, setDevice] = useState(null)

    useEffect(() => {
        setDevice(getSize(window.innerWidth))
    }, [])

    return (
        <ThePathWellTroddenSectionStyle>
            <div className='container'>
                <h2 className='section-title'>The Path Well Trodden' - Victory Easily Within Your Grasp</h2>
                <p>Tiger's may hunt alone but you're sharing a crypto killing-ground with many other greats that have come before you. While most traders choose to remain anonymous some bravely come forward to share their insights and success with the tribe:</p>
                
                <Carousel>
                    <Carousel.Item>
                        <div className='text-with-img'>
                            <div className='img'>
                                <img src='/images/layout/kingston.png' alt='' />
                            </div>
                            <div className='text'>
                                <p>King Arthur (that's his actual name!) is truly a techno-tiger champion. Kingston grew his account by <span className='em'>+15% in 4 weeks</span> whilst having 1 hand tied behind his back (no access to our copy-trade and automation tech) and <span className='em'>during some of the most savagely bearish weeks the crypto markets have EVER seen.</span></p>
                                <p className='trade-history-img'>
                                    <img src='/images/layout/kingston-trade-history.png' alt='' />
                                </p>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='text-with-img'>
                            <div className='img'>
                                <img src='/images/layout/tim.png' alt='' />
                            </div>
                            <div className='text'>
                                <p>Tim Griffin, a mental health nurse, made <span className='em'>+400% gains</span> in a <span className='em'>30 day</span> killing spree without having any previous crypto trading experience.</p>
                                <p className='trade-history-img'>
                                    <img src='/images/layout/tim-trade-history.png' alt='' />
                                </p>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='text-with-img'>
                            <div className='img'>
                                <img src='/images/layout/wagner.png' alt='' />
                            </div>
                            <div className='text'>
                                <p>Berhard Wagner, a master gardener (yes, that's a real job!), secured <span className='em'>+1900%</span> across <span className='em'>5 months</span>. Before that, he had spent 6 months losing money and getting rather confused before he found his inner Techno-Tiger and joined ProfitFarmers.</p>
                                <p className='trade-history-img'>
                                    <img src='/images/layout/wagner-trade-history.png' alt='' />
                                </p>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='text-with-img'>
                            <div className='img'>
                                <img src='/images/layout/hooman.png' alt='' />
                            </div>
                            <div className='text'>
                                <p>Hooman Attaran pulled off a mighty <span className='em'>3x</span>, moving his account from <span className='em'>$5,000 to $14,300</span> in a 2 month period. Hooman came to ProfitFarmers after dabbling with stocks and betting. Hooman found his home!</p>
                                <p className='trade-history-img'>
                                    <img src='/images/layout/hooman-trade-history.png' alt='' />
                                </p>
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>

                <h3>What do all of these people have in common? <span className='hl-red'>Nothing.</span></h3>
                <p>That's exactly why we know that YOU can do the same.</p>
                <p>The knowledge you have within The Pro Trader 5x Blueprint PLUS the technology that comes with a 12 month PRO membership is everything you need to succeed.</p>

                <div className='trustpilot-comments-wrapper'>
                    <div className='luka'>
                        <h6>It's no wonder Luka called ProfitFarmers PRO "The Holy Grail of Crypto"</h6>
                        <img src='/images/layout/luka-tp-feedback.png' alt="It's no wonder Luka called ProfitFarmers PRO 'The Holy Grail of Crypto'" />
                    </div>
                    <div className='hans'>
                        <h6>Hans also crushed it and made $20,000.00 in just 3 weeks!</h6>
                        <img src='/images/layout/hans-tp-feedback.png' alt="Hans also crushed it and made $20,000.00 in just 3 weeks!" />
                    </div>
                </div>
            </div>
        </ThePathWellTroddenSectionStyle>
    )
}