import React, { useEffect, useState } from 'react'
import { PremiumFeaturesSectionStyle } from './styles'
import { Carousel } from 'react-bootstrap'
import { mediaDeviceSizes } from '../../../constants/mediaQueries'
import { getSize } from '../../../utils'

export const PremiumFeaturesSection = () => {
    let [device, setDevice] = useState(null)

    useEffect(() => {
        setDevice(getSize(window.innerWidth))
    }, [])

    return (
        <PremiumFeaturesSectionStyle>
            <div className='container'>
                <h2 className='section-title'>LEARN HOW YOU CAN 2X-10X YOUR ACCOUNT 5X FASTER!</h2>
                
                { (device === mediaDeviceSizes.xs || device === mediaDeviceSizes.sm) && 
                    <Carousel controls={false}>
                        <Carousel.Item>
                            <ul className='features-list'>
                                <li className='item'>
                                    <div className='thumbnail'>
                                        <img src='/images/subscriptions/5x-more.png' alt='5x more trading signals' />
                                    </div>
                                    <h5>5x more trading signals</h5>
                                </li>
                                <li className='item'>
                                    <div className='thumbnail'>
                                        <img src='/images/subscriptions/four-targets.png' alt='4x more take-profit targets' />
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='features-list'>
                                <li className='item'>
                                    <div className='thumbnail'>
                                        <img src='/images/subscriptions/four-targets.png' alt='4x more take-profit targets' />
                                    </div>
                                    <h5>4x more take-profit targets</h5>
                                </li>
                                <li className='item'>
                                    <div className='thumbnail'>
                                        <img src='/images/subscriptions/be-sl.png' alt='Break-even Stop-loss' />
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='features-list'>
                                <li className='item'>
                                    <div className='thumbnail'>
                                        <img src='/images/subscriptions/be-sl.png' alt='Break-even Stop-loss' />
                                    </div>
                                    <h5>Break-Even Stop-loss</h5>
                                </li>
                                <li className='item'>
                                    <div className='thumbnail'>
                                        <img src='/images/subscriptions/6x-reward.png' alt='6x the reward profile' />
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>

                        <Carousel.Item>
                            <ul className='features-list'>
                                <li className='item'>
                                    <div className='thumbnail'>
                                        <img src='/images/subscriptions/6x-reward.png' alt='6x the reward profile' />
                                    </div>
                                    <h5>6x the reward profile</h5>
                                </li>
                                <li className='item'>
                                    <div className='thumbnail'>
                                        <img src='/images/subscriptions/5x-more.png' alt='5x more trading signals' />
                                    </div>
                                </li>
                            </ul>
                        </Carousel.Item>
                    </Carousel>
                } 

                { !(device === mediaDeviceSizes.xs || device === mediaDeviceSizes.sm) && 
                    <ul className='feature-thumbnails-list'>
                        <li className='feature'>
                            <div className='thumbnail'>
                                <img src='/images/subscriptions/5x-more.png' alt='5x more trading signals' />
                            </div>
                            <h5>5x more trading signals</h5>
                        </li>
                        <li className='feature'>
                            <div className='thumbnail'>
                                <img src='/images/subscriptions/four-targets.png' alt='4x more take-profit targets' />
                            </div>
                            <h5>4x more take-profit targets</h5>
                        </li>
                        <li className='feature'>
                            <div className='thumbnail'>
                                <img src='/images/subscriptions/be-sl.png' alt='Break-even Stop-loss' />
                            </div>
                            <h5>Break-Even Stop-loss</h5>
                        </li>
                        <li className='feature'>
                            <div className='thumbnail'>
                                <img src='/images/subscriptions/6x-reward.png' alt='6x the reward profile' />
                            </div>
                            <h5>6x the reward profile</h5>
                        </li>
                    </ul>
                }
            </div>
        </PremiumFeaturesSectionStyle>
    )
}