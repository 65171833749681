import { endpoints, baseUrl } from './CONSTANTS'
import { authServices } from './authService'

const { v3 } = endpoints

function getFuturesOpenOrder() {
    return new Promise((resolve, reject) => {
        const requestOpts = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
            //headers: header.addAuthorizationHeader()
        }

        return fetch(`${baseUrl}${v3.user.trade.futures.open}`, requestOpts)
            .then(handleResponse)
            .then(response => resolve(response))
            .catch(err => reject(err));
    })
}

function getOrderPositions() {
    return new Promise((resolve, reject) => {
        const requestOpts = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
            //headers: header.addAuthorizationHeader()
        }

        return fetch(`${baseUrl}${v3.user.trade.futures.positions}`, requestOpts)
            .then(handleResponse)
            .then(response => resolve(response))
            .catch(err => reject(err));
    })
}

function getFuturesOrderHistory() {
    return new Promise((resolve, reject) => {
        const requestOpts = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
            //headers: header.addAuthorizationHeader()
        }

        return fetch(`${baseUrl}${v3.user.trade.futures.history}`, requestOpts)
            .then(handleResponse)
            .then(response => resolve(response))
            .catch(err => reject(err));
    })
}

function getOrderHistory() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${v3.user.trade.spot.history}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function getOpenOrders() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${v3.user.trade.spot.open}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function getOrderDetails(signalId) {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${v3.user.trade.details(signalId)}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function getOrderResult(orderId) {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${v3.user.trade.result(orderId)}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function cancelOrder(openOrderId, feed) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        //headers: header.addAuthorizationHeader(),
        body: JSON.stringify({
            openOrderId: openOrderId,
            feed: feed
        })
    }

    return fetch(`${baseUrl}${v3.user.trade.cancelOrder}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function closePositions(closePositionPayload) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        //headers: header.addAuthorizationHeader(),
        body: JSON.stringify(closePositionPayload)
    }

    return fetch(`${baseUrl}${v3.user.trade.futures.closePosition}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function exitOrder(model) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        //headers: header.addAuthorizationHeader(),
        body: JSON.stringify(model)
    }

    return fetch(`${baseUrl}${v3.user.trade.exitOrder}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function forceExitOrder(data) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        //headers: header.addAuthorizationHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${baseUrl}${v3.user.trade.forceExit}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function forceCancelOrder(data) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        //headers: header.addAuthorizationHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${baseUrl}${v3.user.trade.forceCancel}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        if (res.status === 401)
            authServices.logout()
        else
            return data
    })
}

export const orderServices = {
    getOrderHistory,
    getOpenOrders,
    getOrderDetails,
    getOrderResult,
    cancelOrder,
    exitOrder,
    forceExitOrder,
    forceCancelOrder,
    getFuturesOrderHistory,
    getFuturesOpenOrder,
    getOrderPositions,
    closePositions
}