import React from 'react'
import _ from 'lodash'
import { Form, Col, FormLabel, Alert, SafeAnchor, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { OverlayTooltip } from '../../../../components'
import { TargetPriceInput, TargetAllocationInput } from '../../styled_components'
import { isNull, isNotNull, onSetNewState, validatePrice, ScientificToDecimal } from '../../../../utils'
import { minOrderConfig, tradeFormErrorMessages } from '../../../../utils/CONSTANTS'

export class TakeProfitForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            baseUsdtValue: null,
            isUpdatingUsdtValue: false,
            targets: null,
            isUpdatingTargets: false,
            hasError: false,
            errMsg: '',
            isValidating: false
        }

        this.mounted = false

        this.targetPriceTypingInterval = null
        this.targetPriceQtyTypingInterval = null
    }

    componentDidMount() {
        this.mounted = true
    }

    componentDidUpdate(prevProps, prevState) {
        let { baseUsdtValue, isUpdatingUsdtValue, targets, isUpdatingTargets, isValidating } = this.state
        const { tradeStrategy, usdQtyValue, isNotValidUsdQty, targetPrices, isToUpdateTargetsPrices, isToValidateTargets, eventHandlers } = this.props

        if (isNull(targets)) {
            if (targetPrices.length > 0) {
                targets = []
                _.forEach(targetPrices, (value) => {
                    targets.push(value)
                })

                onSetNewState(this, {
                    targets,
                    isUpdatingTargets: true
                }, () => {
                    eventHandlers.onChangeTargetPricesHandler(targets)
                    onSetNewState(this, {
                        isUpdatingTargets: false
                    })
                })
            }
        } else {
            if (isNull(baseUsdtValue) && isNotNull(usdQtyValue)) {
                onSetNewState(this, {
                    baseUsdtValue: usdQtyValue
                })
            } else {
                if (isNotNull(usdQtyValue) && usdQtyValue !== baseUsdtValue) {
                    isUpdatingUsdtValue = true

                    onSetNewState(this, {
                        isUpdatingUsdtValue,
                        baseUsdtValue: usdQtyValue
                    })

                    if (isUpdatingUsdtValue) {
                        setTimeout(() => {
                            if (isNotValidUsdQty)
                                this.onValidateTargetPriceQtyAllocationHandler()

                            onSetNewState(this, {
                                isUpdatingUsdtValue: false
                            })
                        }, 1000)
                    }
                }
            }

            //console.log('isToUpdateTargetsPrices: ', isToUpdateTargetsPrices)
            if (isToUpdateTargetsPrices && isNotNull(targetPrices) && targetPrices.length > 0) {
                if (JSON.stringify(targets) !== JSON.stringify(targetPrices) && !isUpdatingTargets) {
                    //targets = []

                    // _.forEach(targetPrices, (value) => {
                    //     let currentTarget = _.find(targets, (target) => {
                    //         return target.id === value.id
                    //     })
                    //     if (currentTarget) {
                    //         currentTarget.stopPrice = value.stopPrice
                    //     }

                    //     //targets.push(value)
                    // })

                    targets = []
                    _.forEach(targetPrices, (value) => {
                        targets.push(value)
                    })

                    switch (tradeStrategy) {
                        default:
                        case 'basic':
                        case 'aggressivestoploss': {
                            targets[0].quantity = 40
                            targets[1].quantity = 30
                            targets[2].quantity = 15
                            targets[3].quantity = 15

                            break
                        }
                        case 'rrhunter': {
                            targets[0].quantity = 50
                            targets[1].quantity = 50

                            break
                        }
                        case 'sniper': {
                            targets[0].quantity = 40
                            targets[1].quantity = 60
                            break
                        }
                        case 'stronguptrend': {
                            targets[0].quantity = 15
                            targets[1].quantity = 35
                            targets[2].quantity = 15
                            targets[3].quantity = 35

                            break
                        }
                        case 'weakuptrend': {
                            targets[0].quantity = 30
                            targets[1].quantity = 40
                            targets[2].quantity = 10
                            targets[3].quantity = 20

                            break
                        }
                        case 'heikoaggressive': 
                        case 'moonbag': {
                            targets[0].quantity = 100
                            break
                        }
                    }

                    // switch(targets.length) {
                    //     default: {
                    //         switch (tradeStrategy) {
                    //             default: {
                    //                 targets[0].quantity = 70
                    //                 targets[1].quantity = 30
                    //                 break
                    //             }
                    //             case 'def': {
                    //                 targets[0].quantity = 75
                    //                 targets[1].quantity = 25
                    //                 break
                    //             }
                    //             case 'agr': {
                    //                 targets[0].quantity = 60
                    //                 targets[1].quantity = 40
                    //                 break
                    //             }
                    //         }
                    //         break
                    //     }
                    //     case 3: {                            
                    //         switch (tradeStrategy) {
                    //             default: {                            
                    //                 targets[0].quantity = 60
                    //                 targets[1].quantity = 30
                    //                 targets[2].quantity = 10
                    //                 break
                    //             }
                    //             case 'def': {
                    //                 targets[0].quantity = 65
                    //                 targets[1].quantity = 25
                    //                 targets[2].quantity = 10
                    //                 break
                    //             }
                    //             case 'agr': {
                    //                 targets[0].quantity = 50
                    //                 targets[1].quantity = 30
                    //                 targets[2].quantity = 20
                    //                 break
                    //             }
                    //         }
                    //         break
                    //     }
                    //     case 4: {
                    //         targets[0].quantity = 40
                    //         targets[1].quantity = 30
                    //         targets[2].quantity = 15
                    //         targets[3].quantity = 15
                    //         break
                    //     }
                    //     case 1: {  
                    //         targets[0].quantity = 100
                    //         break
                    //     }
                    // }

                    onSetNewState(this, {
                        targets,
                        isUpdatingTargets: true
                    }, () => {
                        eventHandlers.onChangeTargetPricesHandler(targets)
                        eventHandlers.onDoneChangingTargetPricesHandler()

                        onSetNewState(this, {
                            isUpdatingTargets: false
                        })

                        this.onValidate()
                    })
                }
            }
        }        

        if (prevProps.isToValidateTargets !== isToValidateTargets) {
            //console.log('prev isToValidateTargets: ', prevProps.isToValidateTargets)
            //console.log('now isToValidateTargets: ', isToValidateTargets)
            if (isToValidateTargets) {                
                if (!isValidating) {
                    onSetNewState(this, {
                        isValidating: true
                    }, async () => {
                        await this.onValidate()
                        eventHandlers.onDoneValidatingTargets()
                    })
                }
            }
        }

        // if (prevState.isValidating !== isValidating) {
        //     if (isNotNull(targets) && isValidatingTargets) {
        //         if (!isValidating) {
        //             isValidating = true
    
        //             onSetNewState(this, {
        //                 isValidating
        //             }, () => {
        //                 this.onValidate()
        //             })
        //         }
        //     }
        // }
    }

    componentWillUnmount() {
        clearTimeout(this.targetPriceTypingInterval)
        this.targetPriceTypingInterval = null

        clearTimeout(this.targetPriceQtyTypingInterval)
        this.targetPriceQtyTypingInterval = null

        this.mounted = false
    }

    onAllowNumbersOnlyHandler = (e) => {
        let { value } = e.target

        if (e.charCode === 46) {
            if (value.indexOf(".") > -1) {
                e.preventDefault()
            }
        }

        if (!(/^0*(1?[0-9]|20)?[0-9]{0,6}(\.[0-9]{0,8}0*)?$/).test(e.key))
            e.preventDefault()
    }

    onChangeTargetPriceHandler = (e, index) => {
        e.preventDefault()

        const { usdQtyValue, tradeStrategy, eventHandlers } = this.props
        if (isNull(usdQtyValue)) {
            eventHandlers.onValidateQtyInUsdtHandler()
        } else {
            let { targets } = this.state

            if (this.targetPriceTypingInterval)
                clearTimeout(this.targetPriceTypingInterval)

            let newValue = e.target.value
            let editingTarget = _.find(targets, (t) => {
                return t.index === index
            })
            if (editingTarget) {
                editingTarget.stopPrice = newValue
                onSetNewState(this, {
                    targets
                })
            }

            if (tradeStrategy === 'opt') {
                if (targets.length > 1) {
                    index--
                } else
                    index = index - 2
            } else
                index--

            this.targetPriceTypingInterval = setTimeout(() => {
                this.onValidateTargetPriceHandler(newValue, index)
            }, 800)
        }
    }

    onValidateTargetPriceHandler = (value, targetIndex) => {
        const { bidPrice, direction, eventHandlers } = this.props
        let { targets } = this.state
        let dir = direction.toLowerCase()

        let hasInValid = false

        for (let index = 0; index < targets.length; index++) {
            targets[index].isNotValid = false
            targets[index].errMsg = ""
        }

        let index = targetIndex
        if (targets.length > 1) {
            while (index < targets.length) {
                if (index === 0) {
                    if (isNaN(parseFloat(targets[index].stopPrice))) {
                        targets[index].isNotValid = true
                        targets[index].errMsg = tradeFormErrorMessages.multiTargets.invalidTarget(index)
                        break
                    } else {
                        if (dir === 'buy') {
                            if (parseFloat(targets[index].stopPrice) <= bidPrice) { // target price must be greater than bid price
                                targets[index].isNotValid = true
                                targets[index].errMsg = tradeFormErrorMessages.multiTargets.mustBeGreaterThanBidPrice(index)
        
                                break
                            } else {
                                targets[index].isNotValid = false
                                targets[index].errMsg = ''
                            }
                        } else {
                            if (parseFloat(targets[index].stopPrice) >= bidPrice) { // target price must be less than bid price
                                targets[index].isNotValid = true
                                targets[index].errMsg = tradeFormErrorMessages.multiTargets.short.mustBeLessThanBidPrice(index)
        
                                break
                            } else {
                                targets[index].isNotValid = false
                                targets[index].errMsg = ''
                            }
                        }
                    }
                } else {
                    // target price must be greater than the previous target
                    let previousTarget = targets[index - 1].stopPrice
                    if (isNaN(parseFloat(previousTarget))) {
                        break
                    } else {
                        if (dir === 'buy') {
                            if (parseFloat(targets[index].stopPrice) <= parseFloat(previousTarget)) {
                                targets[index].isNotValid = true
                                targets[index].errMsg = tradeFormErrorMessages.multiTargets.mustBeGreaterThanPreviousTarget(index)
        
                                break
                            } else {
                                targets[index].isNotValid = false
                                targets[index].errMsg = ''
                            }
                        } else {
                            if (parseFloat(targets[index].stopPrice) >= parseFloat(previousTarget)) {
                                targets[index].isNotValid = true
                                targets[index].errMsg = tradeFormErrorMessages.multiTargets.short.mustBeLessThanPreviousTarget(index)
        
                                break
                            } else {
                                targets[index].isNotValid = false
                                targets[index].errMsg = ''
                            }
                        }
                    }
                }

                index++
            }
        } else {
            let currentTarget = targets[index]
            if (isNaN(parseFloat(currentTarget.stopPrice))) {
                currentTarget.isNotValid = true
                currentTarget.errMsg = tradeFormErrorMessages.singleTarget.invalidTarget
            } else {
                if (dir === 'buy') {
                    if (parseFloat(currentTarget.stopPrice) <= bidPrice) {
                        currentTarget.isNotValid = true
                        currentTarget.errMsg = tradeFormErrorMessages.singleTarget.mustBeGreaterThanBidPrice
                    }
                } else {
                    if (parseFloat(currentTarget.stopPrice) >= bidPrice) {
                        currentTarget.isNotValid = true
                        currentTarget.errMsg = tradeFormErrorMessages.singleTarget.mustBeLessThanBidPrice
                    }
                }
            }
        }

        onSetNewState(this, {
            targets
        })

        hasInValid = _.find(targets, (currentObject) => {
            return currentObject.isNotValid === true
        })

        if (hasInValid) {
            onSetNewState(this, {
                targets
            })

            eventHandlers.onSetTargetPriceValidityStatus(true)
        } else {
            onSetNewState(this, {
                targets,
                hasError: false,
                errMsg: "",
                isValidating: false
            })

            eventHandlers.onChangeTargetPricesHandler(targets)
        }

        this.onValidate()

        return hasInValid
    }

    onChangeTargetPriceValueHandler = (e, index) => {
        e.preventDefault()

        const { usdQtyValue, eventHandlers } = this.props
        if (isNull(usdQtyValue)) {
            eventHandlers.onValidateQtyInUsdtHandler()
        } else {
            if (this.targetPriceQtyTypingInterval)
                clearTimeout(this.targetPriceQtyTypingInterval)

            let newValue = e.target.value
            let { targets } = this.state

            let currentTarget = _.find(targets, (target) => {
                return target.index === index
            })
            if (currentTarget) {
                currentTarget.quantity = isNotNull(newValue) && newValue !== '' && newValue !== '.' ? parseFloat(newValue) : ''

                onSetNewState(this, {
                    targets
                })

                this.targetPriceQtyTypingInterval = setTimeout(() => {
                    this.onValidateTargetPriceQtyHandler(newValue, index)
                }, 800)
            }
        }
    }

    onValidateTargetPriceQtyHandler = (value, targetIndex) => {
        const { eventHandlers } = this.props
        const { targets } = this.state
        let { hasError, errMsg } = this.state
        hasError = false
        errMsg = ""

        let total = _.sumBy(targets, 'quantity')

        for (let index = 0; index < targets.length; index++) {
            targets[index].isQtyNotValid = false
        }


        //let index = targetIndex
        let index = 0
        if (targets.length > 1) {
            while (index <= targets.length) {
                let empty = _.find(targets, (currentObject) => {
                    return currentObject.quantity === ""
                })
                if (empty) {
                    hasError = true
                    break
                } else {
                    if (value === 0) {
                        hasError = true
                    } else {
                        if (total < 100 || total > 100) {
                            hasError = true
                        }
                    }
                }

                index++
            }

            if (hasError) {
                errMsg = "Total target price quantities should be equal to 100%"

                for (let index = 0; index < targets.length; index++) {
                    targets[index].isQtyNotValid = true
                }

                onSetNewState(this, {
                    targets,
                    hasError,
                    errMsg
                })

                eventHandlers.onSetTargetPriceValidityStatus(true)
            } else {
                for (let index = 0; index < targets.length; index++) {
                    targets[index].isQtyNotValid = false
                }

                onSetNewState(this, {
                    targets,
                    hasError: false,
                    errMsg: ""
                })

                // check each target qty allocation
                setTimeout(() => {
                    this.onValidateTargetPriceQtyAllocationHandler()
                }, 1000)
            }
        } else {
            let currentTarget = targets[0]

            if (isNaN(parseFloat(value))) {
                currentTarget.isQtyNotValid = true
                hasError = true

                onSetNewState(this, {
                    targets,
                    hasError,
                    errMsg: "Total target price quantities should be equal to 100%"
                })
            } else {
                if (parseFloat(value) < 100 || parseFloat(value) > 100) {
                    currentTarget.isQtyNotValid = true
                    hasError = true

                    onSetNewState(this, {
                        targets,
                        hasError,
                        errMsg: "Total target price quantities should be equal to 100%"
                    })
                } else {
                    for (let index = 0; index < targets.length; index++) {
                        targets[index].isQtyNotValid = false
                    }

                    onSetNewState(this, {
                        targets,
                        hasError: false,
                        errMsg: ""
                    })
                }
            }
        }

        if (hasError) {
            eventHandlers.onSetTargetPriceValidityStatus(true)
        } else {
            eventHandlers.onChangeTargetPricesHandler(targets)
        }
    }

    onValidateTargetPriceQtyAllocationHandler = () => {
        const { symbol, usdQtyValue, altQtyValue, eventHandlers } = this.props
        const { targets } = this.state
        let { hasError, errMsg } = this.state
        hasError = false
        errMsg = ""

        let isUsdSignal = (symbol.endsWith("USDT") || symbol.endsWith("USD")) ? true : false
        let curr
        if (isUsdSignal) {
            curr = symbol.endsWith("USDT") ? 'USDT' : "BUSD"
        }

        let i = 0
        while (i < targets.length) {
            if (isUsdSignal) {
                let percentage = parseFloat(parseFloat(targets[i].quantity) / 100)
                let allocatedQty = parseFloat(parseFloat(usdQtyValue * percentage)).toFixed(2)
                if (allocatedQty < minOrderConfig.USDT) {
                    targets[i].isQtyNotValid = true
                    hasError = true
                    errMsg = `Sorry, the minimum order size for ${curr} paired coins is ${curr === 'USDT' ? `$${minOrderConfig.USDT}` : `${minOrderConfig.USDT} BUSD`}.
                        You only have ${allocatedQty} ${curr} allocated in T${targets[i].index}.
                        Please increase the total quantity or increase the % allocated to this target and try again.`

                    break
                }
            } else {
                if (altQtyValue > 0) {
                    let percentage = parseFloat(parseFloat(targets[i].quantity) / 100)
                    let allocatedQty = parseFloat(parseFloat(altQtyValue * percentage)).toFixed(8)
                    if (allocatedQty < minOrderConfig.BTC) {
                        targets[i].isQtyNotValid = true

                        hasError = true
                        errMsg = `Sorry, the minimum order size for BTC paired coins is ${minOrderConfig.BTC} BTC.
                            You only have ${allocatedQty} BTC allocated in T${targets[i].index}.
                            Please increase the total quantity or increase the % allocated to this target and try again.`

                        break
                    }
                }
            }

            i++
        }

        if (hasError) {
            onSetNewState(this, {
                targets,
                hasError,
                errMsg
            })

            eventHandlers.onSetTargetPriceValidityStatus(true)
        } else {
            for (let index = 0; index < targets.length; index++) {
                targets[index].isQtyNotValid = false
            }

            onSetNewState(this, {
                targets,
                hasError: false,
                errMsg: ""
            })
        }
    }

    onValidate = async () => {
        const { symbol, usdQtyValue, altQtyValue, bidPrice, direction, eventHandlers } = this.props
        let { isValidating } = this.state
        let { targets, hasError, errMsg } = this.state
        let dir = direction.toLowerCase()
        hasError = false
        errMsg = ""
        //isValidating = true

        onSetNewState(this, {
            //isValidating,
            hasError,
            errMsg
        })

        if (isNotNull(targets)) {            
            let total = _.sumBy(targets, 'quantity')
            if (targets.length > 1) {
                let index = 0

                // checking target prices for validity
                let hasInvalidTargetPrice = false
                while (index < targets.length) {
                    if (index === 0) {
                        if (isNaN(parseFloat(targets[index].stopPrice))) {
                            targets[index].isNotValid = true
                            targets[index].errMsg = tradeFormErrorMessages.multiTargets.invalidTarget(index)

                            hasInvalidTargetPrice = true
                            break
                        } else {
                            if (dir === 'buy') {
                                if (parseFloat(targets[index].stopPrice) <= bidPrice) { // target price must be greater than bid price
                                    targets[index].isNotValid = true
                                    targets[index].errMsg = tradeFormErrorMessages.multiTargets.mustBeGreaterThanBidPrice(index)
            
                                    hasInvalidTargetPrice = true
                                    break
                                }
                            } else {
                                if (parseFloat(targets[index].stopPrice) >= bidPrice) { // target price must be less than bid price
                                    targets[index].isNotValid = true
                                    targets[index].errMsg = tradeFormErrorMessages.multiTargets.short.mustBeLessThanBidPrice(index)
            
                                    hasInvalidTargetPrice = true
                                    break
                                }
                            }
                        }
                    } else {
                        // target price must be greater than the previous target
                        let previousTarget = targets[index - 1].stopPrice

                        if (isNaN(parseFloat(previousTarget))) {
                            targets[index].isNotValid = true
                            targets[index].errMsg = tradeFormErrorMessages.multiTargets.invalidTarget(index)

                            hasInvalidTargetPrice = true
                            break
                        } else {
                            if (dir === 'buy') {
                                if (parseFloat(targets[index].stopPrice) <= parseFloat(previousTarget)) {
                                    targets[index].isNotValid = true
                                    targets[index].errMsg = tradeFormErrorMessages.multiTargets.mustBeGreaterThanPreviousTarget(index)
            
                                    hasInvalidTargetPrice = true
                                    break
                                }
                            } else {
                                if (parseFloat(targets[index].stopPrice) >= parseFloat(previousTarget)) {
                                    targets[index].isNotValid = true
                                    targets[index].errMsg = tradeFormErrorMessages.multiTargets.short.mustBeLessThanPreviousTarget(index)
            
                                    hasInvalidTargetPrice = true
                                    break
                                }
                            }
                        }
                    }

                    index++
                }
                
                if (hasInvalidTargetPrice) {
                    onSetNewState(this, {
                        targets,
                        isValidating: false
                    }, () => {
                        eventHandlers.onSetTargetPriceValidityStatus(true)
                    })
                } else {
                    // when target prices are correct and sorted correctly
                    // check the total quantity
                    for (let index = 0; index < targets.length; index++) {
                        targets[index].isQtyNotValid = false
                    }

                    index = 1
                    while (index <= targets.length) {
                        let empty = _.find(targets, (currentObject) => {
                            return currentObject.quantity === ""
                        })
                        if (empty) {
                            hasError = true
                            break
                        } else {
                            let currentTarget = targets[index - 1]
                            if (currentTarget.quantity === 0) {
                                hasError = true
                                break
                            } else {
                                if (total < 100 || total > 100) {
                                    hasError = true
                                    break
                                }
                            }
                        }

                        index++
                    }

                    if (hasError) {
                        errMsg = "Total target price quantities should be equal to 100%"

                        for (let index = 0; index < targets.length; index++) {
                            targets[index].isQtyNotValid = true
                        }

                        onSetNewState(this, {
                            targets,
                            hasError,
                            errMsg,
                            isValidating: false
                        }, () => {
                            eventHandlers.onSetTargetPriceValidityStatus(true)
                        })
                    } else {
                        for (let index = 0; index < targets.length; index++) {
                            targets[index].isQtyNotValid = false
                        }

                        index = 0
                        let isUsdSignal = (symbol.endsWith("USDT") || symbol.endsWith("USD")) ? true : false
                        let curr
                        if (isUsdSignal) {
                            curr = symbol.endsWith("USDT") ? 'USDT' : 'BUSD'
                        }

                        while (index < targets.length) {
                            if (isUsdSignal) {
                                let percentage = parseFloat(parseFloat(targets[index].quantity) / 100)
                                let allocatedQty = parseFloat(parseFloat(usdQtyValue * percentage)).toFixed(2)
                                if (allocatedQty < minOrderConfig.USDT) {
                                    targets[index].isQtyNotValid = true

                                    hasError = true
                                    errMsg = `Sorry, the minimum order size for ${curr} paired coins is ${curr === 'USDT' ? `$${minOrderConfig.USDT}` : `${minOrderConfig.USDT} BUSD`}.
                                        You only have ${allocatedQty} ${curr} allocated in T${targets[index].index}.
                                        Please increase the total quantity or increase the % allocated to this target and try again.`

                                    break
                                }
                            } else {
                                let percentage = parseFloat(parseFloat(targets[index].quantity) / 100)
                                let allocatedQty = parseFloat(parseFloat(altQtyValue * percentage).toFixed(8))
                                if (allocatedQty < minOrderConfig.BTC) {
                                    targets[index].isQtyNotValid = true

                                    hasError = true
                                    errMsg = `Sorry, the minimum order size for BTC paired coins is ${minOrderConfig.BTC} BTC.
                                        You only have ${allocatedQty} BTC allocated in T${targets[index].index}.
                                        Please increase the total quantity or increase the % allocated to this target and try again.`

                                    break
                                }
                            }

                            index++
                        }
                        
                        if (hasError) {
                            onSetNewState(this, {
                                targets,
                                hasError,
                                errMsg,
                                isValidating: false
                            }, () => {
                                eventHandlers.onSetTargetPriceValidityStatus(true)
                            })
                        } else {
                            for (let index = 0; index < targets.length; index++) {
                                targets[index].isQtyNotValid = false
                            }
                            
                            if (isValidating) {
                                onSetNewState(this, {
                                    targets,
                                    hasError: false,
                                    errMsg: "",
                                    isValidating: false
                                })
                                eventHandlers.onChangeTargetPricesHandler(targets)
                            }
                        }
                    }
                }
            } else {
                let hasInvalidTargetPrice = false
                let currentTarget = targets[0]

                if (isNaN(parseFloat(currentTarget.stopPrice))) {
                    currentTarget.isNotValid = true
                    currentTarget.errMsg = tradeFormErrorMessages.singleTarget.invalidTarget

                    hasInvalidTargetPrice = true
                } else {
                    if (dir === 'buy') {                    
                        if (parseFloat(currentTarget.stopPrice) <= bidPrice) {
                            currentTarget.isNotValid = true
                            currentTarget.errMsg = tradeFormErrorMessages.singleTarget.mustBeGreaterThanBidPrice
                            hasInvalidTargetPrice = true
                        }
                    } else {                    
                        if (parseFloat(currentTarget.stopPrice) >= bidPrice) {
                            currentTarget.isNotValid = true
                            currentTarget.errMsg = tradeFormErrorMessages.singleTarget.mustBeLessThanBidPrice
                            hasInvalidTargetPrice = true
                        }
                    }
                }
                
                if (hasInvalidTargetPrice) {
                    onSetNewState(this, {
                        targets,
                        isValidating: false
                    }, () => {
                        eventHandlers.onSetTargetPriceValidityStatus(true)
                    })
                } else {
                    for (let index = 0; index < targets.length; index++) {
                        targets[index].isQtyNotValid = false
                    }

                    if (isNaN(parseFloat(currentTarget.quantity))) {
                        currentTarget.isQtyNotValid = true
                        hasError = true
                        
                        onSetNewState(this, {
                            targets,
                            hasError,
                            errMsg: "Total target price quantities should be equal to 100%",
                            isValidating: false
                        }, () => {
                            eventHandlers.onSetTargetPriceValidityStatus(true)
                        })
                    } else {
                        if (parseFloat(currentTarget.quantity) < 100 || parseFloat(currentTarget.quantity) > 100) {
                            currentTarget.isQtyNotValid = true
                            hasError = true
                            
                            onSetNewState(this, {
                                targets,
                                hasError,
                                errMsg: "Total target price quantities should be equal to 100%",
                                isValidating: false
                            }, () => {
                                eventHandlers.onSetTargetPriceValidityStatus(true)
                            })
                        } else {
                            let isUsdSignal = (symbol.endsWith("USDT") || symbol.endsWith("USD")) ? true : false
                            let curr
                            if (isUsdSignal) {
                                curr = symbol.endsWith("USDT") ? 'USDT' : 'BUSD'
                            }

                            if (isUsdSignal) {
                                let percentage = parseFloat(parseFloat(currentTarget.quantity) / 100)
                                let allocatedQty = parseFloat(parseFloat(usdQtyValue * percentage)).toFixed(2)
                                if (allocatedQty < minOrderConfig.USDT) {
                                    currentTarget.isQtyNotValid = true

                                    hasError = true
                                    errMsg = `Sorry, the minimum order size for ${curr} paired coins is ${curr === 'USDT' ? `$${minOrderConfig.USDT}` : `${minOrderConfig.USDT} BUSD`}.
                                        You only have ${allocatedQty} ${curr} allocated in T${currentTarget.index}.
                                        Please increase the total quantity or increase the % allocated to this target and try again.`
                                }
                            } else {
                                let percentage = parseFloat(parseFloat(currentTarget.quantity) / 100)
                                let allocatedQty = parseFloat(parseFloat(altQtyValue * percentage)).toFixed(8)
                                if (allocatedQty < minOrderConfig.BTC) {
                                    currentTarget.isQtyNotValid = true

                                    hasError = true
                                    errMsg = `Sorry, the minimum order size for BTC paired coins is ${minOrderConfig.BTC} BTC.
                                        You only have ${allocatedQty} BTC allocated in T${currentTarget.index}.
                                        Please increase the total quantity or increase the % allocated to this target and try again.`
                                }
                            }

                            if (hasError) {
                                onSetNewState(this, {
                                    targets,
                                    hasError,
                                    errMsg,
                                    isValidating: false
                                }, () => {                                
                                    eventHandlers.onSetTargetPriceValidityStatus(true)
                                })
                            } else {
                                for (let index = 0; index < targets.length; index++) {
                                    targets[index].isQtyNotValid = false
                                }

                                if (isValidating) {
                                    onSetNewState(this, {
                                        targets,
                                        hasError: false,
                                        errMsg: "",
                                        isValidating: false
                                    }, () => {
                                        eventHandlers.onChangeTargetPricesHandler(targets)
                                    })
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    onAddTargetPriceHandler = (e) => {
        e.preventDefault()

        const { 
            isPremiumUser,
            isFree,
            usdQtyValue, 
            tradeStrategy,
            originalTargets, 
            eventHandlers } = this.props

        if (isNull(usdQtyValue)) {
            eventHandlers.onValidateQtyInUsdtHandler()
        } else {
            let { targets } = this.state

            onSetNewState(this, {
                hasError: false,
                errMsg: ""
            })

            if (isNotNull(originalTargets) && isNotNull(targets)) {
                for (let index = 0; index < originalTargets.length; index++) {
                    let exists = _.find(targets, (currentObject) => {
                        return currentObject.index === originalTargets[index].index
                    })
                    if (isNull(exists)) {
                        let newTarget = originalTargets[index]
                        
                        if (isFree && !isPremiumUser) {
                            newTarget.stopPrice = ''
                        }

                        delete newTarget.relativeStopPrice
                        newTarget.isNotValid = false
                        newTarget.errMsg = ""
                        targets.splice(index, 0, newTarget)
                        break
                    }
                }

                switch(targets.length) {
                    default: { 
                        targets[0].quantity = 100
                        break
                    }
                    case 2: {
                        switch (tradeStrategy) {
                            default: {
                                targets[0].quantity = 70
                                targets[1].quantity = 30
                                break
                            }
                            case 'rrhunter': {
                                targets[0].quantity = 50
                                targets[1].quantity = 50
                                break
                            }
                            case 'sniper': {
                                targets[0].quantity = 40
                                targets[1].quantity = 60
                                break
                            }
                        }

                        break
                    }
                    case 3: {
                        switch (tradeStrategy) {
                            default: {
                                targets[0].quantity = 60
                                targets[1].quantity = 30
                                targets[2].quantity = 10
                                break
                            }
                            case 'def': {
                                targets[0].quantity = 65
                                targets[1].quantity = 25
                                targets[2].quantity = 10
                                break
                            }
                            case 'agr': {
                                targets[0].quantity = 50
                                targets[1].quantity = 30
                                targets[2].quantity = 20
                                break
                            }
                        }

                        break
                    }
                    case 4: {
                        switch (tradeStrategy) {
                            default:
                            case 'basic':
                            case 'aggressivestoploss': {
                                targets[0].quantity = 40
                                targets[1].quantity = 30
                                targets[2].quantity = 15
                                targets[3].quantity = 15

                                break
                            }
                            case 'stronguptrend': {
                                targets[0].quantity = 15
                                targets[1].quantity = 35
                                targets[2].quantity = 15
                                targets[3].quantity = 35

                                break
                            }
                            case 'weakuptrend': {
                                targets[0].quantity = 20
                                targets[1].quantity = 40
                                targets[2].quantity = 10
                                targets[3].quantity = 20

                                break
                            }
                        }

                        break
                    }
                }

                this.onValidate()
            }
        }
    }

    onRemoveTargetPriceHandler = (e, index) => {
        e.preventDefault()

        const { usdQtyValue, tradeStrategy, eventHandlers } = this.props
        if (isNull(usdQtyValue)) {
            eventHandlers.onValidateQtyInUsdtHandler()
        } else {
            let { targets } = this.state

            _.remove(targets, (currentObject) => {
                return currentObject.index === index
            })

            switch(targets.length) {
                default: { 
                    targets[0].quantity = 100
                    break
                }
                case 2: {
                    switch (tradeStrategy) {
                        default: {
                            targets[0].quantity = 70
                            targets[1].quantity = 30
                            break
                        }
                        case 'rrhunter': {
                            targets[0].quantity = 50
                            targets[1].quantity = 50
                            break
                        }
                        case 'sniper': {
                            targets[0].quantity = 40
                            targets[1].quantity = 60
                            break
                        }
                    }

                    break
                }
                case 3: {
                    switch (tradeStrategy) {
                        default: {
                            targets[0].quantity = 60
                            targets[1].quantity = 30
                            targets[2].quantity = 10
                            break
                        }
                        case 'def': {
                            targets[0].quantity = 65
                            targets[1].quantity = 25
                            targets[2].quantity = 10
                            break
                        }
                        case 'agr': {
                            targets[0].quantity = 50
                            targets[1].quantity = 30
                            targets[2].quantity = 20
                            break
                        }
                    }

                    break
                }
                case 4: {
                    switch (tradeStrategy) {
                        default:
                        case 'basic':
                        case 'aggressivestoploss': {
                            targets[0].quantity = 40
                            targets[1].quantity = 30
                            targets[2].quantity = 15
                            targets[3].quantity = 15

                            break
                        }
                        case 'stronguptrend': {
                            targets[0].quantity = 15
                            targets[1].quantity = 35
                            targets[2].quantity = 15
                            targets[3].quantity = 35

                            break
                        }
                        case 'weakuptrend': {
                            targets[0].quantity = 20
                            targets[1].quantity = 40
                            targets[2].quantity = 10
                            targets[3].quantity = 20

                            break
                        }
                    }

                    break
                }
            }
            
            eventHandlers.onValidateQtyInUsdtHandler(usdQtyValue)
            this.onValidate()
        }
    }

    renderOverlayText = (type) => {
        const text = type === "TARGET" ? "Percentage Gain per target" : "Stop Loss percentage"
        return (
            <Tooltip className="percentage-tooltip" style={{ zIndex: '9999' }}>
                <i className="fas fa-info-circle"></i>
                <span className="ml-2">{text}</span>
            </Tooltip >
        )
    }

    renderTargetGains = (direction, entryPriceValue, targetPriceValue) => {
        const { isBuyNow, isLadderingEnabled, lastPrice, myEntryPrice } = this.props
        const valueChecker = (value) => isNotNull(value) && isFinite(value) ? value : 0

        if (isNotNull(direction) &&
            isNotNull(targetPriceValue) && 
            isNotNull(isLadderingEnabled)) {
                const targetPrice = parseFloat(targetPriceValue)
                let entryPrice; // = !isLadderingEnabled && isBuyNow ? parseFloat(lastPrice) : parseFloat(entryPriceValue)
                if (isLadderingEnabled) {
                    entryPrice = parseFloat(myEntryPrice)
                } else {
                    if (isBuyNow) {
                        entryPrice = parseFloat(lastPrice)
                    } else {
                        entryPrice = parseFloat(entryPriceValue)
                    }
                }
                
                //const entryPrice = parseFloat(entryPriceValue)
                if (direction.toUpperCase() === "BUY") {
                    const total = _.round(((targetPrice - entryPrice) / entryPrice) * 100, 2)
                    return (<span className="target-gains">{valueChecker(total)} %</span>)
                } else {
                    const total = _.round(((entryPrice - targetPrice) / targetPrice) * 100, 2)
                    return (<span className="target-gains">{valueChecker(total)} %</span>)
                }
        } else
            return (<span className="target-gains">{0}%</span>)
    }

    render() {
        const { isPremiumUser, isLadderingEnabled, direction, entryPriceValue, myEntryPrice, myStoplossPrice } = this.props
        const { targets, hasError, errMsg } = this.state

        return (
            <>
                { hasError &&
                    <div>
                        <Alert variant="danger" style={{ padding: '8px 12px', marginBottom: '10px' }}>
                            <p style={{ marginBottom: 0 }}>
                                {errMsg}
                            </p>
                        </Alert>
                    </div>
                }

                { isNotNull(targets) &&
                    targets.map((item, key) => {
                        let m2t = ''
                        let m2s = ''
                        let rr = ''

                        if (isNotNull(item.stopPrice) && 
                            isNotNull(myEntryPrice) && 
                            isNotNull(myStoplossPrice)) {
                            let ep;
                            if (isLadderingEnabled) {
                                ep = parseFloat(myEntryPrice)
                            } else {
                                ep = parseFloat(entryPriceValue)
                            }

                            m2t = parseFloat(item.stopPrice) - parseFloat(ep)
                            m2t /= ep
                            m2t *= 100

                            m2s = parseFloat(ep) - parseFloat(myStoplossPrice)
                            m2s /= parseFloat(myStoplossPrice)
                            m2s *= 100

                            // m2t = parseFloat(item.stopPrice) - parseFloat(myEntryPrice)
                            // m2t /= myEntryPrice
                            // m2t *= 100

                            // m2s = parseFloat(myEntryPrice) - parseFloat(myStoplossPrice)
                            // m2s /= parseFloat(myStoplossPrice)
                            // m2s *= 100

                            rr = parseFloat(m2t) / parseFloat(m2s)
                        }

                        return (
                            <Form.Group key={key}>
                                <Form.Row>
                                    <FormLabel className="col-sm-12 col-md-4 col-lg-4 pl-0 mb-0">
                                        <span>Target Price {key + 1}</span>
                                        <span className='trr'>{isNotNull(rr) && <>{`${rr.toFixed(2)}R`}</>}</span>
                                    </FormLabel>
                                    <Col sm={12} md={8} lg={8}>
                                        <div className="relative">
                                            <div className="input-group">
                                                <OverlayTooltip
                                                    title={'Target Price error'}
                                                    alignment={'right'}
                                                    showTooltip={item.isNotValid}
                                                    message={item.errMsg}
                                                    renderComponent={
                                                        (target) => {
                                                            return (
                                                                <input
                                                                    ref={target}
                                                                    type="text"
                                                                    name={item.id}
                                                                    className={`form-control multitarget target-index${key} ${item.isNotValid ? 'error' : ''}`}
                                                                    placeholder="Target Price"
                                                                    value={ScientificToDecimal(item.stopPrice)}
                                                                    onKeyPress={(e) => { validatePrice(e) }}
                                                                    onChange={(e) => { this.onChangeTargetPriceHandler(e, item.index) }} />
                                                            )
                                                        }
                                                    }
                                                />

                                                <div className="input-group-append" style={{ width: '48px', paddingLeft: '1px', paddingRight: '1px' }}>
                                                    <TargetPriceInput
                                                        type="text"
                                                        id={`qty-target-${key}`}
                                                        name={item.id}
                                                        className={`form-control qty ${item.isQtyNotValid ? 'error' : ''}`}
                                                        placeholder="Qty"
                                                        value={item.quantity}
                                                        onKeyPress={(e) => { validatePrice(e) }}
                                                        onChange={(e) => { this.onChangeTargetPriceValueHandler(e, item.index) }} />
                                                </div>

                                                <div className="input-group-append">
                                                    <TargetAllocationInput className="input-group-text">
                                                        <i className="fas fa-percent"></i>
                                                    </TargetAllocationInput>
                                                </div>

                                                <div className="input-group-append gains">
                                                    <OverlayTrigger
                                                        placement="top-end"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={this.renderOverlayText("TARGET")}
                                                    >
                                                        <TargetAllocationInput className="input-group-text">
                                                            {this.renderTargetGains(direction, entryPriceValue, item.stopPrice)}
                                                        </TargetAllocationInput>
                                                    </OverlayTrigger>
                                                </div>

                                                {targets.length > 1 &&
                                                    <>
                                                        <span className="text-danger p-2"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={(e) => this.onRemoveTargetPriceHandler(e, item.index)}>
                                                            <i className="fas fa-times"></i>
                                                        </span>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                        )
                    })
                }

                { isPremiumUser && isNotNull(targets) && targets.length < 4 &&
                    <Form.Group>
                        <Form.Row>
                            <div className="text-right col-sm-12">
                                <SafeAnchor onClick={(e) => this.onAddTargetPriceHandler(e)} >
                                    <small style={{ color: '#15BD6F', textTransform: 'uppercase' }}>
                                        + ADD TARGET
                                    </small>
                                </SafeAnchor>
                            </div>
                        </Form.Row>
                    </Form.Group>
                }
            </>
        )
    }
}