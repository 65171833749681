
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FooterContainer } from './styled_components/FooterStyle';

export class Footer extends React.Component {

    render() {
        return (
            <FooterContainer>
                <Container>
                    <Row>
                        <Col>
                            <h4>WORKING WITH</h4>
                            <div className="footer-img-wrapper">
                                <img src="/images/logo/bianance.png" alt='' className="img-fluid" />
                                <img src="/images/logo/bitfinex.png" alt='' className="img-fluid" />
                                <img src="/images/logo/bitmex.png" alt='' className="img-fluid" />
                                <img src="/images/logo/houbi.png" alt='' className="img-fluid" />
                                <img src="/images/logo/coinbase.png" alt='' className="img-fluid" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </FooterContainer>
        );

    }
}
