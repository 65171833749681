import glamorous from 'glamorous'
import { mediaQueries } from '../../../constants/mediaQueries'

export const LearnHowToSectionStyle = glamorous.section({    
    position: 'relative',
    padding: '30px 0',
    color: '#fff',
    backgroundColor: '#080d12a6',

    '& .container': {
        '& .section-title': {
            fontSize: '20px',
            fontWeight: 600,
            textAlign: 'center',
            marginBottom: '18px'
        },

        '& .carousel': {
            marginBottom: '25px',
            paddingLeft: '30px',

            '& .features-list': {
                display: 'flex',
                margin: '0 auto',
                padding: 0,
                listStyleType: 'none',
    
                '& .item': {
                    textAlign: 'center',
                    marginRight: '20px',
    
                    '& .thumbnail': {
                        display: 'block',
                        width: '295px',
                        margin: '0 auto 25px',
    
                        '& img': {
                            width: '100%'
                        }
                    }
                }
            },

            '& .carousel-indicators': {
                bottom: '-36px',

                '& li': {
                    width: '7px',
                    height: '7px',
                    borderRadius: '50%',
                    backgroundColor: '#172533',

                    '&.active': {
                        backgroundColor: '#15BD6F'
                    }
                }
            },

            '& .carousel-control-next,& .carousel-control-prev': {
                display: 'none'
            }
        },

        '& .feature-thumbnails-list': {
            display: 'none',
            margin: '0 auto 30px',
            padding: 0,
            listStyleType: 'none',

            '& .feature': {
                '& .thumbnail': {
                    '& img': {
                        width: '100%'
                    }
                },

                '& h5': {
                    textAlign: 'center'
                }
            }
        },

        '& .video-wrapper': {
            '& > div': {
                height: '212px !important'
            }
        }
    },

    [mediaQueries.mediumDevices]: {
        padding: '50px 0',

        '& .container': {
            '& .section-title': {
                fontSize: '26px',
                marginBottom: '25px'
            },

            '& .feature-thumbnails-list': {
                display: 'flex',
                maxWidth: '690px',

                '& .feature': {
                    marginRight: '10px',

                    '& .thumbnail': {
                        width: '165px',
                        marginBottom: '15px'
                    },

                    '& h5': {
                        fontSize: '15px'
                    },

                    '&:last-child': {
                        marginRight: 0
                    }
                }
            },

            '& .video-wrapper': {
                '& > div': {
                    height: '360px !important'
                }
            }
        }
    },

    [mediaQueries.largeDevices]: {
        padding: '50px 0 60px',

        '& .container': {
            '& .section-title': {
                fontSize: '28px',
                marginBottom: '25px'
            },

            '& .feature-thumbnails-list': {
                maxWidth: '980px',
                margin: '0 auto 40px',

                '& .feature': {
                    marginRight: '15px',

                    '& .thumbnail': {
                        width: '221px'
                    },

                    '& h5': {
                        fontSize: '16px'
                    }
                }
            }
        }
    },

    [mediaQueries.extraLargeDevices]: {
        padding: '50px 0 70px',

        '& .container': {
            '& .section-title': {
                fontSize: '36px'
            },

            '& .feature-thumbnails-list': {
                maxWidth: '1140px',

                '& .feature': {
                    '& .thumbnail': {
                        width: '266px'
                    },

                    '& h5': {
                        fontSize: '18px'
                    }
                }
            },

            '& .video-wrapper': {
                '& > div': {
                    height: '420px !important'
                }
            }
        }
    },

    [mediaQueries.fourK]: {
        padding: '80px 0',

        '& .container': {
            maxWidth: '1255px',

            '& .section-title': {
                fontSize: '40px',
                marginBottom: '35px'
            },

            '& .feature-thumbnails-list': {
                maxWidth: '100%',

                '& .feature': {
                    '& .thumbnail': {
                        width: '295px'
                    },

                    '& h5': {
                        fontSize: '22px'
                    }
                }
            },

            '& .video-wrapper': {
                '& > div': {
                    height: '520px !important'
                }
            }
        }
    }
})