import styled from 'styled-components';

export const PreloaderImage = styled.div`
    height: 300px;
    width: 100%;
    margin-bottom: 10px;
`;

export const PreloaderText = styled.div`
    height: 22px;
    width: 100%;
    margin-bottom: 10px;
`;

export const PreloaderTitle = styled.div`
    height: 35px;
    width: 50%;
    margin-bottom: 15px;
`;

export const TradeGuidePreloaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 800px;
    margin: 0 auto;

    @media(max-width: 768px) {
        width: 100%;
    }
`;

export const TradeGuideCloseButton = styled.button`
    position: fixed;
    top: 25px;
    right: 35px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 25px;
    background: none;
    background-color: #bdbdbd;
    color: #202933;
    font-size: 24px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

    &:hover {
        background-color: #fbfbfb;
    }

    @media(max-width: 768px) {
        top: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        z-index: 9999;
    }
`;

export const FooterLogo = styled.span`
    display: block;
    width: 60px;
    height: 60px;
    position: absolute;
    left: calc(50% - 30px);
    top: 7px;
    box-shadow: 1px 6px 12px rgba(0,0,0,.5);
    border-radius: 30px;
    background: #1e2733;

    img {
        width: 100%;
    }

    @media(max-width:414px) {
        top: -5px !important;
    }

    @media(max-width:768px) {
        width: 40px;
        height: 40px;
        top: 5px;
    }
`;

export const SignalBoxCombinationItem = styled.span`

    &.big-drop {
        &.filled {
            background-color: #e1112a;
            box-shadow: 0px 0px 1px 2px #e1112a;
        }

        &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-color: #e1112a transparent;
            border-width: 0 6px 6px 0;
            border-style: solid;
            bottom: -5px;
            left: -5px;
            transform: rotate(0deg);
        }

        &::after {
            content: "";
            position: absolute;
            width: 25px;
            height: 25px;
            top: -3px;
            left: -3px;
            border-radius: 50%;
            box-shadow: 0 0 2px #e71b2e, 0 0 1px #e1112a, 0 0 1px #ea4c5f, 0 0 0px #ff4123, 0 0 10px #e1112a, 0 0 22px #e1112a, 0 0 1px #e1112a, 0 0 1px #ff3e56;
        }
    }

    &.small-drop {
        &.filled {
            background-color: #ff7474;
            box-shadow: 0px 0px 1px 2px #ff7474;
        }
    }

    &.neutral {
        &.filled {
            background-color: #323232;
            box-shadow: 0px 0px 1px 2px #323232;
        }
    }

    &.small-raise {
        &.filled {
            background-color: #c4da50;
            box-shadow: 0px 0px 1px 2px #c4da50;
        }
    }

    &.big-raise {
        &.filled {
            background-color: #00d100;
            box-shadow: 0px 0px 1px 2px #00d100;
        }

        &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-color: transparent #00d100;
            border-width: 0 6px 6px 0;
            border-style: solid;
            top: -5px;
            right: -5px;
            transform: rotate(0deg);
        }

        &::after {
            content: "";
            position: absolute;
            width: 25px;
            height: 25px;
            top: -3px;
            left: -3px;
            border-radius: 50%;
            box-shadow: 0 0 2px #00d100, 0 0 1px #00d100, 0 0 1px #00d100, 0 0 0px #00d100, 0 0 10px #00d100, 0 0 22px #00d100, 0 0 1px #00d100, 0 0 1px #00d100;
        }
    }

    &:after {
        animation: shine 5s ease-in-out  infinite;
        animation-fill-mode: forwards;
        content: "";
        position: absolute;
        top: -110%;
        left: -210%;
        width: 200%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);

        background: rgba(255, 255, 255, 0.13);
        background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.13) 0%,
        rgba(255, 255, 255, 0.13) 77%,
        rgba(255, 255, 255, 0.5) 92%,
        rgba(255, 255, 255, 0.0) 100%
        );
    }
`;

export const SignalBoxCombinationHeader = styled.div`

    ${SignalBoxCombinationItem} {
        display: flex;
        justify-content: space-around;
        padding: 15px 25px;
        color: #ced0dd;
        font-weight: 800;
        font-size: 10px;
        background-color: #36475d;
        border: 4px solid #1e2733;
        width: 236px;
        font-size: 20px;
        margin-right: -5px;

        &:last-child {
            margin-right: 0;
        }
    }
`;

export const SignalBoxCombinationValues = styled.div`
    padding: 15px 0;
    background: #1e2733;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    ${SignalBoxCombinationItem} {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        border: 3px solid #10161d;

        &::after {
            width: 40px;
            height: 40px;
        }
    }
`;

export const SignalBoxCombinationWrapper = styled.div`
    display: block;
    width: 100%;
    padding: 20px 0 12px;

    ${SignalBoxCombinationHeader},
    ${SignalBoxCombinationValues} {
        display: flex;
        justify-content: space-around;
        width: 800px;
        margin: 0 auto;
    }
`;

export const SignalBoxDetails = styled.span`

`;

export const PriceAbbr = styled.span`

`;

export const PriceValue = styled.span`
    font-size: 28px;
    font-weight: 800;
    margin-right: 5px;
`;

export const PairedCoinPrice = styled.div`

`;

export const PairedCoinSymbol = styled.span`
    font-size: 24px;
`;

export const PairedCoinLogo = styled.span`
    width: 45px;
    height: 45px;
    display: inline-block;
    border-radius: 22.5px;
    overflow: hidden;
    vertical-align: middle;
    margin-right: 10px;
    border: 2px solid #36475d;

    img {
        width: 100%;
    }
`;

export const PairedCoin = styled.div`

`;

export const ComboPairedInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 25px;
    border-bottom: 1px solid #0a1329;
    background-color: #141b23;
`;

export const ComboPairedCoinWrapper = styled.div`
    display: block;
    width: 100%;
    // min-height: 276px;
    background-image: url(/images/map.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    padding-bottom: 30px;

    @media(max-width: 768px) {
        ${ComboPairedInfo} {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px;

            ${PairedCoin} {
                display: flex;
                justify-content: center;
                align-items: center;

                ${PairedCoinLogo} {
                    
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                }
                ${PairedCoinSymbol} {
                    font-size:18px;
                }
            }

            ${PairedCoinPrice} {

                ${PriceValue} {
                    font-size:18px;
                }

                ${PriceAbbr} {
                    font-size:14px;
                }
            }
        }

        ${SignalBoxDetails} {
            ${SignalBoxCombinationWrapper} {
                ${SignalBoxCombinationHeader} {
                    width: 100%;
                    ${SignalBoxCombinationItem} {
                        width: 12.5%;
                        font-size: 13px;
                        font-weight: 800;
                        padding: 10px 5%;
                        color: #bdbdbd;
                    }
                }
                ${SignalBoxCombinationValues} {
                    width: 100%;
                    ${SignalBoxCombinationItem} {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1199px) {

        ${SignalBoxDetails} {

            ${SignalBoxCombinationHeader},
            ${SignalBoxCombinationValues} {
                width: 700px;
            }
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 3000px) {
        ${ComboPairedInfo} {
            ${PairedCoin} {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
`;