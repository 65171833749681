import { endpoints, baseUrl } from './CONSTANTS'
import { authServices } from './authService'

const { v3 } = endpoints

async function getTheme() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    try {
        //const res = await fetch(`${baseUrl}${account.getTheme}`, requestOpts)
        const res = await fetch(`${baseUrl}${v3.settings.themes.theme}`, requestOpts)
        const response = await handleResponse(res)
        return Promise.resolve(response)
    } catch (error) {
        return Promise.reject(error)
    }
}

async function saveUserTheme(isDarkMode) {
    let themeDescription = (isDarkMode) ? 'dark' : 'light'

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    try {
        const res = await fetch(`${baseUrl}${v3.settings.themes.changeTheme(themeDescription)}`, requestOptions)
        const response = await handleResponse(res)
        return Promise.resolve(response)
    } catch (error) {
        return Promise.reject(error)
    }
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        if (res.status === 401)
            authServices.logout()
        else
            return data
    })
}

export const themeServices = {
    getTheme,
    saveUserTheme
}