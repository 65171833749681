import { Skeleton } from '@material-ui/lab'
import { Card, Table, SafeAnchor } from 'react-bootstrap'
import _ from 'lodash'
import moment from 'moment'
import { NotificationContentLists } from '../styled_components'
import {
    NotificationPushWrapper, NotificationPanels, NotificationTitle,
    NotificationContainer, NotificationSeperator
} from '../styled_components/NotificationPushStyle'
import {
    NotificationContentWrapper, CoinsImg, NotificationContentContainer,
    NotificationContent, NotificationTime, NotificationCard
} from '../../../components/NotificationBell/styled_components/NotificationItemsStyle'
import { isNotNull, setCoinLogo, setDefaultImage, getCoin, getSpecificHistoryTime } from '../../../utils'

export const NotificationPreviewView = (props) => {

    const { isPreview, isPushPreview, closedNotificationLists } = props

    const {
        isActive, isTradeDetailsLoaded, tradeDetails, isSubmitted,
        loadTradeManualLink, isTradeManualBtnDisable,
        coinLastPrice
    } = props.state

    const { loading, item } = props.notificationPreviews

    const onSubmitHandler = (e) => {
        e.preventDefault()
        props.submitHandler(props.selectedNotification, isPreview, isPushPreview)
    }

    const onPreviewSelectedNotificationItem = (data) => {
        props.self.setState({ isActive: true })
        props.updateSelectedNotification(data)
    }

    const onCalculateProfitLoss = value => {
        const newValue = (((coinLastPrice - value) / value) * 100)
        const calculatedValue = newValue.toFixed(2) * 1
        return calculatedValue === Infinity ? `---` : `${calculatedValue}%`
    }

    const onRedirectToOrdersPage = (e) => {
        e.preventDefault()
        props.submitHandler(props.selectedNotification, isPreview, isPushPreview)

        setTimeout(() => {
            window.location = "/orders"
        }, 1000)
    }

    const renderPreloader = (height = 150) => (
        <>
            <Skeleton variant="rect" style={{
                width: '100%',
                height: `${height}px`,
                backgroundImage: 'linear-gradient(15deg,#334156,#385065)',
                border: '1px solid #27313f',
                marginBottom: '15px'
            }} className="skeleton" />
        </>
    )

    const renderCloseButton = () => {
        if (isPreview)
            return (
                <>
                    {isActive === false
                        ? <button className="btn btn-sidebar" onClick={() => props.handleClose()}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </button>
                        : <button className="btn btn-sidebar" onClick={() => props.previewListsClose()}>
                            <i className="fa fa-arrow-left" aria-hidden="true"></i>
                        </button>
                    }
                </>
            )
        if (isPushPreview)
            return (
                <button className="btn btn-sidebar" onClick={() => props.handleClose()}>
                    <i className="fa fa-times" aria-hidden="true"></i>
                </button>
            )
    }

    const renderSignalDetails = () => (
        <tbody>
            <tr>
                <td>Direction</td>
                <td>{isNotNull(item) && item.direction}</td>
            </tr>
            <tr>
                <td>Coin Pair</td>
                <td>{isNotNull(item) && item.symbol}</td>
            </tr>
            <tr>
                <td>State</td>
                <td>Trade Closed</td>
            </tr>
            <tr>
                <td>Created On</td>
                <td>{isNotNull(item) && moment(item.datePublished).format('L')}</td>
            </tr>
        </tbody>
    )

    const renderOrderDetailsItems = () => {
        const onNullHandler = (item) => {
            return isNotNull(item) ? item : ""
        }

        if (!_.isEmpty(tradeDetails) && isNotNull(tradeDetails) && tradeDetails.length > 0) {
            return tradeDetails.map((value, index) => {
                if (isNotNull(value)) {                    
                    return (<div key={index}>
                        <NotificationContainer>
                            <Card>
                                <Card.Body>
                                    <Table borderless={true}>
                                        <tbody>
                                            <tr>
                                                <th>YOUR TRADE DETAILS</th>
                                                <th><br /></th>
                                            </tr>
                                            <tr>
                                                <td>Trade ID</td>
                                                <td>{onNullHandler(value.groupId)}</td> 
                                            </tr>
                                            <tr>
                                                <td>Coin Pair</td>
                                                <td>{isNotNull(item) && isNotNull(item.toSym) && item.toSym}</td>
                                            </tr>
                                            {
                                                isNotNull(value.tradeStatusValue) && value.tradeStatusValue !== "PENDING"
                                                    ?
                                                        <>
                                                            <tr>
                                                                <td>Buy Price</td>
                                                                <td>{isNotNull(value.buyPrice) ? value.buyPrice : `---`}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Date/Time</td>
                                                                <td>{isNotNull(value.closeDate) ? moment(value.closeDate).format("L") : `---`}</td>
                                                            </tr>
                                                            <tr className="d-flex flex-column mt-2 mb-2">
                                                                <td colSpan="2">
                                                                    {/* <NotificationContainer className="btn-wrapper justify-content-evenly">
                                                                        <button className="btn btn-status" disabled>
                                                                            {value.tradeStatusValue}
                                                                        </button>
                                                                    </NotificationContainer> */}
                                                                    {/* <Link to="/orders" className="btn-close-order">Close Order</Link> */}

                                                                    <button type="button" className="btn-close-order" onClick={(e) => onRedirectToOrdersPage(e) }>Close Order</button>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    :
                                                        isNotNull(value.tradeStatusValue) && value.tradeStatusValue === "PENDING" && isNotNull(value.buyPrice) 
                                                            ?
                                                                <>
                                                                    <tr>
                                                                        <td>Quantity of remaining coin</td>
                                                                        <td>{onNullHandler(value.remainingQuantity)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Buy Price</td>
                                                                        <td>{isNotNull(value) && isNotNull(value.buyPrice) ? value.buyPrice : `---`}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Date/Time</td>
                                                                        <td>{isNotNull(value) && isNotNull(value.closeDate) ? moment(value.closeDate).format("L") : `---`}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>CURRENT STATUS</th>
                                                                        <th><br /></th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Coin Paid</td>
                                                                        <td>{onNullHandler(value.coinPair)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Profit/Loss %</td>
                                                                        <td>{onCalculateProfitLoss(value.buyPrice)}</td>
                                                                    </tr>
                                                                    <tr className="d-flex flex-column mt-2 mb-2">
                                                                        <td colSpan="2">
                                                                            <button type="button" className="btn-close-order" onClick={(e) => onRedirectToOrdersPage(e) }>Close Order</button>
                                                                        </td>
                                                                        {/* <td colSpan="2">
                                                                            {
                                                                                isNotNull(value) && isNotNull(value.buyPrice) ?
                                                                                    <NotificationContainer className="btn-wrapper justify-content-evenly">
                                                                                        <button className="btn btn-sell" onClick={() => props.onPanicSellHandler(value, item)} disabled={isRequesting}>
                                                                                            {
                                                                                                isNotNull(value) && isNotNull(value.loading) && value.loading ?
                                                                                                    <><i className="fas fa-spinner fa-pulse"></i> Selling coin</> :
                                                                                                    <><i className="fas fa-coins"></i> Sell coin</>
                                                                                            }
                                                                                        </button>
                                                                                    </NotificationContainer>
                                                                                    :
                                                                                    <NotificationContainer className="btn-wrapper justify-content-evenly">
                                                                                        <button className="btn btn-cancel" onClick={() => props.onCancelOrderHandler(value, item)} disabled={isRequesting}>
                                                                                            {
                                                                                                isNotNull(value) && isNotNull(value.loading) && value.loading ?
                                                                                                    <><i className="fas fa-spinner fa-pulse"></i> Cancelling order</> :
                                                                                                    <><i className="fas fa-coins"></i> Cancel order</>
                                                                                            }
                                                                                        </button>
                                                                                    </NotificationContainer>
                                                                            }
                                                                        </td> */}
                                                                    </tr>
                                                                </> 
                                                            :
                                                                <>
                                                                    <tr>
                                                                        <td>Status</td>
                                                                        <td>{onNullHandler(value.tradeStatusValue)}</td>
                                                                    </tr>
                                                                    <tr className="d-flex flex-column mt-2 mb-2">
                                                                        <td colSpan="2">
                                                                            {/* {
                                                                                isNotNull(value.buyPrice) ?
                                                                                    <NotificationContainer className="btn-wrapper justify-content-evenly">
                                                                                        <button className="btn btn-sell" onClick={() => props.onPanicSellHandler(value, item)} disabled={isRequesting}>
                                                                                            {
                                                                                                isNotNull(value.loading) && value.loading ?
                                                                                                    <><i className="fas fa-spinner fa-pulse"></i> Selling coin</> :
                                                                                                    <><i className="fas fa-coins"></i> Sell coin</>
                                                                                            }
                                                                                        </button>
                                                                                    </NotificationContainer>
                                                                                    :
                                                                                    <NotificationContainer className="btn-wrapper justify-content-evenly">
                                                                                        <button className="btn btn-cancel" onClick={() => props.onCancelOrderHandler(value, item)} disabled={isRequesting}>
                                                                                            {
                                                                                                isNotNull(value.loading) && value.loading ?
                                                                                                    <><i className="fas fa-spinner fa-pulse"></i> Cancelling order</> :
                                                                                                    <><i className="fas fa-coins"></i> Cancel order</>
                                                                                            }
                                                                                        </button>
                                                                                    </NotificationContainer>
                                                                            } */}

                                                                            <button type="button" className="btn-close-order" onClick={(e) => onRedirectToOrdersPage(e) }>Close Order</button>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </NotificationContainer>
                    </div>)
                }

                return (<></>)
            })
        }
    }

    const renderOrderDetails = () => {
        return (
            <>
                {/* {isNotNull(tradeDetails) && tradeDetails.length > 0 &&
                    <NotificationContainer>
                        <NotificationContent>Clicking ‘Sell Coins Now’ will execute a 100% market sell order based on the below trade details.</NotificationContent>
                        <NotificationContent>Market prices move constantly so the end result may differ from the estimate below.</NotificationContent>
                    </NotificationContainer>
                } */}
                {renderOrderDetailsItems()}
                {isNotNull(tradeDetails) && tradeDetails.length > 0 &&
                    <NotificationSeperator>
                        <span className="back-line">
                            <span className="or-text">OR</span>
                        </span>
                    </NotificationSeperator>
                }
            </>
        )
    }

    const renderClosedNotificationLists = () => {
        if (isNotNull(closedNotificationLists)) {
            const orderLists = _.orderBy(closedNotificationLists, o => new Date(o.dateCreated), 'desc')
            return _.map(orderLists, (value, keys) => {
                if (isNotNull(value)) {
                    const keyNum = value.id + keys + value.actorId
                    const logoSrc = getCoin(value.symbol)
                    return (
                        <NotificationContentWrapper key={keyNum}>
                            <NotificationCard unRead={false} onClick={() => onPreviewSelectedNotificationItem(value)} className="notification-card-item">
                                <CoinsImg src={setCoinLogo(logoSrc)} className={logoSrc} icon={logoSrc} onError={(e) => setDefaultImage(e)} />
                                <NotificationContentContainer className="notification-card-container">
                                    <NotificationContent>{value.message}</NotificationContent>
                                    <NotificationTime unRead={false}>
                                        {getSpecificHistoryTime(value.dateCreated)}
                                    </NotificationTime>
                                </NotificationContentContainer>
                            </NotificationCard>
                        </NotificationContentWrapper>
                    )
                } else
                    return null
            })
        }
    }

    const renderRightPanel = () => (
        <NotificationPanels className={`right-panel ${isActive ? `hidden` : ""}`}>
            <NotificationContentLists>
                {renderClosedNotificationLists()}
            </NotificationContentLists>
        </NotificationPanels>
    )

    const renderLeftPanel = () => {
        return (
            <NotificationPanels className={`left-panel ${!isActive && isPreview === true ? `hidden` : ""}`}>
                <NotificationContainer className="backdrop-sticky">
                    <NotificationTitle>Warning we are closing this trade early! - TAKE ACTION - {isNotNull(props.selectedNotification) && props.selectedNotification.symbol}</NotificationTitle>
                </NotificationContainer>
                <NotificationContainer>
                    <NotificationContent>Hey there ProfitFarmer,</NotificationContent>
                    <NotificationContent>We don’t like the look of this one and will be closing the trade early:</NotificationContent>
                    {loading &&
                        renderPreloader()
                    }
                    {!loading &&
                        <Card>
                            <Card.Body>
                                <Table borderless={true}>
                                    {renderSignalDetails()}
                                </Table>
                            </Card.Body>
                        </Card>
                    }
                </NotificationContainer>
                {isTradeDetailsLoaded &&
                    renderPreloader(300)
                }
                {!isTradeDetailsLoaded && !loading &&
                    <div>
                        {renderOrderDetails()}
                    </div>
                }
                <NotificationContainer>
                    <NotificationContent>If you want to follow along here’s what to do...</NotificationContent>
                    <ol>
                        <li>Navigate to
                            {loadTradeManualLink
                                ? <SafeAnchor><span> <i className="fas fa-spinner fa-pulse"></i> Trade Manually </span></SafeAnchor>
                                : <SafeAnchor href="#" onClick={e => !isTradeManualBtnDisable ? props.onTradeManualHandler(true) : e.preventDefault()}>
                                    <span> Trade Manually</span>
                                </SafeAnchor>
                            }
                        </li>
                        <li>Create a trade plan for selling your coins</li>
                        <li>Close any related open orders that are no longer relevant</li>
                    </ol>
                </NotificationContainer>

                <NotificationContainer>
                    <NotificationContent>
                        Check this <a href="https://profitfarmers.freshdesk.com/en/support/solutions/articles/47001138748-how-to-close-a-trade-manually-take-action" target="_blank" rel="noreferrer">guide</a> for instructions</NotificationContent>
                </NotificationContainer>
                <NotificationContainer className="btn-wrapper justify-content-evenly mb-4">
                    {
                        isSubmitted === false
                            ? <button className="btn" disabled={loading} onClick={(e) => onSubmitHandler(e)}>OK, I understand</button>
                            : <button className="btn"><i className="fas fa-spinner fa-pulse"></i></button>
                    }
                </NotificationContainer>
            </NotificationPanels>
        )
    }

    return (
        <NotificationPushWrapper>
            <div className="header-wrapper">
                <span>Manually Closed Notifications</span>

                {renderCloseButton()}
            </div>
            <div className="body-wrapper">
                {isPreview === true &&
                    renderRightPanel()
                }
                {renderLeftPanel()}
            </div>
        </NotificationPushWrapper>
    )
}