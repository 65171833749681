import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const TradeDoctorSessionsStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    background-color: #EBF3FA;
    padding: 40px 0;

    .container {
        .text-with-img {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;

            .text-col {
                width: 100%;

                .section-title {
                    font-weight: 800;
                    color: #091017;
                    text-align: center;
                    font-size: 30px;
                    margin-bottom: 20px;                    
                }

                p {
                    color: #091017;
                    text-align: center;
                }

                .sessions-benefits {
                    list-style-type: none;
                    margin: 0 auto 25px;
                    padding: 0;
                    max-width: 550px;

                    li {
                        svg {
                            color: #27E38E;
                            margin-right: 10px;
                            font-size: 19px;
                        }

                        span {
                            color: #091017;
                            font-size: 16px;
                            font-weight: 500;
                        }
                    }
                }
            }

            .img-wrapper {
                width: 100%;
                text-align: center;

                img {
                    width: 100%;
                    margin: 0 auto 15px;
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        .container {
            .text-with-img {
                .img-wrapper {
                    img {
                        width: 60%;
                    }
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        .container {
            .text-with-img {
                flex-direction: row;

                .img-wrapper {
                    width: 619px;

                    img {
                        width: 87%;
                    }
                }

                .text-col {
                    width: calc(100% - 619px);
                    padding-left: 20px;

                    .section-title {                        
                        text-align: left;
                        max-width: 100%;
                        font-weight: 900;
                        letter-spacing: 1px;
                    }

                    p {
                        font-size: 16px;
                        text-align: left;
                    }

                    .sessions-benefits {
                        max-width: 100%;

                        li {
                            margin-bottom: 20px;

                            svg {
                                font-size: 19px;
                            }

                            span {
                                font-size: 16px;
                                font-weight: 600;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        padding: 60px 0;

        .container {
            max-width: 1335px;

            .text-with-img {
                .text-col {
                    .section-title {
                        font-size: 49px;
                    }

                    p {
                        font-size: 18px;
                    }
                }
            }
        }
    }
`