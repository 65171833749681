import React from 'react'
import { isNull, isNotNull, onSetNewState } from '../../utils'

export class SymbolOverView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isInjected: false,
            isLoading: true
        }
        this.mounted = false
        this.chartWidget = null
    }

    componentDidMount() {
        this.mounted = true
        const { isLoading } = this.state

        setTimeout(() => {
            if (isLoading) {
                if (!isNull(this.chartWidget)) {
                    onSetNewState(this, {
                        isLoading: false
                    })
                } else {
                    onSetNewState(this, {
                        isLoading: false
                    }, () => {                
                        this.renderChart()
                    })
                }
            }
        }, 1000)
    }

    componentDidUpdate() {
        const { isLoading } = this.state
        if (this.mounted && isNull(this.chartWidget) && isLoading) {
            onSetNewState(this, {
                isLoading: false
            }, () => {                
                this.renderChart()
            })
        }
    }

    componentWillUnmount() {
        this.mounted = false
        this.chartWidget = null
    }

    renderLoader = () => (
        <>
            <div style={{ width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0 }}>
                <div className="pf-spinner xs" style={{ marginTop: '-48px', marginRight: '45px' }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        </> 
    )

    renderChart = () => {
        if (isNotNull(window.TradingView)) {
            this.chartWidget = setTimeout(() => {
                new window.TradingView.MediumWidget({
                    "symbols": [
                        [
                            "BTCUSDT",
                            "BINANCE:BTCUSDT|12M"
                        ],
                        [
                            "ETHUSDT",
                            "BINANCE:ETHUSDT|12M"
                        ],
                        [
                            "SHITPERP",
                            "FTX:SHITPERP|12M"
                        ],
                        [
                            "MIDPERP",
                            "FTX:MIDPERP|12M"
                        ],
                        [
                            "TOTAL",
                            "CRYPTOCAP:TOTAL|12M"
                        ],
                        [
                            "TOTAL2",
                            "CRYPTOCAP:TOTAL2|12M"
                        ]
                    ],
                    "chartOnly": false,
                    "width": "100%",
                    "height": 400,
                    "locale": "en",
                    "colorTheme": "dark",
                    "gridLineColor": "rgba(255, 152, 0, 0)",
                    "fontColor": "#787B86",
                    "isTransparent": true,
                    "autosize": true,
                    "showVolume": false,
                    "scalePosition": "no",
                    "scaleMode": "Normal",
                    "fontFamily": "-apple-system, BlinkMacSystemFont, SegoeUI, Trebuchet MS, Roboto, Ubuntu, sans-serif",
                    "noTimeScale": false,
                    "valuesTracking": "1",
                    "chartType": "candlesticks",
                    "upColor": "#22ab94",
                    "downColor": "#f7525f",
                    "borderUpColor": "#22ab94",
                    "borderDownColor": "#f7525f",
                    "wickUpColor": "#22ab94",
                    "wickDownColor": "#f7525f",
                    "container_id": "tradingview_9981f"
                })
            }, 500)
        }
    }

    render() {
        const { isLoading } = this.state

        return (
            <div 
                className="tradingview-widget-container" 
                style={{ 
                    width: '100%',
                    backgroundColor: '#0B1219',
                    padding: '12px',
                    borderRadius: '9px',
                    border: '2px solid #18283C',
                    minHeight: '130px'
                }}>
                    { isLoading && this.renderLoader() }

                    <div id="tradingview_9981f"></div>
            </div>
        )
    }
}