import { Route, Redirect } from 'react-router-dom'
import { NotFound } from './NotFound'
import { routes } from './CONSTANTS'
import { isLogin, getRole } from '../utils'

const PrivateRoute = ({ component: Component, page, ...rest }) => {
    const role = getRole()

    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to login page
        <Route {...rest} render={props => {
            if (isLogin()) {
                return (<Component page={page} {...props} />)
                
                // if (role === 'Customer' || role === 'Basic')
                //     return (<Component page={page} {...props} />)
                // else
                //     return (<NotFound />)
            } else {
                let returnUrl = window.location.pathname
                window.location.href = `${routes.authentication.login}/?returnUrl=https://app.profitfarmers.com${returnUrl}`
                //window.location.href = routes.authentication.login               

                // return (<Redirect to={{
                //     pathname: `${routes.authentication.login}?returnUrl=${returnUrl}`,
                //     state: { from: props.location }
                // }} />)
            }
        }} />
    )
}

export default PrivateRoute
