import _ from 'lodash'
import React from 'react'
import { OverlayTrigger, Tooltip, FormGroup, SafeAnchor, Dropdown, Form } from 'react-bootstrap'
import { InlineFilter } from '../../../../styles/form-inputs'
import { isNotNull } from '../../../../utils'

export class Heading extends React.Component {
    isSelected = (selectedValue) => {
        const { tradeResultFilter } = this.props
        const { selectedValues } = tradeResultFilter
        if (isNotNull(selectedValue) && 
            isNotNull(selectedValues) && selectedValues.length > 0) {
            let doesExists = _.find(selectedValues, (value, index) => {
                return value === selectedValue
            })
            if (doesExists)
                return true
        }

        return false
    }

    handleSelectMultiAnswer = (e, key, name) => {
        let selectedValue = key
        let isSelected = e.target.checked
        const { tradeResultFilter } = this.props
        let { selectedValues, selectedValueTexts } = tradeResultFilter
        
        if (isNotNull(selectedValues) && selectedValues.length > 0) {
            let doesExists = _.find(selectedValues, (value, index) => {
                return value === selectedValue
            })
            if (doesExists) {
                if (!isSelected) {
                    _.remove(selectedValues, (item) => item === selectedValue)
                    _.remove(selectedValueTexts, (item) => item === name)
                }
            } else {
                if (isSelected) {
                    selectedValues.push(selectedValue)
                    selectedValueTexts.push(name)
                }
            }

            this.props.onChangeTradeResultFilter(selectedValues, selectedValueTexts)
        } else {
            selectedValues.push(selectedValue)
            selectedValueTexts.push(name)
            this.props.onChangeTradeResultFilter(selectedValues, selectedValueTexts)
        }
    }

    render() {
        const { 
            filter, 
            signalExchangeFilter, 
            tradeResultFilter, 
            isLoadingSignals, 
            onChangeFilter, 
            onChangeExchangeFilter, 
            windowWidth, 
            user } = this.props

        // extracting filter options
        let options = null
        if (isNotNull(filter.options)) {
            options = filter.options.map(state => (
                <option key={state.key} value={state.key}>{state.value}</option>
            ))
        }

        // extracting exchange filter options
        let exchangeOptions = null
        if (isNotNull(signalExchangeFilter)) {
            exchangeOptions = signalExchangeFilter.options.map(exchange => (
                <option key={exchange.key} value={exchange.key}>{exchange.name}</option>
            ))
        }

        // extracting trade result options
        let tradeResultOptionsText = "-- select --"
        if (isNotNull(tradeResultFilter) && isNotNull(tradeResultFilter.selectedValueTexts)) {
            if (tradeResultFilter.selectedValueTexts.length > 0) {
                let selections = []

                _.forEach(tradeResultFilter.selectedValueTexts, (item, index) => {
                    let selectedText = _.find(tradeResultFilter.options, (v, i) => {
                        return v.name === item
                    })

                    if (selectedText)
                        selections.push(selectedText.name)
                })

                tradeResultOptionsText = selections.join(", ")
            }
        }

        return (
            <div className="heading">
                { isNotNull(user) && 
                    <div className="title-wrapper">
                        <span className="ico"><i className="fas fa-seedling"></i></span>
                        <h2 className="text theme-section-title">Signals</h2>

                        <OverlayTrigger placement="right" delay={(windowWidth > 1024 && { show: 200, hide: 1000 })} overlay={
                            <Tooltip id='tooltip-right' className="signals-tooltip">
                                <SafeAnchor href={ user.isPaid ? 'https://pro-support.profitfarmers.com/article/72-what-does-the-dashboard-show-me-and-how-are-signal-progress-statistics-calculated' : 'https://support.profitfarmers.com/article/239-what-does-the-dashboard-show-me-and-how-are-signal-progress-statistics-calculated' } target="_blank">
                                    Learn how the dashboard works
                                </SafeAnchor>
                            </Tooltip>
                        }>
                            <span className="icon-question">
                                <i className="fas fa-info-circle"></i>
                            </span>
                        </OverlayTrigger>
                    </div>
                }

                { isNotNull(user) && 
                    <InlineFilter className="theme-main-wrapper-select">
                        <li className="form-inline">
                            <FormGroup className="mr-3">
                                <label htmlFor="State">State</label>
                                { isNotNull(filter.selectedValue) &&                                 
                                    <select
                                        disabled={isLoadingSignals}
                                        value={filter.selectedValue}
                                        onChange={onChangeFilter}
                                        className="form-control"
                                        id="State"
                                        name="State">
                                        {options}
                                    </select>
                                }
                            </FormGroup>

                            { parseInt(filter.selectedValue) === 3 && 
                                <FormGroup className="mr-3">
                                    <label htmlFor="TradeResult">Trade Result</label>
                                    <div className='input-wrapper multi-selection'>
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-results">
                                                <span className='selected-options'>{tradeResultOptionsText}</span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                { tradeResultFilter.options.map((option, item) => (
                                                    <div className='dropdown-menuitem' key={item}>
                                                        <Form.Check
                                                            custom 
                                                            type="checkbox" 
                                                            id={`option-${option.key}`} 
                                                            name={option.key}  
                                                            label={option.name} 
                                                            onChange={(e) => this.handleSelectMultiAnswer(e, option.key, option.name)} 
                                                            checked={this.isSelected(option.key)}
                                                        />
                                                    </div>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </FormGroup>
                            }

                            <FormGroup>
                                <label htmlFor="Exchange">Exchange</label>
                                { isNotNull(signalExchangeFilter.selectedValue) &&                                 
                                    <select 
                                        disabled={isLoadingSignals} 
                                        value={signalExchangeFilter.selectedValue}
                                        onChange={onChangeExchangeFilter} 
                                        id="Exchange" 
                                        name="Exchange" 
                                        className="form-control">
                                        {exchangeOptions}
                                    </select>
                                }
                            </FormGroup>
                        </li>
                    </InlineFilter>
                }
            </div>
        )
    }
}