import styled from 'styled-components'

export const GambleYouNeedToTakeSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    background-color: #091017;
    padding: 60px 0;

    &::before {
        display: block;
        position: absolute;
        content: '';
        width: 1000px;            
        height: 1000px;
        background: transparent radial-gradient(closest-side at 50% 50%,#4f1f31 0%,#091017 100%) 0% 0% no-repeat padding-box;
        top: -560px;
        left: calc(50% - 500px);
        z-index: 0;
    }    

    .container {
        position: relative;
        max-width: 820px;
        margin: 0 auto;
        color: #fff;

        .section-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #fff;
            font-size: 40px;
            font-weight: 600;
            width: 100%;

            .text {
                display: inline-block;
                max-width: 380px;
            }

            .img {
                display: inline-block;
                width: 300px;

                img {
                    width: 100%;
                }
            }
        }

        p {
            text-align: center;
            margin-bottom: 25px;
            font-weight: 300;
        }

        h4,
        h5, 
        h6 {
            display: block;
            text-align: center;
        }

        h4 {
            max-width: 600px;
            margin: 0 auto 25px;
        }

        h5 {
            font-weight: 600;
        }

        h6 {
            color: #9AB9D5;
        }

        .cbdc-for {
            display: block;
            margin: 0 auto 25px;
            background-image: url(/images/layout/cbdc-bg.svg);
            background-repeat: no-repeat;
            background-size: 100%;
            padding: 40px 20px;

            h4 {
                display: block;
                text-align: center;
                font-size: 20px;
                margin-bottom: 25px;
                max-width: 100%;
            }

            ul {
                font-size: 14px;
                max-width: 550px;
                margin: 0 auto;
            }
        }
        
        .centered-img-wrapper {
            display: block;
            width: 100%;
            margin: 0 auto 25px;

            img {
                width: 100%;
            }
        }

        .possible-outcomes {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 0 auto 30px;

            .outcome {
                width: 50%;
                padding: 10px 26px;
                border-right: 1px solid #516475;

                label {
                    display: block;
                    text-align: center;
                    font-weight: 600;
                }

                h4 {
                    font-size: 20px;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 14px;
                }

                &.outcome-1 {
                    label {
                        color: #16BD70;
                    }
                }

                &.outcome-2 {
                    label {
                        color: #E93459;
                    }
                }

                &:last-child {
                    border-right: none;
                }
            }
        }

        .text-with-img {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            .img {
                width: 400px;

                img {
                    width: 100%;
                }
            }

            .text {
                width: calc(100% - 400px);
                padding: 0 0 0 35px;

                p {
                    font-size: 15px;
                    text-align: left;
                }
            }
        }
    }
`