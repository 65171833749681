import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { MessagePreviewStyle } from '../../../styles'
import { isNotNull } from '../../../utils'

export const MessagePreview = (props) => {
    const headerImg = new Image()
    const [imgReady, setHeaderImg] = useState(false)

    let data = props.data
    let messageType = ''
    let typeInText = ''
    
    switch(data.type) {
        case 0: {
            messageType = 'marketanalysis'
            typeInText = 'Market Analysis'
            break
        }
        case 1:
        default: {
            messageType = 'general'
            typeInText = 'General'
            break
        }
        case 2: {
            messageType = 'promotions'
            typeInText = 'Promotions'
            break
        }
    }

    useEffect(() => {
        headerImg.onload = () => {
            setHeaderImg(true)
        }
        headerImg.src = setImage()
    }, [])

    const setImage = () => {
        if (isNotNull(data.headerPhotoUrl))
            return data.headerPhotoUrl

        return `/images/layout/def-cp-${messageType}.jpg`
    }

    const renderLoader = () => (
        <div style={{ width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0 }}>
            <div className="pf-spinner xs" style={{ marginTop: '-48px', marginRight: '45px' }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    )

    return (
        <MessagePreviewStyle>
            <div className='msg-header-photo-container'>
                { imgReady ? (
                    <img src={setImage()} alt="Header Photo" />
                ) : (
                    renderLoader()
                )}
            </div>

            <div className='msg-title'>       
                <h3>{data.title}</h3>                
                <p><small className='msg-ts'>{moment(data.dateCreated).format('LL')}</small> <span className='dot'></span> <small className={`msg-type ${messageType}`}>{typeInText}</small></p>         
                <p>{data.shortDescription}</p>
            </div>
            
            <div className='msg-body-wrapper' dangerouslySetInnerHTML={{__html: data.htmlContent}}></div>
        </MessagePreviewStyle>
    )
}