import styled from 'styled-components';
import { device } from '../../../styles/CONSTANTS';

export const SignalLockedItem = styled.div`
	background: url('https://pf-image-assets.s3.eu-west-2.amazonaws.com/locked/locked_3.png');
	background-position: center !important;
	background-repeat: no-repeat !important;
	background-size: contain !important;
	border-color: #869bb9;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	float: left;
	height: 100%;
	min-height: 401px;
	width: 378px;
	// margin-right: 20px;
	border: 1px solid #27313f;
	border-radius: 4px;
	box-shadow: 0 3px 3px 2px rgba(0,0,0,.08);
	cursor: pointer;

	&.sm {
		float: none;
		margin-bottom: 20px;
    	min-height: 365px;
		border-radius: 10px;
		width: 100%;

			@media (min-width: 320px) {
				background-size: contain !important;
    			min-height: 316px;
			}

			@media (min-width: 360px) {
    			min-height: 360px;
			}

			@media (min-width: 375px) {
				min-height: 378px;
			}

			@media (min-width: 414px) {
				min-height: 420px;
			}

			@media (min-width: 480px) {
				min-height: 495px;
			}

			@media (min-width: 667px) {
				min-height: 495px;
			}

			@media ${device.mobileXXL} {
				width: 100%;
				margin: 0 auto 30px;
				background-size: 100% 100% !important;
			}
	}

	&.lk1 {
		/* background: url('https://pf-image-assets.s3.eu-west-2.amazonaws.com/locked/locked_1.png'); */
		background: url('/images/locked/locked1.png');
	}

	&.lk2 {
		/* background: url('https://pf-image-assets.s3.eu-west-2.amazonaws.com/locked/locked_2.png'); */
		background: url('/images/locked/locked2.png');
	}

	&.lk3 {
		/* background: url('https://pf-image-assets.s3.eu-west-2.amazonaws.com/locked/locked_3.png'); */
		background: url('/images/locked/locked3.png');
	}

	&.lk4 {
		/* background: url('https://pf-image-assets.s3.eu-west-2.amazonaws.com/locked/locked_4.png'); */
		background: url('/images/locked/locked4.png');
	}

	&.lk5 {
		/* background: url('https://pf-image-assets.s3.eu-west-2.amazonaws.com/locked/locked_5.png'); */
		background: url('/images/locked/locked5.png');
	}

	&.lk6 {
		/* background: url('https://pf-image-assets.s3.eu-west-2.amazonaws.com/locked/locked_6.png'); */
		background: url('/images/locked/locked6.png');
	}

	@media screen and (min-width: 320px) and (max-width: 359px) {
		min-height: 276px;
	}

	@media screen and (min-width: 360px) and (max-width: 374px){
		min-height: 350px;
	}

	@media screen and (min-width: 375px) and (max-width: 411px){
		min-height: 367px;
	}

	@media screen and (min-width: 411px) and (max-width: 413px){
		min-height: 404px;
	}

	@media screen and (min-width: 414px) and (max-width: 449px){
		min-height: 407px;
	}

	@media screen and (min-width: 450px) and (max-width: 455px){
		min-height: 446px;
	}

	@media screen and (min-width: 456px) and (max-width: 540px) {
		min-height: 541px;
	}

	@media screen and (min-width: 320px) and (max-width: 766px) {
		width: 100%;
		margin-bottom: 20px;
		background-size: contain !important;

		&.lk1,
		&.lk2,
		&.lk3,
		&.lk4,
		&.lk5,
		&.lk6 {
			background-repeat: no-repeat;
		}
	}

	@media screen and (min-width: 767px) and (max-width: 1023px) {
		width: 100%;

		&.lk1,
		&.lk2,
		&.lk3,
		&.lk4,
		&.lk5,
		&.lk6 {
			background-repeat: no-repeat;
		    background-size: 100% 100% !important;
		}
	}

	@media screen and (min-width: 1024px) and (max-width: 1199px) {
		width: 100%;

		&.lk1,
		&.lk2,
		&.lk3,
		&.lk4,
		&.lk5,
		&.lk6 {
			background-repeat: no-repeat;
			background-size: 100% 100% !important;
		}
	}

	@media screen and (min-width: 1200px) and (max-width: 1359px) {
		width: 100%;

		&.lk1,
		&.lk2,
		&.lk3,
		&.lk4,
		&.lk5,
		&.lk6 {
			background-repeat: no-repeat !important;
    		background-size: 100% 100% !important;
		}
	}

	@media screen and (min-width: 1360px) and (max-width: 1440px) {
		width: 100%;
		min-height: 360px;

		&.lk1,
		&.lk2,
		&.lk3,
		&.lk4,
		&.lk5,
		&.lk6 {
			background-repeat: no-repeat !important;
    		background-size: 100% 100% !important;
		}
	}


    @media screen and (min-width: 1441px) and (max-width: 1550px) {
		width: 100%;
		min-height: 360px;

		&.lk1,
		&.lk2,
		&.lk3,
		&.lk4,
		&.lk5,
		&.lk6 {
			background-repeat: no-repeat !important;
    		background-size: 100% 100% !important;
		}
	}

	@media screen and (min-width: 1551px) and (max-width: 1749px) {
		width: 100%;
		min-height: 360px;

		&.lk1,
		&.lk2,
		&.lk3,
		&.lk4,
		&.lk5,
		&.lk6 {
			background-repeat: no-repeat !important;
    		background-size: 100% 100% !important;
		}
	}

`;
