import { endpoints, baseUrl } from './CONSTANTS'
import { authServices } from './authService'

const { v3 } = endpoints

function getActiveAnnouncement() {
    const requestOpts = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return fetch(
        `${baseUrl}${v3.system.news}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        if (res.status === 401)
            authServices.logout()
        else
            return data
    })
}

export const announcementServices = {
    getActiveAnnouncement
}