import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { SafeAnchor } from 'react-bootstrap'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { onboardingActions } from '../../redux/actions'
import { TileWrapper, UserAvatar } from '../../styles'
import { SubscriptionPlan } from '../../components'
import { isNull, isNotNull, onSetNewState } from '../../utils'

class Profile extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            name: null,
            avatarUrl: null,
            hasInitBalances: false,
            btc: 0,
            usdt: 0,
            usdtFutures: 0
        }
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        const { ...state } = this.state
        const { dispatch, puid, userWalletBalances } = this.props

        if (isNull(userWalletBalances.loading))
            dispatch(onboardingActions.getTradingBalances())
        
        if (isNull(state.name) && isNotNull(puid.data)) {
            const { data } = puid
            if (isNotNull(data.FirstName)) {
                let name = `${data.FirstName} ${isNotNull(data.LastName) ? data.LastName.charAt(0) : ''}`
                onSetNewState(this, {
                    name,
                    avatarUrl: data.AvatarUrl,
                })
            }
        }

        if (!state.hasInitBalances && isNotNull(userWalletBalances.loading)) {
            const { wallet_balances } = userWalletBalances
            if (isNotNull(wallet_balances)) {
                const { walletBalances } = wallet_balances

                if (isNotNull(walletBalances)) {
                    const { spotWallet, futuresWallet } = walletBalances
                    if (isNotNull(spotWallet)) {
                        const { success, data } = spotWallet
                        if (success && isNotNull(data)) {
                            let btcBalance = 0
                            let btcWallet = _.find(data, i => i.name === 'BTC')
                            if (isNotNull(btcWallet)) {
                                btcBalance = _.round(parseFloat(btcWallet.balance.free), 8)
                            }
                            
                            let usdtBalance = 0
                            let usdtWallet = _.find(data, i => i.name === 'USDT')
                            if (isNotNull(usdtWallet)) {
                                if (parseFloat(usdtWallet.balance.free) > 1) {
                                    usdtBalance = _.round(parseFloat(usdtWallet.balance.free), 2)                                            
                                    usdtBalance = usdtBalance.toFixed(2)
                                }
                                else
                                    usdtBalance = parseFloat(usdtWallet.balance.free).toFixed(8)
                            }

                            onSetNewState(this, {
                                btc: btcBalance,
                                usdt: usdtBalance
                            })
                        }
                    }

                    if (isNotNull(futuresWallet)) {
                        const { success, data } = futuresWallet
                        if (success && isNotNull(data)) {                                    
                            let usdtBalance = 0
                            let usdtWallet = _.find(data, i => i.name === 'USDT')
                            if (isNotNull(usdtWallet)) {
                                if (parseFloat(usdtWallet.balance.free) > 1) {
                                    usdtBalance = _.round(parseFloat(usdtWallet.balance.free), 2)                                            
                                    usdtBalance = usdtBalance.toFixed(2)
                                }
                                else
                                    usdtBalance = parseFloat(usdtWallet.balance.free).toFixed(8)
                            }

                            onSetNewState(this, {
                                usdtFutures: usdtBalance
                            })
                        }
                    }

                    onSetNewState(this, {
                        hasInitBalances: true
                    })
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { hasInitBalances } = this.state
            const { puid, userWalletBalances } = this.props

            if (isNotNull(puid) && prevProps.puid !== puid && !puid.loading) {
                const { data } = puid
                if (isNotNull(data)) {
                    if (isNull(this.state.name) && isNotNull(data.FirstName)) {
                        let name = `${data.FirstName} ${isNotNull(data.LastName) ? data.LastName : ''}`
                        onSetNewState(this, {
                            name
                        })
                    }

                    if (isNull(this.state.avatarUrl) && isNotNull(data.AvatarUrl)) {
                        onSetNewState(this, {
                            avatarUrl: data.AvatarUrl
                        })
                    }
                }
            } 

            if (!hasInitBalances && 
                isNotNull(userWalletBalances) && 
                prevProps.userWalletBalances !== userWalletBalances && 
                !userWalletBalances.loading) {
                    const { wallet_balances } = userWalletBalances
                    if (isNotNull(wallet_balances)) {
                        const { walletBalances } = wallet_balances

                        if (isNotNull(walletBalances)) {
                            const { spotWallet, futuresWallet } = walletBalances
                            if (isNotNull(spotWallet)) {
                                const { success, data } = spotWallet
                                if (success && isNotNull(data)) {
                                    let btcBalance = 0
                                    let btcWallet = _.find(data, i => i.name === 'BTC')
                                    if (isNotNull(btcWallet)) {
                                        btcBalance = _.round(parseFloat(btcWallet.balance.free), 8)
                                    }
                                    
                                    let usdtBalance = 0
                                    let usdtWallet = _.find(data, i => i.name === 'USDT')
                                    if (isNotNull(usdtWallet)) {
                                        if (parseFloat(usdtWallet.balance.free) > 1) {
                                            usdtBalance = _.round(parseFloat(usdtWallet.balance.free), 2)                                            
                                            usdtBalance = usdtBalance.toFixed(2)
                                        }
                                        else
                                            usdtBalance = parseFloat(usdtWallet.balance.free).toFixed(8)
                                    }
    
                                    onSetNewState(this, {
                                        btc: btcBalance,
                                        usdt: usdtBalance
                                    })
                                }
                            }
    
                            if (isNotNull(futuresWallet)) {
                                const { success, data } = futuresWallet
                                if (success && isNotNull(data)) {                                    
                                    let usdtBalance = 0
                                    let usdtWallet = _.find(data, i => i.name === 'USDT')
                                    if (isNotNull(usdtWallet)) {
                                        if (parseFloat(usdtWallet.balance.free) > 1) {
                                            usdtBalance = _.round(parseFloat(usdtWallet.balance.free), 2)                                            
                                            usdtBalance = usdtBalance.toFixed(2)
                                        }
                                        else
                                            usdtBalance = parseFloat(usdtWallet.balance.free).toFixed(8)
                                    }
    
                                    onSetNewState(this, {
                                        usdtFutures: usdtBalance
                                    })
                                }
                            }

                            onSetNewState(this, {
                                hasInitBalances: true
                            })
                        }
                    }
            }
        }
    }

    render() {
        const { name, avatarUrl, btc, usdt } = this.state
        const { eventHandlers } = this.props

        return (
            <TileWrapper type="user-profile" className='user-profile'>
                <div className='summary'>
                    <div className='user-info'>
                        <UserAvatar>
                            <div className='img-wrapper'>
                                <img src={avatarUrl} alt='my-avatar' />
                            </div>
                        </UserAvatar>

                        <h2>                            
                            <small>Hello</small><br />
                            <span className='username'>{ isNotNull(name) && name}</span>
                            
                            <SafeAnchor className="referral-link" onClick={(e) => eventHandlers(e)}>
                                <FontAwesomeIcon icon={faPlusCircle} /> <span>Refer a friend</span>
                            </SafeAnchor>
                        </h2>

                        <NavLink to="/account/profile">
                            <FontAwesomeIcon icon={faPen} />
                        </NavLink>
                    </div>

                    {/* <div className='wallet-info'>                        
                        <label>
                            <span className='symbol-logo'>
                                <img src='https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/usdt.png' alt='BTC logo' />
                            </span>
                            <span className='value'>{ isNotNull(usdt) && parseFloat(usdt).toFixed(2) }</span>
                        </label>
                        <label>
                            <span className='symbol-logo'>
                                <img src='https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/btc.png' alt='BTC logo' />
                            </span>
                            <span className='value'>{ isNotNull(btc) && parseFloat(btc).toFixed(8)}</span>
                        </label>
                    </div> */}
                </div>
                
                <SubscriptionPlan />
            </TileWrapper>
        )
    }
}

function mapStateToProps(state) {
    const { puid, authentication, userWalletBalances } = state
    return {
        puid,
        authentication,
        userWalletBalances
    }
}

const connectedProfile = connect(mapStateToProps)(Profile)
export { connectedProfile as Profile }