import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import * as signalR from '@microsoft/signalr'
import jQuery from 'jquery'
import Swal from 'sweetalert2'
import _ from 'lodash'
import moment from 'moment'
import { planActions } from '../../redux/actions'
import { fsWebhook } from '../../services/CONSTANTS'
import { ProPlans } from '../../components'
import { isNotNull, isNull, onSetNewState } from '../../utils'
import { IntroSection } from '../Sections/SalesSections/IntroSection'
import { ProTrialDaysSection } from '../Sections/SalesSections/ProTrialDaysSection'
import { PremiumFeaturesSection } from '../Sections/SalesSections/PremiumFeaturesSection'
import { BlueprintSection } from '../Sections/SalesSections/BlueprintSection'
import { FreeVsProSection } from '../Sections/SalesSections/FreeVsProSection'
import { ThreeYearStatsSection } from '../Sections/SalesSections/ThreeYearStatsSection'
import { ProStatsSection } from '../Sections/SalesSections/ProStatsSection'
import { PowerPFSection } from '../Sections/SalesSections/PowerPFSection'
import { LimitedOffer } from '../Alerts/LimitedOffer'
import { JumpStartSection } from '../Sections/SalesSections/JumpStartSection'
import { PackageBreakdownSection } from '../Sections/SalesSections/PackageBreakdownSection'
import { ThreeSixFiveDaysSection } from '../Sections/SalesSections/ThreeSixFiveDaysSection'
import { TechnoTigerSection } from '../Sections/SalesSections/TechnoTigerSection'
import { TheNetPeakGainsSection } from '../Sections/SalesSections/TheNetPeakGainsSection'
import { EpicOpportunitiesSection } from '../Sections/SalesSections/EpicOpportunitiesSection'
import { WeeklyTotalMarketCapSection } from '../Sections/SalesSections/WeeklyTotalMarketCapSection'
import { GorgeOnCryptoSection } from '../Sections/SalesSections/GorgeOnCryptoSection'
import { ThePathWellTroddenSection } from '../Sections/SalesSections/ThePathWellTroddenSection'
import { UnleashTheTradingBeastSection } from '../Sections/SalesSections/UnleashTheTradingBeastSection'
import { BearMarketResultsSection } from '../Sections/SalesSections/BearMarketResultsSection'
import { AdvancedSignalsAlgorithmsSection } from '../Sections/SalesSections/AdvancedSignalsAlgorithmsSection'
import { GambleYouNeedToTakeSection } from '../Sections/SalesSections/GambleYouNeedToTakeSection'
import { SecureYourFutureSection } from '../Sections/SalesSections/SecureYourFutureSection'
import { RecapSection } from '../Sections/SalesSections/RecapSection'
import { LadderingModeSection } from '../Sections/SalesSections/LadderingModeSection'
import { TestimonialsSection } from '../Sections/SalesSections/TestimonialsSection'
import { TradesInActionSection } from '../Sections/SalesSections/TradesInActionSection'
import { CancelAnytimeSection } from '../Sections/SalesSections/CancelAnytimeSection'
import { SignUpStepsSection } from '../Sections/SalesSections/SignUpStepsSection'
import { TradeGuardianSection } from '../Sections/SalesSections/TradeGuardianSection'
import { ProEmpowerSection } from '../Sections/SalesSections/ProEmpowersSection'
import { BreakevenSection } from '../Sections/SalesSections/BreakevenSection'
import { SaveMoreSection } from '../Sections/SalesSections/SaveMoreSection'
import { FaqSection } from '../Sections/SalesSections/FaqSection'
import { LimitedSixMonthsOffer } from '../Alerts/LimitedSixMonthsOffer'
import { SMIntro } from '../Sections/SalesSections/SixMonthsOffer/SMIntro'
import { SMPackageBreakdown } from '../Sections/SalesSections/SixMonthsOffer/SMPackageBreakdown'
import { LastChance } from '../Sections/SalesSections/SixMonthsOffer/LastChance'
import { SMProStats } from '../Sections/SalesSections/SixMonthsOffer/SMProStats'
import { SixMonths } from '../Sections/SalesSections/SixMonthsOffer/SixMonths'
import { SMTheNetPeakGains } from '../Sections/SalesSections/SixMonthsOffer/SMTheNetPeakGains'
import { SMGorgeOnCrypto } from '../Sections/SalesSections/SixMonthsOffer/SMGorgeOnCrypto'
import { SMUnleashTheTradingBeast } from '../Sections/SalesSections/SixMonthsOffer/SMUnleashTheTradingBeast'
import { SMRecap } from '../Sections/SalesSections/SixMonthsOffer/SMRecap'
import { LimitedRetentionOffer } from '../Alerts/LimitedRetentionOffer'
import { RetIntro } from '../Sections/SalesSections/RetentionOffer/RetIntro'
import { RetLastChance } from '../Sections/SalesSections/RetentionOffer/RetLastChance'
import { TradeDoctorSessions } from '../Sections/SalesSections/RetentionOffer/TradeDoctorSessions'
import { RetSixMonths } from '../Sections/SalesSections/RetentionOffer/RetSixMonths'
import { RetRecap } from '../Sections/SalesSections/RetentionOffer/RetRecap'
import { RetFaq } from '../Sections/SalesSections/RetentionOffer/RetFaq'

class UpgradeOfferContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            show: null,
            userInfo: null,
            hasInitializedOffer: false,
            isInitializingOffer: false,
            trialsubscriptionPlan: null,
            earlyBirdPlan: null,
            finalYearlyOffer: null,
            sixMonthsPlanOffer: null,
            subscriptionPlans: null
        }

        this.baseState = this.state
        this.mounted = false
        this.fsHubConnection = null
        
        this.startListening()
        this.initializeHubConnection()
    }

    componentDidMount() {
        this.mounted = true
        // const { dispatch } = this.props
        // dispatch(planActions.getTrialOffer())
        // dispatch(planActions.get())
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { 
                dispatch, 
                authentication, 
                plan, 
                trialPlan,
                sixMonthPlan } = this.props
            const { 
                show, 
                userInfo, 
                hasInitializedOffer, 
                isInitializingOffer, 
                subscriptionPlans, 
                trialsubscriptionPlan,
                sixMonthsPlanOffer } = this.state

            if (isNull(subscriptionPlans) && 
                isNotNull(plan) && 
                prevProps.plan !== plan && 
                !plan.loading) {
                    let subscriptionPlanList = plan.plans
                    if (isNotNull(subscriptionPlanList.Fastspring)) {
                        let earlyBirdPlan = _.find(subscriptionPlanList.Fastspring, (val) => {
                            return val.normalizedName === 'YEARLYPLANASPECIALOFFER'
                        })

                        let finalYearlyOffer = _.find(subscriptionPlanList.Fastspring, (val) => {
                            return val.normalizedName === 'YEARLYPLANBSPECIALOFFER'
                        })

                        onSetNewState(this, {
                            earlyBirdPlan,
                            finalYearlyOffer,
                            subscriptionPlans: subscriptionPlanList.Fastspring,
                            hasInitializedOffer: true
                        })
                    }
                }

            if (isNull(trialsubscriptionPlan) && 
                isNotNull(trialPlan) && 
                prevProps.trialPlan !== trialPlan && 
                !trialPlan.loading) {
                    if (isNotNull(trialPlan.offer)) {
                        onSetNewState(this, {
                            trialsubscriptionPlan: trialPlan.offer,
                            hasInitializedOffer: true
                        })
                    }
                }
                
            if (isNull(sixMonthsPlanOffer) && 
                isNotNull(sixMonthPlan) && 
                prevProps.sixMonthPlan !== sixMonthPlan && 
                !sixMonthPlan.loading) {
                    if (isNotNull(sixMonthPlan.offer)) {
                        onSetNewState(this, {
                            sixMonthsPlanOffer: sixMonthPlan.offer,
                            hasInitializedOffer: true
                        })
                    }
                }

            if (isNull(userInfo) && 
                isNotNull(authentication.user)) {
                    const { ...data } = authentication.user
                    onSetNewState(this, {
                        userInfo: {
                            id: data.userId,
                            customerId: data.customerId,
                            leadId: data.leadId,
                            isLead: data.isLead,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            userName: data.userName,
                            hasTrialHistory: data.hasTrialHistory,
                            isWithinTrialPeriod: data.isWithinTrialPeriod,
                            trialEndsAt: data.trialPeriodEndDate,
                            hasTrialEnded: data.hasTrialEnded,
                            hasSubscribed: data.isPaid,
                            expiry: data.expiry
                        }
                    })
                }

            if (!hasInitializedOffer && 
                !isInitializingOffer && 
                isNotNull(userInfo)) {
                    if (!userInfo.hasTrialHistory && isNull(trialsubscriptionPlan)) {
                        onSetNewState(this, {
                            isInitializingOffer: true
                        }, () => {                            
                            dispatch(planActions.getTrialOffer())
                        })
                    } else {
                        if (isNull(subscriptionPlans)) {
                            onSetNewState(this, {
                                isInitializingOffer: true
                            }, () => {
                                dispatch(planActions.get())
                                dispatch(planActions.getSixMonthsOffer())
                            })
                        }
                    }
                }

            if (isNull(show) && 
                isNotNull(userInfo) && 
                hasInitializedOffer) {
                    onSetNewState(this, {
                        show: true
                    }, async () => {
                        await this.startFsWebhookConnection(userInfo.userName)
                    })
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
        onSetNewState(this, this.baseState)

        if (this.fsHubConnection !== null) {
            if (!this.fsHubConnection.hasOwnProperty('close'))
                this.fsHubConnection.stop()
        }
    }

    startListening = () => {
        window.onPaymentReceived = data => {
            if (isNotNull(data)) {
                Swal.fire({
                    title: 'Payment successful, please wait while we setup your account.',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    didOpen: () => Swal.showLoading()
                })
            }
        }
    }

    initializeHubConnection = () => {
        let hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${fsWebhook}/fs`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .configureLogging(signalR.LogLevel.None)
            .withAutomaticReconnect([0,0,5000])
            .build()
        hubConnection.serverTimeoutInMilliseconds = 120000

        this.fsHubConnection = hubConnection
        this.fsHubConnection.on('OnPaymentMade', (payload) => {
            const { ...purchase } = payload
            const { userInfo } = this.state
            
            if (isNotNull(purchase) && 
                isNotNull(userInfo) && 
                purchase.userId === userInfo.id) {

                window.dataLayer.push({
                    event: 'purchase',
                    user_id: purchase.userId,
                    product: purchase.product,
                    subscriptionPlanId: purchase.subscriptionPlanId,
                    subscriptionRef: purchase.subscriptionRef,
                    amount: purchase.amount,
                    isRenewal: purchase.isRenewal
                })

                window.dataLayer.push({
                    event: 'proPurchase',
                    user_id: purchase.userId,
                    product: purchase.product,
                    subscriptionPlanId: purchase.subscriptionPlanId,
                    subscriptionRef: purchase.subscriptionRef,
                    amount: purchase.amount,
                    isRenewal: purchase.isRenewal
                })

                jQuery(document).find('#fscCanvas').remove()
                Swal.close()
                window.location = "/dashboard"
            }
        })
    }

    startFsWebhookConnection = async (username) => {
        if (this.mounted) {
            try {
                await this.fsHubConnection
                    .start()
                    .catch(err => console.log(err))

                if (this.fsHubConnection.state === signalR.HubConnectionBuilder.Connected) {
                    this.fsHubConnection.invoke("GetConnectionId", username)
                        .then((connectionId) => {
                            console.log('conn: ', connectionId)
                        })
                        .catch(err => console.log(err))
                }
            }
            catch (err) {
                if (this.fsHubConnection.state === signalR.HubConnectionState.Disconnected) {
                    setTimeout(async () => {
                        await this.startFsWebhookConnection(username)
                    }, 3000)
                }
            }
        }
    }

    renderFinalOffer = (endDate) => {
        const { userInfo, sixMonthsPlanOffer } = this.state

        let now = moment()
        let parsedDate = Date.parse(endDate)
        let expirationDate = new Date(parsedDate)
            expirationDate = moment(expirationDate).format('MM/DD/yyyy h:mm:ss')

        let duration = moment.duration(now.diff(expirationDate))
        let hours = duration.asHours()
        let newExpiry = now.add(72, 'hours')

        // 99 hours => 72 + 24 bonus time
        if (parseInt(hours) <= 99) {
            return (
                <>
                    <LimitedSixMonthsOffer 
                        validUntil={newExpiry} />
                    <SMIntro />
                    <LastChance />
                    <SMPackageBreakdown />
                    {/* <SMProStats /> */}
                    <ThreeYearStatsSection />
                    <SixMonths
                        validUntil={newExpiry}
                        userInfo={userInfo} 
                        subscriptionPlan={sixMonthsPlanOffer} />
                    <TechnoTigerSection />
                    <SMTheNetPeakGains />
                    <EpicOpportunitiesSection />
                    <WeeklyTotalMarketCapSection />
                    <SMGorgeOnCrypto 
                        validUntil={newExpiry} 
                        userInfo={userInfo}
                        subscriptionPlan={sixMonthsPlanOffer} />
                    <ThePathWellTroddenSection />
                    <SMUnleashTheTradingBeast
                        validUntil={newExpiry} 
                        userInfo={userInfo}
                        subscriptionPlan={sixMonthsPlanOffer} />
                    <BearMarketResultsSection />
                    <GambleYouNeedToTakeSection />
                    <SecureYourFutureSection />
                    <TestimonialsSection />
                    <SMRecap
                        validUntil={newExpiry} 
                        userInfo={userInfo}
                        subscriptionPlan={sixMonthsPlanOffer} />
                    <FaqSection withinTrial={true} />
                </>
            )
        } else { 
            return (
                <ProPlans />
            )
        }
    }

    renderRetentionOffer = (dateExpired) => {
        const { userInfo, sixMonthsPlanOffer } = this.state

        let now = moment()
        let parsedDate = Date.parse(dateExpired)
        let expirationDate = new Date(parsedDate)
            expirationDate = moment(expirationDate).format('MM/DD/yyyy h:mm:ss')

        let duration = moment.duration(now.diff(expirationDate))
        let pastDays = duration.asDays()
        let newExpiry = now.add(8, 'days')

        // 8 days retention offer
        if (parseInt(pastDays) <= 8) {
            return (
                <>
                    <LimitedRetentionOffer 
                        validUntil={newExpiry} />
                    <RetIntro />
                    <RetLastChance />
                    <TradeDoctorSessions />
                    <RetSixMonths 
                        validUntil={newExpiry}
                        userInfo={userInfo} 
                        subscriptionPlan={sixMonthsPlanOffer} />
                    <SMTheNetPeakGains />
                    <EpicOpportunitiesSection />
                    <WeeklyTotalMarketCapSection />
                    <ThePathWellTroddenSection />
                    <BearMarketResultsSection />
                    <RetRecap
                        validUntil={newExpiry} 
                        userInfo={userInfo}
                        subscriptionPlan={sixMonthsPlanOffer} />
                    <RetFaq />
                </>
            )
        } else { 
            return (
                <ProPlans />
            )
        }
    }

    render() {
        const { 
            show, 
            userInfo, 
            trialsubscriptionPlan,
            earlyBirdPlan,
            finalYearlyOffer } = this.state

        if (isNotNull(show) && show && 
            isNotNull(userInfo)) { 
            if (userInfo.hasSubscribed) {
                // yearly offer
                if (userInfo.isWithinTrialPeriod)
                    return (
                        <>
                            <LimitedOffer validUntil={userInfo.trialEndsAt} />
                            <IntroSection 
                                page="upgrade"
                                withinTrial={true} 
                                subscriptionPlan={earlyBirdPlan} 
                                finalYearlyOffer={finalYearlyOffer} 
                                userInfo={userInfo} 
                                validUntil={userInfo.trialEndsAt} />
                            <JumpStartSection validUntil={userInfo.trialEndsAt} />
                            <PackageBreakdownSection validUntil={userInfo.trialEndsAt} />
                            {/* <ProStatsSection 
                                page="upgrade"
                                withinTrial={true} 
                                validUntil={userInfo.trialEndsAt} /> */}
                            <ThreeYearStatsSection />
                            <ThreeSixFiveDaysSection 
                                subscriptionPlan={earlyBirdPlan} 
                                userInfo={userInfo} 
                                finalYearlyOffer={finalYearlyOffer} 
                                validUntil={userInfo.trialEndsAt} />
                            <TechnoTigerSection />
                            <TheNetPeakGainsSection validUntil={userInfo.trialEndsAt} />
                            <EpicOpportunitiesSection />
                            <WeeklyTotalMarketCapSection />
                            <GorgeOnCryptoSection 
                                userInfo={userInfo}
                                subscriptionPlan={earlyBirdPlan} 
                                finalYearlyOffer={finalYearlyOffer} 
                                validUntil={userInfo.trialEndsAt} />
                            <ThePathWellTroddenSection />
                            <UnleashTheTradingBeastSection 
                                userInfo={userInfo} 
                                subscriptionPlan={earlyBirdPlan} 
                                finalYearlyOffer={finalYearlyOffer} 
                                validUntil={userInfo.trialEndsAt} />
                            <BearMarketResultsSection />
                            <GambleYouNeedToTakeSection />
                            <SecureYourFutureSection />
                            <TestimonialsSection />
                            <AdvancedSignalsAlgorithmsSection />
                            <RecapSection 
                                userInfo={userInfo}
                                subscriptionPlan={earlyBirdPlan} 
                                finalYearlyOffer={finalYearlyOffer} 
                                validUntil={userInfo.trialEndsAt} />
                            <FaqSection withinTrial={true} />
                        </>
                    )
                else
                    return (<Redirect to={{ pathname: '/dashboard' }} />)
            } else {
                // $21 trial offer
                if (!userInfo.hasTrialHistory) {
                    return (
                        <>
                            <IntroSection 
                                page="upgrade"
                                subscriptionPlan={trialsubscriptionPlan} 
                                userInfo={userInfo} /> 
                            
                            <ProTrialDaysSection />
                            
                            <PremiumFeaturesSection />

                            <BlueprintSection 
                                subscriptionPlan={trialsubscriptionPlan} 
                                userInfo={userInfo} />

                            <FreeVsProSection
                                subscriptionPlan={trialsubscriptionPlan} 
                                userInfo={userInfo} />

                            <ProStatsSection page="upgrade" />
                            <PowerPFSection />
                            <LadderingModeSection />
                            <TestimonialsSection />
                            <TradesInActionSection />

                            <CancelAnytimeSection
                                subscriptionPlan={trialsubscriptionPlan} 
                                userInfo={userInfo} />

                            <SignUpStepsSection />
                            <TradeGuardianSection page="upgrade" />
                            <ProEmpowerSection />
                            <BreakevenSection />

                            <SaveMoreSection 
                                page="upgrade"
                                subscriptionPlan={trialsubscriptionPlan} 
                                userInfo={userInfo} />

                            <FaqSection />
                        </>
                    )
                } else {
                    if (isNotNull(userInfo.trialEndsAt))
                        return (
                            <>{ this.renderFinalOffer(userInfo.trialEndsAt) }</>
                        )
                    else 
                        return (
                            <>{ this.renderRetentionOffer(userInfo.expiry) }</>
                        )
                } 
            }
        }

        return (<></>)
    }
}

function mapStateToProps(state) {
    const { authentication, plan, trialPlan, sixMonthPlan } = state
    return {
        authentication,
        plan,
        trialPlan,
        sixMonthPlan
    }
}

const connectedUpgradeOfferContainer = connect(mapStateToProps)(UpgradeOfferContainer)
export { connectedUpgradeOfferContainer as UpgradeOfferContainer }