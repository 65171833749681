import React from 'react'
import { connect } from 'react-redux'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { Form, Col } from 'react-bootstrap'
import _ from 'lodash'
import { TradeFormHeader, TradeFormSignalDetails, BasicForm } from '../TradingForms'
import {
    TradeFormContainer, TradeFormContent,
    CoinPairingWrapper, TradeFormLabel, TradeDivider,
    BalanceWrapper, WalletBalanceWrapper
} from '../../styled_components'
import { isLiveTradeOrder } from '../../../../services/CONSTANTS'
import { onSetNewState, isNull, isNotNull, toUsdFormat, toBtcFormat, getFromSym, ScientificToDecimal } from '../../../../utils'
import { defaultTargetPricesQty } from '../../../../utils/CONSTANTS'
import { onboardingState } from '../../../../constants/onboardingState'

class TradeForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isPremiumUser: null,
            isFree: null,
            feed: null,
            direction: null,
            signalInfo: null,
            symbol: '',
            fromSym: '',
            toSym: '',
            btcUsdtLastPrice: '',
            btcBusdLastPrice: '',
            isLoadingBtcUsdtLastPrice: false,
            isLoadingBtcBusdLastPrice: false,
            lastPrice: '',
            isLoadingLastPrice: false,
            askPrice: null,
            isLoadingAskPrice: false,
            bidPrice: null,
            isLoadingBidPrice: false,
            myEntryPrice: null,
            myTargetPrices: null,
            myStoplossPrice: null,
            tradeStrategyOptions: [{
                name: 'Basic',
                value: 'basic',
            }, {
                name: 'R:R Hunter T1/T2 Only',
                value: 'rrhunter',
            }, {
                name: 'Sniper',
                value: 'sniper',
            }, {
                name: 'Strong Up Trend',
                value: 'stronguptrend'
            }, {
                name: 'Weak Up Trend',
                value: 'weakuptrend'
            }, {
                name: 'Aggressive Stop Loss',
                value: 'aggressivestoploss'
            }, {
                name: 'Heiko Aggressive Strategy',
                value: 'heikoaggressive'
            }, {
                name: 'The Moon Bag',
                value: 'moonbag'
            }],
            // tradeStrategyOptions: [{
            //         name: 'Defensive',
            //         value: 'def',
            //     }, {
            //         name: 'Standard',
            //         value: 'std',
            //     }, {
            //         name: 'Aggresive',
            //         value: 'agr',
            //     }
            //     // ,
            //     // {
            //     //     name: 'Optimised',
            //     //     value: 'opt',
            //     // }
            // ],
            priceTypeOptions: [{
                    name: 'LAST price follow',
                    value: 'LAST'
                }, {
                    name: 'BID price follow',
                    value: 'BID'
                }, {
                    name: 'ASK price follow',
                    value: 'ASK'
                }
            ],
            breakEvenTypeOptions: [{
                    name: 'UP 1 step behind',
                    value: ''
                }, {
                    name: 'UP 2 steps behind',
                    value: 2
                }, {
                    name: 'Entry only',
                    value: 1
                }
            ],
            marginModeOptions: [{
                    name: 'CROSS',
                    value: 'CROSSED'
                }, {
                    name: 'ISOLATED',
                    value: 'ISOLATED'
                }
            ],
            enableAdvancedForm: false,
            orderDetails: {
                feed: null,
                exchange: 'binance',
                signalId: "",
                tradeStrategy: 'basic',
                coinpair: "",
                leverage: null,
                orderType: 'OSO - CUSTOM',
                volume: 0,
                qtyInUsdt: 0,
                isMarketBuy: false,
                hasLadderedEntries: false,
                primaryOrder: {
                    orderType: '',
                    orderSubType: '',
                    stopCondition: "",
                    autoVolume: false,
                    entryPrice: "",
                    stopPrice: 0.00000,
                    targets: null,
                    partialOso: "",
                    breakeven: false,
                    priceType: '',
                    breakevenType: '',
                    firstTargetFill: ""
                },
                secondaryOrder: {
                    order1: {
                        orderType: '',
                        orderSubType: '',
                        stopCondition: "",
                        autoVolume: true,
                        entryPrice: "",
                        stopPrice: 0.00001,
                        targets: null,
                        breakeven: false,
                        priceType: '',
                        breakevenType: ''
                    },
                    order2: {
                        orderType: '',
                        orderSubType: '',
                        stopCondition: "",
                        autoVolume: true,
                        entryPrice: "",
                        stopPrice: 0.00002,
                        targets: null,
                        breakeven: true,
                        priceType: '',
                        breakevenType: ''
                    }
                },
                isLive: isLiveTradeOrder
            },
            wallet: null
        }

        this.baseState = this.state

        this.priceFeedInterval = null
        this.btcUsdtPriceFeedInterval = null
        this.btcBusdPriceFeedInterval = null
        this.bidAndAskPriceFeedInterval = null

        this.bidPriceInterval = null
        this.askPriceInterval = null

        this.wsClient = null
        this.btcUsdtWsClient = null
        this.btcBusdWsClient = null
        this.bidAndAskWsClient = null
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        const { isPremiumUser } = this.state
        const { user, signalDetails, selectedFeed } = this.props

        if (isNull(isPremiumUser) && isNotNull(user)) {
            onSetNewState(this, {
                isPremiumUser: user.isPaid
            })
        }

        // setting of entry preset values
        let { orderDetails } = this.state
        let { tradeStrategy, primaryOrder, secondaryOrder } = orderDetails
        let { order1, order2 } = secondaryOrder

        if (isNotNull(signalDetails) && isNotNull(signalDetails.item)) {
            const { item } = signalDetails

            let defaultQuantities = isNotNull(tradeStrategy)
                ? defaultTargetPricesQty(tradeStrategy, item.targets.length)
                : null

            // setting preset order details
            orderDetails.signalId = item.id

            // setting exchange
            if (isNotNull(selectedFeed)) {
                orderDetails.feed = selectedFeed.toUpperCase()
                orderDetails.exchange = selectedFeed === 'futures' ? 'binancefutures' : 'binance'
            } else {
                orderDetails.feed = item.feed.toUpperCase()
            }

            // extracting from and to symbols
            let fromSym = getFromSym(item.symbol, item.toSym)
            if (isNotNull(item.toSym) && isNotNull(fromSym))
                orderDetails.coinpair = `${item.toSym}/${fromSym}`

            if (isNotNull(item.leverage))
                orderDetails.leverage = item.leverage

            // check if this signal is spot or futures
            if (orderDetails.feed === "SPOT") {
                // set exchange
                // orderDetails.exchange = "binance"
                // set default price type
                primaryOrder.priceType = "LAST"
                primaryOrder.breakevenType = ""
                // set order types
                if (item.direction.toUpperCase() === "BUY") {
                    primaryOrder.orderType = "STOP_BUY"
                    primaryOrder.orderSubType = "MARKET"
                    primaryOrder.stopCondition = "lte"

                    order1.orderType = "TAKE_PROFIT"
                    order1.orderSubType = "MARKET"
                    order1.priceType = "LAST"
                    order1.breakevenType = ""
                    order1.autoVolume = true

                    // populate preset target prices
                    order1.targets = []
                    item.targets.forEach((i) => {
                        order1.targets.push({
                            id: i.id,
                            index: i.index,
                            stopPrice: i.startPrice,
                            relativeStopPrice: "",
                            quantity: isNotNull(defaultQuantities) ? defaultQuantities[i.index - 1] : 0
                        })
                    })

                    order2.orderType = "STOP_LOSS"
                    order2.orderSubType = "MARKET"
                    order2.entryPrice = parseFloat(item.stopLoss)
                    order2.stopPrice = parseFloat(item.stopLoss)
                    order2.priceType = "LAST"
                    order2.breakevenType = ""
                    order2.autoVolume = true

                    secondaryOrder.order1 = order1
                    secondaryOrder.order2 = order2
                } else {
                    primaryOrder.orderType = "TAKE_PROFIT"
                    primaryOrder.orderSubType = "MARKET"
                    primaryOrder.breakevenType = ""

                    order1.orderType = "STOP_BUY"
                    order1.orderSubType = "MARKET"
                    order1.stopCondition = "lte"
                    order1.priceType = "LAST"
                    order1.breakevenType = ""
                    order1.autoVolume = true
                    order1.targets = []
                    item.targets.forEach((i) => {
                        order1.targets.push({
                            id: i.id,
                            index: i.index,
                            stopPrice: i.startPrice,
                            relativeStopPrice: "",
                            quantity: isNotNull(defaultQuantities) ? defaultQuantities[i.index - 1] : 0
                        })
                    })

                    order2.orderType = "STOP_BUY"
                    order2.orderSubType = "MARKET"
                    order2.stopCondition = "gte"
                    order2.entryPrice = parseFloat(item.stopLoss)
                    order2.stopPrice = parseFloat(item.stopLoss)
                    order2.priceType = "LAST"
                    order2.breakevenType = ""
                    order2.breakeven = true
                    order2.autoVolume = true

                    secondaryOrder.order1 = order1
                    secondaryOrder.order2 = order2

                    //console.log("spot trading with SHORT signals is not supported yet")
                }
            } else {
                // set exchange    
                // orderDetails.exchange = "binancefutures"
                // set default price type
                primaryOrder.priceType = "LAST"
                primaryOrder.breakevenType = ""
                // set order types
                if (item.direction.toUpperCase() === "BUY") {
                    primaryOrder.orderType = "STOP_BUY"
                    primaryOrder.orderSubType = "MARKET"
                    primaryOrder.stopCondition = "lte"

                    order1.orderType = "STOP_LOSS"
                    order1.orderSubType = "MARKET"
                    order1.entryPrice = parseFloat(item.stopLoss)
                    order1.stopPrice = parseFloat(item.stopLoss)
                    order1.priceType = "LAST"
                    order1.breakevenType = ""
                    order1.breakeven = true
                    order1.autoVolume = true

                    order2.orderType = "TAKE_PROFIT"
                    order2.orderSubType = "MARKET"
                    order2.priceType = "LAST"
                    order2.breakevenType = ""
                    order2.autoVolume = true
                    // populate preset target prices
                    order2.targets = []
                    item.targets.forEach((i) => {
                        order2.targets.push({
                            id: i.id,
                            index: i.index,
                            stopPrice: i.startPrice,
                            relativeStopPrice: "",
                            quantity: isNotNull(defaultQuantities) ? defaultQuantities[i.index - 1] : 0
                        })
                    })

                    secondaryOrder.order1 = order1
                    secondaryOrder.order2 = order2
                } else {
                    primaryOrder.orderType = "TAKE_PROFIT"
                    primaryOrder.orderSubType = "MARKET"
                    primaryOrder.breakevenType = ""

                    order1.orderType = "STOP_BUY"
                    order1.orderSubType = "MARKET"
                    order1.stopCondition = "lte"
                    order1.priceType = "LAST"
                    order1.breakevenType = ""
                    order1.autoVolume = true
                    order1.targets = []
                    item.targets.forEach((i) => {
                        order1.targets.push({
                            id: i.id,
                            index: i.index,
                            stopPrice: i.startPrice,
                            relativeStopPrice: "",
                            quantity: isNotNull(defaultQuantities) ? defaultQuantities[i.index - 1] : 0
                        })
                    })

                    order2.orderType = "STOP_BUY"
                    order2.orderSubType = "MARKET"
                    order2.stopCondition = "gte"
                    order2.entryPrice = parseFloat(item.stopLoss)
                    order2.stopPrice = parseFloat(item.stopLoss)
                    order2.priceType = "LAST"
                    order2.breakevenType = ""
                    order2.breakeven = true
                    order2.autoVolume = true

                    secondaryOrder.order1 = order1
                    secondaryOrder.order2 = order2
                }
            }

            orderDetails.primaryOrder = primaryOrder
            orderDetails.secondaryOrder = secondaryOrder

            if (this.mounted) {
                onSetNewState(this, {
                    signalInfo: item,
                    isFree: item.isFree,
                    feed: orderDetails.feed, //item.feed,
                    direction: item.direction,
                    symbol: item.symbol,
                    fromSym,
                    toSym: item.toSym,
                    orderDetails
                })

                this.initPriceFeedBySymbol(item.symbol, fromSym, item.feed)
                this.initBookTickerBySymbol(item.symbol, fromSym, item.feed)

                this.initEntryPrice("basic", fromSym, item.entryStartPrice, item.entryEndPrice)
                this.initTargetPrices("basic")

                this.initBtcUsdtPriceFeed(item.feed)
                this.initBtcBusdPriceFeed(item.feed)
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { cryptoWallet, futureWallet } = this.props
        let { feed, wallet } = this.state

        if (isNotNull(feed) && isNull(wallet)) {
            if (feed.toUpperCase() === "SPOT") {
                if (isNotNull(cryptoWallet) &&
                    isNotNull(cryptoWallet.items) &&
                    isNotNull(cryptoWallet.items.data)) {
                    onSetNewState(this, {
                        wallet: {
                            usdt: _.find(cryptoWallet.items.data, c => c.name === "USDT"),
                            btc: _.find(cryptoWallet.items.data, c => c.name === "BTC"),
                            busd: _.find(cryptoWallet.items.data, c => c.name === "BUSD")
                        }
                    })
                }
            } else {
                if (isNotNull(futureWallet) &&
                    isNotNull(futureWallet.items) &&
                    isNotNull(futureWallet.items.data)) {
                    onSetNewState(this, {
                        wallet: {
                            usdt: _.find(futureWallet.items.data, c => c.name === "USDT"),
                            btc: _.find(futureWallet.items.data, c => c.name === "BTC"),
                            busd: _.find(cryptoWallet.items.data, c => c.name === "BUSD")
                        }
                    })
                }
            }
        }
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)

        if (isNotNull(this.wsClient)) {
            this.wsClient.close()
            this.wsClient = null
        }

        if (isNotNull(this.btcUsdtWsClient)) {
            this.btcUsdtWsClient.close()
            this.btcUsdtWsClient = null
        }

        if (isNotNull(this.btcBusdWsClient)) {
            this.btcBusdWsClient.close()
            this.btcBusdWsClient = null
        }

        if (isNotNull(this.bidAndAskWsClient)) {
            this.bidAndAskWsClient.close()
            this.bidAndAskWsClient = null
        }

        clearTimeout(this.bidAndAskPriceFeedInterval)
        this.bidAndAskPriceFeedInterval = null

        clearTimeout(this.bidPriceInterval)
        this.bidPriceInterval = null

        clearTimeout(this.askPriceInterval)
        this.askPriceInterval = null

        clearTimeout(this.priceFeedInterval)
        this.priceFeedInterval = null

        clearTimeout(this.btcUsdtPriceFeedInterval)
        this.btcUsdtPriceFeedInterval = null

        clearTimeout(this.btcBusdPriceFeedInterval)
        this.btcBusdPriceFeedInterval = null

        this.mounted = false
    }

    initBtcUsdtPriceFeed = (feed) => {
        if (this.mounted && isNull(this.btcUsdtWsClient)) {
            let baseUrl = feed === 'SPOT'
                ? 'wss://stream.binance.com:9443/ws/'
                : 'wss://fstream.binance.com/ws/'
            let streamName = `${baseUrl}btcusdt@ticker`
            this.btcUsdtWsClient = new W3CWebSocket(streamName)

            // connect to binance websockets
            // this.btcUsdtWsClient.onopen = () => {
            //     console.log(`Listening to BTCUSDT price feed`)
            // }

            // try to reconnect when connection is closed
            this.btcUsdtWsClient.onclose = () => {
                this.btcUsdtPriceFeedInterval = setTimeout(() => {
                    this.btcUsdtWsClient = null
                    this.initBtcUsdtPriceFeed(feed)
                }, 3000)
            }

            // try to reconnect when connection throws an error
            this.btcUsdtWsClient.onerror = () => {
                this.btcUsdtPriceFeedInterval = setTimeout(() => {
                    this.btcUsdtWsClient = null
                    this.initBtcUsdtPriceFeed(feed)
                }, 3000)
            }

            this.btcUsdtWsClient.onmessage = (response) => {
                let { data } = response
                if (isNotNull(data)) {
                    data = JSON.parse(data)
                    if (data.e === "24hrTicker") {
                        let { btcUsdtLastPrice } = this.state

                        if (parseFloat(btcUsdtLastPrice) !== parseFloat(data.c)) {
                            this.onSetBtcUsdtLastPrice(toUsdFormat(data.c))
                            // onSetNewState(this, {
                            //     btcUsdtLastPrice: toUsdFormat(data.c)
                            // })
                        }
                    }
                }
            }
        }
    }

    initBtcBusdPriceFeed = (feed) => {
        if (this.mounted && isNull(this.btcBusdWsClient)) {
            let baseUrl = feed === 'SPOT' 
                ? 'wss://stream.binance.com:9443/ws/'
                : 'wss://fstream.binance.com/ws/'
            let streamName = `${baseUrl}btcbusd@ticker`
            this.btcBusdWsClient = new W3CWebSocket(streamName)
            
            // try to reconnect when connection is closed
            this.btcBusdWsClient.onclose = () => {
                this.btcBusdPriceFeedInterval = setTimeout(() => {
                    this.btcBusdWsClient = null
                    this.initBtcBusdPriceFeed(feed)
                }, 3000)
            }

            // try to reconnect when connection throws an error
            this.btcBusdWsClient.onerror = () => {
                this.btcBusdPriceFeedInterval = setTimeout(() => {
                    this.btcBusdWsClient = null
                    this.initBtcBusdPriceFeed(feed)
                }, 3000)
            }

            this.btcBusdWsClient.onmessage = (response) => {
                let { data } = response
                if (isNotNull(data)) {
                    data = JSON.parse(data)
                    if (data.e === "24hrTicker") {
                        let { btcBusdLastPrice } = this.state
                        if (parseFloat(btcBusdLastPrice) !== parseFloat(data.c)) {
                            this.onSetBtcBusdLastPrice(toBtcFormat(data.c))
                        }
                    }
                }
            }
        }
    }

    initPriceFeedBySymbol = (symbol, fromSym, feed) => {
        if (this.mounted && isNull(this.wsClient)) {
            let baseUrl = feed === 'SPOT'
                ? 'wss://stream.binance.com:9443/ws/'
                : 'wss://fstream.binance.com/ws/'
            let streamName = `${baseUrl}${symbol.toLowerCase()}@ticker`
            this.wsClient = new W3CWebSocket(streamName)

            // connect to binance websockets
            // this.wsClient.onopen = () => {
            //     console.log(`Listening to ${symbol} price feed`)
            // }

            // try to reconnect when connection is closed
            this.wsClient.onclose = () => {
                this.priceFeedInterval = setTimeout(() => {
                    this.wsClient = null
                    this.initPriceFeedBySymbol(symbol, fromSym, feed)
                }, 3000)
            }

            // try to reconnect when connection throws an error
            this.wsClient.onerror = () => {
                this.priceFeedInterval = setTimeout(() => {
                    this.wsClient = null
                    this.initPriceFeedBySymbol(symbol, fromSym, feed)
                }, 3000)
            }

            this.wsClient.onmessage = (response) => {
                let { data } = response
                if (isNotNull(data)) {
                    data = JSON.parse(data)
                    if (data.e === "24hrTicker") {
                        let { lastPrice, bidPrice, askPrice } = this.state

                        if (isNotNull(symbol)) {
                            if (symbol === data.s) {
                                let isUsdSignal = (symbol.endsWith("USDT") || symbol.endsWith("USD")) ? true : false

                                // symbol last price
                                if (parseFloat(lastPrice) !== parseFloat(data.c)) {
                                    let price = ""

                                    if (isUsdSignal) {
                                        if (parseFloat(data.c) < 1)
                                            price = data.c
                                        else
                                            price = toUsdFormat(data.c)
                                    } else 
                                        price = data.c


                                    this.onSetLastPrice(price)
                                    // onSetNewState(this, {
                                    //     lastPrice: isUsdSignal ? toUsdFormat(data.c) : toBtcFormat(data.c)
                                    // })
                                }

                                // symbol bid price
                                if (isNull(bidPrice) && parseFloat(bidPrice) !== data.b) {
                                    let newBidPrice = ""
                                    if (fromSym.includes("USD")) {
                                        if (parseFloat(data.b) < 1)
                                            newBidPrice = data.b
                                        else
                                            newBidPrice = toUsdFormat(data.b)
                                    } else 
                                        newBidPrice = data.b

                                    this.onSetBidPrice(newBidPrice)
                                }

                                // // symbol ask price
                                if (isNull(askPrice) && parseFloat(askPrice) !== data.a) {
                                    let newAskPrice = ""
                                    if (fromSym.includes("USD")) {
                                        if (parseFloat(data.a) < 1)
                                            newAskPrice = data.a
                                        else
                                            toUsdFormat(data.a)
                                    } else
                                        newAskPrice = data.a

                                    this.onSetAskPrice(newAskPrice)
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    initBookTickerBySymbol = (symbol, fromSym, feed) => {
        if (this.mounted && isNull(this.bidAndAskWsClient)) {
            let baseUrl = feed === 'SPOT'
                ? 'wss://stream.binance.com:9443/ws/'
                : 'wss://fstream.binance.com/ws/'
            let streamName = `${baseUrl}${symbol.toLowerCase()}@bookTicker`
            this.bidAndAskWsClient = new W3CWebSocket(streamName)

            // connect to binance websockets
            // this.bidAndAskWsClient.onopen = () => {
            //     console.log(`Listening to ${symbol} bid and ask prices feed`)
            // }

            // try to reconnect when connection is closed
            this.bidAndAskWsClient.onclose = () => {
                this.bidAndAskPriceFeedInterval = setTimeout(() => {
                    this.bidAndAskWsClient = null
                    this.initBookTickerBySymbol(symbol, fromSym, feed)
                }, 3000)
            }

            // try to reconnect when connection throws an error
            this.bidAndAskWsClient.onerror = () => {
                this.bidAndAskPriceFeedInterval = setTimeout(() => {
                    this.bidAndAskWsClient = null
                    this.initBookTickerBySymbol(symbol, fromSym, feed)
                }, 3000)
            }

            this.bidAndAskWsClient.onmessage = (response) => {
                let { data } = response
                if (isNotNull(data)) {
                    data = JSON.parse(data)
                    if (isNotNull(data)) {
                        const { bidPrice, askPrice } = this.state
                        if (isNotNull(symbol)) {
                            if (symbol === data.s) {
                                let newBidPrice = ""
                                let newAskPrice = ""

                                if (fromSym.includes("USD")) {
                                    if (parseFloat(data.b) < 1)
                                        newBidPrice = data.b
                                    else
                                        newBidPrice = toUsdFormat(data.b)
                                        
                                    if (parseFloat(data.a) < 1)
                                        newAskPrice = data.a
                                    else
                                        newAskPrice = toUsdFormat(data.a)
                                } else {
                                    newBidPrice = data.b
                                    newAskPrice = data.a
                                }

                                // console.log('bid: ', data.b)
                                // console.log('ask: ', data.a)
                                // const newBidPrice = fromSym.includes("USD") ? toUsdFormat(data.b) : data.b
                                // console.log('new bid price: ' ,newBidPrice)
                                // const newAskPrice = fromSym.includes("USD") ? toUsdFormat(data.a) : data.a

                                // symbol bid price
                                if (isNull(bidPrice)) {
                                    if (parseFloat(bidPrice) !== data.b)
                                        this.onSetBidPrice(newBidPrice)
                                } else {
                                    if (parseFloat(bidPrice) !== data.b)
                                        this.onSetBidPrice(newBidPrice)
                                }

                                // symbol ask price
                                if (isNull(askPrice)) {
                                    if (parseFloat(askPrice) !== data.a)
                                        this.onSetAskPrice(newAskPrice)
                                } else {
                                    if (parseFloat(askPrice) !== data.a)
                                        this.onSetAskPrice(newAskPrice)
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    initEntryPrice = (strategy, fromSym, entryStartPrice, entryEndPrice) => {
        let { orderDetails, myEntryPrice } = this.state
        if (isNotNull(orderDetails)) {
            let { primaryOrder } = orderDetails
            entryStartPrice = parseFloat(entryStartPrice)
            entryEndPrice = parseFloat(entryEndPrice)

            switch (strategy) {
                default: {
                //case "basic": {
                // case "std": {
                    let middleValue = (entryStartPrice + entryEndPrice) / 2
                    myEntryPrice = parseFloat(middleValue)

                    if (fromSym.includes("USD")) {
                        if (parseFloat(middleValue) < 1)
                            primaryOrder.stopPrice = toBtcFormat(middleValue)
                        else
                            primaryOrder.stopPrice = toUsdFormat(middleValue)
                    } else 
                        primaryOrder.stopPrice = toBtcFormat(middleValue)

                    //primaryOrder.stopPrice = fromSym.includes("USD") ? toUsdFormat(middleValue) : toBtcFormat(middleValue)
                    break
                }
                // case "def": {
                //     primaryOrder.stopPrice = entryStartPrice
                //     myEntryPrice = parseFloat(entryStartPrice)
                //     break
                // }
                // case "agr": {
                //     primaryOrder.stopPrice = entryEndPrice
                //     myEntryPrice = parseFloat(entryEndPrice)
                //     break
                // }
                // case "opt": {
                //     // calculate the average depth of entry zone
                //     let averageDepth = ((entryEndPrice - entryStartPrice) * 0.35)
                //     let stopBuyPrice = entryEndPrice - averageDepth

                //     primaryOrder.stopPrice = stopBuyPrice
                //     myEntryPrice = parseFloat(stopBuyPrice)
                //     break
                // }
            }

            orderDetails.primaryOrder = primaryOrder
            onSetNewState(this, {
                myEntryPrice,
                orderDetails
            })
        }
    }

    initTargetPrices = (strategy) => {
        if (isNotNull(strategy)) {
            const { signalDetails } = this.props
            let { direction, orderDetails } = this.state

            if (isNotNull(signalDetails) &&
                isNotNull(direction) &&
                isNotNull(orderDetails)) {
                const { item } = signalDetails
                let { feed, secondaryOrder } = orderDetails
                let { order1, order2 } = secondaryOrder

                let defaultQuantities = null
                if (feed.toUpperCase() === "SPOT") {
                    order1.targets = []

                    switch(strategy) {
                        default:
                        case "stronguptrend":
                        case "weakuptrend":
                        case "aggressivestoploss": {
                            defaultQuantities = defaultTargetPricesQty(strategy, item.targets.length)
                            item.targets.forEach((i) => {
                                order1.targets.push({
                                    id: i.id,
                                    index: i.index,
                                    stopPrice: i.startPrice,
                                    relativeStopPrice: "",
                                    quantity: isNotNull(defaultQuantities) ? defaultQuantities[i.index - 1] : 0
                                })
                            })
                            
                            break
                        }
                        case "rrhunter": {
                            let t1 = item.targets[0]
                            order1.targets.push({
                                id: t1.id,
                                index: t1.index,
                                stopPrice: t1.startPrice,
                                relativeStopPrice: "",
                                quantity: 50
                            })
                            
                            let t2 = item.targets[1]
                            order1.targets.push({
                                id: t2.id,
                                index: t2.index,
                                stopPrice: t2.startPrice,
                                relativeStopPrice: "",
                                quantity: 50
                            })
                            break
                        }
                        case "sniper": {
                            let t1 = item.targets[0]
                            order1.targets.push({
                                id: t1.id,
                                index: t1.index,
                                stopPrice: t1.startPrice,
                                relativeStopPrice: "",
                                quantity: 40
                            })

                            let t2 = item.targets[1]
                            order1.targets.push({
                                id: t2.id,
                                index: t2.index,
                                stopPrice: t2.startPrice,
                                relativeStopPrice: "",
                                quantity: 60
                            })
                            break
                        }
                        case "heikoaggressive": {
                            let t1 = item.targets[0]
                            order1.targets.push({
                                id: t1.id,
                                index: t1.index,
                                stopPrice: t1.startPrice,
                                relativeStopPrice: "",
                                quantity: 100
                            })
                            
                            break
                        }
                        case "moonbag": {
                            let t4 = item.targets[3]
                            order1.targets.push({
                                id: t4.id,
                                index: t4.index,
                                stopPrice: t4.startPrice,
                                relativeStopPrice: "",
                                quantity: 100
                            })

                            break
                        }
                    }

                    // if (strategy === "opt") {
                    //     // select target 2
                    //     let t2 = item.targets[1]

                    //     order1.targets.push({
                    //         id: t2.id,
                    //         index: t2.index,
                    //         stopPrice: t2.startPrice,
                    //         relativeStopPrice: "",
                    //         quantity: 100
                    //     })
                    // } else {
                    //     defaultQuantities = isNotNull(strategy)
                    //         ? defaultTargetPricesQty(strategy, item.targets.length)
                    //         : null

                    //     item.targets.forEach((i) => {
                    //         order1.targets.push({
                    //             id: i.id,
                    //             index: i.index,
                    //             stopPrice: i.startPrice,
                    //             relativeStopPrice: "",
                    //             quantity: isNotNull(defaultQuantities) ? defaultQuantities[i.index - 1] : 0
                    //         })
                    //     })
                    // }
                } else {
                    defaultQuantities = defaultTargetPricesQty(strategy, item.targets.length)
                    
                    if (direction.toUpperCase() === "BUY") {
                        order2.targets = []

                        switch(strategy) {
                            default:
                            case "stronguptrend":
                            case "weakuptrend":
                            case "aggressivestoploss": {
                                item.targets.forEach((i) => {
                                    order2.targets.push({
                                        id: i.id,
                                        index: i.index,
                                        stopPrice: i.startPrice,
                                        relativeStopPrice: "",
                                        quantity: isNotNull(defaultQuantities) ? defaultQuantities[i.index - 1] : 0
                                    })
                                })
                                
                                break
                            }
                            case "rrhunter": {
                                let t1 = item.targets[0]
                                order2.targets.push({
                                    id: t1.id,
                                    index: t1.index,
                                    stopPrice: t1.startPrice,
                                    relativeStopPrice: "",
                                    quantity: 50
                                })
                                
                                let t2 = item.targets[1]
                                order2.targets.push({
                                    id: t2.id,
                                    index: t2.index,
                                    stopPrice: t2.startPrice,
                                    relativeStopPrice: "",
                                    quantity: 50
                                })
                                break
                            }
                            case "sniper": {
                                let t1 = item.targets[0]
                                order2.targets.push({
                                    id: t1.id,
                                    index: t1.index,
                                    stopPrice: t1.startPrice,
                                    relativeStopPrice: "",
                                    quantity: 40
                                })
    
                                let t2 = item.targets[1]
                                order2.targets.push({
                                    id: t2.id,
                                    index: t2.index,
                                    stopPrice: t2.startPrice,
                                    relativeStopPrice: "",
                                    quantity: 60
                                })
                                break
                            }
                            case "heikoaggressive": {
                                let t1 = item.targets[0]
                                order2.targets.push({
                                    id: t1.id,
                                    index: t1.index,
                                    stopPrice: t1.startPrice,
                                    relativeStopPrice: "",
                                    quantity: 100
                                })
                                
                                break
                            }
                            case "moonbag": {
                                let t4 = item.targets[3]
                                order2.targets.push({
                                    id: t4.id,
                                    index: t4.index,
                                    stopPrice: t4.startPrice,
                                    relativeStopPrice: "",
                                    quantity: 100
                                })
    
                                break
                            }
                        }
                    } else {
                        order1.targets = []

                        switch(strategy) {
                            default:
                            case "stronguptrend":
                            case "weakuptrend":
                            case "aggressivestoploss": {
                                item.targets.forEach((i) => {
                                    order1.targets.push({
                                        id: i.id,
                                        index: i.index,
                                        stopPrice: i.startPrice,
                                        relativeStopPrice: "",
                                        quantity: isNotNull(defaultQuantities) ? defaultQuantities[i.index - 1] : 0
                                    })
                                })
                                
                                break
                            }
                            case "rrhunter": {
                                let t1 = item.targets[0]
                                order1.targets.push({
                                    id: t1.id,
                                    index: t1.index,
                                    stopPrice: t1.startPrice,
                                    relativeStopPrice: "",
                                    quantity: 50
                                })
                                
                                let t2 = item.targets[1]
                                order1.targets.push({
                                    id: t2.id,
                                    index: t2.index,
                                    stopPrice: t2.startPrice,
                                    relativeStopPrice: "",
                                    quantity: 50
                                })
                                break
                            }
                            case "sniper": {
                                let t1 = item.targets[0]
                                order1.targets.push({
                                    id: t1.id,
                                    index: t1.index,
                                    stopPrice: t1.startPrice,
                                    relativeStopPrice: "",
                                    quantity: 40
                                })
    
                                let t2 = item.targets[1]
                                order1.targets.push({
                                    id: t2.id,
                                    index: t2.index,
                                    stopPrice: t2.startPrice,
                                    relativeStopPrice: "",
                                    quantity: 60
                                })
                                break
                            }
                            case "heikoaggressive": {
                                let t1 = item.targets[0]
                                order1.targets.push({
                                    id: t1.id,
                                    index: t1.index,
                                    stopPrice: t1.startPrice,
                                    relativeStopPrice: "",
                                    quantity: 100
                                })
                                
                                break
                            }
                            case "moonbag": {
                                let t4 = item.targets[3]
                                order1.targets.push({
                                    id: t4.id,
                                    index: t4.index,
                                    stopPrice: t4.startPrice,
                                    relativeStopPrice: "",
                                    quantity: 100
                                })
    
                                break
                            }
                        }
                    }

                    // if (strategy === "opt") {
                    //     // select target 2
                    //     let t2 = item.targets[1]

                    //     if (direction.toUpperCase() === "BUY") {
                    //         order2.targets = []
                    //         order2.targets.push({
                    //             id: t2.id,
                    //             index: t2.index,
                    //             stopPrice: t2.startPrice,
                    //             relativeStopPrice: "",
                    //             quantity: 100
                    //         })
                    //     } else {
                    //         order1.targets = []
                    //         order1.targets.push({
                    //             id: t2.id,
                    //             index: t2.index,
                    //             stopPrice: t2.startPrice,
                    //             relativeStopPrice: "",
                    //             quantity: 100
                    //         })
                    //     }
                    // } else {
                    //     defaultQuantities = isNotNull(strategy)
                    //         ? defaultTargetPricesQty(strategy, item.targets.length)
                    //         : null

                    //     if (direction.toUpperCase() === "BUY") {
                    //         order2.targets = []
                    //         item.targets.forEach((i) => {
                    //             order2.targets.push({
                    //                 id: i.id,
                    //                 index: i.index,
                    //                 stopPrice: i.startPrice,
                    //                 relativeStopPrice: "",
                    //                 quantity: isNotNull(defaultQuantities) ? defaultQuantities[i.index - 1] : 0
                    //             })
                    //         })
                    //     } else {
                    //         order1.targets = []
                    //         item.targets.forEach((i) => {
                    //             order1.targets.push({
                    //                 id: i.id,
                    //                 index: i.index,
                    //                 stopPrice: i.startPrice,
                    //                 relativeStopPrice: "",
                    //                 quantity: isNotNull(defaultQuantities) ? defaultQuantities[i.index - 1] : 0
                    //             })
                    //         })
                    //     }
                    // }
                }

                onSetNewState(this, {
                    orderDetails
                })
            }
        }
    }

    initStoplossPrice = (feed, direction, strategy, fromSym, entryStartPrice, entryEndPrice, originalStoploss) => {
        if (isNotNull(feed) && 
            isNotNull(direction) && 
            isNotNull(strategy)) {
                direction = direction.toUpperCase()
                entryStartPrice = parseFloat(entryStartPrice)
                entryEndPrice = parseFloat(entryEndPrice)

                let { orderDetails } = this.state
                let { secondaryOrder } = orderDetails
                let { order1, order2 } = secondaryOrder

                if (feed === "SPOT") {
                    let newStopPrice = ''
                    
                    if (strategy === 'aggressivestoploss') {
                        let fivePercent = ((entryEndPrice - entryStartPrice) * 0.05)
                        if (direction === 'BUY') {
                            newStopPrice = entryStartPrice - fivePercent
                        } else {
                            newStopPrice = entryEndPrice + fivePercent
                        }
                    } else if (strategy === 'heikoaggressive') {
                        let diff = entryEndPrice - entryStartPrice
                            diff /= 4

                        if (direction === 'BUY') {
                            newStopPrice = (entryEndPrice - (parseFloat(diff) * 3))
                        } else {
                            newStopPrice = (entryStartPrice + (parseFloat(diff) * 3))
                        }
                    } else {
                        newStopPrice = parseFloat(originalStoploss)
                    }

                    if (newStopPrice !== '') {
                        if (fromSym === 'BTC')
                            newStopPrice = toBtcFormat(newStopPrice)
                        else {
                            if (parseFloat(newStopPrice) < 1)
                                newStopPrice = toBtcFormat(newStopPrice)
                            else
                                newStopPrice = toUsdFormat(newStopPrice)
                        }

                        order2.entryPrice = newStopPrice
                        order2.stopPrice = newStopPrice
                        secondaryOrder.order2 = order2
                    }
                } else {
                    if (direction === "BUY") {
                        order1.entryPrice = parseFloat(item.stopLoss)
                        order1.stopPrice = parseFloat(item.stopLoss)
                        secondaryOrder.order1 = order1
                    } else {
                        order2.entryPrice = parseFloat(item.stopLoss)
                        order2.stopPrice = parseFloat(item.stopLoss)
                        secondaryOrder.order2 = order2
                    }
                }

                orderDetails.secondaryOrder = secondaryOrder

                onSetNewState(this, {
                    orderDetails
                })
        }
    }

    onSetBtcUsdtLastPrice = (price) => {
        const { isLoadingBtcUsdtLastPrice } = this.state
        if (!isLoadingBtcUsdtLastPrice) {
            onSetNewState(this, {
                btcUsdtLastPrice: price,
                isLoadingBtcUsdtLastPrice: true
            }, () => {
                setTimeout(() => {
                    onSetNewState(this, {
                        isLoadingBtcUsdtLastPrice: false
                    })
                })
            })
        }
    }

    onSetBtcBusdLastPrice = (price) => {
        const { isLoadingBtcBusdLastPrice } = this.state
        if (!isLoadingBtcBusdLastPrice) {
            onSetNewState(this, {
                btcBusdLastPrice: price,
                isLoadingBtcBusdLastPrice: true
            }, () => {
                setTimeout(() => {
                    onSetNewState(this, {
                        isLoadingBtcBusdLastPrice: false
                    })   
                })
            })
        }
    }

    onSetLastPrice = (price) => {
        const { isLoadingLastPrice } = this.state

        if (!isLoadingLastPrice) {
            onSetNewState(this, {
                lastPrice: price,
                isLoadingLastPrice: true
            }, () => {
                setTimeout(() => {
                    onSetNewState(this, {
                        isLoadingLastPrice: false
                    })
                }, 5000)
            })
        }
    }

    onSetBidPrice = (price) => {
        const { isLoadingBidPrice } = this.state

        if (!isLoadingBidPrice) {
            onSetNewState(this, {
                bidPrice: price,
                isLoadingBidPrice: true
            }, () => {
                this.bidPriceInterval = setTimeout(() => {
                    onSetNewState(this, {
                        isLoadingBidPrice: false
                    })
                }, 5000)
            })
        }
    }

    onSetAskPrice = (price) => {
        const { isLoadingAskPrice } = this.state

        if (!isLoadingAskPrice) {
            onSetNewState(this, {
                askPrice: price,
                isLoadingAskPrice: true
            }, () => {
                this.askPriceInterval = setTimeout(() => {
                    onSetNewState(this, {
                        isLoadingAskPrice: false
                    })
                }, 5000)
            })
        }
    }

    onSetStopBuyPriceByTradeStrategy = () => {
        let { fromSym, signalInfo, orderDetails } = this.state
        if (isNotNull(fromSym) && isNotNull(signalInfo) && isNotNull(orderDetails)) {
            let { tradeStrategy } = orderDetails

            this.initEntryPrice(tradeStrategy, fromSym, signalInfo.entryStartPrice, signalInfo.entryEndPrice)
            this.initTargetPrices(tradeStrategy)
        }
    }

    onSelectTradeStrategyHandler = (value) => {
        let { orderDetails } = this.state
        orderDetails.tradeStrategy = value

        onSetNewState(this, {
            orderDetails
        }, () => {
            this.onSetStopBuyPriceByTradeStrategy()
        })
    }

    onChangeFormTradeTypeHandler = (e) => {
        const { enableAdvancedForm } = this.state

        onSetNewState(this, {
            enableAdvancedForm: !enableAdvancedForm
        })
    }

    onChangeQtyInUsdtHandler = (value) => {
        let { orderDetails } = this.state
        orderDetails.qtyInUsdt = value

        onSetNewState(this, {
            orderDetails
        })
    }

    onChangeEntryPriceHandler = (value) => {
        let { orderDetails } = this.state
        let { primaryOrder } = orderDetails
        primaryOrder.stopPrice = value

        onSetNewState(this, {
            orderDetails
        })
    }

    onChangeMyEntryPriceHandler = (value) => {
        onSetNewState(this, {
            myEntryPrice: parseFloat(value)
        })
    }

    onChangeQtyInAltHandler = (value) => {
        let { orderDetails } = this.state
        orderDetails.volume = value

        onSetNewState(this, {
            orderDetails
        })
    }

    onSetBuyStopPriceHandler = (e, price) => {
        e.stopPropagation()

        if (isNotNull(price)) {
            let { orderDetails } = this.state
            let { primaryOrder } = orderDetails
            primaryOrder.stopPrice = price

            onSetNewState(this, {
                orderDetails
            })
        }
    }

    onChangeMyTargetPrices = (value) => {
        onSetNewState(this, {
            myTargetPrices: value
        })
    }

    onChangeMyStoplossPriceHandler = (value) => {
        onSetNewState(this, {
            myStoplossPrice: value
        })
    }

    onSubmitTraderOrderHandler = (order) => {
        if (isNotNull(order)) {
            const { onSubmitTradeOrderHandler } = this.props.eventHandlers
            const { direction, symbol } = this.state
            let { orderDetails } = this.state
            let { feed, primaryOrder, secondaryOrder } = orderDetails
            let { order1, order2 } = secondaryOrder

            // set usdt value
            orderDetails.qtyInUsdt = order.usdQtyValue
            
            // is market buy order
            orderDetails.isMarketBuy = order.isBuyNow

            // is laddering entries enabled
            orderDetails.hasLadderedEntries = order.hasLadderedEntries

            if (isNotNull(feed) && isNotNull(direction)) {
                if (feed.toUpperCase() === "SPOT") {
                    // set volume value
                    orderDetails.volume = order.altQtyValue

                    if (direction.toUpperCase() === "BUY") {
                        // setting entry
                        if (order.hasLadderedEntries) {
                            primaryOrder.partialOso = "true"

                            if (order.isBuyNow) {
                                primaryOrder.firstTargetFill = "true"
                            }

                            primaryOrder.targets = order.entryPrices
                        } else {
                            if (order.isBuyNow) {
                                primaryOrder.orderType = "MARKET_BUY"
                                primaryOrder.stopPrice = ""
                            } else {
                                primaryOrder.stopPrice = order.entryPriceValue
                            }
                        }

                        primaryOrder.priceType = order.priceTypeValue
                        primaryOrder.breakevenType = order.breakevenTypeValue

                        // setting targets
                        // // check if there is only 1 target
                        // if (order.targetPrices.length === 1 && order.enableBreakeven) {
                        //     // add a fake target
                        //     let fakeTarget = parseFloat(order.targetPrices[0].stopPrice) * 0.01
                        //     fakeTarget += parseFloat(order.targetPrices[0].stopPrice)
                        //     order.targetPrices.push({
                        //         id: '',
                        //         index: 2,
                        //         stopPrice: fakeTarget,
                        //         relativeStopPrice: "",
                        //         quantity: 0
                        //     })

                        //     order1.targets = order.targetPrices
                        // } else {
                        //     order1.targets = order.targetPrices
                        // }
                        order1.targets = order.targetPrices

                        order1.priceType = order.priceTypeValue
                        order1.breakevenType = order.breakevenTypeValue

                        // setting stoploss
                        order2.entryPrice = order.stopLossPriceValue
                        order2.stopPrice = order.stopLossPriceValue
                        order2.breakeven = order.enableBreakeven
                        order2.priceType = order.priceTypeValue
                        order2.breakevenType = order.breakevenTypeValue

                        secondaryOrder.order1 = order1
                        secondaryOrder.order2 = order2
                    } else {
                        // setting entry
                        if (order.hasLadderedEntries) {
                            primaryOrder.partialOso = "true"
                            
                            if (order.isBuyNow) {
                                primaryOrder.firstTargetFill = "true"
                            }

                            primaryOrder.targets = order.entryPrices
                        } else {
                            if (order.isBuyNow) {
                                primaryOrder.orderType = "MARKET_SELL"
                                primaryOrder.stopPrice = ""
                            } else {
                                primaryOrder.stopPrice = order.entryPriceValue
                            }
                        }
                        primaryOrder.priceType = order.priceTypeValue
                        primaryOrder.breakevenType = order.breakevenTypeValue
                        // setting targets
                        order1.targets = order.targetPrices
                        order1.priceType = order.priceTypeValue
                        order1.breakevenType = order.breakevenTypeValue
                        // setting stoploss
                        order2.entryPrice = order.stopLossPriceValue
                        order2.stopPrice = order.stopLossPriceValue
                        order2.breakeven = order.enableBreakeven
                        order2.priceType = order.priceTypeValue
                        order2.breakevenType = order.breakevenTypeValue

                        secondaryOrder.order1 = order1
                        secondaryOrder.order2 = order2
                    }
                } else {
                    // set leverage value
                    orderDetails.leverage = order.leverage
                    // set volume value
                    orderDetails.volume = order.altQtyValue //order.usdQtyValue

                    if (direction.toUpperCase() === "BUY") {
                        // setting entry
                        if (order.hasLadderedEntries) {
                            primaryOrder.partialOso = "true"

                            if (order.isBuyNow) {
                                primaryOrder.firstTargetFill = "true"
                            }

                            primaryOrder.targets = order.entryPrices
                        } else {     
                            if (order.isBuyNow) {
                                primaryOrder.orderType = "MARKET_BUY"
                                primaryOrder.stopPrice = ""
                            } else {
                                primaryOrder.stopPrice = order.entryPriceValue
                            }
                        }
                        primaryOrder.priceType = order.priceTypeValue
                        primaryOrder.breakevenType = order.breakevenTypeValue
                        // setting targets
                        order2.targets = order.targetPrices
                        order2.priceType = order.priceTypeValue
                        order2.breakevenType = order.breakevenTypeValue
                        // setting stoploss
                        order1.entryPrice = order.stopLossPriceValue
                        order1.stopPrice = order.stopLossPriceValue
                        order1.breakeven = order.enableBreakeven
                        order1.priceType = order.priceTypeValue
                        order1.breakevenType = order.breakevenTypeValue

                        secondaryOrder.order1 = order1
                        secondaryOrder.order2 = order2
                    } else {
                        // setting entry
                        if (order.hasLadderedEntries) {
                            primaryOrder.partialOso = "true"
                            
                            if (order.isBuyNow) {
                                primaryOrder.firstTargetFill = "true"
                            }

                            primaryOrder.targets = order.entryPrices
                        } else {
                            if (order.isBuyNow) {
                                primaryOrder.orderType = "MARKET_SELL"
                                primaryOrder.stopPrice = ""
                            } else {
                                primaryOrder.stopPrice = order.entryPriceValue
                            }
                        }
                        primaryOrder.priceType = order.priceTypeValue
                        primaryOrder.breakevenType = order.breakevenTypeValue
                        // setting targets
                        order1.targets = order.targetPrices
                        order1.priceType = order.priceTypeValue
                        order1.breakevenType = order.breakevenTypeValue
                        // setting stoploss
                        order2.entryPrice = order.stopLossPriceValue
                        order2.stopPrice = order.stopLossPriceValue
                        order2.breakeven = order.enableBreakeven
                        order2.priceType = order.priceTypeValue
                        order2.breakevenType = order.breakevenTypeValue

                        secondaryOrder.order1 = order1
                        secondaryOrder.order2 = order2
                    }
                }
            }

            orderDetails.primaryOrder = primaryOrder
            orderDetails.secondaryOrder = secondaryOrder

            onSetNewState(this, {
                orderDetails
            })
            onSubmitTradeOrderHandler(symbol, orderDetails)
        }
    }

    onShowTradeSummaryHandler = (order) => {
        if (isNotNull(order)) {
            const { onShowTradeSummaryHandler } = this.props.eventHandlers
            const { direction, symbol } = this.state
            let { orderDetails } = this.state
            let { feed, primaryOrder, secondaryOrder } = orderDetails
            let { order1, order2 } = secondaryOrder

            // set usdt value
            orderDetails.qtyInUsdt = order.usdQtyValue

            // is market buy order
            orderDetails.isMarketBuy = order.isBuyNow

            // is laddering entries enabled
            orderDetails.hasLadderedEntries = order.hasLadderedEntries

            if (isNotNull(feed) && isNotNull(direction)) {
                if (feed.toUpperCase() === "SPOT") {
                    // set volume value
                    orderDetails.volume = order.altQtyValue

                    if (direction.toUpperCase() === "BUY") {
                        // setting entry
                        if (order.hasLadderedEntries) {
                            primaryOrder.partialOso = "true"

                            if (order.isBuyNow) {
                                primaryOrder.firstTargetFill = "true"
                            }

                            primaryOrder.targets = order.entryPrices
                        } else {
                            if (order.isBuyNow) {
                                primaryOrder.orderType = "MARKET_BUY"
                                primaryOrder.stopPrice = ""
                            } else {
                                primaryOrder.stopPrice = order.entryPriceValue
                            }
                        }

                        primaryOrder.priceType = order.priceTypeValue
                        primaryOrder.breakevenType = order.breakevenTypeValue
                        order1.targets = order.targetPrices

                        order1.priceType = order.priceTypeValue
                        order1.breakevenType = order.breakevenTypeValue

                        // setting stoploss
                        order2.entryPrice = order.stopLossPriceValue
                        order2.stopPrice = order.stopLossPriceValue
                        order2.breakeven = order.enableBreakeven
                        order2.priceType = order.priceTypeValue
                        order2.breakevenType = order.breakevenTypeValue

                        secondaryOrder.order1 = order1
                        secondaryOrder.order2 = order2
                    } else {
                        // setting entry
                        if (order.hasLadderedEntries) {
                            primaryOrder.partialOso = "true"

                            if (order.isBuyNow) {
                                primaryOrder.firstTargetFill = "true"
                            }

                            primaryOrder.targets = order.entryPrices
                        } else {
                            if (order.isBuyNow) {
                                primaryOrder.orderType = "MARKET_SELL"
                                primaryOrder.stopPrice = ""
                            } else {
                                primaryOrder.stopPrice = order.entryPriceValue
                            }
                        }
                        primaryOrder.priceType = order.priceTypeValue
                        primaryOrder.breakevenType = order.breakevenTypeValue
                        // setting targets
                        order1.targets = order.targetPrices
                        order1.priceType = order.priceTypeValue
                        order1.breakevenType = order.breakevenTypeValue
                        // setting stoploss
                        order2.entryPrice = order.stopLossPriceValue
                        order2.stopPrice = order.stopLossPriceValue
                        order2.breakeven = order.enableBreakeven
                        order2.priceType = order.priceTypeValue
                        order2.breakevenType = order.breakevenTypeValue

                        secondaryOrder.order1 = order1
                        secondaryOrder.order2 = order2
                    }
                } else {
                    // set leverage value
                    orderDetails.leverage = order.leverage
                    // set volume value
                    orderDetails.volume = order.altQtyValue //order.usdQtyValue

                    if (direction.toUpperCase() === "BUY") {
                        // setting entry
                        if (order.hasLadderedEntries) {
                            primaryOrder.partialOso = "true"

                            if (order.isBuyNow) {
                                primaryOrder.firstTargetFill = "true"
                            }

                            primaryOrder.targets = order.entryPrices
                        } else {
                            if (order.isBuyNow) {
                                primaryOrder.orderType = "MARKET_BUY"
                                primaryOrder.stopPrice = ""
                            } else {
                                primaryOrder.stopPrice = order.entryPriceValue
                            }
                        }
                        primaryOrder.priceType = order.priceTypeValue
                        primaryOrder.breakevenType = order.breakevenTypeValue
                        // setting targets
                        order2.targets = order.targetPrices
                        order2.priceType = order.priceTypeValue
                        order2.breakevenType = order.breakevenTypeValue
                        // setting stoploss
                        order1.entryPrice = order.stopLossPriceValue
                        order1.stopPrice = order.stopLossPriceValue
                        order1.breakeven = order.enableBreakeven
                        order1.priceType = order.priceTypeValue
                        order1.breakevenType = order.breakevenTypeValue

                        secondaryOrder.order1 = order1
                        secondaryOrder.order2 = order2
                    } else {
                        // setting entry
                        if (order.hasLadderedEntries) {
                            primaryOrder.partialOso = "true"

                            if (order.isBuyNow) {
                                primaryOrder.firstTargetFill = "true"
                            }

                            primaryOrder.targets = order.entryPrices
                        } else {
                            if (order.isBuyNow) {
                                primaryOrder.orderType = "MARKET_SELL"
                                primaryOrder.stopPrice = ""
                            } else {
                                primaryOrder.stopPrice = order.entryPriceValue
                            }
                        }
                        primaryOrder.priceType = order.priceTypeValue
                        primaryOrder.breakevenType = order.breakevenTypeValue
                        // setting targets
                        order1.targets = order.targetPrices
                        order1.priceType = order.priceTypeValue
                        order1.breakevenType = order.breakevenTypeValue
                        // setting stoploss
                        order2.entryPrice = order.stopLossPriceValue
                        order2.stopPrice = order.stopLossPriceValue
                        order2.breakeven = order.enableBreakeven
                        order2.priceType = order.priceTypeValue
                        order2.breakevenType = order.breakevenTypeValue

                        secondaryOrder.order1 = order1
                        secondaryOrder.order2 = order2
                    }
                }
            }

            orderDetails.primaryOrder = primaryOrder
            orderDetails.secondaryOrder = secondaryOrder

            onSetNewState(this, {
                orderDetails
            })
            onShowTradeSummaryHandler(symbol, orderDetails)
        }
    }

    renderCoinPairInfo = () => {
        const { 
            symbol, 
            lastPrice, 
            askPrice, 
            bidPrice, 
            myEntryPrice,
            myTargetPrices,
            myStoplossPrice } = this.state

        let m2s = parseFloat(myEntryPrice) - parseFloat(myStoplossPrice)
            m2s /= parseFloat(myStoplossPrice)
            m2s *= 100

        let m2ts = []
        if (isNotNull(myTargetPrices)) {
            myTargetPrices.forEach((i) => {
                let m2t = parseFloat(i.stopPrice) - parseFloat(myEntryPrice)
                    m2t /= parseFloat(myEntryPrice)
                    m2t *= 100 
                    m2t = parseFloat(m2t).toFixed(1)

                let rr = parseFloat(m2t) / parseFloat(m2s)
                
                m2ts.push({
                    rr,
                    blended: rr * (parseInt(i.quantity) / 100)
                })
            })
        }

        let blendedRr = ''
        if (m2ts.length > 0) {
            blendedRr = _.sumBy(m2ts, e => e.blended)
        }

        return (
            <CoinPairingWrapper className="coinpair-container">
                <Form.Group>
                    <Form.Row className="coinpair-wrapper">
                        <Col sm={12}>
                            <Form.Row>
                                <TradeFormLabel className="col-sm-12 col-md-3 col-lg-3 pairing-label mb-3">Coin Pairing
                                    <div className="coinpair-title mobile">
                                        <span>{symbol}</span>
                                    </div>
                                </TradeFormLabel>
                                <div className='blended-rr-wrapper'>
                                    <div className='blended-rr-title'>
                                        <span>BLENDED R:R</span>
                                    </div>
                                    <div className='blended-rr-value'>
                                        <span>{isNotNull(blendedRr) && blendedRr.toFixed(2)}</span>
                                    </div>
                                </div>
                            </Form.Row>

                            <Form.Row>
                                <Col sm={12} md={12} lg={12}>
                                    <div className="pairing-prices-wrapper">
                                        <div className="coinpair-title desktop">
                                            <span>{symbol}</span>
                                        </div>

                                        <div className="pairing-prices-content">
                                            <div className="pairing-prices mb-2">
                                                <div className="coin-pairing bid">
                                                    <span className="coinpair-details"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={(e) => this.onSetBuyStopPriceHandler(e, bidPrice)}>
                                                        <span className="bidprice">BID</span>
                                                        <span className="tradeBidPrice">{isNotNull(bidPrice) ? bidPrice : 'loading...'}</span>
                                                    </span>
                                                </div>

                                                <div className="coin-pairing ask">
                                                    <span className="coinpair-details"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={(e) => this.onSetBuyStopPriceHandler(e, askPrice)}>
                                                        <span className="askprice">ASK</span>
                                                        <span className="tradeAskPrice">{isNotNull(askPrice) ? askPrice : 'loading...'}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="pairing-prices">
                                                <div className="coin-pairing price">
                                                    <span className="coinpair-details"
                                                        style={{ cursor: 'pointer' }}>
                                                        <span className="lastprice">LAST PRICE</span>
                                                        <span className="tradeLastPrice">{isNotNull(lastPrice) ? lastPrice : 'loading...'}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Form.Row>
                        </Col>
                    </Form.Row>
                </Form.Group>
            </CoinPairingWrapper>
        )
    }

    renderMobileWallets = (feed, wallet) => {
        let btcBalance = 0
        let usdtBalance = 0.00
        let busdBalance = 0.00

        if (isNotNull(wallet)) {
            const { btc, usdt, busd } = wallet
            if (isNotNull(usdt) && isNotNull(usdt.balance) && isNotNull(usdt.balance.free))
                usdtBalance = parseFloat(usdt.balance.free)

            if (isNotNull(btc) && isNotNull(btc.balance) && isNotNull(btc.balance.free))
                btcBalance = parseFloat(btc.balance.free) 

            if (isNotNull(busd) && isNotNull(busd.balance) && isNotNull(busd.balance.free))
                busdBalance = parseFloat(busd.balance.free)
        }

        return (
            <WalletBalanceWrapper className="wallet-balance-wrapper">
                <p className="order-wallet-title">WALLET</p>
                <BalanceWrapper>

                    <p className="coin-signs"><span className="wallet-value">USDT</span>
                        <span className="text-white">
                            {ScientificToDecimal(usdtBalance)}
                        </span>
                    </p>
                    {isNotNull(feed) && feed === "SPOT" &&
                        <p className="coin-signs btc"><span className="wallet-value">BTC</span>
                            <span className="text-white">
                                {ScientificToDecimal(btcBalance)}
                            </span>
                        </p>
                    }
                </BalanceWrapper>
            </WalletBalanceWrapper>
        )
    }

    render() {
        const { 
            isPremiumUser,
            isFree,
            feed, 
            direction, 
            btcUsdtLastPrice, 
            btcBusdLastPrice,
            symbol, 
            fromSym, 
            toSym, 
            lastPrice, 
            bidPrice, 
            askPrice, 
            signalInfo,
            tradeStrategyOptions, 
            priceTypeOptions, 
            breakEvenTypeOptions, 
            marginModeOptions, 
            enableAdvancedForm, 
            orderDetails, 
            myEntryPrice,
            myStoplossPrice,
            wallet } = this.state
        const { tradeStrategy } = orderDetails
        const { onboardingUser, signalDetails, eventHandlers, isSendingOrder } = this.props

        return (
            <>
                { !signalDetails.loading &&
                    <TradeFormContainer className="create-order-container" id="createOrder">
                        <TradeFormContent className="create-order-column" id="main-col">
                            <div>
                                <TradeFormHeader                                
                                    orderDetails={orderDetails}
                                    tradeStrategyOptions={tradeStrategyOptions}
                                    selectedTradeStrategy={tradeStrategy}
                                    onSelectTradeStrategyHandler={this.onSelectTradeStrategyHandler} />
                            </div>

                            {this.renderCoinPairInfo()}

                            <TradeDivider className="trade-divider" />

                            {!enableAdvancedForm &&
                                <BasicForm
                                    self={this}
                                    hasConnectedExchange={!(onboardingUser.status.state < onboardingState.ADJUST_TRADING_BALANCE)}
                                    tradeStrategy={tradeStrategy}
                                    priceTypeOptions={priceTypeOptions}
                                    breakEvenTypeOptions={breakEvenTypeOptions}
                                    marginModeOptions={marginModeOptions} 
                                    isPremiumUser={isPremiumUser} 
                                    isFree={isFree}
                                    feed={feed}
                                    direction={direction}
                                    symbol={symbol}
                                    fromSym={fromSym}
                                    toSym={toSym}
                                    btcUsdtLastPrice={btcUsdtLastPrice} 
                                    btcBusdLastPrice={btcBusdLastPrice} 
                                    lastPrice={lastPrice}
                                    bidPrice={bidPrice}
                                    askPrice={askPrice}
                                    signalInfo={signalInfo}
                                    orderDetails={orderDetails} 
                                    myEntryPrice={myEntryPrice} 
                                    myStoplossPrice={myStoplossPrice}
                                    wallet={wallet}
                                    isSendingOrder={isSendingOrder}
                                    eventHandlers={{
                                        onChangeQtyInUsdtHandler: this.onChangeQtyInUsdtHandler.bind(this),
                                        onChangeEntryPriceHandler: this.onChangeEntryPriceHandler.bind(this),
                                        onChangeMyEntryPriceHandler: this.onChangeMyEntryPriceHandler.bind(this),
                                        onChangeQtyInAltHandler: this.onChangeQtyInAltHandler.bind(this),
                                        onSetBuyStopPriceHandler: this.onSetBuyStopPriceHandler.bind(this),
                                        onChangeMyTargetPrices: this.onChangeMyTargetPrices.bind(this),
                                        onChangeMyStoplossPriceHandler: this.onChangeMyStoplossPriceHandler.bind(this),
                                        onSubmitTraderOrderHandler: this.onSubmitTraderOrderHandler.bind(this),
                                        onShowTradeSummaryHandler: this.onShowTradeSummaryHandler.bind(this)
                                    }} />
                            }
                        </TradeFormContent>

                        <TradeFormSignalDetails
                            hasConnectedExchange={!(onboardingUser.status.state < onboardingState.ADJUST_TRADING_BALANCE)}
                            feed={feed}
                            symbol={symbol}
                            fromSym={fromSym}
                            toSym={toSym}
                            signalInfo={signalInfo}
                            orderDetails={orderDetails}
                            enableAdvancedForm={enableAdvancedForm}
                            onCreateQuickTradeHandler={eventHandlers.onCreateQuickTradeHandler}
                            onChangeFormTradeTypeHandler={this.onChangeFormTradeTypeHandler} />

                        { isNotNull(fromSym) && fromSym !== 'BUSD' && this.renderMobileWallets(feed, wallet)}
                    </TradeFormContainer>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    const { signalDetails, cryptoWallet, futureWallet } = state
    return {
        signalDetails,
        cryptoWallet,
        futureWallet
    }
}

const connectedTradeForm = connect(mapStateToProps)(TradeForm)
export { connectedTradeForm as TradeForm }