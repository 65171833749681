import styled from 'styled-components'
import { mediaQueries } from '../../constants/mediaQueries'

export const MainSidebar = styled.aside`
    position: fixed;
    width: 278px;
    top: 56px;
    left: 0;
    bottom: 0;
    background-color: #0B1219;
    border-right: 1px solid #080e16;
    overflow: auto;
    padding: 0;
    z-index: 17;
    transform: translate3d(-278px, 0, 0);
    transition: transform 0.35s;

    .sidebar-menu-list {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-left: 12px;
        padding-top: 10px;
        padding-bottom: 15px;

        .sidebar-menu-item {
            .sidebar-menu-title {
                margin-bottom: 5px;

                .menu-title-text {
                    display: block;
                    color: #656C76;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-size: 12px;
                    font-weight: 500;
                }
            }

            &:first-child {
                margin-bottom: 0;
            }

            &:last-child {
                margin-top: auto;
            }
        }
    }

    .sidebar-submenu-list {
        margin: 0 0 15px;
        padding-left: 0;
    }

    .sidebar-menu-list,
    .sidebar-submenu-list {        
        margin: 0;
        list-style-type: none;
        font-size: 14px;

        .sidebar-menu-item {
            margin-bottom: 10px;
            padding: 10px 0 0;
        }

        .sidebar-menu-item,
        .sidebar-submenu-item {
            .menu-item-link,
            .sidebar-submenu-item-link,
            button.menu-btn {
                display: flex;
                padding: 10px 3px;
                text-decoration: none;

                .menu-item-link-icon,
                .submenu-item-link-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #495B70;
                    width: 44px;
                    transition: all ease .2s;
                    transition-property: color;
                    font-size: 13px;
                }

                .menu-item-link-text,
                .submenu-item-link-text {
                    display: flex;
                    text-align: left;
                    color: #879AAF;
                    font-size: 13px;
                    letter-spacing: 1px;
                    width: calc(100% - 44px);
                }

                &:hover {
                    .menu-item-link-icon,
                    .submenu-item-link-icon,
                    .menu-item-link-text,
                    .submenu-item-link-text {
                        color: #fff;
                    }
                }

                &.active {
                    .menu-item-link-icon,
                    .submenu-item-link-icon,
                    .menu-item-link-text,
                    .submenu-item-link-text {
                        color: #13B078;
                    }
                }
            }
        }

        .sidebar-submenu-item {
            .sidebar-submenu-item-link {
                display: flex;
                align-items: center;

                .submenu-item-link-icon {
                    font-size: 16px;
                    position: relative;

                    svg {
                        font-size: 16px;
                    }

                    .has-new {
                        display: block;
                        width: 8px;
                        height: 8px;
                        background-color: #fe0062;
                        border-radius: 50%;
                        right: 6px;
                        position: absolute;
                        top: -2px;
                    }
                }

                .submenu-item-link-text {
                    width: calc(100% - 44px);
                    display: flex;
                    align-items: center;

                    .pro-feature {
                        color: gold;
                        margin-left: 10px;
                        background-color: black;
                        padding: 1px 3px;
                        border-radius: 4px;
                        border: 1px solid gold;
                        font-size: 10px;
                    }
                }

                &:first-child {
                    .submenu-item-link-icon {
                        font-size: 18px;
                        height: 18px;
                    }
                }
            }

            button.menu-btn {
                display: flex;
                align-items: center;
                background: transparent;
                border: none;
                outline: none;
                width: 100%;
                text-align: left;
                color: #879AAF;

                .icon {
                    width: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #495B70;
                    text-align: center;
                    transition: all ease .2s;
                    transition-property: color;
                    font-size: 16px;
                    height: 16px;

                    svg {
                        font-size: 16px;
                    }
                }

                .text {                    
                    width: calc(100% - 44px);
                    display: flex;
                    align-items: center;

                    .pro-feature {
                        color: gold;
                        margin-left: 10px;
                        background-color: black;
                        padding: 1px 3px;
                        border-radius: 4px;
                        border: 1px solid gold;
                        font-size: 10px;
                        letter-spacing: 1px;
                    }
                }

                &:hover {
                    color: #fff;

                    .icon {
                        color: #fff;
                    }
                }
            }
        }
    }

    &.show {
        transform: translate3d(0,0,0);
    }

    &.has-notif-permission {
        top: 135px;
    }

    ${mediaQueries.mediumDevices} {
        &.has-notif-permission {
            top: 92px;
        }
    }

    ${mediaQueries.largeDevices} {
        width: 60px;
        transform: translate3d(0,0,0);

        .sidebar-menu-list {
            padding-left: 0;

            .sidebar-menu-item {
                .sidebar-menu-title {
                    .menu-title-text {
                        display: none;
                    }
                }

                .menu-item-link {
                    .menu-item-link-text {
                        display: none;
                    }
                }
            }
        }

        .sidebar-submenu-list {
            .sidebar-submenu-item {
                padding: 5px 0;

                .sidebar-submenu-item-link {
                    .submenu-item-link-text {
                        display: none;
                    }
                }

                button.menu-btn {
                    .text {
                        display: none;
                    }
                }
            }
        }

        &.show {
            width: 278px;

            .sidebar-menu-list {
                padding-left: 12px;

                .sidebar-menu-item {
                    .sidebar-menu-title {
                        .menu-title-text {
                            display: block;
                        }
                    }

                    .menu-item-link {
                        .menu-item-link-text {
                            display: flex;
                        }
                    }
                }
            }

            .sidebar-submenu-list {
                .sidebar-submenu-item {
                    .sidebar-submenu-item-link {
                        .submenu-item-link-text {
                            display: flex;
                        }
                    }

                    button.menu-btn {
                        .text {
                            display: flex;
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        width: 278px;
        
        .sidebar-menu-list {
            padding-left: 12px;
            padding-right: 12px;

            .sidebar-menu-item {
                .sidebar-menu-title {
                    .menu-title-text {
                        display: block;
                    }
                }

                .menu-item-link {
                    .menu-item-link-text {
                        display: flex;
                    }
                }
            }
        }

        .sidebar-submenu-list {
            .sidebar-submenu-item {
                padding: 0;

                .sidebar-submenu-item-link {
                    .submenu-item-link-text {
                        display: flex;
                    }
                }

                button.menu-btn {
                    .text {
                        display: flex;
                    }
                }
            }
        }
    }
`