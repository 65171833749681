import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const SaveMoreSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    padding: 50px 0px;
    background-color: #0B1219;
    background-image: url(/images/trial-offer/introsection-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;

    .container {
        text-align: center;

        .section-title {
            font-weight: 800;
            color: #FFFFFF;
            font-size: 22px;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

         p {
            font-family: 'Noto Sans';
            color: #FFFFFF;
            font-weight: 300;
            font-size: 15px;
         }

         .checkout-btn {
            .skip-trial-btn {
                background: none;
                border: none;
                outline: none;
                box-shadow: none;
                text-decoration: underline;
                color: #FFFFFF;
                font-size: 16px;
                font-weight: 400;
                padding: 0 0 8px;
            }
         }
    }

    ${mediaQueries.mediumDevices} {
        background-position: center;

        .container {
            max-width: 716px;

            .section-title {
                font-size: 30px;
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        padding: 60px 0;
        
        .container {
            max-width: 720px;

            .section-title {
                font-size: 45px;
            }
            
            p {
                font-size: 20px;
            }
        }
    }
`