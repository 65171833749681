import { useState }  from 'react'
import moment from 'moment'
import { isNotNull } from '../../../utils'
import { PackageBreakdownSectionStyle } from './styles'

export const PackageBreakdownSection = (props) => {    
    let [remainingDays, setRemainingDays] = useState('')
    let [remainingHours, setRemainingHours] = useState('')
    let [remainingMinutes, setRemainingMinutes] = useState('')
    let [remainingSeconds, setRemainingSeconds] = useState('')

    let [isEarlyBirdOffer, setIsEarlyBirdOffer] = useState(false)
    let [isFinalYearlyOffer, setIsFinalYearlyOffer] = useState(false)

    let parsedDate = Date.parse(props.validUntil)
    let expiry = new Date(parsedDate)
        expiry = moment(expiry).format('MM/DD/yyyy h:mm:ss')

    const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

    let today = new Date(),
        dd = String(today.getDate()).padStart(2, "0"),
        mm = String(today.getMonth() + 1).padStart(2, "0"),
        yyyy = today.getFullYear(),
        nextYear = yyyy + 1,
        dayMonth = moment(expiry).format('MM/DD/')

    today = mm + "/" + dd + "/" + yyyy
    if (today > expiry) {
        expiry = dayMonth + nextYear
    }

    const countDown = new Date(expiry).getTime(),
        x = setInterval(function() {    
            const now = new Date().getTime(),
                distance = countDown - now

            setRemainingDays(Math.floor(distance / (day)))
            setRemainingHours(Math.floor((distance % (day)) / (hour)))
            setRemainingMinutes(Math.floor((distance % (hour)) / (minute)))
            setRemainingSeconds(Math.floor((distance % (minute)) / second))

            if (isNotNull(remainingDays)) {
                if (parseInt(remainingDays) >= 14) {
                    if (!isEarlyBirdOffer)
                        setIsEarlyBirdOffer(true)

                    if (isFinalYearlyOffer)
                        setIsFinalYearlyOffer(false)
                } else {                        
                    if (isEarlyBirdOffer)
                        setIsEarlyBirdOffer(false)
                    
                    if (!isFinalYearlyOffer)  
                        setIsFinalYearlyOffer(true)
                }
            }

            if (distance < 0) {
                clearInterval(x)
            }
            //seconds
        }, 1000)

    return (
        <PackageBreakdownSectionStyle>
            <div className='container'>
                <div className='package-breakdown-wrapper'>                    
                    <ul className='package-breakdown'>
                        <li className='package-breakdown-item'>
                            <div className='thumbnail item-1'>
                                <img src='/images/layout/12-months-subscription-to-pf-pro.png' alt='12 Months Subscription to PF PRO' />
                            </div>
                            <div className='description'>
                                <h6><strong>12 Months Subscription to PF PRO</strong> <span className='em'> - Value $2999</span></h6>
                                <p>The ultimate crypto trading 'heads-up'.</p>
                                <p>Access to all of our most loved pro trade features and highly accurate signals. Our AI is ALWAYS watching the market, so you don't have to.</p>
                            </div>
                        </li>
                        <li className='package-breakdown-item'>
                            <div className='thumbnail item-2'>
                                <img src='/images/layout/elite-trading-strategies-pack.png' alt='Elite Trading Strategies Pack' />
                            </div>
                            <div className='description'>
                                <h6><strong>Elite Trading Strategies Pack</strong> <span className='em'> - Value $499</span></h6>
                                <p>Elevate your trading game.</p>
                                <p>These easy to understand and hyper effective trading strategies will make you a king of the charts without busting your brain.</p>
                            </div>
                        </li>
                        <li className='package-breakdown-item'>
                            <div className='thumbnail item-3'>
                                <img src='/images/layout/trade-doctor-sessions.png' alt='2 Trade Doctor Sessions' />
                            </div>
                            <div className='description'>
                                <h6><strong>2 Trade Doctor Sessions</strong> <span className='em'> - Value $499</span></h6>
                                <p>Direct help exactly when you need it.</p>
                                <p>Let our in-house trade doctor diagnose your platform usage problems and spare you from injury.</p>
                            </div>
                        </li>
                        <li className='package-breakdown-item'>
                            <div className='thumbnail item-4'>
                                <img src='/images/layout/crypto-trading-booster-pack.png' alt='Crypto Trading Booster Pack' />
                            </div>
                            <div className='description'>
                                <h6><strong>Crypto Trading Booster Pack</strong> <span className='em'> - Value $99</span></h6>
                                <p>Avoid mistakes and nail the basics.</p>
                                <p>44 pages of everything we wish we knew before we started PRO trading.</p>
                            </div>
                        </li>
                        <li className='package-breakdown-item'>
                            <div className='thumbnail item-5'>
                                <img src='/images/layout/7-steps-to-1000x-altcoins.png' alt='7 Steps to 1000x Altcoins' />
                            </div>
                            <div className='description'>
                                <h6><strong>7 Steps to 1000x Altcoins</strong> <span className='em'> - Value $99</span></h6>
                                <p>Generational wealth on tap.</p>
                                <p>Now you can source, vet and cash in from the regular Altcoin booms.</p>
                            </div>
                        </li>
                        <li className='package-breakdown-item'>
                            <div className='thumbnail item-6'>
                                <img src='/images/layout/dark-edition-spotify-playlist.png' alt='DarkEdition Spotify Playlist' />
                            </div>
                            <div className='description'>
                                <h6><strong>DarkEdition Spotify Playlist</strong> <span className='em'> - Value $49</span></h6>
                                <p>Adjust your frequencies.</p>
                                <p>Unleash your trading profitability with the power of sound. Techno for your soul.</p>
                            </div>
                        </li>
                        <li className='package-breakdown-item'>
                            <div className='thumbnail item-7'>
                                <img src='/images/layout/trader-education-cards.png' alt='Trader Education Cards' />
                            </div>
                            <div className='description'>
                                <h6><strong>Trader Education Cards</strong> <span className='em'> - Value $49</span></h6>
                                <p>Nourish your eyes.</p>
                                <p>More than 100 infographics with great trading tips, tricks, strategies and crypto education.</p>
                            </div>
                        </li>
                        <li className='package-breakdown-item'>
                            <div className='thumbnail item-8'>
                                <img src='/images/layout/first-in-line-access-to-market-intelligence.png' alt='First in line access to Market Intelligence' />
                            </div>
                            <div className='description'>
                                <h6><strong>First in line access to Market Intelligence</strong> <span className='em'> - Invaluable</span></h6>
                                <p>You benefit from knowing exactly what our trading wizards are thinking during all market cycles.</p>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className='price-tag-wrapper'>
                    <div className='img-wrapper'>
                        <img src='/images/layout/the-package.png' alt='Exclusive Price for PRO Trader' />
                    </div>
                    <div className='text-col'>
                        <div className='original-price'>
                            <span className='crashed-out-price'>$4,300</span>
                            <label>TOTAL VALUE</label>
                        </div>

                        { isEarlyBirdOffer &&
                            <>
                                <div className='new-price'>
                                    <span className='new-price-value'>$1,799</span>
                                    <span className='new-price-desc'>Exclusive price for Pro Trader<br />5x Blueprint graduates</span>
                                </div>

                                <h4>But wait, there's more!</h4>
                                <p>There's an additional -20% ($300) Early Bird discount for students that upgrade.</p>
                            </>
                        }

                        { isFinalYearlyOffer &&
                            <h4 style={{ marginTop: '10px' }}>Exclusive price for Pro Trader 5x Blueprint graduates</h4>
                        }

                        <div className='discounted-price'>
                            <span className='discounted-price-value'>
                                { isEarlyBirdOffer && <>$1,499</> }
                                { isFinalYearlyOffer && <>$1,799</> }
                            </span>
                            <span><img src='/images/layout/only-tag.svg' alt='' /></span>
                        </div>

                        <h6 className='impt'>When you secure your offer before times run out!</h6>
                    </div>
                </div>
            </div>
        </PackageBreakdownSectionStyle>
    )
}