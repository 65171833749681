import React from 'react'
import { TargetBar } from '../../styled_components/TargetBarWrapper'
import jQuery from 'jquery'
import { isNotNull, onSetNewState } from '../../../../utils'

export class Target extends React.Component {
    constructor(props) {
        super(props)
        
        this.state = {
            showLoader: false,
        }
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
    }

    componentDidUpdate() {
        if (this.mounted) {
            const { target, value, signalId, targetPrice, currentState } = this.props
            const { showLoader } = this.state
        
            jQuery(`#signal-${signalId}`).on('priceChanged', () => {
                let price = jQuery(`#signal-${signalId}`).text()
                
                if (!showLoader) {
                    if (parseFloat(price) >= parseFloat(targetPrice)) {
                        switch (currentState.item1) {
                            default:
                            case 's-asl':
                            case 's-wez':
                            case 's-lez': 
                            case 's-at1': {
                                if (target <= 1 && value < 100) {
                                    onSetNewState(this, {
                                        showLoader: true
                                    })
                                }
    
                                break
                            }
                            case 's-t1h': {
                                if (target > 1 && target < 3 && value < 100) {
                                    onSetNewState(this, {
                                        showLoader: true
                                    })
                                }
    
                                break
                            }
                            case 's-t2h': {
                                if (target > 2 && target < 4 && value < 100) {
                                    onSetNewState(this, {
                                        showLoader: true
                                    })
                                }
                                
                                break
                            }
                            case 's-t3h': {
                                if (target > 3 && value < 100) {
                                    onSetNewState(this, {
                                        showLoader: true
                                    })
                                }
                                
                                break
                            }
                        }
                    }
                } else {
                    switch (currentState.item1) {
                        default:
                        case 's-t1h': {
                            if (target < 2 && value > 0) {
                                onSetNewState(this, {
                                    showLoader: false
                                })
                            }
                            
                            break
                        }
                        case 's-t2h': {
                            if (target < 3 && value > 0) {
                                onSetNewState(this, {
                                    showLoader: false
                                })
                            }

                            break
                        }
                        case 's-t3h': {
                            if (target < 4 && value > 0) {
                                onSetNewState(this, {
                                    showLoader: false
                                })
                            }
                            
                            break
                        }
                    }
                }
            })
        }
    }

    renderLoader = () => {
        return (
            <>
                <div style={{ width: '100%', height: '26px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="pf-spinner super-xs"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            </>
        )
    }

    render() {
        const { value, target, targetPrice, tp, midEntry, stopLossPrice } = this.props
        const { showLoader } = this.state

        let rrStyle = ''
        let m2t = ''
        let m2s = ''
        let rr = ''

        if (isNotNull(tp) && 
            isNotNull(midEntry) && 
            isNotNull(stopLossPrice)) {
            m2t = parseFloat(tp) - parseFloat(midEntry)
            m2t /= midEntry
            m2t *= 100

            m2s = parseFloat(midEntry) - parseFloat(stopLossPrice)
            m2s /= parseFloat(stopLossPrice)
            m2s *= 100

            rr = parseFloat(m2t) / parseFloat(m2s)

            rr = parseFloat(rr).toFixed(2)
            
            switch(parseInt(target)) {
                default:
                case 1: {
                    if (rr >= 2)
                        rrStyle = 'good-rr'
                    else if (rr > 1.5 && rr < 2) 
                        rrStyle = 'fair-rr'
                    else
                        rrStyle = 'bad-rr'

                    break
                }
                case 2: {
                    if (rr >= 3)
                        rrStyle = 'good-rr'
                    else if (rr > 2 && rr < 3) 
                        rrStyle = 'fair-rr'
                    else
                        rrStyle = 'bad-rr'

                    break
                }
                case 3: {
                    if (rr >= 4)
                        rrStyle = 'good-rr'
                    else if (rr > 3 && rr < 4) 
                        rrStyle = 'fair-rr'
                    else
                        rrStyle = 'bad-rr'

                    break
                }
                case 4: {
                    if (rr >= 5)
                        rrStyle = 'good-rr'
                    else if (rr > 4 && rr < 5) 
                        rrStyle = 'fair-rr'
                    else
                        rrStyle = 'bad-rr'

                    break
                }
            }
        }

        return (
            <TargetBar className={(value === 100) ? 'fill' : ` ${rrStyle}`} fill={value}>
                { showLoader && this.renderLoader() }
                { !showLoader && `T${target}` }
            </TargetBar>
        )
    }
}