import styled from 'styled-components'

export const GorgeOnCryptoSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    padding: 60px 0;
    background-image: url(/images/trial-offer/introsection-bg.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;

    .container {
        text-align: center;
        color: #FFFFFF;

        .section-title {
            font-size: 40px;
            font-weight: 600;
            color: #FFFFFF;
            margin: 0 auto 25px;
            max-width: 810px;
            text-align: center;

            .crashed-out-price {
                color: #516475;
                position: relative;
                font-size: 36px;

                &:before {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 45%;
                    right: 0;
                    border-top: 3px solid;
                    border-color: #FF3030;
                    -webkit-transform: rotate(-18deg);
                    -moz-transform: rotate(-18deg);
                    -ms-transform: rotate(-18deg);
                    -o-transform: rotate(-18deg);
                    transform: rotate(-18deg);
                } 
            }

            .new-price {
                color: #E93459;
            }
        }

        h3 {
            font-size: 25px;
            margin-bottom: 0;
        }

        p {
            &.little-note {
                font-size: 16px;
            }
        }

        .offer-validity-timer {
            ul {
                margin: 30px 0 40px;
                padding: 0;
                list-style-type: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                li {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 0 15px;
                    color: #FFFFFF;
                    line-height: 1;

                    .counter {
                        font-size: 60px;
                        font-weight: 600;
                    }

                    small {
                        font-size: 12px;
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }
`