import React from 'react'
import { connect } from 'react-redux'
import { Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft, faShare } from '@fortawesome/free-solid-svg-icons'
import { SRLWrapper } from 'simple-react-lightbox'
import { TextButton, PrimaryButton } from '../../styles'
import { FormBodyContent, FormActions } from './'
import { isNotNull, onSetNewState } from '../../utils'
import { onboardingScreen } from '../../constants/onboardingScreen'
import { routes } from '../../navigation/CONSTANTS'
import { onboardingActions } from '../../redux/actions'

class ExchangeSecurityVerification extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isProceeding: false
        }
        this.baseState = this.state
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { configureExchangeIntegration, skipOnboarding, eventHandlers } = this.props
            const { isProceeding } = this.state

            if (prevProps.configureExchangeIntegration !== configureExchangeIntegration && 
                !configureExchangeIntegration.loading && 
                isProceeding) {
                    const { result } = configureExchangeIntegration
                    if (isNotNull(result) && result.success && isNotNull(eventHandlers)) {
                        eventHandlers.navigateTo(null, onboardingScreen.ENTER_EXCHANGE_KEYS)
                    }
                }

            if (isNotNull(skipOnboarding) && 
                prevProps.skipOnboarding !== skipOnboarding && 
                !skipOnboarding.loading) {
                    const { response } = skipOnboarding
                    if (isNotNull(response) && response.success) window.location.href = routes.dashboard
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    handleClickNext = (e) => {
        e.preventDefault()

        const { dispatch } = this.props
        dispatch(onboardingActions.configureExchangeKeys())
        onSetNewState(this, {
            isProceeding: true
        })
    }

    handleClickBack = (e) => {
        e.preventDefault()

        const { eventHandlers } = this.props
        if (isNotNull(eventHandlers)) {
            eventHandlers.navigateTo(null, onboardingScreen.GENERATE_EXCHANGE_API)
        }
    }

    showSkipWarning = (e) => {
        const { eventHandlers } = this.props
        eventHandlers.handleSkip(e)
    }

    render() {
        const lightboxOptions = {
            buttons: {
                showAutoplayButton: false,
                showThumbnailsButton: false
            }
        }

        return (
            <>
                <FormBodyContent className='api-connection'>
                    <div className='graphic with-preview notransform'>
                        <SRLWrapper options={lightboxOptions}>
                            <div><img src="/images/form-graphics/step-4.gif" alt="Complete API security checks" style={{ width: '100%' }} /></div>
                        </SRLWrapper>
                    </div>

                    <div className='form'>
                        <h2 className='form-title'>Connect PF to Binance.com</h2>
                        <h3 className='form-instruction'>Complete API security checks</h3>

                        <Form.Group className='detailed-instructions'>
                            <ol>
                                <li>Complete security verification process</li>
                                <li>Click 'Get Code' so that Binance send you a verification code</li>
                                <li>Enter your email verification code once it arrives</li>
                                <li>Enter your Authenticator code (if applicable)</li>
                            </ol>
                        </Form.Group>
                    </div>
                </FormBodyContent>

                <FormActions>
                    <TextButton 
                        textColor="#8A9BAE"
                        size="sm" 
                        className='default-btn back-btn'
                        onClick={(e) => this.handleClickBack(e)}>
                            <FontAwesomeIcon size="xs" className='icon' icon={faArrowLeft} /> 
                            <span>Back</span>
                    </TextButton>
                           
                    <TextButton 
                        textColor="#8A9BAE"
                        size="auto" 
                        className='default-btn skip-btn' 
                        onClick={(e) => this.showSkipWarning(e)}>
                            <FontAwesomeIcon size="xs" className='icon' icon={faShare} /> 
                            <span>Skip</span>
                    </TextButton>

                    <PrimaryButton 
                        type="submit" 
                        size="sm"
                        onClick={(e) => this.handleClickNext(e)}>
                            <FontAwesomeIcon size="xs" className='icon' icon={faArrowRight} /> 
                            <span>Next</span>
                    </PrimaryButton>
                </FormActions>
            </>
        )
    }
}

function mapStateToProps(state) {
    const { configureExchangeIntegration, skipOnboarding } = state
    return {
        configureExchangeIntegration,
        skipOnboarding
    }
}

const connectedExchangeSecurityVerification = connect(mapStateToProps)(ExchangeSecurityVerification)
export { connectedExchangeSecurityVerification as ExchangeSecurityVerification }