const subscriptionStatus = {
    ACTIVE: 0,
    CANCELLED: 1,
    EXPIRED: 3,
    REFUNDED: 4,
    PENDING_DEACTIVATION: 5,
    DEACTIVATED: 7
}

export {
    subscriptionStatus
}