import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const BreakevenSectionStyle = styled.section`
    position: relative;
    width: 100%;
    display: block;
    padding: 40px 5px;
    background-image: linear-gradient(180deg, #124857, #15324E);

    .container {
        .text-with-vid {
            display: flex;
            flex-direction: column;

            .text-col {
                img {
                    margin-bottom: 15px;
                    max-width: 226px;
                    width: 150px;
                }

                .section-title {
                    font-weight: 700;
                    color: #FFFFFF;
                    font-size: 20px;
                }

                p {
                    color: #FFFFFF;
                    font-family: 'Noto Sans';
                    font-weight: 300;
                }

                .benefits-list {
                    list-style-type: none;
                    padding-left: 50px;
                    font-family: 'Noto Sans';
                    color: #C1CBD8;
                    font-weight: 300;

                    .list-item {
                        margin-bottom: 20px;
                        position: relative;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &:before {
                            content: '';
                            position: absolute;
                            width: 32px;
                            height: 32px;
                            top: calc(50% - 16px);
                            left: -50px;
                            background-image: url(/images/trial-offer/check-icon.png);
                            background-repeat: no-repeat;
                            background-size: contain;;
                        }
                    }
                }
            }

            .video-wrapper {
                > div {
                    height: 220px !important;
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        .container {
            .text-with-vid {
                .text-col {
                    margin-bottom: 25px;

                    .benefits-list {
                        .list-item {
                            margin-bottom: 25px
                        }
                    }
                }
                .video-wrapper {
                    > div {
                        height: 360px !important;
                    }
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        padding: 60px 0;

        .container {
            .text-with-vid {
                flex-direction: row;
                align-items: center;

                .text-col {
                    width: 50%;
                    padding-right: 20px;

                    .section-title {
                        font-size: 35px;
                    }

                    .benefits-list {
                        .list-item {
                            margin-bottom: 30px;
                        }
                    }
                }

                .video-wrapper {
                    text-align: left;
                    width: 50%;

                    > div {
                        height: 270px !important;
                    }
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        .container {
            .text-with-vid {
                .text-col {
                    img {
                        width: 200px;
                    }

                    .benefits-list {
                        margin-top: 35px;

                        .list-item {
                            margin-bottom: 35px;
                        }
                    }
                }

                .video-wrapper {
                    > div {
                        height: 360px !important;
                    }
                }
            }
        }
    }

    ${mediaQueries.superExtraLargeDevices} {
        .container {
            .text-with-vid {
                .text-col {
                    .section-title {
                        font-size: 38px;
                    }
                }
            }
        }
    }
`