import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import * as signalR from '@microsoft/signalr'
import _ from 'lodash'
import { Button } from 'react-bootstrap'
import { announcementActions } from '../../redux/actions'
import { announcementActionTypes } from '../../redux/CONSTANTS'
import { apiAuthToken, baseUrl } from '../../services/CONSTANTS'
import {
    AnnouncementWrapper, AnnouncementContainer,
    AnnouncementHtmlBody, AnnouncementButtonWrapper,
    AnnouncementContentWrapper, AnnouncementTitle
} from './styled_components'
import { isNotNull } from '../../utils'

class Announcement extends React.PureComponent {
    constructor(props) {
        super(props)
        this._mounted = false
        this.announcementHubConnection = null
    }

    componentDidMount() {
        this._mounted = true
        // const { dispatch } = this.props
        // dispatch(announcementActions.getActiveAnnouncement())

        // await this.onInitAnnouncementConnection()
    }

    componentWillUnmount() {
        this.stopAnnouncementConnection()
        this._mounted = false
    }

    async onInitAnnouncementConnection() {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${baseUrl}/announcements`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
                accessTokenFactory: () => apiAuthToken
            })
            .build()

        this.announcementHubConnection = hubConnection

        this.announcementHubConnection.onclose(async () => {
            await this.startAnnouncementConnection()
        })

        this.announcementHubConnection.on("PublishAnnouncement", (payload) => {
            if (isNotNull(payload)) {
                const { announcements, dispatch } = this.props
                if (isNotNull(announcements) &&
                    isNotNull(announcements.active_announcement) &&
                    isNotNull(announcements.active_announcement.data)) {
                    let announcementsLists = [...announcements.active_announcement.data]
                    announcementsLists.push(payload)
                    let active_announcement = {
                        data: announcementsLists
                    }
                    dispatch({ type: announcementActionTypes.GET_ACTIVE_ANNOUNCEMENT_SUCCESS, active_announcement })
                } else {
                    let active_announcement = {
                        data: payload
                    }
                    dispatch({ type: announcementActionTypes.GET_ACTIVE_ANNOUNCEMENT_SUCCESS, active_announcement })
                }
            }
        })

        this.announcementHubConnection.on("CloseAnnouncement", (payload) => {
            if (isNotNull(payload)) {
                const { announcements, dispatch } = this.props
                if (isNotNull(announcements) &&
                    isNotNull(announcements.active_announcement) &&
                    isNotNull(announcements.active_announcement.data)) {
                    const announcementLists = _.remove(announcements.active_announcement.data, q => q.id === payload)
                    let active_announcement = {
                        data: announcementLists.length > 1 ? announcementLists : null
                    }
                    dispatch({ type: announcementActionTypes.GET_ACTIVE_ANNOUNCEMENT_SUCCESS, active_announcement })
                }
            }
        })

        await this.startAnnouncementConnection()
    }

    async startAnnouncementConnection() {
        if (this._mounted) {
            try {
                const { username } = this.props.user
                await this.announcementHubConnection.start()
                    .catch(function (err) {
                        // console.log(err)
                    })

                this.announcementHubConnection.invoke("GetConnectionId", username)
                    .then(function (connectionId) {
                        // console.log(connectionId)
                    })
                    .catch(function () { })
            } catch (err) {
                // close connection
                this.stopAnnouncementConnection()
                // restart connection
                setTimeout(async () => await this.startAnnouncementConnection(), 5000)
            }
        }
    }

    stopAnnouncementConnection = () => {
        if (this.announcementHubConnection !== null &&
            this.announcementHubConnection.hasOwnProperty('close')) {
            this.announcementHubConnection.stop()
            this.announcementHubConnection = null
        }
    }

    onCreateMarkupHandler = (htmlBody) => ({ __html: `${htmlBody}` })

    onActionUrlHandler = (actionUrl) => window.open(actionUrl, '_blank')

    render() {
        // const { announcements } = this.props

        // let activeAnnouncement = null
        // if (isNotNull(announcements)) {
        //     const { loading, active_announcement } = announcements
        //     if (!loading && isNotNull(active_announcement) && isNotNull(active_announcement.data)) {
        //         const data = active_announcement.data
        //         if (isNotNull(data)) {
        //             activeAnnouncement = (
        //                 <AnnouncementWrapper className="announcement">
        //                     <AnnouncementContainer>
        //                         <AnnouncementContentWrapper className={isNotNull(data.actionUrl) ? "" : "full"}>
        //                             <AnnouncementTitle>{data.title}</AnnouncementTitle>
        //                             <AnnouncementHtmlBody dangerouslySetInnerHTML={this.onCreateMarkupHandler(data.htmlBody)} />
        //                         </AnnouncementContentWrapper>
        //                         {
        //                             isNotNull(data.actionUrl) &&
        //                             <AnnouncementButtonWrapper>
        //                                 <Button className="btn-action" onClick={() => this.onActionUrlHandler(data.actionUrl)}>Learn More</Button>
        //                             </AnnouncementButtonWrapper>
        //                         }
        //                     </AnnouncementContainer>
        //                 </AnnouncementWrapper>
        //             )
        //         } else
        //             activeAnnouncement = null
        //     }
        // }
        // return (<>{activeAnnouncement}</>)

        return (
            <AnnouncementWrapper className="announcement">
                <AnnouncementContainer>
                    <AnnouncementContentWrapper className="">
                        <AnnouncementHtmlBody>
                            <p>ALERT: Black Friday 21 Day Pro Trial  + Pro Trader 5x Blueprint Training - Just $21- Capacity is Limited and Seats are Filling up Fast - Act Now!</p>
                        </AnnouncementHtmlBody>
                        {/* <AnnouncementTitle>{data.title}</AnnouncementTitle> */}
                        {/* <AnnouncementHtmlBody dangerouslySetInnerHTML={this.onCreateMarkupHandler(data.htmlBody)} /> */}
                    </AnnouncementContentWrapper>
                    <AnnouncementButtonWrapper>
                        <NavLink to="/subscription-plans/pro/subscribe" className='btn-action'>Learn More</NavLink>
                        {/* <Button className="btn-action" onClick={() => this.onActionUrlHandler(data.actionUrl)}>Learn More</Button> */}
                    </AnnouncementButtonWrapper>
                </AnnouncementContainer>
            </AnnouncementWrapper>
        )
    }
}

function mapStateToProps(state) {
    const { announcements } = state
    return {
        announcements
    }
}
const connectedAnnouncement = connect(mapStateToProps)(Announcement)
export { connectedAnnouncement as Announcement }