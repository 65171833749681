import { isNotNull } from '../../utils'
import { Form, Row, ProgressBar, OverlayTrigger, Tooltip, SafeAnchor } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import _ from 'lodash'
import { CheckListWrapper, CheckListTitle, CheckList, ProgressWrapper, CloseButton } from './styled_components'

export const ChecklistsView = (props) => {
    const { loading } = props.cryptoWallet
    const { item } = props.checkList
    const { progress, progressChecklist, progressChecklistTitles } = props.checkListItems
    const { ids } = props.state
    let hasSetTooltip = false
    let tooltipIndex = 0

    _.forEach(progressChecklist, (item, key) => {
        if (item === 0 && !hasSetTooltip) {
            tooltipIndex = key
            hasSetTooltip = true
        }
    })

    const onViewVideoHandler = (url) => {
        var width = window.innerWidth;
        var height = window.innerHeight;

        var left = (width * 0.5);
        var top = (height * 0.5);
        left = left - ((width / 1.5) * 0.5);
        top = top - ((height / 1.5) * 0.5);

        let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
    	width=${width / 1.5},height=${height / 1.5},left=${left},top=${top}`

        window.open(url, 'Window', params)
    }

    const renderChecklistsProgressBar = () => {
        return (
            <ProgressWrapper>
                <ProgressBar animated now={progress} label={`${progress}%`} />
                <span>Progress</span>
            </ProgressWrapper>
        )
    }

    const renderTooltipTitle = (key) => {
        switch (key) {
            case 1:
                return "Connect your Binance account with ProfitFarmers (PF)"
            case 2:
                return "Add Bitcoin to your Binance account"
            case 3:
                return "Make sure you have USDT"
            case 4:
                return "Pick, Click and Grow!"
            default:
                return "Open an account with Binance"
        }
    }

    const renderTooltipParagraph = (key) => {
        switch (key) {
            case 1:
                return "Now you need to connect your Binance account with PF"
            case 2:
                return "Fund your account with Bitcoin either by buying more or transferring some from another wallet"
            case 3:
                return "Some of our signals are traded with Bitcoin (BTC), others with USDT (Digital US dollars). We suggest starting with 50/50 split"
            case 4:
                return "It's time to make money!"
            default:
                return (<>Before you can start making money, you need to open a Binance account. <br />(If you don't already have one)</>)
        }
    }

    const renderTooltipStepsTitle = (key) => {
        switch (key) {
            case 1:
                return "Steps - 3 mins:"
            case 2:
                return "Steps - 5 mins:"
            case 3:
            case 4:
                return "Steps:"
            default:
                return "Steps - 5 mins:"
        }
    }

    const renderTooltipStepsInner = (key) => {
        switch (key) {
            case 1:
                return (
                    <>
                        <ol style={{ marginTop: '8px', marginLeft: '6px', marginBottom: '15px' }}>
                            <li>Watch video below (1.42 mins)</li>
                            <li><SafeAnchor href="https://www.binance.com/en/usercenter/settings/api-management" target="_blank" rel="noreferrer">Go to Binance dashboard and create API key</SafeAnchor></li>
                            <li><SafeAnchor href="/account/auto-trade" target="_blank" rel="noreferrer">Click here to go to your settings page</SafeAnchor></li>
                            <li>Follow instructions on settings page</li>
                        </ol>

                        <p style={{ color: '#b6bec7' }}>Want to trade our FUTURES signals? Here's a <SafeAnchor href="https://profitfarmers.freshdesk.com/support/solutions/articles/47001183203-how-to-create-your-binance-futures-api-and-connect-it-to-profitfarmers" target="_blank" rel="noreferrer">guide</SafeAnchor> on how you can set it up.</p>
                    </>
                )
            case 2:
                return (
                    <ol>
                        <li type="A"><SafeAnchor href="https://profitfarmers.freshdesk.com/support/solutions/articles/47000849243-how-to-buy-bitcoin-through-binance-or-coinbase" target="_blank" rel="noreferrer">Buy Bitcoin (Watch video below to see the best method to buy Bitcoin)</SafeAnchor></li>
                        <span> or </span>
                        <li type="A"><SafeAnchor href="https://profitfarmers.freshdesk.com/support/solutions/articles/47001131496-how-to-send-bitcoin-and-other-crypto-to-your-binance-account" target="_blank" rel="noreferrer">Transfer Bitcoin</SafeAnchor></li>
                    </ol>
                )
            case 3:
                return (
                    <>
                        <ol>
                            <li>Watch video below (1.34 mins)</li>
                            <li>Click on the button below to convert your BTC to UDST</li>
                        </ol>
                        <button className="btn btn-primary quickTrade mt-1" onClick={() => props.onCreateQuickTradeHandler()} disabled={loading ? true : false}>Convert</button>
                    </>
                )
            case 4:
                return (
                    <ol>
                        <li>Watch the video below</li>
                        <li>Pick your first signal</li>
                    </ol>
                )
            default:
                return (
                    <ol>
                        <li>
                            <SafeAnchor href="https://www.binance.com/en/register?ref=42030218" target="_blank" rel="noreferrer">Click here to create a free Binance account</SafeAnchor> <br />
                            <span style={{ color: 'red', fontWeight: '500' }}>(DO NOT buy crypto before you have watched Step 3)</span>
                        </li>
                        <li>
                            <label title="" htmlFor={`custom-inline-checkbox-${key}`} className="custom-control-label active">When your account is set up tick this box</label>
                            <input
                                type="checkbox"
                                id={`custom-inline-checkbox-${key}`}
                                checked={progressChecklist[key] === 1 ? true : false}
                                className="custom-control-input custom1"
                                disabled={progressChecklist[key] === 1 || key !== tooltipIndex}
                                onChange={() => { props.changeHandler(ids[0], true) }} />
                        </li>
                    </ol>
                )
        }
    }

    const renderVideoTutorial = (key) => {
        switch (key) {
            case 1:
                return (
                    <>
                        <iframe title={renderTooltipTitle(key)} src="https://www.youtube.com/embed/NhCycW1yEKg" frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <SafeAnchor onClick={() => onViewVideoHandler('https://www.youtube.com/embed/NhCycW1yEKg')}>Open video in a new window</SafeAnchor>
                    </>
                )
            case 2:
                return (
                    <>
                        <iframe title={renderTooltipTitle(key)} src="https://www.youtube.com/embed/ZiwDzw5IrCI" frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <SafeAnchor onClick={() => onViewVideoHandler('https://www.youtube.com/embed/ZiwDzw5IrCI')}>Open video in a new window</SafeAnchor>
                    </>
                )
            case 3:
                return (
                    <>
                        <iframe title={renderTooltipTitle(key)} src="https://www.youtube.com/embed/6hjAFJTqTrI" frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <SafeAnchor onClick={() => onViewVideoHandler('https://www.youtube.com/embed/6hjAFJTqTrI')}>Open video in a new window</SafeAnchor>
                    </>
                )
            case 4:
                return (
                    <>
                        <iframe title={renderTooltipTitle(key)} src="https://www.youtube.com/embed/VnVtHt4YOsI" frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <SafeAnchor onClick={() => onViewVideoHandler('https://www.youtube.com/embed/VnVtHt4YOsI')}>Open video in a new window</SafeAnchor>
                    </>
                )
            default:
                return (
                    <>
                        <iframe title={renderTooltipTitle(key)} src="https://www.youtube.com/embed/vUzeI-F4AJk" frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <SafeAnchor onClick={() => onViewVideoHandler('https://www.youtube.com/embed/vUzeI-F4AJk')}>Open video in a new window</SafeAnchor>
                    </>
                )
        }
    }

    const renderTooltipMessages = (key) => (
        <div className="tooltip-wrapper">
            <h2 className="tooltip-title">{renderTooltipTitle(key)}</h2>
            <p>{renderTooltipParagraph(key)}</p>

            <div className="steps-wrapper">
                <h4>{renderTooltipStepsTitle(key)}</h4>

                <div className="steps-inner">
                    {renderTooltipStepsInner(key)}
                </div>

                <hr />

                <div className="relative-wrapper">
                    <h4>How to?</h4>
                    {renderVideoTutorial(key)}
                </div>
            </div>
        </div>
    )

    const renderChecklistsItems = () => {
        if (isNotNull(progressChecklistTitles)) {
            return progressChecklistTitles.map((value, key) => (
                <div className="custom-control custom-checkbox" key={key}>
                    {(key === 0 || key === 2) &&
                        <input
                            type="checkbox"
                            id={`custom-inline-checkbox-${key}`}
                            checked={progressChecklist[key] === 1 ? true : false}
                            className="custom-control-input"
                            disabled={progressChecklist[key] === 1 || key !== tooltipIndex}
                            onChange={() => props.changeHandler(ids[key], true)} />
                    }

                    {(key === 1 || key === 3 || key === 4) &&
                        <input
                            type="checkbox"
                            disabled={true}
                            id={`custom-inline-checkbox-${key}`}
                            checked={progressChecklist[key] === 1 ? true : false}
                            className="custom-control-input" onChange={null} />
                    }

                    {key === tooltipIndex &&
                        <>
                            <label
                                title=""
                                htmlFor={`custom-inline-checkbox-${key}`}
                                id={`custom-inline-checkbox-${key}`}
                                className="custom-control-label active">
                                {value}
                            </label>

                            <OverlayTrigger
                                placement={'right'}
                                trigger={'focus'}
                                defaultShow={true}
                                overlay={
                                    <Tooltip className="message-tooltip" id={`step-${key + 1}`}>
                                        <CloseButton className="message-close" onClick={(e) => props.messageHandler(e, false)}>
                                            <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                        </CloseButton>

                                        {renderTooltipMessages(key)}
                                    </Tooltip>
                                }
                            >
                                <span className="hover-icon" onClick={(e) => props.messageHandler(e, true)}>
                                    <i className="fas fa-question-circle"></i>
                                </span>
                            </OverlayTrigger>
                        </>
                    }
                    
                    {key !== tooltipIndex &&
                        <label
                            title=""
                            htmlFor={`custom-inline-checkbox-${key}`}
                            className={`custom-control-label ${progressChecklist[key] === 1 ? 'checked' : ''}`}>
                            {value}
                        </label>
                    }
                </div>
            ))
        }
    }

    const renderCheckLists = () => {
        if (isNotNull(item) && isNotNull(item.data) && isNotNull(item.data.isStepsCompleted) && !item.data.isStepsCompleted) {
            if (isNotNull(item.data.userRequirements) && item.data.userRequirements.length > 0) {
                return (
                    <CheckListWrapper className="to-hide-items">
                        <div>
                            <CheckListTitle>Get Started</CheckListTitle>

                            <CheckList>
                                <Form>
                                    <Form.Group as={Row} controlId="formHorizontalCheck">
                                        {renderChecklistsItems()}
                                    </Form.Group>
                                </Form>

                                {renderChecklistsProgressBar()}
                            </CheckList>
                        </div>
                    </CheckListWrapper>
                )
            }
        } else
            return null
    }

    return (
        <div id="check-display">
            {renderCheckLists()}
        </div>
    )
}