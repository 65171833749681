import { themeActionTypes } from '../CONSTANTS'
import { themeServices } from '../../services'

function getTheme() {
    return dispatch => {
        dispatch(request())

        themeServices.getTheme()
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failure(error)))
    }

    function request() { return { type: themeActionTypes.GET_THEME_REQUEST } }
    function success(theme) { return { type: themeActionTypes.GET_THEME_SUCCESS, theme } }
    function failure(isError) { return { type: themeActionTypes.GET_THEME_FAILURE, isError } }
}

function saveTheme(isDarkMode) {
    return dispatch => {
        dispatch(request())

        themeServices.saveUserTheme(isDarkMode)
        
        let passedTheme = {
            isDarkMode
        }
        dispatch(success(passedTheme))
    }

    function request() { return { type: themeActionTypes.SAVE_THEME_REQUEST } }
    function success(theme) { return { type: themeActionTypes.GET_THEME_SUCCESS, theme } }
    // function failure(error) { return { type: themeActionTypes.SAVE_THEME_FAILURE, error } }
}

function setThemeSettings(isDarkMode) {
    return dispatch => {
        dispatch({
            type: themeActionTypes.SET_THEME_SETTINGS,
            isDarkMode
        })
    }
}

export const themeActions = {
    getTheme,
    setThemeSettings,
    saveTheme
}
