import { orderActionTypes } from '../CONSTANTS'
import { orderServices } from '../../services'

function getFuturesOrderHistory() {
    return dispatch => {
        dispatch(request())

        orderServices.getFuturesOrderHistory()
            .then(response => dispatch(success(response)))
            .catch(err => dispatch(failure(err)))
    }

    function request() { return { type: orderActionTypes.FUTURES_ORDER_HISTORY_REQUEST } }
    function success(order_history) { return { type: orderActionTypes.FUTURES_ORDER_HISTORY_SUCCESS, order_history } }
    function failure(error) { return { type: orderActionTypes.FUTURES_ORDER_HISTORY_FAILURE, error } }
}

function closePositions(positionObject) {    
    return dispatch => {
        dispatch(request())

        orderServices.closePositions(positionObject)
                .then(response => dispatch(success(response)))
                .catch(err => dispatch(failure(err)))
    }

    function request() { return { type: orderActionTypes.CLOSE_ORDER_POSITION_REQUEST } }
    function success(positionsClosed) { return { type: orderActionTypes.CLOSE_ORDER_POSITION_SUCCESS, positionsClosed } }
    function failure(error) { return { type: orderActionTypes.CLOSE_ORDER_POSITION_FAILRUE, error } }
}

function getFuturesOpenOrder() {
    return dispatch => {
        dispatch(request())

        orderServices.getFuturesOpenOrder()
            .then(response => dispatch(success(response)))
            .catch(err => dispatch(failure(err)))
    }

    function request() { return { type: orderActionTypes.FUTURES_OPEN_ORDER_REQUEST } }
    function success(open_order) { return { type: orderActionTypes.FUTURES_OPEN_ORDER_SUCCESS, open_order } }
    function failure(error) { return { type: orderActionTypes.FUTURES_OPEN_ORDER_FAILURE, error } }
}

function getOrderPositions() {
    return dispatch => {
        dispatch(request())

        orderServices.getOrderPositions()
            .then(response => dispatch(success(response)))
            .catch(err => dispatch(failure(err)))
    }

    function request() { return { type: orderActionTypes.ORDER_POSITION_REQUEST } }
    function success(orderPositions) { return { type: orderActionTypes.ORDER_POSITION_SUCCESS, orderPositions } }
    function failure(error) { return { type: orderActionTypes.ORDER_POSITION_FAILRUE, error } }
}

function getOrderHistory() {
    return dispatch => {
        dispatch(request())

        orderServices.getOrderHistory()
            .then(response => dispatch(success(response)),
                error => dispatch(failure(error)))
    }

    function request() { return { type: orderActionTypes.ORDER_HISTORY_REQUEST } }
    function success(order_history) { return { type: orderActionTypes.ORDER_HISTORY_SUCCESS, order_history } }
    function failure(error) { return { type: orderActionTypes.ORDER_HISTORY_FAILURE, error } }
}

function getOpenOrders() {
    return dispatch => {
        dispatch(request())

        orderServices.getOpenOrders()
            .then(response => dispatch(success(response)),
                error => dispatch(failure(error)))
    }

    function request() { return { type: orderActionTypes.OPEN_ORDER_REQUEST } }
    function success(open_order) { return { type: orderActionTypes.OPEN_ORDER_SUCCESS, open_order } }
    function failure(error) { return { type: orderActionTypes.OPEN_ORDER_FAILURE, error } }
}

function getOrderDetails(signalId) {
    return dispatch => {
        dispatch(request())

        orderServices.getOrderDetails(signalId)
            .then(response => {
                if (response.success) {
                    dispatch(success(response.orders))
                }
            }, error => dispatch(failed(error)))
    }

    function request() { return { type: orderActionTypes.ORDER_DETAILS_REQUEST }}
    function success(response) { return { type: orderActionTypes.ORDER_DETAILS_SUCCESS, response }}
    function failed(error) { return { type: orderActionTypes.ORDER_DETAILS_FAILED, error }}
}

function cancelOrder(openOrderId, feed) {
    return dispatch => {
        dispatch(request())

        orderServices.cancelOrder(openOrderId, feed)
            .then(response => dispatch(success(response)),
                error => dispatch(failure(error)))

        function request() { return { type: orderActionTypes.CANCEL_REQUEST } }
        function success(isCancel) { return { type: orderActionTypes.CANCEL_SUCCESS, isCancel } }
        function failure(error) { return { type: orderActionTypes.CANCEL_FAILURE, error } }
    }
}

function exitOrder(orderObject) {
    return dispatch => {
        dispatch(request())

        orderServices.exitOrder(orderObject).then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failure(error))
        })

        function request() { return { type: orderActionTypes.EXIT_ORDER_REQUEST } }
        function success(orderExit) { return { type: orderActionTypes.EXIT_ORDER_SUCCESS, orderExit } }
        function failure(error) { return { type: orderActionTypes.EXIT_ORDER_FAILRUE, error } }
    }
}

function forceExitOrder(data) {
    return dispatch => {
        dispatch(request())

        orderServices.forceExitOrder(data).then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failed(error))
        })
    }

    function request() { return { type: orderActionTypes.FORCE_EXIT_ORDER_REQUEST }}
    function success(response) { return { type: orderActionTypes.FORCE_EXIT_ORDER_SUCCESS, response }}
    function failed(error) { return { type: orderActionTypes.FORCE_EXIT_ORDER_FAILED, error }}
}

function forceCancelOrder(data) {
    return dispatch => {
        dispatch(request())

        orderServices.forceCancelOrder(data).then(response => {
            dispatch(success(response))
        }, error => {
            dispatch(failed(error))
        })
    }

    function request() { return { type: orderActionTypes.FORCE_CANCEL_ORDER_REQUEST }}
    function success(response) { return { type: orderActionTypes.FORCE_CANCEL_ORDER_SUCCESS, response}}
    function failed(error) { return { type: orderActionTypes.FORCE_CANCEL_ORDER_FAILED, error}}
}

function getOrderResult(orderId) {
    return dispatch => {
        dispatch(request())

        orderServices.getOrderResult(orderId)
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                }
            }, error => dispatch(failed(error)))
    }

    function request() { return { type: orderActionTypes.ORDER_RESULT_REQUEST }}
    function success(response) { return { type: orderActionTypes.ORDER_RESULT_SUCCESS, response }}
    function failed(error) { return { type: orderActionTypes.ORDER_RESULT_FAILED, error }}
}

export const orderActions = {
    getOrderHistory,
    getOpenOrders,
    getOrderDetails,
    getOrderResult,
    cancelOrder,
    exitOrder,
    forceExitOrder,
    forceCancelOrder,
    getFuturesOrderHistory,
    getFuturesOpenOrder,
    getOrderPositions,
    closePositions,
}