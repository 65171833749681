import React from 'react'
import moment from 'moment'
import { isNotNull } from '../../../../utils'

export class SignalProgress extends React.Component {
    renderProgress(state, data) {
        if (isNotNull(state)) {
            switch(state) {
                case "s-asl":
                case "s-wez":
                case "s-lez":
                case "s-at1": {
                    let { potentialGains } = data
                    return (                        
                        <h3 className="progress-value positive">
                            <span>{ potentialGains !== '????%' ? `+${potentialGains}` : `${potentialGains} `}</span>
                            <small className="duration">potential gains</small>
                        </h3>
                    )
                }
                // case "s-at1": {
                //     let { progress } = data
                    
                //     if (isNotNull(progress)) {
                //         return (
                //             <h3 className={`progress-value positive`}>
                //                 <span>{progress.item1 === 'loss' ? `--` : `+${progress.item3}`}</span>
                //                 {/* <span>{progress.item1 === 'loss' ? progress.item3 : `+${progress.item3}`}</span> */}
                //                 <small className="duration">since entry</small>
                //             </h3>
                //         )
                //     } else {
                //         return (
                //             <h3 className={`progress-value positive`}>
                //                 <span>...</span>
                //                 <small className="duration">calculating</small>
                //             </h3>
                //         )
                //     }
                // }
                default: {
                    let { datePublished, progress } = data
                    if (isNotNull(progress) && isNotNull(progress.item4)) {
                        let from = moment.utc(moment(new Date(datePublished)).format('YYYY-MM-DD HH:mm:ss'))
                        let to = moment.utc(moment(new Date(progress.item4)).format('YYYY-MM-DD HH:mm:ss'))
                        let durationUntilTargetHit = moment.duration(moment(to).diff(from)).humanize()
                        
                        return (
                            <h3 className="progress-value positive">
                                <span>{`+${progress.item2}%`}</span>
                                <small className="duration">In {durationUntilTargetHit}</small>
                            </h3>
                        )
                    }

                    return (<></>)
                }
            }
        }
    }

    render() {
        let { state, data } = this.props

        return (
            <>
                { this.renderProgress(state, data) }
            </>
        )
    }
}