import React from 'react'
import { connect } from 'react-redux'
import { SafeAnchor } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGem } from '@fortawesome/free-solid-svg-icons'
import { subscriptionActions } from '../../redux/actions'
import { subscriptionNames } from '../../constants/subscriptionNamesConstants'
import { subscriptionStatus } from '../../constants/subscriptionStatus'
import { isNull, isNotNull, onSetNewState } from '../../utils'

class SubscriptionPlan extends React.Component {
    constructor(props) {
        super(props) 

        this.state = {
            isLoading: true,
            isWithinTrialPeriod: null,
            hasSubscription: false,
            plan: null,
            nextBilling: null,
            status: null
        }
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        const { ...state } = this.state
        const { dispatch, subscriptionDetails } = this.props

        if (isNull(subscriptionDetails.loading))
            dispatch(subscriptionActions.getSubscriptionDetails())

        if (state.isLoading && isNotNull(subscriptionDetails.loading)) {
            const { subscription } = subscriptionDetails
            if (isNotNull(subscription)) {                 
                onSetNewState(this, {
                    isLoading: false,
                    hasSubscription: true,
                    plan: subscription.subscriptionName,
                    nextBilling: subscription.nextBillingDate,
                    status: subscription.paymentStatus
                })
            } else {
                onSetNewState(this, {
                    isLoading: false,
                    hasSubscription: false
                })
            }
        }
    }
    
    componentDidUpdate() {
        if (this.mounted) {
            const { isLoading, isWithinTrialPeriod, plan } = this.state
            const { authentication, subscriptionDetails } = this.props

            if (isNull(isWithinTrialPeriod) && 
                isNotNull(authentication.user)) {
                    const { ...data } = authentication.user
                    onSetNewState(this, {
                        isWithinTrialPeriod: data.isWithinTrialPeriod
                    })
                }

            if (isNull(plan) && 
                isNotNull(subscriptionDetails) && 
                !subscriptionDetails.loading && 
                isLoading) {
                    const { subscription } = subscriptionDetails
                    if (isNotNull(subscription)) {                        
                        onSetNewState(this, {
                            isLoading: false,
                            hasSubscription: true,
                            plan: subscription.subscriptionName,
                            nextBilling: subscription.nextBillingDate,
                            status: subscription.paymentStatus
                        })
                    } else {
                        onSetNewState(this, {
                            isLoading: false,
                            hasSubscription: false
                        })
                    }
            }
        }
    }

    renderLoader = () => (
        <>
            <div style={{ width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0 }}>
                <div className="pf-spinner xs" style={{ marginTop: '-48px', marginRight: '45px' }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        </> 
    )

    render() {
        const { 
            isLoading, 
            isWithinTrialPeriod, 
            hasSubscription, 
            plan, 
            nextBilling, 
            status } = this.state

        return (
            <div className='my-subscription'>
                { isLoading && this.renderLoader() }

                { !isLoading && 
                    <>
                        <div>
                            <label>Subscription Plan</label>
                            { hasSubscription && isNotNull(status) && 
                                <>
                                    { (status === subscriptionStatus.ACTIVE || status === subscriptionStatus.CANCELLED || status === subscriptionStatus.PENDING_DEACTIVATION) && 
                                        <>
                                            { isNull(isWithinTrialPeriod) && 
                                                <p><FontAwesomeIcon icon={faGem} className='plan-icon' /> { isNotNull(plan) && plan.toUpperCase()}</p>    
                                            }

                                            { isNotNull(isWithinTrialPeriod) && isWithinTrialPeriod && 
                                                <p><FontAwesomeIcon icon={faGem} className='plan-icon' /> TRIAL PLAN</p>    
                                            }
                                        </>                                        
                                    }

                                    { (status === subscriptionStatus.DEACTIVATED || status === subscriptionStatus.EXPIRED) && 
                                        <p>FREE ACCESS</p>
                                    }
                                </>
                            }
                            
                            { !hasSubscription && 
                                <p>FREE ACCESS</p>
                            }
                        </div>

                        { hasSubscription && isNotNull(plan) &&
                            <>
                                { status === subscriptionStatus.ACTIVE && 
                                    <span className='billing-date' style={{ marginTop: '5px' }}>{ plan.toUpperCase() !== subscriptionNames.LIFETIME ? `Next billing date: ${nextBilling}` : 'Lifetime Access' }</span>
                                }

                                { (status === subscriptionStatus.CANCELLED || status === subscriptionStatus.PENDING_DEACTIVATION) && 
                                    <span className='billing-date' style={{ marginTop: '5px' }}>{ `Valid until ${nextBilling}`}</span>
                                }

                                { status === subscriptionStatus.EXPIRED && 
                                    <span className='billing-date' style={{ marginTop: '5px' }}>Your PRO access has expired</span>
                                }
                            </>
                        }

                        {/* { !hasSubscription &&                         
                            <SafeAnchor href='/subscription-plans/pro/subscribe'>
                                <FontAwesomeIcon icon={faGem} /> Upgrade to PRO
                            </SafeAnchor>
                        } */}
                    </>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { authentication, subscriptionDetails } = state
    return {
        authentication,
        subscriptionDetails
    }
}

const connectedSubscriptionPlan = connect(mapStateToProps)(SubscriptionPlan)
export { connectedSubscriptionPlan as SubscriptionPlan }