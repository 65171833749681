import React from 'react'
import { connect } from 'react-redux'
import { Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft, faShare } from '@fortawesome/free-solid-svg-icons'
import { Form as FinalForm, Field } from 'react-final-form'
import newFormDecorator from 'final-form-focus'
import { SRLWrapper } from 'simple-react-lightbox'
import { TextButton, PrimaryButton } from '../../styles'
import { FormBodyContent, FormInfoText, FormActions } from './'
import { isNull, isNotNull, onSetNewState } from '../../utils'
import { onboardingScreen } from '../../constants/onboardingScreen'
import { customErrorCodes } from '../../constants/customErrorCodes'
import { onboardingActions } from '../../redux/actions'
import { routes } from '../../navigation/CONSTANTS'

class ExchangeKeysForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            apiKey: '',
            secretKey: '',
            isSubmitting: false,
            hasCustomError: false,
            customErrorMsg: ''
        }
        this.baseState = this.state
        this.mounted = false
        this.formDecorator = newFormDecorator()
    }

    componentDidMount() {
        this.mounted = true
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { saveExchangeKeys, skipOnboarding, eventHandlers } = this.props
            const { isSubmitting, apiKey, secretKey } = this.state

            if (prevProps.saveExchangeKeys !== saveExchangeKeys && 
                !saveExchangeKeys.loading && 
                isSubmitting) {
                    const { result } = saveExchangeKeys
                    if (isNotNull(result)) {
                        const { success } = result
                        if (success) {
                            let data = {
                                apiKey,
                                secretKey
                            }
                            eventHandlers.navigateTo(data, onboardingScreen.API_RESTRICTION_SETTINGS)
                        } else {
                            const { msg } = result
                            if (isNotNull(msg) && msg === customErrorCodes.INVALID_EXCHANGE_KEYS) {
                                onSetNewState(this, {
                                    hasCustomError: true,
                                    customErrorMsg: 'Invalid key'
                                })
                            }
                        }
                    }
                    onSetNewState(this, {
                        isSubmitting: false
                    })
                }

            if (isNotNull(skipOnboarding) && prevProps.skipOnboarding !== skipOnboarding && !skipOnboarding.loading) {
                const { response } = skipOnboarding
                if (isNotNull(response) && response.success) window.location.href = routes.dashboard
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    onSubmit = (values) => {
        const { dispatch } = this.props
        const { apiKey, secretKey, isSubmitting } = this.state
        if (!isSubmitting) {
            let data = {
                apiKey,
                secretKey
            }
            dispatch(onboardingActions.saveExchangeKeys(data))
            onSetNewState(this, {
                isSubmitting: true,
                secretKey: "YOUCANTSEEME"
            })
        }
    }

    onValidate = (values) => {
        const errors = {}

        // validate api key
        if (isNull(values.apiKey))
            errors.apiKey = "API Key is required"
        else {
            onSetNewState(this, {
                apiKey: values.apiKey
            })
        }

        // validate secret key
        if (isNull(values.secretKey))
            errors.secretKey = "SECRET Key is required"
        else {
            if (values.secretKey === 'YOUCANTSEEME' && values.secretKey === 'youcantseeme')
                errors.secretKey = "Invalid SECRET key"
            else {
                onSetNewState(this, {
                    secretKey: values.secretKey
                })
            }
        }

        return errors
    }

    handleClickBack = (e) => {
        e.preventDefault()

        const { isSubmitting } = this.state
        if (!isSubmitting) {
            const { eventHandlers } = this.props
            if (isNotNull(eventHandlers)) {
                eventHandlers.navigateTo(null, onboardingScreen.SECURITY_VERIFICATION)
            }
        }
    }

    showSkipWarning = (e) => {
        const { eventHandlers } = this.props
        eventHandlers.handleSkip(e)
    }

    renderLoader = () => (
        <>
            <div style={{ width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="pf-spinner btn"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        </> 
    )

    render() {
        const lightboxOptions = {
            buttons: {
                showAutoplayButton: false,
                showThumbnailsButton: false
            }
        }

        let { ...state } = this.state

        return (
            <FinalForm 
                onSubmit={this.onSubmit} 
                validate={this.onValidate} 
                decorators={[this.formDecorator]} 
                initialValues={{...state}}
                render={({ handleSubmit }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <FormBodyContent className='api-connection'>
                                <div className='graphic with-preview notransform'>
                                    <SRLWrapper options={lightboxOptions}>
                                        <img src="/images/form-graphics/step-6.png" alt="Generate exchange API keys in Binance.com" style={{ width: '100%' }} />
                                    </SRLWrapper>
                                </div>

                                <div className='form'>
                                    <h2 className='form-title'>Connect PF to Binance.com</h2>
                                    <h3 className='form-instruction'>Enter your API key and Secret key below</h3>

                                    <Form.Group controlId="apiKey">
                                        <Form.Label>1. Copy and Paste the API key below</Form.Label>
                                        <Field name="apiKey" type="text">
                                            {({ input, meta }) => (
                                                <>
                                                    <div className={`input-wrapper ${(meta.error && meta.touched) || state.hasCustomError ? 'is-invalid' : ''}`}>
                                                        <Form.Control 
                                                            placeholder="Paste the API key here"
                                                            {...input}
                                                            autoComplete="off" />

                                                        { ((meta.error && meta.touched) || state.hasCustomError) && 
                                                            <Form.Text>
                                                                { !state.hasCustomError && 
                                                                    <>{meta.error}</>
                                                                }

                                                                { state.hasCustomError && 
                                                                    <>{state.customErrorMsg}</>
                                                                }
                                                            </Form.Text>
                                                        }
                                                    </div>
                                                </>
                                            )}
                                        </Field>
                                    </Form.Group>

                                    <Form.Group controlId="secretKey">
                                        <Form.Label>2. Copy and Paste the Secret key below</Form.Label>
                                        <Field name="secretKey" type="password">
                                            {({ input, meta }) => (
                                                <>
                                                    <div className={`input-wrapper ${(meta.error && meta.touched) || state.hasCustomError ? 'is-invalid' : ''}`}>
                                                        <Form.Control 
                                                            placeholder="Paste the SECRET key here"
                                                            {...input}
                                                            autoComplete="off" />

                                                        { ((meta.error && meta.touched) || state.hasCustomError) && 
                                                            <Form.Text>
                                                                { !state.hasCustomError && 
                                                                    <>{meta.error}</>
                                                                }

                                                                { state.hasCustomError && 
                                                                    <>{state.customErrorMsg}</>
                                                                }
                                                            </Form.Text>
                                                        }
                                                    </div>
                                                </>
                                            )}
                                        </Field>
                                    </Form.Group>
                                </div>
                            </FormBodyContent>

                            <FormInfoText type="list">
                                <label><strong>Attention:</strong></label>
                                <ul>
                                    <li>
                                        Make sure you copy the actual code and not ******. If you can no longer see the Secret Key in Binance, you have to delete the previous API and redo the process.
                                    </li>
                                </ul>
                            </FormInfoText>

                            <FormActions>
                                <TextButton 
                                    textColor="#8A9BAE"
                                    size="sm" 
                                    className='default-btn back-btn'
                                    onClick={(e) => this.handleClickBack(e)} 
                                    disabled={state.isSubmitting}>
                                        <FontAwesomeIcon size="xs" className='icon' icon={faArrowLeft} /> 
                                        <span>Back</span>
                                </TextButton>

                                <TextButton 
                                    textColor="#8A9BAE"
                                    size="auto" 
                                    className='default-btn skip-btn' 
                                    onClick={(e) => this.showSkipWarning(e)}
                                    disabled={state.isSubmitting}>
                                        <FontAwesomeIcon size="xs" className='icon' icon={faShare} /> 
                                        <span>Skip</span>
                                </TextButton>

                                <PrimaryButton 
                                    type="submit" 
                                    size="sm">
                                        { state.isSubmitting && this.renderLoader() }
                                        { !state.isSubmitting && 
                                            <>
                                                <FontAwesomeIcon size="xs" className='icon' icon={faArrowRight} /> 
                                                <span>Next</span>
                                            </>
                                        }
                                </PrimaryButton>
                            </FormActions>    
                        </Form>
                    )
                }} />
        )
    }
}

function mapStateToProps(state) {
    const { saveExchangeKeys, skipOnboarding } = state
    return {
        saveExchangeKeys,
        skipOnboarding
    }
}

const connectedExchangeKeys = connect(mapStateToProps)(ExchangeKeysForm)
export { connectedExchangeKeys as ExchangeKeysForm }