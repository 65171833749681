import iziToast from 'izitoast'

export const toastSuccess = (msg, title = 'Success', position = 'topRight') => {
    iziToast.success({
        title: title,
        message: msg,
        displayMode: 1,
        position: position
    })
}

export const toastWarning = (msg, title = 'Warning', position = 'topRight') => {
    iziToast.warning({
        title: title,
        message: msg,
        displayMode: 1,
        position: position
    })
}

export const toastError = (msg, title = 'Error', position = 'topRight') => {
    iziToast.warning({
        title: title,
        message: msg,
        displayMode: 1,
        position: position
    })
}


export const toastCustomError = (msg, title = 'Error', position = 'topRight') => {
    iziToast.warning({
        message: msg,
        class: 'custom-errors',
        displayMode: 1,
        position: position,
        messageColor: '#6E7885',
        timeout: 3000,
        backgroundColor: 'linear-gradient(90deg, rgba(19, 27, 38, 1) 88%, rgba(185, 63, 95, 0.5) 100%)',
        iconUrl: `/images/close-icon.png`,
    })
}