import glamorous from 'glamorous'
import * as glamor from 'glamor'
import { isNotNull } from '../../utils'

const getWidth = (desiredSize) => {
    switch(desiredSize) {
        default: return '100%'
        case 'auto': return 'auto'
        case 'sm': return '160px'
        case 'md': return '200px'
        case 'lg': return '280px'
    }
}

const getPadding = (desiredPadding) => {
    switch(desiredPadding) {
        default: return '10px 12px'
        case 'lg': return '15px 20px'
    }
}

const getBorderRadius = (desiredRadius) => {
    switch(desiredRadius) {
        default: return '25px'
        case 'lg': return '30px'
    }
}

const getAnimation = (animationName) => {
    switch(animationName) {
        default: return `${tada}`
    }
}

const tada = glamor.css.keyframes({
    'from': {
        transform: 'scale3d(1, 1, 1)'
    },
    '10%, 20%': {
        transform: 'scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg)'
    },
    '30%, 50%, 70%, 90%': {
        transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)'
    },
    '40%, 60%, 80%': {
        transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)'
    },
    'to': {
        transform: 'scale3d(1, 1, 1)'
    }
})

export const PrimaryButton = glamorous.button({
    fontSize: '18px',
    fontWeight: 600,
    letterSpacing: '1px',
    transition: 'all 0.5s ease 0s',
    width: '100%',
    height: 'auto',
    marginTop: '25px',
    color: '#fff',
    backgroundColor: '#1ea164',
    border: '2px solid #23d381',
    cursor: 'pointer',

    '&:hover': {
        transform: 'scale(1.05)',
        backfaceVisibility: 'hidden',
        overflow: 'auto'
    },

    // '&:disabled': {
    //     backgroundColor: '#16212e',
    //     borderColor: '#212b36',
    //     color: '#2c3a4a',
    //     cursor: 'not-allowed',

    //     '&:hover': {
    //         transform: 'none'
    //     }
    // }
},
({ customBg, size, animation, animationDelay, animationRepeat }) => ({
    width: getWidth(size),
    padding: getPadding(size),
    borderRadius: getBorderRadius(size),
    animation: isNotNull(animation) ? `${getAnimation(animation)}` : 'none',
    animationDuration: isNotNull(animation) ? '1s' : 'none',
    animationDelay: isNotNull(animationDelay) ? animationDelay : 'none',
    animationIterationCount: isNotNull(animationRepeat) ? animationRepeat : 'none',
    animationTimingFunction: isNotNull(animation) ? 'linear' : 'none',
    animationFillMode: isNotNull(animation) ? 'both' : 'none',
    backgroundImage: isNotNull(customBg) ? 'linear-gradient(to right, #13b078 0%, #2D79D6  51%, #43cea2  100%)' : 'none',
    backgroundSize: isNotNull(customBg) ? '200% auto' : 'none',

    '&:hover': {
        backgroundPosition: isNotNull(customBg) ? 'right center' : 'none'
    }    
}))