import { Container, Row, Col, Card } from 'react-bootstrap'
import { Preloader } from '../../styles/layout'
import { Wrapper, Section, Content } from './styled_components'

export const PricingView = props => {
    const { isLoading, subscriptionPlans } = props.state
 
    const renderPreloader = () => (
        <Preloader>
            <img srcSet={'/images/layout/loader-150.gif'} alt="" className="img-fluid" style={{ width: '120px', height: '120px' }} />
        </Preloader>
    )

    const renderMonthlySubscription = () => (
        <Card className="plan-details">
            <Card.Header>
                <h3>Monthly</h3>
            </Card.Header>
            <Card.Body>
                <div className="price-details">
                    {
                        isLoading 
                            ?
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            :
                                <h4>
                                    ${subscriptionPlans[0].price}
                                    <span className="frequency"> /month</span>
                                </h4>
                    }
                </div>

                <div className="breakdown-list-wrapper">
                    <ul className="breakdown-list">
                        <li>&nbsp;</li>
                        <li className="text-dark">Billed Monthly</li>
                        <li className="text-dark">No Setup Fee</li>
                        <li className="text-dark">Cancel Anytime</li>
                    </ul>
                </div>
            </Card.Body>

            <Card.Footer>
                <button
                    name="vip"
                    onClick={e => props.onPaymentHandler(e, 'monthly')}
                    className="btn btn-warning btn-select">
                    Get Started
                </button>
            </Card.Footer>
        </Card>
    )

    const renderQuarterlySubscription = () => (
        <Card className="plan-details highlighted">
            <Card.Header>
                <h3>Quarterly<br /><small className="badge badge-pill">Most Popular</small></h3>
            </Card.Header>
            <Card.Body>
                <div className="price-details">
                    {
                        isLoading 
                            ?
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            :
                                <h4>
                                    ${subscriptionPlans[1].price}
                                    <span className="frequency"> /month</span>
                                </h4>
                    }
                </div>

                <div className="breakdown-list-wrapper">
                    <ul className="breakdown-list">
                        <li className="text-dark annual-save">
                            <i className='fas fa-piggy-bank icon'></i>
                            <b> in annual saving!</b>
                        </li>
                        <li className="text-green">Save 33%</li>
                        <li className="text-dark">Billed Monthly</li>
                        <li className="text-dark">No Setup Fee</li>
                        <li className="text-dark">Cancel Anytime</li>
                    </ul>
                </div>
            </Card.Body>

            <Card.Footer>
                <button
                    name="vip"
                    onClick={e => props.onPaymentHandler(e, 'monthly')}
                    className="btn btn-warning btn-select">
                    Get Started
                </button>
            </Card.Footer>
        </Card>
    )

    if (isLoading)
        return renderPreloader()
    else
        return (
            <Wrapper>
                <Section className="bg">
                    <Content>
                        <Container className="full-height">
                            <Row className="center">
                                <Col>
                                    <div className="text-center mb-4">
                                        <img className="img-fluid" width="350" src="/images/logo/top-header.png" alt="" />
                                    </div>

                                    <div className="text-center mb-4">
                                        <h2 className="page-title">ProfitFarmers Trading Signals, Copy Trade and Auto-Trade</h2>
                                        <p className="page-subtitle">All in one - everything you need to become a profitable trader.</p>
                                    </div>

                                    <Row className="subscriptions-list">
                                        <Col lg={4}>
                                            {renderMonthlySubscription()}
                                        </Col>
                                        <Col lg={4}>
                                            {renderQuarterlySubscription()}
                                        </Col>
                                        <Col lg={4}>
                                        
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </Content>
                </Section>

                <Section className="guarantee">

                </Section>
            </Wrapper>
        )
}