async function setRole(userName, userRole) {
    const requestOpts = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 98de11f2-5fac-47c9-a338-6d3c8010b2a9' 
        }
    }

    return fetch(`https://www.profitfarmers.com/wp-json/alwp/changerole?uid=${userName}&role=${userRole}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        if (res.status === 401)
            authServices.logout()
        else
            return data
    })
}

export const blueprintService = {
    setRole
}