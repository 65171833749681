import React from 'react'
import { connect } from "react-redux"
import iziToast from 'izitoast'
import _ from 'lodash'
import { notificationActions, tradingActions, orderActions } from '../../../redux/actions'
import { NotificationPreviewView } from './NotificationPreviewView'
import { isNotNull, onSetNewState } from '../../../utils'

// const io = require("socket.io-client")

class NotificationPreview extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            isSubmitted: false,
            isActive: false,
            isSignalTraded: false,
            isTradeDetailsLoaded: false,
            tradeDetails: [],
            isRequesting: false,
            cancelTakeProfitId: null,
            coinLastPrice: null,

            isTradeManualBtnDisable: false,
            loadTradeManualLink: false
        }
        this.baseState = this.state
        this.mounted = false
        this.socket = null
    }

    componentDidMount() {
        this.mounted = true
        const { dispatch, selectedNotification } = this.props

        if (isNotNull(selectedNotification)) {
            const { actorId } = selectedNotification

            if (isNotNull(actorId))
                dispatch(tradingActions.getTradeDetails(actorId))
        }
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { dispatch, tradeDetails, viewTradeManual,
                markedNotification, isPushPreview, isPreview,
                selectedNotification, exitOrder, cancelOrder,
                closedNotificationPreviewSubmitted } = this.props

            if (prevProps.selectedNotification !== selectedNotification) {
                if (isNotNull(selectedNotification)) {
                    const { actorId } = selectedNotification

                    if (isNotNull(actorId))
                        dispatch(tradingActions.getTradeDetails(actorId))
                }
            }

            if (prevProps.tradeDetails !== tradeDetails) {
                const { loading, orderDetails } = tradeDetails
                if (!loading) {
                    if (isNotNull(orderDetails) && isNotNull(orderDetails.tradeDetails)) {
                        const { tradeDetails } = orderDetails
                        onSetNewState(this, {
                            isTradeDetailsLoaded: false,
                            tradeDetails: tradeDetails
                        })
                    }
                    else
                        // add loaded false but unset the trade details
                        onSetNewState(this, { isTradeDetailsLoaded: false })
                }
            }

            if (prevProps.viewTradeManual === false && viewTradeManual === true)
                onSetNewState(this, {
                    loadTradeManualLink: true
                })

            if (prevProps.viewTradeManual === true && viewTradeManual === false)
                onSetNewState(this, {
                    loadTradeManualLink: false
                })

            if (prevProps.markedNotification !== markedNotification) {
                const { item } = markedNotification
                if (isNotNull(item) &&
                    isNotNull(item.success) &&
                    item.success === true) {

                    if (isPushPreview === true) {
                        onSetNewState(this, {
                            isSubmitted: false
                        })
                        this.props.handleClose()
                    }
                }
            }

            if (prevProps.closedNotificationPreviewSubmitted !== closedNotificationPreviewSubmitted && isPreview === true) {
                onSetNewState(this, {
                    isSubmitted: false,
                    isActive: false
                })
                this.onClearNotificationHandler()
            }

            if (prevProps.exitOrder !== exitOrder) {
                const { loading, orderExit } = exitOrder
                if (!loading) {
                    if (isNotNull(orderExit) && isNotNull(orderExit.success) && orderExit.success) {
                        const { orderDetails } = tradeDetails
                        if (isNotNull(orderDetails) && isNotNull(orderDetails.tradeDetails)) {
                            const _tradeDetails = [...this.state.tradeDetails]
                            const index = _.findIndex(_tradeDetails, t => t.takeProfitId === orderExit.orderExit)
                            _tradeDetails[index].remainingTarget = []
                            _tradeDetails[index].takeProfitId = null
                            _tradeDetails[index].tradeStatus = 1
                            _tradeDetails[index].tradeStatusValue = "SOLD"
                            _tradeDetails[index].loading = false

                            onSetNewState(this, {
                                tradeDetails: _tradeDetails,
                                isRequesting: false
                            }, () => {
                                // check if there are no pending
                                var isThereAnyPending = _.findIndex([...this.state.tradeDetails], t => t.tradeStatus === 0)
                                if (isThereAnyPending < 0)
                                    this.onSubmitHandler(selectedNotification, isPreview, isPushPreview)
                            })
                            iziToast.success({
                                title: 'OK',
                                message: `Coin sold successfully!`,
                                position: 'topRight'
                            })
                        }
                    }
                }
            }

            if (isNotNull(cancelOrder) && isNotNull(cancelOrder.loading)) {
                if (cancelOrder.loading === false && prevProps.cancelOrder.loading === true) {
                    const { orderDetails } = tradeDetails
                    if (isNotNull(orderDetails) && isNotNull(orderDetails.tradeDetails)) {
                        const _tradeDetails = [...this.state.tradeDetails]
                        const index = _.findIndex(_tradeDetails, t => t.takeProfitId === this.state.cancelTakeProfitId)
                        _tradeDetails[index].remainingTarget = []
                        _tradeDetails[index].takeProfitId = null
                        _tradeDetails[index].tradeStatus = 2
                        _tradeDetails[index].tradeStatusValue = "CANCELLED"
                        _tradeDetails[index].loading = false

                        onSetNewState(this, {
                            tradeDetails: _tradeDetails,
                            isRequesting: false
                        }, () => {
                            // check if there are no pending
                            var isThereAnyPending = _.findIndex([...this.state.tradeDetails], t => t.tradeStatus === 0)
                            if (isThereAnyPending < 0)
                                this.onSubmitHandler(selectedNotification, isPreview, isPushPreview)
                        })

                        iziToast.success({
                            title: 'OK',
                            message: `Order cancelled successfully`,
                            position: 'topRight'
                        })
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        onSetNewState(this, this.baseState)
        if (isNotNull(this.socket)) this.socket.close()
        this.mounted = false
    }

    onClearNotificationHandler = () => {
        const { dispatch, onCloseNotificationPreviewSubmittedHandler } = this.props
        dispatch(notificationActions.clearNotificationPreview())
        dispatch(tradingActions.clearTradingDetails())
        onCloseNotificationPreviewSubmittedHandler(false)
    }

    onClosePreviewHandler = () => {
        onSetNewState(this, { isActive: false, isSignalTraded: false })
        if (isNotNull(this.socket)) this.socket.close()
        this.onClearNotificationHandler()
    }

    onTradeManualHandler = (isOpen) => this.props.openTradeManual(isOpen)

    onSubmitHandler = (data, isPreview, isPushPreview) => {
        onSetNewState(this, { isSubmitted: true })
        this.props.notificationPreviewClose(data, isPreview, isPushPreview)
    }

    onPanicSellHandler = (sellObject, signalInfo) => {
        const { isRequesting } = this.state
        if (!isRequesting) {
            const { tradeDetails, dispatch } = this.props
            const _tradeDetails = [...tradeDetails.orderDetails.tradeDetails]
            const item = _.findIndex(_tradeDetails, t => t === sellObject)
            _tradeDetails[item].loading = true
            onSetNewState(this, {
                tradeDetails: _tradeDetails,
                isRequesting: true,
            })
            // force exit object to be passed in backend
            const orderObj = {
                sellId: sellObject.takeProfitId,
                type: sellObject.type,
                feed: signalInfo.feed
            }

            dispatch(orderActions.exitOrder(orderObj))
        }
    }

    onCancelOrderHandler = (cancelObject, signalInfo) => {
        const { isRequesting } = this.state
        if (!isRequesting) {
            const { tradeDetails, dispatch } = this.props
            const _tradeDetails = [...tradeDetails.orderDetails.tradeDetails]
            const item = _.findIndex(_tradeDetails, t => t === cancelObject)

            _tradeDetails[item].loading = true
            onSetNewState(this, {
                tradeDetails: _tradeDetails,
                isRequesting: true,
                cancelTakeProfitId: cancelObject.takeProfitId
            })

            dispatch(orderActions.cancelOrder(cancelObject.takeProfitId, signalInfo.feed))
        }
    }

    render() {
        return (
            <NotificationPreviewView
                self={this}
                state={this.state}
                onTradeManualHandler={this.onTradeManualHandler}
                previewListsClose={this.onClosePreviewHandler}
                submitHandler={this.onSubmitHandler}
                onPanicSellHandler={this.onPanicSellHandler}
                onCancelOrderHandler={this.onCancelOrderHandler}
                {...this.props}
            />
        )
    }
}

function mapStateToProps(state) {
    const { notificationPreviews, markedNotification, exitOrder, tradeDetails, cancelOrder } = state
    return {
        notificationPreviews,
        markedNotification,
        exitOrder,
        tradeDetails,
        cancelOrder
    }
}

const connectedNotificationPreview = connect(mapStateToProps)(NotificationPreview)
export { connectedNotificationPreview as NotificationPreview }
