import styled from 'styled-components'
import {
    WalletItems, Wallet,
    WalletCurrency, WalletCoin,
    WalletBalance, WalletCoinWrapper,
    WalletButtonWrapper, WalletButton
} from './WalletItemsStyled'

export const WalletQuickTradeChangeContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 35px;
        border-radius: 100%;
        background: #f2f2f2;
    }
`

export const WalletQuickTradeChangeWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items:center;

    svg {
        font-size: 25px;
        cursor: pointer;

        &.disable {
            color: #505158;
        }
    }
`

export const WalletQuickTradeWrapper = styled.div`
    width: 100%;
    padding: 15px 0 0;

    ${WalletButtonWrapper} {
        padding: 15px 0 0;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;

        ${WalletButton} {
            width: 100%;
        }
    }
`

export const WalletSearchWrapper = styled.div`
    width: 100%;
    margin-bottom: 5px;

    input {
        width: 100%;
        padding: 10px;
        border-radius: 3px;
        color: #ced0dd;
        background-color: #202a35;
        border: none;
        font-size: 14px;

        &:hover,
        &:visited,
        &:focus {
            outline: none;
            box-shadow: none;
        }

        &::placeholder {
            color: #94a7c1;
            opacity: 0.5;
        }
    }

    @media screen and (min-width: 767px) and (max-width: 1023px) {
        input {
            font-size: 10px;
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        input {
            font-size: 12px;
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        input {
            font-size: 13px;
        }
    }

`

export const WalletTitleLists = styled.h2`
    font-size: 15px;
    margin-bottom: 0;
    text-align: left;
    width: 100%;
    color: #6e7885;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 32px;

    .btn-sync {
        color: #41FFA7;
        background-color: #202C39;
        border-color: #202C39;
        padding: 5px 10px;
        border-radius: 2px;
        font-size: 12px;
        z-index: 20;
        margin-right: 8px;
        margin-left: auto;

        &:hover, &:focus, &:visited {
            background-color: #0E1824 !important;
            border-color: #0E1824 !important;
            color: #6E7885;
            box-shadow: none;
            color: #ced0dd;
            outline: none;
        }

        &:disabled {
            color: grey;
            background-color: #202a35;
            border-color: #202a35;

            &:hover {
                color: grey;
                background-color: #202a35 !important;
                border-color: #202a35 !important;
            }
        }
    }
`

export const ShowWalletBalance = styled.label`
    display: block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    margin-bottom: 0;
    color: #778698;
    pointer-events: none;
    font-size: 15px;

    &.active {
        pointer-events: auto;
        color: #94a7c1;
    }

    i {
        position: relative;
        display: inline-block;
        margin-right: .5rem;
        width: 38px;
        height: 21px;
        background-color: #202A35;
        border-radius: 23px;
        vertical-align: text-bottom;
        transition: all 0.3s linear;

        &.active {
            &::before {
                background-color: #19212b;
            }

            &::after {
                background-color: #ccd4de;
            }
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 33px;
            height: 17px;
            background-color: #545659;
            border-radius: 11px;
            transform: translate3d(2px,2px,0) scale3d(1,1,1);
            transition: all 0.25s linear;
        }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 16px;
            height: 16px;
            background-color: #707274;
            border-radius: 11px;
            box-shadow: 0 0px 2px 2px rgba(0,0,0,0.24);
            transform: translate3d(2px,2px,0);
            transition: all 0.2s ease-in-out;
        }
    }

    input {
        display: none;

        &:checked + i {
            background-color: #15bd6f;

            &::before {
                transform: translate3d(14px, 2px, 0) scale3d(0, 0, 0);
            }

            &::after {
                transform: translate3d(20px, 2px, 0);
                background-color: #25313f;
            }
        }
    }

    &:active {

        i {
            &::after {
                width: 32px;
                transform: translate3d(2px,2.3px,0);
            }
        }

        input {

            &:checked + i {

                &::after {
                    width: 32px;
                    transform: translate3d(3px,2px,0);
                }
            }
        }
    }

    @media screen and (min-width: 320px) and (max-width: 1440px) {
        i {
            transform: scale(0.7);
            margin-right: 5px;
            margin-left: -10px;
        }
    }

    @media screen and (min-width: 320px) and (max-width: 1749px) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        font-size: 13px;
    }
`

export const WalletBtnBalanceWrapper = styled.div`
    width: 100%;
    padding-bottom: 15px;
    margin-top: 15px;
    margin-bottom: 0px;

    @media screen and (min-width: 320px) and (max-width: 1440px) {
        padding-bottom: 8px;
        margin-bottom:0px;
    }
`

export const WalletTitle = styled.h2`
    font-size: 15px;
    margin-bottom: 0;
    text-align: left;
    width: 100%;
    color: #6e7885;
    text-transform: uppercase;

    @media screen and (min-width: 320px) and (max-width: 1199px) {
        font-size: 12px;
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        font-size: 13px;
    }
`

export const WalletTitleCointainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    margin-top: 5px;

    @media screen and (min-width: 320px) and (max-width: 1440px) {
        padding-bottom: 8px;
        margin-bottom:8px;
    }
`

export const FeedButton = styled.button`
    &.feed-btn {
        width: 100%;
        border: none !important;
        border-color: transparent !important;
        background-color: transparent !important;
        border-radius: 6px !important;
        color: #6a7b8f !important;
        transition: all .5s ease;

        &.selected {            
            box-shadow: inset 0.2rem 0.2rem 0.5rem #0f1922, inset -0.2rem -0.2rem 0.5rem #223040;
            color: #15bd6f !important;
        }

        &:active,
        &:focus {
            outline: none;
            color: #15bd6f !important;
        }

        &:hover {
            color: #15bd6f !important;
        }
    }
`

export const WalletTitleWrapper = styled.div`
    padding: 20px 15px 20px 18px;
    border-bottom: 1px solid transparent;
    background-color: transparent;
    overflow: hidden;
    position: fixed;
    width: 240px;
    z-index: 10;

    .feed-toggle {
        width: 100%;
        margin-bottom: 15px;
        box-shadow: 0.3rem 0.3rem 0.6rem #0f1922, -0.2rem -0.2rem 0.5rem #223040;
        border-radius: 9px;
        padding: 5px;
    }

    @media screen and (min-width: 320px) and (max-width: 766px) {
        width: 240px;
        font-size: 10px;
        padding: 10px 15px;
    }

    @media screen and (min-width: 767px) and (max-width: 1199px) {
        width: 205px;
        font-size: 12px;
        padding: 10px 15px;
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        width: 210px;
        font-size: 13px;
        padding: 20px 12px 10px 12px;
    }

    @media screen and (min-width: 1441px) and (max-width: 1749px) {
        width: 220px;
        padding: 20px 15px;
    }
`

export const WalletWrapper = styled.div`
    width: 100%;
    height: 100%;
`

export const WalletIcon = styled.span`
    font-size: 20px;
    color: #fff;
    text-align: center;
    transition: 1s;
`

export const WalletList = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 5;
    font-size: 15px;

    .help-link {
        color: #cde1f7; 

        .help-link-content {
            padding: 10px;
            border-radius: 6px;
            background-color: #242d3a;
            border-bottom: 5px solid #0e1824;            
            
            i {
                margin-right: 8px;
                font-size: 16px;
                color: #eeca04;
            }
        }
    }

    @media screen and (min-width: 320px) and (max-width: 766px) {
        ${WalletItems} {
            ${Wallet} {
                height: 90px;
                padding: 10px;

                &.USDT {
                    height: 40px;
                }

                ${WalletCurrency} {

                    ${WalletCoinWrapper} {
                        width: 100%;
                        justify-content: flex-start;

                        img {
                            width: 25px;
                            margin-top: 0;
                        }
                    }

                    ${WalletBalance} {
                        width: 100%;
                        text-align: right;
                    }
                }

                ${WalletButtonWrapper} {
                    ${WalletButton} {
                        padding: 4px 10px;
                        font-size: 10px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 767px) and (max-width: 1023px) {
        font-size: 10px;

        ${WalletItems} {
            ${Wallet} {
                height: 90px;
                padding: 10px;

                &.USDT {
                    height: 40px;
                }

                ${WalletCurrency} {

                    ${WalletCoinWrapper} {
                        width: 100%;
                        justify-content: flex-start;

                        img {
                            width: 25px;
                            margin-top: 0;
                        }
                    }

                    ${WalletBalance} {
                        width: 100%;
                        text-align: right;
                    }
                }

                ${WalletButtonWrapper} {
                    ${WalletButton} {
                        padding: 4px 10px;
                        font-size: 10px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        font-size: 12px;

        ${WalletItems} {
            ${Wallet} {
                height: 90px;
                padding: 10px;

                &.USDT {
                    height: 40px;
                }

                ${WalletCurrency} {

                    ${WalletCoinWrapper} {
                        width: 100%;
                        justify-content: flex-start;

                        img {
                            width: 25px;
                            margin-top: 0;
                        }
                    }

                    ${WalletBalance} {
                        width: 100%;
                        text-align: right;
                    }
                }

                ${WalletButtonWrapper} {
                    ${WalletButton} {
                        padding: 4px 10px;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        font-size: 13px;

        ${WalletItems} {

            ${Wallet} {
                min-height: 100px;
                padding: 10px;

                &.USDT {
                    min-height: 80px;
                }

                ${WalletCurrency} {
                    padding-bottom: 0px;

                    ${WalletCoinWrapper} {
                        width: 100%;
                        justify-content: flex-start;
                        padding-bottom: 8px;

                        img {
                            width: 20px;
                            margin-top: 0px;
                        }

                        ${WalletCoin} {
                            font-size: 14px;
                            text-align: left;
                        }
                    }

                    .balance-text {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        flex-direction: column;

                        &.first {
                            padding-top: 5px;
                        }

                        ${WalletBalance} {
                            width: 100%;
                            font-size: 13px;
                            text-align: right;

                            .coinName {
                                margin-left: 8px;
                                font-size: 13px;
                                color: #9fb0c5;
                            }
                        }

                        .bal-usdt {
                            font-size: 13px !important;
                            color: #98a9be;
                            margin-top: 0 !important;
                        }
                    }
                }

                ${WalletButtonWrapper} {
                    padding-top: 8px;

                    ${WalletButton} {
                        font-size: 12px !important;
                        padding: .190rem .40rem !important;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1441px) and (max-width: 1749px) {
        ${WalletItems} {

            ${Wallet} {
                min-height: 100px;
                padding: 10px;

                &.USDT {
                    min-height: 80px;
                }

                ${WalletCurrency} {
                    padding-bottom: 0px;

                    ${WalletCoinWrapper} {
                        width: 100%;
                        justify-content: flex-start;
                        padding-bottom: 8px;

                        img {
                            width: 20px;
                            margin-top: 0px;
                        }

                        ${WalletCoin} {
                            font-size: 14px;
                            text-align: left;
                        }
                    }

                    .balance-text {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        flex-direction: column;

                        &.first {
                            padding-top: 5px;
                        }

                        ${WalletBalance} {
                            width: 100%;
                            font-size: 13px;
                            text-align: right;

                            .coinName {
                                margin-left: 8px;
                                font-size: 13px;
                                color: #9fb0c5;
                            }
                        }

                        .bal-usdt {
                            font-size: 13px !important;
                            color: #98a9be;
                            margin-top: 0 !important;
                        }
                    }
                }

                ${WalletButtonWrapper} {
                    padding-top: 8px;

                    ${WalletButton} {
                        font-size: 12px !important;
                        padding: .190rem .40rem !important;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1750px) and (max-width: 3000px) {
        ${WalletItems} {
            ${Wallet} {
                ${WalletCurrency} {
                    &:nth-child(2) {
                        ${WalletCoinWrapper} {
                            img {
                                width: 20px;
                            }
                            ${WalletCoin} {
                                font-size: 14px;
                            }
                        }
                        ${WalletBalance} {
                            font-size: 14px;
                        }
                    }
                    ${WalletCoinWrapper} {
                        width: 100%;
                        justify-content: flex-start;
                    }
                    ${WalletBalance} {
                        width: 100%;
                        text-align: right;
                    }
                }
            }
        }
    }
`

export const ContentSidePanel = styled.div`
    position: relative;
    right: 0;
    bottom: 0;
    width: 240px;
    padding: 0 18px 20px;
    overflow-y: auto;
    height: calc(100% - 363px);
    top: 327px;
    margin-top: 35px;

    &.futures {
        top: 100px;
        height: calc(100% - 135px);

        ${WalletWrapper} {
            height: auto;
        }

        &.hide {
            .position {
                -webkit-transition: all 300ms ease-in-out;
                transition: all 300ms ease-in-out;
                padding-left: 80px;
            }
        }

        .position {
            ${WalletList} {
                /* overflow-y: auto;
                height: calc(100vh - 370px); */

                &::-webkit-scrollbar {
                    width: 10px;
                }

                &::-webkit-scrollbar-track {
                    background-color: #202a35;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background-color: #273346;
                }

                .positionAmt {
                    display: grid;

                    .coinName {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: #202a35;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #273346;
    }

    &.hide {
        ${WalletList} {
            -webkit-transition: all 300ms ease-in-out;
            transition: all 300ms ease-in-out;
            padding-left: 80px;
        }
    }

    @media screen and (min-width: 320px) and (max-width: 766px) {
        width: 240px;
        padding: 10px 15px;
        top: 300px;
        height: calc(100% - 335px);

        &.futures {
            top: 100px;
        }

        &::-webkit-scrollbar {
            width: 6px;
        }
    }

    @media screen and (min-width: 767px) and (max-width: 1023px) {
        width: 205px;
        padding: 10px 15px;
        top: 295px;
        height: calc(100% - 331px);

        &.futures {
            top: 100px;
        }

        &::-webkit-scrollbar {
            width: 6px;
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        width: 205px;
        padding: 10px 15px;
        top: 295px;
        height: calc(100% - 331px);

        &.futures {
            top: 100px;
        }

        &::-webkit-scrollbar {
            width: 6px;
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        width: 210px;
        padding: 0 10px 10px;
        top: 315px;
        height: calc(100% - 350px);

        &.futures {
            top: 100px;
        }

        &::-webkit-scrollbar {
            width: 8px;
        }
    }

    @media screen and (min-width: 1441px) and (max-width: 1749px) {
        width: 220px;
        padding: 0 15px 15px;
        top: 327px;
        height: calc(100% - 361px);

        &.futures {
            top: 100px;
        }
    }
`

export const WalletBtnWrapper = styled.div`
    width: 45px;
    bottom: 40px;
    display: none;
    cursor: pointer;
    z-index: 15;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #15ba6e;
    background-image: none;
    border: 2px solid #167753;
    border-right: none;
    right: auto;
    left: -45px;
    position: absolute;
    padding: 5px 10px;

    @media screen and (min-width: 320px) and (max-width: 766px) {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: 18px
        }
    }

    @media screen and (min-width: 767px) and (max-width: 1023px) {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: 18px
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: 18px;
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        padding: 1px;
        left: -25px;
        width: 24px;
        bottom: 60px;
    }
`

export const WalletSideWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    transition: all 300ms ease-in-out;
    z-index: 15;
    background: #192431;
    border-left: 1px solid #101a27;

    &.hide {
        right: -178px;

        ${WalletTitleWrapper} {
            -webkit-transition: all 300ms ease-in-out;
            transition: all 300ms ease-in-out;
            margin-left: 80px;
        }
    }

    .btn-sidebar {
        position: fixed;
        right: 15px;
        color: #6E7885;
        background-color: #202C39;
        border-color: #202C39;
        padding: 5px 10px;
        border-radius: 2px;
        font-size: 12px;
        z-index: 20;

		&:hover {
			background-color: #0E1824 !important;
			border-color: #0E1824 !important;
			color: #6E7885;
            box-shadow: none;
		}

        &:active,
		&:focus {
			color: #6E7885;
			background-color: #202C39;
			border-color: #202C39;
			box-shadow: none;
		}
	}

    &.notif-permission {
        top: 36px;
    }

    @media screen and (min-width: 320px) and (max-width: 766px) {
        margin-top: 48px;
        right: -240px;

        &.hide {
            right: 0;

            ${WalletTitleWrapper} {
                margin-left: 0;
            }

            ${WalletList} {
                padding-left: 0;

            }

            .position {
                padding-left: 0 !important;
            }
        }

        .btn-sidebar {
            display: none;
        }
    }

    @media screen and (min-width: 767px) and (max-width: 1023px) {
        margin-top: 48px;
        right: -205px;

        &.hide {
            right: 0;

            ${WalletTitleWrapper} {
                margin-left: 0;
            }

            ${WalletList} {
                padding-left: 0;
            }

            .position {
                padding-left: 0 !important;
            }
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        margin-top: 47px;
        right: -205px;

        &.hide {
            right: 0;

            ${WalletTitleWrapper} {
                margin-left: 0;
            }

            ${WalletList} {
                padding-left: 0;
            }

            .position {
                padding-left: 0 !important;
            }
        }

        .btn-sidebar {
            display: none;
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        margin-top: 56px;

        &.hide {
            right: -163px;
        }

        .btn-sidebar {
            right: 10px;
            padding: 4px 8px;
            font-size: 10px;
        }
    }

    @media screen and (min-width: 1441px) and (max-width: 1749px) {
        margin-top: 56px;

        &.hide {
            right: -170px;
        }

        .btn-sidebar {
            right: 10px;
        }
    }

    @media screen and (min-width: 1750px) and (max-width: 3000px) {
        margin-top: 56px;
    }
`
