import { tradingActionTypes } from '../CONSTANTS'
import { tradingServices } from '../../services'
import { isNotNull } from '../../utils'

function getAutologin() {
    return dispatch => {
        dispatch(request())

        tradingServices.getAutologin()
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failure(error)))
    }
    function request() { return { type: tradingActionTypes.TRADING_GET_AUTOLOGIN_REQUEST } }
    function success(autologin) { return { type: tradingActionTypes.TRADING_GET_AUTOLOGIN_SUCCESS, autologin } }
    function failure(error) { return { type: tradingActionTypes.TRADING_GET_AUTOLOGIN_FAILURE, error } }
}

function getSettings() {
    return dispatch => {
        dispatch(request())

        tradingServices.settings()
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failure(error)))
    }

    function request() { return { type: tradingActionTypes.TRADING_REQUEST_SETTINGS } }
    function success(settings) { return { type: tradingActionTypes.TRADING_SUCCESS_SETTINGS, settings } }
    function failure(error) { return { type: tradingActionTypes.TRADING_FAILURE_SETTINGS, error } }
}

function getKeys(asid, exchange) {
    return dispatch => {
        dispatch(request())

        tradingServices.getKeys(asid, exchange)
            .then(response => {
                if (isNotNull(response))
                    dispatch(response.success ? success(response) : failure(response.msg))
                else
                    dispatch(failure('An unexpected error occured.'))
            })
            .catch(error => dispatch(failure(error)))
    }

    function request() { return { type: tradingActionTypes.TRADING_GET_KEYS_REQUEST } }
    function success(keys) { return { type: tradingActionTypes.TRADING_GET_KEYS_SUCCESS, keys } }
    function failure(keys_error) { return { type: tradingActionTypes.TRADING_GET_KEYS_FAILURE, keys_error } }
}

function exchangeOptions() {
    return dispatch => {
        dispatch(request());

        tradingServices.exchangeOptions()
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failure(error)))
    }

    function request() { return { type: tradingActionTypes.TRADING_GET_EXCHANGE_REQUEST } }
    function success(exchange) { return { type: tradingActionTypes.TRADING_GET_EXCHANGE_SUCCESS, exchange } }
    function failure(error) { return { type: tradingActionTypes.TRADING_GET_EXCHANGE_FAILURE, error } }
}

function existingTrailingUser(pass) {
    return dispatch => {
        dispatch(request());
        
        tradingServices.existingTrailingUser(pass)
            .then(response => {
                if (isNotNull(response.success))
                    dispatch(response.success ? success(response) : failure(response));
            })
            .catch(error => dispatch(failure(error)))
    }

    function request() { return { type: tradingActionTypes.EXISTING_TRAILING_USER_REQUEST } }
    function success(existingTrailUser) { return { type: tradingActionTypes.EXISTING_TRAILING_USER_SUCCESS, existingTrailUser } }
    function failure(existingError) { return { type: tradingActionTypes.EXISTING_TRAILING_USER_FAILURE, existingError } }
}

function authorizeUser(password) {
    return dispatch => {
        dispatch(request());
        
        tradingServices.authorizeUser(password).then(response => {
            if (isNotNull(response.success))
                dispatch(response.success ? success(response) : failure(response))
            else {
                if (isNotNull(response.failure))
                    dispatch(failure(response.failure));
            }
        }).catch(error => dispatch(failure(error)))
    }

    function request() { return { type: tradingActionTypes.TRADING_SEND_PASSWORD_REQUEST } }
    function success(response) { return { type: tradingActionTypes.TRADING_SEND_PASSWORD_SUCCESS, response } }
    function failure(failure) { return { type: tradingActionTypes.TRADING_SEND_PASSWORD_FAILURE, failure } }
}

function saveAutoCloseTradeSettings(data) {
    return dispatch => {
        dispatch(request())
        
        tradingServices.saveAutoCloseTradeSettings(data)
            .then(response => {
                dispatch(success(response))
            })
            .catch(error => dispatch(failed(error)))
    }

    function request() { return { type: tradingActionTypes.SAVE_AUTO_CLOSE_TRADE_REQUEST }}
    function success(response) { return { type: tradingActionTypes.SAVE_AUTO_CLOSE_TRADE_SUCCESS, response }}
    function failed(error) { return { type: tradingActionTypes.SAVE_AUTO_CLOSE_TRADE_FAILED, error }}
}

function saveKeys(tradingKey) {
    return dispatch => {
        dispatch(request())
        
        tradingServices.saveKeys(tradingKey)
            .then(response => {
                dispatch(success(response))
                // dispatch(userActions.getMe())
            })
            .catch(error => dispatch(failure(error)))
    }

    function request() { return { type: tradingActionTypes.TRADING_SAVE_KEYS_REQUEST } }
    function success(keys) { return { type: tradingActionTypes.TRADING_SAVE_KEYS_SUCCESS, keys } }
    function failure(save_error) { return { type: tradingActionTypes.TRADING_SAVE_KEYS_FAILURE, save_error } }
}

function disableTrading() {
    return dispatch => {
        dispatch(request());

        tradingServices.disableTrading()
            .then(response => dispatch(success(response)))
            .catch(error => dispatch(failure(error)))
    }

    function request() { return { type: tradingActionTypes.TRADING_REQUEST_DISABLE } }
    function success(disable) { return { type: tradingActionTypes.TRADING_SUCCESS_DISABLE, disable } }
    function failure(error) { return { type: tradingActionTypes.TRADING_FAILURE_DISABLE, error } }
}

function deleteKeys(asid, exchange) {
    return dispatch => {
        dispatch(request());

        tradingServices.deleteKeys(asid, exchange).then(response => {
            if (isNotNull(response.success))
                dispatch(response.success ? success(response) : failure(response))

            else if (isNotNull(response.error))
                dispatch(response.error ? success(response) : failure(response))

        }).catch(error => dispatch(failure(error)))
    }

    function request() { return { type: tradingActionTypes.TRADING_REMOVE_KEYS_REQUEST } }
    function success(remove) { return { type: tradingActionTypes.TRADING_REMOVE_KEYS_SUCCESS, remove } }
    function failure(rem_keys) { return { type: tradingActionTypes.TRADING_REMOVE_KEYS_FAILURE, rem_keys } }
}

function clear() {
    return dispatch => {
        dispatch({ type: tradingActionTypes.CLEAR });
    }
}

function disableClear() {
    return dispatch => {
        dispatch({ type: tradingActionTypes.DISABLE_CLEAR });
    }
}

function removeClear() {
    return dispatch => {
        dispatch({ type: tradingActionTypes.CLEAR_API_KEY });
    }
}

function getTradeDetails(signalId) {
    return dispatch => {
        dispatch(request())
       
        tradingServices.getTradeDetails(signalId).then(response => {
            if (isNotNull(response.success))
                dispatch(response.success ? success(response) : failure(response))

        }).catch(error => dispatch(failure(error)))
    }

    function request() { return { type: tradingActionTypes.GET_TRADE_DETAILS_REQUEST } }
    function success(orderDetails) { return { type: tradingActionTypes.GET_TRADE_DETAILS_SUCCESS, orderDetails } }
    function failure(err) { return { type: tradingActionTypes.GET_TRADE_DETAILS_FAILURE, err } }
}

function clearTradingDetails() {
    return dispatch => {
        dispatch({
            type: tradingActionTypes.CLEAR_TRADE_DETAILS
        })
    }
}


export const tradingActions = {
    getAutologin,
    getKeys,
    exchangeOptions,
    getSettings,
    existingTrailingUser,
    authorizeUser,
    clear,
    disableClear,
    saveAutoCloseTradeSettings,
    saveKeys,
    disableTrading,
    deleteKeys,
    removeClear,
    getTradeDetails,
    clearTradingDetails
}