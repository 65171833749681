import React, { createRef } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Card, Form, InputGroup, SafeAnchor } from 'react-bootstrap'
import { Form as FinalForm, Field } from "react-final-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash, faExclamationCircle, faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons"

import { Header, PasswordHelper, Button } from '../../components'

import '../../assets/scss/auth-pages.scss'
import { FormContainer, FormHeadingWrapper, FormBodyWrapper, Error } from '../../styles/forms'
import { Main, Aside } from './styled-components'
import { isNull, passwordValidator, emailValidator } from '../../utils'

export const LogInView = (props) => {
    const $this = props.self
    const { isLoading } = props
    const { eyeIcon, isToRememberMe, passwordInputType, userName, userPass } = props.state

    let passwordCriteria = {
        letter: false,
        capital: false,
        number: false,
        special: false,
        size: false
    }

    const onViewPasswordAsText = () => {
        const oldState = props.state.passwordInputType
        const newState = oldState === "password" ? "text" : "password"
        const newWord = oldState === "password" ? faEyeSlash : faEye

        $this.setState({
            passwordInputType: newState,
            eyeIcon: newWord
        })
    }

    const onValidate = values => {
        const errors = {}
        if (isNull(values.username))
            errors.username = 'Email address is required'
        else {
            if (!emailValidator.isValid(values.username))
                errors.username = 'Please enter a valid email address'
        }

        if (isNull(values.password)) {
            errors.password = 'New Password is required'
            passwordCriteria = {
                letter: false,
                capital: false,
                number: false,
                special: false,
                size: false
            }
        } else {
            passwordCriteria.letter = passwordValidator.hasLowercase(values.password)
            passwordCriteria.capital = passwordValidator.hasUppercase(values.password)
            passwordCriteria.number = passwordValidator.hasNumeric(values.password)
            passwordCriteria.special = passwordValidator.hasSpecialChar(values.password)
            passwordCriteria.size = passwordValidator.isWithinMinAndMaxLength(values.password)

            if (!(passwordCriteria.letter &&
                passwordCriteria.capital &&
                passwordCriteria.number &&
                passwordCriteria.special &&
                passwordCriteria.size))
                errors.password = "Invalid password"
        }
        return errors
    }

    const onRememberMe = (isToRememberMe) => $this.setState({ isToRememberMe: !isToRememberMe })

    const onSubmit = async values => {
        props.submit(values)
    }

    const renderSubmitButton = (isLoading, submitting) => {
        if (isLoading)
            return (
                <Button
                    className="auth-btn"
                    variant="primary"
                    disabled={true}>
                    <span>Signing in...</span>
                </Button>
            )
        else
            return (
                <Button
                    className="auth-btn"
                    type="submit"
                    variant="primary"
                    disabled={submitting} >
                    <span>Sign in</span>
                </Button >
            )
    }

    return (
        <div className="main-wrapper">
            <div className="container">
                <div className="content">
                    <Header purpose="login" />

                    <Main>
                        <Container>
                            <Row className="justify-content-center">
                                <FormContainer>
                                    <FormHeadingWrapper>
                                        <h1>Sign in.</h1>
                                    </FormHeadingWrapper>

                                    <FormBodyWrapper>
                                        <FinalForm
                                            onSubmit={onSubmit}
                                            validate={onValidate}
                                            decorators={[props.decorators]}
                                            initialValues={{ username: userName, password: userPass }}
                                            render={({ handleSubmit, submitting }) => {
                                                return (
                                                    <Form
                                                        onSubmit={handleSubmit}>
                                                        <Form.Group controlId="formBasicEmail">
                                                            <Field name="username" type="email">
                                                                {({ input, meta }) => (
                                                                    <>
                                                                        <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                                                            <InputGroup>
                                                                                <InputGroup.Prepend>
                                                                                    <InputGroup.Text>
                                                                                        <FontAwesomeIcon size="sm" className="icon" icon={faEnvelope} />
                                                                                    </InputGroup.Text>
                                                                                </InputGroup.Prepend>

                                                                                <Form.Control
                                                                                    disabled={isLoading}
                                                                                    placeholder="Email"
                                                                                    {...input}
                                                                                    autoComplete="off" />
                                                                            </InputGroup>
                                                                        </div>

                                                                        <Error className={`${meta.error && meta.touched ? 'invalid' : ''}`}>
                                                                            {meta.error && meta.touched && (
                                                                                <>
                                                                                    <FontAwesomeIcon icon={faExclamationCircle} className="icon" />
                                                                                    <span>{meta.error}</span>
                                                                                </>
                                                                            )}
                                                                        </Error>
                                                                    </>
                                                                )}
                                                            </Field>
                                                        </Form.Group>

                                                        <Form.Group controlId="formBasicPassword">
                                                            <Field name="password">
                                                                {({ input, meta }) => {
                                                                    let element = createRef()
                                                                    return (
                                                                        <>
                                                                            <div ref={element} className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                                                                <InputGroup>
                                                                                    <InputGroup.Prepend>
                                                                                        <InputGroup.Text>
                                                                                            <FontAwesomeIcon size="sm" className="icon" icon={faLock} />
                                                                                        </InputGroup.Text>
                                                                                    </InputGroup.Prepend>

                                                                                    <Form.Control
                                                                                        disabled={isLoading}
                                                                                        type={passwordInputType}
                                                                                        placeholder="Password"
                                                                                        {...input}
                                                                                        autoComplete="off" />

                                                                                    <InputGroup.Append>
                                                                                        <InputGroup.Text onClick={onViewPasswordAsText}>
                                                                                            <FontAwesomeIcon size="sm" icon={eyeIcon} className="" />
                                                                                        </InputGroup.Text>
                                                                                    </InputGroup.Append>
                                                                                </InputGroup>

                                                                                <PasswordHelper
                                                                                    show={meta.active && meta.touched && meta.error && meta.visited}
                                                                                    target={element}
                                                                                    container={element}
                                                                                    placement="bottom"
                                                                                    data={isNull(input.value) ? props.initialPasswordHelperState : passwordCriteria} />
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }}
                                                            </Field>
                                                        </Form.Group>

                                                        {renderSubmitButton(isLoading, submitting)}
                                                    </Form>
                                                )
                                            }}
                                        />

                                        <div className="quicklinks-con" style={{ fontSize: '14px', marginTop: '25px', color: '#fff', textAlign: 'center' }}>
                                            <p style={{ color: '#8fa0b5' }}>Don't have an account? <SafeAnchor href="https://www.profitfarmers.com/account-register/" className="btn-link" style={{ color: '#fff', fontWeight: 700, letterSpacing: '1px' }}>Create an account</SafeAnchor></p>
                                            <Link className="quick-link" to="/account/forgot-password" style={{ color: '#fff', fontWeight: 700 }}>Forgot password?</Link>
                                        </div>
                                    </FormBodyWrapper>
                                </FormContainer>
                            </Row>
                        </Container>
                    </Main>
                </div>
            </div>
        </div>
    )
}
