import { offersActionTypes } from '../CONSTANTS'

export function onetimeOffer(state={}, action) {
    switch(action.type) {
        case offersActionTypes.SKIP_OFFER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case offersActionTypes.SKIP_OFFER_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.response
            }
        case offersActionTypes.SKIP_OFFER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}