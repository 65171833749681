import styled from 'styled-components'

const PasswordHelperWrapper = styled.div`
    &.pass-helper {
        max-width: 100%;
        font-size: 13px;
        width: 100%;

        .arrow {
            left: 5px !important;
            transform: translate(10px, 0px) !important;
        }

        .pass-helper-title {
            font-size: 13px;
        }

        .pass-helper-body {

            .pass-helper-criteria-list {
                list-style: none;
                padding: 0;
                margin-bottom: 0;
    
                .pass-helper-icons {
                    text-align: center;
    
                    i {
                        width: 30px;
    
                        &.error {
                            color: red;
                        }
    
                        &.success {
                            color: #15bd6f;
                        }
                    }
                }
    
                .pass-helper-text {}
            }
        }
    }
`

export {
    PasswordHelperWrapper
}