import React from 'react'
import { connect } from 'react-redux'
import { SafeAnchor } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faLightbulb,
    faBookOpen,
    faHeadphonesAlt } from '@fortawesome/free-solid-svg-icons'
import { isNull, isNotNull, onSetNewState } from '../../utils'

class HelpLinks extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isPremiumAccess: null
        }

        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true

        const { isLoading, isPremiumAccess } = this.state
        const { authentication } = this.props
        const { user } = authentication
        if (isLoading && 
            isNull(isPremiumAccess) && 
            isNotNull(user)) {
            onSetNewState(this, {
                isPremiumAccess: user.isPaid
            })
        }
    }

    componentDidUpdate() {
        if (this.mounted) {
            const { isLoading, isPremiumAccess } = this.state
            const { authentication } = this.props
            const { user } = authentication
            
            if (isLoading && 
                isNull(isPremiumAccess) && 
                isNotNull(user)) {
                onSetNewState(this, {
                    isPremiumAccess: user.isPaid
                })
            }
            
            if (isLoading && isNotNull(isPremiumAccess)) {
                onSetNewState(this, {
                    isLoading: false
                })
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    render() {
        const { isLoading, isPremiumAccess } = this.state

        let baseDomain = null
        if (isNotNull(isPremiumAccess))
            baseDomain = isPremiumAccess ? 'pro-support' : 'support'

        return (
            <ul className='sidebar-submenu-list'>
                <li className='sidebar-submenu-item'>
                    { !isLoading && isNotNull(baseDomain) && 
                        <SafeAnchor href={ isPremiumAccess 
                            ? 'https://pro-support.profitfarmers.com/article/254-contacting-customer-support' 
                            : 'https://support.profitfarmers.com/article/255-customer-support-for-free-members'} 
                            target="_blank" 
                            rel="noreferrer" 
                            className="sidebar-submenu-item-link">
                            <span className='submenu-item-link-icon'>
                                <FontAwesomeIcon size='lg' icon={faHeadphonesAlt} />
                            </span>
                            <span className='submenu-item-link-text'>Contact Support</span>
                        </SafeAnchor>
                    }  
                </li>
                <li className='sidebar-submenu-item'>
                    { !isLoading && isNotNull(baseDomain) && 
                        <SafeAnchor href={`https://${baseDomain}.profitfarmers.com/`} 
                            target="_blank" 
                            rel="noreferrer"
                            className="sidebar-submenu-item-link">
                            <span className='submenu-item-link-icon'>
                                <FontAwesomeIcon size='lg' icon={faLightbulb} />
                            </span>
                            <span className='submenu-item-link-text'>Knowledge Base</span>
                        </SafeAnchor>
                    }  
                </li>
                <li className='sidebar-submenu-item'>
                    { !isLoading && isNotNull(isPremiumAccess) && 
                        <SafeAnchor 
                            href="https://pro-support.profitfarmers.com/article/266-signal-strategies"
                            target="_blank" 
                            rel="noreferrer"
                            className="sidebar-submenu-item-link">
                            <span className='submenu-item-link-icon'>
                                <FontAwesomeIcon size='lg' icon={faBookOpen} />
                            </span>
                            <span className='submenu-item-link-text'>Signal Strategy Guides</span>
                        </SafeAnchor>
                    }  
                </li>
            </ul>
        )
    }
}

function mapStateToProps(state) {
    const { authentication } = state
    return {
        authentication
    }
}

const connectedHelpLinks = connect(mapStateToProps)(HelpLinks)
export { connectedHelpLinks as HelpLinks }