import styled from 'styled-components'
import { mediaQueries } from '../../../constants/mediaQueries'

export const TestimonialsStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    background-color: #162536;
    padding: 60px 0 10px;

    .container {
        padding-left: 0;
        padding-right: 0;
        max-width: 100%;

        .section-title {
            color: #fff;
            font-size: 26px;
            font-weight: 700;
            text-align: center;

            small {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 206px;
                margin: 15px auto 0;
                font-size: 17px;

                .tp-logo-wrapper {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    margin-top: -10px;

                    img {
                        &:first-child {
                            width: 27px;
                        }

                        &:last-child {
                            margin-left: 5px;
                            height: 16px;
                        }
                    }
                }
            }
        }

        .carousel {
            .carousel-inner {
                padding: 0 0 35px;

                .carousel-item {
                    padding: 35px 28px;

                    .testimony-list {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 auto;
                        padding: 0;
                        list-style-type: none;

                        .testimony {
                            position: relative;
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;
                            flex-direction: column;
                            width: 422px;
                            min-height: 500px;
                            border-radius: 3rem;
                            box-shadow: 0.8rem 0.8rem 1.4rem #101e2e, -0.2rem -0.2rem 1.8rem #1f344a;
                            padding: 5rem 2rem 2rem 48px;
                            margin-right: 20px;
                            color: #202b39;
                            font-style: italic;
                            text-align: left;
                            background-color: #0c1620;

                            h6 {
                                font-size: 17px;
                                font-weight: 600;
                                margin-bottom: 20px;
                                color: #fff;
                            }

                            p {
                                font-size: 15px;
                                font-weight: 400;
                                line-height: 1.5;
                                color: #a1aec3;

                                .emoji {
                                    height: 20px;
                                    margin-left: 3px;
                                    margin-bottom: 5px;
                                }
                            }

                            .author {
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                width: 100%;
                                margin-top: auto;
                                font-style: normal;
                                color: #fff;

                                .user-name {
                                    text-align: right;

                                    > label {
                                        font-size: 18px;
                                        font-weight: 600;
                                    }

                                    > img {
                                        display: block;
                                        height: 22px;
                                    }
                                }
                                
                                .user-avatar {
                                    width: 70px;
                                    height: 70px;
                                    border-radius: 35px;
                                    overflow: hidden;
                                    margin-left: 15px;

                                    img {
                                        width: 100%;
                                    }
                                }
                            }

                            &:before {
                                display: block;
                                position: absolute;
                                content: ' ';
                                left: 25px;
                                top: 30px;
                                width: 30px;
                                height: 21px;
                                background-image: url('/images/offers/pf-tml-quote-1.png');
                                background-size: contain;
                                background-position: center;
                                background-repeat: no-repeat;
                            }

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .carousel-control-prev {
                left: -180px;
            }

            .carousel-control-next {
                right: -180px;
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        .container {
            .section-title {
                font-size: 30px;

                small {
                    font-size: 19px;
                    width: 241px;

                    .tp-logo-wrapper {
                        img {
                            &:first-child {
                                width: 34px;
                            }

                            &:last-child {
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        padding: 60px 0;

        .container {
            .carousel {
                .carousel-control-prev {
                    left: -42px;
                }

                .carousel-control-next {
                    right: -42px;
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        padding: 80px 0 60px;

        .container {
            padding-left: 15px;
            padding-right: 15px;
            max-width: 1920px;

            .section-title {
                font-size: 36px;

                small {
                    width: 290px;
                    font-size: 24px;

                    .tp-logo-wrapper {
                        &:first-child {
                            width: 41px;
                        }

                        &:last-child {
                            margin-left: -12px;
                            height: 24px;
                        }
                    }
                }
            }

            .carousel {
                .carousel-inner {
                    .carousel-item {
                        padding: 35px 0;

                        .testimony-list {
                            .testimony {
                                min-height: 572px;

                                h6 {
                                    font-size: 23px;
                                }

                                p {
                                    font-size: 17px;
                                }
                            }
                        }
                    }
                }

                .carousel-control-prev {
                    left: -90px;
                }

                .carousel-control-next {
                    right: -90px;
                }
            }
        }
    }

    ${mediaQueries.superExtraLargeDevices} {
        .container {
            .carousel {
                .carousel-control-prev {
                    left: -180px;
                }

                .carousel-control-next {
                    right: -180px;
                }
            }
        }
    }
`
