import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Modal, Form, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import Dropzone from 'react-dropzone'
import { Form as FinalForm, Field } from 'react-final-form'
import newFormDecorator from 'final-form-focus'
import { Editor } from '@tinymce/tinymce-react'
import _ from 'lodash'
import iziToast from 'izitoast'
import { MessageBoardForm, CloseButton } from '../../styles'
import { isNotNull, isNull, onSetNewState } from '../../utils'
import { messagesActions } from '../../redux/actions'

class CreateMessageForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isEditing: false,
            id: null,
            headerPhotoUrl: undefined,
            title: '',
            shortDescription: '',
            type: '',
            initialHtmlContent: null,
            htmlContent: null,
            audienceType: '',
            datePublished: new Date()
        }

        this.mounted = false
        this.editorRef = React.createRef()
        this.formDecorator = newFormDecorator()
    }

    componentDidMount() {
        this.mounted = true

        const { isEditing, editData } = this.props
        if (isNotNull(isEditing) && isEditing) {
            let datePublished = new Date()

            if (isNotNull(editData.dateCreated)) {
                let parsedDate = Date.parse(editData.dateCreated)
                datePublished = new Date(parsedDate)
            }
            
            onSetNewState(this, {
                isEditing,
                id: editData.id,
                headerPhotoUrl: editData.headerPhotoUrl,
                title: editData.title,
                shortDescription: editData.shortDescription,
                type: editData.type,
                initialHtmlContent: editData.htmlContent,
                audienceType: editData.audienceType,
                datePublished
            })
        }

        // if (isNull(values.datePublished))
        //     values.datePublished = new Date()
        // else {
        //     let parsedDate = Date.parse(values.datePublished)
        //     values.datePublished = new Date(parsedDate)
        // }
    }

    componentDidUpdate() {
        if (this.mounted) {
            const { 
                dispatch,
                msgHeaderPhoto, 
                saveMessageBoardItem, 
                editMessageBoardItem,
                onAdded, 
                onEdited,
                closeMessage } = this.props
            const { ...data } = this.state

            if (isNull(data.headerPhotoUrl) && 
                isNotNull(msgHeaderPhoto) && 
                !msgHeaderPhoto.loading && 
                isNotNull(msgHeaderPhoto.location)) {
                    onSetNewState(this, {
                        headerPhotoUrl: msgHeaderPhoto.location
                    })
            }
            
            if (isNotNull(saveMessageBoardItem) && 
                !saveMessageBoardItem.loading && 
                isNotNull(saveMessageBoardItem.result) && 
                saveMessageBoardItem.result.success) {
                    this.setState(this.baseState)
                    const { id } = saveMessageBoardItem.result                    
                    onAdded(id)
                    dispatch(messagesActions.resetAddMessageState())
                    closeMessage()
                }

            if (data.isEditing && 
                isNotNull(editMessageBoardItem) && 
                !editMessageBoardItem.loading && 
                isNotNull(editMessageBoardItem.editResult) && 
                editMessageBoardItem.editResult.success) {
                    const { id } = editMessageBoardItem.editResult
                    onEdited(id)
                    dispatch(messagesActions.resetState())
                    this.setState(this.baseState)
                }
        }
    }

    componentWillUnmount() {
        this.setState(this.baseState)
        this.mounted = false
        this.editorRef = null
        this.formDecorator = null
    }

    handleMessageTypeSelection = (e) => {
        e.preventDefault()

        onSetNewState(this, {
            type: e.target.value
        })
    }

    handleAudienceSelection = (e) => {
        e.preventDefault()

        onSetNewState(this, {
            audienceType: e.target.value
        })
    }

    handleImageUpload = (blobInfo, progress) => new Promise((resolve, reject) => {
        let xhr, formData

        xhr = new XMLHttpRequest()
        xhr.withCredentials = true
        xhr.open('POST', 'https://api.profitfarmers.com/file-uploader/photo/add')

        xhr.upload.onprogress = function(e) {
            progress(e.loaded / e.total * 100)
        }

        xhr.onload = function() {
            let json

            if (xhr.status === 403) {
                reject('HTTP Error: ' + xhr.status, { remove: true })
            }

            if (xhr.status < 200 || xhr.status >=300) {
                reject('HTTP Error: ' + xhr.status)
            }

            json = JSON.parse(xhr.responseText)

            if (!json || typeof json.location != 'string') {
                reject('Invalid JSON: ' + xhr.responseText)
            }

            resolve(json.location)
        }

        xhr.onerror = function() {
            reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status)
        }

        formData = new FormData()
        formData.append('file', blobInfo.blob(), blobInfo.filename())

        xhr.send(formData)
    })

    handleDateChange = (e) => {
        onSetNewState(this, {
            datePublished: moment(e)
        })
    }

    onDrop = () => {
        const { isEditing, headerPhotoUrl } = this.state
        const { dispatch } = this.props
        const file = document.getElementById('inputUploader').files[0]
        const fileName = document.getElementById('inputUploader').files[0].name
        //const fileExtension = document.getElementById('inputUploader').files[0].type

        if (isNull(file) || isNull(fileName))
            alert('NO DATA!')
        else {
            const promise = new Promise((resolve, reject) => {
                const fileReader = new FileReader()
                fileReader.readAsDataURL(file)
                fileReader.onload = () => {
                    if (!!fileReader.result) {
                        resolve(fileReader.result)
                    } else {
                        reject(console.log("Failed converting to base64"))
                    }
                }
            })

            promise.then(async result => {
                const base64Response = await fetch(result)
                const blob = await base64Response.blob()
                if (isEditing) {
                    dispatch(messagesActions.replaceHeaderPhoto(blob, fileName, headerPhotoUrl))
                } else {
                    dispatch(messagesActions.addHeaderPhoto(blob, fileName))
                }
            }, eror => {
                console.log(error)
            })
        }
    }

    onDropReject = () => {
        iziToast.warning({
            position: 'topRight',
            title: 'Warning',
            message: 'Maximum file upload size is 700KB',
            displayMode: 1
        })
    }

    onValidate = (values) => {
        const errors = {}

        // validate title
        if (isNull(values.title)) 
            errors.title = "Please enter a title"
        else {
            onSetNewState(this, {
                title: values.title
            })
        }

        // validate type
        if (isNull(values.type))
            errors.type = "Please choose a type"
        else {
            onSetNewState(this, {
                type: values.type
            })
        }

        // validate short description
        if (isNull(values.shortDescription)) 
            errors.shortDescription = "Please write a short description"
        else {
            onSetNewState(this, {
                shortDescription: values.shortDescription
            })
        }

        // validate audience
        if (isNull(values.audienceType))
            errors.audienceType = "Please choose an audience"
        else {
            onSetNewState(this, {
                audienceType: values.audienceType
            })
        }

        // validate date published
        if (isNull(values.datePublished))
            errors.datePublished = "Date published is required"
        else {
            let isValidDateFormat = moment(values.datePublished, 'DD/MM/yyyy HH:mm:ss', true).isValid()
            if (!isValidDateFormat) {
                let newDate = new Date(values.datePublished)
                isValidDateFormat = moment(newDate, 'DD/MM/YYYY HH:mm:ss', true).isValid()
                if (!isValidDateFormat)
                    errors.datePublished = "Invalid date"
            }
        }

        return errors
    }

    onSubmit = (values) => {
        const { dispatch } = this.props
        const { isEditing } = this.state

        // const headerPhoto = document.getElementById('header-photo-container')
        //let htmlBody = "<div id='body-content-wrapper'>"
        //     htmlBody += headerPhoto.innerHTML
        
        // let htmlBody = "<div id='body-content-wrapper'>"
        // if (this.editorRef.current) { 
        //     htmlBody += "<div id='inner-body-content'>"
        //     htmlBody += this.editorRef.current.getContent()
        //     htmlBody += "</div>"
        // }
        // htmlBody += "</div>"

        values.htmlContent = this.editorRef.current.getContent()
        values.type = parseInt(values.type)
        values.audienceType = parseInt(values.audienceType)

        let $thisDate = moment(values.datePublished).format()
        values.datePublished = moment(values.datePublished).format('DD/MM/yyyy HH:mm:ss')        
        
        if (isEditing) {
            dispatch(messagesActions.editMessage(values))
        } else {
            dispatch(messagesActions.saveMessage(values))
        }

        values.datePublished = $thisDate
    }

    renderMessageTypes = () => {
        const { type } = this.state
        const { messageTypeOptions } = this.props
        let mappedOpts = []

        if (isNotNull(messageTypeOptions)) {
            mappedOpts = _.map(messageTypeOptions, (value, key) => {
                return (
                    <option 
                        key={key}
                        value={value.key}>{value.value}</option>
                )
            })
        }

        return (
            <Form.Control 
                as="select"
                size="lg"
                value={type}
                onChange={(e) => this.handleMessageTypeSelection(e)}>
                    <option value="">-- choose type --</option>
                    {mappedOpts}
            </Form.Control>
        )
    }

    renderAudiences = () => {
        const { audienceType } = this.state
        const { audienceTypeOptions } = this.props
        let mappedOpts = []

        if (isNotNull(audienceTypeOptions)) {
            mappedOpts = _.map(audienceTypeOptions, (value, key) => {
                return (
                    <option 
                        key={key} 
                        value={value.key}>{value.value}</option>
                )
            })
        }
        
        return (
            <Form.Control 
                as="select" 
                size="lg" 
                value={audienceType} 
                onChange={(e) => this.handleAudienceSelection(e)}>
                    <option>-- choose audience --</option>
                    {mappedOpts}
            </Form.Control>
        )
    }

    render() {
        const { closeMessage } = this.props
        let { ...state } = this.state

        return (
            <FinalForm
                onSubmit={this.onSubmit} 
                validate={this.onValidate} 
                decorators={[this.formDecorator]} 
                initialValues={{...state}} 
                render={({ handleSubmit, pristine }) => {
                    return (
                        <Form onSubmit={handleSubmit} noValidate>
                            <Modal.Header>
                                <h3>{ !state.isEditing ? <>Create New</> : <>Edit</>} Message</h3>
                            </Modal.Header>

                            <Modal.Body>
                                <CloseButton onClick={(e) => closeMessage(e)} className="custom-close msg-modal-close-btn">
                                    <FontAwesomeIcon icon={faTimes} className="icon" />
                                </CloseButton>

                                <MessageBoardForm>
                                    <div className='form-horizontal'>
                                        { state.isEditing && 
                                            <Field name="id" type="hidden">
                                                {({ input, meta }) => (
                                                    <input type="hidden" {...input} />
                                                )}
                                            </Field>
                                        }

                                        <Form.Row>
                                            <Form.Group as={Col} xs={12} sm={12} md={9} lg={9} controlId="title">
                                                <Form.Label>Title <span className='required'>*</span></Form.Label>
                                                <Field name="title" type="text">
                                                    {({ input, meta }) => (
                                                        <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                                            <Form.Control 
                                                                type="text" 
                                                                size="lg" 
                                                                maxLength={100} 
                                                                placeholder="What's going on?"
                                                                autoComplete='off' 
                                                                {...input} />

                                                            { meta.error && meta.touched && 
                                                                <Form.Text>
                                                                    {meta.error}
                                                                </Form.Text>
                                                            }
                                                        </div>
                                                    )}
                                                </Field>
                                            </Form.Group>

                                            <Form.Group as={Col} xs={12} sm={12} md={3} lg={3} controlId="type">
                                                <Form.Label>Type <span className='required'>*</span></Form.Label>
                                                <Field name="type" type="text">
                                                    {({ input, meta }) => (
                                                        <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                                            { this.renderMessageTypes() }
                                                            { meta.error && meta.touched && 
                                                                <Form.Text>
                                                                    {meta.error}
                                                                </Form.Text>
                                                            }
                                                        </div>
                                                    )}
                                                </Field>
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Group controlId="shortDescription">
                                            <Form.Label>Short Description (preview text) <span className='required'>*</span></Form.Label>
                                            <Field name="shortDescription" type="text">
                                                {({ input, meta }) => (
                                                    <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                                        <Form.Control 
                                                            as="textarea" 
                                                            rows={2} 
                                                            maxLength={240} 
                                                            style={{ resize: 'none' }}                                                             
                                                            {...input} />

                                                        { meta.error && meta.touched && 
                                                            <Form.Text>
                                                                {meta.error}
                                                            </Form.Text>
                                                        }
                                                    </div>
                                                )}
                                            </Field>
                                        </Form.Group>

                                        <Form.Group controlId="htmlContent">
                                            <Form.Label>Body Content (optional)</Form.Label>

                                            <div id="header-photo-container">
                                                { !state.headerPhotoUrl &&                                                 
                                                    <Dropzone 
                                                        onDrop={() => this.onDrop() } 
                                                        onDropRejected={() => this.onDropReject() } 
                                                        accept="image/png,image/jpg,image/jpeg" 
                                                        noDrag 
                                                        preventDropOnDocument 
                                                        maxSize={7000000}>
                                                            {({ getRootProps, getInputProps }) => (
                                                                <div className='drop-zone-wrapper'>
                                                                    <div {...getRootProps()}>
                                                                        <input {...getInputProps({ className: 'dropzone' })} id="inputUploader" />
                                                                        <p>Add Header Photo<br /><small>(1008 x 380)</small></p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </Dropzone>
                                                } 

                                                { state.headerPhotoUrl && 
                                                    <div className='header-photo-preview'>
                                                        <img src={state.headerPhotoUrl} alt="Header Photo" />
                                                    </div>
                                                }
                                            </div>

                                            <div id="editor">
                                                <Editor 
                                                    apiKey='79m5f2r3opjcoakuwd3jcotwvuw7az9bmt0rcqaycn3o9yk9'
                                                    onInit={(evt, editor) => this.editorRef.current = editor }
                                                    initialValue={state.initialHtmlContent}
                                                    init={{
                                                        height: 500,
                                                        min_height: 500,
                                                        width: '100%',
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist',
                                                            'autolink',
                                                            'lists',
                                                            'link',
                                                            'image',
                                                            'charmap',
                                                            'preview',
                                                            'anchor',
                                                            'searchreplace',
                                                            'visualblocks',
                                                            'code',
                                                            'fullscreen',
                                                            'insertdatetime',
                                                            'media',
                                                            'table',
                                                            'code',
                                                            'help',
                                                            'wordcount',
                                                            'preview',
                                                            'emoticons'
                                                        ],
                                                        toolbar: 'undo redo | styles | ' +
                                                            'bold italic emoticons backcolor | alignleft aligncenter ' +
                                                            'alignright alignjustify | bullist numlist outdent indent | ' + 
                                                            'media image code | ' + 
                                                            'removeformat preview | help',
                                                        fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
                                                        toolbar_mode: 'floating',
                                                        block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3',
                                                        content_style: 'body { font-size: 15px }',
                                                        images_upload_handler: (blobInfo, progress) => this.handleImageUpload(blobInfo, progress)
                                                    }} />
                                            </div>
                                        </Form.Group>

                                        <Form.Row>
                                            <Form.Group as={Col} xs={12} sm={12} md={6} lg={6}>
                                                <Form.Label>Audience <span className='required'>*</span></Form.Label>
                                                <Field name="audienceType" type="text">
                                                    {({ input, meta }) => (
                                                        <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                                            { this.renderAudiences() }
                                                            { meta.error && meta.touched && 
                                                                <Form.Text>
                                                                    {meta.error}
                                                                </Form.Text>
                                                            }
                                                        </div>
                                                    )}
                                                </Field>
                                            </Form.Group>

                                            <Form.Group as={Col} xs={12} sm={12} md={6} lg={6}>
                                                <Form.Label>Date Published</Form.Label>
                                                <Field name="datePublished" type="date">
                                                {({ input, meta }) => (
                                                    <>
                                                        <div className={`input-wrapper ${meta.error && meta.touched ? 'is-invalid' : ''}`}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DateTimePicker
                                                                    disableFuture 
                                                                    value={state.datePublished}
                                                                    onChange={this.handleDateChange}
                                                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                                                />    
                                                            </LocalizationProvider>

                                                            { meta.error && meta.touched && 
                                                                <Form.Text>
                                                                    {meta.error}
                                                                </Form.Text>
                                                            }
                                                        </div>
                                                    </>
                                                )}
                                            </Field>
                                            </Form.Group>
                                        </Form.Row>
                                    </div>
                                </MessageBoardForm>
                            </Modal.Body>

                            <Modal.Footer>
                                <button type="submit">{ !state.isEditing ? <>Publish</> : <>Save Changes</>}</button>
                            </Modal.Footer>
                        </Form>
                    )
                }}
            />
        )
    }
}

function mapStateToProps(state) {
    const { 
        authentication, 
        msgHeaderPhoto, 
        saveMessageBoardItem, 
        editMessageBoardItem } = state

    return {
        authentication,
        msgHeaderPhoto,
        saveMessageBoardItem,
        editMessageBoardItem
    }
}

const connectedCreateMessageForm = connect(mapStateToProps)(CreateMessageForm)
export { connectedCreateMessageForm as CreateMessageForm }