import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { statisticActions } from '../../redux/actions'
import { subscriptionStatus } from '../../constants/subscriptionStatus'
import { TopTradesList } from './TopTradesList'
import { 
    isNull, 
    isNotNull, 
    onSetNewState, 
    setCoinLogo, 
    setDefaultImage } from '../../utils'

class TopWinners extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            dataList: null,
            isLoadingSubscriptionInfo: true,
            isWithinTrialPeriod: null,
            hasSubscription: null,
            status: null
        }

        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true

        const { dispatch } = this.props
        dispatch(statisticActions.getTopWinners())
    }

    componentDidUpdate() {
        if (this.mounted) {
            const { isLoading, isWithinTrialPeriod, isLoadingSubscriptionInfo, dataList } = this.state
            const { authentication, topTradeWinners, subscriptionDetails } = this.props

            if (isNull(isWithinTrialPeriod) && 
                isNotNull(authentication) && 
                isNotNull(authentication.user)) {
                    const { ...data } = authentication.user
                    onSetNewState(this, {
                        isWithinTrialPeriod: data.isWithinTrialPeriod
                    })
                }

            if (isLoading && 
                isNull(dataList) && 
                !topTradeWinners.loading) {
                    const { result } = topTradeWinners
                    onSetNewState(this, {
                        isLoading: false,
                        dataList: result
                    })
                }

            if (isNotNull(subscriptionDetails) && 
                !subscriptionDetails.loading && 
                isLoadingSubscriptionInfo) {
                    const { subscription } = subscriptionDetails
                    if (isNotNull(subscription)) {                        
                        onSetNewState(this, {
                            isLoadingSubscriptionInfo: false,
                            hasSubscription: true,
                            status: subscription.paymentStatus
                        })
                    } else {
                        onSetNewState(this, {
                            isLoadingSubscriptionInfo: false,
                            hasSubscription: false
                        })
                    }
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    renderTopTrades = () => {
        const { dataList } = this.state
        if (isNotNull(dataList)) {
            return _.map(dataList, (value, key) => {
                let coinLogo = null
                if (isNotNull(value.toSym))
                    coinLogo = setCoinLogo(value.toSym)

                    let duration = null
                    if (isNotNull(value.datePublished) && isNotNull(value.dateClosed)) {            
                        let dateStarted = moment.utc(moment(new Date(value.datePublished)).format('YYYY-MM-DD HH:mm:ss'))
                        let dateEnded = moment.utc(moment(new Date(value.dateClosed)).format('YYYY-MM-DD HH:mm:ss'))
                        duration = moment.duration(moment(dateEnded).diff(dateStarted)).humanize()
                    }
                    
                return (
                    <li key={key} className="closed-trades-list-item">
                        <div className="symbol-logo">
                            <img src={coinLogo} onError={setDefaultImage} alt="" />
                        </div>
                        <div className='trade-details'>
                            <span className='symbol'>{value.symbol}</span>
                            <span className='peak-gains-val'>+{value.peakGains}%</span>
                            <span className='duration'>in {duration}</span>
                        </div>
                    </li>
                )
            })
        }
    }

    render() {
        const { isWithinTrialPeriod, hasSubscription, status } = this.state
        let visibilityStyle = ''

        if (isNotNull(hasSubscription) && 
            isNotNull(status) && 
            hasSubscription && 
            !isWithinTrialPeriod && 
            (status === subscriptionStatus.ACTIVE || status === subscriptionStatus.CANCELLED || status === subscriptionStatus.PENDING_DEACTIVATION)) {
                visibilityStyle = 'full'
            }

        return (
            <TopTradesList>
                <div className='header'>
                    <h3>Recent Closed Trades</h3>
                </div>
                <ul className={`closed-trades-list ${visibilityStyle}`}>
                    {this.renderTopTrades()}
                </ul>
            </TopTradesList>
        )
    }
}

function mapStateToProps(state) {
    const { authentication, topTradeWinners, subscriptionDetails } = state
    return { 
        authentication, 
        topTradeWinners,
        subscriptionDetails
    }
}

const connectedTopWinners = connect(mapStateToProps)(TopWinners)
export { connectedTopWinners as TopWinners }