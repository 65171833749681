import { endpoints, baseUrl } from './CONSTANTS'
import { authServices } from './authService'
import { isNotNull } from '../utils'

const { v3 } = endpoints

function setLeverage(symbol, value) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${v3.terminal.setLeverage(symbol, value)}`, requestOptions)
        .then(handleResponse)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error))
}

function setMarginType(symbol, value) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${v3.terminal.setMarginType(symbol, value)}`, requestOptions)
        .then(handleResponse)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error))
}

function createWithLeverage(symbol, value, order) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${v3.terminal.setLeverage(symbol, value)}`, requestOptions)
        .then(handleResponse)
        .then(response => {

            if (isNotNull(response)) {

                if (response.success) {

                    const tradeOrderRequestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        credentials: 'include',
                        //headers: header.addAuthorizationHeader(),
                        body: JSON.stringify(order)
                    }

                    return fetch(`${baseUrl}${v3.user.trade.create}`, tradeOrderRequestOptions)
                        .then(handleResponse)
                        .then(response => Promise.resolve(response))
                        .catch(error => Promise.reject(error))
                } else {
                    return Promise.resolve(response)
                }
            }
        })
        .catch(error => Promise.reject(error))
}

function create(order) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        //headers: header.addAuthorizationHeader(),
        body: JSON.stringify(order)
    }
    return fetch(`${baseUrl}${v3.user.trade.create}`, requestOptions)
        .then(handleResponse)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error))
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        if (res.status === 401)
            authServices.logout()
        else
            return data
    })
}

export const tradeOrderServices = {
    createWithLeverage,
    setLeverage,
    setMarginType,
    create
}