import { PAscannerActionTypes, RSIscannerActionTypes } from '../CONSTANTS'

export function priceActions(state = {}, action) {
    switch (action.type) {
        case PAscannerActionTypes.GET_ALL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case PAscannerActionTypes.GET_ALL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.price_actions,
                combo: false
            }
        case PAscannerActionTypes.GET_ALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function watchLists(state = {}, action) {
    switch (action.type) {
        case PAscannerActionTypes.GET_WATCHLISTS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case PAscannerActionTypes.GET_WATCHLISTS_SUCCESS:
            return {
                ...state,
                loading: false,
                watch_lists: action.watch_lists
            }
        case PAscannerActionTypes.GET_WATCHLISTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function saveWatchlist(state = {}, action) {
    switch (action.type) {
        case PAscannerActionTypes.SAVE_WATCHLIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case PAscannerActionTypes.SAVE_WATCHLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                isWatched: action.response.success,
                combo: false
            }
        case PAscannerActionTypes.SAVE_WATCHLIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function tradeGuide(state = {}, action) {
    switch (action.type) {
        case PAscannerActionTypes.GET_TRADEGUIDE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case PAscannerActionTypes.GET_TRADEGUIDE_SUCCESS:
            return {
                ...state,
                loading: false,
                trade_guide: action.response,
                combo: false
            }
        case PAscannerActionTypes.GET_TRADEGUIDE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function relativeStrengthAllItems(state = {}, action) {
    switch (action.type) {
        case RSIscannerActionTypes.GET_ALLITEMS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case RSIscannerActionTypes.GET_ALLITEMS_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.get_relative_strengths
            }
        case RSIscannerActionTypes.GET_ALLITEMS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case RSIscannerActionTypes.GET_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.relative_strengths,
            }
        default:
            return state
    }
}


