import React from 'react'
import { ProEmpowersSectionStyle } from './styles'

export const ProEmpowerSection = () => {
    return(
        <ProEmpowersSectionStyle>
            <div className='container'>
                <h2 className='section-title'>HOW PROFITFARMERS PRO EMPOWERS YOU TO 10X YOUR ACCOUNT OR MORE</h2>
                
                <ul className='pro-empower'>
                    <li className='pro-empower-item'>
                        <div className='description'>
                            <h6>5X THE SIGNALS</h6>
                            <p>Potentially hundreds more signals per month. 300+ is not uncommon during bull runs and breakouts.</p>
                        </div>
                        <div className='thumbnail item-1'>
                            <img src='/images/trial-offer/5x-signals.png' alt='ProfitFarmers Bonus Pack Item 1' />
                        </div>
                    </li>
                    <li className='pro-empower-item'>
                        <div className='description'>
                            <h6>ALL 4 TAKE PROFIT TARGETS</h6>
                            <p>T2 is the unsung hero that you’re going to be best friends with. Breakout from the lower Risk:Reward plays and strike gold ~50% of the time on each completed signal. In March 2022, Target 2 even offered a face melting +49% gain per trade. And that’s only T2. Think about what T3 and T4 might bring.</p>
                        </div>
                        <div className='thumbnail item-2'>
                            <img src='/images/trial-offer/take-profit-img.png' alt='ProfitFarmers Bonus Pack Item 2' />
                        </div>
                    </li>
                    <li className='pro-empower-item'>
                        <div className='description'>
                            <h6>DRAMATICALLY IMPROVED RISK:REWARD</h6>
                            <p>Unlock all the highest average gains at T3 (+31%) and T4 (+57%) and at least 6x your reward profile without increasing risk. Almost 20% of our completed signals go to T4. If you get 100 more signals a month, that’s 20 high-powered signals alone. 200 more? That’s 40 MORE signals with average gains of +57% at T4. Are you seeing the power yet?</p>
                        </div>
                        <div className='thumbnail item-3'>
                            <img src='/images/trial-offer/empowers-risk-reward.png' alt='ProfitFarmers Bonus Pack Item 3' />
                        </div>
                    </li>
                    <li className='pro-empower-item'>
                        <div className='description'>
                            <h6>THE BREAK-EVEN STOP-LOSS</h6>
                            <p>Add the #1 most powerful feature to your arsenal, and truly become unstoppable. Break-Even Stop-Loss removes all emotion, limits your losses without limiting profits, and saves you hours of time and unlimited stress. You’ll never trade scared again.</p>
                        </div>
                        <div className='thumbnail item-4'>
                            <img src='/images/trial-offer/empowers-breakeven.png' alt='ProfitFarmers Bonus Pack Item 4' />
                        </div>
                    </li>
                    <li className='pro-empower-item'>
                        <div className='description'>
                            <h6>THE PRO TRADER 5X BLUEPRINT</h6>
                            <p>Learn how to 5x your account the right way! Enjoy a low pressure training schedule, one 60-120min lesson every 2 days for 2 weeks, and you’ll have time to spare. Go faster if you like!</p>
                        </div>
                        <div className='thumbnail item-5'>
                            <img src='/images/trial-offer/pro-empower-blueprint.png' alt='ProfitFarmers Bonus Pack Item 5' />
                        </div>
                    </li>
                </ul>
            </div>
        </ProEmpowersSectionStyle>
    )
}