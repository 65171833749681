import { endpoints, baseUrl } from './CONSTANTS'
import { authServices } from './authService'

const { v3 } = endpoints

async function sendRequest(url, options) {
    try {
      const response = await fetch(url, options);
      return handleResponse(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

function getReminderInfo() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    return sendRequest(`${baseUrl}${v3.system.reminders.getInfo}`, requestOpts);
}

function updateReminderInfo(data) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(data)
    }

    return sendRequest(`${baseUrl}${v3.system.reminders.save}`, requestOpts);
}

async function handleResponse(res) {
    const text = await res.text();
    const data = text && JSON.parse(text);

    if (res.status === 401) {
        authServices.logout();
    } else {
        return data;
    }
}

export const reminderServices = {
    getReminderInfo,
    updateReminderInfo
}