import styled from 'styled-components'

export const NotificationTime = styled.small`
    color: ${props => props.unRead === false ? '#2E89FF' : '#ced0dd'};
    width: 100%;
`;

export const NotificationContent = styled.p`
    margin-bottom: 0;
`;

export const NotificationTitle = styled.h6`
    padding-bottom: 5px;
    margin-bottom: 0;
`;

export const NotificationContentContainer = styled.div`
    width: calc(100% - 67px);

    @media screen and (min-width: 320px) and (max-width: 766px) {
        font-size: 13px;
    }

    ${NotificationTitle},
    ${NotificationContent},
    ${NotificationTime} {
        pointer-events: none;
        width: 100%;
    }

    ${NotificationTitle},
    ${NotificationContent} {
        
    }
`;

export const OrderStateIcon = styled.span`
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 10px;
    box-shadow: 0 2px 5px 1px rgba(0,0,0,0.3);
    pointer-events: none;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    line-height: 32px;
    font-size: 18px;
    
    &.success {
        background-color: rgba(22, 280, 208, 0.1);
        color: #16B46C;
        border: 1px solid #16B46C;
    }

    &.failed {
        background-color: rgba(153, 57, 86, 0.1);
        color: #993956;
        border: 1px solid #993956;
    }

    &.automation {
        background-color: rgba(241, 189, 108, 0.1);
        color: #f1bd6c;
        border: 1px solid #f1bd6c;
    }
`;

export const CoinsImg = styled.img`
    width: 32px;
    height: 32px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-size: 99%;
    border-radius: 50%;
    background-color: #283544;
    box-shadow: 0 2px 5px 1px rgba(0,0,0,0.3);
    pointer-events: none;

    @media screen and (min-width: 320px) and (max-width: 766px) {
        width: 28px;
        height: 28px;
    }
`;

export const UnReadBadge = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: ${props => props.unRead === false ? '#2E89FF' : 'transparent'};
    margin-left: 10px;
`;

export const NotificationCard = styled.div`
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    // border-bottom: 1px solid #383e4e;
    cursor: pointer;
    padding: 10px 15px;
    background-color: ${props => props.unRead === false ? '#293442' : '#19212b'};

    &.all {
        background-color: ${props => props.unRead === false ? '#32455f !important' : '#172331 !important'};

        &:hover {
            background-color: #32455f !important;
        }

        ${NotificationContentContainer} {
            ${NotificationTime} {
                font-weight: ${props => props.unRead === false ? '600' : '400'};
            }
        }
    }

    &:hover {
        background-color: #293442;
        opacity: 0.9;
    }

    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
    }
`;

export const NotificationContentWrapper = styled.li`
    display: flex;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #131920;

    &:last-child {
        padding-bottom: 8px;
        border-bottom: none;
    }
`;

export const NotificationContentLists = styled.ul`
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    overflow-y: auto;
    height: 65vh;

    &.update { 
        height: 50vh;
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #94a7c1;
        outline: 1px solid #383e4e;
        border-radius: 10px;
    }
`;

export const NotificationButton = styled.button`
    position: relative;
    font-size: 20px;
    border: none;
    width: 40px;
    text-align: center;
    height: 40px;

    &:focus,
    &:visited,
    &:hover {
        box-shadow: none;
        border: none;
        outline: none;
        color: #ced0dd !important;
    }

    svg {
        pointer-events: none;
    }
`;


export const LoaderWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 70px;
    padding: 0 10px;
    margin-bottom: 10px;

    &:first-child {
        margin-top: 10px;
    }

    &:last-child {
        border-bottom: 1px solid transparent;
    }
`;

export const NotificationPageBody = styled.div`

`

export const NotificationPageHeader = styled.div`
    border-bottom: 1px solid #2d3948;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0;
    padding-bottom: 5px;
    margin-bottom: 10px;

    h3,
    a {
        font-size: 1.3rem;
        color: #ced0dd;
        margin-bottom: 0;
    }

    a {
        font-weight: 400;
        font-size: 0.9rem;

        &:hover {
            color: #ced0dd;
        }
    }

    @media screen and (min-width: 320px) and (max-width: 766px) {
        h3 {
            font-size: 16px;
        }
        a {
            font-size: 14px;
        }
    }
`

export const NotificationPageContainer = styled.div`
    max-width: 960px;
    padding: 40px 30px;
    margin: 0 auto;

    @media screen and (min-width: 320px) and (max-width: 766px) {
        padding: 40px 20px;
    }

    @media screen and (min-width: 767px) and (max-width: 1023px) {
        max-width: 660px;
    }

    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        max-width: 700px;
    }

    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        max-width: 750px;
    }

    @media screen and (min-width: 1441px) and (max-width: 1749px) {
        max-width: 850px;
    }

    @media screen and (min-width: 1750px) and (max-width: 3000px) {
    }
`

export const NotificaitonPageWrapper = styled.div`
    .notification-page-list {
        height: 100%;
    }
`