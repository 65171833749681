import glamorous from 'glamorous'

export const AccountMenu = glamorous.ul({
    margin: 0,
    padding: 0,
    listStyleType: 'none',

    '& li': {
        position: 'relative',
        display: 'block',
        cursor: 'pointer',

        '& a': {
            display: 'block',
            textDecoration: 'none',
            padding: '13px 20px',

            '& .icon': {
                display: 'inline-block',
                width: '30px',
                textAlign: 'center',
                marginRight: '7px',

                '& svg': {
                    color: '#617184',
                    fontSize: '18px',
                    verticalAlign: '-4px'
                }
            },
            
            '& .text': {
                color: '#D9E3EE'
            }
        },
        
        '& button': {            
            display: 'block',
            width: '100%',
            height: 'auto',
            padding: '13px 20px',
            backgroundColor: 'transparent',
            textAlign: 'left',
            boxShadow: 'none',

            '& .icon': {
                display: 'inline-block',
                width: '30px',
                textAlign: 'center',
                marginRight: '7px',

                '& svg': {
                    color: '#617184',
                    fontSize: '18px',
                    verticalAlign: '-4px'
                }
            },
            
            '& .text': {
                color: '#D9E3EE'
            }
        },

        '&:last-child': {
            borderBottom: 'none',
            borderBottomLeftRadius: '9px',
            borderBottomRightRadius: '9px'
        },

        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.3)'
        }
    }
})