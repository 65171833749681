import styled from 'styled-components'

export const TradeNowBtnStyle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 15px;

	.trade-now-btn {
		background-color: #169E63 !important;
		border: 2px solid #23d381;
      	font-size: 18px;
      	text-transform: uppercase;
      	font-weight: 500;
      	border-radius: 24.5px; //2px;
  		width: auto; //263px;
      	padding: 10px 25px; //10px;
	  	cursor: pointer;
	    letter-spacing: 1px;
		transition: all 0.5s ease 0s;

	  	&:disabled {
			&:hover {
				background-color: transparent !important;
			}
	 	}

	  	i {
			margin-right: 6px;
	  	}

	  	&:hover {
			transform: scale(1.05);
		}

	  	&:focus,
	  	&:active {
			background-color: #169E63 !important;
	  	}

	  	&:focus {
		  	box-shadow: 0 0 0 2px rgb(69, 191, 46);
	  	}
	}
`