import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const ProEmpowersSectionStyle = styled.section`
    position: relative;
    padding: 40px 0;
    width: 100%;
    display: block;
    background-color: #0B1219;

    .container {
        .section-title  {
            font-weight: 600;
            color: #FFFFFF;
            text-align: center;
            font-size: 22px;
        }

        .pro-empower {
            margin: 0 auto;
            padding: 0%;
            list-style-type: none;

            .pro-empower-item {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                justify-content: space-between;
                margin: 25px 0 15px;
                position: relative;

                .thumbnail {
                    position: unset;
                    margin: 0 auto 25px;
                    right: 0;

                    &.item-4 {
                        img {
                            border: 1px solid #475D74;
                        }
                    }

                    img {
                        width: 100%;
                        position: relative;
                        
                    }
                }

                .description {
                    h6 {
                        font-weight: 500;
                        color: #FFFFFF;
                        font-size: 19px;
                    }

                    p {
                        color: #FFFFFF;
                        text-align: justify;
                        font-family: 'Noto Sans';
                        font-weight: 300;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    ${mediaQueries.mediumDevices} {
        .container {
            .section-title {
                margin-bottom: 45px;
                font-size: 30px;
            }

            .pro-empower {
                .pro-empower-item {
                    flex-direction: row-reverse;
                    margin: 25px 0 30px;

                    .description {
                        width: calc(100% - 200px);

                        h6 {
                            font-size: 22px;
                        }

                        p {
                            text-align: left;
                        }
                    }

                    .thumbnail {
                        margin-bottom: 0;
                        text-align: center;

                        img {
                            width: 80%;
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        padding: 60px 0;

        .container {
            .section-title {
                font-size: 40px;
                font-weight: 800;
            }

            .pro-empower {
                .pro-empower-item {
                    .description {
                        width: calc(100% - 450px);

                        h6 {
                            font-size: 25px;
                            font-weight: 600;
                        }

                        p {
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        .container {
            .section-title {
                width: 1000px;
                margin-left: auto;
                margin-right: auto;
            }

            .pro-empower {
                max-width: 1000px;

                .pro-empower-item {
                    margin-bottom: 40px;

                    .description {
                        h6 {
                            font-size: 26px;
                        }

                        p {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
`