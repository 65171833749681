import { mediaDeviceSizes } from '../constants/mediaQueries'

export const getSize = (windowWidth) => {
    if (windowWidth <= 600) 
        return mediaDeviceSizes.xs
    else if (windowWidth > 600 && windowWidth < 767) 
        return mediaDeviceSizes.sm
    else if (windowWidth >= 768 && windowWidth < 992) 
        return mediaDeviceSizes.md
    else if (windowWidth >= 992 && windowWidth < 1200)
        return mediaDeviceSizes.lg
    else if (windowWidth >= 1200 && windowWidth < 1500)
        return mediaDeviceSizes.xl
    else
        return mediaDeviceSizes.xxl
}