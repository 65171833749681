import glamorous from 'glamorous'
import { isNotNull } from '../../utils'

const getWidth = (desiredSize) => {
    switch(desiredSize) {
        default: return '100%'
        case 'auto': return 'auto'
        case 'sm': return '100px'
    }
}

export const TextButton = glamorous.button({
    background: 'none',
    border: 'none',
    outline: 'none',
    fontWeight: 600,
    transition: 'all ease .3s',
    minHeight: '51px',

    '&:hover ': {
        transform: 'scale(1.05)'
    }
}, ({ size, textColor }) => ({
    color: isNotNull(textColor) ? textColor: '#fff',
    width: getWidth(size)
}))