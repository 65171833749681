import glamorous from 'glamorous'
import { mediaQueries } from '../../constants/mediaQueries'

export const OnboardingLayout = glamorous.main({
    position: 'relative',
    fontFamily: 'Montserrat, sans-serif',
    
    '& .container': {
        maxWidth: '100%'
    },

    '& .content-wrapper': {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: 0
    },

    '& form': {
        width: '100%',
        paddingBottom: '80px'
    },

    [mediaQueries.extraSmallDevices]: {
        '& .content-wrapper': {
            paddingBottom: '100px'
        }
    },

    [mediaQueries.mediumDevices]: {
        '& .content-wrapper': {
            paddingBottom: '100px'
        }
    },

    [mediaQueries.largeDevices]: {
        '& .content-wrapper': {
            padding: 0
        }
    },

    [mediaQueries.extraLargeDevices]: {
        '& .container': {
            maxWidth: '1100px'
        }
    }
})