import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Line } from "react-chartjs-2"
import { MonthlyPeakGainsWrapper } from './MonthlyPeakGainsWrapper'
import { statisticActions } from '../../redux/actions'
import { isNull, isNotNull, onSetNewState } from '../../utils'

class MonthlyPeakGains extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            monthlyPeakGainsData: null,
            
        }

        this.mounted = false
        this.monthlyChart = null
    }

    componentDidMount() {
        this.mounted = true

        const { dispatch } = this.props
        dispatch(statisticActions.getMonthlyPeakGains())
    }

    componentDidUpdate() {
        if (this.mounted) {
            const { monthlyNetPeakGains } = this.props
            const { isLoading, monthlyPeakGainsData } = this.state

            if (isLoading && 
                isNull(monthlyPeakGainsData) && 
                !monthlyNetPeakGains.loading) {
                    const { result } = monthlyNetPeakGains
                    onSetNewState(this, {
                        isLoading: false,
                        monthlyPeakGainsData: result.data
                    })
            }
        }   
    }

    componentWillUnmount() {
        this.mounted = false
    }

    renderChartData = (canvas) => {
        const { monthlyPeakGainsData } = this.state
        const ctx = canvas.getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
        gradient.addColorStop(0, 'rgb(53, 197, 135, 0.5)');
        gradient.addColorStop(0.5, 'rgb(53, 197, 135, 0.25)');
        gradient.addColorStop(1, 'rgb(53, 197, 135, 0)');

        let chartLabels = []
        let chartDataSet = []

        if (isNotNull(monthlyPeakGainsData)) {
            // _.forOwn(monthlyPeakGainsData, function(value, key) {
            //     chartLabels.push(key)
            //     chartDataSet.push(value.netPeakGains)
            // })

            _.forEach(monthlyPeakGainsData, (d, index) => {
                chartLabels.push(d.key)
                chartDataSet.push(d.value.netPeakGains)
            })
        }

        return {
            labels: chartLabels,
            datasets: [
                {
                    fill: true,
                    backgroundColor: gradient, // Put the gradient here as a fill color
                    borderColor: '#149158',
                    data: chartDataSet,
                    lineTension: 0.3,
                    pointBackgroundColor: '#27D194'
                }
            ]
        }
    }

    render() {
        const { monthlyPeakGainsData } = this.state
        //const { chartOptions } = this.state
        const chartOptions = {
            title: {
                display: true,
                position: 'left',
                text: 'Net Peak Gains',
                fontColor: '#fff'
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [
                    {
                        display: true,
                        ticks: {
                            beginAtZero: false,
                            display: true,
                            fontColor: '#8A9BAE'
                        },
                        gridLines: {
                            display: true
                        }
                    }
                ],
                xAxes: [
                    {
                        display: true,
                        ticks: {
                            display: true,
                            fontColor: '#8A9BAE'
                        },
                        gridLines: {
                            display: false
                        }
                    }
                ]
            },
            legend: {
                display: false
            },
            elements: {
                point: {
                    radius: 4
                }
            },
            tooltips: {
                enabled: false,
                caretX: 5,
                custom: function(tooltipModel) {
                    // Tooltip Element
                    var tooltipEl = document.getElementById('monthly-peak-gains-tooltip');
    
                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'monthly-peak-gains-tooltip';
                        tooltipEl.innerHTML = '<table></table>';
                        document.body.appendChild(tooltipEl);
                    }
    
                    // Hide if no tooltip
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }
    
                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }
                    
                    function getSummary(key) {
                        if (isNotNull(monthlyPeakGainsData)) {
                            let item = _.find(monthlyPeakGainsData, (val, i) => {
                                return val.key === key
                            })
                            return item.value
                        }
                    }

                    function formatText(val) {
                        let newValue = Math.round(val * 100) / 100
                        if (parseFloat(newValue) > 0)
                            return '<td class="percentage-value increase">+' + newValue + '%</td>'
                        else
                            return '<td class="percentage-value decrease">' + newValue + '%</td>'
                    }

                    let monthlyResultData = getSummary(tooltipModel.dataPoints[0].xLabel)
    
                    // Set Text
                    if (tooltipModel.body) {    
                        var innerHtml = '<thead>';
                            innerHtml += '<tr><th colspan="3" class="tooltip-title">' + monthlyResultData.timestamp + ' Trade Summary</th></tr>';
                            innerHtml += '</thead><tbody>';
                            innerHtml += '<tr><td>Winners: </td><td class="trades-counter">' + monthlyResultData.totalWinners + '</td>' + formatText(monthlyResultData.percentageGains) + '</tr>';
                            innerHtml += '<tr><td>Losers: </td><td class="trades-counter">' + monthlyResultData.totalLosers + '</td>' + formatText(monthlyResultData.percentageLoss) + '</tr>';
                            innerHtml += '<tr><td>Early Closed: </td><td class="trades-counter">' + monthlyResultData.totalEarlyClosedTrades + '</td>' + formatText(monthlyResultData.percentageEarlyClosedTradeResult) + '</tr>';
                            innerHtml += '<tr><td>NET Peak Gains: </td><td class="trades-counter"></td>' + formatText(monthlyResultData.netPeakGains) + '</tr>';
                        innerHtml += '</tbody>';
    
                        var tableRoot = tooltipEl.querySelector('table');
                        tableRoot.innerHTML = innerHtml;
                    }
    
                    // `this` will be the overall tooltip
                    var position = this._chart.canvas.getBoundingClientRect();
    
                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.position = 'absolute';

                    let windowWidth = window.outerWidth
                    if (windowWidth <= 600) {                        
                        let totalSpan = tooltipModel.caretX + 328
                        if (totalSpan >= windowWidth) {
                            let excess = totalSpan - windowWidth
                            tooltipEl.style.left = position.left + window.pageXOffset + (tooltipModel.caretX - excess) + 'px';
                        } else {
                            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                        }
                    } else {
                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                    }
                    //tooltipEl.style.left = position.left + window.pageXOffset + (window.innerWidth - tooltipModel.caretX) + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                    tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                }
            }
        }

        return (
            <MonthlyPeakGainsWrapper>
                <div className='monthly-peak-gains-graph'>
                    <Line
                        ref={reference => (this.monthlyChart = reference)} 
                        data={this.renderChartData}
                        options={chartOptions}
                        height={240} />
                </div>
            </MonthlyPeakGainsWrapper>
        )
    }
}

function mapStateToProps(state) {
    const { authentication, monthlyNetPeakGains } = state
    return {
        authentication,
        monthlyNetPeakGains
    }
}

const connectedMonthlyPeakGains = connect(mapStateToProps)(MonthlyPeakGains)
export { connectedMonthlyPeakGains as MonthlyPeakGains }