const buttonColors = {
    primary: '#15bd6f',
    secondary: '475973'
}

const borderColors = {
    primary: '#61B755'
}

const textColors = {
    primary: '#fff'
}

export {
    buttonColors,
    borderColors,
    textColors
}
