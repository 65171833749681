import CryptoJS from 'crypto-js'
import { isNull } from './object'
const SECRETKEY = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCSKEYS)
const IV = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCIVKEYS)

export const encryptText = (plainText) => {
    if (isNull(plainText))
        return null

    return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plainText), SECRETKEY, {
        keySize: 128 / 8,
        iv: IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    }).toString()
}

export const decryptText = (plainText) => {
    if (isNull(plainText))
        return null
        
    return CryptoJS.AES.decrypt(plainText, SECRETKEY, {
        keySize: 128 / 8,
        iv: IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8)
}