import { GambleYouNeedToTakeSectionStyle } from './styles'

export const GambleYouNeedToTakeSection = () => {
    return (
        <GambleYouNeedToTakeSectionStyle>
            <div className='container'>
                <h2 className='section-title'>
                    <span className='text'>Govern Me Harder Daddy' & WIN-WIN Gamble You NEED To Take</span>
                    <span className='img'>
                        <img src='/images/layout/govern-me-harder-daddy.png' alt="Govern Me Harder Daddy' & WIN-WIN Gamble You NEED To Take" />
                    </span>
                </h2>

                <p>If you thought COVID, Ukraine vs Russia and the 'energy crisis' were the big issues of recent times then we've got news for you. They are simply smaller parts of the overall 'Govern Me Harder, Daddy' agenda being pushed by The World Economic Forum (WEF) and their political cronies.</p>
                <p><strong>Central Bank Digital Currencies (CBDC's)</strong> and the impending 'Great Reset' are where you should be focusing your attention, and this war between 'normal' people and the so called 'elites' will be reaching a cresendo before the close of this decade as part of the United Nations 2030 agenda.</p>
                <p>The fiat economy (that's the current cash system that failed absolutely everyone other than the top 0.1%) is crumbling and the 'elites' would love to convince everyone to move a new digital version in order to 'save the world'.</p>

                <div className='cbdc-for'>
                    <h4>CBDC's are effectively programmable, digital money that allows for:</h4>
                    <ul>
                        <li>Social credit scoring to be tied to your income</li>
                        <li>Money can only be spent on WHAT they say</li>
                        <li>Money can only be spent on WHERE they say</li>
                        <li>Money can only be spent on WHEN they say</li>
                        <li>Money can have a time limit and then *poof*...it's gone</li>
                        <li>Negative interest rates (watch your savings disappear if you don't spend!)</li>
                        <li>Total surveillance of transactions</li>
                        <li>Total Dystopia</li>
                    </ul>
                </div>

                <p>Essentially, CBDC's are a mechanism for pure totalitarian control and, as it stands,</p>
                <h5>There is only one alternative.</h5>

                <div className='centered-img-wrapper'>
                    <img src='/images/layout/btc-boat.png' alt='The Alternative' />
                </div>

                <h5>Scary? Yes.</h5>
                <p>But there is also a mind blogging opportunity here for those that can see through the smoke and mirrors.</p>
                <p>Entering and staying in the crypto markets is your way of voting for, and supporting freedom. Of course, when push comes to shove, people aren't going to take CBDC's willingly, there is a huge chance that cryptocurrency will win the day, at least in western civilisation.</p>
                <p>But only after the masses have really felt the fires beneath their feet will they begin to migrate to Bitcoin at wholesale speed.</p>

                <h4>Why shouldn't you profit from seeing this inevitability before the masses?</h4>
                <h6>Think it through...</h6>

                <div className='centered-img-wrapper'>
                    <img src='/images/layout/boxing-ring.png' alt='Bitcoin vs CBDC' />
                </div>

                <div className='possible-outcomes'>
                    <div className='outcome outcome-1'>
                        <label className='label btc'>Outcome 1:</label>
                        <h4>Bitcoin Is Victorious</h4>
                        <p>If Bitcoin (and other useful cryptocurrencies) win the fight, you get to keep your freedom AND you'll have made a fortune from crypto investing and trading along the way. You'll be YEARS ahead of the competition.</p>
                    </div>
                    <div className='outcome outcome-2'>
                        <label className='label btc'>Outcome 2:</label>
                        <h4>CBDC's Crush Liberty</h4>
                        <p>If CBDC's win, according to The World Economic Forum, you'll <strong>"own nothing and you'll be happy"</strong> anyway... neo-communism will untimately mean 99% of people are put on an equal footing in terms of wealth and access to services.</p>
                    </div>
                </div>

                <div className='text-with-img'>
                    <div className='img'>
                        <img src='/images/layout/happy-person.png' alt='' />
                    </div>
                    <div className='text'>
                        <p>'The Greatest Reset' aims to drain your accounts dry and make you desperate enough to accept the CBDC solution.</p>
                        <p>Now perhaps you understand why businesses were unfairly forced to close down during the pandemic. Why nuclear plants have slowly been shut down in the lead up to the sudden 'energy crisis'. And so on and so on...</p>
                        <p>Why leave your fate in the hands of people that clearly have no interest in your well being? Take control of your life NOW!</p>
                    </div>
                </div>
            </div>
        </GambleYouNeedToTakeSectionStyle>
    )
}