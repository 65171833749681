import React, { useState } from 'react'
import { Collapse, Card } from 'react-bootstrap'
import { FaqSectionStyle } from '../styles'

export const RetFaq = () => {
    const [isLockedToContract, setIsLockedToContract] = useState(false)
    const onToggleIsLockedToContract = () => setIsLockedToContract(!isLockedToContract)

    const [howLong, setHowLong] = useState(false)
    const onToggleHowLong = () => setHowLong(!howLong)

    return (
        <FaqSectionStyle>
            <div className='container'>
                <h2 className='section-title'>Frequently Asked Questions:</h2>

                <Card>
                    <Card.Header onClick={() => onToggleIsLockedToContract()}>
                        <span>Am I locked into a contract?</span>
                        <span
                            className="toggle-icon"
                            aria-controls="is_locked_into_a_contract"
                            aria-expanded={isLockedToContract}>
                            {
                                isLockedToContract
                                    ? <small>-</small>
                                    : <small>+</small>
                            }
                        </span>
                    </Card.Header>

                    <Collapse in={isLockedToContract}>
                        <Card.Body>
                            <p>No, we promise that you can easily cancel anytime before the end of your trial within the app or by messaging our support team. If you cancel before your trial period expires you will not be charged for a monthly plan.</p>
                        </Card.Body>
                    </Collapse>
                </Card>
                
                <Card>
                    <Card.Header onClick={() => onToggleHowLong()}>
                        <span>For how long is this Promo available?</span>
                        <span
                            className="toggle-icon"
                            aria-controls="how_long_is_this_promo_available"
                            aria-expanded={howLong}>
                            {
                                howLong
                                    ? <small>-</small>
                                    : <small>+</small>
                            }
                        </span>
                    </Card.Header>

                    <Collapse in={howLong}>
                        <Card.Body>
                            <p>This is a <strong>LAST CHANCE</strong> offer for returning PRO's. This offer will not be possible to obtain once the timer runs out! Act NOW or you will miss it!</p>
                        </Card.Body>
                    </Collapse>
                </Card>
            </div> 
        </FaqSectionStyle>
    )
}