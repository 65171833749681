import styled from 'styled-components'
import { mediaQueries } from '../../constants/mediaQueries'

export const InnerSidebar = styled.aside`
    position: fixed;
    left: 256px;
    top: 92px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100%;
    background-color: #0c141e;
    padding: 25px 12px;

    .inn-sidebar-menu-list {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-left: 12px;
        padding-top: 10px;
        padding-bottom: 15px;
        list-style-type: none;
        margin: 0;

        .inn-sidebar-menu-item {
            margin-bottom: 5px;
            padding: 5px 0 0;

            .menu-item-link {
                display: flex;
                padding: 10px 10px 10px 0;
                text-decoration: none;

                .menu-item-link-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #495B70;
                    width: 44px;
                    transition: all ease .2s;
                    transition-property: color;
                    font-size: 15px;
                }

                .menu-item-link-text {
                    display: flex;
                    text-align: left;
                    color: #879aaf;
                    font-size: 14px;
                    letter-spacing: 1px;
                    width: calc(100% - 44px);
                }

                &:hover {
                    .menu-item-link-icon,
                    .menu-item-link-text {
                        color: #fff;
                    }
                }

                &.active {
                    .menu-item-link-icon,
                    .menu-item-link-text {
                        color: #13b078;
                    }
                }
            }
        }
    }
`