import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { isNotNull, isNull, onSetNewState } from '../../utils'

class StatisticsMenuLink extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isPremiumAccess: null
        }

        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true

        const { isLoading, isPremiumAccess } = this.state
        const { authentication } = this.props
        const { user } = authentication
        if (isLoading && 
            isNull(isPremiumAccess) && 
            isNotNull(user)) {
                onSetNewState(this, {
                    isPremiumAccess: user.isPaid
                })
            }
    }

    componentDidUpdate() {
        if (this.mounted) {
            const { isLoading, isPremiumAccess } = this.state
            const { authentication } = this.props
            const { user } = authentication
            
            if (isLoading && 
                isNull(isPremiumAccess) && 
                isNotNull(user)) {
                onSetNewState(this, {
                    isPremiumAccess: user.isPaid
                })
            }
            
            if (isLoading && isNotNull(isPremiumAccess)) {
                onSetNewState(this, {
                    isLoading: false
                })
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    render() {
        const { isLoading, isPremiumAccess } = this.state
        if (!isLoading && 
            isNotNull(isPremiumAccess)) {
            return (
                <li className='sidebar-submenu-item'>
                    <NavLink to={ isPremiumAccess ? '/trade/statistics' : '/subscription-plans/pro/subscribe' } className="sidebar-submenu-item-link" activeClassName={ isPremiumAccess ? "active" : "" }>
                        <span className='submenu-item-link-icon'><i className='fas fa-percent'></i></span>
                        <span className='submenu-item-link-text'>PF Statistics { !isPremiumAccess && <small className='pro-feature'>PRO</small>}</span>
                    </NavLink>                                
                </li>  
            )
        }

        return (<></>)
    }
}

function mapStateToProps(state) {
    const { authentication } = state
    return {
        authentication
    }
}

const connectedStatisticsMenuLink = connect(mapStateToProps)(StatisticsMenuLink)
export { connectedStatisticsMenuLink as StatisticsMenuLink }