import { baseUrl, endpoints } from './CONSTANTS'
import { authServices } from './authService'

function getSignalStates() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    //return fetch(`${baseUrl}${endpoints.signals.getStates}`, requestOpts)
    return fetch(`${baseUrl}${endpoints.v3.system.states}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

function getSignals(state, exchange, resultTypes, page, take) {
    let data = {
        state,
        page,
        filter: exchange,
        resultTypes
    }

    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(data)
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${endpoints.v3.system.signals}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

function getFollowedSignals() {
    const requestOpts = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    //return fetch(`${baseUrl}${endpoints.signals.getFollowedSignals}`, requestOpts)
    return fetch(`${baseUrl}${endpoints.v3.user.watchlistedSignals}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

function followSignal(signalId) {
    const requestOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
        //headers: header.addAuthorizationHeader()
    }

    return fetch(`${baseUrl}${endpoints.v3.user.addToOrRemoveFromWatchlist(signalId)}`, requestOpts)
        .then(handleResponse)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

function handleResponse(res) {
    return res.text().then(text => {
        const data = text && JSON.parse(text)

        if (res.status === 401)
            authServices.logout()
        else
            return data
    })
}

export const signalServices = {
    getSignalStates,
    getSignals,
    getFollowedSignals,
    followSignal
}