import styled from 'styled-components'
import { mediaQueries } from '../../../../constants/mediaQueries'

export const PowerPFSectionStyle = styled.section`
    position: relative;
    display: block;
    width: 100%;
    background-color: #1C2A3A;
    padding: 40px 0;
    background-image: url(/images/trial-offer/power-pf-bg.png);
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;

    .container {
        .text-with-img {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .text-col {
                width: 100%;

                .section-title {
                    font-weight: 800;
                    color: #FFFFFF;
                    text-align: center;
                    font-size: 30px;
                    margin-bottom: 20px;

                    .gradient-text {
                        background: -webkit-linear-gradient(180deg,#15BD6F, #3B96E8);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-weight: 900;
                    }
                }

                .white-text {
                    color: #FFFFFF;
                }
            }

            .video-wrapper {
                width: 100%;

                > div {
                    height: 217px !important;
                }
            }
        }
    }
    
    ${mediaQueries.mediumDevices} {
        background-size: contain;
        background-position: bottom;

        .container {
            .text-with-img {
                .text-col {
                    .section-title {
                        font-size: 35px;
                        
                        .gradient-text {
                            font-size: 40px;
                        }
                    }

                    p {
                        text-align: center;
                    }
                }
            }
        }
    }

    ${mediaQueries.largeDevices} {
        .container {
            .text-with-img {
                flex-direction: row;
                
                .text-col {
                    width: 60%;

                    .section-title {
                        text-align: left;
                    }

                    p {
                        text-align: left;
                    }
                }

                .video-wrapper {
                    width: 40%;
                }
            }
        }
    }

    ${mediaQueries.extraLargeDevices} {
        background-size: cover;
        padding: 60px 0;

        .container {
            .text-with-img {
                .text-col {
                    .section-title {
                        font-size: 40px;

                        .gradient-text {
                            font-size: 48px;
                        }
                    }
                }
                
                .video-wrapper {
                    padding-left: 30px;
                    > div {
                        height: 360px !important;
                    }
                    
                }
            }
        }
    }
`