import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Accordion, Card, Table } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { orderActions } from '../../../../redux/actions'
import { orderTypes, orderExchanges } from '../../../../constants/orderConstants'
import { getCoin, setDefaultImage, isNotNull, isNull, onSetNewState } from '../../../../utils'

class OpenOrderDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            isLoadingItems: true,
            hasData: false,
            items: null,
            selectedItem: '',
            orderDetails: null,
            hasMultipleItems: false,
            signalFeed: '',
            signalCoinPair: '',
            signalStrategy: ''
        }
        this.mounted = false
    }

    componentDidMount() { 
        this.mounted = true
        const { data } = this.props
        const { items } = this.state

        if (isNull(items) && isNotNull(data)) {
            let newItems = []

            _.forEach(data, (value, i) => {
                if (i === 0) {
                    newItems.push({
                        show: true,
                        details: value
                    })
                } else {
                    newItems.push({
                        show: false,
                        details: value
                    })
                }
            })

            onSetNewState(this, {
                loading: false,
                items: newItems,
                selectedItem: newItems[0].details
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.mounted) {
            const { dispatch, openOrderDetails } = this.props
            const { loading, selectedItem } = this.state

            if (prevState.selectedItem !== selectedItem) {
                const { actorId } = selectedItem
                dispatch(orderActions.getOrderDetails(actorId))
            } else {
                const { isLoadingItems } = this.state
                if (isNotNull(selectedItem) && isLoadingItems && !loading) {
                    onSetNewState(this, {
                        loading: true
                    }, () => {
                        const { actorId } = selectedItem
                        dispatch(orderActions.getOrderDetails(actorId))
                    })
                }
            }

            if (prevProps.openOrderDetails !== openOrderDetails && !openOrderDetails.loading) {
                const { open_order_details } = openOrderDetails
                if (isNotNull(open_order_details)) {
                    const { feed, symbol, strategy, orderLines } = open_order_details

                    onSetNewState(this, {
                        loading: false,
                        isLoadingItems: false,
                        hasData: true,
                        orderDetails: orderLines,
                        hasMultipleItems: orderLines.length > 1,
                        signalFeed: feed,
                        signalCoinPair: symbol,
                        signalStrategy: strategy
                    })
                } else {
                    onSetNewState(this, {
                        loading: false,
                        isLoadingItems: false,
                        hasData: false
                    })
                }
            }

            // if (prevProps.forceExitOrder !== forceExitOrder && !forceExitOrder.loading) {
            //     const { status } = forceExitOrder
            //     if (isNotNull(status) && status.success) {                    
            //         dispatch(walletActions.getWallets())
            //     }
            // }

            // if (prevProps.forceCancelOrder !== forceCancelOrder && !forceCancelOrder.loading) {
            //     const { status } = forceCancelOrder
            //     if (isNotNull(status) && status.success) {
            //         Swal.close()
            //         Swal.fire(
            //             'Cancelled!',
            //             'Your trade order has been cancelled',
            //             'success'
            //         )
            //     }
            // }
        }
    }

    componentWillUnmount() {
        this.mounted = false
        onSetNewState(this, {
            hasMultipleItems: false,
            isLoadingItems: true,
            orderDetails: null,
            signalFeed: '',
            signalCoinPair: '',
            signalStrategy: ''
        })
    }

    handleToggle = (e, details) => {
        e.preventDefault()

        const { selectedItem } = this.state
        if (selectedItem !== details) {
            onSetNewState(this, {
                hasMultipleItems: false,
                isLoadingItems: true,
                orderDetails: null,
                signalFeed: '',
                signalCoinPair: '',
                signalStrategy: ''
            }, () => {
                onSetNewState(this, {
                    selectedItem: details
                })
            })
        }        
        console.log('equal: ', this.state.selectedItem === details)
    }

    handleMarkAsRead = (e, id) => {
        e.preventDefault()
        this.props.onMarkAsRead(id)
    }

    handleCancelOrder = (e, notificationId, feed, orderId, orderType) => {
        e.preventDefault()
        const { dispatch } = this.props
        const { hasMultipleItems, orderDetails } = this.state

        Swal.fire({
            title: 'Are you sure you want to cancel this order?',
            text: 'It will cancel your Stop Loss and Take Profits Orders. All coins will remain in your wallet',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.value) {
                const requestData = {
                    feed,
                    orderId
                }
                dispatch(orderActions.forceCancelOrder(requestData))

                let title = orderType === orderTypes.STOP_BUY_ORDER 
                    ? "Order Cancelled!" 
                    : "Trade Closed!"
                
                if (hasMultipleItems) {
                    let exists = _.find(orderDetails, ['orderId', orderId])
                        if (exists) {
                            let remainingOrders = orderDetails.length - 1
                            if (remainingOrders > 0) {
                                _.pull(orderDetails, exists)
                                onSetNewState(this, {
                                    isLoadingItems: false
                                })
                            } else {
                                this.props.onMarkAsRead(notificationId)
                            }
                        }
                } else {
                    this.props.onMarkAsRead(notificationId)
                }
                            
                Swal.close()
                Swal.fire({
                    title: title,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    handlePanicExitOrder = (e, notificationId, feed, orderId, orderType) => {
        e.preventDefault()
        const { dispatch } = this.props
        const { hasMultipleItems, orderDetails } = this.state

        Swal.fire({
            title: 'Are you sure you want to exit this order?',
            text: 'It will close this order at market price by placing a Market buy/sell order on exchange',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.value) {                
                onSetNewState(this, {
                    isLoadingItems: true
                }, () => {
                    const requestData = {
                        feed,
                        orderId,
                        orderType
                    }
                    dispatch(orderActions.forceExitOrder(requestData))
                    
                    if (hasMultipleItems) {
                        let exists = _.find(orderDetails, ['orderId', orderId])
                        if (exists) {
                            let remainingOrders = orderDetails.length - 1
                            if (remainingOrders > 0) {
                                _.pull(orderDetails, exists)
                                onSetNewState(this, {
                                    isLoadingItems: false
                                })
                            } else {
                                this.props.onMarkAsRead(notificationId)
                            }
                        }
                    } else {
                        this.props.onMarkAsRead(notificationId)
                    }

                    Swal.close()
                    Swal.fire({
                        title: 'Trade Closed!',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000
                    })
                })
            }
        })
    }

    handleClosePosition = (e, notificationId, feed, orderId, orderType) => {
        e.preventDefault()
        const { dispatch } = this.props
        const { hasMultipleItems, orderDetails } = this.state
        
        Swal.fire({
            title: 'Are you sure you want to exit this position?',
            text: 'You will exit your position at the current Mark Price',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.value) {
                onSetNewState(this, {
                    isLoading: true
                }, () => {
                    const requestData = {
                        feed,
                        orderId,
                        orderType
                    }
                    dispatch(orderActions.forceExitOrder(requestData))

                    if (hasMultipleItems) {
                        if (isNotNull(orderDetails) && isNotNull(orderDetails.orderLines)) {
                            const { orderLines } = orderDetails
                            let exists = _.find(orderLines, ['orderId', orderId])
                            if (exists) {
                                let remainingOrders = orderLines.length - 1
                                if (remainingOrders > 0) {
                                    _.pull(orderLines, exists)
                                    onSetNewState(this, {
                                        isLoading: false
                                    })
                                } else {
                                    this.props.onMarkAsRead(notificationId)
                                }
                            }
                        }
                    } else {
                        this.props.onMarkAsRead(notificationId)
                    }

                    Swal.close()
                    Swal.fire({
                        title: 'Trade Closed!',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000
                    })
                })
            }
        })
    }

    renderSignalInfo = () => {
        const { signalFeed, signalCoinPair, signalStrategy } = this.state
        if (isNotNull(signalCoinPair) && isNotNull(signalStrategy)) {
            return (
                <Card className="signal-info-card">
                    <Card.Body>
                        <h3>Signal Info</h3>  
                        <Table borderless={true}>
                            <tbody>
                                <tr>
                                    <td className="align-right">Coin Pair</td>
                                    <td>
                                        <div className="signal-info">
                                            <i className="coin-icon">
                                                <img 
                                                    src={`https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/${getCoin(signalCoinPair)}.png`} 
                                                    alt="coin-logo"
                                                    onError={setDefaultImage} />
                                            </i>
                                            {signalCoinPair}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-right">Exchange</td>
                                    <td>{signalFeed === 'SPOT' ? 'ON BINANCE' : 'ON BINANCE FUTURES'}</td>
                                </tr>
                                <tr>
                                    <td className="align-right">Strategy</td>
                                    <td>{signalStrategy}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            )
        }
    }

    renderStopBuyOrderDetails = (notificationId, value, key) => {
        let feed = value.orderExchange === orderExchanges.BINANCE ? 'SPOT' : 'FUTURES'

        return (
            <Card key={key}>
                <Card.Body>
                    <h3>Trade Order Details</h3>  
                    <Table borderless={true}>
                        <tbody>
                            <tr>
                                <td className="align-right">Ref #</td>
                                <td>{value.orderGroupId}</td>
                            </tr>
    
                            <tr>
                                <td className="align-right">Order Type</td>
                                <td>{value.type}</td>
                            </tr>
    
                            <tr>
                                <td className="align-right">Coin Pair</td>
                                <td>{value.coinPair}</td>
                            </tr>
    
                            <tr>
                                <td className="align-right">Exchange</td>
                                <td>{feed}</td>
                            </tr>
    
                            <tr>
                                <td className="align-right">Status</td>
                                <td>{value.status}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
                <Card.Footer className="order-actions">
                    <button type="button" className="btn btn-cancel-order" onClick={(e) => {this.handleCancelOrder(e, notificationId, feed, value.orderId, value.type)}}>
                        Cancel Order
                    </button>
                    <button type="button" className="btn btn-neutral-order" onClick={(e) => {this.handleMarkAsRead(e, notificationId)}}>
                        Do nothing
                    </button>
                </Card.Footer>
            </Card>
        )
    }

    renderTakeProfitOrderDetails = (notificationId, value, key) => {
        let feed = value.orderExchange === orderExchanges.BINANCE ? 'SPOT' : 'FUTURES'

        return (
            <div key={key}>
                <Card>
                    <Card.Body>
                        <h3>Trade Order Details</h3>  
                        <Table borderless={true}>
                            <tbody>
                                <tr>
                                    <td className="align-right">Ref #</td>
                                    <td>{value.orderGroupId}</td>
                                </tr>
        
                                <tr>
                                    <td className="align-right">Coin Pair</td>
                                    <td>{value.coinPair}</td>
                                </tr>
        
                                <tr>
                                    <td className="align-right">Exchange</td>
                                    <td>{value.orderExchange === 'binance' ? 'Binance' : 'Binance Futures'}</td>
                                </tr>
        
                                <tr>
                                    <td className="align-right">Buy Price</td>
                                    <td>{value.buyPrice}</td>
                                </tr>

                                { value.orderExchange !== orderExchanges.BINANCE_FUTURES && 
                                    <>
                                        <tr>
                                            <td className="align-right">Remaining Coin(s)</td>
                                            <td>{`${value.unSoldCoins} ${value.coinPair.substring(value.coinPair.indexOf('/') + 1)}`}</td>
                                        </tr>
                                        
                                        <tr>
                                            <td className="align-right">Profit/Loss %</td>
                                            <td>
                                                <span id={`order-${value.orderId}-${value.orderExchange}-pnl`}></span>
                                            </td>
                                        </tr>
                                    </>
                                }
                            </tbody>
                        </Table>
                    </Card.Body>
                    <Card.Footer className="order-actions multi-actions">
                        { value.orderExchange === orderExchanges.BINANCE &&
                            <>
                                <button type="button" className="btn btn-sell-order" onClick={(e) => {this.handlePanicExitOrder(e, notificationId, feed, value.orderId, value.type)}}>
                                    Sell Coins Now
                                </button>
                            </>
                        }
        
                        { value.orderExchange === orderExchanges.BINANCE && 
                            <>
                                <button type="button" className="btn btn-cancel-order" onClick={(e) => {this.handleCancelOrder(e, notificationId, feed, value.orderId, value.type)}}>
                                    Keep My Coins
                                </button>
                            </> 
                        }
        
                        { value.orderExchange === orderExchanges.BINANCE_FUTURES && 
                            <>
                                <button type="button" className="btn btn-cancel-order" onClick={(e) => {this.handleClosePosition(e, notificationId, feed, value.orderId, value.type)}}>
                                    Close My Position
                                </button>
                            </>
                        }

                        <button type="button" className="btn btn-neutral-order" onClick={(e) => {this.handleMarkAsRead(e, notificationId)}}>
                            Do Nothing
                        </button>
                    </Card.Footer>
                </Card>
                
                <p className="note-text">Note: <em>Clicking 'Sell Coins Now' will execute a 100% market sell order based on the trade details shown above</em></p>
            </div>
        )
    }

    renderOrderDetails = (notificationId, symbol) => {
        const { orderDetails } = this.state
        if (isNotNull(orderDetails)) {
            if (orderDetails.length > 0) {
                return _.map(orderDetails, (value, key) => {
                    let quoteCoin = value.coinPair.substring(value.coinPair.indexOf('/') + 1)
                    let baseCoin = value.coinPair.substring(0, value.coinPair.indexOf('/'))
                    let coinPair = `${quoteCoin}${baseCoin}`
                    if (isNotNull(value) && symbol === coinPair) {     
                        if (value.type === orderTypes.STOP_BUY_ORDER)
                            return this.renderStopBuyOrderDetails(notificationId, value, key)
                        else
                            return this.renderTakeProfitOrderDetails(notificationId, value, key)
                    }
                    return null
                })
            } else {
                return this.renderNoOrderFound(notificationId)
            }
        }
    }

    renderNoOrderFound = (notificationId) => (
        <Card>
            <Card.Body>
                <h3>Trade Order Details</h3>  
                <p style={{ marginBottom: 0, textAlign: 'center', padding: '25px' }}>We cannot find any active trades to close.</p>
            </Card.Body>
            <Card.Footer className="order-actions">
                <button type="button" className="btn btn-neutral-order" onClick={(e) => {this.handleMarkAsRead(e, notificationId)}}>
                    Ok
                </button>
            </Card.Footer>
        </Card>
    )

    renderItems = () => {
        const { items, isLoadingItems, hasData } = this.state
        if (isNotNull(items) && items.length > 0) {
            return _.map(items, (value, key) => {
                if (isNotNull(value)) {
                    const { details } = value
                    return (
                        <Card key={key}>
                            <Accordion.Toggle as={Card.Header} eventKey={details.id} onClick={(e) => {this.handleToggle(e, details)}}>
                                <div className="signal-info">
                                    <i className="coin-icon">
                                        <img 
                                            src={`https://pf-cryptocoin-logos.s3.eu-west-2.amazonaws.com/default/${getCoin(details.symbol)}.png`} 
                                            alt="coin-logo"
                                            onError={setDefaultImage} />
                                    </i>
                                    {details.symbol}
                                </div>
                            </Accordion.Toggle>

                            <Accordion.Collapse id={key} eventKey={details.id}>
                                <Card.Body>
                                    { isLoadingItems && 
                                        <>
                                            {this.renderLoader()}
                                        </>
                                    }

                                    { !isLoadingItems && hasData && 
                                        <>
                                            {this.renderSignalInfo()}
                                            {this.renderOrderDetails(details.id, details.symbol)}
                                        </>
                                    }

                                    { !isLoadingItems && !hasData && 
                                        <>
                                            <p style={{ display: 'block', textAlign: 'center', marginBottom: '15px' }}>Your trade order(s) for this signal has already been closed</p>
                                            <button type="button" className="btn btn-neutral-order" onClick={(e) => {this.handleMarkAsRead(e, details.id)}}>
                                                Ok
                                            </button>
                                        </>
                                    }
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )
                }

                return null
            })
        }
    }

    renderLoader = () => {
        return (
            <>
                <div style={{ width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="pf-spinner xs" style={{ marginTop: '-48px', marginRight: '45px' }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            </>
        )
    }

    render() {
        const { items, loading } = this.state

        return (
            <>
                { loading && 
                    <>
                        {this.renderLoader()}
                    </>
                }

                { !loading && 
                    <>
                        { isNotNull(items) && isNotNull(items[0]) && 
                            <Accordion defaultActiveKey={items[0].details.id}>
                                {this.renderItems()}
                            </Accordion>
                        }
                    </>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    const { notificationLists, openOrderDetails, forceExitOrder, forceCancelOrder } = state
    return {
        notificationLists,
        openOrderDetails,
        forceExitOrder,
        forceCancelOrder
    }
}

const connectedOpenOrderDetails = connect(mapStateToProps)(OpenOrderDetails)
export { connectedOpenOrderDetails as OpenOrderDetails }