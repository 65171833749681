import { formActionTypes } from '../CONSTANTS'

export function forms(state = {}, action) {
    var { response, error, ...oldState } = state
    switch (action.type) {
        case formActionTypes.EDIT_PROFILE_REQUEST:
            return {
                loading: true
            }
        case formActionTypes.EDIT_PROFILE_SUCCESS:
            return {
                ...oldState,
                loading: false,
                response: action.response
            }
        case formActionTypes.EDIT_PROFILE_FAILURE:
            return {
                ...oldState,
                loading: false,
                error: action.error
            }
        case formActionTypes.CHANGE_PASSWORD_REQUEST:
            return {
                loading: true
            }
        case formActionTypes.CHANGE_PASSWORD_SUCCESS:
            return {
                ...oldState,
                loading: false,
                response: action.response
            }
        case formActionTypes.CHANGE_PASSWORD_FAILURE:
            return {
                ...oldState,
                loading: false,
                error: action.error
            }
        case formActionTypes.RESET_PASSWORD_REQUEST:
            return {
                loading: true
            }
        case formActionTypes.RESET_PASSWORD_SUCCESS:
            return {
                ...oldState,
                loading: false,
                response: action.response
            }
        case formActionTypes.RESET_PASSWORD_FAILURE:
            return {
                ...oldState,
                loading: false,
                error: action.error
            }
        case formActionTypes.CHANGE_EMAIL_REQUEST:
            return {
                loading: true
            }
        case formActionTypes.CHANGE_EMAIL_SUCCESS:
            return {
                ...oldState,
                loading: false,
                response: action.response
            }
        case formActionTypes.CHANGE_EMAIL_FAILURE:
            return {
                ...oldState,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}
