import { messageTypeActionTypes } from '../CONSTANTS'

export function messageTypes(state={}, action) {
    switch(action.type) {
        case messageTypeActionTypes.GET_MSG_TYPES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case messageTypeActionTypes.GET_MSG_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                dataList: action.response
            }
        case messageTypeActionTypes.GET_MSG_TYPES_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function audienceTypes(state={}, action) {
    switch(action.type) {
        case messageTypeActionTypes.GET_AUDIENCE_TYPES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case messageTypeActionTypes.GET_AUDIENCE_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                dataList: action.response
            }
        case messageTypeActionTypes.GET_AUDIENCE_TYPES_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}