import glamorous from 'glamorous'
import { mediaQueries } from '../../../constants/mediaQueries'

export const CheckoutSectionStyle = glamorous.section({
    position: 'relative',
    padding: '40px 0',
    color: '#fff',
    backgroundColor: '#000000c2',
    backgroundImage: 'url(/images/subscriptions/payment-bg.png)',
    backgroundSize: '1000px 600px',
    backgroundPosition: '-210px center',
    backgroundRepeat: 'no-repeat',

    '&.overlapping': {
        padding: '116px 0 40px'
    },

    '& .container': {
        '& p': {
            textAlign: 'center',
            color: '#85A1BE',
            fontSize: '15px',

            '&.note': {
                fontSize: '14px',
                maxWidth: '100%',
                margin: '0 auto'
            },

            '&.discount-tag': {
                color: '#15BD6F',
                fontSize: '16px'
            }
        },

        '& a ': {
            color: '#298CE6',
            textDecoration: 'underline'
        },

        '& .section-header': {
            marginBottom: '30px',

            '& .section-title': {
                display: 'block',
                textAlign: 'center',
                fontSize: '25px',
                fontWeight: 600
            },

            '& p': {
                maxWidth: '342px',
                margin: '0 auto',
            }
        },

        '& .nav-tabs': {
            maxWidth: '100%',
            margin: '0 auto',
            backgroundColor: '#213247',
            border: '1px solid #546A85',
            borderRadius: '17px',
            justifyContent: 'space-between',

            '& .nav-item': {
                position: 'relative',
                backgroundColor: 'transparent',
                color: '#85a1be',
                border: 'none',
                fontSize: '14px',
                fontWeight: 500,
                width: '25%',
                textAlign: 'center',
                padding: '6px 0',
                textDecoration: 'none',

                '&.active': {
                    backgroundColor: '#15BD6F',
                    borderRadius: '17px',
                    color: '#fff',
                    marginLeft: '-2px',
                    paddingLeft: '2px',

                    '&:after': {
                        display: 'none'
                    }
                },

                '&:after': {
                    display: 'block',
                    position: 'absolute',
                    content: ' ',
                    right: 0,
                    top: 'calc(50% - 6px)',
                    width: '1px',
                    height: '12px',
                    backgroundColor: '#111923'
                },

                '&:last-child': {
                    borderRight: 'none',

                    '&:after': {
                        display: 'none'
                    }
                }
            }
        },

        '& .tab-content': {
            '& .tab-pane': {
                '& .pricing-snippet': {
                    display: 'block',
                    maxWidth: '360px',
                    margin: '30px auto 100px',
                    
                    '& .price-info': {
                        display: 'flex',
                        justifyContent: 'center',
                        width: '360px',
                        margin: '0 auto 20px',
                        padding: 0,
                        listStyleType: 'none',

                        '& li': {
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            textAlign: 'left',
                            marginRight: '10px',

                            '&.original-price': {
                                minWidth: '75px',

                                '& > span': { 
                                    position: 'relative',
                                    fontSize: '26px',
                                    fontWeight: 600,
                                    color: '#e6ebf261',

                                    '&::before': {
                                        position: 'absolute',
                                        width: '100%',
                                        height: '3px',
                                        backgroundColor: '#FE2944',
                                        content: ' ',
                                        top: 'calc(50% - 3px)'
                                    }
                                }
                            },

                            '&.discounted-price': {
                                '& > span': {
                                    fontSize: '50px',
                                    fontWeight: 700,
                                    color: '#fff',
                                    lineHeight: '1.2'
                                }
                            },

                            '&.tag': {
                                position: 'relative',

                                '&::before': {
                                    position: 'absolute',
                                    width: '70px',
                                    height: '70px',
                                    top: 0,
                                    left: 0,
                                    content: ' ',                        
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                },

                                '&.quarterly': {
                                    '&::before': {
                                        width: '83px',
                                        height: '81px',
                                        backgroundImage: 'url(/images/subscriptions/quarterly-discount.png)'
                                    }
                                },

                                '&.yearly': {
                                    '&::before': {
                                        width: '83px',
                                        height: '81px',
                                        backgroundImage: 'url(/images/subscriptions/yearly-discount.png)'    
                                    }
                                },

                                '&.lifetime': {
                                    '&::before': {
                                        width: '85px',
                                        height: '85px',
                                        marginTop: '-4px',
                                        backgroundImage: 'url(/images/subscriptions/best-value.png)'   
                                    }
                                }
                            },

                            '&:last-child': {
                                width: '70px',
                                height: '70px',
                                marginRight: 0
                            }
                        }
                    },

                    '& .checkout-btn': {
                        textAlign: 'center',

                        '& button': {
                            width: 'auto',
                            margin: '10px auto 5px',
                            padding: '17px 25px',
                            borderRadius: '40.5px',
                            border: 'none',
                            letterSpacing: '0.7px',

                            '& svg': {
                                marginRight: '10px'
                            }
                        },
                            
                        '& small': {
                            color: '#85A1BE'
                        }
                    }
                }
            }
        }
    },

    [mediaQueries.mediumDevices]: {
        padding: '50px 0',
        backgroundSize: 'cover',
        backgroundPosition: '-381px center',

        '& .container': {
            '& p': {
                fontSize: '17px',

                '&.note': {
                    fontSize: '15px',
                    maxWidth: '380px'
                },

                '&.discount-tag': {
                    fontSize: '20px',
                    fontWeight: 600
                }
            },

            '& .section-header': {
                '& .section-title': {
                    fontSize: '32px'
                },

                '& p': {
                    fontSize: '20px',
                    maxWidth: '400px'
                }
            },

            '& .nav-tabs': {
                maxWidth: '556px',

                '& .nav-item': {
                    fontSize: '16px',
                    padding: '10px 4px'
                }
            },

            '& .tab-content': {
                '& .tab-pane': {
                    '& .pricing-snippet': {
                        maxWidth: '460px',

                        '& .price-info': {
                            width: '100%',

                            '& li': {
                                marginRight: '20px',

                                '&.original-price': {
                                    '& > span': {
                                        fontSize: '35px'
                                    }
                                },

                                '&.discounted-price': {
                                    '& > span': {
                                        fontSize: '72px'
                                    }
                                },

                                '&.tag': {
                                    '&.lifetime': {
                                        '&::before': {
                                            width: '124px',
                                            height: '124px',
                                            marginTop: '-19px'
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },

    [mediaQueries.largeDevices]: {
        backgroundPosition: '25% center',

        '& .container': {
            '& p': {
                '&.note': {
                    maxWidth: '760px'
                }
            },

            '& .section-header': {
                '& .section-title': {
                    fontSize: '36px'
                },

                '& p': {
                    fontSize: '22px',
                    maxWidth: '100%'
                }
            },

            '& .tab-content': {
                '& .tab-pane': {
                    '& .pricing-snippet': {
                        margin: '30px auto',

                        '& .price-info': {
                            '& li': {
                                '&.tag': {
                                    '&.lifetime': {
                                        '&::before': {
                                            width: '167px',
                                            height: '164px',
                                            marginTop: '-25px'
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },

    [mediaQueries.extraLargeDevices]: {
        backgroundPosition: '200px center',

        '& .container': {
            '& .section-header': {
                '& .section-title': {
                    fontSize: '36px'
                }
            },

            '& .nav-tabs': {
                '& .nav-item': {
                    padding: '15px 0',
                    fontSize: '16px'
                }
            },

            '& .tab-content': {
                '& .tab-pane': {
                    '& .pricing-snippet': {                        
                        '& .price-info': {
                            '& li': {
                                '&.tag': {
                                    '&.lifetime': {
                                        '&::before': {
                                            width: '142px',
                                            height: '142px'
                                        }
                                    }
                                }
                            }
                        },

                        '& .checkout-btn': {
                            '& button': {
                                padding: '25px 35px'
                            }
                        }
                    }
                }
            }
        }
    }
})