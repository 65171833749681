import React from 'react'
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import Slider from '@material-ui/core/Slider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import { InputGroup, Image, Form } from 'react-bootstrap'
import { Field } from "react-final-form"
import _ from 'lodash'
import {
    QuickTradeExchangeWrapper, QuickTradeExchangeIcon, QuickTradeCalculation
} from './styled_components'
import {
    ScientificToDecimal, setCoinLogo, setDefaultImage,
    validatePrice, priceFormat, isNull
} from '../../utils'

class VolumeField extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rangeValue: 0
        }
    }

    muiTheme = createTheme({
        overrides: {
            MuiSlider: {
                thumb: {
                    color: "#15bd6f",
                    marginTop: -4
                },
                track: {
                    height: 5,
                    color: '#15bd6f'
                },
                rail: {
                    height: 5,
                    color: 'black'
                },
                mark: {
                    height: 8,
                    width: 8,
                    color: 'black',
                    borderRadius: '100%',
                    marginTop: -2,
                    marginLeft: -3
                }
            }
        }
    })

    marks = [
        {
            value: 25,
        },
        {
            value: 50,
        },
        {
            value: 75,
        }
    ]

    onPriceHelper = (value, target) => {
        const { quickTradeBidPrice } = this.props
        return target === "BTC"
            ? priceFormat(
                parseFloat(value * quickTradeBidPrice),
                parseFloat(value * quickTradeBidPrice),
                "BTCUSDT").startPrice
            : _.round(parseFloat(value / quickTradeBidPrice), 8)
    }

    onRangeChangeHandler = (event, newValue) => this.props.changeHandler(event, newValue)

    onToggleHandler = (sourceCoin, targetCoin) => {
        this.setState({ rangeValue: 0 })
        this.props.handleChange(sourceCoin, targetCoin)
    }

    onVolumeValueChangeHandler = (e) => {
        const { sourceCoin, targetCoin, quickTradeLastPrice } = this.props
        let value = 0
        if (isNull(e.target.value) || isNaN(e.target.value))
            value = 0
        else
            value = e.target.value

        const percentage = Math.round((value * 100) / parseFloat(quickTradeLastPrice), 0)
        this.setState({ rangeValue: percentage })

        this.props.self.setState({ currentAmount: value })

        if (sourceCoin === "BTC" && targetCoin === "USDT") {
            let price = this.onPriceHelper(value, "BTC")
            this.props.handleCalculation(sourceCoin, targetCoin, price, value)
        }
        if (sourceCoin === "USDT" && targetCoin === "BTC") {
            let price = this.onPriceHelper(value, "USDT")
            this.props.handleCalculation(sourceCoin, targetCoin, price, value)
        }
    }

    renderAmountRangeSliderHandler = (input) => {
        const { sourceCoin, targetCoin, quickTradeLastPrice } = this.props

        const onRangeChangeHandler = (event, newValue) => {
            this.setState({ rangeValue: newValue })
            const balance = parseFloat(quickTradeLastPrice) * (parseFloat(newValue) / 100)
            let convertedBalance = 0

            if (sourceCoin === "BTC" && targetCoin === "USDT") {
                const price = this.onPriceHelper(balance, "BTC")
                this.props.handleCalculation(sourceCoin, targetCoin, price, balance)

                convertedBalance = _.round(parseFloat(balance), 8)
            }
            if (sourceCoin === "USDT" && targetCoin === "BTC") {
                const price = this.onPriceHelper(balance, "USDT")
                this.props.handleCalculation(sourceCoin, targetCoin, price, balance)

                convertedBalance = priceFormat(
                    parseFloat(balance),
                    parseFloat(balance),
                    "BTCUSDT").startPrice
            }

            input.onChange(ScientificToDecimal(convertedBalance))
            this.props.self.setState({ currentAmount: ScientificToDecimal(convertedBalance) })
        }

        return (
            <div className="range-slider-wrapper">
                <ThemeProvider theme={this.muiTheme}>
                    <Slider
                        value={this.state.rangeValue}
                        onChange={onRangeChangeHandler}
                        defaultValue={0}
                        aria-labelledby="range-slider"
                        valueLabelDisplay="auto"
                        valueLabelFormat={value => <span>{`${value}%`}</span>}
                        marks={this.marks}
                        step={1}
                        min={0}
                        max={100}
                    />
                </ThemeProvider>
            </div>
        )
    }

    render() {
        const { sourceCoin, targetCoin, calculation, currentAmount } = this.props

        return (
            <Field name="volume" type="text" autoComplete="off">
                {({ input, meta }) => (
                    <>
                        <QuickTradeCalculation>
                            <div className="input-wrapper">
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            <Image className="quick-trade-logo" src={setCoinLogo(sourceCoin.toLowerCase())} onError={(e) => setDefaultImage(e)} fluid roundedCircle alt="coin logo" />
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>

                                    <Form.Control
                                        autoComplete="off"
                                        placeholder="0"
                                        onKeyPress={(e) => validatePrice(e)}
                                        onKeyUpCapture={(e) => this.onVolumeValueChangeHandler(e)}
                                        id="volume"
                                        value={currentAmount}
                                        {...input}
                                    />

                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            <span className="coin-names">{sourceCoin}</span>
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>

                                {this.renderAmountRangeSliderHandler(input)}
                            </div>

                            <QuickTradeExchangeWrapper className="exchange-wrapper">
                                <QuickTradeExchangeIcon onClick={() => this.onToggleHandler(sourceCoin, targetCoin)} className="exchange-wrapper-content">
                                    <FontAwesomeIcon icon={faExchangeAlt} className="icon" />
                                </QuickTradeExchangeIcon>
                            </QuickTradeExchangeWrapper>

                            <div className="calculation-wrapper">
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            <Image
                                                className="quick-trade-logo"
                                                src={setCoinLogo(targetCoin.toLowerCase())}
                                                onError={(e) => setDefaultImage(e)}
                                                fluid
                                                roundedCircle
                                                alt="coin logo" />
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>

                                    <p className="form-control">{ScientificToDecimal(calculation)}</p>

                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            <span className="coin-names">{targetCoin}</span>
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                        </QuickTradeCalculation>

                        <>{meta.error && meta.touched && (<small style={{ color: '#ff2525', fontWeight: 'bold' }}>{meta.error}</small>)}</>
                    </>
                )}
            </Field>
        )
    }
}

export default VolumeField