import styled from 'styled-components'

export const Wrapper = styled.div`
    height: 100%;
    background: #fff;
    overflow: hidden;
`

export const Section = styled.div`
    .page-title {
        text-transform: none;
        font-size: 32px;
        font-weight: 700;
        color: rgb(3, 168, 78);
        margin-bottom: 15px;
    }

    .page-subtitle {
        color: rgb(0, 0, 0);
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 50px;
        overflow: hidden;
        text-align: center;

        &:before,
        &:after {
            background-color: rgba(0, 0, 0, 0.22);
            content: "";
            display: inline-block;
            height: 1px;
            position: relative;
            vertical-align: middle;
            width: 50%;
        }

        &:before {
            right: 0.5em;
            margin-left: -50%;
        }

        &:after {
            left: 0.5em;
            margin-right: -50%;
        }
    }

    &.bg {
        background: url('/images/pricing-bg.png');
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: bottom;
		height: 100%;
    }

    &.guarantee {
        .title {
            text-align: center;
            font-size: 35px;
			font-weight: 700;
			line-height: 1.2em;
			margin-bottom: 25px;
	
			@media screen and (min-width: 320px) and (max-width: 767px) {
				font-size: 26px;
			}
        }

        .img-container {
            display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			margin: 0 auto;

			@media screen and (min-width: 320px) and (max-width: 767px) {
				padding-bottom: 30px;
				width: 85%;
			}
        }

        .content-body {
            p {
				text-align: justify;
				margin-bottom: 1.6rem;

				&:last-child {
					margin-bottom: 0
				}
			}

			@media screen and (min-width: 320px) and (max-width: 767px) {
				padding: 0 20px;
				
				p {
					font-size: 15px;
				}
			}
        }
    }
`

export const Content = styled.div`
    font-family: Montserrat, sans-serif;
    height: 100%;
    padding: 50px 25px;

    .container {
        &.full-height {
            height: 100%;
        }

        .subscriptions-list {
            .plan-details {
                &.card {
                    background-color: #fff;
                    box-shadow: 0px 1px 20px 0px rgba(0,0,0,0.2);
                    min-height: 475px;
                    padding: 0;

                    .card-header {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        height: 110px;
                        background: rgb(247, 247, 247);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.08);

                        h3 {                            
                            font-size: 23px;
                            font-weight: bold;
                            color: black;
                            margin-bottom: 0;
                            text-align: center;
                        }
                    }

                    .card-body {
                        text-align: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;

                        .price-details {
                            h4 {
                                font-size: 55px;
                                color: #03a84e;
                                height: 100px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;

                                .frequency {
                                    font-size: 15px;
                                    color: #56A250;
                                }
                            }
                        }

                        .breakdown-list-wrapper {
                            .breakdown-list {
                                list-style: none;
                                padding: 0px;
                                font-size: 14px;
                                font-weight: 500;
                                margin: 0px;
                            }
                        }
                    }

                    .card-footer {
                        background-color: transparent;
                        border: none;
                        text-align: center;
                        padding: 20px 20px 30px;

                        button {
                            font-weight: bold;
                            color: rgb(255, 255, 255);
                            background: rgb(255, 239, 181);
                            transition: transform 0.2s ease 0s;
                            border: none;
                            font-size: 20px;
                            width: 200px;
                            padding: 10px;
                        }
                    }
                }

                &.highlighted {
                    .card-header {
                        background-color: rgb(63, 159, 50);
                        
                        h3 {
                            color: #fff;

                            small {
                                display: inline-block;
                                width: auto;
                                border: 2px solid #fff;
                                font-size: 13px;
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
        }

        .card {
            position: relative;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            min-width: 0;
            word-wrap: break-word;
            background-color: transparent;
            background-clip: border-box;
            padding: 20px;
            border: none;
            margin-bottom: 25px;

            .fa-ul {
				.fa-li {
					&.fa-check {
						color: #0fca74;
					}
				}

				.text-muted {
					color: #8c87878c !important;
				}
			}

            &.popular {
				background: #fff;
				box-shadow: 0px 1px 20px 0px rgba(0,0,0,0.2);
			    min-height: 475px;
				margin-bottom: 25px;

				.price-seperator {
					border: 1px solid #0000002b;
				}

				&.monthly-yearly-card {
					h4 {
						font-size: 55px;
					    color: #03a84e;
					    height: 100px;
					    display: flex;
					    justify-content: center;
					    align-items: center;
					    flex-direction: column;
					}

					.price-subtext {
						height: 100px;
						display: flex;
						flex-direction: column;
						justify-content: center;
					}

					.yearly-subtext {
						margin-top: 24px;
					}

					.monthly-description {
						height: 140px;
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						font-size: 18px;
						padding-top: 15px;
					}
					.yearly-description {
						height: 140px;
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						padding-top: 15px;
					}

					.monthly-yearly-title {
						font-size: 23px;
						height: 110px;
						line-height: 110px;
						background: #F7F7F7;
					}
				}

				&.quarterly-card {
					border-color: rgb(25, 156, 9);
  					box-shadow: rgba(36, 134, 24, 0.84) 0px 0px 20px 5px;
					overflow: hidden;

					h4 {
						font-size: 55px;
						color: #03a84e;
					}

					.price-subtext {
						height: 100px;
						display: flex;
						flex-direction: column;
						justify-content: center;
					}

					.quarterly-description {
						height: 140px;
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						padding-top: 15px;

						.text-green {
							color: #56A250;
							font-weight: 600;
						}
					}

					.saving-ribbon {
						transform: rotate(44deg);
						width: 210px;
						color: #fff;
						font-size: 12px;
						position: absolute;
						right: -62px;
						top: 28px;
						background: #de2323;
						padding: 0;

						.for-life {
							font-weight: 100;
						}
					}

					.quarterly-price {
						font-size: 55px;
						color: #03a84e;
						height: 100px;
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;
					}

					.fa-ul {
						.fa-li {
							color: #47da35;
						}
					}
				}

				.per-month {
					font-size: 15px;
					color: #56A250;
				}

				.plan-description {
					border: 1px solid #84828252;
					border-radius: 6px;
					padding: 12px;
					margin: 0px 16px;
				}

				.icon {
					font-size: 18px;
					color: #23A455;
					margin-right: 10px;
				}

				.description-list {
					list-style: none;
				    padding: 0;
				    font-size: 14px;
				    font-weight: 500;
					margin: 0;
				}
			}
        }
    }
`

export const CardTitle = styled.div`
    text-align: center;

    h2 {
        text-transform: none;
        font-size: 32px;
        color: #03a84e;

        @media screen and (min-width: 320px) and (max-width: 1023px) {
            font-size: 20px;
        }

        @media screen and (min-width: 1024px) and (max-width: 1199px) {
            font-size: 30px;
        }
    }

    p {
		font-weight: 500;
        color: #000;
        font-size: 22px;
        margin-bottom: 50px;
        overflow: hidden;
        text-align: center;

        &:before {
            right: 0.5em;
            margin-left: -50%;
        }

        &:after {
            left: 0.5em;
            margin-right: -50%;
        }

        &:before,
        &:after {
            background-color: #00000038;
            content: "";
            display: inline-block;
            height: 1px;
            position: relative;
            vertical-align: middle;
            width: 50%;
        }

        @media screen and (min-width: 320px) and (max-width: 1023px) {
            font-size: 16px;
            margin-bottom: 20px;

            &:before,
            &:after {
                display: none;
            }
        }

        @media screen and (min-width: 1024px) and (max-width: 1199px) {
            font-size: 20px;
        }
    }
`