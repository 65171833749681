import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import jQuery from 'jquery'
import { Skeleton } from "@material-ui/lab"
import { scannerActions } from '../../../redux/actions'
import {
    ComboPairedCoinWrapper, ComboPairedInfo, PairedCoin,
    PairedCoinLogo, PairedCoinSymbol, PairedCoinPrice,
    PriceValue, PriceAbbr, SignalBoxDetails,
    SignalBoxCombinationWrapper, SignalBoxCombinationValues,
    SignalBoxCombinationHeader, SignalBoxCombinationItem,
    FooterLogo, PreloaderWrapper, PreloaderTitle, PreloaderText,
    PreloaderImage
} from '../styled_components'
import { setCoinLogo, setDefaultImage, getCoin, isNotNull, ScientificToDecimal } from '../../../utils'

class TradeGuide extends React.Component {

    componentDidMount() {
        const { dispatch, data } = this.props

        if (isNotNull(data)) {
            var selectedData = {
                "m5": data.sm5,
                "m15": data.sm15,
                "m30": data.sm30,
                "h1": data.sh1,
                "h4": data.sh4,
                "h6": data.sh6,
                "h12": data.sh12,
                "d1": data.sd1
            }
            dispatch(scannerActions.getTradeGuide(selectedData))
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tradeGuide !== this.props.tradeGuide)
            this._displayHtmlData()
    }

    _displayHtmlData() {
        let { trade_guide } = this.props.tradeGuide
        if (typeof trade_guide !== "undefined" && trade_guide !== null) {
            let { data } = trade_guide
            if (typeof data !== "undefined" && data !== null & data !== '') {
                let { previewBody } = data
                if (typeof data !== "undefined" && previewBody !== null && previewBody !== '') { }
                jQuery(".data-body").html(`${data.previewBody}`)

            } else
                jQuery(".data-body").html("<div class='text-center'>No Available Data</div>")
        }
    }

    onGetWaveStyleHandler(waveValue) {

        switch (waveValue) {
            default: { // this means this is already zero
                return "neutral filled"
            }
            case 3: {
                return "big-raise filled"
            }
            case 2: {
                return "small-raise filled"
            }
            case 1: {
                return "neutral filled"
            }
            case -1: {
                return "neutral filled"
            }
            case -2: {
                return "small-drop filled"
            }
            case -3: {
                return "big-drop filled"
            }
        }
    }

    render() {

        let { data } = this.props
        let sm5Style, sm15Style, sm30Style,
            sh1Style, sh4Style, sh6Style,
            sh12Style, sd1Style, abbr, coinName = null

        if (isNotNull(data)) {
            sm5Style = this.onGetWaveStyleHandler(data.sm5)
            sm15Style = this.onGetWaveStyleHandler(data.sm15)
            sm30Style = this.onGetWaveStyleHandler(data.sm30)
            sh1Style = this.onGetWaveStyleHandler(data.sh1)
            sh4Style = this.onGetWaveStyleHandler(data.sh4)
            sh6Style = this.onGetWaveStyleHandler(data.sh6)
            sh12Style = this.onGetWaveStyleHandler(data.sh12)
            sd1Style = this.onGetWaveStyleHandler(data.sd1)
            abbr = data.logo.toUpperCase()
            coinName = data.n
        }

        return (
            <div>
                <Modal.Header>
                    <ComboPairedCoinWrapper>
                        <ComboPairedInfo className="coin-paired-wrapper">
                            <PairedCoin>
                                <PairedCoinLogo>
                                    <img onError={setDefaultImage} src={setCoinLogo(getCoin(coinName))} alt="coin logo" />
                                </PairedCoinLogo>
                                <PairedCoinSymbol>{coinName}</PairedCoinSymbol>
                            </PairedCoin>
                            <PairedCoinPrice>
                                <PriceValue className={`cp-${coinName}`}>{ScientificToDecimal(data.cp)}</PriceValue>
                                <PriceAbbr>{abbr}</PriceAbbr>
                            </PairedCoinPrice>
                        </ComboPairedInfo>

                        <SignalBoxDetails>
                            <SignalBoxCombinationWrapper>
                                <SignalBoxCombinationHeader>
                                    <SignalBoxCombinationItem>M5</SignalBoxCombinationItem>
                                    <SignalBoxCombinationItem>M15</SignalBoxCombinationItem>
                                    <SignalBoxCombinationItem>M30</SignalBoxCombinationItem>
                                    <SignalBoxCombinationItem>H1</SignalBoxCombinationItem>
                                    <SignalBoxCombinationItem>H4</SignalBoxCombinationItem>
                                    <SignalBoxCombinationItem>H6</SignalBoxCombinationItem>
                                    <SignalBoxCombinationItem>H12</SignalBoxCombinationItem>
                                    <SignalBoxCombinationItem>D</SignalBoxCombinationItem>
                                </SignalBoxCombinationHeader>
                                <SignalBoxCombinationValues>
                                    <SignalBoxCombinationItem className={sm5Style} />
                                    <SignalBoxCombinationItem className={sm15Style} />
                                    <SignalBoxCombinationItem className={sm30Style} />
                                    <SignalBoxCombinationItem className={sh1Style} />
                                    <SignalBoxCombinationItem className={sh4Style} />
                                    <SignalBoxCombinationItem className={sh6Style} />
                                    <SignalBoxCombinationItem className={sh12Style} />
                                    <SignalBoxCombinationItem className={sd1Style} />
                                </SignalBoxCombinationValues>
                            </SignalBoxCombinationWrapper>
                        </SignalBoxDetails>
                    </ComboPairedCoinWrapper>
                </Modal.Header>
                <Modal.Body className="data-body">
                    <PreloaderWrapper>
                        <PreloaderTitle>
                            <Skeleton variant="rect" style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: 'linear-gradient(15deg,#334156,#385065)',
                            }} className="skeleton" />
                        </PreloaderTitle>
                        <PreloaderText>
                            <Skeleton variant="rect" style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: 'linear-gradient(15deg,#334156,#385065)',
                            }} className="skeleton" />
                        </PreloaderText>
                        <PreloaderText>
                            <Skeleton variant="rect" style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: 'linear-gradient(15deg,#334156,#385065)',
                            }} className="skeleton" />
                        </PreloaderText>
                        <PreloaderText>
                            <Skeleton variant="rect" style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: 'linear-gradient(15deg,#334156,#385065)',
                            }} className="skeleton" />
                        </PreloaderText>
                        <PreloaderImage>
                            <Skeleton variant="rect" style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: 'linear-gradient(15deg,#334156,#385065)',
                            }} className="skeleton" />
                        </PreloaderImage>
                        <PreloaderText>
                            <Skeleton variant="rect" style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: 'linear-gradient(15deg,#334156,#385065)',
                            }} className="skeleton" />
                        </PreloaderText>
                        <PreloaderText>
                            <Skeleton variant="rect" style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: 'linear-gradient(15deg,#334156,#385065)',
                            }} className="skeleton" />
                        </PreloaderText>
                        <PreloaderText>
                            <Skeleton variant="rect" style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: 'linear-gradient(15deg,#334156,#385065)',
                            }} className="skeleton" />
                        </PreloaderText>
                    </PreloaderWrapper>
                </Modal.Body>
                <Modal.Footer>
                    <FooterLogo>
                        <img src={"https://pf-image-assets.s3.eu-west-2.amazonaws.com/default/pf-icon-white.png"} alt="pf white logo" />
                    </FooterLogo>
                </Modal.Footer>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { tradeGuide } = state
    return {
        tradeGuide
    }
}

const connectedTradeGuide = connect(mapStateToProps)(TradeGuide)
export { connectedTradeGuide as TradeGuide }
