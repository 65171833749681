import React from 'react'
import { ProfitableTradingStyle } from './styles/ProfitableTradingStyle'

export const ProfitableTradingSection = () => {
    return (
        <ProfitableTradingStyle>
            <div className='container'>
                <h2 className='font-weight-bold text-center'>Finally You Can Make Profitable Trading a Viable, Healthy Part of Your Busy Life</h2>

                <div className='text-with-image'>
                    <div className='img-content'>
                        <img src='/images/subscriptions/astro-1.png' alt='' />
                    </div>
                    <div className='text-content'>
                        <p>ProfitFarmers gives you everything you need to profit PLUS trade automation features that let you sleep like a normal human being.</p>
                        <p>With Break-Even Stop-Loss, you won’t even have to monitor your trade to lock-in profits. Just toggle it on, and go enjoy your day knowing you’ll profit on nearly 4 out of 5 completed trades.</p>
                        <p>Pro could more than pay for itself in just a single trade.</p>
                        <p>And it comes with a 30-day money-back guarantee. If we don’t give you completed signals with a win-rate of &gt; 60% in your first 30 days, you get your money back.</p>
                        <p>So you either put yourself in a very strong position with high-probability trade setups or you get your money back. What have you got to lose?</p>
                        <p>Try ProfitFarmers Pro and you’ll never trade the old way again. We promise you.</p>
                    </div>
                </div>
            </div>
        </ProfitableTradingStyle>
    )
}