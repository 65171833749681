import styled from 'styled-components'
import { device } from '../CONSTANTS'

const FormContainer = styled.div`
    padding: 15px;

    @media only screen and (min-width: 767px) AND (max-width: 1023px) {
        padding: 30px 25px;
    }

    @media only screen and (min-width: 1024px) {
        padding: 40px;
    }

    @media only screen and (min-width: 1200px) {
        padding: 50px;
    }

    @media only screen and (min-width: 1441px) {
        padding: 60px;
    }

    @media only screen and (min-width: 1750px) {
        padding: 70px 60px;
    }
`

const FormHeadingWrapper = styled.div`
    text-align: center;
    margin-bottom: 2rem;

    h1 {
        color: #fff;
        font-weight: 700;
        margin: 15px 0;

        @media screen and (max-width: 1023px) and (min-width: 768px) {
            font-size: 22px;
        }

        @media screen and (max-width: 1440px) and (min-width: 1200px) {
            font-size: 23px;
        }
    }

    p {
        font-size: 14px;
        font-weight: 500;

        @media screen and (max-width: 1023px) and (min-width: 768px) {
            font-size: 12px;
        }
    }
`

const FormBodyWrapper = styled.div`
    padding: 10px;

    .hasAgreedToAgreement {
        position: relative;
        width: 100%;
        display: flex;
        padding-left: 9px;
        padding-top: 5px;

        @media ${device.tablet} {
            padding-top: 8px;
        }

        @media ${device.tabletXL} {
            padding-top: 5px;
        }

        @media ${device.laptopL} {
            padding-top: 2.5px;
        }

        label {
            position: relative;
            padding-top: 7px;
            padding-left: 19px;
            cursor: pointer;
            font-size: 14px;
            font-family: Montserrat, sans-serif;
            font-weight: 500;
            margin-bottom: .3rem;
            top: -5px;

            @media ${device.tablet} {
                font-size: 12.5px;
                line-height: 1.5;
                margin-bottom: 0;
                padding-top: 8px;
            }

            @media ${device.laptop} {
                font-size: 12px;
                line-height: normal;
                padding-top: 9.5px;
            }

            @media ${device.laptopL} {
                font-size: 15px;
                line-height: normal;
                padding-top: 6.5px;
            }

            & > a {
                color: #000;
                text-decoration: underline;
            }
            &::before {
                border-radius: .25rem;
                transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                position: absolute;
                top: 9px;
                left: -3px;
                display: block;
                width: 1rem;
                height: 1rem;
                pointer-events: none;
                content: "";
                background-color: #dee2e6;
                border: transparent;
            }

            &::after {
                position: absolute;
                top: 10px;
                left: 2px;
                display: block;
                width: 6px;
                height: 10px;
                content: "";
            }

            &.invalid {
                &::before {
                    border: 1px solid #8f3b4e;
                }
            }
        }

        #formBasicAgreement:checked~label::before, #formBasicPreFlightChecklists:checked~label::before{
            border-color: #3f9f32;
            background-color: #3f9f32;
        }

        #formBasicAgreement:checked~label::after, #formBasicPreFlightChecklists:checked~label::after {
            transform: rotate(45deg);
            border-style: solid;
            border-color: rgb(255, 255, 255);
            border-image: initial;
            border-width: 0px 2px 2px 0px;
        }

        input {
            width: 12px;
            position: absolute;
            z-index: -1;
            opacity: 0;
        }
    }

    .form-group {
        margin-bottom: 10px;

        .input-wrapper {
            position: relative;
            border: 2px solid #060e0a;
            border-radius: 24px;
            background-color: #0E151E;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px 7px;
    
            &.is-invalid {
                border: 1px solid #8f3b4e !important;
            }
    
            .input-group { 
                .form-control {
                    background: none;
                    border: none;
                    color: #d5d5d5;
                    font-weight: 600;
                    font-size: 14px;
                    box-shadow: none;
                }

                .input-group-append, .input-group-prepend {
                    .input-group-text {
                        background: none;
                        border: none;

                        @media screen and (max-width: 766px) and (min-width: 320px) {
                            padding: 0 5px;
                        }
                    }
                }
    
                .input-group-append {
                    .input-group-text {
                        cursor: pointer;
                    }
                }

                .input-group-prepend .input-group-text {                    
                    color: #15bd6f;
                }
            }
        }

        .rememberMe {
            font-size: 13px;
            display: flex;
            align-items: center;
            
            
            input {
                margin-top: 0;
            }

            input, label {
                cursor: pointer;
            }
        }
    }

    .auth-btn {
        font-size: 16px;
        transition: transform 0.2s ease 0s;
        width: 100%;
        margin-top: 25px;
        padding: 6px 7px;
        border-radius: 13px;
        background-color: #1ea164;
        border: 2px solid #23d381;
        border-radius: 20px;

        @media screen and (max-width: 1440px) and (min-width: 1200px) {
            font-size: 14px;
        }

        &:hover {
            color: rgb(255, 255, 255);
            transform: scale(1.05);
            backface-visibility: hidden;
            overflow: auto;
        }
    }
`

export {
    FormContainer,
    FormHeadingWrapper,
    FormBodyWrapper
}
