import { fastSpringUrl } from '../services/CONSTANTS'

const decorateURL = (url) => {
    if (window.clsid) {
        var _pl = '&clientId=' + window.clsid.client_id + '&sessionId=' + window.clsid.session_id        
    }
    return url + '?' + _pl
}

export const InjectFastSpring = () => {
    if (document.getElementById("fsc-api") == null) {
        let fastSpringContent = document.createElement('script');
        fastSpringContent.src = "https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.8.3/fastspring-builder.min.js";
        fastSpringContent.async = true;
        fastSpringContent.id = "fsc-api";
        fastSpringContent.type = "text/javascript";
        fastSpringContent.setAttribute('data-storefront', fastSpringUrl);
        fastSpringContent.setAttribute('data-popup-closed', "onPaymentReceived");
        // gtm integration
        fastSpringContent.setAttribute('data-decorate-callback', decorateURL)
        document.body.appendChild(fastSpringContent);
    }
}