import { Card, Col, Container, Row, SafeAnchor } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { BodyContainer, BodyFeatures, BodyWrapper, FeatureContents, FeatureContentsWrapper, LoadingWrapper, OnboardingStyle, TitleWrapper, WavesEffect } from './styled_components'

export const PaymentView = props => {
    const $state = props.state
    const { isRedirectedToPayment, isContentLoaded, isPriceLoading, subscription } = $state

    //#region Render
    const renderPreLoader = () => (
        <LoadingWrapper>
            <img srcSet={'/images/layout/loader-150.gif'} alt="" className="img-fluid" style={{ width: '200px', height: '200px' }} />
        </LoadingWrapper>
    )

    const renderMonthlyCard = () => (
        <Card className="popular m-lg-0 m-sm-2 monthly-yearly-card">
            <Card.Body>
                <section className="text-center">
                    <p className="monthly-yearly-title">Monthly</p>

                    <div className="monthly-year-info">
                        {
                            isPriceLoading ?
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                :
                                <h4>
                                    ${subscription[0].price}
                                    <span className="per-month"> /month</span>
                                </h4>
                        }
                        <section className="monthly-description">
                            <ul className="description-list">
                                <li>&nbsp;</li>
                                <li className="text-dark">1 month membership</li>
                                <li className="text-dark">No Setup Fee</li>
                                <li className="text-dark">Cancel Anytime</li>
                            </ul>
                        </section>
                        <div className="price-subtext">
                            <div className="btn-wrapper">
                                <button
                                    name="vip"
                                    onClick={e => props.onPaymentHandler(e, 'monthly')}
                                    className="btn btn-warning btn-select">
                                    Get Started
                                </button>
                            </div>
                            <div className="additional-info">
                                <strong>Crypto Payment:</strong>
                                <br />
                                <span>*Crypto payment is only available for <br />Quarterly and Yearly Subcriptions</span>
                            </div>
                        </div>
                    </div>
                </section>
            </Card.Body>
        </Card>
    )

    const renderQuarterlyCard = () => (
        <Card className="popular m-lg-0 m-sm-2 quarterly-card">
            <div></div>
            <Card.Body>
                <section className="text-center">
                    <p className="saving-ribbon">
                        Save 33% /m
                    </p>
                    <p className="popular-text">
                        <span className="qrter-title">
                            Quarterly
                        </span>
                        <span className="text-center">
                            <span className="badge badge-pill badge-success d-relative most-popular-badge">
                                Most Popular
                            </span>
                        </span>
                    </p>
                    {
                        isPriceLoading ?
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            :
                            <h4 className="quarterly-price">${subscription[1].price} <span className="per-month"> /month</span></h4>
                    }
                    <section className="quarterly-description">
                        <ul className="description-list">
                            <li className="text-dark annual-save">
                                <i className='fas fa-piggy-bank icon'></i>
                                {/* <b>${onQuarterlySaveCalculation()} in annual saving!</b> */}
                                <b>Save $298 per quarter</b>
                            </li>
                            {/* <li className="text-green">Save 33%</li> */}
                            <li className="text-dark">Billed Quarterly</li>
                            <li className="text-dark">No Setup Fee</li>
                            <li className="text-dark">Cancel Anytime</li>
                        </ul>
                    </section>
                    <div className="price-subtext">
                        <div className="btn-wrapper">
                            <button
                                name="vip"
                                onClick={e => props.onPaymentHandler(e, 'quarterly')}
                                className="btn btn-warning btn-select btn-popular">
                                Get Started
                            </button>
                        </div>
                        <div className="additional-info">
                            <strong>Crypto Payment:</strong>
                            <br />
                            <SafeAnchor href="https://www.profitfarmers.com/crypto-payment/" target="_blank" className="btn-link">
                                <img src="/images/btn-coinpayments.png" alt="Pay with CoinPayments" />
                            </SafeAnchor>
                        </div>
                    </div>
                </section>
            </Card.Body>
        </Card>
    )

    const renderYearlyCard = () => (
        <Card className="popular m-lg-0 m-sm-2 monthly-yearly-card">
            <Card.Body>
                <section className="text-center">
                    <p className="monthly-yearly-title">Yearly </p>
                    {
                        isPriceLoading ?
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            :
                            <h4>
                                {/* ${subscription[2].price} */}
                                $299
                                <span className="per-month"> /month</span>
                            </h4>
                    }
                    <section className="yearly-description">
                        <ul className="description-list">
                            {/* <li className="text-dark annual-save">
                                <i className='fas fa-piggy-bank icon'></i>
                                <b>Save up to $1589 per year</b>
                            </li> */}
                            <li>&nbsp;</li>
                            <li className="text-dark">Billed Yearly</li>
                            <li className="text-dark">No Setup Fee</li>
                            <li className="text-dark">Cancel Anytime</li>
                        </ul>
                    </section>
                    <div className="price-subtext">
                        <div className="btn-wrapper">
                            <button
                                name="vip"
                                onClick={e => props.onPaymentHandler(e, 'yearly')}
                                className="btn btn-warning btn-select">
                                Get Started
                            </button>
                        </div>
                        <div className="additional-info">
                            <strong>Crypto Payment:</strong>
                            <br />
                            <SafeAnchor href="https://www.profitfarmers.com/crypto-payment/" target="_blank" className="btn-link">
                                <img src="/images/btn-coinpayments.png" alt="Pay with CoinPayments" />
                            </SafeAnchor>
                        </div>
                    </div>
                </section>
            </Card.Body>
        </Card>
    )

    const renderRefundContent = () => (
        <>
            {/* <h2 className="content-title"><span style={{ color: '#3fa032' }}>100% Refund</span> if we don't deliver<br />a 60% Signal Win Rate</h2> */}

            <div className="refund-content-wrapper">                
                <Row>
                    <Col sm={12} md={9} lg={9}>
                        <div className="img-container">
                            <img alt="" className="img-fluid" src="/images/profitfarmers-money-back-guarantee-ribbon.png" />
                        </div>
                    </Col>
                    <Col sm={12} md={3} lg={3}>
                        <div className="img-container partner-stamp">
                            <img alt="" className="img-fluid" src="/images/Partner-Stamp-Logo-.png" />
                        </div>
                        {/* <div className="content-body">
                            <p>
                                <span style={{ fontWeight: '400' }}>We will offer you a full </span><b>100% refund</b>
                                <span style={{ fontWeight: '400' }}> during your </span><b>first 30 days</b>
                                <span style={{ fontWeight: '400' }}> with ProfitFarmers if we are unable to provide trading signals</span>
                                <span style={{ fontWeight: '400' }}> that achieve a </span><b>60%</b>
                                <span style={{ fontWeight: '400' }}> win rate vs lose rate or higher during that time period.</span>
                            </p>
                            <p>
                                <span style={{ fontWeight: '400' }}>Winning signals are defined as those that reach their first target (or higher) and, therefore, offer some level of profitable opportunity. You can read more about </span>
                                <a href="https://www.profitfarmers.com/how-are-trading-signal-results-statistics-calculated/" style={{ color: '#3fa032' }} target="_blank" rel="noopener noreferrer">
                                    <span style={{ fontWeight: '400' }}>how our signal statistics are calculated here</span>
                                </a>
                                <span style={{ fontWeight: '400' }}>.</span>
                            </p>
                            <p>
                                <span style={{ fontWeight: '400' }}>Please note that <b>we obviously cannot guarantee your personal level of performance</b></span>
                                <span style={{ fontWeight: '400' }}> as it will depend on your skill, dedication and choices (and of course some luck).  We can only guarantee that from our side we will produce signals that offer profitable potential at least 60% of the time.</span>
                            </p>
                            <p>
                                <span style={{ fontWeight: '400' }}>How can you claim? Get in touch with support and we will investigate the signal performance during the given date range. If the system did not produce a 60%+ winners vs losers rate we will issue the refund. Simple!</span>
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    <a href="https://www.profitfarmers.com/refund-policy/" style={{ color: '#3fa032' }} target="_blank" rel="noopener noreferrer"><em>Read more about our Refund Policy</em></a>
                                </span>
                            </p>
                        </div > */}
                    </Col >
                </Row >
            </div>
        </>
    )

    const renderPreFlightContent = () => (
        <>
            <Row>
                <Col>
                    <div className="pre-flight-wrapper">
                        <div className="thumbnail">
                            <img src="/images/preflight-checklist-bg.png" alt="" />
                        </div>
                        <div className="pre-flight-content">
                            <h4>Hey there ProfitFarmers</h4>
                            <p>Before you fly we just want to make sure you understand:</p>
                            <ul>
                                <li>
                                    <i>
                                        <img src="/images/checklists/check.png" alt="" />
                                    </i>
                                    <span>Trading can bring big profits but also losses</span>
                                </li>
                                <li>
                                    <i>
                                        <img src="/images/checklists/check.png" alt="" />
                                    </i>
                                    <span>This is not a 1 click get rich scheme, you will not make money purely on autopilot</span>
                                </li>
                                <li>
                                    <i>
                                        <img src="/images/checklists/check.png" alt="" />
                                    </i>
                                    <span>You must be able to use a Binance.com account (not binance.us)</span>
                                </li>
                                <li>
                                    <i>
                                        <img src="/images/checklists/check.png" alt="" />
                                    </i>
                                    <span>Our <SafeAnchor href="https://www.profitfarmers.com/refund-policy" target="_blank">refund policy</SafeAnchor></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )

    const renderFeatures = () => (
        <Container>
            <h2 className="content-title">Features</h2>

            <FeatureContents>
                <ul>
                    <li className="trading-wrapper">
                        <i className="fas fa-seedling"></i>
                        <p className="list-title">Trading Signals</p>

                        <p className="list-description">Provided by our algorithms and experts​</p>
                    </li>

                    <li className="trading-wrapper">
                        <i className="fas fa-robot"></i>
                        <p className="list-title">Auto-Trading</p>

                        <p className="list-description">Copy trades with a click​</p>
                    </li>

                    <li className="trading-wrapper">
                        <i className="fas fa-balance-scale"></i>
                        <p className="list-title">Leverage Trading</p>

                        <p className="list-description">Maximise your gains by multiplying your trading balance up to 125x</p>
                    </li>

                    <li className="trading-wrapper">
                        <i className="fas fa-tachometer-alt"></i>
                        <p className="list-title">RSI Scanner</p>

                        <p className="list-description">Discover hidden opportunities​</p>
                    </li>

                    <li className="trading-wrapper">
                        <i className="far fa-chart-bar"></i>
                        <p className="list-title">Price Action Scanner</p>

                        <p className="list-description">Find trends and reversals to trade​</p>
                    </li>

                    <li className="trading-wrapper">
                        <i className="fas fa-book-open"></i>
                        <p className="list-title">Education</p>

                        <p className="list-description">Learn how to trade profitably with ProfitFarmers How to Videos and Trading Tutorials</p>
                    </li>

                    <li className="trading-wrapper no-content"></li>

                    <li className="trading-wrapper">
                        <img alt="" src="/images/binance-logo.svg" className="img-fluid" />
                        <p className="list-title">Works With Binance</p>

                        <p className="list-description">
                            Binance <br />
                            Binance Margin <br />
                            Binance Futures
                        </p>
                    </li>

                    <li className="trading-wrapper no-content"></li>
                </ul>
            </FeatureContents>
        </Container>
    )

    //#endregion

    if (isContentLoaded)
        renderPreLoader()
    else if (isRedirectedToPayment)
        return (<Redirect to='/payment/complete' />)
    else
        return (
            <BodyWrapper>
                <BodyContainer>
                    <OnboardingStyle>
                        <Container className="default-container">
                            <Row className="center">
                                <Card className="custom-card">
                                    <Card.Body>
                                        <p className="text-center mt-4 mb-5">
                                            <img className="img-fluid" width="350" src="/images/logo/top-header.png" alt="" />
                                        </p>
                                        <section>
                                            <TitleWrapper className="mb-2">
                                                <h2 className="pf-header">The Most Powerful Crypto Trading Tool:</h2>
                                                <p className="pf-subheader">All the Signals and Tools You Need To Become a Profitable Trader</p>
                                            </TitleWrapper>
                                        </section>
                                        <div className="row justify-content-center custom-section">
                                            <Col lg={4}>
                                                {renderMonthlyCard()}
                                            </Col>
                                            <Col lg={4}>
                                                {renderQuarterlyCard()}
                                            </Col>
                                            <Col lg={4}>
                                                {renderYearlyCard()}
                                            </Col>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Row>
                        </Container>
                    </OnboardingStyle>
                </BodyContainer>

                <BodyContainer className="guarantee">
                    <OnboardingStyle className="pt-lg-0">
                        <Container>
                            {renderRefundContent()}
                            
                            {renderPreFlightContent()}
                        </Container>
                    </OnboardingStyle>
                </BodyContainer>

                <BodyFeatures>
                    <WavesEffect></WavesEffect>
                    <FeatureContentsWrapper>
                        {renderFeatures()}
                    </FeatureContentsWrapper>
                </BodyFeatures>

            </BodyWrapper>
        )

}