import { baseUrl, endpoints } from "./CONSTANTS";

const { visitor } = endpoints

async function getVisitorId(vid) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    }

    try {
        const response = await fetch(`${baseUrl}${visitor.getVisitorId(vid)}`, requestOptions);
        const response_1 = await handleResponse(response);
        return Promise.resolve(response_1);
    } catch (error) {
        return Promise.reject({ error });
    }
}


async function addVisitation(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(data)
    }

    try {
        const response = await fetch(`${baseUrl}${visitor.addVisitation}`, requestOptions);
        const response_1 = await handleResponse(response);
        return Promise.resolve(response_1);
    } catch (error) {
        return Promise.reject({ error });
    }
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        //if the token is expired
        if (response.status === 401)
            return 401
        else
            return data;
    });
}

export const visitorServices = {
    getVisitorId,
    addVisitation
}