import { subscriptionActionTypes } from '../CONSTANTS'

export function subscriptionDetails(state={}, action) {
    switch(action.type) {
        case subscriptionActionTypes.GET_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case subscriptionActionTypes.GET_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                subscription: action.response.details
            }
        case subscriptionActionTypes.GET_DETAILS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function cancelSubscription(state = {}, action) {
    switch (action.type) {
        case subscriptionActionTypes.CANCEL_SUB_REQUEST:
            return {
                ...state,
                loading: true
            }
        case subscriptionActionTypes.CANCEL_SUB_SUCCESS:
            return {
                ...state,
                loading: false,
                subscription: action.subscription,
            }
        case subscriptionActionTypes.CANCEL_SUB_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}