import { signalDetailsActionTypes } from '../CONSTANTS'
import { signalDetailsServices } from '../../services'
import { decryptText, toCamelCase } from '../../utils'

function getSignalDetails(signalId) {
    return dispatch => {
        dispatch(request())

        signalDetailsServices.getById(signalId)
        .then(response => {
            let signalsDetailsData = decryptText(response)
            let parsedData = JSON.parse(signalsDetailsData, toCamelCase)
            dispatch(success(parsedData))
        }, error => {
            dispatch(failed(error))
        })
    }

    function request() { return { type: signalDetailsActionTypes.GET_BY_ID_REQUEST } }
    function success(signalDetails) { return { type: signalDetailsActionTypes.GET_BY_ID_SUCCESS, signalDetails } }
    function failed(error) { return { type: signalDetailsActionTypes.GET_BY_ID_FAIL, error } }
}

function updateSignalDetails(signalDetails) {
    return dispatch => {
        dispatch({
            type: signalDetailsActionTypes.UPDATE_SIGNAL_DETAILS,
            signalDetails
        })
    }
}

export const signalDetailsActions = {
    getSignalDetails,
    updateSignalDetails
}