import { onboardingActionTypes } from '../CONSTANTS'

export function onboardingUser(state={}, action) {
    switch(action.type) {
        case onboardingActionTypes.GET_ONBOARDING_STATE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case onboardingActionTypes.GET_ONBOARDING_STATE_SUCCESS:
            return {
                ...state,
                loading: false,
                status: action.response
            }
        case onboardingActionTypes.GET_ONBOARDING_STATE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function onboardingUserProfile(state={}, action) {
    switch(action.type) {
        case onboardingActionTypes.GET_ONBOARDING_USER_PROFILE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case onboardingActionTypes.GET_ONBOARDING_USER_PROFILE_SUCCESS: 
            return {
                ...state,
                loading: false,
                profile: action.response.userProfile
            }
        case onboardingActionTypes.GET_ONBOARDING_USER_PROFILE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function onboardingUserTradingExpDetails(state={}, action) {
    switch(action.type) {
        case onboardingActionTypes.GET_ONBOARDING_USER_TRADING_EXP_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case onboardingActionTypes.GET_ONBOARDING_USER_TRADING_EXP_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                trading_exp: action.response.experienceDetails
            }
        case onboardingActionTypes.GET_ONBOARDING_USER_TRADING_EXP_DETAILS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function onboardingUserFinancialDetails(state={}, action) {
    switch(action.type) {
        case onboardingActionTypes.GET_ONBOARDING_USER_FINANCIAL_DETAILS_REQUEST: 
            return {
                ...state,
                loading: true
            }
        case onboardingActionTypes.GET_ONBOARDING_USER_FINANCIAL_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                financial_details: action.response.financialDetails
            }
        case onboardingActionTypes.GET_ONBOARDING_USER_FINANCIAL_DETAILS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function userWalletBalances(state={}, action) {
    switch(action.type) {
        case onboardingActionTypes.GET_ONBOARDING_USER_WALLET_BALANCES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case onboardingActionTypes.GET_ONBOARDING_USER_WALLET_BALANCES_SUCCESS:
            return {
                ...state,
                loading: false,
                wallet_balances: action.response
            }
        case onboardingActionTypes.GET_ONBOARDING_USER_WALLET_BALANCES_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function saveOnboardingUserProfile(state={}, action) {
    switch(action.type) {
        case onboardingActionTypes.SAVE_ONBOARDING_USER_PROFILE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case onboardingActionTypes.SAVE_ONBOARDING_USER_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.respose
            }
        case onboardingActionTypes.SAVE_ONBOARDING_USER_PROFILE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function saveOnboardingUserTradingExpDetails(state={}, action) {
    switch(action.type) {
        case onboardingActionTypes.SAVE_ONBOARDING_USER_TRADING_EXP_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case onboardingActionTypes.SAVE_ONBOARDING_USER_TRADING_EXP_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.respose
            }
        case onboardingActionTypes.SAVE_ONBOARDING_USER_TRADING_EXP_DETAILS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function saveOnboardingUserFinancialDetails(state={}, action) {
    switch(action.type) {
        case onboardingActionTypes.SAVE_ONBOARDING_USER_FINANCIAL_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case onboardingActionTypes.SAVE_ONBOARDING_USER_FINANCIAL_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.response
            }
        case onboardingActionTypes.SAVE_ONBOARDING_USER_FINANCIAL_DETAILS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function saveExchangeKeys(state={}, action) {
    switch(action.type) {
        case onboardingActionTypes.SAVE_ONBOARDING_USER_EXCHANGE_KEYS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case onboardingActionTypes.SAVE_ONBOARDING_USER_EXCHANGE_KEYS_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.response
            }
        case onboardingActionTypes.SAVE_ONBOARDING_USER_EXCHANGE_KEYS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function configureExchangeIntegration(state={}, action) {
    switch(action.type) {
        case onboardingActionTypes.CONFIGURE_EXCHANGE_INTEGRATION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case onboardingActionTypes.CONFIGURE_EXCHANGE_INTEGRATION_SUCCESS: 
            return {
                ...state,
                loading: false,
                result: action.response
            }
        case onboardingActionTypes.CONFIGURE_EXCHANGE_INTEGRATION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function configureTradingBalance(state={}, action) {
    switch(action.type) {
        case onboardingActionTypes.CONFIGURE_TRADING_BALANCE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case onboardingActionTypes.CONFIGURE_TRADING_BALANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.response
            }
        case onboardingActionTypes.CONFIGURE_TRADING_BALANCE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function setTradingBalance(state={}, action) {
    switch(action.type) {
        case onboardingActionTypes.SET_TRADING_BALANCE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case onboardingActionTypes.SET_TRADING_BALANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                orderResult: action.response
            }
        case onboardingActionTypes.SET_TRADING_BALANCE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function setToComplete(state={}, action) {
    switch(action.type) {
        case onboardingActionTypes.SET_ONBOARDING_COMPLETE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case onboardingActionTypes.SET_ONBOARDING_COMPLETE_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.response
            }
        case onboardingActionTypes.SET_ONBOARDING_COMPLETE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}

export function skipOnboarding(state={}, action) {
    switch(action.type) {
        case onboardingActionTypes.SKIP_ONBOARDING_REQUEST:
            return {
                ...state,
                loading: true
            }
        case onboardingActionTypes.SKIP_ONBOARDING_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.response
            }
        case onboardingActionTypes.SKIP_ONBOARDING_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: return state
    }
}