import React from 'react'
import { Card, Accordion, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons"
import _ from 'lodash'
import {
    NotificationLists, NotificationItems,
    NotificationItemToggle, ToggleButton, RadioButton } from './styled_components'
import { isNotNull } from '../../../../utils'

export default class NotificationTypes extends React.PureComponent {
    onToggleHandler = (key) => {
        this.props.toggle(key)
    }

    onGetNotificationIcon = (types) => {
        switch (types) {
            case 1:
                return 'far fa-stop-circle'
            case 2:
                return 'fas fa-rocket'
            case 3:
                return 'fas fa-percent'
            case 4:
                return 'fas fa-info-circle'
            case 5:
                return 'fas fa-external-link-alt'
            case 9: 
                return 'fas fa-bullhorn'
            default:
                return 'fas fa-seedling'
        }
    }

    onRenderLoader = () => {
        return (
            <>
                <div className="pf-spinner xs" style={{ marginTop: '-46px', marginRight: '27px' }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </>
        )
    }

    onRenderToggle = (value, key, filteredData) => {
        const { toggles } = this.props
        return (
            <>
                <div className="header-wrapper">
                    <i className={this.onGetNotificationIcon(value.type)}></i>
                    <div className="title">
                        {value.desc}
                        <small>{isNotNull(filteredData) ? filteredData : "In-app only"}</small>
                    </div>
                </div>
                {toggles[key] ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />}
            </>
        )
    }

    onRenderWatchlisted = (id, type, isWatchlistedOnly) => {
        return (
            <>
                <h6>Frequency</h6>
                <NotificationLists className="sub-notif-lists">
                    <NotificationItems className="with-divider">
                        <span className="item-label">
                            <span>All</span><br />
                            <small>All signals</small>
                        </span>
                        
                        <RadioButton>
                            <Form.Check 
                                label="" 
                                type="radio" 
                                id={`all-${type}`} 
                                name={`notification-frequency-${type}`}
                                checked={!isWatchlistedOnly}
                                onChange={() => this.props.watchlistedHandler(id, type, false)} />
                        </RadioButton>
                    </NotificationItems>

                    <NotificationItems className="with-divider">
                        <span className="item-label">
                            <span>Watchlisted Only</span><br />
                            <small>Only signals that you have traded or added to your watchlist</small>
                        </span>
                        
                        <RadioButton>
                            <Form.Check 
                                label="" 
                                type="radio" 
                                id={`watchlisted-${type}`}
                                name={`notification-frequency-${type}`}
                                checked={isWatchlistedOnly}
                                onChange={() => this.props.watchlistedHandler(id, type, true)} />
                        </RadioButton>
                    </NotificationItems>
                </NotificationLists>
            </>
        )
    }

    onRenderPush = (id, allowPush) => {
        const { isUpdatingPushTypeSettings } = this.props.state
        return (
            <>
                <span className="push-icon-wrapper item-label">
                    <div className="push-icon">
                        <div className="push-circle"></div>
                    </div>

                    <span>Push</span>
                </span>
                        
                { isUpdatingPushTypeSettings && this.onRenderLoader() }
                { !isUpdatingPushTypeSettings && 
                    <>
                        <NotificationItemToggle onClick={(e) => this.props.onChangePushSettings(e, id, !allowPush)}>
                            <ToggleButton className={`notification-toggle-push ${(allowPush) ? 'active' : ''}`}>
                                <span className="toggle-label"> {allowPush ? 'On' : 'Off'} </span>
                                <div className="input-cont">
                                    <input type="checkbox" 
                                        id={`push-${id}`}
                                        name={`push-settings-${id}`}
                                        checked={allowPush} 
                                        readOnly />
                                    <i className={`notification-toggle-push ${(allowPush) ? 'active' : ''}`} />
                                </div>
                                
                            </ToggleButton>
                        </NotificationItemToggle>
                    </>
                }
            </>
        )
    }

    onRenderEmail = (id, allowEmail) => {
        const { isUpdatingEmailTypeSettings } = this.props.state
        return (
            <>
                <span className="item-label">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <span>Email</span>
                </span>

                { isUpdatingEmailTypeSettings && this.onRenderLoader()}
                { !isUpdatingEmailTypeSettings && 
                    <>
                        <NotificationItemToggle onClick={(e) => this.props.onChangeEmailSettings(e, id, !allowEmail)}>
                            <ToggleButton className={`notification-toggle-email ${(allowEmail) ? 'active' : ''}`}>
                                <span className="toggle-label"> { allowEmail ? 'On' : 'Off'} </span>
                                <div className="input-cont">
                                    <input type="checkbox" 
                                        id={`email-${id}`} 
                                        name={`email-settings-${id}`}
                                        checked={allowEmail} 
                                        readOnly />
                                    <i className={`notification-toggle-email ${(allowEmail) ? 'active' : ''}`} />
                                </div>
                            </ToggleButton>
                        </NotificationItemToggle>
                    </>
                }
            </>
        )
    }

    onRenderNotificationTypes = () => {
        const { userInfo, notification_settings } = this.props
        if (isNotNull(notification_settings)) {
            const { data } = notification_settings
            if (isNotNull(data) &&
                isNotNull(data.types)) {
                if (userInfo.isAutoCloseEnabled) {
                    data.types = _.remove(data.types, (t) => {
                        return t.type !== 4
                    })
                } else {
                    data.types = _.remove(data.types, (t) => {
                        return t.type !== 5
                    })
                }

                const order = [0, 2, 3, 1, 4, 5, 9]
                const sortByOrder = _.sortBy(data.types, (val) => {
                    return _.indexOf(order, val.type)
                })
                return _.map(sortByOrder, (value, key) => {
                    let notificationsReceive = []
                    if (value.isPushAllowed)
                        notificationsReceive[0] = "Push"
                    if (value.type !== 9 && value.isEmailAllowed)
                        notificationsReceive[1] = "Email"

                    const filteredData = _.filter(notificationsReceive).join(', ')
                    return (
                        <Card key={key}
                            style={{ backgroundColor: '#192431', backgroundImage: 'none' }}>
                            <Accordion.Toggle 
                                as={Card.Header} 
                                eventKey={value.Id} 
                                onClick={() => this.onToggleHandler(key)} 
                                style={{ backgroundColor: '#192431' }}>
                                {this.onRenderToggle(value, key, filteredData)}
                            </Accordion.Toggle>

                            <Accordion.Collapse id={key} eventKey={value.Id}>
                                <Card.Body>
                                    { value.type !== 0 && value.type !== 5 && value.type !== 9 && 
                                        <>
                                            {/* <small>
                                                These are notifications of <span className="value-desc">{value.desc}</span> you may have missed
                                            </small>

                                            <NotificationItems className="sub-notif-item">
                                                { this.onRenderWatchlisted(value.type) }
                                            </NotificationItems> */}

                                            { this.onRenderWatchlisted(value.Id, value.type, value.isWatchlistedOnly) }
                                        </>
                                    }

                                    <div className="notification-type">
                                        <h6>Where you receive these notifications</h6>

                                        <NotificationLists className="sub-notif-lists">
                                            <NotificationItems key={`${key}-push-settings-${value.Id}`}>
                                                {this.onRenderPush(value.Id, value.isPushAllowed)}
                                            </NotificationItems>

                                            { value.type !== 9 && 
                                                <NotificationItems key={`${key}-email-settings-${value.Id}`}>
                                                    {this.onRenderEmail(value.Id, value.isEmailAllowed)}
                                                </NotificationItems>
                                            }
                                        </NotificationLists>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )
                })
            }
        }
    }

    render() {
        return (
            <>
                <Accordion>
                    {this.onRenderNotificationTypes()}
                </Accordion>
            </>
        )
    }
} 